import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import {
  getExpandedAppointmentFromBundle,
  getRelativesData,
} from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import {
  getLastNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import { MasterFhirClient } from 'services/masterFhirService'
import { GroupPatient } from 'models/groupPatient'
import moment from 'moment'
import { PatientSearchStatus } from './patientSearchStatusTypes'

const initialState: PatientSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const patientSearchSlice = createSlice({
  name: 'patientSearch',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<PatientSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.patientListWithAppointment =
        action.payload.patientListWithAppointment
      state.patientList = action.payload.patientList
      state.groupDataList = action.payload.groupDataList
      state.totalCount = action.payload.totalCount
    },

    noDataFoundForSearch(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.patientListWithAppointment =
        action.payload.patientListWithAppointment
      state.patientList = action.payload.patientList
      state.groupDataList = action.payload.groupDataList
      state.totalCount = action.payload.totalCount
    },

    errorWhileSearching(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.patientListWithAppointment =
        action.payload.patientListWithAppointment
      state.patientList = action.payload.patientList
      state.groupDataList = action.payload.groupDataList
      state.totalCount = action.payload.totalCount
    },
    resetState(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.patientList = undefined
      state.groupDataList = undefined
      state.totalCount = undefined
    },
  },
})

export const resetPatientSearchPatientTable =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PatientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      patientList: undefined,
    }
    dispatch(patientSearchSlice.actions.resetState(state))
  }

export const searchPatientsForList =
  (
    pageNumber: number,
    perPageCount: number,
    phoneNumber: string,
    name: string,
    gender: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: PatientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(patientSearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        active: true,
        _total: 'accurate',
        _sort: '-_lastUpdated',
        _offset: pageNumber === 0 ? 0 : perPageCount * pageNumber,
        _count: perPageCount,
      }
      if (gender.length > 0) {
        searchParameters.gender = gender.trim()
      }

      let firstName: string = ''
      let middleName: string = ''
      let lastName: string = ''

      if (name.includes(' ')) {
        const localNameArr: string[] = name.split(' ')
        if (localNameArr.length > 2) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          middleName = localNameArr[2]
          lastName = localNameArr[1]
          firstName = localNameArr[0]
        } else if (localNameArr.length === 2) {
          lastName = localNameArr[1]
          firstName = localNameArr[0]
        } else {
          firstName = name
        }
      } else {
        firstName = name
      }
      if (name.length === 0 && phoneNumber.length === 0) {
        searchParameters._total = 'accurate'
      }

      if (phoneNumber.length > 0 && gender.length === 0) {
        searchParameters.phone = `+91${phoneNumber.trim()}`
      }

      //   searchParameters._sort = 'family,given'
      axios.CancelToken.source()
      if (cancelTokenStore.has('patientSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'patientSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('patientSearchControlToken')
      }
      cancelTokenStore.set(
        'patientSearchControlToken',
        axios.CancelToken.source()
      )
      let response: any = {}
      if (name.length > 0) {
        response = await fhirClient.doGetResource(
          `/Patient?name:contains=${firstName}&given=${middleName}&family=${lastName}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (gender.length === 0 && phoneNumber.length > 0) {
        response = await fhirClient.doGetResource(
          `/Patient?patient-identifier-phone-new:contains=${phoneNumber}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (gender.length === 0 && phoneNumber.length > 0) {
        response = await fhirClient.doGetResource(
          `/Patient?patient-identifier-phone-new:contains=${phoneNumber}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (gender.length > 0 && phoneNumber.length === 0) {
        response = await fhirClient.doGetResource(
          `/Patient`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (gender.length > 0 && phoneNumber.length > 0) {
        response = await fhirClient.doGetResource(
          `/Patient?patient-identifier-phone-new:contains=${phoneNumber}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (
        gender.length === 0 &&
        phoneNumber.length === 0 &&
        name.length === 0
      ) {
        response = await fhirClient.doGetResource(
          `/Patient`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      }

      if (response !== undefined) {
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        const patientResponses: R4.IBundle = response
        const total = patientResponses.total ?? 0
        if (patientResponses.entry) {
          const fhirPatientData: FhirPatientDetail[] =
            getExpandedAppointmentFromBundle(patientResponses)
          const patientArray: R4.IPatient[] = fhirPatientData.map(
            (item) => item.patient as R4.IPatient
          )

          //   patientArray.sort((a, b) =>
          //     (a.name ? (a.name[0].given ? a.name[0].given[0] : '') : '')
          //       .toLowerCase()
          //       .localeCompare(
          //         (b.name
          //           ? b.name[0].given
          //             ? b.name[0].given[0]
          //             : ''
          //           : ''
          //         ).toLowerCase()
          //       )
          //   )
          //   fhirPatientData.sort((a, b) =>
          //     a.patientName
          //       .toLowerCase()
          //       .localeCompare(b.patientName.toLowerCase())
          //   )

          //   const finalFhirPatientData: FhirPatientDetail[] = []

          //   finalFhirPatientData.sort((a, b) =>
          //     a.patientName
          //       .toLowerCase()
          //       .localeCompare(b.patientName.toLowerCase())
          //   )

          const searchPatientResult: PatientSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            patientListWithAppointment: fhirPatientData,
            patientList: patientArray,
            totalCount:
              name.length > 0
                ? total > 0
                  ? total
                  : patientArray.length
                : total,
            groupDataList: [],
          }
          dispatch(
            patientSearchSlice.actions.searchResults(searchPatientResult)
          )
          return
        }

        const noSearchResults: PatientSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          patientSearchSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return

        // const errorWhileSearchPatient: PatientSearchStatus = {
        //   error: true,
        //   noResultsAvailable: false,
        //   resultsAvailable: false,
        //   searching: false,
        //   errorMessage: 'Error while searching',
        // }
        // dispatch(
        //   patientSearchSlice.actions.errorWhileSearching(
        //     errorWhileSearchPatient
        //   )
        // )
        // return
      }
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: PatientSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        patientSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

export async function getPatientCreatedDate(
  patientData: FhirPatientDetail
): Promise<FhirPatientDetail> {
  const data: FhirPatientDetail = { ...patientData }
  let date: string = ''
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Patient/${patientData.patient.id}/_history/1`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IPatient> =
    R4.RTTI_Patient.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IPatient = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        date = moment(patResponse.meta.lastUpdated).format('DD-MM-YYYY')
      }
    }
  }
  data.patientCreatedDate = date
  return data
}

async function getRelatives(
  patientData: FhirPatientDetail
): Promise<FhirPatientDetail> {
  let patDetails: FhirPatientDetail = patientData
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/RelatedPerson?patient=${patDetails.patient.id}`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          patDetails = getRelativesData(orgResponse, patDetails)
        }
      }
    }
  }
  return patDetails
}

export default patientSearchSlice.reducer
