import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { UnitServices } from 'models/units/unitServices'
import { WelloUnits } from 'models/welloUnit'
import {
  getAddress,
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'

export function getOrganizationType(
  orgResource: R4.IOrganization
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConceptList(
    orgResource.type ?? [],
    'org-type'
  )

  return code?.code
}

export function getWelloUnitsWithMainOrg(
  orgResource: R4.IOrganization[],
  welloUnit: WelloUnits[]
): WelloUnits[] {
  const unitsArrData: WelloUnits[] = []
  for (let i = 0; i < welloUnit.length; i++) {
    const unitRef = welloUnit[i].orgDetails.partOf
    if (unitRef) {
      const mainOrg = orgResource.filter(
        (org: R4.IOrganization) => unitRef.id === org.id
      )
      unitsArrData.push({
        orgDetails: welloUnit[i].orgDetails,
        onboardDate: welloUnit[i].onboardDate,
        mainOrgName: mainOrg.length > 0 ? mainOrg[0].name ?? '' : '',
        mainOrgId: mainOrg.length > 0 ? mainOrg[0].id ?? '' : '',
        orgName: welloUnit[i].orgDetails.name ?? '',
        address: getAddress(welloUnit[i].orgDetails.address).trim() ?? '',
        phone: welloUnit[i].orgDetails.contact
          ? getTelecomFromContactPoints(
              welloUnit[i].orgDetails.contact![0]!.telecom ?? [],
              ContactPointSystemKind._phone
            ) ?? ''
          : '',
        type:
          getCodeOfSystemFromCodableConceptList(
            welloUnit[i].orgDetails.type ?? [],
            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
          )?.display ?? '',
        email: welloUnit[i].orgDetails.contact
          ? getTelecomFromContactPoints(
              welloUnit[i].orgDetails.contact![0]!.telecom ?? [],
              ContactPointSystemKind._email
            ) ?? ''
          : '',
        status:
          welloUnit[i].orgDetails.active &&
          welloUnit[i].orgDetails.active === true
            ? 'Enabled'
            : 'Disabled',
      })
    }
  }

  return unitsArrData
}

export function getWelloUnitsWithUnitOrg(
  orgResource: R4.IOrganization[],
  welloUnit: WelloUnits[]
): WelloUnits[] {
  const unitsArrData: WelloUnits[] = []
  for (let i = 0; i < welloUnit.length; i++) {
    const refArray: string[] = []
    const refOrgs: R4.IOrganization[] = []

    const mainOrg = orgResource.filter(
      (org: R4.IOrganization) =>
        org.partOf && org.partOf.id === welloUnit[i].orgDetails.id
    )
    for (let j = 0; j < mainOrg.length; j++) {
      refOrgs.push(mainOrg[j])
      refArray.push(`Organization/${mainOrg[j].id}`)
    }

    unitsArrData.push({
      orgDetails: welloUnit[i].orgDetails,
      onboardDate: welloUnit[i].onboardDate,
      unitList: refOrgs ?? [],
      mainOrgName: mainOrg.length > 0 ? mainOrg[0].name ?? '' : '',
      mainOrgId: mainOrg.length > 0 ? mainOrg[0].id ?? '' : '',
      unitReference: refArray,
      orgName: welloUnit[i].orgDetails.name ?? '',
      address: getAddress(welloUnit[i].orgDetails.address).trim() ?? '',
      phone: welloUnit[i].orgDetails.contact
        ? getTelecomFromContactPoints(
            welloUnit[i].orgDetails.contact![0]!.telecom ?? [],
            ContactPointSystemKind._phone
          ) ?? ''
        : '',
      email: welloUnit[i].orgDetails.contact
        ? getTelecomFromContactPoints(
            welloUnit[i].orgDetails.contact![0]!.telecom ?? [],
            ContactPointSystemKind._email
          ) ?? ''
        : '',
      status:
        welloUnit[i].orgDetails.active &&
        welloUnit[i].orgDetails.active === true
          ? 'Enabled'
          : 'Disabled',
    })
  }

  return unitsArrData
}

export function getOrgDetailsAlongWithServices(
  responseSlots: R4.IBundle
): UnitServices[] {
  const convertedService: UnitServices[] = []
  const unit: any = {}
  const serviceType: R4.IHealthcareService[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Organization':
                  unit[value.resource.id] = value.resource as R4.IOrganization
                  break
                case 'HealthcareService':
                  serviceType.push(value.resource as R4.IHealthcareService)

                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in unit) {
          if (key) {
            const currentUnit: R4.IOrganization = unit[key] as R4.IOrganization

            const typeData =
              getCodeOfSystemFromCodableConceptList(
                currentUnit.type ?? [],
                'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
              )?.code ?? ''

            const ipdService: R4.IHealthcareService[] = []
            const opdService: R4.IHealthcareService[] = []
            const dayCareService: R4.IHealthcareService[] = []
            const serviceTypeCoding: R4.ICoding[] = []
            if (typeData === 'clinic' || typeData === 'wellness_center') {
              for (let i = 0; i < serviceType.length; i++) {
                if (serviceType[i].active && serviceType[i].active === true)
                  if (serviceType[i].category) {
                    const serviceUnit: string | undefined = serviceType[i]
                      .providedBy
                      ? serviceType[i].providedBy?.reference?.split('/')[1]
                      : ''
                    if (serviceUnit && serviceUnit.length > 0) {
                      if (serviceUnit === currentUnit.id) {
                        const code =
                          getCodeOfSystemFromCodableConceptList(
                            serviceType[i].category ?? [],
                            'http://snomed.info/sct'
                          )?.code ?? ''

                        if (code === '224891009') {
                          const codeData =
                            getCodeOfSystemFromCodableConceptList(
                              serviceType[i].type ?? [],
                              'http://snomed.info/sct'
                            )?.code ?? ''
                          if (codeData === '33022008') {
                            serviceTypeCoding.push({
                              system: 'http://snomed.info/sct',
                              code: '33022008',
                              display: 'OPD',
                            })
                            opdService.push(serviceType[i])
                          }
                          if (codeData === '394656005') {
                            serviceTypeCoding.push({
                              system: 'http://snomed.info/sct',
                              code: '394656005',
                              display: 'IPD',
                            })

                            ipdService.push(serviceType[i])
                          }
                          if (codeData === '304903009') {
                            serviceTypeCoding.push({
                              system: 'http://snomed.info/sct',
                              code: '304903009',
                              display: 'Day Care',
                            })

                            dayCareService.push(serviceType[i])
                          }
                        }
                      }
                    }
                  }
              }
              for (let i = 0; i < serviceType.length; i++) {
                if (serviceType[i].category) {
                  const serviceUnit: string | undefined = serviceType[i]
                    .providedBy
                    ? serviceType[i].providedBy?.reference?.split('/')[1]
                    : ''
                  if (serviceUnit && serviceUnit.length > 0) {
                    if (serviceUnit === currentUnit.id) {
                      const code =
                        getCodeOfSystemFromCodableConceptList(
                          serviceType[i].category ?? [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs'
                        )?.code ?? ''

                      if (code === '306') {
                        const codeableConceptData = serviceType[i].type
                        const typeCoding: R4.ICoding[] = codeableConceptData
                          ? codeableConceptData[0].coding ?? []
                          : []

                        convertedService.push({
                          orgs: currentUnit,
                          serviceType: serviceTypeCoding,
                          systemOfMedicine: typeCoding,
                          type:
                            getCodeOfSystemFromCodableConceptList(
                              currentUnit.type ?? [],
                              'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                            )?.code ?? '',
                          systemOfMedicineService: serviceType[i],
                          opdService:
                            opdService.length > 0 ? opdService[0] : undefined,
                          ipdService:
                            ipdService.length > 0 ? ipdService[0] : undefined,
                          dayCareService:
                            dayCareService.length > 0
                              ? dayCareService[0]
                              : undefined,
                        })
                      }
                    }
                  }
                }
              }
            } else {
              convertedService.push({
                orgs: currentUnit,
                serviceType: [],
                systemOfMedicine: [],
                type:
                  getCodeOfSystemFromCodableConceptList(
                    currentUnit.type ?? [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                  )?.code ?? '',
              })
            }
          }
        }
      }
    }
  }

  return convertedService.sort((a, b) =>
    (a.orgs.name ?? '')
      .toLowerCase()
      .localeCompare((b.orgs.name ?? '').toLowerCase())
  )
}

export function getValueSetLabelWithCoding(coding: R4.ICoding[]): R4.ICoding[] {
  const changedValueSet: R4.ICoding[] = []

  for (let i = 0; i < coding.length; i++) {
    if (coding[i].code === '33022008') {
      changedValueSet.push({
        code: '33022008',
        system: 'http://snomed.info/sct',
        display: 'OPD',
      })
    } else if (coding[i].code === '394656005') {
      changedValueSet.push({
        code: '394656005',
        system: 'http://snomed.info/sct',
        display: 'IPD',
      })
    } else if (coding[i].code === '304903009') {
      changedValueSet.push({
        code: '304903009',
        system: 'http://snomed.info/sct',
        display: 'Day Care',
      })
    } else if (coding[i].code === 'C0242324') {
      changedValueSet.push({
        code: 'C0242324',
        system: 'http://terminology.hl7.org/CodeSystem/umls',
        display: 'Modern Medicine',
      })
    } else if (coding[i].code === '1259939000') {
      changedValueSet.push({
        code: '1259939000',
        system: 'http://snomed.info/sct',
        display: 'Ayurveda medicine',
      })
    } else if (coding[i].code === '439809005') {
      changedValueSet.push({
        code: '439809005',
        system: 'http://snomed.info/sct',
        display: 'Naturopathy',
      })
    }
  }

  return changedValueSet.sort((a, b) =>
    a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
  )
}

export function getHoverText(code: string): string {
  const changedValueSet: R4.ICoding[] = []

  switch (code) {
    case 'Essential':
      return 'Limited drug availability with basic lab facilities'
    case 'Optimum':
      return 'Adequate drug availability with advanced lab facilities'

    case 'Level 1':
      return 'Ayurveda PHC, Ayush HWC, OPD only clinic without Panchkarma, Ayurveda PHC with Panchkarma, OPD only clinic with Panchkarma facility.'
    case 'Level 2':
      return 'Ayurveda CHC, Ayurveda hospitals with Panchkarma and IPD facilities.'
    case 'Level 3':
      return 'Ayurveda District hospital, Ayurveda multi-speciality hospital, Ayurveda medical Colleges.'
    case 'Level 4':
      return ' Integrated AYUSH hospital, integrated hospitals, Govt. CHC hospital or District hospital with Allopathy and Ayush treatment facility under one roof.'

    default:
      return ''
  }
}

export function getDisplay(code: string): string {
  switch (code) {
    case 'Optimum':
      return 'Optimal'

    default:
      return code
  }
}

export function getOrgDetailsAlongWithServicesForOrg(
  responseSlots: R4.IBundle
): UnitServices[] {
  const convertedService: UnitServices[] = []
  const unit: any = {}
  const serviceType: R4.IHealthcareService[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Organization':
                  unit[value.resource.id] = value.resource as R4.IOrganization
                  break
                case 'HealthcareService':
                  serviceType.push(value.resource as R4.IHealthcareService)

                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in unit) {
          if (key) {
            const currentUnit: R4.IOrganization = unit[key] as R4.IOrganization

            const typeData =
              getCodeOfSystemFromCodableConceptList(
                currentUnit.type ?? [],
                'http://wellopathy.com/fhir/india/core/CodeSystem/org-type'
              )?.code ?? ''

            const ipdService: R4.IHealthcareService[] = []
            const opdService: R4.IHealthcareService[] = []
            const dayCareService: R4.IHealthcareService[] = []
            const serviceTypeCoding: R4.ICoding[] = []
            if (typeData === 'vendor-org') {
              for (let i = 0; i < serviceType.length; i++) {
                if (serviceType[i].active && serviceType[i].active === true)
                  if (serviceType[i].category) {
                    const serviceUnit: string | undefined = serviceType[i]
                      .providedBy
                      ? serviceType[i].providedBy?.reference?.split('/')[1]
                      : ''
                    if (serviceUnit && serviceUnit.length > 0) {
                      if (serviceUnit === currentUnit.id) {
                        const code =
                          getCodeOfSystemFromCodableConceptList(
                            serviceType[i].category ?? [],
                            'http://snomed.info/sct'
                          )?.code ?? ''

                        if (code === '224891009') {
                          const codeData =
                            getCodeOfSystemFromCodableConceptList(
                              serviceType[i].type ?? [],
                              'http://snomed.info/sct'
                            )?.code ?? ''
                          if (codeData === '33022008') {
                            serviceTypeCoding.push({
                              system: 'http://snomed.info/sct',
                              code: '33022008',
                              display: 'OPD',
                            })

                            opdService.push(serviceType[i])
                          }
                          if (codeData === '394656005') {
                            serviceTypeCoding.push({
                              system: 'http://snomed.info/sct',
                              code: '394656005',
                              display: 'IPD',
                            })

                            ipdService.push(serviceType[i])
                          }
                          if (codeData === '304903009') {
                            serviceTypeCoding.push({
                              system: 'http://snomed.info/sct',
                              code: '304903009',
                              display: 'Day Care',
                            })

                            dayCareService.push(serviceType[i])
                          }
                        }
                      }
                    }
                  }
              }
              for (let i = 0; i < serviceType.length; i++) {
                if (serviceType[i].category) {
                  const serviceUnit: string | undefined = serviceType[i]
                    .providedBy
                    ? serviceType[i].providedBy?.reference?.split('/')[1]
                    : ''
                  if (serviceUnit && serviceUnit.length > 0) {
                    if (serviceUnit === currentUnit.id) {
                      const code =
                        getCodeOfSystemFromCodableConceptList(
                          serviceType[i].category ?? [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs'
                        )?.code ?? ''

                      const codeableConceptData = serviceType[i].type
                      const typeCoding: R4.ICoding[] = codeableConceptData
                        ? codeableConceptData[0].coding ?? []
                        : []

                      convertedService.push({
                        orgs: currentUnit,
                        serviceType: serviceTypeCoding,
                        systemOfMedicine: undefined,
                        type:
                          getCodeOfSystemFromCodableConceptList(
                            currentUnit.type ?? [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                          )?.code ?? '',
                        systemOfMedicineService: serviceType[i],
                        opdService:
                          opdService.length > 0 ? opdService[0] : undefined,
                        ipdService:
                          ipdService.length > 0 ? ipdService[0] : undefined,
                        dayCareService:
                          dayCareService.length > 0
                            ? dayCareService[0]
                            : undefined,
                      })
                    }
                  }
                }
              }
            } else {
              convertedService.push({
                orgs: currentUnit,
                serviceType: [],
                systemOfMedicine: [],
                type:
                  getCodeOfSystemFromCodableConceptList(
                    currentUnit.type ?? [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                  )?.code ?? '',
              })
            }
          }
        }
      }
    }
  }

  return convertedService.sort((a, b) =>
    (a.orgs.name ?? '')
      .toLowerCase()
      .localeCompare((b.orgs.name ?? '').toLowerCase())
  )
}

export function getSystemOfMedicineArray(
  services: R4.IHealthcareService[],
  type: string
): R4.IHealthcareService | undefined {
  for (let i = 0; i < services.length; i++) {
    const code =
      getCodeOfSystemFromCodableConceptList(
        services[i].category ?? [],
        'http://snomed.info/sct'
      )?.code ?? ''

    if (code === '224891009') {
      const codeData =
        getCodeOfSystemFromCodableConceptList(
          services[i].type ?? [],
          'http://snomed.info/sct'
        )?.code ?? ''

      if (type === codeData) {
        return services[i]
      }
    }
  }
  return undefined
}

export function getMedicineArray(
  services: R4.IHealthcareService[]
): R4.ICoding[] {
  for (let i = 0; i < services.length; i++) {
    const code =
      getCodeOfSystemFromCodableConceptList(
        services[i].category ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs'
      )?.code ?? ''
    if (code === '306') {
      const codeableConceptData = services[i].type
      const typeCoding: R4.ICoding[] = codeableConceptData
        ? codeableConceptData[0].coding ?? []
        : []
      return typeCoding
    }
  }
  return []
}
