import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  addAndGetEncounterIdOfAppointment,
  getCommunicationRequest,
} from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  getCarePlanOfPatientWithCurrentPractitioner,
  getCommunicationResourceForFollowUp,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import {
  getCommunicationResourceCheck,
  getCommunicationResourceCheckIPD,
} from 'utils/instructionUtils/instructionUtils'
import { sleep } from 'utils/dateUtil'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { InstructionAdditionStatus } from './instructionAdditionState'

const initialState: InstructionAdditionStatus = {
  addingInstruction: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const instructionAdditionSlice = createSlice({
  name: 'instructionAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<InstructionAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },

    resetAddVitalsDetails(
      state,
      action: PayloadAction<InstructionAdditionStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const resetAddSCPG = () => (dispatch: AppDispatch) => {
  dispatch(instructionAdditionSlice.actions.resetAddVitalsDetails(initialState))
}

export const addInstructions =
  (
    patient: R4.IPatient,
    communications: R4.ICommunicationRequest[],
    encounterId: string,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: InstructionAdditionStatus = {
      ...initialState,
    }
    state.addingInstruction = true
    dispatch(instructionAdditionSlice.actions.updatedStatus(state))
    try {
      const hasPlanDef = true
      if (hasPlanDef && encounterId) {
        const carePlanOfPatient: R4.ICarePlan | undefined =
          carePlanId !== undefined
            ? await getCarePlanByIdCurrentState(carePlanId)
            : await getCarePlanOfPatientWithCurrentPractitioner(patient.id!)
        if (carePlanOfPatient) {
          let requestCommunication: R4.ICommunicationRequest | undefined
          const visitContext = sessionStorage.getItem(CURRENT_VISIT_REFERENCE)
          if (visitContext) {
            if (visitContext.toLowerCase().includes('appointment')) {
              const appointmentId = visitContext.split('/')[1]
              requestCommunication = await getCommunicationResourceCheck(
                carePlanOfPatient!.id!,
                appointmentId
              )
            }
            if (visitContext.toLowerCase().includes('servicerequest')) {
              const appointmentId = visitContext.split('/')[1]
              requestCommunication = await getCommunicationResourceCheckIPD(
                carePlanOfPatient!.id!,
                appointmentId
              )
            }
          }

          if (requestCommunication) {
            const oldCommunicationRequest: R4.ICommunicationRequest = {
              ...requestCommunication,
            }

            oldCommunicationRequest.payload = communications[0].payload
            const fhirClient: FHIRApiClient = new FHIRApiClient()

            const response: any = await fhirClient.doUpdateFHIRResourceRequest(
              `/CommunicationRequest/${oldCommunicationRequest.id}` ?? '',
              oldCommunicationRequest,
              oldCommunicationRequest.meta?.versionId ?? ''
            )
            const res: R4.ICommunicationRequest =
              response as R4.ICommunicationRequest

            state.addingInstruction = false
            state.additionSuccessful = true
            state.errorWhileAdding = false
            state.successMessage =
              'Instructions have been updated in care portal.'
            state.errorReason = undefined
            dispatch(
              showSuccessAlert('Instructions have been updated in care portal.')
            )
            dispatch(instructionAdditionSlice.actions.updatedStatus(state))
          } else {
            const responses: AxiosResponse[] | undefined =
              await addInstructionsApiCalls(
                communications,
                patient,

                carePlanOfPatient!.id!,
                encounterId!
              )
            if (responses) {
              let isSucess: boolean = true
              responses.forEach((e) => {
                if (e.status !== 200 && e.status !== 201) {
                  isSucess = false
                }
              })
              await sleep(5000)
              if (isSucess) {
                state.addingInstruction = false
                state.additionSuccessful = true
                state.errorWhileAdding = false
                state.successMessage =
                  'Instructions have been updated in care portal.'
                state.errorReason = undefined
                dispatch(
                  showSuccessAlert(
                    'Instructions have been updated in care portal.'
                  )
                )

                // setTimeout(() => {
                //   dispatch(getInstructionsOfAppointment(appointment))
                // }, 800)
                dispatch(instructionAdditionSlice.actions.updatedStatus(state))
              } else {
                let isPartial: boolean = false
                responses.forEach((e) => {
                  if (e.status === 200 || e.status === 201) {
                    isPartial = true
                  }
                })

                if (isPartial) {
                  state.addingInstruction = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason =
                    'Added few of the Instructions. Check Plan sections'
                  dispatch(
                    showSuccessAlert(
                      'Added few of the Instructions. Check Plan sections'
                    )
                  )
                  dispatch(
                    instructionAdditionSlice.actions.updatedStatus(state)
                  )
                  //   setTimeout(() => {
                  //     dispatch(getInstructionsOfAppointment(appointment))
                  //   }, 800)
                } else {
                  state.addingInstruction = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason =
                    'Error while adding Instructions. Try later'
                  dispatch(
                    instructionAdditionSlice.actions.updatedStatus(state)
                  )
                }
              }
            }
          }
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'User is not subscribed to Care Plan'
          dispatch(instructionAdditionSlice.actions.updatedStatus(state))
        }
      } else {
        const bundle: R4.IBundle = {
          resourceType: 'Bundle',
          type: R4.BundleTypeKind._transaction,
          entry: communications.map(
            (e) =>
              ({
                request: {
                  method: R4.Bundle_RequestMethodKind._post,
                  url: 'CommunicationRequest',
                },
                resource: getCommunicationRequest(
                  e,
                  encounterId ?? '',
                  patient.id ?? ''
                ),
              } as R4.IBundle_Entry)
          ),
        }

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundle)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.addingInstruction = false
          state.additionSuccessful = true
          state.errorWhileAdding = false
          state.successMessage =
            'Instructions have been updated in care portal.'
          state.errorReason = undefined
          dispatch(
            showSuccessAlert('Instructions have been updated in care portal.')
          )
          dispatch(instructionAdditionSlice.actions.updatedStatus(state))
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'Error while adding '
          dispatch(instructionAdditionSlice.actions.updatedStatus(state))
        }
      }
    } catch (error) {
      const resState: InstructionAdditionStatus = {
        addingInstruction: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(instructionAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const addInstructionsApiCalls = async (
  communicationRequests: R4.ICommunicationRequest[],
  patient: R4.IPatient,

  careplanId: string,
  encounterId: string
): Promise<AxiosResponse[] | undefined> => {
  try {
    const body: any = {
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId,
    }

    const enRolClient: EnrolCient = new EnrolCient()

    const instance = axios.create({
      baseURL: process.env.REACT_APP_CC_WORKFLOW_URL,
      timeout: 15000,
    })
    const responses: any[] = []
    communicationRequests.forEach(async (e) => {
      body.communicationRequest = e

      const resp: AxiosResponse =
        await enRolClient.doCreateEnrolmentFlowRequest('instruction/', body)

      responses.push(resp)
    })

    return responses
  } catch (error) {
    logger.info('_______error________________')
    logger.info(error)
  }
  return undefined
}

export default instructionAdditionSlice.reducer
