import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import { MoreVert, Settings } from '@material-ui/icons'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  getRolesAsStringFromPractRole,
  getUserCurrentRole,
  getUserDetails,
} from 'services/userDetailsService'
import { getLogOutUrl } from 'utils/authHelpers'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'
/* import md from '../../../../assets/images/icon_doctor-female.svg' */

export interface IAccountDropDownProps {}

export const LogOutButtonForMobile: React.FC<IAccountDropDownProps> =
  ({}: IAccountDropDownProps) => {
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return
      }
      setOpen(false)
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        setOpen(false)
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus()
      }

      prevOpen.current = open
    }, [open])

    const logoutUser = () => {
      sessionStorage.clear()
      localStorage.clear()
      window.location.href = getLogOutUrl()
    }

    const [showDeleteConfirmation, setShowDeleteConfirmation] =
      useState<ConfirmationDialogProps>({
        open: false,
        id: '1',
      })

    return (
      <Box display='flex' flexDirection='row'>
        <Box
          display='flex'
          flexDirection='row'
          alignContent='center'
          justifyContent='center'
        >
          <IconButton
            color='inherit'
            size='small'
            ref={anchorRef}
            aria-label='close'
            onClick={handleToggle}
          >
            <Settings
              fontSize='small'
              style={{
                padding: '0px',
                margin: '0px',
              }}
            />
          </IconButton>
        </Box>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 1,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                    variant='menu'
                    style={{
                      zIndex: 1,
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e)
                        setShowDeleteConfirmation({
                          open: true,

                          onContinueActionClicked: () => {
                            setShowDeleteConfirmation({ open: false })
                            logoutUser()
                          },
                          onCancelActionClicked: () => {
                            setShowDeleteConfirmation({ open: false })
                          },
                          title: 'Log Out',
                          displayText: 'Are you sure you want to Log Out?',
                          continueActionButtonText: 'Yes',
                          cancelActionButtonText: 'No',
                        })
                      }}
                      style={{
                        zIndex: 1,
                      }}
                    >
                      <Typography variant='body1' color='primary'>
                        Log Out
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        {showDeleteConfirmation.open && (
          <ConfirmationDialog {...showDeleteConfirmation} id='1' />
        )}
      </Box>
    )
  }
