import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { id } from 'fp-ts/lib/Refinement'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { FollowupAppointmentManagementStatus } from 'redux/appointments/followupManager/followupAppointmentManagerStatus'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  availablePaymentTypes,
  availablePaymentTypesHinNotOnline,
  availablePaymentTypesRemovedOnline,
  onlineAvailablePaymentTypes,
  paymentTypes,
} from 'utils/constants'
import {
  getExtensionValueOfNumber,
  getNameFromHumanName,
  getProfilePicDoctor,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import { getAppointmentListForFollowup } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getDateDiff,
  getSlotAmountForAppointment,
} from 'utils/fhirResoureHelpers/fhirSlotHelper'
import {
  validateLastName,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateName,
} from 'utils/formValidators'
import {
  Controls,
  WelloFormItemLabel,
  WelloTextField,
} from 'wello-web-components'
import { WelloPhoneNumberField } from '../LeftMenu/WelloPhoneNumberField'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'
import { ChoosePatient } from '../patients/choosePatientDialog'
import { PatientTile } from '../patients/pateintTile'
import { PatientSearchByText } from '../patients/patientSearchByText'
import { PatientSearchByTextForQuickEntry } from '../patients/patientSearchForQuickDataEntry'

interface Props {
  selectedSlot?: FhirSlotDetail
  patient?: R4.IPatient
  selectedServiceType: string
  onPatientSelected?: (selectedSlot?: R4.IPatient) => void
  onDiscountTypeSelected?: (
    existingStatus: FollowupAppointmentManagementStatus,
    paymentType: R4.ICoding
  ) => void

  onDiscountChanged?: (
    existingStatus: FollowupAppointmentManagementStatus,
    amountVal: number
  ) => void

  onPaymentSelected: (paymentType: string) => void
  onClickOnModfifyPatient: (
    modified: boolean,
    selectedSlot?: R4.IPatient
  ) => void
  onClickFollowupType?: (
    existingStatus: FollowupAppointmentManagementStatus,
    followupType: string
  ) => void
  onClickModifySlot: (
    selectedSlot: FhirSlotDetail,
    patient?: R4.IPatient
  ) => void
  fhirAppointmentDetail?: R4.IAppointment
  followUp?: boolean
  paymentType?: R4.ICoding
  percentData?: number
  amountData?: number
  followUpType?: string
  preDefinedAllowed?: boolean
}

export const PatientSelectorFollowup: React.FC<Props> = ({
  onPatientSelected,
  onDiscountTypeSelected,
  onDiscountChanged,
  onClickFollowupType,
  patient,
  selectedServiceType,
  selectedSlot,
  onPaymentSelected,
  onClickOnModfifyPatient,
  onClickModifySlot,
  fhirAppointmentDetail,
  followUp,
  paymentType,
  percentData,
  amountData,
  followUpType,
  preDefinedAllowed,
}: Props) => {
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.followupAppointmentManagerSlice
  )

  const [percentageData, setPercentageData] = React.useState<number>()
  const [amount, setAmount] = React.useState<number>()
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)
  const [followType, setFollowupType] = useState<string>(followUpType || 'paid')
  const [middleName, setMiddleName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [refferedId, setRefferedId] = useState<string>()
  const [isPrimary, setIsPrimary] = useState<boolean>()
  const [relation, setRelation] = useState<R4.ICoding>()
  const [displayAdvancedFilterOption, setDisplayAdvancedFilterOption] =
    useState<boolean>(true)
  const [selectedPatientData, setSelectedPatientData] = useState<R4.IPatient>()
  const [selectedSlotDetail, setSelectedSlotDetail] = useState<FhirSlotDetail>()
  const language = sessionStorage.getItem('lang')
  const [isSelectedQuick, setIsSelectedQuick] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  console.log(paymentType)

  const handleChange = (event: any) => {
    setIsSelectedQuick(event.target.checked)
  }
  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [selectedPaymentType, setselectedPaymentType] = useState<
    R4.ICoding | undefined
  >(paymentType ?? undefined)

  const [dialog, setDialog] = useState<boolean>(false)
  const [appointment, setAppointment] = useState<R4.IAppointment>()
  const [loadingCount, setLoadingCount] = useState<boolean>(false)

  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )

  useEffect(() => {
    if (
      validateMobileNumberForAdd(phone.replaceAll('+91', ''), true).length === 0
    ) {
      dispatch(searchPatients(phone.replaceAll('+91', ''), ''))
    }
  }, [phone])

  useEffect(() => {
    if (isSelectedQuick) {
      if (
        validateMobileNumberForAdd(phone.replaceAll('+91', ''), true).length ===
          0 &&
        dialog === false
      ) {
        if (
          patientsSearchState.resultsAvailable &&
          patientsSearchState.groupDataList
        ) {
          setDialog(true)
        }
      } else {
        dispatch(resetPatientSearchPatient())
      }
    }
  }, [patientsSearchState.patientList, patientsSearchState.resultsAvailable])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box width='100%'>
        <Grid container>
          <Grid item xs={7}>
            <Box marginX={2} padding={1}>
              <Grid container justify='center' direction='column'>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  {selectedSlot && (
                    <Box paddingTop={1.5}>
                      <Typography variant='subtitle2'>Time Slot</Typography>
                    </Box>
                  )}
                  {selectedSlot && !appointmentManagerSlice.isWalkIn && (
                    <Button
                      id='but_lab_ord_cre_modify_pat'
                      variant='text'
                      color='primary'
                      onClick={(e) => {
                        setDisplayAdvancedFilterOption(false)
                        setSelectedSlotDetail(undefined)
                        onClickModifySlot(selectedSlot, selectedPatientData)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
                {selectedSlot && (
                  <Box width='100%' border={1} borderColor='#A5A5A5'>
                    {displayAdvancedFilterOption && (
                      <Paper elevation={0}>
                        <List style={{ padding: 0 }}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                alt={getNameFromHumanName(
                                  selectedSlot.practitioner?.name ?? []
                                )}
                                src={getProfilePicDoctor(
                                  selectedSlot.practitioner
                                )}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              color='primary'
                              primary={
                                <>
                                  <Typography
                                    variant='subtitle1'
                                    color='primary'
                                  >
                                    {getNameFromHumanName(
                                      selectedSlot.practitioner?.name ?? []
                                    )}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Box width='65%'>
                                    <Typography
                                      component='span'
                                      variant='subtitle2'
                                      color='textPrimary'
                                      style={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-line',
                                      }}
                                    >
                                      {getSpecializationsList(
                                        selectedSlot.practitionerRole
                                          ?.specialty ?? []
                                      )}
                                    </Typography>
                                  </Box>
                                </>
                              }
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={
                                  <>
                                    {/* {fhirAppointmentDetail &&
                                      followUp &&
                                      selectedSlot.chargeItemDefiniton &&
                                      getExtensionValueOfNumber(
                                        selectedSlot.chargeItemDefiniton
                                          .extension ?? [],
                                        'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                      ) > 0 && (
                                        <Box>
                                          {getDateDiff(
                                            fhirAppointmentDetail.appointment
                                              .start,
                                            selectedSlot.slot.start
                                          ) <=
                                            getExtensionValueOfNumber(
                                              selectedSlot.chargeItemDefiniton
                                                .extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                            ) && (
                                            <Typography
                                              variant='subtitle1'
                                              color='primary'
                                              style={{ fontWeight: 'bolder' }}
                                              align='right'
                                            >
                                              ₹ 0
                                            </Typography>
                                          )}

                                          {getDateDiff(
                                            fhirAppointmentDetail.appointment
                                              .start,
                                            selectedSlot.slot.start
                                          ) >
                                            getExtensionValueOfNumber(
                                              selectedSlot.chargeItemDefiniton
                                                .extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                            ) && (
                                            <Typography
                                              variant='subtitle1'
                                              color='primary'
                                              style={{ fontWeight: 'bolder' }}
                                              align='right'
                                            >
                                              ₹{' '}
                                              {getSlotAmountForAppointment(
                                                selectedSlot.chargeItemDefiniton
                                              )}
                                            </Typography>
                                          )}
                                        </Box>
                                      )} */}

                                    {/* {followUp === undefined && (
                                      <Box>
                                        <Typography
                                          variant='subtitle1'
                                          color='primary'
                                          style={{ fontWeight: 'bolder' }}
                                          align='right'
                                        >
                                          ₹{' '}
                                          {getSlotAmountForAppointment(
                                            selectedSlot.chargeItemDefiniton
                                          )}
                                        </Typography>
                                      </Box>
                                    )} */}
                                  </>
                                }
                                secondary={
                                  <>
                                    {appointmentManagerSlice.isWalkIn && (
                                      <>
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Walk-In
                                        </Typography>
                                      </>
                                    )}
                                    {!appointmentManagerSlice.isWalkIn && (
                                      <>
                                        <Typography
                                          variant='subtitle2'
                                          align='right'
                                        >
                                          {moment(selectedSlot.slot.end).format(
                                            'Do MMM YYYY'
                                          )}
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          align='right'
                                        >
                                          {`${moment(
                                            selectedSlot.slot.start
                                          ).format('hh:mm a')} - ${moment(
                                            selectedSlot.slot.end
                                          ).format('hh:mm a')}`}
                                        </Typography>
                                        {selectedServiceType === '556' && (
                                          <Typography
                                            variant='subtitle2'
                                            align='right'
                                          >
                                            {t('labelCommon:In-Person')}
                                          </Typography>
                                        )}
                                        {/* {selectedServiceType === '538' && (
                                          <Typography
                                            variant='subtitle2'
                                            align='right'
                                          >
                                            {t('labelCommon:Phone')}
                                          </Typography>
                                        )} */}
                                        {selectedServiceType ===
                                          'video-counselling' && (
                                          <Typography
                                            variant='subtitle2'
                                            align='right'
                                          >
                                            {t('labelCommon:Video')}
                                          </Typography>
                                        )}
                                      </>
                                    )}
                                  </>
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Paper>
                    )}
                  </Box>
                )}

                {patient && selectedSlot && (
                  <Grid item xs={12}>
                    <Box flexDirection='column'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                      >
                        <WelloFormItemLabel
                          title={t('labelCommon:patient_label')}
                        />

                        {isPatientEditMode &&
                          patient &&
                          preDefinedAllowed === undefined && (
                            <Button
                              id='but_lab_ord_cre_modify_pat'
                              variant='text'
                              color='primary'
                              onClick={(e) => {
                                setIsPatientEditMode(true)
                                setSelectedPatientData(undefined)
                                onClickOnModfifyPatient(
                                  false,
                                  selectedPatientData
                                )
                              }}
                            >
                              Edit
                            </Button>
                          )}
                      </Box>
                      {isPatientEditMode && patient && (
                        <Box width='100%' display='flex' flexDirection='column'>
                          <Paper elevation={0}>
                            <Box width='100%'>
                              <PatientTile patient={patient} />
                            </Box>
                          </Paper>
                          {appointmentManagerSlice.followupAllowed && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              paddingTop={4}
                            >
                              <Box flex flexDirection='row' width='100%'>
                                <Divider />
                              </Box>

                              <Box width='100%' paddingTop={1}>
                                <Typography
                                  variant='subtitle1'
                                  color='textPrimary'
                                >
                                  Follow up Type
                                </Typography>
                              </Box>

                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                                // px={0.5}
                              >
                                <Box
                                  width='50%'
                                  paddingTop={0.5}
                                  display='flex'
                                  flexDirection='row'
                                >
                                  <Box
                                    width='100%'
                                    display='flex'
                                    flexDirection='row'
                                  >
                                    <Box paddingTop={1}>
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                      >
                                        Paid Follow up
                                      </Typography>
                                    </Box>
                                    <Box width='50%'>
                                      <RadioGroup
                                        id={`${id}paid`}
                                        row
                                        aria-label='graphType'
                                        value={followType}
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          // attendantRow.addressProofTypeBoth = ''
                                          // attendantRow.addressProofTypeBothName = ''
                                          // attendantRow.addressProofTypeBothMimeType = ''
                                          setFollowupType(
                                            (event.target as HTMLInputElement)
                                              .value
                                          )
                                          if (onClickFollowupType) {
                                            onClickFollowupType(
                                              appointmentManagerSlice,
                                              'paid'
                                            )
                                          }
                                        }}
                                      >
                                        <Box
                                          px={1.5}
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                        >
                                          <FormControlLabel
                                            id={`${id}paid_label`}
                                            value='paid'
                                            control={<Radio />}
                                            label=''
                                          />
                                        </Box>
                                      </RadioGroup>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  width='50%'
                                  paddingTop={0.5}
                                  display='flex'
                                  flexDirection='row'
                                  justifyContent='flex-end'
                                >
                                  <Box
                                    width='100%'
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='flex-end'
                                  >
                                    <Box paddingTop={1}>
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                      >
                                        Free Follow up
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <RadioGroup
                                        id={`${id}free_grp`}
                                        row
                                        aria-label='graphType'
                                        value={followType}
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          // attendantRow.addressProofTypeBoth = ''
                                          // attendantRow.addressProofTypeBothName = ''
                                          // attendantRow.addressProofTypeBothMimeType = ''
                                          setFollowupType(
                                            (event.target as HTMLInputElement)
                                              .value
                                          )
                                          if (onClickFollowupType) {
                                            onClickFollowupType(
                                              appointmentManagerSlice,
                                              'free'
                                            )
                                          }
                                        }}
                                      >
                                        <Box
                                          paddingLeft={1.5}
                                          display='flex'
                                          flexDirection='row'
                                          justifyContent='flex-end'
                                        >
                                          <FormControlLabel
                                            id={`${id}free_label`}
                                            value='free'
                                            control={<Radio size='medium' />}
                                            label=''
                                          />
                                        </Box>
                                      </RadioGroup>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}
                {isPatientEditMode && !patient && (
                  <Grid item>
                    <Box>
                      <WelloFormItemLabel
                        title={t('labelCommon:select_patient')}
                      />
                      <PatientSearchByText
                        id={300}
                        onNewPatientButtonClicked={() => {}}
                        onPatientSelected={(selectedPatient) => {
                          if (onPatientSelected) {
                            // setIsPatientEditMode(false)
                            setSelectedPatientData(selectedPatient)
                            onPatientSelected(selectedPatient)
                          }
                        }}
                        allowNewPatient={false}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid
                  item
                  style={{
                    height: 0,
                    width: 0,
                    padding: -5,
                    margin: -5,
                  }}
                />
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Box width='100%' display='flex' flexDirection='column'>
              {fhirAppointmentDetail && selectedSlot && (
                <Box
                  width='100%'
                  paddingTop={1}
                  paddingRight={1}
                  paddingLeft={1}
                  display='flex'
                  flexDirection='row'
                >
                  {(paymentType ||
                    followType === 'paid' ||
                    getDateDiff(
                      fhirAppointmentDetail.start,
                      selectedSlot.slot.start
                    ) >
                      getExtensionValueOfNumber(
                        selectedSlot.chargeItemDefiniton!.extension ?? [],
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                      )) && (
                    <Box
                      width={paymentType === undefined ? '100%' : '50%'}
                      paddingRight={1}
                    >
                      {paymentType && (
                        <WelloSelectFHIRCoding
                          title='Discount'
                          id='payment_type'
                          availableCodings={paymentTypes}
                          onChanged={(type) => {
                            if (onDiscountTypeSelected)
                              onDiscountTypeSelected(
                                appointmentManagerSlice,
                                type
                              )
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                          preSelectedCoding={paymentType ?? undefined}
                          role='payment_type'
                        />
                      )}
                      {paymentType === undefined && (
                        <WelloSelectFHIRCoding
                          title='Discount'
                          id='payment_type'
                          availableCodings={paymentTypes}
                          onChanged={(type) => {
                            if (onDiscountTypeSelected)
                              onDiscountTypeSelected(
                                appointmentManagerSlice,
                                type
                              )
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                          role='payment_type'
                        />
                      )}
                    </Box>
                  )}
                  {paymentType && (
                    <Box width='50%'>
                      {paymentType &&
                        paymentType.code &&
                        paymentType.code === 'percent' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <WelloFormItemLabel title='Discount Percentage' />
                            <TextField
                              size='small'
                              variant='outlined'
                              type='number'
                              placeholder=''
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  step: 0.25,
                                },
                              }}
                              value={percentData}
                              onChange={(e) => {
                                const newValue = Number(e.target.value)

                                if (newValue >= 0 && newValue <= 100) {
                                  if (onDiscountChanged)
                                    onDiscountChanged(
                                      appointmentManagerSlice,
                                      Number(e.target.value)
                                    )
                                  // setPercentageData(Number(e.target.value))
                                } else if (/^\d+$/.test(e.target.value)) {
                                  dispatch(
                                    showErrorAlert(
                                      `Percentage cannot be more than 100`
                                    )
                                  )
                                }
                              }}
                            />
                          </Box>
                        )}

                      {paymentType &&
                        paymentType.code &&
                        paymentType.code === 'amount' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <WelloFormItemLabel title='Discount Amount' />
                            <TextField
                              size='small'
                              variant='outlined'
                              type='number'
                              placeholder=''
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  step: 0.25,
                                },
                              }}
                              value={amountData}
                              onChange={(e) => {
                                const newValue = Number(e.target.value)
                                if (
                                  newValue >= 0 &&
                                  newValue <=
                                    getSlotAmountForAppointment(
                                      selectedSlot.chargeItemDefiniton
                                    )
                                ) {
                                  if (onDiscountChanged)
                                    onDiscountChanged(
                                      appointmentManagerSlice,
                                      newValue
                                    )
                                  setAmount(Number(e.target.value))
                                } else if (/^\d+$/.test(e.target.value)) {
                                  dispatch(
                                    showErrorAlert(
                                      `Discount Amount cannot be more than ${getSlotAmountForAppointment(
                                        selectedSlot.chargeItemDefiniton
                                      )}`
                                    )
                                  )
                                }
                              }}
                            />
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              )}

              <Box
                display='flex'
                flexWrap='wrap'
                alignContent='flex-start'
                justifyContent='flex-start'
                p={1}
                height='100%'
              >
                <Box
                  justifyContent='flex-start'
                  width='100%'
                  height='100%'
                  flexDirection='column'
                  style={{ borderRadius: 16 }}
                >
                  {selectedSlot && (
                    <Box
                      paddingTop={preDefinedAllowed === undefined ? 1 : 0.2}
                      paddingBottom={1.2}
                    >
                      <Typography variant='subtitle2'>Price Summary</Typography>
                    </Box>
                  )}
                  <Paper style={{ borderRadius: 4 }} elevation={0}>
                    <Grid container direction='column' justify='center'>
                      {selectedSlot && (
                        <Grid item>
                          <Box
                            display='flex'
                            paddingX={1}
                            bgcolor='background.paper'
                            py={0.4}
                            flexDirection='column'
                            width='100%'
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box p={1} flexGrow={1}>
                                <Typography variant='subtitle1'>
                                  {t('labelCommon:visit_charge')}
                                </Typography>
                              </Box>
                              <Box p={1}>
                                {fhirAppointmentDetail &&
                                  followType === 'free' &&
                                  followUp &&
                                  selectedSlot.chargeItemDefiniton &&
                                  getExtensionValueOfNumber(
                                    selectedSlot.chargeItemDefiniton
                                      .extension ?? [],
                                    'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                  ) > 0 && (
                                    <Box>
                                      {getDateDiff(
                                        fhirAppointmentDetail.start,
                                        selectedSlot.slot.start
                                      ) <=
                                        getExtensionValueOfNumber(
                                          selectedSlot.chargeItemDefiniton
                                            .extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                        ) && (
                                        <Typography
                                          variant='subtitle1'
                                          style={{
                                            color: '#818181',
                                          }}
                                        >
                                          0 ₹
                                        </Typography>
                                      )}

                                      {getDateDiff(
                                        fhirAppointmentDetail.start,
                                        selectedSlot.slot.start
                                      ) >
                                        getExtensionValueOfNumber(
                                          selectedSlot.chargeItemDefiniton
                                            .extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                        ) && (
                                        <Typography
                                          variant='subtitle1'
                                          style={{
                                            color: '#818181',
                                          }}
                                        >
                                          {getSlotAmountForAppointment(
                                            selectedSlot.chargeItemDefiniton
                                          )}{' '}
                                          ₹{' '}
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                {followType === 'paid' && (
                                  <Box>
                                    <Typography
                                      variant='subtitle1'
                                      style={{ color: '#818181' }}
                                    >
                                      {getSlotAmountForAppointment(
                                        selectedSlot.chargeItemDefiniton
                                      )}{' '}
                                      ₹{' '}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box p={1} flexGrow={1}>
                                <Typography variant='subtitle1'>
                                  Discount
                                </Typography>
                              </Box>
                              <Box p={1}>
                                {fhirAppointmentDetail &&
                                  followType === 'free' &&
                                  followUp &&
                                  selectedSlot.chargeItemDefiniton &&
                                  getExtensionValueOfNumber(
                                    selectedSlot.chargeItemDefiniton
                                      .extension ?? [],
                                    'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                  ) > 0 && (
                                    <Box>
                                      {getDateDiff(
                                        fhirAppointmentDetail.start,
                                        selectedSlot.slot.start
                                      ) <=
                                        getExtensionValueOfNumber(
                                          selectedSlot.chargeItemDefiniton
                                            .extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                        ) && (
                                        <Typography
                                          variant='subtitle1'
                                          style={{
                                            color: '#818181',
                                          }}
                                        >
                                          0 ₹
                                        </Typography>
                                      )}

                                      {getDateDiff(
                                        fhirAppointmentDetail.start,
                                        selectedSlot.slot.start
                                      ) >
                                        getExtensionValueOfNumber(
                                          selectedSlot.chargeItemDefiniton
                                            .extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                        ) && (
                                        <Typography
                                          variant='subtitle1'
                                          style={{ color: '#818181' }}
                                        >
                                          {amountData ||
                                            (percentData
                                              ? Number(
                                                  (getSlotAmountForAppointment(
                                                    selectedSlot.chargeItemDefiniton
                                                  ) *
                                                    percentData) /
                                                    100
                                                )
                                              : 0)}{' '}
                                          ₹{' '}
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                {followType === 'paid' && (
                                  <Box>
                                    <Typography
                                      variant='subtitle1'
                                      style={{
                                        color: '#818181',
                                      }}
                                    >
                                      {amountData ||
                                        (percentData
                                          ? Number(
                                              (getSlotAmountForAppointment(
                                                selectedSlot.chargeItemDefiniton
                                              ) *
                                                percentData) /
                                                100
                                            )
                                          : 0)}{' '}
                                      ₹
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {selectedSlot && (
                        <Box flex flexDirection='row'>
                          <Divider />
                        </Box>
                      )}
                      {selectedSlot && (
                        <Grid item>
                          <Box display='flex' paddingX={1}>
                            <Box p={1} flexGrow={1}>
                              <Typography variant='subtitle1' color='primary'>
                                {t('labelCommon:total')}
                              </Typography>
                            </Box>
                            <Box p={1}>
                              {fhirAppointmentDetail &&
                                followType === 'free' &&
                                followUp &&
                                selectedSlot.chargeItemDefiniton &&
                                getExtensionValueOfNumber(
                                  selectedSlot.chargeItemDefiniton.extension ??
                                    [],
                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                ) > 0 && (
                                  <Box>
                                    {getDateDiff(
                                      fhirAppointmentDetail.start,
                                      selectedSlot.slot.start
                                    ) <=
                                      getExtensionValueOfNumber(
                                        selectedSlot.chargeItemDefiniton
                                          .extension ?? [],
                                        'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                      ) && (
                                      <Typography
                                        variant='subtitle1'
                                        style={{
                                          color: '#818181',
                                        }}
                                      >
                                        0 ₹
                                      </Typography>
                                    )}

                                    {getDateDiff(
                                      fhirAppointmentDetail.start,
                                      selectedSlot.slot.start
                                    ) >
                                      getExtensionValueOfNumber(
                                        selectedSlot.chargeItemDefiniton
                                          .extension ?? [],
                                        'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                      ) && (
                                      <Typography
                                        variant='subtitle1'
                                        style={{ color: '#818181' }}
                                      >
                                        {amountData
                                          ? getSlotAmountForAppointment(
                                              selectedSlot.chargeItemDefiniton
                                            ) - amountData
                                          : percentData
                                          ? Number(
                                              (getSlotAmountForAppointment(
                                                selectedSlot.chargeItemDefiniton
                                              ) *
                                                percentData) /
                                                100
                                            ) -
                                            Number(
                                              (getSlotAmountForAppointment(
                                                selectedSlot.chargeItemDefiniton
                                              ) *
                                                percentData) /
                                                100
                                            )
                                          : getSlotAmountForAppointment(
                                              selectedSlot.chargeItemDefiniton
                                            )}{' '}
                                        ₹{' '}
                                      </Typography>
                                    )}
                                  </Box>
                                )}
                              {followType === 'paid' && (
                                <Box>
                                  {paymentType && (
                                    <Typography
                                      variant='subtitle1'
                                      style={{ color: '#818181' }}
                                    >
                                      {amountData
                                        ? getSlotAmountForAppointment(
                                            selectedSlot.chargeItemDefiniton
                                          ) - amountData
                                        : percentData
                                        ? getSlotAmountForAppointment(
                                            selectedSlot.chargeItemDefiniton
                                          ) -
                                          Number(
                                            (getSlotAmountForAppointment(
                                              selectedSlot.chargeItemDefiniton
                                            ) *
                                              percentData) /
                                              100
                                          )
                                        : getSlotAmountForAppointment(
                                            selectedSlot.chargeItemDefiniton
                                          )}{' '}
                                      ₹{' '}
                                    </Typography>
                                  )}
                                  {paymentType === undefined && (
                                    <Typography
                                      variant='subtitle1'
                                      style={{ color: '#818181' }}
                                    >
                                      {getSlotAmountForAppointment(
                                        selectedSlot.chargeItemDefiniton
                                      )}{' '}
                                      ₹
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Box>
              </Box>
              <Box paddingLeft={1} paddingRight={1} paddingBottom={1}>
                <Controls.WelloSelect
                  label={t('labelCommon:payment_type')}
                  availableOptions={
                    selectedServiceType === '556' ||
                    selectedServiceType.length === 0
                      ? language === null || language === 'en'
                        ? availablePaymentTypesRemovedOnline
                        : availablePaymentTypesRemovedOnline
                      : language === null
                      ? availablePaymentTypesRemovedOnline
                      : availablePaymentTypesRemovedOnline
                  }
                  required={true}
                  type='Payment Type'
                  displayField='title'
                  valueField='id'
                  preSelectedOption={
                    selectedServiceType === '556' ||
                    selectedServiceType.length === 0
                      ? availablePaymentTypesRemovedOnline[0]
                      : availablePaymentTypesRemovedOnline[0]
                  }
                  onSelectionChanged={(value: any) => {
                    onPaymentSelected(value.id)
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  )
}
