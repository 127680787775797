import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import {
  GroupedLabResults,
  GroupedLabResultsByName,
} from 'models/groupedLabResults'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForAddLab,
  getSortedObservationForAddLabForPanel,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getLabTestData,
  getLatestObservations,
  getObserVationFfromEncounter,
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromEncounterForDateWiseLAbResult,
  getObserVationFfromEncounterForDateWisePanel1,
  getObserVationFfromEncounterForDateWisePanel2,
  getObserVationFfromEncounterForDateWisePanel3,
  getObserVationFfromEncounterForDateWisePanel4,
  getObserVationFfromEncounterForLabTest,
  getObserVationFfromObserVationDateWiseForDexaOPD,
  getObserVationFfromObserVationDateWiseForHomOPD,
  getObserVationFfromObserVationDateWiseForLabPanel2OPD,
  getObserVationFfromObserVationDateWiseForLabPanel3OPD,
  getObserVationFfromObserVationDateWiseForLabPanel4OPD,
  getObserVationFfromObserVationDateWiseForLabPanelLftOPD,
  getObserVationFfromObserVationDateWiseForLabPanelOPD,
  getObserVationFfromObserVationDateWiseForLabPanelRftOPD,
  getObserVationFfromObserVationDateWiseForLabTest,
  getObserVationFfromObserVationDateWiseForSerumLytesOPD,
  getPanel1Data,
  getPanel2Data,
  getPanel3Data,
  getPanel4Data,
  getPanelDexaData,
  getPaneLftData,
  getPanelHomaData,
  getPanelSerumLytesData,
  getPanelTftData,
  getPaneRftData,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import {
  getLabResultsPanel,
  getLabResultsWithName,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { LabResultDetails } from './labResultStatus'

const initialState: LabResultDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const labResultsDetailsIPD = createSlice({
  name: 'labResultsDetailsIPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabResultDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.keys = action.payload.keys
      state.vitalData = action.payload.vitalData
      state.panel1Data = action.payload.panel1Data
      state.panel1Data1 = action.payload.panel1Data1
      state.panel1Data2 = action.payload.panel1Data2
      state.panel1Data3 = action.payload.panel1Data3
      state.resultData = action.payload.resultData
      state.groupedLabTests = action.payload.groupedLabTests
      state.groupedPanel1 = action.payload.groupedPanel1
      state.groupedPanel2 = action.payload.groupedPanel2
      state.groupedPanel3 = action.payload.groupedPanel3
      state.groupedTft = action.payload.groupedTft
      state.lft = action.payload.lft
      state.rft = action.payload.rft
      state.groupedLft = action.payload.groupedLft
      state.groupedRft = action.payload.groupedRft
      state.groupedPanel4 = action.payload.groupedPanel4
      state.groupedDexa = action.payload.groupedDexa
      state.groupedHoma = action.payload.groupedHoma
      state.groupedSerumLytes = action.payload.groupedSerumLytes
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
      state.vital = action.payload.vital
      state.groupedResultsByName = action.payload.groupedResultsByName
    },
  },
})

export const requestForLabResultsDetailsIPD =
  (selectedPatient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabResultDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(labResultsDetailsIPD.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const searchParameters: any = {
        'based-on': appointmentId,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `Encounter?_revinclude=Encounter:part-of&_revinclude=Observation:encounter&_count=500`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(labResultsDetailsIPD.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const observationData =
            getObserVationFfromEncounterForLabTest(proceduresResponse)
          if (observationData.length > 0) {
            // const vitalLisData: VitalsData[] =
            //   getObserVationFfromEncounterForDateWiseLAbResult(
            //     proceduresResponse
            //   )

            observationData.sort((a, b) =>
              (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
                ? -1
                : (a.issued ?? '') < (b.issued ?? '')
                ? 1
                : 0
            )

            observationData.sort((a, b) =>
              a.code!.text! > b.code!.text!
                ? 1
                : b.code!.text! > a.code!.text!
                ? -1
                : 0
            )

            //   .sort((a, b) =>
            //     (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
            //       ? -1
            //       : (a.issued ?? '') < (b.issued ?? '')
            //       ? 1
            //       : 0
            //   )

            const finalObservationData: R4.IObservation[] =
              getLatestObservations(observationData)

            const vitalLisData: VitalsData[] =
              getObserVationFfromObserVationDateWiseForLabTest(
                getSortedObservationForAddLab(finalObservationData)
              )

            const groupedLabPanelResultLatest: GroupedLabResultsByName[] =
              getLabResultsWithName(finalObservationData).sort((a, b) =>
                (a.date ? a.date : '') > (b.date ? b.date : '')
                  ? -1
                  : (a.date ?? '') < (b.date ?? '')
                  ? 1
                  : 0
              )

            const groupByData = groupBy(vitalLisData, 'startDate')
            if (finalObservationData.length > 0) {
              const keys: string[] = Object.keys(groupByData).sort()
              vitalLisData.sort((a, b) => moment(b.startDate).diff(a.startDate))
              state.resultsAvailable = true
              state.searchingConditions = false
              state.keys = keys
              state.vitalData = vitalLisData

              state.resultData = groupByData
              state.groupedResultsByName = groupedLabPanelResultLatest

              state.vital = vitalLisData[vitalLisData.length - 1]
              state.noResultsAvailable = false
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(labResultsDetailsIPD.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: LabResultDetails = {
                searchingConditions: false,
                errorWhileSearchingProcedures: false,
                resultsAvailable: false,
                noResultsAvailable: true,
                showAppointment: false,
              }
              dispatch(
                labResultsDetailsIPD.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: LabResultDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              labResultsDetailsIPD.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: LabResultDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(
            labResultsDetailsIPD.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      /* */
      logger.error(error)
      const errorSearchDoctor: LabResultDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        showAppointment: false,
      }
      dispatch(labResultsDetailsIPD.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

const groupBy = function (xs: VitalsData[], key: string) {
  return xs.reduce((rv: any, x: any) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default labResultsDetailsIPD.reducer
