import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { kBackgroundDefault, textMain } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import SimpleBar from 'simplebar-react'
import { getWelloMedicationFromMedicationRequest } from 'utils/appointment_handle/medications_util'
import {
  getIdOfCardEntry,
  getMedications,
  hasError,
  isEssential,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import CPGSelectionContext from 'views/contexts/cpgContextSelection'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddMedicationRecommendationButton } from '../add_medication_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const MedicationsOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  carePlanIdentifier,
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const classes = useStyles()

  const visitContext = useContext(VisitAppointmentContext)

  const labDiagnosticTestCodes = useSelector(
    (state: RootState) =>
      state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
  )
  const cdsRecommendationAddition = useContext(CPGSelectionContext)

  const selectedMedications = getSelectionContextTypeSections({
    cpgIdentifier: carePlanIdentifier,
    selectionCategoryType: 'medications',
    selectionData: cdsRecommendationAddition.selectionData ?? [],
  })

  function onSelectedMedicationsChanged(changedTest: string) {
    cdsRecommendationAddition.addSelection(
      carePlanIdentifier,
      'medications',
      changedTest
    )
  }

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('medications') && (
          <Box
            display='flex'
            flexDirection='column'
            key='medications_cpg_outer_label'
            py={0.25}
            overflow='auto'
          >
            <Box
              display='flex'
              flexDirection='column'
              key='medications_cpg_inner_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                display='row'
                width='100%'
                flexDirection='column'
                py={2}
                px={1}
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  color: 'black',

                  backgroundColor: kBackgroundDefault,
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    color: 'black',
                  }}
                >
                  Medications
                </Typography>
              </Box>
              <Box
                width='100%'
                flex='row'
                id='referrals_list'
                display='flex'
                overflow='auto'
              >
                {(card.interceptedSuggestions.get('medications') as any[])
                  .length > 0 && (
                  <Box display='flex' width='100%' overflow='auto'>
                    {(card.interceptedSuggestions.get('medications') as any[])
                      .length > 0 && (
                      <SimpleBar
                        style={{
                          height: '100%',
                          width: '100%',
                          overflowX: 'hidden',
                        }}
                      >
                        <List
                          style={{
                            columnCount:
                              (
                                card.interceptedSuggestions.get(
                                  'medications'
                                ) as any[]
                              ).length > 11
                                ? 3
                                : (
                                    card.interceptedSuggestions.get(
                                      'medications'
                                    ) as any[]
                                  ).length > 4
                                ? 2
                                : 1,
                            width: '100%',
                          }}
                        >
                          {(
                            card.interceptedSuggestions.get(
                              'medications'
                            ) as any[]
                          ).map(
                            (
                              eachTest: R4.IMedicationRequest,
                              index: number
                            ) => (
                              <ListItem
                                key={`${getDisplayOfSystemFromCodableConcept(
                                  eachTest.medicationCodeableConcept
                                )}list_item`}
                                onChange={(e) => {
                                  onSelectedMedicationsChanged(
                                    getDisplayOfSystemFromCodableConcept(
                                      eachTest.medicationCodeableConcept
                                    ) ?? ''
                                  )
                                }}
                                dense
                                style={{
                                  padding: '0px',
                                  paddingLeft: '2px',
                                  margin: '0px',
                                }}
                              >
                                <Box
                                  width='100%'
                                  display='flex'
                                  flexDirection='row'
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge='start'
                                      size='small'
                                      id={`med_plan${index}`}
                                      checked={
                                        selectedMedications.findIndex(
                                          (e) =>
                                            e ===
                                            getDisplayOfSystemFromCodableConcept(
                                              eachTest.medicationCodeableConcept
                                            )
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': `${getDisplayOfSystemFromCodableConcept(
                                          eachTest.medicationCodeableConcept
                                        )}list_item_id`,
                                      }}
                                      color='primary'
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={`${getDefaultCodeOfSystemFromCodableConcept(
                                      eachTest.medicationCodeableConcept
                                    )}list_item_id`}
                                    primary={
                                      getDisplayOfSystemFromCodableConcept(
                                        eachTest.medicationCodeableConcept
                                      ) ??
                                      getDefaultCodeOfSystemFromCodableConcept(
                                        eachTest.medicationCodeableConcept
                                      ) ??
                                      ''
                                    }
                                    primaryTypographyProps={{
                                      variant: 'subtitle1',
                                      style: {
                                        color: isEssential(eachTest)
                                          ? textMain
                                          : '#4d4d4d',
                                      },
                                    }}
                                    secondary={
                                      getWelloMedicationFromMedicationRequest(
                                        eachTest
                                      ).textForm ?? ''
                                    }
                                  />
                                </Box>
                              </ListItem>
                            )
                          )}
                        </List>
                      </SimpleBar>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                width='100%'
                flexDirection='row-reverse'
                id='referrals_list'
                display='flex'
              >
                <AddMedicationRecommendationButton
                  carePlanIdentifier={carePlanIdentifier}
                  medications={(
                    card.interceptedSuggestions.get('medications') as any[]
                  ).filter((e) =>
                    selectedMedications.includes(
                      getDisplayOfSystemFromCodableConcept(
                        e.medicationCodeableConcept
                      )!
                    )
                  )}
                  onRequestFinished={() => {
                    cdsRecommendationAddition.removeSelection(
                      carePlanIdentifier,
                      'medications',
                      ''
                    )
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  )
}
