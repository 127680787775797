import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetAddDietSlice } from 'redux/administration/management/addDiet/addDietSlice'
import { RootState } from 'redux/rootReducer'
import { getAllNutritionCatalogValueSets } from 'redux/valueSet/nutrition_catalogue/dietTableSearchSlice'
import { WelloLoadingIndicator } from 'wello-web-components'
import { AddDiet } from './addDiet'
import { DietDataTable } from './dietDataTable'

export interface IUnitAdmin {
  dietCode: string
  onTap?: () => void
  onAdd?: () => void
}

export const DietSearch: React.FC<IUnitAdmin> = ({
  dietCode,
  onTap,
  onAdd,
}) => {
  const dietTableSearchSlice = useSelector(
    (state: RootState) => state.dietTableSearchSlice
  )
  const dispatch = useDispatch()
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    dispatch(getAllNutritionCatalogValueSets(dietCode))
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          {dietTableSearchSlice.searchingTreatment && (
            <Box flexDirection='row' display='flex' width='100%'>
              <WelloLoadingIndicator message='Fetching Diet Item Details' />
            </Box>
          )}
          {dietTableSearchSlice.noResultsAvailable && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
            >
              <Typography variant='subtitle1' color='initial' align='center'>
                No Diet Data Available.
              </Typography>
            </Box>
          )}
          {dietTableSearchSlice.errorWhileSearchingTreatment && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching Diet. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          {dietTableSearchSlice.resultsAvailable && (
            <Box display='flex' width='100%'>
              <Box display='flex' flexDirection='row' width='100%'>
                <DietDataTable
                  usersData={
                    dietTableSearchSlice.catalogueEntries
                      ? dietTableSearchSlice.catalogueEntries
                      : []
                  }
                />
              </Box>
            </Box>
          )}

          {showPopup && (
            <AddDiet
              open={showPopup}
              onCancelClick={() => {
                setShowPopup(false)
              }}
              onAdd={(dietData?: R4.ICatalogEntry) => {
                if (dietData) {
                  dispatch(getAllNutritionCatalogValueSets())

                  setShowPopup(false)
                }
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  )
}
