import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { TherapyPerDay } from 'models/therapyPerDay'
import { Treatment } from 'models/treatMent'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentUnit } from 'utils/careplan_utils/careplan_utils'
import { sleep } from 'utils/dateUtil'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { requestForTherapiesForIpd } from '../viewPhysioTherapiesOfEncounter/viewPhysioTherapiesOfEncounterSlice'
import { AppPhysioTherapiesStatus } from './addPhysioTherapiesStatus'

const initialState: AppPhysioTherapiesStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addPhysioTherapiesSlice = createSlice({
  name: 'addPhysioTherapiesSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(
      state,
      action: PayloadAction<AppPhysioTherapiesStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addTreatmentBundle = action.payload.addTreatmentBundle
    },

    resetAddVitalsDetails(
      state,
      action: PayloadAction<AppPhysioTherapiesStatus>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addTreatmentBundle = initialState.addTreatmentBundle
    },
  },
})

export const addPhysioTherapiesScheduleDetails =
  (
    treatment: TherapyPerDay[],
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AppPhysioTherapiesStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addPhysioTherapiesSlice.actions.updateAddVitalsStatus(addingState))
    try {
      const plan: R4.ICarePlan | undefined =
        await getCarePlanOfPatientWithCurrentUnit(
          fhirAppointmentDetails.patient.id ?? ''
        )
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        treatment,
        fhirAppointmentDetails,
        plan
      )

      const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
        ? fhirAppointmentDetails.mainServiceRequest.code.coding
          ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean = appointmentType === '304903009'
      const consultation: boolean = appointmentType === '33022008'

      const resource: any = {
        isOpdConsult: consultation,
        isOpdDayCare: ipdDayCare,
        actionBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${fhirAppointmentDetails.mainServiceRequest.id}/addIpdCarePlanDetails`,
        resource
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (response.status === 'Updated Care Plans for IPD') {
        await sleep(5000)
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Therapies card updated successfully'))
        dispatch(
          requestForTherapiesForIpd(
            fhirAppointmentDetails.mainServiceRequest.id!
          )
        )
        dispatch(
          addPhysioTherapiesSlice.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AppPhysioTherapiesStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addPhysioTherapiesSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      console.error(error)
      const errorCreatePersonState: AppPhysioTherapiesStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addPhysioTherapiesSlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddVitalsState = () => (dispatch: AppDispatch) => {
  dispatch(addPhysioTherapiesSlice.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  treatment: TherapyPerDay[],
  fhirIpdDetails: FhirActiveIPDDetailsForMedicalRole,
  plan?: R4.ICarePlan
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const requestId: string = getUniqueTempId()

  for (let i = 0; i < treatment.length; i++) {
    if (
      treatment[i].morning.machineTherapies !== undefined &&
      treatment[i].morning.machineTherapies!.length > 0
    ) {
      const newDate = new Date(
        `${treatment[i].date}T${treatment[i].morning.startTime}`
      )
      const newDateEnd = new Date(
        `${treatment[i].date}T${treatment[i].morning.endTime}`
      )
      const isoStartDateTIme = newDate.toISOString()
      const isoEndDateTIme = newDateEnd.toISOString()
      const momentObj = moment(
        treatment[i].date + treatment[i].morning.startTime,
        'YYYY-MM-DDLT'
      )
      const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      const enmomentObj = moment(
        treatment[i].date + treatment[i].morning.endTime,
        'YYYY-MM-DDLT'
      )
      const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      for (let j = 0; j < treatment[i].morning.machineTherapies!.length; j++) {
        const currentServiceRequest1: R4.IServiceRequest = {
          resourceType: 'ServiceRequest',
          subject: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },

          requester: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },
          status: 'active',
          intent: 'order',
          category:
            treatment[i].morning.machineTherapies![j].healtcareService.category,
          performer: [getCurrentUserUnitReference()!],
          priority: 'urgent',

          occurrencePeriod: {
            start: isoStartDateTIme,
            end: isoEndDateTIme,
          },
          code: treatment[i].morning.machineTherapies![j].healtcareService
            .type![0],
          authoredOn: new Date().toISOString(),
        }

        if (plan) {
          currentServiceRequest1.basedOn = [
            {
              reference: `CarePlan/${plan.id!}`,
            },
          ]
        }

        const performerData: R4.IReference = {
          id: treatment[i].morning.id,
          reference: `HealthcareService/${
            treatment[i].morning.machineTherapies![j].healtcareService.id
          }`,
        }
        if (currentServiceRequest1.performer)
          currentServiceRequest1.performer.push(performerData)

        const midMorningExtension: R4.IExtension = {}
        midMorningExtension.url =
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
        midMorningExtension.valueTiming = {
          code: {
            coding: [
              {
                system: 'http://hl7.org/fhir/event-timing',
                code: 'MORN',
                display: 'Morning',
              },
            ],
          },
        }
        currentServiceRequest1.extension = []
        currentServiceRequest1.extension.push(midMorningExtension)

        requestBundle.entry?.push({
          fullUrl: `${currentServiceRequest1.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: currentServiceRequest1.resourceType,
          },
          resource: currentServiceRequest1,
        })
      }
    }
    if (
      treatment[i].morning.manualTherapies !== undefined &&
      treatment[i].morning.manualTherapies!.length > 0
    ) {
      const newDate = new Date(
        `${treatment[i].date}T${treatment[i].morning.startTime}`
      )
      const newDateEnd = new Date(
        `${treatment[i].date}T${treatment[i].morning.endTime}`
      )
      const isoStartDateTIme = newDate.toISOString()
      const isoEndDateTIme = newDateEnd.toISOString()
      const momentObj = moment(
        treatment[i].date + treatment[i].morning.startTime,
        'YYYY-MM-DDLT'
      )
      const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      const enmomentObj = moment(
        treatment[i].date + treatment[i].morning.endTime,
        'YYYY-MM-DDLT'
      )
      const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      for (let j = 0; j < treatment[i].morning.manualTherapies!.length; j++) {
        const currentServiceRequest1: R4.IServiceRequest = {
          resourceType: 'ServiceRequest',
          subject: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },

          requester: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },
          status: 'active',
          intent: 'order',
          category:
            treatment[i].morning.manualTherapies![j].healtcareService.category,
          performer: [getCurrentUserUnitReference()!],
          priority: 'urgent',

          occurrencePeriod: {
            start: isoStartDateTIme,
            end: isoEndDateTIme,
          },
          code: treatment[i].morning.manualTherapies![j].healtcareService
            .type![0],
          authoredOn: new Date().toISOString(),
        }

        if (plan) {
          currentServiceRequest1.basedOn = [
            {
              reference: `CarePlan/${plan.id!}`,
            },
          ]
        }

        const performerData: R4.IReference = {
          id: treatment[i].morning.id,
          reference: `HealthcareService/${
            treatment[i].morning.manualTherapies![j].healtcareService.id
          }`,
        }
        if (currentServiceRequest1.performer)
          currentServiceRequest1.performer.push(performerData)

        const midMorningExtension: R4.IExtension = {}
        midMorningExtension.url =
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
        midMorningExtension.valueTiming = {
          code: {
            coding: [
              {
                system: 'http://hl7.org/fhir/event-timing',
                code: 'MORN',
                display: 'Morning',
              },
            ],
          },
        }
        currentServiceRequest1.extension = []
        currentServiceRequest1.extension.push(midMorningExtension)

        requestBundle.entry?.push({
          fullUrl: `${currentServiceRequest1.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: currentServiceRequest1.resourceType,
          },
          resource: currentServiceRequest1,
        })
      }
    }

    if (
      treatment[i].afterNoon.machineTherapies !== undefined &&
      treatment[i].afterNoon.machineTherapies!.length > 0
    ) {
      const newDate = new Date(
        `${treatment[i].date}T${treatment[i].afterNoon.startTime}`
      )
      const newDateEnd = new Date(
        `${treatment[i].date}T${treatment[i].afterNoon.endTime}`
      )
      const isoStartDateTIme = newDate.toISOString()
      const isoEndDateTIme = newDateEnd.toISOString()
      const momentObj = moment(
        treatment[i].date + treatment[i].afterNoon.startTime,
        'YYYY-MM-DDLT'
      )
      const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      const enmomentObj = moment(
        treatment[i].date + treatment[i].afterNoon.endTime,
        'YYYY-MM-DDLT'
      )
      const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      for (
        let j = 0;
        j < treatment[i].afterNoon.machineTherapies!.length;
        j++
      ) {
        const currentServiceRequest1: R4.IServiceRequest = {
          resourceType: 'ServiceRequest',
          subject: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },

          requester: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },
          status: 'active',
          intent: 'order',
          category:
            treatment[i].afterNoon.machineTherapies![j].healtcareService
              .category,
          performer: [getCurrentUserUnitReference()!],
          priority: 'urgent',

          occurrencePeriod: {
            start: isoStartDateTIme,
            end: isoEndDateTIme,
          },
          code: treatment[i].afterNoon.machineTherapies![j].healtcareService
            .type![0],
          authoredOn: new Date().toISOString(),
        }

        if (plan) {
          currentServiceRequest1.basedOn = [
            {
              reference: `CarePlan/${plan.id!}`,
            },
          ]
        }

        const performerData: R4.IReference = {
          id: treatment[i].afterNoon.id,
          reference: `HealthcareService/${
            treatment[i].afterNoon.machineTherapies![j].healtcareService.id
          }`,
        }
        if (currentServiceRequest1.performer)
          currentServiceRequest1.performer.push(performerData)

        const afterNoonExtension: R4.IExtension = {}
        afterNoonExtension.url =
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
        afterNoonExtension.valueTiming = {
          code: {
            coding: [
              {
                system: 'http://hl7.org/fhir/event-timing',
                code: 'AFT',
                display: 'Afternoon',
              },
            ],
          },
        }
        currentServiceRequest1.extension = []
        currentServiceRequest1.extension.push(afterNoonExtension)

        requestBundle.entry?.push({
          fullUrl: `${currentServiceRequest1.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: currentServiceRequest1.resourceType,
          },
          resource: currentServiceRequest1,
        })
      }
    }
    if (
      treatment[i].afterNoon.manualTherapies !== undefined &&
      treatment[i].afterNoon.manualTherapies!.length > 0
    ) {
      const newDate = new Date(
        `${treatment[i].date}T${treatment[i].afterNoon.startTime}`
      )
      const newDateEnd = new Date(
        `${treatment[i].date}T${treatment[i].afterNoon.endTime}`
      )
      const isoStartDateTIme = newDate.toISOString()
      const isoEndDateTIme = newDateEnd.toISOString()
      const momentObj = moment(
        treatment[i].date + treatment[i].afterNoon.startTime,
        'YYYY-MM-DDLT'
      )
      const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      const enmomentObj = moment(
        treatment[i].date + treatment[i].afterNoon.endTime,
        'YYYY-MM-DDLT'
      )
      const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
      for (let j = 0; j < treatment[i].afterNoon.manualTherapies!.length; j++) {
        const currentServiceRequest1: R4.IServiceRequest = {
          resourceType: 'ServiceRequest',
          subject: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },

          requester: {
            id: fhirIpdDetails.patient.id,
            reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
            display: getNameOfPatient(fhirIpdDetails.patient),
          },
          status: 'active',
          intent: 'order',
          category:
            treatment[i].afterNoon.manualTherapies![j].healtcareService
              .category,
          performer: [getCurrentUserUnitReference()!],
          priority: 'urgent',
          occurrencePeriod: {
            start: isoStartDateTIme,
            end: isoEndDateTIme,
          },
          code: treatment[i].afterNoon.manualTherapies![j].healtcareService
            .type![0],
          authoredOn: new Date().toISOString(),
        }
        if (plan) {
          currentServiceRequest1.basedOn = [
            {
              reference: `CarePlan/${plan.id!}`,
            },
          ]
        }

        const performerData: R4.IReference = {
          id: treatment[i].afterNoon.id,
          reference: `HealthcareService/${
            treatment[i].afterNoon.manualTherapies![j].healtcareService.id
          }`,
        }
        if (currentServiceRequest1.performer)
          currentServiceRequest1.performer.push(performerData)

        const afterNoonExtension: R4.IExtension = {}
        afterNoonExtension.url =
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
        afterNoonExtension.valueTiming = {
          code: {
            coding: [
              {
                system: 'http://hl7.org/fhir/event-timing',
                code: 'AFT',
                display: 'Afternoon',
              },
            ],
          },
        }
        currentServiceRequest1.extension = []
        currentServiceRequest1.extension.push(afterNoonExtension)

        requestBundle.entry?.push({
          fullUrl: `${currentServiceRequest1.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: currentServiceRequest1.resourceType,
          },
          resource: currentServiceRequest1,
        })
      }
    }
  }

  return requestBundle
}

export default addPhysioTherapiesSlice.reducer
