import { Box, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { AddCustomInstructions } from 'views/components/consultation/plan/addCustomInstruction'
import { RecommendationWrapperForSub } from 'views/components/consultation/plan/cdsRecommendations/cds_reccomendation_wrapper_new'
import { RecommendationWrapperIPD } from './cdsRecomendations/cds_reccomendation_wrapperIPD'
import { FullWidthNextStepsOfConsultationIPD } from './fullWidthnextSteps'
import { HalfWidthNextStepsOfConsultationIPD } from './halfWidthNextStepsIPD'
import { CarePlanInstructionListIPD } from './instructionsListIPD'
import { NextStepPlan } from './nextStepPlan'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const NextStepsOfConsultationIPD: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [showCohorts, setShowCohorts] = useState<boolean>(false)

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)
    const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
      useState<boolean>(false)
    const dispatch = useDispatch()
    const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
      useState(false)
    const [openAddInstruction, setAddInstruction] = useState<boolean>(false)

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' width='100%' px={2}>
            <Box display='flex' flexDirection='column' width='50%'>
              <HalfWidthNextStepsOfConsultationIPD
                fhirAppointmentDetails={fhirAppointmentDetails}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              width='50%'
              pl={0.5}
              pt={0.5}
            >
              <RecommendationWrapperForSub
                encounter={fhirAppointmentDetails.mainEncounter!}
                patient={fhirAppointmentDetails.patient}
                onRecommendationAdditionUpdate={(category) => {
                  switch (category) {
                    case 'instruction': {
                      setTimeout(() => {
                        dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                      }, 500)

                      break
                    }
                    case 'careplan-follow-up': {
                      setTimeout(() => {
                        dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                      }, 500)
                      break
                    }
                    case 'lab': {
                      setTimeout(() => {
                        dispatch(
                          getLabRequestsOfAppointment(
                            fhirAppointmentDetails.patient,
                            fhirAppointmentDetails.practitionerDetail,
                            fhirAppointmentDetails.encounter
                          )
                        )
                      }, 500)
                      break
                    }
                    case 'medication': {
                      setTimeout(() => {
                        dispatch(
                          getMedicationsOfAppointment(
                            fhirAppointmentDetails.patient,
                            fhirAppointmentDetails.encounter
                          )
                        )
                      }, 500)
                      break
                    }
                    case 'specialistReferral': {
                      break
                    }
                    default:
                      break
                  }
                }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={2}>
            <FullWidthNextStepsOfConsultationIPD
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={false}
            />
          </Box>
        </Box>
      </ReactVisibilitySensor>
    )
  }
