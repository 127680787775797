import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React from 'react'
import AodIcon from '@mui/icons-material/Aod'
import {
  getTimeOfDayDisplayForStatement,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { hasMedicationAddedByPatient } from 'utils/fhirResoureHelpers/allergyHelpers'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface MedicationsListProps {
  medicationsList: WelloMedication[]
  onDelete: (medication: WelloMedication) => void
  onEditClicked: (medication: WelloMedication) => void
  history: boolean
  split: boolean
  displayStartEndDates?: boolean
}

export const AyurvedaMedicationsListSplit: React.FC<MedicationsListProps> = ({
  medicationsList,
  onDelete,
  onEditClicked,
  displayStartEndDates = false,
  history = false,
  split = false,
}: MedicationsListProps) => {
  const classes = useStyles()

  return (
    <TableContainer
      style={{
        width: '100%',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Table className={classes.table} aria-label='simple table'>
        <TableHead
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <TableRow
            style={{
              fontWeight: 'bold',
            }}
          >
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhi (Medication)
              </Typography>
            </TableCell>
            <TableCell
              width={8}
              style={{
                minWidth: '50px',
              }}
            >
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Route of Administration
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Time of Day
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhakala
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Anupana
              </Typography>
            </TableCell>
            <TableCell width={8}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            {displayStartEndDates && (
              <TableCell width={8}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Start from
                </Typography>
              </TableCell>
            )}
            {displayStartEndDates && (
              <TableCell width={8}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Till
                </Typography>
              </TableCell>
            )}
            <TableCell
            //   width={20}
            //   style={{
            //     maxWidth: '50px',
            //   }}
            >
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {medicationsList.map((row: WelloAyurvedaMedication, index) => (
            <TableRow
              key={row.id ?? row.medicationCode?.code ?? row.code ?? ''}
              style={{
                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
              }}
            >
              <TableCell>
                {row.base &&
                  row.base === 'statement' &&
                  hasMedicationAddedByPatient(row) && (
                    <Box paddingRight={1}>
                      <Tooltip title=''>
                        <IconButton
                          aria-label='collapse_order_type'
                          size='small'
                        >
                          <AodIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                  id='med_list13'
                >
                  {_.capitalize(
                    row.medicationCode
                      ? row.medicationCode?.display ?? row.code ?? ''
                      : ''
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                  id='med_list15'
                >
                  {row.dosageQuantity ? row.dosageQuantity : ''}{' '}
                  {getUnitOfDosageFromRequest(row.dosageQuantityType ?? '')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                  id='med_list16'
                >
                  {row.administrationCode
                    ? getUnitOfRouteRequest(row.administrationCode)
                    : ''}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    width: '85px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    wordWrap: 'break-word',
                  }}
                  id='med_list17'
                >
                  {getTimeOfDayDisplayForStatement(
                    row.timeOfDayCode ?? []
                  ).join(', ') ?? ''}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                  id='med_list18'
                >
                  {_.capitalize(
                    row.aushadhaKalaCode
                      ? row.aushadhaKalaCode.display ?? ''
                      : ''
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                  id='med_list19'
                >
                  {_.capitalize(
                    row.anupanaCode ? row.anupanaCode.display ?? '' : ''
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                  id='med_list20'
                >
                  {row.anupanaDosageQuantity ? row.anupanaDosageQuantity : ''}{' '}
                  {getUnitOfDosageFromRequest(
                    row.anupanaDosageQuantityType ?? ''
                  )}
                </Typography>
              </TableCell>
              {displayStartEndDates && (
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      width: '65px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                    id='med_list21'
                  >
                    {`${moment(row.startFrom).format('DD-MM-YYYY')}`}
                  </Typography>
                </TableCell>
              )}
              {displayStartEndDates && (
                <TableCell size='medium' width='50px'>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      width: '65px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                    id='med_list22'
                  >
                    {`${moment(row.till).format('DD-MM-YYYY')}`}
                  </Typography>
                </TableCell>
              )}
              <TableCell
              // width={20}
              // style={{
              //   maxWidth: '240px',
              // }}
              >
                <Box width='100%'>
                  <OverflowTypography
                    text={row.notes ?? ''}
                    typographyProps={{
                      variant: 'body2',
                      color: 'initial',
                      id: 'med_notes',
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
