import { R4 } from '@ahryman40k/ts-fhir-types'
import { IAttachment } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addPatientDetails,
  resetState,
} from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import { logger } from 'utils/logger'
import { WelloLoadingIndicator } from 'wello-web-components'
import { ErrorDialog } from './LeftMenu/errorDialog'
import { NewPatient } from './patients/NewPatient'
import { NewPatientMinimal } from './patients/NewPatientMinimal'

export interface Props {
  isLab: boolean
  isAppointment: boolean
  onPatientCreated?: (patientDetail?: R4.IPatient, patientId?: string) => void
  onError?: (message: string) => void
  isMinimal?: boolean
  onCancelClicked: () => void
  popup: any
  patientDetail?: R4.IPatient
  relatedPersonDetail?: R4.IRelatedPerson
}
export const AddNewPatient: React.FC<Props> = ({
  isLab,
  isAppointment,
  onPatientCreated,
  onError,
  onCancelClicked,
  isMinimal = false,
  popup = false,
  patientDetail,
  relatedPersonDetail,
}: Props) => {
  const addPatientState = useSelector((state: RootState) => state.patientSlice)

  const dispatch = useDispatch()
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])
  const [patientId, setPatientId] = React.useState<string>()
  const [showError, setShowError] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<string>()

  useEffect(() => {
    // i18n.changeLanguage(language ?? '')
    if (addPatientState.additionSuccessful) {
      if (onPatientCreated) {
        dispatch(resetState())
        if (addPatientState.patient) {
          logger.info('Added Patient', addPatientState.patient)
        } else {
          logger.info('Patient details not available')
        }

        onPatientCreated(
          addPatientState.patient,
          addPatientState.patientId ?? ''
        )
      }
    }
    if (addPatientState.error) {
      //   dispatch(showErrorAlert(addPatientState.errorMessage ?? ''))
      setErrorText(
        addPatientState.errorMessage ??
          'Something went wrong, Please try again later'
      )
      setShowError(true)

      dispatch(resetState())
      //   if (onError) {
      //     onError(addPatientState.errorMessage ?? '')
      //     dispatch(resetState())
      //   }
    }
  }, [addPatientState, onPatientCreated, onError, dispatch])
  return (
    <div>
      {addPatientState.adding && (
        <Grid container spacing={2} alignItems='center' wrap='nowrap'>
          <Grid item xs={12}>
            <WelloLoadingIndicator message='Adding Patient' />
          </Grid>
        </Grid>
      )}
      {isMinimal && (
        <NewPatientMinimal
          formLabels={{
            addressLabel: t('labelPatient:attr_labels.Patient.address'),
            emailLabel: t('labelCommon:email_address'),
            fatherNameLabel: t('labelCommon:fathers_name_label'),
            genderLabel: t('labelPatient:attr_labels.Patient.gender'),
            husbandNameLabel: t('labelCommon:husbands_name_label'),
            lNameLabel: t('labelCommon:last_name_label'),
            identityLabel: '',
            mNameLabel: t('labelCommon:middle_name_label'),
            maritalStatusLabel: t(
              'labelPatient:attr_labels.Patient.maritalStatus'
            ),
            nameLabel: t('labelCommon:first_name_label'),
            nationalityLabel: t('labelCommon:nationality_label'),
            occupationLabel: t('labelCommon:occupation_label'),
            phoneNumberLabel: t('labelCommon:phone_number_label'),
            photoLabel: t('labelPatient:attr_labels.Patient.photo'),
            referenceIdLabel: t('labelCommon:placeHolder_reference'),
            addressTypeLabel: t('labelCommon:placeHolder_addressType'),
            doblabelForKey: t('labelCommon:doblabelForKey'),
          }}
          placeholderLables={{
            addressLabel: t('labelCommon:placeHolderAddress'),
            emailLabel: t('labelCommon:placeHolder_email_address'),
            fatherNameLabel: t('labelCommon:placeHolder_fathers_name_label'),
            genderLabel: t('labelCommon:placeHolder_gender'),
            husbandNameLabel: t('labelCommon:placeHolder_husbands_name_label'),
            lNameLabel: t('labelCommon:placeHolder_last_name_label'),
            identityLabel: '',
            mNameLabel: t('labelCommon:placeHolder_middle_name_label'),
            maritalStatusLabel: t('labelCommon:placeHolder_maritalStatus'),
            nameLabel: t('labelCommon:placeHolder_first_name_label'),
            nationalityLabel: t('labelCommon:placeHolder_nationality_label'),
            occupationLabel: t('labelCommon:placeHolder_occupation_label'),
            phoneNumberLabel: t('labelCommon:placeHolder_phone_number_label'),
            photoLabel: t('labelPatient:placeHolder_photo'),
            addPatient: t('labelCommon:placeHolder_addPatient'),
            addPhoto: t('labelCommon:placeHolder_addPhoto'),
            doblabel: t('labelCommon:placeHolder_doblabel'),
            referenceIdLabel: t('labelCommon:placeHolder_reference'),
            addressTypeLabel: t('labelCommon:placeHolder_addressType'),
          }}
          resetButtonText={t('labelCommon:cancel')}
          submitButtonText={t('labelCommon:placeHolder_addPatient')}
          count={1}
          preAddedRelatedPatient={relatedPersonDetail}
          id={123}
          popupSate={popup}
          onClickToCancel={() => {
            onCancelClicked()
          }}
          onValidSubmit={(
            patientDetails: R4.IPatient,
            relatedPerson?: R4.IRelatedPerson,
            relatedPersonHs?: R4.IRelatedPerson,
            occupation?: string,
            nationality?: string,
            addressProofFront?: IAttachment,
            addressProofBack?: IAttachment,
            both?: IAttachment,
            referred?: string,
            relation?: R4.ICoding,
            isPrimary?: boolean,
            passportStartDate?: string,
            expiryDate?: string
          ) => {
            dispatch(
              addPatientDetails(
                patientDetails,
                relatedPerson,
                relatedPersonHs,
                nationality ?? 'IN',
                occupation,
                both,
                addressProofFront,
                addressProofBack,
                referred,
                relation,
                isPrimary,
                passportStartDate,
                expiryDate
              )
            )
          }}
        />
      )}
      {!isMinimal && (
        <NewPatient
          isLab={isLab}
          isAppointment={isAppointment}
          preAddedPatient={patientDetail}
          preAddedRelatedPatient={relatedPersonDetail}
          formLabels={{
            addressLabel: t('labelPatient:attr_labels.Patient.address'),
            emailLabel: t('labelCommon:email_address'),
            fatherNameLabel: t('labelCommon:fathers_name_label'),
            genderLabel: t('labelPatient:attr_labels.Patient.gender'),
            husbandNameLabel: t('labelCommon:husbands_name_label'),
            lNameLabel: t('labelCommon:last_name_label'),
            identityLabel: '',
            mNameLabel: t('labelCommon:middle_name_label'),
            maritalStatusLabel: t(
              'labelPatient:attr_labels.Patient.maritalStatus'
            ),
            nameLabel: t('labelCommon:first_name_label'),
            nationalityLabel: t('labelCommon:nationality_label'),
            occupationLabel: t('labelCommon:occupation_label'),
            phoneNumberLabel: t('labelCommon:phone_number_label'),
            photoLabel: t('labelPatient:attr_labels.Patient.photo'),
            referenceIdLabel: t('labelCommon:placeHolder_reference'),
            addressTypeLabel: t('labelCommon:placeHolder_addressType'),
            doblabelForKey: t('labelCommon:doblabelForKey'),
          }}
          placeholderLables={{
            addressLabel: t('labelCommon:placeHolderAddress'),
            emailLabel: t('labelCommon:placeHolder_email_address'),
            fatherNameLabel: t('labelCommon:placeHolder_fathers_name_label'),
            genderLabel: t('labelCommon:placeHolder_gender'),
            husbandNameLabel: t('labelCommon:placeHolder_husbands_name_label'),
            lNameLabel: t('labelCommon:placeHolder_last_name_label'),
            identityLabel: '',
            mNameLabel: t('labelCommon:placeHolder_middle_name_label'),
            maritalStatusLabel: t('labelCommon:placeHolder_maritalStatus'),
            nameLabel: t('labelCommon:placeHolder_first_name_label'),
            nationalityLabel: t('labelCommon:placeHolder_nationality_label'),
            occupationLabel: t('labelCommon:placeHolder_occupation_label'),
            phoneNumberLabel: t('labelCommon:placeHolder_phone_number_label'),
            photoLabel: t('labelPatient:placeHolder_photo'),
            addPatient: t('labelCommon:placeHolder_addPatient'),
            addPhoto: t('labelCommon:placeHolder_addPhoto'),
            doblabel: t('labelCommon:placeHolder_doblabel'),
            referenceIdLabel: t('labelCommon:placeHolder_reference'),
            addressTypeLabel: t('labelCommon:placeHolder_addressType'),
          }}
          resetButtonText={t('labelCommon:cancel')}
          submitButtonText={t('labelCommon:placeHolder_addPatient')}
          count={1}
          id={123}
          popupSate={popup}
          onClickToCancel={() => {
            onCancelClicked()
          }}
          loading={addPatientState.adding}
          onValidSubmit={(
            patientDetails: R4.IPatient,
            relatedPerson?: R4.IRelatedPerson,
            relatedPersonHs?: R4.IRelatedPerson,
            occupation?: string,
            nationality?: string,
            addressProofFront?: IAttachment,
            addressProofBack?: IAttachment,
            both?: IAttachment,
            referred?: string,
            relation?: R4.ICoding,
            isPrimary?: boolean,
            passportStartDate?: string,
            expiryDate?: string,
            passportIssueCity?: string,
            passportIssueCountry?: string
          ) => {
            dispatch(
              addPatientDetails(
                patientDetails,
                relatedPerson,
                relatedPersonHs,
                nationality,
                occupation,
                both,
                addressProofFront,
                addressProofBack,
                referred,
                relation,
                isPrimary,
                passportStartDate,
                expiryDate,
                passportIssueCity,
                passportIssueCountry
              )
            )
          }}
        />
      )}
      <ErrorDialog
        open={showError}
        onClose={() => {
          setShowError(false)
        }}
        notesData={errorText!}
      />
    </div>
  )
}
