import { Snackbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import Alert from '@material-ui/lab/Alert'
import { AlertTitle } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertType } from 'redux/alertHandler/alertActionTypes'
import { clearAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'

export const AlertHandler: React.FC<{}> = () => {
  const alertState = useSelector((state: RootState) => state.alertSlice)
  const dispatch = useDispatch()
  return alertState.showAlert ? (
    <Snackbar
      open={alertState.showAlert}
      autoHideDuration={alertState.disableAutoHide === true ? null : 4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={() => {
        dispatch(clearAlert())
      }}
      key={alertState.alertMessage}
    >
      <Alert
        severity={getType(alertState.alertType)}
        icon={getTypeIcon(alertState.alertType)}
        variant='outlined'
        style={{ backgroundColor: 'white' }}
        action={
          <IconButton
            color='inherit'
            size='small'
            aria-label='close'
            onClick={() => {
              dispatch(clearAlert())
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
      >
        {alertState.alertTitle && (
          <AlertTitle> {alertState.alertTitle} </AlertTitle>
        )}
        {alertState.alertMessage}
      </Alert>
    </Snackbar>
  ) : (
    <div />
  )
}

function getType(type: AlertType): 'info' | 'success' | 'warning' | 'error' {
  switch (type) {
    case AlertType.ErrorAlert:
      return 'error'

    case AlertType.InformationAlert:
      return 'info'

    case AlertType.WarningAlert:
      return 'warning'

    case AlertType.SuccessAlert:
      return 'success'
    default:
      return 'info'
  }
}

function getTypeIcon(type: AlertType) {
  switch (type) {
    case AlertType.ErrorAlert:
      return <ErrorIcon />

    case AlertType.InformationAlert:
      return <InfoIcon />

    case AlertType.WarningAlert:
      return <WarningIcon />

    case AlertType.SuccessAlert:
      return <DoneIcon />
    default:
      return <InfoIcon />
  }
}
