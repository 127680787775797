import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { SubscriptionInterest } from 'models/subscriptionInterestDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { InventoryClient } from 'services/inventoryService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitReference,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getSubscriptionInterestDetailsFromBundleBundle } from 'utils/admisnistration/cpg/susbcriptionsHelper'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { SubscriptionInterestListForProviderSearchStatus } from './subscriptionInterestListForProviderSearchStatus'

const initialState: SubscriptionInterestListForProviderSearchStatus = {
  searchingAppointments: false,
  fetchingExtraOrders: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

const subscriptionInterestListForProviderSearchSlice = createSlice({
  name: 'subscriptionInterestListForProviderSearchSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<SubscriptionInterestListForProviderSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableOrders = action.payload.availableOrders
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.count = action.payload.count
      state.nextPageUrl = action.payload.nextPageUrl
      state.previousPageUrl = action.payload.previousPageUrl
      state.fetchingExtraOrders = action.payload.fetchingExtraOrders
      state.existingOrders = action.payload.existingOrders
    },
  },
})

export const requestForSubscriptionInterests =
  (
    patientName?: string,
    count?: number,
    existingOrder?: SubscriptionInterest[],
    pageState?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SubscriptionInterestListForProviderSearchStatus = {
      searchingAppointments: true,
      fetchingExtraOrders: false,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      existingOrders: existingOrder,
      availableOrders: existingOrder,
    }
    dispatch(
      subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
        state
      )
    )
    try {
      axios.CancelToken.source()
      if (cancelTokenStore.has('therapiesSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'subscriptionInterestSearchControlToken'
        )
        // controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('subscriptionInterestSearchControlToken')
      }
      cancelTokenStore.set(
        'subscriptionInterestSearchControlToken',
        axios.CancelToken.source()
      )
      const searchParameters: any = {
        _count: count ?? 20,
        code: 'subscription-interest',
        _sort: '-authored-on',
        _include: ['Task:focus', 'Task:requester', 'Task:owner'],
      }
      if (isUnitAdmin()) {
        const currentUnitRef = getCurrentUserUnitReference()
        if (currentUnitRef && currentUnitRef.reference) {
          searchParameters['requester:PractitionerRole.organization'] =
            currentUnitRef.reference
        } else {
          throw new Error('No unit found for current user')
        }
      } else {
        const practRoleRef = getCurrentUserPractitionerRoleRef()
        if (practRoleRef && practRoleRef.reference) {
          searchParameters.requester = practRoleRef.reference
        } else {
          throw new Error('No user details found for current user')
        }
      }
      if (patientName && patientName.length > 0) {
        searchParameters['patient:Patient.name:contains'] = patientName
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let response: any
      response = await fhirClient.doGetResourceIncludeAndIncludeIterate(
        `/Task`,
        searchParameters,
        (
          cancelTokenStore.get(
            'subscriptionInterestSearchControlToken'
          ) as CancelTokenSource
        ).token
      )

      if (pageState === undefined) {
        response = await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Task`,
          searchParameters,
          (
            cancelTokenStore.get(
              'subscriptionInterestSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (pageState.length === 0) {
        response = await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Task`,
          searchParameters,
          (
            cancelTokenStore.get(
              'subscriptionInterestSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else {
        const splitUrl = pageState.split('?')[1]
        response = await fhirClient.doGetResource(`?${splitUrl}`)
      }

      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      console.log('-------respDecoded----------', respDecoded)

      if (respDecoded._tag === 'Right') {
        const responseBundle: R4.IBundle = respDecoded.right
        console.log('-------responseBundle----------', responseBundle)
        if (responseBundle.entry) {
          const orders =
            getSubscriptionInterestDetailsFromBundleBundle(responseBundle)

          console.log('-------orders----------', orders)
          const data = [...(existingOrder ?? []), ...orders]

          if (data.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.availableOrders = _.uniqBy(data, 'subscriptionId')
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingOrders = false
            state.count = responseBundle.total ?? 0
            state.nextPageUrl = responseBundle.link?.find(
              (item) => item.relation === 'next'
            )?.url
            state.previousPageUrl = responseBundle.link?.find(
              (item) => item.relation === 'previous'
            )?.url

            dispatch(
              subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
                state
              )
            )
          } else {
            const errorSearchDoctor: SubscriptionInterestListForProviderSearchStatus =
              {
                searchingAppointments: false,
                fetchingExtraOrders: false,
                errorWhileSearchingOrders: false,
                resultsAvailable: false,
                noResultsAvailable: true,
              }
            dispatch(
              subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          const errorSearchDoctor: SubscriptionInterestListForProviderSearchStatus =
            {
              searchingAppointments: false,
              fetchingExtraOrders: false,
              errorWhileSearchingOrders: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
          dispatch(
            subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      }
    } catch (error) {
      console.log('-------respDecoded----------', error)
      logger.error(error)
      const errorSearchDoctor: SubscriptionInterestListForProviderSearchStatus =
        {
          searchingAppointments: false,
          errorWhileSearchingOrders: true,
          resultsAvailable: false,
          fetchingExtraOrders: false,
          errorReason: 'Error while fetching orders',
        }
      dispatch(
        subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

export const getNextPageResults =
  (existingState: SubscriptionInterestListForProviderSearchStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SubscriptionInterestListForProviderSearchStatus = {
      searchingAppointments: true,
      fetchingExtraOrders: false,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(
      subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
        state
      )
    )
    try {
      axios.CancelToken.source()
      if (cancelTokenStore.has('therapiesSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'subscriptionInterestSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('subscriptionInterestSearchControlToken')
      }
      cancelTokenStore.set(
        'subscriptionInterestSearchControlToken',
        axios.CancelToken.source()
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        existingState.nextPageUrl!,

        (
          cancelTokenStore.get(
            'subscriptionInterestSearchControlToken'
          ) as CancelTokenSource
        ).token
      )

      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (respDecoded._tag === 'Right') {
        const responseBundle: R4.IBundle = respDecoded.right

        if (responseBundle.entry) {
          const orders =
            getSubscriptionInterestDetailsFromBundleBundle(responseBundle)

          if (orders.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.availableOrders = orders
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingOrders = false
            state.count = responseBundle.total ?? 0
            state.nextPageUrl = responseBundle.link?.find(
              (item) => item.relation === 'next'
            )?.url
            state.previousPageUrl = responseBundle.link?.find(
              (item) => item.relation === 'previous'
            )?.url

            dispatch(
              subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
                state
              )
            )
          } else {
            const errorSearchDoctor: SubscriptionInterestListForProviderSearchStatus =
              {
                searchingAppointments: false,
                errorWhileSearchingOrders: false,
                resultsAvailable: false,
                fetchingExtraOrders: false,
                noResultsAvailable: true,
              }
            dispatch(
              subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: SubscriptionInterestListForProviderSearchStatus =
        {
          searchingAppointments: false,
          errorWhileSearchingOrders: true,
          fetchingExtraOrders: false,
          resultsAvailable: false,
          errorReason: 'Error while fetching orders',
        }
      dispatch(
        subscriptionInterestListForProviderSearchSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

export default subscriptionInterestListForProviderSearchSlice.reducer
