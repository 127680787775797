import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  ListSubheader,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'

import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
  Add,
  ExpandMore,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import {
  GroupedLabResults,
  GroupedLabResultsByName,
} from 'models/groupedLabResults'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { resetAddLabTestSlice } from 'redux/consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import { requestLabTestDetailsDetailsOfPatient } from 'redux/consultation/labTestOPD/labDetailSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import { cbcpanel } from 'utils/constants'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'

import { AddLabTest } from 'views/components/ipdconusltation/objective/addLabTestResult'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { LabResultTileHistory } from './LabResultsTileForHistory'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface VitalsProp {
  patient: R4.IPatient
  labTest: GroupedLabResultsByName[]
}

export const LabResultsHistory: React.FC<VitalsProp> = ({
  patient,
  labTest,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [groupedLabTest, setGroupedLabTest] = useState<
    GroupedLabResultsByName[]
  >([])
  const [groupedCbcPanel, setGroupedCbcPanel] = useState<GroupedLabResults[]>(
    []
  )

  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )

  const classes = useStyles()
  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.labTestDetailsSliceOPD
  )

  useEffect(() => {
    if (labTest.length > 0) {
      updateLabTest(labTest)
    }
  }, [vitalsDetailsSlice])

  function updateLabTest(labResult: GroupedLabResultsByName[]) {
    const results: GroupedLabResultsByName[] = []
    for (let i = 0; i < labResult.length; i++) {
      results.push({
        date: labResult[i].date,
        obsResults: labResult[i].obsResults,
        checked: true,
      })
    }
    setGroupedLabTest(results)
  }

  function handleCollapse(rate: boolean, index: number) {
    const values: GroupedLabResultsByName[] = [...groupedLabTest]
    values[index].checked = rate
    setGroupedLabTest(values)
  }

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          //   onMouseEnter={() => showAddButton(true)}
          //   onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>
                {' '}
                {t('labelCommon:lab_tests')}{' '}
              </Typography>
            </Box>{' '}
            {/* {isMedicalServiceProvider() && !isPhysiotherapist() && !split && (
                <Box>
                  <IconButton
                    style={{ padding: '4px' }}
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                  >
                    <Add style={{ height: '16px' }} color='primary' />
                  </IconButton>
                </Box>
              )} */}
          </Grid>

          {labTest.length === 0 && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                No data available
              </Typography>
            </Grid>
          )}

          {labTest.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box py={1}>
                {groupedLabTest && groupedLabTest.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedLabTest.map((val, index: number) => (
                        <Box key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Lab Test Results :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapse(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              {val.obsResults.map(
                                (results, indexData: number) => (
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                    key={results.name}
                                  >
                                    <Box
                                      paddingX={1}
                                      borderRadius={2}
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      height={30}
                                      paddingY={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow={1}
                                        alignItems='center'
                                      >
                                        <Typography
                                          variant='subtitle1'
                                          style={{
                                            color: 'black',

                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {results.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Grid
                                      container
                                      direction='row'
                                      id='vitals'
                                      style={{
                                        backgroundColor: kDialogueBackground,
                                      }}
                                    >
                                      <Grid item xs={3}>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Parameter Name
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box
                                            display='flex'
                                            width='2%'
                                            paddingTop={0.1}
                                          >
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </Grid>

                                      <Grid item key={1} xs={5}>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Value
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box
                                            display='flex'
                                            width='2%'
                                            paddingTop={0.1}
                                          >
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </Grid>
                                      <Grid item key={1} xs={4}>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Normal Range
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <LabResultTileHistory
                                      obsData={results.labResults}
                                    />
                                  </Box>
                                )
                              )}
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
