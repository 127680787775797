import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { GroupedOccupation } from 'models/groupedOccupations'
import { GroupedVitals } from 'models/groupedVitals'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getLatestObservations,
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromObserVationDateWise,
  getVitalsData,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { VitalsDetails } from './vitalsDetailStatusType'

const initialState: VitalsDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const vitalsDetailsSlice = createSlice({
  name: 'vitalsDetailsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<VitalsDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.groupedVitals = action.payload.groupedVitals
      state.vitalData = action.payload.vitalData
      state.dateWiseVitals = action.payload.dateWiseVitals
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
    },
  },
})

export const requestVitalDetailsOfPatient =
  (selectedPatient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: VitalsDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(vitalsDetailsSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        patient: `Patient/${selectedPatient.id}`,
        'encounter.appointment': appointmentId,
        category: 'vital-signs',
        status: 'final',
        code: '8310-5,8867-4,8302-2,29463-7,9279-1,85354-9,8480-6,8462-4,39156-5',
        _count: 500,
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Observation',
        appointmentId,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(vitalsDetailsSlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse.entry && proceduresResponse.entry.length > 0) {
          const observationData: R4.IObservation[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IObservation
            ) ?? []

          observationData.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          observationData.sort((a, b) =>
            a.code!.text! > b.code!.text!
              ? 1
              : b.code!.text! > a.code!.text!
              ? -1
              : 0
          )

          const vitalLisDataFinal: VitalsData[] =
            getObserVationFfromObserVationDateWise(
              observationData.sort((a, b) =>
                (a.issued ? a.issued : '') < (b.issued ? b.issued : '')
                  ? -1
                  : (a.issued ?? '') > (b.issued ?? '')
                  ? 1
                  : 0
              )
            )

          const dateWise: DateWiseVitals[] =
            getDateWiseVitals(vitalLisDataFinal)

          dateWise.sort((a, b) => moment(b.date).diff(a.date))

          let observation: any = {}
          for (let i = 0; i < observationData.length; i++) {
            if (observationData[i].component !== undefined) {
              observation = observationData[i]
            }
          }
          if (observationData.length > 0) {
            const serviceId: string = await getServiceRequestId(
              selectedPatient.id ?? ''
            )
            if (serviceId.length > 0) {
              if (observation.component !== undefined) {
                const sysComp: R4.IObservation_Component | undefined =
                  observation.component.find(
                    (val: any) => val.code?.coding?.[0].code === '8480-6'
                  )
                const diaComp: R4.IObservation_Component | undefined =
                  observation.component.find(
                    (val: any) => val.code?.coding?.[0].code === '8462-4'
                  )
                const vitalData: BpCollection = {
                  systolicBp: sysComp ? sysComp.valueQuantity?.value ?? 0 : 0,
                  diastolicBp: diaComp ? diaComp.valueQuantity?.value ?? 0 : 0,
                  serviceRequestId: serviceId,
                }
                const status = await updateEnrolment(vitalData)

                state.resultsAvailable = true
                state.searchingConditions = false
                state.vitalsList = getSortedObservationForIpd(
                  proceduresResponse.entry?.map(
                    (item) => item.resource as R4.IObservation
                  ) ?? []
                )
                state.dateWiseVitals = dateWise
                // state.groupedVitals = groupedOccupationData
                state.noResultsAvailable = false
                state.errorReason = undefined
                state.vitalData = vitalLisDataFinal
                state.errorWhileSearchingProcedures = false
                state.showAppointment = false
                dispatch(vitalsDetailsSlice.actions.updatedStatus(state))
              }
            } else {
              const vitalLisData: R4.IObservation[] =
                proceduresResponse.entry?.map(
                  (item) => item.resource as R4.IObservation
                ) ?? []
              //
              state.dateWiseVitals = dateWise
              //   state.groupedVitals = groupedOccupationData
              state.resultsAvailable = true
              state.searchingConditions = false
              state.vitalsList = getSortedObservationForIpd(vitalLisData)
              state.noResultsAvailable = false
              state.vitalData = vitalLisDataFinal
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(vitalsDetailsSlice.actions.updatedStatus(state))
            }
          } else {
            const errorSearchDoctor: VitalsDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              vitalsDetailsSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: VitalsDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(vitalsDetailsSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: VitalsDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while creating appointment',
        showAppointment: false,
      }
      dispatch(vitalsDetailsSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const resetVitalSearchState = () => (dispatch: AppDispatch) => {
  dispatch(vitalsDetailsSlice.actions.updatedStatus(initialState))
}

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default vitalsDetailsSlice.reducer
