import { R4 } from '@ahryman40k/ts-fhir-types'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { MembershipDetails } from 'models/membershipDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  requestDietTiming,
  resetSearchDietSetting,
} from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import {
  requestQuestionForDoctor,
  resetSearchQuestion,
} from 'redux/appointments/questionSearch/questuionSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  isOnlyUnitAdmin,
} from 'services/userDetailsService'

import {
  addDietTimings,
  updateDietTimingsForDoctor,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { ValueSetSelectMultipleWithSwitchForDietSetting } from 'views/components/common/MultipleDietTimeSettings'

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const DietTimeSettings: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const dietTImeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedMembership, setselectedMembership] =
    useState<MembershipDetails>()
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const [codingData, setCodingData] = React.useState<DietTiming[]>([])
  const [existingQuestionData, setExistingQuestionData] =
    React.useState<R4.IList>()

  function updateDietTime(question: R4.IList, codingArray: DietTiming[]) {
    setUpdateStatus({ requesting: true })

    const oldList: R4.IList = {
      ...question,
    }
    setLoading(true)
    const item: R4.IList_Entry[] = []
    for (let i = 0; i < codingArray.length; i++) {
      if (codingArray[i].time) {
        const extArray: R4.IExtension[] = []
        const codeData: R4.ICoding | undefined = codingArray[i].time
        const ref: R4.IReference = {
          reference: `PractitionerRole/${
            getCurrentUserPractitionerRoleDetails().id
          }`,
        }
        if (codeData) {
          let timeData: string = ''
          let timeFormat: string = ''
          if (codeData.display) {
            if (
              codingArray[i].dietTime === 'Early Morning' ||
              codingArray[i].dietTime === 'Breakfast' ||
              codingArray[i].dietTime === 'Mid Morning'
            ) {
              timeFormat = 'AM'
            } else {
              timeFormat = 'PM'
            }
            timeData = `${codeData.display} ${timeFormat}`
          }

          const extensionData: R4.IExtension = {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext',
            valueTiming: {
              repeat: {
                timeOfDay: [
                  moment(`${timeData}`, ['h:mm A']).format('HH:mm:ss'),
                ],
              },
              code: {
                coding: [
                  {
                    code: codeData.code ?? '',
                    system: 'http://hl7.org/fhir/event-timing',
                    display: codingArray[i].dietTime,
                  },
                ],
                text: codingArray[i].displayLabel,
              },
            },
          }
          extArray.push(extensionData)
          const itemData: R4.IList_Entry = {
            extension: extArray,
            item: ref,
            deleted: codingArray[i].active,
          }
          item.push(itemData)
        }
      } else {
        const extArray: R4.IExtension[] = []
        const codeData: R4.ICoding | undefined = codingArray[i].time
        const ref: R4.IReference = {
          reference: `PractitionerRole/${
            getCurrentUserPractitionerRoleDetails().id
          }`,
        }
        if (codeData) {
          let timeData: string = ''
          let timeFormat: string = ''
          if (codeData.display) {
            if (
              codingArray[i].dietTime === 'Early Morning' ||
              codingArray[i].dietTime === 'Breakfast' ||
              codingArray[i].dietTime === 'Mid Morning'
            ) {
              timeFormat = 'AM'
            } else {
              timeFormat = 'PM'
            }
            timeData = `${codeData.display} ${timeFormat}`
          }

          const extensionData: R4.IExtension = {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext',
            valueTiming: {
              repeat: {
                timeOfDay: [
                  moment(`${timeData}`, ['h:mm A']).format('HH:mm:ss'),
                ],
              },
              code: {
                coding: [
                  {
                    code: codeData.code ?? '',
                    system: 'http://hl7.org/fhir/event-timing',
                    display: codingArray[i].dietTime,
                  },
                ],
                text: codingArray[i].displayLabel,
              },
            },
          }
          extArray.push(extensionData)
          const itemData: R4.IList_Entry = {
            extension: extArray,
            item: ref,
            deleted: codingArray[i].active,
          }
          item.push(itemData)
        }
      }
    }

    oldList.entry = item

    updateDietTimingsForDoctor(oldList).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Diet details updated successfully'))
        dispatch(resetSearchDietSetting())
        dispatch(
          requestDietTiming(getCurrentUserPractitionerDetails().id ?? '')
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating diet timing details. Please try again later'
          )
        )
      }
    })
  }

  function addDietTime(codingArray: DietTiming[]) {
    setUpdateStatus({ requesting: true })
    const practRef: R4.IReference = {
      reference: `Practitioner/${getCurrentUserPractitionerDetails().id}`,
    }
    const oldProcedure: R4.IList = {
      resourceType: 'List',
      title: 'Diet Timings',
      code: {
        coding: [
          {
            code: '272106006',
            system: 'http://snomed.info/sct',
            display: 'Times of day',
          },
        ],
      },
    }
    setLoading(true)
    const item: R4.IList_Entry[] = []
    for (let i = 0; i < codingArray.length; i++) {
      if (codingArray[i].time) {
        const extArray: R4.IExtension[] = []
        const codeData: R4.ICoding | undefined = codingArray[i].time
        const ref: R4.IReference = {
          reference: `PractitionerRole/${
            getCurrentUserPractitionerRoleDetails().id
          }`,
        }
        if (codeData) {
          let timeData: string = ''
          let timeFormat: string = ''
          if (codeData.display) {
            if (
              codingArray[i].dietTime === 'Early Morning' ||
              codingArray[i].dietTime === 'Breakfast' ||
              codingArray[i].dietTime === 'Mid Morning'
            ) {
              timeFormat = 'AM'
            } else {
              timeFormat = 'PM'
            }
            timeData = `${codeData.display} ${timeFormat}`
          }

          const extensionData: R4.IExtension = {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext',
            valueTiming: {
              repeat: {
                timeOfDay: [
                  moment(`${timeData}`, ['h:mm A']).format('HH:mm:ss'),
                ],
              },
              code: {
                coding: [
                  {
                    code: codeData.code ?? '',
                    system: 'http://hl7.org/fhir/event-timing',
                    display: codingArray[i].dietTime,
                  },
                ],
                text: codingArray[i].displayLabel,
              },
            },
          }
          extArray.push(extensionData)
          const itemData: R4.IList_Entry = {
            extension: extArray,
            item: ref,
            deleted: codingArray[i].active,
          }
          item.push(itemData)
        }
      }
    }

    oldProcedure.entry = item

    if (isOnlyUnitAdmin() === false) oldProcedure.source = practRef

    console.log(oldProcedure)

    addDietTimings(oldProcedure).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Diet timing details added successfully'))
        dispatch(resetSearchDietSetting())
        dispatch(
          requestDietTiming(getCurrentUserPractitionerDetails().id ?? '')
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while adding diet details. Please try again later'
          )
        )
      }
    })
  }

  function checkSaveButton() {
    console.log(codingData)
    const trueData: string[] = []
    const falseData: string[] = []
    const data: string[] = []
    if (codingData.length === 0) {
      data.push('1')
    }
    if (codingData.length > 0) {
      for (let i = 0; i < codingData.length; i++) {
        if (codingData[i].time === undefined && codingData[i].active === true) {
          data.push('1')
        } else {
          const codingDataVal: R4.ICoding | undefined = codingData[i].time
          if (codingDataVal && codingData[i].active === true) {
            const timeData = codingDataVal.display ?? ''
            if (timeData.length === 0) {
              data.push('1')
            }
          }
        }

        if (dietTImeSearchSlice.prSelectedCoding) {
          if (codingData[i].isEdit === true) {
            trueData.push('1')
          } else {
            falseData.push('1')
          }
        }
      }
    }
    if (dietTImeSearchSlice.prSelectedCoding) {
      if (trueData.length === 0) {
        data.push('1')
      }
    }

    return data.length
  }

  useEffect(() => {
    dispatch(requestDietTiming(getCurrentUserPractitionerDetails().id ?? ''))
  }, [dispatch])

  useEffect(() => {
    if (
      dietTImeSearchSlice.resultsAvailable &&
      dietTImeSearchSlice.question &&
      dietTImeSearchSlice.prSelectedCoding
    )
      setCodingData(dietTImeSearchSlice.prSelectedCoding ?? [])
  }, [dietTImeSearchSlice])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        width: '100%',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            px={1}
            bgcolor={kDialogueBackground}
            py={0.5}
          >
            <Box
              width='20%'
              height={35}
              px={1}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <Typography variant='body1' color='initial'>
                Diet Type
              </Typography>
            </Box>
            <Box
              width='20%'
              height={35}
              px={1}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <Typography variant='body1' color='initial'>
                Disable/Enable
              </Typography>
            </Box>
            <Box
              width='20%'
              height={35}
              px={1}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <Typography variant='body1' color='initial'>
                Time
              </Typography>
            </Box>
            <Box
              width='20%'
              height={35}
              px={1}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <Typography variant='body1' color='initial'>
                Custom Display Label
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            style={{ width: '100%', height: '2px' }}
            color='primary'
          />
          {dietTImeSearchSlice.searchingAppointments && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexGrow={4}
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
            paddingTop={0.5}
          >
            {dietTImeSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height='100%'
                style={{ backgroundColor: 'white' }}

                // flexGrow={1}
              >
                <ValueSetSelectMultipleWithSwitchForDietSetting
                  id='question'
                  onOptionSelected={(selectedKriya: DietTiming[]) => {
                    setCodingData(_.uniqBy(selectedKriya, 'dietTime'))
                  }}
                />
                {codingData.length > 0 && loading === false && (
                  <Box width='20%' paddingLeft={1.5}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={checkSaveButton() > 0}
                      onClick={() => {
                        addDietTime(codingData)
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {dietTImeSearchSlice.errorWhileSearchingAppointments && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching diet settings.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {dietTImeSearchSlice.question &&
              dietTImeSearchSlice.prSelectedCoding &&
              codingData.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  height='100%'
                  style={{ backgroundColor: 'white' }}

                  // flexGrow={1}
                >
                  <ValueSetSelectMultipleWithSwitchForDietSetting
                    id='question'
                    onOptionSelected={(selectedKriya: DietTiming[]) => {
                      setCodingData(_.uniqBy(selectedKriya, 'dietTime'))
                    }}
                    preSelectedOptions={dietTImeSearchSlice.prSelectedCoding}
                  />

                  <Box
                    width='20%'
                    paddingLeft={1.5}
                    display='flex'
                    flexDirection='row'
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={checkSaveButton() > 0}
                      onClick={() => {
                        setCodingData([])
                        if (dietTImeSearchSlice.question) {
                          updateDietTime(
                            dietTImeSearchSlice.question,
                            codingData
                          )
                        }
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={checkSaveButton() > 0}
                      onClick={() => {
                        dispatch(
                          requestDietTiming(
                            getCurrentUserPractitionerDetails().id ?? ''
                          )
                        )
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
