/* eslint-disable import/no-named-as-default */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core'

import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'

import React, { useState } from 'react'
import { titleCase } from 'utils/fhirResourcesHelper'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface WelloSelectCodingProps {
  title?: string
  id?: string
  placeHolder?: string
  availableCodings: R4.ICoding[]
  onChanged: (selectedCoding: R4.ICoding) => void
  preSelectedCoding?: R4.ICoding
  role?: string
  disabled?: boolean
}

export const WelloSelectFHIRCodingWithAutocomplete: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    title,
    id,
    placeHolder,
    availableCodings,
    onChanged,
    preSelectedCoding,
    disabled,
  }: WelloSelectCodingProps) => {
    console.log(preSelectedCoding)
    return (
      <Box display='flex' flexDirection='column' width='100%'>
        {title && <WelloFormItemLabel title={title} />}
        <Box display='flex' flexDirection='column' width='100%'>
          <Autocomplete
            style={{
              borderRadius: '4px',
            }}
            id={id}
            fullWidth
            size='small'
            componentName='unit'
            defaultValue={preSelectedCoding}
            autoFocus
            disableClearable
            disabled={disabled}
            multiple={false}
            getOptionSelected={(option, valueData) =>
              option.display === valueData.display
            }
            getOptionLabel={(option) => option.display ?? ''}
            options={availableCodings}
            onChange={(e, type, reason) => {
              if (type) {
                onChanged(type)
              }
            }}
            autoComplete
            ChipProps={{
              deleteIcon: (
                <ClearOutlined
                  style={{
                    height: '15px',
                    color: 'white',
                  }}
                />
              ),
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option) => (
              <Typography variant='subtitle2'>{option.display}</Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeHolder || 'Select'}
                fullWidth
                variant='outlined'
                id={id}
                size='small'
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </Box>
      </Box>
    )
  }
