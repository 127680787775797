import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Language } from 'models/language'
import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { locales } from 'utils/constants'
// import * as locales from '@material-ui/core/locale'
import { AppBarTab } from './AppBarTab'

export interface TabProps {
  preSelectedTab?: string
  onTabChange: (selectedTab: string) => void
  menu: TabDetail[]
  totalCount?: number
  isOrdering?: boolean
}

export interface TabDetail {
  name: string

  imgSrc: ReactNode
  count?: number
  priority: string

  position?: 'left' | 'center' | 'right'
  displayCount?: boolean
  countDisplayType?: 'badge' | 'text'
  displayName?: boolean
  id?: string
}

export const AppBarTabs: React.FC<TabProps> = ({
  preSelectedTab,
  onTabChange,
  menu,
  totalCount,
  isOrdering,
}: TabProps) => {
  const [selectedTab, setSelectedTab] = React.useState(preSelectedTab)
  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')
  const langInfo: string | null = sessionStorage.getItem('langObject')
  const [locale, setLocale] = React.useState<Language>(
    langInfo != null
      ? JSON.parse(langInfo)
      : {
          country: 'US',
          language: 'en',
          display: 'English',
        }
  )

  const rightTabDetails: TabDetail[] = menu.filter(
    (val) => val.position === 'right'
  )
  const centerTabDetails: TabDetail[] = menu.filter(
    (val) => val.position === 'center'
  )
  const leftTabDetails: TabDetail[] = menu.filter(
    (val) => val.position === 'left'
  )

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
  }, [])

  const handleChange = (newSelectedTab: string) => {
    onTabChange(newSelectedTab)
    setSelectedTab(newSelectedTab)
  }

  return (
    <Box display='flex' flexDirection='row' flexGrow={1}>
      <Box display='flex' flexDirection='row' px={1}>
        {leftTabDetails.map((value, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`app_tab_left_${i}_${value.name}`} paddingX={1}>
            <AppBarTab
              imgSrc={value.imgSrc}
              textTitle={t('labelCommon:' + `${value.name}`)}
              isSelected={
                t('labelCommon:' + `${selectedTab}`) ===
                t('labelCommon:' + `${value.name}`)
              }
              count={value.count}
              onClick={() => handleChange(value.name)}
              displayCount={value.displayCount}
              displayName={value.displayName}
              countDisplayType={value.countDisplayType}
              id={value.id}
            />
          </Box>
        ))}
      </Box>
      <Box
        flexGrow={1}
        flexDirection='row'
        display='flex'
        px={1}
        justifyContent='center'
      >
        {centerTabDetails.map((value, i) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`app_tab_center_${i}_${value.name}`}
            flexShrink={1}
            paddingX={1}
          >
            <AppBarTab
              imgSrc={value.imgSrc}
              textTitle={t('labelCommon:' + `${value.name}`)}
              isSelected={
                t('labelCommon:' + `${selectedTab}`) ===
                t('labelCommon:' + `${value.name}`)
              }
              count={value.count}
              onClick={() => handleChange(value.name)}
              displayCount={value.displayCount}
              displayName={value.displayName}
              countDisplayType={value.countDisplayType}
              id={value.id}
            />
          </Box>
        ))}
      </Box>
      <Box flexDirection='row' display='flex' px={1}>
        {isOrdering === undefined && (
          <Box>
            <Autocomplete
              options={locales}
              getOptionSelected={(option, value) =>
                option.display === value.display
              }
              size='small'
              style={{ width: 150, height: 20 }}
              value={locale}
              getOptionLabel={(option) => option.display}
              disableClearable
              onChange={(event, newValue) => {
                if (i18n.language === 'hi') {
                  i18n.changeLanguage(newValue.language)
                  sessionStorage.setItem('langObject', JSON.stringify(newValue))
                  sessionStorage.setItem('lang', i18n.language)
                } else {
                  sessionStorage.setItem('langObject', JSON.stringify(newValue))
                  i18n.changeLanguage(newValue.language)
                  sessionStorage.setItem('lang', i18n.language)
                }
                setLocale(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  variant='outlined'
                  style={{ height: 20 }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    color: 'primary',
                  }}
                />
              )}
            />
          </Box>
        )}

        {rightTabDetails.map((value, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`app_tab_right_${i}_${value.name}`} paddingX={1}>
            <AppBarTab
              imgSrc={value.imgSrc}
              textTitle={t('labelCommon:' + `${value.name}`)}
              isSelected={
                t('labelCommon:' + `${selectedTab}`) ===
                t('labelCommon:' + `${value.name}`)
              }
              count={value.count}
              onClick={() => handleChange(value.name)}
              displayCount={value.displayCount}
              displayName={value.displayName}
              countDisplayType={value.countDisplayType}
              id={value.id}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
