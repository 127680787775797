import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItem,
  Typography,
  useTheme,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'

import { getGoalDescription } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { kBackgroundPaper, kPrimaryMain } from 'configs/styles/muiThemes'
import Menu from '@mui/material/Menu'
import { List } from '@mantine/core'
import { getSectionsInCDSCards } from 'utils/careplan_utils/cpg_recommendations_utils'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import _ from 'lodash'
import { SubscriptionOfferingLineWiseDetail } from './planDetails'
import { GoalDetailsOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/goalDetailsOfRecommendation'
import { ConditionsOFCpgRecommendation } from '../cdsRecommendations/recommendationsDetails/conditionsOfCPGRecommendation'
import { ReferralsOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/referralsOfCPGRecommendation'
import { LabTestsOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/labTestOfCPGRecommendation'
import { MedicationsOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/medicationsOfCPGRecommendation'
import { KriyasOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/kriyasOfCPGRecommendation'
import { CarePlanInstructionsOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/carePlanInstrunctionsOfCPGRecommendation'
import { CarePlanActivitiesOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/carePlanActivitiesOfCPGRecommendations'
import { CareTeamFollowUpsOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/careTeamFollowUpOfCPGRecommendation'
import { SummaryOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/summaryCPGOfRecommendation'

import { DietPlanOfCPGRecommendation } from '../cdsRecommendations/recommendationsDetails/dietPlanOfRecommendation'

export interface SubscriptionOfferingDetailProps {
  offer: CarePlanSubscriptionPlan
  id: string
  key: string
  onSelectionChanged?: (selectedSection: string) => void
  onSelectedDurationChanged?: (duration: R4.IChargeItemDefinition) => void
  onRequestFinished?: () => void
}

export const SubscriptionOfferingDetail: React.FC<SubscriptionOfferingDetailProps> =
  ({
    id,
    offer,
    key,
    onSelectionChanged,
    onSelectedDurationChanged,
    onRequestFinished,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation(['common'])
    const disapatch = useDispatch()
    const [selectedSectionDetails, setSelectedSectionDetails] =
      useState('subscriptionPlan')

    const visitContextDetails = useContext(VisitAppointmentContext)

    const [menus, SetMenus] = useState([
      {
        name: 'subscriptionPlan',
        label: 'Subscription Plan',
      },
    ])

    const cpgRecommendationsState = useSelector(
      (state: RootState) => state.cpgRecommendationsSlice
    )

    const patientSubscriptionForUnitSlice = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )
    const [recommendation, setRecommendation] = useState<CardsEntity>()

    useEffect(() => {
      if (
        cpgRecommendationsState.resultsAvailable &&
        cpgRecommendationsState.recommendationsList &&
        cpgRecommendationsState.recommendationsList.length > 0
      ) {
        const selectedRecommendation =
          cpgRecommendationsState.recommendationsList.find((rec) =>
            (offer.rawCpg.library ?? []).includes(rec.cpgUrlIdentifier)
          )
        if (
          selectedRecommendation !== undefined &&
          selectedRecommendation.recommendation?.cards !== undefined &&
          selectedRecommendation.recommendation?.cards !== null
        ) {
          const card = selectedRecommendation.recommendation?.cards[0]
          setRecommendation(card)
        }
      }
    }, [
      cpgRecommendationsState.resultsAvailable,
      cpgRecommendationsState.recommendationsList,
    ])

    useEffect(() => {
      if (recommendation) {
        SetMenus(
          _.uniqBy([...getSectionsInCDSCards(recommendation), ...menus], 'name')
        )
      }
    }, [recommendation])

    return (
      <Box display='flex' flexDirection='column' width='100%' id={id} key={key}>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box
            width='20%'
            minWidth='100px'
            style={{
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            {menus.map((menu) => (
              <Box
                p={1}
                key={menu.name}
                id={menu.name}
                border={0.5}
                borderColor={theme.palette.divider}
                style={{
                  backgroundColor:
                    selectedSectionDetails === menu.name
                      ? kPrimaryMain
                      : 'transparent',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedSectionDetails(menu.name)
                  if (onSelectionChanged) {
                    onSelectionChanged(menu.name)
                  }
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color:
                      selectedSectionDetails === menu.name
                        ? 'white'
                        : undefined,
                  }}
                >
                  {menu.label}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            width='80%'
            style={{
              backgroundColor: kBackgroundPaper,
            }}
          >
            <Box display='flex' flexDirection='row' width='100%' height='5px'>
              {cpgRecommendationsState.fetchingRecommendations && (
                <LinearProgress
                  color='primary'
                  style={{
                    height: '5px',
                    width: '100%',
                  }}
                />
              )}
            </Box>
            <Box
              width='100%'
              px={1}
              pb={1}
              style={{
                overflowY: 'auto',
                maxHeight: 'calc(50vh)',
              }}
            >
              {recommendation && selectedSectionDetails === 'labTests' && (
                <LabTestsOfCPGRecommendation
                  cdsCard={recommendation}
                  carePlanIdentifier={offer.rawCpg.name ?? ''}
                  encounterId={visitContextDetails.encounter?.id!}
                  patient={visitContextDetails.patient!}
                  onRequestFinished={() => {
                    if (onRequestFinished) {
                      onRequestFinished()
                    }
                  }}
                />
              )}
              {recommendation && selectedSectionDetails === 'medications' && (
                <MedicationsOfCPGRecommendation
                  cdsCard={recommendation}
                  carePlanIdentifier={offer.rawCpg.name ?? ''}
                />
              )}
              {recommendation && selectedSectionDetails === 'kriyas' && (
                <KriyasOfCPGRecommendation
                  cdsCard={recommendation}
                  carePlanIdentifier={offer.rawCpg.name ?? ''}
                  encounterId={visitContextDetails.encounter?.id!}
                  patient={visitContextDetails.patient!}
                />
              )}

              {recommendation && selectedSectionDetails === 'dietPlan' && (
                <DietPlanOfCPGRecommendation
                  cdsCard={recommendation}
                  carePlanIdentifier={offer.rawCpg.name ?? ''}
                  encounterId={visitContextDetails.encounter?.id!}
                  patient={visitContextDetails.patient!}
                />
              )}

              {recommendation && selectedSectionDetails === 'instructions' && (
                <CarePlanInstructionsOfCPGRecommendation
                  cdsCard={recommendation}
                  carePlanIdentifier={offer.rawCpg.name ?? ''}
                />
              )}

              {recommendation &&
                selectedSectionDetails === 'carePlanActivities' && (
                  <CarePlanActivitiesOfCPGRecommendation
                    cdsCard={recommendation}
                    carePlanIdentifier={offer.rawCpg.name ?? ''}
                  />
                )}
              {recommendation &&
                selectedSectionDetails === 'careTeamFollowUps' && (
                  <CareTeamFollowUpsOfCPGRecommendation
                    cdsCard={recommendation}
                    carePlanIdentifier={offer.rawCpg.name ?? ''}
                  />
                )}

              {recommendation &&
                selectedSectionDetails === 'specialistReferral' && (
                  <ReferralsOfCPGRecommendation
                    cdsCard={recommendation}
                    carePlanIdentifier={offer.rawCpg.name ?? ''}
                    encounterId={visitContextDetails.encounter?.id!}
                    patient={visitContextDetails.patient!}
                  />
                )}
              {recommendation && selectedSectionDetails === 'conditions' && (
                <ConditionsOFCpgRecommendation cdsCard={recommendation} />
              )}
              {recommendation && selectedSectionDetails === 'summary' && (
                <SummaryOfCPGRecommendation cdsCard={recommendation} />
              )}
              {recommendation && selectedSectionDetails === 'goals' && (
                <GoalDetailsOfCPGRecommendation cdsCard={recommendation} />
              )}
              {selectedSectionDetails === 'subscriptionPlan' && (
                <SubscriptionOfferingLineWiseDetail
                  offer={offer}
                  onPaymentPlanSelected={onSelectedDurationChanged}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
