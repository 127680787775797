/* eslint-disable react/jsx-key */
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getCurrentUserUnitDetails,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import { AppointmentRate } from './appointmentRate'
import { DayCareRateSearch } from './DayCareRateSearch'
import { IpdRoomRateSearch } from './IPDRoomRateSearch'

export const ServicePriceSelector: any = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const menu =
    loggedInUnitType === 'Clinic' ||
    (loggedInUnitType === 'Wellness Center' &&
      sessionStorage.getItem('app') !== null)
      ? ['OPD']
      : ['IPD', 'Daycare', 'OPD']
  const [selectedTab, setSelectedTab] = React.useState(
    loggedInUnitType === 'Wellness Center' &&
      sessionStorage.getItem('app') === null
      ? menu[0]
      : menu[0]
  )

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      width='100%'
      height='100%'
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={kDialogueBackground}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box p={3}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Box display='flex' paddingX={2} paddingY={1}>
                            <Avatar alt={props.unitOrgDetail.name} src='test.jpg' />
                          </Box> */}

                    <Grid item xs>
                      <Box display='flex' flexDirection='Column' height='50%'>
                        <Box paddingX={0.5}>
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            style={{
                              textTransform: 'uppercase',
                              fontFamily: 'Open Sans',

                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;Price
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        height='100%'
        width='100%'
        style={{
          backgroundColor: '#FFFFFFAF',
        }}
      >
        <Box width='100%'>
          <Card
            elevation={0}
            style={{
              padding: 0,
              margin: 0,
              borderRadius: 8,
              //   backgroundColor: '#FFFFFFAF',
            }}
          >
            <CardContent
              style={{
                paddingRight: 16,
                border: 1,
              }}
            >
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  justifyContent='space-between'
                  alignContent='center'
                  alignItems='center'
                >
                  <Box marginLeft={2}>
                    <WelloTabs
                      preSelectedTab={t('labelCommon:' + `${selectedTab}`)}
                      onTabChange={(e) => handleChangeTab(e)}
                      menu={menu}
                    />
                  </Box>
                </Box>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%', height: '2px' }}
                  color='primary'
                />
                {(loggedInUnitType !== 'Wellness Center' ||
                  (loggedInUnitType === 'Wellness Center' &&
                    sessionStorage.getItem('app') !== null)) && (
                  <Box>
                    {selectedTab === menu[0] && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <AppointmentRate onTap={() => {}} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {loggedInUnitType === 'Wellness Center' &&
                  sessionStorage.getItem('app') === null && (
                    <Box>
                      {selectedTab === menu[2] && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <AppointmentRate onTap={() => {}} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {selectedTab === menu[0] &&
                        isCurrentUnitIsWellonessCenter() &&
                        sessionStorage.getItem('app') === null && (
                          <Box>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                  >
                                    <IpdRoomRateSearch />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )}

                      {selectedTab === menu[1] &&
                        isCurrentUnitIsWellonessCenter() &&
                        sessionStorage.getItem('app') === null && (
                          <Box>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                  >
                                    <DayCareRateSearch />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )}
                    </Box>
                  )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}
