import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useTheme,
  Box,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  continueButtonClicked,
  disableButtonClicked,
  onDiscountSelected,
  onDiscountTypeSelected,
  onFollowupTypeChanged,
  onPatientSelected,
  onSlotSelected,
  requestAppointment,
  resetFollowupAppointmentState,
  resetAppointmentStateForModifySlot,
} from 'redux/appointments/followupManager/followAappointmentManagerSlice'
import {
  ACTIONS,
  FollowupAppointmentManagementStatus,
} from 'redux/appointments/followupManager/followupAppointmentManagerStatus'
import { resetSlotSelectionStatus } from 'redux/appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  availablePaymentTypes,
  availablePaymentTypesRemovedOnline,
} from 'utils/constants'
import {
  validateLastName,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateName,
} from 'utils/formValidators'
import { WelloLoadingIndicator } from 'wello-web-components'
import { PatientSelectorFollowup } from './patientSelectorFollowUp'
import { ProviderSlotSelectorFollowUp } from './providerSlotSelectorFollowup'
import { ProviderSlotSelectorNew } from './providerSlotSelector_new'
import { PatientSelector } from './selectPateint'

interface Props {
  open: boolean
  onClose: () => void
  onAppointmentCreated: (
    createdAppointment: R4.IAppointment | undefined
  ) => void
  preSelectedDoctor?: PractitionerWithRole
  preSelectedSlotStartTime?: Date
  fhirAppointmentDetail?: FhirAppointmentDetail

  followUp?: boolean
  preDefinedAllowed?: boolean
}
export const CreateFollowupAppointmentManager: React.FC<Props> = ({
  open,
  onClose,
  onAppointmentCreated,
  preSelectedDoctor,
  preSelectedSlotStartTime,
  fhirAppointmentDetail,
  followUp,
  preDefinedAllowed,
}) => {
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.followupAppointmentManagerSlice
  )

  const dispatch = useDispatch()
  const selectedAppointmentType = useRef('')

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '56%',
    },
  }))
  const { t } = useTranslation()
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)
  const selecctedPaymentType = useRef('cash')

  const classes = useStyles()
  useEffect(() => {
    if (appointmentManagerSlice.appointmentCreatedSuccessfully) {
      if (onAppointmentCreated) {
        onAppointmentCreated(
          appointmentManagerSlice.createdAppointment ?? undefined
        )
      }
    }
  }, [appointmentManagerSlice, onAppointmentCreated])

  /* useEffect(() => {
    if (preSelectedPatient) {
      dispatch(onPatientSelected(appointmentManagerSlice, preSelectedPatient))
    }
  }, []) */

  function enableContinueButton(
    existingStatus: FollowupAppointmentManagementStatus
  ) {
    console.log(existingStatus)
    const data: string[] = []

    if (existingStatus.currentAction === ACTIONS.discountSelection) {
      if (existingStatus.followupType) {
        if (existingStatus.followupType === 'paid') {
          if (existingStatus.paymentType) {
            if (existingStatus.amount || existingStatus.percentage) {
            } else {
              {
                data.push('1')
              }
            }
          } else {
            // data.push('1')
          }
        }
        if (existingStatus.followupType === 'free') {
          if (existingStatus.paymentType) {
            if (existingStatus.amount || existingStatus.percentage) {
            } else {
              {
                data.push('1')
              }
            }
          } else {
            // data.push('1')
          }
        }
      }
    }
    if (existingStatus.currentAction === ACTIONS.PatientSelection) {
      if (existingStatus.selectedPatient === undefined) {
        data.push('1')
      }
      if (existingStatus.selectedSlot === undefined) {
        data.push('1')
      }
    }

    return data.length
  }

  function requestForAppointment() {
    if (
      appointmentManagerSlice.selectedSlot &&
      appointmentManagerSlice.selectedPatient
    )
      dispatch(
        requestAppointment(
          appointmentManagerSlice,
          appointmentManagerSlice.selectedSlot,
          appointmentManagerSlice.selectedPatient,
          selectedAppointmentType.current,
          selecctedPaymentType.current === 'online',

          appointmentManagerSlice.question
        )
      )
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
          ? appointmentManagerSlice.followupAllowed === undefined
            ? `${t('labelCommon:new_patient_app')}`
            : t('labelCommon:followup_patient_app')
          : appointmentManagerSlice.followupAllowed === undefined
          ? `${t('labelCommon:new_appointment_title')}`
          : appointmentManagerSlice.currentAction === ACTIONS.discountSelection
          ? 'Follow up Appointment > Discount'
          : 'Follow up Appointment'}
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          overflow: 'hidden',
          margin: 0,
        }}
      >
        {appointmentManagerSlice.creatingAppointment && (
          <WelloLoadingIndicator message='Requesting for Appointment ' />
        )}

        {appointmentManagerSlice.decidingAction && <WelloLoadingIndicator />}

        {appointmentManagerSlice.currentAction === ACTIONS.SlotSelection &&
          !appointmentManagerSlice.creatingAppointment &&
          !appointmentManagerSlice.decidingAction && (
            <ProviderSlotSelectorFollowUp
              onSlotSelected={(
                appointmentType: string,
                selectedSlot?: FhirSlotDetail,
                question?: R4.IQuestionnaire
              ) => {
                if (selectedSlot) {
                  selectedAppointmentType.current = appointmentType

                  dispatch(
                    onSlotSelected(
                      appointmentManagerSlice,
                      selectedSlot,
                      preSelectedDoctor
                        ? preSelectedDoctor.practitionerObject
                          ? preSelectedDoctor.practitionerObject.id ?? ''
                          : ''
                        : '',
                      appointmentManagerSlice.selectedPatient,

                      question,
                      preDefinedAllowed,
                      fhirAppointmentDetail
                    )
                  )
                } else {
                  dispatch(disableButtonClicked(appointmentManagerSlice))
                }
              }}
              preSelectedDoctor={preSelectedDoctor}
              preSelectedSlotStartTime={preSelectedSlotStartTime}
              fhirAppointmentDetail={fhirAppointmentDetail}
              followUp={followUp}
              preDefinedAllowed={preDefinedAllowed}
            />
          )}
        <Box
          px={10}
          display='flex'
          flexDirection='column'
          marginTop={1}
          marginBottom={2}
          width='100%'
        >
          {(appointmentManagerSlice.currentAction ===
            ACTIONS.PatientSelection ||
            appointmentManagerSlice.currentAction ===
              ACTIONS.discountSelection) &&
            !appointmentManagerSlice.decidingAction &&
            appointmentManagerSlice.selectedSlot &&
            !appointmentManagerSlice.creatingAppointment && (
              <PatientSelectorFollowup
                selectedServiceType={selectedAppointmentType.current}
                selectedSlot={appointmentManagerSlice.selectedSlot}
                paymentType={appointmentManagerSlice.paymentType}
                percentData={appointmentManagerSlice.percentage}
                amountData={appointmentManagerSlice.amount}
                followUpType={appointmentManagerSlice.followupType}
                patient={
                  appointmentManagerSlice.selectedPatient ||
                  fhirAppointmentDetail?.patient
                }
                onPatientSelected={(selectedPatient) => {
                  dispatch(
                    onPatientSelected(
                      appointmentManagerSlice,
                      true,
                      selectedPatient,
                      preSelectedDoctor
                        ? preSelectedDoctor.practitionerObject
                          ? preSelectedDoctor.practitionerObject.id ?? ''
                          : ''
                        : ''
                    )
                  )
                }}
                onPaymentSelected={(selectedPaymentType) => {
                  selecctedPaymentType.current = selectedPaymentType
                }}
                onClickOnModfifyPatient={(modified, selectedPatient) => {
                  dispatch(
                    onPatientSelected(
                      appointmentManagerSlice,
                      modified,
                      undefined
                    )
                  )
                }}
                onClickModifySlot={(
                  selectedSlot: FhirSlotDetail,
                  patient?: R4.IPatient
                ) => {
                  dispatch(
                    resetAppointmentStateForModifySlot(
                      appointmentManagerSlice,
                      patient || appointmentManagerSlice.selectedPatient,
                      appointmentManagerSlice.followupAllowed
                    )
                  )
                }}
                onDiscountTypeSelected={(
                  existingStatus: FollowupAppointmentManagementStatus,
                  paymentType: R4.ICoding
                ) => {
                  dispatch(onDiscountTypeSelected(existingStatus, paymentType))
                }}
                onClickFollowupType={(
                  existingStatus: FollowupAppointmentManagementStatus,
                  followupType: string
                ) => {
                  dispatch(onFollowupTypeChanged(existingStatus, followupType))
                }}
                onDiscountChanged={(
                  existingStatus: FollowupAppointmentManagementStatus,
                  amount: number
                ) => {
                  dispatch(onDiscountSelected(existingStatus, amount))
                }}
                fhirAppointmentDetail={appointmentManagerSlice.followupAllowed}
                followUp={followUp}
                preDefinedAllowed={preDefinedAllowed}
              />
            )}
          {appointmentManagerSlice.selectedPatient &&
            appointmentManagerSlice.followupAllowed === undefined && (
              <Box width='100%' py={1} paddingLeft={3}>
                <Typography variant='subtitle2' color='error'>
                  Follow up not allowed
                </Typography>
              </Box>
            )}
        </Box>
      </DialogContent>
      <DialogActions
        style={{ background: useTheme().palette.background.default }}
      >
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          paddingRight={
            appointmentManagerSlice.currentAction ===
              ACTIONS.PatientSelection ||
            appointmentManagerSlice.currentAction === ACTIONS.discountSelection
              ? 9.5
              : 0
          }
        >
          <Button
            onClick={() => {
              dispatch(resetFollowupAppointmentState())
              dispatch(resetSlotSelectionStatus())
              onClose()
            }}
            id='cancel'
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>

          {appointmentManagerSlice.enableContinueButton}

          <Button
            onClick={() => {
              if (
                appointmentManagerSlice.currentAction ===
                  ACTIONS.PatientSelection ||
                appointmentManagerSlice.currentAction ===
                  ACTIONS.discountSelection
              ) {
                requestForAppointment()
              } else {
                dispatch(
                  continueButtonClicked(
                    appointmentManagerSlice,
                    fhirAppointmentDetail?.patient
                  )
                )
              }
            }}
            id={
              appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
                ? 'create'
                : 'continue'
            }
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              !appointmentManagerSlice.selectedSlot ||
              enableContinueButton(appointmentManagerSlice) > 0
            }
          >
            {appointmentManagerSlice.currentAction ===
              ACTIONS.PatientSelection ||
            appointmentManagerSlice.currentAction === ACTIONS.discountSelection
              ? t('labelCommon:create_appointment')
              : t('labelCommon:continue')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
