import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PhysiotherapyDataUpdate } from 'models/tables/physiotherapyDataUpdate'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCodeOfSystemFromCodableConceptArray,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getPriceComponentFromChangeItem } from 'utils/fhirResoureHelpers/fhirSlotHelper'
import { logger } from 'utils/logger'
import { PhysiotherapyTableSearchStatus } from './physiotherapyTableSearchStatus'

const initialState: PhysiotherapyTableSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const physiotherapyTableSearchSlice = createSlice({
  name: 'physiotherapyTableSearchSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<PhysiotherapyTableSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.therapyList = action.payload.therapyList
      state.therapyListWithPrice = action.payload.therapyListWithPrice
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllPhysiotherapist =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PhysiotherapyTableSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(physiotherapyTableSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/HealthcareService?service-category=304,305&_count=200&_include=HealthcareService:healthcare-service-billing`
      )

      if (response._tag === 'Left') {
        const errorSearchPhysiotherapy: PhysiotherapyTableSearchStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: true,
          resultsAvailable: false,
          errorReason: 'Error while searching Physiotherapies',
        }
        dispatch(
          physiotherapyTableSearchSlice.actions.updatedStatus(
            errorSearchPhysiotherapy
          )
        )

        dispatch(physiotherapyTableSearchSlice.actions.updatedStatus(state))
      } else {
        const resp: R4.IBundle = response as R4.IBundle
        //

        const therapyDataList: R4.IHealthcareService[] =
          resp.entry?.map((item) => item.resource as R4.IHealthcareService) ??
          []

        const physiotherapyDataListWithPrice: PhysiotherapyDataUpdate[] =
          getPhysiotherapyListWithChargeItem(resp)

        physiotherapyDataListWithPrice.sort((a, b) =>
          a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
        )

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.therapyList = therapyDataList
        state.therapyListWithPrice = physiotherapyDataListWithPrice
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(physiotherapyTableSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchPhysiotherapy: PhysiotherapyTableSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Physiotherapies',
      }
      dispatch(
        physiotherapyTableSearchSlice.actions.updatedStatus(
          errorSearchPhysiotherapy
        )
      )
    }
  }

export function getPhysiotherapyListWithChargeItem(
  responseBundle: R4.IBundle
): PhysiotherapyDataUpdate[] {
  const convertedAppointments: PhysiotherapyDataUpdate[] = []
  const healthService: any = {}
  const chargeDdef: any = {}
  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'HealthcareService':
                  healthService[value.resource.id] =
                    value.resource as R4.IHealthcareService
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })
        for (const key in healthService) {
          if (key) {
            const currentAppointment: R4.IHealthcareService = healthService[
              key
            ] as R4.IHealthcareService
            let payment: number = 0
            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                currentAppointment.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.split('/')[1]
            const chargeDdefData: R4.IChargeItemDefinition =
              chargeDdef[chargeID ?? '']
            if (chargeDdefData) {
              const priceComponent:
                | R4.IChargeItemDefinition_PriceComponent
                | undefined = getPriceComponentFromChangeItem(chargeDdefData)
              if (
                priceComponent &&
                priceComponent.amount &&
                priceComponent.amount.value
              ) {
                payment = priceComponent.amount.value
              }
            }
            const billing: string = currentAppointment.characteristic
              ? currentAppointment.characteristic[0].text ?? ''
              : ''

            //   const categorytherapy: string = currentAppointment.category ? (currentAppointment.category[0].coding ? (currentAppointment.category[0].coding[0].display ?? '') : '') : ''

            const therapyCode = getCodeOfSystemFromCodableConceptArray(
              currentAppointment.category!,
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs',
              'http://snomed.info/sct'
            )

            let physiotherapyStatus: string = ''
            if (currentAppointment.active == true)
              physiotherapyStatus = 'Active'
            else physiotherapyStatus = 'Inactive'

            convertedAppointments.push({
              therapy: currentAppointment,
              price: payment ?? 0,
              name: currentAppointment.name ?? '',
              category: therapyCode?.display,
              active: currentAppointment.active,
              status: physiotherapyStatus,
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export default physiotherapyTableSearchSlice.reducer
