import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  DoctorBase,
  DoctorProfileService,
  ErrorResponse,
  SearchDocResponse,
} from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { WelloUnits } from 'models/welloUnit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getRoleCount,
  getUserCurrentRole,
  isMedicalServiceProvider,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getOIDCUserObject } from 'utils/authHelpers'
import {
  getAddress,
  getDefaultCodeOfSystemFromCodableConcept,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  getPractitionersWithRoleObject,
  getPractitionersWithUnitDetails,
} from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { UnitDetailsStatus } from './unitDetailsStatus'

const initialState: UnitDetailsStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const orgUnitSearchSlice = createSlice({
  name: 'orgUnitSearchSlice',
  initialState,
  reducers: {
    searchingUnitDetails(state, action: PayloadAction<UnitDetailsStatus>) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = false
      state.orgList = undefined
      state.unitList = undefined
    },

    searchResults(state, action: PayloadAction<UnitDetailsStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = true
      state.orgList = action.payload.orgList
      state.unitList = action.payload.unitList
    },

    noDataFoundForSearch(state, action: PayloadAction<UnitDetailsStatus>) {
      state.error = false
      state.searching = false

      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.orgList = undefined
      state.unitList = undefined
    },

    errorWhileSearching(state, action: PayloadAction<UnitDetailsStatus>) {
      state.error = true
      state.searching = false

      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.orgList = undefined
      state.unitList = undefined
    },

    resetPractDetails(state, action: PayloadAction<UnitDetailsStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = false
      state.orgList = undefined
      state.unitList = undefined
    },
  },
})

export const searchingUnitForOrg =
  (mainOrg: string, unitType: string, status: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: UnitDetailsStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(orgUnitSearchSlice.actions.searchingUnitDetails(state))
    try {
      const searchParameters: any = {
        _count: 50,
      }

      const fhirClient: MasterFhirClient = new MasterFhirClient()
      let response: any

      if (isOrgAdmin() || isUnitAdmin()) {
        response = await fhirClient.doGetResource(
          `Organization?type=${
            unitType === 'wellness_center'
              ? 'wellness_center'
              : unitType === 'clinic'
              ? 'clinic'
              : unitType
          }&partof=${mainOrg}&active=${status}&_count=50&_tag:not=mirror-resource`
        )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `Organization?type=${
              unitType === 'wellness_center'
                ? 'wellness_center'
                : unitType === 'clinic'
                ? 'clinic'
                : unitType
            }&partof=${mainOrg}&active=${status}&_tag:not=mirror-resource`,
            searchParameters
          )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const org: R4.IOrganization[] =
                practitionerRolesResponses.entry?.map(
                  (item) => item.resource as R4.IOrganization
                ) ?? []

              org.sort((a, b) =>
                (a.name ?? '')
                  .toLowerCase()
                  .localeCompare((b.name ?? '').toLowerCase())
              )

              const unitListData: WelloUnits[] = []
              for (let i = 0; i < org.length; i++) {
                const unitData: WelloUnits = {
                  orgDetails: org[i],
                  orgName: org[i].name ?? '',
                  mainOrgId: org[i].id ?? '',
                  address: getAddress(org[i].address).trim() ?? '',
                  phone: org[i].contact
                    ? getTelecomFromContactPoints(
                        org[i].contact![0].telecom ?? [],
                        ContactPointSystemKind._phone
                      ) ?? ''
                    : '',
                  email: org[i].contact
                    ? getTelecomFromContactPoints(
                        org[i].contact![0].telecom ?? [],
                        ContactPointSystemKind._email
                      ) ?? ''
                    : '',
                  status:
                    org[i].active && org[i].active === true
                      ? 'Enabled'
                      : 'Disabled',
                  onboardDate: '',
                }
                unitListData.push(unitData)
              }
              state.orgList = org
              state.unitList = unitListData
              state.searching = false
              state.error = false
              dispatch(orgUnitSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(orgUnitSearchSlice.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(orgUnitSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(orgUnitSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(orgUnitSearchSlice.actions.errorWhileSearching(state))
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export const resetStateForWelloAdminUnitDetails =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: UnitDetailsStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      orgList: undefined,
    }
    dispatch(orgUnitSearchSlice.actions.resetPractDetails(state))
  }

export default orgUnitSearchSlice.reducer
