import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core'
import { ClearOutlined, DeleteOutlineOutlined } from '@material-ui/icons'
import { Autocomplete, Alert } from '@material-ui/lab'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { UnitMemberRelation } from 'models/unitMemberrelation'
import { UnitServices } from 'models/units/unitServices'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  sendUnitActorBindingInvitations,
  resetUnitAdminAdditionReducerState,
} from 'redux/administration/userSetup/unitActorInvitationSlice/unitActorInvitationSetupSlice'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  getPractList,
  resetUserSearchForSetting,
  searchPractitioners,
} from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import {
  resetRoleSearchState,
  searchRoles,
} from 'redux/practitioner/rolesHandler/roleSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getSelectedOrgUnitType,
} from 'services/userDetailsService'
import {
  profileCompletedForUnitAdmin,
  validateEmailIsExistingOne,
  validateEmailIsExistingOneWithSameUnit,
  validatePhoneIsExistingOneWithSameUnit,
  validateRoleIsExistingOneWithSameUnit,
  validateUnitAdminInvite,
} from 'utils/admisnistration/users/addUserHelpers'
import { UnitTypes } from 'utils/constants/unit_types'
import { ClinicUserRole } from 'utils/constants/user_roles'
import {
  getEmailOfPractitionerRole,
  getFirstNameFromHumanName,
  getTelecomFromContactPoints,
  getTelecomOfPractitioner,
} from 'utils/fhirResourcesHelper'

import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { getOnlyPhoneNumber } from 'utils/fhirResoureHelpers/patientHelpers'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectFHIRCodingWithoutSort } from 'views/components/LeftMenu/WelloSelectFhirCodingWithoutSort'
import { WelloSelectUnitWithServices } from 'views/components/LeftMenu/WelloSelectUnitForServices'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import {
  validateEmail,
  validateMobileNumber,
  WelloTextField,
} from 'wello-web-components'
import {
  ExistingUserConfirmation,
  ExistingUserConfirmationProps,
} from './existingUserConfirmation'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddUnitAdminsHandler: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const unitActorInvitationSetupSlice = useSelector(
    (state: RootState) => state.unitActorInvitationSetupSlice
  )
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )
  const roleSearchSlice = useSelector(
    (state: RootState) => state.roleSearchSlice
  )

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [roleList, setRoleList] = useState<boolean>(false)

  const [loadingCount, setLoadingCount] = useState<boolean>(false)
  const activeUsers = useSelector(
    (state: RootState) => state.practitionerSearchSlice.doctorRoleList
  )

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [showExistingUserInfo, setShowExistingUserInfo] =
    useState<ExistingUserConfirmationProps>({
      open: false,
    })

  const [errorMsg, setErrorMsg] = useState<string>()

  const [userList, setUserList] = useState<PractitionerWithRole[]>([])
  const units: R4.IOrganization[] = []
  let unitServices: UnitServices[] = []
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  if (unitsOfOrganizationSlice && unitsOfOrganizationSlice.length > 0) {
    unitServices = unitsOfOrganizationSlice
  }
  const [relation, setRelations] = useState<UnitMemberRelation[]>([
    {
      id: getUniqueTempId(),
      unitOrganization: unitServices[0].orgs ?? {
        resourceType: 'Organization',
        name: 'No Units added',
        id: getUniqueTempId(),
      },
      unitService: unitServices[0],
      roleType: undefined,
      practitionerEmail: '',
      practitionerName: '',
      practitionerPhoneNumber: '',
      existingPractitionerId: undefined,
      isSelf: false,
    },
  ])

  function getUsers() {
    setPaymentStatus({ requesting: true })

    setLoadingCount(true)

    getPractList()
      .then((e: PractitionerWithRole[]) => {
        setLoadingCount(false)
        if (e) {
          setUserList(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoadingCount(false)
          dispatch(
            showErrorAlert('Error while fetching users. Please try again later')
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoadingCount(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function handleChangeUnitSelection(event: R4.IOrganization, index: number) {
    setErrorMsg(undefined)
    setHasErrors(false)
    const values: UnitMemberRelation[] = [...relation]
    values[index].unitOrganization = event
    setRelations(values)
  }

  function handleSelfSelection(checked: boolean, index: number) {
    setHasErrors(false)
    const values: UnitMemberRelation[] = [...relation]
    values[index].isSelf = checked
    if (checked) {
      values[index].existingPractitionerId =
        getCurrentUserPractitionerDetails()!.id
      values[index].practitionerEmail =
        getTelecomFromContactPoints(
          getCurrentUserPractitionerDetails()!.telecom ?? [],
          R4.ContactPointSystemKind._email ?? ''
        ) ?? ''
      values[index].practitionerPhoneNumber =
        getTelecomFromContactPoints(
          getCurrentUserPractitionerDetails()!.telecom ?? [],
          R4.ContactPointSystemKind._phone ?? ''
        ) ?? ''
      values[index].practitionerName = getFirstNameFromHumanName(
        getCurrentUserPractitionerDetails()!.name ?? []
      )
      values[index].existingRoleId = getCurrentUserPractitionerDetails()!.id
    } else {
      values[index].practitionerName = ''
      values[index].existingPractitionerId = undefined
      values[index].practitionerEmail = ''
      values[index].practitionerPhoneNumber = ''
    }

    setRelations(values)
  }

  function handleChangeRoleSelection(event: R4.ICoding, index: number) {
    const values: UnitMemberRelation[] = [...relation]
    values[index].roleType = event
    setRelations(values)
  }

  function removeRoleSelection(index: number) {
    const values: UnitMemberRelation[] = [...relation]
    values[index].roleType = undefined
    setRelations(values)
  }

  function handleNameChange(event: any, index: number) {
    const values: UnitMemberRelation[] = [...relation]
    values[index].practitionerName = event.target.value
    setRelations(values)
  }
  function handleEmailChange(event: any, index: number) {
    const values: UnitMemberRelation[] = [...relation]
    values[index].practitionerEmail = event.target.value
    if (validateEmail(event.target.value, true).length === 0) {
      const existingUser = validateEmailIsExistingOneWithSameUnit(
        activeUsers ?? [],
        event.target.value,
        values[index].unitOrganization.id!
      )

      if (existingUser) {
        setShowExistingUserInfo({
          open: true,
          user: existingUser.practRole,
          displayText: existingUser.isSmeUnit
            ? `User with email ${event.target.value} already exists in the unit. Do you want update roles of the Existing user in the unit?`
            : `User with email ${event.target.value} already exists in the another unit. Do you want create roles of the Existing user in this unit?`,
          onCancelActionClicked: () => {
            setShowExistingUserInfo({ open: false })
            values[index].practitionerEmail = ''
            setRelations(values)
          },
          onContinueActionClicked: () => {
            setShowExistingUserInfo({ open: false })
            values[index].practitionerEmail =
              existingUser.practRole.primaryContact
            values[index].practitionerName = existingUser.practRole.name
            values[index].practitionerPhoneNumber = getOnlyPhoneNumber(
              existingUser.practRole.phone,
              '+91'
            )
            values[index].existingPractitionerId =
              existingUser.practRole.practitionerObject?.id
            values[index].existingRoleId = existingUser.practRole.roleObject?.id
            values[index].isExistingUserEmail = true
            values[index].isExistingUserPhoneNumber = false

            setRelations(values)
          },
        })
      } else {
        setRelations(values)
      }
    } else {
      setRelations(values)
    }
    // setRelations(values)
  }

  function handlePhoneNumberChange(event: any, index: number) {
    const values: UnitMemberRelation[] = [...relation]
    values[index].practitionerPhoneNumber = event
    if (validateMobileNumber(event, true).length === 0) {
      const existingUser = validatePhoneIsExistingOneWithSameUnit(
        activeUsers ?? [],
        event,
        values[index].unitOrganization.id!
      )

      if (existingUser) {
        setShowExistingUserInfo({
          open: true,
          user: existingUser.practRole,
          displayText: existingUser.isSmeUnit
            ? `User with phone number ${event} already exists in the unit. Do you want update roles of the user?`
            : `User with phone number ${event} already exists in the another unit. Do you want create roles of the Existing user in this unit?`,
          onCancelActionClicked: () => {
            setShowExistingUserInfo({ open: false })
            values[index].practitionerPhoneNumber = ''
            setRelations(values)
          },
          onContinueActionClicked: () => {
            setShowExistingUserInfo({ open: false })
            values[index].practitionerEmail =
              existingUser.practRole.primaryContact
            values[index].practitionerName = existingUser.practRole.name
            values[index].practitionerPhoneNumber = getOnlyPhoneNumber(
              existingUser.practRole.phone,
              '+91'
            )
            values[index].existingPractitionerId =
              existingUser.practRole.practitionerObject?.id
            values[index].existingRoleId = existingUser.practRole.roleObject?.id
            values[index].isExistingUserEmail = true
            values[index].isExistingUserPhoneNumber = false

            setRelations(values)
          },
        })
      } else {
        setRelations(values)
      }
    } else {
      setRelations(values)
    }
  }

  const handleAddMore = () => {
    const values: UnitMemberRelation[] = [...relation]
    values.push({
      id: getUniqueTempId(),
      unitOrganization: unitServices[0].orgs ?? {
        resourceType: 'Organization',
        name: 'No Units added',
        id: getUniqueTempId(),
      },
      unitService: unitServices[0],
      roleType: undefined,
      practitionerEmail: '',
      practitionerName: '',
      practitionerPhoneNumber: '',
    })
    setRelations(values)
  }

  function handleRemove(id: number) {
    const values: UnitMemberRelation[] = [...relation]
    values.splice(id, 1)
    setRelations(values)
  }

  function resetValues() {
    setErrorMsg(undefined)
    setRelations([
      {
        id: getUniqueTempId(),
        unitOrganization: unitServices[0].orgs ?? {
          resourceType: 'Organization',
          name: 'No Units added',
          id: getUniqueTempId(),
        },
        unitService: unitServices[0],
        roleType: undefined,
        practitionerEmail: '',
        practitionerName: '',
        practitionerPhoneNumber: '',
        existingPractitionerId: undefined,
        isSelf: false,
      },
    ])
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    relation.forEach((e) => {
      if (!e.practitionerName || e.practitionerName?.length === 0) {
        hasErrorsInData = true
      }

      if (e.roleType) {
        if (
          validateRoleIsExistingOneWithSameUnit(
            userList ?? [],
            e.practitionerEmail,
            e.roleType.display ?? '',
            e.unitOrganization.id!
          )
        ) {
          setErrorMsg(
            `User with ${e.roleType.display ?? ''} role and ${
              e.practitionerEmail
            } email already exist`
          )
          hasErrorsInData = true
        }
      }
      if (e.roleType) {
        const status = !!(
          e.unitOrganization.meta &&
          e.unitOrganization.meta.tag &&
          e.unitOrganization.meta.tag.length > 0
        )
        if (status === false && e.roleType.display !== 'Unit Admin') {
          setErrorMsg(
            `Unit setup for ${
              e.unitOrganization.name ?? ''
            } has not been completed. Please complete the unit setup then send ${
              e.roleType.display ?? ''
            } invitation`
          )
          hasErrorsInData = true
        }
      }

      if (e.roleType === undefined) {
        hasErrorsInData = true
      }
      if (
        !e.practitionerEmail ||
        e.practitionerEmail?.length === 0 ||
        validateEmail(e.practitionerEmail, true).length > 0
      ) {
        hasErrorsInData = true
      }
      if (
        !e.practitionerPhoneNumber ||
        e.practitionerPhoneNumber?.length === 0 ||
        validateMobileNumber(e.practitionerPhoneNumber, true).length > 0
      ) {
        hasErrorsInData = true
      }
    })
    if (!hasErrorsInData) {
      const uniqueSet = new Set(
        relation.map(
          (e) =>
            e.unitOrganization.id!.trim() +
            e.roleType!.code +
            e.practitionerEmail.toLowerCase().trim() +
            e.practitionerPhoneNumber.trim()
        )
      )
      hasErrorsInData = uniqueSet.size !== relation.length

      if (hasErrorsInData) {
        dispatch(
          showErrorAlert(
            'Same role assigned to same email in same unit.Please check'
          )
        )
      }
    }
    if (!hasErrorsInData) {
      getUsers()
      dispatch(sendUnitActorBindingInvitations(relation))
    }
    setHasErrors(hasErrorsInData)
  }

  useEffect(() => {
    getUsers()
    if (unitServices && unitServices.length > 0) {
      dispatch(
        searchRoles(
          unitServices[0].type,
          unitServices[0].systemOfMedicine ?? []
        )
      )
    }
    if (unitActorInvitationSetupSlice.additionSuccessful) {
      onClose()
      resetValues()
      dispatch(resetUnitAdminAdditionReducerState())
    }
    return () => {}
  }, [unitActorInvitationSetupSlice.additionSuccessful, onClose, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetValues()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Unit Users</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column'>
          {errorMsg && (
            <Box display='flex'>
              <Alert severity='error'>{errorMsg}</Alert>
            </Box>
          )}

          {unitActorInvitationSetupSlice.error && (
            <Box display='flex'>
              <Alert severity='error'>
                {unitActorInvitationSetupSlice.errorMessage}
              </Alert>
            </Box>
          )}
          {unitActorInvitationSetupSlice.additionSuccessful && (
            <Box display='flex'>
              <Alert severity='success'>'Invitation sent Successfully'</Alert>
            </Box>
          )}
          {!unitActorInvitationSetupSlice.additionSuccessful && (
            <Box display='flex' flexDirection='column'>
              {relation.map((memRel: UnitMemberRelation, index: number) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  key={`unit_actor_small_details_${memRel.id}`}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='flex-start'
                    key={`unit_actor_small_details_${memRel.id}`}
                  >
                    <Box flexGrow={1} p={0.5} width='20%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Unit' />
                        <WelloSelectUnitWithServices
                          availableCodings={unitServices}
                          onChanged={(value: UnitServices) => {
                            dispatch(
                              searchRoles(
                                value.type,
                                value.systemOfMedicine ?? []
                              )
                            )
                            removeRoleSelection(index)
                            handleChangeUnitSelection(value.orgs, index)
                          }}
                          preSelectedCoding={memRel.unitService}
                        />
                      </Box>
                    </Box>
                    <Box flexGrow={1} p={0.5} width='20%'>
                      <WelloFormItemLabel title='Role' />
                      <Autocomplete
                        style={{
                          borderRadius: '4px',
                        }}
                        fullWidth
                        size='small'
                        id='unit_admin_role'
                        defaultValue={memRel.roleType}
                        autoFocus
                        disableClearable
                        getOptionLabel={(option) => option.display ?? ''}
                        options={
                          userList
                            ? validateUnitAdminInvite(
                                userList ?? [],
                                'Unit Admin',
                                memRel.unitOrganization.id!
                              )
                              ? roleSearchSlice.roleList ?? []
                              : [
                                  {
                                    system:
                                      'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
                                    code: 'unit-admin',
                                    display: 'Unit Admin',
                                  },
                                ]
                            : [
                                {
                                  system:
                                    'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
                                  code: 'unit-admin',
                                  display: 'Unit Admin',
                                },
                              ]
                        }
                        onChange={(e, changedValue, reason) => {
                          if (changedValue) {
                            handleChangeRoleSelection(changedValue, index)
                          }
                        }}
                        autoComplete
                        ChipProps={{
                          deleteIcon: (
                            <ClearOutlined
                              style={{
                                height: '15px',
                                color: 'white',
                              }}
                            />
                          ),
                          style: {
                            backgroundColor: kPrimaryLight,
                            borderRadius: '4px',
                          },
                        }}
                        disabled={unitActorInvitationSetupSlice.adding}
                        renderOption={(option) => (
                          <Typography variant='subtitle2'>
                            {option.display}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Select Role'
                            fullWidth
                            id='unit_search'
                            variant='outlined'
                            size='small'
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                      <Box paddingLeft={2} id='genderError'>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          style={{ fontWeight: 500 }}
                        >
                          {hasErrors && memRel.roleType === undefined
                            ? 'Select Role'
                            : ''}
                        </Typography>
                      </Box>
                      {/* <WelloSelectFHIRCoding
                        title='Role'
                        id='unit_admin_role'
                        availableCodings={roleSearchSlice.roleList ?? []}
                        onChanged={(e) => {
                          if (e) {
                            handleChangeRoleSelection(e, index)
                          }
                        }}
                        textFieldProps={{
                          size: 'small',
                          disabled: unitActorInvitationSetupSlice.adding,
                          error: hasErrors && memRel.roleType === undefined,
                          helperText:
                            hasErrors && memRel.roleType === undefined
                              ? 'Select Role'
                              : '',
                        }}
                        preSelectedCoding={memRel.roleType}
                      /> */}
                    </Box>
                    <Box
                      display='flex'
                      justifyContent='center'
                      px={1}
                      paddingTop={4}
                    >
                      <FormControlLabel
                        label='Self'
                        control={
                          <Checkbox
                            value=''
                            checked={memRel.isSelf ?? false}
                            onChange={(e, checked) => {
                              setErrorMsg(undefined)
                              handleSelfSelection(checked, index)
                            }}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter') {
                                if (memRel.isSelf !== undefined) {
                                  if (memRel.isSelf === false) {
                                    setErrorMsg(undefined)
                                    handleSelfSelection(true, index)
                                  } else {
                                    setErrorMsg(undefined)
                                    handleSelfSelection(false, index)
                                  }
                                } else {
                                  setErrorMsg(undefined)
                                  handleSelfSelection(true, index)
                                }
                              }
                            }}
                            color='primary'
                          />
                        }
                      />
                    </Box>
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      p={0.5}
                      width='20%'
                    >
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Name' />
                        <WelloTextFieldWithoutTitle
                          //   title={t('labelCommon:person_name_label')}
                          textProps={{
                            id: `actor_name_unit_${t}`,
                            value: memRel.practitionerName,
                            disabled:
                              unitActorInvitationSetupSlice.adding ||
                              memRel.isSelf ||
                              memRel.unitOrganization === undefined ||
                              memRel.unitOrganization.name === 'No Units added',
                            error:
                              hasErrors &&
                              (!memRel.practitionerName ||
                                memRel.practitionerName?.length === 0),
                            helperText:
                              hasErrors &&
                              (!memRel.practitionerName ||
                                memRel.practitionerName?.length === 0)
                                ? 'Name is not Valid'
                                : '',
                            onChange: (changedValue) => {
                              handleNameChange(changedValue, index)
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      p={0.5}
                      width='20%'
                    >
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Email' />
                        <WelloTextFieldWithoutTitle
                          //   title={t('labelCommon:email_name_label')}
                          textProps={{
                            id: `actor_email_unit`,
                            disabled:
                              unitActorInvitationSetupSlice.adding ||
                              memRel.isSelf ||
                              memRel.unitOrganization === undefined ||
                              memRel.unitOrganization.name === 'No Units added',
                            error:
                              hasErrors &&
                              validateEmail(memRel.practitionerEmail, true)
                                .length > 0,
                            helperText:
                              hasErrors &&
                              validateEmail(memRel.practitionerEmail, true)
                                .length > 0
                                ? 'Email is not valid'
                                : '',
                            value: memRel.practitionerEmail,
                            onChange: (changeEmail) => {
                              handleEmailChange(changeEmail, index)
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      p={0.5}
                      width='20%'
                    >
                      <WelloPhoneNumberField
                        textProps={{
                          id: `actor_phone_unit_${t}`,
                          countryCodeEditable: false,
                          defaultCountry: 'in',
                          inputProps: { maxLength: 13 },
                          fullWidth: true,
                          name: t('labelCommon:phone_name_label'),
                          inputMode: 'tel',
                          onlyCountries: ['in'],
                          enableLongNumbers: false,
                          value: memRel.practitionerPhoneNumber,
                          disableDropdown: false,
                          disabled:
                            unitActorInvitationSetupSlice.adding ||
                            memRel.isSelf ||
                            memRel.unitOrganization === undefined ||
                            memRel.unitOrganization.name === 'No Units added',
                          onChange: (
                            e:
                              | React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              | string
                          ) => {
                            if (typeof e === 'string') {
                              handlePhoneNumberChange(e, index)
                            }
                          },
                          regions: ['asia'],
                          autoFormat: false,
                          size: 'small',
                          variant: 'outlined',
                          error:
                            hasErrors &&
                            validateMobileNumber(
                              memRel.practitionerPhoneNumber,
                              true
                            ).length > 0,
                          helperText:
                            hasErrors &&
                            validateMobileNumber(
                              memRel.practitionerPhoneNumber,
                              true
                            ).length > 0
                              ? 'Phone is not valid'
                              : '',

                          required: true,
                        }}
                        title={t('labelCommon:phone_label')}
                      />
                    </Box>

                    {relation.length !== 1 && (
                      <Box
                        display='flex'
                        justifyContent='center'
                        paddingTop={5}
                      >
                        <DeleteOutlineOutlined
                          fontSize='medium'
                          id={`$${index}orgContact`}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowDeleteConfirmation({
                              open: true,
                              onContinueActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                                handleRemove(index)
                              },
                              onCancelActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                              },
                              displayText:
                                'Are you sure you want to delete this record?',
                              continueActionButtonText: 'Delete',
                              cancelActionButtonText: 'Cancel',
                            })
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  {!unitActorInvitationSetupSlice.additionSuccessful &&
                    relation.length - 1 === index &&
                    relation.length < 4 && (
                      <Box
                        display='flex'
                        alignItems='center'
                        py={0.5}
                        flexDirection='row'
                      >
                        <Box>
                          <Button
                            variant='text'
                            onClick={handleAddMore}
                            disabled={unitActorInvitationSetupSlice.adding}
                            style={{
                              paddingLeft: '4px',
                            }}
                          >
                            <Typography variant='subtitle2'>
                              {t('labelCommon:add_more_user')}
                            </Typography>
                          </Button>
                        </Box>
                        <Box flexGrow={1} paddingLeft={2}>
                          {' '}
                          <Divider />{' '}
                        </Box>
                      </Box>
                    )}
                </Box>
              ))}
            </Box>
          )}

          {showDeleteConfirmation.open && (
            <ConfirmationDialog {...showDeleteConfirmation} />
          )}
          {showExistingUserInfo.open && (
            <ExistingUserConfirmation {...showExistingUserInfo} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {unitActorInvitationSetupSlice.adding && <CircularProgress />}
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          disabled={unitActorInvitationSetupSlice.adding}
          disableElevation
        >
          {t('labelCommon:back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            unitActorInvitationSetupSlice.adding || errorMsg !== undefined
          }
          onClick={() => {
            handleSubmit()
          }}
        >
          {t('labelCommon:invite_users')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
