import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getDefaultDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { isAfter } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { CodeFromExistingValueSet } from 'views/components/common/SingleSelectFromValueSetUrlWithCode'
import CustomizedDividersForKriya from 'views/components/LeftMenu/toggleButtonSelector'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextField } from 'wello-web-components'
import { KriyaDetail } from './addKriyasForEncounter'

interface Props {
  existingKriyaDetails?: KriyaDetail
  kriyaValueSetUrl?: string
  onChanged: (kriyaDetails: KriyaDetail) => void
}
export const KriyaDetailsCaptureForm: React.FC<Props> = ({
  existingKriyaDetails,
  kriyaValueSetUrl = 'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-treatments-vs',
  onChanged,
}) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [start, setStart] = React.useState<Date>(
    new Date(
      `2020-01-01 ${isAfter('21:00') ? '21:00' : moment().format('HH:mm')}`
    )
  )
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('3d')
  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [formState, setformState] = useState(_.random(0, 1000).toString())

  const [currentKriya, setCurrentKriya] = useState<KriyaDetail>({
    kriya: existingKriyaDetails
      ? existingKriyaDetails.rawKriyaDetail
        ? existingKriyaDetails.rawKriyaDetail.code
          ? getDefaultCodingOfSystemFromCodableConceptList([
              existingKriyaDetails.rawKriyaDetail.code,
            ])
          : undefined
        : undefined
      : undefined,
    medications: existingKriyaDetails
      ? existingKriyaDetails.rawKriyaDetail
        ? existingKriyaDetails.rawKriyaDetail.orderDetail
          ? existingKriyaDetails.rawKriyaDetail.orderDetail.map(
              (e) => e.coding![0]
            )
          : undefined
        : undefined
      : undefined,

    priority: existingKriyaDetails
      ? existingKriyaDetails.rawKriyaDetail
        ? existingKriyaDetails.rawKriyaDetail.priority
          ? existingKriyaDetails.rawKriyaDetail.priority
          : 'routine'
        : 'routine'
      : 'routine',
    startFrom: new Date(),
    till: getAddedDate(2),
    time: moment().format('HH:mm'),
    timeData: moment().format('hh:mm A'),
  })

  console.log('currentKriya', currentKriya)
  console.log(
    '---------existingKriyaDetails--------',
    getDefaultDisplayOfSystemFromCodableConcept(
      existingKriyaDetails?.rawKriyaDetail?.code
    )
  )

  const [displayChoices, setDisplayChoices] = useState<boolean>(false)

  useEffect(() => {
    onChanged(currentKriya)
  }, [])

  return (
    <Box width='100%' display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='row' width='42.5%'>
          <Box width='100%' display='flex' flexDirection='column'>
            <Box width='100%' key={`${formState}kriya_search`}>
              {existingKriyaDetails === undefined && displayChoices && (
                <ValueSetSelectSingle
                  title='Select Kriya (Procedure)'
                  url={kriyaValueSetUrl}
                  id='kriyas'
                  /* preEnteredText={
              existingKriyaDetails
                ? getDefaultDisplayOfSystemFromCodableConcept(
                    existingKriyaDetails?.rawKriyaDetail?.code
                  )
                : undefined
            } */
                  fuzzySearch={true}
                  disabled={existingKriyaDetails !== undefined}
                  onOptionSelected={(selectedKriya) => {
                    setCurrentKriya({
                      ...currentKriya,
                      kriya: selectedKriya,
                    })
                    onChanged({
                      ...currentKriya,
                      kriya: selectedKriya,
                    })
                  }}
                  helperText={undefined}
                />
              )}
              {existingKriyaDetails !== undefined && displayChoices && (
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' flexDirection='row'>
                    <WelloFormItemLabel title='Selected Kriya (Procedure)' />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    py={0.75}
                    px={0.5}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{ padding: '2px' }}
                    >
                      {getDefaultDisplayOfSystemFromCodableConcept(
                        existingKriyaDetails?.rawKriyaDetail?.code
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
              {existingKriyaDetails !== undefined &&
                existingKriyaDetails.rawKriyaDetail !== undefined &&
                getDefaultCodeOfSystemFromCodableConcept(
                  existingKriyaDetails.rawKriyaDetail.code
                ) &&
                !displayChoices && (
                  <CodeFromExistingValueSet
                    id='kriya_selections_form_value_set'
                    title='Selected Kriya (Procedure)'
                    onNoOptionAvailable={() => {
                      setDisplayChoices(true)
                    }}
                    searchCode={
                      existingKriyaDetails.rawKriyaDetail.code?.coding![0]!
                    }
                    onOptionSelected={(selectedKriya) => {
                      setCurrentKriya({
                        ...currentKriya,
                        kriya: selectedKriya,
                      })
                      onChanged({
                        ...currentKriya,
                        kriya: selectedKriya,
                      })
                    }}
                  />
                )}
            </Box>

            <Box width='100%' key={`${formState}medicine_search`}>
              <ValueSetSelectMultiple
                title='Select Medicines'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-med-vs'
                id='medicines'
                disabled={false}
                fuzzySearch={true}
                pluralWord='Medicines'
                singularWorld='Medicine'
                preSelectedOptions={
                  existingKriyaDetails
                    ? existingKriyaDetails.rawKriyaDetail
                      ? existingKriyaDetails.rawKriyaDetail.orderDetail
                        ? existingKriyaDetails.rawKriyaDetail.orderDetail.map(
                            (e) => e.coding![0]
                          )
                        : undefined
                      : undefined
                    : undefined
                }
                onOptionSelected={(selectedMedicines) => {
                  console.log(
                    '------------selectedMedicines---------------',
                    selectedMedicines
                  )
                  setCurrentKriya({
                    ...currentKriya,
                    medications: selectedMedicines,
                  })
                  onChanged({
                    ...currentKriya,
                    medications: selectedMedicines,
                  })
                }}
                helperText={undefined}
              />
            </Box>
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              key={`${formState}dosage_unit`}
            >
              <Box display='flex' flexDirection='row' width='100%'>
                <Box width='30%' paddingRight={1}>
                  <WelloTextField
                    title={t('labelDosage:resourceName')}
                    textProps={{
                      id: `med_dosage`,
                      placeholder: 'Dosage',
                      error:
                        currentKriya.dosageQuantity! <= 0 ||
                        currentKriya.dosageQuantity! >= 10000 ||
                        Number.isNaN(currentKriya.dosageQuantity!),
                      type: 'number',
                      disabled: currentKriya.dosageQuantityType
                        ? currentKriya.dosageQuantityType === '422135004'
                        : false,

                      // value: tests.scheduleSummary,
                      helperText:
                        currentKriya.dosageQuantity! <= 0 ||
                        Number.isNaN(currentKriya.dosageQuantity!)
                          ? `${t('labelCommon:invlaid_dosages')}`
                          : '',
                      inputProps: {
                        min: 0,
                        max: 10000,
                        step: 0.1,
                      },

                      onChange: (changeData) => {
                        if (changeData) {
                          setCurrentKriya({
                            ...currentKriya,
                            dosageQuantity: parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            ),
                          })
                          onChanged({
                            ...currentKriya,
                            dosageQuantity: parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            ),
                          })
                          changeData.stopPropagation()
                        }
                      },
                    }}
                  />
                </Box>
                <Box width='70%' paddingLeft={1}>
                  <ValueSetSelectSingleDisplaySinge
                    key='wellopathy-relationship-vs_medicines'
                    title='Unit'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                    id='med_units'
                    fuzzySearch={true}
                    disabled={false}
                    alwaysShowAllOptions={true}
                    onOptionSelected={(
                      conditionsData: R4.ICoding | undefined
                    ) => {
                      if (conditionsData) {
                        if (conditionsData.code === '422135004') {
                          setCurrentKriya({
                            ...currentKriya,
                            dosageQuantityType: conditionsData.code,
                            dosageQuantity: undefined,
                          })
                          onChanged({
                            ...currentKriya,
                            dosageQuantityType: conditionsData.code,
                            dosageQuantity: undefined,
                          })
                        } else {
                          setCurrentKriya({
                            ...currentKriya,
                            dosageQuantityType: conditionsData.code,
                          })
                          onChanged({
                            ...currentKriya,
                            dosageQuantityType: conditionsData.code,
                          })
                        }
                      }
                    }}
                    helperText={undefined}
                    placeHolder='Unit'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width='15%' />
        <Box display='flex' flexDirection='row' width='42.5%'>
          <Box width='100%' display='flex' flexDirection='column'>
            <Box width='100%' key={`${formState}start_from`}>
              {selectedDatePart !== 'custom' && (
                <CustomizedDividersForKriya
                  key={`${formState}start_from`}
                  size='small'
                  menuLabel={t('labelCommon:start From')}
                  options={[
                    {
                      displayText: `${t('labelCommon:today')}`,
                      value: 'today',
                    },
                    {
                      displayText: `${t('labelCommon:tomorrow')}`,
                      value: 'tomo',
                    },
                    {
                      displayText: `${t('labelCommon:custom')}`,
                      value: 'custom',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedDatePart(selectedValue)

                    if (selectedValue === 'today') {
                      setStart(
                        new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                      )
                      setCurrentKriya({
                        ...currentKriya,
                        startFrom: new Date(),
                        time: moment().format('HH:mm'),
                        timeData: moment().format('HH:mm A'),
                      })
                      onChanged({
                        ...currentKriya,
                        startFrom: new Date(),
                        time: moment().format('HH:mm'),
                        timeData: moment().format('HH:mm A'),
                      })
                    }
                    if (selectedValue === 'tomo') {
                      setStart(new Date(`2020-01-01 00:00`))
                      setCurrentKriya({
                        ...currentKriya,
                        startFrom: getTomorrowDate(),
                        time: '00:00',
                        timeData: '00:00 AM',
                      })
                      onChanged({
                        ...currentKriya,
                        startFrom: getTomorrowDate(),
                        time: '00:00',
                        timeData: '00:00 AM',
                      })
                    }
                    if (selectedValue === 'custom') {
                      setStart(new Date(`2020-01-01 00:00`))
                      setCurrentKriya({
                        ...currentKriya,
                        time: '00:00',
                        timeData: '00:00 AM',
                      })
                      onChanged({
                        ...currentKriya,
                        time: '00:00',
                        timeData: '00:00 AM',
                      })
                    }
                  }}
                  preSelectedValue={selectedDatePart}
                />
              )}
              {selectedDatePart === 'custom' && (
                <Box width='100%' display='flex' flexDirection='row'>
                  <CustomizedDividersForKriya
                    key={`${formState}start_from`}
                    size='small'
                    menuLabel={t('labelCommon:start From')}
                    options={[
                      {
                        displayText: `${t('labelCommon:today')}`,
                        value: 'today',
                      },
                      {
                        displayText: `${t('labelCommon:tomorrow')}`,
                        value: 'tomo',
                      },
                    ]}
                    onSelectionChanged={(selectedValue) => {
                      setSelectedDatePart(selectedValue)
                      if (selectedValue === 'today') {
                        setStart(
                          new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                        )
                        setCurrentKriya({
                          ...currentKriya,
                          startFrom: new Date(),
                          time: moment().format('HH:mm'),
                          timeData: moment().format('hh:mm A'),
                        })
                        onChanged({
                          ...currentKriya,
                          startFrom: new Date(),
                          time: moment().format('HH:mm'),
                          timeData: moment().format('hh:mm A'),
                        })
                      }
                      if (selectedValue === 'tomo') {
                        setStart(new Date(`2020-01-01 00:00`))
                        setCurrentKriya({
                          ...currentKriya,
                          startFrom: getTomorrowDate(),
                          time: '00:00',
                          timeData: '00:00 AM',
                        })
                        onChanged({
                          ...currentKriya,
                          startFrom: getTomorrowDate(),
                          time: '00:00',
                          timeData: '00:00 AM',
                        })
                      }
                    }}
                    preSelectedValue={selectedDatePart}
                    width='150'
                  />
                  <Box width='53%' paddingTop={1.7} paddingLeft={1.5}>
                    <TextField
                      key='custom'
                      variant='outlined'
                      style={{
                        marginTop: '16px',
                      }}
                      size='small'
                      onChange={(val) => {
                        let tillDateLocal = currentKriya.till

                        // setStartDate(new Date(val.target.value))

                        if (selectedWeekPart === '3d') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(2, 'days')
                              .toDate()
                          } else {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(2, 'days')
                              .toDate()
                          }
                        }

                        if (selectedWeekPart === '3d') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(2, 'days')
                              .toDate()
                          } else {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(2, 'days')
                              .toDate()
                          }
                        }

                        if (selectedWeekPart === '1W') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(6, 'days')
                              .toDate()
                          } else {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(6, 'days')
                              .toDate()
                          }
                        }
                        if (selectedWeekPart === '2W') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(13, 'days')
                              .toDate()
                          } else {
                            tillDateLocal = moment(
                              moment(new Date(val.target.value)).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(13, 'days')
                              .toDate()
                          }
                        }

                        setCurrentKriya({
                          ...currentKriya,
                          startFrom: new Date(val.target.value),
                          till: tillDateLocal,
                        })
                        onChanged({
                          ...currentKriya,
                          startFrom: new Date(val.target.value),
                          till: tillDateLocal,
                        })
                      }}
                      type='date'
                      inputProps={{
                        min: moment
                          .utc(new Date())
                          .local()
                          .format('YYYY-MM-DD'),
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            <Box
              width='100%'
              key={`${formState}time_day`}
              display='flex'
              flexDirection='column'
            >
              <WelloFormItemLabel title='Time of the Day' case='yes' />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      //   disabled={!isAfterSame('09:00', ',')}
                      id='obj_12'
                      //   InputProps={{
                      //     style: {
                      //       paddingTop: 0,
                      //       paddingBottom: 0,
                      //     },
                      //   }}
                    />
                  )}
                  value={start}
                  onChange={(newValue) => {
                    if (newValue) {
                      setStart(newValue)
                      setCurrentKriya({
                        ...currentKriya,
                        time: moment(newValue).format('HH:mm'),
                        timeData: moment(newValue).format('hh:mm A'),
                      })
                      onChanged({
                        ...currentKriya,
                        time: moment(newValue).format('HH:mm'),
                        timeData: moment(newValue).format('hh:mm A'),
                      })
                    }
                  }}
                  minTime={
                    moment().format('YYYY-MM-DD') ===
                    moment(currentKriya.startFrom).format('YYYY-MM-DD')
                      ? new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                      : new Date(0, 0, 0, 0)
                  }
                />
              </LocalizationProvider>
            </Box>

            <Box
              width='100%'
              display='flex'
              flexDirection='column'
              paddingTop={1}
              key={`${formState}duration_data`}
            >
              <CustomizedDividersForKriya
                key={`${formState}duration_data`}
                size='small'
                menuLabel='Duration'
                options={[
                  {
                    displayText: `${t('labelCommon:3_Days')}`,
                    value: '3d',
                  },
                  {
                    displayText: `${t('labelCommon:1_Week')}`,
                    value: '1W',
                  },
                  {
                    displayText: `2 Weeks`,
                    value: '2W',
                  },
                  {
                    displayText: `${t('labelCommon:custom')}`,
                    value: 'custom',
                  },
                ]}
                onSelectionChanged={(selectedValue) => {
                  let tillDateLocal = currentKriya.till
                  setSelectedWeekPart(selectedValue)
                  if (selectedValue === '3d') {
                    if (
                      moment(currentKriya.startFrom).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                    ) {
                      if (selectedDatePart !== 'custom') {
                        tillDateLocal = getAddedDate(2)
                      }
                    } else if (selectedDatePart !== 'custom') {
                      tillDateLocal = getAddedDate(3)
                    }
                  }
                  if (selectedValue === '1W') {
                    if (
                      moment(currentKriya.startFrom).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                    ) {
                      if (selectedDatePart === 'custom') {
                        tillDateLocal = moment(
                          moment(currentKriya.startFrom).format('YYYY-MM-DD')
                        )
                          .add(6, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = getAddedDate(6)
                      }
                    } else if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentKriya.startFrom).format('YYYY-MM-DD')
                      )
                        .add(6, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(7)
                    }
                  }
                  if (selectedValue === '2W') {
                    if (
                      moment(currentKriya.startFrom).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                    ) {
                      if (selectedDatePart === 'custom') {
                        tillDateLocal = moment(
                          moment(currentKriya.startFrom).format('YYYY-MM-DD')
                        )
                          .add(13, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = getAddedDate(13)
                      }
                    } else if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentKriya.startFrom).format('YYYY-MM-DD')
                      )
                        .add(13, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(14)
                    }
                  }

                  setCurrentKriya({
                    ...currentKriya,
                    till: tillDateLocal,
                  })
                  onChanged({
                    ...currentKriya,
                    till: tillDateLocal,
                  })
                }}
                preSelectedValue={selectedWeekPart}
              />
              {selectedWeekPart === 'custom' && (
                <TextField
                  key='week_custom'
                  variant='outlined'
                  style={{
                    marginTop: '16px',
                  }}
                  id='custom_week'
                  size='small'
                  onChange={(val) => {
                    setCurrentKriya({
                      ...currentKriya,
                      till: new Date(val.target.value),
                    })
                    onChanged({
                      ...currentKriya,
                      till: new Date(val.target.value),
                    })

                    // setTillDate(new Date(val.target.value))
                  }}
                  type='date'
                  inputProps={{
                    min: moment
                      .utc(currentKriya.startFrom)
                      .local()
                      .format('YYYY-MM-DD'),
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='100%'>
          <WelloFormItemLabel title='Additional Notes' />
          <TextField
            id='txt_kriya_notes'
            variant='outlined'
            size='small'
            fullWidth
            multiline
            value={currentKriya.remarks ?? ''}
            onChange={(e) => {
              setCurrentKriya({
                ...currentKriya,
                remarks: e.target.value,
              })
              onChanged({
                ...currentKriya,
                remarks: e.target.value,
              })
              e.stopPropagation()
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
