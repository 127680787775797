import { Box, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import {
  getGenderOfPatient,
  getStringValueExtensionsOfUrl,
  getValueCoDeFromValueCodeable,
} from 'utils/fhirResourcesHelper'
import { isGenderFemale } from 'utils/fhirResoureHelpers/ipdHelper'
import { ObservationOCAForViewOnlyIPD } from 'views/components/consultation/assessment/ayurvedaAssessment/doshaVikruthiAssemment/dosaAssessmentFromHistoryIPD'
import { ObservationOCAForViewOnly } from 'views/components/consultation/assessment/ayurvedaAssessment/doshaVikruthiAssemment/doshaAssessmentFromHistory'
import { Allergies } from 'views/components/consultation/history/allergies'
import { MedicalConditions } from 'views/components/consultation/history/medical_conditions'
import { Medication } from 'views/components/consultation/history/medication'
import { AyurvedaHistoryAndSubjective } from 'views/components/consultation/history/OPD/ayurvedaHistoryAndSubjective'
import { Procedures } from 'views/components/consultation/history/procedures'
import { AyurvedaHistoryAndSubjectiveIPD } from 'views/components/ipd/ayurvedaHistoryAndSubjectiveIPD'
import { Addictions } from '../history/additctions'
import { Appetite } from '../history/Appetite/appetite'
import { Bowels } from '../history/Bowels/bowels'
import { Diet } from '../history/Diet'
import { FamilyMedicalConditions } from '../history/familyMedicalConditions'
import { Habits } from '../history/habits'
import { MensurationDetails } from '../history/mensuration/mensurations'
import { Micturition } from '../history/Micturition/micturition'
import { Obstrics } from '../history/obstrics/obstricsList'
import { Occupationals } from '../history/occupational'

interface MedicalAndHealthHistoryOfPatientProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const MedicalAndHealthHistoryOfPatient: React.FC<MedicalAndHealthHistoryOfPatientProps> =
  ({ fhirAppointmentDetails }: MedicalAndHealthHistoryOfPatientProps) => {
    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'history' }))
          }
        }}
      >
        <section id='history' style={{ width: '100%' }}>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box py={1}>
                <Typography variant='h6' style={{ fontWeight: 'bolder' }}>
                  {' '}
                  {t('labelCommon:History')}{' '}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <MedicalConditions
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.IPDAppointment}
              requestingAppointmentId={
                fhirAppointmentDetails.mainServiceRequest.id
              }
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitview={false}
              encounterId={
                fhirAppointmentDetails.mainEncounter
                  ? fhirAppointmentDetails.mainEncounter.id ?? ''
                  : ''
              }
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Procedures
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.IPDAppointment}
              requestingAppointmentId={
                fhirAppointmentDetails.mainServiceRequest.id
              }
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitView={false}
              encounterId={
                fhirAppointmentDetails.mainEncounter
                  ? fhirAppointmentDetails.mainEncounter.id ?? ''
                  : ''
              }
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Allergies
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.IPDAppointment}
              requestingAppointmentId={
                fhirAppointmentDetails.mainServiceRequest.id
              }
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitView={false}
              encounterId={
                fhirAppointmentDetails.mainEncounter
                  ? fhirAppointmentDetails.mainEncounter.id ?? ''
                  : ''
              }
            />
          </Box>

          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Medication
              fhirAppointmentDetails={fhirAppointmentDetails}
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.IPDAppointment}
              requestingAppointmentId={
                fhirAppointmentDetails.mainServiceRequest.id
              }
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitView={false}
              orderMedicine={false}
            />
          </Box>

          {isGenderFemale(getGenderOfPatient(fhirAppointmentDetails.patient)) &&
            (getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ).length === 0 ||
              getValueCoDeFromValueCodeable(
                fhirAppointmentDetails.mainServiceRequest.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
              ) === '439809005') && (
              <Box display='flex' flexDirection='row' width='100%' flexGrow>
                <MensurationDetails
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  status={
                    fhirAppointmentDetails.mainTask.businessStatus!.coding
                      ? fhirAppointmentDetails.mainTask.businessStatus!
                          .coding[0].code === 'initiate-discharge' ||
                        fhirAppointmentDetails.mainTask.businessStatus!
                          .coding[0].code === 'discharged' ||
                        fhirAppointmentDetails.mainServiceRequest.status! ===
                          'completed'
                        ? 'completed'
                        : ''
                      : ''
                  }
                  splitView={false}
                />
              </Box>
            )}

          {isGenderFemale(getGenderOfPatient(fhirAppointmentDetails.patient)) &&
            (getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ).length === 0 ||
              getValueCoDeFromValueCodeable(
                fhirAppointmentDetails.mainServiceRequest.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
              ) === '439809005') && (
              <Box display='flex' flexDirection='row' width='100%' flexGrow>
                <Obstrics
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  status={
                    fhirAppointmentDetails.mainTask.businessStatus!.coding
                      ? fhirAppointmentDetails.mainTask.businessStatus!
                          .coding[0].code === 'initiate-discharge' ||
                        fhirAppointmentDetails.mainTask.businessStatus!
                          .coding[0].code === 'discharged' ||
                        fhirAppointmentDetails.mainServiceRequest.status! ===
                          'completed'
                        ? 'completed'
                        : ''
                      : ''
                  }
                  splitView={false}
                />
              </Box>
            )}
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Occupationals
              fhirAppointmentDetails={fhirAppointmentDetails}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              purposeOfUse={PurposeOfUse.IPDAppointment}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Habits
              fhirAppointmentDetails={fhirAppointmentDetails}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitview={false}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Addictions
              fhirAppointmentDetails={fhirAppointmentDetails}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitview={false}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <FamilyMedicalConditions
              fhirAppointmentDetails={fhirAppointmentDetails}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              splitView={false}
            />
          </Box>
          {(getValueCoDeFromValueCodeable(
            fhirAppointmentDetails.mainServiceRequest.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
          ).length === 0 ||
            getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ) === '439809005') && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <Diet
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
                splitview={false}
              />
            </Box>
          )}
          {(getValueCoDeFromValueCodeable(
            fhirAppointmentDetails.mainServiceRequest.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
          ).length === 0 ||
            getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ) === '439809005') && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <Appetite
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
                splitView={false}
              />
            </Box>
          )}
          {(getValueCoDeFromValueCodeable(
            fhirAppointmentDetails.mainServiceRequest.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
          ).length === 0 ||
            getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ) === '439809005') && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <Micturition
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
                splitView={false}
              />
            </Box>
          )}
          {(getValueCoDeFromValueCodeable(
            fhirAppointmentDetails.mainServiceRequest.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
          ).length === 0 ||
            getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ) === '439809005') && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <Bowels
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
                splitView={false}
              />
            </Box>
          )}

          {/* {isGenderFemale(
            getGenderOfPatient(fhirAppointmentDetails.patient)
          ) && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <ObservationOCAForViewOnlyIPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='history'
                formName='History Menstrual History_OCA'
                observationCode='C0025329'
                isReadOnly={false}
              />
            </Box>
          )}
          {isGenderFemale(
            getGenderOfPatient(fhirAppointmentDetails.patient)
          ) && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              flexGrow
              pl={-2}
            >
              <ObservationOCAForViewOnlyIPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='history'
                formName='History Obstetric History_OCA'
                observationCode='C4045976'
                isReadOnly={false}
              />
            </Box>
          )} */}

          {isAyurvedaDoctor() &&
            getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ).length > 0 &&
            getValueCoDeFromValueCodeable(
              fhirAppointmentDetails.mainServiceRequest.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
            ) === '1259939000' && (
              <Box display='flex' flexDirection='row' width='100%' flexGrow>
                <AyurvedaHistoryAndSubjectiveIPD
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  isReadOnly={
                    fhirAppointmentDetails.mainTask.businessStatus!.coding
                      ? !!(
                          fhirAppointmentDetails.mainTask.businessStatus!
                            .coding[0].code === 'initiate-discharge' ||
                          fhirAppointmentDetails.mainTask.businessStatus!
                            .coding[0].code === 'discharged' ||
                          fhirAppointmentDetails.mainServiceRequest.status! ===
                            'completed'
                        )
                      : false
                  }
                />
              </Box>
            )}
          {/* <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <StressLevel
              fhirAppointmentDetails={fhirAppointmentDetails}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
            />
          </Box> */}
        </section>
      </ReactVisibilitySensor>
    )
  }
