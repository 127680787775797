/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { CNSExaminationsList } from './cnsExaminations/cnsSystemExaminationsList'
import { GeExaminationList } from './geExaminations'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './systemicExaminationList'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  onSelected: (categoryLabel: string) => void
  isExpanded?: boolean
  isReadOnly?: boolean
}

export const GeExaminationAccordion: React.FC<CardioVascularExaminationsListProps> =
  ({
    fhirAppointmentDetails,
    onSelected,
    isReadOnly = false,
    isExpanded = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [preselectedObservations, setPreSelectedObservations] = useState<
      R4.IObservation[]
    >([])

    return (
      <Accordion
        square
        expanded={isExpanded}
        onChange={(e) => {
          onSelected('ge')
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          id='systems_cns_accordion_header'
          expandIcon={<ExpandMoreIcon />}
        >
          <Box
            borderRadius={2}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
            px={1}
          >
            <Box
              justifyContent='flex-start'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',
                  fontSize: '13px',
                  fontWeight: 'semibold',
                }}
              >
                Genito-Urinary System
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <GeExaminationList
            fhirAppointmentDetails={fhirAppointmentDetails}
            isReadOnly={isReadOnly}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
