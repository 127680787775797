import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Tooltip,
  Link,
  Grid,
  Divider,
} from '@material-ui/core'
import { Add, Info } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { requestDietHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/dietHistory/dietHistorySlice'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasAddedObsBySameDoc,
  hasAddedObsBySameDocForColor,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getAddButoonShow,
  getBowels,
  getObservationCodeText,
  getObservationContent,
  getRelationFromFamilyResource,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { WelloSubjective } from 'models/WelloConditions'
import _ from 'lodash'
import { getValueRefValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import { doctorMainBackColor } from 'configs/styles/muiThemes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { GroupedOccupation } from 'models/groupedOccupations'
import { HistoryTabularFormatTile } from './historyTabularFormatTile'
import { AddBowelsOPD } from './Bowl/AddBowels'
import { EditBowelsOPD } from './Bowl/editBowlOPD'

interface DietProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  splitView: boolean
}

export const BowelsOPD: React.FC<DietProps> = ({
  fhirAppointmentDetails,
  status,
  splitView,
}: DietProps) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const dietHistorySlice = useSelector(
    (state: RootState) => state.dietHistorySlice
  )
  const [currentCondition, setCurrentCondition] = useState<WelloSubjective>({
    id: _.random(1, 10000000).toString(),
  })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [groupedBowels, setGroupedBowels] = useState<GroupedOccupation[]>([])

  const [showEdit, setShowEdit] = useState<boolean>(false)

  function createUpdateConditionData(conditionData: R4.IObservation) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.valueCodeableConcept
        ? conditionData.valueCodeableConcept.coding &&
          conditionData.valueCodeableConcept.coding!.length > 0
          ? conditionData.valueCodeableConcept.coding[0]
          : undefined
        : undefined,

      existingObs: conditionData,
      notes: getNotesString(conditionData.note),
    })
  }

  function getBowelsData() {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getBowels(
      fhirAppointmentDetails.appointment.id!,
      fhirAppointmentDetails.patient,
      splitView
    )
      .then((e: GroupedOccupation[]) => {
        setLoading(false)
        if (e) {
          setGroupedBowels(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching details. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }
  useEffect(() => {
    getBowelsData()
  }, [])
  return (
    <>
      <Box display='flex' flexDirection='column' flexGrow width='100%'>
        <Box display='flex' flexDirection='row' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Bowels</Typography>
          </Box>
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitview && (
              <IconButton
                style={{ padding: '4px' }}
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
              >
                <Add style={{ height: '14px' }} color='primary' />
              </IconButton>
            )} */}
        </Box>
        {/* {loading === false && (
          <Box>
            {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitView && (
                <Box
                  display='flex'
                  flexGrow
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingBottom={1}
                >
                  <Tooltip title='' id='diet_1'>
                    <IconButton
                      aria-label='btn_ord_cancel'
                      color='primary'
                      id='diet_2'
                      onClick={() => {
                        setOpenAddDialogue(true)
                      }}
                      style={{ padding: 0 }}
                    >
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        component={Link}
                        style={{ fontSize: 13 }}
                        id='diet_4'
                      >
                        {' '}
                        Bowels
                      </Typography>
                      <AddCircleIcon
                        style={{ height: '22px', padding: 0 }}
                        color='primary'
                        id='diet_3'
                      />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )} */}

        {loading === false && groupedBowels && (
          <Box>
            {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitView &&
              getAddButoonShow(
                groupedBowels,
                fhirAppointmentDetails.appointment.id!
              ) === false && (
                <Box
                  display='flex'
                  flexGrow
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingBottom={1}
                >
                  <Tooltip title='' id='diet_1'>
                    <IconButton
                      aria-label='btn_ord_cancel'
                      color='primary'
                      id='diet_2'
                      onClick={() => {
                        setOpenAddDialogue(true)
                      }}
                      style={{ padding: 0 }}
                    >
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        component={Link}
                        style={{ fontSize: 13 }}
                        id='diet_4'
                      >
                        {' '}
                        Bowels
                      </Typography>
                      <AddCircleIcon
                        style={{ height: '22px', padding: 0 }}
                        color='primary'
                        id='diet_3'
                      />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )}

        {loading === false && groupedBowels.length === 0 && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              {!isMedicalServiceProvider() ||
              status === 'completed' ||
              splitView
                ? 'No data available'
                : ''}{' '}
            </Typography>
          </Box>
        )}

        {loading && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {/* {dietHistorySlice.errorWhileSearchingProcedures && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              color='error'
              style={{
                fontWeight: 400,
              }}
            >
              Error while fetching bowels
            </Typography>
          </Box>
        )} */}

        {loading === false && groupedBowels.length > 0 && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {groupedBowels.map((val) => (
                <Box py={1} key={val.date ?? ''}>
                  <Grid container direction='row' id='vitals'>
                    <Grid item xs={12}>
                      <Box
                        paddingX={1}
                        border={1}
                        style={{
                          backgroundColor: hasAddedObsBySameDocForColor(
                            val.occupation
                          )
                            ? 'lightGrey'
                            : doctorMainBackColor,
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                        {hasAddedObsBySameDocForColor(val.occupation) ===
                          false && (
                          <Box
                            display='flex'
                            justifyContent='flex-end'
                            paddingLeft={1}
                            paddingTop={1}
                            paddingRight={2}
                          >
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                          </Box>
                        )}

                        {hasAddedObsBySameDoc(val.occupation[0]) &&
                          getValueRefValueExtensionsOfUrl(
                            val.occupation[0].extension ?? [],
                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                          ).split('/')[1] ===
                            fhirAppointmentDetails.appointment.id! &&
                          splitView === false && (
                            <Box
                              display='flex'
                              justifyContent='flex-end'
                              paddingLeft={1}
                              paddingTop={1}
                              paddingRight={2}
                            >
                              <Tooltip title='Edit' id='chief_tool_edit'>
                                <IconButton
                                  aria-label='btn_ord_cancel'
                                  color='primary'
                                  id='diet_tool_edit_button'
                                  style={{ padding: 0 }}
                                  onClick={() => {
                                    createUpdateConditionData(val.occupation[0])
                                    setShowEdit(true)
                                  }}
                                >
                                  <img
                                    id='chief_tool_edit_img'
                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                    alt='Edit'
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                  <HistoryTabularFormatTile
                    obsData={val.occupation}
                    type='Bowels'
                    onEditClicked={(obs: R4.IObservation) => {
                      createUpdateConditionData(obs)
                      setShowEdit(true)
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      <AddBowelsOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setOpenAddDialogue(false)
          getBowelsData()
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />

      <EditBowelsOPD
        open={showEdit}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setShowEdit(false)
          getBowelsData()
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingDiet={currentCondition}
      />

      {/* <AddMedicalConditionHandler
              open={openAddDialogue}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onMedicalConditionAdded={(condition) => {
                setOpenAddDialogue(false)
                dispatch(
                  requestConditionHistoryOfPatient(
                    fhirAppointmentDetails.appointment.id!,
                    fhirAppointmentDetails.patient
                  )
                )
              }}
              onClose={() => {
                setOpenAddDialogue(false)
              }}
            /> */}
    </>
  )
}
