import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { Diet } from 'models/diet'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestDietPlanDetails } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getAddedDate, getOnlyDate } from 'utils/dateUtil'
import { getCatalogDetailsFromIds } from 'utils/fhirResoureHelpers/catalogHelper'
import { getFixedDietTimingCheck } from 'utils/fhirResoureHelpers/dietPlanHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getBedTime,
  getDinnerTime,
  getEarlyBreakFastTime,
  getEarlyMorningTime,
  getIdsOfSelectedDetails,
  getLunchTime,
  getMidMorningTime,
  getSnackTime,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import OverflowTypography from '../consultation/plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',

      width: '12%',
      overflow: 'ellipse',
    },
  },
})

interface AssessmentCaptureProps {
  diet?: Diet[]
  start: string
  end: string
  preferredItems: string
  itemsToAvoid: string
}

export const DietPrescription: React.FC<AssessmentCaptureProps> = ({
  diet,
  start,
  end,
  preferredItems,
  itemsToAvoid,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const classes = useStyles()

  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('1W')
  const [startDate, setStartDate] = useState<Date>(getOnlyDate(new Date()))
  const [tillDate, setTillDate] = useState<Date>(getAddedDate(7))
  const [showDateCell, setShowDateCell] = useState<boolean>(false)
  const [createdDate, setCreatedDate] = useState<Date | undefined>(undefined)
  const [dietChart, setDietChart] = useState<Diet[]>([
    {
      id: getUniqueTempId(),
      date: new Date().toUTCString(),
      eight: [],
      five: [],
      seven: [],
      six: [],
      twelve: [],
      two: [],
    },
  ])

  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const [preferredFoods, setPreferredFoods] = useState<string | undefined>()
  const [foodsToAvoid, setFoodsToAvoid] = useState<string | undefined>()

  useEffect(() => {
    setDietChart(diet ?? [])
    if (start) {
      const diffStart = moment(start)
        .endOf('day')
        .diff(moment().endOf('day'), 'day')

      if (diffStart === 0) {
        setSelectedDatePart('today')
      } else if (diffStart === 1) {
        setSelectedDatePart('tomo')
      } else {
        setSelectedDatePart('custom')
      }
      setStartDate(moment(start).startOf('day').toDate())
    }
    if (end) {
      setTillDate(moment(end).startOf('day').toDate())
    }
  }, [])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {diet && diet.length > 0 && (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          maxWidth='100%'
          //   style={{
          //     cursor: onClick !== undefined ? 'pointer' : undefined,
          //   }}
          flexGrow={0}
          //   onClick={() => {
          //     if (onClick) {
          //       onClick()
          //     }
          //   }}
        >
          <Box
            paddingX={1}
            borderRadius={2}
            style={{
              backgroundColor: 'lightGrey',
            }}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
          >
            {/* <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                {displayRecordedDate && (
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    Recorded on{' '}
                    {moment(createdDate).format('Do MMMM YYYY hh:mm A')}
                  </Typography>
                )}
              </Box> */}

            <Box
              justifyContent='flex-end'
              display='flex'
              flexDirection='row'
              flexGrow={1}
              alignItems='center'
            >
              <Box
                px={1}
                border={2}
                borderTop={0}
                borderBottom={0}
                borderColor='white'
              >
                {' '}
                <Typography variant='subtitle2' color='initial'>
                  Start Date
                </Typography>
              </Box>
              <Box px={1}>
                {' '}
                <Typography variant='body2' color='initial'>
                  {start ? moment(start).format('DD-MM-YYYY') : ''}
                </Typography>
              </Box>
              <Box
                px={1}
                border={2}
                borderTop={0}
                borderBottom={0}
                borderColor='white'
              >
                {' '}
                <Typography variant='subtitle2' color='initial'>
                  Till Date
                </Typography>
              </Box>
              <Box px={1}>
                {' '}
                <Typography variant='body2' color='initial'>
                  {end ? moment(end).format('DD-MM-YYYY') : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            style={{
              width: '100%',
              margin: '0px',
              padding: '0px',
            }}
          >
            <Table className={classes.table} aria-label='simple table'>
              <TableHead
                style={{
                  backgroundColor: kDialogueBackground,
                }}
              >
                <TableRow
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {getFixedDietTimingCheck(diet) === false && (
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Date
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getEarlyMorningTime(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getEarlyBreakFastTime(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getMidMorningTime(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getLunchTime(dietTimeSearchSlice.prSelectedCoding ?? [])}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getSnackTime(dietTimeSearchSlice.prSelectedCoding ?? [])}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getDinnerTime(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      )}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {' '}
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      {getBedTime(dietTimeSearchSlice.prSelectedCoding ?? [])}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dietChart.map((row) => (
                  <TableRow key={row.date}>
                    {getFixedDietTimingCheck(diet) === false && (
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {moment(row.date).format('DD-MM-YYYY')} (
                          {moment(row.date).format('dddd')})
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.six ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.eight ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.twelve ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.two ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.five ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.seven ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {getCatalogDetailsFromIds(
                          addDietSlice.catalogueEntries ?? [],
                          getIdsOfSelectedDetails(row.nine ?? []) ?? ''
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell width='12%'>
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Preferred items
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <OverflowTypography
                      text={preferredItems ?? ''}
                      typographyProps={{
                        variant: 'subtitle2',
                        noWrap: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Items to avoid
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <OverflowTypography
                      text={itemsToAvoid ?? ''}
                      typographyProps={{
                        variant: 'subtitle2',
                        noWrap: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  )
}
