/* eslint-disable no-irregular-whitespace */
import { Grid, Typography, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import 'react-vis/dist/style.css'
import { RootState } from 'redux/rootReducer'
import { isCurrentUnitIsClinic } from 'services/userDetailsService'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { showDietTreatment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { AppointmentTreatmentCard } from '../ipdconusltation/treatment/appointmentTreatmentCard'
import { TreatmentTileOPD } from '../ipdviewData/treatmentTileForOPD'
import { AssessDetailsExam } from './assessment/assesmentExam'
import { AssessDetails } from './assessment/assessDetails'
import { AssessmentCaptureDetails } from './assessment/assessmentCaptureDetails'
import { DietTreatmentCard } from './assessment/dietTreatmentCard'

interface AssessmentPartOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const AssessmentPartOfConsultation: React.FC<AssessmentPartOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: AssessmentPartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    const unitsOfOrganizationSlice = useSelector(
      (state: RootState) => state.unitsOfOrganizationSlice
    )

    return (
      <Grid container spacing={1} direction='column'>
        {showDietTreatment(unitsOfOrganizationSlice.unitServices ?? []) ===
          true && (
          <Grid>
            {fhirAppointmentDetails.encounter && split === false && (
              <Box width='100%'>
                <AppointmentTreatmentCard
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  status='active'
                />
              </Box>
            )}
            {fhirAppointmentDetails.encounter && split === true && (
              <Box width='100%'>
                <TreatmentTileOPD
                  fhirAppointmentDetails={fhirAppointmentDetails}
                />
              </Box>
            )}
          </Grid>
        )}

        <Grid>
          {fhirAppointmentDetails.encounter && (
            <AssessmentCaptureDetails
              encounter={fhirAppointmentDetails.encounter}
              patient={fhirAppointmentDetails.patient}
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          )}
        </Grid>
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid>
            <AssessDetails
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Grid>
        )}
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid>
            <AssessDetailsExam
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Grid>
        )}
      </Grid>
    )
  }
