import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetState } from 'redux/cohorts/cohortSearchSlice'
import { resetPatientSearchPatient } from 'redux/patient/patientSearch/patientSearchSlice'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import {
  getAddress,
  getAgeOfPatientData,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getNameOfPatient,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  getCarePlanId,
  getPatientFromContainedResource,
  getPractitionerFromContainedResource,
  getUnitFromContainedResource,
} from 'utils/fhirResoureHelpers/referralHelpers'
import {
  cancelTherapy,
  getTherapiesDetails,
  updateTherapyPerformDetails,
} from 'utils/fhirResoureHelpers/therapiesHelper'
import { logger } from 'utils/logger'
import { AddNewPatient } from 'views/components/add_new_patient'
import { ConsultationManagement } from 'views/components/ipdAppointment/consultation/consultationManagement'
import { CreateIpdAppointmentHandler } from 'views/components/ipdAppointment/createIpdAppoitmentManagement'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { PatientChecker } from 'views/components/patients/patientChecker'

interface Props {
  ipdAppointmentDetails: R4.IServiceRequest
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
  onManageClicked: () => void
  onDataUpdated?: () => void
}

export const ReferralDisplayTile: React.FC<Props> = ({
  ipdAppointmentDetails,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
  onManageClicked,
  onDataUpdated,
}: Props) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  //   const [showText, setShowText] = React.useState<boolean>(false)
  //   const [fullText, setFullText] = React.useState<string>()
  const [visitDetails, setVisitDetails] = useState<R4.IServiceRequest>(
    ipdAppointmentDetails
  )
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false)
  const [showCheckPatient, setShowCheckPatient] = useState<boolean>(false)
  const [showCreateIpd, setShowCreateIpd] = useState<boolean>(false)
  const [showCreateOpd, setShowCreateOpd] = useState<boolean>(false)
  const [showCreateDayCare, setShowCreateDayCare] = useState<boolean>(false)
  const [createdPatientDetails, setCreatedPatient] = useState<R4.IPatient>()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const [appointmentType, setAppointmentType] =
    useState<'ipd' | 'opd' | 'dayCare' | undefined>()
  const [fetchingTherapyDetails, setFetchingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })
  const [updatingTherapyDetails, setUpdatingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [plan, setPlan] = useState<TreatmentPlanData>()

  function fetchAppointmentDetails() {
    /*  if (ipdAppointmentDetails.mainServiceRequestId)
      setFetchingStatus({
        requesting: true,
      })

    getVisitDetailsForTherapist(ipdAppointmentDetails.mainServiceRequestId)
      .then((e) => {
        if (e) {
          if ('mainServiceRequest' in e) {
            setVisitDetails(e as FhirActiveIPDDetailsForMedicalRole)

            setFetchingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      }) */
  }

  function fetchTherapyDetails() {
    if (ipdAppointmentDetails.id)
      setFetchingTherapyDetails({
        requesting: true,
      })

    getTherapiesDetails(ipdAppointmentDetails.id ?? '')
      .then((e) => {
        if (e) {
          if ('date' in e) {
            setPlan(e)

            setFetchingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function updateTherapyDetails(
    appointment: FhirActiveIPDDetailsForMedicalRole,
    treatMent: TreatmentPlanData,
    selectedPractWithRole: PractitionerWithRole[]
  ) {
    if (ipdAppointmentDetails.id)
      setUpdatingTherapyDetails({
        requesting: true,
      })

    updateTherapyPerformDetails(appointment, treatMent, selectedPractWithRole)
      .then((e) => {
        if (e !== undefined) {
          if (e) {
            setUpdatingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
          fetchTherapyDetails()
        } else {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setUpdatingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function cancelTherapyDetails(
    appointment: FhirActiveIPDDetailsForMedicalRole,
    treatMent: TreatmentPlanData,
    cancellationReason: R4.ICoding
  ) {
    if (ipdAppointmentDetails.id)
      setUpdatingTherapyDetails({
        requesting: true,
      })

    cancelTherapy(appointment, treatMent, cancellationReason)
      .then((e) => {
        if (e !== undefined) {
          if (e) {
            setUpdatingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
          fetchTherapyDetails()
        } else {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setUpdatingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    fetchAppointmentDetails()
  }, [])

  useEffect(() => {
    fetchTherapyDetails()
  }, [])

  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignContent='center'
            p={0.5}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='15%'
            >
              <Typography variant='body1' color='initial'>
                {getCompleteDateStringWithOutDay(visitDetails.authoredOn ?? '')}
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignContent='flex-start'
              width='20%'
              px={1}
            >
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='start'
                  px={0.5}
                >
                  <Typography variant='body2' color='initial'>
                    Patient :
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {getNameOfPatient(
                      getPatientFromContainedResource(visitDetails)!
                    )}{' '}
                    {getMiddleNameOfPatient(
                      getPatientFromContainedResource(visitDetails)!
                    )}{' '}
                    {getLastNameOfPatient(
                      getPatientFromContainedResource(visitDetails)!
                    )}
                  </Typography>

                  <Box py={0.5}>
                    {getPatientFromContainedResource(visitDetails)?.gender ===
                    R4.PatientGenderKind._male ? (
                      <Typography
                        component='span'
                        variant='subtitle1'
                        color='initial'
                      >
                        {' '}
                        M /
                      </Typography>
                    ) : (
                      <Typography
                        component='span'
                        variant='subtitle1'
                        color='initial'
                      >
                        {' '}
                        F /
                      </Typography>
                    )}
                    {getPatientFromContainedResource(visitDetails) && (
                      <Typography
                        component='span'
                        variant='subtitle1'
                        color='initial'
                      >
                        {` ${getAgeOfPatientData(
                          getPatientFromContainedResource(visitDetails)!
                        )}`}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {getPatientFromContainedResource(visitDetails) && (
                      <Typography
                        component='span'
                        variant='subtitle1'
                        color='initial'
                      >
                        {` ${getTelecomFromContactPoints(
                          getPatientFromContainedResource(visitDetails)!
                            .telecom ?? [],
                          R4.ContactPointSystemKind._phone
                        )}`}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='Column'
              alignContent='start'
              alignItems='start'
              width='20%'
              paddingLeft={5}
              paddingRight={5}
            >
              <Typography variant='body2' color='initial'>
                Referred By :
              </Typography>

              <Typography variant='subtitle1'>
                {getNameFromHumanName(
                  getPractitionerFromContainedResource(visitDetails)?.name ?? []
                )}

                {}
              </Typography>

              <Typography variant='subtitle1'>
                {getUnitFromContainedResource(visitDetails)?.name}
              </Typography>

              <Typography variant='subtitle1'>
                {getAddress(
                  getUnitFromContainedResource(visitDetails)?.address
                )}
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='Column'
              alignContent='start'
              alignItems='start'
              width='20%'
              paddingLeft={5}
              paddingRight={5}
            >
              <Typography variant='body2' color='initial'>
                Notes
              </Typography>

              {visitDetails.patientInstruction && (
                <Typography
                  variant='body1'
                  color='initial'
                  style={{ paddingLeft: 2, cursor: 'pointer' }}
                  //   component=''
                  onClick={() => {
                    if (
                      visitDetails.patientInstruction &&
                      visitDetails.patientInstruction.length > 60
                    ) {
                      setFullText(visitDetails.patientInstruction)
                      setShowText(true)
                    }
                  }}
                >
                  {visitDetails.patientInstruction.slice(0, 60)}
                  {visitDetails.patientInstruction.length > 60 ? '...' : null}
                </Typography>
              )}
              {/* <Typography variant='subtitle1' color='initial'>
                {visitDetails.patientInstruction ?? '-'}
              </Typography> */}
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='20%'
            >
              {visitDetails.status === 'active' && (
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='row'
                  justifyContent='flex-end'
                >
                  <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      setAppointmentType('ipd')
                      setShowCheckPatient(true)
                      // setShowTherapyPerformDialog(true)
                    }}
                  >
                    Admit for IPD
                  </Button>

                  <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      setAppointmentType('dayCare')
                      setShowCheckPatient(true)
                      // setShowTherapyPerformDialog(true)
                    }}
                  >
                    Admit for Day Care
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
      />
      {showAddPatient && (
        <AddNewPatient
          isLab={false}
          isAppointment={true}
          popup={showAddPatient}
          isMinimal={false}
          patientDetail={getPatientFromContainedResource(visitDetails)}
          onPatientCreated={(
            createdPatient?: R4.IPatient,
            patientId?: string
          ) => {
            dispatch(showSuccessAlert('Patient added successfully'))
            logger.info('Created Patient, ', createdPatient)
            if (createdPatient) {
              if (patientId) {
                const oldPatient: R4.IPatient = {
                  ...createdPatient,
                }
                const patId: string = patientId.split('/')[1]
                oldPatient.id = patId
                setCreatedPatient(oldPatient)
                setShowAddPatient(false)
                if (appointmentType === 'ipd') {
                  setShowCreateIpd(true)
                } else if (appointmentType === 'dayCare') {
                  setShowCreateDayCare(true)
                }
                // onPatientSelected(oldPatient)
              }
            }

            /* if (onPatientSelected && createdPatient) {
              onPatientSelected(createdPatient);
            } */
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )
            dispatch(resetState())
            dispatch(resetPatientSearchPatient())
            setShowAddPatient(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            dispatch(resetPatientSearchPatient())
            setShowAddPatient(false)
          }}
        />
      )}

      {showCheckPatient && (
        <PatientChecker
          id='patient_checker'
          onClose={() => {
            setShowCheckPatient(false)
          }}
          onNewPatientCreated={() => {
            setShowCheckPatient(false)
            setShowAddPatient(true)
          }}
          onNoPatientsAvailable={() => {
            setShowCheckPatient(false)
            setShowAddPatient(true)
          }}
          onPatientSelected={(e) => {
            setShowCheckPatient(false)
            setCreatedPatient(e)
            if (appointmentType === 'ipd') {
              setShowCreateIpd(true)
            } else if (appointmentType === 'dayCare') {
              setShowCreateDayCare(true)
            }
          }}
          open={showCheckPatient}
          patientData={getPatientFromContainedResource(visitDetails)!}
        />
      )}

      {showCreateIpd && (
        <CreateIpdAppointmentHandler
          open={showCreateIpd}
          preselectedPatient={createdPatientDetails}
          referralId={visitDetails.id}
          carePlanId={getCarePlanId(visitDetails)}
          id={2}
          onLabOrderCreated={(orderId: string, paymentType: string) => {
            if (paymentType === 'online') {
              // setDisplayId(orderId)
              setShowCreateIpd(false)
            } else {
              setShowCreateIpd(false)
            }
            if (onDataUpdated) {
              onDataUpdated()
            }
          }}
          onClose={() => {
            setShowCreateIpd(false)
          }}
        />
      )}

      {showCreateOpd && (
        <ConsultationManagement
          open={showCreateOpd}
          preselectedPatient={createdPatientDetails}
          referralId={visitDetails.id}
          id={2}
          onLabOrderCreated={(orderId: string, paymentType: string) => {
            if (paymentType === 'online') {
              // setDisplayId(orderId)
              setShowCreateOpd(false)
            } else {
              setShowCreateOpd(false)
            }
            if (onDataUpdated) {
              onDataUpdated()
            }
          }}
          onClose={() => {
            setShowCreateOpd(false)
          }}
        />
      )}

      {/* <NoteDialog
        id='treatment_notes_therapist'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      /> */}
    </Box>
  )
}
