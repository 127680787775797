import {
  Avatar,
  Box,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import SimpleBar from 'simplebar-react'
import { getSpecializationsList } from 'utils/fhirResourcesHelper'
import { TopMenu } from './TopMenu'

const useStyles = makeStyles({
  root: {
    '&$selected': {
      backgroundColor: '#7986CB',
      mixBlendMode: 'color-burn',
      '&:hover': {
        backgroundColor: '#7986CB',
        mixBlendMode: 'color-burn',
      },
    },
  },
  selected: {},
})

export interface MultiSelectWithSearchOptionProps {
  availableOptions?: any[]
  displayField?: string
  onOptionsClicked?: () => void
  onValuesChanged: (practitioner: any) => void
  id?: string
  isSelected?: boolean
  placeholderLabel?: string
  valueField?: string
  selectedSchedule?: any
  selectedDoctor?: PractitionerWithRole
}

export const DoctorSelectWithSearch: React.FC<MultiSelectWithSearchOptionProps> =
  ({
    availableOptions,
    onValuesChanged,
    onOptionsClicked,
    id,
    isSelected,
    placeholderLabel,
    displayField,
    valueField,
    selectedSchedule,
    selectedDoctor,
  }: MultiSelectWithSearchOptionProps) => {
    const classes = useStyles()
    const [selectedOptions, setSelectedOptions] = React.useState<any>(
      availableOptions ? availableOptions[0].id : ''
    )
    //   if (availableOptions) setSelectedOptions(availableOptions[0].id)
    const [searchTerm, setSearchTerm] = React.useState('')
    const [searchResults, setSearchResults] = React.useState<any>([])
    const [name, setName] = React.useState('')
    const nonSelectedTextColor = 'blue'
    const nonSelectedBodyTextColor = 'black'
    React.useEffect(() => {
      if (availableOptions) {
        const results = availableOptions.filter((item) => {
          if (displayField) {
            return (item.name as string)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          }
          return (item as string).toLowerCase().includes(searchTerm)
        })

        setSearchResults(results)
      }
    }, [searchTerm])

    function isItemSelected(item: any): boolean {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (selectedOptions) selectedOptions
      // eslint-disable-next-line no-lone-blocks
      {
        if (item.id === selectedOptions) {
          isSelected = true

          return true
        }
        return false
      }
    }

    useEffect(() => {
      if (onValuesChanged) {
        onValuesChanged(availableOptions ? availableOptions[0] : '')
      }
    }, [])

    return (
      <Box display='flex' flexDirection='column'>
        <SimpleBar
          style={{
            height: '100%',
            overflowX: 'hidden',
            padding: '4px',
          }}
        >
          <Box width='100%'>
            <Box
              style={{
                backgroundColor: useTheme().palette.common.white,
                opacity: 0.8,
              }}
              p={1}
            >
              <Box>
                <TopMenu />
              </Box>
              <TextField
                fullWidth={true}
                variant='outlined'
                margin='dense'
                color='primary'
                type='search'
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
                id={id}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                style={{ backgroundColor: 'white', borderRadius: 4 }}
                placeholder={placeholderLabel ?? 'Search'}
              />
            </Box>

            <Box
              width='100%'
              flexShrink={1}
              maxHeight={window.innerHeight / 1.34}
              overflow='auto'
            >
              <List
                style={{
                  overflowY: 'auto',
                  overflowX: 'auto',
                }}
              >
                {searchResults &&
                  searchResults.map((item: any) => (
                    <>
                      <ListItem
                        id={
                          valueField
                            ? (item[valueField] as string)
                            : (item as string)
                        }
                        key={valueField ? item[valueField] : item}
                        alignItems='center'
                        selected={isItemSelected(item)}
                        style={{ cursor: 'pointer', marginBottom: 0 }}
                        classes={{
                          root: classes.root,
                          selected: classes.selected,
                        }}
                        onClick={(e) => {
                          if (selectedDoctor !== undefined)
                            if (selectedDoctor.id !== item.id) {
                              if (onValuesChanged) {
                                setSelectedOptions(item.id)
                                onValuesChanged(item)
                              }
                            }
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src='test.jpg' />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                variant='subtitle1'
                                style={{
                                  position: 'relative',
                                  // zIndex: 100,
                                  color: isSelected
                                    ? kPrimaryMain
                                    : kPrimaryMain,
                                }}
                              >
                                {item.name}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <Typography
                                component='span'
                                variant='subtitle2'
                                style={{
                                  position: 'relative',
                                  // zIndex: 100,
                                  color: isSelected
                                    ? nonSelectedBodyTextColor
                                    : nonSelectedBodyTextColor,
                                }}
                              >
                                {getSpecializationsList(
                                  item.roleObject?.specialty ?? []
                                )}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </>
                  ))}
              </List>
            </Box>
          </Box>
        </SimpleBar>
      </Box>
    )
  }
