import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
  Info,
} from '@material-ui/icons'
import AodIcon from '@mui/icons-material/Aod'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  doctorMainBackColor,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedProceDure, GroupedProcedureData } from 'models/groupedProcedure'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestProcedureHistoryOfPatient } from 'redux/patientMedicalHistory/proceduresHistory/proceduresHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeCodeData, getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasAddedBySameDoctor,
  hasAddedBySameDoctorProcedure,
  hasAddedBySameDoctorProcedureForColor,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateProcedureDetail } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getValueCoDeFromValueCodeableForSoapNotes,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { WelloCondition } from 'models/WelloConditions'
import _ from 'lodash'
import { AddProceduresHandler } from '../addition/addProcedureDetails'
import OverflowTypography from '../plan/ayurveda/overflowTypography'
import { EditProcedure } from '../edition/editProcedure'

interface ProceduresProp {
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  requestingAppointmentId?: string
  status: string
  splitView: boolean
  encounterId?: string
}

export const Procedures: React.FC<ProceduresProp> = ({
  patient,
  requestingAppointmentId,
  purposeOfUse,
  status,
  splitView,
  encounterId,
}: ProceduresProp) => {
  const { t } = useTranslation(['common', 'procedure'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const procedureHistorySlice = useSelector(
    (state: RootState) => state.procedureHistorySlice
  )
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [groupVitals, setGroupVitals] = useState<GroupedProcedureData[]>([])
  const [groupVitalsWithDate, setGroupVitalsWithDate] = useState<
    GroupedProcedureData[]
  >([])
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [showEdit, setShowEdit] = useState<boolean>(false)

  const [currentCondition, setCurrentCondition] = useState<WelloCondition>({
    id: _.random(1, 10000000).toString(),
    selectedType: undefined,
    days: 1,
    month: 1,
    year: 1,
    week: 1,
    selectedDate: new Date(),
    isActive: true,
  })

  function createUpdateConditionData(conditionData: R4.IProcedure) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.code
        ? conditionData.code.coding && conditionData.code.coding.length > 0
          ? conditionData.code.coding[0]
          : undefined
        : undefined,
      selectedType:
        conditionData.performedDateTime || conditionData.performedPeriod
          ? 'custom'
          : getAgeOf(conditionData.performedAge).split(' ')[1].toLowerCase(),
      days: conditionData.performedDateTime
        ? 1
        : getAgeCodeData(conditionData.performedAge) === 'd'
        ? parseInt(getAgeOf(conditionData.performedAge).split(' ')[0], 10)
        : 1,
      week: conditionData.performedDateTime
        ? 1
        : getAgeCodeData(conditionData.performedAge) === 'wk'
        ? parseInt(getAgeOf(conditionData.performedAge).split(' ')[0], 10)
        : 1,
      year: conditionData.performedDateTime
        ? 1
        : getAgeCodeData(conditionData.performedAge) === 'a'
        ? parseInt(getAgeOf(conditionData.performedAge).split(' ')[0], 10)
        : 1,
      month: conditionData.performedDateTime
        ? 1
        : getAgeCodeData(conditionData.performedAge) === 'mo'
        ? parseInt(getAgeOf(conditionData.performedAge).split(' ')[0], 10)
        : 1,
      selectedDate: conditionData.performedDateTime
        ? moment(conditionData.performedDateTime).toDate()
        : new Date(),
      existingProcedure: conditionData,
      notes: getNotesString(conditionData.note),
    })
  }

  const [loading, setLoading] = useState<boolean>(false)

  function acceptProcedureDetails(procedure: R4.IProcedure) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IProcedure = {
      ...procedure,
    }
    setLoading(true)
    oldProcedure.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext',
        valueCodeableConcept: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
              code: 'confirmed',
              display: 'Confirmed',
            },
          ],
          text: 'Confirmed',
        },
      },
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${requestingAppointmentId!}`,
        },
      },
    ]
    updateProcedureDetail(oldProcedure, requestingAppointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Procedure details updated successfully'))
        dispatch(
          requestProcedureHistoryOfPatient(
            patient,
            purposeOfUse,
            splitView,
            requestingAppointmentId
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Procedure details. Please try again later'
          )
        )
      }
    })
  }

  function rejectProcedure(procedure: R4.IProcedure) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IProcedure = {
      ...procedure,
    }
    setLoading(true)
    oldProcedure.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext',
        valueCodeableConcept: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
              code: 'refuted',
              display: 'Refuted',
            },
          ],
          text: 'Refuted',
        },
      },
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${requestingAppointmentId!}`,
        },
      },
    ]
    updateProcedureDetail(oldProcedure, requestingAppointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Procedure details updated successfully'))
        dispatch(
          requestProcedureHistoryOfPatient(
            patient,
            purposeOfUse,
            splitView,
            requestingAppointmentId
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Procedure details. Please try again later'
          )
        )
      }
    })
  }

  useEffect(() => {
    dispatch(
      requestProcedureHistoryOfPatient(
        patient,
        purposeOfUse,
        splitView,
        requestingAppointmentId
      )
    )
  }, [dispatch, patient])

  useEffect(() => {
    if (
      procedureHistorySlice.resultsAvailable &&
      procedureHistorySlice.dateWiseProceDureList
    ) {
      updateProcedureList(procedureHistorySlice.dateWiseProceDureList)
    }

    if (
      procedureHistorySlice.resultsAvailable &&
      procedureHistorySlice.dateWiseProceDureListWithDate
    ) {
      updateProcedureListwithDate(
        procedureHistorySlice.dateWiseProceDureListWithDate
      )
    }
  }, [procedureHistorySlice])

  function updateProcedureList(procedureList: GroupedProcedureData[]) {
    const results: GroupedProcedureData[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        date: procedureList[i].date,
        condition: procedureList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function updateProcedureListwithDate(procedureList: GroupedProcedureData[]) {
    const results: GroupedProcedureData[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        date: procedureList[i].date,
        condition: procedureList[i].condition,
        checked: true,
      })
    }
    setGroupVitalsWithDate(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedProcedureData[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }

  function handleCollapseForPanel1WithDate(rate: boolean, index: number) {
    const values: GroupedProcedureData[] = [...groupVitalsWithDate]
    values[index].checked = rate
    setGroupVitalsWithDate(values)
  }
  return (
    <>
      <Box
        width='100%'
        flexDirection='column'
        display='flex'
        style={{ padding: 0, margin: 0 }}
      >
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Box flexDirection='row' display='flex' width='100%'>
            <Box py={1}>
              <Typography variant='subtitle1'>
                {' '}
                {t('labelCommon:surgery')}{' '}
              </Typography>
            </Box>{' '}
            {/* {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitView && (
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                >
                  <Add style={{ height: '14px' }} color='primary' />
                </IconButton>
              )} */}
          </Box>
        </Box>
        {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            paddingBottom={1}
          >
            <Tooltip title='' id='proc_1'>
              <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                id='proc_2'
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
                style={{ padding: 0 }}
              >
                <Typography
                  variant='subtitle2'
                  color='primary'
                  component={Link}
                  id='proc_4'
                >
                  {' '}
                  {t('labelCommon:surgery')}{' '}
                </Typography>
                <AddCircleIcon
                  style={{ height: '22px', padding: 0 }}
                  color='primary'
                  id='proc_3'
                />{' '}
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {procedureHistorySlice.searchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {procedureHistorySlice.noResultsAvailable && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              {!isMedicalServiceProvider() ||
              status === 'completed' ||
              splitView
                ? 'No data available'
                : ''}
            </Typography>
          </Box>
        )}
        {procedureHistorySlice.errorWhileSearchingProcedures && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              color='error'
              style={{
                fontWeight: 400,
              }}
            >
              {procedureHistorySlice.errorReason ??
                'Error while fetching surgery'}
            </Typography>
          </Box>
        )}
        {procedureHistorySlice.resultsAvailable &&
          procedureHistorySlice.procedureList && (
            <Box width='100%' display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%'>
                {groupVitals.map((val, index: number) => (
                  <Box
                    key={`med_cond${val.date}`}
                    width='100%'
                    py={1}
                    id={`med_cond${val.date}`}
                  >
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: hasAddedBySameDoctorProcedureForColor(
                          val.condition
                        )
                          ? 'lightGrey'
                          : doctorMainBackColor,
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        alignItems='center'
                        width='70%'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box
                        justifyContent='flex-end'
                        display='flex'
                        alignItems='center'
                        width='30%'
                      >
                        {hasAddedBySameDoctorProcedureForColor(
                          val.condition
                        ) === false && (
                          <Box paddingRight={1} justifyContent='flex-end'>
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                          </Box>
                        )}
                        <Box px={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                              tabIndex={0}
                              onClick={() => {
                                handleCollapseForPanel1(!val.checked, index)
                              }}
                            >
                              {val.checked && <ArrowDropUpOutlined />}
                              {!val.checked && <ArrowDropDownOutlined />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>

                    <Collapse
                      in={val.checked}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '3px 0px',
                          borderRadius: '4px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                        key={`med_cond${val.date}`}
                        id={`med_cond${val.date}`}
                      >
                        <Grid
                          direction='column'
                          container
                          id={`${moment(val.date!).format(
                            'DD-MM-YYYY'
                          )}column${index}`}
                          wrap='nowrap'
                        >
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='98%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    height={30}
                                    alignItems='center'
                                    paddingLeft={1}
                                    width='100%'
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Surgery
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Performed
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={7}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Additional Notes
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          {val.condition.map((valData, indexFinal: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              key={valData.id}
                              id={`${moment(val.date!).format(
                                'DD-MM-YYYY'
                              )}columnData${indexFinal}`}
                              width='100%'
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={1}
                                id={`${moment(val.date!).format(
                                  'DD-MM-YYYY'
                                )}columnSingle${indexFinal}`}
                                wrap='nowrap'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id={`${moment(val.date!).format(
                                    'DD-MM-YYYY'
                                  )}rowData${indexFinal}`}
                                  wrap='nowrap'
                                >
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  direction='row'
                                  id={`${moment(val.date!).format(
                                    'DD-MM-YYYY'
                                  )}rawData${indexFinal}`}
                                  style={{
                                    backgroundColor:
                                      indexFinal % 2
                                        ? kDialogueBackground
                                        : 'white',
                                  }}
                                  wrap='nowrap'
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}procedure${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='98%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                          width='100%'
                                        >
                                          {valData.meta &&
                                            valData.meta.security &&
                                            valData.meta.security[0].code ===
                                              'PATRPT' && (
                                              <Box paddingRight={1}>
                                                <Tooltip title=''>
                                                  <IconButton
                                                    aria-label='collapse_order_type'
                                                    size='small'
                                                  >
                                                    <AodIcon color='primary' />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            )}
                                          {valData.code &&
                                            valData.code!.coding &&
                                            valData.code!.coding.length > 0 &&
                                            valData.code!.coding![0]
                                              .display && (
                                              <OverflowTypography
                                                text={
                                                  valData.code!.coding![0]
                                                    .display!
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'sur1',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>

                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}onSet${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                            id='sur2'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              //   color: 'black',

                                              //   fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {valData.performedDateTime ||
                                            valData.performedPeriod
                                              ? `${moment(
                                                  valData.performedDateTime ??
                                                    new Date()
                                                ).format('DD-MM-YYYY')}`
                                              : `${getAgeOf(
                                                  valData.performedAge
                                                )} ago`}
                                          </Typography>
                                        </Box>

                                        {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.2}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',
                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={7}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}notes${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        justifyContent='flex-start'
                                        width={
                                          splitView === false ||
                                          status !== 'completed'
                                            ? '90%'
                                            : '90%'
                                        }
                                        paddingLeft={1}
                                        paddingTop={1}
                                      >
                                        {hasNotes(valData.note) && (
                                          <OverflowTypography
                                            text={getNotesString(valData.note)}
                                            typographyProps={{
                                              variant: 'subtitle2',
                                              color: 'initial',
                                              id: 'sur3',
                                            }}
                                          />
                                        )}

                                        {valData.meta &&
                                          valData.meta.security &&
                                          valData.meta.security[0].code ===
                                            'PATRPT' &&
                                          valData.status &&
                                          valData.status !== 'completed' &&
                                          splitView === false &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          getValueCoDeFromValueCodeableForSoapNotes(
                                            valData.extension ?? [],
                                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext'
                                          ) === 'unconfirmed' && (
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='25%'
                                              paddingTop={0.5}
                                              paddingLeft={2}
                                            >
                                              <Button
                                                onClick={() => {
                                                  acceptProcedureDetails(
                                                    valData
                                                  )
                                                }}
                                                size='small'
                                                id='accept'
                                                disableElevation
                                                variant='contained'
                                                color='primary'
                                                disabled={loading}
                                              >
                                                Accept
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  rejectProcedure(valData)
                                                }}
                                                size='small'
                                                variant='outlined'
                                                id='reject'
                                                disableElevation
                                                disabled={loading}
                                              >
                                                Reject
                                              </Button>
                                            </Box>
                                          )}
                                      </Box>
                                      <Box
                                        display='flex'
                                        justifyContent='flex-end'
                                        paddingRight={3}
                                        width='10%'
                                      >
                                        {hasAddedBySameDoctorProcedure(
                                          valData
                                        ) &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          splitView === false && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              paddingLeft={1}
                                              paddingTop={1}
                                            >
                                              <Tooltip title='Edit' id='sur4'>
                                                <IconButton
                                                  aria-label='btn_ord_cancel'
                                                  color='primary'
                                                  id='procedure_tool_edit_button'
                                                  style={{ padding: 0 }}
                                                  onClick={() => {
                                                    createUpdateConditionData(
                                                      valData
                                                    )
                                                    setShowEdit(true)
                                                    // setChiefComplaintsDetails(val)
                                                  }}
                                                >
                                                  <img
                                                    id='sur5'
                                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                    alt='Edit'
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    </Collapse>
                  </Box>
                ))}

                {groupVitalsWithDate.map((val, index: number) => (
                  <Box key={`med_cond${val.date}`} width='100%' py={1}>
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: hasAddedBySameDoctorProcedureForColor(
                          val.condition
                        )
                          ? 'lightGrey'
                          : doctorMainBackColor,
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        alignItems='center'
                        width='70%'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box
                        justifyContent='flex-end'
                        display='flex'
                        alignItems='center'
                        width='30%'
                      >
                        {hasAddedBySameDoctorProcedureForColor(
                          val.condition
                        ) === false && (
                          <Box paddingRight={1} justifyContent='flex-end'>
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                          </Box>
                        )}
                        <Box px={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                              onClick={() => {
                                handleCollapseForPanel1WithDate(
                                  !val.checked,
                                  index
                                )
                              }}
                            >
                              {val.checked && <ArrowDropUpOutlined />}
                              {!val.checked && <ArrowDropDownOutlined />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>

                    <Collapse
                      in={val.checked}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '3px 0px',
                          borderRadius: '4px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                        key={`med_cond${val.date}`}
                      >
                        <Grid direction='column' container>
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                            wrap='nowrap'
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    minHeight={30}
                                    maxHeight={100}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Surgery
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    minHeight={30}
                                    maxHeight={100}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Performed On
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={7}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    minHeight={30}
                                    maxHeight={100}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Additional Notes
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          {val.condition.map((valData, indexFinal: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              key={valData.id}
                              width='100%'
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={1}
                                wrap='nowrap'
                              >
                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  direction='row'
                                  id='v1'
                                  style={{
                                    backgroundColor:
                                      indexFinal % 2
                                        ? kDialogueBackground
                                        : 'white',

                                    flexWrap: 'nowrap',
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='98%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.meta &&
                                            valData.meta.security &&
                                            valData.meta.security[0].code ===
                                              'PATRPT' && (
                                              <Box paddingRight={1}>
                                                <Tooltip title=''>
                                                  <IconButton
                                                    aria-label='collapse_order_type'
                                                    size='small'
                                                  >
                                                    <AodIcon color='primary' />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            )}
                                          {valData.code &&
                                            valData.code!.coding &&
                                            valData.code!.coding.length > 0 &&
                                            valData.code!.coding![0]
                                              .display && (
                                              <OverflowTypography
                                                text={
                                                  valData.code!.coding![0]
                                                    .display!
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'sur6',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>
                                      {/* {hasAddedBySameDoctorProcedureForColor([
                                        valData,
                                      ]) === false && (
                                        <Box
                                          paddingRight={1}
                                          paddingTop={0.5}
                                          width='10%'
                                        >
                                          <Tooltip
                                            title='Surgery added by some other doctor'
                                            placement='right-end'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='manage_ipd'
                                              style={{ padding: 0 }}
                                            >
                                              <Info />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )} */}
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                            id='sur7'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              //   color: 'black',

                                              //   fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {valData.performedDateTime ||
                                            valData.performedPeriod
                                              ? `${moment(
                                                  valData.performedDateTime ??
                                                    new Date()
                                                ).format('DD-MM-YYYY')}`
                                              : `${getAgeOf(
                                                  valData.performedAge
                                                )} ago`}
                                          </Typography>
                                        </Box>

                                        {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.2}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',
                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={7}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width={
                                          splitView === false ||
                                          status !== 'completed'
                                            ? '90%'
                                            : '90%'
                                        }
                                        paddingLeft={1}
                                        paddingTop={1}
                                      >
                                        {hasNotes(valData.note) && (
                                          <OverflowTypography
                                            text={getNotesString(valData.note)}
                                            typographyProps={{
                                              variant: 'subtitle2',
                                              color: 'initial',
                                              id: 'sur8',
                                            }}
                                          />
                                        )}
                                      </Box>
                                      {valData.meta &&
                                        valData.meta.security &&
                                        valData.meta.security[0].code ===
                                          'PATRPT' &&
                                        valData.status &&
                                        valData.status !== 'completed' &&
                                        splitView === false &&
                                        getValueRefValueExtensionsOfUrl(
                                          valData.extension ?? [],
                                          'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                        ).split('/')[1] ===
                                          requestingAppointmentId! &&
                                        getValueCoDeFromValueCodeableForSoapNotes(
                                          valData.extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext'
                                        ) === 'unconfirmed' && (
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='25%'
                                            paddingTop={0.5}
                                            paddingLeft={2}
                                          >
                                            <Button
                                              onClick={() => {
                                                acceptProcedureDetails(valData)
                                              }}
                                              size='small'
                                              id='accept'
                                              disableElevation
                                              variant='contained'
                                              color='primary'
                                              disabled={loading}
                                            >
                                              Accept
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                rejectProcedure(valData)
                                              }}
                                              size='small'
                                              variant='outlined'
                                              id='reject'
                                              disableElevation
                                              disabled={loading}
                                            >
                                              Reject
                                            </Button>
                                          </Box>
                                        )}
                                      <Box
                                        display='flex'
                                        justifyContent='flex-end'
                                        paddingRight={3}
                                        width='10%'
                                      >
                                        {hasAddedBySameDoctorProcedure(
                                          valData
                                        ) &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          splitView === false && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              paddingLeft={1}
                                              paddingTop={1}
                                            >
                                              <Tooltip
                                                title='Edit'
                                                id='sur_tool_edit'
                                              >
                                                <IconButton
                                                  aria-label='btn_ord_cancel'
                                                  color='primary'
                                                  id='procedure_tool_edit_button'
                                                  style={{ padding: 0 }}
                                                  onClick={() => {
                                                    createUpdateConditionData(
                                                      valData
                                                    )
                                                    setShowEdit(true)
                                                    // setChiefComplaintsDetails(val)
                                                  }}
                                                >
                                                  <img
                                                    id='sur_tool_edit_img'
                                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                    alt='Edit'
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                    +{' '}
                                  </Grid>
                                </Grid>
                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </Box>
      <AddProceduresHandler
        open={openAddDialogue}
        patient={patient}
        onMedicalProcedureAdded={(condition) => {
          setOpenAddDialogue(false)
          dispatch(
            requestProcedureHistoryOfPatient(
              patient,
              purposeOfUse,
              splitView,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
        appointmentId={
          purposeOfUse === PurposeOfUse.OPDAppointment
            ? requestingAppointmentId
            : undefined
        }
        encounterId={encounterId}
      />
      {showText && (
        <NoteDialog
          id='notesTile'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Surgery'
        />
      )}
      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
      <EditProcedure
        open={showEdit}
        patient={patient}
        purposeOfUse={purposeOfUse}
        onMedicalConditionAdded={(condition) => {
          setShowEdit(false)
          dispatch(
            requestProcedureHistoryOfPatient(
              patient,
              purposeOfUse,
              splitView,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingCondition={currentCondition}
        appointmentId={requestingAppointmentId}
      />
    </>
  )
}
