import { Box, Typography } from '@material-ui/core'
import SpaIcon from '@material-ui/icons/Spa'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHyperTensionSubscriptionsOfPatient } from 'redux/patient/patientsSubscriptions/patientSubscriptionsSlice'
import { RootState } from 'redux/rootReducer'
import {
  getDisplayOfSystemFromCodableConceptForAppointment,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getTimeForAppointment,
  getUserDetailAndAppointmentDetail,
} from 'utils/fhirResoureHelpers/appointmentHelpers'

interface Props {
  appointmentDetails: FhirAppointmentDetail
  opId?: string
  split?: boolean
}

export const PatientNameWithAppointmentWidget: React.FC<Props> = ({
  appointmentDetails,
  opId = '',
  split,
}: Props) => {
  const dispatch = useDispatch()
  const patientSubscriptionsSlice = useSelector(
    (state: RootState) => state.patientSubscriptionsSlice
  )

  //   useEffect(() => {
  //     dispatch(getHyperTensionSubscriptionsOfPatient(appointmentDetails.patient))
  //   }, [])
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignContent='start'
      justifyContent='start'
    >
      <Box display='flex' flexDirection='row'>
        {getMiddleNameOfPatient(appointmentDetails.patient).length > 0 && (
          <Typography
            variant='h6'
            color='initial'
            style={{
              textTransform: 'capitalize',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {getNameOfPatient(appointmentDetails.patient)}{' '}
            {getMiddleNameOfPatient(appointmentDetails.patient) ?? ''}{' '}
            {getLastNameOfPatient(appointmentDetails.patient)}
          </Typography>
        )}

        {getMiddleNameOfPatient(appointmentDetails.patient).length === 0 && (
          <Typography
            variant='h6'
            style={{
              textTransform: 'capitalize',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {getNameOfPatient(appointmentDetails.patient)}{' '}
            {getLastNameOfPatient(appointmentDetails.patient)}
          </Typography>
        )}
        {patientSubscriptionsSlice.resultsAvailable &&
          patientSubscriptionsSlice.hCarePlans &&
          patientSubscriptionsSlice.hCarePlans.length > 0 && (
            <Box px={1}>
              <SpaIcon
                color='primary'
                style={{
                  fontSize: '20px',
                }}
              />
            </Box>
          )}
      </Box>
      {split === undefined && (
        <Typography variant='subtitle1' style={{}}>
          {getUserDetailAndAppointmentDetail(appointmentDetails, opId)}
        </Typography>
      )}
      {split && (
        <Box width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle1'
            style={{
              fontWeight: 'bold',
            }}
          >
            Appointment Type :
          </Typography>
          <Typography variant='subtitle1' style={{ paddingLeft: 3 }}>
            {getDisplayOfSystemFromCodableConceptForAppointment(
              appointmentDetails?.appointment?.serviceType?.[0]
            ) ?? '  In-Person'}

            {/* {getTimeForAppointment(appointmentDetails, opId)} */}
          </Typography>
        </Box>
      )}
      {split && (
        <Box width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle1'
            style={{
              fontWeight: 'bold',
            }}
          >
            Appointment Date Time :
          </Typography>
          <Typography variant='subtitle1' style={{ paddingLeft: 3 }}>
            {getTimeForAppointment(appointmentDetails, opId)}
          </Typography>
        </Box>
      )}

      {split === undefined && (
        <Typography variant='subtitle1' style={{}}>
          {getTimeForAppointment(appointmentDetails, opId)}
        </Typography>
      )}

      {opId && opId.length > 0 && (
        <Typography variant='subtitle1' style={{}}>
          {opId}
        </Typography>
      )}
    </Box>
  )
}
