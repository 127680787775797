/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirTherapyListWithChargeDef } from 'models/fhirTherapyList'
import { Treatment } from 'models/treatMent'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  deleteTherapy,
  resetDeleteState,
} from 'redux/ipd/deleteTherapy/deleteTherapySlice'
import {
  addTreatmentDetails,
  resetTreatmentState,
} from 'redux/ipd/treatment/addTreatment/addTreatmentSlice'
import { requestForTreatment } from 'redux/ipd/treatment/viewTreatment/treatmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { therapyType } from 'utils/constants'
import { getExtensionValueData } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  dateCompare,
  getNotes,
  getOrderStatusText,
  inBetWeenTime,
  isAfter,
  isBeforeFinal,
  isCurrentDate,
  isSameOrBeforeAddEveing,
  isSameOrBeforeBetweenAdd,
  isSameTherapy,
  isTherapybetweenForAfterNoon,
  isTherapybetweenForEvening,
  isTherapybetweenForMidMoring,
  isTherapybetweenForMorning,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { AlertDialog } from 'views/components/LeftMenu/alertDialog'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { TherapySelectorForPlan } from 'views/components/LeftMenu/therapyListSelector'
import { WelloSelectFhirCodingForTherapy } from 'views/components/LeftMenu/WelloSelectFhirCodingForTherapy'
import { WelloSelectTherapyList } from 'views/components/LeftMenu/WelloSelectTherapyList'
import {
  WelloFormItemLabel,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const TreatmentCard: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  status,
}: AssessmentCaptureProps) => {
  let checkinDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')
  let checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(checkinDate)))
  const days = duration.asDays()
  if (days >= 5) {
    const newDate = moment().add(1, 'days')
    const checkoutDateDurtation = moment.duration(
      moment(checkOutDate).diff(endDateForCheck)
    )
    const checkOutDays = checkoutDateDurtation.asDays()
    if (checkOutDays < 0) {
      checkOutDate = moment().format('YYYY-MM-DD')
    }
    const newCheckinDate = newDate.subtract(5, 'days')
    checkinDate = moment(newCheckinDate).format('YYYY-MM-DD')
  }
  const [value1, setValue1] = React.useState(new Date('2020-01-01 12:00'))
  const [fullText, setFullText] = useState<string>('')
  const [showTextForNotes, setShowTextForNotes] = useState<boolean>(false)

  const appointmentTypeData = fhirAppointmentDetails.mainServiceRequest.code
    ? fhirAppointmentDetails.mainServiceRequest.code.coding
      ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
      : ''
    : ''

  const reason: R4.ICoding = {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'prov-pers',
    display: 'Therapist Not Available',
  }

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const addtreatmentSlice = useSelector(
    (state: RootState) => state.addTreatmentSlice
  )

  const treatmentSearchSlice = useSelector(
    (state: RootState) => state.treatmentSearchSlice
  )

  const deleteTreatmentSlice = useSelector(
    (state: RootState) => state.deleteTreatmentSlice
  )

  const threapyCatalogSearchSlice = useSelector(
    (state: RootState) => state.threapyCatalogSearchSlice
  )

  const threapyCatalogSearchPriceSlice = useSelector(
    (state: RootState) => state.threapyCatalogSearchPriceSlice
  )

  const substanceSearchSlice = useSelector(
    (state: RootState) => state.substanceSearchSlice
  )

  const data: FhirTherapyListWithChargeDef = {
    price: 0,
    therapy: {
      resourceType: 'HealthcareService',
      name: 'Select',
      id: 'sel',
    },
    name: 'Select',
  }

  let morningDuplicate: string[] = []
  let midAfterNoonDuplicate: string[] = []
  let afterNoonDuplicate: string[] = []
  let eveningDuplicate: string[] = []
  const [pricePopUp, setPricePricePopUp] = useState<boolean>(false)
  const [therapyName, setTherapyName] = useState<string>('')
  const [indexData, setIndexData] = useState<number>()
  const [timeData, setTimeData] = useState<string>()
  const [showText, setShowText] = useState<boolean>(false)
  const [showTextForSlot, setShowTextForSlot] = useState<boolean>(false)
  const [showTextForMorning, setShowTextForMorning] = useState<boolean>(false)
  const [showTextForMidMorning, setShowTextForMidMorning] =
    useState<boolean>(false)

  const [showTextForAfterNoon, setShowTextForAfterNoon] =
    useState<boolean>(false)
  const [showTextForEvening, setShowTextForEvening] = useState<boolean>(false)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [treatMent, setTreatMent] = useState<Treatment[]>([
    {
      id: getUniqueTempId(),
      date: moment(moment()).format('YYYY-MM-DD'),
      morning: {
        id: getUniqueTempId(),
        duration: 1,
        treatmentType: '',
        date: moment(moment()).format('YYYY-MM-DD'),
        startTime: '07:30',
        endTime: '08:00',
        start: new Date(`2020-01-01 07:30`),
        startDate: new Date(`2020-01-01 07:30`),
        end: new Date('2020-01-01 08:00'),
        endDate: new Date('2020-01-01 08:00'),
      },

      midAfterNoon: {
        id: getUniqueTempId(),
        date: moment(moment()).format('YYYY-MM-DD'),
        startTime: '09:00',
        duration: 1,
        treatmentType: '',
        endTime: '12:30',
        start: new Date(`2020-01-01 09:00`),
        startDate: new Date(`2020-01-01 09:00`),
        end: new Date('2020-01-01 12:30'),
        endDate: new Date('2020-01-01 12:30'),
      },
      afterNoon: {
        id: getUniqueTempId(),
        duration: 1,
        treatmentType: '',
        date: moment(moment()).format('YYYY-MM-DD'),
        startTime: '15:00',
        endTime: '17:30',
        start: new Date(`2020-01-01 15:00`),
        startDate: new Date(`2020-01-01 15:00`),
        end: new Date('2020-01-01 17:30'),
        endDate: new Date('2020-01-01 17:30'),
      },

      evening: {
        id: getUniqueTempId(),
        duration: 1,
        treatmentType: '',
        date: moment(moment()).format('YYYY-MM-DD'),
        startTime: '19:00',
        endTime: '19:30',
        start: new Date(`2020-01-01 19:30`),
        startDate: new Date(`2020-01-01 19:00`),
        end: new Date('2020-01-01 19:30'),
        endDate: new Date('2020-01-01 19:30'),
      },
    },
  ])

  function handleDateChange(date: any, index: number) {
    const values: Treatment[] = [...treatMent]
    morningDuplicate = []
    eveningDuplicate = []
    midAfterNoonDuplicate = []
    afterNoonDuplicate = []
    values[index].date = date.target.value
    if (!isCurrentDate(date.target.value)) {
      values[index].morning.start = new Date(`2020-01-01 07:30`)
      values[index].morning.startTime = '07:30'
      values[index].midAfterNoon.start = new Date(`2020-01-01 09:00`)
      values[index].midAfterNoon.startTime = '09:00'
      values[index].afterNoon.start = new Date(`2020-01-01 15:00`)
      values[index].afterNoon.startTime = '15:00'
      values[index].evening.start = new Date(`2020-01-01 19:00`)
      values[index].evening.startTime = '19:00'
    } else {
      values[index].morning.start = new Date(
        `2020-01-01 ${
          isAfter('07:30') && isBeforeFinal('08:00')
            ? moment().format('HH:mm')
            : '07:30'
        }`
      )
      values[index].morning.startTime = `${
        isAfter('07:30') && isBeforeFinal('08:00')
          ? moment().format('HH:mm')
          : '07:30'
      }`

      values[index].midAfterNoon.start = new Date(
        `2020-01-01 ${
          isAfter('09:00') && isBeforeFinal('12:30')
            ? moment().format('HH:mm')
            : '09:00'
        }`
      )
      values[index].midAfterNoon.startTime = `${
        isAfter('09:00') && isBeforeFinal('12:30')
          ? moment().format('HH:mm')
          : '09:00'
      }`

      values[index].afterNoon.start = new Date(
        `2020-01-01 ${
          isAfter('15:00') && isBeforeFinal('17:30')
            ? moment().format('HH:mm')
            : '15:00'
        }`
      )
      values[index].afterNoon.startTime = `${
        isAfter('15:00') && isBeforeFinal('17:30')
          ? moment().format('HH:mm')
          : '15:00'
      }`
      values[index].evening.start = new Date(
        `2020-01-01 ${
          isAfter('19:00') && isBeforeFinal('19:30')
            ? moment().format('HH:mm')
            : '19:00'
        }`
      )
      values[index].evening.startTime = `${
        isAfter('19:00') && isBeforeFinal('19:30')
          ? moment().format('HH:mm')
          : '19:00'
      }`
    }

    setTreatMent(values)
  }

  function handleEarlyMorningTherapy(
    th: FhirTherapyListWithChargeDef[],
    index: number
  ) {
    const finalCodingData: R4.ICoding[] = []
    const therapyIds: string[] = []

    for (let i = 0; i < th.length; i++) {
      const thCoding = th[i].therapy.type ?? []
      const thFinalCoding = thCoding.length > 0 ? thCoding[0].coding ?? [] : []
      if (thFinalCoding && thFinalCoding.length > 0) {
        finalCodingData.push(thFinalCoding[0])
      }
      therapyIds.push(th[i].therapy.id ?? '')
    }

    const values: Treatment[] = [...treatMent]
    values[index].morning.duration = getExtensionValueData(
      th[0].therapy.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
    )

    values[index].morning.threapyId = therapyIds
    if (treatmentSearchSlice.mornigTherapy) {
      const filteredData = treatmentSearchSlice.mornigTherapy.filter((d) =>
        moment(d.date).isSame(moment(values[index].date))
      )

      if (filteredData.length > 0) {
        for (let i = 0; i < filteredData.length; i++) {
          if (moment(values[index].date).isSame(moment(filteredData[i].date))) {
            if (filteredData[i].morningTherapy.length === 0) {
              values[index].morning.startTime = '07:30'

              let newDate = new Date(`${values[index].date}T${'07:30'}`)
              newDate.setMinutes(
                newDate.getMinutes() +
                  getExtensionValueData(
                    th[0].therapy.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  )
              ) // timestamp
              newDate = new Date(newDate)
              //   values[index].morning.endTime = moment(newDate).format('HH:mm')
            } else if (
              moment(
                filteredData[i].morningTherapy[0].morningTherapyStart
              ).format('HH:mm') !== '07:30'
            ) {
              const firstElement = `${values[index].date} 07:30`
              const secElement = moment(
                filteredData[i].morningTherapy[0].morningTherapyStart
              ).format('YYYY-MM-DD HH:mm')

              const diff = moment
                .duration(
                  moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                    moment(firstElement, 'YYYY/MM/DD HH:mm')
                  )
                )
                .asMinutes()
              const durtionData = getExtensionValueData(
                th[0].therapy.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
              )
              if (diff >= durtionData) {
                values[index].morning.startTime =
                  moment(firstElement).format('HH:mm')
                let newDate = new Date(
                  `${values[index].date}T${moment(firstElement).format(
                    'HH:mm'
                  )}`
                )
                newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                newDate = new Date(newDate)
                //   const tempEndTime = moment(newDate).format('HH:mm')
                // values[index].morning.endTime = moment(newDate).format('HH:mm')
              }
            } else {
              for (let j = 0; j < filteredData[i].morningTherapy.length; j++) {
                if (filteredData[i].morningTherapy[j + 1] === undefined) {
                  values[index].morning.startTime = moment(
                    filteredData[i].morningTherapy[j].morningTherapyEnd
                  ).format('HH:mm')

                  let newDate = new Date(
                    `${values[index].date}T${moment(
                      filteredData[i].morningTherapy[j].morningTherapyEnd
                    ).format('HH:mm')}`
                  )
                  newDate.setMinutes(
                    newDate.getMinutes() +
                      getExtensionValueData(
                        th[0].therapy.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                      )
                  ) // timestamp
                  newDate = new Date(newDate)
                  const tempEndTime = moment(newDate).format('HH:mm')
                  //   values[index].morning.endTime =
                  //     moment(newDate).format('HH:mm')
                  if (
                    !isTherapybetweenForMorning(
                      values[index].morning.startTime,
                      '07:30',
                      '08:00'
                    ) ||
                    !isTherapybetweenForMorning(
                      values[index].morning.endTime,
                      '07:30',
                      '08:00'
                    )
                  ) {
                    setShowTextForMorning(true)
                    break
                  }
                } else {
                  //   const firstElement =
                  //     filteredData[i].morningTherapy[j].morningTherapyEnd
                  //   const secElement =
                  //     filteredData[i].morningTherapy[j + 1].morningTherapyStart
                  //   const diff = moment
                  //     .duration(
                  //       moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                  //         moment(firstElement, 'YYYY/MM/DD HH:mm')
                  //       )
                  //     )
                  //     .asMinutes()
                  //   const durtionData = getExtensionValueData(
                  //     th[0].therapy.extension,
                  //     'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  //   )
                  //   if (diff >= durtionData) {
                  //     values[index].morning.startTime =
                  //       moment(firstElement).format('HH:mm')
                  //     let newDate = new Date(
                  //       `${values[index].date}T${moment(firstElement).format(
                  //         'HH:mm'
                  //       )}`
                  //     )
                  //     newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                  //     newDate = new Date(newDate)
                  //     //   const tempEndTime = moment(newDate).format('HH:mm')
                  //     values[index].morning.endTime =
                  //       moment(newDate).format('HH:mm')
                  //     break
                  //   }
                }
              }
            }
          }
        }
      } else {
        values[index].morning.startTime = '07:30'

        let newDate = new Date(`${values[index].date}T${'07:30'}`)
        newDate.setMinutes(
          newDate.getMinutes() +
            getExtensionValueData(
              th[0].therapy.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
            )
        ) // timestamp
        newDate = new Date(newDate)
        // values[index].morning.endTime = moment(newDate).format('HH:mm')
      }
    }
    values[index].morning.therapy = finalCodingData
    setTreatMent(values)
  }

  function handleChangeDurationEarlyMorning(event: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].morning.duration = parseInt(event.target.value, 10)
    let newDate = new Date(
      `${values[index].date}T${values[index].morning.startTime}`
    )
    newDate.setMinutes(newDate.getMinutes() + parseInt(event.target.value, 10)) // timestamp
    newDate = new Date(newDate)
    values[index].morning.endTime = moment(newDate).format('HH:mm')
    if (
      !isTherapybetweenForMorning(
        values[index].morning.startTime,
        '07:30',
        '08:00'
      ) ||
      !isTherapybetweenForMorning(
        values[index].morning.endTime,
        '07:30',
        '08:00'
      )
    ) {
      setShowTextForMorning(true)
    }
    setTreatMent(values)
  }

  function handleChangeDurationMidAfterNoon(event: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].midAfterNoon.duration = parseInt(event.target.value, 10)
    let newDate = new Date(
      `${values[index].date}T${values[index].midAfterNoon.startTime}`
    )
    newDate.setMinutes(newDate.getMinutes() + parseInt(event.target.value, 10)) // timestamp
    newDate = new Date(newDate)
    values[index].midAfterNoon.endTime = moment(newDate).format('HH:mm')
    setTreatMent(values)
  }

  function checkForExistingTreatment(
    existingTreatment: TreatmentPlanData[],
    planData: Treatment[]
  ) {
    morningDuplicate = []
    eveningDuplicate = []
    midAfterNoonDuplicate = []
    afterNoonDuplicate = []
    const morning: string[] = []
    const midAfterNoon: string[] = []
    const afternoon: string[] = []
    const evening: string[] = []
    if (existingTreatment.length > 0) {
      for (let i = 0; i < existingTreatment.length; i++) {
        if (
          planData[0].morning.therapy ||
          planData[0].midAfterNoon.therapy ||
          planData[0].afterNoon.therapy ||
          planData[0].evening
        ) {
          const search = dateCompare(
            existingTreatment[i].date,
            planData[0].date
          )
          if (search === true) {
            if (planData[0].morning.therapy) {
              let scoreData1 =
                inBetWeenTime(
                  planData[0].morning.startTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].morning.startTime
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].morning.endTime
                )
              inBetWeenTime(
                planData[0].morning.endTime,
                moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                  'HH:mm'
                ),
                moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                  'HH:mm'
                )
              )

              if (scoreData1 === false) {
                scoreData1 = isSameOrBeforeAddEveing(
                  planData[0].morning.startTime,
                  planData[0].morning.endTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                )
                if (scoreData1 === false) {
                  scoreData1 = isSameOrBeforeBetweenAdd(
                    planData[0].morning.startTime,
                    planData[0].morning.endTime,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  )
                }
              }

              if (
                scoreData1 === true &&
                existingTreatment[i].status !== 'Finished'
              ) {
                for (let k = 0; k < planData[0].morning.therapy.length; k++) {
                  morning.push(planData[0].morning.therapy[k].display ?? '')
                }

                morningDuplicate.push('2')
              }
            }

            if (planData[0].midAfterNoon.therapy) {
              let scoreData2 =
                inBetWeenTime(
                  planData[0].midAfterNoon.startTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].midAfterNoon.startTime
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].midAfterNoon.endTime
                )
              inBetWeenTime(
                planData[0].midAfterNoon.endTime,
                moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                  'HH:mm'
                ),
                moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                  'HH:mm'
                )
              )

              if (scoreData2 === false) {
                scoreData2 = isSameOrBeforeAddEveing(
                  planData[0].midAfterNoon.startTime,
                  planData[0].midAfterNoon.endTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                )
                if (scoreData2 === false) {
                  scoreData2 = isSameOrBeforeBetweenAdd(
                    planData[0].midAfterNoon.startTime,
                    planData[0].midAfterNoon.endTime,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  )
                }
              }

              if (
                scoreData2 === true &&
                existingTreatment[i].status !== 'Finished'
              ) {
                for (
                  let k = 0;
                  k < planData[0].midAfterNoon.therapy.length;
                  k++
                ) {
                  midAfterNoon.push(
                    planData[0].midAfterNoon.therapy[k].display ?? ''
                  )
                }
                // midAfterNoon.push(
                //   planData[0].midAfterNoon.therapy.display ?? ''
                // )
                midAfterNoonDuplicate.push('3')
              }
            }

            if (planData[0].afterNoon.therapy) {
              let scoreData3 =
                inBetWeenTime(
                  planData[0].afterNoon.startTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].afterNoon.startTime
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].afterNoon.endTime
                )
              inBetWeenTime(
                planData[0].afterNoon.endTime,
                moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                  'HH:mm'
                ),
                moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                  'HH:mm'
                )
              )

              if (scoreData3 === false) {
                scoreData3 = isSameOrBeforeAddEveing(
                  planData[0].afterNoon.startTime,
                  planData[0].afterNoon.endTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                )
                if (scoreData3 === false) {
                  scoreData3 = isSameOrBeforeBetweenAdd(
                    planData[0].afterNoon.startTime,
                    planData[0].afterNoon.endTime,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  )
                }
              }

              if (
                scoreData3 === true &&
                existingTreatment[i].status !== 'Finished'
              ) {
                for (let k = 0; k < planData[0].afterNoon.therapy.length; k++) {
                  afternoon.push(planData[0].afterNoon.therapy[k].display ?? '')
                }

                // afternoon.push(planData[0].afterNoon.therapy.display ?? '')
                afterNoonDuplicate.push('33')
              }
            }

            if (planData[0].evening.therapy) {
              let scoreData =
                inBetWeenTime(
                  planData[0].evening.startTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].evening.startTime
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData[0].evening.endTime
                )
              inBetWeenTime(
                planData[0].evening.endTime,
                moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                  'HH:mm'
                ),
                moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                  'HH:mm'
                )
              )

              if (scoreData === false) {
                scoreData = isSameOrBeforeAddEveing(
                  planData[0].evening.startTime,
                  planData[0].evening.endTime,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                )
                if (scoreData === false) {
                  scoreData = isSameOrBeforeBetweenAdd(
                    planData[0].evening.startTime,
                    planData[0].evening.endTime,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  )
                }
              }

              if (
                scoreData === true &&
                existingTreatment[i].status !== 'Finished'
              ) {
                for (let k = 0; k < planData[0].evening.therapy.length; k++) {
                  evening.push(planData[0].evening.therapy[k].display ?? '')
                }
                // evening.push(planData[0].evening.therapy.display ?? '')
                eveningDuplicate.push('44')
              }
            }
          }
        }
      }
    }
  }

  function isEnabled(existingTreatment: TreatmentPlanData[]): boolean {
    const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
      ? fhirAppointmentDetails.mainServiceRequest.code.coding
        ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
        : ''
      : ''
    if (appointmentType === '304903009' && existingTreatment.length === 2) {
      return true
    }
    return false
  }

  function handleEarlyMorningStartTIme(time: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].morning.startTime = moment(time).format('HH:mm')
    values[index].morning.start = time
    setTreatMent(values)
  }

  function handleChangeDurationEvening(event: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].evening.duration = parseInt(event.target.value, 10)
    let newDate = new Date(
      `${values[index].date}T${values[index].evening.startTime}`
    )
    newDate.setMinutes(newDate.getMinutes() + parseInt(event.target.value, 10)) // timestamp
    newDate = new Date(newDate)
    values[index].evening.endTime = moment(newDate).format('HH:mm')
    setTreatMent(values)
  }

  function handleEveningType(type: string, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].evening.treatmentType = type
    setTreatMent(values)
  }

  function handleEarlyMorningEndTIme(time: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].morning.endTime = moment(time).format('HH:mm')
    values[index].morning.end = time
    setTreatMent(values)
  }

  function handleEarlyMorningType(type: string, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].morning.treatmentType = type
    setTreatMent(values)
  }

  function handleChangeDurationAfterNoon(event: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].afterNoon.duration = parseInt(event.target.value, 10)
    let newDate = new Date(
      `${values[index].date}T${values[index].afterNoon.startTime}`
    )
    newDate.setMinutes(newDate.getMinutes() + parseInt(event.target.value, 10)) // timestamp
    newDate = new Date(newDate)
    values[index].afterNoon.endTime = moment(newDate).format('HH:mm')
    setTreatMent(values)
  }

  function handleAfterNoonType(type: string, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].afterNoon.treatmentType = type
    setTreatMent(values)
  }

  function handleEarlyMindAfterNoonType(type: string, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].midAfterNoon.treatmentType = type
    setTreatMent(values)
  }

  function handleEarlyAfterMorningTherapy(
    th: FhirTherapyListWithChargeDef[],
    index: number
  ) {
    const finalCodingData: R4.ICoding[] = []
    const therapyIds: string[] = []

    for (let i = 0; i < th.length; i++) {
      const thCoding = th[i].therapy.type ?? []
      const thFinalCoding = thCoding.length > 0 ? thCoding[0].coding ?? [] : []
      if (thFinalCoding && thFinalCoding.length > 0) {
        finalCodingData.push(thFinalCoding[0])
      }
      therapyIds.push(th[i].therapy.id ?? '')
    }

    const values: Treatment[] = [...treatMent]
    values[index].midAfterNoon.duration = getExtensionValueData(
      th[0].therapy.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
    )

    values[index].midAfterNoon.threapyId = therapyIds
    if (treatmentSearchSlice.midMorningTherapy) {
      const filteredData = treatmentSearchSlice.midMorningTherapy.filter((d) =>
        moment(d.date).isSame(moment(values[index].date))
      )

      if (filteredData.length > 0) {
        for (let i = 0; i < filteredData.length; i++) {
          if (moment(values[index].date).isSame(moment(filteredData[i].date))) {
            if (filteredData[i].midMorningTherapy.length === 0) {
              values[index].midAfterNoon.startTime = '09:00'

              let newDate = new Date(`${values[index].date}T${'09:00'}`)
              newDate.setMinutes(
                newDate.getMinutes() +
                  getExtensionValueData(
                    th[0].therapy.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  )
              ) // timestamp
              newDate = new Date(newDate)
              //   values[index].midAfterNoon.endTime =
              //     moment(newDate).format('HH:mm')
            } else if (
              moment(
                filteredData[i].midMorningTherapy[0].midMorningStart
              ).format('HH:mm') !== '09:00'
            ) {
              const firstElement = `${values[index].date} 09:00`
              const secElement = moment(
                filteredData[i].midMorningTherapy[0].midMorningStart
              ).format('YYYY-MM-DD HH:mm')

              const diff = moment
                .duration(
                  moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                    moment(firstElement, 'YYYY/MM/DD HH:mm')
                  )
                )
                .asMinutes()
              const durtionData = getExtensionValueData(
                th[0].therapy.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
              )
              if (diff >= durtionData) {
                values[index].midAfterNoon.startTime =
                  moment(firstElement).format('HH:mm')
                let newDate = new Date(
                  `${values[index].date}T${moment(firstElement).format(
                    'HH:mm'
                  )}`
                )
                newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                newDate = new Date(newDate)
                //   const tempEndTime = moment(newDate).format('HH:mm')
                // values[index].midAfterNoon.endTime =
                //   moment(newDate).format('HH:mm')
              }
            } else {
              for (
                let j = 0;
                j < filteredData[i].midMorningTherapy.length;
                j++
              ) {
                if (filteredData[i].midMorningTherapy[j + 1] === undefined) {
                  values[index].midAfterNoon.startTime = moment(
                    filteredData[i].midMorningTherapy[j].midMorningEnd
                  ).format('HH:mm')

                  let newDate = new Date(
                    `${values[index].date}T${moment(
                      filteredData[i].midMorningTherapy[j].midMorningEnd
                    ).format('HH:mm')}`
                  )
                  newDate.setMinutes(
                    newDate.getMinutes() +
                      getExtensionValueData(
                        th[0].therapy.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                      )
                  ) // timestamp
                  newDate = new Date(newDate)
                  const tempEndTime = moment(newDate).format('HH:mm')
                  //   values[index].midAfterNoon.endTime =
                  //     moment(newDate).format('HH:mm')
                  if (
                    !isTherapybetweenForMorning(
                      values[index].midAfterNoon.startTime,
                      '09:00',
                      '12:30'
                    ) ||
                    !isTherapybetweenForMorning(
                      values[index].midAfterNoon.endTime,
                      '09:00',
                      '12:30'
                    )
                  ) {
                    setShowTextForMidMorning(true)
                    break
                  }
                } else {
                  //   const firstElement =
                  //     filteredData[i].midMorningTherapy[j].midMorningEnd
                  //   const secElement =
                  //     filteredData[i].midMorningTherapy[j + 1].midMorningStart
                  //   const diff = moment
                  //     .duration(
                  //       moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                  //         moment(firstElement, 'YYYY/MM/DD HH:mm')
                  //       )
                  //     )
                  //     .asMinutes()
                  //   const durtionData = getExtensionValueData(
                  //     th[0].therapy.extension,
                  //     'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  //   )
                  //   if (diff >= durtionData) {
                  //     values[index].midAfterNoon.startTime =
                  //       moment(firstElement).format('HH:mm')
                  //     let newDate = new Date(
                  //       `${values[index].date}T${moment(firstElement).format(
                  //         'HH:mm'
                  //       )}`
                  //     )
                  //     newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                  //     newDate = new Date(newDate)
                  //     //   const tempEndTime = moment(newDate).format('HH:mm')
                  //     // values[index].midAfterNoon.endTime =
                  //     //   moment(newDate).format('HH:mm')
                  //     break
                  //   }
                }
              }
            }
          }
        }
      } else {
        values[index].midAfterNoon.startTime = '09:00'

        let newDate = new Date(`${values[index].date}T${'09:00'}`)
        newDate.setMinutes(
          newDate.getMinutes() +
            getExtensionValueData(
              th[0].therapy.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
            )
        ) // timestamp
        newDate = new Date(newDate)
        // values[index].midAfterNoon.endTime = moment(newDate).format('HH:mm')
      }
    }
    values[index].midAfterNoon.therapy = finalCodingData
    setTreatMent(values)
  }

  function handleMidAfternoonStart(time: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].midAfterNoon.startTime = moment(time).format('HH:mm')
    values[index].midAfterNoon.start = time
    setTreatMent(values)
  }

  function handleAfterNoonTherapy(
    th: FhirTherapyListWithChargeDef[],
    index: number
  ) {
    const finalCodingData: R4.ICoding[] = []
    const therapyIds: string[] = []

    for (let i = 0; i < th.length; i++) {
      const thCoding = th[i].therapy.type ?? []
      const thFinalCoding = thCoding.length > 0 ? thCoding[0].coding ?? [] : []
      if (thFinalCoding && thFinalCoding.length > 0) {
        finalCodingData.push(thFinalCoding[0])
      }
      therapyIds.push(th[i].therapy.id ?? '')
    }

    const values: Treatment[] = [...treatMent]
    values[index].afterNoon.duration = getExtensionValueData(
      th[0].therapy.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
    )

    values[index].afterNoon.threapyId = therapyIds
    if (treatmentSearchSlice.afternoonTherapy) {
      const filteredData = treatmentSearchSlice.afternoonTherapy.filter((d) =>
        moment(d.date).isSame(moment(values[index].date))
      )

      if (filteredData.length > 0) {
        for (let i = 0; i < filteredData.length; i++) {
          if (moment(values[index].date).isSame(moment(filteredData[i].date))) {
            if (filteredData[i].afterNoonTherapy.length === 0) {
              values[index].afterNoon.startTime = '15:00'

              let newDate = new Date(`${values[index].date}T${'15:00'}`)
              newDate.setMinutes(
                newDate.getMinutes() +
                  getExtensionValueData(
                    th[0].therapy.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  )
              ) // timestamp
              newDate = new Date(newDate)
              //   values[index].afterNoon.endTime = moment(newDate).format('HH:mm')
            } else if (
              moment(filteredData[i].afterNoonTherapy[0].afterNoonStart).format(
                'HH:mm'
              ) !== '15:00'
            ) {
              const firstElement = `${values[index].date} 15:00`
              const secElement = moment(
                filteredData[i].afterNoonTherapy[0].afterNoonStart
              ).format('YYYY-MM-DD HH:mm')

              const diff = moment
                .duration(
                  moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                    moment(firstElement, 'YYYY/MM/DD HH:mm')
                  )
                )
                .asMinutes()
              const durtionData = getExtensionValueData(
                th[0].therapy.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
              )
              if (diff >= durtionData) {
                values[index].afterNoon.startTime =
                  moment(firstElement).format('HH:mm')
                let newDate = new Date(
                  `${values[index].date}T${moment(firstElement).format(
                    'HH:mm'
                  )}`
                )
                newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                newDate = new Date(newDate)
                //   const tempEndTime = moment(newDate).format('HH:mm')
                // values[index].afterNoon.endTime =
                //   moment(newDate).format('HH:mm')
              }
            } else {
              for (
                let j = 0;
                j < filteredData[i].afterNoonTherapy.length;
                j++
              ) {
                if (filteredData[i].afterNoonTherapy[j + 1] === undefined) {
                  values[index].afterNoon.startTime = moment(
                    filteredData[i].afterNoonTherapy[j].afterNoonEnd
                  ).format('HH:mm')

                  let newDate = new Date(
                    `${values[index].date}T${moment(
                      filteredData[i].afterNoonTherapy[j].afterNoonEnd
                    ).format('HH:mm')}`
                  )
                  newDate.setMinutes(
                    newDate.getMinutes() +
                      getExtensionValueData(
                        th[0].therapy.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                      )
                  ) // timestamp
                  newDate = new Date(newDate)
                  const tempEndTime = moment(newDate).format('HH:mm')
                  //   values[index].afterNoon.endTime =
                  moment(newDate).format('HH:mm')
                  if (
                    !isTherapybetweenForMorning(
                      values[index].afterNoon.startTime,
                      '15:00',
                      '17:30'
                    ) ||
                    !isTherapybetweenForMorning(
                      values[index].afterNoon.endTime,
                      '15:00',
                      '17:30'
                    )
                  ) {
                    setShowTextForAfterNoon(true)
                    break
                  }
                } else {
                  const firstElement =
                    filteredData[i].afterNoonTherapy[j].afterNoonEnd
                  const secElement =
                    filteredData[i].afterNoonTherapy[j + 1].afterNoonStart

                  const diff = moment
                    .duration(
                      moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                        moment(firstElement, 'YYYY/MM/DD HH:mm')
                      )
                    )
                    .asMinutes()
                  const durtionData = getExtensionValueData(
                    th[0].therapy.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  )

                  if (diff >= durtionData) {
                    values[index].afterNoon.startTime =
                      moment(firstElement).format('HH:mm')
                    let newDate = new Date(
                      `${values[index].date}T${moment(firstElement).format(
                        'HH:mm'
                      )}`
                    )
                    newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                    newDate = new Date(newDate)
                    //   const tempEndTime = moment(newDate).format('HH:mm')
                    // values[index].afterNoon.endTime =
                    //   moment(newDate).format('HH:mm')
                    break
                  }
                }
              }
            }
          }
        }
      } else {
        values[index].afterNoon.startTime = '15:00'

        let newDate = new Date(`${values[index].date}T${'15:00'}`)
        newDate.setMinutes(
          newDate.getMinutes() +
            getExtensionValueData(
              th[0].therapy.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
            )
        ) // timestamp
        newDate = new Date(newDate)
        // values[index].afterNoon.endTime = moment(newDate).format('HH:mm')
      }
    }
    values[index].afterNoon.therapy = finalCodingData

    setTreatMent(values)
  }

  function handleAfternoonStart(time: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].afterNoon.startTime = moment(time).format('HH:mm')

    values[index].afterNoon.start = time
    // values[index].afterNoon.startTime = time.target.value
    setTreatMent(values)
  }

  function handleAfternoonEnd(time: any, index: number) {
    const values: Treatment[] = [...treatMent]

    values[index].afterNoon.endTime = moment(time).format('HH:mm')
    values[index].afterNoon.end = time
    // values[index].afterNoon.endTime = time.target.value
    setTreatMent(values)
  }

  function handleEveingTherapy(
    th: FhirTherapyListWithChargeDef[],
    index: number
  ) {
    const finalCodingData: R4.ICoding[] = []
    const therapyIds: string[] = []

    for (let i = 0; i < th.length; i++) {
      const thCoding = th[i].therapy.type ?? []
      const thFinalCoding = thCoding.length > 0 ? thCoding[0].coding ?? [] : []
      if (thFinalCoding && thFinalCoding.length > 0) {
        finalCodingData.push(thFinalCoding[0])
      }
      therapyIds.push(th[i].therapy.id ?? '')
    }

    const values: Treatment[] = [...treatMent]
    values[index].evening.duration = getExtensionValueData(
      th[0].therapy.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
    )

    values[index].evening.threapyId = therapyIds
    if (treatmentSearchSlice.eveningTherapy) {
      const filteredData = treatmentSearchSlice.eveningTherapy.filter((d) =>
        moment(d.date).isSame(moment(values[index].date))
      )

      if (filteredData.length > 0) {
        for (let i = 0; i < filteredData.length; i++) {
          if (moment(values[index].date).isSame(moment(filteredData[i].date))) {
            if (filteredData[i].eveingTherapy.length === 0) {
              values[index].evening.startTime = '19:00'

              let newDate = new Date(`${values[index].date}T${'19:00'}`)
              newDate.setMinutes(
                newDate.getMinutes() +
                  getExtensionValueData(
                    th[0].therapy.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  )
              ) // timestamp
              newDate = new Date(newDate)
              //   values[index].evening.endTime = moment(newDate).format('HH:mm')
            } else if (
              moment(filteredData[i].eveingTherapy[0].eveingStart).format(
                'HH:mm'
              ) !== '19:00'
            ) {
              const firstElement = `${values[index].date} 19:00`
              const secElement = moment(
                filteredData[i].eveingTherapy[0].eveingStart
              ).format('YYYY-MM-DD HH:mm')

              const diff = moment
                .duration(
                  moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                    moment(firstElement, 'YYYY/MM/DD HH:mm')
                  )
                )
                .asMinutes()
              const durtionData = getExtensionValueData(
                th[0].therapy.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
              )
              if (diff >= durtionData) {
                values[index].evening.startTime =
                  moment(firstElement).format('HH:mm')
                let newDate = new Date(
                  `${values[index].date}T${moment(firstElement).format(
                    'HH:mm'
                  )}`
                )
                newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                newDate = new Date(newDate)
                //   const tempEndTime = moment(newDate).format('HH:mm')
                // values[index].evening.endTime = moment(newDate).format('HH:mm')
              }
            } else {
              for (let j = 0; j < filteredData[i].eveingTherapy.length; j++) {
                if (filteredData[i].eveingTherapy[j + 1] === undefined) {
                  values[index].evening.startTime = moment(
                    filteredData[i].eveingTherapy[j].eveningEnd
                  ).format('HH:mm')

                  let newDate = new Date(
                    `${values[index].date}T${moment(
                      filteredData[i].eveingTherapy[j].eveningEnd
                    ).format('HH:mm')}`
                  )
                  newDate.setMinutes(
                    newDate.getMinutes() +
                      getExtensionValueData(
                        th[0].therapy.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                      )
                  ) // timestamp
                  newDate = new Date(newDate)
                  const tempEndTime = moment(newDate).format('HH:mm')
                  //   values[index].evening.endTime =
                  //     moment(newDate).format('HH:mm')
                  if (
                    !isTherapybetweenForMorning(
                      values[index].evening.startTime,
                      '19:00',
                      '19:30'
                    ) ||
                    !isTherapybetweenForMorning(
                      values[index].evening.endTime,
                      '19:00',
                      '19:30'
                    )
                  ) {
                    setShowTextForEvening(true)
                    break
                  }
                } else {
                  const firstElement =
                    filteredData[i].eveingTherapy[j].eveningEnd
                  const secElement =
                    filteredData[i].eveingTherapy[j + 1].eveingStart

                  const diff = moment
                    .duration(
                      moment(secElement, 'YYYY/MM/DD HH:mm').diff(
                        moment(firstElement, 'YYYY/MM/DD HH:mm')
                      )
                    )
                    .asMinutes()
                  const durtionData = getExtensionValueData(
                    th[0].therapy.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
                  )
                  //   if (diff >= durtionData) {
                  //     values[index].evening.startTime =
                  //       moment(firstElement).format('HH:mm')
                  //     let newDate = new Date(
                  //       `${values[index].date}T${moment(firstElement).format(
                  //         'HH:mm'
                  //       )}`
                  //     )
                  //     newDate.setMinutes(newDate.getMinutes() + durtionData) // timestamp
                  //     newDate = new Date(newDate)
                  //     //   const tempEndTime = moment(newDate).format('HH:mm')
                  //     values[index].evening.endTime =
                  //       moment(newDate).format('HH:mm')
                  //     break
                  //   }
                }
              }
            }
          }
        }
      } else {
        values[index].evening.startTime = '19:00'

        let newDate = new Date(`${values[index].date}T${'19:00'}`)
        newDate.setMinutes(
          newDate.getMinutes() +
            getExtensionValueData(
              th[0].therapy.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
            )
        ) // timestamp
        newDate = new Date(newDate)
        // values[index].evening.endTime = moment(newDate).format('HH:mm')
      }
    }
    values[index].evening.therapy = finalCodingData
    setTreatMent(values)
  }

  function handleEveningStart(time: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].evening.startTime = moment(time).format('HH:mm')
    values[index].evening.start = time
    setTreatMent(values)
  }
  function handleEveningEnd(time: any, index: number) {
    const values: Treatment[] = [...treatMent]
    values[index].evening.endTime = moment(time).format('HH:mm')
    values[index].evening.end = time
    // values[index].evening.endTime = time.target.value
    setTreatMent(values)
  }

  function resetValues() {
    morningDuplicate = []
    eveningDuplicate = []
    midAfterNoonDuplicate = []
    eveningDuplicate = []
    setTreatMent([
      {
        id: getUniqueTempId(),

        date: moment(moment()).format('YYYY-MM-DD'),
        morning: {
          id: getUniqueTempId(),
          treatmentType: '',
          date: checkinDate,
          startTime: '07:30',
          endTime: '08:00',
          start: new Date(`2020-01-01 07:30`),
          startDate: new Date(`2020-01-01 07:30`),
          end: new Date('2020-01-01 08:00'),
          duration: 1,
          endDate: new Date('2020-01-01 08:00'),
        },

        midAfterNoon: {
          id: getUniqueTempId(),
          treatmentType: '',
          date: moment(moment()).format('YYYY-MM-DD'),
          startTime: '09:00',
          endTime: '12:30',
          duration: 1,
          start: new Date(`2020-01-01 09:00`),
          startDate: new Date(`2020-01-01 09:00`),
          end: new Date('2020-01-01 12:30'),
          endDate: new Date('2020-01-01 12:30'),
        },
        afterNoon: {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          duration: 1,
          treatmentType: '',
          startTime: '15:00',
          endTime: '17:30',
          start: new Date(`2020-01-01 15:00`),
          startDate: new Date(`2020-01-01 15:00`),
          end: new Date('2020-01-01 17:30'),
          endDate: new Date('2020-01-01 17:30'),
        },

        evening: {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          startTime: '19:00',
          endTime: '19:30',
          treatmentType: '',
          duration: 1,
          start: new Date(`2020-01-01 19:00`),
          startDate: new Date(`2020-01-01 19:00`),
          end: new Date('2020-01-01 19:30'),
          endDate: new Date('2020-01-01 19:30'),
        },
      },
    ])
  }

  useEffect(() => {
    if (addtreatmentSlice.additionSuccessful) {
      resetValues()

      dispatch(resetTreatmentState())
      dispatch(
        requestForTreatment(fhirAppointmentDetails.mainServiceRequest.id!)
      )
    }
    if (deleteTreatmentSlice.updateSuccessful) {
      dispatch(resetDeleteState())
    }
  }, [dispatch, addtreatmentSlice, resetValues, deleteTreatmentSlice])

  useEffect(() => {
    dispatch(requestForTreatment(fhirAppointmentDetails.mainServiceRequest.id!))
  }, [dispatch])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Grid item id='diagnosis'>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box
                      sx={{ width: '100%' }}
                      display='flex'
                      alignItems='center'
                      flexDirection='row'
                      bgcolor='#999999'
                      minHeight={45}
                    >
                      <Box p={2}>
                        <Typography
                          variant='body1'
                          style={{ color: 'white', fontWeight: 600 }}
                        >
                          {' '}
                          Treatment Plan
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xl>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    width='100%'
                    border={4}
                    style={{
                      boxShadow: '0px 0px 4px #ccc',
                      margin: '2px 0px',
                      borderRadius: '1px',
                      borderColor: '#F8F8F8',
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#F8F8F8',
                    }}
                  >
                    <Grid container direction='row' spacing={1}>
                      <Grid
                        container
                        direction='row'
                        id='vitals'
                        style={{
                          backgroundColor: kDialogueBackground,
                        }}
                      >
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Date
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Therapy
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Start Time
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  End Time
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Actual Start
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Actual End
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Notes
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Performed By
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Status
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              width='2%'
                              justifyContent='flex-end'
                              flexGrow
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  minHeight: '30px',
                                  maxHeight: '100px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {treatmentSearchSlice.searchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.errorWhileSearchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Some Error has occured
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.noResultsAvailable && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Treatment Plans not available
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isUnitAdmin() || status === 'completed'
                          ? 'No data available'
                          : 'Add Treatment Plan'}
                      </Typography>
                    </Box>
                  )}

                {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length > 0 && (
                    <Grid item xl>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                      >
                        <Grid container direction='row' spacing={1}>
                          {treatmentSearchSlice.treatmentList.map(
                            (
                              treatmentData: TreatmentPlanData,
                              index: number
                            ) => (
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                // style={{
                                //   backgroundColor:
                                //     index % 2 ? kDialogueBackground : 'white',
                                // }}
                              >
                                {treatmentData.treatmentPlan &&
                                  treatmentData.treatmentPlan.status !==
                                    'unknown' && (
                                    <Box
                                      width='100%'
                                      //   style={{
                                      //     backgroundColor:
                                      //       index % 2 ? kDialogueBackground : 'white',
                                      //   }}
                                    >
                                      <Grid
                                        container
                                        direction='row'
                                        id='vitals'
                                        style={{
                                          backgroundColor:
                                            index % 2
                                              ? kDialogueBackground
                                              : 'white',
                                        }}
                                      >
                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {moment(
                                                    treatmentData.date
                                                  ).format('DD-MM-YYYY')}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.therapy}{' '}
                                                  {treatmentData.treatmentType
                                                    ? `- ${treatmentData.treatmentType}`
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.startTime}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.endTime}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    paddingLeft: 2,
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.actualStart &&
                                                  treatmentData.actualStart !==
                                                    'Invalid Date'
                                                    ? treatmentData.actualStart
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    paddingLeft: 2,
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.actualEnd &&
                                                  treatmentData.actualEnd !==
                                                    'Invalid Date'
                                                    ? treatmentData.actualEnd
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                {treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'revoked' && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='initial'
                                                      style={{
                                                        cursor: 'pointer',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {' '}
                                                      {treatmentData
                                                        .treatmentPlan.note
                                                        ? treatmentData
                                                            .treatmentPlan
                                                            .note[0].text ?? ''
                                                        : treatmentData
                                                            .treatmentPlan
                                                            .reasonCode
                                                        ? treatmentData
                                                            .treatmentPlan
                                                            .reasonCode[0]
                                                          ? treatmentData
                                                              .treatmentPlan
                                                              .reasonCode[0]
                                                              .coding
                                                            ? treatmentData
                                                                .treatmentPlan
                                                                .reasonCode[0]
                                                                .coding[0]
                                                              ? treatmentData
                                                                  .treatmentPlan
                                                                  .reasonCode[0]
                                                                  .coding[0]
                                                                  .display ?? ''
                                                              : ''
                                                            : ''
                                                          : ''
                                                        : ''}
                                                    </Typography>
                                                  )}

                                                {treatmentData.procedure && (
                                                  <Box>
                                                    <Typography
                                                      variant='body2'
                                                      color='initial'
                                                      style={{
                                                        cursor: 'pointer',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        lineHeight: 'normal',
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          getNotes(
                                                            treatmentData.procedure!
                                                          ).length > 60
                                                        ) {
                                                          setFullText(
                                                            getNotes(
                                                              treatmentData.procedure!
                                                            )
                                                          )
                                                          setShowText(true)
                                                        }
                                                      }}
                                                    >
                                                      {getNotes(
                                                        treatmentData.procedure!
                                                      ).slice(0, 60)}
                                                      {getNotes(
                                                        treatmentData.procedure!
                                                      ).length > 60
                                                        ? '...'
                                                        : null}
                                                    </Typography>

                                                    {/* <Typography
                                                  variant='body1'
                                                  color='initial'
                                                  style={{
                                                    paddingLeft: 2,
                                                    cursor: 'pointer',
                                                  }}
                                                  //   component=''
                                                  onClick={() => {
                                                    if (
                                                      getNotes(
                                                        treatmentData.procedure
                                                      ).length > 60
                                                    ) {
                                                      setFullText(
                                                        ipdAppointmentDetails.notes
                                                      )
                                                      setShowText(true)
                                                    }
                                                  }}
                                                >
                                                  {ipdAppointmentDetails.notes.slice(
                                                    0,
                                                    60
                                                  )}
                                                </Typography> */}
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                {treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'revoked' && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='initial'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {treatmentData
                                                        .treatmentPlan.performer
                                                        ? treatmentData
                                                            .treatmentPlan
                                                            .performer[0]
                                                            .display ?? ''
                                                        : ''}
                                                    </Typography>
                                                  )}
                                                {treatmentData.procedure && (
                                                  <Typography
                                                    variant='subtitle1'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {' '}
                                                    {treatmentData.procedure.performer
                                                      ?.map(
                                                        (e) =>
                                                          e.actor.display ?? ''
                                                      )
                                                      .join(', ')}
                                                  </Typography>
                                                )}
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                paddingLeft={0.4}
                                                paddingRight={0.7}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color={
                                                    (treatmentData.treatmentPlan &&
                                                      treatmentData
                                                        .treatmentPlan
                                                        .status === 'active') ||
                                                    (treatmentData.treatmentPlan &&
                                                      treatmentData
                                                        .treatmentPlan
                                                        .status === 'completed')
                                                      ? 'primary'
                                                      : 'error'
                                                  }
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.procedure ===
                                                    undefined && (
                                                    <Typography
                                                      variant='body2'
                                                      color={
                                                        (treatmentData.treatmentPlan &&
                                                          treatmentData
                                                            .treatmentPlan
                                                            .status ===
                                                            'active') ||
                                                        (treatmentData.treatmentPlan &&
                                                          treatmentData
                                                            .treatmentPlan
                                                            .status ===
                                                            'completed') ||
                                                        treatmentData.procedure
                                                          ? 'primary'
                                                          : 'error'
                                                      }
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {' '}
                                                      {treatmentData.treatmentPlan &&
                                                        treatmentData.procedure ===
                                                          undefined &&
                                                        getOrderStatusText(
                                                          treatmentData.treatmentPlan
                                                        )}
                                                    </Typography>
                                                  )}

                                                  {treatmentData.procedure && (
                                                    <Typography
                                                      variant='body2'
                                                      color='primary'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {' '}
                                                      Completed
                                                    </Typography>
                                                  )}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='center'
                                            py={1}
                                          >
                                            {treatmentData.procedure ===
                                              undefined &&
                                              treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status !== 'revoked' &&
                                              status !== 'completed' && (
                                                <DeleteOutlineOutlined
                                                  fontSize='small'
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  id={`del${index}`}
                                                  onClick={() => {
                                                    setShowDeleteConfirmation({
                                                      open: true,
                                                      onContinueActionClicked:
                                                        () => {
                                                          setShowDeleteConfirmation(
                                                            {
                                                              open: false,
                                                            }
                                                          )
                                                          if (
                                                            treatmentData &&
                                                            treatmentData.treatmentPlan &&
                                                            reason!
                                                          ) {
                                                            dispatch(
                                                              deleteTherapy(
                                                                treatmentData.treatmentPlan,
                                                                fhirAppointmentDetails
                                                              )
                                                            )
                                                          }
                                                        },
                                                      onCancelActionClicked:
                                                        () => {
                                                          setShowDeleteConfirmation(
                                                            {
                                                              open: false,
                                                            }
                                                          )
                                                        },
                                                      displayText:
                                                        'Are you sure you want to delete this record?',
                                                      continueActionButtonText:
                                                        'Delete',
                                                      cancelActionButtonText:
                                                        'Cancel',
                                                    })
                                                  }}
                                                />
                                              )}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction='row'
                                        id='vitals'
                                      >
                                        <Grid item xs={12}>
                                          <Box width='100%' paddingLeft={0.2}>
                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  )}

                {addtreatmentSlice.adding && (
                  <WelloLoadingIndicator message='Adding details' />
                )}
                {!isUnitAdmin() &&
                  status !== 'completed' &&
                  !addtreatmentSlice.adding && (
                    <Grid item xs={12}>
                      <Box display='flex' flexDirection='column'>
                        {treatMent.map(
                          (treatmentRow: Treatment, index: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              alignItems='flex-start'
                              key={`unit_actor_small_details_${treatmentRow.morning.id}`}
                            >
                              <Box px={0.5} width='20%'>
                                <WelloTextField
                                  title='Date'
                                  textProps={{
                                    id: `actor_email_unit`,
                                    value: treatmentRow.date,
                                    inputProps: {
                                      min: checkinDate,
                                      max: checkOutDate,
                                    },
                                    onKeyDown: (e) => {
                                      e.preventDefault()
                                    },

                                    type: 'date',
                                    onChange: (changeData) => {
                                      handleDateChange(changeData, index)
                                    },
                                  }}
                                />
                              </Box>

                              <Box
                                sx={{ width: '30%' }}
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Box px={4} py={1}>
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {' '}
                                    Early Morning (7.30 AM - 8 AM)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                              >
                                <Box px={4} width='30%'>
                                  <TherapySelectorForPlan
                                    showPrice={true}
                                    id={`earlyMorning${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEarlyMorningTherapy(e, index)
                                      }
                                    }}
                                  />
                                  {/* <WelloSelectTherapyList
                                    id={`earlyMorning${index}`}
                                    title='Therapy'
                                    preSelectedCoding={data}
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEarlyMorningTherapy(e, index)
                                      }
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      //   disabled:
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('08:00'),
                                      //     isCurrentDate(treatmentRow.date),
                                    }}
                                  /> */}
                                  {/* <WelloSelectFHIRCoding
                                    title='Therapy'
                                    availableCodings={listOfTherapies}
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEarlyMorningTherapy(e, index)
                                      }
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      disabled:
                                        isCurrentDate(treatmentRow.date) &&
                                        !isBeforeTime('08:00', ','),
                                    }}
                                  /> */}
                                </Box>
                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='Start Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          //   disabled={
                                          //     isCurrentDate(treatmentRow.date)
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('08:00')
                                          //   }
                                          error={
                                            !isTherapybetweenForMorning(
                                              treatMent[0].morning.startTime,
                                              '04:59',
                                              '08:01'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForMorning(
                                              treatMent[0].morning.startTime,
                                              '04:59',
                                              '08:01'
                                            )
                                              ? 'Start Time should be in between 5 AM to 8 AM'
                                              : ''
                                          }
                                        />
                                      )}
                                      value={treatmentRow.morning.start}
                                      onChange={(newValue) => {
                                        handleEarlyMorningStartTIme(
                                          newValue,
                                          index
                                        )
                                      }}
                                      minTime={treatmentRow.morning.startDate}
                                      maxTime={new Date(0, 0, 0, 8)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}

                                {/* <Box px={0.5}>
                                  <WelloFormItemLabel title='Duration (in mins)' />
                                  <TextField
                                    variant='outlined'
                                    fullWidth
                                    type='number'
                                    size='small'
                                    value={treatmentRow.morning.duration}
                                    onChange={(event) => {
                                      handleChangeDurationEarlyMorning(
                                        event,
                                        index
                                      )
                                    }}
                                    id='d1'
                                    error={
                                      treatmentRow.morning.duration < 1 ||
                                      treatmentRow.morning.duration > 180 ||
                                      Number.isNaN(
                                        treatmentRow.morning.duration
                                      )
                                    }
                                    helperText={
                                      treatmentRow.morning.duration < 1 ||
                                      treatmentRow.morning.duration > 180 ||
                                      Number.isNaN(
                                        treatmentRow.morning.duration
                                      )
                                        ? `Duration should be in between 1 to 180`
                                        : ''
                                    }
                                    InputProps={{
                                      color: 'primary',

                                      inputProps: {
                                        max: 180,
                                        min: 1,
                                      },
                                    }}
                                  />
                                </Box> */}
                                <Box px={0.5} width='20%'>
                                  <WelloSelectFhirCodingForTherapy
                                    title='Temperature'
                                    id='d200'
                                    // preSelectedCoding={therapyType[0]}
                                    availableCodings={therapyType}
                                    onChanged={(type) => {
                                      handleEarlyMorningType(
                                        type.display ?? '',
                                        index
                                      )
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      fullWidth: true,
                                    }}
                                  />
                                </Box>

                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='End Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          //   disabled={
                                          //     isCurrentDate(treatmentRow.date)
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('08:00')
                                          //   }
                                          error={
                                            !isTherapybetweenForMorning(
                                              treatMent[0].morning.endTime,
                                              treatMent[0].morning.startTime,
                                              '08:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForMorning(
                                              treatMent[0].morning.endTime,
                                              treatMent[0].morning.startTime,
                                              '08:00'
                                            )
                                              ? `End Time should be in between ${moment(
                                                  treatMent[0].morning
                                                    .startTime,
                                                  'HHmm'
                                                ).format('hh:mm A')} to 8 AM`
                                              : ''
                                          }
                                        />
                                      )}
                                      //   disabled={
                                      //     isCurrentDate(treatmentRow.date)
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('08:00')
                                      //   }
                                      value={treatmentRow.morning.end}
                                      onChange={(newValue) => {
                                        handleEarlyMorningEndTIme(
                                          newValue,
                                          index
                                        )
                                      }}
                                      minTime={treatmentRow.morning.start}
                                      maxTime={new Date(0, 0, 0, 8)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}
                              </Box>
                              <Box
                                sx={{ width: '30%' }}
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Box px={4} py={1}>
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {' '}
                                    Morning (9 AM - 12.30 PM)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                              >
                                <Box px={4} width='30%'>
                                  <TherapySelectorForPlan
                                    showPrice={true}
                                    id={`morning${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEarlyAfterMorningTherapy(e, index)
                                      }
                                    }}
                                  />
                                  {/* <WelloSelectTherapyList
                                    id={`morning${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEarlyAfterMorningTherapy(e, index)
                                      }
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      //   disabled: isCurrentDate(
                                      //     treatmentRow.date
                                      //   ),
                                      // isCurrentDate(treatmentRow.date) &&
                                      // !isBeforeTime('12:00'),
                                    }}
                                  /> */}
                                </Box>

                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='Start Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          //   disabled={
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('12:00')
                                          //     isCurrentDate(treatmentRow.date)
                                          //   }
                                          error={
                                            !isTherapybetweenForMidMoring(
                                              treatMent[0].midAfterNoon
                                                .startTime,
                                              '07:59',
                                              '12:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].midAfterNoon
                                                .startTime,
                                              '07:59',
                                              '12:00'
                                            )
                                              ? 'Start Time should be in between 8 AM to 12 PM'
                                              : ''
                                          }
                                        />
                                      )}
                                      //   disabled={
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('12:00')
                                      //     isCurrentDate(treatmentRow.date)
                                      //   }
                                      value={treatmentRow.midAfterNoon.start}
                                      onChange={(newValue) => {
                                        handleMidAfternoonStart(newValue, index)
                                      }}
                                      minTime={
                                        treatmentRow.midAfterNoon.startDate
                                      }
                                      maxTime={new Date(0, 0, 0, 12)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}

                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='End Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          //   disabled={
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('12:00')
                                          //     isCurrentDate(treatmentRow.date)
                                          //   }
                                          error={
                                            !isTherapybetweenForMidMoring(
                                              treatMent[0].midAfterNoon.endTime,
                                              treatMent[0].midAfterNoon
                                                .startTime,
                                              '12:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForMidMoring(
                                              treatMent[0].midAfterNoon.endTime,
                                              treatMent[0].midAfterNoon
                                                .startTime,
                                              '12:00'
                                            )
                                              ? `End Time should be in between ${moment(
                                                  treatMent[0].midAfterNoon
                                                    .startTime,
                                                  'HHmm'
                                                ).format('hh:mm A')} to 12 PM`
                                              : ''
                                          }
                                        />
                                      )}
                                      //   disabled={
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('12:00')
                                      //     isCurrentDate(treatmentRow.date)
                                      //   }
                                      value={treatmentRow.midAfterNoon.end}
                                      onChange={(newValue) => {
                                        handleMidAfternoonEnd(newValue, index)
                                      }}
                                      minTime={treatmentRow.midAfterNoon.start}
                                      maxTime={new Date(0, 0, 0, 12)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}
                                {/* <Box px={0.5}>
                                  <WelloFormItemLabel title='Duration (in mins)' />
                                  <TextField
                                    variant='outlined'
                                    fullWidth
                                    type='number'
                                    size='small'
                                    value={treatmentRow.midAfterNoon.duration}
                                    onChange={(event) => {
                                      handleChangeDurationMidAfterNoon(
                                        event,
                                        index
                                      )
                                    }}
                                    id='d2'
                                    error={
                                      treatmentRow.midAfterNoon.duration < 1 ||
                                      treatmentRow.midAfterNoon.duration >
                                        180 ||
                                      Number.isNaN(
                                        treatmentRow.midAfterNoon.duration
                                      )
                                    }
                                    helperText={
                                      treatmentRow.midAfterNoon.duration < 1 ||
                                      treatmentRow.midAfterNoon.duration >
                                        180 ||
                                      Number.isNaN(
                                        treatmentRow.midAfterNoon.duration
                                      )
                                        ? `Duration should be in between 1 to 180`
                                        : ''
                                    }
                                    InputProps={{
                                      color: 'primary',

                                      inputProps: {
                                        max: 180,
                                        min: 1,
                                      },
                                    }}
                                  />
                                </Box> */}
                                <Box px={0.5} width='20%'>
                                  <WelloSelectFhirCodingForTherapy
                                    title='Temperature'
                                    id='d50'
                                    availableCodings={therapyType}
                                    // preSelectedCoding={therapyType[0]}
                                    onChanged={(type) => {
                                      handleEarlyMindAfterNoonType(
                                        type.display ?? '',
                                        index
                                      )
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      fullWidth: true,
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box
                                sx={{ width: '30%' }}
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Box px={4} py={1}>
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {' '}
                                    Afternoon (3 PM - 5.30 PM)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                              >
                                <Box px={4} width='30%'>
                                  <TherapySelectorForPlan
                                    showPrice={true}
                                    id={`afternoon${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleAfterNoonTherapy(e, index)
                                      }
                                    }}
                                  />
                                  {/* <WelloSelectTherapyList
                                    id={`afternoon${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleAfterNoonTherapy(e, index)
                                      }
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      //   disabled:
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('18:00'),
                                      //     isCurrentDate(treatmentRow.date),
                                    }}
                                  /> */}
                                </Box>
                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='Start Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          error={
                                            !isTherapybetweenForAfterNoon(
                                              treatMent[0].afterNoon.startTime,
                                              '11:59',
                                              '18:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForAfterNoon(
                                              treatMent[0].afterNoon.startTime,
                                              '11:59',
                                              '21:00'
                                            )
                                              ? 'Start Time should be in between 12 PM to 6 PM'
                                              : ''
                                          }
                                          //   disabled={
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('18:00')
                                          //     isCurrentDate(treatmentRow.date)
                                          //   }
                                        />
                                      )}
                                      //   disabled={
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('18:00')
                                      //     isCurrentDate(treatmentRow.date)
                                      //   }
                                      value={treatmentRow.afterNoon.start}
                                      onChange={(newValue) => {
                                        handleAfternoonStart(newValue, index)
                                      }}
                                      minTime={treatmentRow.afterNoon.startDate}
                                      maxTime={new Date(0, 0, 0, 18)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}

                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='End Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          //   disabled={
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('18:00')
                                          //     isCurrentDate(treatmentRow.date)
                                          //   }
                                          error={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].afterNoon.endTime,
                                              treatMent[0].afterNoon.startTime,
                                              '18:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].afterNoon.endTime,
                                              treatMent[0].afterNoon.startTime,
                                              '18:00'
                                            )
                                              ? `End Time should be in between ${moment(
                                                  treatMent[0].afterNoon
                                                    .startTime,
                                                  'HHmm'
                                                ).format('hh:mm A')} to 6 PM`
                                              : ''
                                          }
                                        />
                                      )}
                                      //   disabled={
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('18:00')
                                      //     isCurrentDate(treatmentRow.date)
                                      //   }
                                      value={treatmentRow.afterNoon.end}
                                      onChange={(newValue) => {
                                        handleAfternoonEnd(newValue, index)
                                      }}
                                      minTime={treatmentRow.afterNoon.start}
                                      maxTime={new Date(0, 0, 0, 18)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}
                                {/* <Box px={0.5}>
                                  <WelloFormItemLabel title='Duration (in mins)' />
                                  <TextField
                                    variant='outlined'
                                    fullWidth
                                    type='number'
                                    size='small'
                                    id='d4'
                                    value={treatmentRow.afterNoon.duration}
                                    onChange={(event) => {
                                      handleChangeDurationAfterNoon(
                                        event,
                                        index
                                      )
                                    }}
                                    error={
                                      treatmentRow.afterNoon.duration < 1 ||
                                      treatmentRow.afterNoon.duration > 180 ||
                                      Number.isNaN(
                                        treatmentRow.afterNoon.duration
                                      )
                                    }
                                    helperText={
                                      treatmentRow.afterNoon.duration < 1 ||
                                      treatmentRow.afterNoon.duration > 180 ||
                                      Number.isNaN(
                                        treatmentRow.afterNoon.duration
                                      )
                                        ? `Duration should be in between 1 to 180`
                                        : ''
                                    }
                                    InputProps={{
                                      color: 'primary',

                                      inputProps: {
                                        max: 180,
                                        min: 1,
                                      },
                                    }}
                                  />
                                </Box> */}
                                <Box px={0.5} width='20%'>
                                  <WelloSelectFhirCodingForTherapy
                                    title='Temperature'
                                    id='d9'
                                    // preSelectedCoding={therapyType[0]}
                                    availableCodings={therapyType}
                                    onChanged={(type) => {
                                      handleAfterNoonType(
                                        type.display ?? '',
                                        index
                                      )
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      fullWidth: true,
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box
                                sx={{ width: '30%' }}
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Box px={4}>
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {' '}
                                    Evening (7 PM - 7.30 PM)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                              >
                                <Box px={4} width='30%'>
                                  <TherapySelectorForPlan
                                    showPrice={true}
                                    id={`evening${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEveingTherapy(e, index)
                                      }
                                    }}
                                  />
                                  {/* <WelloSelectTherapyList
                                    id={`evening${index}`}
                                    title='Therapy'
                                    appointmentTypeDataVal={appointmentTypeData}
                                    availableCodings={
                                      appointmentTypeData === '304903009' ||
                                      appointmentTypeData === '33022008'
                                        ? threapyCatalogSearchPriceSlice.therapyList ??
                                          []
                                        : threapyCatalogSearchSlice.therapyList ??
                                          []
                                    }
                                    onChanged={(e) => {
                                      if (e) {
                                        handleEveingTherapy(e, index)
                                        //   if (e.price > 0) {
                                        //     setTherapyName(
                                        //       `Price of ${
                                        //         e.therapy.name ?? ''
                                        //       } is Rs. ${
                                        //         e.price
                                        //       }. Do you want to add to the plan ?`
                                        //     )
                                        //     setPricePricePopUp(true)
                                        //   } else {
                                        //     setTherapyName('')
                                        //     setPricePricePopUp(false)
                                        //   }
                                      }
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      //   disabled:
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('21:00'),
                                      //     isCurrentDate(treatmentRow.date),
                                    }}
                                  /> */}
                                </Box>
                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='Start Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          error={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].evening.startTime,
                                              '17:59',
                                              '21:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].evening.startTime,
                                              '17:59',
                                              '21:00'
                                            )
                                              ? 'Start Time should be in between 6 PM to 9 PM'
                                              : ''
                                          }
                                          //   disabled={
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('21:00')
                                          //     isCurrentDate(treatmentRow.date)
                                          //   }
                                        />
                                      )}
                                      //   disabled={
                                      //     // isCurrentDate(treatmentRow.date) &&
                                      //     // !isBeforeTime('21:00')
                                      //     isCurrentDate(treatmentRow.date)
                                      //   }
                                      value={treatmentRow.evening.start}
                                      onChange={(newValue) => {
                                        handleEveningStart(newValue, index)
                                      }}
                                      minTime={treatmentRow.evening.startDate}
                                      maxTime={new Date(0, 0, 0, 21)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}

                                {/* <Box px={0.5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <WelloFormItemLabel title='End Time' />
                                    <TimePicker
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          error={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].evening.endTime,
                                              treatMent[0].evening.startTime,
                                              '21:00'
                                            )
                                          }
                                          helperText={
                                            !isTherapybetweenForEvening(
                                              treatMent[0].evening.endTime,
                                              treatMent[0].evening.startTime,
                                              '21:00'
                                            )
                                              ? `End Time should be in between ${moment(
                                                  treatMent[0].evening
                                                    .startTime,
                                                  'HHmm'
                                                ).format('hh:mm A')} to 9 PM`
                                              : ''
                                          }
                                          //   disabled={
                                          //     // isCurrentDate(treatmentRow.date) &&
                                          //     // !isBeforeTime('21:00')
                                          //     isCurrentDate(treatmentRow.date)
                                          //   }
                                        />
                                      )}
                                      value={treatmentRow.evening.end}
                                      onChange={(newValue) => {
                                        handleEveningEnd(newValue, index)
                                      }}
                                      minTime={treatmentRow.evening.start}
                                      maxTime={new Date(0, 0, 0, 21)}
                                    />
                                  </LocalizationProvider>
                                </Box> */}

                                {/* <Box px={0.5}>
                                  <WelloFormItemLabel title='Duration (in mins)' />
                                  <TextField
                                    variant='outlined'
                                    fullWidth
                                    type='number'
                                    size='small'
                                    value={treatmentRow.evening.duration}
                                    onChange={(event) => {
                                      handleChangeDurationEvening(event, index)
                                    }}
                                    id='d20'
                                    error={
                                      treatmentRow.evening.duration < 1 ||
                                      treatmentRow.evening.duration > 180 ||
                                      Number.isNaN(
                                        treatmentRow.evening.duration
                                      )
                                    }
                                    helperText={
                                      treatmentRow.evening.duration < 1 ||
                                      treatmentRow.evening.duration > 180 ||
                                      Number.isNaN(
                                        treatmentRow.afterNoon.duration
                                      )
                                        ? `Duration should be in between 1 to 180`
                                        : ''
                                    }
                                    InputProps={{
                                      color: 'primary',

                                      inputProps: {
                                        max: 180,
                                        min: 1,
                                      },
                                    }}
                                  />
                                </Box> */}
                                <Box px={0.5} width='20%'>
                                  <WelloSelectFhirCodingForTherapy
                                    title='Temperature '
                                    id='24'
                                    // preSelectedCoding={therapyType[0]}
                                    availableCodings={therapyType}
                                    onChanged={(type) => {
                                      handleEveningType(
                                        type.display ?? '',
                                        index
                                      )
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      fullWidth: true,
                                    }}
                                  />
                                </Box>
                              </Box>

                              <Box
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                                paddingTop={1}
                              >
                                <Box>
                                  <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    disabled={
                                      (!treatMent[0].evening.therapy &&
                                        !treatMent[0].afterNoon.therapy &&
                                        !treatMent[0].morning.therapy &&
                                        !treatMent[0].midAfterNoon.therapy) ||
                                      //   treatMent[0].evening.duration < 1 ||
                                      //   treatMent[0].afterNoon.duration < 1 ||
                                      //   treatMent[0].morning.duration < 1 ||
                                      //   treatMent[0].midAfterNoon.duration < 1 ||
                                      //   treatMent[0].evening.duration > 180 ||
                                      //   Number.isNaN(
                                      //     treatMent[0].midAfterNoon.duration
                                      //   ) ||
                                      //   treatMent[0].midAfterNoon.duration >
                                      //     180 ||
                                      //   Number.isNaN(
                                      //     treatMent[0].midAfterNoon.duration
                                      //   ) ||
                                      //   treatMent[0].morning.duration > 180 ||
                                      //   Number.isNaN(
                                      //     treatMent[0].morning.duration
                                      //   ) ||
                                      //   treatMent[0].afterNoon.duration > 180 ||
                                      //   Number.isNaN(
                                      //     treatMent[0].afterNoon.duration
                                      //   ) ||
                                      isEnabled(
                                        treatmentSearchSlice.treatmentList ?? []
                                      ) ||
                                      !isTherapybetweenForMorning(
                                        treatMent[0].morning.startTime,
                                        '07:30',
                                        '08:00'
                                      ) ||
                                      !isTherapybetweenForMorning(
                                        treatMent[0].morning.endTime,
                                        '07:30',
                                        '08:00'
                                      ) ||
                                      !isTherapybetweenForMidMoring(
                                        treatMent[0].midAfterNoon.startTime,
                                        '09:00',
                                        '12:30'
                                      ) ||
                                      !isTherapybetweenForMidMoring(
                                        treatMent[0].midAfterNoon.endTime,
                                        '09:00',
                                        '12:30'
                                      ) ||
                                      !isTherapybetweenForAfterNoon(
                                        treatMent[0].afterNoon.startTime,
                                        '15:00',
                                        '17:30'
                                      ) ||
                                      !isTherapybetweenForAfterNoon(
                                        treatMent[0].afterNoon.endTime,
                                        '15:00',
                                        '17:30'
                                      ) ||
                                      !isTherapybetweenForEvening(
                                        treatMent[0].evening.startTime,
                                        '19:00',
                                        '19:30'
                                      ) ||
                                      !isTherapybetweenForEvening(
                                        treatMent[0].evening.endTime,
                                        '19:00',
                                        '19:30'
                                      )
                                    }
                                    onClick={() => {
                                      if (
                                        treatmentSearchSlice.treatmentList &&
                                        treatmentSearchSlice.treatmentList
                                          .length > 0
                                      ) {
                                        checkForExistingTreatment(
                                          treatmentSearchSlice.treatmentList,
                                          treatMent
                                        )
                                      }

                                      if (
                                        isTherapybetweenForMorning(
                                          treatMent[0].morning.startTime,
                                          '07:30',
                                          '08:00'
                                        ) &&
                                        isTherapybetweenForMorning(
                                          treatMent[0].morning.endTime,
                                          '07:30',
                                          '08:00'
                                        ) &&
                                        isTherapybetweenForMidMoring(
                                          treatMent[0].midAfterNoon.startTime,
                                          '09:00',
                                          '12:30'
                                        ) &&
                                        isTherapybetweenForMidMoring(
                                          treatMent[0].midAfterNoon.endTime,
                                          '09:00',
                                          '12:30'
                                        ) &&
                                        isTherapybetweenForAfterNoon(
                                          treatMent[0].afterNoon.startTime,
                                          '15:00',
                                          '17:30'
                                        ) &&
                                        isTherapybetweenForAfterNoon(
                                          treatMent[0].afterNoon.endTime,
                                          '15:00',
                                          '17:30'
                                        ) &&
                                        isTherapybetweenForEvening(
                                          treatMent[0].evening.startTime,
                                          '19:00',
                                          '19:30'
                                        ) &&
                                        isTherapybetweenForEvening(
                                          treatMent[0].evening.endTime,
                                          '19:00',
                                          '19:30'
                                        )
                                      ) {
                                        if (
                                          morningDuplicate.length > 0 ||
                                          midAfterNoonDuplicate.length > 0 ||
                                          afterNoonDuplicate.length > 0 ||
                                          eveningDuplicate.length > 0
                                        ) {
                                          setShowText(true)
                                        } else {
                                          console.log(treatMent)
                                          dispatch(
                                            addTreatmentDetails(
                                              treatMent,
                                              fhirAppointmentDetails
                                            )
                                          )
                                        }
                                      } else {
                                        setShowTextForSlot(true)
                                      }

                                      //   if (
                                      //     !morningDuplicate.current &&
                                      //     !midAfterNoonDuplicate.current &&
                                      //     !afterNoonDuplicate.current &&
                                      //     !eveningDuplicate.current
                                      //   ) {
                                    }}
                                    id='add_therapy'
                                  >
                                    <Typography variant='subtitle2'>
                                      {' '}
                                      Save
                                    </Typography>
                                  </Button>
                                </Box>

                                <Box>
                                  <Button
                                    variant='contained'
                                    size='small'
                                    id='reset_therapy'
                                    color='primary'
                                    onClick={() => {
                                      resetValues()
                                    }}
                                  >
                                    <Typography variant='subtitle2'>
                                      {' '}
                                      Reset
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          )
                        )}
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>

      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}
      <AlertDialog
        open={showText}
        onClose={() => {
          resetValues()
          setShowText(false)
        }}
        notesData='This time slot is already booked'
      />

      <NoteDialog
        id='680'
        open={showTextForNotes}
        onClose={() => {
          setFullText('')
          setShowTextForNotes(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
      <AlertDialog
        open={showTextForSlot}
        onClose={() => {
          setShowTextForSlot(false)
        }}
        notesData='Please choose slot in given time frame'
      />

      <AlertDialog
        open={showTextForMorning}
        onClose={() => {
          setShowTextForMorning(false)
        }}
        notesData='Please choose morning slot in given time frame '
      />

      <AlertDialog
        open={showTextForMidMorning}
        onClose={() => {
          setShowTextForMidMorning(false)
        }}
        notesData='Please choose mid morning slot in given time frame '
      />

      <AlertDialog
        open={showTextForAfterNoon}
        onClose={() => {
          setShowTextForAfterNoon(false)
        }}
        notesData='Please choose afternoon slot in given time frame '
      />
      <AlertDialog
        open={showTextForEvening}
        onClose={() => {
          setShowTextForEvening(false)
        }}
        notesData='Please choose evening slot in given time frame '
      />
    </Box>
  )
}
