import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import {
  getCommunicationRequestFromBundle,
  getServiceRequestFromBundle,
} from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { logger } from 'utils/logger'
import { SpecialistReferralsListStatus } from './specialistReferralsListStatus'

const initialState: SpecialistReferralsListStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const specialistReferralsListSlice = createSlice({
  name: 'specialistReferralsListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<SpecialistReferralsListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.lanDiagnosticRequests = action.payload.lanDiagnosticRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getSpecialistReferralsOfAppointment =
  (followUp?: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SpecialistReferralsListStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(specialistReferralsListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        code: 'doctor-referral',
      }

      const visitReference = sessionStorage.getItem(CURRENT_VISIT_REFERENCE)
      if (visitReference) {
        if (visitReference.toLowerCase().includes('appointment')) {
          const appointmentId = visitReference.split('/')[1]
          searchParameters['encounter.appointment'] = appointmentId
        } else {
          const serviceRequestId = visitReference.split('/')[1]
          searchParameters['encounter.based-on'] = serviceRequestId
        }

        const response: any = await fhirClient.doGetResourceForAppointment(
          '/ServiceRequest',
          visitReference!,
          searchParameters
        )

        logger.info(
          '-----------------------------CE-----REQ----------------------------------------------',
          response
        )
        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (resp._tag === 'Left') {
          state.errorWhileSearchingImpressions = true
          state.fetchingImpressions = false

          state.errorReason = 'Error while fetching referrals details'

          dispatch(specialistReferralsListSlice.actions.updatedStatus(state))
        } else {
          const conditionResponse: R4.IBundle = resp.right
          logger.info('CommunicationRequest resp', conditionResponse.entry)
          if (conditionResponse?.total && conditionResponse?.total > 0) {
            const clinicalImpressions: R4.IServiceRequest[] =
              getServiceRequestFromBundle(conditionResponse)

            if (clinicalImpressions && clinicalImpressions.length > 0) {
              state.resultsAvailable = true
              state.fetchingImpressions = false
              state.lanDiagnosticRequests = clinicalImpressions

              state.noResultsAvailable = false
              state.errorReason = undefined
              state.errorWhileSearchingImpressions = false
              dispatch(
                specialistReferralsListSlice.actions.updatedStatus(state)
              )
            }
          } else {
            const errorSearchDoctor: SpecialistReferralsListStatus = {
              ...initialState,
            }
            errorSearchDoctor.noResultsAvailable = true
            dispatch(
              specialistReferralsListSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        }
      } else {
        const errorSearchDoctor: SpecialistReferralsListStatus = {
          ...initialState,
        }
        errorSearchDoctor.errorReason = 'Error while fetching referrals details'
        errorSearchDoctor.errorWhileSearchingImpressions = false
        dispatch(
          specialistReferralsListSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
      /* */
    } catch (error) {
      const errorSearchDoctor: SpecialistReferralsListStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching referrals details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        specialistReferralsListSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }
export default specialistReferralsListSlice.reducer
