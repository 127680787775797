import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { MedicationGroupedData } from 'models/medicationGrouppedData'
import { getMedicationSearchDataGrouped } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { TerminologyClient } from 'services/terminologyClient'
import { IndianMedicationSearchStatus } from './indianMedicationSearchStatusType'

const initialState: IndianMedicationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const indianMedicationSearchSlice = createSlice({
  name: 'indianMedicationSearchSlice',
  initialState,
  reducers: {
    searchingMedication(
      state,
      action: PayloadAction<IndianMedicationSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<IndianMedicationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.medicationList = action.payload.medicationList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<IndianMedicationSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.medicationList = action.payload.medicationList
      state.totalCount = action.payload.totalCount
    },

    errorWhileSearching(
      state,
      action: PayloadAction<IndianMedicationSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.medicationList = action.payload.medicationList
      state.totalCount = action.payload.totalCount
    },
    resetState(state, action: PayloadAction<IndianMedicationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
      state.medicationList = undefined
      state.totalCount = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: any) => {
    const state: IndianMedicationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
      totalCount: undefined,
    }
    dispatch(indianMedicationSearchSlice.actions.resetState(state))
  }

export const searchMedicationsIdnain =
  (
    pageNumber: number,
    searchString: string,
    selectOther: boolean,
    category?: string,
    existingMedicationData?: MedicationGroupedData[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: IndianMedicationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      indianMedicationSearchSlice.actions.errorWhileSearching(
        errorSearchPatient
      )
    )
    try {
      const fhirClient: TerminologyClient = new TerminologyClient()
      const response: any = await fhirClient.doGetResource(
        `medication/search?text=${searchString}&fuzzy=true&category_token=${
          category ?? ''
        }&size=10&offset=${pageNumber}`
      )

      if (response !== 'undefined') {
        const data: MedicationGroupedData[] =
          getMedicationSearchDataGrouped(response)
        console.log(data)

        let finalData: MedicationGroupedData[] = existingMedicationData ?? []

        if (finalData.length > 0) {
          finalData.concat(data)
        } else {
          finalData = data
        }

        const searchPatientResult: IndianMedicationSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: [],
          totalCount: finalData.length,
          medicationList: finalData,
        }
        dispatch(
          indianMedicationSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: IndianMedicationSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        indianMedicationSearchSlice.actions.noDataFoundForSearch(
          noSearchResults
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: IndianMedicationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        indianMedicationSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: any) => {
  dispatch(indianMedicationSearchSlice.actions.resetState(initialState))
}

export default indianMedicationSearchSlice.reducer
