import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { WelloUnits } from 'models/welloUnit'

import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getUserDetails, isOrgAdmin } from 'services/userDetailsService'
import {
  getAddress,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  getWelloUnitsWithMainOrg,
  getWelloUnitsWithUnitOrg,
} from 'utils/fhirResoureHelpers/organizationHelpers'
import { logger } from 'utils/logger'
import { SearchUnitStatus } from './unitCountStatus'

const initialState: SearchUnitStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const unitCountSearchSlice = createSlice({
  name: 'unitCountSearchSlice',
  initialState,
  reducers: {
    searchingPatientDetails(state, action: PayloadAction<SearchUnitStatus>) {},

    searchResults(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicCount = action.payload.clinicCount
      state.labCount = action.payload.labCount
      state.wellnessCenterCount = action.payload.wellnessCenterCount
    },

    noDataFoundForSearch(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicCount = action.payload.clinicCount
      state.labCount = action.payload.labCount
      state.wellnessCenterCount = action.payload.wellnessCenterCount
    },

    errorWhileSearching(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicCount = action.payload.clinicCount
      state.labCount = action.payload.labCount
      state.wellnessCenterCount = action.payload.wellnessCenterCount
    },
    resetCountUnit(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicCount = action.payload.clinicCount
      state.labCount = action.payload.labCount
      state.wellnessCenterCount = action.payload.wellnessCenterCount
    },
  },
})

export const resetUnitSearchCount =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const errorWhileSearchPatient: SearchUnitStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: '',
    }
    dispatch(
      unitCountSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
  }

export const searchForUnitCount =
  (orgId: string): AppThunk =>
  async (dispatch: any) => {
    const errorFetchingUnits: SearchUnitStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(unitCountSearchSlice.actions.resetCountUnit(errorFetchingUnits))
    try {
      const mainOrganization: R4.IOrganization | undefined =
        getUserDetails()?.mainOrganization
      const searchParametes = {}

      const fhirClient: MasterFhirClient = new MasterFhirClient()
      let response: any
      if (isOrgAdmin()) {
        response = await fhirClient.doGetResource(
          `/Organization?_count=300&_revinclude=Organization:partof&_id=${orgId}&_tag:not=mirror-resource`,
          ''
        )
      } else {
        await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
          `/Organization?_count=300&_revinclude=Organization:partof&_id=${orgId}&_tag:not=mirror-resource`,
          '',
          searchParametes
        )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (orgResponse.total) {
          if (orgResponse.total > 0) {
            if (orgResponse.entry) {
              const orgArray: R4.IOrganization[] = orgResponse.entry.map(
                (item) => item.resource as R4.IOrganization
              )
              const mainOrganizationArr: R4.IOrganization[] = orgArray.filter(
                (org: R4.IOrganization) =>
                  org.partOf === undefined &&
                  org.meta &&
                  org.name &&
                  org.name !== 'Wellopathy'
              )

              const unitOrgArray: R4.IOrganization[] = orgArray.filter(
                (org: R4.IOrganization) => org.partOf !== undefined
              )
              unitOrgArray.sort((a, b) =>
                (a.name ?? '')
                  .toLowerCase()
                  .localeCompare((b.name ?? '').toLowerCase())
              )

              const fetchUnitListResult: SearchUnitStatus = {
                error: false,
                noResultsAvailable: false,
                clinicCount: {
                  totalCount: getUnitDetails(unitOrgArray),
                  enabledCount: getUnitDetailsActive(unitOrgArray),
                  disabledCount: getUnitDetailsDisabled(unitOrgArray),
                },
                labCount: {
                  totalCount: unitOrgArray.filter(
                    (org: R4.IOrganization) =>
                      org.type &&
                      org.type[0].coding &&
                      org.type[0].coding[0].code === 'lab'
                  ).length,
                  enabledCount: unitOrgArray.filter(
                    (org: R4.IOrganization) =>
                      org.type &&
                      org.type[0].coding &&
                      org.type[0].coding[0].code === 'lab' &&
                      org.active &&
                      org.active === true
                  ).length,
                  disabledCount: unitOrgArray.filter(
                    (org: R4.IOrganization) =>
                      org.type &&
                      org.type[0].coding &&
                      org.type[0].coding[0].code === 'lab' &&
                      org.active! === false
                  ).length,
                },
                wellnessCenterCount: {
                  totalCount: unitOrgArray.filter(
                    (org: R4.IOrganization) =>
                      org.type &&
                      org.type[0].coding &&
                      org.type[0].coding[0].code === 'wellness_center'
                  ).length,
                  enabledCount: unitOrgArray.filter(
                    (org: R4.IOrganization) =>
                      org.type &&
                      org.type[0].coding &&
                      org.type[0].coding[0].code === 'wellness_center' &&
                      org.active &&
                      org.active === true
                  ).length,
                  disabledCount: unitOrgArray.filter(
                    (org: R4.IOrganization) =>
                      org.type &&
                      org.type[0].coding &&
                      org.type[0].coding[0].code === 'wellness_center' &&
                      org.active! === false
                  ).length,
                },
                resultsAvailable: true,
                searching: false,
              }
              dispatch(
                unitCountSearchSlice.actions.searchResults(fetchUnitListResult)
              )
              return
            }
          }
        }
        const noSearchResults: SearchUnitStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          unitCountSearchSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return
      }
      const errorWhileSearchPatient: SearchUnitStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        unitCountSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: SearchUnitStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        unitCountSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

async function getOrgOnbardedDate(org: R4.IOrganization): Promise<WelloUnits> {
  let date: string = ''
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `/Organization/${org.id}/_history/1`,
      ''
    )
  logger.info('Org  Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IOrganization> =
    R4.RTTI_Organization.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IOrganization = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        date = moment(patResponse.meta.lastUpdated).format('DD-MM-YYYY')
      }
    }
  }
  const unitData: WelloUnits = {
    orgDetails: org,
    onboardDate: date,
    orgName: org.name ?? '',
    address: getAddress(org.address).trim() ?? '',
    phone: org.contact
      ? getTelecomFromContactPoints(
          org.contact[0].telecom ?? [],
          ContactPointSystemKind._phone
        ) ?? ''
      : '',
    email: org.contact
      ? getTelecomFromContactPoints(
          org.contact[0].telecom ?? [],
          ContactPointSystemKind._email
        ) ?? ''
      : '',
    status: org.active && org.active === true ? 'Enabled' : 'Disabled',
  }
  return unitData
}

function getUnitDetails(unitArray: R4.IOrganization[]): number {
  const totalCount: string[] = []
  if (unitArray.length > 0) {
    for (let i = 0; i < unitArray.length; i++) {
      const tag = unitArray[i].meta
      if (tag) {
        const metaTag = tag.tag ?? []
        if (metaTag.length > 0) {
          const stateData = metaTag.filter(
            (d: R4.ICoding) => d.code && d.code === 'mirror-resource'
          )
          if (stateData.length === 0) {
            const unitType = unitArray[i].type ?? []
            if (unitType.length > 0) {
              for (let j = 0; j < unitType.length; j++) {
                const codingArray = unitType[j].coding ?? []
                if (codingArray.length > 0) {
                  for (let k = 0; k < codingArray.length; k++) {
                    const codeData = codingArray[k].code ?? ''
                    if (codeData.length > 0 && codeData.trim() === 'clinic') {
                      totalCount.push('1')
                    }
                  }
                }
              }
            }
          }
        } else {
          const unitType = unitArray[i].type ?? []
          if (unitType.length > 0) {
            for (let j = 0; j < unitType.length; j++) {
              const codingArray = unitType[j].coding ?? []
              if (codingArray.length > 0) {
                for (let k = 0; k < codingArray.length; k++) {
                  const codeData = codingArray[k].code ?? ''
                  if (codeData.length > 0 && codeData.trim() === 'clinic') {
                    totalCount.push('1')
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return totalCount.length
}

function getUnitDetailsActive(unitArray: R4.IOrganization[]): number {
  const totalCount: string[] = []
  if (unitArray.length > 0) {
    for (let i = 0; i < unitArray.length; i++) {
      const tag = unitArray[i].meta
      if (tag && unitArray[i].active && unitArray[i].active === true) {
        const metaTag = tag.tag ?? []
        if (metaTag.length > 0) {
          const stateData = metaTag.filter(
            (d: R4.ICoding) => d.code && d.code === 'mirror-resource'
          )
          if (stateData.length === 0) {
            console.log(unitArray[i])
            const unitType = unitArray[i].type ?? []
            if (unitType.length > 0) {
              for (let j = 0; j < unitType.length; j++) {
                const codingArray = unitType[j].coding ?? []
                if (codingArray.length > 0) {
                  for (let k = 0; k < codingArray.length; k++) {
                    const codeData = codingArray[k].code ?? ''
                    if (codeData.length > 0 && codeData.trim() === 'clinic') {
                      totalCount.push('1')
                    }
                  }
                }
              }
            }
          }
        } else {
          console.log(unitArray[i])
          const unitType = unitArray[i].type ?? []
          if (unitType.length > 0) {
            for (let j = 0; j < unitType.length; j++) {
              const codingArray = unitType[j].coding ?? []
              if (codingArray.length > 0) {
                for (let k = 0; k < codingArray.length; k++) {
                  const codeData = codingArray[k].code ?? ''
                  if (codeData.length > 0 && codeData.trim() === 'clinic') {
                    totalCount.push('1')
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return totalCount.length
}

function getUnitDetailsDisabled(unitArray: R4.IOrganization[]): number {
  const totalCount: string[] = []
  if (unitArray.length > 0) {
    for (let i = 0; i < unitArray.length; i++) {
      const tag = unitArray[i].meta
      if (tag && unitArray[i].active && unitArray[i].active === false) {
        const metaTag = tag.tag ?? []
        if (metaTag.length > 0) {
          const stateData = metaTag.filter(
            (d: R4.ICoding) => d.code && d.code === 'mirror-resource'
          )
          if (stateData.length === 0) {
            const unitType = unitArray[i].type ?? []
            if (unitType.length > 0) {
              for (let j = 0; j < unitType.length; j++) {
                const codingArray = unitType[j].coding ?? []
                if (codingArray.length > 0) {
                  for (let k = 0; k < codingArray.length; k++) {
                    const codeData = codingArray[k].code ?? ''
                    if (codeData.length > 0 && codeData.trim() === 'clinic') {
                      totalCount.push('1')
                    }
                  }
                }
              }
            }
          }
        } else {
          const unitType = unitArray[i].type ?? []
          if (unitType.length > 0) {
            for (let j = 0; j < unitType.length; j++) {
              const codingArray = unitType[j].coding ?? []
              if (codingArray.length > 0) {
                for (let k = 0; k < codingArray.length; k++) {
                  const codeData = codingArray[k].code ?? ''
                  if (codeData.length > 0 && codeData.trim() === 'clinic') {
                    totalCount.push('1')
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return totalCount.length
}

export default unitCountSearchSlice.reducer
