import { R4 } from '@ahryman40k/ts-fhir-types'
import { IOrganization } from '@ahryman40k/ts-fhir-types/lib/R4'
import { leftTask } from 'fp-ts/lib/TaskThese'
import jwtDecode from 'jwt-decode'
import { OpenAPI } from 'lib/openApi'
import { CurrentLoggedInUser, RoleDetails } from 'models/currentUserDetails'
import { OIDCUser } from 'models/oidcUser'
import { OIDCUserInfo } from 'models/oidcUserInfo'
import { UnitServices } from 'models/units/unitServices'
import { getUserDetails } from 'services/userDetailsService'
import {
  ACCESS_TOKEN,
  CURRENT_UNIT_SERVICES,
  ID_TOKEN,
  USER_DETAILS,
  USER_INFO,
} from './appConstants'
import { logger } from './logger'

export const isAuthenticated = (): string | null => {
  const authToken: string | null = getAccessToken()

  if (authToken) {
    const decodedToken: any = jwtDecode(authToken)
    return authToken
  }
  return null
}

export const isAuthenticatedLL = (): string | null => {
  const authToken: string | null = getAccessToken()

  if (authToken) {
    const decodedToken: any = jwtDecode(authToken)
    return authToken
  }
  return null
}

export const storeToken = (idToken: string, accessToken: string) => {
  OpenAPI.BASE = process.env.REACT_APP_BASE_URL ?? ''
  OpenAPI.TOKEN = accessToken ?? ''
  sessionStorage.setItem(ACCESS_TOKEN, accessToken)
  sessionStorage.setItem(ID_TOKEN, idToken)
}

export const storeUserInfo = (userInfo: any) => {
  logger.info(userInfo)
  logger.info('oidcUser before storing', userInfo)
  // eslint-disable-next-line @typescript-eslint/dot-notation
  logger.info('oidcUser fhir user before storing', userInfo['fhirUser'])
  sessionStorage.setItem(USER_INFO, JSON.stringify(userInfo))
}

export const storeUserDetails = async (
  userInfo: CurrentLoggedInUser
): Promise<boolean> => {
  const res = sessionStorage.getItem(USER_DETAILS)
  if (res != null) {
    sessionStorage.removeItem(USER_DETAILS)
  }

  sessionStorage.setItem(USER_DETAILS, JSON.stringify(userInfo))
  const aftRes = sessionStorage.getItem(USER_DETAILS)
  if (aftRes != null) {
    return true
  }

  return false
}

export const storeUnitServices = async (
  userInfo: UnitServices[]
): Promise<boolean> => {
  const res = sessionStorage.getItem(CURRENT_UNIT_SERVICES)
  if (res != null) {
    sessionStorage.removeItem(CURRENT_UNIT_SERVICES)
  }

  sessionStorage.setItem(CURRENT_UNIT_SERVICES, JSON.stringify(userInfo))
  const aftRes = sessionStorage.getItem(CURRENT_UNIT_SERVICES)
  if (aftRes != null) {
    return true
  }

  return false
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/* export const getUserDetails = (): CurrentLoggedInUser | undefined => {
  const tempString = sessionStorage.getItem(USER_DETAILS)
  if (tempString) {
    const loggedInUserDetails: CurrentLoggedInUser = JSON.parse(tempString)
    return loggedInUserDetails
  }
  return undefined
} */

export const getUserInfo = (): string | null =>
  sessionStorage.getItem(USER_INFO)

export const getAccessToken = (): string | null => {
  OpenAPI.BASE = process.env.REACT_APP_BASE_URL ?? ''
  OpenAPI.TOKEN = sessionStorage.getItem(ACCESS_TOKEN) ?? ''
  return sessionStorage.getItem(ACCESS_TOKEN)
}

export const getOIDCUserObject = (): OIDCUser | null => {
  OpenAPI.BASE = process.env.REACT_APP_BASE_URL ?? ''
  const strUserInfo: string | null = getUserInfo()
  if (strUserInfo != null) {
    const oidcUser: OIDCUser = JSON.parse(strUserInfo)
    return oidcUser
  }
  return null
}

export const getCurrentUnitServices = (): UnitServices[] | null => {
  const strUserInfo: string | null = sessionStorage.getItem(
    CURRENT_UNIT_SERVICES
  )
  if (strUserInfo != null) {
    const oidcUser: UnitServices[] = JSON.parse(strUserInfo)
    return oidcUser
  }
  return null
}

export const getOIDCUserInfoObject = (): OIDCUserInfo | undefined => {
  OpenAPI.BASE = process.env.REACT_APP_BASE_URL ?? ''
  const strUserInfo: string | null = getUserInfo()
  logger.info('strUserInfo', strUserInfo)
  if (strUserInfo != null) {
    const oidcUser: OIDCUserInfo = JSON.parse(strUserInfo) as OIDCUserInfo
    logger.info('oidcUser', oidcUser)
    logger.info('oidcUser fhir user', oidcUser.fhirUser)
    return oidcUser
  }
  return undefined
}

export const getLogOutUrl = (): string => {
  const token: string = sessionStorage.getItem(ACCESS_TOKEN) ?? ''
  const clientId: string =
    process.env.REACT_APP_NAME === 'WelloLAB'
      ? 'wello_lab_web'
      : process.env.REACT_APP_NAME === 'WelloDR'
      ? 'wello_clinic_web'
      : 'wello_admin_web'
  const redirectUri: string = process.env.REACT_APP_REDIRECT_URL ?? ''
  const url: string = `${
    process.env.REACT_APP_AUTH_URL ?? ''
  }/oidc/session/end?post_logout_redirect_uri=${redirectUri}&client_id=${clientId}`
  return url
}

export const getCurrentUserDetailsFromReference = (
  inputBundle: R4.IBundle,
  isBeforeRoleSelection?: boolean
): CurrentLoggedInUser | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  let practitioner: R4.IPractitioner | undefined
  const practitionerRoles: any = {}
  const organizations: Map<string, R4.IOrganization> = new Map<
    string,
    R4.IOrganization
  >()
  const locations: any = {}
  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Practitioner':
                  practitioner = value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Organization':
                  organizations.set(
                    value.resource.id,
                    value.resource as R4.IOrganization
                  )
                  logger.info('Organizations', organizations)
                  break
                case 'Location':
                  locations[value.resource.id] = value.resource as R4.ILocation
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (practitioner) {
          const practitionerDetails: R4.IPractitioner = practitioner
          let mainOrganizationDetails: R4.IOrganization = {
            resourceType: 'Organization',
          }
          organizations.forEach((org) => {
            if (org.partOf === undefined) {
              mainOrganizationDetails = org
            }
          })
          const availableRolesDetails: RoleDetails[] = []
          for (const key in practitionerRoles) {
            if (key) {
              const currentRole: R4.IPractitionerRole = practitionerRoles[key]
              let currentOrganization: R4.IOrganization | undefined
              let currentLocation: R4.ILocation | undefined
              const organizationId: string | undefined =
                currentRole.organization?.reference?.split('/')[1]

              const locationId: string | undefined =
                currentRole.location?.[0].reference?.split('/')[1]
              if (organizationId && organizations.has(organizationId)) {
                currentOrganization = organizations.get(organizationId)
              }

              if (locationId && locations[locationId]) {
                currentLocation = locations[locationId]
              }

              availableRolesDetails.push({
                practitionerRole: currentRole,
                unitOrganization: currentOrganization,
                locationDetails: currentLocation,
              })
            }
          }
          let currentUserDetails: CurrentLoggedInUser = {
            mainOrganization: mainOrganizationDetails,
            practitioner: practitionerDetails,
            practitionerRole: availableRolesDetails[0].practitionerRole,
            availableRoles: availableRolesDetails,
            unitOrganization: availableRolesDetails[0].unitOrganization,
            locationDetails: availableRolesDetails[0].locationDetails,
          }

          if (isBeforeRoleSelection && availableRolesDetails.length > 1) {
            currentUserDetails = {
              mainOrganization: mainOrganizationDetails,
              practitioner: practitionerDetails,
              practitionerRole: undefined,
              availableRoles: availableRolesDetails,
              unitOrganization: undefined,
              locationDetails: undefined,
            }
          } else {
            let userIndex = 0
            const userD = getUserDetails()
            if (userD && userD.practitionerRole && userD.practitionerRole.id) {
              userIndex = availableRolesDetails.findIndex(
                (roleDetail) =>
                  roleDetail.practitionerRole.id === userD.practitionerRole!.id
              )

              if (userIndex < 0) {
                userIndex = 0
              }
            }

            currentUserDetails = {
              mainOrganization: mainOrganizationDetails,
              practitioner: practitionerDetails,
              practitionerRole:
                availableRolesDetails[userIndex].practitionerRole,
              availableRoles: availableRolesDetails,
              unitOrganization:
                availableRolesDetails[userIndex].unitOrganization,
              locationDetails: availableRolesDetails[userIndex].locationDetails,
            }
          }
          logger.info(
            '-------------------Inside distillation function response------------------------'
          )
          return currentUserDetails
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}
