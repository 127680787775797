import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showWarningAlert } from 'redux/alertHandler/alertSlice'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getIdentifierValueBySystem,
  getTelecomFromContactPoints,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloLoadingIndicator } from 'wello-web-components'

interface Props {
  id: string
  open: boolean
  patientData: R4.IPatient
  onPatientSelected: (selectedPatient: R4.IPatient) => void
  onClose: () => void
  onNewPatientCreated: () => void
  onNoPatientsAvailable: () => void
  isLab?: boolean
}
export const PatientChecker: React.FC<Props> = ({
  id,
  open,
  patientData,
  onClose,
  onNewPatientCreated,
  onPatientSelected,
  onNoPatientsAvailable,
  isLab,
}) => {
  const { t } = useTranslation()
  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )
  const [selectedPatient, setSelectedPatient] = useState<string>()
  const [selected, setSelected] = useState<boolean>(false)
  const [selectedRelationShip, setSelectedRelationShip] = useState<R4.ICoding>()
  const [isActiveCondition, setIsActiveCondtion] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (patientsSearchState.noResultsAvailable) {
      dispatch(showWarningAlert('No Matching patients are there'))
      onNoPatientsAvailable()
    }
  }, [patientsSearchState.noResultsAvailable])

  useEffect(() => {
    if (isLab === undefined) {
      if (patientData) {
        if (
          getTelecomFromContactPoints(
            patientData.telecom ?? [],
            R4.ContactPointSystemKind._phone
          ) !== undefined
        ) {
          dispatch(
            searchPatients(
              getTelecomOfPatient(
                patientData!,
                R4.ContactPointSystemKind._phone
              )!
                .replace(/\D/g, '')
                .slice(-10),
              ''
            )
          )
        }
      }
    } else {
      console.log(patientData)
      dispatch(
        searchPatients(
          getTelecomOfPatient(patientData!, R4.ContactPointSystemKind._phone)!
            .replace(/\D/g, '')
            .slice(-10),
          '',
          getIdentifierValueBySystem(
            patientData!.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/id/offset-phone'
          )
        )
      )
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        dispatch(resetPatientSearchPatient())
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Checking for Matched Patients</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                dispatch(resetPatientSearchPatient())
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        {patientsSearchState.searching && (
          <WelloLoadingIndicator message='Checking if patient with mobile number is already there' />
        )}
        {patientsSearchState.patientList &&
          patientsSearchState.patientList.length > 0 && (
            <Box display='flex' flexDirection='column' py={1}>
              <Box display='flex' flexDirection='row'>
                <Typography variant='h6' color='initial'>
                  Select patient{' '}
                </Typography>
              </Box>
              {patientsSearchState.patientList.map((patient) => (
                <PatientTile
                  key={patient.id ?? ''}
                  patient={patient}
                  isSelected={patient.id === selectedPatient}
                  onSelected={(selectedOptions: string) => {
                    onPatientSelected(patient)
                    dispatch(resetPatientSearchPatient())
                  }}
                />
              ))}{' '}
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(resetPatientSearchPatient())
            onNewPatientCreated()
          }}
          variant='outlined'
          disableElevation
        >
          Create new Patient
        </Button>
      </DialogActions>
    </Dialog>
  )
}
