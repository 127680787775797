import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedOccupation } from 'models/groupedOccupations'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import {
  getAddictionsDataFromObs,
  getSortedObservationForAddiction,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { IPDAddictionsHistoryStatus } from './IPDaddictionsHistoryTypes'

const initialState: IPDAddictionsHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const addictionsHistorySliceIPD = createSlice({
  name: 'addictionsHistorySlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IPDAddictionsHistoryStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.noResultsAvailableOccupation =
        action.payload.noResultsAvailableOccupation
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.addictionsList = action.payload.addictionsList
      state.addictionFinalData = action.payload.addictionFinalData
      state.groupedObs = action.payload.groupedObs
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const requestAddictionsHistoryOfPatientIPD =
  (
    appointmentId: string,
    selectedPatient: R4.IPatient,
    split: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IPDAddictionsHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(addictionsHistorySliceIPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        category: 'social-history,survey',
        _count: 500,
        code: '64218-1,96103-7,10000-3,68518-0',
        _sort: '-_lastUpdated',
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }
      const date = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      ).toISOString()}`

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`
      let response: any
      if (split) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformIPD(
            `/Observation?date=gt${endDate}`,
            appointmentId,
            searchParameters
          )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformIPD(
            '/Observation',
            appointmentId,
            searchParameters
          )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const proceduresResponse: R4.IBundle = response

      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const observations: R4.IObservation[] =
          proceduresResponse.entry?.map(
            (item) => item.resource as R4.IObservation
          ) ?? []
        const finalObservations: R4.IObservation[] = []
        for (let i = 0; i < observations.length; i++) {
          finalObservations.push(observations[i])
        }

        if (finalObservations.length > 0) {
          finalObservations.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          finalObservations.sort((a, b) =>
            a.code!.text! > b.code!.text!
              ? 1
              : b.code!.text! > a.code!.text!
              ? -1
              : 0
          )

          const addictionData: VitalsData[] =
            getAddictionsDataFromObs(finalObservations)
          const sortedObservation: R4.IObservation[] =
            getSortedObservationForAddiction(finalObservations)

          //   finalObservations.sort((a, b) =>
          //   (a.code.text ? a.code.text : '') > (b.issued ? b.issued : '')
          //     ? -1
          //     : (a.issued ?? '') < (b.issued ?? '')
          //     ? 1
          //     : 0
          // )

          let groupedOccupationData: GroupedOccupation[] = []
          const finalGroupedOccupationData: GroupedOccupation[] = []
          for (let i = 0; i < finalObservations.length; i++) {
            if (finalObservations[i].issued) {
              if (groupedOccupationData.length > 0) {
                for (let j = 0; j < groupedOccupationData.length; j++) {
                  if (
                    moment(finalObservations[i].issued).format('YYYY-MM-DD') ===
                    moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
                  ) {
                    groupedOccupationData[j].occupation.push(
                      finalObservations[i]
                    )
                  } else {
                    groupedOccupationData.push({
                      date: finalObservations[i].issued ?? '',
                      occupation: [finalObservations[i]],
                    })
                  }
                }
              } else {
                groupedOccupationData.push({
                  date: finalObservations[i].issued ?? '',
                  occupation: [finalObservations[i]],
                })
              }
            }
          }
          groupedOccupationData = groupedOccupationData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  moment(t.date).format('YYYY-MM-DD') ===
                  moment(value.date).format('YYYY-MM-DD')
              )
          )

          for (let i = 0; i < groupedOccupationData.length; i++) {
            finalGroupedOccupationData.push({
              date: groupedOccupationData[i].date,
              occupation: getSortedObservationForAddiction(
                groupedOccupationData[i].occupation
              ),
            })
          }

          state.resultsAvailable = true
          state.searchingConditions = false
          state.addictionsList = finalObservations
          state.addictionFinalData = addictionData
          state.noResultsAvailable = false
          state.groupedObs = finalGroupedOccupationData
          state.errorReason = undefined
          state.errorWhileSearchingProcedures = false
          dispatch(addictionsHistorySliceIPD.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IPDAddictionsHistoryStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            addictionsHistorySliceIPD.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } else {
        const errorSearchDoctor: IPDAddictionsHistoryStatus = {
          searchingConditions: false,
          errorWhileSearchingProcedures: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          addictionsHistorySliceIPD.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      logger.error(error)

      const errorSearchDoctor: IPDAddictionsHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching addictions',
      }
      dispatch(
        addictionsHistorySliceIPD.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default addictionsHistorySliceIPD.reducer
