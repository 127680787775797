import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  LinearProgress,
  List,
  ListSubheader,
  Paper,
  Typography,
  withStyles,
  TextField,
  IconButton,
  createTheme,
  ThemeProvider,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { requestAppointmentsCountForToday } from 'redux/appointments/appointmentCount/appointmentCountSlice'
import { requestIPDAppCountForToday } from 'redux/clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import { requestForDateWiseIpdAppointmentLists } from 'redux/clinic/ipdmanagement/therapiesList/therapiesListSlice'
import { searchMedicalProviders } from 'redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'
import { RootState } from 'redux/rootReducer'
import { useWindowSize } from 'rooks'
import {
  getCurrentUserUnitDetails,
  isTherapist,
} from 'services/userDetailsService'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { desktopScreenWidth } from 'utils/screen_utils'
import { TherapyDisplayTile } from 'views/components/ipd/ipdAppointmentList/therapyDisplayTile'
import { TherapyListHeader } from 'views/components/ipd/ipdAppointmentList/TherapyListHeader'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import { TabbedMultiSelectorNew } from 'views/components/tabbed_multi_selector_new'
import NewToggleSelectors from 'views/components/toggle_selector_new'
import '../../../App.css'
import { lightTheme } from 'configs/styles/therapyPageTheme'
import DateSliderSelector from 'views/components/dateSliderSelector'
import { AccountCardMobile } from 'views/components/LeftMenu/AccountCardMobile'
import { TherapyDisplayTileForMobile } from 'views/components/ipd/ipdAppointmentList/therapyDisplayTileForMobile'
import { getStatusOfTherapyByCode } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { LogOutButtonForMobile } from 'views/components/LeftMenu/LogOutButtonForMobile'
import CustomizedDividers, {
  ToggleOption,
} from '../../components/toggle_selector'

const therapyOptions = [
  {
    id: 'active',
    display: 'Scheduled',
  },
  {
    id: 'completed',
    display: 'Completed',
  },
  {
    id: 'revoked',
    display: 'Cancelled',
  },
]
export interface PageProps {
  type: 'opd' | 'ipd' | 'opd-daycare' | 'appointment'
}

export const ClinicalTherapistHomePageMobile: React.FC<PageProps> = ({
  type,
}: PageProps) => {
  const selectedDateQ = useSelector(
    (state: RootState) => state.therapiesListSlice.selectedDate
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] =
    useState<Date | undefined>(selectedDateQ)

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.therapiesListSlice
  )

  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    appointmentSearchSlice.selectedStatuses
  )

  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<'opd' | 'ipd' | 'opd-daycare' | 'appointment'>(type)
  const [name, setName] = useState<string>('')

  const menu: ToggleOption[] = [-4, -3, -2, -1, 0, 1].map((e) => ({
    value: moment().add(e, 'days').startOf('day').format(),
    displayText:
      moment()
        .add(e, 'days')
        .calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'DD MMM',
          lastDay: '[Yesterday]',
          lastWeek: 'DD MMM',
          sameElse: 'DD MMM',
        })
        .split(' at')[0] ?? '',
  }))

  const appointmentTypes: ToggleOption[] = [
    {
      value: 'ipd',
      displayText: 'IPD',
    },
    {
      value: 'opd',
      displayText: 'OPD',
    },
    {
      value: 'opd-daycare',
      displayText: 'Day Care',
    },
  ]

  const statusMenu: ToggleOption[] = therapyOptions.map((e) => ({
    value: e.id,
    displayText: e.display,
  }))

  useEffect(() => {
    dispatch(
      requestForDateWiseIpdAppointmentLists(
        selectedAppointmentType,
        name,
        selectedDate,
        selectedIpdStatuses,
        undefined,
        undefined,
        undefined
      )
    )
  }, [dispatch, selectedDate, selectedIpdStatuses, selectedAppointmentType])

  useEffect(() => {
    dispatch(searchMedicalProviders('', '', '106296000'))
  }, [])

  return (
    <ThemeProvider theme={lightTheme}>
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100vh',
        }}
        id={type}
        key={type}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          px={2}
          style={{ overflow: 'auto', maxHeight: '100%', gap: '8px' }}
        >
          <Box
            flexDirection='row'
            width='100%'
            display='flex'
            py={2}
            justifyContent='space-between'
            alignContent='center'
            alignItems='center'
          >
            <Box
              flexDirection='row'
              flexGrow={1}
              display='flex'
              justifyContent='left'
              alignContent='center'
              alignItems='center'
            >
              <Typography
                style={{
                  color: '#19274F',
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '24px',
                  letterSpacing: '-0.18px',
                  fontFeatureSettings: `'dlig' on`,
                  fontVariantNumeric: 'lining-nums proportional-nums',
                }}
              >
                Therapies
              </Typography>
            </Box>
            <LogOutButtonForMobile />
          </Box>

          <Box
            flexDirection='row'
            width='100%'
            display='flex'
            py={1}
            justifyContent='center'
            alignContent='center'
            alignItems='center'
          >
            <AccountCardMobile />
          </Box>

          <Box
            flexDirection='column'
            width='100%'
            display='flex'
            style={{ gap: '8px' }}
          >
            {type !== 'appointment' && (
              <Box
                display='flex'
                flexDirection='row'
                alignContent='center'
                alignItems='center'
              >
                <NewToggleSelectors
                  onSelectionChanged={(value) => {
                    setSelectedAppointmentType(
                      value as 'opd' | 'ipd' | 'opd-daycare'
                    )
                  }}
                  size='small'
                  options={appointmentTypes}
                  preSelectedValue={selectedAppointmentType}
                  id='therapy'
                />
              </Box>
            )}
            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              alignItems='center'
            >
              <TabbedMultiSelectorNew
                onSelectionChanged={(value) => {
                  if (value !== null) {
                    setSelectedIPDStatuses(value)
                  }
                }}
                options={statusMenu}
                preSelectedValues={[therapyOptions[0].id]}
              />
            </Box>
            <Box display='flex' flexDirection='row'>
              <TextField
                variant='outlined'
                onChange={(event) => {
                  dispatch(
                    requestForDateWiseIpdAppointmentLists(
                      type,
                      event.target.value,
                      selectedDate,
                      selectedIpdStatuses,
                      undefined,
                      undefined,
                      undefined
                    )
                  )
                  setName(event.target.value)
                }}
                size='small'
                autoFocus={true}
                placeholder='Search'
                value={name}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Box pl='8px' pr='8px' pt='4px'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='17'
                        viewBox='0 0 16 17'
                        fill='none'
                      >
                        <path
                          d='M11.115 11.5213L14 14.4272M12.6667 7.76052C12.6667 10.706 10.2789 13.0939 7.33333 13.0939C4.38781 13.0939 2 10.706 2 7.76052C2 4.815 4.38781 2.42719 7.33333 2.42719C10.2789 2.42719 12.6667 4.815 12.6667 7.76052Z'
                          stroke='#979797'
                          style={{ stroke: '#979797', strokeOpacity: 1 }}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </Box>
                  ),
                  endAdornment: name && (
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setName('')
                        dispatch(
                          requestForDateWiseIpdAppointmentLists(
                            type,
                            name,
                            selectedDate,
                            selectedIpdStatuses,
                            undefined,
                            undefined,
                            undefined
                          )
                        )
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              alignItems='center'
              width='250'
              height={8}
            />
            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              alignItems='center'
              width='250'
            >
              <DateSliderSelector
                onSelectionChanged={(value) => {
                  if (value !== null) {
                    if (type === 'appointment') {
                      dispatch(
                        requestAppointmentsCountForToday(
                          moment(value).startOf('day').toDate()
                        )
                      )
                    } else {
                      dispatch(
                        requestIPDAppCountForToday(
                          moment(value).startOf('day').toDate()
                        )
                      )
                    }

                    setSelectedDate(moment(value).startOf('day').toDate())
                  }
                }}
                options={menu}
                preSelectedValue={moment(selectedDate).startOf('day').format()}
                id='therapy'
              />
            </Box>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            justifyContent='Start'
            alignContent='center'
            overflow='auto'
          >
            {appointmentSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                paddingTop={24}
                alignContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  {isTherapist()
                    ? 'No Therapies to be performed'
                    : 'Add IPD Appointments'}
                </Typography>
              </Box>
            )}

            {appointmentSearchSlice.statusWiseAppointments &&
              appointmentSearchSlice.statusWiseAppointments.length > 0 &&
              selectedIpdStatuses.length === 1 && (
                <Box
                  flexDirection='row'
                  width='100%'
                  display='flex'
                  py={1}
                  justifyContent='left'
                  alignContent='center'
                  alignItems='center'
                >
                  <Typography
                    variant='h6'
                    color='initial'
                    style={{
                      color: '#19274F',
                      fontWeight: 500,
                      fontSize: '14px',
                      fontFeatureSettings: `'dlig' on`,
                      fontVariantNumeric: 'lining-nums proportional-nums',
                    }}
                  >
                    Patient Details
                  </Typography>
                </Box>
              )}

            {appointmentSearchSlice.errorWhileSearchingOrders && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='error' align='center'>
                  Error while searching Therapies
                </Typography>
              </Box>
            )}

            {appointmentSearchSlice.statusWiseAppointments && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
              >
                <Box display='flex' overflow='auto' height='100%' flexGrow={2}>
                  <List
                    id='scrollableDiv'
                    subheader={<li style={{ padding: 0, width: '100%' }} />}
                    style={{
                      padding: 0,
                      width: '100%',
                      overflow: 'auto',
                      height: '100%',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={appointmentSearchSlice.recordsCount ?? 0}
                      scrollThreshold='300px'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                      }}
                      next={() => {
                        dispatch(
                          requestForDateWiseIpdAppointmentLists(
                            type,
                            name,
                            selectedDate,
                            selectedIpdStatuses,
                            appointmentSearchSlice.pageState,
                            appointmentSearchSlice.availableAppointments
                              ?.length,
                            appointmentSearchSlice.dateWiseAppointments,
                            appointmentSearchSlice.availableAppointments,
                            appointmentSearchSlice.statusWiseAppointments
                          )
                        )
                      }}
                      hasMore={
                        (appointmentSearchSlice.availableAppointments?.length ??
                          0) < (appointmentSearchSlice.recordsCount ?? -1)
                      }
                      loader=''
                      endMessage={
                        appointmentSearchSlice.searchingAppointments ? (
                          <p />
                        ) : (
                          <p style={{ textAlign: 'center' }} />
                        )
                      }
                      scrollableTarget='scrollableDiv'
                    >
                      {appointmentSearchSlice.statusWiseAppointments!.map(
                        (e) => (
                          <li
                            key={`section-${e.status ?? ''}`}
                            style={{
                              padding: 0,
                              width: '100%',
                              gap: '8px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <ul
                              style={{
                                padding: 0,
                                gap: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {selectedIpdStatuses.length > 1 && (
                                <ListSubheader
                                  style={{
                                    color: '#19274F',
                                    fontWeight: 500,
                                    position: 'sticky',
                                    width: '100%',
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    backgroundColor: '#ffffff',
                                    lineHeight: '16px',
                                  }}
                                >
                                  <Box>
                                    {getStatusOfTherapyByCode(e.status ?? '')}
                                  </Box>
                                </ListSubheader>
                              )}
                              {e.orders?.map((item, index) => (
                                <TherapyDisplayTileForMobile
                                  ipdAppointmentDetails={item}
                                  key={`lab_item_key${item.therapy.id}` ?? ''}
                                  id={`${index}`}
                                  type={type}
                                />
                              ))}
                            </ul>
                          </li>
                        )
                      )}
                    </InfiniteScroll>
                  </List>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </ThemeProvider>
  )
}
