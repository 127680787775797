import React, { useState } from 'react'
import CPGSelectionContext, {
  CPGSelections,
} from 'views/contexts/cpgContextSelection'

interface CPGSelectedRecommendationProps {}

function CPGSelectedRecommendationProvider({
  children,
}: React.PropsWithChildren<CPGSelectedRecommendationProps>) {
  const [eventData, setEventData] = useState<CPGSelections[]>([])

  const addSelection = (
    cpgIdentifier: string,
    selectedItemType: string,
    newSelectedItemIdentifier: string
  ) => {
    if (eventData.length === 0) {
      setEventData([
        {
          cpgIdentifier,
          selectedData: [
            {
              type: selectedItemType,
              selectedItemsIdentifiers: [newSelectedItemIdentifier],
            },
          ],
        },
      ])
      return
    }

    const existingRecordIndex = eventData.findIndex(
      (cpgSelection) => cpgSelection.cpgIdentifier === cpgIdentifier
    )

    if (existingRecordIndex < 0) {
      const newCpgData = [
        ...eventData,
        {
          cpgIdentifier,
          selectedData: [
            {
              type: selectedItemType,
              selectedItemsIdentifiers: [newSelectedItemIdentifier],
            },
          ],
        },
      ]

      setEventData(newCpgData)
      return
    }

    const existingRecord = eventData[existingRecordIndex]

    if (existingRecord.selectedData.length > 0) {
      const newEventTypeIndex = existingRecord.selectedData.findIndex(
        (eventType) => eventType.type === selectedItemType
      )

      if (newEventTypeIndex < 0) {
        const updatedEventData = [
          ...eventData.splice(0, existingRecordIndex),
          {
            ...existingRecord,
            selectedData: [
              ...existingRecord.selectedData,
              {
                type: selectedItemType,
                selectedItemsIdentifiers: [newSelectedItemIdentifier],
              },
            ],
          },
          ...eventData.splice(existingRecordIndex + 1),
        ]

        setEventData(updatedEventData)

        return
      }
      const newEventType = existingRecord.selectedData[newEventTypeIndex]
      /// check itemIdentifiers already exists
      const newItemIdentifierIndex =
        newEventType.selectedItemsIdentifiers.findIndex(
          (itemIdentifier) => itemIdentifier === newSelectedItemIdentifier
        )

      if (newItemIdentifierIndex > -1) {
        const updatedItemIdentifiers =
          newEventType.selectedItemsIdentifiers.filter(
            (itemIdentifier) => itemIdentifier !== newSelectedItemIdentifier
          )
        const updatedEventData = [
          ...eventData.splice(0, existingRecordIndex),
          {
            ...existingRecord,
            selectedData: [
              ...existingRecord.selectedData.splice(0, newEventTypeIndex),
              {
                ...newEventType,
                selectedItemsIdentifiers: updatedItemIdentifiers,
              },
              ...existingRecord.selectedData.splice(newEventTypeIndex + 1),
            ],
          },
          ...eventData.splice(existingRecordIndex + 1),
        ]

        setEventData(updatedEventData)
      } else {
        const updatedEventData = [
          ...eventData.splice(0, existingRecordIndex),
          {
            ...existingRecord,
            selectedData: [
              ...existingRecord.selectedData.splice(0, newEventTypeIndex),
              {
                ...newEventType,
                selectedItemsIdentifiers: [
                  ...newEventType.selectedItemsIdentifiers,
                  newSelectedItemIdentifier,
                ],
              },
              ...existingRecord.selectedData.splice(newEventTypeIndex + 1),
            ],
          },
          ...eventData.splice(existingRecordIndex + 1),
        ]
        setEventData(updatedEventData)
      }
    }

    /* const updatedEventDate: CPGSelections[] = []

    /// get existing data of cpg identifier
    const existingData = eventData.find(
      (cpgSelection) => cpgSelection.cpgIdentifier === cpgIdentifier
    )!

    console.log('--------------addSelection------------', updatedEventDate)
    setEventData(updatedEventDate) */
  }

  const removeSelection = (
    cpgIdentifier: string,
    selectedItemType: string,
    newSelectedItemIdentifier: string
  ) => {
    setEventData([
      {
        cpgIdentifier,
        selectedData: [
          {
            type: selectedItemType,
            selectedItemsIdentifiers: [],
          },
        ],
      },
    ])
  }

  return (
    <CPGSelectionContext.Provider
      value={{ selectionData: eventData, addSelection, removeSelection }}
    >
      {children}
    </CPGSelectionContext.Provider>
  )
}

export default CPGSelectedRecommendationProvider
