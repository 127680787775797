import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { Diet, DietByWeek } from 'models/diet'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestDietPlanDetails } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getAddedDate, getOnlyDate } from 'utils/dateUtil'
import { getCatalogDetailsFromIds } from 'utils/fhirResoureHelpers/catalogHelper'
import { getFixedDietTimingCheck } from 'utils/fhirResoureHelpers/dietPlanHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getBedTime,
  getDinnerTime,
  getEarlyBreakFastTime,
  getEarlyMorningTime,
  getIdsOfSelectedDetails,
  getLunchTime,
  getMidMorningTime,
  getSnackTime,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import OverflowTypography from '../consultation/plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',

      maxWidth: '12%',
      overflow: 'ellipse',
    },
  },
})

interface AssessmentCaptureProps {
  encounterID?: string
  split?: boolean
  patientID: string
  displayRecordedDate?: boolean
  practitionerRoleId?: string
  onClick?: () => void
}

export const DietPlanCardForEncounterView: React.FC<AssessmentCaptureProps> = ({
  encounterID,
  split,
  patientID,
  displayRecordedDate = true,
  practitionerRoleId,
  onClick,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const classes = useStyles()
  const existingDietPlanSearchSlice = useSelector(
    (state: RootState) => state.existingDietPlanSearchSlice
  )
  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )

  const { width } = useWindowDimensions()

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('1W')
  const [startDate, setStartDate] = useState<Date>(getOnlyDate(new Date()))
  const [tillDate, setTillDate] = useState<Date>(getAddedDate(7))
  const [showDateCell, setShowDateCell] = useState<boolean>(false)
  const [createdDate, setCreatedDate] = useState<Date | undefined>(undefined)
  const [dietChart, setDietChart] = useState<Diet[]>([
    {
      id: getUniqueTempId(),
      date: new Date().toUTCString(),
      eight: [],
      five: [],
      seven: [],
      six: [],
      twelve: [],
      two: [],
    },
  ])

  const [dietByWeekData, setDietByWeekData] = useState<DietByWeek[]>([])

  const [preferredFoods, setPreferredFoods] = useState<string | undefined>()
  const [foodsToAvoid, setFoodsToAvoid] = useState<string | undefined>()

  useEffect(() => {
    if (!existingDietPlanSearchSlice.resultsAvailable) {
      dispatch(
        requestDietPlanDetails({
          dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
          patientId: patientID,
          encounterId: encounterID,
          practitionerRoleId,
          showPlanSuggestedBySameDoctor: true,
          split,
        })
      )
    }
  }, [])

  useEffect(() => {
    if (existingDietPlanSearchSlice.resultsAvailable) {
      if (
        existingDietPlanSearchSlice.dateWiseDietList ||
        existingDietPlanSearchSlice.dateWiseDietListFixed
      ) {
        const allDiet = existingDietPlanSearchSlice.dateWiseDietList ?? []
        const fixedDiet =
          existingDietPlanSearchSlice.dateWiseDietListFixed ?? []

        if (allDiet.length > 0) {
          setShowDateCell(getFixedDietTimingCheck(allDiet))
          setDietChart(allDiet)
          setDietByWeekData(existingDietPlanSearchSlice.dietByWeeksData ?? [])
          //   allDiet.sort((a, b) => moment(b.date).diff(a.date))
        } else {
          setShowDateCell(true)
          setDietChart(existingDietPlanSearchSlice.dateWiseDietListFixed ?? [])
        }
      }
      setFoodsToAvoid(existingDietPlanSearchSlice.itemsToAvoid)
      setPreferredFoods(existingDietPlanSearchSlice.preferredFoods)
      setCreatedDate(moment(existingDietPlanSearchSlice.createdDate).toDate())
      if (existingDietPlanSearchSlice.startDate) {
        const diffStart = moment(existingDietPlanSearchSlice.startDate)
          .endOf('day')
          .diff(moment().endOf('day'), 'day')

        if (diffStart === 0) {
          setSelectedDatePart('today')
        } else if (diffStart === 1) {
          setSelectedDatePart('tomo')
        } else {
          setSelectedDatePart('custom')
        }
        setStartDate(
          moment(existingDietPlanSearchSlice.startDate).startOf('day').toDate()
        )
      }

      if (existingDietPlanSearchSlice.endDate) {
        const diffEnd = moment(existingDietPlanSearchSlice.endDate)
          .endOf('day')
          .diff(
            moment(existingDietPlanSearchSlice.startDate).endOf('day'),
            'day'
          )

        if (diffEnd === 7) {
          setSelectedWeekPart('1W')
        } else if (diffEnd === 14) {
          setSelectedWeekPart('2W')
        } else if (diffEnd === 30) {
          setSelectedWeekPart('1m')
        } else {
          setSelectedWeekPart('custom')
        }
        setTillDate(
          moment(existingDietPlanSearchSlice.endDate).startOf('day').toDate()
        )
      }
    }
  }, [existingDietPlanSearchSlice.resultsAvailable])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {existingDietPlanSearchSlice.searchingTreatment && (
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          alignContent='center'
          alignItems='center'
          justifyContent='center'
          justifyItems='center'
        >
          <CircularProgress size={28} />
        </Box>
      )}
      {existingDietPlanSearchSlice.resultsAvailable &&
        !existingDietPlanSearchSlice.isPreExistingPlanAvailable && (
          <Box width='100%' display='flex' flexDirection='row' px={1}>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {split ? 'No data available' : ''}
            </Typography>
          </Box>
        )}
      {existingDietPlanSearchSlice.resultsAvailable &&
        existingDietPlanSearchSlice.isPreExistingPlanAvailable && (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            // style={{
            //   cursor: onClick !== undefined ? 'pointer' : undefined,
            // }}

            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <Box
              paddingX={1}
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                alignItems='center'
              >
                {displayRecordedDate && (
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    Recorded on{' '}
                    {moment(createdDate).format('Do MMMM YYYY hh:mm A')}
                  </Typography>
                )}
              </Box>

              <Box
                justifyContent='flex-end'
                display='flex'
                flexDirection='row'
                alignItems='center'
              >
                <Box
                  px={1}
                  border={2}
                  borderTop={0}
                  borderBottom={0}
                  borderColor='white'
                >
                  {' '}
                  <Typography variant='subtitle2' color='initial'>
                    Start Date
                  </Typography>
                </Box>
                <Box px={1}>
                  {' '}
                  <Typography variant='body2' color='initial'>
                    {moment(startDate).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
                <Box
                  px={1}
                  border={2}
                  borderTop={0}
                  borderBottom={0}
                  borderColor='white'
                >
                  {' '}
                  <Typography variant='subtitle2' color='initial'>
                    Till Date
                  </Typography>
                </Box>
                <Box px={1}>
                  {' '}
                  <Typography variant='body2' color='initial'>
                    {moment(tillDate).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {showDateCell === false && (
              <Box
                display='flex'
                flexDirection='column'
                width={split ? width - 750 : '100%'}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  style={{
                    backgroundColor: kDialogueBackground,
                  }}
                  height={30}
                  border={1}
                >
                  <Box
                    width='11%'
                    display='flex'
                    flexDirection='row'
                    paddingLeft={0.5}
                    borderRight={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        Date
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    paddingLeft={0.5}
                    borderRight={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getEarlyMorningTime(
                          dietTimeSearchSlice.prSelectedCoding ?? []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    borderRight={1}
                    paddingLeft={0.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getEarlyBreakFastTime(
                          dietTimeSearchSlice.prSelectedCoding ?? []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    borderRight={1}
                    paddingLeft={0.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getMidMorningTime(
                          dietTimeSearchSlice.prSelectedCoding ?? []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    borderRight={1}
                    paddingLeft={0.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getLunchTime(
                          dietTimeSearchSlice.prSelectedCoding ?? []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    borderRight={1}
                    paddingLeft={0.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getSnackTime(
                          dietTimeSearchSlice.prSelectedCoding ?? []
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    borderRight={1}
                    paddingLeft={0.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getDinnerTime(
                          dietTimeSearchSlice.prSelectedCoding ?? []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width='12.71%'
                    display='flex'
                    flexDirection='row'
                    borderRight={1}
                    paddingLeft={0.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                          paddingLeft: 1,
                        }}
                      >
                        {getBedTime(dietTimeSearchSlice.prSelectedCoding ?? [])}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {dietByWeekData.map((data, index: number) => (
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    key={data.week}
                  >
                    {data.dietData.map((row) => (
                      <Box
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        key={row.date}
                        height={40}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? 'white' : kDialogueBackground,
                        }}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                          border={1}
                        >
                          <Box
                            width='11%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            paddingLeft={0.5}
                            py={0.5}
                            px={0.5}
                          >
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              {moment(row.date).format('DD-MM-YYYY')} (
                              {moment(row.date).format('dddd')})
                            </Typography>

                            {/* <Box display='flex' width='2%' paddingTop={0.1}>
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '50px',
              
                                          width: '2px',
                                        }}
                                      />
                                    </Box> */}
                          </Box>
                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            paddingLeft={0.5}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.six ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.six ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>
                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            paddingLeft={0.5}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.eight ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.eight ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>
                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.twelve ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.twelve ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>
                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            paddingLeft={0.5}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.two ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.two ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>
                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            paddingLeft={0.5}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.five ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.five ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>
                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            paddingLeft={0.5}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.seven ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.seven ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>

                          <Box
                            width='12.71%'
                            display='flex'
                            flexDirection='row'
                            borderRight={1}
                            py={0.5}
                            px={0.5}
                          >
                            <OverflowTypography
                              text={getCatalogDetailsFromIds(
                                addDietSlice.catalogueEntries ?? [],
                                getIdsOfSelectedDetails(row.nine ?? []) ?? ''
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                            {/* <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
              
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingLeft: 1,
                                      }}
                                    >
                                      {getCatalogDetailsFromIds(
                                        addDietSlice.catalogueEntries ?? [],
                                        getIdsOfSelectedDetails(row.nine ?? []) ?? ''
                                      )}
                                    </Typography> */}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            )}
            {showDateCell === true && (
              <TableContainer
                component={Paper}
                style={{
                  width: '100%',
                  margin: '0px',
                  padding: '0px',
                }}
              >
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead
                    style={{
                      backgroundColor: kDialogueBackground,
                    }}
                  >
                    <TableRow
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getEarlyMorningTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getEarlyBreakFastTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getMidMorningTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getLunchTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getSnackTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getDinnerTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {' '}
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {getBedTime(
                            dietTimeSearchSlice.prSelectedCoding ?? []
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dietChart.map((row) => (
                      <TableRow key={row.date}>
                        {/* {showDateCell === false && (
                          <TableCell>
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              {moment(row.date).format('DD-MM-YYYY')} (
                              {moment(row.date).format('dddd')})
                            </Typography>
                          </TableCell>
                        )} */}

                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.six ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.eight ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.twelve ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.two ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.five ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.seven ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {getCatalogDetailsFromIds(
                              addDietSlice.catalogueEntries ?? [],
                              getIdsOfSelectedDetails(row.nine ?? []) ?? ''
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell width='12%'>
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Preferred items
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {preferredFoods}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Items to avoid
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {foodsToAvoid}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
    </Box>
  )
}
