import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import {
  FhirAppointmentDetail,
  FhirPractitionerDetail,
} from 'models/fhirAppointmentDetail'
import { MedicationGroupedData } from 'models/medicationGrouppedData'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addMedicationsDetails,
  resetAddMedicationsState,
} from 'redux/consultation/addMedications/addMedicationSlice'
import { RootState } from 'redux/rootReducer'
import { oralUnits, routesOfAdministration } from 'utils/constants'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import { IndianMedicationMasterSelector } from '../subjective/indianMedicationMasterSelector'
import { MedicationRequestTile } from './medicationRequestTile'

// import { MedicationTile } from './medication_tile'

interface Props {
  open: boolean
  encounter: R4.IEncounter
  patient: R4.IPatient
  practitionerDetail: FhirPractitionerDetail
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const AddMedicationsHandler: React.FC<Props> = ({
  open,
  onClose,
  onMedicalConditionAdded,
  encounter,
  patient,
  practitionerDetail,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<WelloMedication[]>(
    []
  )

  const [otherData, setOtherData] = useState<string>()
  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('3d')
  const [medication, setMedication] = useState<R4.IMedication>()
  const [addAnother, setAddAnother] = React.useState<boolean>(true)
  const [routeOfAdmin, setRouteOfAdmin] = React.useState<string>('26643006')
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedDosage, setSelectedDosage] = useState<number>()
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<string[]>([
    'morning',
  ])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)
  const defaultBackgroundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  const handleClose = () => {
    setAnchorEl(null)
  }
  const [dosageType, setDosageType] = React.useState<string>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [selectedMeal, setSelectedMeal] = useState<string>('before')
  const [brandName, setBrandName] = useState<string>('')
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [tillDate, setTillDate] = useState<Date>(getAddedDate(2))
  const dispatch = useDispatch()
  const [addMore, setAddMore] = useState<boolean>(false)
  const [other, setOther] = useState<boolean>(false)
  const [otherRoute, setOtherRoute] = React.useState<boolean>(false)
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const addMedicationsSlice = useSelector(
    (state: RootState) => state.addMedicationsSlice
  )

  useEffect(() => {
    if (addMedicationsSlice.additionSuccessful) {
      resetForm()
      setAddedMedications([])

      onMedicalConditionAdded()
      dispatch(resetAddMedicationsState())
    }
  }, [dispatch, addMedicationsSlice, onMedicalConditionAdded])

  function resetForm() {
    Promise.resolve().then(() => {
      setSelectedDatePart('today')
      setOtherData(undefined)
      setMedication(undefined)
      setOther(false)
      setRouteOfAdmin('26643006')
      setSelectedMeal('before')
      setSelectedTimeOfDay(['morning'])
      setSelectedMedication(undefined)
      setSelectedDosage(undefined)
      setStartDate(new Date())
      setSelectedWeekPart('3d')
      setTillDate(getAddedDate(2))
      setDosageType(undefined)
      setOtherRoute(false)
      setAdditionalNotes(undefined)
    })
    setAddMore(false)
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloMedication[] = [...addedMedications]
    const newMedication: WelloMedication = {
      medication: medication!,
      administrationCode: routeOfAdmin,
      dosageQuantity: selectedDosage!,
      dosageQuantityType: dosageType,
      mealCode: selectedMeal,
      rxNormCode: selectedMedication!,
      medicationCode: selectedMedication!,
      startFrom: startDate,
      till: tillDate,
      timeOfDayCode: selectedTimeOfDay,
      brandName,
      otherDataText: otherData!,
      id: '',
      base: 'no-base',
      medicationDate: new Date(),
      notes: additionalNotes,
    }

    tempMedication.push(newMedication)
    Promise.resolve().then(() => {
      setAddedMedications(tempMedication)

      resetForm()
    })

    if (pushToSave) {
      dispatch(
        addMedicationsDetails(
          patient,
          encounter!,
          practitionerDetail,
          tempMedication
        )
      )
    }
  }

  function isOtherSelected(): boolean {
    if (other === true && !otherData) {
      return true
    }
    return false
  }

  function isValidMedicationDetails() {
    if (medication !== undefined) {
      console.log('---------valid medication----------')
      if (
        selectedDosage !== undefined &&
        dosageType !== undefined &&
        selectedTimeOfDay !== undefined &&
        selectedTimeOfDay.length > 0 &&
        selectedDosage > 0
      ) {
        return true
      }
      if (dosageType !== undefined) {
        if (dosageType === '422135004') {
          return true
        }
      }

      if (routeOfAdmin !== undefined) {
        if (routeOfAdmin === '6064005') {
          return true
        }
      }
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
        setAddedMedications([])
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_medication')}
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          marginX={2}
          marginY={1}
          display='flex'
          flexDirection='column'
          width='100%'
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box display='flex' flexDirection='row' p={1} width='40%'>
              {addMore && (
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title={t('common:medication')} />

                  <IndianMedicationMasterSelector
                    disabled={false}
                    onSelectionChanges={(
                      medicationData: MedicationGroupedData
                    ) => {
                      setMedication(medicationData.medication)
                    }}
                    selectOther={isActive}
                  />
                </Box>
              )}

              {!addMore && (
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title={t('common:medication')} />

                  <IndianMedicationMasterSelector
                    disabled={false}
                    onSelectionChanges={(
                      medicationData: MedicationGroupedData
                    ) => {
                      setMedication(medicationData.medication)
                    }}
                    selectOther={isActive}
                  />
                </Box>
              )}
              {other && (
                <Box display='flex' width='100%' flexDirection='column'>
                  <WelloFormItemLabel title='Any Other' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `med_anyOther`,
                      type: 'text',
                      error: validateOthers(otherData!, false).length > 0,
                      helperText:
                        validateOthers(otherData!, false).length > 0
                          ? `${validateOthers(otherData!, false)}`
                          : '',
                      onChange: (changeData) => {
                        setOtherData(changeData.target.value)
                        // setSelectedMedication({
                        //   code: selectedMedication!.code,
                        //   system: selectedMedication!.system,
                        //   display: changeData.target.value,
                        // })
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box py={1} display='flex' flexDirection='row' width='60%'>
              {addMore && (
                <Box width='50%' paddingLeft={1}>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title={t('labelDosage:resourceName')} />
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `med_dosage`,
                        placeholder: 'Dosage',
                        error:
                          selectedDosage! <= 0 ||
                          selectedDosage! >= 10000 ||
                          Number.isNaN(selectedDosage!),
                        type: 'number',

                        helperText:
                          selectedDosage! <= 0 || Number.isNaN(selectedDosage!)
                            ? `${t('labelCommon:invlaid_dosages')}`
                            : '',
                        inputProps: {
                          min: 0,
                          max: 10000,
                          step: 0.1,
                        },

                        onChange: (changeData) => {
                          if (changeData) {
                            setSelectedDosage(
                              parseFloat(
                                parseFloat(changeData.target.value).toFixed(1)
                              )
                            )
                          }
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}

              {addMore && (
                <Box width='46%' paddingLeft={2} paddingRight={2}>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <ValueSetSelectSingleDisplaySinge
                      key='wellopathy-relationship-vs_medicines'
                      title='Unit'
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                      id='med_units'
                      fuzzySearch={true}
                      disabled={false}
                      onOptionSelected={(
                        conditionsData: R4.ICoding | undefined
                      ) => {
                        if (conditionsData) {
                          if (conditionsData.code === '422135004') {
                            setDosageType(conditionsData.code)
                            setSelectedDosage(undefined)
                          } else {
                            setDosageType(conditionsData.code)
                          }
                        }
                      }}
                      helperText={undefined}
                      placeHolder='Unit'
                    />
                  </Box>
                </Box>
              )}

              {!addMore && (
                <Box width='50%' paddingLeft={1}>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title={t('labelDosage:resourceName')} />
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `med_dosage`,
                        placeholder: 'Dosage',
                        error:
                          selectedDosage! <= 0 ||
                          selectedDosage! >= 10000 ||
                          Number.isNaN(selectedDosage!),
                        type: 'number',

                        disabled: !!(dosageType && dosageType === '422135004'),
                        helperText:
                          selectedDosage! <= 0 || Number.isNaN(selectedDosage!)
                            ? `${t('labelCommon:invlaid_dosages')}`
                            : '',
                        inputProps: {
                          min: 0,
                          max: 10000,
                          step: 0.1,
                        },

                        //   value: tests.testPrice,
                        onChange: (changeData) => {
                          if (changeData) {
                            setSelectedDosage(
                              parseFloat(
                                parseFloat(changeData.target.value).toFixed(1)
                              )
                            )
                          }
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}

              {!addMore && (
                <Box width='46%' paddingLeft={2} paddingRight={2}>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <ValueSetSelectSingleDisplaySinge
                      key='wellopathy-relationship-vs_medicines'
                      title='Unit'
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                      id='med_units'
                      fuzzySearch={true}
                      disabled={false}
                      onOptionSelected={(
                        conditionsData: R4.ICoding | undefined
                      ) => {
                        if (conditionsData) {
                          if (conditionsData.code === '422135004') {
                            setDosageType(conditionsData.code)
                            setSelectedDosage(undefined)
                          } else {
                            setDosageType(conditionsData.code)
                          }
                        }
                      }}
                      helperText={undefined}
                      placeHolder='Unit'
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box display='flex' flexDirection='row' width='100%'>
            {addMore && (
              <Box
                display='flex'
                flexDirection='row'
                width='40%'
                paddingRight={1.8}
                paddingLeft={1}
              >
                <ValueSetSelectSingleDisplaySinge
                  key='wellopathy-relationship-vs_medicines_routes'
                  title='Route of Administration'
                  url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-route-of-administration-vs'
                  id='route_type'
                  fuzzySearch={true}
                  alwaysShowAllOptions={false}
                  disabled={false}
                  onOptionSelected={(
                    conditionsData: R4.ICoding | undefined
                  ) => {
                    if (conditionsData) {
                      setRouteOfAdmin(conditionsData.code ?? '')
                      setOtherRoute(false)
                    }
                  }}
                  helperText={undefined}
                  preSelectedOptions={{
                    system: 'http://snomed.info/sct',
                    code: '26643006',
                    display: 'Oral (by mouth) ',
                  }}
                  placeHolder='Route of Administration'
                />
              </Box>
            )}
            {!addMore && (
              <Box
                display='flex'
                flexDirection='row'
                width='40%'
                paddingRight={1}
                paddingLeft={1}
              >
                <ValueSetSelectSingleDisplaySinge
                  key='wellopathy-relationship-vs_medicines_routes'
                  title='Route of Administration'
                  url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-route-of-administration-vs'
                  id='route_type'
                  fuzzySearch={true}
                  alwaysShowAllOptions={true}
                  disabled={false}
                  onOptionSelected={(
                    conditionsData: R4.ICoding | undefined
                  ) => {
                    if (conditionsData) {
                      setRouteOfAdmin(conditionsData.code ?? '')
                      setOtherRoute(false)
                    }
                  }}
                  helperText={undefined}
                  preSelectedOptions={{
                    system: 'http://snomed.info/sct',
                    code: '26643006',
                    display: 'Oral (by mouth) ',
                  }}
                  placeHolder='Route of Administration'
                />
              </Box>
            )}
            <Box
              width='60%'
              display='flex'
              flexDirection='row'
              paddingTop={0.5}
            >
              {!addMore && (
                <Box width='50%' paddingLeft={1}>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <TabbedMultiSelector
                      size='small'
                      preSelectedValues={selectedTimeOfDay}
                      menuLabel='Time of the Day'
                      options={[
                        {
                          displayText: `${t('labelCommon:morning')}`,
                          value: 'morning',
                        },
                        {
                          displayText: `${t('labelCommon:afternoon')}`,
                          value: 'afternoon',
                        },
                        {
                          displayText: `${t('labelCommon:Evening')}`,
                          value: 'evening',
                        },
                        {
                          displayText: `${t('labelCommon:night')}`,
                          value: 'night',
                        },
                      ]}
                      onSelectionChanged={(selectedValue) => {
                        setSelectedTimeOfDay(selectedValue)
                      }}
                    />{' '}
                  </Box>
                </Box>
              )}

              {addMore && (
                <Box width='50%' paddingLeft={1}>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <TabbedMultiSelector
                      size='small'
                      preSelectedValues={selectedTimeOfDay}
                      menuLabel='Time of the Day'
                      options={[
                        {
                          displayText: `${t('labelCommon:morning')}`,
                          value: 'morning',
                        },
                        {
                          displayText: `${t('labelCommon:afternoon')}`,
                          value: 'afternoon',
                        },
                        {
                          displayText: `${t('labelCommon:Evening')}`,
                          value: 'evening',
                        },
                        {
                          displayText: `${t('labelCommon:night')}`,
                          value: 'night',
                        },
                      ]}
                      onSelectionChanged={(selectedValue) => {
                        setSelectedTimeOfDay(selectedValue)
                      }}
                    />{' '}
                  </Box>
                </Box>
              )}

              {!addMore && (
                <Box width='50%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    paddingLeft={2.5}
                    width='100%'
                  >
                    <Box>
                      <CustomizedDividers
                        key={selectedMeal}
                        size='small'
                        preSelectedValue={selectedMeal}
                        menuLabel={t(
                          'labelDosage:attr_labels.Dosage.patientInstruction'
                        )}
                        options={[
                          {
                            displayText: `${t('labelCommon:before')}`,
                            value: 'before',
                          },
                          {
                            displayText: `${t('labelCommon:after')}`,
                            value: 'after',
                          },
                          {
                            displayText: 'Anytime',
                            value: 'anytime',
                          },
                        ]}
                        onSelectionChanged={(selectedValue) => {
                          setSelectedMeal(selectedValue)
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              {addMore && (
                <Box width='50%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    paddingLeft={2.5}
                    width='100%'
                  >
                    <Box>
                      <CustomizedDividers
                        key={selectedMeal}
                        size='small'
                        preSelectedValue={selectedMeal}
                        menuLabel={t(
                          'labelDosage:attr_labels.Dosage.patientInstruction'
                        )}
                        options={[
                          {
                            displayText: `${t('labelCommon:before')}`,
                            value: 'before',
                          },
                          {
                            displayText: `${t('labelCommon:after')}`,
                            value: 'after',
                          },
                          {
                            displayText: 'Anytime',
                            value: 'anytime',
                          },
                        ]}
                        onSelectionChanged={(selectedValue) => {
                          setSelectedMeal(selectedValue)
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            // justifyContent='space-between'
          >
            {addMore && (
              <Box display='flex' flexDirection='column' px={1} width='40%'>
                <Box width='100%'>
                  <CustomizedDividers
                    key='_date_part'
                    size='small'
                    menuLabel={t('labelCommon:start From')}
                    options={[
                      {
                        displayText: `${t('labelCommon:today')}`,
                        value: 'today',
                      },
                      {
                        displayText: `${t('labelCommon:tomorrow')}`,
                        value: 'tomo',
                      },
                      {
                        displayText: `${t('labelCommon:custom')}`,
                        value: 'custom',
                      },
                    ]}
                    onSelectionChanged={(selectedValue) => {
                      setSelectedDatePart(selectedValue)
                      if (selectedValue === 'today') {
                        setStartDate(new Date())
                        setTillDate(getAddedDate(2))
                      }
                      if (selectedValue === 'tomo') {
                        setStartDate(getTomorrowDate())
                        setTillDate(getAddedDate(3))
                      }
                    }}
                    preSelectedValue={selectedDatePart}
                  />
                </Box>
                <Box width='45%'>
                  {selectedDatePart === 'custom' && (
                    <TextField
                      key='custom'
                      id='med_date'
                      variant='outlined'
                      style={{
                        marginTop: '16px',
                      }}
                      size='small'
                      onChange={(val) => {
                        setStartDate(new Date(val.target.value))

                        if (selectedWeekPart === '3d') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          }
                        }

                        if (selectedWeekPart === '3d') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          }
                        }

                        if (selectedWeekPart === '1W') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(6, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(6, 'days')
                                .toDate()
                            )
                          }
                        }
                        if (selectedWeekPart === '2W') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(13, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(13, 'days')
                                .toDate()
                            )
                          }
                        }
                      }}
                      type='date'
                      inputProps={{
                        min: moment
                          .utc(new Date())
                          .local()
                          .format('YYYY-MM-DD'),
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}

            {!addMore && (
              <Box display='flex' flexDirection='column' px={1} width='40%'>
                <Box width='100%'>
                  <CustomizedDividers
                    key='_date_part'
                    size='small'
                    menuLabel={t('labelCommon:start From')}
                    options={[
                      {
                        displayText: `${t('labelCommon:today')}`,
                        value: 'today',
                      },
                      {
                        displayText: `${t('labelCommon:tomorrow')}`,
                        value: 'tomo',
                      },
                      {
                        displayText: `${t('labelCommon:custom')}`,
                        value: 'custom',
                      },
                    ]}
                    onSelectionChanged={(selectedValue) => {
                      setSelectedDatePart(selectedValue)
                      if (selectedValue === 'today') {
                        setStartDate(new Date())
                        setTillDate(getAddedDate(2))
                      }
                      if (selectedValue === 'tomo') {
                        setStartDate(getTomorrowDate())
                        setTillDate(getAddedDate(3))
                      }
                    }}
                    preSelectedValue={selectedDatePart}
                  />
                </Box>
                <Box width='45%'>
                  {selectedDatePart === 'custom' && (
                    <TextField
                      key='custom'
                      variant='outlined'
                      fullWidth
                      style={{
                        marginTop: '16px',
                      }}
                      id='custom_date'
                      size='small'
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(val) => {
                        setStartDate(new Date(val.target.value))

                        if (selectedWeekPart === '3d') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          }
                        }

                        if (selectedWeekPart === '3d') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(2, 'days')
                                .toDate()
                            )
                          }
                        }

                        if (selectedWeekPart === '1W') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(6, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(6, 'days')
                                .toDate()
                            )
                          }
                        }
                        if (selectedWeekPart === '2W') {
                          if (
                            moment(new Date(val.target.value)).format(
                              'YYYY-MM-DD'
                            ) === moment().format('YYYY-MM-DD')
                          ) {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(13, 'days')
                                .toDate()
                            )
                          } else {
                            setTillDate(
                              moment(
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                )
                              )
                                .add(13, 'days')
                                .toDate()
                            )
                          }
                        }
                      }}
                      type='date'
                      inputProps={{
                        min: moment
                          .utc(new Date())
                          .local()
                          .format('YYYY-MM-DD'),
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}

            {!addMore && (
              <Box display='flex' flexDirection='column' px={1.5}>
                <CustomizedDividers
                  key='week_part'
                  size='small'
                  menuLabel={t('labelCommon:till')}
                  options={[
                    {
                      displayText: `${t('labelCommon:3_Days')}`,
                      value: '3d',
                    },
                    {
                      displayText: `${t('labelCommon:1_Week')}`,
                      value: '1W',
                    },
                    {
                      displayText: `2 Weeks`,
                      value: '2W',
                    },
                    {
                      displayText: `${t('labelCommon:custom')}`,
                      value: 'custom',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedWeekPart(selectedValue)
                    if (selectedValue === '3d') {
                      if (
                        moment(startDate).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      ) {
                        if (selectedDatePart !== 'custom') {
                          setTillDate(getAddedDate(2))
                        }
                      } else if (selectedDatePart !== 'custom') {
                        setTillDate(getAddedDate(3))
                      }
                    }
                    if (selectedValue === '1W') {
                      if (
                        moment(startDate).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      ) {
                        if (selectedDatePart === 'custom') {
                          setTillDate(
                            moment(moment(startDate).format('YYYY-MM-DD'))
                              .add(6, 'days')
                              .toDate()
                          )
                        } else {
                          setTillDate(getAddedDate(6))
                        }
                      } else if (selectedDatePart === 'custom') {
                        setTillDate(
                          moment(moment(startDate).format('YYYY-MM-DD'))
                            .add(6, 'days')
                            .toDate()
                        )
                      } else {
                        setTillDate(getAddedDate(7))
                      }
                    }
                    if (selectedValue === '2W') {
                      if (
                        moment(startDate).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      ) {
                        if (selectedDatePart === 'custom') {
                          setTillDate(
                            moment(moment(startDate).format('YYYY-MM-DD'))
                              .add(13, 'days')
                              .toDate()
                          )
                        } else {
                          setTillDate(getAddedDate(13))
                        }
                      } else if (selectedDatePart === 'custom') {
                        setTillDate(
                          moment(moment(startDate).format('YYYY-MM-DD'))
                            .add(13, 'days')
                            .toDate()
                        )
                      } else {
                        setTillDate(getAddedDate(14))
                      }
                    }
                  }}
                  preSelectedValue={selectedWeekPart}
                />

                {selectedWeekPart === 'custom' && (
                  <TextField
                    key='week_custom'
                    variant='outlined'
                    style={{
                      marginTop: '16px',
                    }}
                    id='custom_week'
                    size='small'
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(val) => {
                      setTillDate(new Date(val.target.value))
                    }}
                    type='date'
                    inputProps={{
                      min: moment.utc(startDate).local().format('YYYY-MM-DD'),
                    }}
                  />
                )}
              </Box>
            )}

            {addMore && (
              <Box display='flex' flexDirection='column' px={1.5}>
                <CustomizedDividers
                  key='week_part'
                  size='small'
                  menuLabel={t('labelCommon:till')}
                  options={[
                    {
                      displayText: `${t('labelCommon:3_Days')}`,
                      value: '3d',
                    },
                    {
                      displayText: `${t('labelCommon:1_Week')}`,
                      value: '1W',
                    },
                    {
                      displayText: `2 Weeks`,
                      value: '2W',
                    },
                    {
                      displayText: `${t('labelCommon:custom')}`,
                      value: 'custom',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedWeekPart(selectedValue)
                    if (selectedValue === '3d') {
                      if (
                        moment(startDate).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      ) {
                        if (selectedDatePart !== 'custom') {
                          setTillDate(getAddedDate(2))
                        }
                      } else if (selectedDatePart !== 'custom') {
                        setTillDate(getAddedDate(3))
                      }
                    }
                    if (selectedValue === '1W') {
                      if (
                        moment(startDate).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      ) {
                        if (selectedDatePart === 'custom') {
                          setTillDate(
                            moment(moment(startDate).format('YYYY-MM-DD'))
                              .add(6, 'days')
                              .toDate()
                          )
                        } else {
                          setTillDate(getAddedDate(6))
                        }
                      } else if (selectedDatePart === 'custom') {
                        setTillDate(
                          moment(moment(startDate).format('YYYY-MM-DD'))
                            .add(6, 'days')
                            .toDate()
                        )
                      } else {
                        setTillDate(getAddedDate(7))
                      }
                    }
                    if (selectedValue === '2W') {
                      if (
                        moment(startDate).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      ) {
                        if (selectedDatePart === 'custom') {
                          setTillDate(
                            moment(moment(startDate).format('YYYY-MM-DD'))
                              .add(13, 'days')
                              .toDate()
                          )
                        } else {
                          setTillDate(getAddedDate(13))
                        }
                      } else if (selectedDatePart === 'custom') {
                        setTillDate(
                          moment(moment(startDate).format('YYYY-MM-DD'))
                            .add(13, 'days')
                            .toDate()
                        )
                      } else {
                        setTillDate(getAddedDate(14))
                      }
                    }
                  }}
                  preSelectedValue={selectedWeekPart}
                />

                {selectedWeekPart === 'custom' && (
                  <TextField
                    key='week_custom'
                    variant='outlined'
                    style={{
                      marginTop: '16px',
                    }}
                    id='custom_week'
                    size='small'
                    onChange={(val) => {
                      setTillDate(new Date(val.target.value))
                    }}
                    type='date'
                    inputProps={{
                      min: moment.utc(startDate).local().format('YYYY-MM-DD'),
                    }}
                  />
                )}
              </Box>
            )}
          </Box>

          {addMore && (
            <Box display='flex' width='96.5%' flexDirection='column' px={1}>
              <WelloFormItemLabel title='Additional Notes' />

              <TextField
                size='small'
                fullWidth
                multiline
                id='notesPart'
                type='string'
                value={additionalNotes}
                variant='outlined'
                onChange={(event) => {
                  setAdditionalNotes(event.target.value)
                }}
              />
            </Box>
          )}

          {!addMore && (
            <Box display='flex' width='96.5%' flexDirection='column' px={1}>
              <WelloFormItemLabel title='Additional Notes' />

              <TextField
                size='small'
                fullWidth
                id='notes_other'
                multiline
                type='string'
                value={additionalNotes}
                variant='outlined'
                onChange={(event) => {
                  setAdditionalNotes(event.target.value)
                }}
              />
            </Box>
          )}

          {addedMedications.length > 0 && (
            <Box display='flex' width='95.5%' flexDirection='column'>
              <Box
                marginX={1}
                marginY={1}
                display='flex'
                flexDirection='column'
                width='100%'
                paddingRight={1}
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{ padding: '4px' }}
                >
                  Currently Added Medications
                </Typography>
                {addedMedications.map((e, index: number) => (
                  <Box py={1} key={e.medicationCode?.code ?? ''}>
                    <MedicationRequestTile
                      medication={e}
                      color={index % 2 === 0 ? 'white' : kDialogueBackground}
                      index={index}
                      history={true}
                      split={true}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          flexGrow={1}
          alignItems='center'
        >
          {addMedicationsSlice.adding && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Box flexGrow={1} alignSelf='center' paddingX={1}>
            <FormControlLabel
              value='end'
              control={
                <Checkbox
                  inputProps={{
                    id: 'add_another',
                  }}
                  color='primary'
                  checked={addAnother}
                  onChange={(eve, checked) => {
                    setAddAnother(checked)
                  }}
                />
              }
              label={t('labelCommon:add_another_medication')}
              labelPlacement='end'
            />
          </Box>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                  setAddedMedications([])
                }}
                disabled={addMedicationsSlice.adding}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            {addAnother && (
              <Box paddingX={1}>
                <Button
                  onClick={() => {
                    setSelectedTimeOfDay(['morning'])
                    setAddMore(true)
                    setOther(false)
                    setOtherData(undefined)
                    setSelectedWeekPart('3d')
                    setSelectedMeal('before')
                    setTillDate(getAddedDate(2))
                    setAdditionalNotes(undefined)
                    addCurrentDetails()
                  }}
                  id='another'
                  variant='contained'
                  color='primary'
                  disableElevation
                  disabled={
                    !isValidMedicationDetails() ||
                    isOtherSelected() ||
                    selectedTimeOfDay.length === 0 ||
                    validateOthers(otherData!, false).length > 0
                  }
                >
                  {t('labelCommon:add_more_new')}
                </Button>
              </Box>
            )}

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (isValidMedicationDetails()) {
                    addCurrentDetails(true)
                  } else if (addedMedications.length > 0) {
                    dispatch(
                      addMedicationsDetails(
                        patient,
                        encounter!,
                        practitionerDetail,
                        addedMedications
                      )
                    )
                  }
                }}
                variant='contained'
                color='primary'
                disableElevation
                id='save'
                disabled={
                  (!isValidMedicationDetails() ||
                    isOtherSelected() ||
                    addMedicationsSlice.adding ||
                    validateOthers(otherData!, false).length > 0) &&
                  addedMedications.length === 0
                }
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
