import { Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { resetDietSearchState } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RecommendationWrapperForSub } from './cdsRecommendations/cds_reccomendation_wrapper_new'
import { FullWidthNextStepsOfConsultation } from './fullWidthnextSteps'
import { HalfWidthNextStepsOfConsultation } from './halfWidthNextSteps'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const NextStepsOfConsultation: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: NextStepsOfConsultationProps) => {
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(resetDietSearchState())
    }, [dispatch])

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' width='100%' px={2}>
            <Box display='flex' flexDirection='column' width='50%'>
              <HalfWidthNextStepsOfConsultation
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              width='50%'
              pl={0.5}
              pt={0.5}
            >
              <RecommendationWrapperForSub
                encounter={fhirAppointmentDetails.encounter!}
                patient={fhirAppointmentDetails.patient}
                onRecommendationAdditionUpdate={(category) => {
                  switch (category) {
                    case 'instruction': {
                      setTimeout(() => {
                        dispatch(
                          getInstructionsOfAppointment(fhirAppointmentDetails)
                        )
                      }, 500)

                      break
                    }
                    case 'careplan-follow-up': {
                      setTimeout(() => {
                        dispatch(
                          getInstructionsOfAppointment(fhirAppointmentDetails)
                        )
                      }, 500)
                      break
                    }
                    case 'lab': {
                      setTimeout(() => {
                        dispatch(
                          getLabRequestsOfAppointment(
                            fhirAppointmentDetails.patient,
                            fhirAppointmentDetails.practitionerDetail,
                            fhirAppointmentDetails.encounter
                          )
                        )
                      }, 500)
                      break
                    }
                    case 'medication': {
                      setTimeout(() => {
                        dispatch(
                          getMedicationsOfAppointment(
                            fhirAppointmentDetails.patient,

                            fhirAppointmentDetails.encounter
                          )
                        )
                      }, 500)
                      break
                    }
                    case 'specialistReferral': {
                      break
                    }
                    default:
                      break
                  }
                }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={2}>
            <FullWidthNextStepsOfConsultation
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Box>
        </Box>
      </ReactVisibilitySensor>
    )
  }
