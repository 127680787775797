import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { GoalFormModel } from 'models/goalFormModel'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import {
  deleteGoal,
  fetchGoalsOfSubscription,
} from 'redux/subscription/goalOfSusbcription/goalsOfSubscriptionsSlice'
import { getActiveCarePlanIdOfSubscriptionInterests } from 'utils/admisnistration/cpg/susbcriptionsHelper'

import { hasError } from 'utils/careplan_utils/cpg_recommendations_utils'
import {
  getGoalDataUpdateDetailsFromGoalModel,
  getGoalTargetDetailsFromGoalModel,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getDefaultDisplayOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import OverflowTypography from '../../ayurveda/overflowTypography'
import { AddNewGoalHandler } from './addNewGoalForCarePlan'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },

  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
}))
interface CdsCardDetailsProps {
  cdsCard: CardsEntity
}

export const GoalDetailsOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const classes = useStyles()

  const patientSubscriptionForUnitSlice = useSelector(
    (state: RootState) => state.patientSubscriptionForUnitSlice
  )
  const goalsOfSubscriptionSlice = useSelector(
    (state: RootState) => state.goalsOfSubscriptionsSlice
  )
  const dispatch = useDispatch()

  const visitContext = useContext(VisitAppointmentContext)
  const { patient } = visitContext

  const [openAddGoal, setopenAddGoal] = useState(false)

  const encounterId = visitContext.encounter?.id
  const carePlanId = getActiveCarePlanIdOfSubscriptionInterests(
    patientSubscriptionForUnitSlice.tasks ?? []
  )

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  useEffect(() => {
    if (carePlanId) {
      if (
        ['interested', 'accepted', 'active'].includes(
          patientSubscriptionForUnitSlice.subscriptionStatus
        )
      )
        dispatch(fetchGoalsOfSubscription(carePlanId))
    }
  }, [carePlanId])

  useEffect(() => {
    if (goalsOfSubscriptionSlice.additionSuccessful && carePlanId) {
      dispatch(fetchGoalsOfSubscription(carePlanId!))
    }
  }, [goalsOfSubscriptionSlice])

  return (
    <Box
      display='flex'
      flexDirection='column'
      py={0.25}
      overflow='auto'
      width='100%'
    >
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('goals') && (
          <Box
            display='flex'
            flexDirection='column'
            key='carePlanGoals_outer_label'
            py={0.25}
            pl={2}
            overflow='auto'
            width='100%'
          >
            <Box
              display='flex'
              flexDirection='column'
              key='carePlanGoals_inner_label'
              py={0.25}
              overflow='auto'
            >
              {(card.interceptedSuggestions.get('goals') as any[]).length >
                0 && (
                <Typography display='inline' style={{ whiteSpace: 'pre-line' }}>
                  {(card.interceptedSuggestions.get('goals') as any[]).map(
                    (app) => (
                      <Typography className={classes.subTitleText} key={app.id}>
                        {app.description.text ?? ''}
                      </Typography>
                    )
                  )}
                </Typography>
              )}
            </Box>

            {['interested', 'accepted', 'active'].includes(
              patientSubscriptionForUnitSlice.subscriptionStatus
            ) &&
              goalsOfSubscriptionSlice.fetching && (
                <Box
                  display='flex'
                  flexDirection='row'
                  py={0.25}
                  overflow='auto'
                >
                  <Typography variant='body2'>Loading...</Typography>
                </Box>
              )}

            {['interested', 'accepted', 'active'].includes(
              patientSubscriptionForUnitSlice.subscriptionStatus
            ) &&
              goalsOfSubscriptionSlice.fetchingError && (
                <Box
                  display='flex'
                  flexDirection='row'
                  py={0.25}
                  overflow='auto'
                >
                  <Typography variant='body2'>
                    Error while fetching care plans. Please refresh the plage
                  </Typography>
                </Box>
              )}

            {['interested', 'accepted', 'active'].includes(
              patientSubscriptionForUnitSlice.subscriptionStatus
            ) &&
              goalsOfSubscriptionSlice.fetchingSuccessful &&
              goalsOfSubscriptionSlice.rawGoalsOfSubscription &&
              goalsOfSubscriptionSlice.rawGoalsOfSubscription.length > 0 && (
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    key='label_carePlanGoals'
                    pt={2}
                    overflow='auto'
                    width='100%'
                  >
                    <Typography variant='body2'>
                      Current Care Plan Goals
                    </Typography>

                    <Box
                      display='flex'
                      flexDirection='row'
                      pt={1}
                      overflow='auto'
                      maxWidth='740px'
                    >
                      <TableContainer
                        style={{
                          width: '100%',
                          margin: '0px',
                          padding: '0px',
                        }}
                      >
                        <Table
                          className={classes.table}
                          aria-label='simple table'
                        >
                          <TableHead
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <TableRow
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              <TableCell>
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Goal Measure
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Target/Goal details
                                </Typography>
                              </TableCell>
                              <TableCell
                                width={8}
                                style={{
                                  minWidth: '50px',
                                }}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Data update details
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: '50px',
                                }}
                              />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {goalsOfSubscriptionSlice.goalsFormModel!.map(
                              (row: GoalFormModel, index) => (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                                  }}
                                >
                                  <TableCell>
                                    <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {_.capitalize(
                                        getDefaultDisplayOfSystemFromCodableConcept(
                                          row.goalObservationCoding
                                        )
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Box width='100%' maxWidth={450}>
                                      <OverflowTypography
                                        text={getGoalTargetDetailsFromGoalModel(
                                          row
                                        )}
                                        typographyProps={{
                                          variant: 'body2',
                                          color: 'initial',
                                        }}
                                      />
                                    </Box>
                                  </TableCell>

                                  <TableCell>
                                    <Box width='100%' overflow='auto'>
                                      <OverflowTypography
                                        text={getGoalDataUpdateDetailsFromGoalModel(
                                          row
                                        )}
                                        typographyProps={{
                                          variant: 'body2',
                                          color: 'initial',
                                        }}
                                      />
                                    </Box>
                                  </TableCell>

                                  <TableCell>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      justifyContent='space-between'
                                      alignContent='center'
                                      alignItems='center'
                                    >
                                      <Box px={1}>
                                        <Tooltip
                                          title='Delete Goal'
                                          id='manage_goal_tooltip'
                                        >
                                          <IconButton
                                            aria-label='btn_goal_delete'
                                            color='primary'
                                            id='btn_goal_delete'
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                              setShowDeleteConfirmation({
                                                open: true,
                                                onContinueActionClicked: () => {
                                                  setShowDeleteConfirmation({
                                                    open: false,
                                                  })
                                                  dispatch(
                                                    deleteGoal(
                                                      row,
                                                      carePlanId,
                                                      goalsOfSubscriptionSlice
                                                    )
                                                  )
                                                },
                                                onCancelActionClicked: () => {
                                                  setShowDeleteConfirmation({
                                                    open: false,
                                                  })
                                                },
                                                displayText: `Are you sure you want to remove Goal of ${getDefaultDisplayOfSystemFromCodableConcept(
                                                  row.goalObservationCoding
                                                )}?`,
                                                continueActionButtonText:
                                                  'Remove Goal',
                                                cancelActionButtonText:
                                                  'Cancel',
                                              })
                                            }}
                                          >
                                            <img
                                              src={`${process.env.PUBLIC_URL}/deleteVector.png`}
                                              alt='Delete'
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Box>
              )}

            {['interested', 'accepted', 'active'].includes(
              patientSubscriptionForUnitSlice.subscriptionStatus
            ) && (
              <Box
                display='flex'
                flexDirection='row-reverse'
                py={0.25}
                overflow='auto'
                width='100%'
                alignContent='end'
                alignItems='end'
              >
                <Button
                  variant='text'
                  color='primary'
                  className={classes.buttonText}
                  id='btn_carePlanGoals_id'
                  onClick={() => {
                    console.log('---------patient-------------', patient)
                    console.log('---------carePlanId-------------', carePlanId)
                    console.log(
                      '---------encounterId-------------',
                      encounterId
                    )

                    setopenAddGoal(true)
                  }}
                >
                  Add New Goal
                </Button>
              </Box>
            )}
          </Box>
        )}
      {openAddGoal && carePlanId && encounterId && patient && (
        <Box>
          <AddNewGoalHandler
            onAllergiesDetailsAdded={() => {
              dispatch(fetchGoalsOfSubscription(carePlanId))
            }}
            onClose={() => {
              setopenAddGoal(false)
            }}
            carePlanId={carePlanId}
            encounterId={encounterId}
            patient={patient}
            open={openAddGoal}
          />
        </Box>
      )}

      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}
    </Box>
  )
}
