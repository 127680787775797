import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  titleCase,
} from 'utils/fhirResourcesHelper'
import {
  getCurrentUserUnitDetails,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getTestNameFromOrderDetails } from 'utils/fhirResoureHelpers/referralHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { LabReferralService, LabTabularService } from 'models/labOfferDetail'
import {
  getLabName,
  getLabOrgName,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { WelloCondition } from 'models/WelloConditions'
import { WelloDiet } from 'models/WelloDiet'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { dietDuration } from 'utils/constants'

interface MedicationTileProps {
  referrals: WelloDiet[]
}

export const DietTile: React.FC<MedicationTileProps> = ({
  referrals,
}: MedicationTileProps) => {
  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  function getTimeData(timeString: string[]): string {
    const mapDurationToCodes: R4.ICoding[] = []
    const displayTime: string[] = []
    timeString.map((e) => {
      if (e === 'MORN.early') mapDurationToCodes.push(dietDuration[0])
      if (e === 'MORN') mapDurationToCodes.push(dietDuration[1])
      if (e === 'MORN.late') mapDurationToCodes.push(dietDuration[2])
      if (e === 'NOON') mapDurationToCodes.push(dietDuration[3])
      if (e === 'EVE') mapDurationToCodes.push(dietDuration[4])
      if (e === 'NIGHT') mapDurationToCodes.push(dietDuration[5])
      if (e === 'PCV') mapDurationToCodes.push(dietDuration[6])
    })
    if (mapDurationToCodes.length > 0) {
      for (let j = 0; j < mapDurationToCodes.length; j++) {
        displayTime.push(mapDurationToCodes[j].display ?? '')
      }
    }
    return displayTime.join(', ')
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        <Grid
          container
          direction='row'
          id='vitals'
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <Grid item xs={4}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Diet Item Name
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          {isCurrentUnitIsWellonessCenter() &&
            sessionStorage.getItem('app') === null && (
              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Diet Type
                      </Typography>
                    </Box>

                    {/* <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          /> */}
                  </Box>
                  <Box display='flex' width='2%' paddingTop={0.2}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          <Grid
            item
            xs={
              isCurrentUnitIsWellonessCenter() &&
              sessionStorage.getItem('app') === null
                ? 6
                : 8
            }
          >
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Time of Day
                  </Typography>
                </Box>

                {/* <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          /> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}
        {referrals.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: index % 2 ? kDialogueBackground : 'white',
              }}
            >
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {e.dietItem && (
                        <OverflowTypography
                          text={
                            getExtensionValueOfUrl(
                              e.dietItem.extension,
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
                            )!.valueCodeableConcept!.text ?? ''
                          }
                          typographyProps={{
                            variant: 'subtitle2',
                            color: 'initial',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box display='flex' width='2%' paddingTop={0.1}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              {isCurrentUnitIsWellonessCenter() &&
                sessionStorage.getItem('app') === null && (
                  <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          {e.dietType && (
                            <OverflowTypography
                              text={e.dietType.display ?? ''}
                              typographyProps={{
                                variant: 'subtitle2',
                                color: 'initial',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' paddingTop={0.1}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              <Grid
                item
                xs={
                  isCurrentUnitIsWellonessCenter() &&
                  sessionStorage.getItem('app') === null
                    ? 6
                    : 8
                }
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {e.selectedTime && (
                        <OverflowTypography
                          text={getTimeData(e.selectedTime)}
                          typographyProps={{
                            variant: 'subtitle2',
                            color: 'initial',
                          }}
                        />
                      )}
                    </Box>

                    {/* <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          /> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
