import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  availablePaymentTypes,
  availablePaymentTypesHinNotOnline,
  availablePaymentTypesRemovedOnline,
  onlineAvailablePaymentTypes,
} from 'utils/constants'
import {
  getExtensionValueOfNumber,
  getNameFromHumanName,
  getProfilePicDoctor,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getDateDiff,
  getSlotAmountForAppointment,
} from 'utils/fhirResoureHelpers/fhirSlotHelper'
import {
  validateLastName,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateName,
} from 'utils/formValidators'
import {
  Controls,
  WelloFormItemLabel,
  WelloTextField,
} from 'wello-web-components'
import { WelloPhoneNumberField } from '../LeftMenu/WelloPhoneNumberField'
import { ChoosePatient } from '../patients/choosePatientDialog'
import { PatientTile } from '../patients/pateintTile'
import { PatientSearchByText } from '../patients/patientSearchByText'
import { PatientSearchByTextForQuickEntry } from '../patients/patientSearchForQuickDataEntry'

interface Props {
  selectedSlot?: FhirSlotDetail
  patient?: R4.IPatient
  selectedServiceType: string
  onPatientSelected?: (selectedSlot?: R4.IPatient) => void
  onQuickEntry?: (
    fName: string,
    middleName: string,
    lName: string,
    phone: string,
    isValid?: boolean,
    referredId?: string,
    isPrimary?: boolean,
    relation?: R4.ICoding
  ) => void

  onPaymentSelected: (paymentType: string) => void
  onClickOnModfifyPatient: (
    modified: boolean,
    selectedSlot?: R4.IPatient
  ) => void

  onClickModifySlot: (
    selectedSlot: FhirSlotDetail,
    patient?: R4.IPatient
  ) => void
  fhirAppointmentDetail?: FhirAppointmentDetail
  followUp?: boolean
  quickDataEntry?: boolean
}

export const PatientSelector: React.FC<Props> = ({
  onPatientSelected,
  onQuickEntry,
  patient,
  selectedServiceType,
  selectedSlot,
  onPaymentSelected,
  onClickOnModfifyPatient,
  onClickModifySlot,
  fhirAppointmentDetail,
  followUp,
  quickDataEntry,
}: Props) => {
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.appointmentManagerSlice
  )
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)
  const [firstName, setFirstName] = useState<string>('')
  const [middleName, setMiddleName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [refferedId, setRefferedId] = useState<string>()
  const [isPrimary, setIsPrimary] = useState<boolean>()
  const [relation, setRelation] = useState<R4.ICoding>()
  const [displayAdvancedFilterOption, setDisplayAdvancedFilterOption] =
    useState<boolean>(true)
  const [selectedPatientData, setSelectedPatientData] = useState<R4.IPatient>()
  const [selectedSlotDetail, setSelectedSlotDetail] = useState<FhirSlotDetail>()
  const language = sessionStorage.getItem('lang')
  const [isSelectedQuick, setIsSelectedQuick] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleChange = (event: any) => {
    setIsSelectedQuick(event.target.checked)
  }

  const [dialog, setDialog] = useState<boolean>(false)

  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )

  function handleFirstNameChange(nameData: any) {
    setFirstName(nameData.target.value)

    if (patientsSearchState.groupDataList) {
      if (
        patientsSearchState.groupDataList.length > 0 &&
        refferedId === undefined &&
        dialog === false
      ) {
        if (onQuickEntry) {
          onQuickEntry(
            nameData.target.value.trim(),
            middleName,
            lastName,
            phone,
            false,
            refferedId,
            isPrimary,
            relation
          )
        }
      } else if (
        patientsSearchState.groupDataList.length > 0 &&
        refferedId !== undefined &&
        dialog === false
      ) {
        if (onQuickEntry) {
          onQuickEntry(
            nameData.target.value.trim(),
            middleName,
            lastName,
            phone,
            true,
            refferedId,
            isPrimary,
            relation
          )
        }
      }
    } else if (onQuickEntry) {
      onQuickEntry(
        nameData.target.value.trim(),
        middleName,
        lastName,
        phone,
        true,
        refferedId,
        isPrimary,
        relation
      )
    }
  }

  function handleMiddleNameChange(nameData: any) {
    setMiddleName(nameData.target.value)

    if (patientsSearchState.groupDataList) {
      if (
        patientsSearchState.groupDataList.length > 0 &&
        refferedId === undefined &&
        dialog === false
      ) {
        if (onQuickEntry) {
          onQuickEntry(
            firstName,
            nameData.target.value.trim(),
            lastName,
            phone,
            false,
            refferedId,
            isPrimary,
            relation
          )
        }
      } else if (
        patientsSearchState.groupDataList.length > 0 &&
        refferedId !== undefined &&
        dialog === false
      ) {
        if (onQuickEntry) {
          onQuickEntry(
            firstName,
            nameData.target.value.trim(),
            lastName,
            phone,
            true,
            refferedId,
            isPrimary,
            relation
          )
        }
      }
    } else if (onQuickEntry) {
      onQuickEntry(
        firstName,
        nameData.target.value.trim(),
        lastName,
        phone,
        true,
        refferedId,
        isPrimary,
        relation
      )
    }
  }
  function handleLastNameChange(nameData: any) {
    setLastName(nameData.target.value)

    if (patientsSearchState.groupDataList) {
      if (
        patientsSearchState.groupDataList.length > 0 &&
        refferedId === undefined &&
        dialog === false
      ) {
        if (onQuickEntry) {
          onQuickEntry(
            firstName,
            middleName,
            nameData.target.value.trim(),
            phone,
            false,
            refferedId,
            isPrimary,
            relation
          )
        }
      }
      if (onQuickEntry) {
        onQuickEntry(
          firstName,
          middleName,
          nameData.target.value.trim(),
          phone,
          true,
          refferedId,
          isPrimary,
          relation
        )
      }
    } else if (onQuickEntry) {
      onQuickEntry(
        firstName,
        middleName,
        nameData.target.value.trim(),
        phone,
        true,
        refferedId,
        isPrimary,
        relation
      )
    }
  }
  function handlePhoneNumberChange(event: any) {
    setPhone(event)
    if (patientsSearchState.groupDataList) {
      if (
        patientsSearchState.groupDataList.length > 0 &&
        refferedId === undefined &&
        dialog === false
      ) {
        if (onQuickEntry) {
          onQuickEntry(
            firstName,
            middleName,
            lastName,
            event,
            true,
            refferedId,
            isPrimary,
            relation
          )
        }
      }
      if (onQuickEntry) {
        onQuickEntry(
          firstName,
          middleName,
          lastName,
          event,
          true,
          refferedId,
          isPrimary,
          relation
        )
      }
    } else if (onQuickEntry) {
      onQuickEntry(
        firstName,
        middleName,
        lastName,
        event,
        true,
        refferedId,
        isPrimary,
        relation
      )
    }
  }

  useEffect(() => {
    if (
      validateMobileNumberForAdd(phone.replaceAll('+91', ''), true).length === 0
    ) {
      dispatch(searchPatients(phone.replaceAll('+91', ''), ''))
    }
  }, [phone])

  useEffect(() => {
    if (isSelectedQuick) {
      if (
        validateMobileNumberForAdd(phone.replaceAll('+91', ''), true).length ===
          0 &&
        dialog === false
      ) {
        if (
          patientsSearchState.resultsAvailable &&
          patientsSearchState.groupDataList
        ) {
          setDialog(true)
        }
      } else {
        dispatch(resetPatientSearchPatient())
      }
    }
  }, [patientsSearchState.patientList, patientsSearchState.resultsAvailable])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box width='100%'>
        <Grid container>
          <Grid item xs={8}>
            <Box marginX={2} padding={1}>
              <Grid container justify='center' direction='column'>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  {selectedSlot && (
                    <Box paddingTop={1.5} paddingBottom={1}>
                      <Typography variant='subtitle2'>Time Slot</Typography>
                    </Box>
                  )}
                  {selectedSlot && !appointmentManagerSlice.isWalkIn && (
                    <Button
                      id='but_lab_ord_cre_modify_pat'
                      variant='text'
                      color='primary'
                      onClick={(e) => {
                        setDisplayAdvancedFilterOption(false)
                        setSelectedSlotDetail(undefined)
                        onClickModifySlot(selectedSlot, selectedPatientData)
                      }}
                    >
                      Modify
                    </Button>
                  )}
                </Box>
                {selectedSlot && (
                  <Box width='100%'>
                    {displayAdvancedFilterOption && (
                      <Paper elevation={0}>
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                alt={getNameFromHumanName(
                                  selectedSlot.practitioner?.name ?? []
                                )}
                                src={getProfilePicDoctor(
                                  selectedSlot.practitioner
                                )}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              color='primary'
                              primary={
                                <>
                                  <Typography
                                    variant='subtitle1'
                                    color='primary'
                                  >
                                    {getNameFromHumanName(
                                      selectedSlot.practitioner?.name ?? []
                                    )}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Box width='80%'>
                                    <Typography
                                      component='span'
                                      variant='subtitle2'
                                      color='textPrimary'
                                      style={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-line',
                                      }}
                                    >
                                      {getSpecializationsList(
                                        selectedSlot.practitionerRole
                                          ?.specialty ?? []
                                      )}
                                    </Typography>
                                  </Box>
                                </>
                              }
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={
                                  <>
                                    {/* {fhirAppointmentDetail &&
                                      followUp &&
                                      selectedSlot.chargeItemDefiniton &&
                                      getExtensionValueOfNumber(
                                        selectedSlot.chargeItemDefiniton
                                          .extension ?? [],
                                        'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                      ) > 0 && (
                                        <Box>
                                          {getDateDiff(
                                            fhirAppointmentDetail.appointment
                                              .start,
                                            selectedSlot.slot.start
                                          ) <=
                                            getExtensionValueOfNumber(
                                              selectedSlot.chargeItemDefiniton
                                                .extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                            ) && (
                                            <Typography
                                              variant='subtitle1'
                                              color='primary'
                                              style={{ fontWeight: 'bolder' }}
                                              align='right'
                                            >
                                              ₹ 0
                                            </Typography>
                                          )}

                                          {getDateDiff(
                                            fhirAppointmentDetail.appointment
                                              .start,
                                            selectedSlot.slot.start
                                          ) >
                                            getExtensionValueOfNumber(
                                              selectedSlot.chargeItemDefiniton
                                                .extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                            ) && (
                                            <Typography
                                              variant='subtitle1'
                                              color='primary'
                                              style={{ fontWeight: 'bolder' }}
                                              align='right'
                                            >
                                              ₹{' '}
                                              {getSlotAmountForAppointment(
                                                selectedSlot.chargeItemDefiniton
                                              )}
                                            </Typography>
                                          )}
                                        </Box>
                                      )} */}

                                    {/* {followUp === undefined && (
                                      <Box>
                                        <Typography
                                          variant='subtitle1'
                                          color='primary'
                                          style={{ fontWeight: 'bolder' }}
                                          align='right'
                                        >
                                          ₹{' '}
                                          {getSlotAmountForAppointment(
                                            selectedSlot.chargeItemDefiniton
                                          )}
                                        </Typography>
                                      </Box>
                                    )} */}
                                  </>
                                }
                                secondary={
                                  <>
                                    {appointmentManagerSlice.isWalkIn && (
                                      <>
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Walk-In
                                        </Typography>
                                      </>
                                    )}
                                    {!appointmentManagerSlice.isWalkIn && (
                                      <>
                                        <Typography
                                          variant='subtitle2'
                                          align='right'
                                        >
                                          {moment(selectedSlot.slot.end).format(
                                            'Do MMM YYYY'
                                          )}
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          align='right'
                                        >
                                          {`${moment(
                                            selectedSlot.slot.start
                                          ).format('hh:mm a')} - ${moment(
                                            selectedSlot.slot.end
                                          ).format('hh:mm a')}`}
                                        </Typography>
                                        {selectedServiceType === '556' && (
                                          <Typography
                                            variant='subtitle2'
                                            align='right'
                                          >
                                            {t('labelCommon:In-Person')}
                                          </Typography>
                                        )}
                                        {/* {selectedServiceType === '538' && (
                                          <Typography
                                            variant='subtitle2'
                                            align='right'
                                          >
                                            {t('labelCommon:Phone')}
                                          </Typography>
                                        )} */}
                                        {selectedServiceType ===
                                          'video-counselling' && (
                                          <Typography
                                            variant='subtitle2'
                                            align='right'
                                          >
                                            {t('labelCommon:Video')}
                                          </Typography>
                                        )}
                                      </>
                                    )}
                                  </>
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Paper>
                    )}
                  </Box>
                )}

                {patient && (
                  <Grid item xs={12}>
                    <Box flexDirection='column'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                      >
                        <WelloFormItemLabel
                          title={t('labelCommon:patient_label')}
                        />

                        {isPatientEditMode &&
                          patient &&
                          followUp === undefined && (
                            <Button
                              id='but_lab_ord_cre_modify_pat'
                              variant='text'
                              color='primary'
                              onClick={(e) => {
                                setIsPatientEditMode(true)
                                setSelectedPatientData(undefined)
                                onClickOnModfifyPatient(
                                  false,
                                  selectedPatientData
                                )
                              }}
                            >
                              Modify
                            </Button>
                          )}
                      </Box>
                      {isPatientEditMode && patient && (
                        <Box paddingBottom={1}>
                          <Paper elevation={0}>
                            <Box paddingLeft={2} width='100%'>
                              <PatientTile patient={patient} />
                            </Box>
                          </Paper>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}
                {isPatientEditMode && !patient && (
                  <Grid item>
                    {quickDataEntry === false && (
                      <Box>
                        <WelloFormItemLabel
                          title={t('labelCommon:select_patient')}
                        />
                        <PatientSearchByText
                          id={300}
                          onNewPatientButtonClicked={() => {}}
                          onPatientSelected={(selectedPatient) => {
                            if (onPatientSelected) {
                              // setIsPatientEditMode(false)
                              setSelectedPatientData(selectedPatient)
                              onPatientSelected(selectedPatient)
                            }
                          }}
                        />
                      </Box>
                    )}
                    {quickDataEntry && (
                      <Box paddingBottom={1}>
                        <Box display='flex' flexDirection='row'>
                          <Box paddingRight={2}>
                            <WelloFormItemLabel title='Invite patient' />
                          </Box>
                          {isSelectedQuick && (
                            <Box paddingTop={0.7}>
                              <Checkbox
                                edge='start'
                                id='check'
                                checked={isSelectedQuick}
                                onChange={handleChange}
                                tabIndex={-1}
                                size='small'
                                disableRipple
                                color='primary'
                                style={{ padding: 0 }}
                                inputProps={{ 'aria-labelledby': 'consent' }}
                              />
                            </Box>
                          )}
                        </Box>

                        {isSelectedQuick === false && (
                          <PatientSearchByTextForQuickEntry
                            id={301}
                            onNewPatientButtonClicked={() => {
                              setIsSelectedQuick(true)
                            }}
                            onPatientSelected={(selectedPatient) => {
                              if (onPatientSelected) {
                                // setIsPatientEditMode(false)
                                setSelectedPatientData(selectedPatient)
                                onPatientSelected(selectedPatient)
                              }
                            }}
                          />
                        )}

                        {isSelectedQuick && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-start'
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='space-between'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexGrow={1}
                                alignItems='flex-start'
                                justifyContent='center'
                              >
                                <WelloTextField
                                  title='First Name'
                                  textProps={{
                                    required: true,
                                    id: 'fName',
                                    name: 'firstName',
                                    // id: `${id}${index}attendant_name`,
                                    placeholder: 'First Name',
                                    error:
                                      validateName(firstName, true).length > 0,
                                    helperText:
                                      validateName(firstName, true).length > 0
                                        ? validateName(firstName, true)
                                        : '',
                                    onChange: (changeData) => {
                                      handleFirstNameChange(changeData)
                                    },
                                  }}
                                />
                              </Box>

                              <Box
                                display='flex'
                                flexGrow={1}
                                alignItems='flex-start'
                                justifyContent='center'
                                paddingLeft={1.6}
                              >
                                <WelloTextField
                                  title='Middle Name'
                                  textProps={{
                                    required: true,
                                    id: 'mName',
                                    name: 'middleName',
                                    error:
                                      validateMiddleName(middleName, false)
                                        .length > 0,
                                    helperText:
                                      validateMiddleName(middleName, false)
                                        .length > 0
                                        ? validateMiddleName(middleName, false)
                                        : '',
                                    // id: `${id}${index}attendant_name`,
                                    placeholder: 'Middle Name(Optional)',

                                    onChange: (changeData) => {
                                      handleMiddleNameChange(changeData)
                                    },
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                justifyContent='flex-start'
                                width='49%'
                              >
                                <WelloTextField
                                  title='Last Name'
                                  textProps={{
                                    required: true,
                                    name: 'lName',
                                    id: 'lName',
                                    // id: `${id}${index}attendant_name`,
                                    placeholder: 'Last Name',
                                    error:
                                      validateLastName(lastName, true).length >
                                      0,
                                    helperText:
                                      validateLastName(lastName, true).length >
                                      0
                                        ? validateLastName(lastName, true)
                                        : '',

                                    onChange: (changeData) => {
                                      handleLastNameChange(changeData)
                                    },
                                  }}
                                />
                              </Box>

                              <Box
                                display='flex'
                                justifyContent='flex-start'
                                paddingLeft={1.6}
                                width='51%'
                              >
                                <WelloPhoneNumberField
                                  textProps={{
                                    countryCodeEditable: false,
                                    defaultCountry: 'in',
                                    id: 'phone',
                                    fullWidth: true,
                                    name: 'phone',
                                    inputMode: 'tel',
                                    onlyCountries: ['in'],
                                    disableDropdown: false,
                                    inputProps: { maxLength: 13, id: 'phone' },
                                    error:
                                      validateMobileNumberForAdd(
                                        phone.replaceAll('+91', ''),
                                        true
                                      ).length > 0,
                                    helperText:
                                      validateMobileNumberForAdd(
                                        phone.replaceAll('+91', ''),
                                        true
                                      ).length > 0
                                        ? validateMobileNumberForAdd(
                                            phone.replaceAll('+91', ''),
                                            true
                                          )
                                        : '',
                                    disabled: false,
                                    onChange: (
                                      e:
                                        | React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                          >
                                        | string
                                    ) => {
                                      if (typeof e === 'string') {
                                        handlePhoneNumberChange(e)
                                      }
                                    },
                                    regions: ['asia'],
                                    autoFormat: false,
                                    size: 'small',
                                    variant: 'outlined',
                                    required: false,
                                  }}
                                  title={t('labelCommon:phone_number_label')}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                )}
                {quickDataEntry === false && (
                  <Grid item>
                    <Box paddingY={1} paddingRight={16}>
                      <Controls.WelloSelect
                        label={t('labelCommon:payment_type')}
                        availableOptions={
                          selectedServiceType === '556' ||
                          selectedServiceType.length === 0
                            ? language === null || language === 'en'
                              ? availablePaymentTypesRemovedOnline
                              : availablePaymentTypesRemovedOnline
                            : language === null
                            ? availablePaymentTypesRemovedOnline
                            : availablePaymentTypesRemovedOnline
                        }
                        required={true}
                        type='Payment Type'
                        displayField='title'
                        valueField='id'
                        preSelectedOption={
                          selectedServiceType === '556' ||
                          selectedServiceType.length === 0
                            ? availablePaymentTypesRemovedOnline[0]
                            : availablePaymentTypesRemovedOnline[0]
                        }
                        onSelectionChanged={(value: any) => {
                          onPaymentSelected(value.id)
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  style={{
                    height: 0,
                    width: 0,
                    padding: -5,
                    margin: -5,
                  }}
                />
              </Grid>
            </Box>
          </Grid>

          <Grid item style={{ flexGrow: 1 }} xs={4}>
            <Box
              display='flex'
              flexWrap='wrap'
              alignContent='flex-start'
              justifyContent='flex-start'
              p={1}
              height='100%'
            >
              <Box
                justifyContent='flex-start'
                width='100%'
                height='100 %'
                flexDirection='column'
                style={{ borderRadius: 16 }}
              >
                {selectedSlot && (
                  <Box paddingTop={1} paddingBottom={1.2}>
                    <Typography variant='subtitle2'>Price Summary</Typography>
                  </Box>
                )}
                <Paper style={{ borderRadius: 8 }} elevation={0}>
                  <Grid container direction='column' justify='center'>
                    {selectedSlot && (
                      <Grid item>
                        <Box
                          display='flex'
                          paddingX={1}
                          bgcolor='background.paper'
                          py={0.4}
                        >
                          <Box p={1} flexGrow={1}>
                            <Typography variant='subtitle1'>
                              {t('labelCommon:visit_charge')}
                            </Typography>
                          </Box>
                          <Box p={1}>
                            {fhirAppointmentDetail &&
                              followUp &&
                              selectedSlot.chargeItemDefiniton &&
                              getExtensionValueOfNumber(
                                selectedSlot.chargeItemDefiniton.extension ??
                                  [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                              ) > 0 && (
                                <Box>
                                  {getDateDiff(
                                    fhirAppointmentDetail.appointment.start,
                                    selectedSlot.slot.start
                                  ) <=
                                    getExtensionValueOfNumber(
                                      selectedSlot.chargeItemDefiniton
                                        .extension ?? [],
                                      'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                    ) && (
                                    <Typography
                                      variant='subtitle1'
                                      style={{ fontWeight: 'bolder' }}
                                    >
                                      ₹ 0
                                    </Typography>
                                  )}

                                  {getDateDiff(
                                    fhirAppointmentDetail.appointment.start,
                                    selectedSlot.slot.start
                                  ) >
                                    getExtensionValueOfNumber(
                                      selectedSlot.chargeItemDefiniton
                                        .extension ?? [],
                                      'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                    ) && (
                                    <Typography
                                      variant='subtitle1'
                                      style={{ fontWeight: 'bolder' }}
                                    >
                                      ₹{' '}
                                      {getSlotAmountForAppointment(
                                        selectedSlot.chargeItemDefiniton
                                      )}
                                    </Typography>
                                  )}
                                </Box>
                              )}

                            {followUp === undefined && (
                              <Box>
                                <Typography
                                  variant='subtitle1'
                                  style={{ fontWeight: 'bolder' }}
                                >
                                  ₹{' '}
                                  {getSlotAmountForAppointment(
                                    selectedSlot.chargeItemDefiniton
                                  )}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {selectedSlot && (
                      <Box flex flexDirection='row'>
                        <Divider />
                      </Box>
                    )}
                    {selectedSlot && (
                      <Grid item>
                        <Box display='flex' paddingX={1}>
                          <Box p={1} flexGrow={1}>
                            <Typography variant='subtitle1' color='primary'>
                              {t('labelCommon:total')}
                            </Typography>
                          </Box>
                          <Box p={1}>
                            {fhirAppointmentDetail &&
                              followUp &&
                              selectedSlot.chargeItemDefiniton &&
                              getExtensionValueOfNumber(
                                selectedSlot.chargeItemDefiniton.extension ??
                                  [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                              ) > 0 && (
                                <Box>
                                  {getDateDiff(
                                    fhirAppointmentDetail.appointment.start,
                                    selectedSlot.slot.start
                                  ) <=
                                    getExtensionValueOfNumber(
                                      selectedSlot.chargeItemDefiniton
                                        .extension ?? [],
                                      'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                    ) && (
                                    <Typography
                                      variant='subtitle1'
                                      style={{ fontWeight: 'bolder' }}
                                    >
                                      ₹ 0
                                    </Typography>
                                  )}

                                  {getDateDiff(
                                    fhirAppointmentDetail.appointment.start,
                                    selectedSlot.slot.start
                                  ) >
                                    getExtensionValueOfNumber(
                                      selectedSlot.chargeItemDefiniton
                                        .extension ?? [],
                                      'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                                    ) && (
                                    <Typography
                                      variant='subtitle1'
                                      style={{ fontWeight: 'bolder' }}
                                    >
                                      ₹{' '}
                                      {getSlotAmountForAppointment(
                                        selectedSlot.chargeItemDefiniton
                                      )}
                                    </Typography>
                                  )}
                                </Box>
                              )}

                            {followUp === undefined && (
                              <Box>
                                <Typography
                                  variant='subtitle1'
                                  style={{ fontWeight: 'bolder' }}
                                >
                                  ₹{' '}
                                  {getSlotAmountForAppointment(
                                    selectedSlot.chargeItemDefiniton
                                  )}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Box>
              {quickDataEntry && (
                <Box width='100%' paddingTop={isSelectedQuick ? 3.9 : 0}>
                  <Controls.WelloSelect
                    label={t('labelCommon:payment_type')}
                    availableOptions={
                      selectedServiceType === '556' ||
                      selectedServiceType.length === 0
                        ? language === null || language === 'en'
                          ? availablePaymentTypes
                          : onlineAvailablePaymentTypes
                        : language === null
                        ? availablePaymentTypes
                        : onlineAvailablePaymentTypes
                    }
                    required={true}
                    type='Payment Type'
                    displayField='title'
                    valueField='id'
                    preSelectedOption={
                      selectedServiceType === '556' ||
                      selectedServiceType.length === 0
                        ? availablePaymentTypes[0]
                        : availablePaymentTypes[0]
                    }
                    onSelectionChanged={(value: any) => {
                      onPaymentSelected(value.id)
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {dialog && (
        <ChoosePatient
          id='1'
          open={dialog}
          patients={patientsSearchState.groupDataList ?? []}
          onClose={() => {
            setDialog(false)
            if (onQuickEntry)
              onQuickEntry(firstName, middleName, lastName, phone, false)
          }}
          onChoose={(
            patientId: string,
            relationShip: R4.ICoding,
            checked: boolean
          ) => {
            setRelation(relationShip)
            setRefferedId(patientId)
            setIsPrimary(checked)
            setDialog(false)
            if (onQuickEntry)
              onQuickEntry(
                firstName,
                middleName,
                lastName,
                phone,
                true,
                patientId,
                checked,
                relationShip
              )
          }}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}
