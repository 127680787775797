import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core'
// import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  kBackgroundDefault,
  kPrimaryMain,
  textMain,
} from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { RootState } from 'redux/rootReducer'
import { getWelloMedicationFromMedicationRequest } from 'utils/appointment_handle/medications_util'
import { getIdOfCardEntry } from 'utils/careplan_utils/careplan_utils'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  CategorizedKriyas,
  getAddressingConditions,
  getAddressingConditionsFromCarePlan,
  getAppointments,
  getCarePlanActivities,
  getCarePlanDescriptions,
  getCarePlans,
  getCategorizedKriyas,
  getErrors,
  getGoalFromEntries,
  getInstructions,
  getJustificationOfPlanDef,
  getKriyas,
  getKriyasOrderDetailsFromEntries,
  getLabTestsOfType,
  getLoincCodeFromPlanDef,
  getMedications,
  getTestsOfTypeInCard,
  getTestsToBePerformed,
  hasError,
  hasLabTestsOfType,
  isEssential,
} from 'utils/patientHelper/cds_helper'
import { AddCareTeamFollowUpsButton } from 'views/components/consultation/plan/cdsRecommendations/add_care_follow_ups_button'
import { AddInstructionsButton } from 'views/components/consultation/plan/cdsRecommendations/add_instructions_button'
import { AddKriyaRecommendationButton } from 'views/components/consultation/plan/cdsRecommendations/add_kriya_button'
import { AddLabTestsButton } from 'views/components/consultation/plan/cdsRecommendations/add_lab_orders_button'
import { AddMedicationRecommendationButton } from 'views/components/consultation/plan/cdsRecommendations/add_medication_button'
import { AddReferralsButton } from 'views/components/consultation/plan/cdsRecommendations/add_referrals_button'
import { CDSPlanActivities } from '../cds_recomendations/carePlanActivities/carePlanActivities'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  encounter: R4.IEncounter
  cdsCard: CardsEntity
  mainIndex: string
}

export const CdsCardDetails: React.FC<CdsCardDetailsProps> = ({
  patient,
  encounter,
  cdsCard: card,
  mainIndex,
}: CdsCardDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const labDiagnosticTestCodes = useSelector(
    (state: RootState) =>
      state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
  )
  const [selectedTestsToOrder, setSelectedTestsToOrder] = useState<string[]>(
    getTestsOfTypeInCard(card, 'essential').map(
      (e) => getLoincCodeFromPlanDef(e)!
    )
  )

  const [selectedMedications, setSelectedMedications] = useState<string[]>([])

  const [selectedKriyas, setSelectedKriyas] = useState<string[]>([])

  function isTestAlreadyAdded(testCode: string): boolean {
    return (labDiagnosticTestCodes ?? []).includes(testCode)
  }

  function onSelectedTestsChanged(changedTest: string) {
    let temp: string[] = [...selectedTestsToOrder]
    const res = temp.findIndex((e) => e === changedTest)
    if (res >= 0) {
      temp = temp.filter((e) => e !== changedTest)
    } else {
      temp.push(changedTest)
    }
    setSelectedTestsToOrder(temp)
  }

  function onSelectedMedicationsChanged(changedTest: string) {
    let temp: string[] = [...selectedMedications]
    const res = temp.findIndex((e) => e === changedTest)
    if (res >= 0) {
      temp = temp.filter((e) => e !== changedTest)
    } else {
      temp.push(changedTest)
    }

    setSelectedMedications(temp)
  }

  function onSelectedKriyasChanged(changedTest: string) {
    let temp: string[] = [...selectedKriyas]
    const res = temp.findIndex((e) => e === changedTest)
    if (res >= 0) {
      temp = temp.filter((e) => e !== changedTest)
    } else {
      temp.push(changedTest)
    }

    setSelectedKriyas(temp)
  }

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {hasError(card) && (
        <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
          <Typography variant='subtitle1' color='initial'>
            Error while fetching recommendations.
          </Typography>
          <List aria-label='error'>
            {getErrors(card)?.map((error) => (
              <ListItem key={card.detail} disableGutters>
                <ListItemText primary={error} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {!hasError(card) &&
        card.suggestions &&
        card.suggestions?.map((each, headerIndex: number) => (
          <Box
            display='flex'
            flexDirection='column'
            key={`${each.label}_label`}
            py={0.25}
            pl={2}
            overflow='auto'
          >
            {/* <Typography variant="subtitle2" color="initial">
                            {getIdOfCardEntry(card) ?? 'Id not available'}
                        </Typography> */}
            {/* <Typography variant='subtitle1' color='initial'>
                          {each.label}
                        </Typography> */}
            {each.actions && each.actions.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                key={`${each.label}_each_label`}
                py={0.25}
                overflow='auto'
              >
                {getGoalFromEntries(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`cp${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography className={classes.titleText}>
                        Goal
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        display='inline'
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {getGoalFromEntries(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).length > 0 &&
                          getGoalFromEntries(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          ).map((app) => (
                            <Typography
                              className={classes.subTitleText}
                              key={app.id}
                            >
                              {app.description.text ?? ''}
                            </Typography>
                          ))}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}

                {getAddressingConditions(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`condition${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography className={classes.titleText}>
                        Conditions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getCarePlans(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getCarePlans(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Box key={app.title ?? app.id}>
                            {getAddressingConditionsFromCarePlan(app).length >
                              0 &&
                              getAddressingConditionsFromCarePlan(app).map(
                                (cond) => (
                                  <Typography
                                    className={classes.subTitleText}
                                    key={getDisplayOfSystemFromCodableConcept(
                                      cond.code
                                    )}
                                  >
                                    {getDisplayOfSystemFromCodableConcept(
                                      cond.code
                                    )}
                                  </Typography>
                                )
                              )}
                          </Box>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getAppointments(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`referral${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Specialist Referral
                        </Typography>
                        <AddReferralsButton
                          patient={patient}
                          encounterID={encounter!.id!}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          appointments={getAppointments(
                            (each.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).map(
                            (e) =>
                              getDefaultCodingOfSystemFromCodableConceptList(
                                e.specialty!
                              )!
                          )}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getAppointments(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getAppointments(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Typography
                            className={classes.subTitleText}
                            key={getSpecializationsList(app.specialty ?? [])}
                          >
                            {getSpecializationsList(app.specialty ?? [])}
                          </Typography>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getTestsToBePerformed(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`labTest${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Lab Tests
                        </Typography>
                        <AddLabTestsButton
                          /*  encounterID={encounter!.id!}
                          patient={patient} */
                          carePlanIdentifier={getIdOfCardEntry(card) ?? ''}
                          onRequestFinished={() => {
                            /*  dispatch(
                              getLabRequestsOfAppointment(fhirPatientDetail)
                            ) */
                          }}
                          tests={getTestsToBePerformed(
                            (each!.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).filter((e) =>
                            selectedTestsToOrder.includes(
                              getLoincCodeFromPlanDef(e) ?? ''
                            )
                          )}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getTestsToBePerformed(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          {hasLabTestsOfType(
                            getTestsToBePerformed(
                              (each.actions[0].resource as R4.IBundle).entry ??
                                []
                            ),
                            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                            'test'
                          ) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              flexGrow='1'
                              minWidth='50%'
                            >
                              <Typography variant='h6' color='initial'>
                                Tests
                              </Typography>
                              {getLabTestsOfType(
                                getTestsToBePerformed(
                                  (each.actions[0].resource as R4.IBundle)
                                    .entry ?? []
                                ),
                                'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                                'test'
                              )!.map((eachTest: R4.IPlanDefinition, index) => (
                                <ListItem
                                  key={`${
                                    eachTest.name ?? `${index}test`
                                  }list_item`}
                                  disabled={isTestAlreadyAdded(
                                    getLoincCodeFromPlanDef(eachTest) ?? ''
                                  )}
                                  onChange={(e) => {
                                    onSelectedTestsChanged(
                                      getLoincCodeFromPlanDef(eachTest) ?? ''
                                    )
                                  }}
                                  dense
                                  style={{
                                    padding: '0px',
                                    paddingLeft: '2px',
                                    margin: '0px',
                                  }}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge='start'
                                      size='small'
                                      id={`checkCp${index}`}
                                      checked={
                                        selectedTestsToOrder.findIndex(
                                          (e) =>
                                            e ===
                                            getLoincCodeFromPlanDef(eachTest)
                                        ) !== -1
                                      }
                                      disabled={isTestAlreadyAdded(
                                        getLoincCodeFromPlanDef(eachTest) ?? ''
                                      )}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': `${eachTest.name}list_item_id`,
                                        id: `${eachTest.name}list_item_id`,
                                      }}
                                      color='primary'
                                      //   style={{
                                      //     color: kPrimaryMain,
                                      //   }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={`${eachTest.name}list_item_id`}
                                    primary={eachTest.title ?? ''}
                                    primaryTypographyProps={{
                                      variant: 'subtitle1',
                                      style: {
                                        color: isEssential(eachTest)
                                          ? selectedTestsToOrder.findIndex(
                                              (e) =>
                                                e ===
                                                getLoincCodeFromPlanDef(
                                                  eachTest
                                                )
                                            ) !== -1
                                            ? kPrimaryMain
                                            : '#4d4d4d'
                                          : '#4d4d4d',
                                      },
                                    }}
                                    secondary={
                                      <Typography
                                        className={classes.subTitleText}
                                        display='inline'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            getJustificationOfPlanDef(
                                              eachTest
                                            ) ?? '',
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                              ))}
                            </Box>
                          )}

                          {hasLabTestsOfType(
                            getTestsToBePerformed(
                              (each.actions[0].resource as R4.IBundle).entry ??
                                []
                            ),
                            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                            'panel'
                          ) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              flexGrow='1'
                              minWidth='50%'
                            >
                              <Typography variant='h6' color='initial'>
                                Panels
                              </Typography>
                              {getLabTestsOfType(
                                getTestsToBePerformed(
                                  (each.actions[0].resource as R4.IBundle)
                                    .entry ?? []
                                ),
                                'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                                'panel'
                              )!.map((eachTest: R4.IPlanDefinition, index) => (
                                <ListItem
                                  key={`${
                                    eachTest.name ?? `${index}test`
                                  }list_item`}
                                  onChange={(e) => {
                                    onSelectedTestsChanged(
                                      getLoincCodeFromPlanDef(eachTest) ?? ''
                                    )
                                  }}
                                  dense
                                  disabled={isTestAlreadyAdded(
                                    getLoincCodeFromPlanDef(eachTest) ?? ''
                                  )}
                                  style={{
                                    padding: '0px',
                                    paddingLeft: '2px',
                                    margin: '0px',
                                  }}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge='start'
                                      size='small'
                                      id={`checkCpPanel${index}`}
                                      disabled={isTestAlreadyAdded(
                                        getLoincCodeFromPlanDef(eachTest) ?? ''
                                      )}
                                      checked={
                                        selectedTestsToOrder.findIndex(
                                          (e) =>
                                            e ===
                                            getLoincCodeFromPlanDef(eachTest)
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': `${eachTest.name}list_item_id`,
                                        id: `${eachTest.name}list_item_id_panel`,
                                      }}
                                      color='primary'
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={`${eachTest.name}list_item_id`}
                                    primary={eachTest.title ?? ''}
                                    primaryTypographyProps={{
                                      variant: 'subtitle1',
                                      style: {
                                        color: isEssential(eachTest)
                                          ? selectedTestsToOrder.findIndex(
                                              (e) =>
                                                e ===
                                                getLoincCodeFromPlanDef(
                                                  eachTest
                                                )
                                            ) !== -1
                                            ? kPrimaryMain
                                            : '#4d4d4d'
                                          : '#4d4d4d',
                                      },
                                    }}
                                    secondary={
                                      <Typography
                                        className={classes.subTitleText}
                                        display='inline'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            getJustificationOfPlanDef(
                                              eachTest
                                            ) ?? '',
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                              ))}
                            </Box>
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getMedications(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`medication${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Medications
                        </Typography>
                        <AddMedicationRecommendationButton
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          medications={getMedications(
                            (each.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).filter((e) =>
                            selectedMedications.includes(
                              getDisplayOfSystemFromCodableConcept(
                                e.medicationCodeableConcept
                              )!
                            )
                          )}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getMedications(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 && (
                        <List
                          style={{
                            columnCount:
                              getMedications(
                                (each.actions[0].resource as R4.IBundle)
                                  .entry ?? []
                              ).length > 4
                                ? 2
                                : 1,
                            width: '100%',
                          }}
                        >
                          {getMedications(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          )
                            .sort((a, b) =>
                              (getDisplayOfSystemFromCodableConcept(
                                a.medicationCodeableConcept
                              ) ?? '') >
                              (getDisplayOfSystemFromCodableConcept(
                                b.medicationCodeableConcept
                              ) ?? '')
                                ? 1
                                : -1
                            )
                            .map(
                              (
                                eachTest: R4.IMedicationRequest,
                                index: number
                              ) => (
                                <ListItem
                                  key={`${getDisplayOfSystemFromCodableConcept(
                                    eachTest.medicationCodeableConcept
                                  )}list_item`}
                                  onChange={(e) => {
                                    onSelectedMedicationsChanged(
                                      getDisplayOfSystemFromCodableConcept(
                                        eachTest.medicationCodeableConcept
                                      ) ?? ''
                                    )
                                  }}
                                  dense
                                  style={{
                                    padding: '0px',
                                    paddingLeft: '2px',
                                    margin: '0px',
                                  }}
                                >
                                  <Box
                                    width='100%'
                                    display='flex'
                                    flexDirection='row'
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge='start'
                                        size='small'
                                        id={`med_plan${index}`}
                                        checked={
                                          selectedMedications.findIndex(
                                            (e) =>
                                              e ===
                                              getDisplayOfSystemFromCodableConcept(
                                                eachTest.medicationCodeableConcept
                                              )
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          'aria-labelledby': `${getDisplayOfSystemFromCodableConcept(
                                            eachTest.medicationCodeableConcept
                                          )}list_item_id`,
                                        }}
                                        color='primary'
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={`${getDefaultCodeOfSystemFromCodableConcept(
                                        eachTest.medicationCodeableConcept
                                      )}list_item_id`}
                                      primary={
                                        getDisplayOfSystemFromCodableConcept(
                                          eachTest.medicationCodeableConcept
                                        ) ??
                                        getDefaultCodeOfSystemFromCodableConcept(
                                          eachTest.medicationCodeableConcept
                                        ) ??
                                        ''
                                      }
                                      primaryTypographyProps={{
                                        variant: 'subtitle1',
                                        style: {
                                          color: isEssential(eachTest)
                                            ? kPrimaryMain
                                            : selectedMedications.findIndex(
                                                (e) =>
                                                  e ===
                                                  getDisplayOfSystemFromCodableConcept(
                                                    eachTest.medicationCodeableConcept
                                                  )
                                              ) !== -1
                                            ? kPrimaryMain
                                            : '#4d4d4d',
                                        },
                                      }}
                                      secondary={
                                        getWelloMedicationFromMedicationRequest(
                                          eachTest
                                        ).textForm ?? ''
                                      }
                                    />
                                  </Box>
                                </ListItem>
                              )
                            )}
                        </List>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getKriyas((each.actions[0].resource as R4.IBundle).entry ?? [])
                  .length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`kriya${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Kriyas
                        </Typography>
                        <AddKriyaRecommendationButton
                          encounterId={encounter!.id!}
                          patient={patient}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          kriyas={getKriyas(
                            (each.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).filter((e) =>
                            selectedKriyas.includes(
                              getDisplayOfSystemFromCodableConcept(e.code) ??
                                'na'
                            )
                          )}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getKriyas(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 && (
                        <List
                          style={{
                            columnCount: 2,
                            width: '100%',
                          }}
                        >
                          {getCategorizedKriyas(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          )
                            .sort((a, b) =>
                              (getDisplayOfSystemFromCodableConcept(
                                a.category
                              ) ?? '') >
                              (getDisplayOfSystemFromCodableConcept(
                                b.category
                              ) ?? '')
                                ? 1
                                : -1
                            )
                            .map((eachCat: CategorizedKriyas) => (
                              <li
                                key={`section-${getDisplayOfSystemFromCodableConcept(
                                  eachCat.category
                                )}`}
                              >
                                <ul>
                                  <ListSubheader
                                    key={`${getDisplayOfSystemFromCodableConcept(
                                      eachCat.category
                                    )}`}
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      color: 'black',
                                      backgroundColor: kBackgroundDefault,
                                    }}
                                  >
                                    {getDisplayOfSystemFromCodableConcept(
                                      eachCat.category
                                    ) ?? ''}
                                  </ListSubheader>

                                  {eachCat.kriyas
                                    .sort((a, b) =>
                                      (getDisplayOfSystemFromCodableConcept(
                                        a.code
                                      ) ?? '') >
                                      (getDisplayOfSystemFromCodableConcept(
                                        b.code
                                      ) ?? '')
                                        ? 1
                                        : -1
                                    )
                                    .map(
                                      (
                                        eachTest: R4.IServiceRequest,
                                        index: number
                                      ) => (
                                        <ListItem
                                          key={`${getDisplayOfSystemFromCodableConcept(
                                            eachTest.code
                                          )}list_item`}
                                          onChange={(e) => {
                                            onSelectedKriyasChanged(
                                              getDisplayOfSystemFromCodableConcept(
                                                eachTest.code
                                              ) ?? ''
                                            )
                                          }}
                                          dense
                                          style={{
                                            padding: '0px',
                                            paddingLeft: '2px',
                                            margin: '0px',
                                          }}
                                        >
                                          <ListItemIcon>
                                            <Checkbox
                                              edge='start'
                                              size='small'
                                              id={`kriyacp${index}`}
                                              checked={
                                                selectedKriyas.findIndex(
                                                  (e) =>
                                                    e ===
                                                    getDisplayOfSystemFromCodableConcept(
                                                      eachTest.code
                                                    )
                                                ) !== -1
                                              }
                                              tabIndex={-1}
                                              disableRipple
                                              inputProps={{
                                                'aria-labelledby': `${getDisplayOfSystemFromCodableConcept(
                                                  eachTest.code
                                                )}list_item_id`,
                                              }}
                                              color='primary'
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            id={`${getDefaultCodeOfSystemFromCodableConcept(
                                              eachTest.code
                                            )}list_item_id`}
                                            primary={
                                              getDisplayOfSystemFromCodableConcept(
                                                eachTest.code
                                              ) ??
                                              getDefaultCodeOfSystemFromCodableConcept(
                                                eachTest.code
                                              ) ??
                                              ''
                                            }
                                            secondary={getKriyasOrderDetailsFromEntries(
                                              eachTest
                                            )}
                                            primaryTypographyProps={{
                                              variant: 'subtitle1',
                                              style: {
                                                color: isEssential(eachTest)
                                                  ? textMain
                                                  : '#4d4d4d',
                                              },
                                            }}
                                          />
                                        </ListItem>
                                      )
                                    )}
                                </ul>
                              </li>
                            ))}
                        </List>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {getCarePlanDescriptions(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 1 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`cpfollowups${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Care Team Follow Ups
                        </Typography>
                        <Box flexDirection='row'>
                          <AddCareTeamFollowUpsButton
                            carePanBundle={
                              each.actions?.[0].resource as R4.IBundle
                            }
                            carePlanIdentifier={getIdOfCardEntry(card)}
                          />
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getCarePlanDescriptions(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getCarePlans(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Typography
                            className={classes.subTitleText}
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                            key={app.id}
                          >
                            {app.description ?? ''}
                          </Typography>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}
                {getInstructions(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`instructionsCp${headerIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Instructions
                        </Typography>
                        <AddInstructionsButton
                          carePanBundle={getInstructions(
                            (each.actions?.[0].resource as R4.IBundle).entry ??
                              []
                          )}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getInstructions(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getInstructions(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Typography
                            className={classes.subTitleText}
                            display='inline'
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                            key={app.id}
                            dangerouslySetInnerHTML={{
                              __html: app.payload?.[0].contentString ?? '',
                            }}
                          />
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getCarePlanActivities(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <CDSPlanActivities
                    action={each.actions[0]}
                    ariaContrls='panel1a-content'
                    id={`summaryCp${headerIndex}`}
                    patientId={patient.id!}
                    encounterId={encounter?.id}
                    carePlanUrl={getIdOfCardEntry(card)}
                  />
                )}

                {card.detail && card.detail.length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                      expandIcon={<ExpandMore />}
                    >
                      <Typography className={classes.titleText}>
                        Summary
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        display='inline'
                        style={{ whiteSpace: 'pre-line' }}
                        className={classes.subTitleText}
                      >
                        {card.detail}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            )}
          </Box>
        ))}
    </Box>
  )
}
