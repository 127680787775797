import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import CancelIcon from '@mui/icons-material/Cancel'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { desktopScreenWidth } from 'utils/screen_utils'
import {
  PatientDataWithAppointment,
  RowsPerPage,
} from 'models/patientDataWithAppointment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPatientDetails,
  resetState,
} from 'redux/patient/addPatient/addPatientSlice'
import { resetUpdatePatientState } from 'redux/patient/editPatient/editPatientSlice'
import { searchPatients } from 'redux/patient/patientSearch/patientSearchSlice'
import {
  resetPatientSearchPatientTable,
  searchPatientsForList,
} from 'redux/patient/patientSearch/patientSearchSliceForDataTable'
import { RootState } from 'redux/rootReducer'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getCodeOfSystemFromCodableConceptList,
  getGenderCoding,
} from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { GenderList } from 'utils/constants'
import { useWindowSize } from 'rooks'
import { resetPatientHandlerSlice } from 'redux/patient/addPatient/patientDetailsHandler'
import { PatientDataTable } from '../common/patientDataTable'
import { EditPatient } from './editPatient'

export interface IUnitAdmin {
  children?: React.ReactNode
}

export const PatientsManagementPage: React.FC = () => {
  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSliceForTable
  )
  const patientAddSlice = useSelector((state: RootState) => state.patientSlice)
  const dispatch = useDispatch()
  //   sessionStorage.removeItem('version')
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const patientDataSelected = useRef<PatientDataWithAppointment>()
  const alreadySelectedPatient = useRef<PatientDataWithAppointment>()
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [split, setSplit] = useState<boolean>(false)

  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])
  const [dataPerPage, setDataPerPage] = useState<RowsPerPage>({
    pageNo: 0,
    numberOfPages: 10,
  })
  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()

  useEffect(() => {
    i18n.changeLanguage(language ?? 'en')
    setName('')
    setPhone('')
    setGender('')
    dispatch(
      searchPatientsForList(
        dataPerPage.pageNo,
        dataPerPage.numberOfPages,
        '',
        '',
        ''
      )
    )
  }, [])

  return (
    <Box style={{ maxWidth: '100%', overflow: 'none' }}>
      {split === false && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          paddingTop={1.5}
          px={2}
        >
          <Box width='15%'>
            <TextField
              variant='outlined'
              onChange={(event) => {
                setName(event.target.value)
                if (event.target.value.length > 0) {
                  setDataPerPage({
                    ...dataPerPage,
                    numberOfPages: 10,
                    pageNo: 0,
                  })
                  dispatch(
                    searchPatientsForList(0, 10, '', event.target.value, gender)
                  )
                } else {
                  dispatch(searchPatientsForList(0, 10, '', '', gender))
                }
              }}
              size='small'
              id='name'
              autoFocus={true}
              placeholder='Search By Patient Name'
              value={name}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <img
                      src={`${process.env.PUBLIC_URL}/search.png`}
                      alt='s'
                      style={{ paddingLeft: name ? 1 : 0 }}
                    />
                  </IconButton>
                ),
                endAdornment: name && (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setName('')
                      dispatch(searchPatientsForList(0, 10, phone, '', gender))
                    }}
                  >
                    <ClearOutlined
                      style={{
                        height: '15px',
                      }}
                    />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <Box width='20%' px={1}>
            <TextField
              variant='outlined'
              fullWidth
              onChange={(event) => {
                if (!Number.isNaN(event.target.value)) {
                  if (event.target.value.length === 10) {
                    dispatch(
                      searchPatientsForList(
                        0,
                        10,
                        event.target.value,
                        name,
                        gender
                      )
                    )
                  }
                  setPhone(event.target.value)
                }
              }}
              size='small'
              autoFocus={false}
              value={phone}
              id='phone'
              placeholder='Enter 10 digit mobile number'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <img
                      src={`${process.env.PUBLIC_URL}/search.png`}
                      alt='s'
                      style={{ paddingLeft: phone ? 1 : 0 }}
                    />
                  </IconButton>
                ),
                endAdornment: phone && (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setPhone('')
                      dispatch(searchPatientsForList(0, 10, '', name, gender))
                    }}
                    size='small'
                  >
                    <ClearOutlined
                      style={{
                        height: '15px',
                      }}
                    />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <Box width='15%' display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='row' width='100%'>
              <Autocomplete
                style={{
                  borderRadius: '4px',
                }}
                fullWidth
                size='small'
                id='gender'
                autoFocus
                getOptionSelected={(option, value) =>
                  option.display === value.display
                }
                value={getGenderCoding(GenderList, gender)}
                getOptionLabel={(option) => option.display ?? ''}
                options={GenderList}
                onChange={(e, changedValue, reason) => {
                  if (changedValue) {
                    dispatch(
                      searchPatientsForList(
                        0,
                        10,
                        phone,
                        name,
                        changedValue.id ?? ''
                      )
                    )
                    setGender(changedValue.id ?? '')
                  } else {
                    dispatch(searchPatientsForList(0, 10, phone, name, ''))
                    setGender('')
                  }
                }}
                autoComplete
                renderOption={(option) => (
                  <Typography variant='subtitle2'>{option.display}</Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Gender'
                    fullWidth
                    id='gender_search'
                    variant='outlined'
                    size='small'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      )}

      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        overflow='auto'
      >
        {patientsSearchState.searching && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
      {patientsSearchState.resultsAvailable && (
        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' overflow='auto' height='100%' flexGrow={2} p={1}>
            <PatientDataTable
              patientData={
                patientsSearchState.patientListWithAppointment
                  ? patientsSearchState.patientListWithAppointment
                  : []
              }
              pageNumber={dataPerPage.pageNo}
              perPage={dataPerPage.numberOfPages}
              onPatientSelected={(patient) => {
                if (patientDataSelected.current !== undefined) {
                  patientDataSelected.current = undefined
                  alreadySelectedPatient.current = patient
                } else {
                  alreadySelectedPatient.current = undefined
                  patientDataSelected.current = patient
                }

                dispatch(resetPatientHandlerSlice())
                sessionStorage.removeItem('version')

                i18n.changeLanguage(language ?? 'en')
              }}
              onRowsPerChange={(pageNo: number, NoOfRecords: number) => {
                setDataPerPage({
                  ...dataPerPage,
                  numberOfPages: NoOfRecords,
                  pageNo,
                })
                dispatch(
                  searchPatientsForList(
                    pageNo,
                    NoOfRecords,
                    phone,
                    name,
                    gender
                  )
                )
              }}
              total={patientsSearchState.totalCount ?? 0}
            />
          </Box>
          {patientDataSelected.current && innerWidth! > desktopScreenWidth && (
            <Box
              display='flex'
              overflow='auto'
              width='90%'
              style={{ backgroundColor: '#ececec' }}
              paddingTop={1.5}
            >
              <EditPatient
                isLab={
                  !!(
                    loggedInUnitType === 'Lab' ||
                    loggedInUnitType === 'Collection Center'
                  )
                }
                patientData={patientDataSelected.current.fhirPatientDetail}
                fathersData={patientDataSelected.current.fatherDetails}
                husbandDetails={patientDataSelected.current.husbandDetails}
                patProfile={patientDataSelected.current.patProfile}
                status={patientDataSelected.current.status}
                unitType={loggedInUnitType}
                isPrimary={patientDataSelected.current.isPrimary}
                onCloseClicked={() => {
                  dispatch(resetPatientHandlerSlice())
                  dispatch(
                    searchPatientsForList(
                      dataPerPage.pageNo,
                      dataPerPage.numberOfPages,
                      '',
                      '',
                      ''
                    )
                  )
                  setName('')
                  setPhone('')
                  setGender('')
                  setSplit(false)
                  patientDataSelected.current = undefined
                  alreadySelectedPatient.current = undefined
                }}
                onEditSucsess={() => {
                  dispatch(resetPatientHandlerSlice())
                  patientDataSelected.current = undefined
                  alreadySelectedPatient.current = undefined
                  dispatch(
                    searchPatientsForList(
                      dataPerPage.pageNo,
                      dataPerPage.numberOfPages,
                      '',
                      '',
                      ''
                    )
                  )
                  setSplit(false)
                  setName('')
                  setPhone('')
                  setGender('')
                  dispatch(resetUpdatePatientState())
                }}
              />
            </Box>
          )}

          {alreadySelectedPatient.current && innerWidth! > desktopScreenWidth && (
            <Box
              display='flex'
              overflow='auto'
              width='90%'
              style={{ backgroundColor: '#ececec' }}
              paddingTop={1.5}
            >
              <EditPatient
                isLab={
                  !!(
                    loggedInUnitType === 'Lab' ||
                    loggedInUnitType === 'Collection Center'
                  )
                }
                patientData={alreadySelectedPatient.current.fhirPatientDetail}
                fathersData={alreadySelectedPatient.current.fatherDetails}
                husbandDetails={alreadySelectedPatient.current.husbandDetails}
                patProfile={alreadySelectedPatient.current.patProfile}
                status={alreadySelectedPatient.current.status}
                unitType={loggedInUnitType}
                isPrimary={alreadySelectedPatient.current.isPrimary}
                onCloseClicked={() => {
                  dispatch(
                    searchPatientsForList(
                      dataPerPage.pageNo,
                      dataPerPage.numberOfPages,
                      '',
                      '',
                      ''
                    )
                  )
                  setName('')
                  setPhone('')
                  setGender('')
                  setSplit(false)
                  patientDataSelected.current = undefined
                  alreadySelectedPatient.current = undefined
                }}
                onEditSucsess={() => {
                  patientDataSelected.current = undefined
                  alreadySelectedPatient.current = undefined
                  dispatch(
                    searchPatientsForList(
                      dataPerPage.pageNo,
                      dataPerPage.numberOfPages,
                      '',
                      '',
                      ''
                    )
                  )
                  setSplit(false)
                  setName('')
                  setPhone('')
                  setGender('')
                  dispatch(resetUpdatePatientState())
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {patientsSearchState.noResultsAvailable && (
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          flexDirection='column'
        >
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            flexDirection='row'
          >
            <Typography variant='subtitle2' color='primary'>
              {' '}
              No Patient Data Available
            </Typography>
          </Box>
        </Box>
      )}

      {patientsSearchState.error && (
        <Typography variant='subtitle2' color='error'>
          {' '}
          {patientsSearchState.errorMessage ??
            'Error while searching patients . Pleas try again'}
        </Typography>
      )}
    </Box>
  )
}
