import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import {
  getPriorityCodeDisplayFromCode,
  getUnitOfDosageFromRequest,
  getUnitOfDosageFromRequestForKriya,
} from 'utils/appointment_handle/medications_util'
import { KriyaDetail } from '../plan/ayurveda/addKriyasForEncounter'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      //   border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface KriyaListProps {
  kriyasList: KriyaDetail[]
}

export const AddedKriyasListForPrescription: React.FC<KriyaListProps> = ({
  kriyasList,
}: KriyaListProps) => {
  const classes = useStyles()

  return (
    <Box width='100%' display='flex' flexDirection='column'>
      {kriyasList.length > 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='column' px={2}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            width='100%'
          >
            <Grid container direction='column' spacing={1}>
              <Grid container direction='row' id='vitals'>
                <Grid item xs={5}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width={isAyurvedaDoctor() ? '50%' : '100%'}
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={20}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Kriya
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    {isAyurvedaDoctor() && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={20}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Start
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={20}
                        alignItems='center'
                        px={0.7}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          End
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={20}
                        alignItems='center'
                        px={0.7}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Time
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* ------------------------------------------------------------------------------- */}
          {kriyasList.map((e, index: number) => (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              flexGrow={1}
              key={e.kriya!.code!}
              paddingTop={1}
            >
              <Grid
                container
                direction='row'
                id='vitals'
                //   style={{
                //     backgroundColor: index % 2 === 0 ? 'white' : 'white',
                //   }}
              >
                <Grid item xs={5}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width={isAyurvedaDoctor() ? '50%' : '100%'}
                      flexGrow={1}
                      px={0.5}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        alignItems='center'
                      >
                        {e.kriya && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {_.capitalize(e.kriya?.display ?? '')}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width={isAyurvedaDoctor() ? '50%' : '100%'}
                      flexGrow={1}
                      px={0.5}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        alignItems='center'
                      >
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {e.startFrom
                            ? moment(e.startFrom).format('DD-MM-YYYY')
                            : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        alignItems='center'
                        px={0.8}
                      >
                        <Box display='flex' flexGrow flexDirection='row'>
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            <Typography color='initial' variant='body2'>
                              {e.till
                                ? moment(e.till).format('DD-MM-YYYY')
                                : ''}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='center'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        alignItems='center'
                        px={0.7}
                      >
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {e.time ? (e.timeData ? e.timeData : e.time) : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {e.medications && (
                <Grid container direction='row' id='vitals'>
                  <Grid item xs={5}>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      minHeight={30}
                      alignItems='center'
                      px={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            alignItems='center'
                            minHeight={20}
                            flexDirection='row'
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Medicine :{' '}
                            </Typography>
                            <Box paddingLeft={0.3}>
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {(e.medications ?? [])
                                  .map((med) => med.display)
                                  .join(', ')}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={7}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        justifyContent='center'
                        flexGrow={1}
                        paddingLeft={0.2}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          minHeight={30}
                          alignItems='center'
                          px={0.7}
                        >
                          {e.dosageQuantity && (
                            <OverflowTypography
                              text={_.capitalize(
                                `${e.dosageQuantity?.toFixed(
                                  0
                                )}  ${getUnitOfDosageFromRequestForKriya(
                                  e.dosageQuantityType ?? ''
                                )}`
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                          )}
                          {e.dosageQuantity === undefined && (
                            <OverflowTypography
                              text={_.capitalize(
                                `${getUnitOfDosageFromRequest(
                                  e.dosageQuantityType ?? ''
                                )}`
                              )}
                              typographyProps={{
                                variant: 'subtitle2',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={2} />

                  <Grid item xs={1} />
                </Grid>
              )}

              {e.remarks && e.remarks.length > 0 && (
                <Grid container direction='row' id='vitals'>
                  <Grid item xs={12}>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      minHeight={30}
                      alignItems='center'
                      px={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            alignItems='center'
                            minHeight={20}
                            flexDirection='row'
                            paddingBottom={1}
                          >
                            <Box width='7%' paddingRight={0.5}>
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Notes :{' '}
                              </Typography>
                            </Box>
                            <Box paddingLeft={0.3} width='93%'>
                              <Typography
                                // display='inline'
                                // dangerouslySetInnerHTML={{
                                //   __html: getCommunicationRequestPayloadString(e) ?? '',
                                // }}
                                onClick={() => {
                                  //   setFullText(e.remarks ?? '')
                                  //   setShowText(true)
                                }}
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 11,
                                  color: 'black',
                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  wordWrap: 'break-word',
                                  whiteSpace: 'pre-line',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {e.remarks ?? ''}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%' paddingLeft={0.2}>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
