import { Box } from '@material-ui/core'
import React from 'react'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { WelloLandingPage } from 'views/pages/welloLandingPage'

export function Dashboard() {
  return (
    <Box overflow='auto' id='home_wello' width='100%'>
      <WelloLandingPage
        key={getCurrentUserPractitionerRoleDetails().id ?? ''}
        onTap={() => {}}
      />
    </Box>
  )
}
