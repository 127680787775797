import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { ObservationOCAForViewOnly } from 'views/components/consultation/assessment/ayurvedaAssessment/doshaVikruthiAssemment/doshaAssessmentFromHistory'

// import { AddMensuration } from './AddMensuration'
// import { AddAddiction } from '../addition/addAddiction'
// import { AddHabits } from '../addition/addHabits'
// import { AddOccupation } from '../occupation/Addoccupation'

interface HabitsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  splitView: boolean
}

export const ObstricsOPD: React.FC<HabitsProp> = ({
  fhirAppointmentDetails,
  status,
  splitView,
}: HabitsProp) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const dispatch = useDispatch()
  const mensurationSearhcSliceObs = useSelector(
    (state: RootState) => state.obsSearchSlice
  )
  /* useEffect(() => {
    dispatch(requestObsOfPatient('', fhirAppointmentDetails.patient))
  }, [dispatch, fhirAppointmentDetails]) */
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Obstetric History</Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>

      {/*  {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={1}
        >
          <Tooltip title=''>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              onClick={() => {
                setOpenAddDialogue(true)
              }}
              style={{ padding: 0 }}
            >
              <AddCircleIcon
                style={{ height: '16px', padding: 0 }}
                color='primary'
              />{' '}
              <Typography variant='subtitle2' color='primary' component={Link}>
                {' '}
                Obstetrics History
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
      )} */}

      <ObservationOCAForViewOnly
        fhirAppointmentDetails={fhirAppointmentDetails}
        category='history'
        formName='History Obstetric History_OCA'
        isForHistory={true}
        observationCode='C0425963'
        isReadOnly={
          splitView || status === 'completed' || !isMedicalServiceProvider()
        }
      />
      {/*  {mensurationSearhcSliceObs.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )} */}

      {/*  {mensurationSearhcSliceObs.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {isMedicalServiceProvider() || status === 'completed'
              ? splitView
                ? 'No data available'
                : ''
              : ''}{' '}
          </Typography>
        </Box>
      )} */}
      {/* {mensurationSearhcSliceObs.resultsAvailable &&
        mensurationSearhcSliceObs.occupationalList && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            {mensurationSearhcSliceObs.occupationalList.length === 0 && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  {isMedicalServiceProvider() ? '' : ''}{' '}
                </Typography>
              </Box>
            )}
          </Box>
        )} */}

      {/*  {mensurationSearhcSliceObs.errorWhileSearchingProcedures && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            color='error'
            style={{
              fontWeight: 400,
            }}
          >
            Error while fetching Obstetrics
          </Typography>
        </Box>
      )} */}
      {/* {mensurationSearhcSliceObs.resultsAvailable &&
        mensurationSearhcSliceObs.groupedObs && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {mensurationSearhcSliceObs.groupedObs.map((val) => (
                <Box py={1} key={val.date ?? ''}>
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                      </Typography>
                    </Box>
                  </Box>
                  <HistoryTabularFormatTile
                    obsData={val.occupation}
                    type='Mode Of Delivery'
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )} */}

      {/* <AddObsOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onAddictionAdded={() => {
          setOpenAddDialogue(false)
          dispatch(requestObsOfPatient('', fhirAppointmentDetails.patient))
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      /> */}
    </Box>
  )
}
