import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import moment from 'moment'
import {
  getAddressKindByAddress,
  getAddressOfCity,
  getAddressOfPatient,
  getAddressOfPatientForTable,
  getAddressOfPatientForTempAddress,
  getAddressOfState,
  getAgeOfPatient,
  getAgeOfPatientData,
  getAgeOfPatientForSorting,
  getCountry,
  getDataFromCoding,
  getEmailOfPatient,
  getExtensionCodingOfUrlPatient,
  getExtensionValueOfUrlPatient,
  getFirstNameOfRelatedPerson,
  getGenderOfPatient,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemTypeCoding,
  getIdOfPatient,
  getLastNameOfPatient,
  getLastNameOfRelatedPerson,
  getMaritalStatus,
  getMaritalStatusCode,
  getMiddleNameOfPatient,
  getMiddleNameOfRelatedPerson,
  getNameOfPatient,
  getOccupationValueOfUrlPatient,
  getPassportEndDate,
  getPassportStartDate,
  getProfilePicPatient,
  getStringValueExtensionsOfUrl,
  getTelecomOfPatient,
  getTelecomOfPatientEdit,
  getTempAddressOfCity,
  getTempAddressOfState,
  getVisaCity,
  getVisaCountry,
  getVisaType,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { PatientDataWithAppointment } from '../../models/patientDataWithAppointment'
import { PatientProfile, VisaDetails } from '../../models/patientProfile'

const occupations = [
  { id: 'govtOfficer', title: 'Govt. Officer' },
  { id: 'Politician', title: 'Politician' },
  { id: 'Self Employed', title: 'Self Employed' },
  { id: 'businessman', title: 'Businessman' },
  { id: 'Doctor', title: 'Doctor' },
  { id: 'Hospitality service', title: 'Hospitality service' },
  { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
  { id: 'Chartered Accountant', title: 'Chartered Accountant' },
  { id: 'Finance Professional', title: 'Finance Professional' },
  { id: 'Housewife', title: 'Housewife' },
  { id: 'Student', title: 'Student' },
].sort((a, b) => (a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0))

export function getTableDataFromFhir(
  pat: FhirPatientDetail[]
): PatientDataWithAppointment[] {
  const convertedPatientData: PatientDataWithAppointment[] = []

  for (let i = 0; i < pat.length; i++) {
    //

    const midName = getMiddleNameOfPatient(pat[i].patient)
    const mName = midName || ''
    const midFName = getMiddleNameOfRelatedPerson(pat[i].relatedPersonHusband)
    const mFName = midFName || ''
    const midFHName = getMiddleNameOfRelatedPerson(pat[i].relatedPersonHusband)
    const mHName = midFName || ''

    const patProfile: PatientProfile = {
      id: getIdOfPatient(pat[i].patient),
      name: `${getNameOfPatient(pat[i].patient)}`,
      middleName: `${getMiddleNameOfPatient(pat[i].patient)}`,
      dob: moment(pat[i].patient.birthDate).format('YYYY-MM-DD'),
      lastName: `${getLastNameOfPatient(pat[i].patient)}`,
      gender: `${getGenderOfPatient(pat[i].patient)}`,
      type: '',
      phone: `${getTelecomOfPatientEdit(pat[i].patient)}`,
      email: `${getEmailOfPatient(pat[i].patient)}`,
      imgName: '',
      patient: pat[i].patient,
      photoUri: getProfilePicPatient(pat[i].patient),
      address: `${
        getAddressOfPatientForTable(pat[i].patient.address ?? []) ?? ''
      }`,
      maritalStaTus: `${getMaritalStatusCode(pat[i].patient)}`,
      labReference: `${getIdentifierValueBySystem(
        pat[i].patient.identifier ?? [],
        'labReference'
      )}`,
      nationality: titleCase(
        getExtensionValueOfUrlPatient(
          pat[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        )
      ),
      fathersName: pat[i].relatedPersonFather
        ? `${getFirstNameOfRelatedPerson(
            pat[i].relatedPersonFather
          )} ${mFName} ${getLastNameOfRelatedPerson(
            pat[i].relatedPersonFather
          )}`
        : '',
      husbandName: pat[i].relatedPersonHusband
        ? `${getFirstNameOfRelatedPerson(
            pat[i].relatedPersonHusband
          )} ${mHName} ${getLastNameOfRelatedPerson(
            pat[i].relatedPersonHusband
          )}`
        : '',
      occupation: getOccupationValueOfUrlPatient(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation',
        'http://wellopathy.com/fhir/india/core/CodeSystem/occupation'
      ),
      contentType: '',
      addressType: '',
      occuptionOtherData: '',
      city: `${getAddressOfCity(pat[i].patient.address ?? []) ?? ''}`,
      state: `${getAddressOfState(pat[i].patient.address ?? []) ?? ''}`,
      status: pat[i].status,
      passportStartDate: getPassportStartDate(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
      ),
      passportEndDate: getPassportEndDate(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
      ),
      passportNumber: '',
      tempAddress:
        getAddressOfPatientForTempAddress(pat[i].patient.address ?? []) ?? '',
      tempCity: `${getTempAddressOfCity(pat[i].patient.address ?? []) ?? ''}`,
      tempCountry: `${
        getTempAddressOfState(pat[i].patient.address ?? []) ?? ''
      }`,
      country: `${getCountry(pat[i].patient.address ?? []) ?? ''}`,
      nationalityCoding: getExtensionCodingOfUrlPatient(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/country'
      ),
      patientCreatedDate: pat[i].patientCreatedDate,
      visaNo:
        getStringValueExtensionsOfUrl(
          pat[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
        ) ?? '',
      visaType: getDataFromCoding(
        pat[i].patient.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
      ),
      visaTypeCoding: getVisaType(
        pat[i].patient.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
      ),
      visaIssueDate: getPassportStartDate(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
      ),
      visaExpiryDate: getPassportEndDate(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
      ),
      visaIssueCity: getVisaCity(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
      ),
      visaIssueCountry:
        getExtensionCodingOfUrlPatient(
          pat[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
        ).display ?? '',
      visaIssueCountryCode: getExtensionCodingOfUrlPatient(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
      ),
      addressProofTypeCoding: getIdentifierValueBySystemTypeCoding(
        pat[i].patient.identifier ?? [],
        'address-proof'
      )!,
      addressProofNumber: getIdentifierValueBySystem(
        pat[i].patient.identifier ?? [],
        'address-proof'
      )!,
    }

    const val: PatientDataWithAppointment = {
      id: getIdOfPatient(pat[i].patient),
      patientName: `${getNameOfPatient(
        pat[i].patient
      )} ${mName} ${getLastNameOfPatient(pat[i].patient)}`,
      age: getAgeOfPatient(pat[i].patient),
      ageData: getAgeOfPatientForSorting(pat[i].patient),
      ageString: getAgeOfPatientData(pat[i].patient),
      gender: getGenderOfPatient(pat[i].patient),
      emailAddress: getEmailOfPatient(pat[i].patient),
      fhirPatientDetail: pat[i].patient,
      patProfile,
      nationality: titleCase(
        getExtensionValueOfUrlPatient(
          pat[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        )
      ),
      phoneNumber: getTelecomOfPatient(pat[i].patient),
      profilePic: getProfilePicPatient(pat[i].patient),
      address: getAddressOfPatient(pat[i].patient.address ?? []) ?? '',
      maritalStaTus: getMaritalStatus(pat[i].patient),
      labReference: getIdentifierValueBySystem(
        pat[i].patient.identifier ?? [],
        'labReference'
      ),
      lastUpdatedDate: pat[i].patient.meta?.lastUpdated ?? '',
      appointmentDate: moment(pat[i].appointment).format('DD-MM-YYYY'),
      condition: pat[i].condition,
      fathersName: pat[i].relatedPersonFather
        ? `${getFirstNameOfRelatedPerson(
            pat[i].relatedPersonFather
          )} ${mFName} ${getLastNameOfRelatedPerson(
            pat[i].relatedPersonFather
          )}`
        : '',
      husbandName: pat[i].relatedPersonHusband
        ? `${getFirstNameOfRelatedPerson(
            pat[i].relatedPersonHusband
          )} ${mHName} ${getLastNameOfRelatedPerson(
            pat[i].relatedPersonHusband
          )}`
        : '',
      fatherDetails: pat[i].relatedPersonFather,
      occupation: getOccupationValueOfUrlPatient(
        pat[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation',
        'http://wellopathy.com/fhir/india/core/CodeSystem/occupation'
      ),
      passportStartDate: '',
      passportEndDate: '',
      passportNumber: '',
      husbandDetails: pat[i].relatedPersonHusband,
      status: pat[i].status,
      isPrimary: pat[i].isPrimary,
      visaNo:
        getStringValueExtensionsOfUrl(
          pat[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
        ) ?? '',
      createdDate: pat[i].patientCreatedDate,
    }

    convertedPatientData.push(val)
  }
  return convertedPatientData.sort((a, b) =>
    moment(b.lastUpdatedDate).diff(a.lastUpdatedDate)
  )
}

export function getPatientProfile(
  patientData: R4.IPatient,
  status: boolean
): PatientProfile {
  let dataOcc: string = ''
  let occupationData: string = getOccupationValueOfUrlPatient(
    patientData.extension,
    'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation',
    'http://wellopathy.com/fhir/india/core/CodeSystem/occupation'
  )
  if (occupationData.length > 0) {
    const selectedOccu: any = occupations.find(
      (occupation) => occupation.id === occupationData
    )
    if (selectedOccu === undefined) {
      dataOcc = 'other'
    } else {
      occupationData = ''
    }
  }
  const convertedPatientData: PatientProfile = {
    id: patientData.id ?? '',
    name: `${getNameOfPatient(patientData)}`,
    addressType: `${getAddressKindByAddress(patientData.address ?? []) ?? ''}`,
    middleName: `${getMiddleNameOfPatient(patientData)}`,
    dob: moment(patientData.birthDate).format('YYYY-MM-DD'),
    type: '',
    lastName: `${getLastNameOfPatient(patientData)}`,
    gender: `${getGenderOfPatient(patientData)}`,
    phone: `${getTelecomOfPatientEdit(patientData)}`,
    email: `${getEmailOfPatient(patientData)}`,
    imgName: '',
    patient: patientData,
    photoUri: getProfilePicPatient(patientData),
    address: `${getAddressOfPatientForTable(patientData.address ?? []) ?? ''}`,
    maritalStaTus: `${getMaritalStatusCode(patientData)}`,
    labReference: `${getIdentifierValueBySystem(
      patientData.identifier ?? [],
      'labReference'
    )}`,
    nationality: getExtensionValueOfUrlPatient(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/country'
    ),
    fathersName: '',
    husbandName: '',
    occupation:
      dataOcc.length === 0
        ? getOccupationValueOfUrlPatient(
            patientData.extension,
            'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation',
            'http://wellopathy.com/fhir/india/core/CodeSystem/occupation'
          )
        : dataOcc,
    contentType: '',
    occuptionOtherData: occupationData,
    city: `${getAddressOfCity(patientData.address ?? []) ?? ''}`,
    state: `${getAddressOfState(patientData.address ?? []) ?? ''}`,
    status,
    passportStartDate: getPassportStartDate(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
    ),
    passportEndDate: getPassportEndDate(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
    ),
    passportNumber: '',
    tempAddress:
      getAddressOfPatientForTempAddress(patientData.address ?? []) ?? '',
    tempCity: `${getTempAddressOfCity(patientData.address ?? []) ?? ''}`,
    tempCountry: `${getTempAddressOfState(patientData.address ?? []) ?? ''}`,
    country: `${getCountry(patientData.address ?? []) ?? ''}`,
    nationalityCoding: getExtensionCodingOfUrlPatient(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/country'
    ),
    patientCreatedDate: '',
    visaNo:
      getStringValueExtensionsOfUrl(
        patientData.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
      ) ?? '',
    visaType: getDataFromCoding(
      patientData.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
    ),
    visaTypeCoding: getVisaType(
      patientData.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
    ),
    visaIssueDate: getPassportStartDate(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
    ),
    visaExpiryDate: getPassportEndDate(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
    ),
    visaIssueCity: getVisaCity(
      patientData.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
    ),
    visaIssueCountry: '',
    visaIssueCountryCode: undefined,
    addressProofTypeCoding: getIdentifierValueBySystemTypeCoding(
      patientData.identifier ?? [],
      'address-proof'
    )!,
    addressProofNumber: getIdentifierValueBySystem(
      patientData.identifier ?? [],
      'address-proof'
    )!,
  }
  return convertedPatientData
}

export function getPatientProfileFinal(
  patientData: PatientProfile,
  date: string
): PatientProfile {
  console.log(patientData)
  const convertedPatientData: PatientProfile = {
    id: patientData.id ?? '',
    name: patientData.name,
    addressType: patientData.addressType,
    middleName: patientData.middleName,
    dob: patientData.dob,
    type: '',
    lastName: patientData.lastName,
    patient: patientData.patient,
    gender: patientData.gender,
    phone: patientData.phone,
    email: patientData.email,
    imgName: patientData.imgName,
    photoUri: patientData.photoUri,
    address: patientData.address,
    maritalStaTus: patientData.maritalStaTus,
    labReference: patientData.labReference,
    nationality: patientData.nationality,
    fathersName: patientData.fathersName,
    husbandName: patientData.husbandName,
    occupation: patientData.occupation,
    contentType: patientData.contentType,
    occuptionOtherData: patientData.occuptionOtherData,
    city: patientData.city,
    state: patientData.state,
    status: patientData.status,
    passportStartDate: patientData.passportStartDate,
    passportEndDate: patientData.passportEndDate,
    passportNumber: patientData.passportNumber,
    tempAddress: patientData.tempAddress,
    tempCity: patientData.tempCity,
    tempCountry: patientData.tempCountry,
    country: patientData.country,
    nationalityCoding: patientData.nationalityCoding,
    patientCreatedDate: date,
    visaNo: patientData.visaNo,
    visaType: patientData.visaType,
    visaTypeCoding: patientData.visaTypeCoding,
    visaIssueDate: patientData.visaIssueDate,
    visaExpiryDate: patientData.visaExpiryDate,
    visaIssueCity: patientData.visaIssueCity,
    visaIssueCountry: patientData.nationalityCoding.display ?? '',
    visaIssueCountryCode: undefined,
    addressProofNumber: patientData.addressProofNumber,
    addressProofTypeCoding: patientData.addressProofTypeCoding,
  }
  return convertedPatientData
}

export function getVisaDetails(patient: R4.IPatient): VisaDetails {
  const convertedPatientData: VisaDetails = {
    visaNumber:
      getStringValueExtensionsOfUrl(
        patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
      ) ?? '',
    visaTypeCoding: getVisaType(
      patient.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
    ),
    visaIssueDate: getPassportStartDate(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
    ),
    visaExpiryDate: getPassportEndDate(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
    ),
    visaIssueCity: getVisaCity(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
    ),
    visaIssueCountry: getVisaCountry(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
    )
      ? getVisaCountry(
          patient.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
        )!.display ?? ''
      : '',
    visaIssueCountryCoding: getVisaCountry(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
    ),
  }
  return convertedPatientData
}
