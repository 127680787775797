import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AppDispatch, AppThunk } from 'redux/store'
import { CPGApiService } from 'services/cpgApiService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import {
  hasError,
  interceptCdsResponse,
  mergeAndGetSingleCard,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { getVendorPartId } from 'utils/routes_helper'
import {
  CPGRecommendations,
  CPGRecommendationsStatus,
} from './cpgRecommendationsState'
import { CPGRecommendationResponse } from './cpgResponse'

const initialState: CPGRecommendationsStatus = {
  fetchingRecommendations: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileRequesting: false,
}

const cpgRecommendationsSlice = createSlice({
  name: 'cpgRecommendationsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<CPGRecommendationsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingRecommendations = action.payload.fetchingRecommendations
      state.resultsAvailable = action.payload.resultsAvailable
      state.recommendationsList = action.payload.recommendationsList
      state.errorReason = action.payload.errorReason
      state.errorWhileRequesting = action.payload.errorWhileRequesting
      state.patientId = action.payload.patientId
    },
  },
})

export const resetRecommendations =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CPGRecommendationsStatus = {
      ...initialState,
    }

    dispatch(cpgRecommendationsSlice.actions.updatedStatus(state))
  }

export const requestCPGRecommendationForSubscribedPatientData =
  ({
    selectedPatient,
    onlyEssential,
    planDefinition,
    currentState,
  }: {
    selectedPatient: R4.IPatient
    planDefinition: R4.IPlanDefinition[]
    onlyEssential?: boolean
    currentState?: CPGRecommendationsStatus
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    let state: CPGRecommendationsStatus = {
      fetchingRecommendations: true,
      errorWhileRequesting: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      patientId: selectedPatient.id,
    }
    if (currentState) {
      state = {
        ...currentState,
        fetchingRecommendations: true,
        patientId: selectedPatient.id,
      }
    }
    dispatch(cpgRecommendationsSlice.actions.updatedStatus(state))

    console.log('---------planDefinition-------------', planDefinition)

    try {
      const validPlanDefinition = planDefinition.filter(
        (e) => e.library && e.library.length > 0
      )

      const recommendations = await getRecommendationsOfPatientForSelectedPlans(
        {
          patient: selectedPatient,
          planDefinitions: validPlanDefinition,
          onlyEssential,
        }
      )

      if (recommendations && recommendations.length > 0) {
        const resultState: CPGRecommendationsStatus = {
          ...state,
          fetchingRecommendations: false,
          resultsAvailable: true,
          noResultsAvailable: false,
          errorWhileRequesting: false,
          recommendationsList: recommendations,
        }

        dispatch(cpgRecommendationsSlice.actions.updatedStatus(resultState))
      } else {
        const errorState: CPGRecommendationsStatus = {
          ...state,
          fetchingRecommendations: false,
          patientId: selectedPatient.id,
          errorWhileRequesting: true,
          errorReason: 'No proper url is avialable',
        }

        dispatch(cpgRecommendationsSlice.actions.updatedStatus(errorState))
      }
    } catch (error) {
      console.log('---------error-------------', error)
      const errorState: CPGRecommendationsStatus = {
        ...state,
        fetchingRecommendations: true,
        patientId: undefined,
        errorWhileRequesting: true,
        errorReason: 'Error while fetching CPG recommendations',
      }

      dispatch(cpgRecommendationsSlice.actions.updatedStatus(errorState))
    }
  }

async function fetchCPGRecommendationsForPatient({
  patient,
  onlyEssential,
  endPoint,
}: {
  patient: R4.IPatient
  onlyEssential?: boolean
  endPoint: string
}): Promise<CPGRecommendations> {
  try {
    const cpgApiService: CPGApiService = new CPGApiService()
    const response: AxiosResponse = await cpgApiService.doPost(
      `${endPoint.replace('/', '')}/`,
      {
        hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
        hook: 'patient-view',
        vcpg_role: getCurrentUserPractitionerRoleDetails().id,
        fhirServer: `${
          process.env.REACT_APP_FHIR_BASE_URL ?? ''
        }/${getVendorPartId()}`,
        context: {
          patientId: patient.id,
          userId: 'Practitioner/COREPRACTITIONER1',
        },
        parameters: {
          Optimal: true,
          Essential: false,
        },
        fhirAuthorization: {
          access_token: getAccessToken(),
          expires_in: 300,
          scope: 'patient/*.read',
          subject: 'lazy-checker',
        },
        prefetch: {},
      }
    )

    if (response.status === 200 || response.status === 201) {
      const currentResponseCards: CPGRecommendationResponse =
        response.data as CPGRecommendationResponse
      console.log('---------------url-----------------', endPoint)
      console.log(response.data)

      if (currentResponseCards.cards && currentResponseCards.cards.length > 0) {
        const errorResponses = [...currentResponseCards.cards].filter((e) =>
          hasError(e)
        )
        console.log('---------------errorResponses-----------------', endPoint)
        console.log(errorResponses)
        const resultResponses = [...currentResponseCards.cards].filter(
          (e) => !hasError(e)
        )

        console.log('---------------resultResponses-----------------', endPoint)
        console.log(resultResponses)

        const interceptedSuggestions = resultResponses.map((e) =>
          interceptCdsResponse(e)
        )

        console.log(
          '---------------interceptedSuggestions-----------------',
          interceptedSuggestions
        )

        const mergedCards = mergeAndGetSingleCard({
          cards: interceptedSuggestions,
        })

        return {
          cpgUrlIdentifier: endPoint,
          recommendation: {
            cards: mergedCards !== undefined ? [mergedCards] : [],
          },
          errorResponse:
            errorResponses.length > 0
              ? {
                  cards: errorResponses,
                }
              : undefined,
          recommendationsResultTime: new Date().toISOString(),
        }
      }
    }
  } catch (error) {
    console.log('---------error-------------', error)
    return {
      cpgUrlIdentifier: endPoint,

      recommendationsResultTime: new Date().toISOString(),
    }
  }

  return {
    cpgUrlIdentifier: endPoint,

    recommendationsResultTime: new Date().toISOString(),
  }
}

/// get all cds recommendation for a patient form plan definitions list

export async function getRecommendationsOfPatientForSelectedPlans({
  patient,
  planDefinitions,
  onlyEssential,
}: {
  patient: R4.IPatient
  planDefinitions: R4.IPlanDefinition[]
  onlyEssential?: boolean
}): Promise<CPGRecommendations[]> {
  const recommendations = await Promise.all(
    planDefinitions.map(async (planDefinition) => {
      const cpgEndPoint = planDefinition.library![0]
      const response: CPGRecommendations =
        await fetchCPGRecommendationsForPatient({
          endPoint: cpgEndPoint,
          patient,
          onlyEssential,
        })
      return response
    })
  )

  return recommendations
}

export default cpgRecommendationsSlice.reducer
