import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Divider,
  Grid,
  Switch,
  withStyles,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import {
  getCategoryCode,
  getTimeOfDayDisplayForStatement,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { medicationCancellationReason } from 'utils/constants'

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface MedicationsListProps {
  medicationsList: WelloMedication[]
  onDelete: (medication: WelloMedication) => void
  onEditClicked: (medication: WelloMedication) => void
  history: boolean
  split: boolean
  displayStartEndDates?: boolean
  onUpdateStatus?: (
    ins: WelloMedication,
    checked: boolean,
    index: number
  ) => void
  onUpdateRequest?: (ins: WelloMedication, reason: R4.ICoding) => void
}

export const AyurvedaMedicationsList: React.FC<MedicationsListProps> = ({
  medicationsList,
  onDelete,
  onEditClicked,
  displayStartEndDates = false,
  history = false,
  split = false,
  onUpdateStatus,
  onUpdateRequest,
}: MedicationsListProps) => {
  const classes = useStyles()
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  return (
    <TableContainer
      style={{
        width: '100%',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Table className={classes.table} aria-label='simple table'>
        <TableHead
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <TableRow
            style={{
              fontWeight: 'bold',
            }}
          >
            <TableCell width={30}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhi (Medication)
              </Typography>
            </TableCell>
            <TableCell width={30}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            <TableCell width={25}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Route of Administration
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Time of Day
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhakala
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Anupana
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            {displayStartEndDates && (
              <TableCell width={15}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Start from
                </Typography>
              </TableCell>
            )}
            {displayStartEndDates && (
              <TableCell width={15}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Till
                </Typography>
              </TableCell>
            )}
            <TableCell
              width={20}
              style={{
                maxWidth: '50px',
              }}
            >
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography>
            </TableCell>
            {history === false && split === false && (
              <TableCell width={4}>
                {/* <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography> */}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {medicationsList.map((row: WelloAyurvedaMedication, index) => (
            <TableRow
              key={row.id ?? row.medicationCode?.code ?? row.code ?? ''}
              style={{
                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
              }}
            >
              <TableCell width={30}>
                <Box width='100%' px={0.5}>
                  <OverflowTypography
                    text={_.capitalize(
                      row.medicationCode
                        ? row.medicationCode?.display ?? row.code ?? ''
                        : ''
                    )}
                    typographyProps={{
                      variant: 'body2',
                      color: 'initial',
                    }}
                  />
                </Box>
                {/* <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {_.capitalize(
                    row.medicationCode
                      ? row.medicationCode?.display ?? row.code ?? ''
                      : ''
                  )}
                </Typography> */}
              </TableCell>
              <TableCell
                width={30}
                style={{ maxWidth: '60px', minWidth: '60px' }}
              >
                <OverflowTypography
                  text={`${row.dosageQuantity ? row.dosageQuantity : ''} 
                        ${getUnitOfDosageFromRequest(
                          row.dosageQuantityType ?? ''
                        )}
                    `}
                  typographyProps={{
                    variant: 'subtitle2',
                    color: 'initial',
                  }}
                />
              </TableCell>
              <TableCell width={20}>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {row.administrationCode
                    ? getUnitOfRouteRequest(row.administrationCode)
                    : ''}
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    width: '85px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    wordWrap: 'break-word',
                  }}
                >
                  {getTimeOfDayDisplayForStatement(
                    row.timeOfDayCode ?? []
                  ).join(', ') ?? ''}
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {_.capitalize(
                    row.aushadhaKalaCode
                      ? row.aushadhaKalaCode.display ?? ''
                      : ''
                  )}
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {_.capitalize(
                    row.anupanaCode ? row.anupanaCode.display ?? '' : ''
                  )}
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {row.anupanaDosageQuantity ? row.anupanaDosageQuantity : ''}{' '}
                  {getUnitOfDosageFromRequest(
                    row.anupanaDosageQuantityType ?? ''
                  )}
                </Typography>
              </TableCell>
              {displayStartEndDates && (
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      width: '65px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {`${moment(row.startFrom).format('DD-MM-YYYY')}`}
                  </Typography>
                </TableCell>
              )}
              {displayStartEndDates && (
                <TableCell size='medium' width='50px'>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      width: '65px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {`${moment(row.till).format('DD-MM-YYYY')}`}
                  </Typography>
                </TableCell>
              )}
              <TableCell
                width={20}
                style={{
                  maxWidth: '240px',
                }}
              >
                <Box width='100%'>
                  <OverflowTypography
                    text={row.notes ?? ''}
                    typographyProps={{
                      variant: 'body2',
                      color: 'initial',
                    }}
                  />
                </Box>
              </TableCell>
              {history === false && split === false && (
                <TableCell width={4}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='flex-start'
                    alignContent='center'
                    alignItems='center'
                  >
                    <Box paddingLeft={2}>
                      <Tooltip
                        title='Edit Medication'
                        id='manage_medication_tooltip'
                      >
                        <IconButton
                          aria-label='btn_ord_cancel'
                          color='primary'
                          id='manage_medication_ipd'
                          style={{ padding: 0 }}
                          onClick={() => {
                            onEditClicked(row)
                          }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/editVector.png`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
