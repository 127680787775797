import { R4 } from '@ahryman40k/ts-fhir-types'
import { FHIRApiClient } from 'services/fhirApiServices'

export async function getCommunicationResourceCheck(
  carePlanId: string,
  appointmentId: string
): Promise<R4.ICommunicationRequest | undefined> {
  let carePlan: R4.ICommunicationRequest | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    'encounter.appointment': appointmentId,
    priority: 'routine',
    'based-on': carePlanId,
  }
  const response: any = await fhirClient.doGetResource(
    '/CommunicationRequest',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry && responseBundle.entry.length > 0) {
    carePlan = responseBundle.entry[0].resource as R4.ICommunicationRequest
  }

  return carePlan
}

export async function getCommunicationResourceCheckIPD(
  carePlanId: string,
  encounterId: string
): Promise<R4.ICommunicationRequest | undefined> {
  let carePlan: R4.ICommunicationRequest | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    'encounter.based-on': encounterId,
    priority: 'routine',
    'based-on': carePlanId,
  }
  const response: any = await fhirClient.doGetResource(
    '/CommunicationRequest',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry && responseBundle.entry.length > 0) {
    carePlan = responseBundle.entry[0].resource as R4.ICommunicationRequest
  }

  return carePlan
}

/* export async function updateInvoiceDetailsToServer(
  invoice: R4.IInvoice,
  svId: string
) {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/invoice/payment`,
      { eventType: 'update-payment', invoice }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
    
  }
  return false
} */
