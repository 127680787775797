import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  FhirAppointmentDetail,
  FhirPractitionerDetail,
} from 'models/fhirAppointmentDetail'
import { WelloMedication } from 'models/welloMedication'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isCurrentUnitIsWellonessCenter } from 'services/userDetailsService'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { getWelloMedicationsFromBundleResponses } from 'utils/appointment_handle/medications_util'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { MedicationDetailsStatus } from './medicationsListTypes'

const initialState: MedicationDetailsStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const medicationsListSlice = createSlice({
  name: 'medicationsListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MedicationDetailsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.medications = action.payload.medications
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
      state.raw = action.payload.raw
    },
  },
})

export const getMedicationsOfAppointment =
  (
    patient: R4.IPatient,

    encounter?: R4.IEncounter,
    followUp?: boolean,
    supportingInfo?: R4.IReference[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicationDetailsStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(medicationsListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let searchParameters: any = {
        encounter: encounter?.id,
        subject: `Patient/${patient.id!}`,
        _sort: '-_lastUpdated',
        '_tag:not': 'mirror-resource',
      }
      if (isCurrentUnitIsWellonessCenter()) {
        searchParameters.subject = `Patient/${patient.id!}`
      }

      if (
        followUp &&
        supportingInfo &&
        getAppointmentIdForFollowUp(supportingInfo ?? []).length > 0
      ) {
        searchParameters = {
          'encounter.appointment': getAppointmentIdForFollowUp(
            supportingInfo ?? []
          ),
        }
      }

      const currentVisitReference = sessionStorage.getItem(
        CURRENT_VISIT_REFERENCE
      )

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/MedicationRequest',
        currentVisitReference!,
        searchParameters
      )
      logger.info(
        '-----------------------------MeREQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(medicationsListSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('Medication resp', conditionResponse.entry)
        if (conditionResponse?.entry && conditionResponse?.entry.length > 0) {
          logger.info(
            '-----------------------------MeREQ!----------------------------------------------'
          )
          const clinicalImpressions: WelloMedication[] =
            getWelloMedicationsFromBundleResponses(conditionResponse)

          logger.info(
            '-----------------------------MeREQ2----------------------------------------------'
          )
          if (clinicalImpressions && clinicalImpressions.length > 0) {
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.medications = clinicalImpressions

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            state.raw = conditionResponse.entry
              .filter((e) => e.resource?.resourceType === 'MedicationRequest')
              .map((e) => e.resource as R4.IMedicationRequest)
            dispatch(medicationsListSlice.actions.updatedStatus(state))
          }
        } else {
          const errorSearchDoctor: MedicationDetailsStatus = { ...initialState }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            medicationsListSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: MedicationDetailsStatus = { ...initialState }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(medicationsListSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }
export default medicationsListSlice.reducer
