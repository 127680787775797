import { R4 } from '@ahryman40k/ts-fhir-types'
import { doctorAppointmentView, DoctorBase } from 'lib/openApi'
import { DateWiseCombinedSlots } from 'models/dateCombinedSlots'
import { DateWiseSlots } from 'models/dateSeparatedSlots'
import {
  DateSeparatedPendingAppointments,
  DateSeperatedAppointments,
} from 'models/dateSeperatedAppointments'
import {
  FhirAppointmentDetail,
  FhirPendingAppointmentDetails,
} from 'models/fhirAppointmentDetail'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { GroupedAllergy } from 'models/groupedAlergy'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { GroupedProceDure } from 'models/groupedProcedure'
import { MedicationGroupedData } from 'models/medicationGrouppedData'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
  isOnlyUnitAdmin,
} from 'services/userDetailsService'
import {
  getWelloMedicationFromMedicationRequest,
  getWelloMedicationFromMedicationRequestForHistory,
  getWelloMedicationFromMedicationStatement,
} from 'utils/appointment_handle/medications_util'
import { getStartAndEndTimeInString } from 'utils/dateUtil'
import {
  getCodeOfSystemFromCodableConcept,
  getCodeValueForChiefComplaints,
  getCommaSeparatedCondition,
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConceptForAppointment,
  getNameFromHumanName,
  getNameOfPatient,
  getStringValueExtensionsOfUrl,
  getStringValueExtensionsOfUrlForDurationVal,
  getUnitAsString,
  getUnitAsStringForVal,
  getValueCodingStringFromExtension,
  getValueRefValueExtensionsOfUrl,
  isMarried,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'
import { GroupedWelloMedication } from 'models/groupedWelloMedication'
import _ from 'lodash'
import { GroupedOccupation } from 'models/groupedOccupations'
import { GroupedFamilyHistory } from 'models/groupedFamilyHistory'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { VitalsData } from 'models/vitalsData'
import {
  GroupedLabResults,
  GroupedLabResultsByName,
} from 'models/groupedLabResults'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'
import { PathyaApathyaDetails } from 'utils/sopaNotes/plan/pathyaApathyaHelpers'
import { Diet } from 'models/diet'
import {
  AssociatedComplaintData,
  ChiefComplaintData,
  ChiefComplaintTerminology,
} from 'models/chiefComplaintData'
import { ChiefComplaintsData } from 'models/chiefComplaintsData'
import { LocationData } from 'models/location'
import { Conditions } from 'models/conditions'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { FollowupAppointmentManagementStatus } from 'redux/appointments/followupManager/followupAppointmentManagerStatus'
import {
  getInvoiceDetails,
  getPaymentReconciliationResource,
} from 'redux/appointments/followupManager/followAappointmentManagerSlice'
import { UnitServices } from 'models/units/unitServices'
import {
  getSlotAmountForAppointment,
  getSlotsBasedonTimesFromSlotsRelationsForAgent,
} from './fhirSlotHelper'
import { getUniqueTempId } from './idHelpers'
import {
  getLabTestData,
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromEncounterForDateWiseForHistory,
  getPanel1Data,
  getPanel2Data,
  getPanel3Data,
  getPanel4Data,
  getPanelDexaData,
  getPaneLftData,
  getPanelHomaData,
  getPanelSerumLytesData,
  getPanelTftData,
  getPaneRftData,
} from './ipdObservationHelper'
import { getDateWiseVitals } from './labOrderHelpers'
import { getLabResultsPanel, getLabResultsWithName } from './observationHelpers'
import {
  getNotesString,
  hasNotesForChiefForNote,
  hasNotesForChiefForPatient,
  hasNotesForChiefForPatientText,
} from './allergyHelpers'
import {
  DateWiseNutritionOrders,
  getEndDate,
  getLatestNutritionOrderFromBundleSorted,
  getStartDate,
} from './dietPlanHelpers'
import { getNutritionOrdersForSepcificTimeForOPD } from './ipdDietHelper'

export function getEncounterObjectForAppointment(
  appointment: FhirAppointmentDetail
): R4.IEncounter {
  const encounter: R4.IEncounter = {
    resourceType: 'Encounter',
    id: appointment.encounter ? appointment.encounter.id ?? '' : '',
    class: {
      system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
      code: 'AMB',
      display: 'ambulatory',
    },
    status: R4.EncounterStatusKind._inProgress,
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },
    appointment: [
      {
        reference: `${appointment.appointment.resourceType}/${appointment.appointment.id}`,
        id: appointment.appointment.id,
        type: appointment.appointment.resourceType,
      },
    ],
    period: {
      start: moment().toISOString(),
    },
    participant: [
      {
        type: [
          {
            text: 'primary performer',
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: {
          display: getNameFromHumanName(
            appointment.practitionerDetail.practitioner?.name ?? []
          ),
          reference: `${appointment.practitionerDetail.practitioner?.resourceType}/${appointment.practitionerDetail.practitioner?.id}`,
          id: appointment.practitionerDetail.practitioner?.id,
          type: appointment.practitionerDetail.practitioner?.resourceType,
        },
      },
      {
        type: [
          {
            text: 'primary performer',
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: {
          reference: `${appointment.practitionerDetail.practitionerRole?.resourceType}/${appointment.practitionerDetail.practitionerRole?.id}`,
          id: appointment.practitionerDetail.practitionerRole?.id,
          type: appointment.practitionerDetail.practitionerRole?.resourceType,
        },
      },
    ],
  }
  return encounter
}

export function getDateWiseSlots(
  availableSlots: FhirSlotDetail[]
): DateWiseSlots[] {
  const dateWiseSlots: DateWiseSlots[] = []

  availableSlots.forEach((item) => {
    if (item.slot.start) {
      const date = moment.utc(item.slot.start).local().format('YYYY-MM-DD')
      const local = moment.utc(item.slot.start).local().format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex((s) => s.date === date)
      if (index < 0) {
        const dateWiseSlot: DateWiseSlots = { date }

        if (isMorningSlot(item.slot.start)) {
          dateWiseSlot.morningSlots = [item]
          dateWiseSlot.afterNoonSlots = []
          dateWiseSlot.eveningSlots = []
        }
        if (isAfterNoonSlot(item.slot.start)) {
          dateWiseSlot.afterNoonSlots = [item]
          dateWiseSlot.morningSlots = []
          dateWiseSlot.eveningSlots = []
        }
        if (isEvening(item.slot.start)) {
          dateWiseSlot.eveningSlots = [item]
          dateWiseSlot.afterNoonSlots = []
          dateWiseSlot.morningSlots = []
        }

        dateWiseSlots.push(dateWiseSlot)
      } else {
        if (isMorningSlot(item.slot.start)) {
          dateWiseSlots[index].morningSlots?.push(item)
        }
        if (isAfterNoonSlot(item.slot.start)) {
          dateWiseSlots[index].afterNoonSlots?.push(item)
        }
        if (isEvening(item.slot.start)) {
          dateWiseSlots[index].eveningSlots?.push(item)
        }
      }
    }
  })

  return dateWiseSlots
}

export function getDateWiseCombinedSlots(
  availableSlots: FhirSlotDetail[]
): DateWiseCombinedSlots[] {
  const dateWiseSlots: DateWiseSlots[] = getDateWiseSlots(availableSlots)

  const dateWiseCombinedSlots: DateWiseCombinedSlots[] = dateWiseSlots.map(
    (e) => {
      const slotDetails: DateWiseCombinedSlots = {
        date: e.date,
        morningSlots: getSlotsBasedonTimesFromSlotsRelationsForAgent(
          e.morningSlots!
        ),
        afterNoonSlots: getSlotsBasedonTimesFromSlotsRelationsForAgent(
          e.afterNoonSlots!
        ),
        eveningSlots: getSlotsBasedonTimesFromSlotsRelationsForAgent(
          e.eveningSlots!
        ),
      }
      return slotDetails
    }
  )

  return dateWiseCombinedSlots
}

export function isMorningSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 0 && currentHour < 12) {
    return true
  }
  return false
}
export function isAfterNoonSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 12 && currentHour < 18) {
    return true
  }
  return false
}

export function isEvening(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 18 && currentHour < 24) {
    return true
  }
  return false
}

export function getOrderCreationSuccessfulMessage(
  patient: R4.IPatient
): string {
  const message: string = `Order created for ${getNameOfPatient(patient)}`

  return message
}

export function getOrderUpdateSuccessfulMessage(patient: R4.IPatient): string {
  const message: string = `Order updated for ${getNameOfPatient(patient)}`

  return message
}

export function getAppointmentSuccessfulMessage(
  patient: R4.IPatient,
  selectedDoctor: R4.IPractitioner | undefined,
  selectedSlot: R4.ISlot
): string {
  const message: string = `OPD Appointment created for ${getNameOfPatient(
    patient
  )} with ${getNameFromHumanName(
    selectedDoctor?.name ?? []
  )} on ${dateToFromNowDaily(selectedSlot.start?.toString() ?? '')} at ${moment(
    selectedSlot.start ?? ''
  ).format('HH:mm a')}`

  return message
}

export function getAppointmentSuccessfulMessageIPD(
  patient: R4.IPatient
): string {
  const message: string = `IPD Appointment created for ${getNameOfPatient(
    patient
  )}`

  return message
}

export function getAppointmentSuccessfulMessageDayCare(
  patient: R4.IPatient
): string {
  const message: string = `OPD Appointment created for ${getNameOfPatient(
    patient
  )}`

  return message
}
export function getAppointmentCancelMessage(
  appointmentDetails: FhirAppointmentDetail
): string {
  const message: string = `OPD Appointment cancelled for ${getNameOfPatient(
    appointmentDetails.patient
  )} with ${getNameFromHumanName(
    appointmentDetails.practitionerDetail.practitioner.name ?? []
  )} scheduled on ${dateToFromNowDaily(
    appointmentDetails.appointment.start?.toString() ?? ''
  )} at ${moment(appointmentDetails.appointment.start ?? '').format(
    'HH:mm a'
  )}.`

  return message
}
function dateToFromNowDaily(myDate: string) {
  // get from-now for this date
  const fromNow = moment(myDate).fromNow()

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse() {
      return `[${fromNow}]`
    },
  })
}

export function filterAppointmentsAsPerDoctorSelection(
  selectedDoctors: DoctorBase[],
  appointments: doctorAppointmentView[]
): doctorAppointmentView[] {
  if (selectedDoctors) {
    if (selectedDoctors.length > 0) {
      const doctorIds: string[] = selectedDoctors.map((doc) => doc?.id ?? '')

      const filterOutAppointments: doctorAppointmentView[] =
        appointments.filter((item, index, arr) =>
          doctorIds.includes(item.doctorId ?? 'null')
        )

      return filterOutAppointments
    }
  }
  return appointments
}

export function getExpandedAppointmentFromBundle(
  responseSlots: R4.IBundle,
  name?: string
): FhirAppointmentDetail[] {
  const convertedAppointments: FhirAppointmentDetail[] = []
  const appointments: any = {}
  const slots: any = {}
  const encounters: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const paymentReconilation: any = {}

  if (responseSlots.entry) {
    const entries: R4.IBundle_Entry[] = responseSlots.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.id) {
          switch (value.resource.resourceType) {
            case 'Appointment':
              appointments[value.resource.id] =
                value.resource as R4.IAppointment
              break
            case 'Slot':
              slots[value.resource.id] = value.resource as R4.ISlot
              break
            case 'Practitioner':
              practitioners[value.resource.id] =
                value.resource as R4.IPractitioner
              break
            case 'PractitionerRole':
              practitionerRoles[value.resource.id] =
                value.resource as R4.IPractitionerRole
              break
            case 'Encounter':
              encounters[
                value.resource.appointment?.[0].reference?.split('/')[1] ?? ''
              ] = value.resource as R4.IEncounter
              break
            case 'Patient':
              patients[value.resource.id] = value.resource as R4.IPatient
              break
            case 'PaymentReconciliation':
              paymentReconilation[value.resource.id] =
                value.resource as R4.IPaymentReconciliation
              break
            default:
              break
          }
        }
      }
    })

    for (const key in appointments) {
      if (key) {
        const currentAppointment: R4.IAppointment = appointments[
          key
        ] as R4.IAppointment

        let paymentId: string | undefined
        const slotId: string | undefined =
          currentAppointment.slot?.[0].reference?.split('/')[1]

        const practitionerId: string | undefined =
          currentAppointment?.participant
            ?.find((val) => val.actor?.reference?.includes('Practitioner/'))
            ?.actor?.reference?.split('/')[1]

        const practitionerRoleId: string | undefined =
          currentAppointment?.participant
            ?.find((val) => val.actor?.reference?.includes('PractitionerRole/'))
            ?.actor?.reference?.split('/')[1]

        const patientId: string | undefined = currentAppointment?.participant
          ?.find((val) => val.actor?.reference?.includes('Patient/'))
          ?.actor?.reference?.split('/')[1]
        for (const key1 in paymentReconilation) {
          if (key1) {
            const paymentInfo: R4.IPaymentReconciliation = paymentReconilation[
              key1
            ] as R4.IPaymentReconciliation

            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                paymentInfo.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref'
              )?.split('/')[1]
            if (chargeID === currentAppointment.id) {
              paymentId = paymentInfo.id
            }
          }
        }

        const practitionerRoleData: R4.IPractitionerRole =
          practitionerRoles[practitionerRoleId ?? '']
        const unitOrg = getCurrentUserUnitDetails()
        if (practitionerRoleData) {
          const orgId = practitionerRoleData.organization?.id ?? ''

          if (unitOrg.id === orgId) {
            const startStr: string =
              (appointments[key] as R4.IAppointment).start ?? ''

            convertedAppointments.push({
              start: (appointments[key] as R4.IAppointment).start ?? '',
              end: (appointments[key] as R4.IAppointment).end ?? '',
              appointment: appointments[key],
              slot: slots[slotId ?? ''],
              patient: patients[patientId ?? ''],
              encounter:
                encounters[(appointments[key] as R4.IAppointment).id ?? ''],
              practitionerDetail: {
                practitioner: practitioners[practitionerId ?? ''],
                practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
              },
              token: '',
              roomId: '',
              paymentReconcilation: paymentReconilation[paymentId ?? ''],
              enabled: !!(
                (moment(moment(moment()).format('YYYY-MM-DDTHH:mm:ss')).diff(
                  moment(startStr).format('YYYY-MM-DDTHH:mm:ss'),
                  'hours'
                ) <= 24 &&
                  moment(moment(moment()).format('YYYY-MM-DDTHH:mm:ss')).diff(
                    moment(startStr).format('YYYY-MM-DDTHH:mm:ss'),
                    'hours'
                  ) >= 0 &&
                  Number.isNaN(
                    moment(moment(moment()).format('YYYY-MM-DDTHH:mm:ss')).diff(
                      moment(startStr).format('YYYY-MM-DDTHH:mm:ss'),
                      'hours'
                    )
                  ) === false) ||
                moment(moment(moment()).format('YYYY-MM-DD')).isSame(
                  moment(startStr).format('YYYY-MM-DD')
                )
              ),
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getExpandedAppointmentFullDetailFromBundle(
  responseSlots: R4.IBundle
): FhirAppointmentFullDetail[] {
  const convertedAppointments: FhirAppointmentFullDetail[] = []
  const appointments: any = {}
  const slots: any = {}
  const encounters: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const clinicalImpressions: any = {}
  const paymentReconilation: any = {}
  const conditions: Map<string, R4.ICondition[]> = new Map<
    string,
    R4.ICondition[]
  >()
  const allergies: Map<string, R4.IAllergyIntolerance[]> = new Map<
    string,
    R4.IAllergyIntolerance[]
  >()

  const medications: Map<string, WelloMedication[]> = new Map<
    string,
    WelloMedication[]
  >()

  const observations: Map<string, R4.IObservation[]> = new Map<
    string,
    R4.IObservation[]
  >()
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'PaymentReconciliation':
                  paymentReconilation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                case 'Encounter':
                  encounters[
                    value.resource.appointment?.[0].reference?.split('/')[1] ??
                      ''
                  ] = value.resource as R4.IEncounter
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'ClinicalImpression':
                  clinicalImpressions[
                    value.resource.encounter?.reference?.split('/')[1] ?? ''
                  ] = value.resource as R4.IClinicalImpression
                  break

                case 'Observation': {
                  const encounterId: string =
                    value.resource.encounter?.reference?.split('/')[1] ?? ''
                  if (observations.has(encounterId)) {
                    observations
                      .get(encounterId)
                      ?.push(value.resource as R4.IObservation)
                  } else {
                    observations.set(encounterId, [
                      value.resource as R4.IObservation,
                    ])
                  }

                  break
                }

                case 'AllergyIntolerance': {
                  const encounterId: string =
                    value.resource.encounter?.reference?.split('/')[1] ?? ''
                  if (allergies.has(encounterId)) {
                    allergies
                      .get(encounterId)
                      ?.push(value.resource as R4.IAllergyIntolerance)
                  } else {
                    allergies.set(encounterId, [
                      value.resource as R4.IAllergyIntolerance,
                    ])
                  }

                  break
                }

                case 'MedicationRequest': {
                  const encounterId: string =
                    value.resource.encounter?.reference?.split('/')[1] ?? ''
                  if (medications.has(encounterId)) {
                    medications
                      .get(encounterId)
                      ?.push(
                        getWelloMedicationFromMedicationRequest(
                          value.resource as R4.IMedicationRequest
                        )
                      )
                  } else {
                    medications.set(encounterId, [
                      getWelloMedicationFromMedicationRequest(
                        value.resource as R4.IMedicationRequest
                      ),
                    ])
                  }

                  break
                }

                case 'Condition': {
                  const encounterId: string =
                    value.resource.encounter?.reference?.split('/')[1] ?? ''
                  if (conditions.has(encounterId)) {
                    conditions
                      .get(encounterId)
                      ?.push(value.resource as R4.ICondition)
                  } else {
                    conditions.set(encounterId, [
                      value.resource as R4.ICondition,
                    ])
                  }

                  break
                }

                default:
                  break
              }
            }
          }
        })

        for (const key in appointments) {
          if (key) {
            const currentAppointment: R4.IAppointment = appointments[
              key
            ] as R4.IAppointment

            let paymentId: string | undefined
            for (const key1 in paymentReconilation) {
              if (key1) {
                const paymentInfo: R4.IPaymentReconciliation =
                  paymentReconilation[key1] as R4.IPaymentReconciliation

                const chargeID: string | undefined =
                  getValueRefValueExtensionsOfUrl(
                    paymentInfo.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref'
                  )?.split('/')[1]
                if (chargeID === currentAppointment.id) {
                  paymentId = paymentInfo.id
                }
              }
            }
            const slotId: string | undefined =
              currentAppointment.slot?.[0].reference?.split('/')[1]
            const practitionerId: string | undefined =
              currentAppointment?.participant
                ?.find((val) => val.actor?.reference?.includes('Practitioner/'))
                ?.actor?.reference?.split('/')[1]
            const practitionerRoleId: string | undefined =
              currentAppointment?.participant
                ?.find((val) =>
                  val.actor?.reference?.includes('PractitionerRole/')
                )
                ?.actor?.reference?.split('/')[1]

            const patientId: string | undefined =
              currentAppointment?.participant
                ?.find((val) => val.actor?.reference?.includes('Patient/'))
                ?.actor?.reference?.split('/')[1]

            const practitionerRoleData: R4.IPractitionerRole =
              practitionerRoles[practitionerRoleId ?? '']
            const unitOrg = getCurrentUserUnitDetails()

            const encounterId: string | undefined = (
              encounters[
                (appointments[key] as R4.IAppointment).id ?? ''
              ] as R4.IEncounter
            ).id
            const clinicalImp: R4.IClinicalImpression | undefined =
              clinicalImpressions[encounterId ?? '']

            if (practitionerRoleData) {
              const orgId = practitionerRoleData.organization?.id ?? ''
              if (unitOrg.id === orgId) {
                convertedAppointments.push({
                  start: (appointments[key] as R4.IAppointment).start ?? '',
                  end: (appointments[key] as R4.IAppointment).end ?? '',
                  appointment: appointments[key],
                  slot: slots[slotId ?? ''],
                  patient: patients[patientId ?? ''],
                  encounter:
                    encounters[(appointments[key] as R4.IAppointment).id ?? ''],
                  practitionerDetail: {
                    practitioner: practitioners[practitionerId ?? ''],
                    practitionerRole:
                      practitionerRoles[practitionerRoleId ?? ''],
                  },
                  allergies: allergies.get(encounterId ?? ''),
                  conditions: conditions.get(encounterId ?? ''),
                  medications: medications.get(encounterId ?? ''),
                  observations: observations.get(encounterId ?? ''),
                  clinicalImpression: clinicalImp,
                  token: '',
                  roomId: '',
                  paymentReconcilation: paymentReconilation[paymentId ?? ''],
                  enabled: false,
                })
              }
            }
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getUserDetailAndAppointmentDetail(
  responseSlots: FhirAppointmentDetail,
  opdId?: string
): string {
  let text: string = 'Single,'
  if (opdId && opdId.length > 0) {
    if (responseSlots.patient.maritalStatus) {
      text = isMarried(responseSlots.patient.maritalStatus)
        ? 'Married, '
        : 'Single, '
    }
    text = `${text}  ${
      getDisplayOfSystemFromCodableConceptForAppointment(
        responseSlots?.appointment?.serviceType?.[0]
      ) ?? ''
    }`
  } else {
    if (responseSlots.patient.maritalStatus) {
      text = isMarried(responseSlots.patient.maritalStatus)
        ? 'Married, '
        : 'Single, '
    }
    text = `${text} ${
      getDisplayOfSystemFromCodableConceptForAppointment(
        responseSlots?.appointment?.serviceType?.[0]
      ) ?? '  In-Person'
    }`
  }

  return text
}
export function getTimeForAppointment(
  responseSlots: FhirAppointmentDetail,
  opdId?: string
): string {
  let text: string = 'Single,'
  if (opdId && opdId.length > 0) {
    text = `${moment(responseSlots.start).format('h:mm a')} - ${moment(
      responseSlots.end
    ).format('h:mm a')}`
  } else {
    text = ` ${moment(responseSlots.start).format(
      'DD-MM-YYYY h:mm a'
    )} - ${moment(responseSlots.end).format('h:mm a')}`
  }

  return text
}

export function getDateForAppointment(
  responseSlots: FhirAppointmentDetail,
  opdId?: string
): string {
  let text: string = 'Single,'
  if (opdId && opdId.length > 0) {
    text = `${moment(responseSlots.start).format('h:mm a')} - ${moment(
      responseSlots.end
    ).format('h:mm a')}`
  } else {
    text = ` ${moment(responseSlots.start).format('DD-MM-YYYY h:mm A')}`
  }

  return text
}

export function getDateWiseAppointments(
  availableSlots: FhirAppointmentDetail[]
): DateSeperatedAppointments[] {
  const dateWiseSlots: DateSeperatedAppointments[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateSeperatedAppointments = {
          date: item.start,
          appointments: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.appointments) {
        dateWiseSlots[index].appointments?.push(item)
      } else {
        dateWiseSlots[index].appointments = [item]
      }
    }
  })

  return dateWiseSlots.sort((a, b) => moment(b.date!).diff(a.date!))
}

export function getDateWisePendingAppointment(
  availableSlots: FhirPendingAppointmentDetails[]
): DateSeparatedPendingAppointments[] {
  const dateWiseSlots: DateSeparatedPendingAppointments[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateSeparatedPendingAppointments = {
          date: item.start,
          appointments: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.appointments) {
        dateWiseSlots[index].appointments?.push(item)
      } else {
        dateWiseSlots[index].appointments = [item]
      }
    }
  })

  return dateWiseSlots.sort((a, b) => moment(b.date!).diff(a.date!))
}

export function getAppointmentTime(
  appointmentDetails: FhirAppointmentDetail
): string | undefined {
  return getStartAndEndTimeInString(
    appointmentDetails.appointment.start ?? '',
    appointmentDetails.appointment.end ?? ''
  )
}

export function getAppointmentTimeForPending(
  appointmentDetails: FhirPendingAppointmentDetails
): string | undefined {
  return getStartAndEndTimeInString(
    appointmentDetails.appointment.start ?? '',
    appointmentDetails.appointment.end ?? ''
  )
}

export function getAppointmentDisplayCode(
  appointmentDetails: FhirAppointmentDetail
): string | undefined {
  let appointmentType: string = ''
  if (appointmentDetails.slot) {
    const slotData = appointmentDetails.slot

    const serviceType = slotData.serviceType ?? []
    const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
      serviceType[0] ?? {},
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-type'
    )
    appointmentType = code?.display ?? ''
    if (appointmentType === 'Walk-in Centre /Non-Emergency')
      appointmentType = 'IN-PERSON'
  }

  return appointmentType
}

export function getAppointmentStatusText(
  appointmentDetails: FhirAppointmentDetail
): string {
  let statusName: string = ''
  if (appointmentDetails.appointment) {
    switch (appointmentDetails.appointment.status) {
      case 'fulfilled':
        statusName = 'Completed'
        return statusName
      case 'booked':
        statusName = 'Booked'
        return statusName
      case 'cancelled':
        statusName = 'Cancelled'
        return statusName
      default:
        break
    }
  }
  return statusName
}

export function getAppointmentPaymentDetails(
  appointmentDetails: FhirAppointmentDetail
): boolean {
  let statusName: boolean = false
  if (appointmentDetails.paymentReconcilation) {
    if (
      appointmentDetails.paymentReconcilation.outcome &&
      appointmentDetails.paymentReconcilation.outcome === 'complete'
    ) {
      statusName = true
    }
  }
  return statusName
}

export function getPaymentStatusForAppointment(
  appointmentDetails: FhirAppointmentDetail
): boolean {
  let statusName: boolean = false
  if (appointmentDetails.paymentReconcilation) {
    const code =
      getValueCodingStringFromExtension(
        appointmentDetails.paymentReconcilation.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-status'
      ) ?? ''
    if (code === 'completed') {
      statusName = true
    }
  }
  return statusName
}

export function getAssementConditions(
  appointmentDetails: FhirAppointmentFullDetail
): (string | undefined)[] {
  let aseessments: (string | undefined)[] = []
  if (
    appointmentDetails.conditions &&
    appointmentDetails.conditions.length > 0
  ) {
    aseessments = appointmentDetails.conditions?.map(
      (e) => e.code?.coding?.[0].display
    )

    aseessments = aseessments.filter((e) => e !== undefined)
  }

  let allergies: (string | undefined)[] = []
  if (appointmentDetails.allergies && appointmentDetails.allergies.length > 0) {
    allergies = appointmentDetails.allergies?.map(
      (e) => e.code?.coding?.[0].display
    )
    allergies = allergies.filter((e) => e !== undefined)
  }
  return [...aseessments, ...allergies].filter((e) => e !== undefined)
}

export function getMedicationsStrings(
  appointmentDetails: FhirAppointmentFullDetail
): string[] {
  let aseessments: string[] = []
  aseessments =
    appointmentDetails.medications?.map((e) => {
      const sentence: string = `${
        e.medicationCode!.display ?? e.medicationCode!.code ?? ''
      } (${e.dosageQuantity} ${e.dosageQuantityType} )  From  ${moment(
        e.startFrom
      ).format('DD MMM')}  Till  ${moment(e.till).format('DD MMM ')} `

      return sentence
    }) ?? []
  return aseessments
}

export function isVideoAppointment(
  appointmentDetails: FhirAppointmentDetail
): boolean {
  const endTime = moment(appointmentDetails.start).add(3, 'hour')
  let type: string = ''

  if (appointmentDetails.appointment.serviceType) {
    if (appointmentDetails.appointment.serviceType[0].coding) {
      type = appointmentDetails.appointment.serviceType[0].coding[0].code ?? ''
    }
  }
  if (
    type === 'video-counselling' &&
    moment().isBetween(moment(appointmentDetails.start), endTime)
  ) {
    return true
  }

  return false
}

export function showVideoAppointment(
  appointmentDetails: FhirAppointmentDetail
): boolean {
  const endTime = moment(appointmentDetails.start).add(0, 'hour')
  let type: string = ''

  if (appointmentDetails.appointment.serviceType) {
    if (appointmentDetails.appointment.serviceType[0].coding) {
      type = appointmentDetails.appointment.serviceType[0].coding[0].code ?? ''
    }
  }
  if (type)
    if (
      type === 'video-counselling' &&
      moment().isSameOrBefore(moment(appointmentDetails.start))
    ) {
      return true
    }

  return false
}

export function enableVideoButton(
  appointmentDetails: FhirAppointmentDetail
): boolean {
  const endTime = moment(appointmentDetails.start).add(0, 'hour')
  let type: string = ''

  if (appointmentDetails.appointment.serviceType) {
    if (appointmentDetails.appointment.serviceType[0].coding) {
      type = appointmentDetails.appointment.serviceType[0].coding[0].code ?? ''
    }
  }

  if (type)
    if (
      type === 'video-counselling' &&
      moment().isSameOrAfter(moment(appointmentDetails.start))
    ) {
      return true
    }

  return false
}

export function getChargeItemForSelectedAppointment(
  subject: R4.IPatient,
  appointmentRef: R4.IReference,
  serviceTypeCharge?: R4.IChargeItemDefinition,
  deductibles?: R4.IChargeItemDefinition[],
  additions?: R4.IChargeItemDefinition[],
  followUp?: boolean
): R4.IChargeItem {
  let chargeDefinitionReferences: string[] = []
  if (serviceTypeCharge)
    chargeDefinitionReferences.push(
      `${serviceTypeCharge.resourceType}/${serviceTypeCharge.id}/_history/${
        serviceTypeCharge.meta?.versionId ?? 1
      }`
    )

  logger.info(chargeDefinitionReferences)
  if (deductibles && deductibles.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...deductibles.map(
        (e) => `${e.resourceType}/${e.id}/_history/${e.meta?.versionId ?? 1}`
      ),
    ]
  }

  logger.info(chargeDefinitionReferences)

  if (additions && additions.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...additions.map(
        (e) =>
          `${e.resourceType}/${e.id}/${e.id}/_history/${e.meta?.versionId ?? 1}`
      ),
    ]
  }
  const chargeItem: R4.IChargeItem = {
    resourceType: 'ChargeItem',
    status: R4.ChargeItemStatusKind._billed,
    enterer: getCurrentUserPractitionerRoleRef(),
    enteredDate: new Date().toISOString(),
    supportingInformation: [appointmentRef],
    requestingOrganization: getCurrentUserUnitReference(),
    code: {},
    subject: {
      reference: `${subject?.resourceType}/${subject.id}`,
    },
  }
  if (followUp === undefined) {
    chargeItem.definitionCanonical = chargeDefinitionReferences
  }

  return chargeItem
}

export function getTaskForAppointment(
  subject: R4.IPatient,
  appointmentRef: R4.IReference,
  serviceTypeCharge?: R4.IChargeItemDefinition,
  deductibles?: R4.IChargeItemDefinition[],
  additions?: R4.IChargeItemDefinition[],
  followUp?: boolean
): R4.ITask {
  const requestId: string = getUniqueTempId()
  const task: R4.ITask = {
    resourceType: 'Task',
    id: requestId,
    status: R4.TaskStatusKind._requested,
    //   enterer: getCurrentUserPractitionerRoleRef(),
    intent: R4.TaskIntentKind._order,
    priority: 'routine',
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '273510007',
          display: 'Health assessment questionnaire',
        },
      ],
    },
    focus: {
      reference: appointmentRef.reference,
    },
    for: {
      id: subject.id,
      reference: `Patient/${subject.id}`,
    },
    authoredOn: moment().toISOString(),
    owner: {
      reference: getCurrentUserUnitReference()?.reference,
    },
    requester: getCurrentUserPractitionerRoleRef(),
  }

  return task
}

export function getProvenanceForTask(
  subject: R4.IPatient,
  appointmentRef: R4.IReference,
  serviceTypeCharge?: R4.IChargeItemDefinition,
  deductibles?: R4.IChargeItemDefinition[],
  additions?: R4.IChargeItemDefinition[],
  followUp?: boolean
): R4.IProvenance {
  const provenance: R4.IProvenance = {
    resourceType: 'Provenance',
    target: [appointmentRef],
    occurredDateTime: moment().toISOString(),
    activity: {
      coding: [
        {
          system: 'hhttp://hl7.org/fhir/task-status',
          code: 'requested',
          display: 'Requested',
        },
      ],
    },
    agent: [
      {
        type: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
              code: 'enterer',
              display: 'Enterer',
            },
          ],
        },
        who: {
          reference: getCurrentUserPractitionerRoleRef()?.reference,
        },
      },
    ],
  }

  return provenance
}

export function getGroupedConditions(
  conditions: R4.ICondition[]
): GroupedCondition[] {
  const finalData: GroupedCondition[] = []
  let date: string = moment().toISOString()
  for (let i = 0; i < conditions.length; i++) {
    const ageData = conditions[i].onsetAge

    if (ageData) {
      const measure: string = ageData.code ?? ''
      if (measure === 'mo') {
        const monthVal = ageData.value ?? 0
        date = moment().subtract(monthVal, 'months').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      } else if (measure === 'a') {
        const yearVal = ageData.value ?? 0
        date = moment().subtract(yearVal, 'years').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      } else if (measure === 'wk') {
        const weekVal = ageData.value ?? 0
        date = moment().subtract(weekVal, 'weeks').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      } else {
        const daysVal = ageData.value ?? 0
        date = moment().subtract(daysVal, 'd').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      }
    } else {
      finalData.push({
        date: conditions[i].onsetDateTime ?? '',
        condition: conditions[i],
        checked: true,
        recordedDate: conditions[i].meta
          ? conditions[i].meta!.lastUpdated!
          : moment().toISOString(),
      })
    }
  }
  finalData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
  return finalData
}

export function getGroupedConditionsForHistory(
  conditions: R4.ICondition[],
  encounterId?: string
): GroupedCondition[] {
  const finalData: GroupedCondition[] = []
  let date: string = moment().toISOString()
  for (let i = 0; i < conditions.length; i++) {
    if (conditions[i].encounter) {
      const currentEncounter = conditions[i].encounter?.reference?.split('/')[1]

      if (currentEncounter !== encounterId) {
        const ageData = conditions[i].onsetAge

        if (ageData) {
          const measure: string = ageData.code ?? ''
          if (measure === 'mo') {
            const monthVal = ageData.value ?? 0
            date = moment().subtract(monthVal, 'months').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          } else if (measure === 'a') {
            const yearVal = ageData.value ?? 0
            date = moment().subtract(yearVal, 'years').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          } else if (measure === 'wk') {
            const weekVal = ageData.value ?? 0
            date = moment().subtract(weekVal, 'weeks').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          } else {
            const daysVal = ageData.value ?? 0
            date = moment().subtract(daysVal, 'd').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          }
        } else {
          finalData.push({
            date: conditions[i].onsetDateTime ?? '',
            recordedDate: conditions[i].meta
              ? conditions[i].meta!.lastUpdated!
              : moment().toISOString(),
            condition: conditions[i],
            checked: true,
          })
        }
      } else {
        const ageData = conditions[i].onsetAge

        if (ageData) {
          const measure: string = ageData.code ?? ''
          if (measure === 'mo') {
            const monthVal = ageData.value ?? 0
            date = moment().subtract(monthVal, 'months').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          } else if (measure === 'a') {
            const yearVal = ageData.value ?? 0
            date = moment().subtract(yearVal, 'years').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          } else if (measure === 'wk') {
            const weekVal = ageData.value ?? 0
            date = moment().subtract(weekVal, 'weeks').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          } else {
            const daysVal = ageData.value ?? 0
            date = moment().subtract(daysVal, 'd').toISOString()
            finalData.push({
              date,
              recordedDate: conditions[i].meta
                ? conditions[i].meta!.lastUpdated!
                : moment().toISOString(),
              condition: conditions[i],
              checked: true,
            })
          }
        } else {
          finalData.push({
            date: conditions[i].onsetDateTime ?? '',
            recordedDate: conditions[i].meta
              ? conditions[i].meta!.lastUpdated!
              : moment().toISOString(),
            condition: conditions[i],
            checked: true,
          })
        }
      }
    } else {
      const ageData = conditions[i].onsetAge

      if (ageData) {
        const measure: string = ageData.code ?? ''
        if (measure === 'mo') {
          const monthVal = ageData.value ?? 0
          date = moment().subtract(monthVal, 'months').toISOString()
          finalData.push({
            date,
            recordedDate: conditions[i].meta
              ? conditions[i].meta!.lastUpdated!
              : moment().toISOString(),
            condition: conditions[i],
            checked: true,
          })
        } else if (measure === 'a') {
          const yearVal = ageData.value ?? 0
          date = moment().subtract(yearVal, 'years').toISOString()
          finalData.push({
            date,
            recordedDate: conditions[i].meta
              ? conditions[i].meta!.lastUpdated!
              : moment().toISOString(),
            condition: conditions[i],
            checked: true,
          })
        } else if (measure === 'wk') {
          const weekVal = ageData.value ?? 0
          date = moment().subtract(weekVal, 'weeks').toISOString()
          finalData.push({
            date,
            recordedDate: conditions[i].meta
              ? conditions[i].meta!.lastUpdated!
              : moment().toISOString(),
            condition: conditions[i],
            checked: true,
          })
        } else {
          const daysVal = ageData.value ?? 0
          date = moment().subtract(daysVal, 'd').toISOString()
          finalData.push({
            date,
            recordedDate: conditions[i].meta
              ? conditions[i].meta!.lastUpdated!
              : moment().toISOString(),
            condition: conditions[i],
            checked: true,
          })
        }
      } else {
        finalData.push({
          date: conditions[i].onsetDateTime ?? '',
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
          condition: conditions[i],
          checked: true,
        })
      }
    }
  }
  finalData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
  console.log(finalData)
  return finalData
}

export function getGroupedSurgery(
  conditions: R4.IProcedure[]
): GroupedProceDure[] {
  const finalData: GroupedProceDure[] = []
  let date: string = moment().toISOString()
  for (let i = 0; i < conditions.length; i++) {
    const ageData = conditions[i].performedAge
    if (ageData) {
      const measure: string = ageData.code ?? ''
      if (measure === 'mo') {
        const monthVal = ageData.value ?? 0
        date = moment().subtract(monthVal, 'months').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
          checked: true,
        })
      } else if (measure === 'a') {
        const yearVal = ageData.value ?? 0
        date = moment().subtract(yearVal, 'years').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      } else if (measure === 'wk') {
        const weekVal = ageData.value ?? 0
        date = moment().subtract(weekVal, 'weeks').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      } else {
        const daysVal = ageData.value ?? 0
        date = moment().subtract(daysVal, 'd').toISOString()
        finalData.push({
          date,
          condition: conditions[i],
          checked: true,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
        })
      }
    } else {
      //   finalData.push({
      //     date: conditions[i].performedDateTime ?? '',
      //     condition: conditions[i],
      //     checked: true,
      //   })
    }
  }
  finalData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
  return finalData
}

export function getGroupedSurgeryWithDate(
  conditions: R4.IProcedure[]
): GroupedProceDure[] {
  const finalData: GroupedProceDure[] = []
  //   let date: string = moment().toISOString()
  for (let i = 0; i < conditions.length; i++) {
    const ageData = conditions[i].performedAge
    if (ageData) {
    } else {
      finalData.push({
        recordedDate: conditions[i].meta
          ? conditions[i].meta!.lastUpdated!
          : moment().toISOString(),
        date: conditions[i].performedDateTime ?? '',
        condition: conditions[i],
        checked: true,
      })
    }
  }
  finalData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
  return finalData
}

export function getGroupedAllergy(
  conditions: R4.IAllergyIntolerance[]
): GroupedAllergy[] {
  const finalData: GroupedAllergy[] = []
  let date: string = moment().toISOString()
  for (let i = 0; i < conditions.length; i++) {
    const ageData = conditions[i].onsetAge
    if (ageData) {
      const measure: string = ageData.code ?? ''
      if (measure === 'mo') {
        const monthVal = ageData.value ?? 0
        date = moment().subtract(monthVal, 'months').toISOString()
        finalData.push({
          date,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
          condition: conditions[i],
          checked: true,
        })
      } else if (measure === 'a') {
        const yearVal = ageData.value ?? 0
        date = moment().subtract(yearVal, 'years').toISOString()
        finalData.push({
          date,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
          condition: conditions[i],
          checked: true,
        })
      } else if (measure === 'wk') {
        const weekVal = ageData.value ?? 0
        date = moment().subtract(weekVal, 'weeks').toISOString()
        finalData.push({
          date,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
          condition: conditions[i],
          checked: true,
        })
      } else {
        const daysVal = ageData.value ?? 0
        date = moment().subtract(daysVal, 'd').toISOString()
        finalData.push({
          date,
          recordedDate: conditions[i].meta
            ? conditions[i].meta!.lastUpdated!
            : moment().toISOString(),
          condition: conditions[i],
          checked: true,
        })
      }
    } else {
      finalData.push({
        date: conditions[i].onsetDateTime ?? '',
        condition: conditions[i],
        checked: true,
        recordedDate: conditions[i].meta
          ? conditions[i].meta!.lastUpdated!
          : moment().toISOString(),
      })
    }
  }
  finalData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
  return finalData
}

export function getMedicationSearchDataGrouped(
  medicationResponse: any
): MedicationGroupedData[] {
  const finalData: MedicationGroupedData[] = []
  if (medicationResponse.hits.length > 0) {
    for (let j = 0; j < medicationResponse.hits.length; j++) {
      const medData: MedicationGroupedData = {
        medication: medicationResponse.hits[j].fhir_medication,
        name: medicationResponse.hits[j].fhir_medication.code.text ?? '',
        id: medicationResponse.hits[j].fhir_medication.id,
      }
      finalData.push(medData)
    }
  }

  return finalData
}

export async function updateProcedureDetail(
  procedure: R4.IProcedure,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IProcedure[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateDetails(
  prescriptionsBundle: R4.IBundle,
  appointmentId: string
) {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doCreateFHIRTransactionForUpdate(
    '',
    appointmentId,
    prescriptionsBundle
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (relatedFhirDecodeRes._tag === 'Right') {
    return true
  }
  return false
}

export async function updateAllergyDetails(
  procedure: R4.IAllergyIntolerance,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IAllergyIntolerance[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateMedicalConditions(
  procedure: R4.ICondition,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.ICondition[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateMedications(
  procedure: R4.IMedicationRequest,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IMedicationRequest[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateMedicationStatement(
  procedure: R4.IMedicationStatement,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IMedicationStatement[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateObs(
  procedure: R4.IObservation,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IObservation[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateObsForEdit(
  procedure: R4.IObservation,
  newObs: R4.IObservation,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IObservation[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }
    requestBundle.entry?.push({
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: newObs.resourceType,
      },
      resource: newObs,
    })

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateAddictions(
  bundleObject: R4.IBundle,
  appointmentId: string
) {
  let result = false
  try {
    result = await updateDetails(bundleObject, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateQuestion(question: R4.IQuestionnaire) {
  let result = false
  try {
    const procedureArray: R4.IQuestionnaire[] = []
    procedureArray.push(question)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInDefaultPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateDietTimingsForDoctor(dietTime: R4.IList) {
  let result = false
  try {
    const finalData = { ...dietTime }
    if (isOnlyUnitAdmin()) {
      const procedureArray: R4.IList[] = []
      procedureArray.push(finalData)
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: procedureArray.map((sv) => ({
          resource: sv,
          fullUrl: `${sv.resourceType}/${sv.id}`,
          request: {
            method: R4.Bundle_RequestMethodKind._put,
            url: `${sv.resourceType}/${sv.id}`,
          },
        })),
      }

      result = await updateDetailsInVendorPartition(requestBundle)
    } else if (finalData.source === undefined) {
      const oldProcedure: R4.IList = {
        resourceType: 'List',
        title: finalData.title,
        code: {
          coding: [
            {
              code: '272106006',
              system: 'http://snomed.info/sct',
              display: 'Times of day',
            },
          ],
        },
      }
      const practRef: R4.IReference = {
        reference: `Practitioner/${getCurrentUserPractitionerDetails().id}`,
      }
      oldProcedure.entry = finalData.entry
      oldProcedure.source = practRef
      const procedureArray: R4.IList[] = []
      procedureArray.push(oldProcedure)
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: procedureArray.map((sv) => ({
          resource: sv,
          fullUrl: `${sv.resourceType}/${sv.id}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: `${sv.resourceType}/${sv.id}`,
          },
        })),
      }
      result = await updateDetailsInVendorPartition(requestBundle)
    } else {
      const procedureArray: R4.IList[] = []
      procedureArray.push(finalData)
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: procedureArray.map((sv) => ({
          resource: sv,
          fullUrl: `${sv.resourceType}/${sv.id}`,
          request: {
            method: R4.Bundle_RequestMethodKind._put,
            url: `${sv.resourceType}/${sv.id}`,
          },
        })),
      }
      result = await updateDetailsInVendorPartition(requestBundle)
    }
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function addQuestion(question: R4.IQuestionnaire) {
  let result = false
  try {
    const procedureArray: R4.IQuestionnaire[] = []
    procedureArray.push(question)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInDefaultPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateDetailsInDefaultPartition(
  prescriptionsBundle: R4.IBundle
) {
  const fhirApi: FHIRDefaultApiClient = new FHIRDefaultApiClient()
  const response = await fhirApi.doCreateFHIRTransactionForUpdateDefault(
    '',
    prescriptionsBundle
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (relatedFhirDecodeRes._tag === 'Right') {
    return true
  }
  return false
}

export async function updateDetailsInVendorPartition(
  prescriptionsBundle: R4.IBundle
) {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doCreateFHIRTransaction(
    '',
    prescriptionsBundle
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (relatedFhirDecodeRes._tag === 'Right') {
    return true
  }
  return false
}

export async function updateDietTimings(prescriptionsBundle: R4.IBundle) {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doCreateFHIRTransactionForUpdateDefault(
    '',
    prescriptionsBundle
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (relatedFhirDecodeRes._tag === 'Right') {
    return true
  }
  return false
}

export function getEncounterFromBundle(
  responseSlots: R4.IBundle
): R4.IEncounter[] {
  const convertedAppointments: R4.IEncounter[] = []
  const appointments: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break
                case 'Encounter':
                  convertedAppointments.push(value.resource as R4.IEncounter)

                  break

                default:
                  break
              }
            }
          }
        })
      }
    }
  }
  return convertedAppointments
}

export function getEncounterFromBundleForList(
  responseSlots: R4.IBundle,
  currentEncounter?: R4.IEncounter
): R4.IEncounter[] {
  const convertedAppointments: R4.IEncounter[] = []
  const convertedAppointmentsFinal: R4.IEncounter[] = []
  const appointments: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break
                case 'Encounter':
                  convertedAppointments.push(value.resource as R4.IEncounter)

                  break

                default:
                  break
              }
            }
          }
        })
      }
    }
  }

  for (let i = 0; i < convertedAppointments.length; i++) {
    if (
      convertedAppointments[i].status &&
      convertedAppointments[i].status === 'in-progress'
    ) {
      if (currentEncounter) {
        if (convertedAppointments[i].id === currentEncounter.id) {
          //   convertedAppointmentsFinal.push(convertedAppointments[i])
        }
      } else {
        convertedAppointmentsFinal.push(convertedAppointments[i])
      }
    }
  }
  return convertedAppointmentsFinal
}

export function getAppointmentArrayFromBundle(
  responseSlots: R4.IBundle
): string[] {
  const convertedAppointments: R4.IAppointment[] = []
  const appointmentData: string[] = []
  const appointments: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  convertedAppointments.push(value.resource as R4.IAppointment)
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break

                case 'Encounter':
                  // convertedAppointments.push(value.resource as R4.IEncounter)

                  break

                default:
                  break
              }
            }
          }
        })
      }
    }
  }
  for (let i = 0; i < convertedAppointments.length; i++) {
    appointmentData.push(convertedAppointments[i].id ?? '')
  }
  return appointmentData
}

export function getExpandedAppointmentFromBundlePendingList(
  responseSlots: R4.IBundle,
  name?: string
): FhirPendingAppointmentDetails[] {
  const convertedAppointments: FhirPendingAppointmentDetails[] = []
  const appointments: any = {}
  const slots: any = {}
  const encounters: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const paymentReconilation: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Encounter':
                  encounters[
                    value.resource.appointment?.[0].reference?.split('/')[1] ??
                      ''
                  ] = value.resource as R4.IEncounter
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'PaymentReconciliation':
                  paymentReconilation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in appointments) {
          if (key) {
            const currentAppointment: R4.IAppointment = appointments[
              key
            ] as R4.IAppointment

            const practitionerId: string | undefined =
              currentAppointment?.participant
                ?.find((val) => val.actor?.reference?.includes('Practitioner/'))
                ?.actor?.reference?.split('/')[1]
            const practitionerRoleId: string | undefined =
              currentAppointment?.participant
                ?.find((val) =>
                  val.actor?.reference?.includes('PractitionerRole/')
                )
                ?.actor?.reference?.split('/')[1]

            const patientId: string | undefined =
              currentAppointment?.participant
                ?.find((val) => val.actor?.reference?.includes('Patient/'))
                ?.actor?.reference?.split('/')[1]

            const practitionerRoleData: R4.IPractitionerRole =
              practitionerRoles[practitionerRoleId ?? '']
            const unitOrg = getCurrentUserUnitDetails()
            if (practitionerRoleData) {
              const orgId = practitionerRoleData.organization?.id ?? ''

              if (unitOrg.id === orgId) {
                if (
                  encounters[(appointments[key] as R4.IAppointment).id ?? '']
                ) {
                  convertedAppointments.push({
                    start: (appointments[key] as R4.IAppointment).start ?? '',
                    end: (appointments[key] as R4.IAppointment).end ?? '',
                    appointment: appointments[key],

                    patient: patients[patientId ?? ''],
                    encounter:
                      encounters[
                        (appointments[key] as R4.IAppointment).id ?? ''
                      ],
                    practitionerDetail: {
                      practitioner: practitioners[practitionerId ?? ''],
                      practitionerRole:
                        practitionerRoles[practitionerRoleId ?? ''],
                    },
                  })
                }
              }
            }
          }
        }
      }
    }
  }
  return convertedAppointments
}

export async function addDietTimings(question: R4.IList) {
  let result = false
  try {
    const procedureArray: R4.IList[] = []
    procedureArray.push(question)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDietTimings(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function downloadPrescription(question: R4.IList) {
  let result = false
  try {
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const procedureArray: R4.IList[] = []
    procedureArray.push(question)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDietTimings(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export function getMedicationData(response: any): GroupedWelloMedication[] {
  const welloMedicationData: WelloMedication[] = []
  let finalGroupedData: GroupedWelloMedication[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.current_medication) {
          const medicationsData =
            response.soap_summary.subjective.history.current_medication
              ._resources ?? []
          if (medicationsData && medicationsData.length > 0) {
            const medReq: R4.IMedicationRequest[] = []
            const medStatement: R4.IMedicationStatement[] = []
            for (let i = 0; i < medicationsData.length; i++) {
              if (medicationsData[i].resourceType === 'MedicationRequest') {
                medReq.push(medicationsData[i])
              }
              if (medicationsData[i].resourceType === 'MedicationStatement') {
                medStatement.push(medicationsData[i])
              }
            }
            for (let i = 0; i < medStatement.length; i++) {
              const statement = getWelloMedicationFromMedicationStatement(
                medStatement[i]
              )
              welloMedicationData.push(statement)
            }
            for (let i = 0; i < medReq.length; i++) {
              const req = getWelloMedicationFromMedicationRequestForHistory(
                medReq[i]
              )
              welloMedicationData.push(req)
            }
          }
        }
      }
    }
  }
  if (welloMedicationData.length > 0) {
    finalGroupedData = _.chain(welloMedicationData)
      // Group the elements of Array based on `color` property
      .groupBy('date')
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ date: key, medications: value }))
      .value()

    finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))
  }
  return finalGroupedData
}

export function getHistoryDataForReferral(response: any): GroupedOccupation[] {
  let welloOccupation: GroupedOccupation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.occupation) {
          const occupationData =
            response.soap_summary.subjective.history.occupation._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.IObservation[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'Observation') {
                occReq.push(occupationData[i])
              }
            }
            occupationData.sort((a: any, b: any) =>
              (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
                ? -1
                : (a.issued ?? '') < (b.issued ?? '')
                ? 1
                : 0
            )
            welloOccupation = obsHelper(occupationData)
          }
        }
      }
    }
  }
  return welloOccupation
}

export function obsHelper(
  observations: R4.IObservation[]
): GroupedOccupation[] {
  let groupedOccupationData: GroupedOccupation[] = []

  for (let i = 0; i < observations.length; i++) {
    if (
      observations[i].issued &&
      observations[i].status &&
      observations[i].status === 'final'
    ) {
      if (groupedOccupationData.length > 0) {
        for (let j = 0; j < groupedOccupationData.length; j++) {
          if (
            moment(observations[i].issued).format('YYYY-MM-DD') ===
            moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
          ) {
            groupedOccupationData[j].occupation.push(observations[i])
          } else {
            groupedOccupationData.push({
              date: observations[i].issued ?? '',
              occupation: [observations[i]],
            })
            break
          }
        }
      } else {
        groupedOccupationData.push({
          date: observations[i].issued ?? '',
          occupation: [observations[i]],
        })
      }
    }
  }
  groupedOccupationData = groupedOccupationData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          moment(t.date).format('YYYY-MM-DD') ===
          moment(value.date).format('YYYY-MM-DD')
      )
  )

  const finalGroupedOccupationData: GroupedOccupation[] = []
  for (let i = 0; i < groupedOccupationData.length; i++) {
    finalGroupedOccupationData.push({
      date: groupedOccupationData[i].date,
      occupation: groupedOccupationData[i].occupation.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              getDefaultCodeOfSystemFromCodableConcept(t.code) ===
              getDefaultCodeOfSystemFromCodableConcept(value.code)
          )
      ),
    })
  }
  return finalGroupedOccupationData
}

export function getProcedureDataForReferral(response: any): GroupedProceDure[] {
  let procedure: GroupedProceDure[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.surgeries) {
          const occupationData =
            response.soap_summary.subjective.history.surgeries._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.IProcedure[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'Procedure') {
                occReq.push(occupationData[i])
              }
            }
            procedure = getGroupedSurgery(occReq)
          }
        }
      }
    }
  }
  return procedure
}

export function getAllergiesDataForReferral(response: any): GroupedAllergy[] {
  let allergies: GroupedAllergy[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.allergies) {
          const occupationData =
            response.soap_summary.subjective.history.allergies._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.IAllergyIntolerance[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'AllergyIntolerance') {
                occReq.push(occupationData[i])
              }
            }
            allergies = getGroupedAllergy(occReq)
          }
        }
      }
    }
  }
  return allergies
}

export function getConditionDataForReferral(response: any): GroupedCondition[] {
  let allergies: GroupedCondition[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.conditions) {
          const occupationData =
            response.soap_summary.subjective.history.conditions._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.ICondition[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'Condition') {
                occReq.push(occupationData[i])
              }
            }
            allergies = getGroupedConditions(occReq)
          }
        }
      }
    }
  }
  return allergies
}

export function getHistoryHabitForReferral(response: any): GroupedOccupation[] {
  let welloOccupation: GroupedOccupation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.habits) {
          const occupationData =
            response.soap_summary.subjective.history.habits._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.IObservation[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'Observation') {
                occReq.push(occupationData[i])
              }
            }
            occupationData.sort((a: any, b: any) =>
              (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
                ? -1
                : (a.issued ?? '') < (b.issued ?? '')
                ? 1
                : 0
            )
            welloOccupation = obsHelper(occupationData)
          }
        }
      }
    }
  }
  return welloOccupation
}

export function getHistoryAddictionsForReferral(
  response: any
): GroupedOccupation[] {
  let welloOccupation: GroupedOccupation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.addictions) {
          const occupationData =
            response.soap_summary.subjective.history.addictions._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.IObservation[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'Observation') {
                occReq.push(occupationData[i])
              }
            }
            occupationData.sort((a: any, b: any) =>
              (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
                ? -1
                : (a.issued ?? '') < (b.issued ?? '')
                ? 1
                : 0
            )
            welloOccupation = obsHelper(occupationData)
          }
        }
      }
    }
  }
  return welloOccupation
}

export function getFamilyConditionForReferral(
  response: any
): GroupedFamilyHistory[] {
  let procedure: GroupedFamilyHistory[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.family_history) {
          const occupationData =
            response.soap_summary.subjective.history.family_history
              ._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const occReq: R4.IFamilyMemberHistory[] = []

            for (let i = 0; i < occupationData.length; i++) {
              if (occupationData[i].resourceType === 'FamilyMemberHistory') {
                occReq.push(occupationData[i])
              }
            }
            procedure = familyConditionHelper(occReq)
          }
        }
      }
    }
  }
  return procedure
}

export function familyConditionHelper(
  history: R4.IFamilyMemberHistory[]
): GroupedFamilyHistory[] {
  let groupedData: GroupedFamilyHistory[] = []
  for (let i = 0; i < history.length; i++) {
    if (history[i].meta && history[i].meta?.lastUpdated) {
      if (groupedData.length > 0) {
        for (let j = 0; j < groupedData.length; j++) {
          if (
            moment(history[i].meta?.lastUpdated).format('YYYY-MM-DD') ===
            moment(groupedData[j].date).format('YYYY-MM-DD')
          ) {
            groupedData[j].conditions.push(history[i])
          } else {
            groupedData.push({
              date: history[i].date ?? '',
              conditions: [history[i]],
            })
            break
          }
        }
      } else {
        groupedData.push({
          date: history[i].date ?? '',
          conditions: [history[i]],
        })
      }
    }
  }
  groupedData = groupedData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          moment(t.date).format('YYYY-MM-DD') ===
          moment(value.date).format('YYYY-MM-DD')
      )
  )

  const finalGroupedOccupationData: GroupedFamilyHistory[] = []
  for (let i = 0; i < groupedData.length; i++) {
    finalGroupedOccupationData.push({
      date: groupedData[i].date,
      conditions: groupedData[i].conditions.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              getCommaSeparatedCondition(t.condition ?? []) ===
              getCommaSeparatedCondition(value.condition ?? [])
          )
      ),
    })
  }
  return finalGroupedOccupationData
}

export function getGeneralExaminationForReferral(
  response: any
): GroupedOccupation[] {
  let welloOccupation: GroupedOccupation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.general_examination) {
        const occupationData =
          response.soap_summary.objective.general_examination._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          welloOccupation = obsHelper(occupationData)
        }
      }
    }
  }
  return welloOccupation
}

export function getVitalsForReferral(response: any): DateWiseVitals[] {
  let dateWise: DateWiseVitals[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.vitals) {
        const occupationData =
          response.soap_summary.objective.vitals._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }

          occReq.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          const vitalLisData: VitalsData[] =
            getObserVationFfromEncounterForDateWiseForHistory(occupationData)
          dateWise = getDateWiseVitals(vitalLisData)

          dateWise.sort((a, b) => moment(b.date).diff(a.date))
        }
      }
    }
  }
  return dateWise
}

export function getLabResultsForReferral(
  response: any
): GroupedLabResultsByName[] {
  let labResults: GroupedLabResultsByName[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsWithName(occupationData)
        }
      }
    }
  }
  return labResults
}

export function getPanel1ForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanel1Data(occupationData),
            'panel1'
          )
        }
      }
    }
  }
  return labResults
}

export function getPanel2ForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanel2Data(occupationData),
            'panel2'
          )
        }
      }
    }
  }
  return labResults
}

export function getPanel3ForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanel3Data(occupationData),
            'panel3'
          )
        }
      }
    }
  }
  return labResults
}

export function getLftForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(getPaneLftData(occupationData), 'lft')
        }
      }
    }
  }
  return labResults
}

export function getRftForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(getPaneRftData(occupationData), 'rft')
        }
      }
    }
  }
  return labResults
}

export function getHomaForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanelHomaData(occupationData),
            'Homa'
          )
        }
      }
    }
  }
  return labResults
}

export function getDexaForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanelDexaData(occupationData),
            'Dexa'
          )
        }
      }
    }
  }
  return labResults
}

export function getSerumForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanelSerumLytesData(occupationData),
            'Serum Lytes'
          )
        }
      }
    }
  }
  return labResults
}

export function getUrinePanelForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanel4Data(occupationData),
            'urine'
          )
        }
      }
    }
  }
  return labResults
}

export function getTftPanelForReferral(response: any): GroupedLabResults[] {
  let labResults: GroupedLabResults[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.lab_results) {
        const occupationData =
          response.soap_summary.objective.lab_results._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occupationData.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          labResults = getLabResultsPanel(
            getPanelTftData(occupationData),
            'TFT'
          )
        }
      }
    }
  }
  return labResults
}

export function getArithmeticMeasurements(response: any): R4.IObservation[] {
  let welloOccupation: R4.IObservation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.objective) {
      if (response.soap_summary.objective.anthropometric_measurements) {
        const occupationData =
          response.soap_summary.objective.anthropometric_measurements
            ._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occReq.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          welloOccupation = occReq
        }
      }
    }
  }
  return welloOccupation
}

export function getRootCauseObs(response: any): R4.IObservation[] {
  let welloOccupation: R4.IObservation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.assessment) {
      if (response.soap_summary.assessment.root_cause) {
        const occupationData =
          response.soap_summary.assessment.root_cause._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occReq.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          welloOccupation = occReq
        }
      }
    }
  }
  return welloOccupation
}

export function getSkinCauseObs(response: any): R4.IObservation[] {
  let welloOccupation: R4.IObservation[] = []
  if (response.soap_summary) {
    if (response.soap_summary.assessment) {
      if (response.soap_summary.assessment.skin_related) {
        const occupationData =
          response.soap_summary.assessment.skin_related._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IObservation[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Observation') {
              occReq.push(occupationData[i])
            }
          }
          occReq.sort((a: any, b: any) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          welloOccupation = occReq
        }
      }
    }
  }
  return welloOccupation
}

export function getAssessmentCondition(response: any): GroupedConditionData[] {
  let welloOccupation: GroupedConditionData[] = []
  if (response.soap_summary) {
    if (response.soap_summary.assessment) {
      if (response.soap_summary.assessment.condition) {
        const occupationData =
          response.soap_summary.assessment.condition._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.ICondition[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'Condition') {
              occReq.push(occupationData[i])
            }
          }
          const finalData = getGroupedConditions(occupationData)
          const finalGroupedData: GroupedConditionData[] = []
          if (finalData.length > 0) {
            for (let i = 0; i < finalData.length; i++) {
              if (finalGroupedData.length === 0) {
                if (finalData[i].date) {
                  finalGroupedData.push({
                    date: finalData[i].date!,
                    condition: [finalData[i].condition],
                  })
                }
              } else {
                const indexData = finalGroupedData.findIndex(
                  (x) =>
                    moment(x.date).format('YYYY-MM-DD') ===
                    moment(finalData[i].date).format('YYYY-MM-DD')
                )
                if (indexData > -1) {
                  finalGroupedData[indexData].condition.push(
                    finalData[i].condition
                  )
                } else {
                  finalGroupedData.push({
                    date: finalData[i].date!,
                    condition: [finalData[i].condition],
                  })
                }
              }

              //   finalGroupedData[indexData].condition.push(finalData[i].condition)
            }
          }

          finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))
          welloOccupation = finalGroupedData
        }
      }
    }
  }
  return welloOccupation
}

export function getPatientSummary(response: any): R4.IClinicalImpression[] {
  let welloOccupation: R4.IClinicalImpression[] = []
  if (response.soap_summary) {
    if (response.soap_summary.assessment) {
      if (response.soap_summary.assessment.patient_summary) {
        const occupationData: R4.IClinicalImpression[] =
          response.soap_summary.assessment.patient_summary._resources ?? []
        const category: R4.IClinicalImpression[] = []
        if (occupationData && occupationData.length > 0) {
          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'ClinicalImpression') {
              const summary = occupationData[i].summary ?? ''
              if (summary.length > 0) {
                category.push(occupationData[i])
              }
            }
          }

          welloOccupation = category
        }
      }
    }
  }

  return welloOccupation
}

export function getReferralDetails(
  response: any
): R4.IServiceRequest | undefined {
  if (response.referral_source) {
    if (response.referral_source.service_request) {
      return response.referral_source.service_request as R4.IServiceRequest
    }
  }
  return undefined
}

export function getDoctorDetails(response: any): R4.IPractitioner | undefined {
  if (response.referral_source) {
    if (response.referral_source.practitioner) {
      return response.referral_source.practitioner as R4.IPractitioner
    }
  }
  return undefined
}
export function getUnitDetails(response: any): R4.IOrganization | undefined {
  if (response.referral_source) {
    if (response.referral_source.unit) {
      return response.referral_source.unit as R4.IOrganization
    }
  }
  return undefined
}
export function getReferralLabTests(response: any): R4.IServiceRequest[] {
  let welloOccupation: R4.IServiceRequest[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.lab_referral) {
        const occupationData =
          response.soap_summary.plan.lab_referral._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IServiceRequest[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'ServiceRequest') {
              occReq.push(occupationData[i])
            }
          }
          welloOccupation = occReq
        }
      }
    }
  }
  return welloOccupation
}

export function getReferralImaging(response: any): R4.IServiceRequest[] {
  let welloOccupation: R4.IServiceRequest[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.imaging_studies) {
        const occupationData =
          response.soap_summary.plan.imaging_studies._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IServiceRequest[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'ServiceRequest') {
              occReq.push(occupationData[i])
            }
          }
          welloOccupation = occReq
        }
      }
    }
  }
  return welloOccupation
}

export function getKriyaDetails(response: any): KriyaDetail[] {
  let welloOccupation: KriyaDetail[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.kriya) {
        const occupationData = response.soap_summary.plan.kriya._resources ?? []
        if (occupationData && occupationData.length > 0) {
          const occReq: R4.IServiceRequest[] = []

          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].resourceType === 'ServiceRequest') {
              occReq.push(occupationData[i])
            }
          }
          if (occReq && occReq.length > 0) {
            welloOccupation = occReq.map((task) => {
              const res: KriyaDetail = {
                recordedDate: task.authoredOn ?? '',
                kriya: task.code?.coding?.[0]!,
                medications: task.orderDetail?.map((e) => e.coding?.[0]!) ?? [],
                remarks: getNotesString(task.note),
                rawKriyaDetail: task,
                priority: task.priority,
              }

              return res
            })
          }
        }
      }
    }
  }

  return welloOccupation
}

export function getInstructionList(response: any): R4.ICommunicationRequest[] {
  let welloOccupation: R4.ICommunicationRequest[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.instructions) {
        const occupationData: R4.ICommunicationRequest[] =
          response.soap_summary.plan.instructions._resources ?? []
        const finalData: R4.ICommunicationRequest[] = []
        if (occupationData && occupationData.length > 0) {
          for (let i = 0; i < occupationData.length; i++) {
            if (occupationData[i].medium === undefined) {
              finalData.push(occupationData[i])
            }
          }

          welloOccupation = finalData
        }
      }
    }
  }
  return welloOccupation
}

export function getAAharaList(response: any): PathyaApathyaDetails[] {
  let welloOccupation: PathyaApathyaDetails[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.ahara) {
        const taskObject: R4.ITask[] =
          response.soap_summary.plan.ahara._resources ?? []
        if (taskObject && taskObject.length > 0) {
          if (taskObject && taskObject.length > 0) {
            welloOccupation = taskObject.map((task) => {
              const res: PathyaApathyaDetails = {
                recordedDate: task.authoredOn ?? '',
              }
              task.input?.forEach((input: any) => {
                if (input.type?.coding && input.type?.coding.length > 0) {
                  if (input.type?.coding[0].code === 'ITA-8.1.3') {
                    res.pathyaText = input.valueString ?? ''
                  } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
                    res.apathyaText = input.valueString ?? ''
                  }
                }
              })
              return res
            })
          }
        }
      }
    }
  }
  return welloOccupation
}

export function getViharaList(response: any): PathyaApathyaDetails[] {
  let welloOccupation: PathyaApathyaDetails[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.vihara) {
        const taskObject: R4.ITask[] =
          response.soap_summary.plan.vihara._resources ?? []
        if (taskObject && taskObject.length > 0) {
          if (taskObject && taskObject.length > 0) {
            welloOccupation = taskObject.map((task) => {
              const res: PathyaApathyaDetails = {
                recordedDate: task.authoredOn ?? '',
              }
              task.input?.forEach((input: any) => {
                if (input.type?.coding && input.type?.coding.length > 0) {
                  if (input.type?.coding[0].code === 'ITA-8.1.3') {
                    res.pathyaText = input.valueString ?? ''
                  } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
                    res.apathyaText = input.valueString ?? ''
                  }
                }
              })
              return res
            })
          }
        }
      }
    }
  }
  return welloOccupation
}

export function getDietChart(response: any): Diet[] {
  let welloOccupation: Diet[] = []
  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.diet) {
        const taskObject: R4.INutritionOrder[] =
          response.soap_summary.plan.diet._resources ?? []
        const finalData: R4.INutritionOrder[] = []
        if (taskObject && taskObject.length > 0) {
          for (let i = 0; i < taskObject.length; i++) {
            if (taskObject[i].intent === 'plan') {
              finalData.push(taskObject[i])
            }
          }
          const finalDate = moment.utc(getEndDate(taskObject)).local()
          const start = moment.utc(getStartDate(taskObject)).local()

          welloOccupation = getDietPlanFromOrder(
            taskObject,
            moment(start).format('YYYY-MM-DD'),
            moment(finalDate).format('YYYY-MM-DD')
          )
        }
      }
    }
  }
  console.log(welloOccupation)
  return welloOccupation
}

export function getDietPlanFromOrder(
  responseBundle: R4.INutritionOrder[],
  start: string,
  end: string
): Diet[] {
  const planData: Diet[] = []

  const dateWiseOrders: DateWiseNutritionOrders[] = []

  try {
    responseBundle.forEach((item) => {
      const dateDet = moment(item.oralDiet?.schedule![0].event![0]).format(
        'YYYY-MM-DD'
      )
      const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
      if (index < 0) {
        dateWiseOrders.push({
          date: dateDet,
          nutritionOrders: [item],
        })
      } else {
        dateWiseOrders[index].nutritionOrders.push(item)
      }
    })

    let currentDateData: Date = moment(start).toDate()
    let endDateData: Date = moment(end).toDate()
    console.log('adding date wise orders')
    do {
      const index = dateWiseOrders.findIndex(
        // eslint-disable-next-line no-loop-func
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        (e) => e.date === moment(currentDateData).format('YYYY-MM-DD')
      )

      const ref: R4.IReference = {
        reference: `Patient/0`,
      }

      if (index < 0) {
      } else {
        planData.push({
          date: moment(currentDateData).format('YYYY-MM-DD'),
          eight: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '830AM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'MORN',
              display: 'Breakfast',
            }
          ),
          five: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '5PM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'EVE',
              display: 'Evening Snack',
            }
          ),
          id: `${moment(currentDateData).format('YYYY-MM-DD')}id`,
          seven: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '7PM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'NIGHT',
              display: 'Dinner',
            }
          ),
          six: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '630AM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'MORN.early',
              display: 'Early Morning',
            }
          ),
          twelve: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '12PM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'MORN.late',
              display: 'Mid Morning',
            }
          ),
          two: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '2PM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'NOON',
              display: 'Lunch',
            }
          ),
          nine: getNutritionOrdersForSepcificTimeForOPD(
            dateWiseOrders[index].nutritionOrders,
            '10PM',
            '',
            ref,
            currentDateData,
            {
              system: 'http://hl7.org/fhir/event-timing',
              code: 'PCV',
              display: 'Bed Time',
            }
          ),
        })
      }

      const newDate = moment(currentDateData).add(1, 'days').toDate()
      currentDateData = newDate
      if (moment().isAfter(moment())) {
        const newEndDate = moment().local().endOf('day').toDate()
        endDateData = newEndDate
      }
    } while (currentDateData <= endDateData)
  } catch (error) {
    console.error(error)
  }

  return planData
}

export function getChiefComplaintData(response: any): ChiefComplaintsData[] {
  let allergies: ChiefComplaintsData[] = []
  const mainCondition: R4.ICondition[] = []
  const associatedCondition: R4.ICondition[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.chief_complaints) {
        if (response.soap_summary.subjective.chief_complaints) {
          const occupationData =
            response.soap_summary.subjective.chief_complaints._resources ?? []
          if (occupationData && occupationData.length > 0) {
            for (let i = 0; i < occupationData.length; i++) {
              const cat: string =
                occupationData[i].category &&
                occupationData[i].category[0].coding
                  ? occupationData[i].category[0].coding[0].code ?? ''
                  : ''
              if (cat !== 'associated-complaint') {
                mainCondition.push(occupationData[i])
              } else {
                associatedCondition.push(occupationData[i])
              }
            }
            allergies = updateChiefComplaint(mainCondition, associatedCondition)
          }
        }
      }
    }
  }
  return allergies
}

const updateChiefComplaint = (
  condition: R4.ICondition[],
  associated: R4.ICondition[]
): ChiefComplaintsData[] => {
  const chiefDataArray: ChiefComplaintsData[] = []

  for (let i = 0; i < condition.length; i++) {
    const assichiefDataArray: AssociatedComplaintData[] = []
    const locationArr: LocationData[] = []
    const evidenceArr: ChiefComplaintData[] = []
    const conditionData: R4.ICondition = condition[i]
    let locationData: R4.ICoding[] = []
    let evidenceData: R4.ICoding[] = []

    if (conditionData.bodySite?.length && conditionData.bodySite?.length > 0) {
      const location: R4.ICoding[] = conditionData.bodySite?.map(
        (val) => val.coding?.[0] ?? {}
      )
      locationData = location
      logger.info('inside use updateChiefComplaint', location)
      //   setLocations(location)
    }

    if (conditionData.evidence && conditionData.evidence.length > 0) {
      if (
        conditionData.evidence[0].code &&
        conditionData.evidence[0].code.length > 0
      ) {
        const eviArr: R4.ICoding[] = []
        for (let m = 0; m < conditionData.evidence.length; m++) {
          const evi: R4.ICoding[] | undefined = conditionData.evidence[
            m
          ].code?.map((val) => val.coding?.[0] ?? {})

          if (evi) {
            eviArr.push(evi[0])
          }

          evidenceData = eviArr
        }
      }
    }

    if (associated.length > 0) {
      const filteredData = associated.filter(
        (d) =>
          getValueRefValueExtensionsOfUrl(
            d.extension,
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext'
          )?.split('/')[1]! === conditionData.id!
      )

      if (filteredData.length > 0) {
        for (let j = 0; j < filteredData.length; j++) {
          const conditionDataAssociated: R4.ICondition = filteredData[j]
          if (conditionDataAssociated.id !== undefined) {
            const additional: AssociatedComplaintData = {
              id: 0,
              typeData: conditionDataAssociated.onsetAge
                ? getUnitAsStringForVal(
                    conditionDataAssociated.onsetAge.unit ?? ''
                  )
                : conditionDataAssociated.onsetDateTime
                ? 'custom'
                : '',
              cui: conditionDataAssociated.code?.coding?.[0],
              preselectedAssociated:
                conditionDataAssociated.code as ChiefComplaintTerminology,
              days: conditionDataAssociated.onsetAge
                ? getUnitAsString(
                    conditionDataAssociated.onsetAge.unit ?? ''
                  ) === 'Days'
                  ? conditionDataAssociated.onsetAge.value ?? 0
                  : 0
                : 0,
              weeks: conditionDataAssociated.onsetAge
                ? getUnitAsString(
                    conditionDataAssociated.onsetAge.unit ?? ''
                  ) === 'Weeks'
                  ? conditionDataAssociated.onsetAge.value ?? 0
                  : 0
                : 0,
              months: conditionDataAssociated.onsetAge
                ? getUnitAsString(
                    conditionDataAssociated.onsetAge.unit ?? ''
                  ) === 'Months'
                  ? conditionDataAssociated.onsetAge.value ?? 0
                  : 0
                : 0,
              year: conditionDataAssociated.onsetAge
                ? getUnitAsString(
                    conditionDataAssociated.onsetAge.unit ?? ''
                  ) === 'Years'
                  ? conditionDataAssociated.onsetAge.value ?? 0
                  : 0
                : 0,
              date: conditionDataAssociated.onsetDateTime
                ? moment(conditionDataAssociated.onsetDateTime).toDate()
                : new Date(),
              preselectedId: conditionDataAssociated.id ?? '',
              patientWording: conditionDataAssociated.note
                ? hasNotesForChiefForPatientText(
                    conditionDataAssociated.note!
                  ).trim()
                : '',
              anyOther: false,
              anyOtherData: '',
              checked: false,
            }

            assichiefDataArray.push(additional)
          }
        }
      }
    }
    if (evidenceData.length > 0) {
      for (let j = 0; j < evidenceData.length; j++) {
        const evi = {
          cui: evidenceData[j].code ?? '',

          type: '',
          icd: titleCase(evidenceData[j].display ?? ''),
          synonyms: titleCase(evidenceData[j].display ?? ''),
        }
        evidenceArr.push(evi)
      }
    }

    if (locationData.length > 0) {
      for (let j = 0; j < locationData.length; j++) {
        const loc = {
          cui: '',
          snomed_ct: '',
          display: locationData[j].display ?? '',
        }
        locationArr.push(loc)
      }
    }

    // if (conditionData.meta) {
    //   setLastUpdated(
    //     moment(conditionData.meta.lastUpdated ?? '').format('Do MMMM, YYYY')
    //   )
    // }
    const cat: string =
      conditionData.category && conditionData.category[0].coding
        ? conditionData.category[0].coding[0].code ?? ''
        : ''

    if (cat !== 'associated-complaint') {
      chiefDataArray.push({
        preselectedComplaints: conditionData.code! as ChiefComplaintTerminology,
        preselectedSymptoms:
          conditionData.evidence && conditionData.evidence.length > 0
            ? conditionData.evidence[0].code!
            : [],
        preSelectedLocation: locationArr,
        collapsed: conditionData.meta
          ? moment(
              moment(conditionData.meta.lastUpdated ?? '').format('YYYY-MM-DD')
            ).isSame(moment().format('YYYY-MM-DD'))
          : false,
        preSelectedDuration: {
          cui:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? ''.length > 0
              ? getCodeValueForChiefComplaints(
                  conditionData.extension,
                  'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
                ) ?? ''
              : '',
          snomed_ct: 'http://unitsofmeasure.org',
          display:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '',
          label:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '',
        },
        id: conditionData.id ?? '',
        selectedCondition: conditionData.code! as ChiefComplaintTerminology,
        selectedSeverity: conditionData.severity
          ? conditionData.severity.coding?.[0]
          : undefined,
        selectedLocation: locationData,
        duration:
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
          ) ?? '',
        character: getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
        )!,
        alleviating: getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
        )!,
        radiation: getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
        )!,
        temporal:
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
          ) ?? '',
        lastUpdated: conditionData.meta
          ? moment(conditionData.meta.lastUpdated ?? '').format('Do MMMM, YYYY')
          : '',
        selectedDate: conditionData.onsetDateTime
          ? moment(conditionData.onsetDateTime).toDate()
          : null,
        itemNumber: i + 1,
        notes: hasNotesForChiefForNote(conditionData.note!),
        patientFeeling: hasNotesForChiefForPatient(conditionData.note!),
        category:
          conditionData.category && conditionData.category[0].coding
            ? conditionData.category[0].coding[0].code ?? ''
            : '',
        evidenceData:
          conditionData.evidence && conditionData.evidence.length > 0
            ? conditionData.evidence[0].code!
            : [],
        selectedEvidence: evidenceData,
        preselectedVal: conditionData.onsetAge
          ? conditionData.onsetAge.value ?? 0
          : 0,
        preSelectedUnitType: conditionData.onsetAge
          ? getUnitAsString(conditionData.onsetAge.unit ?? '')
          : conditionData.onsetDateTime
          ? 'custom'
          : '',
        unitValForDays: conditionData.onsetAge
          ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Days'
            ? conditionData.onsetAge.value ?? 0
            : 0
          : 0,
        unitValForMonth: conditionData.onsetAge
          ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Months'
            ? conditionData.onsetAge.value ?? 0
            : 0
          : 0,
        unitValForYear: conditionData.onsetAge
          ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Years'
            ? conditionData.onsetAge.value ?? 0
            : 0
          : 0,
        unitValForWeek: conditionData.onsetAge
          ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Weeks'
            ? conditionData.onsetAge.value ?? 0
            : 0
          : 0,
        unitTypeDays: conditionData.onsetAge
          ? getUnitAsStringForVal(conditionData.onsetAge.unit ?? '')
          : conditionData.onsetDateTime
          ? 'custom'
          : '',
        associatedCondition: assichiefDataArray,
        painChecked:
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
          )!.length > 0 ||
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!.length > 0 ||
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!.length > 0 ||
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!.length > 0 ||
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
          )!.length > 0 ||
          evidenceArr.length > 0,
        patientWording: conditionData.note
          ? hasNotesForChiefForPatientText(conditionData.note!).trim()
          : '',
        associatedChecked: assichiefDataArray.length > 0,
        painDuration: parseInt(
          getStringValueExtensionsOfUrlForDurationVal(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
          ) ?? '1',
          10
        ),
        notesData: hasNotesForChiefForNote(conditionData.note!)
          ? hasNotesForChiefForNote(conditionData.note!)?.text ?? ''
          : '',
      })
    }
  }
  return chiefDataArray
}

export function getPlanPageMedication(response: any): WelloMedication[] {
  const welloMedicationData: WelloMedication[] = []

  if (response.soap_summary) {
    if (response.soap_summary.plan) {
      if (response.soap_summary.plan.medication) {
        const medicationsData =
          response.soap_summary.subjective.history.current_medication
            ._resources ?? []
        if (medicationsData && medicationsData.length > 0) {
          const medReq: R4.IMedicationRequest[] = []

          for (let i = 0; i < medicationsData.length; i++) {
            if (medicationsData[i].resourceType === 'MedicationRequest') {
              medReq.push(medicationsData[i])
            }
          }

          for (let i = 0; i < medReq.length; i++) {
            const req = getWelloMedicationFromMedicationRequestForHistory(
              medReq[i]
            )
            welloMedicationData.push(req)
          }
        }
      }
    }
  }

  return welloMedicationData
}

export function getConditionList(
  leftCoding?: Conditions[],
  rightCodings?: Conditions[],
  coding?: R4.ICoding
): string {
  const welloMedicationData: string[] = []

  if (leftCoding) {
    for (let i = 0; i < leftCoding.length; i++) {
      if (leftCoding[i].checked) {
        welloMedicationData.push(leftCoding[i].data.display ?? '')
      }
    }
  }

  if (rightCodings) {
    for (let i = 0; i < rightCodings.length; i++) {
      if (rightCodings[i].checked) {
        welloMedicationData.push(rightCodings[i].data.display ?? '')
      }
    }
  }

  if (coding) {
    welloMedicationData.push(coding.display ?? '')
  }

  return welloMedicationData.join(', ')
}

export function getDietTimeList(dietTime: DietTiming[]): R4.ICoding[] {
  console.log(dietTime)
  const timing: R4.ICoding[] = []
  for (let i = 0; i < dietTime.length; i++) {
    if (dietTime[i].active && dietTime[i].dietTime.length > 0) {
      if (dietTime[i].time) {
        const timeCoding = dietTime[i].time
        if (timeCoding) {
          timing.push({
            system: timeCoding.system,
            code: timeCoding.code,
            display: dietTime[i].dietTime,
          })
        }
      }
    }
  }
  if (timing.length > 0) {
    timing.push({
      system: 'http://hl7.org/fhir/event-timing',
      code: 'Any',
      display: 'Any',
    })
  }
  return timing
}

export function getAppointmentList(
  list: FhirAppointmentDetail[],
  status: string
): FhirAppointmentDetail[] {
  const appointmentList: FhirAppointmentDetail[] = []

  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      if (status.includes('booked')) {
        if (
          list[i].appointment.status &&
          list[i].appointment.status === 'booked'
        ) {
          appointmentList.push(list[i])
        }
      }
      if (status.includes('fulfilled')) {
        if (
          list[i].appointment.status &&
          list[i].appointment.status === 'fulfilled'
        ) {
          appointmentList.push(list[i])
        }
      }
      if (status.includes('cancelled')) {
        if (
          list[i].appointment.status &&
          list[i].appointment.status === 'cancelled'
        ) {
          appointmentList.push(list[i])
        }
      }
    }
  }

  return appointmentList
}

export async function updateConvFee(procedure: R4.IChargeItemDefinition) {
  let result = false
  try {
    const procedureArray: R4.IChargeItemDefinition[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInVendorPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function addSettlement(question: R4.IBasic) {
  let result = false
  try {
    const procedureArray: R4.IBasic[] = []
    procedureArray.push(question)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInVendorPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function addCancellation(question: R4.IChargeItemDefinition) {
  let result = false
  try {
    const procedureArray: R4.IChargeItemDefinition[] = []
    procedureArray.push(question)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInVendorPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateSettlement(settle: R4.IBasic) {
  let result = false
  try {
    const finalData = { ...settle }

    const procedureArray: R4.IBasic[] = []
    procedureArray.push(finalData)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInVendorPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateCancellation(settle: R4.IChargeItemDefinition) {
  let result = false
  try {
    const finalData = { ...settle }

    const procedureArray: R4.IChargeItemDefinition[] = []
    procedureArray.push(finalData)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetailsInVendorPartition(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateConditionDetailsFollowup(
  procedure: R4.ICondition,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.ICondition[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updateObsDetailsFollowup(
  procedure: R4.IObservation,
  appointmentId: string
) {
  let result = false
  try {
    const procedureArray: R4.IObservation[] = []
    procedureArray.push(procedure)
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: procedureArray.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function getAppointmentListForFollowup(
  practitionerId: string,
  patientId: string
): Promise<R4.IAppointment | undefined> {
  const searchParameters: any = {
    _sort: '-_lastUpdated',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const newResponse = await fhirClient.doGetResource(
    `/Appointment?practitioner=Practitioner/${practitionerId}&patient=Patient/${patientId}`,
    searchParameters
  )

  if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
    const observationData: R4.IAppointment[] =
      newResponse.entry?.map((item: any) => item.resource as R4.IAppointment) ??
      []
    if (observationData.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      return observationData[0]
    }
  }

  return undefined
}

export function getAppointmentTransactionObjectForFollowup(
  activeState: FollowupAppointmentManagementStatus,
  currentSlot: R4.ISlot,
  isPrePaid: boolean,
  selectedServiceType: string,
  selectedPatient: R4.IPatient,
  selectedDoctorSlot: FhirSlotDetail,
  question?: R4.IQuestionnaire
): R4.IBundle {
  console.log(question)
  let appointmentType: string = ''
  const serviceTypeCodeable: R4.ICodeableConcept = {}
  const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  const serviceTypeList: React.SetStateAction<R4.ICodeableConcept[]> = []
  if (selectedServiceType === '556') {
    appointmentType = 'In-Person'
    const serviceTypeInPerson: R4.ICoding = {
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      code: '556',
      display: 'Walk-in Centre /Non-Emergency',
    }
    serviceTypeCodingList.push(serviceTypeInPerson)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
  }

  if (selectedServiceType === '538') {
    const serviceTypeInPerson: R4.ICoding = {
      code: '538',
      display: 'Telephone Counselling',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    }
    serviceTypeCodingList.push(serviceTypeInPerson)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
    appointmentType = 'Phone'
  }

  if (selectedServiceType === 'video-counselling') {
    appointmentType = 'Video'
    const serviceTypeVideo: R4.ICoding = {
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      code: 'video-counselling',
      display: 'Video Counselling',
    }
    serviceTypeCodingList.push(serviceTypeVideo)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
  }

  const requestId: string = getUniqueTempId()

  const appointmentObject: R4.IAppointment = {
    resourceType: 'Appointment',
    id: requestId,
    slot: [
      {
        reference: `${currentSlot.resourceType}/${currentSlot.id}`,
        id: currentSlot.id,
        type: currentSlot.resourceType,
      },
    ],
    serviceType: serviceTypeList,
    start: selectedDoctorSlot.slot.start,
    end: selectedDoctorSlot.slot.end,
    status: R4.AppointmentStatusKind._booked,
    specialty: selectedDoctorSlot.slot.specialty,

    participant: [
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameOfPatient(selectedPatient),
          reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
          id: selectedPatient.id,
          type: selectedPatient.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameFromHumanName(
            selectedDoctorSlot.practitioner?.name ?? []
          ),
          reference: `${selectedDoctorSlot.practitioner?.resourceType}/${selectedDoctorSlot.practitioner?.id}`,
          id: selectedDoctorSlot.practitioner?.id,
          type: selectedDoctorSlot.practitioner?.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          reference: `${selectedDoctorSlot.practitionerRole?.resourceType}/${selectedDoctorSlot.practitionerRole?.id}`,
          id: selectedDoctorSlot.practitionerRole?.id,
          type: selectedDoctorSlot.practitionerRole?.resourceType,
        },
      },
    ],
  }

  if (question) {
    if (appointmentObject.supportingInformation) {
      appointmentObject.supportingInformation.push({
        reference: `${question.resourceType}/${question.id}`,
        id: question.id,
        type: question.resourceType,
      })
    } else {
      appointmentObject.supportingInformation = [
        {
          reference: `${question.resourceType}/${question.id}`,
          id: question.id,
          type: question.resourceType,
        },
      ]
    }
  }

  if (activeState.followupAllowed) {
    appointmentObject.appointmentType = {
      coding: [
        {
          code: 'FOLLOWUP',
          system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
          display: 'A follow up visit from a previous appointment',
        },
      ],
    }
    appointmentObject.supportingInformation = [
      {
        reference: `${activeState.followupAllowed.resourceType}/${activeState.followupAllowed.id}`,
        id: activeState.followupAllowed.id,
        type: activeState.followupAllowed.resourceType,
      },
    ]
  }
  const modifiedSlot: R4.ISlot = currentSlot
  modifiedSlot.status = R4.SlotStatusKind._busy
  const matchString: string = `W/${JSON.stringify(
    modifiedSlot.meta?.versionId ?? ' '
  )}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Slot/${currentSlot.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Slot/${currentSlot.id}`,
        },
        resource: modifiedSlot,
      },
      {
        fullUrl: 'Appointment',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Appointment',
        },
        resource: appointmentObject,
      },
    ],
  }

  requestBundle.entry?.push({
    fullUrl: `ChargeItem/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'ChargeItem',
    },
    resource: getChargeItemForSelectedAppointment(
      selectedPatient,
      {
        reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
      },
      selectedDoctorSlot.chargeItemDefiniton
    ),
  })

  const appointmentTask: R4.ITask = getTaskForAppointment(
    selectedPatient,
    {
      reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
    },
    selectedDoctorSlot.chargeItemDefiniton
  )

  requestBundle.entry?.push({
    fullUrl: `Task/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'Task',
    },
    resource: appointmentTask,
  })

  requestBundle.entry?.push({
    fullUrl: `Provenance/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'Provenance',
    },
    resource: getProvenanceForTask(
      selectedPatient,
      {
        reference: `${appointmentTask.resourceType}/${appointmentTask.id}`,
      },
      selectedDoctorSlot.chargeItemDefiniton
    ),
  })

  const paymentReconcilation = getPaymentReconciliationResource(
    {
      reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
    },
    {
      currency: 'INR',
      value: getSlotAmountForAppointment(
        selectedDoctorSlot.chargeItemDefiniton
      ),
    },
    isPrePaid
  )
  requestBundle.entry?.push({
    fullUrl: `PaymentReconciliation`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'PaymentReconciliation',
    },
    resource: paymentReconcilation,
  })

  const invoiceData = getInvoiceDetails(
    activeState,
    {
      reference: `${appointmentObject.resourceType}/${appointmentObject.id}`,
    },
    {
      reference: `${paymentReconcilation.resourceType}/${paymentReconcilation.id}`,
    },
    selectedPatient,
    selectedDoctorSlot,
    isPrePaid
  )
  //   requestBundle.entry?.push({
  //     fullUrl: `Invoice`,
  //     request: {
  //       method: R4.Bundle_RequestMethodKind._post,
  //       url: 'Invoice',
  //     },
  //     resource: invoiceData,
  //   })

  return requestBundle
}

export function getSymptomsProfile(response: any): GroupedConditionData[] {
  let welloOccupation: GroupedConditionData[] = []
  if (response.soap_summary) {
    if (response.soap_summary.subjective) {
      if (response.soap_summary.subjective.history) {
        if (response.soap_summary.subjective.history.symptom_profile) {
          const occupationData =
            response.soap_summary.subjective.history.symptom_profile
              ._resources ?? []
          if (occupationData && occupationData.length > 0) {
            const finalData = getGroupedConditions(occupationData)
            const finalGroupedData: GroupedConditionData[] = []
            if (finalData.length > 0) {
              for (let i = 0; i < finalData.length; i++) {
                if (finalGroupedData.length === 0) {
                  if (finalData[i].date) {
                    finalGroupedData.push({
                      date: finalData[i].date!,
                      condition: [finalData[i].condition],
                    })
                  }
                } else {
                  const indexData = finalGroupedData.findIndex(
                    (x) =>
                      moment(x.date).format('YYYY-MM-DD') ===
                      moment(finalData[i].date).format('YYYY-MM-DD')
                  )
                  if (indexData > -1) {
                    finalGroupedData[indexData].condition.push(
                      finalData[i].condition
                    )
                  } else {
                    finalGroupedData.push({
                      date: finalData[i].date!,
                      condition: [finalData[i].condition],
                    })
                  }
                }

                //   finalGroupedData[indexData].condition.push(finalData[i].condition)
              }
            }

            finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))
            welloOccupation = finalGroupedData
          }
        }
      }
    }
  }
  return welloOccupation
}

export function showDietTreatment(unitServices: UnitServices[]): boolean {
  const finalData: string[] = []
  if (unitServices.length > 0) {
    for (let i = 0; i < unitServices.length; i++) {
      const system = unitServices[i].systemOfMedicine ?? []
      if (system.length > 0) {
        const data = system.filter(
          (org: R4.ICoding) => org.code === '439809005'
        )
        if (data.length > 0) {
          finalData.push('1')
        }
      }
    }
  }

  if (finalData.length > 0) return true
  return false
}
