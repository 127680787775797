import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { Diet } from 'models/diet'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { addDietDetails } from 'redux/ipd/diet/addDiet/addDiet'
import { requestDietDetails } from 'redux/ipd/diet/viewDiet/dietSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isCurrentUserDoctor } from 'services/userDetailsService'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  isDietCompleted,
  isExistingDiet,
  isFutureDateTimeForDiet,
  isPastDateTimeForDiet,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { DietSelector } from './diet_selector'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const DietCardForAyurveda: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  status,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const addDietSlice = useSelector((state: RootState) => state.addDietSlice)

  const getDietDetails = useSelector(
    (state: RootState) => state.dietSearchSlice
  )

  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))

  function updateCurrentSelection(
    catalogType: R4.ICatalogEntry[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    const nextSelected: Diet = { ...dietChart[dietDate + 1] }
    switch (timeIndex) {
      case '6AM':
        selectedDiet.six = [updateNutrition(catalogType, selectedDiet.six![0])]

        break
      case '830AM':
        selectedDiet.eight = [
          updateNutrition(catalogType, selectedDiet.eight![0]),
        ]

        break
      case '12PM':
        selectedDiet.twelve = [
          updateNutrition(catalogType, selectedDiet.twelve![0]),
        ]

        break
      case '2PM':
        selectedDiet.two = [updateNutrition(catalogType, selectedDiet.two![0])]
        break
      case '5PM':
        selectedDiet.five = [
          updateNutrition(catalogType, selectedDiet.five![0]),
        ]
        break
      case '7PM':
        selectedDiet.seven = [
          updateNutrition(catalogType, selectedDiet.seven![0]),
        ]
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    if (nextSelected.repeat) {
      newDietChart[dietDate + 1].six = updateNutritionForRepeat(
        selectedDiet.six ?? [],
        nextSelected.date
      )

      newDietChart[dietDate + 1].eight = updateNutritionForRepeat(
        selectedDiet.eight ?? [],
        nextSelected.date
      )
      newDietChart[dietDate + 1].twelve = updateNutritionForRepeat(
        selectedDiet.twelve ?? [],
        nextSelected.date
      )
      newDietChart[dietDate + 1].two = updateNutritionForRepeat(
        selectedDiet.two ?? [],
        nextSelected.date
      )
      newDietChart[dietDate + 1].five = updateNutritionForRepeat(
        selectedDiet.five ?? [],
        nextSelected.date
      )

      newDietChart[dietDate + 1].seven = updateNutritionForRepeat(
        selectedDiet.seven ?? [],
        nextSelected.date
      )
    }
    console.log(newDietChart)
    setDietChart(newDietChart)
    setFormState(_.random(0, 109090).toFixed(2))
  }

  function updateCurrentSelectionForRepeat(
    date: string,
    event: any,
    timeIndex: number
  ) {
    const selectedDiet: Diet = { ...dietChart[timeIndex - 1] }
    const selectedDiet1: Diet = { ...dietChart[timeIndex] }

    const newDietChart = [...dietChart]
    const oldDietChart = [...beforeDietChart]
    newDietChart[timeIndex] = selectedDiet1
    newDietChart[timeIndex].date = date

    if (event.target.checked === false) {
      newDietChart[timeIndex] = oldDietChart[timeIndex]
      setDietChart(newDietChart)
    } else {
      newDietChart[timeIndex].repeat = event.target.checked
      newDietChart[timeIndex].id = `${date}id`

      if (selectedDiet.six) {
        if (isDietCompleted(selectedDiet1.six ?? []) === false) {
          newDietChart[timeIndex].six = updateNutritionForRepeat(
            selectedDiet.six ?? [],
            date
          )
        }
      }
      if (selectedDiet.eight) {
        if (isDietCompleted(selectedDiet1.eight ?? []) === false) {
          newDietChart[timeIndex].eight = updateNutritionForRepeat(
            selectedDiet.eight ?? [],
            date
          )
        }
      }

      if (selectedDiet.twelve) {
        if (isDietCompleted(selectedDiet1.twelve ?? []) === false) {
          newDietChart[timeIndex].twelve = updateNutritionForRepeat(
            selectedDiet.twelve ?? [],
            date
          )
        }
      }
      if (selectedDiet.two) {
        if (isDietCompleted(selectedDiet1.two ?? []) === false) {
          newDietChart[timeIndex].two = updateNutritionForRepeat(
            selectedDiet.two ?? [],
            date
          )
        }
      }
      if (selectedDiet.five) {
        if (isDietCompleted(selectedDiet1.five ?? []) === false) {
          newDietChart[timeIndex].five = updateNutritionForRepeat(
            selectedDiet.five ?? [],
            date
          )
        }
      }
      if (selectedDiet.seven) {
        if (isDietCompleted(selectedDiet1.seven ?? []) === false) {
          newDietChart[timeIndex].seven = updateNutritionForRepeat(
            selectedDiet.seven ?? [],
            date
          )
        }
      }
      setDietChart(newDietChart)
    }
    console.log(newDietChart)
  }

  function updateNutritionCompletionStatus(
    no: R4.INutritionOrder[],
    dietDate: number,
    timeIndex: string
  ) {
    console.log(no)
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case '6AM':
        selectedDiet.six = no

        break
      case '830AM':
        selectedDiet.eight = no

        break
      case '12PM':
        selectedDiet.twelve = no

        break
      case '2PM':
        selectedDiet.two = no

        break
      case '5PM':
        selectedDiet.five = no

        break
      case '7PM':
        selectedDiet.seven = no

        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    setDietChart(newDietChart)
  }

  const [dietChart, setDietChart] = useState<Diet[]>([])
  const [beforeDietChart, setBeforeDiet] = useState<Diet[]>([])

  function updateNutrition(
    catalogType: R4.ICatalogEntry[],
    nutritionResource: R4.INutritionOrder
  ) {
    let nutritionOrder: R4.INutritionOrder = { ...nutritionResource }
    nutritionOrder.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndNutritionOrderNutritionProduct',
      ],
    }

    const extList: R4.IExtension[] = []

    catalogType.forEach((e) => {
      extList.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext',
        valueReference: {
          reference: `CatalogEntry/${e.id}`,
          display: getCatalogName(e),
        },
      })
    })

    nutritionOrder = {
      ...nutritionOrder,
      oralDiet: {
        ...nutritionOrder.oralDiet,
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
            extension: extList,
          },
        ],
      },
    }
    return nutritionOrder
  }

  function updateNutritionForRepeat(
    nutritionResource: R4.INutritionOrder[],
    date: string
  ): R4.INutritionOrder[] {
    const resource: R4.INutritionOrder[] = []
    for (let i = 0; i < nutritionResource.length; i++) {
      let nutritionOrder: R4.INutritionOrder = nutritionResource[i]

      const oralDietData: R4.INutritionOrder_OralDiet | undefined =
        nutritionOrder.oralDiet

      if (oralDietData) {
        const oralSchedule: R4.ITiming[] = oralDietData.schedule ?? []
        if (oralSchedule) {
          nutritionOrder = {
            ...nutritionOrder,
            id: getUniqueTempId(),
            status: 'active',
            oralDiet: {
              extension: oralDietData.extension ?? [],
              schedule: [
                {
                  event: [moment(date).toISOString()],
                  code: oralSchedule[0].code,
                },
              ],
            },
          }
        }
      }

      resource.push(nutritionOrder)
    }

    return resource
  }

  function checkForRepeat(diet: Diet): string[] {
    const checkArr: string[] = []

    if (diet.seven) {
      for (let i = 0; i < diet.seven.length; i++) {
        if (diet.seven[i].oralDiet) {
          const { oralDiet } = diet.seven[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.five) {
      for (let i = 0; i < diet.five.length; i++) {
        if (diet.five[i].oralDiet) {
          const { oralDiet } = diet.five[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.twelve) {
      for (let i = 0; i < diet.twelve.length; i++) {
        if (diet.twelve[i].oralDiet) {
          const { oralDiet } = diet.twelve[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.two) {
      for (let i = 0; i < diet.two.length; i++) {
        if (diet.two[i].oralDiet) {
          const { oralDiet } = diet.two[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.eight) {
      for (let i = 0; i < diet.eight.length; i++) {
        if (diet.eight[i].oralDiet) {
          const { oralDiet } = diet.eight[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }
    if (diet.six) {
      for (let i = 0; i < diet.six.length; i++) {
        if (diet.six[i].oralDiet) {
          const { oralDiet } = diet.six[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            checkArr.push('1')
          }
        }
      }
    }

    return checkArr
  }

  function disableRepeat(diet: Diet): string[] {
    console.log(diet)
    const checkArr: string[] = []
    const idArray: string[] = []

    if (diet.seven) {
      for (let i = 0; i < diet.seven.length; i++) {
        const id = diet.seven[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.seven[i].oralDiet) {
          const { oralDiet } = diet.seven[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.five) {
      for (let i = 0; i < diet.five.length; i++) {
        const id = diet.five[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.five[i].oralDiet) {
          const { oralDiet } = diet.five[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.twelve) {
      for (let i = 0; i < diet.twelve.length; i++) {
        const id = diet.twelve[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.twelve[i].oralDiet) {
          const { oralDiet } = diet.twelve[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.two) {
      for (let i = 0; i < diet.two.length; i++) {
        const id = diet.two[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.two[i].oralDiet) {
          const { oralDiet } = diet.two[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.eight) {
      for (let i = 0; i < diet.eight.length; i++) {
        const id = diet.eight[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.eight[i].oralDiet) {
          const { oralDiet } = diet.eight[i]
          if (oralDiet && oralDiet.extension && oralDiet.extension.length > 0) {
            idArray.push('1')
          }
        }
      }
    }
    if (diet.six) {
      for (let i = 0; i < diet.six.length; i++) {
        const id = diet.six[i].id ?? ''
        if (id.includes('urn')) {
          checkArr.push('1')
        } else if (diet.six[i].oralDiet) {
          const oralDietData = diet.six[i].oralDiet
          console.log(diet)

          console.log(diet.six)
          console.log(oralDietData)
          if (
            oralDietData &&
            oralDietData.extension &&
            oralDietData.extension.length > 0
          ) {
            idArray.push('1')
          }
        }
      }
    }
    if (idArray.length === 0) return idArray
    return idArray
  }

  useEffect(() => {
    dispatch(
      requestDietDetails(
        fhirAppointmentDetails.patient,
        fhirAppointmentDetails.mainServiceRequest.id!,
        fhirAppointmentDetails.mainServiceRequest,
        fhirAppointmentDetails.mainEncounter?.id!,
        true
      )
    )
  }, [])

  useEffect(() => {
    if (getDietDetails.resultsAvailable && getDietDetails.dateWiseDietList) {
      setDietChart(getDietDetails.dateWiseDietList)
      setBeforeDiet(getDietDetails.dateWiseDietList)
    }
  }, [getDietDetails.resultsAvailable])

  return (
    <Box style={{ paddingLeft: '2px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container spacing={2} direction='column'>
          <Grid container direction='column'>
            {addDietSlice.adding && <LinearProgress />}
          </Grid>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid item id='diagnosis'>
                <Grid item xl style={{ padding: 0, margin: 0 }}>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box
                      width='100%'
                      display='flex'
                      flexDirection='row'
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                      minHeight={30}
                    >
                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        paddingLeft={2}
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          Date
                        </Typography>
                      </Box>

                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          6 AM
                        </Typography>
                      </Box>

                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          8.30 AM
                        </Typography>
                      </Box>

                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          12:30 PM
                        </Typography>
                      </Box>

                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          2 PM
                        </Typography>
                      </Box>
                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          4:30 PM
                        </Typography>
                      </Box>
                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          6:30 PM
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {getDietDetails.searchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    alignContent='center'
                    alignItems='center'
                    justifyContent='center'
                    justifyItems='center'
                  >
                    {' '}
                    <CircularProgress size={28} />
                  </Box>
                )}

                {getDietDetails.resultsAvailable && (
                  <Grid item xl style={{ padding: 0, margin: 0 }}>
                    <Box display='flex' flexDirection='column' width='100%'>
                      {dietChart.map((dietRow: Diet, index: number) => (
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='flex-start'
                          justifyContent='center'
                          key={`diet${dietRow.date}`}
                        >
                          <Box
                            width='12%'
                            py={0.5}
                            display='flex'
                            flexDirection='column'
                          >
                            <WelloFormItemLabel
                              title={moment(dietRow.date).format('DD-MM-YYYY')}
                              displayDoneLabel={false}
                              id={`${index}date`}
                            />
                            {index > 0 && (
                              <Box paddingLeft={0.3}>
                                {disableRepeat(dietRow).length === 0 && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size='small'
                                        color='primary'
                                        disabled={
                                          checkForRepeat(dietChart[index - 1])
                                            .length === 0
                                        }
                                        checked={
                                          dietRow.repeat
                                            ? dietRow.repeat
                                            : false
                                        }
                                        onChange={(eve, checked) => {
                                          updateCurrentSelectionForRepeat(
                                            dietRow.date,
                                            eve,
                                            index
                                          )
                                        }}
                                        id={`repeat${index}`}
                                      />
                                    }
                                    label={
                                      <Typography variant='subtitle2'>
                                        Repeat
                                      </Typography>
                                    }
                                    labelPlacement='end'
                                  />
                                )}
                              </Box>
                            )}
                          </Box>

                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              nutritionOrders={dietRow.six ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              id={index}
                              searchType='MORN.early'
                              timeOfDay='6AM'
                              patient={fhirAppointmentDetails.patient}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '6AM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '6AM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.six)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '6AM')
                              }}
                              repeat={dietRow.repeat}
                            />
                          </Box>

                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              patient={fhirAppointmentDetails.patient}
                              nutritionOrders={dietRow.eight ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              searchType='MORN'
                              timeOfDay='830AM'
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '830AM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '830AM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.eight)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(
                                  e,
                                  index,
                                  '830AM'
                                )
                              }}
                              repeat={dietRow.repeat}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              patient={fhirAppointmentDetails.patient}
                              nutritionOrders={dietRow.twelve ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              searchType='MORN.late'
                              timeOfDay='12PM'
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '12PM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '12PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.twelve)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(
                                  e,
                                  index,
                                  '12PM'
                                )
                              }}
                              repeat={dietRow.repeat}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              patient={fhirAppointmentDetails.patient}
                              nutritionOrders={dietRow.two ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              searchType='NOON'
                              timeOfDay='2PM'
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '2PM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '2PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.two)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '2PM')
                              }}
                              repeat={dietRow.repeat}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              nutritionOrders={dietRow.five ?? []}
                              id={index}
                              patient={fhirAppointmentDetails.patient}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              searchType='EVE'
                              timeOfDay='5PM'
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '5PM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '5PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.five)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '5PM')
                              }}
                              repeat={dietRow.repeat}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              patient={fhirAppointmentDetails.patient}
                              nutritionOrders={dietRow.seven ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              searchType='NIGHT'
                              timeOfDay='7PM'
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '7PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.seven)
                              }
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '7PM',
                                  3
                                ) || !isCurrentUserDoctor()
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '7PM')
                              }}
                            />
                          </Box>
                          <Box py={0.5} width='10%'>
                            {status !== 'completed' && (
                              <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                id={`${index}button`}
                                onClick={() => {
                                  dispatch(
                                    addDietDetails(
                                      dietChart[index],
                                      fhirAppointmentDetails
                                    )
                                  )
                                }}
                              >
                                <Typography variant='subtitle2'>
                                  {isExistingDiet(dietRow) ? 'Update' : 'Add'}
                                </Typography>
                              </Button>
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
