import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { textMain } from 'configs/styles/muiThemes'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import { getWelloMedicationFromMedicationRequest } from 'utils/appointment_handle/medications_util'
import {
  getActionBundleFromCds,
  getCarePlanDescriptions,
  getCarePlans,
  getIdOfCardEntry,
  getMedications,
  hasError,
  isEssential,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import CPGSelectionContext from 'views/contexts/cpgContextSelection'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddCareTeamFollowUpsButton } from '../add_care_follow_ups_button'
import { AddMedicationRecommendationButton } from '../add_medication_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const CareTeamFollowUpsOfCPGRecommendation: React.FC<CdsCardDetailsProps> =
  ({ carePlanIdentifier, cdsCard: card }: CdsCardDetailsProps) => {
    const classes = useStyles()

    const visitContext = useContext(VisitAppointmentContext)

    const labDiagnosticTestCodes = useSelector(
      (state: RootState) =>
        state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
    )
    const cdsRecommendationAddition = useContext(CPGSelectionContext)

    return (
      <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
        {!hasError(card) &&
          card.interceptedSuggestions &&
          card.interceptedSuggestions.has('careTeamFollowUps') && (
            <Box
              display='flex'
              flexDirection='column'
              key='careTeamFollowUps_cpg_outer_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                display='flex'
                flexDirection='column'
                key='careTeamFollowUps_cpg_inner_label'
                py={0.25}
                overflow='auto'
              >
                {(
                  card.interceptedSuggestions.get('careTeamFollowUps') as any[]
                ).map((app, index) => {
                  console.log(
                    '--------------------recommendation----------------------',
                    app
                  )
                  return (
                    <Box
                      width='100%'
                      flex='row'
                      id='ct_follow_up_list'
                      display='flex'
                      overflow='auto'
                      key={app.id}
                    >
                      <Typography
                        className={classes.subTitleText}
                        style={{
                          whiteSpace: 'pre-line',
                        }}
                        id={`cpg_recommendation_ct_follow_up_${index}`}
                      >
                        {app ?? ''}
                      </Typography>
                    </Box>
                  )
                })}

                <Box
                  width='100%'
                  flexDirection='row-reverse'
                  id='ct_follow_up_btn'
                  display='flex'
                >
                  <AddCareTeamFollowUpsButton
                    carePanBundle={
                      getActionBundleFromCds(card)[0].resource as R4.IBundle
                    }
                    carePlanIdentifier={carePlanIdentifier}
                  />
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    )
  }
