import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirPrescriptionDetails } from 'models/fhirPrescriptionDetails'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  getCurrentUserUnitDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import {
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getAddress,
  getAgeOfPatientData,
  getCodingValForDoc,
  getDifferenceBetweenDates,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getNameOfPatient,
  getRegistrationNumber,
  getSpecializationsList,
  getTelecomFromContactPoints,
  getTelecomOfPatient,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionContentType,
  getValueCodingFromExtension,
} from 'utils/fhirResourcesHelper'
import { getDateForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { LogoViewer } from '../common/logoViewer'
import { AaharaPrescription } from '../consultation/assessment/aaharaPrescription'
import { AddedKriyasList } from '../consultation/assessment/addedKriyasList'
import { AddedKriyasListForPrescription } from '../consultation/assessment/addKriyaListForPrescription'
import { LabTestPrescriptionReferral } from '../consultation/assessment/ayurvedaAssessment/PrescriptionLabReferral'
import { FollowUpAppointmentTabularTile } from '../consultation/assessment/followUpAppointmentTabularTile'
import { PrescriptionInstruction } from '../consultation/assessment/prescriptionInstruction'
import { LabTestPrescription } from '../consultation/assessment/prescriptionLab'
import { SymptomsList } from '../consultation/assessment/symptomsList'
import { NoteDialog } from '../LeftMenu/TextDialog'
import { SelectedFile } from '../LeftMenu/WelloFilePicker'
import { DietPrescription } from './dietForPrescription'

interface Props {
  appointmentDetails: FhirAppointmentDetail
  refData?: any
  planData?: FhirPrescriptionDetails
  symptoms: boolean
  phone: boolean
}
export const AyurvedaPrescriptionData: React.FC<Props> = ({
  refData,
  appointmentDetails,
  planData,
  symptoms,
  phone,
}) => {
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  return (
    <Box
      {...{ ref: refData }}
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      style={{ overflow: 'scroll' }}
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        // height={900}
        px={2}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            width='50%'
            justifyContent='flex-start'
          >
            {getValueAttachmentFromExtension(
              getCurrentUserUnitDetails().extension,
              'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
            ) === '1' && (
              <Box
                display='flex'
                width='30%'
                flexDirection='row'
                justifyContent='flex-start'
                paddingLeft={2}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    width='100%'
                    flexDirection='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    height='50%'
                  >
                    <LogoViewer
                      contentType='image/png'
                      decodingRequired={true}
                      imagePath="Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)"
                      resourceId={getCurrentUserUnitDetails().id ?? ''}
                      resourceType='Organization'
                      onSelectionChanged={(value: SelectedFile) => {
                        //   setFile(value)
                      }}
                      update={false}
                      purpose='tile3'
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='70%'
            py={2}
            justifyContent='flex-start'
          >
            <Typography
              variant='h5'
              style={{ color: 'black', fontWeight: 'bold' }}
            >
              {getCurrentUserUnitDetails().name ?? ''}
            </Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='row' width='100%' px={2} py={2}>
          <Box display='flex' flexDirection='column' width='70%'>
            <Typography
              variant='subtitle1'
              style={{ color: 'black', fontWeight: 'bold' }}
            >
              {getNameFromHumanName(
                appointmentDetails.practitionerDetail.practitioner.name ?? []
              )}
            </Typography>
            <Box display='flex' flexDirection='row'>
              <Typography variant='subtitle2' color='initial'>
                {appointmentDetails.practitionerDetail.practitioner
                  .qualification &&
                getCodingValForDoc(
                  appointmentDetails.practitionerDetail.practitioner
                    .qualification[0].code
                ).length > 0
                  ? getCodingValForDoc(
                      appointmentDetails.practitionerDetail.practitioner
                        .qualification[0].code
                    )
                  : ''}
              </Typography>
              {appointmentDetails.practitionerDetail.practitioner
                .qualification &&
                getValueCodingFromExtension(
                  appointmentDetails.practitionerDetail.practitioner
                    .qualification[0].extension,
                  'http://wellopathy.com/fhir/india/core/qualificationSpecialization'
                ) && (
                  <Box paddingLeft={0.5}>
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{ paddingLeft: 0.4 }}
                    >
                      (
                    </Typography>
                  </Box>
                )}
              <Typography variant='subtitle2' color='initial'>
                {appointmentDetails.practitionerDetail.practitioner
                  .qualification &&
                getValueCodingFromExtension(
                  appointmentDetails.practitionerDetail.practitioner
                    .qualification[0].extension,
                  'http://wellopathy.com/fhir/india/core/qualificationSpecialization'
                )
                  ? getValueCodingFromExtension(
                      appointmentDetails.practitionerDetail.practitioner
                        .qualification[0].extension,
                      'http://wellopathy.com/fhir/india/core/qualificationSpecialization'
                    )?.display ?? ''
                  : ''}
              </Typography>
              {appointmentDetails.practitionerDetail.practitioner
                .qualification &&
                getValueCodingFromExtension(
                  appointmentDetails.practitionerDetail.practitioner
                    .qualification[0].extension,
                  'http://wellopathy.com/fhir/india/core/qualificationSpecialization'
                ) && (
                  <Typography variant='subtitle2' color='initial'>
                    )
                  </Typography>
                )}
            </Box>
            <Typography variant='subtitle2' color='initial'>
              {getSpecializationsList(
                appointmentDetails.practitionerDetail.practitionerRole
                  .specialty ?? []
              )}
            </Typography>
            <Typography variant='subtitle1' color='initial'>
              {getRegistrationNumber(
                appointmentDetails.practitionerDetail.practitioner
              )}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='30%'
            paddingLeft={1}
            justifyContent='center'
          >
            <Box display='flex' flexDirection='column' width='100%'>
              <Typography variant='subtitle1' color='initial'>
                {getAddress(getCurrentUserUnitDetails().address ?? []) ?? ''}
              </Typography>
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  textTransform: 'capitalize',
                  fontFamily: 'Open Sans',

                  lineHeight: 'normal',
                  fontWeight: 600,
                }}
              >
                {getCurrentUserUnitDetails().contact
                  ? getTelecomFromContactPoints(
                      getCurrentUserUnitDetails().contact![0].telecom ?? [],
                      ContactPointSystemKind._email
                    )
                  : ''}
              </Typography>
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  textTransform: 'capitalize',
                  fontFamily: 'Open Sans',

                  lineHeight: 'normal',
                  fontWeight: 600,
                }}
              >
                {getCurrentUserUnitDetails().contact
                  ? getTelecomFromContactPoints(
                      getCurrentUserUnitDetails().contact![0].telecom ?? [],
                      ContactPointSystemKind._phone
                    )
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          flexGrow
          width='100%'
          display='flex'
          flexDirection='column'
          px={2}
          py={1}
        >
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                    background: 'black',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box display='flex' flexDirection='column' paddingTop={1.5} px={2}>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='flex-start'
            border={4}
            style={{
              boxShadow: '0px 0px 4px #ccc',
              margin: '2px 0px',
              borderRadius: '1px',
              borderColor: '#F8F8F8',
              width: '100%',
              height: 'auto',
              backgroundColor: '#F8F8F8',
            }}
          >
            <Grid container direction='column' spacing={1}>
              <Grid container direction='row' id='vitals'>
                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Patient Name
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Box display='flex' flexDirection='row'>
                          {getMiddleNameOfPatient(appointmentDetails.patient)
                            .length > 0 && (
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                //   color: 'black',
                                //   fontWeight: 'bold',
                              }}
                            >
                              {getNameOfPatient(appointmentDetails.patient)}{' '}
                              {getMiddleNameOfPatient(
                                appointmentDetails.patient
                              ) ?? ''}{' '}
                              {getLastNameOfPatient(appointmentDetails.patient)}
                            </Typography>
                          )}

                          {getMiddleNameOfPatient(appointmentDetails.patient)
                            .length === 0 && (
                            <Typography
                              variant='subtitle2'
                              style={{
                                textTransform: 'capitalize',
                                //   color: 'black',
                                //   fontWeight: 'bold',
                              }}
                            >
                              {getNameOfPatient(appointmentDetails.patient)}{' '}
                              {getLastNameOfPatient(appointmentDetails.patient)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Date
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Box display='flex' flexDirection='row'>
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              textTransform: 'capitalize',
                              // color: 'black',
                              // fontWeight: 'bold',
                            }}
                          >
                            {getDateForAppointment(appointmentDetails)}{' '}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Weight
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        {planData && planData.weight && (
                          <Box>
                            {planData.weight > 0 && (
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={
                                  {
                                    // color: 'black',
                                    // fontWeight: 'bold',
                                  }
                                }
                              >
                                {`${planData.weight} kg`}
                              </Typography>
                            )}
                          </Box>
                        )}

                        {planData && planData.weight === undefined && (
                          <Box>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={
                                {
                                  // color: 'black',
                                  // fontWeight: 'bold',
                                }
                              }
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%'>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction='row' id='vitals'>
                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Age/Sex
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Box display='flex' flexDirection='row'>
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              textTransform: 'capitalize',
                              // color: 'black',
                              // fontWeight: 'bold',
                            }}
                          >
                            {getAgeOfPatientData(appointmentDetails.patient)}
                          </Typography>
                          <Box px={0.3}>
                            <Typography variant='subtitle2' color='initial'>
                              /
                            </Typography>
                          </Box>
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              textTransform: 'capitalize',
                              // color: 'black',
                              // fontWeight: 'bold',
                            }}
                          >
                            {appointmentDetails.patient.gender ===
                            R4.PatientGenderKind._male
                              ? 'Male'
                              : 'Female'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Phone No.
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Box display='flex' flexDirection='row'>
                          {phone && (
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                // color: 'black',
                                // fontWeight: 'bold',
                              }}
                            >
                              {getTelecomOfPatient(
                                appointmentDetails.patient,
                                R4.ContactPointSystemKind._phone
                              )}{' '}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {symptoms && (
          <Box flexGrow width='100%' display='flex'>
            {planData &&
              planData.chiefComplaintsData &&
              planData.chiefComplaintsData.length > 0 && (
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  px={2}
                >
                  <Box py={1}>
                    <Typography
                      variant='subtitle1'
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Symptoms
                    </Typography>
                  </Box>{' '}
                  <Box display='flex' flexDirection='column' width='100%'>
                    <SymptomsList
                      instructions={planData.chiefComplaintsData}
                      prescription={true}
                    />
                  </Box>
                </Box>
              )}
          </Box>
        )}

        {planData &&
          planData.ayurvdeaMedication &&
          planData.ayurvdeaMedication.length > 0 && (
            <Box
              flexGrow
              width='100%'
              display='flex'
              flexDirection='column'
              px={2}
            >
              <Box py={1}>
                <Typography
                  variant='subtitle1'
                  style={{
                    fontFamily: 'Open Sans',
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Rx
                </Typography>
              </Box>{' '}
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                //   border={4}
                //   style={{
                //     boxShadow: '0px 0px 4px #ccc',
                //     margin: '2px 0px',
                //     borderRadius: '1px',
                //     borderColor: '#F8F8F8',
                //     width: '100%',
                //     height: 'auto',
                //     backgroundColor: '#F8F8F8',
                //   }}
              >
                <Grid container direction='column' spacing={1}>
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    //   style={{
                    //     backgroundColor: kDialogueBackground,
                    //   }}
                  >
                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width={isAyurvedaDoctor() ? '50%' : '100%'}
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={20}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Medication
                            </Typography>
                          </Box>
                        </Box>

                        {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>

                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        {isAyurvedaDoctor() && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={20}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Timing of Medication
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={20}
                            alignItems='center'
                            px={0.7}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Route
                            </Typography>
                          </Box>

                          {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={20}
                            alignItems='center'
                            px={0.7}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Dosage
                            </Typography>
                          </Box>

                          {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={20}
                            alignItems='center'
                            paddingLeft={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Timing
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={20}
                            alignItems='center'
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Duration
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {/* ------------------------------------------------------------------------------- */}
              {planData.ayurvdeaMedication.map((e, index: number) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                  key={e.id}
                  paddingTop={1}
                >
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    //   style={{
                    //     backgroundColor: index % 2 === 0 ? 'white' : 'white',
                    //   }}
                  >
                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width={isAyurvedaDoctor() ? '50%' : '100%'}
                          flexGrow={1}
                          px={0.5}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            alignItems='center'
                          >
                            {/* <Box paddingRight={0.4}>
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',
                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {`${index + 1}) `}
                              </Typography>
                            </Box> */}
                            {e.medication && e.medication.code && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {e.medication.code.text ?? ''}
                              </Typography>
                            )}

                            {e.name && e.name.length > 0 && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {e.name}
                              </Typography>
                            )}

                            {e.medicationCode && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {e.medicationCode.display}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width={isAyurvedaDoctor() ? '50%' : '100%'}
                          flexGrow={1}
                          px={0.5}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            alignItems='center'
                          >
                            {e.aushadhaKalaCode && e.aushadhaKalaCode.code && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {e.aushadhaKalaCode.display ?? ''}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          justifyContent='center'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            alignItems='center'
                            px={0.8}
                          >
                            {e.administrationCode && (
                              <Box display='flex' flexGrow flexDirection='row'>
                                <Typography
                                  color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,

                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {getUnitOfRouteRequest(e.administrationCode)}
                                </Typography>
                              </Box>
                            )}
                            {/* {e.timeCodes && e.timeCodes && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {e.timeCodes}
                          </Typography>
                        )} */}
                          </Box>

                          {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.1}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            minHeight: '35px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          justifyContent='center'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            alignItems='center'
                            px={0.7}
                          >
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {e.dosageQuantity}
                            </Typography>
                            {e.dosageQuantity && e.dosageQuantityType && (
                              <Box paddingLeft={0.5}>
                                <Typography
                                  color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    paddingLeft: 0.5,

                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                >
                                  {getUnitOfDosageFromRequest(
                                    e.dosageQuantityType
                                  )}
                                </Typography>
                              </Box>
                            )}
                            {/* {e.timeCodes && e.timeCodes && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {e.timeCodes}
                          </Typography>
                        )} */}
                          </Box>

                          {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.1}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            minHeight: '35px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                        paddingTop={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                          justifyContent='center'
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            alignItems='center'
                            px={0.8}
                          >
                            {' '}
                            {e.timeCodes && e.timeCodes && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {e.timeCodes}
                              </Typography>
                            )}
                          </Box>
                          {/* <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            alignItems='center'
                          >
                            {e.timeOfDayCode && e.mealCode && (
                              <Box paddingLeft={0.5}>
                                <Typography
                                  color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    paddingLeft: 1,
                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                >
                                  {' '}
                                  (
                                </Typography>
                              </Box>
                            )}
                            {e.timeOfDayCode && e.mealCode && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {getTimeOfDayDisplayMealForPrescription(
                                  e.mealCode
                                )}
                              </Typography>
                            )}
                            {e.timeOfDayCode && e.mealCode && (
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                )
                              </Typography>
                            )}
                          </Box> */}
                        </Box>
                        {/* <Box display='flex' width='2%' paddingTop={0.1}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            minHeight: '35px',

                            width: '2px',
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          justifyContent='center'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            alignItems='center'
                          >
                            <Box
                              display='flex'
                              flexGrow
                              flexDirection='row'
                              px={0.7}
                            >
                              {e.till && e.startFrom && (
                                <Box>
                                  <Typography
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {getDifferenceBetweenDates(
                                      e.startFrom,
                                      e.till
                                    )}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    {/* <Grid item xs={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        justifyContent='center'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          minHeight={30}
                          alignItems='center'
                          px={1.4}
                        >
                          <Box display='flex' flexGrow flexDirection='row'>
                            <Typography
                              // display='inline'
                              // dangerouslySetInnerHTML={{
                              //   __html: getCommunicationRequestPayloadString(e) ?? '',
                              // }}
                              onClick={() => {
                                setFullText(e.notes ?? '')
                                setShowText(true)
                              }}
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 11,
                                color: 'black',
                                fontWeight: 600,
                                lineHeight: 'normal',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {e.notes ?? ''}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid> */}
                  </Grid>

                  {e.anupanaCode && (
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={5}>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          minHeight={30}
                          alignItems='center'
                          px={0.5}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                alignItems='center'
                                minHeight={20}
                                flexDirection='row'
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  To be taken with :{' '}
                                </Typography>
                                <Box paddingLeft={0.3}>
                                  <Typography
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {e.anupanaCode.display ?? ''}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={3} />
                      <Grid item xs={2}>
                        {e.anupanaDosageQuantity &&
                          e.anupanaDosageQuantityType && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                justifyContent='center'
                                flexGrow={1}
                                paddingLeft={0.2}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  minHeight={30}
                                  alignItems='center'
                                  px={0.7}
                                >
                                  <Typography
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {e.anupanaDosageQuantity}
                                  </Typography>
                                  {e.anupanaDosageQuantity &&
                                    e.anupanaDosageQuantityType && (
                                      <Box paddingLeft={0.5}>
                                        <Typography
                                          color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            paddingLeft: 0.5,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {getUnitOfDosageFromRequest(
                                            e.anupanaDosageQuantityType
                                          )}
                                        </Typography>
                                      </Box>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          )}
                      </Grid>

                      <Grid item xs={2} />

                      <Grid item xs={1} />
                    </Grid>
                  )}

                  {e.notes && e.notes.length > 0 && (
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          minHeight={30}
                          alignItems='center'
                          px={0.5}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                alignItems='center'
                                minHeight={20}
                                flexDirection='row'
                                paddingBottom={1}
                              >
                                <Box width='7%' paddingRight={0.5}>
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Notes :{' '}
                                  </Typography>
                                </Box>
                                <Box paddingLeft={0.3} width='93%'>
                                  <Typography
                                    // display='inline'
                                    // dangerouslySetInnerHTML={{
                                    //   __html: getCommunicationRequestPayloadString(e) ?? '',
                                    // }}
                                    onClick={() => {
                                      setFullText(e.notes ?? '')
                                      setShowText(true)
                                    }}
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 11,
                                      color: 'black',
                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-line',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {e.notes ?? ''}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container direction='row' id='vitals'>
                    <Grid item xs={12}>
                      <Box width='100%' paddingLeft={0.2}>
                        <Divider
                          orientation='horizontal'
                          flexItem
                          style={{
                            height: '2px',
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          )}
        {planData && planData.diet && planData.diet.length > 0 && (
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='column'
            px={2}
          >
            <Box py={0.5}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Diet
              </Typography>
            </Box>{' '}
            <DietPrescription
              diet={planData.diet ?? []}
              start={planData.start ?? ''}
              end={planData.end ?? ''}
              preferredItems={planData.preferredFoods ?? ''}
              itemsToAvoid={planData.itemsToAvoid ?? ''}
            />
          </Box>
        )}

        {planData && planData.labTest && planData.labTest.length > 0 && (
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='column'
            px={2}
          >
            <Box py={1}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Tests Prescribed
              </Typography>
            </Box>{' '}
            <Box display='flex' flexDirection='column' width='100%'>
              <LabTestPrescriptionReferral
                referrals={planData.labTest}
                type='referrals'
                prescription={true}
              />
            </Box>
          </Box>
        )}

        {planData && planData.imaging && planData.imaging.length > 0 && (
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='column'
            px={2}
          >
            <Box py={1}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Imaging Prescribed
              </Typography>
            </Box>{' '}
            <Box display='flex' flexDirection='column' width='100%'>
              <LabTestPrescription
                referrals={planData.imaging}
                type='imaging'
                prescription={true}
              />
            </Box>
          </Box>
        )}

        {((planData &&
          planData.pathyaApathya &&
          planData.pathyaApathya.length > 0) ||
          (planData &&
            planData.prescriptionVihara &&
            planData.prescriptionVihara.length > 0)) && (
          <Box width='100%' display='flex' flexDirection='column' px={2}>
            <Box py={1}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dos and Donts
              </Typography>
            </Box>{' '}
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='50%' paddingRight={1}>
                {planData &&
                  planData.pathyaApathya &&
                  planData.pathyaApathya.length > 0 && (
                    <AaharaPrescription
                      referrals={planData.pathyaApathya}
                      type='Foods'
                      prescription={true}
                    />
                  )}
              </Box>
              <Box width='50%' paddingLeft={1}>
                {planData &&
                  planData.prescriptionVihara &&
                  planData.prescriptionVihara.length > 0 && (
                    <Box display='flex' flexDirection='row' width='100%'>
                      <AaharaPrescription
                        referrals={planData.prescriptionVihara}
                        type='Activities'
                        prescription={true}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        )}

        {planData && planData.kriyaDetails && planData.kriyaDetails.length > 0 && (
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='column'
            px={2}
          >
            <Box py={1}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Kriyas Prescribed
              </Typography>
            </Box>{' '}
            <Box display='flex' flexDirection='column' width='100%'>
              <AddedKriyasListForPrescription
                kriyasList={planData.kriyaDetails}
              />
            </Box>
          </Box>
        )}

        {planData && planData.instructions && planData.instructions.length > 0 && (
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='column'
            px={2}
          >
            <Box py={1}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Advice / Instructions
              </Typography>
            </Box>{' '}
            <Box display='flex' flexDirection='column' width='100%'>
              <PrescriptionInstruction
                instructions={planData.instructions}
                prescription={true}
              />
            </Box>
          </Box>
        )}

        {planData && planData.followUp && planData.followUp.length > 0 && (
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='column'
            px={2}
          >
            <Box py={1}>
              <Typography
                variant='subtitle1'
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Follow Up
              </Typography>
            </Box>{' '}
            <Box display='flex' flexDirection='column' width='100%'>
              <FollowUpAppointmentTabularTile
                followUps={planData.followUp}
                type=''
                prescription={true}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        paddingRight={5}
        justifyContent='flex-end'
        px={2}
      >
        <Box flexGrow width='100%' display='flex' flexDirection='column' py={1}>
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                    background: 'black',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='flex-end'
          paddingRight={2}
        >
          <LogoViewer
            contentType={getValueAttachmentFromExtensionContentType(
              appointmentDetails.practitionerDetail.practitioner.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
            )}
            decodingRequired={true}
            imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext').value.as(Attachment)"
            resourceId={
              appointmentDetails.practitionerDetail.practitioner.id ?? ''
            }
            resourceType='Practitioner'
            onSelectionChanged={(value: SelectedFile) => {
              //   setFile(value)
            }}
            update={false}
            purpose='tile3'
            prescription={true}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='flex-end'
        >
          <Typography
            variant='subtitle1'
            style={{ color: 'black', fontWeight: 'bold' }}
          >
            {getNameFromHumanName(
              appointmentDetails.practitionerDetail.practitioner.name ?? []
            )}
          </Typography>
        </Box>
        <Box flexGrow width='100%' display='flex' flexDirection='column' py={1}>
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                    background: 'black',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
    </Box>
  )
}
