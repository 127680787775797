import { R4 } from '@ahryman40k/ts-fhir-types'
import { GoalLifecycleStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { add } from 'lodash'
import { GoalFormModel } from 'models/goalFormModel'
import { getCurrentUserDetails } from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import {
  getGoalFormModelFromBundle,
  getGoalFormModelFromGoalResource,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import {
  getDefaultDisplayOfSystemFromCodableConcept,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import { GoalsOfSubscriptionStatus } from './goalsOfSusbcriptionsStatus'

const initialState: GoalsOfSubscriptionStatus = {
  adding: false,
  additionSuccessful: false,
  fetching: false,
  fetchingSuccessful: false,
  additionError: false,
  fetchingError: false,
}

const goalsOfSubscriptionsSlice = createSlice({
  name: 'goalsOfSubscriptionsSlice',
  initialState,
  reducers: {
    updateAddConditionStatus(
      state,
      action: PayloadAction<GoalsOfSubscriptionStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.additionError = action.payload.additionError
      state.fetching = action.payload.fetching
      state.fetchingSuccessful = action.payload.fetchingSuccessful
      state.fetchingError = action.payload.fetchingError
      state.errorMessage = action.payload.errorMessage
      state.rawGoalsOfSubscription = action.payload.rawGoalsOfSubscription
      state.goalsFormModel = action.payload.goalsFormModel
    },

    resetAllergiesDetails(
      state,
      action: PayloadAction<GoalsOfSubscriptionStatus>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.additionError = initialState.additionError
      state.fetching = initialState.fetching
      state.fetchingSuccessful = initialState.fetchingSuccessful
      state.fetchingError = initialState.fetchingError
      state.errorMessage = initialState.errorMessage
      state.rawGoalsOfSubscription = initialState.rawGoalsOfSubscription
      state.goalsFormModel = initialState.goalsFormModel
    },
  },
})

export const addNewGoalDetails =
  (
    patientDetails: R4.IPatient,
    addedGoalsList: GoalFormModel[],
    carePlanId?: string,
    encounterId?: string,
    state?: GoalsOfSubscriptionStatus
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: GoalsOfSubscriptionStatus = {
      ...initialState,
      adding: true,
      additionSuccessful: false,
    }
    if (state) {
      addingState = {
        ...state,
        adding: true,
        additionSuccessful: false,
      }
    }

    dispatch(
      goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(addingState)
    )

    try {
      const unitId = getCurrentUserUnitDetails().id
      const fhirClient: EnrolCient = new EnrolCient()
      const response =
        await fhirClient.doCreateEnrolmentFlowRequestWithRawResponse(
          `/careplan-goal/addNewGoalToCareplan/${unitId}`,
          {
            carePlanId,
            encounterId,
            goals: addedGoalsList.map((goal) => ({
              ...goal,
              obsDefinition: undefined,
              inputType: undefined,
            })),
          }
        )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response.data)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const newAddingState = {
          ...addingState,
          adding: false,
          additionSuccessful: true,
        }
        dispatch(showSuccessAlert('Goals are added successfully'))
        dispatch(
          goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
            newAddingState
          )
        )
      } else {
        console.log(
          '-----------relatedFhirDecodeRes--------------',
          relatedFhirDecodeRes.left
        )
        const errorCreatePersonState: GoalsOfSubscriptionStatus = {
          ...addingState,
          adding: false,
          additionSuccessful: false,
          additionError: true,
          errorMessage: 'Error while goal details',
        }
        dispatch(
          goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
      }
    } catch (error) {
      console.log('---------error---------- adding goal', error)
      const errorCreatePersonState: GoalsOfSubscriptionStatus = {
        ...addingState,
        adding: false,
        additionSuccessful: false,
        additionError: true,
        errorMessage: 'error wile adding goal details',
      }
      dispatch(
        goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const deleteGoal =
  (
    addedGoalsList: GoalFormModel,
    carePlanId?: string,
    state?: GoalsOfSubscriptionStatus
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: GoalsOfSubscriptionStatus = {
      ...initialState,
      adding: true,
      additionSuccessful: false,
    }
    if (state) {
      addingState = {
        ...state,
        adding: true,
        additionSuccessful: false,
      }
    }

    dispatch(
      goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(addingState)
    )

    try {
      const updatedFormModel: GoalFormModel = {
        ...addedGoalsList,
        lifeCycleStatus: GoalLifecycleStatusKind._cancelled,
        obsDefinition: undefined,
        inputType: undefined,
      }
      const unitId = getCurrentUserUnitDetails().id
      const fhirClient: EnrolCient = new EnrolCient()
      const response =
        await fhirClient.doCreateEnrolmentFlowRequestWithRawResponse(
          `/careplan-goal/updateGoalOfCarePlan/${unitId}?id=${addedGoalsList.id}`,
          {
            carePlanId,
            ...updatedFormModel,
          }
        )

      console.log('response', response)

      const relatedFhirDecodeRes: E.Either<Errors, R4.IGoal> =
        R4.RTTI_Goal.decode(response.data)
      if (relatedFhirDecodeRes._tag === 'Right') {
        console.log(
          '-----------relatedFhirDecodeRes--------------',
          relatedFhirDecodeRes
        )
        addingState = {
          ...addingState,
          adding: false,
          additionSuccessful: true,
        }
        dispatch(showSuccessAlert('Goals are updated successfully'))
        dispatch(
          goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
            addingState
          )
        )
        return
      }
      const errorCreatePersonState: GoalsOfSubscriptionStatus = {
        ...addingState,
        adding: false,
        additionSuccessful: false,
        additionError: true,
        errorMessage: 'Error while goal details',
      }
      dispatch(
        goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    } catch (error) {
      console.log('---------error adding goal----------', error)
      const errorCreatePersonState: GoalsOfSubscriptionStatus = {
        ...addingState,
        adding: false,
        additionSuccessful: false,
        additionError: true,
        errorMessage: 'error wile adding goal details',
      }
      dispatch(
        goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const fetchGoalsOfSubscription =
  (carePlanId: string, state?: GoalsOfSubscriptionStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    let fetchingState: GoalsOfSubscriptionStatus = {
      ...initialState,
      fetching: true,
      fetchingSuccessful: false,
    }
    if (state) {
      fetchingState = {
        ...state,
        fetching: true,
        fetchingSuccessful: false,
      }
    }

    dispatch(
      goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(fetchingState)
    )
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doGetResource(
        `/Goal?goal-based-on=CarePlan/${carePlanId}&_include=Goal:goal-based-on&_revinclude=Task:focus&_count=200&lifecycle-status=${GoalLifecycleStatusKind._accepted},${GoalLifecycleStatusKind._completed},${GoalLifecycleStatusKind._planned},${GoalLifecycleStatusKind._onHold},${GoalLifecycleStatusKind._proposed},${GoalLifecycleStatusKind._active}`
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const resBundle: R4.IBundle = relatedFhirDecodeRes.right
        const rawGoalsOfSubscription: R4.IGoal[] =
          resBundle.entry
            ?.filter((e) => e.resource?.resourceType === 'Goal')
            .map((entry) => entry.resource as R4.IGoal) ?? []

        /* const activeGoals = rawGoalsOfSubscription.filter((goal) =>
          [
            GoalLifecycleStatusKind._accepted,
            GoalLifecycleStatusKind._completed,
            GoalLifecycleStatusKind._planned,
            GoalLifecycleStatusKind._onHold,
            GoalLifecycleStatusKind._proposed,
            GoalLifecycleStatusKind._active,
          ].includes(goal.lifecycleStatus!)
        ) */

        const goalFormModel: GoalFormModel[] =
          getGoalFormModelFromBundle(resBundle) ?? []

        /* activeGoals.forEach((goal) => {
          const goalFormModelFromGoalResource =
            getGoalFormModelFromGoalResource(goal)
          if (goalFormModelFromGoalResource !== undefined) {
            goalFormModel.push(goalFormModelFromGoalResource)
          }
        }) */

        fetchingState = {
          ...fetchingState,
          fetching: false,
          fetchingSuccessful: true,
          rawGoalsOfSubscription,
          goalsFormModel: goalFormModel.sort((a, b) =>
            (
              getDefaultDisplayOfSystemFromCodableConcept(
                a.goalObservationCoding
              ) ?? ''
            ).localeCompare(
              getDefaultDisplayOfSystemFromCodableConcept(
                b.goalObservationCoding
              ) ?? ''
            )
          ),
        }
        dispatch(
          goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
            fetchingState
          )
        )
      } else {
        const errorCreatePersonState: GoalsOfSubscriptionStatus = {
          ...fetchingState,
          fetching: false,
          fetchingSuccessful: false,
          fetchingError: true,
          rawGoalsOfSubscription: [],
          errorMessage: 'Error while fetching goal details',
        }
        dispatch(
          goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: GoalsOfSubscriptionStatus = {
        ...fetchingState,
        fetching: false,
        fetchingSuccessful: false,
        fetchingError: true,
        errorMessage: 'Error while fetching goal details',
      }
      dispatch(
        goalsOfSubscriptionsSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddAllergiesState = () => (dispatch: AppDispatch) => {
  dispatch(
    goalsOfSubscriptionsSlice.actions.resetAllergiesDetails(initialState)
  )
}

export default goalsOfSubscriptionsSlice.reducer
