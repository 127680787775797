import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppThunk } from 'redux/store'
import { getAvailableWellnessReferrals } from 'utils/fhirResoureHelpers/referralHelpers'

import { logger } from 'utils/logger'
import { WellnessReferralsProvidersType } from './wellnessReferralsProvidersType'

const initialState: WellnessReferralsProvidersType = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const wellnessReferralsProvidersSlice = createSlice({
  name: 'wellnessReferralsProvidersSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<WellnessReferralsProvidersType>
    ) {},

    searchResults(
      state,
      action: PayloadAction<WellnessReferralsProvidersType>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<WellnessReferralsProvidersType>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<WellnessReferralsProvidersType>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },
  },
})

export const getWellnessProviders = (): AppThunk => async (dispatch: any) => {
  const initialSearchState: WellnessReferralsProvidersType = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(
    wellnessReferralsProvidersSlice.actions.errorWhileSearching(
      initialSearchState
    )
  )
  try {
    const orgArray: R4.IHealthcareService[] | undefined =
      await getAvailableWellnessReferrals()
    if (orgArray) {
      const wellnessProvidersListResult: WellnessReferralsProvidersType = {
        error: false,
        noResultsAvailable: false,
        resultsAvailable: true,
        searching: false,
        unitsList: orgArray,
        totalCount: orgArray?.length ?? 0,
      }
      dispatch(
        wellnessReferralsProvidersSlice.actions.searchResults(
          wellnessProvidersListResult
        )
      )
      return
    }

    const errorWhileSearchPatient: WellnessReferralsProvidersType = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error while searching',
    }
    dispatch(
      wellnessReferralsProvidersSlice.actions.errorWhileSearching(
        errorWhileSearchPatient
      )
    )
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: WellnessReferralsProvidersType = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      wellnessReferralsProvidersSlice.actions.errorWhileSearching(
        errorWhileSearchPatient
      )
    )
  }
}

export default wellnessReferralsProvidersSlice.reducer
