import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirSlotDetail } from 'models/fhirSlotDetail'

// eslint-disable-next-line @typescript-eslint/comma-dangle
export enum ACTIONS {
  SlotSelection,
  PatientSelection,
  discountSelection,
}

export interface FollowupAppointmentManagementStatus {
  currentAction: ACTIONS
  enableContinueButton: boolean
  isWalkIn?: boolean
  isQuickEntry?: boolean
  selectedSlot?: FhirSlotDetail
  followupAllowed?: R4.IAppointment
  paymentType?: R4.ICoding
  percentage?: number
  amount?: number
  followupType?: string

  selectedPatient?: R4.IPatient
  creatingAppointment: boolean
  appointmentCreatedSuccessfully: boolean
  createdAppointment?: R4.IAppointment
  errorWhileCreatingAppointment: boolean
  errorReason?: string
  decidingAction?: boolean
  question?: R4.IQuestionnaire
}
