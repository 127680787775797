import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'

interface OccupationTileProps {
  type?: string
}

export const TherapyListHeader: React.FC<OccupationTileProps> = ({
  type,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const defaultBackgroundColor = useTheme().palette.background.default

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      width='100%'
      //   border={4}
      style={{
        // boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        // borderColor: '#F8F8F8',
        width: '100%',
        height: '40px',
        backgroundColor: defaultBackgroundColor,
      }}
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          width='15%'
          paddingLeft={2}
        >
          {type && type === 'appointment' && (
            <Typography variant='subtitle1' color='initial'>
              Time Details
            </Typography>
          )}
          {type && type !== 'appointment' && (
            <Typography variant='subtitle1' color='initial'>
              IPD Details
            </Typography>
          )}
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='center'
          paddingRight={6}
          width='15%'
        >
          <Typography variant='subtitle1' color='initial'>
            Patient Details
          </Typography>
        </Box>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          width='50%'
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box
              width='51%'
              px={0.5}
              flexDirection='row'
              justifyContent='center'
              paddingLeft={6}
            >
              <Typography variant='subtitle1' color='initial'>
                Treatment Details
              </Typography>
            </Box>
            <Box
              width='34%'
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              px={1.2}
            >
              <Typography variant='subtitle1' color='initial'>
                Medication Details
              </Typography>
            </Box>
            <Box
              width='15%'
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
            >
              <Typography variant='subtitle1' color='initial'>
                Status
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
