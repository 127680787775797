import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirSlotDetail } from 'models/fhirSlotDetail'

// eslint-disable-next-line @typescript-eslint/comma-dangle
export enum ACTIONS {
  SlotSelection,
  PatientSelection,
}

export interface AppointmentManagementStatus {
  currentAction: ACTIONS
  enableContinueButton: boolean
  isWalkIn?: boolean
  isQuickEntry?: boolean
  selectedSlot?: FhirSlotDetail
  selectedPatient?: R4.IPatient
  creatingAppointment: boolean
  appointmentCreatedSuccessfully: boolean
  createdAppointment?: R4.IAppointment
  errorWhileCreatingAppointment: boolean
  errorReason?: string
  decidingAction?: boolean
  question?: R4.IQuestionnaire
  practRole?: R4.IPractitionerRole
}
