import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getAddressFromServiceRequest,
  getTestNameFromOrderDetails,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { getCommunicationRequestPayloadString } from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { ShowIngredients } from './ShowIngrenient'

interface MedicationTileProps {
  instructions: R4.ICommunicationRequest[]
  onDeleteClicked?: () => {}
  onEditClicked: (ins: R4.ICommunicationRequest) => void
  prescription?: boolean
  split?: boolean
}

export const InstructionsTableTileForTile: React.FC<MedicationTileProps> = ({
  instructions,
  onDeleteClicked,
  onEditClicked,
  prescription,
  split,
}: MedicationTileProps) => {
  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        {prescription === undefined && (
          <Grid container direction='row' id='vitals'>
            <Grid item xs={11} style={{ backgroundColor: kDialogueBackground }}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Instructions
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} style={{ backgroundColor: kDialogueBackground }}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}
        {instructions.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor:
                  index % 2 === 0
                    ? 'white'
                    : prescription
                    ? 'white'
                    : kDialogueBackground,
              }}
            >
              <Grid item xs={split && split === true ? 11 : 12}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      alignItems='center'
                      minHeight={30}
                      px={1}
                      flexDirection='row'
                    >
                      <Typography
                        key={e.id ?? ''}
                        // display='inline'
                        // dangerouslySetInnerHTML={{
                        //   __html: getCommunicationRequestPayloadString(e) ?? '',
                        // }}
                        onClick={() => {
                          setFullText(
                            getCommunicationRequestPayloadString(e) ?? ''
                          )
                          setShowText(true)
                        }}
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {getCommunicationRequestPayloadString(e) ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {split && split === true && (
                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        alignItems='center'
                        minHeight={30}
                        px={1}
                        flexDirection='row'
                      >
                        <Tooltip title='Edit' id='chief_tool_edit'>
                          <IconButton
                            aria-label='btn_ord_cancel'
                            color='primary'
                            id='ins_edit_button'
                            style={{ padding: 0 }}
                            onClick={() => {
                              onEditClicked(e)
                            }}
                          >
                            <img
                              id='chief_tool_edit_img'
                              src={`${process.env.PUBLIC_URL}/editVector.png`}
                              alt='Edit'
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        dialogText='Instruction'
        notesData={fullText!}
      />
    </Box>
  )
}
