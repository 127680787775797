import {
  ContactPointSystemKind,
  IPatient,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/therapyPageTheme'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import {
  getDisplayOfSystemFromCodableConcept,
  getLastNameOfPatient,
  getMedicationForTherapyForMobile,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getStatusOfTherapy,
  isAfterTherapyFinal,
  isPastDateForTherapy,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'

interface Props {
  open: boolean
  onClose: () => void
  therapyBasicDetails: FhirClinicTherapyBasic
  patient: IPatient
  treatmentPlanData: TreatmentPlanData
  appointmentRefId?: string
  onCompleteClicked: (
    therapyBasicDetails: FhirClinicTherapyBasic,
    patient: IPatient,
    treatmentPlanData: TreatmentPlanData
  ) => void

  onCancelTherapyClicked: (
    therapyBasicDetails: FhirClinicTherapyBasic,
    patient: IPatient,
    treatmentPlanData: TreatmentPlanData
  ) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'fit-content',
      border: `1px solid ${theme.palette.divider}`,

      backgroundColor: theme.palette.background.paper,
    },
  })
)

export const ScheduledTherapyDetailsSheet: React.FC<Props> = ({
  open,
  onClose,
  therapyBasicDetails,
  patient,
  appointmentRefId,
  treatmentPlanData,
  onCompleteClicked,
  onCancelTherapyClicked,
}) => (
  <BottomSheet
    open={open}
    onDismiss={onClose}
    initialFocusRef={false}
    snapPoints={({ minHeight, maxHeight }) => [minHeight + 32, maxHeight / 0.6]}
    // Opens the largest snap point by default, unless the user selected one previously
    defaultSnap={({ lastSnap, snapPoints }) =>
      lastSnap ?? Math.min(...snapPoints) + 32
    }
    header={
      <Box display='flex' flexDirection='row'>
        <Box>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355ZM14.75 16C14.75 16.4142 14.4142 16.75 14 16.75H10C9.58579 16.75 9.25 16.4142 9.25 16C9.25 15.5858 9.58579 15.25 10 15.25H14C14.4142 15.25 14.75 15.5858 14.75 16ZM16 12.75C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16ZM18.75 8C18.75 8.41421 18.4142 8.75 18 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H18C18.4142 7.25 18.75 7.58579 18.75 8Z'
              fill='#1C274C'
              style={{ fill: '#1C274C', fillOpacity: 1 }}
            />
          </svg>
        </Box>
        <Box width={8} />
        <Box>
          <Typography
            style={{
              color: '#19274F',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-1px',
            }}
          >
            Therapy Details
          </Typography>
        </Box>
      </Box>
    }
    footer={
      <Box
        display='flex'
        width='100%'
        style={{
          gap: '8px',
        }}
      >
        <Button
          style={{
            width: '100%',
            backgroundColor: '#C00010',
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '-0.16px',
            display: 'flex',
            flexGrow: 1,
            borderRadius: 8,
          }}
          onClick={() => {
            onCancelTherapyClicked(
              therapyBasicDetails,
              patient,
              treatmentPlanData
            )
          }}
        >
          Cancel
        </Button>
        {isPastDateForTherapy(
          moment(treatmentPlanData.date).format('YYYY-MM-DD')
        ) &&
          isAfterTherapyFinal(
            moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm'),
            moment(treatmentPlanData.date).format('YYYY-MM-DD')
          ) && (
            <Button
              style={{
                width: '100%',
                backgroundColor: kPrimaryMain,
                color: '#ffffff',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                flexGrow: 1,
                letterSpacing: '-0.16px',
                borderRadius: 8,
              }}
              onClick={() => {
                onCompleteClicked(
                  therapyBasicDetails,
                  patient,
                  treatmentPlanData
                )
              }}
            >
              Complete
            </Button>
          )}
      </Box>
    }
  >
    <Box
      display='flex'
      flexDirection='column'
      px={2}
      style={{
        gap: '16px',
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        style={{
          backgroundColor: '#DBDBF3',
          borderRadius: 8,
          padding: '8px 16px',
        }}
        width='100%'
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' alignSelf='stretch'>
            <Typography
              variant='subtitle1'
              color='initial'
              style={{
                color: '#19274F',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.16px',
              }}
            >
              {getNameOfPatient(patient)} {getMiddleNameOfPatient(patient)}{' '}
              {getLastNameOfPatient(patient)}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
          >
            <Box display='flex' flexGrow={1}>
              <Typography
                style={{
                  color: '#19274F',
                  fontSize: '12px',
                  fontWeight: 400,

                  letterSpacing: '-0.12px',
                }}
              >
                {getTelecomOfPatient(patient, ContactPointSystemKind._phone)}
              </Typography>
            </Box>
            <Box display='flex' flexGrow={1}>
              <Typography
                style={{
                  color: '#19274F',
                  fontSize: '12px',
                  fontWeight: 400,

                  letterSpacing: '-0.12px',
                }}
              >
                {appointmentRefId ?? ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' alignSelf='stretch'>
            <Typography
              variant='subtitle1'
              color='initial'
              style={{
                color: '#19274F',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.16px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              Treatment Timing
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row'>
            <Typography
              style={{
                color: '#8C9097',
                fontSize: '12px',
                fontWeight: 400,

                letterSpacing: '-0.12px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              {moment(therapyBasicDetails.start).format('hh:mm A')} -{' '}
              {moment(therapyBasicDetails.end).format('hh:mm A')}
            </Typography>
          </Box>
          <Box height={8} borderBottom='1px solid #DADADA' width='100%' />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' alignSelf='stretch'>
            <Typography
              variant='subtitle1'
              color='initial'
              style={{
                color: '#19274F',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.16px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              Treatment Details
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row'>
            <Typography
              style={{
                color: '#8C9097',
                fontSize: '12px',
                fontWeight: 400,

                letterSpacing: '-0.12px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              {getDisplayOfSystemFromCodableConcept(
                treatmentPlanData?.treatmentPlan?.code
              ) ?? ''}
            </Typography>
          </Box>
          <Box height={8} borderBottom='1px solid #DADADA' width='100%' />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' alignSelf='stretch'>
            <Typography
              variant='subtitle1'
              color='initial'
              style={{
                color: '#19274F',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.16px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              Medication Details
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row'>
            <Typography
              style={{
                color: '#8C9097',
                fontSize: '12px',
                fontWeight: 400,

                letterSpacing: '-0.12px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              {getMedicationForTherapyForMobile(
                treatmentPlanData?.treatmentPlan?.supportingInfo
              ) ?? 'Nil'}
            </Typography>
          </Box>
          <Box height={8} borderBottom='1px solid #DADADA' width='100%' />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' alignSelf='stretch'>
            <Typography
              variant='subtitle1'
              color='initial'
              style={{
                color: '#19274F',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.16px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              Status
            </Typography>
          </Box>
          <Box display='flex' flexDirection='row'>
            <Typography
              style={{
                color: '#8C9097',
                fontSize: '12px',
                fontWeight: 400,

                letterSpacing: '-0.12px',
                fontFeatureSettings: "'dlig' on",
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              {getStatusOfTherapy(treatmentPlanData?.treatmentPlan!)}
            </Typography>
          </Box>
          <Box height={8} width='100%' />
        </Box>
      </Box>
    </Box>
  </BottomSheet>
)
