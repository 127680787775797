/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
  ClearOutlined,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight, kDialogueBackground } from 'configs/styles/muiThemes'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addDietOPD,
  resetAddDiet,
} from 'redux/fhirMedicalResources/opd/diet/dietAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  bowels,
  breathe,
  dietType,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingIndicator,
} from 'wello-web-components'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { addBowels } from 'utils/fhirResoureHelpers/observationHelpers'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onDietAdd: () => void
}
export const AddBowelsOPD: React.FC<Props> = ({
  open,
  onClose,
  onDietAdd,
  fhirAppointmentDetails,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [diet, setDiet] = useState<R4.ICoding>()
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function addBowelsData() {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    addBowels(fhirAppointmentDetails, additionalNotes ?? '', diet!)
      .then((e: boolean) => {
        setLoading(false)
        if (e === true) {
          setDiet(undefined)
          setAdditionalNotes('')
          onDietAdd()
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          dispatch(
            showErrorAlert('Error while adding details. Please try again later')
          )
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert('Error while adding details. Please try again later')
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setDiet(undefined)
        setAdditionalNotes('')
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Bowels</DialogTitle>
      <DialogContent dividers={true}>
        {loading && <WelloLoadingIndicator message='Adding details' />}

        {loading === false && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloSelectFHIRCodingWithAutocomplete
                        title='Bowels'
                        id='bow_5'
                        availableCodings={bowels}
                        onChanged={(type) => {
                          setDiet(type)
                        }}
                      />
                    </Box>
                    {/* <WelloSelectFHIRCoding
                      title='Diet'
                      availableCodings={dietType}
                      onChanged={(type) => {
                        setDiet(type)
                      }}
                      id='diet_5'
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                    /> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='diet_6'
                      type='string'
                      value={additionalNotes}
                      variant='outlined'
                      onChange={(event) => {
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setDiet(undefined)
              setAdditionalNotes('')
            }}
            id='diet_7'
            disabled={loading}
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              addBowelsData()
            }}
            variant='contained'
            color='primary'
            id='diet_8'
            disableElevation
            disabled={diet === undefined || loading}
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
