import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isOrgAdmin } from 'services/userDetailsService'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { getGroupedConditions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'

import { logger } from 'utils/logger'
import { condition } from 'wello-fhir-l10n/dist/in/hi'
import { MedicalConditionAssessmentStatus } from './medicalAssessmentConditionsType'

const initialState: MedicalConditionAssessmentStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingConditions: false,
}

const medicalAssessmentConditionSlice = createSlice({
  name: 'medicalAssessmentConditionSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<MedicalConditionAssessmentStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableConditions = action.payload.availableConditions
      state.groupedConditions = action.payload.groupedConditions
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingConditions =
        action.payload.errorWhileSearchingConditions
      state.groupedDateWiseConditions = action.payload.groupedDateWiseConditions
    },
  },
})

export const requestAssessmentMedicalConditions =
  (selectedPatient: R4.IPatient): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicalConditionAssessmentStatus = {
      searchingConditions: true,
      errorWhileSearchingConditions: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(medicalAssessmentConditionSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        category: 'encounter-diagnosis',
        _count: 500,
        _sort: 'onset-age',
        'verification-status': 'confirmed',
      }
      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      const visitContext = sessionStorage.getItem(CURRENT_VISIT_REFERENCE)

      if (visitContext) {
        const response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            `/Condition`,
            visitContext,
            searchParameters
          )

        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (resp._tag === 'Left') {
          state.errorWhileSearchingConditions = true
          state.searchingConditions = false

          dispatch(medicalAssessmentConditionSlice.actions.updatedStatus(state))
        } else {
          const appointmentResponse: R4.IBundle = resp.right
          if (appointmentResponse?.total && appointmentResponse?.total > 0) {
            const finalData = getGroupedConditions(
              appointmentResponse.entry?.map(
                (item) => item.resource as R4.ICondition
              ) ?? []
            )
            const finalGroupedData: GroupedConditionData[] = []
            if (finalData.length > 0) {
              for (let i = 0; i < finalData.length; i++) {
                if (finalGroupedData.length === 0) {
                  if (finalData[i].date) {
                    finalGroupedData.push({
                      date: finalData[i].date!,
                      condition: [finalData[i].condition],
                    })
                  }
                } else {
                  const indexData = finalGroupedData.findIndex(
                    (x) =>
                      moment(x.date).format('YYYY-MM-DD') ===
                      moment(finalData[i].date).format('YYYY-MM-DD')
                  )
                  if (indexData > -1) {
                    finalGroupedData[indexData].condition.push(
                      finalData[i].condition
                    )
                  } else {
                    finalGroupedData.push({
                      date: finalData[i].date!,
                      condition: [finalData[i].condition],
                    })
                  }
                }

                //   finalGroupedData[indexData].condition.push(finalData[i].condition)
              }
            }

            finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))
            state.resultsAvailable = true
            state.searchingConditions = false
            state.groupedConditions = finalData
            state.groupedDateWiseConditions = finalGroupedData
            state.availableConditions = appointmentResponse.entry?.map(
              (item) => item.resource as R4.ICondition
            )
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingConditions = false
            dispatch(
              medicalAssessmentConditionSlice.actions.updatedStatus(state)
            )
          } else {
            const errorSearchDoctor: MedicalConditionAssessmentStatus = {
              searchingConditions: false,
              errorWhileSearchingConditions: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              medicalAssessmentConditionSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: MedicalConditionAssessmentStatus = {
        searchingConditions: false,
        errorWhileSearchingConditions: true,
        resultsAvailable: false,
        errorReason: 'Error fetching details',
      }
      dispatch(
        medicalAssessmentConditionSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default medicalAssessmentConditionSlice.reducer
