import axios, { CancelTokenSource } from 'axios'

import { PractitionerWithRole } from 'models/practitionerWithRole'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'

export async function getUserWithPhoneNumber(
  unitId: string,
  phoneNumber: string,
  searchKey: string
): Promise<PractitionerWithRole[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters = {
    'practitioner:Practitioner.phone': phoneNumber,
    organization: `Organization/${unitId}`,
    _include: 'PractitionerRole:practitioner',
  }
  axios.CancelToken.source()
  if (cancelTokenStore.has(searchKey)) {
    const controlTokenForSearch: CancelTokenSource =
      cancelTokenStore.get(searchKey)
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete(searchKey)
  }
  cancelTokenStore.set(searchKey, axios.CancelToken.source())
  try {
    const response: any = await fhirClient.doGetResource(
      '/PractitionerRole',
      searchParameters,
      (cancelTokenStore.get(searchKey) as CancelTokenSource).token
    )

    if (response && response.entry && response.entry.length > 0) {
      const res = getPractitionersWithRoleObject(response) ?? []
      return res
    }
    return []
  } catch (error) {}
  return []
}

export async function getUserWithEmail(
  unitId: string,
  emailAddress: string,
  searchKey: string
): Promise<PractitionerWithRole[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters = {
    'practitioner:Practitioner.email': emailAddress.trim(),
    organization: `Organization/${unitId}`,
    _include: 'PractitionerRole:practitioner',
  }

  axios.CancelToken.source()
  if (cancelTokenStore.has(searchKey)) {
    const controlTokenForSearch: CancelTokenSource =
      cancelTokenStore.get(searchKey)
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete(searchKey)
  }
  cancelTokenStore.set(searchKey, axios.CancelToken.source())
  try {
    const response: any = await fhirClient.doGetResource(
      '/PractitionerRole',
      searchParameters,
      (cancelTokenStore.get(searchKey) as CancelTokenSource).token
    )

    if (response && response.entry && response.entry.length > 0) {
      const res = getPractitionersWithRoleObject(response) ?? []
      return res
    }
    return []
  } catch (error) {}
  return []
}

export function validateEmailIsExistingOne(
  existingUsers: PractitionerWithRole[],
  email: string
) {
  const existingUser = existingUsers.findIndex(
    (user) =>
      user.primaryContact.trim().toLowerCase() === email.trim().toLowerCase()
  )
  if (existingUser > -1) {
    return existingUsers[existingUser]
  }
  return undefined
}

export function validatePhoneIsExistingOne(
  existingUsers: PractitionerWithRole[],
  phone: string
) {
  let checkPhone = phone

  if (phone.length === 10) {
    checkPhone = `+91${phone}`
  }
  const existingUser = existingUsers.findIndex(
    (user) => user.phone.trim() === checkPhone.trim()
  )
  if (existingUser > -1) {
    return existingUsers[existingUser]
  }
  return undefined
}

export function validatePhoneIsExistingOneWithSameUnit(
  existingUsers: PractitionerWithRole[],
  phone: string,
  unitOrgId: string
) {
  let checkPhone = phone

  if (phone.length === 10) {
    checkPhone = `+91${phone}`
  }
  const existingUser = existingUsers.filter(
    (user) => user.phone.trim() === checkPhone.trim()
  )
  if (existingUser.length > 0) {
    const userForSameUnit = existingUser.findIndex(
      (user) =>
        user.roleObject.organization?.reference?.split('/')[1].trim() ===
        unitOrgId.trim()
    )
    if (userForSameUnit > -1) {
      return { practRole: existingUser[userForSameUnit], isSmeUnit: true }
    }
    return { practRole: existingUser[0], isSmeUnit: false }
  }

  return undefined
}

export function validateEmailIsExistingOneWithSameUnit(
  existingUsers: PractitionerWithRole[],
  email: string,
  unitOrgId: string
) {
  const existingUser = existingUsers.filter(
    (user) =>
      user.primaryContact.trim().toLowerCase() === email.trim().toLowerCase()
  )
  if (existingUser.length > 0) {
    const userForSameUnit = existingUser.findIndex(
      (user) =>
        user.roleObject.organization?.reference?.split('/')[1].trim() ===
        unitOrgId.trim()
    )
    if (userForSameUnit > -1) {
      return { practRole: existingUser[userForSameUnit], isSmeUnit: true }
    }
    return { practRole: existingUser[0], isSmeUnit: false }
  }

  return undefined
}

export function validateRoleIsExistingOneWithSameUnit(
  existingUsers: PractitionerWithRole[],
  email: string,
  role: string,
  unitOrgId: string
): boolean {
  let dataCheck: boolean = false
  const existingUser = existingUsers.filter(
    (user) =>
      user.primaryContact.trim().toLowerCase() === email.trim().toLowerCase() &&
      user.role &&
      user.role === role
  )

  if (existingUser.length > 0) {
    const userForSameUnit = existingUser.findIndex(
      (user) =>
        user.roleObject.organization?.reference?.split('/')[1].trim() ===
        unitOrgId.trim()
    )

    if (userForSameUnit > -1) {
      dataCheck = true
    }
    if (userForSameUnit === -1) {
      dataCheck = false
    }
  }

  return dataCheck
}

export function validateUnitAdminInvite(
  existingUsers: PractitionerWithRole[],
  role: string,
  unitOrgId: string
): boolean {
  let dataCheck: boolean = false
  console.log(existingUsers)

  const existingUser = existingUsers.filter(
    (user) => user.role && user.role === role
  )

  if (existingUser.length > 0) {
    const userForSameUnit = existingUser.findIndex(
      (user) =>
        user.roleObject.organization?.reference?.split('/')[1].trim() ===
        unitOrgId.trim()
    )

    if (userForSameUnit > -1) {
      dataCheck = true
    }
    if (userForSameUnit === -1) {
      dataCheck = false
    }
  }

  return dataCheck
}

export function profileCompletedForUnitAdmin(
  existingUsers: PractitionerWithRole[],
  role: string,
  unitOrgId: string
) {
  const existingUser = existingUsers.filter(
    (user) =>
      user.status === 'Inactive' && user.role && user.role === 'Unit Admin'
  )
  if (existingUser.length > 0) {
    const userForSameUnit = existingUser.findIndex(
      (user) =>
        user.roleObject.organization?.reference?.split('/')[1].trim() ===
        unitOrgId.trim()
    )
    if (userForSameUnit > -1) {
      return { practRole: existingUser[userForSameUnit], isSmeUnit: true }
    }
    return { practRole: existingUser[0], isSmeUnit: false }
  }

  return undefined
}
