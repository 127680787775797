import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getUserDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getOrgDetailsAlongWithServices } from 'utils/fhirResoureHelpers/organizationHelpers'
import { logger } from 'utils/logger'
import { UnitsOfOrganizationStatus } from './unitsOfOrganizationType'

const initialState: UnitsOfOrganizationStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const unitsOfOrganizationSlice = createSlice({
  name: 'unitsOfOrganizationSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<UnitsOfOrganizationStatus>
    ) {},

    searchResults(state, action: PayloadAction<UnitsOfOrganizationStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
      state.unitServices = action.payload.unitServices
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<UnitsOfOrganizationStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
      state.unitServices = action.payload.unitServices
    },

    errorWhileSearching(
      state,
      action: PayloadAction<UnitsOfOrganizationStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
      state.unitServices = action.payload.unitServices
    },
    resetState(state, action: PayloadAction<UnitsOfOrganizationStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.unitsList = undefined
      state.unitServices = undefined
    },
  },
})

export const resetUnitServicesSlice =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: UnitsOfOrganizationStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      unitServices: undefined,
      unitsList: undefined,
    }
    dispatch(unitsOfOrganizationSlice.actions.resetState(state))
  }

export const searchUnits =
  (unitId?: string): AppThunk =>
  async (dispatch: any) => {
    const errorFetchingUnits: UnitsOfOrganizationStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      unitsOfOrganizationSlice.actions.errorWhileSearching(errorFetchingUnits)
    )
    try {
      const mainOrganization: R4.IOrganization | undefined =
        getUserDetails()?.mainOrganization
      let searchParametes = {}
      if (mainOrganization) {
        searchParametes = {
          partof: `${mainOrganization?.resourceType}/${
            mainOrganization.id ?? ''
          }`,
          _count: 200,
        }
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let response: any
      if (isOrgAdmin()) {
        response = await fhirClient.doGetResource(
          '/Organization?_count=300&_revinclude=HealthcareService:organization&_tag:not=mirror-resource',
          searchParametes
        )
      }
      if (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) {
        response = await fhirClient.doGetResource(
          `/Organization?_id=${unitId}&_revinclude=HealthcareService:organization&_tag:not=mirror-resource`,
          searchParametes
        )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (orgResponse.total) {
          if (orgResponse.total > 0) {
            if (orgResponse.entry) {
              const orgArray: R4.IOrganization[] = orgResponse.entry.map(
                (item) => item.resource as R4.IOrganization
              )

              const unitServicesData =
                getOrgDetailsAlongWithServices(orgResponse)

              orgArray.sort((a, b) =>
                (a.name ?? '')
                  .toLowerCase()
                  .localeCompare((b.name ?? '').toLowerCase())
              )
              const fetchUnitListResult: UnitsOfOrganizationStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                unitsList: orgArray,
                unitServices: unitServicesData,
                totalCount: orgResponse.total,
              }
              dispatch(
                unitsOfOrganizationSlice.actions.searchResults(
                  fetchUnitListResult
                )
              )
              return
            }
          }
        }
        const noSearchResults: UnitsOfOrganizationStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          unitsOfOrganizationSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return
      }
      const errorWhileSearchPatient: UnitsOfOrganizationStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        unitsOfOrganizationSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: UnitsOfOrganizationStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        unitsOfOrganizationSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export default unitsOfOrganizationSlice.reducer
