import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchIPDAppointmentDetails,
  resetIPDDetails,
} from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'

import {
  requestForDateWiseIPDList,
  resetCaseSheetIPD,
} from 'redux/clinic/ipdmanagement/ipdSearch/caseSheetSearchSlice'

import { RootState } from 'redux/rootReducer'

import { useWindowSize } from 'rooks'

import SimpleBar from 'simplebar-react'
import { getCompleteDateStringWithOutDay, isToday } from 'utils/dateUtil'

import { desktopScreenWidth } from 'utils/screen_utils'

import { CaseSheetIPDTile } from 'views/components/ipd/ipdAppointmentList/caseSheetIPDTile'

import { IPDDetails } from 'views/components/ipd/IpdDetails'
import { IPDDetailsPopUp } from 'views/components/ipd/IpdDetailsPopUp'

import '../../../../App.css'

export const CaseSheet: React.FC = () => {
  const selectedDateQ = useSelector(
    (state: RootState) => state.caseSheetSearchSlice.selectedDate
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] =
    useState<Date | undefined>(selectedDateQ)

  const [name, setName] = useState<string>('')

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.caseSheetSearchSlice
  )
  sessionStorage.removeItem('version')
  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    appointmentSearchSlice.selectedStatuses
  )
  const [selectedIpd, setSelectedIPd] = useState<FhirClinicIpdDetails>()

  const { innerWidth } = useWindowSize()

  useEffect(() => {
    dispatch(resetCaseSheetIPD())
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
        paddingTop: '16px',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              <TextField
                variant='outlined'
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    dispatch(
                      requestForDateWiseIPDList(
                        event.target.value,
                        undefined,
                        ['active', 'completed'],
                        undefined,
                        undefined,
                        [],
                        []
                      )
                    )
                  } else {
                    dispatch(resetCaseSheetIPD())
                  }

                  setName(event.target.value)
                }}
                size='small'
                autoFocus={true}
                placeholder='Search By Patient Name'
                value={name}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <img
                        src={`${process.env.PUBLIC_URL}/search.png`}
                        alt='s'
                        style={{ paddingLeft: name ? 1 : 0 }}
                      />
                    </IconButton>
                  ),
                  endAdornment: name && (
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setName('')
                        dispatch(resetCaseSheetIPD())
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                }}
              />

              {appointmentSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                  px={1}
                >
                  <CircularProgress size={25} />
                </Box>
              )}
              {/* {!isTherapist() && (
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-end'
                  alignContent='flex-end'
                  flexGrow='1'
                >
                  <Button
                    variant='contained'
                    color='primary'
                    title='Book Appointment (IPD)'
                    id='add_appointments_ipd'
                    onClick={() => {
                      sessionStorage.removeItem('selecttedNames')
                      setOpenIpdCreate(true)
                    }}
                  >
                    Admit New Patient (IPD)
                  </Button>
                  {isCurrentUnitIsWellonessCenter() && (
                    <Button
                      variant='contained'
                      color='primary'
                      title={t('labelCommon:add_appointment')}
                      id='add_appointments_opd'
                      onClick={() => {
                        setOpenAppointmentTypeDialog(true)
                      }}
                    >
                      Add Appointment (OPD)
                    </Button>
                  )}

                  <Button
                    variant='contained'
                    color='primary'
                    id='add_patient'
                    onClick={() => {
                      setOpenAddPatientPopup(true)
                    }}
                  >
                    {t('labelCommon:add_patient')}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setShowReportGenerationOption({
                        open: true,
                        onCancelActionClicked: () => {
                          setShowReportGenerationOption({ open: false })
                        },
                      })
                    }}
                  >
                    Reports
                  </Button>
                </Box>
              )} */}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No IPD available
                  </Typography>

                  {/* {selectedIpdStatuses.length > 0 &&
                    selectedIpdStatuses[0] !== 'completed' && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isTherapist()
                          ? 'No Therapies to be performed'
                          : name.length > 0
                          ? 'No IPD found for this patient'
                          : 'No IPD available'}
                      </Typography>
                    )} */}
                </Box>
              )}

              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching IPD Appointments
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.dateWiseAppointments && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    overflow='auto'
                    height='100%'
                    flexGrow={2}
                  >
                    <List
                      id='scrollableDiv'
                      subheader={<li style={{ padding: 0, width: '100%' }} />}
                      style={{
                        padding: 0,
                        width: '100%',
                        overflow: 'auto',
                        height: '100%',
                      }}
                    >
                      {/* {(appointmentSearchSlice.recordsCount === undefined ||
                        appointmentSearchSlice.recordsCount === 0) &&
                        !appointmentSearchSlice.searchingAppointments && (
                          <Typography variant='body1' color='initial'>
                            No IPD's available .
                          </Typography>
                        )} */}
                      <InfiniteScroll
                        dataLength={appointmentSearchSlice.recordsCount ?? 0}
                        scrollThreshold='300px'
                        next={() => {
                          dispatch(
                            requestForDateWiseIPDList(
                              name,
                              selectedDate,
                              selectedIpdStatuses,
                              appointmentSearchSlice.pageState,
                              appointmentSearchSlice.availableAppointments
                                ?.length,
                              appointmentSearchSlice.dateWiseAppointments,
                              appointmentSearchSlice.availableAppointments,
                              undefined,
                              true
                            )
                          )
                        }}
                        hasMore={
                          (appointmentSearchSlice.availableAppointments
                            ?.length ?? 0) <
                          (appointmentSearchSlice.recordsCount ?? -1)
                        }
                        loader=''
                        endMessage={
                          appointmentSearchSlice.searchingAppointments ? (
                            <p />
                          ) : (
                            <p style={{ textAlign: 'center' }} />
                          )
                        }
                        scrollableTarget='scrollableDiv'
                      >
                        {appointmentSearchSlice.dateWiseAppointments!.map(
                          (e, indexData: number) => (
                            <li
                              key={`section-${e.date ?? ''}`}
                              style={{ padding: 0, width: '100%' }}
                            >
                              <ul style={{ padding: 0 }}>
                                <ListSubheader
                                  style={{
                                    color: '#333333',
                                    fontWeight: 600,
                                    position: 'sticky',
                                    paddingLeft: 12,
                                    width: '100%',
                                    backgroundColor: '#ececec',
                                  }}
                                >
                                  {getCompleteDateStringWithOutDay(
                                    e.date ?? ''
                                  )}
                                </ListSubheader>
                                {e.orders?.map((item, index: number) => (
                                  <CaseSheetIPDTile
                                    index={`${index}${moment(e.date).format(
                                      'DD-MM-YYYY'
                                    )}`}
                                    ipdAppointmentDetails={item}
                                    isOdd={index % 2 === 0}
                                    dense={selectedIpd !== undefined}
                                    isSelected={
                                      selectedIpd !== undefined &&
                                      item.serviceRequest.id ===
                                        selectedIpd?.serviceRequest.id &&
                                      innerWidth! > desktopScreenWidth
                                    }
                                    onViewClicked={() => {
                                      setSelectedIPd(item)
                                      dispatch(resetIPDDetails())
                                      dispatch(
                                        fetchIPDAppointmentDetails(
                                          item.serviceRequest.id!
                                        )
                                      )
                                    }}
                                    onManageClicked={() => {
                                      //   openAppointment(item)
                                    }}
                                    key={
                                      `lab_item_key${item.serviceRequest.id}` ??
                                      ''
                                    }
                                  />
                                ))}
                              </ul>
                            </li>
                          )
                        )}
                      </InfiniteScroll>
                    </List>
                  </Box>

                  {selectedIpd && innerWidth! > desktopScreenWidth && (
                    <Box
                      display='flex'
                      overflow='auto'
                      width='90%'
                      paddingTop={5.8}
                    >
                      <IPDDetails
                        ipdDetailsDataFinal={selectedIpd}
                        onCloseClicked={() => {
                          setSelectedDate(selectedDate)
                          setSelectedIPd(undefined)
                        }}
                        date={selectedDate}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>

      {innerWidth! <= desktopScreenWidth && selectedIpd && (
        <IPDDetailsPopUp
          ipdDetails={selectedIpd}
          onCloseClicked={() => {
            setSelectedIPd(undefined)
          }}
          open={innerWidth! <= desktopScreenWidth && selectedIpd !== undefined}
        />
      )}
    </div>
  )
}
