/* eslint-disable no-unreachable */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getUserCurrentRole,
  isDoctor,
  isFrontDesk,
  isGre,
  isOrgAdmin,
  isTherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { MedicalProvidersSearchStatus } from './medicalProviderSearchStatusTypes'

const initialState: MedicalProvidersSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const medicalProvidersSearchSlice = createSlice({
  name: 'medicalProvidersSearchSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<MedicalProvidersSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
    },

    searchResults(state, action: PayloadAction<MedicalProvidersSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.doctorList = action.payload.doctorList
      state.doctorRoleList = action.payload.doctorRoleList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<MedicalProvidersSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<MedicalProvidersSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
    },
  },
})

export const searchMedicalProviders =
  (phoneNumber?: string, name?: string, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicalProvidersSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(medicalProvidersSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const searchParameters: any = {
        _include: 'PractitionerRole:practitioner',
      }
      /*   if (roles && roles.length > 0) {
        roles.forEach((e) => {
          selectedRoles = `${e.code},`
        })
      } */

      const currentRole = getUserCurrentRole() ?? []

      if (
        !isUnitAdmin() &&
        !isFrontDesk() &&
        !isOrgAdmin() &&
        !currentRole.includes('receptionist') &&
        !currentRole.includes('nurse') &&
        !currentRole.includes('106296000')
      ) {
        searchParameters._id = getCurrentUserPractitionerRoleDetails().id
      }

      searchParameters.role = type ?? 'doctor,ayurveda,1255370008'
      searchParameters.organization = `Organization/${
        getCurrentUserUnitDetails().id
      }`

      const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()

      if (isDoctor()) searchParameters.practitioner = `Practitioner/${pract.id}`

      if (phoneNumber) {
        searchParameters.phone = `+91${phoneNumber.trim()}`
      }

      if (name) {
        searchParameters['practitioner.name'] = name
      }

      const response: any = await fhirClient.doGetResource(
        '/PractitionerRole',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right

        if (
          practitionerRolesResponses.entry &&
          practitionerRolesResponses.entry.length > 0
        ) {
          const practitionerWithRoleList: PractitionerWithRole[] =
            getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
          const practWithRoleListFinal: PractitionerWithRole[] = []
          const practWithRoleListFinalData: PractitionerWithRole[] = []

          if (isTherapist()) {
            for (let i = 0; i < practitionerWithRoleList.length; i++) {
              //   if (practitionerWithRoleList[i].id !== pract.id ?? '') {
              practWithRoleListFinal.push(practitionerWithRoleList[i])
              //   }
            }
            const currentPractitioner: R4.IPractitioner =
              getCurrentUserPractitionerDetails()
            const gender = getGenderOfDoctor(currentPractitioner)

            for (let j = 0; j < practWithRoleListFinal.length; j++) {
              if (practWithRoleListFinal[j].enabled === true) {
                if (gender === 'male') {
                  if (practWithRoleListFinal[j].gender) {
                    if (practWithRoleListFinal[j].gender === 'male') {
                      practWithRoleListFinalData.push(practWithRoleListFinal[j])
                    }
                  }
                }
                if (gender === 'female') {
                  if (practWithRoleListFinal[j].gender) {
                    if (practWithRoleListFinal[j].gender === 'female') {
                      practWithRoleListFinalData.push(practWithRoleListFinal[j])
                    }
                  }
                }
              }
            }

            if (practWithRoleListFinal.length > 0) {
              state.resultsAvailable = true
              state.doctorRoleList = practWithRoleListFinalData
              state.searching = false
              state.error = false
              dispatch(medicalProvidersSearchSlice.actions.searchResults(state))
              return
            }

            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              medicalProvidersSearchSlice.actions.noDataFoundForSearch(state)
            )
            return
          }
          if (isGre() || isUnitAdmin()) {
            for (let i = 0; i < practitionerWithRoleList.length; i++) {
              if (
                practitionerWithRoleList[i].enabled === true &&
                practitionerWithRoleList[i].status === 'Active'
              ) {
                practWithRoleListFinal.push(practitionerWithRoleList[i])
              }
            }
            if (practWithRoleListFinal.length > 0) {
              state.resultsAvailable = true
              state.doctorRoleList = practWithRoleListFinal
              state.searching = false
              state.error = false
              dispatch(medicalProvidersSearchSlice.actions.searchResults(state))
              return
            }

            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              medicalProvidersSearchSlice.actions.noDataFoundForSearch(state)
            )
            return
          }
          state.resultsAvailable = true
          state.doctorRoleList = practitionerWithRoleList
          state.searching = false
          state.error = false
          dispatch(medicalProvidersSearchSlice.actions.searchResults(state))
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          medicalProvidersSearchSlice.actions.noDataFoundForSearch(state)
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(medicalProvidersSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(medicalProvidersSearchSlice.actions.errorWhileSearching(state))
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default medicalProvidersSearchSlice.reducer
