import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { CurrentLoggedInUser, RoleDetails } from 'models/currentUserDetails'
import React from 'react'
import {
  getCurrentUserMainOrganizationDetails,
  getRolesAsStringFromPractRole,
  getUserCurrentRole,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
/* import md from '../../../../assets/images/icon_doctor-female.svg' */

export interface IAccountDropDownProps {
  user: CurrentLoggedInUser
  onNewRoleSelected?: (e?: RoleDetails) => void
  onLogOutPressed?: () => void
  logOutText?: string
}

export const AccountDropDown: React.FC<IAccountDropDownProps> = ({
  user,
  onNewRoleSelected,
  onLogOutPressed,
  logOutText,
}: IAccountDropDownProps) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const rolesData: string[] = getUserCurrentRole() ?? []
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item>
          <Card elevation={4}>
            <CardContent style={{ padding: 0 }}>
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                style={{
                  textTransform: 'none',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
                onClick={handleToggle}
                endIcon={<ArrowDropDownIcon />}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='center'
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start
                    '
                    alignContent='center'
                    alignItems='baseline'
                  >
                    <FontAwesomeIcon
                      icon={faUserMd}
                      style={{ paddingRight: '2px' }}
                    />
                    {/* <img alt='doctor' src={md} style={{ height: 15, width: 15 }} /> */}

                    <Typography
                      variant='subtitle1'
                      color='primary'
                      style={{
                        fontFamily: 'Oswald',
                        fontVariant: 'normal',
                      }}
                    >
                      {getNameFromHumanName(user.practitioner.name ?? [])}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <Typography variant='subtitle2'>
                      {`${getRolesAsStringFromPractRole(
                        user.practitionerRole!
                      )} \u25CF ${user.unitOrganization?.name}`}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              {rolesData.length > 1 && (
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                  >
                    {user.availableRoles?.map((value, i) => (
                      <React.Fragment key={value.practitionerRole.id}>
                        <MenuItem
                          onClick={(e) => {
                            handleClose(e)
                            if (onNewRoleSelected) onNewRoleSelected(value)
                          }}
                          //   selected={
                          //     rolesData.length > 0 &&
                          //     rolesData.includes(
                          //       getRolesAsStringFromPractRole(
                          //         value.practitionerRole
                          //       ) ?? ''
                          //     )
                          //   }
                        >
                          <Box display='flex' flexDirection='column'>
                            <Typography variant='h6'>
                              {getNameFromHumanName(
                                user.practitioner.name ?? []
                              )}
                            </Typography>
                            {value.locationDetails && (
                              <Typography variant='subtitle2'>
                                {`
${getRolesAsStringFromPractRole(value.practitionerRole)} \u25CF ${
                                  value.locationDetails.name ?? ''
                                }`}
                              </Typography>
                            )}
                            {value.locationDetails === undefined && (
                              <Typography variant='subtitle2'>
                                {`
${getRolesAsStringFromPractRole(value.practitionerRole)} \u25CF ${
                                  getCurrentUserMainOrganizationDetails()
                                    .name ?? ''
                                }`}
                              </Typography>
                            )}
                          </Box>
                        </MenuItem>
                      </React.Fragment>
                    ))}
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e)
                        if (onLogOutPressed) onLogOutPressed()
                      }}
                    >
                      <Typography variant='body1' color='primary'>
                        <Box m={0.5} fontWeight={500} paddingLeft={0.5}>
                          {logOutText ?? 'Log Out'}
                        </Box>
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              )}
              {rolesData.length === 1 && (
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e)
                        if (onLogOutPressed) onLogOutPressed()
                      }}
                    >
                      <Typography variant='body1' color='primary'>
                        <Box m={0.5} fontWeight={500} paddingLeft={0.5}>
                          {logOutText ?? 'Log Out'}
                        </Box>
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
