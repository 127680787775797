import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getSubscriptionInterestDetailsFromBundleBundle } from 'utils/admisnistration/cpg/susbcriptionsHelper'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { getSubscriptionIntentStatus } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { PatientSubscriptionForUnitStatus } from './patientSubscriptionForUnitStatus'

const initialState: PatientSubscriptionForUnitStatus = {
  requesting: false,
  resultsAvailable: false,
  subscriptionStatus: 'not-checked',
  errorInProcess: false,
}

const patientSubscriptionForUnitSlice = createSlice({
  name: 'patientSubscriptionForUnitSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<PatientSubscriptionForUnitStatus>
    ) {
      state.errorReason = action.payload.errorReason

      state.requesting = action.payload.requesting
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorInProcess = action.payload.errorInProcess
      state.errorReason = action.payload.errorReason
      state.subscriptionStatus = action.payload.subscriptionStatus
      state.tasks = action.payload.tasks
    },
    resetState(state, action: PayloadAction<PatientSubscriptionForUnitStatus>) {
      state.errorReason = action.payload.errorReason
      state.requesting = action.payload.requesting
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorInProcess = action.payload.errorInProcess
      state.errorReason = action.payload.errorReason
      state.subscriptionStatus = action.payload.subscriptionStatus
      state.tasks = action.payload.tasks
    },
  },
})

export const resetSusbcriptionStatusOfPatinetSlice =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PatientSubscriptionForUnitStatus = {
      requesting: true,
      errorInProcess: false,
      resultsAvailable: false,
      errorReason: undefined,
      subscriptionStatus: 'not-checked',
    }
    dispatch(patientSubscriptionForUnitSlice.actions.resetState(state))
  }

export const requestForSubscriptionInterestCapture =
  (
    patientId: string,
    encounterId: string,
    planReference: string,
    durationReference: string,
    currentState: PatientSubscriptionForUnitStatus
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PatientSubscriptionForUnitStatus = {
      ...currentState,
      requesting: true,
    }
    dispatch(patientSubscriptionForUnitSlice.actions.updatedStatus(state))
    try {
      console.log('--------------planReference----------------', planReference)
      const fhirClient: EnrolCient = new EnrolCient()
      const unitId = getCurrentUserUnitDetails()?.id!
      const encounterReference = `Encounter/${encounterId}`
      const visitContext = sessionStorage.getItem(CURRENT_VISIT_REFERENCE)
      const contextReference = visitContext

      const response: any = await fhirClient.doCreateEnrolmentFlowRequest(
        `/care-plan-subscription/captureInterestForSubscription?unitId=${unitId}`,
        {
          patientId,
          subscriptionPlanReference: planReference,
          contextEncounterReference: encounterReference,
          planDurationReference: durationReference,
          contextVisitReference: contextReference,
        }
      )
      console.log('--------response-------', response)

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        const updatesState = {
          ...state,
          errorInProcess: true,
          resultsAvailable: true,
          requesting: false,
          errorReason: 'Error while initiating interest',
        }
        console.log('--------error-------', resp.left)
        dispatch(
          patientSubscriptionForUnitSlice.actions.updatedStatus(updatesState)
        )
      } else {
        console.log('--------response------- inside', response)

        const interestTasks = await getInterestsOfPatientForUnit(
          patientId,
          unitId
        )

        const updatesState23: PatientSubscriptionForUnitStatus = {
          ...state,
          errorInProcess: false,
          errorReason: undefined,
          subscriptionStatus: 'interested',
          requesting: false,
          resultsAvailable: true,
          tasks: getSubscriptionInterestDetailsFromBundleBundle(interestTasks),
        }
        dispatch(showSuccessAlert('Interest captured successfully'))

        dispatch(
          patientSubscriptionForUnitSlice.actions.updatedStatus(updatesState23)
        )
        return
      }
      /* */
    } catch (error) {
      const errorSearchDoctor: PatientSubscriptionForUnitStatus = {
        ...state,
        requesting: false,
        resultsAvailable: true,
        errorInProcess: true,
        errorReason: 'Error while initiating interest',
      }
      dispatch(
        patientSubscriptionForUnitSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const requestForSubscriptionStatusOfPatient =
  (patientId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PatientSubscriptionForUnitStatus = {
      ...initialState,
      requesting: true,
    }

    console.log('--------bundleResponse-------')
    console.log('--------bundleResponse-------')

    try {
      const bundleResponse = await getInterestsOfPatientForUnit(
        patientId,
        getCurrentUserUnitDetails()?.id!
      )

      console.log('--------bundleResponse-------', bundleResponse)

      if (bundleResponse.entry && bundleResponse.entry.length > 0) {
        const tasks: R4.ITask[] = bundleResponse.entry
          .filter((entry) => entry.resource?.resourceType === 'Task')
          .map((entry) => entry.resource as R4.ITask)
        const taskResponse: R4.ITask = bundleResponse.entry[0]
          .resource as R4.ITask

        const updatesState: PatientSubscriptionForUnitStatus = {
          ...state,
          errorInProcess: false,
          errorReason: undefined,
          subscriptionStatus: getSubscriptionIntentStatus(taskResponse),
          requesting: false,
          resultsAvailable: true,
          tasks: getSubscriptionInterestDetailsFromBundleBundle(bundleResponse),
        }

        dispatch(
          patientSubscriptionForUnitSlice.actions.updatedStatus(updatesState)
        )
      } else if (
        bundleResponse.total === 0 ||
        (bundleResponse.entry && bundleResponse.entry.length === 0)
      ) {
        const updatesState: PatientSubscriptionForUnitStatus = {
          ...state,
          errorInProcess: false,
          errorReason: undefined,
          subscriptionStatus: 'not-offered',
          requesting: false,
          resultsAvailable: true,
        }
        dispatch(
          patientSubscriptionForUnitSlice.actions.updatedStatus(updatesState)
        )
      }
    } catch (error) {
      const updatesState: PatientSubscriptionForUnitStatus = {
        ...state,
        errorInProcess: false,
        errorReason: undefined,
        subscriptionStatus: 'error',
        requesting: false,
        resultsAvailable: true,
      }
      dispatch(
        patientSubscriptionForUnitSlice.actions.updatedStatus(updatesState)
      )
    }
  }

export async function getInterestsOfPatientForUnit(
  patientId: string,
  unitId: string
): Promise<R4.IBundle> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParams = {
    patient: patientId,
    code: 'subscription-interest',
    'requester:PractitionerRole.organization': `Organization/${unitId}`,
    _sort: '-authored-on',
    _include: ['Task:focus', 'Task:requester', 'Task:owner'],
    status: 'draft,requested,active,accepted',
  }
  const response = await fhirClient.doGetResourceIncludeAndIncludeIterate(
    '/Task',
    searchParams
  )

  const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

  if (resp._tag === 'Left') {
    throw new Error('Error while initiating interest')
  } else {
    const bundleResponse: R4.IBundle = resp.right

    return bundleResponse
  }
}

export default patientSubscriptionForUnitSlice.reducer
