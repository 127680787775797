import { Box, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getLabRequestsOfAppointmentForIPD } from 'redux/consultation/labReferralListIPD/labDiagnosticsListSliceIPD'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { AddMedicationsHandler } from 'views/components/consultation/assessment/addMedication'
import { AddCustomInstructions } from 'views/components/consultation/plan/addCustomInstruction'
import { AddDiagnosticsTests } from 'views/components/consultation/plan/add_diagnostic_orders'
import { RecommendationWrapperForSub } from 'views/components/consultation/plan/cdsRecommendations/cds_reccomendation_wrapper_new'
import { LabDiagnosticRequestsList } from 'views/components/consultation/plan/labDiagnosticList'
import { SpecialistReferralList } from 'views/components/consultation/plan/specialistRequestList'

import { RecommendationWrapperIPD } from './cdsRecomendations/cds_reccomendation_wrapperIPD'
import { CarePlanInstructionListIPD } from './instructionsListIPD'
import { LabDiagnosticRequestsListIPD } from './labDiagnosticListIPD'
import { NextStepPlan } from './nextStepPlan'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const HalfWidthNextStepsOfConsultationIPD: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [showCohorts, setShowCohorts] = useState<boolean>(false)

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)
    const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
      useState<boolean>(false)
    const dispatch = useDispatch()
    const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
      useState(false)
    const [openAddInstruction, setAddInstruction] = useState<boolean>(false)

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' padding={0.5}>
            <NextStepPlan
              planName={t('labelCommon:lab_test')}
              subString=''
              bottomWidget={
                <LabDiagnosticRequestsList
                  patient={fhirAppointmentDetails.patient}
                  practitionerDetails={
                    fhirAppointmentDetails.practitionerDetail
                  }
                  encounter={fhirAppointmentDetails.mainEncounter}
                  followUp={false}
                  supportingInfo={[]}
                />
              }
              onClicked={() => {
                setOpenAddDiagnosticDetails(true)
              }}
            />
          </Box>

          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            <NextStepPlan
              planName='Specialist Referral Note'
              subString=''
              bottomWidget={<SpecialistReferralList />}
            />
          </Box>
          {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName={t('labelCommon:followup_appointment')}
                subString=''
                bottomWidget={
                  <PatientFolloWUpAppointments
                    fhirPatientDetail={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenCreateAppointmentPopup(true)
                }}
              />
            </Box> */}
          {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Referral Appointment'
                subString=''
                bottomWidget={
                  <ReferralRequestList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}

          <Box display='flex' flexDirection='row' padding={0.5}>
            <NextStepPlan
              planName='Instructions'
              subString=''
              bottomWidget={
                <CarePlanInstructionListIPD
                  fhirAppointmentDetails={fhirAppointmentDetails}
                />
              }
              onClicked={() => {
                if (
                  fhirAppointmentDetails.mainEncounter !== undefined &&
                  fhirAppointmentDetails.mainEncounter.id !== undefined
                ) {
                  setAddInstruction(true)
                } else {
                  dispatch(
                    showErrorAlert(
                      'No details have been added as part of Appointment. Please add few details about patient visit'
                    )
                  )
                }
              }}
            />
          </Box>
          <AddDiagnosticsTests
            open={openAddDiagnosticDetails}
            encounter={fhirAppointmentDetails.mainEncounter!}
            patient={fhirAppointmentDetails.patient}
            practitionerDetails={fhirAppointmentDetails.practitionerDetail}
            onClose={() => {
              dispatch(
                getLabRequestsOfAppointment(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.practitionerDetail,
                  fhirAppointmentDetails.mainEncounter,
                  undefined,
                  []
                )
              )
              setOpenAddDiagnosticDetails(false)
            }}
            onLabDiagnosticsAdded={() => {
              dispatch(
                getLabRequestsOfAppointment(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.practitionerDetail,
                  fhirAppointmentDetails.mainEncounter,
                  undefined,
                  []
                )
              )
              setOpenAddDiagnosticDetails(false)
            }}
          />

          {/* <CreateAppointmentHandler
              allowWalkIn={false}
              open={openCreateAppointmentPopup}
              preSelectedDoctor={{
                id:
                  fhirAppointmentDetails.practitionerDetail.practitioner.id ??
                  '',
                roleObject:
                  fhirAppointmentDetails.practitionerDetail.practitionerRole,
                practRoleId:
                  fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
                practitionerObject:
                  fhirAppointmentDetails.practitionerDetail.practitioner,
                name: getNameFromHumanName(
                  fhirAppointmentDetails.practitionerDetail.practitioner.name ??
                    []
                ),
                phone: '',
                fullName: '',
                color: '',
                primaryContact: '',
                status: '',
                enabled: fhirAppointmentDetails.practitionerDetail.practitioner
                  .active
                  ? fhirAppointmentDetails.practitionerDetail.practitioner
                      .active
                  : false,
              }}
              fhirAppointmentDetail={fhirAppointmentDetails}
              onClose={() => {
                setOpenCreateAppointmentPopup(false)
                dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
              }}
              onAppointmentCreated={(createdAppointment) => {
                setOpenCreateAppointmentPopup(false)
                dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
              }}
            /> */}

          {openAddInstruction && (
            <AddCustomInstructions
              open={openAddInstruction}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${
                  fhirAppointmentDetails.mainEncounter!.id
                }`,
              }}
              onClose={() => {
                dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
              onInstructionAdded={() => {
                dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
            />
          )}
        </Box>
      </ReactVisibilitySensor>
    )
  }
