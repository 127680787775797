import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
  PatientGenderKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientProfile } from 'models/patientProfile'
import moment from 'moment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { nationalityValueSet } from 'utils/constants'

import { logger } from 'utils/logger'
import { EditPatientMinimalStatus } from './editPatientMinimalStatus'

const initialState: EditPatientMinimalStatus = {
  updating: false,
  updatedSuccessfully: false,
  error: false,
  errorMessage: '',
  patient: undefined,
}

const editPatientMinimalSlice = createSlice({
  name: 'editPatientMinimalSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<EditPatientMinimalStatus>) {
      state.updating = action.payload.updating
      state.updatedSuccessfully = action.payload.updatedSuccessfully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const updatePatientData =
  (
    oldPatient: R4.IPatient,
    preAddedPatient: R4.IPatient,
    relatedPersonDetails?: R4.IRelatedPerson,
    relatedPersonHS?: R4.IRelatedPerson,
    nationality?: string,
    occupation?: string,
    addressProofBoth?: R4.IAttachment,
    addressProofFront?: R4.IAttachment,
    addressProofBack?: R4.IAttachment,
    passportStartDate?: string,
    expiryDate?: string,
    passportIssueCity?: string,
    passportIssueCountry?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: EditPatientMinimalStatus = {
      updating: true,
      updatedSuccessfully: false,
      error: false,
    }
    dispatch(
      editPatientMinimalSlice.actions.updateStatus(addingCreatePersonState)
    )

    try {
      oldPatient.extension = []
      const unitOrg = getCurrentUserUnitDetails()
      const unitExtension: R4.IExtension = {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit',
        valueReference: {
          id: unitOrg.id,
          reference: `Organization/${unitOrg.id}`,
          display: unitOrg.name,
        },
      }
      oldPatient.extension.push(unitExtension)
      const mainOrganization: R4.IOrganization =
        getCurrentUserMainOrganizationDetails()
      oldPatient.managingOrganization = {
        id: mainOrganization.id ?? '',
        display: mainOrganization.name,
        reference: `${mainOrganization.resourceType}/${mainOrganization.id}`,
      }

      if (nationality) {
        const filteredData = nationalityValueSet.filter(
          (d) => d.code!.trim() === nationality.trim()!
        )
        const nationalityExt: R4.IExtension = {}
        nationalityExt.url =
          'http://hl7.org/fhir/StructureDefinition/patient-nationality'
        nationalityExt.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/CodeSystem/country',
            valueCodeableConcept: {
              text:
                nationality === 'IN' ? 'india' : filteredData[0].display ?? '',
              coding: filteredData.length > 0 ? filteredData : [],
            },
          },
        ]
        oldPatient.extension.push(nationalityExt)
        if (nationality !== 'IN') {
          if (passportStartDate && expiryDate) {
            const passportExtStart: R4.IExtension = {}

            passportExtStart.url =
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
            passportExtStart.valuePeriod = {
              start: moment(passportStartDate).toISOString(),
              end: moment(expiryDate).toISOString(),
            }
            oldPatient.extension.push(passportExtStart)
          }

          if (passportIssueCity && passportIssueCountry) {
            const passportExtCountry: R4.IExtension = {}
            const filteredDataForCountry = nationalityValueSet.filter(
              (d) => d.display! === passportIssueCountry!
            )
            passportExtCountry.url =
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-issued-country-ext'
            passportExtCountry.valueCodeableConcept = {
              text: passportIssueCity,
              coding:
                filteredDataForCountry.length > 0 ? filteredDataForCountry : [],
            }

            oldPatient.extension.push(passportExtCountry)
          }
        }
      }

      if (occupation) {
        const occupationExt: R4.IExtension = {}
        occupationExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
        occupationExt.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/CodeSystem/occupation',
            valueCodeableConcept: {
              text: occupation,
            },
          },
        ]
        oldPatient.extension.push(occupationExt)
      }

      if (addressProofFront) {
        const addressProofFrontExt: R4.IExtension = {}
        addressProofFrontExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
        addressProofFrontExt.valueAttachment = addressProofFront
        oldPatient.extension.push(addressProofFrontExt)
      }
      if (addressProofBoth) {
        const addressProofBothExt: R4.IExtension = {}

        addressProofBothExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
        addressProofBothExt.valueAttachment = addressProofBoth
        oldPatient.extension.push(addressProofBothExt)
      }

      if (addressProofBack) {
        const addressProofFrontExt: R4.IExtension = {}
        addressProofFrontExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
        addressProofFrontExt.valueAttachment = addressProofBack
        oldPatient.extension.push(addressProofFrontExt)
      }

      if (occupation) {
        const occupationExt: R4.IExtension = {}
        occupationExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
        occupationExt.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/CodeSystem/occupation',
            valueCodeableConcept: {
              text: occupation,
            },
          },
        ]
        oldPatient.extension.push(occupationExt)
      }

      const patientBundle: R4.IBundle = getPatientBundle(
        oldPatient,
        preAddedPatient,
        relatedPersonDetails,
        relatedPersonHS
      )
      logger.info('patientDetails')
      logger.info(oldPatient)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '',
        patientBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Patient un decoded', respDecoded.right)

        dispatch(showSuccessAlert('Patient details updated successfully'))

        try {
          if (addressProofFront) {
            await uploadFileDetails(
              addressProofFront,
              "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)",
              'Patient',
              oldPatient.id ?? ''
            )
          }
          if (addressProofBoth) {
            await uploadFileDetails(
              addressProofBoth,
              "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)",
              'Patient',
              oldPatient.id ?? ''
            )
          }
          if (addressProofBack) {
            await uploadFileDetails(
              addressProofBack,
              "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back').value.as(Attachment)",
              'Patient',
              oldPatient.id ?? ''
            )
          }
        } catch (error) {
          logger.info(error)
          dispatch(showErrorAlert('Error in uploading proofs'))
        }
        const successCreatePersonState: EditPatientMinimalStatus = {
          updating: false,
          updatedSuccessfully: true,
          error: false,
          errorMessage: '',
        }
        dispatch(
          editPatientMinimalSlice.actions.updateStatus(successCreatePersonState)
        )
        return
      }
      logger.info('Error while updating patient')

      logger.info(respDecoded.left)

      const errorCreatePersonState: EditPatientMinimalStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while updating patient',
      }
      dispatch(
        editPatientMinimalSlice.actions.updateStatus(errorCreatePersonState)
      )

      dispatch(
        showWarningAlert('Error while adding new address. Please try later')
      )
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: EditPatientMinimalStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(
        editPatientMinimalSlice.actions.updateStatus(errorCreatePersonState)
      )
    }
  }

function getPatientBundle(
  patientDetails: R4.IPatient,
  oldPatient: R4.IPatient,
  relatedPersonDetails?: R4.IRelatedPerson,
  relatedPersonHS?: R4.IRelatedPerson
): R4.IBundle {
  let vData: string = ''
  const versionData = sessionStorage.getItem('version')
  if (versionData !== null) vData = JSON.stringify(versionData)
  const patientData: R4.IPatient = { ...patientDetails }

  const profileTag: R4.ICoding = {
    system:
      'http://wellopathy.com/fhir/india/core/vs/profileCompletionIndicators',
    code: 'profile_details_completed',
  }
  const meta: R4.IMeta = {
    tag: [profileTag],
  }
  patientData.meta = meta

  const patMatchString: string =
    versionData === null
      ? `W/${JSON.stringify(oldPatient?.meta?.versionId ?? ' ')}`
      : `W/${vData}`
  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  if (patMatchString.length > 0) {
    bundleObject.entry?.push({
      fullUrl: `Patient/${patientData.id}`,
      request: {
        ifMatch: patMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Patient/${patientData.id}`,
      },
      resource: patientData,
    })
  } else {
    bundleObject.entry?.push({
      fullUrl: `Patient`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `Patient`,
      },
      resource: patientData,
    })
  }

  if (relatedPersonDetails) {
    const relatedPersonDetailsData: R4.IRelatedPerson = {
      ...relatedPersonDetails,
    }
    relatedPersonDetailsData.patient = {
      reference: `Patient/${patientDetails.id}`,
    }
    bundleObject.entry?.push({
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `RelatedPerson`,
      },
      resource: relatedPersonDetailsData,
    })
  }

  if (relatedPersonHS) {
    const relatedPersonDetailHsData: R4.IRelatedPerson = {
      ...relatedPersonHS,
    }
    relatedPersonDetailHsData.patient = {
      reference: `Patient/${patientDetails.id}`,
    }
    bundleObject.entry?.push({
      fullUrl: `RelatedPerson`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `RelatedPerson`,
      },
      resource: relatedPersonDetailHsData,
    })
  }

  return bundleObject
}

export const resetUpdatePatientStateMinimal = () => (dispatch: AppDispatch) => {
  dispatch(editPatientMinimalSlice.actions.updateStatus(initialState))
}

export default editPatientMinimalSlice.reducer
