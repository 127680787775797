import { R4 } from '@ahryman40k/ts-fhir-types'
import { CircularProgress, TextField, Box } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FhirTherapyListWithChargeDef } from 'models/fhirTherapyList'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { subs } from 'utils/constants'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'
import { ConfirmationDialog } from '../common/confirmationDialog'

export interface CatalogEntrySelectorProps {
  id: string
  title: string
  onChanged: (changedValue: FhirTherapyListWithChargeDef[]) => void
  availableCodings: FhirTherapyListWithChargeDef[]
  preSelectedCoding?: FhirTherapyListWithChargeDef[]
  appointmentTypeDataVal: string
  displayDoneLabel?: boolean
  showPrice?: boolean
}

export const TherapySelectorForPlan: React.FC<CatalogEntrySelectorProps> = ({
  id,
  title,
  onChanged,
  availableCodings,
  preSelectedCoding,
  appointmentTypeDataVal,
  displayDoneLabel,
  showPrice,
}: CatalogEntrySelectorProps) => {
  const [pricePopUp, setPricePricePopUp] = useState<boolean>(false)
  const [therapyName, setTherapyName] = useState<string>('')
  const [dataVal, setDataVal] = useState<FhirTherapyListWithChargeDef>()
  const [item, setItem] = useState<FhirTherapyListWithChargeDef[]>([])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {title.length > 0 && (
        <WelloFormItemLabel title={title} displayDoneLabel={displayDoneLabel} />
      )}

      <Box width='100%'>
        <Autocomplete
          multiple={true}
          id={id}
          getOptionSelected={(option, value) =>
            option.therapy.id === value.therapy.id
          }
          getOptionLabel={(option) => option.therapy.name ?? ''}
          fullWidth
          options={availableCodings}
          onChange={(e, changedValue) => {
            if (changedValue != null) {
              if (
                appointmentTypeDataVal === '304903009' ||
                appointmentTypeDataVal === '33022008'
              ) {
                if (changedValue) {
                  onChanged(changedValue)
                  setItem(changedValue)
                }
              } else if (changedValue) {
                const data = changedValue ?? []
                for (let i = 0; i < data.length; i++) {
                  if (data[i].price > 0 && showPrice === undefined) {
                    setTherapyName(
                      `Price of ${
                        data[i].therapy ? data[i].therapy.name ?? '' : ''
                      } is Rs. ${
                        data[i].price
                      }. Do you want to add to the plan ?`
                    )
                    setDataVal(data[i])
                    setPricePricePopUp(true)
                  } else {
                    onChanged(changedValue)
                    setItem(changedValue)
                  }
                }
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Select Therapy'
              variant='outlined'
              size='small'
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />

        <ConfirmationDialog
          open={pricePopUp}
          displayText={therapyName}
          title='Therapy Info'
          doneButtonText='Yes'
          cancelButtonText='No'
          onClose={() => {
            setPricePricePopUp(false)
          }}
          onDiscard={() => {
            onChanged([dataVal!])
            setItem([dataVal!])
            setPricePricePopUp(false)
          }}
        />
      </Box>
    </Box>
  )
}
