import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromObserVationDateWise,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { FollowUpVitalsDetails } from './followUpVitalsvDetailStatusType'

const initialState: FollowUpVitalsDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const followUpVitalsDetailsSlice = createSlice({
  name: 'followUpVitalsDetailsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<FollowUpVitalsDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.groupedVitals = action.payload.groupedVitals
      state.vitalData = action.payload.vitalData
      state.dateWiseVitals = action.payload.dateWiseVitals
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
    },
  },
})

export const requestVitalDetailsOfFollowUpPatient =
  (selectedPatient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowUpVitalsDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(followUpVitalsDetailsSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        patient: `Patient/${selectedPatient.id}`,
        'encounter.appointment': appointmentId,
        category: 'vital-signs',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Observation?_sort=_lastUpdated',
        appointmentId,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(followUpVitalsDetailsSlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const observationData: R4.IObservation[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IObservation
            ) ?? []

          const vitalLisDataFinal: VitalsData[] =
            getObserVationFfromObserVationDateWise(observationData)

          //   vitalLisDataFinal.sort((a, b) =>
          //     moment(b.startDate).diff(a.startDate)
          //   )
          const dateWise: DateWiseVitals[] =
            getDateWiseVitals(vitalLisDataFinal)

          let observation: any = {}
          for (let i = 0; i < observationData.length; i++) {
            if (observationData[i].component !== undefined) {
              observation = observationData[i]
            }
          }
          if (observationData.length > 0) {
            const serviceId: string = await getServiceRequestId(
              selectedPatient.id ?? ''
            )
            if (serviceId.length > 0) {
              if (observation.component !== undefined) {
                const sysComp: R4.IObservation_Component | undefined =
                  observation.component.find(
                    (val: any) => val.code?.coding?.[0].code === '8480-6'
                  )
                const diaComp: R4.IObservation_Component | undefined =
                  observation.component.find(
                    (val: any) => val.code?.coding?.[0].code === '8462-4'
                  )
                const vitalData: BpCollection = {
                  systolicBp: sysComp ? sysComp.valueQuantity?.value ?? 0 : 0,
                  diastolicBp: diaComp ? diaComp.valueQuantity?.value ?? 0 : 0,
                  serviceRequestId: serviceId,
                }
                const status = await updateEnrolment(vitalData)

                state.resultsAvailable = true
                state.searchingConditions = false
                state.vitalsList = getSortedObservationForIpd(
                  proceduresResponse.entry?.map(
                    (item) => item.resource as R4.IObservation
                  ) ?? []
                )
                state.dateWiseVitals = dateWise
                state.noResultsAvailable = false
                state.errorReason = undefined
                state.vitalData = vitalLisDataFinal
                state.errorWhileSearchingProcedures = false
                state.showAppointment = false
                dispatch(
                  followUpVitalsDetailsSlice.actions.updatedStatus(state)
                )
              }
            } else {
              const vitalLisData: R4.IObservation[] =
                proceduresResponse.entry?.map(
                  (item) => item.resource as R4.IObservation
                ) ?? []
              //
              state.resultsAvailable = true
              state.searchingConditions = false
              state.vitalsList = getSortedObservationForIpd(vitalLisData)
              state.noResultsAvailable = false
              state.vitalData = vitalLisDataFinal
              state.dateWiseVitals = dateWise
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(followUpVitalsDetailsSlice.actions.updatedStatus(state))
            }
          } else {
            const errorSearchDoctor: FollowUpVitalsDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              followUpVitalsDetailsSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          const errorSearchDoctor: FollowUpVitalsDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(
            followUpVitalsDetailsSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: FollowUpVitalsDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        showAppointment: false,
      }
      dispatch(
        followUpVitalsDetailsSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

export const resetVitalSearchState = () => (dispatch: AppDispatch) => {
  dispatch(followUpVitalsDetailsSlice.actions.updatedStatus(initialState))
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default followUpVitalsDetailsSlice.reducer
