import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientProfile } from 'models/patientProfile'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getPatientProfile,
  getPatientProfileFinal,
} from 'utils/common/patientDataConverter'
import { getProfileData } from 'utils/common/patientDataTableHelper'
import { logger } from 'utils/logger'
import { PatientAddStatus } from './patientAddStatusTypes'

const initialState: PatientAddStatus = {
  fetchingPatientDetails: false,
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  updating: false,
  updated: false,
  resultsAvailable: false,
}

const patientHandlerSlice = createSlice({
  name: 'patientHandlerSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PatientAddStatus>) {
      state.fetchingPatientDetails = action.payload.fetchingPatientDetails
      state.resultsAvailable = action.payload.resultsAvailable
      state.error = action.payload.error
      state.patientProfile = action.payload.patientProfile
      state.patientId = action.payload.patientId
    },
    addingPatientDetails(state, action: PayloadAction<PatientAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    updatingPatientDetails(state, action: PayloadAction<PatientAddStatus>) {
      state.updating = action.payload.updating
      state.updated = action.payload.updated
      state.error = action.payload.error
      state.patient = action.payload.patient
    },

    patientDetailsAdded(state, action: PayloadAction<PatientAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patient = action.payload.patient
      state.patientId = action.payload.patientId
    },

    patientDetailsUpdated(state, action: PayloadAction<PatientAddStatus>) {
      state.updating = action.payload.updating
      state.updated = action.payload.updated
      state.error = action.payload.error
      state.patient = action.payload.patient
      state.version = action.payload.version
    },

    resetPatientDetails(state, action: PayloadAction<PatientAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
      state.patientProfile = undefined
      state.version = undefined
      state.patient = undefined
      state.fetchingPatientDetails = false
      state.resultsAvailable = false
      state.patientId = undefined
    },
  },
})

export const getPatientDetailsById =
  (patientId: String, status: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PatientAddStatus = { ...initialState }
    state.fetchingPatientDetails = true
    dispatch(patientHandlerSlice.actions.updatedStatus(state))

    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        `/Patient/${patientId}`
      )

      const resp: E.Either<Errors, R4.IPatient> =
        R4.RTTI_Patient.decode(response)
      if (response === undefined) {
        const currentState = { ...initialState }
        currentState.error = true
        dispatch(patientHandlerSlice.actions.updatedStatus(currentState))
      } else {
        const patientResponse: R4.IPatient = response as R4.IPatient
        const statusData = await getTaskStatus(patientResponse)
        let patProfile = getPatientProfile(patientResponse, statusData)
        patProfile = await getRelatives(patProfile)
        const date = await getPatientCreatedDate(patProfile.id)
        patProfile = getPatientProfileFinal(patProfile, date)
        const currentState = { ...initialState }
        currentState.resultsAvailable = true
        currentState.patientProfile = patProfile
        dispatch(patientHandlerSlice.actions.updatedStatus(currentState))
      } /* */
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.error = true
      currentState.errorMessage = 'Error while fetching orders'

      dispatch(patientHandlerSlice.actions.updatedStatus(currentState))
    }
  }

export const resetPatientHandlerSlice = () => (dispatch: AppDispatch) => {
  dispatch(patientHandlerSlice.actions.resetPatientDetails(initialState))
}

async function getRelatives(
  patientData: PatientProfile
): Promise<PatientProfile> {
  let patDetails: PatientProfile = patientData
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/RelatedPerson?patient=${patDetails.id}`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          patDetails = getProfileData(orgResponse, patDetails)
        }
      }
    }
  }
  return patDetails
}

export async function getTaskStatus(
  patientData: R4.IPatient
): Promise<boolean> {
  const checkStatus: string[] = []
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Task?patient=Patient/${patientData.id}&code=user-invitation`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right

    if (orgResponse.entry) {
      const taskData: R4.ITask[] =
        orgResponse.entry?.map((item) => item.resource as R4.ITask) ?? []
      if (taskData.length > 0) {
        for (let i = 0; i < taskData.length; i++) {
          if (taskData[i].status && taskData[i].status === 'completed') {
            checkStatus.push('1')
          }
        }
      }
    }
  }
  return checkStatus.length > 0
}

export async function getPatientCreatedDate(
  patientId: string
): Promise<string> {
  let date: string = ''
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Patient/${patientId}/_history/1`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IPatient> =
    R4.RTTI_Patient.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IPatient = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        date = moment(patResponse.meta.lastUpdated).format('DD-MM-YYYY')
      }
    }
  }
  return date
}

export default patientHandlerSlice.reducer
