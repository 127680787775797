import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  ListSubheader,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'

import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
  Add,
  ExpandMore,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import {
  GroupedLabResults,
  GroupedLabResultsByName,
} from 'models/groupedLabResults'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { resetAddVitalsState } from 'redux/ipd/addLabTestIPD/addLabTestSlice'
import { requestForLabResultsDetailsIPD } from 'redux/ipd/viewLabResult/viewLabResultsIPD'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isPhysiotherapist, isUnitAdmin } from 'services/userDetailsService'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { LabResultTile } from 'views/components/consultation/objective/tiles/labResultTile'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { AddLabTest } from './addLabTestResult'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface VitalsProp {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const LabResults: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  status,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const classes = useStyles()

  const dispatch = useDispatch()
  const vitalsDetailsSliceIpd = useSelector(
    (state: RootState) => state.labResultsDetailsIPD
  )

  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )

  const [groupedLabTestByName, setGroupedLabTestByName] = useState<
    GroupedLabResultsByName[]
  >([])

  useEffect(() => {
    if (fhirAppointmentDetails) {
      dispatch(
        requestForLabResultsDetailsIPD(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.mainServiceRequest.id!
        )
      )
    }
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (
      vitalsDetailsSliceIpd.resultsAvailable &&
      vitalsDetailsSliceIpd.groupedResultsByName &&
      vitalsDetailsSliceIpd.groupedResultsByName.length > 0
    ) {
      updatePanel3(vitalsDetailsSliceIpd.groupedResultsByName ?? [])
    }
  }, [vitalsDetailsSliceIpd])

  function updatePanel3(panel: GroupedLabResultsByName[]) {
    const results: GroupedLabResultsByName[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,

        obsResults: panel[i].obsResults,
        checked: true,
      })
    }
    setGroupedLabTestByName(results)
  }

  function handleCollapseForPanel3(rate: boolean, index: number) {
    const values: GroupedLabResultsByName[] = [...groupedLabTestByName]
    values[index].checked = rate
    setGroupedLabTestByName(values)
  }

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' spacing={2}>
        <Grid
          item
          //   onMouseEnter={() => showAddButton(true)}
          //   onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>
                {' '}
                {t('labelCommon:lab_tests')}{' '}
              </Typography>
            </Box>{' '}
            {/* {!isUnitAdmin() && !isPhysiotherapist() && (
              <Box>
                {status !== 'completed' && (
                  <IconButton
                    style={{ padding: '4px' }}
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                  >
                    <Add style={{ height: '16px' }} color='primary' />
                  </IconButton>
                )}
              </Box>
            )} */}
          </Grid>

          {!isUnitAdmin() && !isPhysiotherapist() && status !== 'completed' && (
            <Box
              display='flex'
              flexGrow
              flexDirection='row'
              justifyContent='flex-start'
              paddingBottom={1}
            >
              <Tooltip title='' id='add_test'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                  id='add_test_1'
                  style={{ padding: 0 }}
                >
                  <Typography
                    variant='subtitle2'
                    color='primary'
                    component={Link}
                    id='add_test_3'
                    style={{ fontSize: 13 }}
                  >
                    {' '}
                    {t('labelCommon:lab_tests')}{' '}
                  </Typography>
                  <AddCircleIcon
                    style={{ height: '22px', padding: 0 }}
                    color='primary'
                    id='add_test_2'
                  />{' '}
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {vitalsDetailsSliceIpd.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSliceIpd.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                {isUnitAdmin() || isPhysiotherapist() || status === 'completed'
                  ? 'No data available'
                  : ''}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSliceIpd.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSliceIpd.errorReason ??
                  'Error while fetching Vitals'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSliceIpd.resultsAvailable &&
            vitalsDetailsSliceIpd.groupedResultsByName && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedLabTestByName && groupedLabTestByName.length > 0 && (
                    <Grid item style={{ padding: 0, margin: 0 }}>
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedLabTestByName.map((val, index: number) => (
                          <Box py={1} key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForPanel3(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                {val.obsResults.map(
                                  (results, indexData: number) => (
                                    <Box
                                      flexGrow
                                      width='100%'
                                      display='flex'
                                      flexDirection='column'
                                      key={results.name}
                                    >
                                      <Box
                                        paddingX={1}
                                        borderRadius={2}
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        height={30}
                                        paddingY={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow={1}
                                          alignItems='center'
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {results.name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Grid
                                        container
                                        direction='row'
                                        id='vitals'
                                        style={{
                                          backgroundColor: kDialogueBackground,
                                        }}
                                      >
                                        <Grid item xs={3}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Parameter Name
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              paddingTop={0.1}
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  height: '30px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item key={1} xs={5}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Value
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              paddingTop={0.1}
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  height: '30px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item key={1} xs={4}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Normal Range
                                                </Typography>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <LabResultTile
                                        obsData={results.labResults}
                                        obsDefData={[]}
                                        patientData={
                                          fhirAppointmentDetails.patient
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
        </Grid>

        {openAddDialogue && (
          <AddLabTest
            open={openAddDialogue}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onVitalAdded={() => {
              dispatch(
                requestForLabResultsDetailsIPD(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.mainServiceRequest.id!
                )
              )
              setOpenAddDialogue(false)
            }}
            onClose={() => {
              setOpenAddDialogue(false)
            }}
          />
        )}
      </Grid>
    </ReactVisibilitySensor>
  )
}
