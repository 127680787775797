import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { settingsBackgroundColor } from 'configs/styles/muiThemes'
import { DateWiseDietPlans } from 'models/dateWiseDietPlanData'
import { DietOfVisit } from 'models/diet'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { isCurrentUserDoctor } from 'services/userDetailsService'
import {
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'
import {
  isDietCompleted,
  isFutureDateTimeForDiet,
  isPastDateTimeForDiet,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { DietSelector } from 'views/components/ipdconusltation/diet/diet_selector'

interface AssessmentCaptureProps {
  dietPlansWithDate: DateWiseDietPlans
  id: string
}

export const DietListOfTheDate: React.FC<AssessmentCaptureProps> = ({
  dietPlansWithDate,
  id,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])

  const dispatch = useDispatch()

  function updateCurrentSelection(
    catalogType: R4.ICatalogEntry[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: DietOfVisit = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case '6AM':
        selectedDiet.six = [updateNutrition(catalogType, selectedDiet.six![0])]
        break
      case '830AM':
        selectedDiet.eight = [
          updateNutrition(catalogType, selectedDiet.eight![0]),
        ]
        break
      case '12PM':
        selectedDiet.twelve = [
          updateNutrition(catalogType, selectedDiet.twelve![0]),
        ]
        break
      case '2PM':
        selectedDiet.two = [updateNutrition(catalogType, selectedDiet.two![0])]
        break
      case '5PM':
        selectedDiet.five = [
          updateNutrition(catalogType, selectedDiet.five![0]),
        ]
        break
      case '7PM':
        selectedDiet.seven = [
          updateNutrition(catalogType, selectedDiet.seven![0]),
        ]
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    setDietChart(newDietChart)
  }

  function updateNutritionCompletionStatus(
    no: R4.INutritionOrder[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: DietOfVisit = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case '6AM':
        selectedDiet.six = no
        break
      case '830AM':
        selectedDiet.eight = no
        break
      case '12PM':
        selectedDiet.twelve = no
        break
      case '2PM':
        selectedDiet.two = no
        break
      case '5PM':
        selectedDiet.five = no
        break
      case '7PM':
        selectedDiet.seven = no
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    setDietChart(newDietChart)
  }

  const [dietChart, setDietChart] = useState<DietOfVisit[]>(
    dietPlansWithDate.dateWiseDiets
  )

  function updateNutrition(
    catalogType: R4.ICatalogEntry[],
    nutritionResource: R4.INutritionOrder
  ) {
    let nutritionOrder: R4.INutritionOrder = { ...nutritionResource }

    const extList: R4.IExtension[] = []

    catalogType.forEach((e) => {
      extList.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext',
        valueReference: {
          reference: `CatalogEntry/${e.id}`,
          display: getCatalogName(e),
        },
      })
    })

    nutritionOrder = {
      ...nutritionOrder,
      oralDiet: {
        ...nutritionOrder.oralDiet,
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
            extension: extList,
          },
        ],
      },
    }
    return nutritionOrder
  }

  return (
    <Box display='flex' flexDirection='column' width='100%' id={`diet${id}`}>
      {dietChart.map((dietRow: DietOfVisit, index: number) => (
        <Box
          display='flex'
          flexDirection='row'
          alignItems='flex-start'
          justifyContent='center'
          key={`unit_actor_small_details_${dietRow.encounterId}`}
          paddingY={2}
          id={`dietRow${index}`}
          style={{
            backgroundColor:
              index % 2 === 0 ? '#FFFFFFAF' : settingsBackgroundColor,
          }}
        >
          <Box
            width='15%'
            p={0.5}
            display='column'
            flexDirection='row'
            id={
              getMiddleNameOfPatient(dietRow.patient!).length > 0
                ? `${getNameOfPatient(dietRow.patient!) ?? ''}_
              ${getMiddleNameOfPatient(dietRow.patient!) ?? ''}_
              ${getLastNameOfPatient(dietRow.patient!)}`
                : `${getNameOfPatient(dietRow.patient!) ?? ''}_
              ${getLastNameOfPatient(dietRow.patient!)}`
            }
          >
            <Typography variant='subtitle2' color='initial'>
              {`${getNameOfPatient(dietRow.patient!) ?? ''} ${
                getMiddleNameOfPatient(dietRow.patient!) ?? ''
              } ${getLastNameOfPatient(dietRow.patient!) ?? ''}`}
            </Typography>
            <Typography variant='subtitle2' color='primary'>
              {getIdentifierValueBySystem(
                dietRow.serviceRequest!.identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
              ) ??
                getIdentifierValueBySystem(
                  dietRow.serviceRequest!.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )}
            </Typography>
          </Box>

          <Box flexGrow={1} width='85%'>
            <Grid container spacing={1}>
              <Grid item sm={4} md={3} lg={2}>
                <Box
                  display='flex'
                  flexDirection='column'
                  p={0.5}
                  style={{
                    backgroundColor:
                      index % 2 === 1 ? '#FFFFFFAF' : settingsBackgroundColor,
                  }}
                  height='100%'
                >
                  <Typography
                    variant='caption'
                    color='primary'
                    id={`6AM-${id}label`}
                  >
                    6 AM
                  </Typography>
                  <DietSelector
                    patient={dietRow.patient!}
                    id={index}
                    dateString={dietRow.date}
                    nutritionOrders={dietRow.six ?? []}
                    onSelectionChanged={(a, b) => {
                      updateCurrentSelection(a, index, b)
                    }}
                    searchType='MORN.early'
                    timeOfDay='6AM'
                    // disableCheckingOpt={
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'active'
                    //     : false) ||
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'completed'
                    //     : false)
                    // }
                    hideChecking={isFutureDateTimeForDiet(
                      moment(dietRow.date).format('DD-MM-YYYY'),
                      '6AM'
                    )}
                    disableSelection={
                      isPastDateTimeForDiet(
                        moment(dietRow.date).format('DD-MM-YYYY'),
                        '6AM'
                      ) ||
                      !isCurrentUserDoctor() ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'active'
                        : false) ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'completed'
                        : false) ||
                      isDietCompleted(dietRow.six)
                    }
                    onNutritionIntakeUpdated={(e) => {
                      updateNutritionCompletionStatus(e, index, '6AM')
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Box
                  display='flex'
                  flexDirection='column'
                  p={0.5}
                  style={{
                    backgroundColor:
                      index % 2 === 1 ? '#FFFFFFAF' : settingsBackgroundColor,
                  }}
                  height='100%'
                >
                  <Typography
                    variant='caption'
                    color='primary'
                    id={`830AM-${id}label`}
                  >
                    8.30 AM
                  </Typography>
                  <DietSelector
                    patient={dietRow.patient!}
                    id={index}
                    dateString={dietRow.date}
                    nutritionOrders={dietRow.eight ?? []}
                    onSelectionChanged={(a, b) => {
                      updateCurrentSelection(a, index, b)
                    }}
                    searchType='MORN'
                    timeOfDay='830AM'
                    // disableCheckingOpt={
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'active'
                    //     : false) ||
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'completed'
                    //     : false)
                    // }
                    hideChecking={isFutureDateTimeForDiet(
                      moment(dietRow.date).format('DD-MM-YYYY'),
                      '830AM'
                    )}
                    disableSelection={
                      isPastDateTimeForDiet(
                        moment(dietRow.date).format('DD-MM-YYYY'),
                        '830AM'
                      ) ||
                      !isCurrentUserDoctor() ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'active'
                        : false) ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'completed'
                        : false) ||
                      isDietCompleted(dietRow.eight)
                    }
                    onNutritionIntakeUpdated={(e) => {
                      updateNutritionCompletionStatus(e, index, '830AM')
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Box
                  p={0.5}
                  display='flex'
                  flexDirection='column'
                  style={{
                    backgroundColor:
                      index % 2 === 1 ? '#FFFFFFAF' : settingsBackgroundColor,
                  }}
                  height='100%'
                >
                  <Typography
                    variant='caption'
                    color='primary'
                    id={`1230PM-${id}label`}
                  >
                    12:30 PM
                  </Typography>
                  <DietSelector
                    patient={dietRow.patient!}
                    id={index}
                    dateString={dietRow.date}
                    nutritionOrders={dietRow.twelve ?? []}
                    onSelectionChanged={(a, b) => {
                      updateCurrentSelection(a, index, b)
                    }}
                    // disableCheckingOpt={
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'active'
                    //     : false) ||
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'completed'
                    //     : false)
                    // }
                    searchType='MORN.late'
                    timeOfDay='12PM'
                    hideChecking={isFutureDateTimeForDiet(
                      moment(dietRow.date).format('DD-MM-YYYY'),
                      '12PM'
                    )}
                    disableSelection={
                      isPastDateTimeForDiet(
                        moment(dietRow.date).format('DD-MM-YYYY'),
                        '12PM'
                      ) ||
                      !isCurrentUserDoctor() ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'active'
                        : false) ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'completed'
                        : false) ||
                      isDietCompleted(dietRow.twelve)
                    }
                    onNutritionIntakeUpdated={(e) => {
                      updateNutritionCompletionStatus(e, index, '12PM')
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Box
                  p={0.5}
                  display='flex'
                  flexDirection='column'
                  style={{
                    backgroundColor:
                      index % 2 === 1 ? '#FFFFFFAF' : settingsBackgroundColor,
                  }}
                  height='100%'
                >
                  <Typography
                    variant='caption'
                    color='primary'
                    id={`2PM-${id}label`}
                  >
                    2 PM
                  </Typography>
                  <DietSelector
                    patient={dietRow.patient!}
                    id={index}
                    dateString={dietRow.date}
                    nutritionOrders={dietRow.two ?? []}
                    onSelectionChanged={(a, b) => {
                      updateCurrentSelection(a, index, b)
                    }}
                    // disableCheckingOpt={
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'active'
                    //     : false) ||
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'completed'
                    //     : false)
                    // }
                    searchType='NOON'
                    timeOfDay='2PM'
                    hideChecking={isFutureDateTimeForDiet(
                      moment(dietRow.date).format('DD-MM-YYYY'),
                      '2PM'
                    )}
                    disableSelection={
                      isPastDateTimeForDiet(
                        moment(dietRow.date).format('DD-MM-YYYY'),
                        '2PM'
                      ) ||
                      !isCurrentUserDoctor() ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'active'
                        : false) ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'completed'
                        : false) ||
                      isDietCompleted(dietRow.two)
                    }
                    onNutritionIntakeUpdated={(e) => {
                      updateNutritionCompletionStatus(e, index, '2PM')
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Box
                  p={0.5}
                  display='flex'
                  flexDirection='column'
                  style={{
                    backgroundColor:
                      index % 2 === 1 ? '#FFFFFFAF' : settingsBackgroundColor,
                  }}
                  height='100%'
                >
                  <Typography
                    variant='caption'
                    color='primary'
                    id={`430PM-${id}label`}
                  >
                    4:30 PM
                  </Typography>
                  <DietSelector
                    patient={dietRow.patient!}
                    id={index}
                    dateString={dietRow.date}
                    nutritionOrders={dietRow.five ?? []}
                    onSelectionChanged={(a, b) => {
                      updateCurrentSelection(a, index, b)
                    }}
                    // disableCheckingOpt={
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'active'
                    //     : false) ||
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'completed'
                    //     : false)
                    // }
                    searchType='EVE'
                    timeOfDay='5PM'
                    hideChecking={isFutureDateTimeForDiet(
                      moment(dietRow.date).format('DD-MM-YYYY'),
                      '5PM'
                    )}
                    disableSelection={
                      isPastDateTimeForDiet(
                        moment(dietRow.date).format('DD-MM-YYYY'),
                        '5PM'
                      ) ||
                      !isCurrentUserDoctor() ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'active'
                        : false) ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'completed'
                        : false) ||
                      isDietCompleted(dietRow.five)
                    }
                    onNutritionIntakeUpdated={(e) => {
                      updateNutritionCompletionStatus(e, index, '5PM')
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Box
                  p={0.5}
                  display='flex'
                  flexDirection='column'
                  style={{
                    backgroundColor:
                      index % 2 === 1 ? '#FFFFFFAF' : settingsBackgroundColor,
                  }}
                  height='100%'
                >
                  <Typography
                    variant='caption'
                    color='primary'
                    id={`630PM-${id}label`}
                  >
                    6:30 PM
                  </Typography>
                  <DietSelector
                    patient={dietRow.patient!}
                    id={index}
                    dateString={dietRow.date}
                    nutritionOrders={dietRow.seven ?? []}
                    onSelectionChanged={(a, b) => {
                      updateCurrentSelection(a, index, b)
                    }}
                    searchType='NIGHT'
                    timeOfDay='7PM'
                    // disableCheckingOpt={
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'active'
                    //     : false) ||
                    //   (dietRow.serviceRequest
                    //     ? dietRow.serviceRequest.status !== 'completed'
                    //     : false)
                    // }
                    disableSelection={
                      isPastDateTimeForDiet(
                        moment(dietRow.date).format('DD-MM-YYYY'),
                        '7PM'
                      ) ||
                      !isCurrentUserDoctor() ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'active'
                        : false) ||
                      !(dietRow.serviceRequest
                        ? dietRow.serviceRequest.status !== 'completed'
                        : false) ||
                      isDietCompleted(dietRow.seven)
                    }
                    hideChecking={isFutureDateTimeForDiet(
                      moment(dietRow.date).format('DD-MM-YYYY'),
                      '7PM',
                      3
                    )}
                    onNutritionIntakeUpdated={(e) => {
                      updateNutritionCompletionStatus(e, index, '7PM')
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
