import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import {
  FhirActiveIPDDetailsForMedicalRole,
  FhirAppointmentWithPatient,
  FhirIPDwithPatientDetails,
} from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  getNotes,
  isAfterVital,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { AlertDialog } from 'views/components/LeftMenu/alertDialog'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel } from 'wello-web-components'
import { TherapyDataDisplay } from './therapyDataFile'

interface Props {
  open: boolean
  onClose: () => void
  onLabOrderCreated: () => void
  fhirAppointmentDetails: FhirAppointmentWithPatient
  treatmentPlanData: TreatmentPlanData
}
export const TherapyDetailsOPD: React.FC<Props> = ({
  open,
  onClose,
  onLabOrderCreated,
  fhirAppointmentDetails,
  treatmentPlanData,
}) => {
  const addProcedureDetailsSlice = useSelector(
    (state: RootState) => state.addProcedureDetailsSlice
  )

  const cancelTherapyDetailsSlice = useSelector(
    (state: RootState) => state.cancelTreatmentSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedPractWithRole, setSelecctedPractWithRole] =
    useState<PractitionerWithRole[]>()
  const [startTime, setStartedTime] = useState<string>()
  const currentDate = moment(treatmentPlanData.date).format('YYYY-MM-DD')
  const timeAndDate = moment(
    `${currentDate}  ${moment(treatmentPlanData.startTime, ['hh:mm A']).format(
      'HH:mm'
    )}`
  ).format('YYYY-MM-DD HH:mm')

  const endTimeDate = moment(
    `${currentDate} ${
      isAfterVital(
        moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
      )
        ? moment().format('HH:mm')
        : moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
    }`
  ).format('YYYY-MM-DD HH:mm')

  const [showTextForSlot, setShowTextForSlot] = useState<boolean>(false)

  const time1 = moment(timeAndDate).format('YYYY-MM-DD HH:mm:ss')

  const status = moment(time1).subtract(1, 'hour')

  useEffect(() => {
    if (addProcedureDetailsSlice.additionSuccessful) {
      if (onLabOrderCreated) {
        onLabOrderCreated()
      }
    }
  }, [
    addProcedureDetailsSlice.additionSuccessful,
    addProcedureDetailsSlice,
    onLabOrderCreated,
  ])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Therapy Details</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='column' width='100%'>
              <WelloFormItemLabel title='Patient' />
              <PatientTile patient={fhirAppointmentDetails.patient} />
            </Box>

            {treatmentPlanData && (
              <Box display='flex' flexDirection='column' width='100%'>
                <TherapyDataDisplay treatmentPlanData={treatmentPlanData} />
              </Box>
            )}

            {treatmentPlanData && treatmentPlanData.treatmentPlan && (
              <Box display='flex' flexDirection='column' width='100%'>
                <CardContent>
                  {treatmentPlanData.treatmentPlan.status === 'completed' && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={0.5}
                      >
                        <Typography variant='subtitle1' color='initial'>
                          Actual Perform Time
                        </Typography>
                      </Box>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Typography variant='body1' color='initial'>
                          {moment(treatmentPlanData.date).format('DD-MM-YYYY')}
                        </Typography>
                      </Box>

                      <Box display='flex' flexDirection='row' width='100%'>
                        <Typography variant='body1' color='initial'>
                          {treatmentPlanData.actualStart} -{' '}
                          {treatmentPlanData.actualEnd}
                        </Typography>
                      </Box>

                      {treatmentPlanData.procedure && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          paddingTop={0.5}
                        >
                          <Typography variant='subtitle1' color='initial'>
                            Performed by
                          </Typography>
                        </Box>
                      )}
                      {treatmentPlanData.procedure && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <Typography variant='body1' color='initial'>
                            {treatmentPlanData.procedure.performer
                              ?.map((e) => e.actor.display ?? '')
                              .join(', ')}
                          </Typography>
                        </Box>
                      )}

                      {treatmentPlanData.procedure &&
                        getNotes(treatmentPlanData.procedure!).length > 0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingTop={0.5}
                          >
                            <Typography variant='subtitle1' color='initial'>
                              Notes
                            </Typography>
                          </Box>
                        )}
                      {treatmentPlanData.procedure &&
                        getNotes(treatmentPlanData.procedure!).length > 0 && (
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Typography variant='body1' color='initial'>
                              {getNotes(treatmentPlanData.procedure!)}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  )}

                  {treatmentPlanData.treatmentPlan.status === 'revoked' && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={0.5}
                      >
                        <Typography variant='subtitle1' color='initial'>
                          Cancellation Reason
                        </Typography>
                      </Box>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Typography variant='body1' color='initial'>
                          {' '}
                          {treatmentPlanData.treatmentPlan.note
                            ? treatmentPlanData.treatmentPlan.note[0].text ?? ''
                            : treatmentPlanData.treatmentPlan.reasonCode
                            ? treatmentPlanData.treatmentPlan.reasonCode[0]
                              ? treatmentPlanData.treatmentPlan.reasonCode[0]
                                  .coding
                                ? treatmentPlanData.treatmentPlan.reasonCode[0]
                                    .coding[0]
                                  ? treatmentPlanData.treatmentPlan
                                      .reasonCode[0].coding[0].display ?? ''
                                  : ''
                                : ''
                              : ''
                            : ''}
                        </Typography>
                      </Box>

                      {treatmentPlanData.treatmentPlan.performer &&
                        treatmentPlanData.treatmentPlan.performer.length >
                          0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingTop={0.5}
                          >
                            <Typography variant='subtitle1' color='initial'>
                              Cancelled By
                            </Typography>
                          </Box>
                        )}
                      {treatmentPlanData.treatmentPlan.performer &&
                        treatmentPlanData.treatmentPlan.performer.length >
                          0 && (
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Typography variant='body1' color='initial'>
                              {treatmentPlanData.treatmentPlan.performer
                                ? treatmentPlanData.treatmentPlan.performer
                                    .length > 1
                                  ? treatmentPlanData.treatmentPlan.performer[2]
                                    ? treatmentPlanData.treatmentPlan
                                        .performer[2].display ?? ''
                                    : ''
                                  : treatmentPlanData.treatmentPlan.performer[0]
                                      .display ?? ''
                                : ''}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  )}
                </CardContent>
              </Box>
            )}
          </Box>
          <AlertDialog
            open={showTextForSlot}
            onClose={() => {
              setShowTextForSlot(false)
            }}
            notesData='Please choose slot in given time frame'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {addProcedureDetailsSlice.adding && <CircularProgress size={30} />}
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          style={{ color: ' #979797;' }}
          disableElevation
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
