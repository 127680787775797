import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { Conditions } from 'models/conditions'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloFamilyHistory } from 'models/welloSelectFamilyConditions'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getVitalsObservationObjectForIPD } from 'utils/fhirResoureHelpers/observationHelpers'
import { AddFamilyHistoryStatus } from './addFamilyHistoryStatus'

const initialState: AddFamilyHistoryStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addFamilyHistorySliceOPD = createSlice({
  name: 'addFamilyHistorySliceOPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(
      state,
      action: PayloadAction<AddFamilyHistoryStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddHabitState(state, action: PayloadAction<AddFamilyHistoryStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addFamilyHistoryOPD =
  (
    appointment: FhirAppointmentDetail,
    familyConditions: WelloFamilyHistory[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddFamilyHistoryStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addFamilyHistorySliceOPD.actions.updateAddVitalsStatus(addingState)
    )

    try {
      const practitionerDetail = getCurrentUserPractitionerDetails()
      const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [],
      }
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()

      for (let i = 0; i < familyConditions.length; i++) {
        const data: R4.IFamilyMemberHistory = {
          resourceType: 'FamilyMemberHistory',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndFamilyMemberHistoryBase',
            ],
          },
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
              valueReference: {
                reference: `Appointment/${appointment.appointment.id!}`,
              },
            },
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-practitioner-role-ext',
              valueReference: {
                display: getNameFromHumanName(practitionerDetail.name ?? []),
                reference: `${practRole.resourceType}/${practRole.id}`,
                id: practRole.id,
                type: practRole.resourceType,
              },
            },
          ],
          patient: {
            id: appointment.patient.id,
            reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
          },
          relationship: {
            text: familyConditions[i].relationShips!.display,
            coding: [familyConditions[i].relationShips!],
          },
          date: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        }

        if (familyConditions[i].condition) {
          data.condition = [
            {
              code: {
                coding: [familyConditions[i].condition!],
              },
              onsetString: moment().toISOString(),
            },
          ]
        }
        console.log(data)
        for (
          let j = 0;
          j < familyConditions[i].checkBoxFirstCondition!.length;
          j++
        ) {
          if (familyConditions[i].checkBoxFirstCondition![j].checked) {
            if (data.condition === undefined) {
              data.condition = [
                {
                  code: {
                    coding: [
                      familyConditions[i].checkBoxFirstCondition![j].data,
                    ],
                  },
                  onsetString: moment().toISOString(),
                },
              ]
            } else if (data.condition && data.condition.length > 0) {
              data.condition.push({
                code: {
                  coding: [familyConditions[i].checkBoxFirstCondition![j].data],
                },
                onsetString: moment().toISOString(),
              })
            }
          }
        }

        for (
          let k = 0;
          k < familyConditions[i].checkBoxSecCondition!.length;
          k++
        ) {
          if (familyConditions[i].checkBoxSecCondition![k].checked) {
            if (data.condition === undefined) {
              data.condition = [
                {
                  code: {
                    coding: [familyConditions[i].checkBoxSecCondition![k].data],
                  },
                  onsetString: moment().toISOString(),
                },
              ]
            } else if (data.condition && data.condition.length > 0) {
              data.condition.push({
                code: {
                  coding: [familyConditions[i].checkBoxSecCondition![k].data],
                },
                onsetString: moment().toISOString(),
              })
            }
          }
        }
        const practitioner: R4.IPractitioner =
          getCurrentUserPractitionerDetails()
        if (
          familyConditions[i].notes &&
          familyConditions[i].notes!.length > 0
        ) {
          data.note = [
            {
              authorReference: {
                reference: `${practitioner.resourceType}/${practitioner.id}`,
              },
              text: familyConditions[i].notes!,
              time: new Date().toISOString(),
            },
          ]
        }

        const entry: R4.IBundle_Entry = {
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: data.resourceType,
          },
          resource: data,
        }
        requestBundle.entry?.push(entry)
      }

      console.log(requestBundle)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        requestBundle
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)
        dispatch(
          requestFamilyConditionHistoryOfPatient(
            appointment.appointment.id!,
            appointment.patient,
            false,
            'yes'
          )
        )
        dispatch(showSuccessAlert('Family Member Condition Added Successfully'))

        dispatch(
          addFamilyHistorySliceOPD.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AddFamilyHistoryStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding Family Member Condition',
        }
        dispatch(
          addFamilyHistorySliceOPD.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddFamilyHistoryStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding Family Member Condition',
      }
      dispatch(
        addFamilyHistorySliceOPD.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddFamilyHistory = () => (dispatch: AppDispatch) => {
  dispatch(addFamilyHistorySliceOPD.actions.resetAddHabitState(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  excersise?: R4.ICoding,
  intensity?: R4.ICoding,
  energyLevel?: R4.ICoding,
  breatePattern?: R4.ICoding,
  sleepData?: R4.ICoding
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (excersise != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Exercise duration/Exercise frequency',
      coding: [
        {
          code: '74009-2',
          display: 'Exercise duration/Exercise frequency',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: excersise.display ?? '',
      coding: [excersise],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (intensity != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Exercise Intensity',
      coding: [
        {
          code: '74008-4',
          display: 'Exercise Intensity',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: intensity.display ?? '',
      coding: [intensity],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (energyLevel != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Energy level in past 7 days',
      coding: [
        {
          code: '65515-9',
          display: 'Energy level in past 7 days',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: energyLevel.display ?? '',
      coding: [energyLevel],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (breatePattern != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Breathing pattern impairment',
      coding: [
        {
          code: '28148-5',
          display: 'Breathing pattern impairment',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: breatePattern.display ?? '',
      coding: [breatePattern],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (sleepData != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'How many hours do you normally sleep',
      coding: [
        {
          code: '65968-0',
          display: 'How many hours do you normally sleep',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: sleepData.display ?? '',
      coding: [sleepData],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addFamilyHistorySliceOPD.reducer
