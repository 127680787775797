import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import React, { useState } from 'react'
import { getImagePathFromPlanDefinition } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import CPGSelectedRecommendationProvider from 'views/providers/cpgSelectionProvider'
import { SubscriptionOfferingDetail } from './susbcriptionOfferDetail'

export interface SubscriptionOfferingsDetailsPopUp {
  offersList: CarePlanSubscriptionPlan[]
  onSelectedPlanChanged?: (plan: CarePlanSubscriptionPlan) => void
  onSelectedDurationChanged?: (
    duration: R4.IChargeItemDefinition | undefined
  ) => void
  onSelectedSectionChanged?: (selectedSection: string) => void
  onRequestFinished?: () => void
  preSelectedPlan?: CarePlanSubscriptionPlan
}
export const SubscriptionOfferingsDetails: React.FC<SubscriptionOfferingsDetailsPopUp> =
  ({
    offersList,
    onSelectedPlanChanged,
    onSelectedSectionChanged,
    onSelectedDurationChanged,
    onRequestFinished,
    preSelectedPlan,
  }) => {
    const theme = useTheme()

    const [selectedPlan, setSelectedPlan] = useState(
      offersList !== undefined
        ? preSelectedPlan !== undefined
          ? preSelectedPlan.cpgId
          : offersList[0].cpgId
        : undefined
    )
    const [selectedDuration, setSelectedDuration] = useState(
      offersList !== undefined
        ? offersList[0].subscriptionPlans !== undefined
          ? offersList[0].subscriptionPlans[0]
          : undefined
        : undefined
    )

    console.log('-----------selectedPlan------------', selectedPlan)
    console.log('-----------preSelectedPlan------------', preSelectedPlan)
    return (
      <CPGSelectedRecommendationProvider>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            style={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              width='20%'
              minWidth='100px'
              style={{
                borderRight: `1px solid ${theme.palette.divider}`,
              }}
            />
            {offersList.map((offer, index) => (
              <Box
                width='25%'
                minWidth='100px'
                display='flex'
                flexDirection='row'
                key={offer.cpgId}
                p={1}
                borderColor={theme.palette.divider}
                border={0.5}
                bgcolor={
                  offer.cpgId === selectedPlan
                    ? theme.palette.background.paper
                    : theme.palette.background.default
                }
                style={{
                  cursor: 'pointer',
                }}
                alignContent='center'
                alignItems='center'
                onClick={(e) => {
                  setSelectedPlan(offer.cpgId)
                  if (onSelectedPlanChanged) {
                    if (onSelectedDurationChanged) {
                      if (
                        offer.subscriptionPlans !== undefined &&
                        offer.subscriptionPlans.length > 0
                      ) {
                        onSelectedDurationChanged(offer.subscriptionPlans[0])
                        setSelectedDuration(offer.subscriptionPlans[0])
                      } else {
                        setSelectedDuration(undefined)
                        onSelectedDurationChanged(undefined)
                      }
                    }
                    onSelectedPlanChanged(offer)
                  }
                }}
              >
                <img
                  src={`${
                    process.env.PUBLIC_URL
                  }/icons/${getImagePathFromPlanDefinition(offer.rawCpg)}`}
                  alt={`img_${_.snakeCase(offer.planName)}`}
                  height='50%'
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                  }}
                />

                <Typography variant='subtitle1' color='primary'>
                  {offer.planName}
                </Typography>
              </Box>
            ))}
          </Box>
          {selectedPlan && (
            <SubscriptionOfferingDetail
              key={selectedPlan}
              id='subscription_offering_detail'
              offer={offersList.find((offer) => offer.cpgId === selectedPlan)!}
              onSelectionChanged={onSelectedSectionChanged}
              onSelectedDurationChanged={onSelectedDurationChanged}
              onRequestFinished={() => {
                if (onRequestFinished) {
                  onRequestFinished()
                }
              }}
            />
          )}
        </Box>
      </CPGSelectedRecommendationProvider>
    )
  }
