import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import {
  GroupedReferralService,
  LabReferralService,
} from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetReferralOfferingSearch,
  searchLabOfferings,
} from 'redux/administration/referralSetup/labTestSearch/referralLabOfferingSearchSlice'
import { searchLabOfferingsMultiple } from 'redux/administration/referralSetup/labTestSearch/referralLabOfferingSliceMultiple'
import { RootState } from 'redux/rootReducer'
import {
  getIdentifierValueBySystem,
  getMultipleLionCode,
} from 'utils/fhirResourcesHelper'
import { LabReferralSelector } from 'views/components/lab/orderCreationManagement/labReferralSelector'
import { LabReferralSelectorForCarePlan } from 'views/components/lab/orderCreationManagement/labReferralSelectorWithCarePlan'
import { WarningDialog } from 'views/components/LeftMenu/warnigDialog'

interface Props {
  open: boolean

  preAddedLabTest: R4.IPlanDefinition[]
  onClose: () => void
  onLabTestAdded: (
    latest: LabReferralService[],
    planDef: R4.IPlanDefinition[]
  ) => void
}
export const AddLabTestFromCPGHandler: React.FC<Props> = ({
  open,
  onClose,
  onLabTestAdded,
  preAddedLabTest,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedLabTest, setAddedlabTest] = useState<LabReferralService[]>([])

  const [selectedService, setSelectedService] = useState<LabReferralService>()
  const [remainingLabTest, setRemainingLabTest] = useState<
    R4.IPlanDefinition[]
  >([])

  const dispatch = useDispatch()

  const referralLabOfferingSlice = useSelector(
    (state: RootState) => state.referralLabOfferingSliceMultiple
  )

  const [disabled, setDisabled] = useState(false)

  function resetForm() {
    Promise.resolve().then(() => {
      setAddedlabTest([])
      setRemainingLabTest([])
    })
  }

  function addCurrentDetails() {
    Promise.resolve().then(() => {
      console.log(addedLabTest)
      console.log(remainingLabTest)

      onLabTestAdded(addedLabTest, remainingLabTest)
    })
  }

  useEffect(() => {
    dispatch(
      searchLabOfferingsMultiple(
        preAddedLabTest,

        getMultipleLionCode(preAddedLabTest)
      )
    )
  }, [dispatch])

  useEffect(() => {
    if (
      referralLabOfferingSlice.resultsAvailable &&
      referralLabOfferingSlice.groupedServices
    ) {
      updateServiceDetailsDefault(referralLabOfferingSlice.groupedServices)
    }
    if (referralLabOfferingSlice.noResultsAvailable) {
      updateNoLabs(preAddedLabTest)
    }
  }, [dispatch, referralLabOfferingSlice])

  function updateServiceDetails(vitalList: GroupedReferralService[]) {
    const results: LabReferralService[] = []
    const withoutService: R4.IPlanDefinition[] = []
    for (let i = 0; i < vitalList.length; i++) {
      if (vitalList[i].healthService && vitalList[i].healthService.length > 0) {
        results.push({
          planDefinition: vitalList[i].planDef,
          name: vitalList[i].healthService[0].name,
          healthService: vitalList[i].healthService[0].healthService,
        })
        setAddedlabTest(results)
      } else {
        withoutService.push(vitalList[i].planDef)
        setRemainingLabTest(withoutService)
      }
    }
  }

  function updateServiceDetailsDefault(vitalList: GroupedReferralService[]) {
    const withoutService: R4.IPlanDefinition[] = []
    for (let i = 0; i < vitalList.length; i++) {
      withoutService.push(vitalList[i].planDef)
      setRemainingLabTest(withoutService)
    }
  }

  function updateNoLabs(preAddedTest: R4.IPlanDefinition[]) {
    const results: LabReferralService[] = []
    const withoutService: R4.IPlanDefinition[] = []
    for (let i = 0; i < preAddedTest.length; i++) {
      withoutService.push(preAddedTest[i])
      setRemainingLabTest(withoutService)
    }
  }

  function enableFinishButton() {
    const data: string[] = []
    if (
      referralLabOfferingSlice.resultsAvailable &&
      referralLabOfferingSlice.groupedServices
    ) {
      for (
        let i = 0;
        i < referralLabOfferingSlice.groupedServices.length;
        i++
      ) {
        if (
          referralLabOfferingSlice.groupedServices[i].healthService &&
          referralLabOfferingSlice.groupedServices[i].healthService.length > 0
        ) {
          data.push('1')
        }
      }
      if (addedLabTest.length < data.length) {
        return true
      }
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
        setAddedlabTest([])
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Add Lab Test</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          marginX={2}
          marginY={1}
          display='flex'
          flexDirection='column'
          width='100%'
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box
              marginX={2}
              marginY={1}
              display='flex'
              flexDirection='column'
              width='100%'
            >
              {referralLabOfferingSlice.searching && (
                <Box flexGrow width='100%' display='flex' flexDirection='row'>
                  <CircularProgress size={15} />
                </Box>
              )}
              {referralLabOfferingSlice.error && (
                <Typography>
                  {' '}
                  {referralLabOfferingSlice.errorMessage ??
                    'Error while searching'}
                </Typography>
              )}
              {referralLabOfferingSlice.noResultsAvailable && (
                <Box width='100%' paddingRight={3}>
                  {preAddedLabTest.map((label, index: number) => (
                    <Box
                      width='100%'
                      key={`${label.id}`}
                      display='flex'
                      flexDirection='column'
                      py={0.5}
                    >
                      <Box width='100%' py={0.5}>
                        <Typography variant='subtitle2'>
                          {label.title ?? ''}
                        </Typography>
                      </Box>
                      <Box px={1}>
                        <Typography variant='subtitle1'>
                          {' '}
                          No labs available
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              {referralLabOfferingSlice.resultsAvailable &&
                referralLabOfferingSlice.groupedServices && (
                  <Box width='100%' paddingRight={3}>
                    {referralLabOfferingSlice.groupedServices.map(
                      (label, index: number) => (
                        <Box
                          width='100%'
                          key={`${label.planDef.id}`}
                          display='flex'
                          flexDirection='column'
                          py={0.5}
                        >
                          <Box width='100%' py={0.5}>
                            <Typography variant='subtitle2'>
                              {label.planDef.title ?? ''}
                            </Typography>
                          </Box>

                          {label.healthService.length === 0 && (
                            <Box px={1}>
                              <Typography variant='subtitle1'>
                                {' '}
                                No labs available
                              </Typography>
                            </Box>
                          )}
                          {label.healthService.length > 0 && (
                            <LabReferralSelectorForCarePlan
                              data={label.healthService ?? []}
                              onSelectionChanges={(e: any) => {
                                const tempService: LabReferralService[] = [
                                  ...addedLabTest,
                                ]

                                const indexData = tempService.findIndex(
                                  (object) =>
                                    object.planDefinition.id ===
                                    label.planDef.id
                                )
                                if (indexData > -1) {
                                  tempService[indexData].healthService =
                                    e.healthService
                                  tempService[indexData].name = e.name
                                  setAddedlabTest(tempService)
                                } else {
                                  tempService.push({
                                    name: e.name,
                                    healthService: e.healthService,
                                    planDefinition: label.planDef,
                                  })
                                  setAddedlabTest(tempService)
                                }
                              }}
                              //   preSelectedOrdersRoles={
                              //     label.healthService.length > 0
                              //       ? label.healthService[0]
                              //       : undefined
                              //   }
                              disabled={false}
                            />
                          )}
                        </Box>
                      )
                    )}
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          flexGrow={1}
          alignItems='end'
          alignContent='end'
          justifyContent='end'
          width='100%'
        >
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                  setAddedlabTest([])
                }}
                variant='outlined'
                disableElevation
                id='finalize_cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            <Box paddingX={1}>
              {' '}
              <Button
                onClick={() => {
                  addCurrentDetails()
                }}
                variant='contained'
                color='primary'
                id='finalize_cancel'
                disabled={
                  enableFinishButton() ||
                  remainingLabTest.length === 0 ||
                  referralLabOfferingSlice.searching
                }
              >
                Finish
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
      <WarningDialog
        id='notes'
        open={disabled}
        onClose={() => {
          setDisabled(false)
          onClose()
        }}
        notesData='No Lab found for the selected tests/Panel'
        dialogText='Warning'
      />
    </Dialog>
  )
}
