import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, IconButton, Typography, Grid, Divider } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { Edit } from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloOrgDetails } from 'models/welloUnit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { RootState } from 'redux/rootReducer'
import { getEducationalColleges } from 'services/fileServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { PROFILE_SELF_DESCRIPTION_URL } from 'utils/constants/fhir_vs_ext_constants'
import {
  getAddress,
  getAddressOfPatient,
  getAgeOfAttendant,
  getAgeOfPatientData,
  getEmailOfIRelatedPerson,
  getEmailOfPatient,
  getFirstNameOfRelatedPerson,
  getGenderOfDoctor,
  getGenderOfPatient,
  getGenderOfRelatedPerson,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemType,
  getLanguagesList,
  getLastNameOfPatient,
  getLastNameOfRelatedPerson,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getOrgFromPractitioner,
  getQualificationsList,
  getRegistrationNumber,
  getStringValueExtensionsOfUrl,
  getTelecomFromContactPoints,
  getTelecomOfAttendant,
  getTelecomOfPatient,
  getTotalExperience,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionContentType,
  getValueAttachmentFromExtensionDataForUrl,
  getValueCodingFromExtension,
  getWelloOrgOrgFromPractitioner,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { EditPractitioner } from 'views/pages/general/editPractitionerDoc'
import { AttachmentImage } from '../common/attachementImageViewer'
import { LogoViewer } from '../common/logoViewer'
import OverflowTypography from '../consultation/plan/ayurveda/overflowTypography'
import { SelectedFile } from './WelloFilePicker'

export interface TabProps {
  practitionerRole: R4.IPractitionerRole
  practitioner: R4.IPractitioner
  onDone?: (
    practObj: R4.IPractitioner,
    practObjRole: R4.IPractitionerRole
  ) => void
}

export const PractitionerProfile: React.FC<TabProps> = ({
  practitionerRole,
  practitioner,
  onDone,
}: TabProps) => {
  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const dispatch = useDispatch()
  const [edit, setEdit] = useState<boolean>(false)
  const [practObj, setPractObj] = useState<R4.IPractitioner>(
    getCurrentUserPractitionerDetails()
  )
  const [practRoleObj, setPractRoleObj] = useState<R4.IPractitionerRole>(
    getCurrentUserPractitionerRoleDetails()
  )

  const [eduColleges, setEduCollegs] = useState<WelloOrgDetails[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [roleSpecificFormErrors, setRoleSpecificFormErrors] =
    useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function getCollegesList() {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getEducationalColleges()
      .then((e: WelloOrgDetails[]) => {
        setLoading(false)
        if (e) {
          setEduCollegs(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching colleges list. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    getCollegesList()
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='Column'
      paddingX={2}
      alignContent='center'
      width='100%'
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' paddingTop={2} width='90%'>
          <Typography variant='subtitle1' color='primary'>
            {' '}
            About
          </Typography>
        </Box>
        <Box display='flex' width='10%' justifyContent='flex-start'>
          <Tooltip title='Edit'>
            <IconButton
              aria-label='btn_ord_reschedule'
              color='primary'
              onClick={() => {
                setEdit(true)
              }}
            >
              <Edit color='primary' />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        width='100%'
        border={4}
        style={{
          boxShadow: '0px 0px 4px #ccc',
          margin: '2px 0px',
          borderRadius: '1px',
          borderColor: '#F8F8F8',
          width: '100%',
          height: 'auto',
          backgroundColor: '#F8F8F8',
        }}
      >
        <Grid container direction='row' spacing={1}>
          <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Qualification
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getQualificationsList(
                          getCurrentUserPractitionerDetails().qualification ??
                            []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Total Experience
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getTotalExperience(
                          getCurrentUserPractitionerDetails().qualification ??
                            []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Date of Birth
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {moment(
                          getCurrentUserPractitionerDetails().birthDate
                        ).format('DD-MM-YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Gender
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {titleCase(
                          getGenderOfDoctor(getCurrentUserPractitionerDetails())
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Proof Type
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {getValueCodingFromExtension(
                        getCurrentUserPractitionerDetails().extension,
                        'http://wellopathy.com/fhir/india/core/Code/identityProofType'
                      ) && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getValueCodingFromExtension(
                            getCurrentUserPractitionerDetails().extension,
                            'http://wellopathy.com/fhir/india/core/Code/identityProofType'
                          )
                            ? getValueCodingFromExtension(
                                getCurrentUserPractitionerDetails().extension,
                                'http://wellopathy.com/fhir/india/core/Code/identityProofType'
                              )?.display ?? ''
                            : ''}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Proof Number
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {getValueAttachmentFromExtensionDataForUrl(
                        getCurrentUserPractitionerDetails().extension ?? [],
                        'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
                      ) && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getValueAttachmentFromExtensionDataForUrl(
                            getCurrentUserPractitionerDetails().extension ?? [],
                            'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
                          ) ?? ''}
                        </Typography>
                      )}
                      {/* <OverflowTypography
                        text={getAddressOfPatient(patient.address ?? []) ?? ''}
                        typographyProps={{
                          variant: 'subtitle2',
                          color: 'initial',
                          noWrap: true,
                        }}
                      /> */}
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Medical Council
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {getWelloOrgOrgFromPractitioner(
                        getCurrentUserPractitionerDetails()
                      ) && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {
                            getWelloOrgOrgFromPractitioner(
                              getCurrentUserPractitionerDetails()
                            )!.name
                          }
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Registration Number
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getRegistrationNumber(
                          getCurrentUserPractitionerDetails()
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Phone
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getTelecomFromContactPoints(
                          getCurrentUserPractitionerRoleDetails().telecom ?? [],
                          ContactPointSystemKind._phone
                        ) ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Email
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getTelecomFromContactPoints(
                          getCurrentUserPractitionerRoleDetails().telecom ?? [],
                          ContactPointSystemKind._email
                        ) ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Profile Description
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getStringValueExtensionsOfUrl(
                          getCurrentUserPractitionerDetails().extension ?? [],
                          PROFILE_SELF_DESCRIPTION_URL
                        ) ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Address
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <OverflowTypography
                        text={
                          getAddress(
                            getCurrentUserPractitionerDetails().address
                          ) ?? ''
                        }
                        typographyProps={{
                          variant: 'subtitle2',
                          color: 'initial',
                          noWrap: true,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Box
        display='flex'
        width='100%'
        flexDirection='row'
        justifyContent='flex-start'
      >
        {getCurrentUserPractitionerDetails() &&
          getValueAttachmentFromExtension(
            getCurrentUserPractitionerDetails().extension,
            'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
          ) === '1' && (
            <Box
              display='flex'
              width='30%'
              flexDirection='row'
              justifyContent='flex-start'
              py={1}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
              >
                <Box width='100%' justifyContent='flex-start'>
                  <Typography
                    color='initial'
                    variant='body1'
                    style={{ wordWrap: 'break-word' }}
                  >
                    Identity Proof Front
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  width='50%'
                  flexDirection='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  py={1}
                >
                  <LogoViewer
                    contentType={getValueAttachmentFromExtensionContentType(
                      getCurrentUserPractitionerDetails().extension,
                      'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                    )}
                    decodingRequired={true}
                    imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                    resourceId={getCurrentUserPractitionerDetails().id ?? ''}
                    resourceType='Practitioner'
                    onSelectionChanged={(value: SelectedFile) => {
                      //   setFrontFile(value)
                    }}
                    update={false}
                    purpose='display2'
                  />
                  {/* <AttachmentImage
                        contentType='image/png'
                        decodingRequired={true}
                        imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                        resourceId={userDetails.practitionerObject.id ?? ''}
                        resourceType='Practitioner'
                      /> */}
                </Box>
              </Box>
            </Box>
          )}

        {getValueAttachmentFromExtension(
          getCurrentUserPractitionerDetails().extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofback'
        ) === '1' && (
          <Box
            display='flex'
            width='30%'
            flexDirection='row'
            justifyContent='flex-start'
            py={1}
            px={1}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-start'
            >
              <Box width='100%' justifyContent='flex-start'>
                <Typography
                  color='initial'
                  variant='body1'
                  style={{ wordWrap: 'break-word' }}
                >
                  Identity Proof Back
                </Typography>
              </Box>
              <Box
                display='flex'
                width='50%'
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                py={1}
              >
                <LogoViewer
                  contentType={getValueAttachmentFromExtensionContentType(
                    getCurrentUserPractitionerDetails().extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                  )}
                  decodingRequired={true}
                  imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)"
                  resourceId={getCurrentUserPractitionerDetails().id ?? ''}
                  resourceType='Practitioner'
                  onSelectionChanged={(value: SelectedFile) => {
                    // setBackFile(value)
                  }}
                  update={false}
                  purpose='display1'
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <EditPractitioner
        practitioner={getCurrentUserPractitionerDetails()}
        practitionerRole={getCurrentUserPractitionerRoleDetails()}
        collegesList={eduColleges}
        open={edit}
        onDone={() => {
          dispatch(fetchUser(getCurrentUserPractitionerDetails().id ?? ''))
          setPractObj(getCurrentUserPractitionerDetails())
          setPractRoleObj(getCurrentUserPractitionerRoleDetails())
          setEdit(false)
          if (onDone) {
            onDone(
              getCurrentUserPractitionerDetails(),
              getCurrentUserPractitionerRoleDetails()
            )
          }
        }}
        onClose={() => {
          setEdit(false)
        }}
      />
    </Box>
  )
}
