import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CPGApiService } from 'services/cpgApiService'
import {
  getCurrentUserPractitionerRoleDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { mergeAndGetSingleCard } from 'utils/careplan_utils/careplan_utils'
import { getErrors, hasError } from 'utils/patientHelper/cds_helper'
import { getVendorPartId } from 'utils/routes_helper'
import { CdsRecommendationsStatus } from './cdsRecommendationsState'
import { CDSResponse } from './cdsResponse'

const initialState: CdsRecommendationsStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const cdsRecommendationsSlice = createSlice({
  name: 'cdsRecommendationsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<CdsRecommendationsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestCdsRecommendationsOfPatient =
  (
    selectedPatient: R4.IPatient,
    type: string,
    onlyEssential?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CdsRecommendationsStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(cdsRecommendationsSlice.actions.updatedStatus(state))

    try {
      const cdsResponses: CDSResponse = {
        cards: [],
      }
      const errorResponsesAll: CDSResponse = {
        cards: [],
      }
      if (type === 'OPD') {
        const response: AxiosResponse =
          await fetchAllopathyCDSRecommendationForPatient(
            selectedPatient,
            onlyEssential
          )

        if (response.status === 200 || response.status === 201) {
          const currentResponseCards: CDSResponse = response.data as CDSResponse

          if (
            currentResponseCards.cards &&
            currentResponseCards.cards.length > 0
          ) {
            const errorResponses = [...currentResponseCards.cards].filter((e) =>
              hasError(e)
            )
            if (errorResponses && errorResponses.length > 0) {
              errorResponsesAll.cards = [
                ...errorResponsesAll.cards!,
                ...errorResponses,
              ]
            }

            const resultResponses = [...currentResponseCards.cards].filter(
              (e) => !hasError(e)
            )
            if (resultResponses && resultResponses.length > 0)
              cdsResponses.cards = [
                ...cdsResponses.cards!,
                mergeAndGetSingleCard({ cards: resultResponses })!,
              ]
          }
        }
      }
      if (type === 'OPD' || type === 'IPD') {
        const ayurvedResponse: AxiosResponse =
          await fetchHTAyurvedaCDSRecommendationForPatient(
            selectedPatient,
            onlyEssential
          )

        if (ayurvedResponse.status === 200 || ayurvedResponse.status === 201) {
          const currentResponseCards: CDSResponse =
            ayurvedResponse.data as CDSResponse
          if (
            currentResponseCards.cards &&
            currentResponseCards.cards.length > 0
          ) {
            const errorResponses = [...currentResponseCards.cards].filter((e) =>
              hasError(e)
            )
            if (errorResponses && errorResponses.length > 0) {
              errorResponsesAll.cards = [
                ...errorResponsesAll.cards!,
                ...errorResponses,
              ]
            }
            const resultResponses = [...currentResponseCards.cards].filter(
              (e) => !hasError(e)
            )

            if (resultResponses && resultResponses.length > 0)
              cdsResponses.cards = [
                ...cdsResponses.cards!,
                ...((ayurvedResponse.data as CDSResponse).cards ?? []),
              ]
          }
        }

        const ayurvedaDiabetesResponse: AxiosResponse =
          await fetchDiabetesAyurvedaCDSRecommendationForPatient(
            selectedPatient,
            onlyEssential
          )

        if (
          ayurvedaDiabetesResponse.status === 200 ||
          ayurvedaDiabetesResponse.status === 201
        ) {
          const currentResponseCards: CDSResponse =
            ayurvedaDiabetesResponse.data as CDSResponse
          if (
            currentResponseCards.cards &&
            currentResponseCards.cards.length > 0
          ) {
            const errorResponses = [...currentResponseCards.cards].filter((e) =>
              hasError(e)
            )
            if (errorResponses && errorResponses.length > 0) {
              errorResponsesAll.cards = [
                ...errorResponsesAll.cards!,
                ...errorResponses,
              ]
            }
            const resultResponses = [...currentResponseCards.cards].filter(
              (e) => !hasError(e)
            )
            if (resultResponses && resultResponses.length > 0)
              cdsResponses.cards = [
                ...cdsResponses.cards!,
                mergeAndGetSingleCard({ cards: resultResponses })!,
              ]
          }
        }
      }

      const ayurvedResponse: AxiosResponse =
        await fetchAyurvedaGenericCDSRecommendationForPatient(
          selectedPatient,
          onlyEssential
        )

      if (ayurvedResponse.status === 200 || ayurvedResponse.status === 201) {
        const currentResponseCards: CDSResponse =
          ayurvedResponse.data as CDSResponse
        if (
          currentResponseCards.cards &&
          currentResponseCards.cards.length > 0
        ) {
          const errorResponses = [...currentResponseCards.cards].filter((e) =>
            hasError(e)
          )
          if (errorResponses && errorResponses.length > 0) {
            errorResponsesAll.cards = [
              ...errorResponsesAll.cards!,
              ...errorResponses,
            ]
          }
          const resultResponses = [...currentResponseCards.cards].filter(
            (e) => !hasError(e)
          )

          if (resultResponses && resultResponses.length > 0)
            cdsResponses.cards = [
              ...cdsResponses.cards!,
              ...((ayurvedResponse.data as CDSResponse).cards ?? []),
            ]
        }
      }

      if (type === 'OPD') {
        const allopathicDiabetesResponse: AxiosResponse =
          await fetchDiabetesAllopathyCDSRecommendationForPatient(
            selectedPatient,
            onlyEssential
          )

        if (
          allopathicDiabetesResponse.status === 200 ||
          allopathicDiabetesResponse.status === 201
        ) {
          const currentResponseCards: CDSResponse =
            allopathicDiabetesResponse.data as CDSResponse
          if (
            currentResponseCards.cards &&
            currentResponseCards.cards.length > 0
          ) {
            const errorResponses = [...currentResponseCards.cards].filter((e) =>
              hasError(e)
            )
            if (errorResponses && errorResponses.length > 0) {
              errorResponsesAll.cards = [
                ...errorResponsesAll.cards!,
                ...errorResponses,
              ]
            }
            const resultResponses = [...currentResponseCards.cards].filter(
              (e) => !hasError(e)
            )
            if (resultResponses && resultResponses.length > 0)
              cdsResponses.cards = [
                ...cdsResponses.cards!,
                mergeAndGetSingleCard({ cards: resultResponses })!,
              ]
          }
        }
      }

      if (
        errorResponsesAll &&
        errorResponsesAll.cards &&
        errorResponsesAll.cards.length > 0
      ) {
        errorResponsesAll.cards.forEach((e) => {
          dispatch(
            showErrorAlert(getErrors(e)?.[0] ?? '', undefined, e.summary, true)
          )
        })
      }

      cdsResponses.cards?.sort((a, b) => a.summary.localeCompare(b.summary))

      const resState: CdsRecommendationsStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: false,
        resultsAvailable: true,
        noResultsAvailable: false,
        availableAppointments: cdsResponses,
      }
      dispatch(cdsRecommendationsSlice.actions.updatedStatus(resState))
    } catch (error) {
      console.error(error)
      const resState: CdsRecommendationsStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(cdsRecommendationsSlice.actions.updatedStatus(resState))
    }
  }

const fetchAllopathyCDSRecommendationForPatient = async (
  patient: R4.IPatient,
  onlyEssential?: boolean
): Promise<AxiosResponse> => {
  const cpgApiService: CPGApiService = new CPGApiService()
  const response: AxiosResponse = await cpgApiService.doPost(
    'allopathy-hypertension-cpg/',
    {
      hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
      hook: 'patient-view',
      vcpg_role: getCurrentUserPractitionerRoleDetails().id,
      fhirServer: `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${getVendorPartId()}`,
      context: {
        patientId: patient.id,
        userId: 'Practitioner/COREPRACTITIONER1',
      },
      parameters: {
        Optimal: true,
        Essential: false,
      },
      fhirAuthorization: {
        access_token: getAccessToken(),
        token_type: 'Bearer',
        expires_in: 300,
        scope: 'patient/*.read',
        subject: 'lazy-checker',
      },
      prefetch: {},
    }
  )

  return response
}

const fetchHTAyurvedaCDSRecommendationForPatient = async (
  patient: R4.IPatient,
  onlyEssential?: boolean
): Promise<AxiosResponse> => {
  const cpgApiService: CPGApiService = new CPGApiService()
  const response: AxiosResponse = await cpgApiService.doPost(
    'tcam-hypertension-cpg/',
    {
      hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
      hook: 'patient-view',
      vcpg_role: getCurrentUserPractitionerRoleDetails().id,
      fhirServer: `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${getVendorPartId()}`,

      context: {
        patientId: patient.id,
        userId: 'Practitioner/COREPRACTITIONER1',
      },
      parameters: {
        Optimal: onlyEssential !== true,
        Essential: onlyEssential !== false,
      },
      fhirAuthorization: {
        access_token: getAccessToken(),
        token_type: 'Bearer',
        expires_in: 300,
        scope: 'patient/*.read',
        subject: 'lazy-checker',
      },
      prefetch: {},
    }
  )

  return response
}

const fetchAyurvedaGenericCDSRecommendationForPatient = async (
  patient: R4.IPatient,
  onlyEssential?: boolean
): Promise<AxiosResponse> => {
  const cpgApiService: CPGApiService = new CPGApiService()
  const response: AxiosResponse = await cpgApiService.doPost(
    'wp_ayurveda_hypertension_cpg/',
    {
      hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
      hook: 'patient-view',
      vcpg_role: getCurrentUserPractitionerRoleDetails().id,
      fhirServer: `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${getVendorPartId()}`,

      context: {
        patientId: patient.id,
        userId: 'Practitioner/COREPRACTITIONER1',
      },
      parameters: {
        Optimal: onlyEssential !== true,
        Essential: onlyEssential !== false,
      },
      fhirAuthorization: {
        access_token: getAccessToken(),
        token_type: 'Bearer',
        expires_in: 300,
        scope: 'patient/*.read',
        subject: 'lazy-checker',
      },
      prefetch: {},
    }
  )

  return response
}

const fetchDiabetesAyurvedaCDSRecommendationForPatient = async (
  patient: R4.IPatient,
  onlyEssential?: boolean
): Promise<AxiosResponse> => {
  const cpgApiService: CPGApiService = new CPGApiService()
  const response: AxiosResponse = await cpgApiService.doPost(
    'hv-diabetes-cpg/',
    {
      hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
      hook: 'patient-view',
      vcpg_role: getCurrentUserPractitionerRoleDetails().id,
      fhirServer: `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${getVendorPartId()}`,

      context: {
        patientId: patient.id,
        userId: 'Practitioner/COREPRACTITIONER1',
      },

      fhirAuthorization: {
        access_token: getAccessToken(),
        token_type: 'Bearer',
        expires_in: 300,
        scope: 'patient/*.read',
        subject: 'lazy-checker',
      },
      prefetch: {},
    }
  )

  return response
}

const fetchDiabetesAllopathyCDSRecommendationForPatient = async (
  patient: R4.IPatient,
  onlyEssential?: boolean
): Promise<AxiosResponse> => {
  const cpgApiService: CPGApiService = new CPGApiService()
  const response: AxiosResponse = await cpgApiService.doPost(
    'allopathy-diabetes-cpg/',
    {
      hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
      hook: 'patient-view',
      vcpg_role: getCurrentUserPractitionerRoleDetails().id,
      fhirServer: `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${getVendorPartId()}`,

      context: {
        patientId: patient.id,
        userId: 'Practitioner/COREPRACTITIONER1',
      },

      fhirAuthorization: {
        access_token: getAccessToken(),
        token_type: 'Bearer',
        expires_in: 300,
        scope: 'patient/*.read',
        subject: 'lazy-checker',
      },
      prefetch: {},
    }
  )

  return response
}

export default cdsRecommendationsSlice.reducer
