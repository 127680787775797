import { Box, CircularProgress, useTheme } from '@material-ui/core'
import {
  faEdit,
  faFilePdf,
  faFileWord,
  faTrash,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getContentOfResource } from 'services/fileServices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImageViewer } from './reportViewer/imageViewer'
import { PdfViewer } from './fhirResources/pdfviewer'
import { SelectedFile } from '../LeftMenu/welloFilePickerIPD'

export interface AttachmentImageViewer {
  imagePath: string
  resourceId: string
  decodingRequired: boolean
  resourceType: string
  contentType: string
  attendant?: boolean
}

interface ImgState {
  height: number
  width: number
}

export const AttachmentImage: React.FC<AttachmentImageViewer> = ({
  imagePath,
  resourceId,
  decodingRequired,
  resourceType,
  contentType,
  attendant,
}) => {
  const { t } = useTranslation()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })
  const kPrimaryColor: string = useTheme().palette.primary.main
  const [imgSrc, setImgSrc] = useState<string>()
  const [file, setFile] = useState<SelectedFile>()

  const [imgSrcForPreview, setImgSrcForPreview] = useState<string>()

  const [show, setShow] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const [openImageState, setOpenImageState] = useState<ImgState>({
    height: 90,
    width: attendant ? 130 : 160,
  })

  function fetchInvoice() {
    setFetchingStatus({
      requesting: true,
    })

    getContentOfResource(
      resourceType,
      '',
      resourceId,
      contentType,
      imagePath,
      decodingRequired
    )
      .then((src) => {
        setImgSrc(src)
        setFetchingStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    fetchInvoice()
  }, [])

  return (
    <>
      {fetchingStatus.requesting && <CircularProgress size={20} />}
      {fetchingStatus.requestSuccess &&
        imgSrc &&
        contentType.includes('image') && (
          <Box
            display='flex'
            flexDirection='column'
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              setShow(true)
              setImgSrcForPreview(imgSrc)
            }}
          >
            <img
              src={imgSrc}
              alt='img'
              height={`${openImageState.height}px`}
              width={`${openImageState.width}px`}
            />
          </Box>
        )}

      {fetchingStatus.requestSuccess && imgSrc && contentType.includes('pdf') && (
        <Box
          display='flex'
          flexDirection='column'
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon
            id='selected_icon'
            icon={faFilePdf}
            size='2x'
            color={kPrimaryColor}
            style={{
              color: kPrimaryColor,
            }}
            onClick={() => {
              setFile({
                type: 'document',
                path: imgSrc,
                name: 'pdf',
              })

              setShowPreview(true)
            }}
          />
        </Box>
      )}

      {showPreview && file && (
        <PdfViewer
          data={file.path ? (file.path as string) : ''}
          showPreview={showPreview}
          onCloseClicked={() => {
            setShowPreview(false)
          }}
        />
      )}

      <ImageViewer
        imageSrc={imgSrcForPreview!}
        showPreview={show}
        height={210}
        width={300}
        onCloseClicked={() => {
          setShow(false)
          setImgSrcForPreview(undefined)
        }}
      />
    </>
  )
}
