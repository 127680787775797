/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  TextField,
  ListItem,
} from '@material-ui/core'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import * as _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addObsData,
  addObsDataForAppointment,
  getErrorStatus,
  getObsDefData,
  getObsDefDataForLabResults,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  ObsDataWithValForCatalog,
  ObsServiceDataWithVal,
} from 'models/obsServiceData'

import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  getAgeOfPatientForChecking,
  getGenderOfPatient,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { MasterDataSelecter } from 'views/components/lab/catalougeSetup/masterDataSelector'
import { WelloLoadingPage, WelloTextField } from 'wello-web-components'
import moment from 'moment'
import { requestLabTestDetailsDetailsOfPatient } from 'redux/consultation/labTestOPD/labDetailSlice'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import {
  combinedArray,
  getObsDataForDisplay,
  removeDuplicateTestNames,
} from 'utils/fhirResoureHelpers/observationHelpers'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onVitalAdded: () => void
}
export const AddLabTestOPD: React.FC<Props> = ({
  open,
  onClose,
  onVitalAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const useStylesDialog = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))
  const classes = useStyles()
  const classesDialog = useStylesDialog()
  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )

  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [addStatus, setAddStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [obsData, setObsData] = useState<ObsServiceDataWithVal>()
  const [obsDataArr, setObsDataArr] = useState<ObsServiceDataWithVal[]>([])

  const [obsDataWithGrouping, SetObsDataWithGrouping] = useState<
    ObsDataWithValForCatalog[]
  >([])

  const [reportDate, setReportDate] = useState<Date>(new Date())
  const [planDefData, setPlanDefData] = useState<R4.IPlanDefinition>()

  function updateLabResults(obsDataFInal: ObsServiceDataWithVal[]) {
    setAddStatus({ requesting: true })

    setSaveLoading(true)

    addObsDataForAppointment(
      reportDate,
      obsDataFInal,
      planDefData!,
      fhirAppointmentDetails
    )
      .then((e: boolean) => {
        setSaveLoading(false)
        if (e === true) {
          setObsDataArr([])
          setPlanDefData(undefined)
          setReportDate(new Date())
          dispatch(
            requestLabTestDetailsDetailsOfPatient(
              fhirAppointmentDetails.patient,
              fhirAppointmentDetails.appointment.id!
            )
          )
          onVitalAdded()
          dispatch(showSuccessAlert('Lab results added successfully'))
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })
          setSaveLoading(false)
          dispatch(
            showErrorAlert('Error while adding details. Please try again later')
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setSaveLoading(false)
        setAddStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function getObservationDefinitionData(PlanDef: R4.IPlanDefinition) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getObsDefDataForLabResults(PlanDef)
      .then((e: ObsServiceDataWithVal) => {
        setLoading(false)
        if (e) {
          setObsData({
            name: e.name,
            obsDef: e.obsDef.sort((a, b) =>
              a.obsDef.preferredReportName! > b.obsDef.preferredReportName!
                ? 1
                : b.obsDef.preferredReportName! > a.obsDef.preferredReportName!
                ? -1
                : 0
            ),
          })
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching details. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function enableData() {
    let result: boolean = false
    const data: string[] = []
    const withData: string[] = []
    if (obsDataArr.length === 0) {
      return true
    }
    console.log(obsDataArr)
    for (let i = 0; i < obsDataArr.length; i++) {
      if (obsDataArr[i].obsDef && obsDataArr[i].obsDef.length > 0) {
        for (let j = 0; j < obsDataArr[i].obsDef.length; j++) {
          if (
            obsDataArr[i].obsDef[j].obsDef.permittedDataType &&
            obsDataArr[i].obsDef[j].obsDef.permittedDataType![0] === 'Quantity'
          ) {
            if (obsDataArr[i].obsDef[j].valInt === undefined) {
              data.push('1')
            } else {
              withData.push('1')
            }
          }
          if (
            obsDataArr[i].obsDef[j].obsDef.permittedDataType &&
            obsDataArr[i].obsDef[j].obsDef.permittedDataType![0] !== 'Quantity'
          ) {
            if (obsDataArr[i].obsDef[j].valStringData === undefined) {
              data.push('1')
            }
            if (obsDataArr[i].obsDef[j].valStringData) {
              if (obsDataArr[i].obsDef[j].valStringData!.length === 0) {
                data.push('1')
              } else {
                withData.push('1')
              }
            }
          }
        }
      }
    }

    if (data.length > 0 && withData.length > 0) {
      result = false
    }
    if (data.length === 0 && withData.length > 0) {
      result = false
    }
    if (data.length > 0 && withData.length === 0) {
      result = true
    }

    return result
  }

  function getMinError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let lowVal: number | undefined = 0

    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            lowVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.low?.value
              : undefined
          }
        } else {
          lowVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.low?.value
            : 0
        }
      }
    }

    return lowVal
  }

  function getHighError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let highVal: number | undefined
    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            highVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.high?.value
              : 0
          }
        } else {
          highVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.high?.value
            : 0
        }
      }
    }

    return highVal
  }

  function handleDateChange(date: any) {
    setReportDate(moment(date.target.value).toDate())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classesDialog.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>Add Lab Test Result</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box width='30%' py={1}>
            <WelloTextField
              title='Select Date'
              textProps={{
                id: `date`,
                value: moment(reportDate).format('YYYY-MM-DD'),
                inputProps: {
                  max: moment().format('YYYY-MM-DD'),
                  min: getAgeOfPatientForChecking(
                    fhirAppointmentDetails.patient
                  ),
                },
                onKeyDown: (e) => {
                  if (e.code !== 'Tab') {
                    e.preventDefault()
                  }
                },

                type: 'date',
                onChange: (changeData) => {
                  handleDateChange(changeData)
                },
              }}
            />
          </Box>
          <Box width='100%' display='flex' flexDirection='column'>
            <Box width='60%' display='flex' flexDirection='column'>
              <WelloFormItemLabel title='Select Lab Test/Panel' />
              {labTestSearchSlice.obsDefVal &&
                labTestSearchSlice.obsDefVal.length > 0 && (
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    limitTags={2}
                    fullWidth
                    size='small'
                    multiple={true}
                    id='free-solo-2-demo'
                    disableClearable
                    renderGroup={(params) => (
                      <Box key={params.key}>
                        <Box px={1}>
                          <Typography variant='subtitle1'>
                            {params.group}
                          </Typography>
                        </Box>
                        <Box px={1} py={0}>
                          <Typography variant='subtitle2'>
                            {params.children}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      titleCase(option.obsDisplayName)
                    }
                    options={
                      labTestSearchSlice.obsDefVal ??
                      []
                        .map((option: ObsDataWithValForCatalog) => {
                          const firstLetter = titleCase(option.obsDisplayName)
                          return {
                            firstLetter: /[0-9]/.test(firstLetter)
                              ? '0-9'
                              : firstLetter,
                            ...option,
                          }
                        })
                        .sort(
                          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )
                    }
                    groupBy={(option) => option.name}
                    onChange={(
                      e,
                      changedValue: ObsDataWithValForCatalog[],
                      reason
                    ) => {
                      console.log(obsDataArr)
                      if (obsDataArr.length > 0) {
                        setObsDataArr(
                          combinedArray(
                            getObsDataForDisplay(changedValue, []),
                            obsDataArr
                          )
                        )
                      } else {
                        setObsDataArr(getObsDataForDisplay(changedValue, []))
                      }
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderOption={(option, { inputValue }) => {
                      const matches = match(
                        option.obsDisplayName ?? '',
                        inputValue
                      )
                      const parts = parse(option.obsDisplayName ?? '', matches)
                      return (
                        <ListItem id={`lab_test_opt_${option.id}`}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={part.text}
                                style={{
                                  backgroundColor: part.highlight
                                    ? kPrimaryLight
                                    : undefined,
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </ListItem>
                      )
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Lab Tests'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                )}

              {/* <MasterDataSelecter
              disabled={false}
              onSelectionChanges={(plans) => {
                setPlanDefData(plans)
                getObservationDefinitionData(plans)
              }}
            /> */}
            </Box>
          </Box>

          <Box display='flex' flexDirection='column' width='100%' py={1}>
            {loading && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
              >
                <CircularProgress size={20} />
              </Box>
            )}
            <Box display='flex' flexDirection='column' width='100%'>
              {obsDataArr.map((obsArrData, indexFinal) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  key={obsArrData.name}
                  width='100%'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height={40}
                    py={1}
                  >
                    <Typography
                      variant='subtitle2'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                        lineHeight: 'normal',
                      }}
                    >
                      {' '}
                      {obsArrData.name}
                    </Typography>
                  </Box>
                  {obsArrData && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      {obsArrData.obsDef.length > 0 && (
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          width='100%'
                          height={40}
                          style={{
                            backgroundColor: 'lightGrey',
                          }}
                        >
                          <Box
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            px={1}
                          >
                            <Box width='20%'>
                              <Typography
                                variant='subtitle2'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                {' '}
                                Parameter Name
                              </Typography>
                            </Box>
                            <Box
                              width='40%'
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              paddingRight={1}
                            >
                              <Typography
                                variant='subtitle2'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                {' '}
                                Value
                              </Typography>
                            </Box>
                            <Box
                              width='25%'
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              paddingLeft={2.5}
                            >
                              <Typography
                                variant='subtitle2'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                {' '}
                                Unit
                              </Typography>
                            </Box>
                            <Box
                              width='15%'
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                            >
                              <Typography
                                variant='subtitle2'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                {' '}
                                Normal Range
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {obsArrData.obsDef.map((item, index) => (
                        <Box
                          width='100%'
                          display='flex'
                          flexDirection='row'
                          px={1}
                          py={1}
                          key={item.obsDef.id}
                        >
                          <Box width='20%' py={1} paddingRight={1}>
                            <Typography
                              variant='subtitle2'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {item.obsDef.preferredReportName ?? ''}
                            </Typography>
                          </Box>

                          <Box
                            width='40%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            paddingRight={1}
                          >
                            {item.obsDef.permittedDataType &&
                              item.obsDef.permittedDataType[0] ===
                                'Quantity' && (
                                <TextField
                                  variant='outlined'
                                  fullWidth
                                  type='number'
                                  size='small'
                                  disabled={saveLoading}
                                  value={item.valInt ? item.valInt : undefined}
                                  onChange={(event) => {
                                    const values: ObsServiceDataWithVal = {
                                      ...obsArrData,
                                    }
                                    console.log(Number(event.target.value))

                                    if (event.target.value.length > 0) {
                                      values.obsDef[index].valInt = parseFloat(
                                        parseFloat(event.target.value).toFixed(
                                          2
                                        )
                                      )
                                    } else {
                                      values.obsDef[index].valInt = Number(
                                        event.target.value
                                      )
                                    }

                                    if (
                                      getErrorStatus(
                                        item.obsDef,
                                        fhirAppointmentDetails.patient,
                                        parseFloat(
                                          parseFloat(
                                            event.target.value
                                          ).toFixed(2)
                                        )
                                      )
                                    ) {
                                      values.obsDef[index].checked = true
                                    } else {
                                      values.obsDef[index].checked = false
                                    }
                                    setObsData(values)
                                  }}
                                  error={
                                    item.valInt
                                      ? getErrorStatus(
                                          item.obsDef,
                                          fhirAppointmentDetails.patient,
                                          item.valInt
                                        )
                                      : false
                                  }
                                  InputProps={{
                                    inputProps: {
                                      step: 0.1,
                                    },
                                  }}
                                />
                              )}
                            {item.obsDef.permittedDataType &&
                              item.obsDef.permittedDataType[0] !==
                                'Quantity' && (
                                <TextField
                                  variant='outlined'
                                  fullWidth
                                  type='text'
                                  size='small'
                                  disabled={saveLoading}
                                  value={
                                    item.valStringData ? item.valStringData : ''
                                  }
                                  onChange={(event) => {
                                    const values: ObsServiceDataWithVal = {
                                      ...obsArrData,
                                    }
                                    console.log(event.target.value)
                                    if (event.target.value.length > 0) {
                                      values.obsDef[index].valStringData =
                                        event.target.value
                                    } else {
                                      values.obsDef[index].valStringData = ''
                                    }

                                    setObsData(values)
                                  }}
                                  error={
                                    item.valStringData
                                      ? item.valStringData.length === 0
                                      : false
                                  }
                                />
                              )}
                          </Box>
                          <Box
                            width='25%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            paddingLeft={3}
                          >
                            {item.obsDef.permittedDataType &&
                              item.obsDef.permittedDataType[0] ===
                                'Quantity' && (
                                <Box
                                  py={1}
                                  display='flex'
                                  flexDirection='row'
                                  justifyContent='center'
                                  px={2}
                                  width='100%'
                                >
                                  <Typography
                                    variant='subtitle2'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {item.obsDef.quantitativeDetails
                                      ? item.obsDef.quantitativeDetails.unit
                                        ? item.obsDef.quantitativeDetails.unit
                                            .coding![0].code!
                                        : ''
                                      : ''}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                          <Box
                            width='15%'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            paddingLeft={3}
                          >
                            {item.obsDef.permittedDataType &&
                              item.obsDef.permittedDataType[0] ===
                                'Quantity' && (
                                <Box
                                  width='100%'
                                  display='flex'
                                  flexDirection='row'
                                  py={1}
                                >
                                  {getMinError(
                                    item.obsDef,
                                    fhirAppointmentDetails.patient
                                  ) !== undefined &&
                                    getHighError(
                                      item.obsDef,
                                      fhirAppointmentDetails.patient
                                    ) === undefined && (
                                      <Typography
                                        variant='subtitle2'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {` >= ${getMinError(
                                          item.obsDef,
                                          fhirAppointmentDetails.patient
                                        )}`}
                                      </Typography>
                                    )}

                                  {getMinError(
                                    item.obsDef,
                                    fhirAppointmentDetails.patient
                                  ) === undefined &&
                                    getHighError(
                                      item.obsDef,
                                      fhirAppointmentDetails.patient
                                    ) !== undefined && (
                                      <Typography
                                        variant='subtitle2'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {` <= ${getHighError(
                                          item.obsDef,
                                          fhirAppointmentDetails.patient
                                        )}`}
                                      </Typography>
                                    )}
                                  {getMinError(
                                    item.obsDef,
                                    fhirAppointmentDetails.patient
                                  ) !== undefined &&
                                    getHighError(
                                      item.obsDef,
                                      fhirAppointmentDetails.patient
                                    ) !== undefined && (
                                      <Typography
                                        variant='subtitle2'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {`${getMinError(
                                          item.obsDef,
                                          fhirAppointmentDetails.patient
                                        )} - ${getHighError(
                                          item.obsDef,
                                          fhirAppointmentDetails.patient
                                        )}`}
                                      </Typography>
                                    )}
                                </Box>
                              )}
                          </Box>
                          {/* {item.obsDef.permittedDataType &&
                      item.obsDef.permittedDataType[0] === 'Quantity' && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                          width='20%'
                        >
                          <Checkbox
                            edge='start'
                            checked={
                              item.valInt
                                ? getErrorStatus(
                                    item.obsDef,
                                    fhirAppointmentDetails.patient,
                                    item.valInt ?? 0
                                  ) || item.checked
                                : false
                            }
                            // onChange={handleChange}
                            tabIndex={-1}
                            size='small'
                            disableRipple
                            color='primary'
                            inputProps={{ 'aria-labelledby': 'consent' }}
                            role='unit_search_consent'
                          />
                        </Box>
                      )} */}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          id='cancel'
          variant='outlined'
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>

        <Button
          onClick={() => {
            updateLabResults(obsDataArr)
          }}
          variant='contained'
          color='primary'
          id='add'
          disableElevation
          disabled={enableData() || saveLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
