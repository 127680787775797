import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import { CarePlan } from 'models/carePlan'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getWellnessServiceRequestsOfAppointment } from 'redux/consultation/wellness_referrals_list/wellnessReferralsListSlice'
import { fetchFollowUpAppointments } from 'redux/fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import {
  getCurrentUserPractitionerDetails,
  isAyurvedaDoctor,
  isCurrentUserDoctor,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'

import { requestDietTiming } from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { AddMedicationsHandler } from '../assessment/addMedication'
import { AddWellnessReferrals } from '../assessment/addWellnessReferrals'
import { MedicationsList } from '../assessment/medicationsList'
import { AddDiagnosticsTests } from './add_diagnostic_orders'
import { KriyasListForEncounter } from './ayurveda/kriyasListForEncounter'
import { PathyaApathyaListForEncounter } from './ayurveda/pathyaApathyaList'
import { RecommendationWrapper } from './cdsRecommendations/cds_reccomendation_wrapper'
import { RecommendationWrapperForSub } from './cdsRecommendations/cds_reccomendation_wrapper_new'
import { DietPlanList } from './dietPlanList'
import { ImagingDiagnosticsList } from './imagingDiagnosticsList'
import { CarePlanInstructionList } from './instructionsList'
import { LabDiagnosticRequestsList } from './labDiagnosticList'
import { NextStepPlan } from './nextStepPlan'
import { NextStepMultipleSubPlan } from './nextStepPlanMultipleSubPlan'
import { PatientFolloWUpAppointments } from './pateintFollowUpAppointments'
import { ReferralRequestList } from './referrals_list'
import { NextStepMultipleSubPlanForSplit } from './splitPageMultiPlan'
import { NextSplit } from './splitPlan'
import { WeeklyDietPlanList } from './weeklyDietPlanList'
import { WellnessReferralsList } from './wellnessRefferalsList'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const PlanSplit: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  split,
}: NextStepsOfConsultationProps) => {
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [showCohorts, setShowCohorts] = useState<boolean>(false)
  const plans: CarePlan[] = [
    {
      planName: 'Hypertension Care Plan',
      planDescription: '',
    },
  ]
  const [kriyaListState, setKriyaListState] = useState<string>(
    _.random(0, 10000).toString()
  )
  const [aharaUpdateState, setAharaUpdateState] = useState<string>(
    _.random(0, 10000).toString()
  )
  const [viharaUpdateState, setViharaUpdateState] = useState<string>(
    _.random(0, 10000).toString()
  )
  const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
    useState<boolean>(false)
  const [openAddWellReferrals, setOpenAddWellReferrals] =
    useState<boolean>(false)
  const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
    useState(false)

  useEffect(() => {
    dispatch(getAllNutritionValueSets())

    if (isCurrentUserDoctor()) {
      dispatch(requestDietTiming(getCurrentUserPractitionerDetails().id ?? ''))
    }
  }, [])

  return (
    <Box display='flex' flexDirection='row' width='100%'>
      <Box display='flex' flexDirection='column' width='100%'>
        <Box
          display='flex'
          flexDirection='row'
          paddingTop={1}
          paddingBottom={0.5}
          flexGrow
          width={isAyurvedaDoctor() ? '100%' : '100%'}
        >
          <NextSplit
            planName={t('labelMedication:resourceName')}
            subString=''
            onClicked={() => {
              if (split === false) {
                setOpenAddMedicationDialogue(true)
              }
            }}
            bottomWidget={
              <MedicationsList
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={true}
              />
            }
            /* count={2} */
          />
        </Box>
        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName={t('labelCommon:lab_test')}
            subString=''
            bottomWidget={
              <LabDiagnosticRequestsList
                patient={fhirAppointmentDetails.patient}
                practitionerDetails={fhirAppointmentDetails.practitionerDetail}
                encounter={fhirAppointmentDetails.encounter}
                followUp={false}
                supportingInfo={
                  fhirAppointmentDetails.appointment.supportingInformation ?? []
                }
              />
            }
            onClicked={() => {
              if (split === false) {
                setOpenAddDiagnosticDetails(true)
              }
            }}
          />
        </Box>

        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName='Imaging Studies'
            subString=''
            bottomWidget={
              <ImagingDiagnosticsList
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            }
            onClicked={() => {
              //   setOpenAddImagingDiagnosticDetails(true)
            }}
          />
        </Box>

        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName={t('labelCommon:followup_appointment')}
            subString=''
            bottomWidget={
              <PatientFolloWUpAppointments
                fhirPatientDetail={fhirAppointmentDetails}
              />
            }
            onClicked={() => {
              if (split === false) {
                setOpenCreateAppointmentPopup(true)
              }
            }}
          />
        </Box>
        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName='Referral Appointment'
            subString=''
            onClicked={() => {}}
            bottomWidget={
              <ReferralRequestList
                fhirAppointmentDetails={fhirAppointmentDetails}
              />
            }
          />
        </Box>
        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName='Wellness Referrals'
            subString=''
            onClicked={() => {
              if (split === false) {
                setOpenAddWellReferrals(true)
              }
            }}
            bottomWidget={
              <WellnessReferralsList
                fhirAppointmentDetails={fhirAppointmentDetails}
              />
            }
          />
        </Box>

        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName='Instructions'
            subString=''
            bottomWidget={
              <CarePlanInstructionList
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={false}
              />
            }
          />
        </Box>

        {isAyurvedaDoctor() && (
          <Box display='flex' flexDirection='row' py={0.5} width='100%'>
            <NextSplit
              planName='Kriyas (Procedure)'
              subString=''
              bottomWidget={
                <KriyasListForEncounter
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  updateList={kriyaListState}
                  isReadonly={split}
                />
              }
              onClicked={() => {
                if (
                  fhirAppointmentDetails.encounter !== undefined &&
                  fhirAppointmentDetails.encounter.id !== undefined
                ) {
                  //   setOpenKriyas(true)
                } else {
                  dispatch(
                    showErrorAlert(
                      'No details have been added as part of Appointment. Please add few details about patient visit'
                    )
                  )
                }
              }}
            />
          </Box>
        )}

        <Box display='flex' flexDirection='row' py={0.5} width='100%'>
          <NextSplit
            planName='Diet Plan'
            subString=''
            bottomWidget={
              <Box display='flex' flexDirection='row' width='100%'>
                {fhirAppointmentDetails.encounter && (
                  <DietPlanList
                    encounter={fhirAppointmentDetails.encounter}
                    patient={fhirAppointmentDetails.patient}
                    practitionerRole={
                      fhirAppointmentDetails.practitionerDetail.practitionerRole
                    }
                    onClick={() => {
                      // setOpenDietPlan(true)
                    }}
                    split={true}
                  />
                )}
                {/* <WeeklyDietPlanList
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  onClick={() => {
                    console.log('weekly diet plan clicked')
                  }}
                /> */}
              </Box>
            }
          />
        </Box>

        {isAyurvedaDoctor() && (
          <Box display='flex' flexDirection='row' py={0.5} width='100%'>
            <NextStepMultipleSubPlanForSplit
              planName='Pathya Apathya'
              subPlans={[
                {
                  subPlanName: 'Ahara',
                  subString: '',
                  isAddDisplay: false,
                  isEditAllowed: false,
                  bottomWidget: (
                    <PathyaApathyaListForEncounter
                      fhirAppointmentDetails={fhirAppointmentDetails}
                      pathyaApathyaTypeCode={{
                        system: 'http://www.who.int/ita',
                        code: 'ITA-8.1.2',
                        display: 'Ahara',
                      }}
                      updateList={aharaUpdateState}
                      isReadonly={split}
                    />
                  ),
                  onClicked: () => {
                    // setAddPathyaApathyProps({
                    //   ...addPathyaApathyProps,
                    //   open: true,
                    //   pathyaApathyaTypeCode: {
                    //     system: 'http://www.who.int/ita',
                    //     code: 'ITA-8.1.2',
                    //     display: 'Ahara',
                    //   },
                    //   onLabDiagnosticsAdded: () => {
                    //     setAharaUpdateState(_.random(0, 1000).toString())
                    //   },
                    //   onClose: () => {},
                    // })
                  },
                },
                {
                  subPlanName: 'Vihara',
                  isAddDisplay: false,
                  isEditAllowed: false,
                  bottomWidget: (
                    <PathyaApathyaListForEncounter
                      fhirAppointmentDetails={fhirAppointmentDetails}
                      pathyaApathyaTypeCode={{
                        system: 'http://www.who.int/ita',
                        code: 'ITA-10.2.14',
                        display: 'Vihara',
                      }}
                      updateList={viharaUpdateState}
                      isReadonly={split}
                    />
                  ),
                  subString: '',
                  onClicked: () => {
                    // setAddPathyaApathyProps({
                    //   ...addPathyaApathyProps,
                    //   open: true,
                    //   pathyaApathyaTypeCode: {
                    //     system: 'http://www.who.int/ita',
                    //     code: 'ITA-10.2.14',
                    //     display: 'Vihara',
                    //   },
                    //   onLabDiagnosticsAdded: () => {
                    //     setViharaUpdateState(_.random(0, 1000).toString())
                    //   },
                    //   onClose: () => {
                    //     setAddPathyaApathyProps({
                    //       ...addPathyaApathyProps,
                    //       open: false,
                    //     })
                    //   },
                    // })
                  },
                },
              ]}
            />
          </Box>
        )}
        <AddDiagnosticsTests
          open={openAddDiagnosticDetails}
          encounter={fhirAppointmentDetails.encounter!}
          patient={fhirAppointmentDetails.patient}
          practitionerDetails={fhirAppointmentDetails.practitionerDetail}
          onClose={() => {
            dispatch(
              getLabRequestsOfAppointment(
                fhirAppointmentDetails.patient,
                fhirAppointmentDetails.practitionerDetail,
                fhirAppointmentDetails.encounter
              )
            )
            setOpenAddDiagnosticDetails(false)
          }}
          onLabDiagnosticsAdded={() => {
            dispatch(
              getLabRequestsOfAppointment(
                fhirAppointmentDetails.patient,
                fhirAppointmentDetails.practitionerDetail,
                fhirAppointmentDetails.encounter
              )
            )
            setOpenAddDiagnosticDetails(false)
          }}
        />
        <AddMedicationsHandler
          open={openAddMedicationDialogue}
          encounter={fhirAppointmentDetails.encounter!}
          patient={fhirAppointmentDetails.patient}
          practitionerDetail={fhirAppointmentDetails.practitionerDetail}
          onClose={() => {
            dispatch(
              getMedicationsOfAppointment(
                fhirAppointmentDetails.patient,

                fhirAppointmentDetails.encounter,
                undefined,
                fhirAppointmentDetails.appointment.supportingInformation
              )
            )
            setOpenAddMedicationDialogue(false)
          }}
          onMedicalConditionAdded={() => {
            dispatch(
              getMedicationsOfAppointment(
                fhirAppointmentDetails.patient,

                fhirAppointmentDetails.encounter,
                undefined,
                fhirAppointmentDetails.appointment.supportingInformation
              )
            )
            setOpenAddMedicationDialogue(false)
          }}
        />

        {openAddWellReferrals && (
          <AddWellnessReferrals
            open={openAddWellReferrals}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getWellnessServiceRequestsOfAppointment(fhirAppointmentDetails)
              )
              setOpenAddWellReferrals(false)
            }}
            onReferralsAdded={() => {
              dispatch(
                getWellnessServiceRequestsOfAppointment(fhirAppointmentDetails)
              )
              setOpenAddWellReferrals(false)
            }}
          />
        )}
        <CreateAppointmentHandler
          open={openCreateAppointmentPopup}
          allowWalkIn={false}
          followUp={true}
          preSelectedDoctor={{
            id: fhirAppointmentDetails.practitionerDetail.practitioner.id ?? '',
            roleObject:
              fhirAppointmentDetails.practitionerDetail.practitionerRole,
            practRoleId:
              fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
            practitionerObject:
              fhirAppointmentDetails.practitionerDetail.practitioner,
            name: getNameFromHumanName(
              fhirAppointmentDetails.practitionerDetail.practitioner.name ?? []
            ),
            phone: '',
            fullName: '',
            color: '',
            primaryContact: '',
            status: '',
            enabled: fhirAppointmentDetails.practitionerDetail.practitioner
              .active
              ? fhirAppointmentDetails.practitionerDetail.practitioner.active
              : false,
          }}
          fhirAppointmentDetail={fhirAppointmentDetails}
          onClose={() => {
            setOpenCreateAppointmentPopup(false)
            dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
          }}
          onAppointmentCreated={(createdAppointment) => {
            setOpenCreateAppointmentPopup(false)
            dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
          }}
        />
      </Box>
      {!split && (
        <Box display='flex' flexDirection='column' width='50%' p={2}>
          <RecommendationWrapperForSub
            encounter={fhirAppointmentDetails.encounter!}
            patient={fhirAppointmentDetails.patient}
            onRecommendationAdditionUpdate={(category) => {
              switch (category) {
                case 'instruction': {
                  setTimeout(() => {
                    dispatch(
                      getInstructionsOfAppointment(fhirAppointmentDetails)
                    )
                  }, 500)

                  break
                }
                case 'careplan-follow-up': {
                  setTimeout(() => {
                    dispatch(
                      getInstructionsOfAppointment(fhirAppointmentDetails)
                    )
                  }, 500)
                  break
                }
                case 'lab': {
                  setTimeout(() => {
                    dispatch(
                      getLabRequestsOfAppointment(
                        fhirAppointmentDetails.patient,
                        fhirAppointmentDetails.practitionerDetail,
                        fhirAppointmentDetails.encounter
                      )
                    )
                  }, 500)
                  break
                }
                case 'medication': {
                  setTimeout(() => {
                    dispatch(
                      getMedicationsOfAppointment(
                        fhirAppointmentDetails.patient,

                        fhirAppointmentDetails.encounter
                      )
                    )
                  }, 500)
                  break
                }
                case 'specialistReferral': {
                  break
                }
                default:
                  break
              }
            }}
          />
        </Box>
      )}
    </Box>
  )
}
