import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import {
  getExpandedAppointmentFromBundle,
  getRelativesData,
} from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import {
  getLastNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import { MasterFhirClient } from 'services/masterFhirService'
import { GroupPatient } from 'models/groupPatient'
import moment from 'moment'
import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'
import { isFrontDesk } from 'services/userDetailsService'
import { PatientSearchStatus } from './patientSearchStatusTypes'

const initialState: PatientSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const patientSearchSlice = createSlice({
  name: 'patientSearch',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<PatientSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.patientListWithAppointment =
        action.payload.patientListWithAppointment
      state.patientList = action.payload.patientList
      state.groupDataList = action.payload.groupDataList
    },

    noDataFoundForSearch(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.patientListWithAppointment =
        action.payload.patientListWithAppointment
      state.patientList = action.payload.patientList
      state.groupDataList = action.payload.groupDataList
    },

    errorWhileSearching(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.patientListWithAppointment =
        action.payload.patientListWithAppointment
      state.patientList = action.payload.patientList
      state.groupDataList = action.payload.groupDataList
    },
    resetState(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.patientList = undefined
      state.groupDataList = undefined
    },
  },
})

export const resetPatientSearchPatient =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PatientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      patientList: undefined,
    }
    dispatch(patientSearchSlice.actions.resetState(state))
  }

export const searchPatients =
  (phoneNumber: string, name: string, id?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: PatientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(patientSearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const fhirClientMaster: FHIRWithMasterApiClient =
        new FHIRWithMasterApiClient()
      const searchParameters: any = {
        _count: 5000,
        _total: 'accurate',
        active: true,
        _revinclude: 'RelatedPerson:patient',
      }

      if (phoneNumber.length > 0 && id === undefined) {
        searchParameters.phone = `+91${phoneNumber.trim()}`
      }

      //   searchParameters._sort = 'family,given'
      axios.CancelToken.source()
      if (cancelTokenStore.has('patientSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'patientSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('patientSearchControlToken')
      }
      cancelTokenStore.set(
        'patientSearchControlToken',
        axios.CancelToken.source()
      )
      let response: any = {}
      if (isFrontDesk() === false) {
        if (name.length > 0) {
          response = await fhirClient.doGetResource(
            `/Patient?name:contains=${name}`,
            searchParameters,
            (
              cancelTokenStore.get(
                'patientSearchControlToken'
              ) as CancelTokenSource
            ).token
          )
        } else if (id === undefined && phoneNumber.length > 0) {
          response = await fhirClient.doGetResource(
            `/Patient?patient-identifier-phone-new:contains=${phoneNumber}&_revinclude=Group:member`,
            searchParameters,
            (
              cancelTokenStore.get(
                'patientSearchControlToken'
              ) as CancelTokenSource
            ).token
          )
        } else if (id === undefined && phoneNumber.length === 0) {
          response = await fhirClient.doGetResource(
            `/Patient?_revinclude=Group:member`,
            searchParameters,
            (
              cancelTokenStore.get(
                'patientSearchControlToken'
              ) as CancelTokenSource
            ).token
          )
        } else if (id !== undefined) {
          let idData: string = ''
          if (id) {
            idData = id.split('/')[0]
          }
          const finalData = `${idData}/%2B91${phoneNumber}`
          response = await fhirClient.doGetResource(
            `/Patient?patient-identifier-phone-new=${finalData}&_revinclude=Group:member`,
            searchParameters,
            (
              cancelTokenStore.get(
                'patientSearchControlToken'
              ) as CancelTokenSource
            ).token
          )
        }
      } else if (name.length > 0) {
        response = await fhirClientMaster.doGetResource(
          `/Patient?name:contains=${name}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (id === undefined && phoneNumber.length > 0) {
        response = await fhirClientMaster.doGetResource(
          `/Patient?patient-identifier-phone-new:contains=${phoneNumber}&_revinclude=Group:member`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (id === undefined && phoneNumber.length === 0) {
        response = await fhirClientMaster.doGetResource(
          `/Patient?_revinclude=Group:member`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (id !== undefined) {
        let idData: string = ''
        if (id) {
          idData = id.split('/')[0]
        }
        const finalData = `${idData}/%2B91${phoneNumber}`
        response = await fhirClientMaster.doGetResource(
          `/Patient?patient-identifier-phone-new=${finalData}&_revinclude=Group:member`,
          searchParameters,
          (
            cancelTokenStore.get(
              'patientSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      }

      if (response !== undefined) {
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        const patientResponses: R4.IBundle = response
        if (patientResponses.total) {
          if (patientResponses.total > 0) {
            if (patientResponses.entry) {
              const fhirPatientData: FhirPatientDetail[] =
                getExpandedAppointmentFromBundle(patientResponses)
              const patientArray: R4.IPatient[] = fhirPatientData.map(
                (item) => item.patient as R4.IPatient
              )

              const groupData: GroupPatient[] = []
              for (let i = 0; i < fhirPatientData.length; i++) {
                groupData.push({
                  patient: fhirPatientData[i].patient,
                  isPrimary: fhirPatientData[i].isPrimary,
                })
              }

              groupData.sort((a, b) =>
                (a.patient.name
                  ? a.patient.name[0].given
                    ? a.patient.name[0].given[0]
                    : ''
                  : ''
                )
                  .toLowerCase()
                  .localeCompare(
                    (b.patient.name
                      ? b.patient.name[0].given
                        ? b.patient.name[0].given[0]
                        : ''
                      : ''
                    ).toLowerCase()
                  )
              )

              patientArray.sort((a, b) =>
                (a.name ? (a.name[0].given ? a.name[0].given[0] : '') : '')
                  .toLowerCase()
                  .localeCompare(
                    (b.name
                      ? b.name[0].given
                        ? b.name[0].given[0]
                        : ''
                      : ''
                    ).toLowerCase()
                  )
              )
              fhirPatientData.sort((a, b) =>
                a.patientName
                  .toLowerCase()
                  .localeCompare(b.patientName.toLowerCase())
              )

              const finalFhirPatientData: FhirPatientDetail[] = []
              //   await Promise.all(
              //     fhirPatientData.map(async (e) => {
              //       try {
              //         const data = await getPatientCreatedDate(e)
              //         finalFhirPatientData.push(data)
              //       } catch (error) {
              //         finalFhirPatientData.push(e)
              //       }
              //     })
              //   )

              finalFhirPatientData.sort((a, b) =>
                a.patientName
                  .toLowerCase()
                  .localeCompare(b.patientName.toLowerCase())
              )

              const searchPatientResult: PatientSearchStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                patientListWithAppointment: fhirPatientData,
                patientList: patientArray,
                groupDataList: groupData,
                totalCount: patientResponses.total,
              }
              dispatch(
                patientSearchSlice.actions.searchResults(searchPatientResult)
              )
              return
            }
          }
        }
        const noSearchResults: PatientSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          patientSearchSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return

        // const errorWhileSearchPatient: PatientSearchStatus = {
        //   error: true,
        //   noResultsAvailable: false,
        //   resultsAvailable: false,
        //   searching: false,
        //   errorMessage: 'Error while searching',
        // }
        // dispatch(
        //   patientSearchSlice.actions.errorWhileSearching(
        //     errorWhileSearchPatient
        //   )
        // )
        // return
      }
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: PatientSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        patientSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

export async function getPatientCreatedDate(
  patientData: FhirPatientDetail
): Promise<FhirPatientDetail> {
  const data: FhirPatientDetail = { ...patientData }
  let date: string = ''
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Patient/${patientData.patient.id}/_history/1`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IPatient> =
    R4.RTTI_Patient.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IPatient = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        date = moment(patResponse.meta.lastUpdated).format('DD-MM-YYYY')
      }
    }
  }
  data.patientCreatedDate = date
  return data
}

async function getRelatives(
  patientData: FhirPatientDetail
): Promise<FhirPatientDetail> {
  let patDetails: FhirPatientDetail = patientData
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/RelatedPerson?patient=${patDetails.patient.id}`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          patDetails = getRelativesData(orgResponse, patDetails)
        }
      }
    }
  }
  return patDetails
}

export default patientSearchSlice.reducer
