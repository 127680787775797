import { Button, Typography, Box } from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  DoctorMultiSelectSearch,
  WelloFormItemLabel,
  WelloLoadingIndicator,
} from 'wello-web-components'
import { searchMedicalProviders } from '../../../../redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'

interface Props {
  onProviderSelectionChanged?: (selectedValues: PractitionerWithRole[]) => void
  onPractitionerSelectionChanged?: (
    selectedValues: PractitionerWithRole[]
  ) => void
  doctorListId?: string
  preSelectedPractitionerRoles?: PractitionerWithRole[]
  preSelectAll?: boolean
  showTitle?: boolean
}
export const ProviderSelector: React.FC<Props> = ({
  onProviderSelectionChanged,
  onPractitionerSelectionChanged,
  doctorListId,
  preSelectedPractitionerRoles,
  preSelectAll = false,
  showTitle = true,
}: Props) => {
  const practitionerSearchState = useSelector(
    (state: RootState) => state.medicalProvidersSearchSlice
  )
  const { t, i18n } = useTranslation(['common', 'patient'])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(searchMedicalProviders())
  }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchMedicalProviders())
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <div>
        {practitionerSearchState.searching && (
          <WelloLoadingIndicator message={t('labelCommon:searchingDoc')} />
        )}
        {practitionerSearchState.error && (
          <Typography>
            {' '}
            {practitionerSearchState.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {practitionerSearchState.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
        {practitionerSearchState.resultsAvailable &&
          practitionerSearchState.doctorRoleList && (
            <Box width='100%' display='flex' flexDirection='column' px={1}>
              <WelloFormItemLabel title='Doctors' />
              <Box width='95%'>
                <DoctorMultiSelectSearch
                  color='color'
                  showTitle={false}
                  title={showTitle ? t('labelCommon:doctors') : ''}
                  displayField='name'
                  id={doctorListId}
                  valueField='id'
                  preSelectedOptions={
                    preSelectAll
                      ? practitionerSearchState.doctorRoleList
                      : preSelectedPractitionerRoles || []
                  }
                  listHeight={window.innerHeight / 5}
                  availableOptions={practitionerSearchState.doctorRoleList}
                  onOptionsClicked={() => {
                    //
                  }}
                  onValuesChanged={(changedValues: any[]) => {
                    if (onPractitionerSelectionChanged) {
                      onPractitionerSelectionChanged(changedValues)
                    }
                  }}
                />
              </Box>
            </Box>
          )}
      </div>
    </ErrorBoundary>
  )
}
