import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  IChargeItemDefinition_PropertyGroup,
  IUsageContext,
  QuestionnaireStatusKind,
  Questionnaire_ItemTypeKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  IconButton,
  TextField,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import Info from '@material-ui/icons/Info'
import ImageFilter9 from 'material-ui/svg-icons/image/filter-9'
import { CancellationModel, Settlement } from 'models/convinienceFeeDetails'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { MembershipDetails } from 'models/membershipDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetCancellationSearch,
  searchingCancellationCharge,
} from 'redux/administration/payment-settings/cancellation/cancellationSearchSlice'
import {
  resetSettlementSearch,
  SearchingSettlement,
} from 'redux/administration/payment-settings/settlement/settlementSearchSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  requestDietTiming,
  resetSearchDietSetting,
} from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import {
  requestQuestionForDoctor,
  resetSearchQuestion,
} from 'redux/appointments/questionSearch/questuionSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  isOnlyUnitAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import {
  addCancellation,
  addDietTimings,
  addQuestion,
  addSettlement,
  updateCancellation,
  updateDietTimingsForDoctor,
  updateQuestion,
  updateSettlement,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { ValueSetSelectMultipleWithSwitchForDietSetting } from 'views/components/common/MultipleDietTimeSettings'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'

// import EditTherapy from './editTherapy';

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const Cancellation: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const cancellationSearchSlice = useSelector(
    (state: RootState) => state.cancellationSearchSlice
  )

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const [data, setData] = React.useState<CancellationModel>()

  const [existingQuestionData, setExistingQuestionData] =
    React.useState<R4.IList>()

  function updDateSettlementData(finalRes: R4.IChargeItemDefinition) {
    setUpdateStatus({ requesting: true })

    const oldList: R4.IChargeItemDefinition = {
      ...finalRes,
    }
    setLoading(true)
    // if (data && data.settleMentType) {
    //   oldList.extension = [
    //     {
    //       url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-business-settlement-ext',
    //       valueString: data.settleMentType,
    //     },
    //   ]
    // }

    const finalProperty: IUsageContext[] = []
    if (data && data.lessThan) {
     
      const lessContext = {
        code: {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-usage-context-type-cs',
          code: 'applicability',
          display: 'Less Than',
        },
        valueQuantity: {
          value: Number(data.lessThan / 100),
          unit: '%',
          system: 'http://unitsofmeasure.org',
          code: '%',
        },
      }
      finalProperty.push(lessContext)
    }
    if (data && data.greaterThan) {
     
      const greaterContext = {
        code: {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-usage-context-type-cs',
          code: 'applicability',
          display: 'Greater Than',
        },
        valueQuantity: {
          value: Number(data.greaterThan / 100),
          unit: '%',
          system: 'http://unitsofmeasure.org',
          code: '%',
        },
      }
      finalProperty.push(greaterContext)
    }
    oldList.useContext = finalProperty

    updateCancellation(oldList).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Cancellation details updated successfully'))
        dispatch(resetCancellationSearch())
        dispatch(searchingCancellationCharge())
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Cancellation details. Please try again later'
          )
        )
      }
    })
  }

  function addDietTime() {
    setUpdateStatus({ requesting: true })
    const practRef: R4.IReference = {
      reference: `Practitioner/${getCurrentUserPractitionerDetails().id}`,
    }
    const unitOrg = getCurrentUserUnitDetails()
    const practRole: R4.IPractitionerRole =
      getCurrentUserPractitionerRoleDetails()
    const inviteData: R4.IChargeItemDefinition = {
      resourceType: 'ChargeItemDefinition',
      status: R4.ChargeItemDefinitionStatusKind._active,
      title: 'Cancellation Fee',
      jurisdiction: [
        {
          coding: [
            {
              system: 'urn:iso:std:iso:3166',
              code: 'IN',
              display: 'INDIA',
            },
          ],
        },
      ],
      extension: [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ChargeItemDefinitionManagingOrganization',
          valueReference: {
            id: unitOrg.id,
            reference: `Organization/${unitOrg.id}`,
            type: 'Organization',
            display: unitOrg.name,
          },
        },
      ],
      code: {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-codes-cs',
            code: 'cancellation-fee',
            display: 'Cancellation Fee',
          },
        ],
      },
    }
    const finalProperty: IUsageContext[] = []
    if (data && data.lessThan) {
      const lessContext = {
        code: {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-usage-context-type-cs',
          code: 'applicability',
          display: 'Less Than',
        },
        valueQuantity: {
          value: parseInt((data.lessThan / 100).toFixed(2), 10),
          unit: '%',
          system: 'http://unitsofmeasure.org',
          code: '%',
        },
      }
      finalProperty.push(lessContext)
    }
    if (data && data.greaterThan) {
      const greaterContext = {
        code: {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-usage-context-type-cs',
          code: 'applicability',
          display: 'Greater Than',
        },
        valueQuantity: {
          value: parseInt((data.greaterThan / 100).toFixed(2), 10),
          unit: '%',
          system: 'http://unitsofmeasure.org',
          code: '%',
        },
      }
      finalProperty.push(greaterContext)
    }
    inviteData.useContext = finalProperty

    setLoading(true)

    addCancellation(inviteData).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Cancellation details added successfully'))
        dispatch(resetCancellationSearch())
        dispatch(searchingCancellationCharge())
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while adding Cancellation details. Please try again later'
          )
        )
      }
    })
  }

  function checkSaveButton() {
    const trueData: string[] = []
    const falseData: string[] = []
    const dataNew: string[] = []
    if (data === undefined) {
      dataNew.push('1')
    }
    if (data) {
      if (data.greaterThan === undefined) {
        dataNew.push('1')
      }
      if (data.greaterThan) {
        if (data.greaterThan < 0 || data.greaterThan > 100) {
          dataNew.push('1')
        }
        if (data.lessThan === undefined) {
          dataNew.push('1')
        }
        if (data.lessThan) {
          if (data.lessThan === 0 || data.lessThan > 100) {
            dataNew.push('1')
          }
        }
      }
    }

    // if (settlementSearchSlice.referralList) {
    //   if (data) {
    //     if (
    //       data.settleMentType ===
    //       settlementSearchSlice.referralList.settleMentType
    //     ) {
    //       dataNew.push('1')
    //     }
    //   }
    // }
    if (dataNew.length > 0) {
      return true
    }
    return false
  }

  useEffect(() => {
    dispatch(searchingCancellationCharge())
  }, [dispatch])

  useEffect(() => {
    if (
      cancellationSearchSlice.resultsAvailable &&
      cancellationSearchSlice.referralList
    )
      setData(cancellationSearchSlice.referralList)
  }, [cancellationSearchSlice])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        width: '100%',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            bgcolor={kDialogueBackground}
            py={4}
          >
            <Box
              width='100%'
              height={35}
              px={1}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              style={{ backgroundColor: 'grey' }}
            >
              <Typography
                variant='h6'
                color='primary'
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  fontFamily: 'Open Sans',

                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Cancellation
              </Typography>
            </Box>
          </Box>

          {cancellationSearchSlice.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
            paddingLeft={1.5}
          >
            {cancellationSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height={300}
                style={{ backgroundColor: 'white' }}
                px={1}

                // flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  height={35}
                  justifyContent='flex-end'
                >
                  <WelloFormItemLabel title='Cancellation charges in %' />{' '}
                  <Divider
                    orientation='horizontal'
                    variant='fullWidth'
                    flexItem
                    style={{ width: '100%', height: '2px' }}
                    color='primary'
                  />
                </Box>
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  paddingTop={1.5}
                >
                  <Box width='15%' display='flex' flexDirection='row'>
                    <WelloFormItemLabel title='Less than 24 Hours' />{' '}
                    <Tooltip title='Less than 24 Hours' placement='right-end'>
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        id='manage_ipd'
                        style={{ padding: 0 }}
                      >
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box width='50%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='tel'
                      placeholder=''
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.25,
                        },
                      }}
                      value={data ? data.greaterThan : undefined}
                      onChange={(e) => {
                        const newValue = Number(e.target.value)

                        if (newValue >= 0 && newValue <= 100) {
                          setData({
                            ...data,
                            greaterThan: newValue,
                          })
                        } else if (/^\d+$/.test(e.target.value)) {
                          dispatch(
                            showErrorAlert(`Percentage cannot be more than 100`)
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  paddingTop={2}
                >
                  <Box width='15%' display='flex' flexDirection='row'>
                    <WelloFormItemLabel title='Up to 24 Hours' />{' '}
                    <Tooltip title='Up to 24 Hours' placement='right-end'>
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        id='manage_ipd'
                        style={{ padding: 0 }}
                      >
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box width='50%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='tel'
                      placeholder=''
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.25,
                        },
                      }}
                      value={data ? data.lessThan : undefined}
                      onChange={(e) => {
                        const newValue = Number(e.target.value)

                        if (newValue >= 0 && newValue <= 100) {
                          setData({
                            ...data,
                            lessThan: newValue,
                          })
                        } else if (/^\d+$/.test(e.target.value)) {
                          dispatch(
                            showErrorAlert(`Percentage cannot be more than 100`)
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>

                {data && loading === false && (
                  <Box
                    width='5%'
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    height='100%'
                    paddingBottom={4}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={checkSaveButton()}
                      onClick={() => {
                        addDietTime()
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {cancellationSearchSlice.errorMessage && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching cancellation settings.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {cancellationSearchSlice.referralList && data && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height={330}
                style={{ backgroundColor: 'white' }}
                px={1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  height={35}
                  justifyContent='flex-end'
                >
                  <Box width='100%' display='flex' flexDirection='row'>
                    <Box width='15%' />
                    <WelloFormItemLabel title='Cancellation charges in %' />{' '}
                  </Box>
                  <Divider
                    orientation='horizontal'
                    variant='fullWidth'
                    flexItem
                    style={{ width: '100%', height: '2px' }}
                    color='primary'
                  />
                </Box>
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  paddingTop={1.5}
                >
                  <Box width='15%' display='flex' flexDirection='row'>
                    <WelloFormItemLabel title='Less than 24 Hours' />{' '}
                    <Tooltip title='Less than 24 Hours' placement='right-end'>
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        id='manage_ipd'
                        style={{ padding: 0 }}
                      >
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box width='50%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='tel'
                      placeholder=''
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.25,
                        },
                      }}
                      value={data ? data.greaterThan : undefined}
                      onChange={(e) => {
                        const newValue = Number(e.target.value)

                        if (newValue >= 0 && newValue <= 100) {
                          setData({
                            ...data,
                            greaterThan: newValue,
                          })
                        } else if (/^\d+$/.test(e.target.value)) {
                          dispatch(
                            showErrorAlert(`Percentage cannot be more than 100`)
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  paddingTop={2}
                >
                  <Box width='15%' display='flex' flexDirection='row'>
                    <WelloFormItemLabel title='Up to 24 Hours' />{' '}
                    <Tooltip title='Up to 24 Hours' placement='right-end'>
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        id='manage_ipd'
                        style={{ padding: 0 }}
                      >
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box width='50%'>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='tel'
                      placeholder=''
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.25,
                        },
                      }}
                      value={data ? data.lessThan : undefined}
                      onChange={(e) => {
                        const newValue = Number(e.target.value)

                        if (newValue >= 0 && newValue <= 100) {
                          setData({
                            ...data,
                            lessThan: newValue,
                          })
                        } else if (/^\d+$/.test(e.target.value)) {
                          dispatch(
                            showErrorAlert(`Percentage cannot be more than 100`)
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  width='20%'
                  paddingLeft={0.5}
                  display='flex'
                  flexDirection='row'
                  paddingTop={15}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    // disabled={checkSaveButton()}
                    onClick={() => {
                      if (data && data.chargeItem) {
                        updDateSettlementData(data.chargeItem)
                      }
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      //   dispatch(resetSettlementSearch())
                      //   dispatch(SearchingSettlement())
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
