import { List, ListSubheader, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getDateWiseAppointments } from 'utils/fhirResoureHelpers/appointmentHelpers'
import InfiniteScroll from 'react-infinite-scroll-component'
import { RootState } from 'redux/rootReducer'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { AppointmentListDetailTile } from './appointmentListDetailTile'

interface Props {
  presSelectedAppointmentId?: string
  doctorAppointments?: FhirAppointmentDetail[]
  onAppointmentSelected?: (selectedAppointMent: FhirAppointmentDetail) => void
  onAppointmentSelectedToStart?: (
    selectedAppointMent: FhirAppointmentDetail
  ) => void
  dense?: boolean
  onDownloadClicked: (appointment: FhirAppointmentDetail) => void
  onReportViewClicked: (appointment: FhirAppointmentDetail) => void
  onPendingAppointment: (appointment: FhirAppointmentDetail) => void
  onCancelClicked: (appointment: FhirAppointmentDetail) => void
  onRescheduleClicked: (appointment: FhirAppointmentDetail) => void
  dataLength?: number
  pageState?: string
  onLoadMore?: (total: number, pageStateData?: string) => void
}

export const AppointmentGridView: React.FC<Props> = ({
  presSelectedAppointmentId,
  doctorAppointments,
  onDownloadClicked,
  onReportViewClicked,
  onPendingAppointment,
  onAppointmentSelected,
  onAppointmentSelectedToStart,
  dense = false,
  onCancelClicked,
  onRescheduleClicked,
  dataLength,
  pageState,
  onLoadMore,
}: Props) => {
  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.appointmentSearchSlice
  )

  return (
    <Box
      display='flex'
      flexDirection='row'
      width='100%'
      height='100%'
      overflow='auto'
    >
      <Box display='flex' overflow='auto' height='100%' flexGrow={2}>
        <List
          id='scrollableDiv'
          subheader={<li style={{ padding: 0, width: '100%' }} />}
          style={{
            padding: 0,
            width: '100%',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <InfiniteScroll
            dataLength={dataLength ?? 0}
            next={() => {
              if (pageState !== undefined) {
                if (onLoadMore) {
                  onLoadMore(dataLength ?? 0, pageState)
                }
              }
            }}
            hasMore={pageState !== undefined}
            loader=''
            endMessage={
              appointmentSearchSlice.searchingAppointments ? (
                <p />
              ) : (
                <p style={{ textAlign: 'center' }} />
              )
            }
            scrollableTarget='scrollableDiv'
            scrollThreshold='300px'
          >
            <List
              subheader={<li style={{ padding: 0, width: '100%' }} />}
              style={{
                padding: 0,
                width: '100%',
                overflow: 'auto',
                height: '100%',
              }}
            >
              {dense}
              {getDateWiseAppointments(doctorAppointments ?? []).map((e) => (
                <li
                  key={`section-${e.date ?? ''}`}
                  style={{ padding: 0, width: '100%' }}
                >
                  <ul style={{ padding: 0 }}>
                    <ListSubheader
                      style={{
                        color: '#333333',
                        fontWeight: 600,
                        paddingLeft: 12,
                        width: '100%',
                      }}
                    >
                      {getCompleteDateStringWithOutDay(e.date ?? '')}
                      {dense}
                    </ListSubheader>
                    {e.appointments?.map((item, index) => (
                      <AppointmentListDetailTile
                        appointmentDetails={item}
                        isOdd={index % 2 === 0}
                        isSelected={
                          presSelectedAppointmentId !== undefined &&
                          item.appointment.id === presSelectedAppointmentId
                        }
                        dense={dense}
                        onTap={() => {
                          if (onAppointmentSelected) {
                            onAppointmentSelected(item)
                          }
                        }}
                        onView={() => {
                          if (onAppointmentSelectedToStart) {
                            onAppointmentSelectedToStart(item)
                          }
                        }}
                        onViewReport={(
                          appointmentDetails: FhirAppointmentDetail
                        ) => {
                          if (onReportViewClicked) {
                            onReportViewClicked(appointmentDetails)
                          }
                        }}
                        onPendingAppointment={(
                          appointmentDetails: FhirAppointmentDetail
                        ) => {
                          if (onPendingAppointment) {
                            onPendingAppointment(appointmentDetails)
                          }
                        }}
                        onCancelClicked={() => {
                          onCancelClicked(item)
                        }}
                        onRescheduleClicked={() => {
                          onRescheduleClicked(item)
                        }}
                        onDownloadClicked={() => {
                          onDownloadClicked(item)
                        }}
                        key={`lab_item_key${item.appointment.id}` ?? ''}
                        index={`${index}${moment(e.date).format('DD-MM-YYYY')}`}
                      />
                    ))}
                  </ul>
                </li>
              ))}
            </List>
          </InfiniteScroll>
        </List>
      </Box>
    </Box>
  )
}
