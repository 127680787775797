import { R4 } from '@ahryman40k/ts-fhir-types'
import { Invoice_PriceComponentTypeKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import moment from 'moment'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getOnTheFlyWalkInSlotForDoctor } from 'utils/appointment_handle/appointmentCreationHelper'
import {
  getExtensionValueOfNumber,
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getAppointmentListForFollowup,
  getAppointmentSuccessfulMessage,
  getAppointmentTransactionObjectForFollowup,
  getChargeItemForSelectedAppointment,
  getProvenanceForTask,
  getTaskForAppointment,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getDateDiff,
  getSlotAmountForAppointment,
} from 'utils/fhirResoureHelpers/fhirSlotHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getTransactionBodyForOfflinePayment,
  getTransactionBodyResourceAppointment,
  getTransactionBodyResourceAppointmentOnline,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getPatientResourceAfterAdd,
  getPatientResourceFromQuick,
} from 'utils/formHelper'
import { logger } from 'utils/logger'
import * as Edata from 'dinero.js'

import { Currency } from 'dinero.js'

import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'
import {
  ACTIONS,
  FollowupAppointmentManagementStatus,
} from './followupAppointmentManagerStatus'

const initialState: FollowupAppointmentManagementStatus = {
  currentAction: ACTIONS.SlotSelection,
  enableContinueButton: false,
  creatingAppointment: false,
  errorWhileCreatingAppointment: false,
  appointmentCreatedSuccessfully: false,
  isWalkIn: false,
  isQuickEntry: false,
  decidingAction: false,
}

const followupAppointmentManagerSlice = createSlice({
  name: 'followupAppointmentManager',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<FollowupAppointmentManagementStatus>
    ) {
      state.currentAction = action.payload.currentAction
      state.enableContinueButton = action.payload.enableContinueButton
      state.isWalkIn = action.payload.isWalkIn
      state.isQuickEntry = action.payload.isQuickEntry
      state.selectedPatient = action.payload.selectedPatient
      state.selectedSlot = action.payload.selectedSlot
      state.errorReason = action.payload.errorReason
      state.errorWhileCreatingAppointment =
        action.payload.errorWhileCreatingAppointment
      state.creatingAppointment = action.payload.creatingAppointment
      state.createdAppointment = action.payload.createdAppointment
      state.appointmentCreatedSuccessfully =
        action.payload.appointmentCreatedSuccessfully
      state.decidingAction = action.payload.decidingAction
      state.question = action.payload.question
      state.followupAllowed = action.payload.followupAllowed
      state.amount = action.payload.amount
      state.percentage = action.payload.percentage
      state.paymentType = action.payload.paymentType
      state.followupType = action.payload.followupType
    },
  },
})

export const onSlotSelected =
  (
    activeState: FollowupAppointmentManagementStatus,
    selectedSlot: FhirSlotDetail,
    doctorId: string,
    preselectedPatient?: R4.IPatient,
    questionData?: R4.IQuestionnaire,
    preDefinedAllowed?: boolean,
    fhirAppointmentDetail?: FhirAppointmentDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.SlotSelection,
      enableContinueButton: true,
      selectedSlot,
      selectedPatient: preselectedPatient ?? activeState.selectedPatient,
      creatingAppointment: false,
      followupType: activeState.followupType ?? 'paid',
      amount:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'amount'
          ? activeState.amount
          : undefined,
      percentage:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'percent'
          ? activeState.percentage
          : undefined,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
      question: questionData,
      followupAllowed: activeState.followupAllowed,
    }
    if (fhirAppointmentDetail && selectedSlot.chargeItemDefiniton) {
      const diff =
        getDateDiff(fhirAppointmentDetail.start, selectedSlot.slot.start) <=
        getExtensionValueOfNumber(
          selectedSlot.chargeItemDefiniton.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
        )
      if (diff === true) {
        state.followupType = 'free'
      } else {
        state.followupType = 'paid'
      }
    } else {
      state.followupType = 'paid'
    }
    if (preselectedPatient) {
      state.currentAction = ACTIONS.PatientSelection
      if (fhirAppointmentDetail) {
        state.followupAllowed = fhirAppointmentDetail.appointment
      }
    }
    if (
      preselectedPatient &&
      doctorId &&
      doctorId.length > 0 &&
      fhirAppointmentDetail === undefined
    ) {
      const appData = await getAppointmentListForFollowup(
        doctorId,
        preselectedPatient.id!
      )

      if (appData !== undefined) {
        state.followupAllowed = appData
      }
    } else if (fhirAppointmentDetail) {
      state.followupAllowed = fhirAppointmentDetail.appointment
    }
    console.log(state)

    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onDiscountTypeSelected =
  (
    activeState: FollowupAppointmentManagementStatus,
    discountType: R4.ICoding
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.discountSelection,
      enableContinueButton: false,
      paymentType: discountType,
      amount: undefined,
      followupType: activeState.followupType,
      percentage: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      decidingAction: false,
      selectedSlot: activeState.selectedSlot,
      selectedPatient: activeState.selectedPatient,
      followupAllowed: activeState.followupAllowed,
    }

    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onFollowupTypeChanged =
  (
    activeState: FollowupAppointmentManagementStatus,
    discountType: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.discountSelection,
      enableContinueButton: false,
      followupType: discountType,
      paymentType: undefined,
      amount: undefined,
      percentage: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      decidingAction: false,
      selectedSlot: activeState.selectedSlot,
      selectedPatient: activeState.selectedPatient,
      followupAllowed: activeState.followupAllowed,
    }

    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onDiscountSelected =
  (
    activeState: FollowupAppointmentManagementStatus,
    amountData: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.discountSelection,
      enableContinueButton: false,
      paymentType: activeState.paymentType,
      followupType: activeState.followupType,
      amount:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'amount'
          ? amountData
          : undefined,
      percentage:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'percent'
          ? amountData
          : undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      decidingAction: false,
      selectedSlot: activeState.selectedSlot,
      selectedPatient: activeState.selectedPatient,
      followupAllowed: activeState.followupAllowed,
    }

    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onAmountSelected =
  (
    activeState: FollowupAppointmentManagementStatus,
    amount: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.discountSelection,
      enableContinueButton: true,
      followupType: activeState.followupType,
      amount:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'amount'
          ? amount
          : undefined,
      percentage:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'percent'
          ? amount
          : undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }

    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onPatientSelected =
  (
    activeState: FollowupAppointmentManagementStatus,
    modifiled: boolean,
    selectedPatients?: R4.IPatient,
    doctorId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.PatientSelection,
      enableContinueButton: modifiled === true,
      followupType: activeState.followupType,
      selectedPatient: selectedPatients,
      selectedSlot: activeState.selectedSlot,
      question: activeState.question,

      amount:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'amount'
          ? activeState.amount
          : undefined,
      percentage:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'percent'
          ? activeState.percentage
          : undefined,

      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
      followupAllowed: undefined,
      isQuickEntry: activeState.isQuickEntry,
    }
    if (selectedPatients && doctorId && doctorId.length > 0) {
      const appData = await getAppointmentListForFollowup(
        doctorId,
        selectedPatients.id!
      )

      if (appData !== undefined) {
        state.followupAllowed = appData
      }
    } else {
    }

    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const continueButtonClicked =
  (
    activeState: FollowupAppointmentManagementStatus,
    patient?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === ACTIONS.SlotSelection) {
      const state: FollowupAppointmentManagementStatus = {
        currentAction: ACTIONS.PatientSelection,
        enableContinueButton: false,
        followupType: activeState.followupType,
        amount:
          activeState.paymentType &&
          activeState.paymentType.code &&
          activeState.paymentType.code === 'amount'
            ? activeState.amount
            : undefined,
        percentage:
          activeState.paymentType &&
          activeState.paymentType.code &&
          activeState.paymentType.code === 'percent'
            ? activeState.percentage
            : undefined,
        followupAllowed: activeState.followupAllowed,
        selectedPatient: activeState.selectedPatient || patient,
        selectedSlot: activeState.selectedSlot,
        question: activeState.question,
        creatingAppointment: false,
        errorWhileCreatingAppointment: false,
        appointmentCreatedSuccessfully: false,
        isWalkIn: activeState.isWalkIn,
        isQuickEntry: activeState.isQuickEntry,
      }
      dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
    }
  }
export const disableButtonClicked =
  (activeState: FollowupAppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === ACTIONS.SlotSelection) {
      const state: FollowupAppointmentManagementStatus = {
        currentAction: activeState.currentAction,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        creatingAppointment: activeState.creatingAppointment,
        errorWhileCreatingAppointment:
          activeState.errorWhileCreatingAppointment,
        appointmentCreatedSuccessfully:
          activeState.appointmentCreatedSuccessfully,
        isWalkIn: activeState.isWalkIn,
      }
      dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
    }
  }

export const resetFollowupAppointmentState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.SlotSelection,
      enableContinueButton: false,
      selectedPatient: undefined,
      selectedSlot: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const resetAppointmentStateForModifySlot =
  (
    activeState: FollowupAppointmentManagementStatus,
    preselectedPatient?: R4.IPatient,
    followupAllowed?: R4.IAppointment
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.SlotSelection,
      enableContinueButton: false,
      selectedPatient: preselectedPatient,

      selectedSlot: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
      isWalkIn: activeState.isWalkIn,
      paymentType: activeState.paymentType,

      followupType: activeState.followupType ?? 'paid',
      amount:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'amount'
          ? activeState.amount
          : undefined,
      percentage:
        activeState.paymentType &&
        activeState.paymentType.code &&
        activeState.paymentType.code === 'percent'
          ? activeState.percentage
          : undefined,
      followupAllowed,
    }
    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const requestAppointment =
  (
    activeState: FollowupAppointmentManagementStatus,
    slotDetails: FhirSlotDetail,
    selectedPatient: R4.IPatient,
    selectedServiceType: string,
    isPrePaid: boolean,
    questionData?: R4.IQuestionnaire
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowupAppointmentManagementStatus = {
      currentAction: ACTIONS.discountSelection,
      enableContinueButton: false,
      creatingAppointment: true,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(followupAppointmentManagerSlice.actions.updatedStatus(state))
    console.log(activeState)

    try {
      const currentSlotState: R4.ISlot | undefined = await getSlotCurrentState(
        slotDetails.slot
      )

      if (currentSlotState?.status === R4.SlotStatusKind._free) {
        const bundleObject: R4.IBundle =
          getAppointmentTransactionObjectForFollowup(
            activeState,
            currentSlotState,
            isPrePaid,
            selectedServiceType,
            selectedPatient,
            slotDetails,
            questionData
          )
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
          let appointment: string = ''
          let paymentReconciliation: string = ''
          if (appointmentResponse.entry) {
            for (let i = 0; i < appointmentResponse.entry.length; i++) {
              const entryResponse = appointmentResponse.entry[i].response
              if (entryResponse) {
                if (entryResponse.location) {
                  if (entryResponse.location.includes('Appointment')) {
                    appointment = entryResponse.location.replace(
                      '/_history/1',
                      ''
                    )
                  }
                  if (
                    entryResponse.location.includes('PaymentReconciliation')
                  ) {
                    paymentReconciliation = entryResponse.location.replace(
                      '/_history/1',
                      ''
                    )
                  }
                }
              }
            }
          }

          if (!isPrePaid) {
            const invResp: boolean = await finishTransaction(
              selectedServiceType,
              slotDetails,
              appointment,
              paymentReconciliation,
              'cash',
              'doctor-appointment',
              selectedPatient.id ?? '',
              activeState.paymentType,
              activeState.amount,
              activeState.percentage
            )

            if (invResp) {
              state.appointmentCreatedSuccessfully = true
              state.creatingAppointment = false

              dispatch(
                showSuccessAlert(
                  getAppointmentSuccessfulMessage(
                    selectedPatient,
                    slotDetails.practitioner,
                    currentSlotState
                  )
                )
              )
              dispatch(requestAppointmentsCountForToday())
              dispatch(
                followupAppointmentManagerSlice.actions.updatedStatus(state)
              )
            } else {
              const errorSearchDoctor: FollowupAppointmentManagementStatus = {
                currentAction: ACTIONS.PatientSelection,
                enableContinueButton: false,
                creatingAppointment: false,
                errorWhileCreatingAppointment: true,
                appointmentCreatedSuccessfully: false,
                errorReason: 'Error',
              }
              dispatch(
                followupAppointmentManagerSlice.actions.updatedStatus(
                  errorSearchDoctor
                )
              )
            }
          } else {
            const transactionResp: boolean = await createTransactionLink(
              selectedServiceType,
              slotDetails,
              appointment,
              paymentReconciliation,
              'online',
              'doctor-appointment',
              selectedPatient.id ?? '',
              activeState.paymentType,
              activeState.amount,
              activeState.percentage
            )

            if (transactionResp) {
              state.appointmentCreatedSuccessfully = true
              state.creatingAppointment = false
              const linkResponseForFinal: boolean = await handleNotification(
                appointment,
                ''
              )

              dispatch(
                showSuccessAlert(
                  getAppointmentSuccessfulMessage(
                    selectedPatient,
                    slotDetails.practitioner,
                    currentSlotState
                  )
                )
              )

              dispatch(requestAppointmentsCountForToday())
              dispatch(
                followupAppointmentManagerSlice.actions.updatedStatus(state)
              )
            } else {
              const errorSearchDoctor: FollowupAppointmentManagementStatus = {
                currentAction: ACTIONS.PatientSelection,
                enableContinueButton: false,
                creatingAppointment: false,
                errorWhileCreatingAppointment: true,
                appointmentCreatedSuccessfully: false,
                errorReason: 'Error',
              }
              dispatch(
                followupAppointmentManagerSlice.actions.updatedStatus(
                  errorSearchDoctor
                )
              )
            }
          }
        }
      } else {
        // slot is occupied
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: FollowupAppointmentManagementStatus = {
        currentAction: ACTIONS.PatientSelection,
        enableContinueButton: false,
        creatingAppointment: false,
        errorWhileCreatingAppointment: true,
        appointmentCreatedSuccessfully: false,
        errorReason: 'Error',
      }
      dispatch(
        followupAppointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export async function finishTransaction(
  selectedServiceType: string,
  slotDetails: FhirSlotDetail,
  serviceId: string,
  paymentReconciliationId: string,
  paymentType: string,
  transactionType: string,
  patientId: string,
  discountType?: R4.ICoding,
  amount?: number,
  percent?: number
): Promise<boolean> {
  const requestBody: R4.IParameters = getTransactionBodyForOfflinePayment(
    selectedServiceType,
    slotDetails,
    serviceId,
    paymentReconciliationId,
    paymentType,
    transactionType,
    patientId,
    discountType,
    amount,
    percent
  )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/offline`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 'active') {
      return true
    }
  }

  return false
}

export async function sendAppointmentLink(
  appointmentId: string,
  patientId: string
): Promise<boolean> {
  const requestBody = {
    patientId,
    appointmentId,
  }

  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/invitation/form/pre-appointment`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.includes(process.env.REACT_APP_PRE_APPOINTMENT_URL)) {
      return true
    }
  }

  return false
}

async function handleNotification(
  appointment: string,
  patientId: string
): Promise<boolean> {
  const requestBody = {
    appointmentId: appointment.split('/')[1],
    unitType: 'clinic',
  }

  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/reminder/appointmentReminder`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.includes(process.env.REACT_APP_PRE_APPOINTMENT_URL)) {
      return true
    }
  }

  return false
}

async function createTransactionLink(
  selectedServiceType: string,
  slotDetails: FhirSlotDetail,
  serviceId: string,
  paymentReconciliationId: string,
  paymentType: string,
  transactionType: string,
  patientId: string,
  discountType?: R4.ICoding,
  amount?: number,
  percent?: number
): Promise<boolean> {
  const requestBody: R4.IParameters =
    getTransactionBodyResourceAppointmentOnline(
      selectedServiceType,
      slotDetails,
      serviceId,
      paymentReconciliationId,
      paymentType,
      transactionType,
      patientId,
      discountType,
      amount,
      percent
    )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/initiate`,
      requestBody
    )
    logger.info('Payment Response')

    if (response.status === 'created') {
      return true
    }
  }

  return false
}

async function getSlotCurrentState(
  slot: R4.ISlot
): Promise<R4.ISlot | undefined> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/Slot/${slot.id}`)
  const relatedFhirDecodeRes: E.Either<Errors, R4.ISlot> =
    R4.RTTI_Slot.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const slotResponse: R4.ISlot = relatedFhirDecodeRes.right
    return slotResponse
  }
  return undefined
}

export function getInvoiceDetails(
  activeState: FollowupAppointmentManagementStatus,
  appointmentRef: R4.IReference,
  paymentRef: R4.IReference,
  selectedPatient: R4.IPatient,
  selectedSlotDetails: FhirSlotDetail,
  isPrepaid: boolean
): R4.IInvoice {
  const invoiceData: R4.IInvoice = {
    resourceType: 'Invoice',
    status: isPrepaid
      ? R4.InvoiceStatusKind._issued
      : R4.InvoiceStatusKind._balanced,
    date: moment().toISOString(),
    type: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          display: 'OPD - Outpatients department',
          code: '33022008',
        },
      ],
    },
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-reconciliation-ext',
        valueReference: paymentRef,
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-order-ext',
        valueReference: appointmentRef,
      },
    ],
    subject: {
      id: selectedPatient.id,
      reference: `Patient/${selectedPatient.id}`,
    },
    issuer: {
      id: getCurrentUserUnitDetails().id,
      reference: `Organization/${getCurrentUserUnitDetails().id}`,
      type: 'Organization',
      display: getCurrentUserUnitDetails().name,
    },
  }
  if (getCurrentUserPractitionerRoleRef()) {
    invoiceData.participant = [
      {
        actor: getCurrentUserPractitionerRoleRef()!,
      },
    ]
  }
  const lineItem: R4.IInvoice_LineItem = {
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time',
        valueDateTime: moment().toISOString(),
      },
    ],
    sequence: 1,
  }
  const priceComponentArray: R4.IInvoice_PriceComponent[] = []
  const priceData: R4.IInvoice_PriceComponent = {
    type: Invoice_PriceComponentTypeKind._base,
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '1681000175101',
          display: 'Package - unit of product usage',
        },
      ],
    },
    factor: 1,
    amount: {
      value: getSlotAmountForAppointment(
        selectedSlotDetails.chargeItemDefiniton
      ),
      currency: 'INR',
    },
  }
  priceComponentArray.push(priceData)
  if (
    activeState.paymentType &&
    activeState.paymentType.code &&
    activeState.paymentType.code === 'amount'
  ) {
    const discountAmount = activeState.amount
      ? Number(activeState.amount.toFixed(2))
      : 0
    const factorAmount = activeState.amount
      ? Number(
          (
            activeState.amount /
            getSlotAmountForAppointment(selectedSlotDetails.chargeItemDefiniton)
          ).toFixed(2)
        )
      : 0
    const discountAmountData: R4.IInvoice_PriceComponent = {
      type: Invoice_PriceComponentTypeKind._discount,
      code: {
        coding: [
          {
            system: 'http://unitsofmeasure.org',
            code: '[arb’U]',
            display: 'arbitrary unit',
          },
        ],
      },
      factor: factorAmount,
      amount: {
        value: discountAmount,
        currency: 'INR',
      },
    }
    priceComponentArray.push(discountAmountData)
  }
  if (
    activeState.paymentType &&
    activeState.paymentType.code &&
    activeState.paymentType.code === 'percent'
  ) {
    const percentVal = activeState.percentage
      ? Number((activeState.percentage / 100).toFixed(2))
      : 0
    const factorAmount = activeState.percentage
      ? Number(
          (
            (activeState.percentage *
              getSlotAmountForAppointment(
                selectedSlotDetails.chargeItemDefiniton
              )) /
            100
          ).toFixed(2)
        )
      : 0
    const percentData: R4.IInvoice_PriceComponent = {
      type: Invoice_PriceComponentTypeKind._discount,
      code: {
        coding: [
          {
            system: 'http://unitsofmeasure.org',
            code: '%',
            display: 'percent',
          },
        ],
      },
      factor: percentVal,
      amount: {
        value: factorAmount,
        currency: 'INR',
      },
    }
    priceComponentArray.push(percentData)
  }
  lineItem.priceComponent = priceComponentArray
  invoiceData.lineItem = [lineItem]

  return invoiceData
}

export function getPaymentReconciliationResource(
  taskRef: R4.IReference,
  amount: R4.IMoney,
  isPrePaid: boolean
): R4.IPaymentReconciliation {
  const paymentId: string = getUniqueTempId()
  const paymentReconciliation: R4.IPaymentReconciliation = {
    resourceType: 'PaymentReconciliation',
    status: 'active',
    id: paymentId,
    paymentAmount: amount,
    requestor: getCurrentUserPractitionerRoleRef(),
    paymentIssuer: getCurrentUserUnitReference(),
    outcome: R4.PaymentReconciliationOutcomeKind._queued,
  }
  if (isPrePaid) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref',
        valueReference: {
          reference: `${taskRef.reference}`,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'online',
          display: 'Online',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
        valueCoding: {
          code: 'prepaid',
          display: 'Prepaid',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
        },
      },
    ]
    const typeOptions: R4.ICoding[] = [
      {
        code: 'prepaid',
        display: 'Prepaid',
        system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
      },
    ]
    const codeType: R4.ICodeableConcept = {}
    codeType.coding = typeOptions
    paymentReconciliation.detail = [
      {
        type: codeType,
        request: taskRef,
      },
    ]
  } else {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref',
        valueReference: {
          reference: `${taskRef.reference}`,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'Cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
        valueCoding: {
          code: 'postpaid',
          display: 'Postpaid',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
        },
      },
    ]
    const typeOptions1: R4.ICoding[] = isPrePaid
      ? [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
            code: 'online',
            display: 'Online',
          },
        ]
      : [
          {
            code: 'online',
            display: 'Online',
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
          },
        ]
    const codeType1: R4.ICodeableConcept = {}
    codeType1.coding = typeOptions1
    paymentReconciliation.detail = [
      {
        type: codeType1,
        request: taskRef,
      },
    ]
  }

  return paymentReconciliation
}

async function getQuestion(
  selectedDoctors: R4.IPractitionerRole
): Promise<R4.IQuestionnaire | undefined> {
  const fhirApi: FHIRDefaultApiClient = new FHIRDefaultApiClient()
  const response: any = await fhirApi.doGetResource(
    `Questionnaire?questionnaire-practitioner=${selectedDoctors.id!}`
  )
  logger.info('ChargeItemDefinition Fetching')
  logger.info(response)
  const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
  if (response) {
    if (response.entry && response.entry.length > 0) {
      return response.entry[0].resource as R4.IQuestionnaire
    }
  }
  return undefined
}

export default followupAppointmentManagerSlice.reducer
