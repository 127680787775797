import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AssociatedComplaintData } from 'models/chiefComplaintData'
import { EnrolData } from 'models/enrolData'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import {
  getIdentifierValueBySystem,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { conditionIdWiseGroupedObservations } from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { getNameOfPatient } from 'wello-web-components'
import { IPDChiefComplaintsStatus } from './IPDchiefComplaintStatusType'

const initialState: IPDChiefComplaintsStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedConditions: false,
  updatingConditions: false,
  errorWhileSearchingProcedures: false,
  errorWhileUpdatingProcedures: false,
}

let count: number = 0

const IPDchiefComplaintSlice = createSlice({
  name: 'IPDchiefComplaintSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IPDChiefComplaintsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefCondition = action.payload.chiefCondition
      state.associatedCondition = action.payload.associatedCondition
      state.conditionsBasedObservations =
        action.payload.conditionsBasedObservations
      state.observations = action.payload.observations
      state.updatedConditions = action.payload.updatedConditions
      state.updatingConditions = action.payload.updatingConditions
      state.errorWhileUpdatingProcedures =
        action.payload.errorWhileUpdatingProcedures
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
    resetChiefComplaintDetails(
      state,
      action: PayloadAction<IPDChiefComplaintsStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefCondition = action.payload.chiefCondition
      state.associatedCondition = action.payload.associatedCondition
      state.updatedConditions = action.payload.updatedConditions
      state.updatingConditions = action.payload.updatingConditions
      state.errorWhileUpdatingProcedures =
        action.payload.errorWhileUpdatingProcedures
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.conditionsBasedObservations =
        action.payload.conditionsBasedObservations
      state.observations = action.payload.observations
    },
  },
})

export const IPDgetChiefComplaintOfAppointment =
  (
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole,
    split: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    count = 0
    const state: IPDChiefComplaintsStatus = { ...initialState }
    state.searchingConditions = true
    dispatch(IPDchiefComplaintSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        'patient:Patient.identifier': `http://wellopathy.com/fhir/india/core/id/offset-phone|${getIdentifierValueBySystem(
          fhirAppointmentDetails.patient.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/id/offset-phone'
        )!}`,
        category: 'chief-complaint',
        _sort: '_lastUpdated',
        _count: 200,
      }

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .format('DD-MM-YYYY')}`
      const currentDate = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      const convertedEndDate = moment(endDate, 'DD-MM-YYYY')
      const dateForQuery = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .format('YYYY-MM-DD')}`

      const diffInDays = currentDate.diff(convertedEndDate, 'days')
      let diffInMonths = currentDate.diff(convertedEndDate, 'months')
      const diffInYears = currentDate.diff(convertedEndDate, 'years')
      const diffInWeeks = currentDate.diff(convertedEndDate, 'weeks')

      if (diffInMonths === 2) {
        diffInMonths += 1
      }
      let observations = []
      if (isAyurvedaDoctor()) {
        const respObservations = await fhirClient.doGetResourceForAppointment(
          `/Observation?encounter=${fhirAppointmentDetails.mainEncounter?.id}&category=chief-complaint&_sort=-_lastUpdated&status=final,preliminary`,
          fhirAppointmentDetails.mainServiceRequest.id!
        )

        if (respObservations.entry && respObservations.entry.length > 0) {
          observations = respObservations.entry.map(
            (item: any) => item.resource
          )
        }
      }

      let response: any
      if (split) {
        response = await fhirClient.doGetResourceForAppointment(
          `/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp&_filter=onset-age le ${diffInDays} or onset-date gt ${dateForQuery}`,
          '',
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResourceForAppointment(
          '/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp',
          '',
          searchParameters
        )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false

        dispatch(IPDchiefComplaintSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info(
          'Chief Complaint resp',
          conditionResponse.entry?.[0].resource as R4.ICondition
        )
        if (conditionResponse?.total && conditionResponse?.total > 0) {
          const conditionData: R4.ICondition[] = []
          const associatedComplaints: R4.ICondition[] = []
          if (conditionResponse.entry) {
            for (let i = 0; i < conditionResponse.entry.length; i++) {
              const condition: R4.ICondition = conditionResponse.entry[i]
                .resource as R4.ICondition
              const active =
                condition.clinicalStatus && condition.clinicalStatus.coding
                  ? condition.clinicalStatus.coding[0].code === 'active'
                  : false

              const cat: string =
                condition.category && condition.category[0].coding
                  ? condition.category[0].coding[0].code ?? ''
                  : ''
              if (split) {
                if (active) {
                  if (cat !== 'associated-complaint') {
                    if (condition.onsetAge) {
                      const measure: string = condition.onsetAge
                        ? condition.onsetAge.code ?? ''
                        : ''
                      if (measure === 'mo') {
                        const monthVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0

                        if (diffInMonths >= monthVal) {
                          conditionData.push(condition)
                        }
                      } else if (measure === 'a') {
                        const yearVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0
                        if (diffInYears >= yearVal) {
                          conditionData.push(condition)
                        }
                      } else if (measure === 'wk') {
                        const weekVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0
                        if (diffInWeeks >= weekVal) {
                          conditionData.push(condition)
                        }
                      } else {
                        conditionData.push(condition)
                      }
                    } else {
                      conditionData.push(condition)
                    }
                  } else {
                    const ashCondition: R4.ICondition = conditionResponse.entry[
                      i
                    ].resource as R4.ICondition

                    if (ashCondition.onsetAge) {
                      const measure: string = ashCondition.onsetAge
                        ? ashCondition.onsetAge.code ?? ''
                        : ''
                      if (measure === 'mo') {
                        const monthVal = ashCondition.onsetAge
                          ? ashCondition.onsetAge.value ?? 0
                          : 0
                        if (diffInMonths >= monthVal) {
                          associatedComplaints.push(ashCondition)
                        }
                      } else if (measure === 'a') {
                        const yearVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0
                        if (diffInYears >= yearVal) {
                          associatedComplaints.push(ashCondition)
                        }
                      } else if (measure === 'wk') {
                        const weekVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0
                        if (diffInWeeks >= weekVal) {
                          associatedComplaints.push(ashCondition)
                        }
                      } else {
                        associatedComplaints.push(ashCondition)
                      }
                    } else {
                      associatedComplaints.push(ashCondition)
                    }

                    // associatedComplaints.push(
                    //   conditionResponse.entry[i].resource as R4.ICondition
                    // )
                  }
                }
              } else if (active) {
                if (cat !== 'associated-complaint') {
                  conditionData.push(
                    conditionResponse.entry[i].resource as R4.ICondition
                  )
                } else {
                  associatedComplaints.push(
                    conditionResponse.entry[i].resource as R4.ICondition
                  )
                }
              }
            }
          }
          if (conditionData.length === 0) {
            localStorage.setItem('count', conditionData.length.toString())
            const errorSearchDoctor: IPDChiefComplaintsStatus = {
              ...initialState,
            }
            errorSearchDoctor.noResultsAvailable = true
            dispatch(
              IPDchiefComplaintSlice.actions.updatedStatus(errorSearchDoctor)
            )
          } else {
            localStorage.setItem('count', conditionData.length.toString())
            state.resultsAvailable = true
            state.searchingConditions = false
            state.chiefCondition = conditionData.sort((a, b) =>
              (a.meta?.lastUpdated ? a.meta?.lastUpdated : '') >
              (b.meta?.lastUpdated ? b.meta?.lastUpdated : '')
                ? -1
                : (a.meta?.lastUpdated ?? '') < (b.meta?.lastUpdated ?? '')
                ? 1
                : 0
            )
            state.associatedCondition = associatedComplaints.sort((a, b) =>
              (a.id ? a.id : '') < (b.id ? b.id : '')
                ? -1
                : (a.id ?? '') > (b.id ?? '')
                ? 1
                : 0
            )
            state.observations = observations
            state.conditionsBasedObservations =
              conditionIdWiseGroupedObservations(observations)
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingProcedures = false
            dispatch(IPDchiefComplaintSlice.actions.updatedStatus(state))
          }
        } else {
          localStorage.setItem('count', '0')
          const errorSearchDoctor: IPDChiefComplaintsStatus = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            IPDchiefComplaintSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IPDChiefComplaintsStatus = { ...initialState }
      errorSearchDoctor.errorReason =
        'Error while fetching chief complaint details'
      errorSearchDoctor.errorWhileSearchingProcedures = false
      dispatch(IPDchiefComplaintSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const IPDupdateChiefComplaintDetails =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    complaintCode: R4.ICodeableConcept,
    typeData: string,
    days: number,
    weeks: number,
    month: number,
    year: number,
    operation: string,
    id: string,
    other: boolean,
    notes: string,
    location?: R4.ICoding[],
    onsetDate?: Date,
    severity?: R4.ICoding,
    duration?: string,
    character?: string,
    alleviatingFactor?: string,
    radiation?: string,
    temporal?: string,
    otherData?: string,
    patientWording?: string,
    checked?: boolean,
    associatedComplaints?: AssociatedComplaintData[],
    selectedPainProfileSymptoms?: R4.ICodeableConcept[],
    associatedChecked?: boolean,
    painDurationType?: string,
    painDuration?: number,
    ayurvedaObservations?: R4.IObservation[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let state: IPDChiefComplaintsStatus = { ...initialState }
    state.updatingConditions = true
    dispatch(IPDchiefComplaintSlice.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle = createBundleObjectForCHiefComplaint(
        appointment,
        complaintCode,
        typeData,
        days,
        weeks,
        month,
        year,
        operation,

        id,
        other,
        notes,
        location,
        onsetDate,
        severity,
        duration,
        character,
        alleviatingFactor,
        radiation,
        temporal,
        otherData,
        patientWording,
        checked,
        associatedComplaints,
        selectedPainProfileSymptoms,
        associatedChecked,
        painDurationType,
        painDuration,
        ayurvedaObservations
      )

      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const conditionResponse: R4.IBundle = relatedFhirDecodeRes.right
        const conditionData: R4.ICondition[] = []
        if (operation === 'add') {
          state = { ...initialState }
          state.updatingConditions = false
          state.updatedConditions = true
          state.chiefCondition = conditionData

          dispatch(IPDgetChiefComplaintOfAppointment(appointment, false))
          dispatch(
            showSuccessAlert(
              operation === 'add'
                ? 'Chief Complaints added successfully'
                : 'Chief Complaints updted successfully'
            )
          )
          dispatch(IPDchiefComplaintSlice.actions.updatedStatus(state))

          return
        }
        state = { ...initialState }
        state.updatingConditions = false
        state.updatedConditions = true
        state.chiefCondition = conditionData

        dispatch(IPDchiefComplaintSlice.actions.updatedStatus(state))
        if (operation === 'add' || operation === 'update')
          dispatch(
            showSuccessAlert(
              operation === 'add'
                ? 'Chief Complaints added successfully'
                : 'Chief Complaints updted successfully'
            )
          )
        else dispatch(showSuccessAlert('Chief Complaints deleted successfully'))

        return
      }

      const errorSearchDoctor: IPDChiefComplaintsStatus = { ...initialState }
      errorSearchDoctor.errorWhileUpdatingProcedures = true
      errorSearchDoctor.errorReason = 'error while updating . try later'
      dispatch(IPDchiefComplaintSlice.actions.updatedStatus(errorSearchDoctor))
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IPDChiefComplaintsStatus = { ...initialState }
      errorSearchDoctor.errorReason =
        'Error while adding/updating chief complaint details'
      errorSearchDoctor.errorWhileUpdatingProcedures = false
      dispatch(IPDchiefComplaintSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const resetChiefComplaintDetails = () => (dispatch: AppDispatch) => {
  dispatch(
    IPDchiefComplaintSlice.actions.resetChiefComplaintDetails(initialState)
  )
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }

    return serviceRequestId
  }

  return serviceRequestId
}

async function addEnroment(enrolData: EnrolData): Promise<string> {
  logger.info('Enrolment Taken')

  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/pre-appt/patient`,
      enrolData
    )

    return response.success
  } catch (error) {
    return 'false'
  }
}

function createBundleObjectForCHiefComplaint(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  complaintCode: R4.ICodeableConcept,
  typeData: string,
  days: number,
  weeks: number,
  months: number,
  years: number,
  operation: string,
  id: string,
  other: boolean,
  notes: string,
  location?: R4.ICoding[],
  onsetDate?: Date,
  severity?: R4.ICoding,
  duration?: string,
  character?: string,
  alleviatingFactor?: string,
  radiation?: string,
  temporal?: string,
  otherData?: string,
  patientWording?: string,
  checked?: boolean,
  associatedComplaints?: AssociatedComplaintData[],
  selectedPainProfileSymptoms?: R4.ICodeableConcept[],
  associatedChecked?: boolean,
  painDurationType?: string,
  painDuration?: number,
  observations?: R4.IObservation[]
): R4.IBundle {
  let codeData: R4.ICoding = complaintCode

  console.log(appointment)

  if (other) {
    codeData = {
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-medical-condition-cs',
      code: 'other-complaint',
      display: otherData ?? '',
    }
  }
  const chiefComplaintExtns: R4.IExtension[] = []
  if (checked && checked === true) {
    if (alleviatingFactor) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor',
        valueString: alleviatingFactor,
      })
    }
    if (character) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character',
        valueString: character,
      })
    }
    if (radiation) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation',
        valueString: radiation,
      })
    }
    if (temporal) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal',
        valueString: temporal,
      })
    }
    if (painDurationType) {
      if (painDuration) {
        chiefComplaintExtns.push({
          url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration',
          valueString: `${painDuration} ${painDurationType}`,
        })
      } else {
        chiefComplaintExtns.push({
          url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration',
          valueString: `${painDurationType}`,
        })
      }
    }
  }

  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const condtionData: R4.ICondition = {
    resourceType: 'Condition',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionChiefComplaint',
      ],
    },
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },

    code: complaintCode,

    clinicalStatus: {
      coding: [
        {
          code: 'active',
          system: 'http://terminology.hl7.org/ValueSet/condition-clinical',
          display: 'Active',
        },
      ],
    },
    bodySite: location
      ? location.map((val) => {
          const site: R4.ICodeableConcept = {
            text: val.display,
            coding: [val],
          }
          return site
        })
      : [],
    recorder: {
      display: getNameFromHumanName(pract.name ?? []),
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },

    severity: severity
      ? {
          coding: [severity],
        }
      : {},
    category: [
      {
        coding: [
          {
            code: 'chief-complaint',
            display: 'Chief Complaint',
            system: 'http://wellopathy.com/condition-category',
          },
        ],
      },
    ],

    extension: chiefComplaintExtns,
    verificationStatus: {
      text: 'Unconfirmed',
      coding: [
        {
          code: 'unconfirmed',
          display: 'Unconfirmed',
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
        },
      ],
    },
  }

  if (appointment.mainEncounter) {
    condtionData.encounter = {
      reference: `${appointment.mainEncounter.resourceType}/${appointment.mainEncounter.id}`,
      type: appointment.mainEncounter.resourceType,
    }
  }
  //   if (onsetDate) {
  //     condtionData.onsetDateTime = onsetDate.toISOString()
  //   }

  if (checked && checked === true) {
    if (selectedPainProfileSymptoms && selectedPainProfileSymptoms.length > 0) {
      const evidenceCodeArry: R4.ICondition_Evidence[] = []

      const evidenceCode: R4.ICondition_Evidence = {
        code: selectedPainProfileSymptoms,
      }
      evidenceCodeArry.push(evidenceCode)
      if (evidenceCodeArry.length > 0) {
        condtionData.evidence = evidenceCodeArry
      }
    }
  }

  if (typeData.length > 0) {
    if (typeData === 'days') {
      if (days > 0) {
        condtionData.onsetAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'd',
          code: 'd',
          value: days,
        }
      }
    } else if (typeData === 'months') {
      condtionData.onsetAge = {
        system: 'http://unitsofmeasure.org',
        unit: 'mo',
        code: 'mo',
        value: months,
      }
    } else if (typeData === 'weeks') {
      condtionData.onsetAge = {
        system: 'http://unitsofmeasure.org',
        unit: 'wk',
        code: 'wk',
        value: weeks,
      }
    } else if (typeData === 'years') {
      condtionData.onsetAge = {
        system: 'http://unitsofmeasure.org',
        unit: 'a',
        code: 'a',
        value: years,
      }
    } else if (typeData === 'custom') {
      if (onsetDate) condtionData.onsetDateTime = onsetDate.toISOString()
    }
  }

  const noteData: R4.IAnnotation[] = []
  if (notes) {
    if (notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: notes,
        time: new Date().toISOString(),
      })
    }
  }
  if (patientWording) {
    if (patientWording.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        },
        text: patientWording,
        time: new Date().toISOString(),
      })
    }
  }
  if (noteData.length > 0) {
    condtionData.note = noteData
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232325',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: condtionData.resourceType,
        },
        resource: condtionData,
      },
    ],
  }

  if (associatedComplaints && associatedComplaints.length) {
    for (let i = 0; i < associatedComplaints.length; i++) {
      if (
        associatedComplaints[i].codeableData &&
        associatedComplaints[i].checked &&
        associatedComplaints[i].patientWording.length > 0
      ) {
        const additionalComplaints: R4.ICondition = {
          resourceType: 'Condition',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionChiefComplaint',
            ],
          },
          subject: {
            display: getNameOfPatient(appointment.patient),
            id: appointment.patient.id,
            reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            type: appointment.patient.resourceType,
          },
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext',
              valueReference: {
                reference: `${condtionData.resourceType}/${
                  id.length === 0 ? 'urn:uuid:1232323232325' : id
                }`,
                type: condtionData.resourceType,
              },
            },
          ],

          code: associatedComplaints[i].codeableData,

          clinicalStatus: {
            coding: [
              {
                code:
                  operation === 'delete' ||
                  associatedComplaints[i].checked === false
                    ? 'inactive'
                    : 'active',
                system:
                  'http://terminology.hl7.org/ValueSet/condition-clinical',
                display:
                  operation === 'delete' ||
                  associatedComplaints[i].checked === false
                    ? 'inActive'
                    : 'Active',
              },
            ],
          },

          recorder: {
            display: getNameFromHumanName(pract.name ?? []),
            id: practRole.id,
            reference: `${practRole.resourceType}/${practRole.id}`,
            type: practRole.resourceType,
          },
          category: [
            {
              coding: [
                {
                  code: 'associated-complaint',
                  display: 'Associated Complaint',
                  system:
                    'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-condition-category-cs',
                },
              ],
            },
          ],
          verificationStatus: {
            text: 'Unconfirmed',
            coding: [
              {
                code: 'unconfirmed',
                display: 'Unconfirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
        }

        const noteDataforAssociated: R4.IAnnotation[] = []

        if (associatedComplaints[i].patientWording.length > 0) {
          noteDataforAssociated.push({
            authorReference: {
              reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            },
            text: associatedComplaints[i].patientWording,
            time: new Date().toISOString(),
          })
        }

        if (noteDataforAssociated.length > 0) {
          additionalComplaints.note = noteDataforAssociated
        }

        if (associatedComplaints[i].typeData.length > 0) {
          if (associatedComplaints[i].typeData === 'days') {
            if (associatedComplaints[i].days > 0) {
              additionalComplaints.onsetAge = {
                system: 'http://unitsofmeasure.org',
                unit: 'd',
                code: 'd',
                value: associatedComplaints[i].days,
              }
            }
          } else if (associatedComplaints[i].typeData === 'months') {
            additionalComplaints.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'mo',
              code: 'mo',
              value: associatedComplaints[i].months,
            }
          } else if (associatedComplaints[i].typeData === 'weeks') {
            additionalComplaints.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'wk',
              code: 'wk',
              value: associatedComplaints[i].weeks,
            }
          } else if (associatedComplaints[i].typeData === 'years') {
            additionalComplaints.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'a',
              code: 'a',
              value: associatedComplaints[i].year,
            }
          } else if (associatedComplaints[i].typeData === 'custom') {
            additionalComplaints.onsetDateTime =
              associatedComplaints[i].date.toISOString()
          }
        }

        if (appointment.mainEncounter) {
          additionalComplaints.encounter = {
            reference: `${appointment.mainEncounter.resourceType}/${appointment.mainEncounter.id}`,
            type: appointment.mainEncounter.resourceType,
          }
        }

        const entry: R4.IBundle_Entry = {
          fullUrl: `Condition/`,

          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: additionalComplaints.resourceType,
          },

          resource: additionalComplaints,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }
  if (observations && observations.length > 0) {
    observations.forEach((obs) => {
      const obsEntry: R4.IBundle_Entry = {
        fullUrl: `${obs.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: obs.resourceType,
        },
        resource: {
          ...obs,
          status:
            operation === 'delete'
              ? ObservationStatusKind._enteredInError
              : obs.status,
          extension: [
            ...(obs.extension ?? []),

            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/base-cheif-complaint-reference',
              valueReference: {
                reference: `${condtionData.resourceType}/${
                  id.length === 0 ? 'urn:uuid:1232323232325' : id
                }`,
                type: condtionData.resourceType,
              },
            },
          ],
        },
      }

      requestBundle.entry?.push(obsEntry)
    })
  }

  return requestBundle
}

export default IPDchiefComplaintSlice.reducer
