import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import {
  FhirActiveIPDDetailsForMedicalRole,
  FhirIPDwithPatientDetails,
} from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  getOrderStatusText,
  isAfterVital,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { TherapistSearch } from './therapistSearch'

interface Props {
  open: boolean
  onClose: () => void
  fhirAppointmentDetails: FhirIPDwithPatientDetails
  treatmentPlanData: TreatmentPlanData
  onSubmitClicked: (
    startTime?: string,
    endTime?: string,
    selectedPractWithRole?: PractitionerWithRole[]
  ) => void
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    right: 150,
    top: 100,
    width: '25%',
  },
})
export const TherapyPerformCapture: React.FC<Props> = ({
  open,
  onClose,
  fhirAppointmentDetails,
  treatmentPlanData,
  onSubmitClicked,
}) => {
  const addProcedureDetailsSlice = useSelector(
    (state: RootState) => state.addProcedureDetailsSlice
  )
  const classes = useStyles()
  const cancelTherapyDetailsSlice = useSelector(
    (state: RootState) => state.cancelTreatmentSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedPractWithRole, setSelecctedPractWithRole] =
    useState<PractitionerWithRole[]>()
  const [startTime, setStartedTime] = useState<string>()
  const currentDate = moment(treatmentPlanData.date).format('YYYY-MM-DD')
  const timeAndDate = moment(
    `${currentDate}  ${moment(treatmentPlanData.startTime, ['hh:mm A']).format(
      'HH:mm'
    )}`
  ).format('YYYY-MM-DD HH:mm')

  const endTimeDate = moment(
    `${currentDate} ${
      isAfterVital(
        moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
      )
        ? moment().format('HH:mm')
        : moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
    }`
  ).format('YYYY-MM-DD HH:mm')

  const [showTextForSlot, setShowTextForSlot] = useState<boolean>(false)

  const time1 = moment(timeAndDate).format('YYYY-MM-DD HH:mm:ss')

  const status = moment(time1).subtract(1, 'hour')

  const [endTime, setEndTime] = useState<string>()

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Complete Therapy</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column'>
            {/* <Box display='flex' flexDirection='Row'>
              <Box display='flex' flexDirection='column' width='50%'>
                <Card elevation={0}>
                  <CardContent>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      height='80px'
                    >
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Typography
                          variant='h6'
                          color='initial'
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 12,
                          }}
                        >
                          Patient
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                        >
                          <Avatar
                            alt={
                              getNameOfPatient(
                                fhirAppointmentDetails.patient
                              ) ?? ''
                            }
                            src={
                              getProfilePicPatient(
                                fhirAppointmentDetails.patient
                              ) ?? ''
                            }
                          />
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='start'
                          px={0.5}
                        >
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            style={{ textTransform: 'capitalize' }}
                          >
                            {getNameOfPatient(fhirAppointmentDetails.patient)}{' '}
                            {getMiddleNameOfPatient(
                              fhirAppointmentDetails.patient
                            )}{' '}
                            {getLastNameOfPatient(
                              fhirAppointmentDetails.patient
                            )}
                          </Typography>

                          <Box mx={1} py={0.5}>
                            {fhirAppointmentDetails.patient?.gender ===
                            R4.PatientGenderKind._male ? (
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='primary'
                              >
                                {' '}
                                M /
                              </Typography>
                            ) : (
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='primary'
                              >
                                {' '}
                                F /
                              </Typography>
                            )}
                            {fhirAppointmentDetails.patient && (
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='primary'
                              >
                                {` ${getAgeOfPatientData(
                                  fhirAppointmentDetails.patient
                                )}`}
                              </Typography>
                            )}
                          </Box>

                          <Typography variant='subtitle2' color='initial'>
                            {getIdentifierValueBySystem(
                              fhirAppointmentDetails.mainServiceRequest
                                .identifier ?? [],
                              'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                            ) ??
                              getIdentifierValueBySystem(
                                fhirAppointmentDetails.mainServiceRequest
                                  .identifier ?? [],
                                'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
             {treatmentPlanData && (
                <Box display='flex' flexDirection='column' width='50%'>
                  <TherapyDataDisplay
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    treatmentPlanData={treatmentPlanData}
                  />
                </Box>
              )}
              
            </Box> */}

            {treatmentPlanData && treatmentPlanData.treatmentPlan && (
              <Box display='flex' flexDirection='column' width='100%'>
                {getOrderStatusText(treatmentPlanData.treatmentPlan) ===
                  'Scheduled' && (
                  <Box>
                    {treatmentPlanData && !treatmentPlanData.procedure && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        py={1}
                      >
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Typography
                            variant='h6'
                            color='initial'
                            style={{
                              textTransform: 'uppercase',
                              fontSize: 12,
                            }}
                          >
                            Select Performers
                          </Typography>
                        </Box>
                        <TherapistSearch
                          onPractionerSelected={(
                            practionerList: PractitionerWithRole[]
                          ) => {
                            setSelecctedPractWithRole(practionerList)
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addProcedureDetailsSlice.adding && <CircularProgress size={30} />}
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          style={{ color: ' #979797;' }}
          disableElevation
          id='close_perform'
        >
          Close
        </Button>
        <Button
          onClick={() => {
            onSubmitClicked(startTime, endTime, selectedPractWithRole)
          }}
          variant='contained'
          color='primary'
          disableElevation
          disabled={
            treatmentPlanData
              ? selectedPractWithRole === undefined ||
                selectedPractWithRole.length === 0
              : true
          }
          id='save_perform'
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
