import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationDefinitionPermittedDataTypeKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { GoalFormModel } from 'models/goalFormModel'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showWarningAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import {
  addNewGoalDetails,
  resetAddAllergiesState,
} from 'redux/subscription/goalOfSusbcription/goalsOfSubscriptionsSlice'
import {
  getGoalDataUpdateDetailsFromGoalModel,
  getGoalTargetDetailsFromGoalModel,
  getObsDefinitionIntervalNumerical,
  getObsDefinitionIntervals,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import {
  getDefaultCodingOfSystemFromCodableConceptList,
  getDefaultDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { ObservationDefinitionSelectSingle } from 'views/components/common/SingleSelectFromObservationDefinition'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import OverflowTypography from '../../ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface Props {
  open: boolean
  patient: R4.IPatient
  onClose: () => void
  onAllergiesDetailsAdded: () => void
  encounterId?: string
  carePlanId?: string
}
export const AddNewGoalHandler: React.FC<Props> = ({
  open,
  onClose,
  onAllergiesDetailsAdded,
  patient,
  encounterId,
  carePlanId,
}) => {
  const classes = useStyles()

  const [currentGoal, setGoal] = useState<GoalFormModel>({
    id: _.random(1, 10000000).toString(),
    goalObservationCoding: undefined,
    goalTargetUnitCoding: undefined,
    goalTargetValue: undefined,
    goalTargetValueLow: undefined,
    goalTargetValueHigh: undefined,
    goalTargetValueText: undefined,
    goalTargetDurationValue: undefined,
    goalTargetDurationUnit: undefined,
    goalTargetDate: undefined,
    updateFrequencyNumber: 1,
    updateFrequencyUnit: 'month',
    inputDoneBy: 'practitioner',
  })
  const [addedGoals, setAddedGoals] = useState<GoalFormModel[]>([])

  const durationTypes = [
    { value: 'day', label: 'Days' },
    { value: 'week', label: 'Weeks' },
    { value: 'month', label: 'Months' },
  ]

  const dataInputBy = [
    { value: 'patient', label: 'Patient' },
    { value: 'practitioner', label: 'Practitioner' },
    { value: 'any', label: 'Any One' },
  ]

  function isCurrentDetailsValid(): boolean {
    if (currentGoal.goalObservationCoding === undefined) return false

    if (
      currentGoal.inputType ===
      ObservationDefinitionPermittedDataTypeKind._quantity
    ) {
      if (currentGoal.goalTargetValue === undefined) return false
    }

    if (
      currentGoal.inputType ===
      ObservationDefinitionPermittedDataTypeKind._string
    ) {
      return true
    }
    return true
  }

  function addCurrentDetails(pushToSave?: boolean) {
    if (pushToSave) {
      const totalGoals = [...addedGoals, currentGoal]
      console.log('---------------totalGoals--------------', totalGoals)
      dispatch(addNewGoalDetails(patient, totalGoals, carePlanId, encounterId))
      setAddedGoals(totalGoals)
      resetDetails()
    } else {
      setAddedGoals([...addedGoals, currentGoal])
      resetDetails()
    }
  }

  function resetDetails() {
    setGoal({
      id: _.random(1, 10000000).toString(),
      goalObservationCoding: undefined,
      goalTargetUnitCoding: undefined,
      goalTargetValue: undefined,
      goalTargetValueLow: undefined,
      goalTargetValueHigh: undefined,
      goalTargetValueText: undefined,
      goalTargetDurationValue: undefined,
      goalTargetDurationUnit: undefined,
      goalTargetDate: undefined,
      updateFrequencyNumber: 1,
      updateFrequencyUnit: 'month',
      inputDoneBy: 'practitioner',
    })
  }
  function isEnableSaveButton(): boolean | undefined {
    const result: boolean = false
    if (addedGoals.length > 0) return true

    if (
      currentGoal.goalObservationCoding !== undefined ||
      currentGoal.goalTargetValue !== undefined ||
      currentGoal.goalTargetUnitCoding !== undefined
    )
      return true
    return result
  }

  function onEditClicked(row: GoalFormModel) {
    setGoal(row)
    setAddedGoals(addedGoals.filter((e) => e.id !== row.id))
  }

  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()

  const addAllergySlice = useSelector(
    (state: RootState) => state.goalsOfSubscriptionsSlice
  )

  useEffect(() => {
    console.log('-------- changed addAllergySlice----------', addAllergySlice)
    if (addAllergySlice.additionSuccessful) {
      /// onAllergiesDetailsAdded(addAllergySlice.allergy)

      setAddedGoals([])
      onAllergiesDetailsAdded()
      onClose()
    }
  }, [addAllergySlice])
  const currentGoalData = useSelector(
    (state: RootState) => state.goalsOfSubscriptionsSlice.goalsFormModel
  )
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()

        setAddedGoals([])
      }}
      aria-labelledby='add-new-goal-for-patient-dialog-title'
      disableBackdropClick={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='add-new-goal-dialog-title'>Add New Goal</DialogTitle>
      <DialogContent dividers={true}>
        {!addAllergySlice.adding && (
          <Box
            marginX={1}
            paddingBottom={1}
            key={currentGoal.id}
            display='flex'
            flexDirection='column'
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Box display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='row'>
                  <ObservationDefinitionSelectSingle
                    key='wellopathy-imaging-techniques-vs-category'
                    title='Select Goal Measure'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-categories-vs'
                    id='select_goal_category'
                    fuzzySearch={true}
                    disabled={false}
                    preSelectedOptions={[
                      ...addedGoals.map((e) => e.goalObservationCoding!),
                      ...(currentGoalData ?? []).map(
                        (e) => e.goalObservationCoding!
                      ),
                    ]}
                    onOptionSelected={(
                      conditionsData: R4.IObservationDefinition | undefined
                    ) => {
                      if (conditionsData) {
                        setGoal({
                          ...currentGoal,
                          inputType: conditionsData.permittedDataType?.[0],
                          obsDefinition: conditionsData,
                          goalObservationCoding: conditionsData.code,
                          goalTargetValue: conditionsData
                            ? Number(
                                getObsDefinitionIntervalNumerical(
                                  conditionsData
                                )
                              )
                            : undefined,
                          goalTargetUnitCoding: conditionsData
                            .quantitativeDetails?.unit
                            ? getDefaultCodingOfSystemFromCodableConceptList([
                                conditionsData.quantitativeDetails?.unit,
                              ])
                            : undefined,
                          goalTargetValueText: '',
                        })
                      }
                    }}
                    helperText={undefined}
                    placeHolder='Goal Measure'
                    // preSelectedOptions={org.code}
                    alwaysShowAllOptions={true}
                    isImaging={true}
                  />
                </Box>
              </Box>

              {currentGoal.inputType ===
                ObservationDefinitionPermittedDataTypeKind._quantity && (
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Target level' />
                    <TextField
                      id='txt_target_level'
                      type='number'
                      variant='outlined'
                      fullWidth
                      margin='dense'
                      size='small'
                      helperText={
                        currentGoal.obsDefinition
                          ? getObsDefinitionIntervals(currentGoal.obsDefinition)
                          : undefined
                      }
                      defaultValue={currentGoal.goalTargetValue}
                      value={currentGoal.goalTargetValue}
                      onChange={(e) => {
                        setGoal({
                          ...currentGoal,
                          goalTargetValue: parseFloat(e.target.value),
                        })
                      }}
                    />
                  </Box>
                </Box>
              )}
              {currentGoal.inputType ===
                ObservationDefinitionPermittedDataTypeKind._string && (
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Goal details' />
                    <TextField
                      id='txt_target_description'
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      size='small'
                      value={currentGoal.goalTargetValueText}
                      onChange={(e) => {
                        setGoal({
                          ...currentGoal,
                          goalTargetValueText: e.target.value,
                        })
                      }}
                    />
                  </Box>
                </Box>
              )}

              {currentGoal &&
                currentGoal.goalTargetUnitCoding &&
                currentGoal.inputType ===
                  ObservationDefinitionPermittedDataTypeKind._quantity &&
                (currentGoal.goalTargetUnitCoding.display ||
                  currentGoal.goalTargetUnitCoding.code) && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel
                        title='Unit of Measure'
                        case='normal'
                      />
                      <TextField
                        id='txt_target_unit'
                        variant='outlined'
                        margin='dense'
                        size='small'
                        fullWidth
                        contentEditable={false}
                        value={
                          currentGoal.goalTargetUnitCoding.display ??
                          currentGoal.goalTargetUnitCoding.code
                        }
                      />
                    </Box>
                  </Box>
                )}

              {currentGoal &&
                currentGoal.goalTargetUnitCoding &&
                currentGoal.inputType ===
                  ObservationDefinitionPermittedDataTypeKind._quantity &&
                (currentGoal.goalTargetUnitCoding.display ||
                  currentGoal.goalTargetUnitCoding.code) && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel
                          title='Data updated by'
                          case='normal'
                        />
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          justifyItems='space-between'
                        >
                          <TextField
                            id='frequency_duration_type'
                            variant='outlined'
                            margin='dense'
                            select
                            size='small'
                            value={currentGoal.inputDoneBy}
                            defaultValue={currentGoal.inputDoneBy}
                            onChange={(e) => {
                              const updatedGoal = {
                                ...currentGoal,
                                inputDoneBy: e.target.value as
                                  | 'patient'
                                  | 'practitioner'
                                  | 'relatedPerson'
                                  | 'device'
                                  | 'any',
                              }
                              setGoal(updatedGoal)
                            }}
                          >
                            {dataInputBy.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      </Box>
                    </Box>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel
                          title='Update Frequency'
                          case='normal'
                        />
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          justifyContent='left'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Box>
                            <Typography
                              variant='body2'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Once in Every
                            </Typography>
                          </Box>
                          <Box px={1} maxWidth='80px'>
                            <TextField
                              id='txt_target_unit'
                              variant='outlined'
                              margin='dense'
                              size='small'
                              type='number'
                              value={currentGoal.updateFrequencyNumber}
                              defaultValue={currentGoal.updateFrequencyNumber}
                              onChange={(e) => {
                                const updatedGoal = {
                                  ...currentGoal,
                                  updateFrequencyNumber: parseInt(
                                    e.target.value,
                                    10
                                  ),
                                }
                                setGoal(updatedGoal)
                              }}
                            />
                          </Box>
                          <Box>
                            <TextField
                              id='frequency_duration_type'
                              variant='outlined'
                              margin='dense'
                              select
                              size='small'
                              value={currentGoal.updateFrequencyUnit}
                              onChange={(e) => {
                                const updatedGoal = {
                                  ...currentGoal,
                                  updateFrequencyUnit: e.target.value as
                                    | 'day'
                                    | 'week'
                                    | 'month',
                                }
                                setGoal(updatedGoal)
                              }}
                            >
                              {durationTypes.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
            </MuiPickersUtilsProvider>

            {addedGoals.length > 0 && (
              <Box display='flex' flexDirection='row' width='100%'>
                <Box
                  marginY={1}
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  overflow='auto'
                >
                  <WelloFormItemLabel title='Currently Added Goals' />

                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    overflow='auto'
                    //   key={e.id}
                  >
                    <TableContainer
                      style={{
                        width: '100%',
                        maxWidth: '100%',
                        margin: '0px',
                        padding: '0px',
                        overflow: 'auto',
                      }}
                    >
                      <Table
                        className={classes.table}
                        aria-label='simple table'
                        style={{
                          overflow: 'auto',
                        }}
                      >
                        <TableHead
                          style={{
                            backgroundColor: kDialogueBackground,
                          }}
                        >
                          <TableRow
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            <TableCell width={20}>
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Goal Measure
                              </Typography>
                            </TableCell>
                            <TableCell
                              width={8}
                              style={{
                                minWidth: '50px',
                              }}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Target/Goal details
                              </Typography>
                            </TableCell>

                            <TableCell
                              width={8}
                              style={{
                                minWidth: '50px',
                              }}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Data update details
                              </Typography>
                            </TableCell>

                            <TableCell
                              width={20}
                              style={{
                                maxWidth: '50px',
                              }}
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {addedGoals.map((row: GoalFormModel, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                              }}
                            >
                              <TableCell>
                                <Typography
                                  color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {_.capitalize(
                                    getDefaultDisplayOfSystemFromCodableConcept(
                                      row.goalObservationCoding
                                    )
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Box width='100%' overflow='auto'>
                                  <OverflowTypography
                                    text={getGoalTargetDetailsFromGoalModel(
                                      row
                                    )}
                                    typographyProps={{
                                      variant: 'body2',
                                      color: 'initial',
                                    }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box width='100%' overflow='auto'>
                                  <OverflowTypography
                                    text={getGoalDataUpdateDetailsFromGoalModel(
                                      row
                                    )}
                                    typographyProps={{
                                      variant: 'body2',
                                      color: 'initial',
                                    }}
                                  />
                                </Box>
                              </TableCell>

                              <TableCell width={4}>
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  justifyContent='flex-start'
                                  alignContent='center'
                                  alignItems='center'
                                >
                                  <Box paddingLeft={2}>
                                    <Tooltip
                                      title='Delete Goal'
                                      id='manage_goal_tooltip'
                                    >
                                      <IconButton
                                        aria-label='btn_goal_delete'
                                        color='primary'
                                        id='btn_goal_delete'
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                          onEditClicked(row)
                                        }}
                                      >
                                        <img
                                          src={`${process.env.PUBLIC_URL}/deleteVector.png`}
                                          alt='Delete'
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          <Button
            onClick={() => {
              onClose()
              setAddedGoals([])
            }}
            variant='outlined'
            id='al_33'
            disableElevation
            disabled={addAllergySlice.adding}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addCurrentDetails(false)
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={!isCurrentDetailsValid()}
          >
            Add More
          </Button>

          <Button
            onClick={() => {
              if (isCurrentDetailsValid()) {
                addCurrentDetails(true)
              } else if (addedGoals.length > 0) {
                dispatch(
                  showWarningAlert('Not adding current goal as it is not valid')
                )
                dispatch(
                  addNewGoalDetails(
                    patient,
                    addedGoals,
                    carePlanId,
                    encounterId
                  )
                )
              }
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='btn_save_all'
            disabled={addAllergySlice.adding || !isEnableSaveButton()}
          >
            {addAllergySlice.adding && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
            {!addAllergySlice.adding && 'Save'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
