/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestForRateUpdate } from 'redux/practitioner/medicalProviderManagement/medicalServiceProviderManagementSlice'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloTextField } from 'wello-web-components'

interface Props {
  practDetails: PractitionerWithRate
}

const useStyles = makeStyles((theme) => ({
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
      },
      input: {
        backgroundColor: 'white',
      },
      //   inputAdornedStart:{
      //       paddingLeft:8
      //   }
    },
  },
  dialogCustomizedWidth: {
    'max-width': '55%',
  },
}))
export const FollowUpTile: React.FC<Props> = ({ practDetails }: Props) => {
  const medicalServiceProviderManagementSlice = useSelector(
    (state: RootState) => state.medicalServiceProviderManagementSlice
  )
  const classes = useStyles()
  const { t } = useTranslation()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [price, SetPrice] = React.useState<number>(0)
  const [edit, setEdit] = React.useState<boolean>(false)

  useEffect(() => {
    SetPrice(practDetails.followUp)
  }, [practDetails.followUp])

  function handleSubmit() {
    let hasErrorsInData: boolean = false

    if (!price || price < 1 || price > 10 || Number.isNaN(price)) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      dispatch(requestForRateUpdate(practDetails, price, 'In Person', true))
    }
    setHasErrors(hasErrorsInData)
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      {practDetails && (
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' width='20%'>
            <Box width='100%' display='flex' flexDirection='column'>
              <WelloFormItemLabel title='Follow Up' />

              <TextField
                size='small'
                variant='outlined'
                type='tel'
                placeholder=''
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='flex-start'
                      >
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          Days
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 1,
                    max: 10,
                  },
                }}
                value={price}
                onChange={(e) => {
                  const newValue = Number(e.target.value)

                  if (newValue >= 0 && newValue <= 10) {
                    setEdit(true)
                    SetPrice(newValue)
                  } else if (/^\d+$/.test(e.target.value)) {
                    dispatch(
                      showErrorAlert(`Follow up days should be between  1 - 10`)
                    )
                  }
                }}
              />
              {/* <WelloTextFieldWithoutTitle
                textProps={{
                  id: `rateId`,
                  value: price,
                  type: 'phone',
                  className: classes.overrides,

                  inputProps: {
                    max: 10,
                    endAdornment: (
                      <InputAdornment position='end'>
                        {t('labelCommon:mins')}
                      </InputAdornment>
                    ),
                  },

                  error: hasErrors && (price <= 1 || price > 10),
                  helperText:
                    hasErrors && (price <= 1 || price > 10)
                      ? 'Day between 1 to 10'
                      : '',
                  onChange: (changeCommission) => {
                    SetPrice(parseInt(changeCommission.target.value, 10))
                    setEdit(true)
                  },
                }}
              /> */}
            </Box>
          </Box>
          <Box width='100%' display='flex' flexDirection='row'>
            <Box py={1}>
              <Button
                onClick={() => {
                  handleSubmit()
                }}
                variant='contained'
                disabled={
                  medicalServiceProviderManagementSlice.updatingPartnerDetail ||
                  practDetails.status !== 'Active' ||
                  edit === false
                }
                disableElevation
                color='primary'
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
            <Box py={1}>
              <Button
                variant='contained'
                color='primary'
                disabled={edit === false}
                onClick={() => {
                  SetPrice(practDetails.followUp)
                  setEdit(false)
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
