/* eslint-disable no-plusplus */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CardsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { RootState } from 'redux/rootReducer'
import {
  getErrors,
  getLoincCodeFromPlanDef,
  getTestsOfTypeInCard,
  hasError,
} from 'utils/patientHelper/cds_helper'
import {
  CdsRecommendationPopupProps,
  CdsRecommendationsPopup,
} from 'views/components/patients/patient_profile/cds_recommendations'
import autoStories from '../../../../assets/icons/CarePlanIcon.svg'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '19.07px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#4C4C4C',
    textTransform: 'capitalize',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#4D4D4D',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  button: {
    padding: '4px 12px',
    borderRadius: '2px',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  encounter: R4.IEncounter
  cdsCard: CardsEntity
  index: number
  onViewDetailsClicked?: (card: CardsEntity) => void
}

export const CdsCardSummary: React.FC<CdsCardDetailsProps> = ({
  patient,
  encounter,
  cdsCard: card,
  index,
  onViewDetailsClicked,
}: CdsCardDetailsProps) => {
  const classes = useStyles()

  const [showCdsRecommendation, setShowCdsRecommendation] =
    useState<CdsRecommendationPopupProps>({
      open: false,
      encounter,
      patientDetails: patient,
      onClose: () => {},
    })

  return (
    <Box
      display='flex'
      flexDirection='column'
      overflow='auto'
      key={card.summary ?? card.indicator ?? ''}
    >
      {hasError(card) && (
        <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
          <Box
            display='flex'
            flexDirection='row'
            alignContent='center'
            alignItems='center'
          >
            <Icon color='primary'>
              <img src={autoStories} alt='ebm_icon' />
            </Icon>
            <Typography className={classes.titleText}>
              {card.summary ?? card.indicator ?? ''}
            </Typography>
          </Box>
          <List aria-label='error'>
            {getErrors(card)?.map((error) => (
              <ListItem key={error} disableGutters>
                <ListItemText
                  primary={error}
                  className={classes.subTitleText}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {!hasError(card) &&
        card.suggestions &&
        _.map(card.suggestions, (each, i: number) => (
          <Box
            display='flex'
            flexDirection='column'
            key={`${each.label}_label`}
            overflow='auto'
          >
            {each.actions && each.actions.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                key={`${each.label}_each_label`}
                overflow='auto'
              >
                <Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    py={1}
                    alignContent='center'
                    alignItems='center'
                  >
                    <Icon color='primary'>
                      <img src={autoStories} alt='ebm_icon' />
                    </Icon>
                    <Typography className={classes.titleText}>
                      {card.summary ?? card.indicator ?? ''}
                    </Typography>
                  </Box>
                  {card.detail && (
                    <Box display='flex' overflow='hidden'>
                      <Typography
                        className={classes.subTitleText}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '3',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {card.detail ?? ''}
                      </Typography>
                    </Box>
                  )}
                  <Box display='flex' flexDirection='row' py={1}>
                    <Box width='100%'>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        id={`view_plan${index}`}
                        className={classes.button}
                        onClick={() => {
                          if (onViewDetailsClicked) {
                            onViewDetailsClicked(card)
                          } else {
                            setShowCdsRecommendation({
                              ...showCdsRecommendation,
                              open: true,
                              indexOfRecommendation: index,
                              onClose: () => {
                                setShowCdsRecommendation({
                                  ...showCdsRecommendation,
                                  open: false,
                                  indexOfRecommendation: undefined,
                                })
                              },
                            })
                          }
                        }}
                      >
                        <Typography
                          className={classes.buttonText}
                          id={`view_planText${index}`}
                        >
                          View Plan
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      {showCdsRecommendation.open && (
        <CdsRecommendationsPopup {...showCdsRecommendation} />
      )}
    </Box>
  )
}
