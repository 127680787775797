import { Box, Card, Divider, Typography } from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

export interface TabProps {
  mainComponent: React.ReactNode
}

export const LandingLogoComponent: React.FC<TabProps> = ({ mainComponent }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Card>
        <Box
          display='flex'
          flexDirection={isTabletOrMobile ? 'column' : 'row'}
          padding={2}
          minHeight='342px'
          justifyContent='center'
          alignContent='center'
          style={{
            gap: isTabletOrMobile ? '16px' : undefined,
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignContent='center'
            justifyContent='center'
            alignItems='center'
            justifyItems='center'
            minWidth='380px'
          >
            <img
              src={`${process.env.PUBLIC_URL}/logo_with_steam.png`}
              alt='delete'
              height='170px'
              width='255.88px'
            />
            <Box paddingX={2} paddingBottom={isTabletOrMobile ? 2 : undefined}>
              <Typography
                variant='subtitle1'
                color='initial'
                align='center'
                style={{
                  color: '#53B447',
                }}
              >
                {process.env.REACT_APP_NAME === 'WelloLAB'
                  ? 'Efficient patient engagements, phlebotomist operations'
                  : process.env.REACT_APP_NAME === 'WelloDR'
                  ? 'Superior personalized care, world class evidence'
                  : 'Smartly monitor, manage, control overall operations'}
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation={isTabletOrMobile ? 'horizontal' : 'vertical'}
            flexItem
            style={{
              height: isTabletOrMobile ? '1px' : undefined,

              marginLeft: 16,
              marginRight: 16,
              backgroundColor: kPrimaryMain,
            }}
          />{' '}
          <Box
            padding={2}
            minWidth='380px'
            alignContent='center'
            justifyContent='center'
            alignItems='center'
            justifyItems='center'
            display='flex'
            flexDirection='column'
          >
            {mainComponent}
          </Box>
        </Box>
      </Card>
    </Box>
  )
}
