import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  FhirAppointmentDetail,
  FhirPractitionerDetail,
} from 'models/fhirAppointmentDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { en } from 'wello-fhir-l10n/dist/in'
import { CreateLabDiagnosticsStatus } from './createLabDiagnosticsStatus'

const initialState: CreateLabDiagnosticsStatus = {
  creatingAppointment: false,
  errorWhileCreatingAppointment: false,
  additionSuccessful: false,
}

const createLabDiagnosticsSlice = createSlice({
  name: 'createLabDiagnosticsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<CreateLabDiagnosticsStatus>) {
      state.additionSuccessful = action.payload.additionSuccessful
      state.errorReason = action.payload.errorReason
      state.errorWhileCreatingAppointment =
        action.payload.errorWhileCreatingAppointment
      state.creatingAppointment = action.payload.creatingAppointment
      state.createdAppointment = action.payload.createdAppointment
      state.additionSuccessful = action.payload.additionSuccessful
    },
  },
})

export const resetLabDiagnosticCreationState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CreateLabDiagnosticsStatus = { ...initialState }
    dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))
  }

export const requestLabOrder =
  (
    selectedLabOfferings: R4.IPlanDefinition[],
    selectedPatient: R4.IPatient,
    encounter: R4.IEncounter,
    practitionerDetail: FhirPractitionerDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CreateLabDiagnosticsStatus = { ...initialState }
    state.creatingAppointment = true
    dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))

    try {
      const bundleObject: R4.IBundle = getOrderCreationTransactionObject(
        selectedLabOfferings,
        selectedPatient,
        encounter,
        practitionerDetail
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        state.additionSuccessful = true
        state.creatingAppointment = false
        dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))
        /* dispatch(
          showSuccessAlert(getOrderCreationSuccessfulMessage(selectedPatient))
        ) */
      } else {
        state.additionSuccessful = false
        state.creatingAppointment = false
        state.errorWhileCreatingAppointment = false
        dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.info(error)
      const errorSearchDoctor: CreateLabDiagnosticsStatus = {
        creatingAppointment: false,
        errorWhileCreatingAppointment: true,
        additionSuccessful: false,
        errorReason: 'Error',
      }
      dispatch(
        createLabDiagnosticsSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const requestLabOrderFromCds =
  (
    selectedLabOfferings: R4.IPlanDefinition[],
    selectedPatient: R4.IPatient,
    encounter: R4.IEncounter,
    practitionerDetail: FhirPractitionerDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CreateLabDiagnosticsStatus = { ...initialState }
    state.creatingAppointment = true
    dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))
    const fhirmasterApi: FHIRApiClient = new FHIRApiClient()
    let originalPlanDefs: R4.IPlanDefinition[] = []

    await Promise.all(
      selectedLabOfferings.map(async (i) => {
        const response = await fhirmasterApi.doGetResource('PlanDefinition', {
          'plandefinition-identifier': i!.identifier![0].value,
        })
        const bundle: R4.IBundle = response as R4.IBundle
        originalPlanDefs = [
          ...originalPlanDefs,
          {
            resourceType: 'PlanDefinition',
            id: bundle.entry![0].resource!.id,
          },
        ]
      })
    )

    try {
      const bundleObject: R4.IBundle = getOrderCreationTransactionObject(
        originalPlanDefs,
        selectedPatient,
        encounter,
        practitionerDetail
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        state.additionSuccessful = true
        state.creatingAppointment = false
        dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))
        dispatch(showSuccessAlert('Lab Tests are added successfully'))
      } else {
        state.additionSuccessful = false
        state.creatingAppointment = false
        state.errorWhileCreatingAppointment = false
        dispatch(createLabDiagnosticsSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.info(error)
      const errorSearchDoctor: CreateLabDiagnosticsStatus = {
        creatingAppointment: false,
        errorWhileCreatingAppointment: true,
        additionSuccessful: false,
        errorReason: 'Error',
      }
      dispatch(
        createLabDiagnosticsSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

function getOrderCreationTransactionObject(
  selectedLabOfferings: R4.IPlanDefinition[],
  selectedPatient: R4.IPatient,
  encounter: R4.IEncounter,
  practitionerDetail: FhirPractitionerDetail
): R4.IBundle {
  const requestId: string = getUniqueTempId()

  const currentUser: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const currentServiceRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    instantiatesCanonical: selectedLabOfferings.map(
      (e) => `${e.resourceType}/${e.id}` ?? ''
    ),
    requester: {
      id: currentUser.id,
      reference: `${currentUser.resourceType}/${currentUser.id}`,
    },
    status: 'active',
    intent: 'directive',
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],

    priority: 'urgent',
    occurrenceDateTime: new Date().toISOString(),
    authoredOn: new Date().toISOString(),
    encounter: {
      reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
      type: encounter.resourceType,
    },
    id: requestId,
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: currentServiceRequest.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: currentServiceRequest.resourceType,
        },
        resource: currentServiceRequest,
      },
    ],
  }

  logger.info(requestBundle)

  return requestBundle
}

function getCDSOrderCreationTransactionObject(
  selectedLabOfferings: R4.IPlanDefinition[],
  selectedPatient: R4.IPatient,
  fhirAppointmentDetails: FhirAppointmentDetail
): R4.IBundle {
  const requestId: string = getUniqueTempId()
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(
    fhirAppointmentDetails
  )

  const currentUser: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const matchString: string = `${encounter.resourceType}?appointment=${fhirAppointmentDetails.appointment.resourceType}/${fhirAppointmentDetails.appointment.id}`
  const currentServiceRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    instantiatesCanonical: selectedLabOfferings.map(
      (e) => `${e.resourceType}?name=${e.name}` ?? ''
    ),
    requester: {
      id: currentUser.id,
      reference: `${currentUser.resourceType}/${currentUser.id}`,
    },
    status: 'active',
    intent: 'directive',
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],

    priority: 'urgent',
    occurrenceDateTime: new Date().toISOString(),
    authoredOn: new Date().toISOString(),
    encounter: {
      reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
      type: encounter.resourceType,
    },
    id: requestId,
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
      {
        fullUrl: currentServiceRequest.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: currentServiceRequest.resourceType,
        },
        resource: currentServiceRequest,
      },
    ],
  }

  logger.info(requestBundle)

  return requestBundle
}

export default createLabDiagnosticsSlice.reducer
