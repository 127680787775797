import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PurposeOfUse } from 'models/purposeOfUse'
import { WelloMedication } from 'models/welloMedication'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  requestMedicationHistoryOfPatient,
  resetCurrentMedicationSearch,
} from 'redux/patientMedicalHistory/medicationHistory/medicationHistorySearchSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { createBundleObjectForWelloMedications } from 'utils/appointment_handle/medicationStatement_utils'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import { AddMedicationStatementStatusTypes } from './addMedicationStatementStatusTypes'

const initialState: AddMedicationStatementStatusTypes = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addMedicationStatementSearchSlice = createSlice({
  name: 'addMedicationStatementSearchSlice',
  initialState,
  reducers: {
    updateAddConditionStatus(
      state,
      action: PayloadAction<AddMedicationStatementStatusTypes>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.medicationStatement = action.payload.medicationStatement
    },

    resetAllergiesDetails(
      state,
      action: PayloadAction<AddMedicationStatementStatusTypes>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.medicationStatement = initialState.medicationStatement
    },
  },
})

export const addMedicationStateMentDetails =
  (
    patient: R4.IPatient,
    encounter: R4.IEncounter,
    medications: WelloMedication[],
    brandName: string,
    ipd?: boolean,
    appointmentId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddMedicationStatementStatusTypes = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }

    dispatch(
      addMedicationStatementSearchSlice.actions.updateAddConditionStatus(
        addingState
      )
    )
    const practitionerDetail = getCurrentUserPractitionerDetails()
    const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
    try {
      const bundleObject: R4.IBundle = createBundleObjectForWelloMedications(
        patient,
        encounter,

        medications
      )

      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        ``,
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(resetCurrentMedicationSearch())

          if (ipd && appointmentId) {
            dispatch(
              requestMedicationHistoryOfPatient(
                patient,
                PurposeOfUse.IPDAppointment,
                false,
                appointmentId
              )
            )
          }

          dispatch(showSuccessAlert('Current Medication Added Successfully'))
          dispatch(
            addMedicationStatementSearchSlice.actions.updateAddConditionStatus(
              addingState
            )
          )
        }
      } else {
        const errorCreatePersonState: AddMedicationStatementStatusTypes = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while medication details',
        }
        dispatch(
          addMedicationStatementSearchSlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddMedicationStatementStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addMedicationStatementSearchSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddMedicationStatementState =
  () => (dispatch: AppDispatch) => {
    dispatch(
      addMedicationStatementSearchSlice.actions.resetAllergiesDetails(
        initialState
      )
    )
  }

export default addMedicationStatementSearchSlice.reducer
