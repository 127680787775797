import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getClinicalImpressionsOfAppointment,
  updateClinicalImpressions,
} from 'redux/consultation/assessmentDetailsSlice/assessmentDetailsSlice'
import { requestAssessmentMedicalConditions } from 'redux/consultation/assessmentMedicalCondition/medicalAssessmentSlice'
import { getClinicalImpressionsOfAppointmentForFollowUp } from 'redux/consultation/followUpAssesmentDetails/followUpAssessmentDetailsSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  hasAllergies,
  hasConditions,
  hasSameSummary,
} from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'
import { SOAPNumberedElement } from 'wello-web-components'
import { ConditionTile } from '../objective/tiles/conditionTile'
import { ConditionTileForFollowUp } from '../objective/tiles/conditionTileFollowUp'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const AssessDetails: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  split,
}: AssessmentCaptureProps) => {
  const [summary, setSummary] = React.useState<string>()

  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [diagnosedConditions, setDiagnosedConditions] = useState<
    R4.ICondition[]
  >([])
  const [diagnosedAllergies, setDiagnosedAllergies] = useState<R4.ICoding[]>([])
  /*  const [findings, setFindings] = useState<R4.IClinicalImpression_Finding[]>([]) */
  const assessmentDetailsSlice = useSelector(
    (state: RootState) => state.followUpAssessmentDetailsSlice
  )

  useEffect(() => {
    /*  if (assessmentDetailsSlice.updatedImpressions) {
      dispatch(updateAppointmentStatus(fhirAppointmentDetails.appointment.id!))
    } */
  }, [assessmentDetailsSlice])

  const updateAssessmentDetails = (condition: FhirClinicalImpressionDetail) => {
    logger.info('FhirAssessment Details,', condition)
    /*  if (condition.clinicalImpression.finding) {
      setFindings(condition.clinicalImpression.finding)
    } */
    if (condition.clinicalImpression.summary) {
      setSummary(condition.clinicalImpression.summary)
    }
    if (
      condition.diagnosedAllergyCodes &&
      condition.diagnosedAllergyCodes.length > 0
    ) {
      setDiagnosedAllergies(condition.diagnosedAllergyCodes)
    }
    if (
      condition.diagnosedConditions &&
      condition.diagnosedConditions.length > 0
    ) {
      setDiagnosedConditions(condition.diagnosedConditions)
    }
    if (initialFetch.current) {
      initialFetch.current = false
    }
  }

  useEffect(() => {
    dispatch(
      getClinicalImpressionsOfAppointmentForFollowUp(fhirAppointmentDetails)
    )
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (assessmentDetailsSlice.resultsAvailable) {
      if (assessmentDetailsSlice.clinicalImpressions) {
        initialFetch.current = true
        updateAssessmentDetails(assessmentDetailsSlice.clinicalImpressions)
      }
    }
    if (assessmentDetailsSlice.errorWhileSearchingImpressions) {
      if (initialFetch.current) {
        initialFetch.current = false
      }
    }
    if (assessmentDetailsSlice.errorWhileUpdatingImpressions) {
      if (initialFetch.current) {
        initialFetch.current = false
      }
    }
    if (assessmentDetailsSlice.noResultsAvailable) {
      if (initialFetch.current) {
        initialFetch.current = false
      }
    }
  }, [assessmentDetailsSlice])

  return (
    <div style={{ paddingLeft: '4px' }}>
      <Grid container spacing={2} direction='column'>
        <Grid item>
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({ selectedSection: 'visit_summary' })
                )
              }
            }}
          >
            <Grid container spacing={2} direction='column' id='visit_summary'>
              <Grid item>
                <Box display='flex'>
                  {summary && summary.length > 0 && (
                    <Box flexGrow={1}>
                      <Typography variant='h6'>
                        {' '}
                        Previous Appointment Patient Summary
                      </Typography>
                    </Box>
                  )}

                  {(assessmentDetailsSlice.updatingImpressions ||
                    assessmentDetailsSlice.fetchingImpressions) && (
                    <CircularProgress size={15} />
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Box display='flex' flexDirection='column'>
                  <Box>
                    {' '}
                    <Typography variant='body1'>{summary}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ReactVisibilitySensor>
        </Grid>
        <Grid item xs={split ? 12 : 10}>
          <ReactVisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
              }
            }}
          >
            <Grid container direction='column'>
              <Grid item id='diagnosis'>
                <Grid
                  container
                  direction='row'
                  style={{ padding: 0, margin: 0 }}
                >
                  <Box py={1}>
                    <Typography variant='h6'>
                      {' '}
                      Previous Appointment Diagnosis{' '}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid item>
                {/* <CaptureDiagnosis
                  onAddClicked={(selectedType, selectedCodings) => {
                    logger.info(selectedType)
                    logger.info(selectedCodings)
                    if (selectedCodings) {
                      if (selectedType === 'condition') {
                        setDiagnosedConditions((addedConditionCodes) => [
                          ...addedConditionCodes,
                          selectedCodings,
                        ])
                      } else {
                        setDiagnosedAllergies((addedAllergyCodings) => [
                          ...addedAllergyCodings,
                          selectedCodings,
                        ])
                      }
                      
                        '--------------Added to local list------------------'
                      )
                      updateDetails()
                    }
                  }}
                /> */}
              </Grid>
              {diagnosedConditions && diagnosedConditions.length === 0 && (
                <Box paddingBottom={1}>No condition data available</Box>
              )}

              {diagnosedConditions && diagnosedConditions.length > 0 && (
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  paddingY={1}
                >
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                    paddingY={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Condition
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {diagnosedConditions && diagnosedConditions.length > 0 && (
                      <Box>
                        <ConditionTileForFollowUp
                          obsData={diagnosedConditions}
                          fhirAppointmentDetails={fhirAppointmentDetails}
                          onMedicalConditionAdded={() => {
                            setDiagnosedConditions([])
                            dispatch(
                              requestAssessmentMedicalConditions(
                                fhirAppointmentDetails.patient
                              )
                            )
                            dispatch(
                              getClinicalImpressionsOfAppointmentForFollowUp(
                                fhirAppointmentDetails
                              )
                            )
                          }}
                          split={split}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
          </ReactVisibilitySensor>
        </Grid>
      </Grid>

      {/* <AddFindingsHandler
        fhirAppointmentDetails={fhirAppointmentDetails}
        open={openAddFindingDialogue}
        onClose={() => {
          setOpenAddFindingDialogue(false)
        }}
        onFindingsAdded={(finding) => {
          // setOpenAddFindingDialogue(false);
          if (finding) {
            setFindings((newFindings) => [...newFindings, finding])
            // handleUpdate();
          }
        }}
      /> */}
    </div>
  )
}
