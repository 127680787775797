/* eslint-disable no-useless-catch */
import { R4 } from '@ahryman40k/ts-fhir-types'
import _ from 'lodash'
import { CurrentLoggedInUser } from 'models/currentUserDetails'
import { OIDCUserInfo } from 'models/oidcUserInfo'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { Services, UnitServices } from 'models/units/unitServices'
import { USER_DETAILS } from 'utils/appConstants'
import {
  getCurrentUserDetailsFromReference,
  getOIDCUserInfoObject,
  storeUnitServices,
  storeUserDetails,
} from 'utils/authHelpers'
import {
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getLastNameFromHumanName,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import {
  getMedicineArray,
  getOrganizationType,
  getOrgDetailsAlongWithServices,
  getOrgDetailsAlongWithServicesForOrg,
  getSystemOfMedicineArray,
} from 'utils/fhirResoureHelpers/organizationHelpers'
import { logger } from 'utils/logger'
import { isContactExist } from 'utils/medicineHelper/cart-apis'
import { FHIRApiClient } from './fhirApiServices'

export const getUserDetails = (): CurrentLoggedInUser | undefined => {
  const tempString = sessionStorage.getItem(USER_DETAILS)
  if (tempString) {
    const loggedInUserDetails: CurrentLoggedInUser = JSON.parse(tempString)
    return loggedInUserDetails
  }
  return undefined
}

export function getUserCurrentRole(): string[] | undefined {
  if (getCurrentUserPractitionerRoleDetails()) {
    const roles: R4.ICodeableConcept[] =
      getCurrentUserPractitionerRoleDetails().code ?? []
    if (roles.length > 0) {
      if (roles[0] && roles[0].coding && roles[0].coding[0]) {
        return roles.map(
          (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
        )
      }
    }
  }

  return undefined
}

export function isCurrentUserDoctor(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole)
    return (
      currentRole.includes('doctor') ||
      currentRole.includes('ayurveda') ||
      currentRole.includes('1255370008')
    )
  return false
}

export function getUserCurrentRoleDisplay(): string | undefined {
  const roles: R4.ICodeableConcept[] =
    getCurrentUserPractitionerRoleDetails().code ?? []
  if (roles.length > 0) {
    if (roles[0] && roles[0].coding && roles[0].coding[0]) {
      if (roles[0].coding[0].code === 'ayurveda') {
        return 'Ayurveda (Vaidya)'
      }
      if (roles[0].coding[0].display?.toUpperCase() === 'GRE') {
        return 'GRM'
      }

      if (isDietician()) {
        return 'Dietitian'
      }

      return roles[0].coding[0].display
    }
  }
  return undefined
}

export function getCurrentUserPractitionerDetails(): R4.IPractitioner {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    return currentUserDetails.practitioner
  }
  return { resourceType: 'Practitioner' }
}

export function getCurrentUserUnitDetails(): R4.IOrganization {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    return (
      currentUserDetails.unitOrganization ?? { resourceType: 'Organization' }
    )
  }
  return { resourceType: 'Organization' }
}

export function getCurrentUserUnitReference(): R4.IReference | undefined {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    const { unitOrganization } = currentUserDetails
    if (unitOrganization) {
      return {
        id: unitOrganization.id ?? '',
        reference: `${unitOrganization.resourceType}/${
          unitOrganization.id ?? ''
        }`,
      }
    }
  }
  return undefined
}

export function getCurrentUserUnitLocationDetails(): R4.ILocation {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    return currentUserDetails.locationDetails ?? { resourceType: 'Location' }
  }
  return { resourceType: 'Location' }
}

export function getCurrentUserPractitionerRoleDetails(): R4.IPractitionerRole {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    return currentUserDetails.practitionerRole!
  }
  return { resourceType: 'PractitionerRole' }
}

export function getCurrentUserPractitionerRoleRef(): R4.IReference | undefined {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    const unitOrganization: R4.IPractitionerRole | undefined =
      currentUserDetails.practitionerRole
    if (unitOrganization) {
      return {
        id: unitOrganization.id ?? '',
        reference: `${unitOrganization.resourceType}/${
          unitOrganization.id ?? ''
        }`,
      }
    }
  }
  return undefined
}

export function getCurrentUserMainOrganizationDetails(): R4.IOrganization {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    return (
      currentUserDetails.mainOrganization ?? { resourceType: 'Organization' }
    )
  }
  return { resourceType: 'Organization' }
}

export function getCurrentUserPractitionerRoleDetailsWithPractitioner():
  | PractitionerWithRole
  | undefined {
  const currentUserDetails: CurrentLoggedInUser | undefined = getUserDetails()

  if (currentUserDetails) {
    return {
      id: currentUserDetails.practitioner.id ?? '',
      gender: '',
      name: getNameFromHumanName(currentUserDetails.practitioner.name ?? []),
      roleObject: currentUserDetails.practitionerRole!,
      practitionerObject: currentUserDetails.practitioner,
      fullName: '',
      color: '',
      primaryContact: '',
      status: '',
      phone: '',
      enabled: currentUserDetails.practitioner
        ? currentUserDetails.practitioner.active
          ? currentUserDetails.practitioner.active
          : false
        : false,
    }
  }
  return undefined
}

export async function updateUserDetailsFromServer(
  isBeforeRoleSelection?: boolean
): Promise<boolean> {
  const value1: OIDCUserInfo | undefined = getOIDCUserInfoObject()

  logger.info('----------------currentLoggedInUser---------------------')
  logger.info('Store resp', value1)
  if (value1) {
    let fhirUserRef: string = value1?.fhirUser ?? ''

    if (fhirUserRef === undefined || fhirUserRef.length === 0) {
      const currentPractitioner: R4.IPractitioner =
        getCurrentUserPractitionerDetails()
      if (
        currentPractitioner.id === undefined ||
        currentPractitioner.id?.length === 0
      ) {
        return false
      }
      fhirUserRef = `${currentPractitioner.resourceType}/${currentPractitioner.id}`
    }

    const currentLoggedInUser: CurrentLoggedInUser | undefined =
      await getUserDetailsFromFhir(fhirUserRef, isBeforeRoleSelection)

    logger.info('currentLoggedInUser --------------')

    if (currentLoggedInUser) {
      const res = await storeUserDetails(currentLoggedInUser)

      if (!isOrgAdmin()) {
        const servicesDetails = await getUnitDetailsFromServer(
          getCurrentUserUnitDetails().id ?? ''
        )
        const storeUnitServicesRes = await storeUnitServices(servicesDetails)
        return storeUnitServicesRes
      }

      return res
    }
  }
  return false
}

// todo: Update user details with preserving user current role

const getUserDetailsFromFhir = async (
  fhirProvider: string,
  isBeforeRoleSelection?: boolean
): Promise<CurrentLoggedInUser | undefined> => {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    practitioner: fhirProvider,
    '_include:iterate': '*',
    '_tag:not': 'mirror-resource',
  }
  const response: any = await fhirClient.doGetResource(
    '/PractitionerRole',
    searchParameters
  )

  const slotsResponse: R4.IBundle = response as R4.IBundle

  return getCurrentUserDetailsFromReference(
    slotsResponse,
    isBeforeRoleSelection
  )
}

export const getUnitDetailsFromServer = async (
  unitId?: string
): Promise<UnitServices[]> => {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const mainOrganization: R4.IOrganization | undefined =
    getUserDetails()?.mainOrganization
  let searchParametes = {}
  if (mainOrganization) {
    searchParametes = {
      partof: `${mainOrganization?.resourceType}/${mainOrganization.id ?? ''}`,
      _count: 10,
      '_tag:not': 'mirror-resource',
    }
  }
  const response: any = await fhirClient.doGetResource(
    `/Organization?_id=${unitId}&_revinclude=HealthcareService:organization&_tag:not=mirror-resource`,
    searchParametes
  )

  const slotsResponse: R4.IBundle = response as R4.IBundle
  const unitServicesData: UnitServices[] =
    getOrgDetailsAlongWithServices(slotsResponse)
  return unitServicesData
}

export const getServiceDetailsFromServer = async (
  unitId?: string
): Promise<Services> => {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const serviceData: Services = {
    systemOfMedicine: [],
    serviceType: [],
    ipdService: undefined,
    opdService: undefined,
    dayCareService: undefined,
    systemOfMedicineService: undefined,
    allopathyEssential: undefined,
    ayurvedaEssentials: undefined,
  }
  const response: any = await fhirClient.doGetResource(
    `/HealthcareService?organization=Organization/${unitId!}&service-category=306,224891009,33022008,304903009`
  )

  const slotsResponse: R4.IBundle = response as R4.IBundle
  const observations: R4.IHealthcareService[] =
    slotsResponse.entry?.map(
      (item) => item.resource as R4.IHealthcareService
    ) ?? []

  if (observations.length > 0) {
    const serviceDataWithData: Services = {
      systemOfMedicine: getMedicineArray(observations),
      serviceType: [],
      ipdService: getSystemOfMedicineArray(observations, '394656005'),
      opdService: getSystemOfMedicineArray(observations, '33022008'),
      dayCareService: getSystemOfMedicineArray(observations, '304903009'),
      systemOfMedicineService: undefined,
      allopathyEssential: undefined,
      ayurvedaEssentials: undefined,
    }
    return serviceDataWithData
  }
  return serviceData
}

export const getOrgDetailsFromServer = async (
  unitId?: string
): Promise<UnitServices[]> => {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParametes = {
    _count: 10,

    '_tag:not': 'mirror-resource',
  }

  const response: any = await fhirClient.doGetResource(
    `/Organization?_id=${unitId}&_revinclude=HealthcareService:organization&type=vendor-org&_tag:not=mirror-resource`,
    searchParametes
  )

  const slotsResponse: R4.IBundle = response as R4.IBundle
  const unitServicesData: UnitServices[] =
    getOrgDetailsAlongWithServicesForOrg(slotsResponse)

  return unitServicesData
}

// export const getContactDetails = async (): Promise<string> => {
//   const contactId = await isContactExist(
//     getCurrentUserPractitionerDetails().id ?? ''
//   )

//   return contactId
// }

export function isMedicalServiceProvider(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole)
    return (
      currentRole.includes('doctor') ||
      currentRole.includes('nurse') ||
      currentRole.includes('ayurveda') ||
      currentRole.includes('doctor-partner') ||
      currentRole.includes('dietician') ||
      currentRole.includes('36682004') ||
      currentRole.includes('106296000') ||
      currentRole.includes('1255370008')
    )
  return false
}

export function isNurse(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('nurse')
  return false
}

export function isTherapist(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('106296000')
  return false
}

export function isFrontDesk(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('front-desk')
  return false
}

export function isNaturoPath(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('1255370008')
  return false
}

export function isGre(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('receptionist')
  return false
}

export function isPartnerLab(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('partner-lab')
  return false
}

export function isAgent(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('lab-agent')
  return false
}

export function isDoctor(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole)
    return (
      currentRole.includes('doctor') ||
      currentRole.includes('doctor-partner') ||
      currentRole.includes('ayurveda') ||
      currentRole.includes('1255370008')
    )
  return false
}

export function isOnlyDoctor(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('doctor')
  return false
}

export function isOnlyUnitAdmin(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('unit-admin')
  return false
}
export function isAyurvedaDoctor(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('ayurveda')
  return false
}

export function isNaturoPathsDoctor(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('1255370008')
  return false
}

export function isAyurvedaDoctorForOnboarding(
  userRole: R4.IPractitionerRole
): boolean {
  const currentRole: string[] | undefined = getUserRoles(userRole)
  if (currentRole) return currentRole.includes('ayurveda')
  return false
}

export function isPTDoctorForO(userRole: R4.IPractitionerRole): boolean {
  const currentRole: string[] | undefined = getUserRoles(userRole)
  if (currentRole) return currentRole.includes('36682004')
  return false
}

export function isTherapistForOnboarding(
  userRole: R4.IPractitionerRole
): boolean {
  const currentRole: string[] | undefined = getUserRoles(userRole)
  if (currentRole) return currentRole.includes('106296000')
  return false
}

export function isNaturoPathForTable(userRole: R4.IPractitionerRole): boolean {
  const currentRole: string[] | undefined = getUserRoles(userRole)
  if (currentRole) return currentRole.includes('1255370008')
  return false
}

export function isDietitianForOnboarding(
  userRole: R4.IPractitionerRole
): boolean {
  const currentRole: string[] | undefined = getUserRoles(userRole)
  if (currentRole) return currentRole.includes('dietician')
  return false
}
export function isUserDoctor(userRole: R4.IPractitionerRole): boolean {
  const currentRole: string[] | undefined = getUserRoles(userRole)
  if (currentRole)
    return (
      currentRole.includes('doctor') ||
      currentRole.includes('doctor-partner') ||
      currentRole.includes('1255370008') ||
      currentRole.includes('ayurveda')
    )
  return false
}

export function isUnitAdmin(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('unit-admin')
  return false
}
export function isOrgAdmin(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('org-admin')
  return false
}

export function isWpAdmin(): boolean {
  const currentRole: string[] | undefined = getUserCurrentRole()
  if (currentRole) return currentRole.includes('wp-admin')
  return false
}

export function isPhysiotherapist(): boolean {
  const currentRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  let rolesData: string[] = []

  if (currentRole) {
    const roles: R4.ICodeableConcept[] =
      getCurrentUserPractitionerRoleDetails().code ?? []
    if (roles.length > 0) {
      if (roles[0] && roles[0].coding && roles[0].coding[0]) {
        rolesData = roles.map(
          (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
        )
      }
    }
  }

  if (rolesData) return rolesData.includes('36682004')
  return false
}

export function isDietician(practRole?: R4.IPractitionerRole): boolean {
  const currentRole: R4.IPractitionerRole =
    practRole ?? getCurrentUserPractitionerRoleDetails()

  let rolesData: string[] = []

  if (currentRole) {
    const roles: R4.ICodeableConcept[] = currentRole.code ?? []
    if (roles.length > 0) {
      if (roles[0] && roles[0].coding && roles[0].coding[0]) {
        rolesData = roles.map(
          (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
        )
      }
    }
  }

  if (rolesData) return rolesData.includes('dietician')
  return false
}
export function isOrgUser(): boolean {
  const currentOrg: R4.IOrganization = getCurrentUserUnitDetails()

  return getOrganizationType(currentOrg) === 'vendor-org'
}

export function isWpOrgUser(): boolean {
  const currentOrg: R4.IOrganization = getCurrentUserUnitDetails()

  return getOrganizationType(currentOrg) === 'wellopathy-org'
}

export function isUnitUser(): boolean {
  const currentOrg: R4.IOrganization = getCurrentUserUnitDetails()
  return getOrganizationType(currentOrg) === 'vendor-unit'
}

export function getUnitType(): String {
  const currentOrg: R4.IOrganization = getCurrentUserUnitDetails()

  const currentUnitType: String =
    getCodeOfSystemFromCodableConceptList(
      currentOrg.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.code ?? ''
  return currentUnitType
}

export function getSelectedOrgUnitType(org: R4.IOrganization): string {
  const currentUnitType: string =
    getCodeOfSystemFromCodableConceptList(
      org.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.code ?? ''
  return currentUnitType
}

export function getPractitionerRoleObject(
  item: R4.IPractitionerRole,
  practitioner: R4.IPractitioner
) {
  const name = getNameFromHumanName(practitioner.name ?? [])
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name,
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}
export function getRolesAsStringFromPractRole(item: R4.IPractitionerRole) {
  let rolesString = item.code
    ?.map((e) =>
      getDisplayNameOfRole(getDefaultCodingOfSystemFromCodableConceptList([e])!)
    )
    .join(', ')

  if (isDietician(item)) {
    rolesString = 'Dietitian'
  }

  if (isNaturoPathForTable(item)) {
    rolesString = 'Naturopathy Doctor'
  }

  if (isTherapistForOnboarding(item)) {
    rolesString = 'Therapist'
  }

  return rolesString
}

export function getDisplayNameOfRole(code: R4.ICoding): string {
  switch (code.code) {
    case 'org-admin':
      return 'Organization Admin'
    case 'receptionist': {
      if (code.display === 'GRE' || code.display === 'GRM') return 'GRM'
      return 'Receptionist'
    }

    case 'unit-admin':
      return 'Unit Admin'
    case 'ayurveda':
      return 'Ayurveda (Vaidya)'
    // case '1255370008':
    //   return 'Doctor'

    default:
      return _.upperFirst(code.display ?? '')
  }
}

export function getUserRoles(
  userRole: R4.IPractitionerRole
): string[] | undefined {
  if (userRole) {
    const roles: R4.ICodeableConcept[] = userRole.code ?? []
    if (roles.length > 0) {
      if (roles[0] && roles[0].coding && roles[0].coding[0]) {
        return roles.map(
          (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
        )
      }
    }
  }

  return undefined
}

export function isCurrentUnitIsClinic(): boolean {
  const currentUnit = getUnitType()
  return currentUnit.toLowerCase() === 'clinic'
}

export function isCurrentUnitIsWellonessCenter(): boolean {
  const currentUnit = getUnitType()
  return currentUnit.toLowerCase() === 'wellness_center'
}

export function isCurrentUnitIsLab(): boolean {
  const currentUnit = getUnitType()
  return currentUnit.toLowerCase() === 'lab'
}
export function isCurrentUnitIsCollectionCenter(): boolean {
  const currentUnit = getUnitType()
  return currentUnit.toLowerCase() === 'collection_center'
}

export function getRoleCount(
  userRole: R4.IPractitionerRole[],
  role: string
): number {
  let rolesArray: string[] = []
  let count: number = 0
  for (let i = 0; i < userRole.length; i++) {
    if (userRole) {
      const roles: R4.ICodeableConcept[] = userRole[i].code ?? []
      if (roles.length > 0) {
        if (roles[0] && roles[0].coding && roles[0].coding[0]) {
          rolesArray = roles.map(
            (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
          )
          if (rolesArray.includes(role)) count += 1
        }
      }
    }
  }

  return count
}

export function getRoleCountForEnabled(
  userRole: R4.IPractitionerRole[],
  role: string
): number {
  let rolesArray: string[] = []
  let count: number = 0
  for (let i = 0; i < userRole.length; i++) {
    if (userRole) {
      const roles: R4.ICodeableConcept[] = userRole[i].code ?? []
      if (roles.length > 0) {
        if (roles[0] && roles[0].coding && roles[0].coding[0]) {
          rolesArray = roles.map(
            (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
          )
          if (rolesArray.includes(role)) count += 1
        }
      }
    }
  }

  return count
}

export function getRoleCountForDisabled(
  userRole: R4.IPractitionerRole[],
  role: string
): number {
  let rolesArray: string[] = []
  let count: number = 0
  for (let i = 0; i < userRole.length; i++) {
    if (userRole) {
      const roles: R4.ICodeableConcept[] = userRole[i].code ?? []
      if (roles.length > 0) {
        if (roles[0] && roles[0].coding && roles[0].coding[0]) {
          rolesArray = roles.map(
            (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
          )
          if (
            rolesArray.includes(role) &&
            userRole[i].active &&
            userRole[i].active === false
          ) {
            count += 1
          }
        }
      }
    }
  }

  return count
}

export function getRoleCountForUnitAdmin(
  userRole: R4.IPractitionerRole[]
): number {
  let rolesArray: string[] = []
  let count: number = 0
  for (let i = 0; i < userRole.length; i++) {
    if (userRole) {
      const roles: R4.ICodeableConcept[] = userRole[i].code ?? []
      if (roles.length > 0) {
        if (roles[0] && roles[0].coding && roles[0].coding[0]) {
          rolesArray = roles.map(
            (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
          )
          if (rolesArray.includes('unit-admin')) count += 1
        }
      }
    }
  }

  return count
}

export function getRoleCountForUnitAdminEnabled(
  userRole: R4.IPractitionerRole[]
): number {
  let rolesArray: string[] = []
  let count: number = 0
  for (let i = 0; i < userRole.length; i++) {
    if (userRole) {
      const roles: R4.ICodeableConcept[] = userRole[i].code ?? []
      if (roles.length > 0) {
        if (roles[0] && roles[0].coding && roles[0].coding[0]) {
          rolesArray = roles.map(
            (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
          )
          if (
            rolesArray.includes('unit-admin') &&
            userRole[i].active &&
            userRole[i].active === true
          )
            count += 1
        }
      }
    }
  }

  return count
}

export function getRoleCountForUnitAdminDisabled(
  userRole: R4.IPractitionerRole[]
): number {
  let rolesArray: string[] = []
  let count: number = 0
  for (let i = 0; i < userRole.length; i++) {
    if (userRole) {
      const roles: R4.ICodeableConcept[] = userRole[i].code ?? []
      if (roles.length > 0) {
        if (roles[0] && roles[0].coding && roles[0].coding[0]) {
          rolesArray = roles.map(
            (e) => getDefaultCodeOfSystemFromCodableConcept(e) ?? ''
          )
          if (
            rolesArray.includes('unit-admin') &&
            userRole[i].active &&
            userRole[i].active === false
          )
            count += 1
        }
      }
    }
  }

  return count
}

export function getUnitTypeForWp(org: R4.IOrganization): String {
  const currentUnitType: String =
    getCodeOfSystemFromCodableConceptList(
      org.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.code ?? ''
  return currentUnitType
}

export function isIPDApplicable(services: Services): boolean {
  if (services.ipdService && services.dayCareService && services.opdService) {
    return true
  }

  return false
}

export function isIPDDayCareApplicable(services: Services): boolean {
  if (
    services.ipdService &&
    services.dayCareService &&
    services.opdService === undefined
  ) {
    return true
  }
  return false
}

export function isOnlyIPDApplicable(services: Services): boolean {
  if (
    services.ipdService &&
    services.dayCareService === undefined &&
    services.opdService === undefined
  ) {
    return true
  }

  return false
}

export function isOnlyOPD(services: Services): boolean {
  if (
    services.ipdService === undefined &&
    services.dayCareService === undefined &&
    services.opdService
  ) {
    return true
  }

  return false
}

export function isOnlyOPDForMobile(services: UnitServices[]): boolean {
  if (services.length > 0) {
    for (let i = 0; i < services.length; i++) {
      const typeData =
        getCodeOfSystemFromCodableConceptList(
          services[i].orgs.type ?? [],
          'http://wellopathy.com/fhir/india/core/CodeSystem/org-type'
        )?.code ?? ''

      if (typeData === 'vendor-unit') {
        if (
          services[i].ipdService === undefined &&
          services[i].dayCareService === undefined &&
          services[i].opdService
        ) {
          return true
        }
      }
    }
  }
  return false
}
