/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  InputAdornment,
  Typography,
  TextField,
} from '@material-ui/core'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestForRateUpdate } from 'redux/practitioner/medicalProviderManagement/medicalServiceProviderManagementSlice'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTextField } from 'wello-web-components'

interface Props {
  practDetails: PractitionerWithRate
}

export const InPersonTile: React.FC<Props> = ({ practDetails }: Props) => {
  const medicalServiceProviderManagementSlice = useSelector(
    (state: RootState) => state.medicalServiceProviderManagementSlice
  )
  const { t } = useTranslation()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [price, SetPrice] = React.useState<number>(0)
  const [edit, setEdit] = React.useState<boolean>(false)

  useEffect(() => {
    if (practDetails.inPersonRate) {
      SetPrice(practDetails.inPersonRate)
    }
  }, [practDetails.inPersonRate])

  function handleSubmit() {
    let hasErrorsInData: boolean = false

    if (!price || price < 1 || price > 2000 || Number.isNaN(price)) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      dispatch(requestForRateUpdate(practDetails, price, 'In Person'))
    }
    setHasErrors(hasErrorsInData)
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      {practDetails && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' width='20%'>
            {' '}
            <Box width='100%' display='flex' flexDirection='column'>
              <WelloFormItemLabel title='RATE' />

              <TextField
                size='small'
                variant='outlined'
                type='tel'
                placeholder=''
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='flex-start'
                      >
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          ₹
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 1,
                    max: 10,
                  },
                }}
                value={price}
                onChange={(e) => {
                  const newValue = Number(e.target.value)

                  if (newValue > 0 && newValue <= 2000) {
                    setEdit(true)
                    SetPrice(newValue)
                  } else if (/^\d+$/.test(e.target.value)) {
                    dispatch(showErrorAlert(`Rate between 1 to 2000`))
                  }
                }}
              />
              {/* <WelloTextField
              title='RATE'
              textProps={{
                id: `rateId`,
                value: price,
                type: 'number',
                inputProps: {
                  max: 2000,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='flex-start'
                      >
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          ₹
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                },
                error: hasErrors && (price <= 1 || price > 2000),
                helperText:
                  hasErrors && (price <= 1 || price > 2000)
                    ? 'Rate between 1 to 2000'
                    : '',
                onChange: (changeCommission) => {
                  SetPrice(parseInt(changeCommission.target.value, 10))
                  setEdit(true)
                },
              }}
            /> */}
            </Box>
          </Box>
          <Box width='100%' display='flex' flexDirection='row'>
            <Box py={1}>
              <Button
                onClick={() => {
                  handleSubmit()
                }}
                variant='contained'
                disabled={
                  medicalServiceProviderManagementSlice.updatingPartnerDetail ||
                  practDetails.status !== 'Active' ||
                  edit === false
                }
                disableElevation
                color='primary'
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
            <Box py={1}>
              <Button
                variant='contained'
                color='primary'
                disabled={edit === false}
                onClick={() => {
                  SetPrice(practDetails.inPersonRate)
                  setEdit(false)
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
