import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import TextField from '@mui/material/TextField/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addProcedueDetailsIPD } from 'redux/ipd/procedure/addProcedureIpdSlice'
import { RootState } from 'redux/rootReducer'
import { isPhysiotherapist } from 'services/userDetailsService'
import {
  getOrderStatusText,
  isAfterTherapyFinal,
  isAfterVital,
  isCurrentDateForTherapy,
  isPastDateForTherapy,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { AlertDialog } from 'views/components/LeftMenu/alertDialog'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'
import { TherapistSearch } from './therapistSearch'
import { TherapyDataDisplay } from './therapyDataFile'

interface Props {
  open: boolean
  onClose: () => void
  onLabOrderCreated: () => void
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  treatmentPlanData: TreatmentPlanData
}
export const TherapyPerform: React.FC<Props> = ({
  open,
  onClose,
  onLabOrderCreated,
  fhirAppointmentDetails,
  treatmentPlanData,
}) => {
  const addProcedureDetailsSlice = useSelector(
    (state: RootState) => state.addProcedureDetailsSlice
  )

  const cancelTherapyDetailsSlice = useSelector(
    (state: RootState) => state.cancelTreatmentSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedPractWithRole, setSelecctedPractWithRole] =
    useState<PractitionerWithRole[]>()
  const [startTime, setStartedTime] = useState<string>()
  const currentDate = moment(treatmentPlanData.date).format('YYYY-MM-DD')
  const timeAndDate = moment(
    `${currentDate}  ${moment(treatmentPlanData.startTime, ['hh:mm A']).format(
      'HH:mm'
    )}`
  ).format('YYYY-MM-DD HH:mm')

  const endTimeDate = moment(
    `${currentDate} ${
      isAfterVital(
        moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
      )
        ? isCurrentDateForTherapy(
            moment(treatmentPlanData.date).format('YYYY-MM-DD')
          ) === 0
          ? moment().format('HH:mm')
          : moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
        : moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
    }`
  ).format('YYYY-MM-DD HH:mm')

  const [showTextForSlot, setShowTextForSlot] = useState<boolean>(false)

  const time1 = moment(timeAndDate).format('YYYY-MM-DD HH:mm:ss')

  const status = moment(time1).subtract(1, 'hour')

  let minDate = new Date(
    0,
    0,
    parseInt(moment(timeAndDate).format('mm'), 10),
    parseInt(moment(timeAndDate).format('HH'), 10)
  )

  let minDateNew = new Date(
    0,
    0,
    parseInt(moment(status).format('mm'), 10),
    parseInt(moment(status).format('HH'), 10)
  )

  let minDateNewEnd = new Date(
    `${currentDate} ${moment(treatmentPlanData.startTime, ['hh:mm A']).format(
      'HH:mm'
    )}`
  )
  let maxDate = new Date(
    `${currentDate} ${
      isAfterVital(
        moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
      )
        ? moment().format('HH:mm')
        : moment(treatmentPlanData.endTime, ['hh:mm A']).format('HH:mm')
    }`
  )

  const [currentTime, setCurrentTime] = useState<Date>(new Date(timeAndDate))

  const [currentEndTime, setCurrentEndTime] = useState<Date>(
    new Date(endTimeDate)
  )

  const [endTime, setEndTime] = useState<string>()
  const [notes, setNotes] = useState<string>()

  useEffect(() => {
    if (addProcedureDetailsSlice.additionSuccessful) {
      if (onLabOrderCreated) {
        onLabOrderCreated()

        resetForm()
      }
    }
  }, [
    addProcedureDetailsSlice.additionSuccessful,
    addProcedureDetailsSlice,
    onLabOrderCreated,
  ])

  //   useEffect(() => {
  //     if (cancelTherapyDetailsSlice.updateSuccessful) {
  //       if (onLabOrderCreated) {
  //         dispatch(resetProdcedureState())
  //         resetForm()
  //         onLabOrderCreated()
  //       }
  //     }
  //   }, [cancelTherapyDetailsSlice, onLabOrderCreated])

  function resetForm() {
    setStartedTime(undefined)
    setEndTime(undefined)
    minDateNew = new Date()
    minDateNewEnd = new Date()
    minDate = new Date()
    maxDate = new Date()
    setNotes(undefined)
    setSelecctedPractWithRole(undefined)
    // dispatch(resetCancelState())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Mark Therapy as Complete</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='column' width='100%'>
              <WelloFormItemLabel title='Patient' />
              <PatientTile patient={fhirAppointmentDetails.patient} />
            </Box>

            {treatmentPlanData && (
              <Box display='flex' flexDirection='column' width='100%'>
                <TherapyDataDisplay treatmentPlanData={treatmentPlanData} />
              </Box>
            )}

            {treatmentPlanData && treatmentPlanData.treatmentPlan && (
              <Box display='flex' flexDirection='column' width='100%'>
                {getOrderStatusText(treatmentPlanData.treatmentPlan) ===
                  'Scheduled' && (
                  <Box>
                    {treatmentPlanData && !treatmentPlanData.procedure && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        py={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          py={1}
                        >
                          <Box px={0.5} width='40%'>
                            {treatmentPlanData && (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <WelloFormItemLabel title='Actual Start Time' />
                                <TimePicker
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      id='actualStartTime'
                                    />
                                  )}
                                  value={currentTime}
                                  onChange={(newValue) => {
                                    if (newValue !== null)
                                      setCurrentTime(newValue)
                                    setStartedTime(
                                      moment(newValue).format('HH:mm')
                                    )
                                  }}
                                  minTime={minDateNew}
                                />
                              </LocalizationProvider>
                            )}
                            {/* <WelloTextField
                              title='Actual Start Time'
                              textProps={{
                                id: `actor_email_unit`,
                                value: startTime,
                                disabled: addProcedureDetailsSlice.adding,
                                inputProps: {
                                  min: '05:00',
                                  max: '08:00',
                                  required: true,
                                },
                                type: 'time',
                                onChange: (changeData) => {
                                  setStartedTime(changeData.target.value)
                                },
                              }}
                            /> */}
                          </Box>

                          <Box width='40%' paddingLeft={4}>
                            {treatmentPlanData && (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <WelloFormItemLabel title='Actual End Time' />
                                <TimePicker
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      id='actualEndTime'
                                    />
                                  )}
                                  value={currentEndTime}
                                  onChange={(newValue) => {
                                    if (newValue !== null)
                                      setCurrentEndTime(newValue)
                                    setEndTime(moment(newValue).format('HH:mm'))
                                  }}
                                  minTime={minDateNewEnd}
                                />
                              </LocalizationProvider>
                            )}
                          </Box>
                        </Box>

                        <Box px={0.5}>
                          <Box display='flex' flexGrow={1} p={0.5}>
                            <TherapistSearch
                              onPractionerSelected={(
                                practionerList: PractitionerWithRole[]
                              ) => {
                                setSelecctedPractWithRole(practionerList)
                              }}
                            />
                          </Box>
                        </Box>
                        <Box px={0.5}>
                          <Box
                            display='flex'
                            flexGrow={1}
                            alignItems='flex-end'
                            justifyContent='center'
                            p={0.5}
                          >
                            <WelloTextField
                              title='Notes'
                              textProps={{
                                id: `actor_name_unit_${t}`,
                                value: notes,
                                disabled: addProcedureDetailsSlice.adding,
                                multiline: true,
                                minRows: 4,
                                onChange: (changedValue) => {
                                  setNotes(changedValue.target.value)
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <AlertDialog
            open={showTextForSlot}
            onClose={() => {
              setShowTextForSlot(false)
            }}
            notesData='Please choose slot in given time frame'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {addProcedureDetailsSlice.adding && <CircularProgress size={30} />}
        <Button
          onClick={() => {
            onClose()
            resetForm()
          }}
          id='cancel'
          variant='outlined'
          style={{ color: ' #979797;' }}
          disableElevation
        >
          Cancel
        </Button>
        {!isPhysiotherapist() && (
          <Button
            onClick={() => {
              if (treatmentPlanData) {
                dispatch(
                  addProcedueDetailsIPD(
                    fhirAppointmentDetails,
                    treatmentPlanData,
                    startTime,
                    endTime,
                    notes,
                    selectedPractWithRole
                  )
                )
              }
            }}
            id='completeTherapy'
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              treatmentPlanData
                ? !isPastDateForTherapy(
                    moment(treatmentPlanData.date).format('YYYY-MM-DD')
                  ) ||
                  !isAfterTherapyFinal(
                    moment(treatmentPlanData.endTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(treatmentPlanData.date).format('YYYY-MM-DD')
                  ) ||
                  selectedPractWithRole === undefined ||
                  selectedPractWithRole.length === 0 ||
                  addProcedureDetailsSlice.adding
                : true
            }
          >
            Submit
          </Button>
        )}

        {isPhysiotherapist() && (
          <Button
            onClick={() => {
              if (treatmentPlanData) {
                dispatch(
                  addProcedueDetailsIPD(
                    fhirAppointmentDetails,
                    treatmentPlanData,
                    startTime,
                    endTime,
                    notes,
                    selectedPractWithRole
                  )
                )
              }
            }}
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              treatmentPlanData
                ? !isPastDateForTherapy(
                    moment(treatmentPlanData.date).format('YYYY-MM-DD')
                  ) ||
                  !isAfterTherapyFinal(
                    moment(treatmentPlanData.endTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(treatmentPlanData.date).format('YYYY-MM-DD')
                  )
                : true
            }
            id='completeTherapyPT'
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
