import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import moment from 'moment'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestForClinicalImpressions } from 'redux/fhirMedicalResources/clinicalImpression/clinicalImpressionSlice'
import { getTaskStatus } from 'redux/patient/addPatient/addPatientSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { requestForSubscriptionStatusOfPatient } from 'redux/subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isDoctor, isMedicalServiceProvider } from 'services/userDetailsService'
import { addAndGetEncounterDetailsOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import {
  getDefaultCodeOfSystemFromCodableConceptList,
  getNameFromHumanName,
  getNameOfPatient,
  getValueCodingStringFromExtension,
} from 'utils/fhirResourcesHelper'
import {
  getEncounterFromBundle,
  getExpandedAppointmentFromBundle,
  getExpandedAppointmentFullDetailFromBundle,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getTransactionBodyForCompleteAppointment } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
// import { getNameOfPatient } from 'wello-web-components'
// import {
//     getNameFromHumanName,
//     getNameOfPatient,
//   } from 'utils/fhirResourcesHelper'
import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'
import { AppointmentHandlerStatus } from './appointmentHandlerStatus'

const initialState: AppointmentHandlerStatus = {
  fetchingAppointmentDetails: false,
  enableEndAppointmentButton: false,
  errorWhileUpdatingAppointment: false,
  appointmentFetched: false,
  appointmentDetailsUpdated: false,
  errorWhileFetchingAppointment: false,
  updatingAppointment: false,
  enableStartVideoButton: false,
  endingAppointment: false,
  endedAppointment: false,
  enableReportDisplay: false,
  showResendOption: false,
}

const appointmentHandlerSlice = createSlice({
  name: 'appointmentViewHandler',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AppointmentHandlerStatus>) {
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingAppointment =
        action.payload.errorWhileUpdatingAppointment
      state.fetchingAppointmentDetails =
        action.payload.fetchingAppointmentDetails
      state.appointmentDetails = action.payload.appointmentDetails
      state.appointmentFetched = action.payload.appointmentFetched
      state.enableEndAppointmentButton =
        action.payload.enableEndAppointmentButton
      state.appointmentId = action.payload.appointmentId
      state.endedAppointment = action.payload.endedAppointment
      state.endingAppointment = action.payload.endingAppointment
      state.enableReportDisplay = action.payload.enableReportDisplay
      state.showResendOption = action.payload.showResendOption
    },
    updatedDetails(state, action: PayloadAction<FhirAppointmentDetail>) {
      state.appointmentDetails = action.payload
    },
  },
})

export const fetchAppointmentDetails =
  (appointmentId: string, encounterNeeded?: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: true,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
      enableReportDisplay: false,
      showResendOption: false,
    }
    dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _id: appointmentId,
        _revinclude: 'Encounter:appointment',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Appointment?_include:iterate:Appointment:supportingInformation&_revinclude:iterate=PaymentReconciliation:payment-reconciliation-app',
        appointmentId,
        searchParameters
      )

      //   const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      //     const appointmentResponse: R4.IBundle = resp.right
      if (response?.total && response?.total > 0) {
        let fhirAppointments: FhirAppointmentDetail[] =
          getExpandedAppointmentFromBundle(response)

        const statusData = await getTaskStatus(fhirAppointments[0].patient)

        if (statusData === true) {
          state.showResendOption = false
        } else {
          state.showResendOption = true
        }

        const respData = await getEncounter(
          fhirAppointments[0].appointment.id ?? ''
        )

        if (respData === '0' && encounterNeeded) {
          if (fhirAppointments[0].encounter === undefined) {
            if (isMedicalServiceProvider()) {
              const encounter = await addAndGetEncounterDetailsOfAppointment(
                fhirAppointments[0]
              )
              fhirAppointments = [{ ...fhirAppointments[0], encounter }]
            }
          }
        }

        if (fhirAppointments[0].encounter) {
          const data = await getReports(fhirAppointments[0])
          if (data.reports || data.diagnosticReport) {
            state.enableReportDisplay = true
          }
          state.appointmentId = appointmentId
          state.appointmentFetched = true
          state.fetchingAppointmentDetails = false
          state.enableEndAppointmentButton = fhirAppointments[0].encounter
            ? fhirAppointments[0].encounter?.status !==
              R4.EncounterStatusKind._finished
            : false
          state.enableStartVideoButton = true
          state.appointmentDetails = fhirAppointments[0]

          dispatch(
            requestForSubscriptionStatusOfPatient(
              fhirAppointments[0].patient.id!
            )
          )
          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        } else {
          state.errorWhileFetchingAppointment = true
          state.fetchingAppointmentDetails = false
          state.errorReason = 'Appointment not available'
          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        }
      } else {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false
        state.errorReason = 'Appointment not available'
        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
      state.errorWhileFetchingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details`
      dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    }
  }

async function getReports(
  appointment: FhirAppointmentDetail
): Promise<FhirAppointmentDetail> {
  const appointmentData: FhirAppointmentDetail = {
    ...appointment,
  }
  const reportsFiles: R4.IDocumentReference[] = []
  const diagnosticReport: R4.IDiagnosticReport[] = []
  let referredEncounter: R4.IEncounter[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  if (appointmentData.appointment.basedOn) {
    if (appointmentData.appointment.basedOn[0].reference) {
      const serviceRequestData =
        appointmentData.appointment.basedOn[0].reference.split('/')

      const newResponse =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
          `/ServiceRequest?_id=${serviceRequestData[1]}&_include=ServiceRequest:encounter`,
          ''
        )
      if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
        referredEncounter = getEncounterFromBundle(newResponse as R4.IBundle)
        if (referredEncounter.length > 0) {
          const searchParametersOld: any = {
            encounter: referredEncounter[0].id ?? '',
            _sort: '-_lastUpdated',
          }
          let reportResponse: any

          reportResponse =
            await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
              '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
              referredEncounter[0].appointment
                ? referredEncounter[0].appointment[0].id ?? ''
                : '',
              searchParametersOld
            )

          logger.info('Org  Response')
          logger.info(reportResponse)
          if (
            reportResponse &&
            reportResponse.entry &&
            reportResponse.entry.length > 0
          ) {
            for (let i = 0; i < reportResponse.entry.length; i++) {
              diagnosticReport.push(
                reportResponse.entry[i].resource as R4.IDiagnosticReport
              )
            }
          } else {
            reportResponse =
              await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
                '/DocumentReference?type=11502-2&status=current',
                referredEncounter[0].appointment
                  ? referredEncounter[0].appointment[0].id ?? ''
                  : '',
                searchParametersOld
              )

            if (
              reportResponse &&
              reportResponse.entry &&
              reportResponse.entry.length > 0
            ) {
              for (let i = 0; i < reportResponse.entry.length; i++) {
                reportsFiles.push(
                  reportResponse.entry[i].resource as R4.IDocumentReference
                )
              }
            }
          }
        }
      }
    }
  }
  if (appointmentData.encounter) {
    const searchParameters: any = {
      encounter: appointment.encounter ? appointment.encounter.id ?? '' : '',
      _sort: '-_lastUpdated',
    }
    let response: any

    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
        '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
        appointment.appointment.id!,
        searchParameters
      )

    logger.info('Org  Response')
    logger.info(response)

    if (response && response.entry && response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        diagnosticReport.push(
          response.entry[i].resource as R4.IDiagnosticReport
        )
      }
    } else {
      response =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
          '/DocumentReference?type=11502-2&status=current',
          appointment.appointment.id!,
          searchParameters
        )

      if (response && response.entry && response.entry.length > 0) {
        for (let i = 0; i < response.entry.length; i++) {
          reportsFiles.push(response.entry[i].resource as R4.IDocumentReference)
        }
      }
    }
  }

  if (appointment.appointment.id) {
    const searchParametersNew: any = {
      _sort: '-_lastUpdated',
    }

    const docResponseList =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
        `/DocumentReference?related=Appointment/${appointment.appointment.id}&type=11502-2&status=current`,
        appointment.appointment.id!,
        searchParametersNew
      )

    if (
      docResponseList &&
      docResponseList.entry &&
      docResponseList.entry.length > 0
    ) {
      for (let i = 0; i < docResponseList.entry.length; i++) {
        reportsFiles.push(
          docResponseList.entry[i].resource as R4.IDocumentReference
        )
      }
    }
  }

  if (reportsFiles.length > 0) {
    appointmentData.reports = reportsFiles
  }

  if (diagnosticReport.length > 0) {
    appointmentData.diagnosticReport = diagnosticReport.sort((a, b) =>
      a.code &&
      a.code.coding &&
      a.code.coding[0]!.display! > b.code &&
      b.code.coding &&
      b.code.coding[0]!.display!
        ? 1
        : b.code &&
          b.code.coding &&
          b.code.coding[0]!.display! > a.code &&
          a.code.coding &&
          a.code.coding[0]!.display!
        ? -1
        : 0
    )
  }

  return appointmentData
}

export const updateAppointmentDetails =
  (appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _id: appointmentId,
        _revinclude: 'Encounter:appointment',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Appointment?',
        appointmentId,
        searchParameters
      )

      //   const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      //     const appointmentResponse: R4.IBundle = resp.right
      if (response?.total && response?.total > 0) {
        const fhirAppointments: FhirAppointmentDetail[] =
          getExpandedAppointmentFromBundle(response)

        dispatch(
          appointmentHandlerSlice.actions.updatedDetails(fhirAppointments[0])
        )
      } else {
        dispatch(showErrorAlert('Error while updating appointment status'))
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
    }
  }

export const fetchAppointmentDetailsAfterComplete =
  (appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: true,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
      enableReportDisplay: false,
      showResendOption: false,
    }
    dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _id: appointmentId,
        _revinclude: 'Encounter:appointment',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Appointment',
        appointmentId,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false

        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirAppointmentFullDetail[] =
            getExpandedAppointmentFullDetailFromBundle(appointmentResponse)
          const appointmentDetail: FhirAppointmentFullDetail =
            fhirAppointments[0]

          if (
            appointmentDetail.encounter === undefined ||
            appointmentDetail.encounter?.id === undefined ||
            appointmentDetail.encounter?.id?.length === 0
          ) {
            const encounter: R4.IEncounter | undefined =
              await addAndGetEncounterDetailsOfAppointment(appointmentDetail)

            appointmentDetail.encounter = encounter
          }

          //   const appointmentType =getAppointmentDisplayCode(fhirAppointments[1])

          state.appointmentFetched = true
          state.fetchingAppointmentDetails = false
          state.enableEndAppointmentButton = appointmentDetail.encounter
            ? fhirAppointments[0].encounter?.status !==
              R4.EncounterStatusKind._finished
            : false
          state.enableStartVideoButton = true
          state.appointmentFullDetails = appointmentDetail
          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        } else {
          state.errorWhileFetchingAppointment = true
          state.fetchingAppointmentDetails = false
          state.errorReason = 'Appointment not available'
          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        }
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
      state.errorWhileFetchingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details`
      dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    }
  }

export const updateAppointmentStatus =
  (appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: false,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
      enableReportDisplay: false,
      showResendOption: false,
    }
    dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _id: appointmentId,
        _revinclude: 'Encounter:appointment',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Appointment',
        appointmentId,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false

        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirAppointmentDetail[] =
            getExpandedAppointmentFromBundle(appointmentResponse)

          state.appointmentFetched = true
          state.fetchingAppointmentDetails = false
          state.enableEndAppointmentButton = fhirAppointments[0].encounter
            ? fhirAppointments[0].encounter?.status !==
              R4.EncounterStatusKind._finished
            : false

          state.appointmentDetails = fhirAppointments[0]
          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        } else {
          state.errorWhileFetchingAppointment = true
          state.fetchingAppointmentDetails = false
          state.errorReason = 'Appointment not available'
          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        }
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
      state.errorWhileFetchingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details`
      dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    }
  }

export const addClinicalNotes =
  (appointment: FhirAppointmentDetail, doctorNotes: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: false,
      appointmentDetails: appointment,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: true,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: true,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
      enableReportDisplay: false,
      showResendOption: false,
    }
    dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle = createBundleObjectForClinicalImpression(
        appointment,
        doctorNotes
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          if (appointment.patient.id) {
            dispatch(
              requestForClinicalImpressions(
                appointment.patient.id,
                appointment.appointment.id!
              )
            )
          }
          state.appointmentDetailsUpdated = true
          state.fetchingAppointmentDetails = false
          state.enableEndAppointmentButton = true

          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
          return
        }
        state.errorWhileUpdatingAppointment = true
        state.fetchingAppointmentDetails = false

        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      } else {
        state.errorWhileUpdatingAppointment = true
        state.fetchingAppointmentDetails = false

        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error(error)
      state.errorWhileUpdatingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details}`
      dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    }
  }

export const endClinicVisit =
  (appointment: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: false,
      appointmentDetails: appointment,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: true,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: true,
      endedAppointment: false,
      enableStartVideoButton: false,
      enableReportDisplay: false,
      showResendOption: false,
    }
    dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _id: appointment.appointment.id,
        _revinclude: 'Encounter:appointment',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Appointment',
        appointment.appointment.id!,
        searchParameters
      )

      if (response?.total && response?.total > 0) {
        const fhirAppointments: FhirAppointmentDetail[] =
          getExpandedAppointmentFromBundle(response)

        const bundleObject: R4.IBundle | undefined =
          createBundleObjectForEndVisit(fhirAppointments[0])
        const networkResponse = await fhirClient.doCreateFHIRTransaction(
          '',
          bundleObject
        )
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(networkResponse)

        if (relatedFhirDecodeRes._tag === 'Left') {
          state.errorWhileFetchingAppointment = true
          state.fetchingAppointmentDetails = false

          dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
        } else if (
          appointment.paymentReconcilation &&
          appointment.appointment.basedOn === undefined
        ) {
          const code =
            getValueCodingStringFromExtension(
              appointment.paymentReconcilation.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType'
            ) ?? ''

          if (
            code.length > 0 &&
            code === 'prepaid' &&
            appointment.paymentReconcilation.outcome === 'complete'
          ) {
            const transactionResp: boolean = await completePayment(
              appointment.patient ? appointment.patient.id ?? '' : '',
              appointment.paymentReconcilation
                ? appointment.paymentReconcilation.id ?? ''
                : '',
              appointment.appointment.id ?? '',
              appointment.paymentReconcilation
            )

            if (transactionResp) {
              const newStatePayment: AppointmentHandlerStatus = {
                fetchingAppointmentDetails: false,
                appointmentDetails: appointment,
                errorWhileUpdatingAppointment: false,
                appointmentFetched: true,
                appointmentDetailsUpdated: false,
                enableReportDisplay: false,
                errorWhileFetchingAppointment: false,
                updatingAppointment: false,
                enableEndAppointmentButton: false,
                endingAppointment: false,
                endedAppointment: true,
                enableStartVideoButton: false,
                showResendOption: false,
              }
              dispatch(requestAppointmentsCountForToday())
              dispatch(
                appointmentHandlerSlice.actions.updatedStatus(newStatePayment)
              )
            } else {
              const newStateData: AppointmentHandlerStatus = {
                fetchingAppointmentDetails: false,
                appointmentDetails: appointment,
                errorWhileUpdatingAppointment: false,
                appointmentFetched: true,
                enableReportDisplay: false,
                appointmentDetailsUpdated: false,
                errorWhileFetchingAppointment: false,
                updatingAppointment: false,
                enableEndAppointmentButton: false,
                endingAppointment: false,
                endedAppointment: true,
                enableStartVideoButton: false,
                showResendOption: false,
              }
              dispatch(requestAppointmentsCountForToday())
              dispatch(
                appointmentHandlerSlice.actions.updatedStatus(newStateData)
              )
            }
          }
          const newState: AppointmentHandlerStatus = {
            fetchingAppointmentDetails: false,
            appointmentDetails: appointment,
            errorWhileUpdatingAppointment: false,
            appointmentFetched: true,
            appointmentDetailsUpdated: false,
            errorWhileFetchingAppointment: false,
            updatingAppointment: false,
            enableEndAppointmentButton: false,
            endingAppointment: false,
            endedAppointment: true,
            enableStartVideoButton: false,
            enableReportDisplay: false,
            showResendOption: false,
          }
          dispatch(requestAppointmentsCountForToday())
          dispatch(appointmentHandlerSlice.actions.updatedStatus(newState))
        } else {
          const newState: AppointmentHandlerStatus = {
            fetchingAppointmentDetails: false,
            appointmentDetails: appointment,
            errorWhileUpdatingAppointment: false,
            appointmentFetched: true,
            appointmentDetailsUpdated: false,
            errorWhileFetchingAppointment: false,
            updatingAppointment: false,
            enableEndAppointmentButton: false,
            endingAppointment: false,
            endedAppointment: true,
            enableStartVideoButton: false,
            enableReportDisplay: false,
            showResendOption: false,
          }
          dispatch(requestAppointmentsCountForToday())
          dispatch(appointmentHandlerSlice.actions.updatedStatus(newState))
        }
      } else {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false
        state.errorReason = 'Appointment not available'
        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error(error)
      state.errorWhileUpdatingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details}`
      dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    }
  }

export const startVideoAppointment =
  (appointment: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: true,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
      enableReportDisplay: false,
      showResendOption: false,
    }
    dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        `/Appointment/${appointment.appointment.id!}/$create-meeting-room`
      )
      const appData: FhirAppointmentDetail = appointment
      if (response.parameter.length > 0) {
        appData.roomId = response.parameter[1].valueStringp
        appData.token = response.parameter[2].valueString
        state.appointmentFetched = true
        state.fetchingAppointmentDetails = false
        state.enableEndAppointmentButton = appData.encounter
          ? appData.encounter?.status !== R4.EncounterStatusKind._finished
          : false
        state.enableStartVideoButton = true
        state.appointmentDetails = appData
        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      } else {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false
        state.errorReason = 'Appointment not available'
        dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error(error)
      state.errorWhileUpdatingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details}`
      dispatch(appointmentHandlerSlice.actions.updatedStatus(state))
    }
  }

function createBundleObjectForClinicalImpression(
  appointment: FhirAppointmentDetail,
  note: string
): R4.IBundle {
  const encounter: R4.IEncounter = {
    resourceType: 'Encounter',
    class: {
      system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
      code: 'AMB',
      display: 'ambulatory',
    },
    status: R4.EncounterStatusKind._inProgress,
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },
    appointment: [
      {
        reference: `${appointment.appointment.resourceType}/${appointment.appointment.id}`,
        id: appointment.appointment.id,
        type: appointment.appointment.resourceType,
      },
    ],
    participant: [
      {
        type: [
          {
            text: 'primary performer',
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: {
          display: getNameFromHumanName(
            appointment.practitionerDetail.practitioner?.name ?? []
          ),
          reference: `${appointment.practitionerDetail.practitioner?.resourceType}/${appointment.practitionerDetail.practitioner?.id}`,
          id: appointment.practitionerDetail.practitioner?.id,
          type: appointment.practitionerDetail.practitioner?.resourceType,
        },
      },
      {
        type: [
          {
            text: 'primary performer',
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: {
          reference: `${appointment.practitionerDetail.practitionerRole?.resourceType}/${appointment.practitionerDetail.practitionerRole?.id}`,
          id: appointment.practitionerDetail.practitionerRole?.id,
          type: appointment.practitionerDetail.practitionerRole?.resourceType,
        },
      },
    ],
  }
  const fullUrlEncounter: string = 'urn:uuid:1232323232324'
  const matchStringEncounter: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const matchStringClinicalImpression: string = `${'ClinicalImpression?encounter='}${fullUrlEncounter}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: fullUrlEncounter,
        request: {
          url: matchStringEncounter,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
      {
        fullUrl: 'urn:uuid:1232323232325',
        request: {
          url: matchStringClinicalImpression,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: {
          resourceType: 'ClinicalImpression',
          status: 'completed',
          encounter: {
            reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
            type: encounter.resourceType,
          },
          assessor: {
            reference: `${appointment.practitionerDetail.practitionerRole?.resourceType}/${appointment.practitionerDetail.practitionerRole?.id}`,
            id: appointment.practitionerDetail.practitionerRole?.id,
            type: appointment.practitionerDetail.practitionerRole?.resourceType,
          },
          subject: {
            display: getNameOfPatient(appointment.patient),
            reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            id: appointment.patient.id,
            type: appointment.patient.resourceType,
          },
          summary: note,
          date: moment(new Date()).format(),
        },
      },
    ],
  }

  return requestBundle
}

function createBundleObjectForEndVisit(
  appointment: FhirAppointmentDetail
): R4.IBundle | undefined {
  if (appointment.encounter) {
    const { encounter } = appointment
    encounter.status = R4.EncounterStatusKind._finished
    const appointmentObj: R4.IAppointment = appointment.appointment
    appointmentObj.status = R4.AppointmentStatusKind._fulfilled
    const appointmentMatchString: string = `W/${JSON.stringify(
      appointmentObj.meta?.versionId ?? ' '
    )}`
    const encounterMatchString: string = `W/${JSON.stringify(
      encounter.meta?.versionId ?? ' '
    )}`
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${encounter.resourceType}/${encounter.id}`,
          request: {
            ifMatch: encounterMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${encounter.resourceType}/${encounter.id}`,
          },
          resource: encounter,
        },
        {
          fullUrl: `${appointmentObj.resourceType}/${appointmentObj.id}`,
          request: {
            ifMatch: appointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${appointmentObj.resourceType}/${appointmentObj.id}`,
          },
          resource: appointmentObj,
        },
      ],
    }
    return requestBundle
  }
  return {
    resourceType: 'Bundle',
  }
}

async function getEncounter(appointmentId: string): Promise<string> {
  let count: string = '0'

  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Encounter?appointment=Appointment/${appointmentId}`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          if (orgResponse.entry.length > 0) {
            count = '1'
          }
        }
      }
    }
  }
  return count
}

async function completePayment(
  patientId: string,
  paymentReconciliationId: string,
  appointmentId: string,
  paymentReconciliation?: R4.IPaymentReconciliation
): Promise<boolean> {
  const requestBody: R4.IParameters = getTransactionBodyForCompleteAppointment(
    patientId,
    paymentReconciliationId,
    appointmentId,
    paymentReconciliation
  )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/complete`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 200) {
      return true
    }
  }

  return false
}

export default appointmentHandlerSlice.reducer
