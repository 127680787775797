import Paper from '@material-ui/core/Paper'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Box,
  Typography,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kPrimaryMain, kSelectedTabBackground } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'
import Carousel from 'react-simply-carousel'

import { useTranslation } from 'react-i18next'
import {
  ArrowBackIos,
  ArrowBackOutlined,
  ArrowForwardIos,
} from '@material-ui/icons'
import moment from 'moment'
import { Icon } from '@iconify/react'

export interface ToggleOption {
  value: string
  displayText: string
}

export interface ToggleOptionProps {
  options: ToggleOption[]
  preSelectedValue?: string
  onSelectionChanged: (option: string) => void
  menuLabel?: string

  id?: string
}

function getValueToIndex(selValue: string, options: ToggleOption[]) {
  return options.findIndex((option) => option.value === selValue)
}

export default function DateSliderSelector(props: ToggleOptionProps) {
  const [selectedIndex, setSelectedIndex] = React.useState(
    props.preSelectedValue
      ? getValueToIndex(
          props.preSelectedValue ?? props.options[0].value,
          props.options
        )
      : 0
  )

  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')

  const handleAlignment = (index: number) => {
    if (index !== null) {
      props.onSelectionChanged(props.options[index].value)
      setSelectedIndex(index)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
  }, [])

  return (
    <Carousel
      preventScrollOnSwipe
      activeSlideIndex={selectedIndex}
      onRequestChange={(index) => {
        handleAlignment(index)
      }}
      infinite={false}
      forwardBtnProps={{
        children: (
          <Icon
            icon='tabler:chevron-right'
            style={{
              fontSize: '24px',
              color: '#1C274C',
              opacity: selectedIndex === props.options.length - 1 ? 0.5 : 1,
            }}
          />
        ),
        style: {
          border: 'none',
          background: 'none',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          justifySelf: 'center',
          alignSelf: 'center',
          textAlign: 'center',
        },
      }}
      backwardBtnProps={{
        children: (
          <Icon
            icon='tabler:chevron-left'
            style={{
              fontSize: '24px',
              color: '#1C274C',
              opacity: selectedIndex === 0 ? 0.5 : 1,
            }}
          />
        ),
        style: {
          border: 'none',
          background: 'none',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          justifySelf: 'center',
          alignSelf: 'center',
          textAlign: 'center',
        },
      }}
      dotsNav={{
        show: false,
      }}
      itemsToShow={1}
      speed={400}
      centerMode
    >
      {props.options.map((item, index) => (
        <Box
          key={item.value}
          display='flex'
          flexDirection='column'
          alignContent='center'
          justifyContent='center'
          minWidth='90px'
        >
          {['yesterday', 'today', 'tomorrow'].includes(
            item.displayText.toLowerCase()
          ) && (
            <Typography
              variant='subtitle2'
              style={{
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 500,
                color: '#19274F',
              }}
            >
              {item.displayText}
            </Typography>
          )}
          <Typography
            variant='subtitle2'
            style={{
              textAlign: 'center',
              fontSize: ['yesterday', 'today', 'tomorrow'].includes(
                item.displayText.toLowerCase()
              )
                ? '12px'
                : '14px',
              fontWeight: 400,
              color: ['yesterday', 'today', 'tomorrow'].includes(
                item.displayText.toLowerCase()
              )
                ? '#8C9097'
                : '#19274F',
              textTransform: 'uppercase',
            }}
          >
            {moment(item.value).format('DD MMM YYYY')}
          </Typography>
        </Box>
      ))}
    </Carousel>
  )
}
