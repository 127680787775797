/* eslint-disable no-irregular-whitespace */
import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { isPhysiotherapist } from 'services/userDetailsService'
import { PurposeOfUse } from 'models/purposeOfUse'
import { SystemDiagnosisManagement } from '../consultation/assessment/gastroIntestinalSystemDiagnosisNew'
import { LabAndRadiologyResults } from '../consultation/assessment/labReportsAndDiagnosits'
import { OtherSystemDiagnosis } from '../consultation/assessment/otherSystemsDiagnosis'
import { SignaturesOfIPD } from '../consultation/signatureOfIPD'
import { Assessment } from './assessment'
import { DietCard } from './diet/dietCard'
import { SubstanceCard } from './substance/substanceCard'
import { TreatmentCard } from './treatment/treatmentCard'
import { CVSExaminationsAccordionForEncounter } from '../consultation/assessment/systemicExamination/CVSExaminationsAccordianForEncounter'
import { CNSExaminationsListOfEncounter } from '../consultation/assessment/systemicExamination/cnsExaminations/CNSExaminationsListOfEncounter'
import { GISExaminationsAccordionForEncounter } from '../consultation/assessment/systemicExamination/GISExaminationsAccordionForEncounter'
import { RSExaminationsAccordionOfEncounter } from '../consultation/assessment/systemicExamination/RSExaminationsAccordionForEncounter'
import { CNSExaminationsAccordionForEncounter } from '../consultation/assessment/systemicExamination/CNSExaminationsAccordionForEncounter'
import { AssessmentCaptureDetails } from '../consultation/assessment/assessmentCaptureDetails'

export const Accordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      paddingBottom: '8px',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'lightGrey',
    borderRadius: 2,
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
    margin: '0px',
    padding: '0px',
    paddingRight: '16px',
  },
  content: {
    margin: '0px',
    padding: '0px',
    '&$expanded': {
      margin: '0px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

interface AssessmentPartOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const ActiveAssementDetailsForMedicalRole: React.FC<AssessmentPartOfConsultationProps> =
  ({ fhirAppointmentDetails }: AssessmentPartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    const [selectedSystem, setSelectedSystem] = useState<string>()

    const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
      ? fhirAppointmentDetails.mainServiceRequest.code.coding
        ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
        : ''
      : ''
    const consultation: boolean = appointmentType === '33022008'

    return (
      <Grid container direction='column'>
        <Box display='flex' flexDirection='column'>
          {!isPhysiotherapist() && (
            <Box>
              <Typography variant='h5' color='primary'>
                {' '}
                Systemic Examination
              </Typography>
            </Box>
          )}
          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box py={0.5}>
              <CVSExaminationsAccordionForEncounter
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patient={fhirAppointmentDetails.patient!}
                purposeOfUseType={PurposeOfUse.IPDAppointment}
                isReadOnly={false}
                isExpanded={selectedSystem === 'cvs'}
                onSelected={(categoryLabel) => {
                  if (selectedSystem === 'cvs') {
                    setSelectedSystem(undefined)
                  } else {
                    setSelectedSystem(categoryLabel)
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box py={0.5}>
              <CNSExaminationsAccordionForEncounter
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patient={fhirAppointmentDetails.patient!}
                purposeOfUseType={PurposeOfUse.IPDAppointment}
                isReadOnly={false}
                isExpanded={selectedSystem === 'cns'}
                onSelected={(categoryLabel) => {
                  if (selectedSystem === 'cns') {
                    setSelectedSystem(undefined)
                  } else {
                    setSelectedSystem(categoryLabel)
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box py={0.5}>
              <GISExaminationsAccordionForEncounter
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patient={fhirAppointmentDetails.patient!}
                purposeOfUseType={PurposeOfUse.IPDAppointment}
                isReadOnly={false}
                isExpanded={selectedSystem === 'gis'}
                onSelected={(categoryLabel) => {
                  if (selectedSystem === 'gis') {
                    setSelectedSystem(undefined)
                  } else {
                    setSelectedSystem(categoryLabel)
                  }
                }}
              />
            </Box>
          </Grid>

          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box py={0.5}>
              <RSExaminationsAccordionOfEncounter
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patient={fhirAppointmentDetails.patient!}
                purposeOfUseType={PurposeOfUse.IPDAppointment}
                isReadOnly={false}
                isExpanded={selectedSystem === 'rs'}
                onSelected={(categoryLabel) => {
                  if (selectedSystem === 'rs') {
                    setSelectedSystem(undefined)
                  } else {
                    setSelectedSystem(categoryLabel)
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box>
              <SystemDiagnosisManagement
                valueSetCatCode='skelital'
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patientId={fhirAppointmentDetails.patient?.id!}
                categoryCoding={{
                  system: 'http://snomed.info/sct',
                  code: '88230002',
                  display: 'Skeletal System',
                }}
                title='Skeletal System'
                otherCoding={{
                  system:
                    'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-skeletal-system-finding-cs',
                  code: 'other-skeletal-system-finding',
                  display: 'Other',
                }}
                splitView={false}
              />
            </Box>
          </Grid>
          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box>
              <SystemDiagnosisManagement
                valueSetCatCode='excretory'
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patientId={fhirAppointmentDetails.patient?.id!}
                categoryCoding={{
                  system: 'http://snomed.info/sct',
                  code: '63911002',
                  display: 'Excretory System',
                }}
                title='Excretion System'
                otherCoding={{
                  system:
                    'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-excretory-system-finding-cs',
                  code: 'other-excretory-system-finding',
                  display: 'Other',
                }}
                splitView={false}
              />
            </Box>
          </Grid>

          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box>
              <OtherSystemDiagnosis
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patientId={fhirAppointmentDetails.patient?.id!}
              />
            </Box>
          </Grid>

          <Grid>
            <Box>
              <LabAndRadiologyResults
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patientId={fhirAppointmentDetails.patient?.id!}
                allowAddition={true}
              />
            </Box>
          </Grid>

          <Grid
            style={{
              margin: '0px',
            }}
          >
            <Box>
              <SystemDiagnosisManagement
                valueSetCatCode='conditionMaster'
                encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                patientId={fhirAppointmentDetails.patient?.id!}
                categoryCoding={{
                  code: '148006',
                  display: 'Preliminary diagnosis',
                  system: 'http://snomed.info/sct',
                }}
                title='Provisional Diagnosis'
                otherCoding={{
                  code: 'J95-J99',
                  display: 'Other',
                }}
                splitView={false}
              />
            </Box>
          </Grid>

          {/* <Grid>
        <Box>
          <SubstanceCard fhirAppointmentDetails={fhirAppointmentDetails} />
        </Box>
      </Grid> */}

          <Grid>
            <Box>
              <Assessment fhirAppointmentDetails={fhirAppointmentDetails} />
            </Box>
          </Grid>

          {!isPhysiotherapist() && (
            <Grid item>
              <AssessmentCaptureDetails
                encounter={fhirAppointmentDetails.mainEncounter!}
                patient={fhirAppointmentDetails.patient}
                split={false}
              />
            </Grid>
          )}

          {!isPhysiotherapist() && (
            <Grid item>
              <Typography variant='h5' color='primary' id='dietTreatment'>
                {' '}
                Diet & Treatment
              </Typography>
            </Grid>
          )}

          {(!isPhysiotherapist() || consultation === false) && (
            <Grid>
              <DietCard
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
              />
            </Grid>
          )}

          {isPhysiotherapist() && (
            <Grid item>
              <Typography variant='h5' color='primary'>
                {' '}
                Treatment
              </Typography>
            </Grid>
          )}
          <Grid>
            <Box>
              <TreatmentCard
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
              />
            </Box>
          </Grid>
          <Grid>
            <Box>
              <SubstanceCard
                fhirAppointmentDetails={fhirAppointmentDetails}
                status={
                  fhirAppointmentDetails.mainTask.businessStatus!.coding
                    ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'discharged' ||
                      fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                        .code === 'initiate-discharge' ||
                      fhirAppointmentDetails.mainServiceRequest.status! ===
                        'completed'
                      ? 'completed'
                      : ''
                    : ''
                }
              />
            </Box>
          </Grid>
          <Grid>
            <SignaturesOfIPD
              fhirAppointmentDetails={fhirAppointmentDetails}
              isReadOnly={false}
            />
          </Grid>
        </Box>
      </Grid>
    )
  }
