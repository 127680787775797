import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { ActionsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { handleAddCarePlanActivities } from 'utils/careplan_utils/carePlanActivities'
import { getCarePlanActivities } from 'utils/patientHelper/cds_helper'
import { CDSPlanActivityTile } from './carePlanActivityTile'

interface Props {
  patientId: string

  encounterId?: string

  carePlanUrl?: string
  ariaContrls: string
  id: string
  action: ActionsEntity
}

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

export const CDSPlanActivities: React.FC<Props> = ({
  patientId,

  encounterId,

  carePlanUrl,
  ariaContrls,
  id,
  action,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [selectedTestsToOrder, setSelectedTestsToOrder] = useState<
    R4.ICarePlan_Detail[]
  >([])
  const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  function onSelectedTestsChanged(changedTest: R4.ICarePlan_Detail) {
    let temp: R4.ICarePlan_Detail[] = [...selectedTestsToOrder]
    const res = temp.findIndex((e) => e.description === changedTest.description)
    if (res >= 0) {
      temp = temp.filter((e) => e !== changedTest)
    } else {
      temp.push(changedTest)
    }

    setSelectedTestsToOrder(temp)
  }

  function isSelected(changedTest: R4.ICarePlan_Detail) {
    const res = selectedTestsToOrder.findIndex(
      (e) => e.description === changedTest.description
    )
    if (res >= 0) {
      return true
    }
    return false
  }

  function handleUpdateButton() {
    setUpdatingStatus({
      requesting: true,
    })

    handleAddCarePlanActivities(
      patientId,
      selectedTestsToOrder,
      encounterId,
      carePlanUrl
    ).then((e) => {
      const res: string[] = []
      let overAllRes = true
      e.forEach((resp) => {
        overAllRes = overAllRes && resp.response === 'valid'
        if (resp.response === 'error') {
          res.push(resp.errorReason ?? resp.responseData)
        }
      })
      if (overAllRes) {
        setUpdatingStatus({
          requesting: false,
          requestSuccess: true,
        })
      } else {
        setUpdatingStatus({
          requesting: false,
          requestSuccess: false,
          requestError: true,
        })
        dispatch(
          showErrorAlert(res.join(','), '', 'Error while adding details')
        )
      }
    })
  }

  return (
    <Accordion>
      <AccordionSummary
        aria-controls={ariaContrls}
        id={id}
        expandIcon={<ExpandMore />}
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignContent='center'
          alignItems='center'
          width='100%'
          overflow='auto'
        >
          <Typography className={classes.titleText}>
            CarePlan Activities
          </Typography>

          <Box flexDirection='row'>
            {updatingStatus.requesting && (
              <Box height={25} width={25}>
                <CircularProgress size={20} />
              </Box>
            )}
            <Button
              variant='text'
              color='primary'
              className={classes.buttonText}
              disabled={
                updatingStatus.requesting || selectedTestsToOrder.length === 0
              }
              onClick={(e) => {
                handleUpdateButton()
                e.stopPropagation()
              }}
            >
              Finalize & Add
            </Button>

            {/* <Button
              variant='text'
              color='secondary'
              disabled={updatingStatus.requesting}
              className={classes.rejectButtonText}
            >
              Reject
            </Button> */}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <List aria-label='error' style={{ width: '100%' }}>
          {getCarePlanActivities((action.resource as R4.IBundle).entry ?? [])
            .length > 0 &&
            getCarePlanActivities(
              (action.resource as R4.IBundle).entry ?? []
            ).map((app, index) => (
              <ListItem key={app.description ?? ''} disableGutters>
                <CDSPlanActivityTile
                  isSelected={isSelected(app)}
                  onSelectionChanged={() => {
                    if (app.kind === 'Appointment') onSelectedTestsChanged(app)
                  }}
                  disableOption={app.kind !== 'Appointment'}
                  activity={app}
                />
              </ListItem>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
