import { IPatient, IReference } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getOrderStatusText } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { updateTherapyPerformDetailsForTherapiesCommon } from 'utils/fhirResoureHelpers/therapiesHelper'
import { TherapistSearchMobile } from './therapistSearchMobile'

interface Props {
  open: boolean
  onClose: () => void
  patient: IPatient
  visitReferance: IReference
  treatmentPlanData: TreatmentPlanData
  onSuccess: () => void
}

export const TherapyPerformCaptureMobile: React.FC<Props> = ({
  open,
  onClose,
  patient,
  visitReferance,
  treatmentPlanData,
  onSuccess,
}) => {
  const [selectedPractWithRole, setSelecctedPractWithRole] =
    useState<PractitionerWithRole[]>()

  const [updatingTherapyDetails, setUpdatingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const dispatch = useDispatch()

  function updateTherapyDetails() {
    if (visitReferance !== undefined && selectedPractWithRole !== undefined) {
      setUpdatingTherapyDetails({
        requesting: true,
      })

      updateTherapyPerformDetailsForTherapiesCommon(
        patient,
        visitReferance,
        treatmentPlanData,
        selectedPractWithRole
      )
        .then((e) => {
          if (e !== undefined) {
            if (e) {
              setUpdatingTherapyDetails({
                requesting: false,
                requestSuccess: true,
              })
              dispatch(showSuccessAlert('Therapy details updated successfully'))
              onSuccess()
            } else {
              setUpdatingTherapyDetails({
                requesting: false,
                requestError: true,
              })
              dispatch(
                showErrorAlert(
                  'Error while updating therapy details. Please try later'
                )
              )
            }
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
            dispatch(
              showErrorAlert(
                'Error while updating therapy details. Please try later'
              )
            )
          }
        })
        .catch((e) => {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
          dispatch(
            showErrorAlert(
              'Error while updating therapy details. Please try later'
            )
          )
        })
    }
  }

  if (
    treatmentPlanData &&
    treatmentPlanData.treatmentPlan &&
    getOrderStatusText(treatmentPlanData.treatmentPlan) === 'Scheduled' &&
    treatmentPlanData.procedure === undefined
  ) {
    return (
      <BottomSheet
        open={open}
        onDismiss={onClose}
        snapPoints={({ minHeight, maxHeight }) => [
          minHeight + 32,
          maxHeight / 0.6,
        ]}
        // Opens the largest snap point by default, unless the user selected one previously
        defaultSnap={({ lastSnap, snapPoints }) => Math.min(...snapPoints) + 32}
        header={
          <Box display='flex' flexDirection='row'>
            <Box>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z'
                  fill='#1C274C'
                  style={{ fill: '#1C274C', fillOpacity: 1 }}
                />
              </svg>
            </Box>
            <Box width={8} />
            <Box>
              <Typography
                style={{
                  color: '#19274F',
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-1px',
                }}
              >
                Complete Therapy
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <Box display='flex' width='100%'>
            <Button
              variant='contained'
              disabled={
                selectedPractWithRole === undefined ||
                selectedPractWithRole.length === 0
              }
              color='primary'
              style={{
                width: '100%',

                color: '#ffffff',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',

                flexGrow: 1,
                letterSpacing: '-0.16px',
                borderRadius: 8,
              }}
              onClick={() => {
                if (!updatingTherapyDetails.requesting) {
                  updateTherapyDetails()
                }
              }}
            >
              {updatingTherapyDetails.requesting && (
                <CircularProgress size={25} style={{ color: 'white' }} />
              )}
              {!updatingTherapyDetails.requesting && 'Submit'}
            </Button>
          </Box>
        }
      >
        <Box display='flex' flexDirection='column' px={2}>
          <Box display='flex' flexDirection='row' width='100%'>
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' flexDirection='row' alignSelf='stretch'>
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{
                    color: '#19274F',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.16px',
                    fontFeatureSettings: "'dlig' on",
                    fontVariantNumeric: 'lining-nums proportional-nums',
                  }}
                >
                  Select Performer
                </Typography>
              </Box>
              <Box display='flex' flexDirection='row'>
                <TherapistSearchMobile
                  disableSelection={updatingTherapyDetails.requesting}
                  onPractionerSelected={(
                    practionerList: PractitionerWithRole[]
                  ) => {
                    setSelecctedPractWithRole(practionerList)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </BottomSheet>
    )
  }

  return <></>
}
