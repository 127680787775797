import { R4 } from '@ahryman40k/ts-fhir-types'
import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchSpecialties } from 'redux/practitioner/spcializationSearchHandler/specialtiesSearchSlice'
import { RootState } from 'redux/rootReducer'
import { Specializations } from 'utils/constants/spcializations'
import {
  MultiSelectWithSearchSpl,
  WelloLoadingIndicator,
} from 'wello-web-components'

interface Props {
  onSpecializationsSelectionChanged?: (selectedValues: R4.ICoding[]) => void
  specialty?: string
  showTitle?: boolean
}
export const SpecializationSelector: React.FC<Props> = ({
  onSpecializationsSelectionChanged,
  specialty,
  showTitle = true,
}: Props) => {
  const specialtiesSearchState = useSelector(
    (state: RootState) => state.specialtiesSearchSlice
  )
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation(['common', 'patient'])

  useEffect(() => {
    dispatch(searchSpecialties(''))
  }, [dispatch])
  return (
    <div>
      <MultiSelectWithSearchSpl
        showTitle={showTitle}
        title={t('labelCommon:specializations')}
        id={specialty}
        displayField='display'
        valueField='code'
        preSelectedOptions={[]}
        listHeight={window.innerHeight / 4}
        availableOptions={Specializations}
        onOptionsClicked={() => {}}
        onValuesChanged={(changedValues: any[]) => {
          if (onSpecializationsSelectionChanged) {
            onSpecializationsSelectionChanged(changedValues)
          }
        }}
      />
    </div>
  )
}
