/* eslint-disable consistent-return */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Step,
  StepButton,
  Stepper,
  Typography,
  useTheme,
  Button,
} from '@material-ui/core'
import { ArrowBackRounded } from '@material-ui/icons'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { sendPatientInvite } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { requestDietTiming } from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import { searchObservationsForAddLabTest } from 'redux/lab/diaognsticLabTestSearchSlice/dianogsticObsFinderSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { getTaskStatus } from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import { getAllKriya } from 'redux/valueSet/kriyaListWithChargable/kriyaWithPriceSearchSlice'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { getAllSubstance } from 'redux/valueSet/substance/substanceSearchSlice'
import { updateSessionTherapiesList } from 'redux/valueSet/therapies_catalogue/therapiesCatalogueSlice'
import { getAllTherapist } from 'redux/valueSet/therapyList/threapyCatalogSearchSlice'
import { getAllTherapiesWithPrice } from 'redux/valueSet/therapyListwithChargable/threapyCatalogWithPriceSearchSlice'
import {
  getCurrentUserPractitionerDetails,
  isAyurvedaDoctor,
  isCurrentUserDoctor,
  isDoctor,
  isGre,
  isNaturoPathsDoctor,
  isNurse,
  isPhysiotherapist,
  isTherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { getValueCoDeFromValueCodeable } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getAdjustedPath, getVendorPartId } from 'utils/routes_helper'
import { getUrlPathParameter } from 'utils/urlHelper'
import { PatientNameInIPDWidget } from 'views/components/clinic_home_page/patientNameForIpd'
import { ProfilePicWithAgeAndGender } from 'views/components/clinic_home_page/profilePicWithAgeAndGender'
import { ActiveAssementDetailsForMedicalRole } from 'views/components/ipdconusltation/assementDetailsOfConsultation'
import { AssessmentDetailsScreenForPT } from 'views/components/ipdconusltation/assementDetailsScreenForPT'
import { PlanPartOfConsultationIPDAyurveda } from 'views/components/ipdconusltation/assessment/planDetailsForAyurvedaIPD'
import { ActiveAssementDetailsForMedicalRoleIPD } from 'views/components/ipdconusltation/assessmentPartOfConsultationAyurvedaIPD'
import { Discharge } from 'views/components/ipdconusltation/discharge'
import { Feedback } from 'views/components/ipdconusltation/feedback'
import { ObjectivePartOfConsultation } from 'views/components/ipdconusltation/objectiveDetailsOfConsultation'
import { PlanPartOfConsultationIPD } from 'views/components/ipdconusltation/planDetailsForConsultationIPD'

import { SubjectivePartOfConsultation } from 'views/components/ipdconusltation/subjectiveDetailsOfConsultation'
import { TreatmentCardForTherapist } from 'views/components/ipdconusltation/treatment/treatmentCardForTherapist'
import { WelloLoadingIndicator } from 'wello-web-components'

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& root': {
      padding: '0',
    },
    '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: 'p',
      },
  },
}))

let steps: string[] = []

// steps =
//   isUnitAdmin() || isNurse()
//     ? ['Dashboard', 'Objective']
//     : ['Dashboard', 'Objective', 'PlanS & Treatment', 'Discharge']

export const ActiveIPDDetailsForMedicalRole: React.FC<{}> = () => {
  const appointmentHandlerSlice = useSelector(
    (state: RootState) => state.ipdActiveAppointmentSlice
  )

  const ayurveda = getUrlPathParameter(window.location.search, 'isAyurveda')

  steps = isTherapist()
    ? ['Treatments']
    : isUnitAdmin()
    ? ayurveda !== null && ayurveda
      ? ['Subjective', 'Objective', 'Assessment']
      : ['Subjective', 'Objective', 'Assessment & Treatment']
    : isPhysiotherapist()
    ? ayurveda !== null && ayurveda
      ? ['Subjective', 'Objective', 'Assessment', 'Discharge Assessment']
      : [
          'Subjective',
          'Objective',
          'Assessment & Treatment',
          'Discharge Assessment',
        ]
    : isGre()
    ? ['Feedback']
    : isNurse()
    ? ['Subjective', 'Objective']
    : ayurveda !== null && ayurveda
    ? ['Subjective', 'Objective', 'Assessment', 'Plan & Treatment', 'Discharge']
    : ['Subjective', 'Objective', 'Assessment & Treatment', 'Plan', 'Discharge']

  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [completed, setCompleted] = React.useState([])
  const dispatch = useDispatch()
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const classes = useStyles()
  const [resend, setResend] = useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [statusLoading, setStatusLoading] = useState<boolean>(false)

  const [sendStatus, setSendSTatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function requestPollingForResendOption() {
    if (appointmentHandlerSlice.activeIPDActionDetails)
      getPatientTaskStatus(
        appointmentHandlerSlice.activeIPDActionDetails.patient
      )
  }

  function getPatientTaskStatus(patientDetails: R4.IPatient) {
    setPaymentStatus({ requesting: true })

    setStatusLoading(true)

    getTaskStatus(patientDetails)
      .then((e: boolean) => {
        setStatusLoading(false)

        if (e === false) {
          sendInvite(patientDetails)
        } else {
          dispatch(showWarningAlert('Invitation has been already accepted'))
        }
        setPaymentStatus({
          requesting: false,
          requestSuccess: true,
        })

        // dispatch(showSuccessAlert('Medication deleted successfully'))
        // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      })
      .catch((e) => {
        dispatch(showErrorAlert('Error while fetching invitation status'))
        console.log(e)
        setStatusLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function sendInvite(patient: R4.IPatient) {
    setSendSTatus({ requesting: true })

    setLoading(true)

    sendPatientInvite(patient.id!)
      .then((e: boolean) => {
        setLoading(false)
        if (e) {
          dispatch(showSuccessAlert('Invitation link sent successfully'))
          setSendSTatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setResend(true)
          setSendSTatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          // dispatch(showErrorAlert('Error while sending invitation'))
        }
      })
      .catch((e) => {
        setResend(true)
        dispatch(showErrorAlert('Error while sending invitation'))
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    dispatch(getAllNutritionValueSets())
    if (isPhysiotherapist()) dispatch(updateSessionTherapiesList())
    dispatch(getAllTherapist())

    if (ayurveda === null) {
      dispatch(getAllTherapiesWithPrice())
    }
    dispatch(getAllSubstance())
    dispatch(searchObservationsForAddLabTest())
    i18n.changeLanguage(language ?? '')
    if (ayurveda) {
      dispatch(getAllKriya())
    }
    if (isCurrentUserDoctor()) {
      dispatch(requestDietTiming(getCurrentUserPractitionerDetails().id ?? ''))
    }
    logger.error(window.location.search)
    if (window.location.search) {
      logger.error('inside search')
      // const params = new URLSearchParams(window.location.search)
      const id = getUrlPathParameter(window.location.search, 'id')

      if (id) {
        sessionStorage.setItem(CURRENT_VISIT_REFERENCE, `ServiceRequest/${id}`)
        sessionStorage.setItem('appointmentReference', `ServiceRequest/${id}`)
        dispatch(fetchIPDAppointmentDetails(id))
      }
    } else {
      logger.error(window.location.href)
    }
    return () => {
      sessionStorage.removeItem(CURRENT_VISIT_REFERENCE)
    }
  }, [dispatch])
  const history = useHistory()

  const navigateToPath = (path: string) => {
    history.push(path)
  }

  useEffect(() => {
    dispatch(
      setSelectedSection({
        selectedSection: 'chief_complaint',
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (appointmentHandlerSlice.endedAppointment) {
      window.location.href = `/${getVendorPartId()}/dashboard`
    }
  }, [appointmentHandlerSlice.endedAppointment, history])

  const paperColor = useTheme().palette.background.paper
  const defaultColor = useTheme().palette.background.default

  const scrollToTop = () => {
    scroll.scrollTo(0)
  }

  const totalSteps = () => steps.length

  const completedSteps = () => Object.keys(completed).length

  const isLastStep = () => activeStep === totalSteps() - 1

  const allStepsCompleted = () => completedSteps() === totalSteps()

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: any) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    // newCompleted[activeStep] = true
    // setCompleted(newCompleted)
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted([])
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      className={classes.calendar}
      height='100vh'
      overflow='auto'
      width='100%'
    >
      {/* <SOAPNavigationDrawer
        style={{
          width: '260px',
          flexShrink: 0,
        }}
      /> */}

      {appointmentHandlerSlice.fetchingAppointmentDetails && (
        <WelloLoadingIndicator message='Fetching appointment Details' />
      )}

      {appointmentHandlerSlice.errorWhileFetchingAppointment && (
        <Typography>{t('error_fetch_appointment')}</Typography>
      )}
      {appointmentHandlerSlice.errorWhileUpdatingAppointment && (
        <Typography>{t('error_fetch_appointment')}</Typography>
      )}
      {appointmentHandlerSlice.appointmentFetched &&
        appointmentHandlerSlice.activeIPDActionDetails && (
          <Box
            overflow='auto'
            display='flex'
            flexDirection='column'
            width='100%'
          >
            <Box display='flex' width='100%'>
              <Box paddingX={1} paddingTop={2} paddingBottom={4}>
                <IconButton
                  aria-label='back-to_home'
                  onClick={() => {
                    sessionStorage.removeItem('time')
                    window.location.href = `${getAdjustedPath('/dashboard')}`
                  }}
                >
                  <ArrowBackRounded />
                </IconButton>
              </Box>
              <Box width='85%'>
                {appointmentHandlerSlice.activeIPDActionDetails?.patient && (
                  <Box display='flex' alignItems='center' alignContent='center'>
                    <Box px={1}>
                      <ProfilePicWithAgeAndGender
                        patient={
                          appointmentHandlerSlice.activeIPDActionDetails
                            ?.patient
                        }
                      />
                    </Box>
                    <PatientNameInIPDWidget
                      appointmentDetails={
                        appointmentHandlerSlice.activeIPDActionDetails?.patient
                      }
                      serviceRequest={
                        appointmentHandlerSlice.activeIPDActionDetails
                          .mainServiceRequest
                      }
                      location={
                        appointmentHandlerSlice.activeIPDActionDetails.roomData
                      }
                    />
                  </Box>
                )}
              </Box>

              {appointmentHandlerSlice.activeIPDActionDetails
                ?.mainServiceRequest?.status !==
                R4.AppointmentStatusKind._fulfilled && (
                <Box display='flex' height='100%' flexDirection='column' p={2}>
                  {' '}
                  <Box display='flex' flexDirection='row' width='100%'>
                    {appointmentHandlerSlice.activeIPDActionDetails &&
                      appointmentHandlerSlice.showResend === false && (
                        <Button
                          variant='contained'
                          color='primary'
                          id='resend'
                          onClick={() => {
                            getPatientTaskStatus(
                              appointmentHandlerSlice.activeIPDActionDetails!
                                .patient
                            )
                          }}
                          disabled={
                            appointmentHandlerSlice.activeIPDActionDetails ===
                              undefined ||
                            appointmentHandlerSlice.activeIPDActionDetails
                              .patient === undefined ||
                            loading
                          }
                        >
                          Resend Invite
                        </Button>
                      )}

                    {/* {!isUnitAdmin() && (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          setShowCdsRecommendation(true)
                        }}
                      >
                        {t('labelCommon:care_plan_recommendations')}
                      </Button>
                    )} */}
                    {/*   <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setShowProfileDetails(true)
                      }}
                      disabled={
                        appointmentHandlerSlice.appointmentDetails ===
                          undefined ||
                        appointmentHandlerSlice.appointmentDetails.patient ===
                          undefined
                      }
                    >
                      {t('labelCommon:view_profile')}
                    </Button>
                    {!isUnitAdmin() && (
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={
                          !isVideoAppointment(
                            appointmentHandlerSlice.appointmentDetails
                          )
                        }
                        onClick={() => {
                          setStartVideo(true)
                        }}
                      >
                        {t('labelCommon:start_video')}
                      </Button>
                    )} */}
                    {/* {!isUnitAdmin() && (
                      <Button
                        variant='contained'
                        color='secondary'
                        disabled={
                          !appointmentHandlerSlice.enableEndAppointmentButton
                        }
                        onClick={() => {
                          if (appointmentHandlerSlice.appointmentDetails)
                            dispatch(
                              endClinicVisit(
                                appointmentHandlerSlice.appointmentDetails
                              )
                            )
                        }}
                      >
                        {t('labelCommon:end_visit')}
                      </Button>
                    )} */}
                  </Box>
                </Box>
              )}
            </Box>
            <Divider variant='fullWidth' />
            {!isTherapist() && (
              <Box
                sx={{ width: '100%' }}
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection='row'
                bgcolor={kPrimaryMain}
              >
                <Box
                  sx={{ width: isUnitAdmin() ? '100%' : '100%' }}
                  px={30}
                  borderRadius={12}
                  bgcolor={kPrimaryMain}
                >
                  <Stepper
                    activeStep={activeStep}
                    nonLinear
                    style={{ borderRadius: 3, backgroundColor: kPrimaryMain }}
                  >
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton
                          color='red'
                          onClick={handleStep(index)}
                          id={`${label}${index}`}
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                              color: paperColor,
                            }}
                          >
                            {t('labelCommon:' + `${label}`)}
                            {/* {t('labelCommon:view_profile')} */}
                          </Typography>
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
            )}

            {isTherapist() && (
              <Box
                sx={{ width: '100%' }}
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection='row'
                bgcolor={kPrimaryMain}
                height={50}
              >
                <Box borderRadius={12} bgcolor={kPrimaryMain}>
                  <Typography
                    variant='subtitle2'
                    style={{
                      textTransform: 'uppercase',
                      color: paperColor,
                    }}
                  >
                    Treatments
                  </Typography>
                </Box>
              </Box>
            )}

            <Box
              display='flex'
              overflow='auto'
              flexDirection='row'
              px={isTherapist() ? 0 : 10}
              borderRadius={12}
              py={isTherapist() ? 2 : 1.5}
            >
              <Box
                flexDirection='column'
                overflow='auto'
                width='100%'
                id='rootEL'
                borderRadius={12}
                alignItems='center'
                justifyContent='center'
                paddingLeft={activeStep === 5 ? 60 : 0}
              >
                {' '}
                <SimpleBar
                  autoHide={false}
                  style={{
                    height: '100%',
                    overflowX: 'hidden',
                    padding: '4px',
                  }}
                >
                  <Box
                    flexDirection='column'
                    width={activeStep === 5 ? '50%' : '100%'}
                    bgcolor={paperColor}
                    borderRadius={4}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      py={isTherapist() ? 2 : 2}
                      px={isTherapist() ? 0 : 3}
                    >
                      {activeStep === 0 && (
                        <Box width='100%' borderRadius={4}>
                          {isTherapist() && (
                            <TreatmentCardForTherapist
                              fhirAppointmentDetails={
                                appointmentHandlerSlice.activeIPDActionDetails
                              }
                            />
                          )}

                          {isGre() && (
                            <Feedback
                              fhirAppointmentDetails={
                                appointmentHandlerSlice.activeIPDActionDetails
                              }
                              disable={false}
                            />
                          )}

                          {!isTherapist() && !isGre() && (
                            <SubjectivePartOfConsultation
                              fhirAppointmentDetails={
                                appointmentHandlerSlice.activeIPDActionDetails
                              }
                            />
                          )}
                        </Box>
                      )}

                      {activeStep === 1 && (
                        <Box width='110%'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            py={1.5}
                          >
                            <ObjectivePartOfConsultation
                              fhirAppointmentDetails={
                                appointmentHandlerSlice.activeIPDActionDetails
                              }
                            />
                          </Box>
                        </Box>
                      )}

                      {activeStep === 2 && !isPhysiotherapist() && !isGre() && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          py={1.5}
                        >
                          {getValueCoDeFromValueCodeable(
                            appointmentHandlerSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ).length > 0 &&
                            getValueCoDeFromValueCodeable(
                              appointmentHandlerSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ) === '1259939000' && (
                              <Box width='100%'>
                                <ActiveAssementDetailsForMedicalRoleIPD
                                  fhirAppointmentDetails={
                                    appointmentHandlerSlice.activeIPDActionDetails
                                  }
                                />
                              </Box>
                            )}

                          {(getValueCoDeFromValueCodeable(
                            appointmentHandlerSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ).length === 0 ||
                            getValueCoDeFromValueCodeable(
                              appointmentHandlerSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ) === '439809005') && (
                            <Box width='100%'>
                              <ActiveAssementDetailsForMedicalRole
                                fhirAppointmentDetails={
                                  appointmentHandlerSlice.activeIPDActionDetails
                                }
                              />
                            </Box>
                          )}
                        </Box>
                      )}

                      {activeStep === 3 &&
                        (isDoctor() || isNaturoPathsDoctor()) &&
                        !isPhysiotherapist() && (
                          <Box width='100%'>
                            {(getValueCoDeFromValueCodeable(
                              appointmentHandlerSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ).length === 0 ||
                              getValueCoDeFromValueCodeable(
                                appointmentHandlerSlice.activeIPDActionDetails
                                  .mainServiceRequest.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                              ) === '439809005') && (
                              <PlanPartOfConsultationIPD
                                fhirAppointmentDetails={
                                  appointmentHandlerSlice.activeIPDActionDetails
                                }
                              />
                            )}
                            {getValueCoDeFromValueCodeable(
                              appointmentHandlerSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ).length > 0 &&
                              getValueCoDeFromValueCodeable(
                                appointmentHandlerSlice.activeIPDActionDetails
                                  .mainServiceRequest.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                              ) === '1259939000' && (
                                <Box width='100%'>
                                  <PlanPartOfConsultationIPDAyurveda
                                    fhirAppointmentDetails={
                                      appointmentHandlerSlice.activeIPDActionDetails
                                    }
                                  />
                                </Box>
                              )}
                          </Box>
                        )}

                      {activeStep === 2 && isPhysiotherapist() && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          py={1.5}
                        >
                          <AssessmentDetailsScreenForPT
                            fhirAppointmentDetails={
                              appointmentHandlerSlice.activeIPDActionDetails
                            }
                          />
                        </Box>
                      )}

                      {activeStep === 3 && isPhysiotherapist() && (
                        <Box width='110%'>
                          <Discharge
                            fhirAppointmentDetails={
                              appointmentHandlerSlice.activeIPDActionDetails
                            }
                            disable={false}
                            status={
                              appointmentHandlerSlice.activeIPDActionDetails
                                .mainTask.businessStatus!.coding
                                ? appointmentHandlerSlice.activeIPDActionDetails
                                    .mainTask.businessStatus!.coding[0].code ===
                                    'initiate-discharge' ||
                                  appointmentHandlerSlice.activeIPDActionDetails
                                    .mainTask.businessStatus!.coding[0].code ===
                                    'discharged' ||
                                  appointmentHandlerSlice.activeIPDActionDetails
                                    .mainServiceRequest.status! === 'completed'
                                  ? 'completed'
                                  : ''
                                : ''
                            }
                          />
                        </Box>
                      )}

                      {(isDoctor() || isNaturoPathsDoctor()) &&
                        activeStep === 4 && (
                          <Box width='110%'>
                            {!isUnitAdmin() && !isGre() && (
                              <Discharge
                                fhirAppointmentDetails={
                                  appointmentHandlerSlice.activeIPDActionDetails
                                }
                                disable={false}
                                status={
                                  appointmentHandlerSlice.activeIPDActionDetails
                                    .mainTask.businessStatus!.coding
                                    ? appointmentHandlerSlice
                                        .activeIPDActionDetails.mainTask
                                        .businessStatus!.coding[0].code ===
                                        'initiate-discharge' ||
                                      appointmentHandlerSlice
                                        .activeIPDActionDetails.mainTask
                                        .businessStatus!.coding[0].code ===
                                        'discharged' ||
                                      appointmentHandlerSlice
                                        .activeIPDActionDetails
                                        .mainServiceRequest.status! ===
                                        'completed'
                                      ? 'completed'
                                      : ''
                                    : ''
                                }
                              />
                            )}
                          </Box>
                        )}

                      {/* {activeStep === 4 && (
                        <Box width='110%'>
                          <Box />
                        </Box>
                      )} */}
                    </Box>
                  </Box>

                  {/* <Box display='flex' flexDirection='row' width='100%' py={1.5}>
                    <AssessmentPartOfConsultation
                      fhirAppointmentDetails={
                        appointmentHandlerSlice.appointmentDetails
                      }
                    />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%' py={1.5}>
                    <PlanPartOfConsultation
                      fhirAppointmentDetails={
                        appointmentHandlerSlice.appointmentDetails
                      }
                    />
                  </Box> */}
                </SimpleBar>
              </Box>
            </Box>
          </Box>
        )}

      {/*  <PatientProfileDetailPopUp
        onClose={() => {
          setShowProfileDetails(false)
        }}
        open={showProfileDetails}
        patientDetails={appointmentHandlerSlice.activeIPDActionDetails?.patient!}
        fhirPatientDetail={appointmentHandlerSlice.activeIPDActionDetails!}
      />

      <CdsRecommendationsPopup
        onClose={() => {
          setShowCdsRecommendation(false)
        }}
        open={showCdsRecommendation}
        patientDetails={appointmentHandlerSlice.appointmentDetails?.patient!}
        fhirPatientDetail={appointmentHandlerSlice.appointmentDetails!}
      /> */}
    </Box>
  )
}
