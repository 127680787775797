import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
  Info,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { doctorMainBackColor } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import { WelloHabits } from 'models/WelloConditions'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestHabitsHistoryOfPatient } from 'redux/patientMedicalHistory/habitsHistory/habitsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { hasAddedObsBySameDocForColor } from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getDateWiseVitals,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getAddButoonShow,
  getAddedinSameAppointment,
  getObservationCodeText,
  getObservationCodeTextVal,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { AddHabits } from 'views/components/ipdconusltation/addition/addHabits'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { EditHabits } from '../edition/editHabits'
import { AddHabitsOPD } from './addHabitsOPD'
import { HabitStatementTile } from './HabitStatementTabularTile'

interface HabitsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  splitView: boolean
}

export const Habits: React.FC<HabitsProp> = ({
  fhirAppointmentDetails,
  splitView,
}: HabitsProp) => {
  const { t } = useTranslation(['observation', 'common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const habitsHistorySlice = useSelector(
    (state: RootState) => state.habitsHistorySlice
  )
  const [currentCondition, setCurrentCondition] = useState<WelloHabits>({
    id: _.random(1, 10000000).toString(),
  })

  const [showEdit, setShowEdit] = useState<boolean>(false)
  function getData(obsList: R4.IObservation[]) {
    const firstColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '10000-2'
        )
    )
    const secColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '65515-9'
        )
    )
    const thirdColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '74008-4'
        )
    )

    const fourthColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '74009-2'
        )
    )

    const fiveColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '65968-0'
        )
    )
    const sixColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '65561-3'
        )
    )
    const sevenColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '10000-1'
        )
    )

    const otherColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === 'other-habit'
        )
    )
    setCurrentCondition({
      ...currentCondition,
      existingObs: obsList,
      coffee:
        firstColumn.length > 0
          ? firstColumn[0].valueCodeableConcept
            ? firstColumn[0].valueCodeableConcept.coding &&
              firstColumn[0].valueCodeableConcept.coding.length > 0
              ? firstColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      energyLevel:
        secColumn.length > 0
          ? secColumn[0].valueCodeableConcept
            ? secColumn[0].valueCodeableConcept.coding &&
              secColumn[0].valueCodeableConcept.coding.length > 0
              ? secColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      exerciseIntensity:
        thirdColumn.length > 0
          ? thirdColumn[0].valueCodeableConcept
            ? thirdColumn[0].valueCodeableConcept.coding &&
              thirdColumn[0].valueCodeableConcept.coding.length > 0
              ? thirdColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      exerciseDuration:
        fourthColumn.length > 0
          ? fourthColumn[0].valueCodeableConcept
            ? fourthColumn[0].valueCodeableConcept.coding &&
              fourthColumn[0].valueCodeableConcept.coding.length > 0
              ? fourthColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      sleepDay:
        fiveColumn.length > 0
          ? fiveColumn[0].valueCodeableConcept
            ? fiveColumn[0].valueCodeableConcept.coding &&
              fiveColumn[0].valueCodeableConcept.coding.length > 0
              ? fiveColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      sleepNight:
        sixColumn.length > 0
          ? sixColumn[0].valueCodeableConcept
            ? sixColumn[0].valueCodeableConcept.coding &&
              sixColumn[0].valueCodeableConcept.coding.length > 0
              ? sixColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      tea:
        sevenColumn.length > 0
          ? sevenColumn[0].valueCodeableConcept
            ? sevenColumn[0].valueCodeableConcept.coding &&
              sevenColumn[0].valueCodeableConcept.coding.length > 0
              ? sevenColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      otherHabit:
        otherColumn.length > 0
          ? otherColumn[0].valueCodeableConcept
            ? otherColumn[0].valueCodeableConcept.coding &&
              otherColumn[0].valueCodeableConcept.coding.length > 0
              ? otherColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
    })
  }
  const [groupVitals, setGroupVitals] = useState<GroupedOccupation[]>([])
  useEffect(() => {
    dispatch(
      requestHabitsHistoryOfPatient(
        fhirAppointmentDetails.appointment.id!,
        fhirAppointmentDetails.patient,
        false,
        'OPD'
      )
    )
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (
      habitsHistorySlice.resultsAvailable &&
      habitsHistorySlice.groupedHabitData
    ) {
      updateVitals(habitsHistorySlice.groupedHabitData)
    }
  }, [habitsHistorySlice])

  function updateVitals(vitalList: GroupedOccupation[]) {
    const results: GroupedOccupation[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        occupation: vitalList[i].occupation,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedOccupation[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              {t('labelCommon:habits')}{' '}
            </Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>
      {habitsHistorySlice.noResultsAvailable && (
        <Box>
          {isMedicalServiceProvider() && !splitView && (
            <Box
              display='flex'
              flexGrow
              flexDirection='row'
              justifyContent='flex-start'
              paddingBottom={1}
            >
              <Tooltip title='' id='hab_4'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  id='hab_5'
                  color='primary'
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                  style={{ padding: 0 }}
                >
                  <Typography
                    variant='subtitle2'
                    color='primary'
                    component={Link}
                    id='add_habit'
                    style={{ fontSize: 13 }}
                  >
                    {' '}
                    {t('labelCommon:habits')}{' '}
                  </Typography>
                  <AddCircleIcon
                    id='hab_6'
                    style={{ height: '22px', padding: 0 }}
                    color='primary'
                  />{' '}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      {habitsHistorySlice.resultsAvailable &&
        habitsHistorySlice.groupedHabitData && (
          <Box>
            {isMedicalServiceProvider() &&
              !splitView &&
              getAddButoonShow(
                habitsHistorySlice.groupedHabitData ?? [],
                fhirAppointmentDetails.appointment.id!
              ) === false && (
                <Box
                  display='flex'
                  flexGrow
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingBottom={1}
                >
                  <Tooltip title='' id='hab_4'>
                    <IconButton
                      aria-label='btn_ord_cancel'
                      id='hab_5'
                      color='primary'
                      onClick={() => {
                        setOpenAddDialogue(true)
                      }}
                      style={{ padding: 0 }}
                    >
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        component={Link}
                        id='add_habit'
                        style={{ fontSize: 13 }}
                      >
                        {' '}
                        {t('labelCommon:habits')}{' '}
                      </Typography>
                      <AddCircleIcon
                        id='hab_6'
                        style={{ height: '22px', padding: 0 }}
                        color='primary'
                      />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )}
      {habitsHistorySlice.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}
      {habitsHistorySlice.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {splitView ? 'No data available' : ''}
          </Typography>
        </Box>
      )}
      {habitsHistorySlice.errorWhileSearchingProcedures && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {habitsHistorySlice.errorReason ??
              'Error while fetching conditions'}
          </Typography>
        </Box>
      )}
      {/* {habitsHistorySlice.resultsAvailable && habitsHistorySlice.habitsList && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Box display='flex' flexDirection='column' width='100%' flexGrow>
            {habitsHistorySlice.habitsList.map((val: any) => (
              <HistoryObjectiveIndicativeComp
                key={`habit_${getObservationCodeTextVal(val)}`}
                description={getObservationContent(val)}
                timeData={`Recorded on ${moment(
                  val.issued ?? new Date()
                ).format('DD-MM-YYYY')}`}
                title={getObservationCodeText(val)}
                showIndicativeBars={false}
                type='other'
                showAlarmingPrefixIcon={false}
              />
            ))}
          </Box>
        </Box>
      )} */}
      {habitsHistorySlice.resultsAvailable &&
        habitsHistorySlice.groupedHabitData && (
          <Box flexGrow width='100%' display='flex' flexDirection='column'>
            {groupVitals.map((val, index: number) => (
              <Box py={1} key={val.date ?? ''} id={`habitGrouped${index}`}>
                <Box
                  paddingX={1}
                  borderRadius={2}
                  style={{
                    backgroundColor: hasAddedObsBySameDocForColor(
                      val.occupation
                    )
                      ? 'lightGrey'
                      : doctorMainBackColor,
                  }}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height={40}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    alignItems='center'
                    width='70%'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: 'black',

                        fontWeight: 'bold',
                      }}
                    >
                      Recorded on{' '}
                      {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                    </Typography>
                  </Box>
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    alignItems='center'
                    width='30%'
                  >
                    {!splitView &&
                      getAddedinSameAppointment(
                        val.occupation,
                        fhirAppointmentDetails.appointment.id!
                      ) === true && (
                        <Box paddingLeft={1}>
                          <Tooltip title='Edit' id='chief_tool_edit'>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              id='habits_edit_button'
                              style={{ padding: 0 }}
                              onClick={() => {
                                getData(val.occupation)
                                setShowEdit(true)
                              }}
                            >
                              <img
                                id='chief_tool_edit_img'
                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                alt='Edit'
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    {hasAddedObsBySameDocForColor(val.occupation) === false && (
                      <Box
                        display='flex'
                        justifyContent='flex-end'
                        paddingLeft={1}
                        paddingTop={0.5}
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Patient Reported
                        </Typography>
                      </Box>
                    )}

                    <Box px={1}>
                      <Tooltip title=''>
                        <IconButton
                          aria-label='collapse_order_type'
                          size='small'
                          onClick={() => {
                            handleCollapseForPanel1(!val.checked, index)
                          }}
                        >
                          {val.checked && <ArrowDropUpOutlined />}
                          {!val.checked && <ArrowDropDownOutlined />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <Collapse
                  in={val.checked}
                  style={{
                    width: '100%',
                  }}
                >
                  <HabitStatementTile
                    topObs={val.occupation}
                    id={`habitGroupedData${index}`}
                  />
                </Collapse>
              </Box>
            ))}
          </Box>
        )}
      <AddHabitsOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onHabbitAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestHabitsHistoryOfPatient(
              fhirAppointmentDetails.appointment.id!,
              fhirAppointmentDetails.patient,
              false,
              'OPD'
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />
      <EditHabits
        open={showEdit}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onHabbitAdded={() => {
          setShowEdit(false)
          dispatch(
            requestHabitsHistoryOfPatient(
              fhirAppointmentDetails.appointment.id!,
              fhirAppointmentDetails.patient,
              false,
              'OPD'
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingHabits={currentCondition}
      />
    </Box>
  )
}
