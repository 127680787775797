import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  getCaseStudyData,
  getTreatmentReportOfVisit,
} from 'utils/fhirResoureHelpers/reportHelper'

export interface DischargeReportButtonProps {
  ipdDetails: R4.IServiceRequest
  serviceReqId: string
  encounterId: string
  patient: R4.IPatient
  location?: R4.ILocation
  disabled?: boolean
  title?: string
}

export const CaseStudyPrintButton: React.FC<DischargeReportButtonProps> = ({
  ipdDetails,
  serviceReqId,
  encounterId,
  patient,
  location,
  disabled = false,
  title,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [generatingStatus, setGeneratingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  function handleInvoiceGenerationButton() {
    setGeneratingStatus({
      requesting: true,
    })

    if (serviceReqId && encounterId) {
      getCaseStudyData(
        ipdDetails,
        serviceReqId,
        encounterId,
        patient,
        location
      ).then((e) => {
        if (e) {
          setGeneratingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setGeneratingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Treatment Card is not available'))
        }
      })
    }
  }

  return (
    <Box px={1} paddingTop={0.5}>
      {generatingStatus.requesting && <CircularProgress size={20} />}
      {!generatingStatus.requesting && (
        <Tooltip title='Print Case Study'>
          <IconButton
            onClick={() => {
              handleInvoiceGenerationButton()
            }}
            color='primary'
            size='small'
            disabled={disabled}
          >
            <img src={`${process.env.PUBLIC_URL}/uploadRep.png`} alt='delete' />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
