import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloMedication } from 'models/welloMedication'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getMedicationsOfIPD } from 'redux/clinic/ipdmanagement/medicationListIPD/medicationsListSliceIPD'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { RootState } from 'redux/rootReducer'
import { AddedMedicationsListCPG } from 'views/components/consultation/assessment/medicationListTableCPG'
import { MedicationTile } from 'views/components/consultation/assessment/medication_tile'
import { EditMedicationHandlerAllopathy } from 'views/components/consultation/edition/editMedicationAllopathy'
import { EditMedicationAyurvedaIPD } from './editMedicationAyurvedaIPD'

interface MedicationsListProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const MedicationsListIPD: React.FC<MedicationsListProps> = ({
  fhirAppointmentDetails,
}: MedicationsListProps) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const medicationsListSlice = useSelector(
    (state: RootState) => state.medicationsListSlice
  )

  const [existingMedication, setExistingMedication] =
    useState<WelloMedication>()
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(
      getMedicationsOfAppointment(
        fhirAppointmentDetails.patient,

        fhirAppointmentDetails.mainEncounter,
        false,
        fhirAppointmentDetails.mainServiceRequest.supportingInfo
      )
    )
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(medicationsListSlice.updatingImpressions ||
        medicationsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}

      {/* {medicationsListSlice.noResultsAvailable && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              No data available
            </Typography>
          </Box>
        )} */}
      {medicationsListSlice.medications &&
        medicationsListSlice.medications.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%'>
            <AddedMedicationsListCPG
              medication={medicationsListSlice.medications}
              patientDetails={fhirAppointmentDetails.patient}
              appointmentId={fhirAppointmentDetails.mainServiceRequest.id ?? ''}
              key='1'
              displayTypeOfMedication={false}
              displayStartEndDates={true}
              orderAllowed={false}
              history={false}
              split={false}
              onEditCLicked={(data: WelloMedication) => {
                setExistingMedication(data)
              }}
            />
          </Box>
        )}
      {/* {existingMedication &&
        existingMedication.type &&
        existingMedication.type === 'allopathy' && (
          <EditMedicationHandlerAllopathy
            open={existingMedication !== undefined}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getMedicationsOfAppointment(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.practitionerDetail,
                  fhirAppointmentDetails.encounter,
                  followUp,
                  fhirAppointmentDetails.appointment.supportingInformation
                )
              )
              setExistingMedication(undefined)
            }}
            onMedicalConditionAdded={() => {
              dispatch(
                getMedicationsOfAppointment(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.practitionerDetail,
                  fhirAppointmentDetails.encounter,
                  followUp,
                  fhirAppointmentDetails.appointment.supportingInformation
                )
              )
              setExistingMedication(undefined)
            }}
            preselectedMedication={existingMedication!}
          />
        )} */}

      {existingMedication &&
        existingMedication.type &&
        existingMedication.type === 'ayurveda' && (
          <EditMedicationAyurvedaIPD
            open={existingMedication !== undefined}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getMedicationsOfAppointment(
                  fhirAppointmentDetails.patient,

                  fhirAppointmentDetails.encounter,
                  false,
                  fhirAppointmentDetails.mainServiceRequest.supportingInfo
                )
              )
              setExistingMedication(undefined)
            }}
            onMedicalConditionAdded={() => {
              dispatch(
                getMedicationsOfAppointment(
                  fhirAppointmentDetails.patient,

                  fhirAppointmentDetails.encounter,
                  false,
                  fhirAppointmentDetails.mainServiceRequest.supportingInfo
                )
              )
              setExistingMedication(undefined)
            }}
            preselectedMedication={existingMedication!}
          />
        )}
    </Box>
  )
}
