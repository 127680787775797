import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  ListSubheader,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedLabResults } from 'models/groupedLabResults'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloReports } from 'models/WelloConditions'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'

import { getReportsForSocketIPD } from 'redux/consultation/reports/reportsSearchSlice'

import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'

import { logger } from 'utils/logger'

import SocketContext from 'views/contexts/socketMessageContext'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface VitalsProp {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  split: boolean
}

export const LabReportsIPD: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  split,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const reportsSearchSlice = useSelector(
    (state: RootState) => state.reportsSearchSlice
  )

  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })

  const [reportData, setReportData] = useState<WelloReports>()

  const { eventData } = useContext(SocketContext)

  function fetchReports() {
    setFetchingStatus({
      requesting: true,
    })
    getReportsForSocketIPD(fhirAppointmentDetails)
      .then((e) => {
        if (e) {
          setReportData(e)
          setFetchingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    const convertedData = eventData as any
    console.log(JSON.stringify(convertedData))
    console.log(convertedData)
    if (convertedData !== null) {
      fetchReports()
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])

  //   useEffect(() => {
  //     if (
  //       fhirAppointmentDetails.encounter ||
  //       fhirAppointmentDetails.appointment.basedOn
  //     )
  //       dispatch(getLabReportsForTheAppointment(fhirAppointmentDetails, split))
  //   }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    fetchReports()
  }, [])

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'> Lab Reports</Typography>
            </Box>{' '}
          </Grid>

          {fetchingStatus.requesting && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {reportData &&
            reportData.diagnosticReports &&
            reportData.diagnosticReports.length === 0 &&
            reportData.reportFiles &&
            reportData.reportFiles.length === 0 && (
              <Grid item>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  {!isUnitAdmin() || !split ? '' : 'No data available'}
                </Typography>
              </Grid>
            )}

          {/* {reportData &&
              reportData.reportFiles &&
              reportData.reportFiles.length > 0 && (
                <Grid item>
                  <ReportTileForAppointment
                    reports={reportData.reportFiles}
                    type='Report'
                    appointmentId={fhirAppointmentDetails.appointment.id!}
                  />
                </Grid>
              )} */}

          {/* {reportData &&
            reportData.diagnosticReports &&
            reportData.diagnosticReports.length > 0 && (
              <Grid item>
                <ReportTileForAppointmentDiagnosticReport
                  reports={reportData.diagnosticReports}
                  type='Report'
                />
              </Grid>
            )} */}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
