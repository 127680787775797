import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { createBundleObjectForRequests } from 'utils/appointment_handle/medications_util'
import { getCarePlanOfPatientWithCurrentPractitioner } from 'utils/careplan_utils/careplan_utils'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { createBundleObjectForWellnessReferral } from 'utils/fhirResoureHelpers/referralHelpers'
import { MedicationAddStatus as AddWellnessReferralsStatusTypes } from './addWellnessReferralsStatusTypes'

const initialState: AddWellnessReferralsStatusTypes = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addWellnessReferralsSlice = createSlice({
  name: 'addWellnessReferralsSlice',
  initialState,
  reducers: {
    updateAddMedicationsStatus(
      state,
      action: PayloadAction<AddWellnessReferralsStatusTypes>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetMedicationsDetails(
      state,
      action: PayloadAction<AddWellnessReferralsStatusTypes>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.allergy = initialState.allergy
    },
  },
})

export const addWellnessReferralDetails =
  (
    appointment: FhirAppointmentDetail,
    medications: R4.IHealthcareService,
    extraInfo?: string,
    medicationRequests?: R4.IMedicationRequest[],
    communicationRequests?: R4.ICommunicationRequest[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddWellnessReferralsStatusTypes = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addWellnessReferralsSlice.actions.updateAddMedicationsStatus(addingState)
    )

    const workflowClient = new EnrolCient()

    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    try {
      const carePlanOfPatient =
        await getCarePlanOfPatientWithCurrentPractitioner(
          appointment.patient.id!
        )
      const res = await workflowClient.doCreateEnrolmentFlowRequest(
        'referral',
        {
          patientId: appointment.patient.id,
          referralServiceId: medications.id,
          extraNotes: extraInfo,
          unitId: `Organization/${getCurrentUserUnitDetails().id!}`,
          carePlanId: carePlanOfPatient?.id,
        }
      )

      const resFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(res)

      if (resFhirDecodeRes._tag === 'Right') {
        const bundleObject: R4.IBundle = createBundleObjectForWellnessReferral(
          appointment,
          encounter,
          medications,
          carePlanOfPatient?.id,
          extraInfo
        )

        const fhirClient: FHIRApiClient = new FHIRApiClient()
        const response = await fhirClient.doCreateFHIRTransaction(
          '',
          bundleObject
        )
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        if (relatedFhirDecodeRes._tag === 'Right') {
          if (relatedFhirDecodeRes.right) {
            addingState = {
              adding: false,
              additionSuccessful: true,
              error: false,
              errorMessage: '',
            }
            dispatch(
              addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
                addingState
              )
            )
          } else {
            const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
              adding: false,
              additionSuccessful: false,
              error: true,
              errorMessage: 'Error while referral details',
            }
            dispatch(
              addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
                errorCreatePersonState
              )
            )
            return
          }
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while adding referral details',
          }
          dispatch(
            addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      } else {
        const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage:
            'Error while creating referral details in other organization',
        }
        dispatch(
          addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const addCDSMedicationsDetails =
  (
    appointment: FhirAppointmentDetail,
    medications: R4.IMedicationRequest[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddWellnessReferralsStatusTypes = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addWellnessReferralsSlice.actions.updateAddMedicationsStatus(addingState)
    )
    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    try {
      const bundleObject: R4.IBundle = createBundleObjectForRequests(
        appointment,
        encounter,
        medications
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,

            error: false,
            errorMessage: '',
          }
          dispatch(
            addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
              addingState
            )
          )
          dispatch(showSuccessAlert('Medications added successfully'))
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while allergy details',
          }
          dispatch(
            addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      } else {
        const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding medications',
        }
        dispatch(
          addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addWellnessReferralsSlice.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddMedicationsState = () => (dispatch: AppDispatch) => {
  dispatch(
    addWellnessReferralsSlice.actions.resetMedicationsDetails(initialState)
  )
}

export default addWellnessReferralsSlice.reducer
