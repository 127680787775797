import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObsDefMatched,
  getObservationCodeText,
  getObservationContent,
  getRangeForLabTest,
  getRangeValuesAlongWithUnit,
  getRangeValuesAppintment,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import {
  getErrorStatus,
  getErrorStatusForLabClinic,
  getErrorStatusForLabTest,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'

interface ExaminationTileProps {
  obsData: R4.IObservation[]
  type?: string
}

export const LabResultTileHistory: React.FC<ExaminationTileProps> = ({
  obsData,

  type,
}: ExaminationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: kDialogueBackground,
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}
        {obsData.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid container direction='row' id='vitals'>
              <Grid
                item
                xs={3}
                style={{
                  backgroundColor: index % 2 ? kDialogueBackground : '#EDF2FA',
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            textTransform: 'capitalize',
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getObservationCodeText(e)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  backgroundColor: index % 2 ? kDialogueBackground : 'white',
                }}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {type && (
                        <Typography
                          variant='subtitle2'
                          //   color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            color:
                              getNotesString(e.note).length > 0
                                ? 'red'
                                : 'black',
                          }}
                        >
                          {getObservationContent(e)}
                        </Typography>
                      )}

                      {type === undefined && (
                        <Typography
                          variant='subtitle2'
                          //   color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            color:
                              getNotesString(e.note).length > 0
                                ? 'red'
                                : 'black',
                          }}
                        >
                          {getObservationContent(e)}
                        </Typography>
                      )}
                    </Box>

                    {/* <Divider
                          orientation='horizontal'
                          flexItem
                          style={{
                            height: '2px',
                          }}
                        /> */}
                  </Box>
                  <Box display='flex' width='2%'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: index % 2 ? kDialogueBackground : 'white',
                }}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {type === undefined && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getRangeValuesAppintment(e)}
                        </Typography>
                      )}

                      {type && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getRangeValuesAppintment(e)}
                        </Typography>
                      )}
                    </Box>

                    {/* <Divider
                          orientation='horizontal'
                          flexItem
                          style={{
                            height: '2px',
                          }}
                        /> */}
                  </Box>
                  {/* <Box display='flex' width='2%'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      {showText && (
        <NoteDialog
          id='notesTile'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Remarks'
        />
      )}
    </Box>
  )
}
