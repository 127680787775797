import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import {
  getIdOfCardEntry,
  getInstructions,
  hasError,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import CPGSelectionContext from 'views/contexts/cpgContextSelection'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddInstructionsButton } from '../add_instructions_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const CarePlanInstructionsOfCPGRecommendation: React.FC<CdsCardDetailsProps> =
  ({ carePlanIdentifier, cdsCard: card }: CdsCardDetailsProps) => {
    const classes = useStyles()

    const visitContext = useContext(VisitAppointmentContext)

    const labDiagnosticTestCodes = useSelector(
      (state: RootState) =>
        state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
    )
    const cdsRecommendationAddition = useContext(CPGSelectionContext)

    const selectedMedications = getSelectionContextTypeSections({
      cpgIdentifier: carePlanIdentifier,
      selectionCategoryType: 'medications',
      selectionData: cdsRecommendationAddition.selectionData ?? [],
    })

    function onSelectedMedicationsChanged(changedTest: string) {
      cdsRecommendationAddition.addSelection(
        carePlanIdentifier,
        'medications',
        changedTest
      )
    }

    return (
      <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
        {!hasError(card) &&
          card.interceptedSuggestions &&
          card.interceptedSuggestions.has('instructions') && (
            <Box
              display='flex'
              flexDirection='column'
              key='carePlanInstructions_cpg_outer_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                display='flex'
                flexDirection='column'
                key='carePlanInstructions_cpg_inner_label'
                py={0.25}
                overflow='auto'
              >
                {(card.interceptedSuggestions.get('instructions') as any[]).map(
                  (app, index) => (
                    <Box
                      width='100%'
                      flex='row'
                      id='referrals_list'
                      display='flex'
                      overflow='auto'
                      paddingTop={1}
                      key={app.id}
                    >
                      <Typography
                        className={classes.subTitleText}
                        display='inline'
                        style={{
                          whiteSpace: 'pre-line',
                        }}
                        id={`cpg_instructions_${index}`}
                        dangerouslySetInnerHTML={{
                          __html: app.payload?.[0].contentString ?? '',
                        }}
                      />
                    </Box>
                  )
                )}

                <Box
                  width='100%'
                  flexDirection='row-reverse'
                  id='referrals_list'
                  display='flex'
                >
                  <AddInstructionsButton
                    carePanBundle={
                      card.interceptedSuggestions.get('instructions') as any[]
                    }
                    carePlanIdentifier={getIdOfCardEntry(card)}
                  />
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    )
  }
