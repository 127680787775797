import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Box,
  Typography,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kPrimaryMain, kSelectedTabBackground } from 'configs/styles/muiThemes'

import React, { useEffect } from 'react'
import { Icon } from '@iconify/react'
import cross from '@iconify-icons/charm/cross'
import { logger } from 'utils/logger'
import { WelloFormItemLabel } from './LeftMenu/WelloFormItemLabel'
// import { WelloFormItemLabel } from './LeftMenu/WelloFormItemLabel'

export interface TabOption {
  value: string
  displayText: string
}

export interface TabbedMultiSelectorProps {
  options: TabOption[]
  preSelectedValues: string[]
  onSelectionChanged: (option: string[]) => void
  menuLabel?: string
  size?: 'small' | 'medium' | 'large'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export function TabbedMultiSelectorNew(props: TabbedMultiSelectorProps) {
  const [values, setValues] = React.useState(props.preSelectedValues)

  const handleSelection = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[]
  ) => {
    if (newAlignment.length > 0) {
      setValues(newAlignment)
      props.onSelectionChanged(newAlignment)
    }
  }

  const classes = useStyles()

  const defaultBackgroundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  return (
    <StyledToggleButtonGroup
      size={props.size ?? 'small'}
      value={values}
      onChange={handleSelection}
      aria-label='text alignment'
      style={{
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        gap: '8px',
        justifyContent: 'space-between',
      }}
      //   id={value}
    >
      {props.options.map((option, index) => (
        <ToggleButton
          //   id={value}
          key={`tg_button_key${option.value}`}
          value={option.value}
          aria-label='left aligned'
          id={`tg_button_key${option.value}`}
          style={{
            background: values.includes(option.value)
              ? kPrimaryMain
              : 'transparent',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingBottom: '4px',
            paddingTop: '4px',
            borderRadius: 100,
            borderColor: values.includes(option.value)
              ? kPrimaryMain
              : '#E6E6E6',
            borderStyle: 'solid',
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderLeftWidth: 1,

            borderRightWidth: 1,
            flexGrow: 1,
            display: 'flex',
          }}
        >
          <Typography
            variant='subtitle2'
            style={{
              color: values.includes(option.value) ? '#FFFFFF' : '#1C274C',
              letterSpacing: '1.0',
              paddingLeft: 8,
              paddingRight: values.includes(option.value) ? 2 : 8,
              fontSize: 12,
              fontWeight: values.includes(option.value) ? 700 : 500,
              fontFeatureSettings: `'dlig' on`,
              fontVariantNumeric: 'lining-nums proportional-nums',
            }}
          >
            {option.displayText}
          </Typography>
          {values.includes(option.value) && (
            <Icon
              icon='charm:cross'
              style={{
                color: values.includes(option.value) ? '#FFFFFF' : '#1C274C',
                fontSize: 16,
              }}
            />
          )}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
