/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getPaymentUpdateForLabOrder } from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { downloadingDocument } from 'redux/lab/download/downloadInvoice'
import {
  fetchOrderDetails,
  requestCancellationOfOrder,
  requestRescheduleOfOrder,
  requestUpdateStatusOfOrder,
} from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { RootState } from 'redux/rootReducer'
import {
  onSiteOrderStatusTypes,
  orderStatusTypes,
} from 'utils/constants/order_status'
import {
  checkForOrderStatus,
  getFinalReports,
  getFinalReportsWithStatusCurrent,
  getOrderFinalStatusText,
  isHomeOrder,
  isLabOrderEditable,
  isLabOrderStatusChangeable,
  isOnsiteOrder,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { WelloSelectFHIRTest } from 'views/components/lab/common/WelloSelectFHIRTest'
import { PatientTileSplit } from 'views/components/patients/patientTileSplit'
import SocketContext from 'views/contexts/socketMessageContext'
import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'
import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
import { PaymentTile } from '../orderCreationManagement/paymentTile'
import { RefundTile } from '../orderCreationManagement/refundTile'
import { DiagnosticReport } from './diaognsticReportTile'
import { OrderAppointmentDetails } from './orderAppointmentDetails'
import { OrderCancellationHandler } from './orderCancellationHandler'
import { OrderReScheduleHandler } from './orderRescheduleHandler'
import { OrderTimeLine } from './orderTimeLine'
import { ReportTile } from './reportTile'

interface Props {
  orderDetail: FhirLabOrderDetail
  isOdd?: boolean
  onCloseClicked: () => void
  dense?: boolean
}

export const LabOrderDetails: React.FC<Props> = ({
  orderDetail,
  isOdd = false,
  onCloseClicked,
  dense = false,
}: Props) => {
  const orderManagementSlice = useSelector(
    (state: RootState) => state.orderManagementSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<any>()
  const [reason, setReason] = useState<string>()
  const [columns, setColumns] = useState<string[]>([])
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [showRescheduleHandler, setShowRescheduleHandler] =
    useState<boolean>(false)
  const [labOrderDetail, setLabOrderDetail] =
    useState<FhirLabOrderDetail>(orderDetail)

  const [paymentDetails, setPaymentDetails] =
    useState<FhirLabOrderDetail>(orderDetail)

  const { eventData } = useContext(SocketContext)

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const arr: string[] = []

  useEffect(() => {
    dispatch(fetchOrderDetails(labOrderDetail.serviceRequest.id ?? ''))
    return () => {}
  }, [])

  useEffect(() => {
    if (orderManagementSlice.orderDetailsAvailable) {
      if (orderManagementSlice.orderDetail) {
        if (orderManagementSlice.orderDetail.statuses) {
          for (
            let i = 0;
            i < orderManagementSlice.orderDetail.statuses.length;
            i++
          ) {
            const data =
              orderManagementSlice.orderDetail.statuses[i].activity?.coding ??
              []
            arr.push(data[0].code ?? '')
          }
        }
        if (checkForOrderStatus(arr) && isOnsiteOrder(labOrderDetail)) {
          arr.push('processing_samples')
        }

        setColumns(arr)
        setLabOrderDetail(orderManagementSlice.orderDetail)
      }
    }
    return () => {}
  }, [orderManagementSlice.orderDetailsAvailable])

  function requestAppointmentForPolling() {
    if (
      paymentDetails &&
      paymentDetails.paymentReconilation &&
      paymentDetails.paymentReconilation.outcome &&
      paymentDetails.paymentReconilation.outcome !== 'complete'
    ) {
      getPaymentStatus(paymentDetails)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  function getPaymentStatus(appointmentDetailsData: FhirLabOrderDetail) {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getPaymentUpdateForLabOrder(appointmentDetailsData)
      .then((e: FhirLabOrderDetail) => {
        setLoading(false)
        if (e) {
          setPaymentDetails(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching payment update. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    const convertedData = eventData as any

    if (convertedData !== null) {
      if (
        paymentDetails &&
        paymentDetails.paymentReconilation &&
        paymentDetails.paymentReconilation.outcome &&
        paymentDetails.paymentReconilation.outcome !== 'complete'
      ) {
        getPaymentStatus(paymentDetails)
      }
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Typography variant='h6' color='initial'>
                {`${t('labelCommon:order')}#${
                  labOrderDetail!.serviceRequest.id ?? ''
                }`}
              </Typography>
              <Box display='flex' flexDirection='row'>
                <Tooltip title='Close'>
                  <IconButton
                    aria-label='btn_ord_detail_menu'
                    onClick={onCloseClicked}
                    id='close_details'
                  >
                    <CloseOutlined color='primary' />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              style={{ width: '100%', height: '2px' }}
              color='primary'
            />

            <Box display='flex' flexDirection='row' width='100%'>
              <Box display='flex' flexDirection='column' width='50%'>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel
                      title={t('labelCommon:patient_information')}
                    />
                    <Box border={1} borderRadius={4} borderColor='lightGrey'>
                      <PatientTileSplit patient={labOrderDetail.patient} />
                    </Box>
                  </Box>
                </Box>

                {labOrderDetail.serviceRequest.status &&
                  labOrderDetail.serviceRequest.status === 'completed' &&
                  labOrderDetail.reports &&
                  labOrderDetail.reports.length > 0 &&
                  getFinalReports(labOrderDetail.reportsDia ?? []).length ===
                    0 &&
                  getFinalReportsWithStatusCurrent(labOrderDetail.reports ?? [])
                    .length > 0 && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Reports' />

                      <ReportTile
                        reports={getFinalReportsWithStatusCurrent(
                          labOrderDetail.reports ?? []
                        )}
                      />
                    </Box>
                  )}

                {labOrderDetail.serviceRequest.status &&
                  //   labOrderDetail.serviceRequest.status === 'completed' &&
                  labOrderDetail.reportsDia &&
                  labOrderDetail.reportsDia.length > 0 && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Reports' />

                      <DiagnosticReport
                        reports={getFinalReports(
                          labOrderDetail.reportsDia ?? []
                        )}
                      />
                    </Box>
                  )}

                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel
                      title={t('labelCommon:appointment_details')}
                    />

                    <OrderAppointmentDetails
                      labOrderDetail={labOrderDetail}
                      showAddress={true}
                      showDate={true}
                    />
                  </Box>
                </Box>
                {labOrderDetail.tests && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel
                      title={t('labelCommon:test_restrictions')}
                    />
                    <RestrictionsOfSelectedOfferings
                      selectedOfferings={labOrderDetail.tests!}
                    />
                  </Box>
                )}

                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel
                      title={t('labelCommon:order_summary')}
                    />
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      border={1}
                      borderRadius={4}
                      borderColor='lightGrey'
                    >
                      <OrderAmountSummaryTile
                        planDefinitions={labOrderDetail.tests}
                        showTitle={false}
                      />
                    </Box>
                  </Box>
                </Box>
                {paymentDetails.paymentReconilation && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel
                        title={t('labelCommon:payment_status')}
                      />
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        border={1}
                        borderRadius={4}
                        borderColor='lightGrey'
                      >
                        {paymentDetails.paymentReconilation.status ===
                          'active' && (
                          <PaymentTile
                            start={labOrderDetail.start}
                            payment={paymentDetails.paymentReconilation}
                            patient={labOrderDetail.patient}
                          />
                        )}
                        {paymentDetails.paymentReconilation.status ===
                          'cancelled' && (
                          <RefundTile
                            start={labOrderDetail.start}
                            payment={paymentDetails.paymentReconilation}
                            patient={labOrderDetail.patient}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
                {paymentDetails.paymentReconilation && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    {(paymentDetails.paymentReconilation.status === 'active' ||
                      paymentDetails.paymentReconilation.status ===
                        'cancelled') &&
                      paymentDetails.paymentReconilation.outcome ===
                        'complete' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <Box py={1.2}>
                            <img
                              src={`${process.env.PUBLIC_URL}/bill.ico`}
                              height={15}
                              width={15}
                              alt=''
                            />
                          </Box>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            py={1}
                            paddingLeft={0.5}
                          >
                            <Link
                              onClick={() => {
                                dispatch(
                                  downloadingDocument(
                                    'ServiceRequest',
                                    paymentDetails.serviceRequest.id ?? '',
                                    paymentDetails.paymentReconilation
                                      ? paymentDetails.paymentReconilation.id ??
                                          ''
                                      : ''
                                  )
                                )
                              }}
                              color='primary'
                              id='download_invoice_order'
                            >
                              <Typography
                                variant='subtitle1'
                                color='primary'
                                style={{ cursor: 'pointer' }}
                                id='download_invoice_order_text'
                              >
                                Download Invoice
                              </Typography>
                            </Link>
                          </Box>
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
              <Box
                width='50%'
                display='flex'
                flexDirection='column'
                py={1}
                px={2}
                alignContent='center'
              >
                {orderManagementSlice.fetchingOrderDetails && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignContent='center'
                  >
                    <WelloLoadingIndicator message='Fetching current status' />
                  </Box>
                )}
                {labOrderDetail.statuses && labOrderDetail.statuses.length > 0 && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='start'
                  >
                    <WelloFormItemLabel title={t('labelCommon:order_status')} />
                  </Box>
                )}

                {labOrderDetail.statuses &&
                  labOrderDetail.statuses.length > 0 && (
                    <OrderTimeLine labOrderDetail={labOrderDetail} />
                  )}
                {labOrderDetail.task && (
                  <Box>
                    {labOrderDetail.task.businessStatus?.coding &&
                      labOrderDetail.task.status &&
                      (labOrderDetail.task.status.includes('received') ||
                        labOrderDetail.task.status.includes('ready')) &&
                      labOrderDetail.statuses &&
                      labOrderDetail.statuses.length > 0 &&
                      isHomeOrder(labOrderDetail) && (
                        <Box>
                          <WelloSelectFHIRTest
                            title={t('labelCommon:change_status')}
                            type={t('Select Test ')}
                            id='order_status_update'
                            selectedColumn={columns}
                            preSelectedCoding={
                              labOrderDetail.task.businessStatus.coding[0]
                            }
                            availableCodings={orderStatusTypes}
                            onChanged={(e) => {
                              if (e) {
                                setStatus(e)
                              }
                            }}
                            textFieldProps={{
                              size: 'small',
                              placeholder: t('Select Test'),
                              required: true,
                            }}
                          />
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='20%'
                            py={1}
                          >
                            <Button
                              onClick={() => {
                                dispatch(
                                  requestUpdateStatusOfOrder(
                                    labOrderDetail,
                                    status,
                                    reason
                                  )
                                )
                                onCloseClicked()
                              }}
                              id='order_update_details'
                              variant='contained'
                              color='primary'
                              disableElevation
                              disabled={
                                labOrderDetail.task?.status === 'completed' ||
                                labOrderDetail.task?.status === 'in-progress'
                              }
                            >
                              {t('labelCommon:update')}
                            </Button>
                          </Box>
                        </Box>
                      )}

                    {labOrderDetail.task.businessStatus?.coding &&
                      labOrderDetail.task.status &&
                      (labOrderDetail.task.status.includes('in-progress') ||
                        labOrderDetail.task.status.includes('ready') ||
                        labOrderDetail.task.status.includes('received') ||
                        labOrderDetail.task.status.includes('accepted')) &&
                      isOnsiteOrder(labOrderDetail) &&
                      isLabOrderStatusChangeable(labOrderDetail) && (
                        <Box>
                          <WelloSelectFHIRTest
                            title={t('labelCommon:change_status')}
                            type={t('Select Test ')}
                            id='onsite_update_order'
                            selectedColumn={columns}
                            preSelectedCoding={
                              labOrderDetail.task.businessStatus.coding[0]
                            }
                            availableCodings={onSiteOrderStatusTypes}
                            onChanged={(e) => {
                              if (e) {
                                setStatus(e)
                              }
                            }}
                            textFieldProps={{
                              size: 'small',
                              placeholder: t('Select Test'),
                              required: true,
                            }}
                          />
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='20%'
                            py={1}
                          >
                            <Button
                              onClick={() => {
                                dispatch(
                                  requestUpdateStatusOfOrder(
                                    labOrderDetail,
                                    status,
                                    reason
                                  )
                                )
                                onCloseClicked()
                              }}
                              variant='contained'
                              color='primary'
                              disableElevation
                              disabled={
                                labOrderDetail.partnerLabTask?.status ===
                                  'completed' ||
                                labOrderDetail.paymentReconilation?.outcome ===
                                  'queued'
                              }
                              id='onsite_order_button'
                            >
                              {t('labelCommon:update')}
                            </Button>
                          </Box>
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <OrderCancellationHandler
        open={showCancelHandler}
        onClose={() => {
          setShowCancelHandler(false)
        }}
        orderDetail={labOrderDetail}
        onCancelConfirmed={(cancelReason, note) => {
          dispatch(
            requestCancellationOfOrder(labOrderDetail, cancelReason, note)
          )
          setShowCancelHandler(false)
        }}
      />

      <OrderReScheduleHandler
        open={showRescheduleHandler}
        onClose={() => {
          setShowRescheduleHandler(false)
        }}
        orderDetail={labOrderDetail}
        onReScheduleConfirmed={(cancelReason, note, newSlot) => {
          dispatch(
            requestRescheduleOfOrder(
              labOrderDetail,
              cancelReason,
              newSlot,
              note
            )
          )
          setShowRescheduleHandler(false)
        }}
      />
    </Box>
  )
}
