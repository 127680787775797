import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Tab, Tabs } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetAddSCPG } from 'redux/consultation/recommendation_additions/instruction_addition/instrutionAdditionSlice'
import { CDSResponse } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { RootState } from 'redux/rootReducer'
import { WelloLoadingIndicator } from 'wello-web-components'
import { CdsCardDetails } from './cdsCardDetailsDisply'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: 'auto', height: '100%' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

interface MedicationsListProps {
  patient: R4.IPatient
  encounter: R4.IEncounter
  cdsResponse?: CDSResponse
  preSelectedIndex?: number
}

export const CdsRecommendationsList: React.FC<MedicationsListProps> = ({
  patient,
  encounter,
  cdsResponse,
  preSelectedIndex,
}: MedicationsListProps) => {
  const initialFetch = useRef(true)

  const [value, setValue] = React.useState(preSelectedIndex ?? 0)

  const { t } = useTranslation(['common'])

  const patientMedicalTimeLineSlice = useSelector(
    (state: RootState) => state.cdsRecommendationsSlice
  )

  const dispatch = useDispatch()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(resetAddSCPG())
    setValue(newValue)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      overflow='auto'
      style={{ overflow: 'auto' }}
    >
      <Box
        display='flex'
        flexDirection='row'
        flexGrow={1}
        overflow='auto'
        style={{ overflow: 'auto' }}
      >
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '500px' }}
          width='100%'
        >
          {patientMedicalTimeLineSlice.searchingAppointments && (
            <WelloLoadingIndicator />
          )}

          {patientMedicalTimeLineSlice.errorWhileSearchingAppointments &&
            patientMedicalTimeLineSlice.errorReason && (
              <Box>
                {' '}
                {JSON.stringify(patientMedicalTimeLineSlice.errorReason)}
              </Box>
            )}

          {patientMedicalTimeLineSlice.resultsAvailable &&
            patientMedicalTimeLineSlice.availableAppointments &&
            patientMedicalTimeLineSlice.availableAppointments.cards && (
              <Box display='flex' flexDirection='column' overflow='auto'>
                <Box display='flex' flexDirection='row'>
                  <Tabs
                    value={value}
                    indicatorColor='primary'
                    textColor='primary'
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    aria-label='disabled tabs example'
                    id='tab'
                  >
                    {patientMedicalTimeLineSlice.availableAppointments.cards.map(
                      (e, index: number) => (
                        <Tab
                          key={`${e.summary}_tab`}
                          label={e.summary ?? e.indicator}
                          id={`tab${index}`}
                        />
                      )
                    )}
                  </Tabs>
                </Box>
                <Box
                  style={{
                    overflow: 'auto',
                    backgroundColor: 'white',
                  }}
                >
                  {patientMedicalTimeLineSlice.availableAppointments.cards.map(
                    (card, index) => (
                      <TabPanel
                        value={value}
                        index={index}
                        key={`${card.summary}_details`}
                      >
                        <CdsCardDetails
                          cdsCard={card}
                          encounter={encounter}
                          patient={patient}
                          key={`${card.summary}_details_tab`}
                          mainIndex={`details-${index}`}
                        />
                      </TabPanel>
                    )
                  )}
                </Box>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  )
}
