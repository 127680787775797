import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
  Info,
} from '@material-ui/icons'
import AodIcon from '@mui/icons-material/Aod'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  doctorMainBackColor,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedProceDure, GroupedProcedureData } from 'models/groupedProcedure'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestProcedureHistoryOfPatient } from 'redux/patientMedicalHistory/proceduresHistory/proceduresHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeCodeData, getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasAddedBySameDoctor,
  hasAddedBySameDoctorForMultiple,
  hasAddedBySameDoctorForMultipleForSymtomProfile,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  updateConditionDetailsFollowup,
  updateProcedureDetail,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getAppointmentIdForFollowUp,
  getValueCoDeFromValueCodeableForSoapNotes,
  getValueCodingFromExtension,
  getValueRefValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrlForBasic,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { GroupedConditionData } from 'models/groupedCondition'
import { requestSymptomOfPatient } from 'redux/patientMedicalHistory/medicationHistory/symptomProfileHistory/sysmptomProfileSearchSlice'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { WelloCondition } from 'models/WelloConditions'
import _, { split } from 'lodash'
import { EditSymptomProfile } from 'views/components/consultation/edition/editSymptomProfile'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { statusCodings } from 'utils/constants'

import { AddSymptomProfile } from './addSymptomProfile'

interface ProceduresProp {
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  appointmentDetails: FhirAppointmentDetail
  requestingAppointmentId?: string
  status: string
  splitview: boolean
  encounterId?: string
}

export const SymptomProfile: React.FC<ProceduresProp> = ({
  patient,
  requestingAppointmentId,
  purposeOfUse,
  appointmentDetails,
  status,
  splitview,
  encounterId,
}: ProceduresProp) => {
  const { t } = useTranslation(['common', 'procedure'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const medicalHistorySlice = useSelector(
    (state: RootState) => state.symptomProfileSearchSlice
  )

  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)

  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [groupVitals, setGroupVitals] = useState<GroupedConditionData[]>([])

  const [showEdit, setShowEdit] = useState<boolean>(false)

  const [currentCondition, setCurrentCondition] = useState<WelloCondition>({
    id: _.random(1, 10000000).toString(),
    selectedType: undefined,
    days: 1,
    month: 1,
    year: 1,
    week: 1,
    selectedDate: new Date(),
    isActive: true,
  })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function updateConditionsForStatus(
    condition: R4.ICondition,
    statusCoding: R4.ICoding
  ) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.ICondition = {
      ...condition,
    }

    if (oldProcedure.extension) {
      const codingData = getValueCodingFromExtension(
        oldProcedure.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
      )
      if (codingData) {
        oldProcedure.extension =
          removeExtensionFromExtensions(
            oldProcedure.extension,
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
          ) ?? []
      }

      oldProcedure.extension = [
        ...oldProcedure.extension,
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
          valueCoding: statusCoding,
        },
      ]
    } else {
      oldProcedure.extension = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
          valueCoding: statusCoding,
        },
      ]
    }
    setLoading(true)

    updateConditionDetailsFollowup(
      oldProcedure,
      getAppointmentIdForFollowUp(
        appointmentDetails.appointment.supportingInformation ?? []
      )
    ).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showSuccessAlert(
            'Symptom Profile status details updated successfully'
          )
        )
        setGroupVitals([])

        dispatch(
          requestSymptomOfPatient(
            patient,
            purposeOfUse,
            splitview,
            requestingAppointmentId,
            encounterId
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Symptom Profile. Please try again later'
          )
        )
      }
    })
  }

  function createUpdateConditionData(conditionData: R4.ICondition) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.code
        ? conditionData.code.coding && conditionData.code.coding.length > 0
          ? conditionData.code.coding[0]
          : undefined
        : undefined,
      symptomProfile: conditionData.code,
      selectedType: conditionData.onsetDateTime
        ? 'custom'
        : getAgeOf(conditionData.onsetAge).split(' ')[1].toLowerCase(),
      days: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'd'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      week: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'wk'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      year: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'a'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      month: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'mo'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      existingCondition: conditionData,
      selectedDate: conditionData.onsetDateTime
        ? moment(conditionData.onsetDateTime).toDate()
        : moment().toDate(),
    })
  }

  useEffect(() => {
    dispatch(
      requestSymptomOfPatient(
        patient,
        purposeOfUse,
        splitview,
        requestingAppointmentId,
        encounterId
      )
    )
  }, [dispatch, patient])

  useEffect(() => {
    if (
      medicalHistorySlice.resultsAvailable &&
      medicalHistorySlice.dateWiseGroupped
    ) {
      updateConditions(medicalHistorySlice.dateWiseGroupped)
    }
  }, [medicalHistorySlice])

  function updateConditions(conditionList: GroupedConditionData[]) {
    const results: GroupedConditionData[] = []
    for (let i = 0; i < conditionList.length; i++) {
      results.push({
        date: conditionList[i].date,
        condition: conditionList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedConditionData[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <>
      <Box
        flexGrow
        width='100%'
        flexDirection='column'
        display='flex'
        style={{ padding: 0, margin: 0 }}
      >
        <Box
          flexGrow
          width='100%'
          display='flex'
          flexDirection='row'
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Box flexDirection='row' display='flex' flexGrow width='100%'>
            <Box py={1}>
              <Typography variant='subtitle1'> Symptom Profile</Typography>
            </Box>{' '}
            {/* {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitView && (
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                >
                  <Add style={{ height: '14px' }} color='primary' />
                </IconButton>
              )} */}
          </Box>
        </Box>
        {isMedicalServiceProvider() && status !== 'completed' && !splitview && (
          <Box
            display='flex'
            flexGrow
            flexDirection='row'
            justifyContent='flex-start'
            paddingBottom={1}
          >
            <Tooltip title='' id='proc_1'>
              <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                id='proc_2'
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
                style={{ padding: 0 }}
              >
                <Typography
                  variant='subtitle2'
                  color='primary'
                  style={{ fontSize: 13 }}
                  component={Link}
                  id='proc_4'
                >
                  {' '}
                  Symptom Profile
                </Typography>
                <AddCircleIcon
                  style={{ height: '22px', padding: 0 }}
                  color='primary'
                  id='proc_3'
                />{' '}
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {medicalHistorySlice.searchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {medicalHistorySlice.noResultsAvailable && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              {!isMedicalServiceProvider() ||
              status === 'completed' ||
              splitview
                ? 'No data available'
                : ''}
            </Typography>
          </Box>
        )}
        {medicalHistorySlice.errorWhileSearchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              color='error'
              style={{
                fontWeight: 400,
              }}
            >
              {medicalHistorySlice.errorReason ??
                'Error while fetching symptoms'}
            </Typography>
          </Box>
        )}
        {medicalHistorySlice.resultsAvailable &&
          medicalHistorySlice.availableConditions && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%' flexGrow>
                {groupVitals.map((val, index: number) => (
                  <Box
                    width='100%'
                    py={1}
                    key={`med_cond${val.date}`}
                    id={`${moment(val.date!).format(
                      'DD-MM-YYYY'
                    )}profile${index}`}
                  >
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor:
                          hasAddedBySameDoctorForMultipleForSymtomProfile(
                            val.condition
                          )
                            ? 'lightGrey'
                            : doctorMainBackColor,
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        alignItems='center'
                        width='70%'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date!).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box
                        justifyContent='flex-end'
                        display='flex'
                        alignItems='center'
                        width='30%'
                      >
                        {hasAddedBySameDoctorForMultiple(val.condition) ===
                          false && (
                          <Box paddingRight={1} justifyContent='flex-end'>
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                          </Box>
                        )}
                        <Box px={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                              onClick={() => {
                                handleCollapseForPanel1(!val.checked, index)
                              }}
                            >
                              {val.checked && <ArrowDropUpOutlined />}
                              {!val.checked && <ArrowDropDownOutlined />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    <Collapse
                      in={val.checked}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '3px 0px',
                          borderRadius: '4px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                        key={`med_cond${val.date}`}
                      >
                        <Grid container direction='column' spacing={1}>
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={8}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    minHeight={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Symptoms
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                  paddingRight={0.2}
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Onset Duration
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                  paddingRight={0.2}
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Current Status
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          {val.condition.map((valData, indexFinal: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              key={valData.id}
                              id={`${moment(val.date!).format(
                                'DD-MM-YYYY'
                              )}profileDataList${index}`}
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={1}
                                id={`${moment(val.date!).format(
                                  'DD-MM-YYYY'
                                )}profileDataListColumn${indexFinal}`}
                                wrap='nowrap'
                              >
                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  direction='row'
                                  id={`${moment(val.date!).format(
                                    'DD-MM-YYYY'
                                  )}profileDataRow${indexFinal}`}
                                  style={{
                                    backgroundColor:
                                      indexFinal % 2
                                        ? kDialogueBackground
                                        : 'white',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}profileData${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='90%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.meta &&
                                            valData.meta.security &&
                                            valData.meta.security[0].code ===
                                              'PATRPT' && (
                                              <Box paddingRight={1}>
                                                <Tooltip title=''>
                                                  <IconButton
                                                    aria-label='collapse_order_type'
                                                    size='small'
                                                  >
                                                    <AodIcon color='primary' />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            )}
                                          {valData.code &&
                                            valData.code!.coding &&
                                            valData.code!.coding.length > 0 &&
                                            valData.code!.coding![0]
                                              .display && (
                                              <OverflowTypography
                                                text={
                                                  valData.code!.coding![0]
                                                    .display!
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='10%'
                                        justifyContent='flex-end'
                                        paddingRight={0.3}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}profileDataOnSet${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='90%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {valData.onsetDateTime
                                              ? valData.clinicalStatus
                                                  ?.coding?.[0].code ===
                                                'active'
                                                ? `Since ${moment(
                                                    valData.onsetDateTime ??
                                                      new Date()
                                                  ).format('DD-MM-YYYY')}`
                                                : `Since ${moment(
                                                    valData.onsetDateTime ??
                                                      new Date()
                                                  ).format('DD-MM-YYYY')}`
                                              : valData.clinicalStatus
                                                  ?.coding?.[0].code ===
                                                'active'
                                              ? `Since  ${getAgeOf(
                                                  valData.onsetAge
                                                )}`
                                              : `${getAgeOf(
                                                  valData.onsetAge
                                                )} ago`}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='10%'
                                        paddingTop={0.2}
                                        justifyContent='flex-end'
                                        paddingRight={0.5}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2}>
                                    {/* {getValueRefValueExtensionsOfUrlForBasic(
                                      valData.extension ?? [],
                                      'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                    )} */}
                                    {getAppointmentIdForFollowUp(
                                      appointmentDetails.appointment
                                        .supportingInformation ?? []
                                    ) ===
                                      getValueRefValueExtensionsOfUrl(
                                        valData.extension ?? [],
                                        'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                      ).split('/')[1] && (
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        px={0.5}
                                        py={0.2}
                                      >
                                        <WelloSelectFHIRCoding
                                          id='edit'
                                          availableCodings={statusCodings}
                                          onChanged={(type) => {
                                            if (type && valData) {
                                              updateConditionsForStatus(
                                                valData,
                                                type
                                              )
                                            }
                                          }}
                                          textFieldProps={{
                                            size: 'small',
                                            fullWidth: true,
                                          }}
                                          preSelectedCoding={getValueCodingFromExtension(
                                            valData.extension ?? [],
                                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                          )}
                                          disabled={splitview}
                                        />
                                      </Box>
                                    )}
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      paddingRight={3}
                                      justifyContent='flex-end'
                                    >
                                      {hasAddedBySameDoctor(valData) &&
                                        getValueRefValueExtensionsOfUrl(
                                          valData.extension ?? [],
                                          'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                        ).split('/')[1] ===
                                          requestingAppointmentId! &&
                                        splitview === false && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            paddingLeft={1}
                                            paddingTop={1}
                                          >
                                            <Tooltip
                                              title='Edit'
                                              id='chief_tool_edit'
                                            >
                                              <IconButton
                                                aria-label='btn_ord_cancel'
                                                color='primary'
                                                id='symptom_tool_edit_button'
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                  createUpdateConditionData(
                                                    valData
                                                  )
                                                  setShowEdit(true)
                                                  // setChiefComplaintsDetails(val)
                                                }}
                                              >
                                                <img
                                                  id='chief_tool_edit_img'
                                                  src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                  alt='Edit'
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                        )}
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </Box>
      <AddSymptomProfile
        open={openAddDialogue}
        patient={patient}
        purposeOfUse={purposeOfUse}
        onMedicalConditionAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestSymptomOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId,
              encounterId
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
        appointmentId={
          purposeOfUse === PurposeOfUse.OPDAppointment
            ? requestingAppointmentId
            : undefined
        }
      />
      {showText && (
        <NoteDialog
          id='notesTile'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Surgery'
        />
      )}
      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
      <EditSymptomProfile
        open={showEdit}
        patient={patient}
        purposeOfUse={purposeOfUse}
        onMedicalConditionAdded={(condition) => {
          setShowEdit(false)
          dispatch(
            requestSymptomOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId,
              encounterId
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingCondition={currentCondition}
        appointmentId={requestingAppointmentId}
      />
    </>
  )
}
