/* eslint-disable react/display-name */
import MaterialTable, {
  MTableBodyRow,
  MTableToolbar,
} from '@material-table/core'
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import femaleIcon from '../../../assets/icons/f.png'
import maleIcon from '../../../assets/icons/m.png'
import { getTableDataFromFhir } from '../../../utils/common/patientDataConverter'
import OverflowTypography from '../consultation/plan/ayurveda/overflowTypography'
import { PatientProfile } from './patientProfile'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 4,
      paddingRight: 0,
    },
    '& .MuiTablePagination-root': {
      backGroundColor: 'red',
    },
  },
})

interface ICardProperties {
  patientData: FhirPatientDetail[]
  perPage: number
  pageNumber: number
  onPatientSelected: (selectedPatient: PatientDataWithAppointment) => void
  onRowsPerChange: (pageNo: number, perPage: number) => void
  total: number
}

export const PatientDataTable: React.FC<ICardProperties> = ({
  patientData,
  perPage,
  pageNumber,
  onPatientSelected,
  onRowsPerChange,
  total,
}: ICardProperties) => {
  const classes = useStyles()

  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])

  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)

  const handlePageChange = (pageNo: number, pageSize: number) => {
    onRowsPerChange(pageNo, pageSize)
  }

  const handleChangeRowsPerPage = (event: number) => {
    onRowsPerChange(0, event)
  }

  const { height, width } = useWindowDimensions()

  return (
    <Box
      style={{ overflow: 'none' }}
      paddingLeft={1.5}
      id='list38'
      width='100%'
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                color: '#333333',
                height: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),

          Container: (props) => <Paper {...props} elevation={0} />,

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
                height: '60px',
                color: '#333333',
              }}
            >
              <TablePagination
                {...props}
                on
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: '#ececec',
                  },
                }}
              />
            </div>
          ),
          Row: (props) => <MTableBodyRow id='list_body' {...props} />,
        }}
        columns={[
          {
            title: (
              <Box minWidth='150px' id='list1' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelPatient:attr_labels.Patient.name')}
                </Typography>
              </Box>
            ),
            field: 'patientName',
            filterPlaceholder: t('labelPatient:attr_labels.Patient.name'),

            cellStyle: {
              width: 150,
              maxWidth: 150,
            },
            render: (rowData: any) => (
              <Box id='list2'>
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{ wordWrap: 'break-word' }}
                >
                  {rowData.patientName.split('\n').map((i: any, key: any) => (
                    <div key={i}>{i}</div>
                  ))}
                </Typography>
              </Box>
            ),
          },
          {
            title: (
              <Box minWidth='50px' id='list4' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelCommon:age')}
                </Typography>
              </Box>
            ),
            field: 'ageString',

            render: (rowData: any) => (
              <Box id='list6'>
                <Typography variant='subtitle2' color='initial'>
                  {rowData.ageString}
                </Typography>
              </Box>
            ),
          },

          {
            title: (
              <Box minWidth='50px' id='list7' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelPatient:attr_labels.Patient.gender')}
                </Typography>
              </Box>
            ),
            filterPlaceholder: t('labelPatient:attr_labels.Patient.gender'),
            field: 'gender',

            cellStyle: {
              width: 50,
              maxWidth: 100,
            },
            render: (rowData: any) => (
              <Box paddingY={1} id='list8'>
                {rowData.gender === 'male' && (
                  <Box display='flex' alignItems='center'>
                    <img src={maleIcon} alt='' style={{ height: 20 }} />
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='initial'
                    >
                      &nbsp; Male
                    </Typography>
                  </Box>
                )}
                {rowData.gender === 'female' && (
                  <Box display='flex' alignItems='center'>
                    <img src={femaleIcon} alt='' style={{ height: 20 }} />
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='initial'
                    >
                      &nbsp; Female
                    </Typography>
                  </Box>
                )}
                {rowData.gender === 'other' && (
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='initial'
                    >
                      &nbsp; Other
                    </Typography>
                  </Box>
                )}
                {rowData.gender === 'unknown' && (
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='initial'
                    >
                      &nbsp; Unknown
                    </Typography>
                  </Box>
                )}
              </Box>
            ),
          },

          {
            title: (
              <Box minWidth='50px' id='list10' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelPatient:attr_labels.Patient.maritalStatus')}
                </Typography>
              </Box>
            ),
            filterPlaceholder: 'Marital Status ',
            field: 'maritalStaTus',

            cellStyle: {
              width: 150,
              maxWidth: 100,
            },
            render: (rowData: any) => (
              <Box paddingY={1} id='list11'>
                {rowData.maritalStaTus === 'Single' && (
                  <Box display='flex' alignItems='center'>
                    <Typography variant='subtitle2' color='initial'>
                      Single
                    </Typography>
                  </Box>
                )}
                {rowData.maritalStaTus === 'Married' && (
                  <Typography variant='subtitle2' color='initial'>
                    {rowData.maritalStaTus}
                  </Typography>
                )}
                {rowData.maritalStaTus === 'Divorced' && (
                  <Typography variant='subtitle2' color='initial'>
                    {rowData.maritalStaTus}
                  </Typography>
                )}

                {rowData.maritalStaTus === 'Widowed' && (
                  <Typography variant='subtitle2' color='initial'>
                    {rowData.maritalStaTus}
                  </Typography>
                )}
              </Box>
            ),
          },

          {
            title: (
              <Box id='list13' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelCommon:phone_number_label')}
                </Typography>
              </Box>
            ),
            filterPlaceholder: 'Phone Number ',
            field: 'phoneNumber',
            customSort: (a: any, b: any) =>
              a.phoneNumber > b.phoneNumber
                ? 1
                : b.phoneNumber > a.phoneNumber
                ? -1
                : 0,
            render: (rowData: any) => (
              <Box paddingY={1} id='list14'>
                <Typography variant='subtitle2' color='initial'>
                  {rowData.phoneNumber}
                </Typography>
              </Box>
            ),
          },

          {
            title: (
              <Box id='list16' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelCommon:email_address')}
                </Typography>
              </Box>
            ),
            filterPlaceholder: 'Email ',
            field: 'emailAddress',

            customSort: (a: any, b: any) =>
              a.emailAddress.localeCompare(b.emailAddress, 'en', {
                sensitivity: 'base',
              }),
            render: (rowData: any) => (
              <Box paddingY={1} id='list17'>
                <Typography noWrap variant='subtitle2' color='initial'>
                  {rowData.emailAddress}
                </Typography>
              </Box>
            ),
          },

          {
            title: (
              <Box id='list19' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelCommon:nationality_label')}
                </Typography>
              </Box>
            ),
            filterPlaceholder: 'Nationality ',
            field: 'nationality',

            customSort: (a: any, b: any) =>
              a.nationality > b.nationality
                ? 1
                : b.nationality > a.nationality
                ? -1
                : 0,
            render: (rowData: any) => (
              <Box paddingY={1} id='list20'>
                <Typography noWrap variant='subtitle2' color='initial'>
                  {rowData.nationality === 'india' ||
                  rowData.nationality === 'India'
                    ? 'India'
                    : rowData.nationality}
                </Typography>
              </Box>
            ),
          },

          {
            title: (
              <Box id='list29' style={{ cursor: 'default' }}>
                <Typography variant='h4' style={{ fontSize: 14 }}>
                  {t('labelPatient:attr_labels.Patient.address')}
                </Typography>
              </Box>
            ),

            field: 'address',

            cellStyle: {
              width: 20,
              maxWidth: 100,
            },
            render: (rowData: any) => (
              <Box p={1} id='list30'>
                <OverflowTypography
                  text={rowData.address}
                  typographyProps={{
                    variant: 'subtitle2',
                    noWrap: true,
                  }}
                />
              </Box>
            ),
          },
        ]}
        data={getTableDataFromFhir(patientData)}
        totalCount={total}
        onRowClick={(event, rowData) => {
          if (rowData) onPatientSelected(rowData)
          i18n.changeLanguage(language ?? 'en')

          setSelectedRow(true)
          setSelectedRowId(rowData?.id ?? '')
        }}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handlePageChange}
        page={pageNumber}
        options={{
          showTitle: false,
          padding: 'dense',
          search: false,

          pageSize: perPage,
          pageSizeOptions: [10, 20, 30, 50],
          maxBodyHeight: `${height - 190}px`,
          minBodyHeight: `${height - 260}px`,

          sorting: false,
          headerStyle: {
            backgroundColor: '#ececec',
            color: '#333333',
            width: 110,
            position: 'sticky',
            top: 0,
            height: 40,
          },

          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </Box>
  )
}
