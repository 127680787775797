/* eslint-disable no-lone-blocks */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { Treatment } from 'models/treatMent'
import moment from 'moment'
import { join } from 'path'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentUnit } from 'utils/careplan_utils/careplan_utils'
import { sleep } from 'utils/dateUtil'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { requestForTreatment } from '../viewTreatment/treatmentSearchSlice'
import { TreatmentAddStatus } from './addTreatmentStatusTypes'

const initialState: TreatmentAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addTreatmentSlice = createSlice({
  name: 'addTreatmentSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<TreatmentAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addTreatmentBundle = action.payload.addTreatmentBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<TreatmentAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addTreatmentBundle = initialState.addTreatmentBundle
    },
  },
})

export const addTreatmentDetails =
  (
    treatment: Treatment[],
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: TreatmentAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(addTreatmentSlice.actions.updateAddVitalsStatus(addingState))
    try {
      const plan: R4.ICarePlan | undefined =
        await getCarePlanOfPatientWithCurrentUnit(
          fhirAppointmentDetails.patient
            ? fhirAppointmentDetails.patient.id ?? ''
            : ''
        )
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        treatment,
        fhirAppointmentDetails,
        plan
      )

      const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
        ? fhirAppointmentDetails.mainServiceRequest.code.coding
          ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean = appointmentType === '304903009'
      const consultation: boolean = appointmentType === '33022008'

      const resource: any = {
        isOpdConsult: consultation,
        isOpdDayCare: ipdDayCare,
        actionBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${fhirAppointmentDetails.mainServiceRequest.id}/addIpdCarePlanDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      await sleep(3000)
      if (response.status === 'Updated Care Plans for IPD') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }

        dispatch(showSuccessAlert('Treatment card updated successfully'))
        dispatch(
          requestForTreatment(fhirAppointmentDetails.mainServiceRequest.id!)
        )
        dispatch(addTreatmentSlice.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: TreatmentAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addTreatmentSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: TreatmentAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addTreatmentSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetTreatmentState = () => (dispatch: AppDispatch) => {
  dispatch(addTreatmentSlice.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  treatment: Treatment[],
  fhirIpdDetails: FhirActiveIPDDetailsForMedicalRole,
  plan?: R4.ICarePlan
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  for (let i = 0; i < treatment.length; i++) {
    const therapyCoding: R4.ICoding[] = treatment[i].morning.therapy ?? []
    const idData: string[] = treatment[i].morning.threapyId ?? []

    if (therapyCoding.length > 0) {
      {
        for (let k = 0; k < therapyCoding.length; k++) {
          const displayData: string = therapyCoding[k].display ?? ''
          if (displayData) {
            const newDate = new Date(
              `${treatment[i].date}T${treatment[i].morning.startTime}`
            )
            const newDateEnd = new Date(
              `${treatment[i].date}T${treatment[i].morning.endTime}`
            )
            const isoStartDateTIme = newDate.toISOString()
            const isoEndDateTIme = newDateEnd.toISOString()
            const momentObj = moment(
              treatment[i].date + treatment[i].morning.startTime,
              'YYYY-MM-DDLT'
            )
            const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
            const enmomentObj = moment(
              treatment[i].date + treatment[i].morning.endTime,
              'YYYY-MM-DDLT'
            )
            const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')

            const currentServiceRequest1: R4.IServiceRequest = {
              resourceType: 'ServiceRequest',
              meta: {
                profile: [
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
                ],
              },
              subject: {
                id: fhirIpdDetails.patient.id,
                reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
                display: getNameOfPatient(fhirIpdDetails.patient),
              },

              requester: {
                id: fhirIpdDetails.patient.id,
                reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
                display: getNameOfPatient(fhirIpdDetails.patient),
              },
              status: 'active',
              intent: 'order',
              category: [
                {
                  coding: [
                    {
                      system: 'http://snomed.info/sct',
                      code: '225365006',
                      display: 'Regime care therapy',
                    },
                  ],
                },
              ],
              performer: [getCurrentUserUnitReference()!],
              priority: 'urgent',

              occurrencePeriod: {
                start: isoStartDateTIme,
                end: isoEndDateTIme,
              },
              code: {
                text:
                  treatment[i].morning.treatmentType === 'None'
                    ? ''
                    : treatment[i].morning.treatmentType,
                coding: [therapyCoding[k]],
              },
              authoredOn: new Date().toISOString(),
            }

            if (plan) {
              currentServiceRequest1.basedOn = [
                {
                  reference: `CarePlan/${plan.id!}`,
                },
              ]
            }

            const performerData: R4.IReference = {
              id: `${idData[k]}`,
              reference: `HealthcareService/${idData[k]}`,
            }

            currentServiceRequest1.supportingInfo = [
              {
                id: `${idData[k]}`,
                reference: `HealthcareService/${idData[k]}`,
              },
            ]

            if (currentServiceRequest1.performer)
              currentServiceRequest1.performer.push(performerData)
            const morningExtension: R4.IExtension = {}
            morningExtension.url =
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
            morningExtension.valueTiming = {
              code: {
                coding: [
                  {
                    system: 'http://hl7.org/fhir/event-timing',
                    code: 'MORN.early',
                    display: 'Early Morning',
                  },
                ],
              },
            }
            currentServiceRequest1.extension = []
            currentServiceRequest1.extension.push(morningExtension)

            requestBundle.entry?.push({
              fullUrl: `${currentServiceRequest1.resourceType}/`,
              request: {
                method: R4.Bundle_RequestMethodKind._post,
                url: currentServiceRequest1.resourceType,
              },
              resource: currentServiceRequest1,
            })
          }
        }
      }
    }

    if (treatment[i].midAfterNoon.therapy) {
      const midAfterNoonThreapy: R4.ICoding[] =
        treatment[i].midAfterNoon.therapy ?? []
      const midAfterNoonId: string[] = treatment[i].midAfterNoon.threapyId ?? []
      if (midAfterNoonThreapy && midAfterNoonThreapy.length > 0)
        for (let k = 0; k < midAfterNoonThreapy.length; k++) {
          const momentObj = moment(
            treatment[i].date + treatment[i].midAfterNoon.startTime,
            'YYYY-MM-DDLT'
          )
          const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const enmomentObj = moment(
            treatment[i].date + treatment[i].midAfterNoon.endTime,
            'YYYY-MM-DDLT'
          )
          const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')

          const currentServiceRequest2: R4.IServiceRequest = {
            resourceType: 'ServiceRequest',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
              ],
            },
            subject: {
              id: fhirIpdDetails.patient.id,
              reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
              display: getNameOfPatient(fhirIpdDetails.patient),
            },

            requester: {
              id: fhirIpdDetails.patient.id,
              reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
              display: getNameOfPatient(fhirIpdDetails.patient),
            },
            status: 'active',
            intent: 'order',
            category: [
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '225365006',
                    display: 'Regime care therapy',
                  },
                ],
              },
            ],
            performer: [getCurrentUserUnitReference()!],
            priority: 'urgent',
            occurrencePeriod: {
              start: dateTime,
              end: endateTime,
            },

            authoredOn: new Date().toISOString(),
            code: {
              text:
                treatment[i].midAfterNoon.treatmentType === 'None'
                  ? ''
                  : treatment[i].midAfterNoon.treatmentType,
              coding: [midAfterNoonThreapy[k]],
            },
          }

          // if (fhirIpdDetails.mainServiceRequest.basedOn) {
          //   const referenceArray = fhirIpdDetails.mainServiceRequest.basedOn ?? []
          //   if (referenceArray.length > 0)
          //     for (let j = 0; j < referenceArray.length; j++) {
          //       const ref = referenceArray[j].reference
          //       if (ref) {
          //         const planData = ref.split('/')[0]
          //         if (planData === 'CarePlan') {
          //           currentServiceRequest2.basedOn = [
          //             {
          //               reference: ref,
          //             },
          //           ]
          //         }
          //       }
          //     }
          // }

          if (plan) {
            currentServiceRequest2.basedOn = [
              {
                reference: `CarePlan/${plan.id!}`,
              },
            ]
          }

          const performerData: R4.IReference = {
            id: `${midAfterNoonId[k]}`,
            reference: `HealthcareService/${midAfterNoonId[k]}`,
          }

          currentServiceRequest2.supportingInfo = [
            {
              id: `${midAfterNoonId[k]}`,
              reference: `HealthcareService/${midAfterNoonId[k]}`,
            },
          ]
          if (currentServiceRequest2.performer)
            currentServiceRequest2.performer.push(performerData)

          const midMorningExtension: R4.IExtension = {}
          midMorningExtension.url =
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
          midMorningExtension.valueTiming = {
            code: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/event-timing',
                  code: 'MORN',
                  display: 'Morning',
                },
              ],
            },
          }
          currentServiceRequest2.extension = []
          currentServiceRequest2.extension.push(midMorningExtension)

          requestBundle.entry?.push({
            fullUrl: `${currentServiceRequest2.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: currentServiceRequest2.resourceType,
            },
            resource: currentServiceRequest2,
          })
        }
    }

    if (treatment[i].afterNoon.therapy) {
      const afternoonTherapy: R4.ICoding[] =
        treatment[i].afterNoon.therapy ?? []
      const afternoonId: string[] = treatment[i].afterNoon.threapyId ?? []
      if (afternoonTherapy && afternoonTherapy.length > 0) {
        for (let k = 0; k < afternoonTherapy.length; k++) {
          const momentObj = moment(
            treatment[i].date + treatment[i].afterNoon.startTime,
            'YYYY-MM-DDLT'
          )
          const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const enmomentObj = moment(
            treatment[i].date + treatment[i].afterNoon.endTime,
            'YYYY-MM-DDLT'
          )
          const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const currentServiceRequest3: R4.IServiceRequest = {
            resourceType: 'ServiceRequest',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
              ],
            },
            subject: {
              id: fhirIpdDetails.patient.id,
              reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
              display: getNameOfPatient(fhirIpdDetails.patient),
            },

            requester: {
              id: fhirIpdDetails.patient.id,
              reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
              display: getNameOfPatient(fhirIpdDetails.patient),
            },
            status: 'active',
            intent: 'order',
            category: [
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '225365006',
                    display: 'Regime care therapy',
                  },
                ],
              },
            ],
            performer: [getCurrentUserUnitReference()!],
            priority: 'urgent',

            occurrencePeriod: {
              start: dateTime,
              end: endateTime,
            },
            authoredOn: new Date().toISOString(),
            code: {
              text:
                treatment[i].afterNoon.treatmentType === 'None'
                  ? ''
                  : treatment[i].afterNoon.treatmentType,
              coding: [afternoonTherapy[k]],
            },
          }

          if (plan) {
            currentServiceRequest3.basedOn = [
              {
                reference: `CarePlan/${plan.id!}`,
              },
            ]
          }

          const performerData: R4.IReference = {
            id: `${afternoonId[k]}`,
            reference: `HealthcareService/${afternoonId[k]}`,
          }

          currentServiceRequest3.supportingInfo = [
            {
              id: `${afternoonId[k]}`,
              reference: `HealthcareService/${afternoonId[k]}`,
            },
          ]
          if (currentServiceRequest3.performer)
            currentServiceRequest3.performer.push(performerData)

          const afterNoonExtension: R4.IExtension = {}
          afterNoonExtension.url =
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
          afterNoonExtension.valueTiming = {
            code: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/event-timing',
                  code: 'AFT',
                  display: 'Afternoon',
                },
              ],
            },
          }
          currentServiceRequest3.extension = []
          currentServiceRequest3.extension.push(afterNoonExtension)
          requestBundle.entry?.push({
            fullUrl: `${currentServiceRequest3.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: currentServiceRequest3.resourceType,
            },
            resource: currentServiceRequest3,
          })
        }
      }
    }

    if (treatment[i].evening.therapy) {
      const eveningTherapy: R4.ICoding[] = treatment[i].evening.therapy ?? []
      const eveningId: string[] = treatment[i].evening.threapyId ?? []
      if (eveningTherapy && eveningTherapy.length > 0) {
        for (let k = 0; k < eveningTherapy.length; k++) {
          const momentObj = moment(
            treatment[i].date + treatment[i].evening.startTime,
            'YYYY-MM-DDLT'
          )
          const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const enmomentObj = moment(
            treatment[i].date + treatment[i].evening.endTime,
            'YYYY-MM-DDLT'
          )
          const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const currentServiceRequest4: R4.IServiceRequest = {
            resourceType: 'ServiceRequest',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
              ],
            },
            subject: {
              id: fhirIpdDetails.patient.id,
              reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
              display: getNameOfPatient(fhirIpdDetails.patient),
            },

            requester: {
              id: fhirIpdDetails.patient.id,
              reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
              display: getNameOfPatient(fhirIpdDetails.patient),
            },
            status: 'active',
            intent: 'order',
            category: [
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '225365006',
                    display: 'Regime care therapy',
                  },
                ],
              },
            ],
            performer: [getCurrentUserUnitReference()!],
            priority: 'urgent',

            occurrencePeriod: {
              start: dateTime,
              end: endateTime,
            },
            code: {
              text:
                treatment[i].evening.treatmentType === 'None'
                  ? ''
                  : treatment[i].evening.treatmentType,
              coding: [eveningTherapy[k]],
            },
          }

          //       //   if (fhirIpdDetails.mainServiceRequest.basedOn) {
          //       //     const referenceArray =
          //       //       fhirIpdDetails.mainServiceRequest.basedOn ?? []
          //       //     if (referenceArray.length > 0)
          //       //       for (let j = 0; j < referenceArray.length; j++) {
          //       //         const ref = referenceArray[j].reference
          //       //         if (ref) {
          //       //           const planData = ref.split('/')[0]
          //       //           if (planData === 'CarePlan') {
          //       //             currentServiceRequest4.basedOn = [
          //       //               {
          //       //                 reference: ref,
          //       //               },
          //       //             ]
          //       //           }
          //       //         }
          //       //       }
          //       //   }

          if (plan) {
            currentServiceRequest4.basedOn = [
              {
                reference: `CarePlan/${plan.id!}`,
              },
            ]
          }

          const performerData: R4.IReference = {
            id: `HealthcareService/${eveningId[k]}`,
            reference: `HealthcareService/${eveningId[k]}`,
          }
          if (currentServiceRequest4.performer)
            currentServiceRequest4.performer.push(performerData)

          const eveningExtension: R4.IExtension = {}
          eveningExtension.url =
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
          eveningExtension.valueTiming = {
            code: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/event-timing',
                  code: 'EVE',
                  display: 'Evening',
                },
              ],
            },
          }

          currentServiceRequest4.supportingInfo = [
            {
              id: `HealthcareService/${eveningId[k]}`,
              reference: `HealthcareService/${eveningId[k]}`,
            },
          ]
          currentServiceRequest4.extension = []
          currentServiceRequest4.extension.push(eveningExtension)
          requestBundle.entry?.push({
            fullUrl: `${currentServiceRequest4.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: currentServiceRequest4.resourceType,
            },
            resource: currentServiceRequest4,
          })
        }
      }
    }
  }

  return requestBundle
}

export default addTreatmentSlice.reducer
