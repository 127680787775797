import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetStateForWelloAdminPractDetails } from 'redux/welloAdmin/UnitUserDetails/unitPractitionerDetails'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { UnitUserDetails } from './unitUserDetail'

interface PatientProps {
  unitId: string
  unitName: string
  docCount: number
  enabledDocCount: number
  disabledDocCount: number
  type: string
  consultingDoctor: number
  enableConsultingDoc: number
  disableConsultingDoc: number
  ayurvedaDoctorCount: number
  enableAyurveda: number
  disableAyurveda: number
  unitAdminCount: number
  enableUnitAdmin: number
  disableUnitAdmin: number
  grmCount: number
  enableGrm: number
  disableGrm: number
  therapistCount: number
  enableTherapist: number
  disableTherapist: number
  radiologistCount: number
  agentCount: number
  enableAgentCount: number
  disableAgentCount: number
  orgType?: string
  mainOrgIds?: string[]
  enabledNaturePathDocCount: number
  disabledNaturepathDoc: number
  ptCount: number
  enabledPtCount: number
  disabledPtCount: number
  dieticianCount: number
  enabledDieticianCount: number
  disabledDieticianCount: number
}

export const UnitDetailsTile: React.FC<PatientProps> = ({
  unitId,
  unitName,
  docCount,
  enabledDocCount,
  disabledDocCount,
  type,
  consultingDoctor,
  enableConsultingDoc,
  disableConsultingDoc,
  ayurvedaDoctorCount,
  enableAyurveda,
  disableAyurveda,
  unitAdminCount,
  enableUnitAdmin,
  disableUnitAdmin,
  grmCount,
  enableGrm,
  disableGrm,
  therapistCount,
  enableTherapist,
  disableTherapist,
  radiologistCount,
  agentCount,
  enableAgentCount,
  disableAgentCount,
  orgType,
  mainOrgIds,
  enabledNaturePathDocCount,
  disabledNaturepathDoc,
  ptCount,
  enabledPtCount,
  disabledPtCount,
  dieticianCount,
  enabledDieticianCount,
  disabledDieticianCount,
}: PatientProps) => {
  const dispatch = useDispatch()
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const [role, setRole] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const [roleName, setRoleName] = useState<string>()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        User Roles
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Count
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Enabled
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Disabled
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Doctors
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {docCount}
                    </Typography>
                  </Box>
                  {docCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          // disabled={ipdDetails.serviceRequest.status === 'draft'}
                          onClick={() => {
                            setOpen(true)
                            setRole('doctor')
                            setRoleName('Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>

                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enabledDocCount}
                    </Typography>
                  </Box>
                  {/* {enabledDocCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          // disabled={ipdDetails.serviceRequest.status === 'draft'}
                          onClick={() => {
                            setOpen(true)
                            setRole('doctor')
                            setRoleName('Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>

                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disabledDocCount}
                    </Typography>
                  </Box>
                  {/* {disabledDocCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          // disabled={ipdDetails.serviceRequest.status === 'draft'}
                          onClick={() => {
                            setOpen(true)
                            setRole('doctor')
                            setRoleName('Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>

                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Consulting Doctors
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {consultingDoctor}
                    </Typography>
                  </Box>
                  {consultingDoctor > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('doctor-partner')
                            setRoleName('Consulting Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enableConsultingDoc}
                    </Typography>
                  </Box>
                  {/* {enableConsultingDoc > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('doctor-partner')
                            setRoleName('Consulting Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disableConsultingDoc}
                    </Typography>
                  </Box>
                  {/* {disableConsultingDoc > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('doctor-partner')
                            setRoleName('Consulting Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Ayurveda (Vaidya)
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {ayurvedaDoctorCount}
                    </Typography>
                  </Box>
                  {ayurvedaDoctorCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('ayurveda')
                            setRoleName('Ayurveda (Vaidya)')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enableAyurveda}
                    </Typography>
                  </Box>
                  {/* {enableAyurveda > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('ayurveda')
                            setRoleName('Ayurveda Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disableAyurveda}
                    </Typography>
                  </Box>
                  {/* {disableAyurveda > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('ayurveda')
                            setRoleName('Ayurveda Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      GRMs
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {grmCount}
                    </Typography>
                  </Box>
                  {grmCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('receptionist')
                            setRoleName('GRM')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enableGrm}
                    </Typography>
                  </Box>
                  {/* {enableGrm > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('receptionist')
                            setRoleName('GRM')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disableGrm}
                    </Typography>
                  </Box>
                  {/* {disableGrm > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('receptionist')
                            setRoleName('GRM')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Nurses
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      0
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      0
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      0
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Naturopathy Doctor
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {radiologistCount ?? 0}
                    </Typography>
                  </Box>
                  {radiologistCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('1255370008')
                            setRoleName('Naturopathy Doctor')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enabledNaturePathDocCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disabledNaturepathDoc}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Therapists
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {therapistCount}
                    </Typography>
                  </Box>
                  {therapistCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('106296000')
                            setRoleName('Therapist')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enableTherapist}
                    </Typography>
                  </Box>
                  {/* {enableTherapist > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('106296000')
                            setRoleName('Therapist')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disableTherapist}
                    </Typography>
                  </Box>
                  {/* {disableTherapist > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('106296000')
                            setRoleName('Therapist')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Unit Admins
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {unitAdminCount}
                    </Typography>
                  </Box>
                  {unitAdminCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('unit-admin')
                            setRoleName('Unit Admin')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enableUnitAdmin}
                    </Typography>
                  </Box>
                  {/* {enableUnitAdmin > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('unit-admin')
                            setRoleName('Unit Admin')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disableUnitAdmin}
                    </Typography>
                  </Box>
                  {/* {disableUnitAdmin > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('unit-admin')
                            setRoleName('Unit Admin')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Agents
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {agentCount}
                    </Typography>
                  </Box>
                  {agentCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('lab-agent')
                            setRoleName('Agent')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enableAgentCount}
                    </Typography>
                  </Box>
                  {/* {enableAgentCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('lab-agent')
                            setRoleName('Agent')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disableAgentCount}
                    </Typography>
                  </Box>
                  {/* {disableAgentCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('lab-agent')
                            setRoleName('Agent')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )} */}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Physiotherapist
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {ptCount ?? 0}
                    </Typography>
                  </Box>
                  {ptCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('36682004')
                            setRoleName('Physiotherapist')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enabledPtCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disabledPtCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Dietician
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {dieticianCount ?? 0}
                    </Typography>
                  </Box>
                  {dieticianCount > 0 && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          onClick={() => {
                            setOpen(true)
                            setRole('dietician')
                            setRoleName('Dietician')
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {enabledDieticianCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {disabledDieticianCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <UnitUserDetails
        id={unitId}
        name={unitName}
        roleNameData={roleName ?? ''}
        mainOrgIds={mainOrgIds ?? []}
        open={open}
        role={role ?? ''}
        onClose={() => {
          dispatch(resetStateForWelloAdminPractDetails())
          setOpen(false)
          setRole(undefined)
          setRoleName(undefined)
        }}
      />
    </Box>
  )
}
