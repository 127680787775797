import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  getRolesAsStringFromPractRole,
  getUserCurrentRole,
  getUserDetails,
} from 'services/userDetailsService'
import { getLogOutUrl } from 'utils/authHelpers'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'
/* import md from '../../../../assets/images/icon_doctor-female.svg' */

export interface IAccountDropDownProps {}

export const AccountCardMobile: React.FC<IAccountDropDownProps> =
  ({}: IAccountDropDownProps) => {
    const user = getUserDetails()!

    const isSmallMobile = useMediaQuery({ query: '(max-width: 400px)' })

    // return focus to the button when we transitioned from !open -> open

    return (
      <Box display='flex' width='100%' flexDirection='row'>
        <Box>
          <Avatar
            style={{
              width: '52.43px',
              height: '52.43px',
              backgroundColor: '#E7E7E7',
            }}
          >
            <FontAwesomeIcon icon={faUserMd} style={{ paddingRight: '2px' }} />
          </Avatar>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignContent='center'
          px={1}
          flexGrow={1}
        >
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start
                    '
            alignContent='center'
            alignItems='baseline'
          >
            <Typography
              variant='subtitle1'
              color='primary'
              style={{
                color: '#19274F',
                fontWeight: 400,
                fontSize: '14px',
                fontFeatureSettings: `'dlig' on`,
                fontVariantNumeric: 'lining-nums proportional-nums',
              }}
            >
              {getNameFromHumanName(user.practitioner.name ?? [])}
            </Typography>
          </Box>
          {!isSmallMobile && (
            <Box display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  color: '#19274F',
                  fontWeight: 700,
                  fontSize: '16px',
                  fontFeatureSettings: `'dlig' on`,
                  fontVariantNumeric: 'lining-nums proportional-nums',
                }}
              >
                {`${getRolesAsStringFromPractRole(
                  user.practitionerRole!
                )} \u25CF ${user.unitOrganization?.name}`}
              </Typography>
            </Box>
          )}
          {isSmallMobile && (
            <Box display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  color: '#19274F',
                  fontWeight: 700,
                  fontSize: '16px',
                  fontFeatureSettings: `'dlig' on`,
                  fontVariantNumeric: 'lining-nums proportional-nums',
                }}
              >
                {`${getRolesAsStringFromPractRole(user.practitionerRole!)} `}
              </Typography>
            </Box>
          )}
          {isSmallMobile && (
            <Box display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  color: '#19274F',
                  fontWeight: 700,
                  fontSize: '16px',
                  fontFeatureSettings: `'dlig' on`,
                  fontVariantNumeric: 'lining-nums proportional-nums',
                }}
              >
                {` ${user.unitOrganization?.name}`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
