import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentUnit } from 'utils/careplan_utils/careplan_utils'
import { logger } from 'utils/logger'

import { IpdRegisterStatus } from './ipdRegisterAddStatus'

const initialState: IpdRegisterStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  patientError: '',
  serviceRequest: '',
}

const ipdRegisterSlice = createSlice({
  name: 'ipdRegisterSlice',
  initialState,
  reducers: {
    addingPatientDetails(state, action: PayloadAction<IpdRegisterStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientError = action.payload.patientError
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
    },

    patientDetailsAdded(state, action: PayloadAction<IpdRegisterStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientError = action.payload.patientError
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
    },

    errorInAddingPatientDetails(
      state,
      action: PayloadAction<IpdRegisterStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientError = action.payload.patientError
      state.errorMessage = action.payload.errorMessage
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
    },

    resetPatientDetails(state, action: PayloadAction<IpdRegisterStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patientError = action.payload.patientError
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
    },
  },
})

export const registerIpdPatient =
  (
    startDate: Date,
    endDate: Date,
    discount: number,
    noOfDays: number,
    roomNo: string,
    selectedSystemOfMedicine: R4.ICoding,
    selectedVistPurpose: R4.ICoding,
    roomType?: R4.ICoding,
    chargeItemDef?: R4.IChargeItemDefinition,
    patientDetails?: R4.IPatient,
    referralId?: string,
    visaDetails?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: IpdRegisterStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
      serviceRequest: '',
    }
    dispatch(
      ipdRegisterSlice.actions.addingPatientDetails(addingCreatePersonState)
    )

    let response: any
    try {
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()

      logger.info('patientDetails')

      logger.info(patientDetails)
      const date = moment(endDate).format('DD-MM-YYYY')

      const dateTime = moment(
        `${date} ${moment().format('HH:mm')}`,
        'DD/MM/YYYY HH:mm'
      )
      const EndDateOff = moment(endDate).format('DD-MM-YYYY')
      const endDateTime = moment(`${date} 09:00`, 'DD/MM/YYYY HH:mm')
      let resource: any = {}

      const plan: R4.ICarePlan | undefined =
        await getCarePlanOfPatientWithCurrentUnit(
          patientDetails ? patientDetails.id ?? '' : ''
        )
      if (roomType && chargeItemDef && selectedSystemOfMedicine) {
        resource = {
          discountPercentage: discount > 0 ? discount.toFixed(2) : '0',
          numberOfDays: noOfDays === 0 || noOfDays === 1 ? 1 : noOfDays,
          patientId: patientDetails ? patientDetails.id ?? '' : '',
          roomTypeCode: roomType,
          systemOfMedicine: selectedSystemOfMedicine,
          roomNumber: roomNo.toString(),
          roomTypeChargeDetails: chargeItemDef,
          fromDate: startDate.toISOString(),
          endDate: endDateTime.toISOString(),
          referralRequestId: referralId,
          isOpdBased: false,
          carePlanId: plan ? plan.id ?? '' : undefined,
        }

        //   const fhirClient: FHIRApiClient = new FHIRApiClient()
        const enRolClient: EnrolCient = new EnrolCient()
        response = await enRolClient.doCreateEnrolmentFlowRequest(
          'ipd/request-booking',
          resource
        )
        console.log(response)

        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        if (relatedFhirDecodeRes._tag === 'Right') {
          const orderResponse: R4.IBundle = relatedFhirDecodeRes.right
          let serviceRequestId: string = ''
          if (orderResponse.entry) {
            for (let i = 0; i < orderResponse.entry.length; i++) {
              const entryResponse = orderResponse.entry[i].response
              if (entryResponse) {
                if (entryResponse.location) {
                  if (entryResponse.location.includes('ServiceRequest')) {
                    serviceRequestId = entryResponse.location.replace(
                      '/_history/1',
                      ''
                    )
                  }
                }
              }
            }
          }

          if (visaDetails !== undefined) {
            const boolean = updateVisaDetails(visaDetails, serviceRequestId)
          }

          const successCreatePersonState: IpdRegisterStatus = {
            adding: false,
            additionSuccessful: true,
            serviceRequest: serviceRequestId,
            patientId: patientDetails ? patientDetails.id ?? '' : '',
            error: false,
            errorMessage: '',
          }
          dispatch(
            ipdRegisterSlice.actions.patientDetailsAdded(
              successCreatePersonState
            )
          )

          return
        }
        const errorCreatePersonState: IpdRegisterStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while register patient',
          serviceRequest: '',
        }
        dispatch(
          ipdRegisterSlice.actions.patientDetailsAdded(errorCreatePersonState)
        )

        return
      }
      const errorCreatePersonState: IpdRegisterStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while register patient',
        serviceRequest: '',
      }
      dispatch(
        ipdRegisterSlice.actions.patientDetailsAdded(errorCreatePersonState)
      )

      return
    } catch (error) {
      const errorCreatePersonState: IpdRegisterStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        patientError: 'Patient is still in IPD/OPD',
        errorMessage: 'Something went wrong',
        serviceRequest: '',
      }
      dispatch(
        ipdRegisterSlice.actions.errorInAddingPatientDetails(
          errorCreatePersonState
        )
      )
    }
  }

export async function updateVisaDetails(
  visaDetails: string,
  serviceId: string
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const responseData: any = await fhirClient.doGetResource(
    `/Basic?_id=${visaDetails.split('/')[1]}&code=transfer`
  )

  const relatedFhirDecodeResForBasic: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(responseData)
  if (relatedFhirDecodeResForBasic._tag === 'Right') {
    const slotResponse: R4.IBundle = relatedFhirDecodeResForBasic.right

    if (slotResponse.entry && slotResponse.entry.length > 0) {
      const extensionS: R4.IExtension[] = slotResponse.entry[0].extension ?? []
      const basicResource: R4.IBasic = slotResponse.entry[0]
        .resource as R4.IBasic
      //   extensionS.push({
      //     url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-membership-definition-ext',
      //     valueReference: {
      //       type: 'ServiceRequest',
      //       id: serviceId,
      //       reference: `ServiceRequest/${serviceId}`,
      //     },
      //   })

      const serviceExtension: R4.IExtension = {}
      serviceExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-service-request-ref-ext'
      serviceExtension.valueReference = {
        type: 'ServiceRequest',
        id: serviceId.split('/')[1],
        reference: `${serviceId}`,
      }
      const newExtArr: R4.IExtension[] = []
      newExtArr.push(serviceExtension)

      const kvpAll: R4.IExtension[] = basicResource.extension
        ? basicResource.extension.concat(newExtArr)
        : []

      basicResource.extension = kvpAll

      const response: any = await fhirClient.doUpdateFHIRResourceRequest(
        `/Basic/${basicResource.id}` ?? '',
        basicResource,
        basicResource.meta?.versionId ?? ''
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBasic> =
        R4.RTTI_Basic.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        return true
      }
      return false
    }
  }
  return false
}

export const resetMainPatientIpdState = () => (dispatch: AppDispatch) => {
  dispatch(ipdRegisterSlice.actions.resetPatientDetails(initialState))
}

export default ipdRegisterSlice.reducer
