import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import {
  isDoctor,
  isGre,
  isPhysiotherapist,
  isTherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getAddressOfPatient,
  getAddressOfPatientForOPDAppointment,
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { isDischargeInitiated } from 'utils/fhirResoureHelpers/invoice_helper'
import {
  getAdmissionBy,
  getAppointmentEndTIme,
  getCheckOutTIme,
  getDispositionTime,
  getIpdAdmitTIme,
  getIPDDetails,
  getOPDStartDate,
  getOPDStartTIme,
  getRoomNo,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { ProfilePicViewer } from 'views/components/common/profilePicViewer'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { GenderAndAgeTileWithoutBack } from 'views/components/patients/patient_profile/genderAgeTilleWithoutBackGround'
import SocketContext from 'views/contexts/socketMessageContext'
import { SelectedFile } from 'wello-web-components/dist/components/PrmitiveComponent/component/WelloFilePicker'
import { CaseStudyPrintButton } from '../caseStudyButton'
import { ViewBillingOfIPD } from '../view_billing'

interface Props {
  index: string
  ipdAppointmentDetails: FhirClinicIpdDetails
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
  onManageClicked: () => void
}

const options = ['Start IPD']

const ITEM_HEIGHT = 48

export const CaseSheetIPDTile: React.FC<Props> = ({
  index,
  ipdAppointmentDetails,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
  onManageClicked,
}: Props) => {
  const { t } = useTranslation()
  const [showBilling, setBillingHandler] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [ipdDetails, setIpdDetails] = React.useState<FhirClinicIpdDetails>(
    ipdAppointmentDetails
  )
  const { eventData } = useContext(SocketContext)
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })

  return (
    <Box width='100%' id={`ipdTile${index}`}>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              onClick={(e) => {
                if (onViewClicked) onViewClicked()
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <ProfilePicViewer
                  contentType='image/png'
                  existingFile={false}
                  decodingRequired={true}
                  imagePath='Patient.photo'
                  resourceId={ipdAppointmentDetails.patient.id ?? ''}
                  resourceType='Patient'
                  onDataFetched={(value: string) => {
                    // setDataFile(true)
                  }}
                  onSelectionChanged={(value: SelectedFile) => {}}
                  update={false}
                  purpose='tile3'
                  prescription={true}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
              >
                <Typography
                  variant='subtitle1'
                  color='primary'
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {`${getNameOfPatient(ipdDetails.patient) ?? ''} ${
                    getMiddleNameOfPatient(ipdDetails.patient) ?? ''
                  } ${getLastNameOfPatient(ipdDetails.patient) ?? ''}`}
                </Typography>
                <Typography
                  variant='body2'
                  color='initial'
                  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {getAddressOfPatient(ipdDetails.patient.address ?? [])}
                </Typography>

                <Typography variant='subtitle2' color='initial'>
                  {getIdentifierValueBySystem(
                    ipdDetails.serviceRequest.identifier ?? [],
                    'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                  )}
                </Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  {ipdDetails.type === 'ipd' && (
                    <Typography variant='body2' color='initial'>
                      DOA {`${getIpdAdmitTIme(ipdAppointmentDetails)}`}
                    </Typography>
                  )}
                  {ipdDetails.type === 'opd' && (
                    <Typography variant='body2' color='initial'>
                      DOA
                    </Typography>
                  )}
                  {/* {ipdDetails.type === 'ipd' && (
                        <Typography variant='body2' color='initial'>
                          {`${getIpdAdmitTIme(ipdAppointmentDetails)}`} -
                        </Typography>
                      )} */}
                  {ipdDetails.type === 'opd' && (
                    <Typography variant='body2' color='initial'>
                      {`${getIpdAdmitTIme(ipdAppointmentDetails)}`} -{' '}
                      {`${getAppointmentEndTIme(ipdAppointmentDetails)}`}
                    </Typography>
                  )}

                  {ipdDetails.type === 'ipd' && (
                    <Typography variant='body2' color='initial'>
                      {`${getRoomNo(ipdAppointmentDetails)}`}
                    </Typography>
                  )}

                  <Box display='flex' flexBasis='row'>
                    {ipdDetails.type === 'ipd' && (
                      <Typography variant='body2' color='initial'>
                        DOD
                      </Typography>
                    )}
                    <Box display='flex' flexBasis='row' paddingLeft={0.3}>
                      {ipdDetails.type === 'ipd' && (
                        <Typography variant='body2' color='initial'>
                          {` ${getCheckOutTIme(ipdAppointmentDetails)}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {ipdDetails.appType.length > 0 && (
                    <Typography variant='body2' color='initial'>
                      {ipdDetails.appType}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='20%'
              >
                {ipdDetails.type === 'opd' && (
                  <Typography variant='body2' color='initial'>
                    DOA
                  </Typography>
                )}

                {ipdDetails.type === 'opd' && (
                  <Typography variant='body2' color='initial'>
                    {`${getOPDStartDate(ipdAppointmentDetails)}`}
                  </Typography>
                )}

                {ipdDetails.type === 'opd' && (
                  <Typography variant='body2' color='initial'>
                    {`${getOPDStartTIme(ipdAppointmentDetails)}`} -{' '}
                    {`${getAppointmentEndTIme(ipdAppointmentDetails)}`}
                  </Typography>
                )}
                {ipdDetails.appType.length > 0 && (
                  <Typography variant='body2' color='initial'>
                    {ipdDetails.appType}
                  </Typography>
                )}
                {ipdDetails.type === 'ipd' && (
                  <Typography variant='body2' color='initial'>
                    DOA {`${getIpdAdmitTIme(ipdAppointmentDetails)}`}
                  </Typography>
                )}
                {/* {ipdDetails.type === 'ipd' &&
                      ipdDetails.serviceRequest.status === 'draft' && (
                        <Typography
                          variant='body2'
                          style={{
                            color: '#00000042',
                          }}
                        >
                          DOA {`${getIpdAdmitTIme(ipdAppointmentDetails)}`}
                        </Typography>
                      )} */}
                {/* {ipdDetails.type === 'ipd' && (
                      <Typography variant='body2' color='initial'>
                        {`${getIpdAdmitTIme(ipdAppointmentDetails)}`}
                      </Typography>
                    )} */}
                {!isTherapist() && (
                  <Box>
                    {ipdDetails.type === 'ipd' && (
                      <Typography variant='body2' color='initial'>
                        {`${getRoomNo(ipdAppointmentDetails)}`}
                      </Typography>
                    )}

                    {/* {ipdDetails.type === 'ipd' &&
                          ipdDetails.serviceRequest.status === 'draft' && (
                            <Typography
                              variant='body2'
                              style={{
                                color: '#00000042',
                              }}
                            >
                              {`${getRoomNo(ipdAppointmentDetails)}`}
                            </Typography>
                          )} */}
                  </Box>
                )}
                {ipdDetails.type === 'ipd' && (
                  <Typography variant='body2' color='initial'>
                    DOD {` ${getCheckOutTIme(ipdAppointmentDetails)}`}
                  </Typography>
                )}
                {/* {ipdDetails.type === 'ipd' &&
                      ipdDetails.serviceRequest.status === 'draft' && (
                        <Typography
                          variant='body2'
                          style={{
                            color: '#00000042',
                          }}
                        >
                          DOD {` ${getCheckOutTIme(ipdAppointmentDetails)}`}
                        </Typography>
                      )} */}
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                px={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <ProfilePicViewer
                      contentType='image/png'
                      existingFile={false}
                      decodingRequired={true}
                      imagePath='Patient.photo'
                      resourceId={ipdAppointmentDetails.patient.id ?? ''}
                      resourceType='Patient'
                      onDataFetched={(value: string) => {
                        // setDataFile(true)
                      }}
                      onSelectionChanged={(value: SelectedFile) => {}}
                      update={false}
                      purpose='tile3'
                      prescription={true}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='17%'
                id={`patient_name${index}`}
              >
                {getMiddleNameOfPatient(ipdDetails.patient).length > 0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(ipdDetails.patient)}{' '}
                    {getMiddleNameOfPatient(ipdDetails.patient) ?? ''}{' '}
                    {getLastNameOfPatient(ipdDetails.patient)}
                  </Typography>
                )}

                {getMiddleNameOfPatient(ipdDetails.patient).length === 0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(ipdDetails.patient)}{' '}
                    {getLastNameOfPatient(ipdDetails.patient)}
                  </Typography>
                )}

                <Box
                  display='flex'
                  flexDirection='column'
                  width='45%'
                  justifyContent='flex-start'
                >
                  <GenderAndAgeTileWithoutBack
                    patient={ipdDetails.patient}
                    status={false}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Typography
                    variant='body2'
                    color='initial'
                    style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                  >
                    {getAddressOfPatientForOPDAppointment(
                      ipdDetails.patient.address ?? []
                    )}
                  </Typography>
                </Box>

                {/* {ipdDetails.serviceRequest.status === 'draft' && (
                      <Typography
                        variant='body2'
                        color='initial'
                        style={{
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-line',
                          color: '#00000042',
                        }}
                      >
                        {getAddressOfPatient(ipdDetails.patient.address ?? [])}
                      </Typography>
                    )} */}
              </Box>

              {/* {!isTherapist() && ipdDetails.serviceRequest.status === 'draft' && (
                  <Box
                    width='62%'
                    display='flex'
                    alignContent='center'
                    justifyContent='center'
                    alignItems='center'
                    alignSelf='center'
                    justifySelf='center'
                    justifyItems='center'
                  >
                    <ActivityProgressIndicator
                      allStatuses={['initiated', 'room allotted', 'admitted']}
                      completedStatuses={getIPDPastStatuses(ipdDetails)}
                    />
                  </Box>
                )} */}

              {isTherapist() && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='flex-start'
                  width='45%'
                  paddingLeft={5}
                />
              )}

              {!isTherapist() && ipdDetails.serviceRequest.status !== 'draft' && (
                <Box
                  display='flex'
                  flexDirection={
                    isDischargeInitiated(ipdDetails.task!) ? 'row' : 'column'
                  }
                  justifyContent='center'
                  alignContent='flex-start'
                  alignItems={
                    isDischargeInitiated(ipdDetails.task!)
                      ? 'center'
                      : undefined
                  }
                  width='45%'
                  paddingLeft={5}
                >
                  {ipdDetails.serviceRequest.status !== 'draft' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignContent='flex-start'
                    >
                      <Box display='flex' flexDirection='row'>
                        <Typography
                          variant='body1'
                          color='initial'
                          style={{ fontWeight: 600 }}
                        >
                          Admission By :
                        </Typography>
                        <Typography
                          variant='body1'
                          color='initial'
                          style={{ paddingLeft: 2 }}
                        >
                          {getAdmissionBy(ipdAppointmentDetails)}
                        </Typography>
                      </Box>
                      <Typography variant='subtitle2' color='initial'>
                        {getIdentifierValueBySystem(
                          ipdDetails.serviceRequest.identifier ?? [],
                          'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                        )}
                        {/* {`${ipdAppointmentDetails!.serviceRequest.id ?? ''}`} */}
                      </Typography>
                    </Box>
                  )}

                  {ipdDetails.serviceRequest.status &&
                    ipdDetails.serviceRequest.status === 'active' &&
                    isDischargeInitiated(ipdDetails.task!) && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        flexGrow={1}
                        alignContent='center'
                        justifyContent='center'
                        alignItems='center'
                        px={2}
                      >
                        <Typography variant='subtitle2' color='initial'>
                          Discharge Initiated
                        </Typography>
                      </Box>
                    )}

                  {ipdDetails.serviceRequest.status &&
                    ipdDetails.serviceRequest.status !== 'active' &&
                    ipdDetails.serviceRequest.status !== 'draft' && (
                      <Box display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='row'>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ fontWeight: 600 }}
                          >
                            Disposition By :{' '}
                          </Typography>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ paddingLeft: 2 }}
                          >
                            {titleCase(ipdDetails.dispositionByDetails)}
                            {/* {getDispositionBy(ipdAppointmentDetails)} */}
                          </Typography>
                        </Box>

                        <Box display='flex' flexDirection='row' width='100%'>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ fontWeight: 600 }}
                          >
                            Disposition Time :
                          </Typography>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ paddingLeft: 2 }}
                          >
                            {getDispositionTime(ipdAppointmentDetails)}
                          </Typography>
                        </Box>

                        <Box display='flex' flexDirection='row'>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ fontWeight: 600 }}
                          >
                            Notes :
                          </Typography>
                          {ipdDetails.notes && (
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ paddingLeft: 2, cursor: 'pointer' }}
                              //   component=''
                              onClick={() => {
                                if (
                                  ipdDetails.notes &&
                                  ipdDetails.notes.length > 60
                                ) {
                                  setFullText(ipdDetails.notes)
                                  setShowText(true)
                                }
                              }}
                            >
                              {ipdDetails.notes.slice(0, 60)}
                              {ipdDetails.notes.length > 60 ? '...' : null}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                </Box>
              )}

              {!isTherapist() && ipdDetails.serviceRequest.status !== 'draft' && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='5%'
                >
                  <CaseStudyPrintButton
                    ipdDetails={ipdDetails.serviceRequest}
                    serviceReqId={ipdDetails.serviceRequest.id ?? ''}
                    encounterId={
                      ipdDetails.serviceRequest.encounter
                        ? ipdDetails.serviceRequest.encounter.reference
                          ? ipdDetails.serviceRequest.encounter.reference.split(
                              '/'
                            )[1]
                          : ''
                        : ''
                    }
                    patient={ipdDetails.patient}
                    location={ipdDetails.location}
                  />
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
      />
      {showBilling && (
        <ViewBillingOfIPD
          ipdDetails={ipdAppointmentDetails}
          onClose={() => {
            setBillingHandler(false)
          }}
          open={showBilling}
        />
      )}
    </Box>
  )
}
