import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import { DateWiseDietPlans } from 'models/dateWiseDietPlanData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
  isDietician,
  isTherapist,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { logger } from 'utils/logger'
import { IpdCountStatus } from './ipdCountStatus'

const initialState: IpdCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

const ipdCount = createSlice({
  name: 'ipdCount',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IpdCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestIPDAppCountForToday =
  (selectedDate?: Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IpdCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(ipdCount.actions.updatedStatus(state))
    const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
    try {
      const enRolClient: EnrolCient = new EnrolCient()

      const date = `${moment
        .utc(new Date())
        .subtract(150, 'days')
        .local()
        .format('YYYY-MM-DD')}`
      const endDate = moment(date).add(150, 'days')
      const apiDate = `${moment.utc(endDate).local().format('YYYY-MM-DD')}`
      let response: any

      if (!isTherapist() && !isDietician()) {
        const searchParams: any = {
          perPageCount: 0,
          status: 'active',
          unitId,
          type: 'ipd',
        }
        if (selectedDate) {
          const tempDate = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
            .startOf('day')
            .utc()
            .format()}`
          searchParams.startDate = tempDate
        }
        response = await enRolClient.doGetResource(`ipd`, searchParams)
      } else if (isTherapist()) {
        const startdate = `${moment
          .utc(selectedDate || new Date())

          .local()
          .format('YYYY-MM-DD')}`

        response = await getCountForTherapist(
          unitId!,
          startdate,
          'ipd',
          getGenderOfDoctor(getCurrentUserPractitionerDetails())
        )
      } else {
        const searchParams: any = {
          'encounter:Encounter.based-on:ServiceRequest.status':
            'active,completed',
          _count: 5000,
        }

        searchParams['encounter:Encounter.based-on:ServiceRequest.code'] =
          '281685003'

        const startDate = `${moment(
          moment(moment(selectedDate || new Date()).format('YYYY-MM-DD'))
            .startOf('day')
            .utc()
            .format()
        ).toISOString()}`

        const endDateDiet = `${moment(
          moment(moment(selectedDate || new Date()).format('YYYY-MM-DD'))
            .startOf('day')
            .utc()
            .format()
        )
          .add(1, 'days')
          .toISOString()}`

        axios.CancelToken.source()
        if (cancelTokenStore.has('dietSearchControlToken')) {
          const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
            'dietSearchControlToken'
          )
          controlTokenForSearch.cancel('new param added')
          cancelTokenStore.delete('dietSearchControlToken')
        }
        cancelTokenStore.set(
          'dietSearchControlToken',
          axios.CancelToken.source()
        )
        const fhirClient: MasterFhirClient = new MasterFhirClient()

        response = await fhirClient.doGetResourceForAppointmentIPD(
          `/NutritionOrder?_include:iterate=NutritionOrder:patient&_include:iterate=NutritionOrder:encounter&_include:iterate=Encounter:based-on&nutrition-schedule=ge${startDate}&nutrition-schedule=lt${endDateDiet}&nutrition-unit=${
            getCurrentUserUnitDetails().id
          }`,
          searchParams
        )
      }

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(ipdCount.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp

        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          if (isDietician()) {
            const dietplansData: DateWiseDietPlans[] =
              getDateWiseDietPlansFromBundle(appointmentResponse)
            state.count =
              dietplansData.length > 0
                ? dietplansData[0].dateWiseDiets.length
                : 0
            state.resultsAvailable = true
            state.searchingAppointments = false

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingAppointments = false
            dispatch(ipdCount.actions.updatedStatus(state))
          } else {
            state.count = appointmentResponse.total
            state.resultsAvailable = true
            state.searchingAppointments = false

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingAppointments = false
            dispatch(ipdCount.actions.updatedStatus(state))
          }
        } else {
          const errorSearchDoctor: IpdCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(ipdCount.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IpdCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(ipdCount.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default ipdCount.reducer
