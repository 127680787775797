/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Step,
  StepButton,
  Stepper,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ArrowBackRounded } from '@material-ui/icons'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { fetchAppointmentDetails } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { resetDietSearchState } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  isAyurvedaDoctor,
  isCurrentUserDoctor,
  isDoctor,
  isMedicalServiceProvider,
  isNurse,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import {
  enableVideoButton,
  isVideoAppointment,
  showVideoAppointment,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import { getAdjustedPath, getVendorPartId } from 'utils/routes_helper'
import { getUrlPathParameter } from 'utils/urlHelper'
import { PatientNameWithAppointmentWidget } from 'views/components/clinic_home_page/patientNameWithAppointmentWidget'
import { ProfilePicWithAgeAndGender } from 'views/components/clinic_home_page/profilePicWithAgeAndGender'
import { AssessmentPartOfConsultation } from 'views/components/consultation/assementDetailsOfConsultation'
import { EndVisit } from 'views/components/consultation/endVisit/endVisit'
import { ObjectivePartOfConsultation } from 'views/components/consultation/objectiveDetailsOfConsultation'
import { PlanPartOfConsultation } from 'views/components/consultation/planDetailsForConsultation'
import { SubjectivePartOfConsultation } from 'views/components/consultation/subjectiveDetailsOfConsultation'
import { CdsRecommendationsPopup } from 'views/components/patients/patient_profile/cds_recommendations'
import { PatientProfileDetailPopUp } from 'views/components/patients/patient_profile/patient_profile_dialog'
import { WelloLoadingIndicator } from 'wello-web-components'
import { useReactToPrint } from 'react-to-print'
import { Prescription } from 'views/components/patients/prescription'
import { getPrescriptionDetails } from 'redux/prescription/medicationsListSlice/prescriptionSearchSlice'
import { searchObservationsForAddLabTest } from 'redux/lab/diaognsticLabTestSearchSlice/dianogsticObsFinderSlice'
import { ResizablePIP } from 'views/components/common/resizable-pip'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { requestDietTiming } from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import { ReferralHistory } from 'views/components/patients/referralHistory'
import { sendAppointmentLink } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { getTaskStatus } from 'redux/patient/addPatient/addPatientSlice'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { getAllKriya } from 'redux/valueSet/kriyaListWithChargable/kriyaWithPriceSearchSlice'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { getAllTherapist } from 'redux/valueSet/therapyList/threapyCatalogSearchSlice'
import { getAllTherapiesWithPrice } from 'redux/valueSet/therapyListwithChargable/threapyCatalogWithPriceSearchSlice'
import { VideoCallComponent } from './videoCallingComponent'

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& root': {
      padding: '0',
    },
    '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: 'p',
      },
  },
}))

const steps =
  !isDoctor() && !isPhysiotherapist() && !isAyurvedaDoctor()
    ? ['Subjective', 'Objective']
    : ['Subjective', 'Objective', 'Assessment', 'Plan', 'end_visit']

export const ViewAppointmentPage: React.FC<{}> = () => {
  const appointmentHandlerSlice = useSelector(
    (state: RootState) => state.appointmentHandlerSlice
  )
  const [showProfileDetails, setShowProfileDetails] = useState<boolean>(false)
  const [showReferralDetails, setShowReferralDetails] = useState<boolean>(false)
  const [showPrescription, setShowPresctiption] = useState<boolean>(false)
  const [showCdsRecommendation, setShowCdsRecommendation] =
    useState<boolean>(false)
  const [startVideo, setStartVideo] = useState<boolean>(false)
  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [lastStepData, setLastStepData] = React.useState<number>(0)
  const [completed, setCompleted] = React.useState([])

  const [resend, setResend] = useState<boolean>(false)

  const dispatch = useDispatch()
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const classes = useStyles()
  const { height, width } = useWindowDimensions()

  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)
  const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
    useState(false)

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [sendStatus, setSendSTatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [statusLoading, setStatusLoading] = useState<boolean>(false)

  function requestAppointmentForPolling() {
    if (appointmentHandlerSlice.appointmentDetails)
      setOpenAddPatientPopup(
        isVideoAppointment(appointmentHandlerSlice.appointmentDetails)
      )
  }

  function getPatientTaskStatus(appointmentDetails: FhirAppointmentDetail) {
    setPaymentStatus({ requesting: true })

    setStatusLoading(true)

    getTaskStatus(appointmentDetails.patient)
      .then((e: boolean) => {
        setStatusLoading(false)
        if (e === false) {
          sendInvite(appointmentDetails)
        } else {
          dispatch(showWarningAlert('Invitation has been already accepted'))
        }
        setPaymentStatus({
          requesting: false,
          requestSuccess: true,
        })

        // dispatch(showSuccessAlert('Medication deleted successfully'))
        // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      })
      .catch((e) => {
        dispatch(showErrorAlert('Error while fetching invitation status'))
        console.log(e)
        setStatusLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function sendInvite(appointmentDetails: FhirAppointmentDetail) {
    setSendSTatus({ requesting: true })

    setLoading(true)

    sendAppointmentLink(
      `Appointment/${appointmentDetails.appointment.id!}`,
      appointmentDetails.patient.id!
    )
      .then((e: boolean) => {
        setLoading(false)
        if (e) {
          dispatch(showSuccessAlert('Invitation link sent successfully'))
          setSendSTatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setResend(true)
          setSendSTatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          // dispatch(showErrorAlert('Error while sending invitation'))
        }
      })
      .catch((e) => {
        setResend(true)
        dispatch(showErrorAlert('Error while sending invitation'))
        console.log(e)
        setLoading(false)
        setSendSTatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
    logger.error(window.location.search)

    if (window.location.search) {
      logger.error('inside search')
      // const params = new URLSearchParams(window.location.search)
      const id = getUrlPathParameter(window.location.search, 'id')
      sessionStorage.setItem(CURRENT_VISIT_REFERENCE, `Appointment/${id}`)
      sessionStorage.setItem('appointmentReference', `Appointment/${id}`)
      if (id) {
        dispatch(fetchAppointmentDetails(id, true))
      }
    } else {
      logger.error(window.location.href)
    }
    return () => {
      if (window.location.search) {
        sessionStorage.removeItem(CURRENT_VISIT_REFERENCE)
      }
    }
  }, [])
  const history = useHistory()

  const navigateToPath = (path: string) => {
    history.push(path)
  }

  useEffect(() => {
    if (
      appointmentHandlerSlice.appointmentFetched &&
      appointmentHandlerSlice.appointmentDetails &&
      showVideoAppointment(appointmentHandlerSlice.appointmentDetails) ===
        true &&
      openAddPatientPopup === false
    ) {
      const intervalId = setInterval(requestAppointmentForPolling, 1000)
      return () => clearInterval(intervalId)
    }
    if (
      appointmentHandlerSlice.appointmentDetails &&
      isVideoAppointment(appointmentHandlerSlice.appointmentDetails)
    ) {
      setOpenAddPatientPopup(
        isVideoAppointment(appointmentHandlerSlice.appointmentDetails)
      )
    }
  }, [requestAppointmentForPolling])

  useEffect(() => {
    dispatch(
      setSelectedSection({
        selectedSection: 'chief_complaint',
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (appointmentHandlerSlice.endedAppointment) {
      window.location.href = `/${getVendorPartId()}/dashboard`
    }
  }, [appointmentHandlerSlice.endedAppointment, history])

  useEffect(() => {
    if (isMedicalServiceProvider()) {
      dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
    }
    dispatch(getAllNutritionValueSets())
    dispatch(getAllKriya())
    dispatch(searchObservationsForAddLabTest())

    if (isCurrentUserDoctor()) {
      //   dispatch(getAllTherapist())
      dispatch(getAllTherapiesWithPrice())

      dispatch(requestDietTiming(getCurrentUserPractitionerDetails().id ?? ''))
    }
  }, [])

  const paperColor = useTheme().palette.background.paper
  const defaultColor = useTheme().palette.background.default

  const scrollToTop = () => {
    scroll.scrollTo(0)
  }

  const totalSteps = () => steps.length

  const completedSteps = () => Object.keys(completed).length

  const isLastStep = () => activeStep === totalSteps() - 1

  const allStepsCompleted = () => completedSteps() === totalSteps()

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    if (newActiveStep === 3) {
      dispatch(resetDietSearchState())
    }
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: any) => () => {
    setLastStepData(activeStep)
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    // newCompleted[activeStep] = true
    // setCompleted(newCompleted)
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted([])
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      className={classes.calendar}
      height='100vh'
      overflow='auto'
      width='100%'
    >
      {/* <SOAPNavigationDrawer
        style={{
          width: '260px',
          flexShrink: 0,
        }}
      /> */}

      {appointmentHandlerSlice.fetchingAppointmentDetails && (
        <WelloLoadingIndicator message='Fetching appointment Details' />
      )}

      {appointmentHandlerSlice.errorWhileFetchingAppointment && (
        <Typography>{t('labelCommon:error_fetch_appointment')}</Typography>
      )}
      {appointmentHandlerSlice.errorWhileUpdatingAppointment && (
        <Typography>{t('labelCommon:error_fetch_appointment')}</Typography>
      )}
      {appointmentHandlerSlice.appointmentFetched &&
        appointmentHandlerSlice.appointmentDetails && (
          <Box
            overflow='auto'
            display='flex'
            flexDirection='column'
            width='100%'
          >
            <Box display='flex' width='100%'>
              <Box paddingX={1} paddingTop={2} paddingBottom={4}>
                <IconButton
                  aria-label='back-to_home'
                  onClick={() => {
                    window.location.href = `${getAdjustedPath('/dashboard')}`
                  }}
                >
                  <ArrowBackRounded />
                </IconButton>
              </Box>
              <Box flexGrow={1}>
                {appointmentHandlerSlice.appointmentDetails?.patient && (
                  <Box display='flex' alignItems='center' alignContent='center'>
                    <Box px={1}>
                      <ProfilePicWithAgeAndGender
                        patient={
                          appointmentHandlerSlice.appointmentDetails?.patient
                        }
                      />
                    </Box>
                    <PatientNameWithAppointmentWidget
                      appointmentDetails={
                        appointmentHandlerSlice.appointmentDetails!
                      }
                    />
                  </Box>
                )}
              </Box>

              {appointmentHandlerSlice.appointmentDetails?.appointment
                ?.status !== R4.AppointmentStatusKind._fulfilled && (
                <Box
                  display='flex'
                  justifyContent='center'
                  height='100%'
                  flexDirection='column'
                  p={2}
                >
                  {' '}
                  <Box display='flex' flexDirection='row'>
                    {/* <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        if (i18n.language === 'hi') {
                          i18n.changeLanguage('en')
                          sessionStorage.setItem('lang', i18n.language)
                        } else {
                          i18n.changeLanguage('hi')
                          sessionStorage.setItem('lang', i18n.language)
                        }
                      }}
                    >
                      {t('labelCommon:toggle-language')}
                    </Button> */}
                    {/* {!isUnitAdmin() && (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          setShowCdsRecommendation(true)
                        }}
                      >
                        {t('labelCommon:care_plan_recommendations')}
                      </Button>
                    )} */}

                    {appointmentHandlerSlice.appointmentDetails &&
                      appointmentHandlerSlice.appointmentDetails.appointment
                        .basedOn && (
                        <Button
                          variant='contained'
                          color='primary'
                          id='viewReferral'
                          onClick={() => {
                            setShowReferralDetails(true)
                          }}
                          disabled={
                            appointmentHandlerSlice.appointmentDetails ===
                              undefined ||
                            appointmentHandlerSlice.appointmentDetails
                              .patient === undefined
                          }
                        >
                          Referral Insights
                        </Button>
                      )}
                    {appointmentHandlerSlice.appointmentDetails &&
                      appointmentHandlerSlice.showResendOption === true && (
                        <Button
                          variant='contained'
                          color='primary'
                          id='resend'
                          onClick={() => {
                            if (appointmentHandlerSlice.appointmentDetails) {
                              getPatientTaskStatus(
                                appointmentHandlerSlice.appointmentDetails
                              )
                            }
                          }}
                          disabled={
                            appointmentHandlerSlice.appointmentDetails ===
                              undefined ||
                            appointmentHandlerSlice.appointmentDetails
                              .patient === undefined ||
                            loading
                          }
                        >
                          Resend Invite
                        </Button>
                      )}

                    <Button
                      variant='contained'
                      color='primary'
                      id='viewProfile'
                      onClick={() => {
                        setShowProfileDetails(true)
                      }}
                      disabled={
                        appointmentHandlerSlice.appointmentDetails ===
                          undefined ||
                        appointmentHandlerSlice.appointmentDetails.patient ===
                          undefined
                      }
                    >
                      {t('labelCommon:view_profile')}
                    </Button>

                    <Box>
                      {/* <Box style={{ display: 'none' }}>
                        <Prescription
                          appointmentDetails={
                            appointmentHandlerSlice.appointmentDetails
                          }
                          refData={componentRef}
                        />
                      </Box> */}
                    </Box>
                    <Button
                      variant='contained'
                      color='primary'
                      id='viewPrescription'
                      onClick={() => {
                        setShowPresctiption(true)
                      }}
                      disabled={
                        appointmentHandlerSlice.appointmentDetails ===
                          undefined ||
                        appointmentHandlerSlice.appointmentDetails.patient ===
                          undefined ||
                        !appointmentHandlerSlice.enableEndAppointmentButton
                      }
                    >
                      View Prescription
                    </Button>

                    {(isDoctor() || isPhysiotherapist()) &&
                      openAddPatientPopup && (
                        <Button
                          variant='contained'
                          color='primary'
                          id='startVideo'
                          disabled={!openAddPatientPopup}
                          onClick={() => {
                            setStartVideo(true)
                          }}
                        >
                          {t('labelCommon:start_video')}
                        </Button>
                      )}
                    {/* {!isUnitAdmin() && (
                      <Button
                        variant='contained'
                        color='secondary'
                        disabled={
                          !appointmentHandlerSlice.enableEndAppointmentButton
                        }
                        onClick={() => {
                          if (appointmentHandlerSlice.appointmentDetails)
                            dispatch(
                              endClinicVisit(
                                appointmentHandlerSlice.appointmentDetails
                              )
                            )
                        }}
                      >
                        {t('labelCommon:end_visit')}
                      </Button>
                    )} */}
                  </Box>
                </Box>
              )}
            </Box>
            <Divider variant='fullWidth' />
            <Box
              sx={{ width: '100%' }}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='row'
              bgcolor={kPrimaryMain}
            >
              <Box
                sx={{
                  width:
                    !isDoctor() && !isPhysiotherapist() && !isAyurvedaDoctor()
                      ? '70%'
                      : '100%',
                }}
                px={30}
                borderRadius={12}
                bgcolor={kPrimaryMain}
              >
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  style={{ borderRadius: 3, backgroundColor: kPrimaryMain }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton
                        color='red'
                        onClick={handleStep(index)}
                        id={`${label}${index}`}
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            textTransform: 'uppercase',
                            color: paperColor,
                          }}
                        >
                          {t('labelCommon:' + `${label}`)}
                          {/* {t('labelCommon:view_profile')} */}
                        </Typography>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
            <Box
              display='flex'
              overflow='auto'
              flexDirection='row'
              px={10}
              borderRadius={12}
              py={1.5}
            >
              <Box
                flexDirection='column'
                overflow='auto'
                width='100%'
                id='rootEL'
                borderRadius={12}
                alignItems='center'
                justifyContent='center'
                paddingLeft={activeStep === 4 ? 24 : 0}
                paddingRight={activeStep === 4 ? 24 : 0}
              >
                {' '}
                <SimpleBar
                  autoHide={false}
                  style={{
                    height: '100%',
                    overflowX: 'hidden',
                    padding: '4px',
                  }}
                >
                  <Box
                    flexDirection='column'
                    width={activeStep === 4 ? '100%' : '100%'}
                    bgcolor={paperColor}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      py={2}
                      px={3}
                    >
                      {activeStep === 0 && (
                        <SubjectivePartOfConsultation
                          fhirAppointmentDetails={
                            appointmentHandlerSlice.appointmentDetails
                          }
                          split={false}
                        />
                      )}

                      {activeStep === 1 && (
                        <ObjectivePartOfConsultation
                          fhirAppointmentDetails={
                            appointmentHandlerSlice.appointmentDetails
                          }
                          split={false}
                          displayReport={
                            appointmentHandlerSlice.enableReportDisplay
                          }
                        />
                      )}

                      {activeStep === 2 && (
                        <AssessmentPartOfConsultation
                          fhirAppointmentDetails={
                            appointmentHandlerSlice.appointmentDetails
                          }
                          split={false}
                        />
                      )}

                      {activeStep === 3 && (
                        <PlanPartOfConsultation
                          fhirAppointmentDetails={
                            appointmentHandlerSlice.appointmentDetails
                          }
                          split={false}
                        />
                      )}

                      {activeStep === 4 && (
                        <Box width='110%'>
                          <EndVisit
                            fhirAppointmentDetails={
                              appointmentHandlerSlice.appointmentDetails
                            }
                            disable={
                              !appointmentHandlerSlice.enableEndAppointmentButton
                            }
                            OnNo={() => {
                              setActiveStep(lastStepData)
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {/* <Box display='flex' flexDirection='row' width='100%' py={1.5}>
                    <ObjectivePartOfConsultation
                      fhirAppointmentDetails={
                        appointmentHandlerSlice.appointmentDetails
                      }
                    />
                  </Box> */}
                  {/* <Box display='flex' flexDirection='row' width='100%' py={1.5}>
                    <AssessmentPartOfConsultation
                      fhirAppointmentDetails={
                        appointmentHandlerSlice.appointmentDetails
                      }
                    />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%' py={1.5}>
                    <PlanPartOfConsultation
                      fhirAppointmentDetails={
                        appointmentHandlerSlice.appointmentDetails
                      }
                    />
                  </Box> */}
                </SimpleBar>
              </Box>
            </Box>
            {startVideo && (
              <VideoCallComponent
                onEndMeeting={() => {
                  setStartVideo(false)
                }}
                onConfrenseJoined={() => {}}
                OnParticipantJoined={() => {}}
                OnParticipantLeft={() => {}}
                appointmentDetails={appointmentHandlerSlice.appointmentDetails}
                height={600}
                width={500}
              />
            )}
            <PatientProfileDetailPopUp
              onClose={() => {
                setShowProfileDetails(false)
              }}
              open={showProfileDetails}
              patientDetails={
                appointmentHandlerSlice.appointmentDetails?.patient!
              }
              fhirPatientDetail={appointmentHandlerSlice.appointmentDetails!}
            />

            <Prescription
              onClose={() => {
                setShowPresctiption(false)
              }}
              appointmentDetails={appointmentHandlerSlice.appointmentDetails!}
              open={showPrescription}
            />
            <ReferralHistory
              onClose={() => {
                setShowReferralDetails(false)
              }}
              appointmentDetails={appointmentHandlerSlice.appointmentDetails!}
              open={showReferralDetails}
            />
            <CdsRecommendationsPopup
              onClose={() => {
                setShowCdsRecommendation(false)
              }}
              open={showCdsRecommendation}
              patientDetails={
                appointmentHandlerSlice.appointmentDetails?.patient!
              }
              encounter={appointmentHandlerSlice.appointmentDetails!.encounter!}
            />
          </Box>
        )}
    </Box>
  )
}
