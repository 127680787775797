import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography, Grid } from '@material-ui/core'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloInstructions } from 'models/WelloConditions'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { RootState } from 'redux/rootReducer'
import { getCommunicationRequestPayloadString } from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { InstructionsTableTile } from '../assessment/instructionsTabularTile'
import { InstructionsTableTileForTile } from '../assessment/instructionsTabularTileForScreen'
import { EditInstruction } from '../edition/editInstructions'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  followUp?: boolean
  split?: boolean
}

export const CarePlanInstructionList: React.FC<Props> = ({
  fhirAppointmentDetails,
  followUp,
  split,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [showEdit, setShowEdit] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [editText, setEditText] = React.useState<string>()

  const instructionsListSlice = useSelector(
    (state: RootState) => state.instructionsListSlice
  )

  const [currentCondition, setCurrentCondition] = useState<WelloInstructions>({
    id: _.random(1, 10000000).toString(),
  })

  useEffect(() => {
    dispatch(getInstructionsOfAppointment(fhirAppointmentDetails, followUp))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(instructionsListSlice.updatingImpressions ||
        instructionsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {/* {instructionsListSlice.noResultsAvailable && (
        <Box py={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )} */}
      {instructionsListSlice.lanDiagnosticRequests &&
        instructionsListSlice.lanDiagnosticRequests.length > 0 && (
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box display='flex' flexDirection='column' width='100%'>
                <InstructionsTableTileForTile
                  instructions={instructionsListSlice.lanDiagnosticRequests}
                  onEditClicked={(insData: R4.ICommunicationRequest) => {
                    setCurrentCondition({
                      ...currentCondition,
                      existingObs: insData,
                      existingText:
                        getCommunicationRequestPayloadString(insData) ?? '',
                    })

                    setShowEdit(true)
                  }}
                  split={split}
                />
              </Box>
            </Grid>
          </Grid>
        )}

      {showEdit && (
        <EditInstruction
          open={showEdit}
          patientReference={{
            reference: `Patient/${fhirAppointmentDetails.patient.id}`,
          }}
          encounterReference={{
            reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
          }}
          onClose={() => {
            dispatch(getInstructionsOfAppointment(fhirAppointmentDetails))
            setShowEdit(false)
          }}
          onInstructionAdded={() => {
            dispatch(getInstructionsOfAppointment(fhirAppointmentDetails))
            setShowEdit(false)
          }}
          existingIns={currentCondition}
          appointmentId={fhirAppointmentDetails.appointment.id!}
        />
      )}

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        dialogText='Instruction'
        notesData={fullText!}
      />
    </Box>
  )
}
