/* eslint-disable no-lone-blocks */
/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { PractitionerWithUnit } from 'models/practionerWithUnit'
import {
  getPractitionerRoleDisplay,
  PractitionerWithRole,
} from 'models/practitionerWithRole'
import {
  getAddress,
  getFirstNameFromHumanName,
  getGenderOfDoctor,
  getLastNameFromHumanName,
  getNameFromHumanName,
  getPreFix,
  getRoleDetailsFromPractRole,
  getStringValueExtensionsOfUrl,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'

export const getPractitionersWithRoleObject = (
  inputBundle: R4.IBundle
): PractitionerWithRole[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const practListWithRole: PractitionerWithRole[] = []
  const practitionerRoles: any = {}
  const practitioners: Map<string, R4.IPractitioner> = new Map<
    string,
    R4.IPractitioner
  >()

  const location: any = {}
  const healthService: any = {}
  const charDef: R4.IChargeItemDefinition[] = []

  if (inputBundle.entry && inputBundle.entry.length > 0) {
    const entries: R4.IBundle_Entry[] = inputBundle.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.id) {
          switch (value.resource.resourceType) {
            case 'PractitionerRole':
              practitionerRoles[value.resource.id] =
                value.resource as R4.IPractitionerRole
              break
            case 'Practitioner':
              practitioners.set(
                value.resource.id,
                value.resource as R4.IPractitioner
              )
              logger.info('Practitioners', practitioners)
              break
            case 'Location':
              location[value.resource.id] = value.resource as R4.ILocation

              break
            case 'HealthcareService':
              healthService[value.resource.id] =
                value.resource as R4.IHealthcareService
              break
            case 'ChargeItemDefinition':
              charDef.push(value.resource as R4.IChargeItemDefinition)
              break

            default:
              break
          }
        }
      }
    })
    logger.info(
      '-------------------Inside distillation function middle------------------------'
    )

    if (practitionerRoles) {
      for (const key in practitionerRoles) {
        if (key) {
          let locationShow: boolean = false
          const chargeItemDef: R4.IChargeItemDefinition[] = []
          const currentRole: R4.IPractitionerRole = practitionerRoles[key]
          let currentLoc: R4.ILocation = location[0]
          const practId: string | undefined =
            currentRole.practitioner?.reference?.split('/')[1]
          const currentPract: R4.IPractitioner | undefined = practitioners.get(
            practId ?? ''
          )

          for (const key1 in location) {
            if (key1) {
              const loc: R4.ILocation = location[key1] as R4.ILocation
              if (loc.description === 'PartnerLab') {
                locationShow = true
                currentLoc = loc
              }
            }
          }
          for (const key2 in healthService) {
            {
              if (key2) {
                const service: R4.IHealthcareService = healthService[key2]

                if (service.extension) {
                  for (let i = 0; i < service.extension.length; i++) {
                    const serviceDef = service.extension[i]
                    if (serviceDef) {
                      const ref =
                        serviceDef.valueReference?.reference?.split('/')[1]
                      for (let k = 0; k < charDef.length; k++) {
                        if (charDef[k].id === ref) {
                          chargeItemDef.push(charDef[k])
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const role = getRoleDetailsFromPractRole(currentRole)

          let statusData: string = currentPract?.meta?.tag
            ? 'Active'
            : 'Inactive'
          if (role === 'doctor') {
            if (currentRole.healthcareService) {
              statusData = 'Active'
            } else {
              statusData = 'Inactive'
            }
          }

          if (statusData === 'Active' && currentRole.active === true) {
            statusData = 'Active'
          } else if (statusData === 'Inactive' && currentRole.active === true) {
            statusData = 'Inactive'
          } else {
            statusData = 'Deactivate'
          }
          let colorData: string | undefined = ''
          if (currentPract)
            colorData = getStringValueExtensionsOfUrl(
              currentPract.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
            )

          practListWithRole.push({
            id: practId ?? '',
            color: colorData ?? '',
            practRoleId: currentRole.id ?? '',
            gender: getGenderOfDoctor(currentPract),
            name: getNameFromHumanName(currentPract?.name ?? []),
            fullName: ` ${getPreFix(
              currentPract?.name ?? []
            )} ${getFirstNameFromHumanName(
              currentPract?.name ?? []
            )} ${getLastNameFromHumanName(currentPract?.name ?? [])}`,
            primaryContact:
              getTelecomFromContactPoints(
                currentPract?.telecom ?? [],
                ContactPointSystemKind._email
              ) ?? '',
            status: statusData,

            phone:
              getTelecomFromContactPoints(
                currentPract?.telecom ?? [],
                ContactPointSystemKind._phone
              ) ?? '',
            role: getPractitionerRoleDisplay(currentRole),
            roleObject: currentRole,
            practitionerObject: currentPract,
            locationObject: locationShow === true ? currentLoc : undefined,
            chargeItemDef,
            enabled: currentRole
              ? currentRole.active
                ? currentRole.active
                : false
              : false,
          })
        }
      }
      practListWithRole.sort((a, b) => a.fullName.localeCompare(b.fullName))

      return practListWithRole
    }
  }

  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export const getPractitionersWithUnitDetails = (
  inputBundle: R4.IBundle
): PractitionerWithRole[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const practListWithRole: PractitionerWithRole[] = []
  const practitionerRoles: any = {}
  const practitioners: Map<string, R4.IPractitioner> = new Map<
    string,
    R4.IPractitioner
  >()

  const location: any = {}
  const healthService: any = {}
  const charDef: R4.IChargeItemDefinition[] = []

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Practitioner':
                  practitioners.set(
                    value.resource.id,
                    value.resource as R4.IPractitioner
                  )
                  logger.info('Practitioners', practitioners)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )

        if (practitionerRoles) {
          for (const key in practitionerRoles) {
            if (key) {
              const chargeItemDef: R4.IChargeItemDefinition[] = []
              const currentRole: R4.IPractitionerRole = practitionerRoles[key]

              const practId: string | undefined =
                currentRole.practitioner?.reference?.split('/')[1]
              const currentPract: R4.IPractitioner | undefined =
                practitioners.get(practId ?? '')

              const role = getRoleDetailsFromPractRole(currentRole)

              let statusData: string = currentPract?.meta?.tag
                ? 'Active'
                : 'Inactive'
              if (role === 'doctor') {
                if (currentRole.healthcareService) {
                  statusData = 'Active'
                } else {
                  statusData = 'Inactive'
                }
              }

              if (statusData === 'Active' && currentRole.active === true) {
                statusData = 'Active'
              } else if (
                statusData === 'Inactive' &&
                currentRole.active === true
              ) {
                statusData = 'Inactive'
              } else {
                statusData = 'Deactivate'
              }
              let colorData: string | undefined = ''
              if (currentPract)
                colorData = getStringValueExtensionsOfUrl(
                  currentPract.extension,
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
                )

              practListWithRole.push({
                id: practId ?? '',
                color: colorData ?? '',
                unitName: currentRole.organization
                  ? currentRole.organization.display ?? ''
                  : '',
                practRoleId: currentRole.id ?? '',
                gender: getGenderOfDoctor(currentPract),
                name: getNameFromHumanName(currentPract?.name ?? []),
                fullName: ` ${getPreFix(
                  currentPract?.name ?? []
                )} ${getFirstNameFromHumanName(
                  currentPract?.name ?? []
                )} ${getLastNameFromHumanName(currentPract?.name ?? [])}`,
                primaryContact:
                  getTelecomFromContactPoints(
                    currentPract?.telecom ?? [],
                    ContactPointSystemKind._email
                  ) ?? '',
                status: statusData,

                phone:
                  getTelecomFromContactPoints(
                    currentPract?.telecom ?? [],
                    ContactPointSystemKind._phone
                  ) ?? '',
                role: getPractitionerRoleDisplay(currentRole),
                roleObject: currentRole,
                practitionerObject: currentPract,
                locationObject: undefined,
                chargeItemDef,
                enabled: currentRole
                  ? currentRole.active
                    ? currentRole.active
                    : false
                  : false,
              })
            }
          }
          practListWithRole.sort((a, b) => a.fullName.localeCompare(b.fullName))

          return practListWithRole
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export const getPractitionersWithUnit = (
  inputBundle: R4.IBundle
): PractitionerWithUnit[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const practListWithUnit: PractitionerWithUnit[] = []
  const practitionerRoles: any = {}
  const practitioners: Map<string, R4.IPractitioner> = new Map<
    string,
    R4.IPractitioner
  >()

  const organization: any = {}
  const healthService: any = {}
  const charDef: R4.IChargeItemDefinition[] = []

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Organization':
                  organization[value.resource.id] =
                    value.resource as R4.IOrganization

                  logger.info('Organization', organization)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )

        if (practitionerRoles) {
          for (const key in practitionerRoles) {
            if (key) {
              const currentRole: R4.IPractitionerRole = practitionerRoles[key]

              const unitId: string | undefined =
                currentRole.organization?.reference?.split('/')[1]

              if (unitId) {
                const currentOrg: R4.IOrganization | undefined =
                  organization[unitId]

                const role = getRoleDetailsFromPractRole(currentRole)

                practListWithUnit.push({
                  id: currentOrg ? currentOrg.id ?? '' : '',
                  unitName: currentOrg ? currentOrg.name ?? '' : '',
                  adminName: currentRole.practitioner
                    ? currentRole.practitioner.display ?? ''
                    : '',
                  unitType: currentOrg
                    ? currentOrg.type?.[0].coding?.[0].display ?? ''
                    : '',
                  address: currentOrg ? getAddress(currentOrg.address) : '',
                  emailId:
                    getTelecomFromContactPoints(
                      currentRole.telecom ?? [],
                      ContactPointSystemKind._email
                    ) ?? '',
                  phoneNo:
                    getTelecomFromContactPoints(
                      currentRole.telecom ?? [],
                      ContactPointSystemKind._phone
                    ) ?? '',
                  practitionerRole: currentRole,
                })
              }
            }
            practListWithUnit.sort((a, b) =>
              a.unitName.localeCompare(b.unitName)
            )
          }

          return practListWithUnit
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export function checkRole(roleArray: string[]): boolean {
  const data: string[] = roleArray.filter((e: string) => e.includes('doctor'))
  if (data.length > 0) return true
  return false
}
