import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import {
  FhirActiveIPDDetailsForMedicalRole,
  FhirIPDwithPatientDetails,
} from 'models/fhirActiveIPDDetailsForMedicalRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useState } from 'react'
import { reasonData } from 'utils/constants'
import {
  getAgeOfPatientData,
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import {
  getOrderStatusText,
  isPastDate,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { TherapyDataDisplay } from './therapyDataFile'

// import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
// import { AgentSlotForOrder } from './agent_slot_selector'
// import { LabOfferingSelector } from './labTestSearch'
// import { PatientAddressSelector } from './patient_address_selector'

interface Props {
  open: boolean
  onClose: () => void
  onLabOrderCreated: (cancelReasonCode: R4.ICoding) => void
  fhirAppointmentDetails: FhirIPDwithPatientDetails
  treatmentPlanData?: TreatmentPlanData
}
export const CancelTherapyNew: React.FC<Props> = ({
  open,
  onClose,
  onLabOrderCreated,
  fhirAppointmentDetails,
  treatmentPlanData,
}) => {
  const [selectedCancellationReason, setCancelReason] = useState<R4.ICoding>()
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setCancelReason(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Cancel Therapy</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                setCancelReason(undefined)
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='Row'>
              <Box display='flex' flexDirection='column' width='50%'>
                <Card elevation={0}>
                  <CardContent>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      height='80px'
                    >
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Typography
                          variant='h6'
                          color='initial'
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 12,
                          }}
                        >
                          Patient
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                        >
                          <Avatar
                            alt={
                              getNameOfPatient(
                                fhirAppointmentDetails.patient
                              ) ?? ''
                            }
                            src={
                              getProfilePicPatient(
                                fhirAppointmentDetails.patient
                              ) ?? ''
                            }
                          />
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='start'
                          px={0.5}
                        >
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            style={{ textTransform: 'capitalize' }}
                          >
                            {getNameOfPatient(fhirAppointmentDetails.patient)}{' '}
                            {getMiddleNameOfPatient(
                              fhirAppointmentDetails.patient
                            )}{' '}
                            {getLastNameOfPatient(
                              fhirAppointmentDetails.patient
                            )}
                          </Typography>

                          <Box mx={1} py={0.5}>
                            {fhirAppointmentDetails.patient?.gender ===
                            R4.PatientGenderKind._male ? (
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='primary'
                              >
                                {' '}
                                M /
                              </Typography>
                            ) : (
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='primary'
                              >
                                {' '}
                                F /
                              </Typography>
                            )}
                            {fhirAppointmentDetails.patient && (
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='primary'
                              >
                                {` ${getAgeOfPatientData(
                                  fhirAppointmentDetails.patient
                                )}`}
                              </Typography>
                            )}
                          </Box>

                          <Typography variant='subtitle2' color='initial'>
                            {getIdentifierValueBySystem(
                              fhirAppointmentDetails.mainServiceRequest
                                .identifier ?? [],
                              'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                            ) ??
                              getIdentifierValueBySystem(
                                fhirAppointmentDetails.mainServiceRequest
                                  .identifier ?? [],
                                'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              {treatmentPlanData && (
                <Box display='flex' flexDirection='column' width='50%'>
                  <TherapyDataDisplay treatmentPlanData={treatmentPlanData} />
                </Box>
              )}
            </Box>

            {treatmentPlanData && treatmentPlanData.treatmentPlan && (
              <Box display='flex' flexDirection='column' width='100%'>
                {getOrderStatusText(treatmentPlanData.treatmentPlan) ===
                  'Scheduled' && (
                  <Box>
                    {treatmentPlanData && !treatmentPlanData.procedure && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        py={1}
                      >
                        <Typography variant='subtitle2' color='initial'>
                          Select Reason
                        </Typography>
                        <Box
                          display='flex'
                          flexWrap='wrap'
                          justifyContent='flex-start'
                          py={0.5}
                          width='100%'
                        >
                          {reasonData.map((item, index: number) => (
                            <Chip
                              id={`cancelReason${index}`}
                              title={item.display ?? ''}
                              style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                paddingRight: '2px',
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                marginRight: '15px',

                                marginTop: '4px',
                                marginBottom: '4px',
                                height: '35px',
                              }}
                              label={item.display ?? ''}
                              variant={
                                (
                                  selectedCancellationReason !== undefined
                                    ? selectedCancellationReason.code ===
                                      item.code
                                    : false
                                )
                                  ? 'default'
                                  : 'outlined'
                              }
                              key={`key_${item.code}`}
                              size='medium'
                              clickable
                              color='primary'
                              onClick={(e) => {
                                setCancelReason(item as R4.ICoding)
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setCancelReason(undefined)
            }}
            variant='outlined'
            style={{ color: ' #979797;' }}
            disableElevation
            id='close_cancel'
            size='small'
          >
            Close
          </Button>
          <Button
            onClick={() => {
              if (
                treatmentPlanData &&
                treatmentPlanData.treatmentPlan &&
                selectedCancellationReason!
              ) {
                onLabOrderCreated(selectedCancellationReason)
              }
            }}
            id='save_cancel'
            variant='contained'
            color='primary'
            size='small'
            disableElevation
            disabled={
              treatmentPlanData
                ? isPastDate(
                    moment(treatmentPlanData.date).format('DD-MM-YYYY')
                  ) ||
                  treatmentPlanData.procedure !== undefined ||
                  /* isFutureDate(
                  moment(treatmentPlanData.date).format('DD-MM-YYYY')
                ) || */
                  !selectedCancellationReason
                : true
            }
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
