import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { getTaskStatus } from 'redux/patient/addPatient/addPatientSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { requestForSubscriptionStatusOfPatient } from 'redux/subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'
import { WelloClinicAPIClient } from 'services/welloclinicAPIService'
import { getExpandedIPDAppointmentFromBundle } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { IPDAppointmentHandlerStatus } from './ipdAppointmentHandlerStatus'

const initialState: IPDAppointmentHandlerStatus = {
  fetchingAppointmentDetails: false,
  enableEndAppointmentButton: false,
  errorWhileUpdatingAppointment: false,
  appointmentFetched: false,
  appointmentDetailsUpdated: false,
  errorWhileFetchingAppointment: false,
  updatingAppointment: false,
  enableStartVideoButton: false,
  endingAppointment: false,
  endedAppointment: false,
  showResend: false,
}

const ipdActiveAppointmentSlice = createSlice({
  name: 'ipdActiveAppointmentSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IPDAppointmentHandlerStatus>) {
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingAppointment =
        action.payload.errorWhileUpdatingAppointment
      state.fetchingAppointmentDetails =
        action.payload.fetchingAppointmentDetails
      state.ipdAdmitDetail = action.payload.ipdAdmitDetail
      state.appointmentFetched = action.payload.appointmentFetched
      state.enableEndAppointmentButton =
        action.payload.enableEndAppointmentButton
      state.endedAppointment = action.payload.endedAppointment
      state.endingAppointment = action.payload.endingAppointment
      state.activeIPDActionDetails = action.payload.activeIPDActionDetails
      state.ipdAdmitDetail = action.payload.ipdAdmitDetail
      state.showResend = action.payload.showResend
    },
  },
})

export const fetchIPDAppointmentDetails =
  (appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IPDAppointmentHandlerStatus = {
      fetchingAppointmentDetails: true,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
      showResend: false,
    }
    dispatch(ipdActiveAppointmentSlice.actions.updatedStatus(state))
    try {
      const fhirClient: WelloClinicAPIClient = new WelloClinicAPIClient()

      const response: any = await fhirClient.doGetRequest(
        `/ipd/${appointmentId}`,
        {}
      )

      //   const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      //     const appointmentResponse: R4.IBundle = resp.right
      if (response?.total && response?.total > 0) {
        const fhirAppointments: FhirActiveIPDDetailsForMedicalRole[] =
          getExpandedIPDAppointmentFromBundle(response)
        console.log(fhirAppointments)
        const statusData = await getTaskStatus(fhirAppointments[0].patient)
        if (statusData) {
          state.showResend = true
        }
        dispatch(
          requestForSubscriptionStatusOfPatient(fhirAppointments[0].patient.id!)
        )
        // try {
        //   await getContentOfResource(
        //     'Patient',
        //     fhirAppointments[0].patient.id ?? '',
        //     'image/jpeg',
        //     "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)"
        //   )
        // } catch (error) {
        //   logger.info(error)
        //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
        // }

        state.appointmentFetched = true
        state.fetchingAppointmentDetails = false

        state.enableStartVideoButton = true
        state.activeIPDActionDetails = fhirAppointments[0]
        dispatch(ipdActiveAppointmentSlice.actions.updatedStatus(state))
      } else {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false
        state.errorReason = 'Appointment not available'
        dispatch(ipdActiveAppointmentSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
      state.errorWhileFetchingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details`
      dispatch(ipdActiveAppointmentSlice.actions.updatedStatus(state))
    }
  }

export const resetIPDDetails =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const resetState: IPDAppointmentHandlerStatus = {
      fetchingAppointmentDetails: false,
      enableEndAppointmentButton: false,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableStartVideoButton: false,
      endingAppointment: false,
      endedAppointment: false,
      showResend: false,
    }
    dispatch(ipdActiveAppointmentSlice.actions.updatedStatus(resetState))
  }

export default ipdActiveAppointmentSlice.reducer
