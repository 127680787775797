import { ICoding } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { reasonData } from 'utils/constants'
import { getOrderStatusText } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { cancelTherapyForTherapistCommon } from 'utils/fhirResoureHelpers/therapiesHelper'

interface Props {
  open: boolean
  onClose: () => void
  onSuccess: () => void

  treatmentPlanData: TreatmentPlanData
}

export const TherapyCancelCaptureMobile: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  treatmentPlanData,
}) => {
  const [selectedCancellationReason, setCancelReason] = useState<ICoding>()

  const [updatingTherapyDetails, setUpdatingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const dispatch = useDispatch()

  function updateTherapyDetails() {
    if (selectedCancellationReason !== undefined) {
      setUpdatingTherapyDetails({
        requesting: true,
      })

      cancelTherapyForTherapistCommon(
        treatmentPlanData,
        selectedCancellationReason
      )
        .then((e) => {
          if (e !== undefined) {
            if (e) {
              setUpdatingTherapyDetails({
                requesting: false,
                requestSuccess: true,
              })
              dispatch(showSuccessAlert('Therapy cancelled successfully'))
              onSuccess()
            } else {
              setUpdatingTherapyDetails({
                requesting: false,
                requestError: true,
              })
              dispatch(
                showErrorAlert(
                  'Error while cancelling therapy details. Please try later'
                )
              )
            }
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
            dispatch(
              showErrorAlert(
                'Error while cancelling therapy details. Please try later'
              )
            )
          }
        })
        .catch((e) => {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })

          dispatch(
            showErrorAlert(
              'Error while cancelling therapy details. Please try later'
            )
          )
        })
    }
  }

  if (
    treatmentPlanData &&
    treatmentPlanData.treatmentPlan &&
    getOrderStatusText(treatmentPlanData.treatmentPlan) === 'Scheduled' &&
    treatmentPlanData.procedure === undefined
  ) {
    return (
      <BottomSheet
        open={open}
        onDismiss={onClose}
        snapPoints={({ minHeight, maxHeight }) => [
          minHeight + 32,
          maxHeight / 0.6,
        ]}
        // Opens the largest snap point by default, unless the user selected one previously
        defaultSnap={({ lastSnap, snapPoints }) => Math.min(...snapPoints) + 32}
        header={
          <Box display='flex' flexDirection='row'>
            <Box>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z'
                  fill='#1C274C'
                  style={{ fill: '#1C274C', fillOpacity: 1 }}
                />
              </svg>
            </Box>
            <Box width={8} />
            <Box>
              <Typography
                style={{
                  color: '#19274F',
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-1px',
                }}
              >
                Cancel Therapy
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <Box display='flex' width='100%'>
            <Button
              variant='contained'
              disabled={selectedCancellationReason === undefined}
              color='primary'
              style={{
                width: '100%',

                color: '#ffffff',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',

                flexGrow: 1,
                letterSpacing: '-0.16px',
                borderRadius: 8,
              }}
              onClick={() => {
                if (!updatingTherapyDetails.requesting) {
                  updateTherapyDetails()
                }
              }}
            >
              {updatingTherapyDetails.requesting && (
                <CircularProgress size={25} style={{ color: 'white' }} />
              )}
              {!updatingTherapyDetails.requesting && 'Submit'}
            </Button>
          </Box>
        }
      >
        <Box display='flex' flexDirection='column' px={2}>
          <Box display='flex' flexDirection='row' width='100%'>
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' flexDirection='row' alignSelf='stretch'>
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{
                    color: '#19274F',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.16px',
                    fontFeatureSettings: "'dlig' on",
                    fontVariantNumeric: 'lining-nums proportional-nums',
                  }}
                >
                  Select Reasons
                </Typography>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  flexWrap='wrap'
                  justifyContent='flex-start'
                  py={0.5}
                  width='100%'
                >
                  {reasonData.map((item, index: number) => (
                    <Chip
                      id={`cancelReason${index}`}
                      title={item.display ?? ''}
                      style={{
                        fontSize: '12px',
                        fontWeight: (
                          selectedCancellationReason !== undefined
                            ? selectedCancellationReason.code === item.code
                            : false
                        )
                          ? 500
                          : 400,
                        padding: '2px',
                        margin: '4px',

                        backgroundColor: '#DBDBF3',

                        color: (
                          selectedCancellationReason !== undefined
                            ? selectedCancellationReason.code === item.code
                            : false
                        )
                          ? '#4542D7'
                          : '#19274F',
                        lineHeight: '24px',
                      }}
                      variant={
                        (
                          selectedCancellationReason !== undefined
                            ? selectedCancellationReason.code === item.code
                            : false
                        )
                          ? 'outlined'
                          : 'default'
                      }
                      label={item.display ?? ''}
                      key={`key_${item.code}`}
                      size='medium'
                      clickable
                      color='primary'
                      onClick={(e) => {
                        setCancelReason(item as ICoding)
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BottomSheet>
    )
  }

  return <></>
}
