import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { Diet } from 'models/diet'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getIdsOfSelectedDetails,
  isExistingDiet,
  isExistingResource,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { requestDietDetails } from '../viewDiet/dietSearchSlice'
import { requestDietDetailsOPD } from '../viewDiet/dietSearchSliceOPD'
import { TreatmentAddStatus } from './addDietStatusType'

const initialState: TreatmentAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addDietSliceOPD = createSlice({
  name: 'addDietSliceOPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<TreatmentAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addDietBundle = action.payload.addDietBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<TreatmentAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addDietBundle = initialState.addDietBundle
    },
  },
})

export const addDietDetailsOPD =
  (treatment: Diet, fhirAppointmentDetails: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: TreatmentAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(addDietSliceOPD.actions.updateAddVitalsStatus(addingState))
    try {
      console.log(treatment)
      const bundleObject: R4.IBundle =
        createBundleObjectForObservations(treatment)
      console.log(bundleObject)

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: R4.IBundle | FHIRErrorResponses =
        await fhirClient.doCreateFHIRTransaction(``, bundleObject)

      /*       const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response) */
      if ((response as R4.IBundle)!.entry!.length > 0) {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Diet plan updated successfully'))
        dispatch(addDietSliceOPD.actions.updateAddVitalsStatus(addingState))
        dispatch(
          requestDietDetailsOPD(
            fhirAppointmentDetails.patient,
            fhirAppointmentDetails.encounter?.id!,
            fhirAppointmentDetails.appointment,
            fhirAppointmentDetails.encounter?.id!
          )
        )
      } else {
        const errorCreatePersonState: TreatmentAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addDietSliceOPD.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: TreatmentAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addDietSliceOPD.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddVitalsState = () => (dispatch: AppDispatch) => {
  dispatch(addDietSliceOPD.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(treatment: Diet): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const updatedTreatment: Diet = { ...treatment }

  const requestId: string = getUniqueTempId()
  if (isExistingResource(treatment.six ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.six![0].resourceType}/${treatment.six![0].id}`,
      resource: treatment.six![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.six![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.six ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.six![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.six![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.eight ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.eight![0].resourceType}/${treatment.eight![0].id}`,
      resource: treatment.eight![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.eight![0].id}`,
      },
    })
  } else {
    requestBundle.entry?.push({
      fullUrl: treatment.eight![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.eight![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.twelve ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.twelve![0].resourceType}/${
        treatment.twelve![0].id
      }`,
      resource: treatment.twelve![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.twelve![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.twelve ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.twelve![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.twelve![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.two ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.two![0].resourceType}/${treatment.two![0].id}`,
      resource: treatment.two![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.two![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.two ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.two![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.two![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }
  if (isExistingResource(treatment.five ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.five![0].resourceType}/${treatment.five![0].id}`,
      resource: treatment.five![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.five![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.five ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.five![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.five![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.seven ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.seven![0].resourceType}/${treatment.seven![0].id}`,
      resource: treatment.seven![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.seven![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.seven ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.seven![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.seven![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }
  console.log('------ dev requestBundle-------------', requestBundle)

  return requestBundle
}

export default addDietSliceOPD.reducer
