import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointSystemKind,
  QuestionnaireStatusKind,
  Questionnaire_ItemTypeKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { AppointmentRate, PractQuestions } from 'models/appointmentRate'
import { Conditions } from 'models/conditions'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloOrgDetails } from 'models/welloUnit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormErrors, FormMethods, useForm } from 'react-cool-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ContinuousColorLegend } from 'react-vis'
// import {
//   openBasicDetailsForm,
//   openBasicForm,
// } from 'redux/administration/userSetup/unitActorSetupFormsSlice/unitActorSetupFormsSlice'
import {
  resetUserCompletionState,
  updatePractitionerBasicDetails,
  updatePractitionerRoleDetails,
  openBasicForm,
} from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { getEducationalColleges } from 'services/fileServices'
import { isUserDoctor } from 'services/userDetailsService'
import { PROFILE_SELF_DESCRIPTION_URL } from 'utils/constants/fhir_vs_ext_constants'
import { IdProofType } from 'utils/constants/proof_types'
import {
  addExtensionToExtensionsOfUrl,
  getAddress,
  getDefaultCodeOfSystemFromCodableConceptList,
  getFirstNameFromHumanName,
  getFirstNameFromHumanNameForData,
  getLastNameFromHumanName,
  getMiddleNameFromHumanName,
  getNameFromHumanName,
  getPreFix,
  getStringValueExtensionsOfUrl,
  getTelecomFromContactPoints,
  getValueAttachmentFromExtensionDataForUrl,
  getValueCodingFromExtension,
} from 'utils/fhirResourcesHelper'
import { validateGender } from 'utils/formValidators'
import { logger } from 'utils/logger'
import { validateMobileNumber } from 'wello-web-components'
import { ClinicalExperienceDetailsForm } from './clinician_detail_form'
import { UnitActorBasicDetailForm } from './unit_actor_basic_detail_form'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
  task?: R4.ITask
}

export interface UnitAdminRegFormValues {
  firstName: string
  middleName: string
  lastName: string
  profilePic: string
  email: string
  phoneNumber: string
  dob: string
  gender: string
  size: number
  address: string
  selfieImage: string
  identityProofTypeCode: string
  identityProofNumber: string
  identityProofImageFront: string
  identityProofImageFrontType: string
  identityProofImageFrontName: string
  identityProofImageBack: string
  identityProofImageBackType: string
  identityProofImageBackName: string
  addressProofTypeCode: string
  addressProofNumber: string
  addressProofImageFront: string
  addressProofImageFrontType: string
  addressProofImageFrontName: string
  addressProofImageBack: string
  addressProofImageBackType: string
  addressProofImageBackName: string
  idProofSameAsAddress: boolean
}
export const FinishUnitAccountSetUp: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
  task,
}) => {
  const userProfileCompletionSlice = useSelector(
    (state: RootState) => state.userProfileCompletionSlice
  )
  const practitioner: R4.IPractitioner = {
    ...userProfileCompletionSlice.practitionerDetail,
  }
  const practitionerRole: R4.IPractitionerRole = {
    ...userProfileCompletionSlice.practitionerRoleDetails,
  }
  const conditionsData: Conditions[] = []

  const data: Conditions[] = [...conditionsData]

  console.log(userProfileCompletionSlice.practitionerDetail)

  const question: R4.IQuestionnaire = {
    resourceType: 'Questionnaire',
    version: '0.10',
    name: 'ProviderPreAppointmentQuestionnaire',
    title: 'Provider Pre-Appointment Questionnaire',
    status: QuestionnaireStatusKind._active,
    subjectType: ['Patient'],
    date: moment().format('YYYY-MM-DD'),
    publisher: getNameFromHumanName(practitioner.name ?? []),

    description: 'Patient discharged feedback',
    copyright: 'NA',
    approvalDate: moment().format('YYYY-MM-DD'),
    lastReviewDate: '2023-11-02',
    effectivePeriod: {
      start: moment().format('YYYY-MM-DD'),
      end: '2025-11-02',
    },
    code: [
      {
        system: 'http://loinc.org',
        code: '71950-0',
        display: 'Health Assessment Questionnaire [HAQ]',
      },
      {
        system: 'http://snomed.info/sct',
        code: '273510007',
        display: 'Health assessment questionnaire',
      },
    ],
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-practitioner-role-ext',
        valueReference: {
          reference: `PractitionerRole/${practitionerRole.id}`,
        },
      },
    ],
    item: [
      {
        linkId: `1.1`,
        text: 'Reason for Visit',
        type: Questionnaire_ItemTypeKind._display,
        code: [
          {
            code: '406524005',
            system: 'http://snomed.info/sct',
            display: 'Reason for Visit',
          },
        ],
        required: true,
      },
    ],
  }

  const chargeItemDef: AppointmentRate = {
    inPerson: 0,
    video: 0,
    phone: 0,
    followUp: 0,
  }

  const practConditions: PractQuestions = {
    conditions: [
      {
        checked: true,
        data: {
          system: 'http://snomed.info/sct',
          code: '406524005',
          display: 'Reason for Visit',
        },
      },
    ],
  }

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))
  const classes = useStyles()

  const [eduColleges, setEduCollegs] = useState<WelloOrgDetails[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [roleSpecificFormErrors, setRoleSpecificFormErrors] =
    useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const codingData = getValueCodingFromExtension(
    userProfileCompletionSlice.practitionerDetail.extension,
    'http://wellopathy.com/fhir/india/core/Code/identityProofType'
  )
  const isUpdating =
    userProfileCompletionSlice.updatingBasicDetails ||
    userProfileCompletionSlice.updatingRoleSpecificDetails
  const formRef: FormMethods<UnitAdminRegFormValues> =
    useForm<UnitAdminRegFormValues>({
      defaultValues: {
        firstName: `${getPreFix(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        )} ${getFirstNameFromHumanNameForData(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        )}`,
        lastName: getLastNameFromHumanName(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        ),
        profilePic: '',
        middleName: getMiddleNameFromHumanName(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        ),
        email:
          getTelecomFromContactPoints(
            userProfileCompletionSlice.practitionerDetail.telecom ?? [],
            ContactPointSystemKind._email
          ) ?? '',
        phoneNumber:
          getTelecomFromContactPoints(
            userProfileCompletionSlice.practitionerDetail.telecom ?? [],
            ContactPointSystemKind._phone
          ) ?? '',
        address: getAddress(
          userProfileCompletionSlice.practitionerDetail.address ?? []
        ),
        dob: userProfileCompletionSlice.practitionerDetail.birthDate ?? '',
        size: 0,
        gender: userProfileCompletionSlice.practitionerDetail.gender
          ? userProfileCompletionSlice.practitionerDetail.gender
          : '',
        selfieImage: '',
        identityProofTypeCode: codingData
          ? codingData.code ?? IdProofType[0].code ?? ''
          : IdProofType[0].code ?? '',
        identityProofNumber:
          getValueAttachmentFromExtensionDataForUrl(
            userProfileCompletionSlice.practitionerDetail.extension,
            'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
          ) ?? '',
        identityProofImageFront: '',
        identityProofImageBack: '',
        addressProofNumber: '',
        addressProofImageFront: '',
        addressProofImageBack: '',
        addressProofTypeCode: '',
        idProofSameAsAddress: true,
        identityProofImageFrontType: '',
        identityProofImageFrontName: '',
        identityProofImageBackName: '',
        identityProofImageBackType: '',
        addressProofImageFrontType: '',
        addressProofImageBackName: '',
        addressProofImageBackType: '',
        addressProofImageFrontName: '',
      },
      validate: (values) => {
        const errors: FormErrors<UnitAdminRegFormValues> = {}
        const res: string = validateMobileNumber(values.phoneNumber, true)
        if (res != null && res.length > 0) {
          errors.phoneNumber = res
        }

        const genderRes: string = validateGender(values.gender, true)
        if (genderRes != null && genderRes.length > 0) {
          errors.gender = genderRes
        }

        return errors
      },
      onSubmit: (values) => {
        logger.info('values', values)
        dispatch(updatePractitionerBasicDetails(values, task))
      },
      onError: (errors) => {
        logger.info('errors', errors)
      },
    })

  const { t } = useTranslation()

  const dispatch = useDispatch()

  function getCollegesList() {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getEducationalColleges()
      .then((e: WelloOrgDetails[]) => {
        setLoading(false)
        if (e) {
          setEduCollegs(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching colleges list update. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    getCollegesList()
  }, [])

  useEffect(() => {
    if (userProfileCompletionSlice.updatedRoleSpecificDetails) {
      dispatch(resetUserCompletionState())
      onContinueClick()
    }
  }, [
    userProfileCompletionSlice.updatedRoleSpecificDetails,
    onContinueClick,
    dispatch,
  ])

  function isValidRoleDetails(): boolean {
    let result: boolean = true
    if (practitioner.qualification) {
      if (practitioner.qualification.length) {
        practitioner.qualification.forEach((e) => {
          if (e.code === undefined || e.code.coding === undefined) {
            logger.info('qualification code')

            result = false
          }
          if (e.identifier === undefined || e.identifier.length === 0) {
            logger.info('qualification identifier')

            result = false
          }
          if (e.issuer === undefined) {
            logger.info('qualification issuer')

            result = false
          }
          if (e.period === undefined || e.period.end === undefined) {
            logger.info('qualification period')

            result = false
          }
          if (e.extension === undefined || e.extension.length === 0) {
            logger.info('qualification  modifierExtension')

            result = false
          }
        })
      }
    } else {
      logger.info('qualification empty')
      result = false
    }
    if (isUserDoctor(practitionerRole)) {
      if (
        practitioner.communication === undefined ||
        practitioner.communication.length === 0
      ) {
        logger.info(' communication ')
        result = false
      }
      if (
        getStringValueExtensionsOfUrl(
          practitioner.extension,
          PROFILE_SELF_DESCRIPTION_URL
        ) === undefined ||
        getStringValueExtensionsOfUrl(
          practitioner.extension,
          PROFILE_SELF_DESCRIPTION_URL
        )?.length === 0
      ) {
        logger.info('Profile details')
        result = false
      }
      //   if (chargeItemDef.phone <= 1 || chargeItemDef.phone > 1000) {
      //     result = false
      //   }
      if (chargeItemDef.inPerson <= 1 || chargeItemDef.inPerson > 1000) {
        result = false
      }

      if (chargeItemDef.video <= 1 || chargeItemDef.video > 1000) {
        result = false
      }

      if (chargeItemDef.followUp <= 1 || chargeItemDef.followUp > 10) {
        result = false
      }
      if (isUserDoctor(practitionerRole)) {
        if (practConditions.conditions.length === 0) {
          result = false
        }
      }
    }
    if (
      practitionerRole.specialty === undefined ||
      practitionerRole.specialty.length === 0
    ) {
      logger.info('specialty')
      result = false
    }

    return result
  }

  function validateRoleSpecificDetails() {
    const res = isValidRoleDetails()

    if (res) {
      dispatch(
        updatePractitionerRoleDetails(
          practitioner,
          practitionerRole,
          chargeItemDef,
          practConditions,
          question,
          task
        )
      )
    } else {
      logger.info('Error in form')
    }
    setRoleSpecificFormErrors(!res)
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:finish_user_setup_details')}
      </DialogTitle>
      <DialogContent dividers={true}>
        {userProfileCompletionSlice.currentStep === 'basic_details' && (
          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-start'
          >
            <UnitActorBasicDetailForm formRef={formRef} />
          </Box>
        )}
        {userProfileCompletionSlice.currentStep === 'role_specific_details' &&
          eduColleges.length > 0 && (
            <ClinicalExperienceDetailsForm
              practitioner={practitioner}
              practitionerRole={practitionerRole}
              question={practConditions}
              chargeItemDef={chargeItemDef}
              hasError={roleSpecificFormErrors}
              onSplChange={(spl: R4.ICodeableConcept[]) => {
                practitionerRole.specialty = spl
              }}
              onProfileDescriptionChange={(desc: string) => {
                const selfExt: R4.IExtension = {
                  url: PROFILE_SELF_DESCRIPTION_URL,
                  valueString: desc,
                }

                practitioner.extension = addExtensionToExtensionsOfUrl(
                  practitioner.extension,
                  selfExt
                )
              }}
              eduColleges={eduColleges}
            />
          )}
      </DialogContent>
      <DialogActions>
        {userProfileCompletionSlice.updatingBasicDetails && (
          <CircularProgress />
        )}
        {userProfileCompletionSlice.updatingRoleSpecificDetails && (
          <CircularProgress />
        )}

        {!isUpdating && (
          <Box flex='display' flexDirection='row'>
            {userProfileCompletionSlice.currentStep === 'basic_details' && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  formRef.submit()
                }}
              >
                {t('labelCommon:continue')}
              </Button>
            )}

            {userProfileCompletionSlice.currentStep ===
              'role_specific_details' && (
              <Box display='flex' flexGrow width='100%' px={1}>
                {/* <Box display='flex' flexGrow='1' flexDirection='row'>
                  <Button variant='contained' color='primary'>
                    {t('preview')}
                  </Button>
                </Box> */}
                <Box display='flex' flexDirection='row'>
                  {/* <Box px={1}>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        dispatch(openBasicForm())
                      }}
                    >
                      {t('labelCommon:back')}
                    </Button>
                  </Box> */}
                  <Box px={1}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        logger.info('Pract', practitioner)
                        logger.info('Pract Role ', practitionerRole)
                        validateRoleSpecificDetails()
                      }}
                    >
                      {t('labelCommon:finish')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
