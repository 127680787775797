import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  IPractitioner,
  IPractitionerRole,
  IReference,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getReferralRequestsOfAppointment } from 'redux/consultation/referrals_list/referralListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { addAndGetEncounterIdOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  getCommunicationResourceForFollowUp,
} from 'utils/careplan_utils/careplan_utils'
import { sleep } from 'utils/dateUtil'
import { getFullNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { ReferralAdditionStatus } from './referralAdditionState'

const initialState: ReferralAdditionStatus = {
  addingLabTests: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const referralAdditionSlice = createSlice({
  name: 'referralAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ReferralAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingLabTests = action.payload.addingLabTests
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },

    resetBookFollowUpAppointment(
      state,
      action: PayloadAction<ReferralAdditionStatus>
    ) {
      state.addingLabTests = false
      state.additionSuccessful = false
      state.errorWhileAdding = false
      state.errorReason = undefined
      state.successMessage = undefined
    },
  },
})

export const resetAddReferralCPGUpdate = () => (dispatch: AppDispatch) => {
  const resState: ReferralAdditionStatus = {
    addingLabTests: false,
    errorWhileAdding: false,
    additionSuccessful: false,
    noResultsAvailable: false,
    errorReason: '',
  }
  dispatch(referralAdditionSlice.actions.resetBookFollowUpAppointment(resState))
}

export const requestCDSSpecialistAppointment =
  (
    patient: R4.IPatient,
    specialization: R4.ICoding[],
    encounterId: string,
    carePlanUrl?: string,
    carePlanId?: string,
    referralNote?: string,
    selectedDoctorPractitionerRole?: IPractitionerRole,
    selectedDoctorPractitioner?: IPractitioner
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ReferralAdditionStatus = {
      ...initialState,
    }

    state.addingLabTests = true
    dispatch(referralAdditionSlice.actions.updatedStatus(state))
    try {
      const practitionerID = getCurrentUserPractitionerRoleDetails().id!
      const carePlanOfPatient: R4.ICarePlan | undefined =
        carePlanId !== undefined
          ? await getCarePlanByIdCurrentState(carePlanId)
          : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
      if (carePlanOfPatient) {
        const response: AxiosResponse = (await requestCdsAppointment({
          patient,
          specialization,
          practitionerID,
          encounterId,
          careplanId: carePlanOfPatient?.id!,
          referralNote,
          selectedDoctorPractitionerRole: selectedDoctorPractitionerRole!,
          selectedDoctorPractitioner: selectedDoctorPractitioner!,
        })) as AxiosResponse
        await sleep(5000)
        if (response) {
          const resp: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (resp._tag === 'Right') {
            state.addingLabTests = false
            state.additionSuccessful = true
            state.errorWhileAdding = false
            state.successMessage =
              'Referral appointment request has been assigned to Care Team'
            state.errorReason = undefined
            dispatch(referralAdditionSlice.actions.updatedStatus(state))
            dispatch(showSuccessAlert(state.successMessage!))
            /*  setTimeout(() => {
              dispatch(getReferralRequestsOfAppointment(appointmentDetails))
            }, 500) */
          } else {
            state.addingLabTests = false
            state.additionSuccessful = false
            state.errorWhileAdding = true
            state.successMessage = undefined
            state.errorReason =
              'Error while adding Referral appointments. Try later'
            dispatch(showErrorAlert(state.errorReason!))
            dispatch(referralAdditionSlice.actions.updatedStatus(state))
          }
        }
      } else {
        state.addingLabTests = false
        state.additionSuccessful = false
        state.errorWhileAdding = true
        state.successMessage = undefined
        state.errorReason = 'User is not subscribed to Care Plan'
        dispatch(showErrorAlert(state.errorReason!))
        dispatch(referralAdditionSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      const resState: ReferralAdditionStatus = {
        addingLabTests: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(showErrorAlert(state.errorReason!))
      dispatch(referralAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const requestCdsAppointment = async ({
  patient,
  specialization,
  practitionerID,
  encounterId,
  careplanId,
  referralNote,
  selectedDoctorPractitionerRole,
  selectedDoctorPractitioner,
}: {
  patient: R4.IPatient
  specialization: R4.ICoding[]
  practitionerID: string
  encounterId: string
  careplanId: string
  referralNote?: string
  selectedDoctorPractitionerRole: IPractitionerRole
  selectedDoctorPractitioner: IPractitioner
}): Promise<AxiosResponse> => {
  const enRolClient: EnrolCient = new EnrolCient()
  const practReference: IReference = {
    ...selectedDoctorPractitionerRole.practitioner!,
    display: getFullNameFromHumanName(selectedDoctorPractitioner!.name ?? []),
  }
  const practitionerRoleReference: IReference = {
    ...selectedDoctorPractitionerRole.practitioner,
    reference: `PractitionerRole/${selectedDoctorPractitionerRole.id}`,
    display: getFullNameFromHumanName(selectedDoctorPractitioner!.name ?? []),
  }
  const response: AxiosResponse =
    await enRolClient.doCreateEnrolmentFlowRequest('doctor-referral', {
      specializations: specialization,
      patientId: `Patient/${patient.id}`,
      carePlanId: careplanId,
      requestingPractitionerRoleId: practitionerID,
      encounterId,
      maxDays: 1,
      selectedServiceType: '556',
      specializationSnomedCode: specialization[0],
      referralNote,
      selectedDoctorPractitionerRoleReference: practitionerRoleReference,
      selectedDoctorPractitionerReference: practReference,
    })

  return response
}

export default referralAdditionSlice.reducer
