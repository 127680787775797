import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import moment from 'moment'
import { updateVisaDetails } from 'redux/clinic/ipdmanagement/ipdRegister/ipdRegisterSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentUnit } from 'utils/careplan_utils/careplan_utils'
import { errorMsgForOPD } from 'utils/constants'
import { logger } from 'utils/logger'

import { DayCareRegisterStatus } from './dayCareRegisterStatus'

const initialState: DayCareRegisterStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  serviceRequest: '',
  patientError: '',
}

const dayCareegisterSlice = createSlice({
  name: 'dayCareegisterSlice',
  initialState,
  reducers: {
    addingPatientDetails(state, action: PayloadAction<DayCareRegisterStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientId = action.payload.patientId
      state.patientError = action.payload.patientError
      state.serviceRequest = action.payload.serviceRequest
      state.patientError = action.payload.patientError
    },

    patientDetailsAdded(state, action: PayloadAction<DayCareRegisterStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
      state.patientError = action.payload.patientError
    },

    errorInAddingPatientDetails(
      state,
      action: PayloadAction<DayCareRegisterStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
      state.patientError = action.payload.patientError
    },

    resetPatientDetails(state, action: PayloadAction<DayCareRegisterStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patientId = action.payload.patientId
      state.serviceRequest = action.payload.serviceRequest
      state.patientError = action.payload.patientError
    },
  },
})

export const registerDayCare =
  (
    discount: number,
    chargeItemDef?: R4.IChargeItemDefinition,
    patientDetails?: R4.IPatient,
    selectedServiceMedicine?: R4.ICoding,
    type?: string,
    visaDetails?: string,
    referralId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: DayCareRegisterStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
      serviceRequest: '',
    }
    dispatch(
      dayCareegisterSlice.actions.addingPatientDetails(addingCreatePersonState)
    )

    try {
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()

      logger.info('patientDetails')

      logger.info(patientDetails)

      let resource: any = {}
      const plan: R4.ICarePlan | undefined =
        await getCarePlanOfPatientWithCurrentUnit(
          patientDetails ? patientDetails.id ?? '' : ''
        )
      if (chargeItemDef && selectedServiceMedicine) {
        resource = {
          discountPercentage: discount > 0 ? discount.toFixed(2) : '0',
          numberOfDays: 1,
          patientId: patientDetails ? patientDetails.id ?? '' : '',
          isOpdBased: true,
          systemOfMedicine: selectedServiceMedicine,
          roomTypeChargeDetails: chargeItemDef,
          fromDate: moment().toISOString(),
          endDate: moment()
            .add(type === 'consultation' ? 1 : 4, 'hours')
            .toISOString(),
          carePlanId: plan ? plan.id ?? '' : undefined,
        }

        //   const fhirClient: FHIRApiClient = new FHIRApiClient()
        const enRolClient: EnrolCient = new EnrolCient()
        const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
          'ipd/request-booking',
          resource
        )

        const orderResponse: R4.IBundle = response as R4.IBundle
        let serviceRequestId: string = ''
        if (orderResponse.entry) {
          for (let i = 0; i < orderResponse.entry.length; i++) {
            const entryResponse = orderResponse.entry[i].response
            if (entryResponse) {
              if (entryResponse.location) {
                if (entryResponse.location.includes('ServiceRequest')) {
                  serviceRequestId = entryResponse.location.replace(
                    '/_history/1',
                    ''
                  )
                }
              }
            }
          }
        }
        if (visaDetails !== undefined) {
          const boolean = updateVisaDetails(visaDetails, serviceRequestId)
        }

        const successCreatePersonState: DayCareRegisterStatus = {
          adding: false,
          additionSuccessful: true,
          serviceRequest: serviceRequestId,
          patientId: patientDetails ? patientDetails.id ?? '' : '',
          error: false,
          errorMessage: '',
        }
        dispatch(
          dayCareegisterSlice.actions.patientDetailsAdded(
            successCreatePersonState
          )
        )

        return
      }
    } catch (error) {
      const errorCreatePersonState: DayCareRegisterStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        patientError: 'Patient is still in IPD/OPD',

        errorMessage: 'Something went wrong',
        serviceRequest: '',
      }
      dispatch(
        dayCareegisterSlice.actions.errorInAddingPatientDetails(
          errorCreatePersonState
        )
      )
    }
  }

// export async function updateVisaDetails(
//   visaDetails: R4.IBasic,
//   serviceId: string
// ) {
//   const oldVisaDetails: R4.IBasic = {
//     ...visaDetails,
//   }
//   const extensionS: R4.IExtension[] = oldVisaDetails.extension ?? []
//   //   extensionS.push({
//   //     url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-membership-definition-ext',
//   //     valueReference: {
//   //       type: 'ServiceRequest',
//   //       id: serviceId,
//   //       reference: `ServiceRequest/${serviceId}`,
//   //     },
//   //   })

//   const serviceExtension: R4.IExtension = {}
//   serviceExtension.url =
//     'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-service-request-ref-ext'
//   serviceExtension.valueReference = {
//     type: 'ServiceRequest',
//     id: serviceId.split('/')[1],
//     reference: `${serviceId}`,
//   }
//   const newExtArr: R4.IExtension[] = []
//   newExtArr.push(serviceExtension)

//   const kvpAll: R4.IExtension[] = oldVisaDetails.extension
//     ? oldVisaDetails.extension.concat(newExtArr)
//     : []

//   oldVisaDetails.extension = kvpAll
//   const fhirClient: FHIRApiClient = new FHIRApiClient()
//   const response: any = await fhirClient.doUpdateFHIRResourceRequest(
//     `/Basic/${visaDetails.id}` ?? '',
//     oldVisaDetails,
//     visaDetails.meta?.versionId ?? ''
//   )

//   const relatedFhirDecodeRes: E.Either<Errors, R4.IBasic> =
//     R4.RTTI_Basic.decode(response)

//   if (relatedFhirDecodeRes._tag === 'Right') {
//     return true
//   }
//   return false
// }

export const resetDayCareRegister = () => (dispatch: AppDispatch) => {
  dispatch(dayCareegisterSlice.actions.resetPatientDetails(initialState))
}

export default dayCareegisterSlice.reducer
