import { Button, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import React from 'react'

// const useStyles = makeStyles((theme) => ({
//   padding: {
//     paddingBottom: '30px',
//   },
// }))

export const TopMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  //   const classes = useStyles()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  //   const handleClose = () => {
  //     setAnchorEl(null)
  //   }

  return (
    <div>
      <Button
        onClick={handleClick}
        style={{
          color: useTheme().palette.common.black,
        }}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography variant='subtitle1'>All Doctors</Typography>
      </Button>
    </div>
  )
}
