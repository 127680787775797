import { R4 } from '@ahryman40k/ts-fhir-types'
import { PatientGenderKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirReferralInsightDetails } from 'models/fhirPrescriptionDetails'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { getGenderOfPatient } from 'utils/fhirResourcesHelper'
import { isGenderFemale } from 'utils/fhirResoureHelpers/ipdHelper'
import {
  addObservationDetailsForSys,
  getObservationResourceForPostForSystemicExam,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { Appetite } from 'views/components/ipdconusltation/history/Appetite/appetite'
import { Diet } from 'views/components/ipdconusltation/history/Diet'
import { MensurationDetails } from 'views/components/ipdconusltation/history/mensuration/mensurations'
import { Micturition } from 'views/components/ipdconusltation/history/Micturition/micturition'
import { Obstrics } from 'views/components/ipdconusltation/history/obstrics/obstricsList'
import { Occupationals } from 'views/components/ipdconusltation/history/occupational'
import { OCARenderer } from 'wello-web-components'
import { MedicalConditions } from '../consultation/history/medical_conditions'
import { AddictionsHistory } from './history/addictionsHistory'
import { AllergiesHistory } from './history/allergiesHistory'
import { CurrentMedication } from './history/currentMedicationHistory'
import { FamilyMedicalConditionsHistory } from './history/familyHistory'
import { HabitsHistory } from './history/habitHistory'
import { MedicalConditionHistory } from './history/medicalConditionHistory'
import { OccupationalHistory } from './history/occupationalHistory'
import { ProceduresHistory } from './history/procedueHistory'
import { SymptomProfileForHistory } from './history/symptomProfileHistory'

interface MedicalAndHealthHistoryOfPatientProps {
  fhirAppointmentDetails?: FhirReferralInsightDetails
  split: boolean
}

export const MedicalHistorySubjectiveForReferral: React.FC<MedicalAndHealthHistoryOfPatientProps> =
  ({
    fhirAppointmentDetails,
    split,
  }: MedicalAndHealthHistoryOfPatientProps) => {
    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'history' }))
          }
        }}
      >
        <section id='history' style={{ width: '100%' }}>
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            onMouseEnter={() => showAddButton(true)}
            onMouseLeave={() => showAddButton(false)}
          >
            <Box display='flex' flexDirection='row' width='100%'>
              <Box py={1}>
                <Typography variant='h6' style={{ fontWeight: 'bolder' }}>
                  {' '}
                  {t('labelCommon:History')}{' '}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width={split ? '100%' : '100%'}
          >
            <MedicalConditionHistory
              conditions={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyConditionData ?? []
                  : []
              }
            />
          </Box>

          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <ProceduresHistory
              procedures={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyProcedureData ?? []
                  : []
              }
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <AllergiesHistory
              allergies={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyAllergies ?? []
                  : []
              }
            />
          </Box>

          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <CurrentMedication
              medicationList={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyMedication ?? []
                  : []
              }
            />
          </Box>

          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <SymptomProfileForHistory
              data={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.symptomProfile ?? []
                  : []
              }
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <OccupationalHistory
              occupationList={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyOccupation ?? []
                  : []
              }
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <HabitsHistory
              habitList={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyHabits ?? []
                  : []
              }
            />
          </Box>

          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <AddictionsHistory
              addictionList={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyAddictions ?? []
                  : []
              }
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <FamilyMedicalConditionsHistory
              familyConditionList={
                fhirAppointmentDetails
                  ? fhirAppointmentDetails.historyFamilyHistory ?? []
                  : []
              }
            />
          </Box>
        </section>
      </ReactVisibilitySensor>
    )
  }
