import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  requestForAppointmentCount,
  resetAppointmentForUnit,
} from 'redux/welloAdmin/appointmentCount/appointmentCountPerUnit'
import {
  requestForPaymentTotalForUnit,
  resetRevenueForUnit,
} from 'redux/welloAdmin/revenue/paymentSearchSlice'
import {
  resetUnitSearchCount,
  searchForUnitCount,
} from 'redux/welloAdmin/unitCount/unitCountSearchSlice'
import {
  resetPatientSearchPatientForCount,
  searchPatientCount,
} from 'redux/welloAdmin/user/patientCount/patientCountSliceForUnit'
import {
  resetStateForWelloAdminPract,
  searchPractitionersForUnit,
} from 'redux/welloAdmin/user/practitionerSearchSliceAcrossUnit'
import {
  requestForWellnessAppointmentCount,
  resetWellnessAppointmentForUnit,
} from 'redux/welloAdmin/wellnessAppointmentCount/wellnessAppointmentCountPerUnit'
import SimpleBar from 'simplebar-react'
import { wpAdminOptionsMain } from 'utils/constants'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import CustomizedDividers from 'views/components/toggle_selector'
import { AppointmentCountTile } from '../appointmentCountTile'
import { OrgAppointmentCountTile } from '../orgAppointmentCountTile'
import { OrgOrderTile } from '../orgLabOrderCountTile'
import { PatientCountTile } from '../patinetCountTile'
import { RevenueTileOrg } from '../paymentTileOrg'
import { UnitCountTile } from '../unitCountTile'
import { UnitDetailsTile } from '../unitDetails'
// import { AppointmentCountTile } from './appointmentCountTile'
// import { PatientCountTile } from './patinetCountTile'
// import { RevenueTile } from './paymentAmountTile'
// import { UnitDetailsTile } from './unitDetails'

interface Props {
  org: R4.IOrganization
  mainOrgId: string[]
  type?: string
  isOdd?: boolean
  onCloseClicked: () => void
  onTypeSelected: (type: string) => void
  dense?: boolean
  date?: Date
  unitList: R4.IOrganization[]
}

export const MainOrgSplit: React.FC<Props> = ({
  org,
  mainOrgId,
  type,
  isOdd = false,
  onCloseClicked,
  onTypeSelected,
  dense = false,
  date,
  unitList,
}: Props) => {
  const practitionerSearchForWelloAdminSlice = useSelector(
    (state: RootState) => state.practitionerSearchForWelloAdminSlice
  )

  const patientSearchCountSlice = useSelector(
    (state: RootState) => state.patientSearchCountSlice
  )

  const appointmentCountPerUnitSlice = useSelector(
    (state: RootState) => state.appointmentCountPerUnitSlice
  )

  const totalPaymentUnitWiseSlice = useSelector(
    (state: RootState) => state.totalPaymentUnitWiseSlice
  )

  const unitCountSearchSlice = useSelector(
    (state: RootState) => state.unitCountSearchSlice
  )

  const appointmentWellnessCountPerUnitSlice = useSelector(
    (state: RootState) => state.appointmentWellnessCountPerUnitSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [optionId, setOptionId] = useState<string>(wpAdminOptionsMain[0].value)
  const typeData =
    getCodeOfSystemFromCodableConceptList(
      org.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.code ?? ''

  useEffect(() => {
    if (optionId === 'users')
      dispatch(searchPractitionersForUnit(org.id ?? '', mainOrgId))
    if (optionId === 'orders') {
      dispatch(requestForAppointmentCount(mainOrgId.join(','), 'lab'))
    }

    if (optionId === 'patient')
      dispatch(searchPatientCount(`Organization/${org.id}`, ''))
    if (optionId === 'appointments')
      dispatch(requestForWellnessAppointmentCount(mainOrgId.join(','), 'org'))
    if (optionId === 'revenue')
      dispatch(
        requestForPaymentTotalForUnit(
          mainOrgId.join(','),
          getCodeOfSystemFromCodableConceptList(
            org.type ?? [],
            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
          )?.code ?? ''
        )
      )
    if (optionId === 'units') {
      dispatch(searchForUnitCount(org.id ?? ''))
    }

    return () => {}
  }, [])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                justifyContent='flex-start'
                paddingLeft={1}
              >
                <Box
                  display='flex'
                  justifyContent='flex-start'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingBottom={1}
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                    style={{ color: 'black', fontWeight: 'bold' }}
                  >
                    Organization :
                  </Typography>
                  <Box paddingLeft={0.5}>
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      {org.name ?? ''}
                    </Typography>
                  </Box>
                </Box>
                <CustomizedDividers
                  onSelectionChanged={(id) => {
                    setOptionId(id)
                    if (id === 'patient') {
                      dispatch(resetUnitSearchCount())
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetAppointmentForUnit())
                      dispatch(resetRevenueForUnit())
                      dispatch(searchPatientCount(`Organization/${org.id}`, ''))
                    }

                    if (id === 'units') {
                      dispatch(resetUnitSearchCount())
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetRevenueForUnit())
                      dispatch(searchForUnitCount(org.id ?? ''))
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetWellnessAppointmentForUnit())

                      dispatch(resetAppointmentForUnit())
                    }
                    if (id === 'users') {
                      dispatch(resetUnitSearchCount())
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetAppointmentForUnit())
                      dispatch(resetRevenueForUnit())
                      dispatch(
                        searchPractitionersForUnit(org.id ?? '', mainOrgId)
                      )
                    }

                    if (id === 'orders') {
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetRevenueForUnit())

                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(
                        requestForAppointmentCount(mainOrgId.join(','), 'lab')
                      )
                    }

                    if (id === 'appointments') {
                      dispatch(resetUnitSearchCount())
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetRevenueForUnit())

                      dispatch(resetWellnessAppointmentForUnit())

                      dispatch(resetAppointmentForUnit())
                      dispatch(
                        requestForWellnessAppointmentCount(
                          mainOrgId.join(','),
                          'org'
                        )
                      )
                    }

                    // if (id === 'orders') {
                    //   dispatch(resetStateForWelloAdminPract())
                    //   dispatch(resetPatientSearchPatientForCount())
                    //   dispatch(resetRevenueForUnit())

                    //   dispatch(resetWellnessAppointmentForUnit())
                    //   dispatch(
                    //     requestForAppointmentCount(mainOrgId.join(','), 'lab')
                    //   )

                    //   //   } else {
                    //   //     dispatch(resetAppointmentForUnit())
                    //   //     dispatch(
                    //   //       requestForWellnessAppointmentCount(org.id ?? '')
                    //   //     )
                    //   //   }
                    // }

                    if (id === 'revenue') {
                      dispatch(resetUnitSearchCount())
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetAppointmentForUnit())
                      dispatch(
                        requestForPaymentTotalForUnit(
                          mainOrgId.join(','),
                          getCodeOfSystemFromCodableConceptList(
                            org.type ?? [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                          )?.code ?? ''
                        )
                      )
                    }
                    onTypeSelected(id)
                  }}
                  options={wpAdminOptionsMain}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                justifyContent='flex-end'
              >
                <Tooltip title='Close'>
                  <IconButton
                    aria-label='btn_ord_detail_menu'
                    onClick={onCloseClicked}
                  >
                    <CloseOutlined color='primary' />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            {practitionerSearchForWelloAdminSlice.searching &&
              optionId !== 'units' && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}

            {unitCountSearchSlice.searching && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {/* {practitionerSearchForWelloAdminSlice.noResultsAvailable && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <Typography variant='subtitle1' color='initial' align='center'>
                    No data available
                  </Typography>
                </Box>
              )} */}

            {patientSearchCountSlice.searching && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {/* {patientSearchCountSlice.noResultsAvailable && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <Typography variant='subtitle1' color='initial' align='center'>
                    No data available
                  </Typography>
                </Box>
              )} */}

            {appointmentCountPerUnitSlice.searchingAppointments && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {/* {appointmentCountPerUnitSlice.noResultsAvailable && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <Typography variant='subtitle1' color='initial' align='center'>
                    No data available
                  </Typography>
                </Box>
              )} */}

            {totalPaymentUnitWiseSlice.searchingAppointments && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {/* 
              {totalPaymentUnitWiseSlice.noResultsAvailable && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <Typography variant='subtitle1' color='initial' align='center'>
                    No data available
                  </Typography>
                </Box>
              )} */}

            <Box display='flex' flexDirection='column'>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height='100%'
                overflow='auto'
              >
                <SimpleBar
                  style={{
                    height: '100%',
                    width: '100%',
                    overflowX: 'hidden',
                    padding: '8px',
                  }}
                >
                  {/* {optionId === 'appointments' &&
                    appointmentCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentCountPerUnitSlice.count ?? 0}
                          type='clinic'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )} */}
                  {optionId === 'appointments' &&
                    appointmentWellnessCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={
                            appointmentWellnessCountPerUnitSlice.count ?? 0
                          }
                          type='org'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )}

                  {optionId === 'patient' &&
                    patientSearchCountSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <PatientCountTile
                          patientCount={patientSearchCountSlice.totalCount ?? 0}
                          malePatientCount={
                            patientSearchCountSlice.maleCount ?? 0
                          }
                          femalePatientCount={
                            patientSearchCountSlice.femaleCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'units' &&
                    unitCountSearchSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <UnitDetailsTile
                          unitId={org.id ?? ''}
                          unitName={org.name ?? ''}
                          type='mainOrg'
                          orgType='main'
                          docCount={
                            practitionerSearchForWelloAdminSlice.doctorCount ??
                            0
                          }
                          enabledDocCount={
                            practitionerSearchForWelloAdminSlice.enabledDocCount ??
                            0
                          }
                          disabledDocCount={
                            practitionerSearchForWelloAdminSlice.disabledDocCount ??
                            0
                          }
                          consultingDoctor={
                            practitionerSearchForWelloAdminSlice.consultingDoctorCount ??
                            0
                          }
                          enableConsultingDoc={
                            practitionerSearchForWelloAdminSlice.EnabledConsultingDoctorCount ??
                            0
                          }
                          disableConsultingDoc={
                            practitionerSearchForWelloAdminSlice.disabledConsultingDoctor ??
                            0
                          }
                          ayurvedaDoctorCount={
                            practitionerSearchForWelloAdminSlice.ayurvedaDoctorCount ??
                            0
                          }
                          enableAyurveda={
                            practitionerSearchForWelloAdminSlice.enabledAyurvedaDocCount ??
                            0
                          }
                          disableAyurveda={
                            practitionerSearchForWelloAdminSlice.disabledAyurvedaDocCount ??
                            0
                          }
                          unitAdminCount={
                            practitionerSearchForWelloAdminSlice.unitAdminCount ??
                            0
                          }
                          enableUnitAdmin={
                            practitionerSearchForWelloAdminSlice.enabledUnitAdminCount ??
                            0
                          }
                          disableUnitAdmin={
                            practitionerSearchForWelloAdminSlice.disabledUnitAdmin ??
                            0
                          }
                          grmCount={
                            practitionerSearchForWelloAdminSlice.grmCount ?? 0
                          }
                          enableGrm={
                            practitionerSearchForWelloAdminSlice.enabledGrmCount ??
                            0
                          }
                          disableGrm={
                            practitionerSearchForWelloAdminSlice.disabledGrmCount ??
                            0
                          }
                          therapistCount={
                            practitionerSearchForWelloAdminSlice.therapistCount ??
                            0
                          }
                          enableTherapist={
                            practitionerSearchForWelloAdminSlice.enabledTherapistCount ??
                            0
                          }
                          disableTherapist={
                            practitionerSearchForWelloAdminSlice.disabledTherapistCount ??
                            0
                          }
                          radiologistCount={
                            practitionerSearchForWelloAdminSlice.radiologistCount ??
                            0
                          }
                          agentCount={
                            practitionerSearchForWelloAdminSlice.agentCount ?? 0
                          }
                          enableAgentCount={
                            practitionerSearchForWelloAdminSlice.enabledAgentCount ??
                            0
                          }
                          disableAgentCount={
                            practitionerSearchForWelloAdminSlice.disabledAgentCount ??
                            0
                          }
                          enabledNaturePathDocCount={
                            practitionerSearchForWelloAdminSlice.enabledRadioLogistCount ??
                            0
                          }
                          disabledNaturepathDoc={
                            practitionerSearchForWelloAdminSlice.disabledRadiologistCount ??
                            0
                          }
                          ptCount={
                            practitionerSearchForWelloAdminSlice.ptDocCount ?? 0
                          }
                          enabledPtCount={
                            practitionerSearchForWelloAdminSlice.enabledPTdoctorCount ??
                            0
                          }
                          disabledPtCount={
                            practitionerSearchForWelloAdminSlice.disabledPTDocCount ??
                            0
                          }
                          dieticianCount={
                            practitionerSearchForWelloAdminSlice.dietician ?? 0
                          }
                          enabledDieticianCount={
                            practitionerSearchForWelloAdminSlice.enabledDieticianDoctorCount ??
                            0
                          }
                          disabledDieticianCount={
                            practitionerSearchForWelloAdminSlice.disabledDieticianDocCount ??
                            0
                          }
                          mainOrgIds={mainOrgId}
                        />
                      </Box>
                    )}
                  {optionId === 'users' &&
                    practitionerSearchForWelloAdminSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <UnitDetailsTile
                          unitId={org.id ?? ''}
                          unitName={org.name ?? ''}
                          type='mainOrg'
                          orgType='main'
                          docCount={
                            practitionerSearchForWelloAdminSlice.doctorCount ??
                            0
                          }
                          enabledDocCount={
                            practitionerSearchForWelloAdminSlice.enabledDocCount ??
                            0
                          }
                          disabledDocCount={
                            practitionerSearchForWelloAdminSlice.disabledDocCount ??
                            0
                          }
                          consultingDoctor={
                            practitionerSearchForWelloAdminSlice.consultingDoctorCount ??
                            0
                          }
                          enableConsultingDoc={
                            practitionerSearchForWelloAdminSlice.EnabledConsultingDoctorCount ??
                            0
                          }
                          disableConsultingDoc={
                            practitionerSearchForWelloAdminSlice.disabledConsultingDoctor ??
                            0
                          }
                          ayurvedaDoctorCount={
                            practitionerSearchForWelloAdminSlice.ayurvedaDoctorCount ??
                            0
                          }
                          enableAyurveda={
                            practitionerSearchForWelloAdminSlice.enabledAyurvedaDocCount ??
                            0
                          }
                          disableAyurveda={
                            practitionerSearchForWelloAdminSlice.disabledAyurvedaDocCount ??
                            0
                          }
                          unitAdminCount={
                            practitionerSearchForWelloAdminSlice.unitAdminCount ??
                            0
                          }
                          enableUnitAdmin={
                            practitionerSearchForWelloAdminSlice.enabledUnitAdminCount ??
                            0
                          }
                          disableUnitAdmin={
                            practitionerSearchForWelloAdminSlice.disabledUnitAdmin ??
                            0
                          }
                          grmCount={
                            practitionerSearchForWelloAdminSlice.grmCount ?? 0
                          }
                          enableGrm={
                            practitionerSearchForWelloAdminSlice.enabledGrmCount ??
                            0
                          }
                          disableGrm={
                            practitionerSearchForWelloAdminSlice.disabledGrmCount ??
                            0
                          }
                          therapistCount={
                            practitionerSearchForWelloAdminSlice.therapistCount ??
                            0
                          }
                          enableTherapist={
                            practitionerSearchForWelloAdminSlice.enabledTherapistCount ??
                            0
                          }
                          disableTherapist={
                            practitionerSearchForWelloAdminSlice.disabledTherapistCount ??
                            0
                          }
                          radiologistCount={
                            practitionerSearchForWelloAdminSlice.radiologistCount ??
                            0
                          }
                          agentCount={
                            practitionerSearchForWelloAdminSlice.agentCount ?? 0
                          }
                          enableAgentCount={
                            practitionerSearchForWelloAdminSlice.enabledAgentCount ??
                            0
                          }
                          disableAgentCount={
                            practitionerSearchForWelloAdminSlice.disabledAgentCount ??
                            0
                          }
                          enabledNaturePathDocCount={
                            practitionerSearchForWelloAdminSlice.enabledRadioLogistCount ??
                            0
                          }
                          disabledNaturepathDoc={
                            practitionerSearchForWelloAdminSlice.disabledRadiologistCount ??
                            0
                          }
                          ptCount={
                            practitionerSearchForWelloAdminSlice.ptDocCount ?? 0
                          }
                          enabledPtCount={
                            practitionerSearchForWelloAdminSlice.enabledPTdoctorCount ??
                            0
                          }
                          disabledPtCount={
                            practitionerSearchForWelloAdminSlice.disabledPTDocCount ??
                            0
                          }
                          dieticianCount={
                            practitionerSearchForWelloAdminSlice.dietician ?? 0
                          }
                          enabledDieticianCount={
                            practitionerSearchForWelloAdminSlice.enabledDieticianDoctorCount ??
                            0
                          }
                          disabledDieticianCount={
                            practitionerSearchForWelloAdminSlice.disabledDieticianDocCount ??
                            0
                          }
                          mainOrgIds={mainOrgId}
                        />
                      </Box>
                    )}

                  {optionId === 'units' &&
                    unitCountSearchSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <UnitCountTile
                          orgId={org.id ?? ''}
                          name={org.name ?? ''}
                          clinicCount={unitCountSearchSlice.clinicCount}
                          labCount={unitCountSearchSlice.labCount}
                          wellnessCount={
                            unitCountSearchSlice.wellnessCenterCount
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'units' &&
                    unitCountSearchSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <UnitCountTile
                          orgId={org.id ?? ''}
                          name={org.name ?? ''}
                          clinicCount={unitCountSearchSlice.clinicCount}
                          labCount={unitCountSearchSlice.labCount}
                          wellnessCount={
                            unitCountSearchSlice.wellnessCenterCount
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'patient' &&
                    patientSearchCountSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <PatientCountTile
                          patientCount={patientSearchCountSlice.totalCount ?? 0}
                          malePatientCount={
                            patientSearchCountSlice.maleCount ?? 0
                          }
                          femalePatientCount={
                            patientSearchCountSlice.femaleCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'appointments' &&
                    appointmentWellnessCountPerUnitSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <OrgAppointmentCountTile
                          ipdCount={appointmentWellnessCountPerUnitSlice.count}
                          type='org'
                          unitId={org.id ?? ''}
                          newCount={
                            appointmentWellnessCountPerUnitSlice.ipdCount
                          }
                          opdCount={
                            appointmentWellnessCountPerUnitSlice.consultationCount
                          }
                          dayCareCount={
                            appointmentWellnessCountPerUnitSlice.dayCareCount
                          }
                          mainOrg='yes'
                          unitList={unitList}
                        />
                      </Box>
                    )}

                  {optionId === 'orders' &&
                    appointmentCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        paddingTop={1}
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentCountPerUnitSlice.count}
                          type='lab'
                          unitId={org.id ?? ''}
                          mainOrg='yes'
                        />
                      </Box>
                    )}

                  {optionId === 'orders' &&
                    appointmentCountPerUnitSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        paddingTop={1}
                      >
                        <OrgOrderTile
                          ipdCount={appointmentCountPerUnitSlice.count}
                          type='org'
                          unitId={org.id ?? ''}
                          mainOrg='yes'
                          unitList={unitList}
                        />
                        {/* <AppointmentCountTile
                          ipdCount={appointmentCountPerUnitSlice.count}
                          type='lab'
                          unitId={org.id ?? ''}
                          mainOrg='yes'
                        /> */}
                      </Box>
                    )}

                  {/* {optionId === 'appointments' &&
                    appointmentWellnessCountPerUnitSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentWellnessCountPerUnitSlice.count}
                          type='wellness_center'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )} */}

                  {optionId === 'revenue' &&
                    totalPaymentUnitWiseSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <RevenueTileOrg
                          ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                          unitList={unitList}
                        />
                      </Box>
                    )}
                  {optionId === 'revenue' &&
                    totalPaymentUnitWiseSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <RevenueTileOrg
                          ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                          unitList={unitList}
                        />
                      </Box>
                    )}
                </SimpleBar>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
