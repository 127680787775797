import { Box, Button, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { resetAppointmentState } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { endClinicVisit } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { resetSearchAppointmentForDisplay } from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { resetFollowupAppointmentState } from 'redux/appointments/followupManager/followAappointmentManagerSlice'
import { resetSlotSelectionStatus } from 'redux/fhirMedicalResources/clinicalImpression/clinicalImpressionSlice'
import { RootState } from 'redux/rootReducer'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { CreateFollowupAppointmentManager } from 'views/components/appointments/createFollowupAppointmentManager'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { WelloLoadingIndicator } from 'wello-web-components'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  disable: boolean
  OnNo: () => void
}

export const EndVisit: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  disable,
  OnNo,
}: NextStepsOfConsultationProps) => {
  const appointmentHandlerSlice = useSelector(
    (state: RootState) => state.appointmentHandlerSlice
  )
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const [showDeleteConfirmationForSplit, setShowDeleteConfirmationForSplit] =
    useState<ConfirmationDialogProps>({
      open: false,
    })
  const [
    openCreateFollowupAppointmentPopup,
    setOpenCreateFollowupAppointmentPopup,
  ] = useState(false)

  useEffect(() => {
    if (appointmentHandlerSlice.endedAppointment) {
      window.location.href = `/${getVendorPartId()}/dashboard`
    }
  }, [appointmentHandlerSlice.endedAppointment, history])

  return (
    <Box display='flex' flexDirection='row'>
      {appointmentHandlerSlice.endingAppointment && (
        <WelloLoadingIndicator message='Finishing Appointment' />
      )}
      {!appointmentHandlerSlice.endingAppointment && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row' padding={0.5} flexGrow>
            <Box py={1}>
              <Typography
                variant='h6'
                color='primary'
                style={{ fontSize: 18, fontWeight: 'bold' }}
              >
                {' '}
                {t('labelCommon:end_visit')}{' '}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' p={0.5} flexGrow>
            <Box py={1}>
              <Typography variant='subtitle1'>
                {' '}
                Do you wish to end the appointment with the current patient?{' '}
              </Typography>
            </Box>
            <Box py={1} display='flex' flexDirection='row' flexGrow>
              <Button
                variant='contained'
                color='primary'
                id='yes'
                disabled={disable}
                onClick={() => {
                  if (appointmentHandlerSlice.appointmentDetails) {
                    setShowDeleteConfirmationForSplit({
                      open: true,
                      onContinueActionClicked: () => {
                        setShowDeleteConfirmationForSplit({
                          open: false,
                        })
                        setOpenCreateFollowupAppointmentPopup(true)
                      },
                      onCancelActionClicked: () => {
                        if (appointmentHandlerSlice.appointmentDetails)
                          dispatch(
                            endClinicVisit(
                              appointmentHandlerSlice.appointmentDetails
                            )
                          )
                        setShowDeleteConfirmationForSplit({
                          open: false,
                        })
                      },
                      displayText:
                        'Do you want to create a follow-up appointment for this patient?',
                      continueActionButtonText: 'Yes',
                      cancelActionButtonText: 'No',
                    })
                  }
                }}
              >
                Yes, End Appointment
              </Button>
              <Button
                variant='contained'
                id='no'
                color='primary'
                disabled={disable}
                onClick={() => {
                  OnNo()
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {showDeleteConfirmationForSplit.open && (
        <ConfirmationDialog
          {...showDeleteConfirmationForSplit}
          id='split'
          title='Follow-up'
        />
      )}

      <CreateFollowupAppointmentManager
        open={openCreateFollowupAppointmentPopup}
        preSelectedDoctor={{
          id: fhirAppointmentDetails.practitionerDetail.practitioner.id ?? '',
          roleObject:
            fhirAppointmentDetails.practitionerDetail.practitionerRole,
          practRoleId:
            fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
          practitionerObject:
            fhirAppointmentDetails.practitionerDetail.practitioner,
          name: getNameFromHumanName(
            fhirAppointmentDetails.practitionerDetail.practitioner.name ?? []
          ),
          phone: '',
          fullName: '',
          color: '',
          primaryContact: '',
          status: '',
          enabled: fhirAppointmentDetails.practitionerDetail.practitioner.active
            ? fhirAppointmentDetails.practitionerDetail.practitioner.active
            : false,
        }}
        preSelectedSlotStartTime={new Date()}
        fhirAppointmentDetail={appointmentHandlerSlice.appointmentDetails}
        onClose={() => {
          setOpenCreateFollowupAppointmentPopup(false)
          dispatch(resetAppointmentState())
          dispatch(resetSlotSelectionStatus())
          /* dispatch(
                      requestAppointmentSForTheDateRange(
                        new Date(),
                        new Date(),
                        selectedDoctors
                      )
                    ); */
        }}
        onAppointmentCreated={(createdAppointment) => {
          setOpenCreateFollowupAppointmentPopup(false)
          dispatch(resetFollowupAppointmentState())
          dispatch(resetSlotSelectionStatus())
          dispatch(resetSearchAppointmentForDisplay())
          if (appointmentHandlerSlice.appointmentDetails)
            dispatch(endClinicVisit(appointmentHandlerSlice.appointmentDetails))
        }}
        followUp={true}
        preDefinedAllowed={true}
      />
    </Box>
  )
}
