import { R4 } from '@ahryman40k/ts-fhir-types'

export const UnitTypes: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type',
    code: 'clinic',
    display: 'Clinic',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type',
    code: 'lab',
    display: 'Lab',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type',
    code: 'collection_center',
    display: 'Collection Center',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type',
    code: 'wellness_center',
    display: 'Wellness Center',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const UnitTypesForSetting: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type',
    code: 'clinic',
    display: 'Clinic',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type',
    code: 'lab',
    display: 'Lab',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const serviceTypeForSetting: R4.ICoding[] = [
  {
    code: '556',
    display: 'In-Person',
    system: 'http://terminology.hl7.org/CodeSystem/service-type',
  },
  {
    system: 'http://wellopathy.com/service-type-codes',
    code: 'video-counselling',
    display: 'Video Appointment',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const serviceTypeForLab: R4.ICoding[] = [
  {
    system: '',
    code: 'lab',
    display: 'Order',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
