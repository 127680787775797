import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getTestNameFromOrderDetails } from 'utils/fhirResoureHelpers/referralHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { LabReferralService, LabTabularService } from 'models/labOfferDetail'
import {
  getLabName,
  getLabOrgName,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { WelloSignExam } from 'models/WelloConditions'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { getDataString } from 'utils/fhirResoureHelpers/ipdObservationHelper'

interface MedicationTileProps {
  obsData: WelloSignExam[]
  split: boolean
  onEditCLicked?: (medication: WelloSignExam) => void
}

export const PhysicalExaForDisplayTileReferral: React.FC<MedicationTileProps> =
  ({ obsData, split, onEditCLicked }: MedicationTileProps) => {
    const loggedInUnitType =
      getCodeOfSystemFromCodableConceptList(
        getCurrentUserUnitDetails().type ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
      )?.display ?? ''

    console.log(obsData)

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        width='100%'
        border={4}
        style={{
          boxShadow: '0px 0px 4px #ccc',
          margin: '2px 0px',
          borderRadius: '1px',
          borderColor: '#F8F8F8',
          width: '100%',
          height: 'auto',
          backgroundColor: '#F8F8F8',
        }}
      >
        <Grid container direction='column' spacing={1}>
          {/* ------------------------------------------------------------------------------- */}
          {obsData.map((e, index: number) => (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              flexGrow={1}
              key={e.id}
            >
              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor:
                    index % 2 === 0 ? '#EDF2FA' : kDialogueBackground,
                }}
              >
                <Grid item xs={12}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box display='flex' flexDirection='column' width='100%'>
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        height={30}
                        alignItems='center'
                        paddingLeft={1}
                        width='100%'
                      >
                        {e.condition && (
                          <OverflowTypography
                            text={`${getDataString(e)}`}
                            typographyProps={{
                              variant: 'subtitle2',
                              color: 'initial',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    {split === false && (
                      <Box
                        justifyContent='flex-end'
                        display='flex'
                        height={30}
                        alignItems='center'
                        paddingRight={3}
                      >
                        <Tooltip title='Edit' id='chief_tool_edit'>
                          <IconButton
                            aria-label='btn_ord_cancel'
                            color='primary'
                            id='physical_tool_edit_button'
                            style={{ padding: 0 }}
                            onClick={() => {
                              if (onEditCLicked) onEditCLicked(e)
                            }}
                          >
                            <img
                              id='chief_tool_edit_img'
                              src={`${process.env.PUBLIC_URL}/editVector.png`}
                              alt='Edit'
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Box>
    )
  }
