import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getUserCurrentRole,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/rateHelper'
import { logger } from 'utils/logger'
import { MedicalServiceProviderSearchStatus } from './medicalServiceProviderSearchStatus'

const initialState: MedicalServiceProviderSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const medicalProviderSearchSliceForRate = createSlice({
  name: 'medicalProviderSearchSliceForRate',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<MedicalServiceProviderSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
    },

    searchResults(
      state,
      action: PayloadAction<MedicalServiceProviderSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.doctorList = action.payload.doctorList
      state.doctorRoleList = action.payload.doctorRoleList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<MedicalServiceProviderSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<MedicalServiceProviderSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
    },
  },
})

export const searchMedicalProvidersForRate =
  (name?: string, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicalServiceProviderSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      medicalProviderSearchSliceForRate.actions.searchingDoctorDetails(state)
    )
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'PractitionerRole:practitioner',
      }
      if (name) {
        searchParameters['practitioner.name'] = name
      }
      searchParameters.role = type ?? 'doctor,ayurveda,1255370008'
      searchParameters.organization = `Organization/${
        getCurrentUserUnitDetails().id
      }`

      const response: any = await fhirClient.doGetResource(
        '/PractitionerRole?&_include=PractitionerRole:service&_include:iterate=HealthcareService:healthcare-service-billing',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practitionerWithRoleList: PractitionerWithRate[] =
                getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
              state.doctorRoleList = practitionerWithRoleList
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(
                medicalProviderSearchSliceForRate.actions.searchResults(state)
              )
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              medicalProviderSearchSliceForRate.actions.noDataFoundForSearch(
                state
              )
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          medicalProviderSearchSliceForRate.actions.noDataFoundForSearch(state)
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        medicalProviderSearchSliceForRate.actions.errorWhileSearching(state)
      )
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        medicalProviderSearchSliceForRate.actions.errorWhileSearching(state)
      )
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default medicalProviderSearchSliceForRate.reducer
