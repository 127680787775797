/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faEdit,
  faFilePdf,
  faFileWord,
  faTrash,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputProps,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { compress, compressAccurately } from 'image-conversion'
import { getFileSizeInMB } from 'utils/fhirResourcesHelper'
import { NoteWarningDialog } from './WarningDialog'
import { AllPages } from '../common/fhirResources/allPagePdfFile'
import { PdfViewer } from '../common/fhirResources/pdfviewer'

export interface SelectedFile {
  name?: string
  path?: string | ArrayBuffer
  type?: 'image' | 'document'
  local?: boolean
  files?: File
}

export type WelloFilePickerProps = {
  id: string
  name: string
  fileTypeToPick: 'photo' | 'document' | 'any'
  displaySelectedPhoto?: 'square' | 'circle'
  prescription?: boolean
  onFileChanged: (changedFile: SelectedFile) => void
  preSelectedFile?: SelectedFile
  onDeleteClicked?: () => void
  labelName?: string
  labelIcon?: React.ReactNode
  labelOrientation?: 'row' | 'column'
  size?: 'small' | 'medium' | 'large' | 'big'
  inputProps?: Partial<Omit<InputProps, 'id'>>
  showSelectedFileName?: boolean
  allowUpdate?: boolean
  allowDelete?: boolean
  required?: boolean
  error?: boolean
  helperText?: string
  compress?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    big: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
)

export const WelloFilePicker: React.FC<WelloFilePickerProps> = (
  props: WelloFilePickerProps
) => {
  const imageAccepts = '.png, .jpg, .jpeg'
  const documentAccepts = '.png,.jpg,.jpeg,.pdf'
  const kPrimaryColor: string = useTheme().palette.primary.main
  const [selectedFilePath, setSelectedFilePath] = useState<SelectedFile>(
    props.preSelectedFile ?? {}
  )
  const [showTextForPic, setShowTextForPic] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const classes = useStyles()

  function isDocument(): boolean {
    let result = false
    if (selectedFilePath) {
      if (selectedFilePath.type) {
        return selectedFilePath.type === 'document'
      }
    }
    if (props.preSelectedFile && props.preSelectedFile.type === 'document') {
      result = true
    }
    if (props.fileTypeToPick === 'document') {
      result = true
    }
    return result
  }

  function getIconSize() {
    switch (props.size) {
      case 'small':
        return '1x'

      case 'medium':
        return '2x'

      case 'large':
        return '3x'
      case 'big':
        return '5x'

      default:
        return '2x'
    }
  }

  function getFontSize() {
    switch (props.size) {
      case 'small':
        return 'subtitle2'

      case 'medium':
        return 'h6'

      case 'large':
        return 'h5'
      case 'big':
        return 'h5'

      default:
        return 'subtitle1'
    }
  }

  function getAvatarClass() {
    switch (props.size) {
      case 'small':
        return classes.small

      case 'medium':
        return classes.medium

      case 'large':
        return classes.large

      case 'big':
        return classes.big

      default:
        return classes.large
    }
  }

  function getImageSize(): string {
    switch (props.size) {
      case 'small':
        return '75px'

      case 'medium':
        return '100px'

      case 'large':
        return '125px'

      case 'big':
        return '150px'

      default:
        return '100px'
    }
  }

  function getFilesTypes() {
    switch (props.fileTypeToPick) {
      case 'photo':
        return imageAccepts

      case 'document':
        return documentAccepts

      default:
        return documentAccepts
    }
  }
  const onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== undefined && e.target.value.length > 0) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        async () => {
          // convert image file to base64 string
          if (
            e.target !== null &&
            e.target.files !== null &&
            e.target.files[0] !== null
          ) {
            if (e.target.files[0] !== null) {
              if (e.target.files[0].type.includes('image') && props.compress) {
                const convFile = await compressAccurately(e.target.files[0], 50)
                const file: SelectedFile = {
                  path: await convFile.arrayBuffer(),
                  name: e.target.files[0].name,
                  local: true,
                  type: e.target.files[0].type.includes('image')
                    ? 'image'
                    : 'document',
                  files: e.target.files[0],
                }
                props.onFileChanged(file)
                setSelectedFilePath({ ...file })
              } else {
                const file: SelectedFile = {
                  path: reader.result as string | ArrayBuffer,
                  name: e.target.files[0].name,
                  local: true,
                  type: e.target.files[0].type.includes('image')
                    ? 'image'
                    : 'document',
                  files: e.target.files[0],
                }

                props.onFileChanged(file)
                setSelectedFilePath({ ...file })
              }
            }
          }
        },
        false
      )
      if (
        e.target !== null &&
        e.target.files !== null &&
        e.target.files[0] !== null
      )
        reader.readAsDataURL(e.target.files[0])
    }
  }

  function isValidFileAvailable(): boolean {
    if (selectedFilePath) {
      if (selectedFilePath.path) {
        if (selectedFilePath.path.toString().length > 0) {
          return true
        }
      }
    }
    return false
  }

  return (
    <div id={`${props.id}_div`}>
      <FormControl
        required={props.required}
        error={props.error}
        variant='outlined'
        style={{
          alignItems: 'center',
        }}
        id={`${props.id}_fc`}
      >
        <Input
          type='file'
          id={props.id}
          name={props.name}
          error={props.error}
          required={props.required}
          tabIndex={0}
          inputProps={{
            multiple: false,
            accept: getFilesTypes(),
          }}
          style={{
            display: 'none',
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              //   if (checked === false) {
              //     setChecked(true)
              //     setOpenConsent(true)
              //   } else {
              //     setChecked(false)
              //     setOpenConsent(false)
              //   }
            }
          }}
          onClick={(e) => {
            const data = e.target as HTMLInputElement
            data.value = ''
          }}
          onChange={onChanged}
          {...props.inputProps}
        />
        {!isValidFileAvailable() && (
          <Box display='flex' flexShrink={1} flexWrap='nowrap'>
            <label htmlFor={props.id} style={{ cursor: 'pointer' }}>
              <Box
                display='flex'
                flexDirection={props.labelOrientation}
                justifyContent='flex-start'
                alignItems='center'
                flexShrink={1}
                flexWrap='nowrap'
              >
                <Box maxHeight='100px' maxWidth='100px'>
                  {props.labelIcon !== undefined && props.labelIcon}
                  {props.labelIcon === undefined && (
                    <FontAwesomeIcon
                      size={getIconSize()}
                      icon={faUserCircle}
                      color={kPrimaryColor}
                      style={{
                        color: kPrimaryColor,
                      }}
                    />
                  )}
                </Box>
                <Box p={0.5}>
                  <Typography variant={getFontSize()} color='primary'>
                    {' '}
                    {props.labelName ?? 'Add Photo'}
                  </Typography>
                </Box>
              </Box>
            </label>
          </Box>
        )}
        {isValidFileAvailable() && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            flexShrink={1}
            flexWrap='nowrap'
            overflow='auto'
            maxWidth='100%'
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              flexShrink={1}
              flexWrap='nowrap'
            >
              <Box
                display='flex'
                flexDirection='row'
                flexGrow
                width='100%'
                justifyContent='space-between'
              >
                <Box>
                  <FontAwesomeIcon
                    id={`${props.id}_delete_icon`}
                    size='1x'
                    icon={faTrash}
                    style={{ cursor: 'pointer', color: kPrimaryColor }}
                    onClick={(e) => {
                      setSelectedFilePath({})
                      if (props.onDeleteClicked) {
                        props.onDeleteClicked()
                      }
                    }}
                    color={kPrimaryColor}
                    visibility={props.allowDelete ? 'visible' : 'hidden'}
                  />
                </Box>
                <Box>
                  <label htmlFor={props.id ?? ''} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon
                      id={`${props.id}_edit_icon`}
                      size='1x'
                      icon={faEdit}
                      style={{ cursor: 'pointer', color: kPrimaryColor }}
                      color={kPrimaryColor}
                      visibility={props.allowUpdate ? 'visible' : 'hidden'}
                    />
                  </label>
                </Box>
              </Box>
              <Box>
                {selectedFilePath.type === 'image' &&
                  props.displaySelectedPhoto === 'circle' && (
                    <Avatar
                      id={`${props.id}_selected_photo_circle`}
                      src={selectedFilePath.path as string}
                      className={getAvatarClass()}
                      variant='circle'
                      onClick={() => {
                        // setShowPreview(true)
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  )}
                {/* {selectedFilePath.type && selectedFilePath.type === 'document' && (
                  <Box
                    style={{
                      height: getImageSize(),
                      width: getImageSize(),
                      //   objectFit: 'cover',
                      maxHeight: props.prescription ? '20px' : '50px',
                      maxWidth: '150px',
                      cursor: 'pointer',
                    }}
                  >
                    <AllPages pdf={selectedFilePath.path as string} />
                  </Box>
                )} */}

                {selectedFilePath.type === 'image' &&
                  props.displaySelectedPhoto !== 'circle' && (
                    <img
                      id={`${props.id}_selected_photo_shapeless`}
                      src={selectedFilePath.path as string}
                      className={getAvatarClass()}
                      alt='selectedImage'
                      onClick={() => {
                        // setShowPreview(true)
                      }}
                      style={{
                        height: getImageSize(),
                        width: getImageSize(),
                        objectFit: 'contain',
                        maxHeight: props.prescription ? '150px' : '150px',
                        maxWidth: '150px',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                {selectedFilePath && selectedFilePath.type === 'document' && (
                  <div>
                    {selectedFilePath?.name?.toLowerCase().includes('pdf') && (
                      <FontAwesomeIcon
                        id={`${props.id}_selected_icon`}
                        icon={faFilePdf}
                        size={getIconSize()}
                        color={kPrimaryColor}
                        style={{
                          color: kPrimaryColor,
                        }}
                        onClick={() => {
                          setShowPreview(true)
                        }}
                      />
                    )}
                    {selectedFilePath?.name?.toLowerCase().includes('doc') && (
                      <FontAwesomeIcon
                        id={`${props.id}_selected_icon`}
                        icon={faFileWord}
                        size={getIconSize()}
                        color={kPrimaryColor}
                        style={{
                          color: kPrimaryColor,
                        }}
                      />
                    )}
                    {selectedFilePath?.name?.toLowerCase().includes('jpeg') && (
                      <img
                        id={`${props.id}_selected_document_shapeless`}
                        src={selectedFilePath.path as string}
                        className={getAvatarClass()}
                        alt='selectedImage'
                        style={{
                          height: getImageSize(),
                          width: getImageSize(),
                          objectFit: 'cover',
                          maxHeight: '150px',
                          maxWidth: '150px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          //   setShowPreview(true)
                        }}
                      />
                    )}
                  </div>
                )}
              </Box>
              {props.showSelectedFileName && selectedFilePath.name && (
                <Box py={1} maxWidth='100%'>
                  <Typography
                    variant='body1'
                    id={`${props.id}_selected_file_name`}
                    style={{
                      maxWidth: '100px',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {selectedFilePath.name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}

        {showPreview && (
          <PdfViewer
            data={
              selectedFilePath.path ? (selectedFilePath.path as string) : ''
            }
            showPreview={showPreview}
            onCloseClicked={() => {
              setShowPreview(false)
            }}
          />
        )}

        {/* <Dialog
        open={showPreview}
        onClose={() => {
          setShowPreview(false)
        }}
        scroll={'body'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogContent>
          {selectedFilePath && selectedFilePath.type == 'image' && (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableFileName: true,
                  disableHeader: true,
                  retainURLParams: false
                }
              }}
              documents={[
                {
                  uri: selectedFilePath
                    ? (selectedFilePath.path as string) ?? ''
                    : ''
                }
              ]}
            />
          )}
        </DialogContent>
      </Dialog> */}
        <NoteWarningDialog
          open={showTextForPic}
          onClose={() => {
            setShowTextForPic(false)
          }}
          notesData='Maximum file size should be greater than 5 MB'
        />
        <FormHelperText
          id={`${props.id}_helper_text`}
          error={!!props.error}
          variant='outlined'
        >
          {props.helperText ?? ''}
        </FormHelperText>
      </FormControl>
    </div>
  )
}
