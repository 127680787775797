import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedOccupation } from 'models/groupedOccupations'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { OccupationsHistoryStatus } from './occupationSearchStatus'

const initialState: OccupationsHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const occupationSearchSlice = createSlice({
  name: 'occupationSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<OccupationsHistoryStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.noResultsAvailableOccupation =
        action.payload.noResultsAvailableOccupation
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.occupationalList = action.payload.occupationalList
      state.groupedOccupationList = action.payload.groupedOccupationList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const requestOccupationHistoryOfPatient =
  (
    appointmentId: string,
    selectedPatient: R4.IPatient,

    type?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OccupationsHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(occupationSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        category: 'social-history,survey',
        _count: 500,
        status: 'final,preliminary',
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }
      let response: any
      if (type) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            '/Observation?code=21843-8&verification-status=confirmed,unconfirmed',
            appointmentId,
            searchParameters
          )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformIPD(
            '/Observation?code=21843-8',
            appointmentId,
            searchParameters
          )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const proceduresResponse: R4.IBundle = response

      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const observations: R4.IObservation[] =
          proceduresResponse.entry?.map(
            (item) => item.resource as R4.IObservation
          ) ?? []
        const finalOccupationList: R4.IObservation[] = []
        for (let i = 0; i < observations.length; i++) {
          const codeData = observations[i].code
            ? observations[i].code.coding![0].code ?? ''
            : ''

          finalOccupationList.push(observations[i])
        }

        if (finalOccupationList.length > 0) {
          finalOccupationList.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          const groupedOccupationData: GroupedOccupation[] = []

          for (let i = 0; i < finalOccupationList.length; i++) {
            if (finalOccupationList[i].issued) {
              if (groupedOccupationData.length > 0) {
                for (let j = 0; j < groupedOccupationData.length; j++) {
                  if (
                    moment(finalOccupationList[i].meta!.lastUpdated!).isSame(
                      groupedOccupationData[j].date
                    )
                  ) {
                    groupedOccupationData[j].occupation.push(
                      finalOccupationList[i]
                    )
                  } else {
                    groupedOccupationData.push({
                      date: finalOccupationList[i].meta!.lastUpdated!,
                      occupation: [finalOccupationList[i]],
                    })
                    break
                  }
                }
              } else {
                groupedOccupationData.push({
                  date: finalOccupationList[i].meta!.lastUpdated!,
                  occupation: [finalOccupationList[i]],
                })
              }
            }
          }

          //   groupedOccupationData = groupedOccupationData.filter(
          //     (value, index, self) =>
          //       index === self.findIndex((t) => moment(t.date).isSame(value.date))
          //   )

          const finalGroupedOccupationData: GroupedOccupation[] = []
          //   for (let i = 0; i < groupedOccupationData.length; i++) {
          //     finalGroupedOccupationData.push({
          //       date: groupedOccupationData[i].date,
          //       occupation: groupedOccupationData[i].occupation.filter(
          //         (value, index, self) =>
          //           index ===
          //           self.findIndex(
          //             (t) =>
          //               getDefaultCodeOfSystemFromCodableConcept(
          //                 t.valueCodeableConcept
          //               ) ===
          //               getDefaultCodeOfSystemFromCodableConcept(
          //                 value.valueCodeableConcept
          //               )
          //           )
          //       ),
          //     })
          //   }

          state.resultsAvailable = true
          state.searchingConditions = false
          state.occupationalList = finalOccupationList
          state.groupedOccupationList = groupedOccupationData
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingProcedures = false
          dispatch(occupationSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OccupationsHistoryStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            occupationSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } else {
        const errorSearchDoctor: OccupationsHistoryStatus = {
          searchingConditions: false,
          errorWhileSearchingProcedures: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(occupationSearchSlice.actions.updatedStatus(errorSearchDoctor))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OccupationsHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(occupationSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default occupationSearchSlice.reducer
