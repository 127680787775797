import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add, Info } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { doctorMainBackColor } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloAddiction } from 'models/WelloConditions'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { hasAddedObsBySameDocForColor } from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getDateWiseVitals,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getAddButoonShow,
  getAddedinSameAppointment,
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { AddAddiction } from 'views/components/ipdconusltation/addition/addAddiction'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { EditAddictions } from '../edition/editAddictions'
import { EditOccupationOPD } from '../edition/editOccupationOPD'
import { AddAddictionOPD } from './addAddictionsOPD'
import { AddictionTile } from './addictionTile'

interface AddictionsProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  splitView: boolean
}

export const Addictions: React.FC<AddictionsProps> = ({
  fhirAppointmentDetails,
  splitView,
}: AddictionsProps) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const dispatch = useDispatch()
  const addictionsHistorySlice = useSelector(
    (state: RootState) => state.addictionsHistorySlice
  )

  const [currentCondition, setCurrentCondition] = useState<WelloAddiction>({
    id: _.random(1, 10000000).toString(),
  })

  function getData(obsList: R4.IObservation[]) {
    const firstColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '68518-0'
        )
    )
    const secColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '96103-7'
        )
    )
    const thirdColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '10000-3'
        )
    )

    const fourthColumn: R4.IObservation[] = obsList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t1) =>
            getDefaultCodeOfSystemFromCodableConcept(t1.code) === '64218-1'
        )
    )
    setCurrentCondition({
      ...currentCondition,
      existingObs: obsList,
      alcoholCondition:
        firstColumn.length > 0
          ? firstColumn[0].valueCodeableConcept
            ? firstColumn[0].valueCodeableConcept.coding &&
              firstColumn[0].valueCodeableConcept.coding.length > 0
              ? firstColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      tobacoCondition:
        secColumn.length > 0
          ? secColumn[0].valueCodeableConcept
            ? secColumn[0].valueCodeableConcept.coding &&
              secColumn[0].valueCodeableConcept.coding.length > 0
              ? secColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      drugs:
        thirdColumn.length > 0
          ? thirdColumn[0].valueCodeableConcept
            ? thirdColumn[0].valueCodeableConcept.coding &&
              thirdColumn[0].valueCodeableConcept.coding.length > 0
              ? thirdColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
      smoking:
        fourthColumn.length > 0
          ? fourthColumn[0].valueCodeableConcept
            ? fourthColumn[0].valueCodeableConcept.coding &&
              fourthColumn[0].valueCodeableConcept.coding.length > 0
              ? fourthColumn[0].valueCodeableConcept.coding[0]
              : undefined
            : undefined
          : undefined,
    })
  }
  useEffect(() => {
    dispatch(
      requestAddictionsHistoryOfPatient(
        fhirAppointmentDetails.appointment.id!,
        fhirAppointmentDetails.patient
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              {t('labelCommon:addictions')}{' '}
            </Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>
      {getAddButoonShow(
        addictionsHistorySlice.groupedObs ?? [],
        fhirAppointmentDetails.appointment.id!
      )}
      {addictionsHistorySlice.noResultsAvailable && (
        <Box>
          {isMedicalServiceProvider() && !splitView && (
            <Box
              display='flex'
              flexGrow
              flexDirection='row'
              justifyContent='flex-start'
              paddingBottom={1}
            >
              <Tooltip title='' id='addictions_add'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  id='addictions_add_button'
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                  style={{ padding: 0 }}
                >
                  <Typography
                    variant='subtitle2'
                    color='primary'
                    id='addictions_add_button_link'
                    component={Link}
                    style={{ fontSize: 13 }}
                  >
                    {' '}
                    {t('labelCommon:addictions')}{' '}
                  </Typography>
                  <AddCircleIcon
                    style={{ height: '22px', padding: 0 }}
                    color='primary'
                  />{' '}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      {addictionsHistorySlice.resultsAvailable &&
        addictionsHistorySlice.groupedObs && (
          <Box>
            {isMedicalServiceProvider() &&
              !splitView &&
              getAddButoonShow(
                addictionsHistorySlice.groupedObs ?? [],
                fhirAppointmentDetails.appointment.id!
              ) === false && (
                <Box
                  display='flex'
                  flexGrow
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddingBottom={1}
                >
                  <Tooltip title='' id='addictions_add'>
                    <IconButton
                      aria-label='btn_ord_cancel'
                      color='primary'
                      id='addictions_add_button'
                      onClick={() => {
                        setOpenAddDialogue(true)
                      }}
                      style={{ padding: 0 }}
                    >
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        id='addictions_add_button_link'
                        component={Link}
                        style={{ fontSize: 13 }}
                      >
                        {' '}
                        {t('labelCommon:addictions')}{' '}
                      </Typography>
                      <AddCircleIcon
                        style={{ height: '22px', padding: 0 }}
                        color='primary'
                      />{' '}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )}
      {addictionsHistorySlice.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}
      {addictionsHistorySlice.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {isMedicalServiceProvider() ? '' : 'No data available'}{' '}
          </Typography>
        </Box>
      )}
      {addictionsHistorySlice.errorWhileSearchingProcedures && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            color='error'
            style={{
              fontWeight: 400,
            }}
          >
            {addictionsHistorySlice.errorReason ??
              'Error while fetching conditions'}
          </Typography>
        </Box>
      )}

      {addictionsHistorySlice.resultsAvailable &&
        addictionsHistorySlice.groupedObs && (
          <Box flexGrow width='100%' display='flex' flexDirection='column'>
            {addictionsHistorySlice.groupedObs.map(
              (val: any, index: number) => (
                <Box
                  py={1}
                  key={val.date ?? ''}
                  id={`addictionsGrouped${index}`}
                >
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: hasAddedObsBySameDocForColor(
                        val.occupation
                      )
                        ? 'lightGrey'
                        : doctorMainBackColor,
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                      </Typography>
                    </Box>
                    {hasAddedObsBySameDocForColor(val.occupation) === false && (
                      <Box
                        display='flex'
                        justifyContent='flex-end'
                        paddingLeft={1}
                        paddingTop={1}
                        paddingRight={2}
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Patient Reported
                        </Typography>
                      </Box>
                    )}
                    {!splitView &&
                      getAddedinSameAppointment(
                        val.occupation,
                        fhirAppointmentDetails.appointment.id!
                      ) === true && (
                        <Box px={1} justifyContent='flex-end' paddingTop={1.5}>
                          <Tooltip title='Edit' id='chief_tool_edit'>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              id='addiction_tool_edit_button'
                              style={{ padding: 0 }}
                              onClick={() => {
                                getData(val.occupation)
                                setShowEdit(true)
                              }}
                            >
                              <img
                                id='chief_tool_edit_img'
                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                alt='Edit'
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                  </Box>
                  <AddictionTile
                    topObs={val.occupation}
                    id={`habitGrouped${index}`}
                  />
                </Box>
              )
            )}
          </Box>
        )}

      {/* {addictionsHistorySlice.resultsAvailable &&
        addictionsHistorySlice.addictionsList && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {addictionsHistorySlice.addictionsList.map((val) => (
                <HistoryObjectiveIndicativeComp
                  key={`habit_${getObservationCodeText(val)}`}
                  description={getObservationContent(val)}
                  timeData={`Recorded on ${moment(
                    val.issued ?? new Date()
                  ).format('DD-MM-YYYY')}`}
                  title={getObservationCodeText(val)}
                  showIndicativeBars={false}
                  type='other'
                  showAlarmingPrefixIcon={false}
                />
              ))}
            </Box>
          </Box>
        )} */}
      <AddAddictionOPD
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onAddictionAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestAddictionsHistoryOfPatient(
              fhirAppointmentDetails.appointment.id!,
              fhirAppointmentDetails.patient
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />

      <EditAddictions
        open={showEdit}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onAddictionAdded={() => {
          setShowEdit(false)
          dispatch(
            requestAddictionsHistoryOfPatient(
              fhirAppointmentDetails.appointment.id!,
              fhirAppointmentDetails.patient
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingAddictions={currentCondition}
      />
    </Box>
  )
}
