import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { addAndGetEncounterIdOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  getCommunicationResourceForFollowUp,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { sleep } from 'utils/dateUtil'
import { updateAppointmentDetails } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import { CarePlanFollowUpStatus } from './carePlanFollowUpAdditionState'

const initialState: CarePlanFollowUpStatus = {
  addingInstruction: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const carePlanFollowUpAdditionSlice = createSlice({
  name: 'carePlanFollowUpAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<CarePlanFollowUpStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
    resetCareTeamFollowUp(
      state,
      action: PayloadAction<CarePlanFollowUpStatus>
    ) {
      state.addingInstruction = false
      state.additionSuccessful = false
      state.errorWhileAdding = false
      state.errorReason = undefined
      state.successMessage = undefined
    },
  },
})

export const resetAddCareTeamFollowUpCPGState =
  () => (dispatch: AppDispatch) => {
    const resState: CarePlanFollowUpStatus = {
      addingInstruction: false,
      errorWhileAdding: false,
      additionSuccessful: false,
      noResultsAvailable: false,
      errorReason: '',
    }
    dispatch(
      carePlanFollowUpAdditionSlice.actions.resetCareTeamFollowUp(resState)
    )
  }

export const addCarePlanFollowups =
  (
    patient: R4.IPatient,
    followUp: R4.ICarePlan[],
    encounterId: string,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CarePlanFollowUpStatus = {
      ...initialState,
    }
    state.addingInstruction = true
    dispatch(carePlanFollowUpAdditionSlice.actions.updatedStatus(state))
    try {
      const hasPlanDef = true
      if (hasPlanDef) {
        const carePlanOfPatient: R4.ICarePlan | undefined =
          carePlanId !== undefined
            ? await getCarePlanByIdCurrentState(carePlanId)
            : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
        if (carePlanOfPatient && encounterId) {
          const responses: AxiosResponse[] | undefined =
            await addInstructionsApiCalls(
              followUp.map((e) =>
                getCommunicationResourceForFollowUp(
                  patient,
                  e.description ?? '',
                  encounterId
                )
              ),
              patient,
              carePlanOfPatient!.id!,
              encounterId!
            )
          if (responses) {
            let isSucess: boolean = true
            responses.forEach((e) => {
              if (e.status !== 200 && e.status !== 201) {
                isSucess = false
              }
            })
            await sleep(5000)
            if (isSucess) {
              state.addingInstruction = false
              state.additionSuccessful = true
              state.errorWhileAdding = false
              state.successMessage =
                'Care plan recommendations have been updated in care portal.'
              state.errorReason = undefined
              dispatch(
                carePlanFollowUpAdditionSlice.actions.updatedStatus(state)
              )
              dispatch(showSuccessAlert(state.successMessage!))
              /* setTimeout(() => {
                dispatch(getInstructionsOfAppointment(appointment))
              }, 500) */
            } else {
              let isPartial: boolean = false
              responses.forEach((e) => {
                if (e.status === 200 || e.status === 201) {
                  isPartial = true
                }
              })

              if (isPartial) {
                state.addingInstruction = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason =
                  'Added few of the care plan recommendations. Check Plan sections'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(
                  carePlanFollowUpAdditionSlice.actions.updatedStatus(state)
                )
                /*  setTimeout(() => {
                  dispatch(getInstructionsOfAppointment(appointment))
                }, 500) */
              } else {
                state.addingInstruction = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason =
                  'Error while adding care plan recommendations. Try later'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(
                  carePlanFollowUpAdditionSlice.actions.updatedStatus(state)
                )
              }
            }
          }
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'User is not subscribed to Care Plan'
          dispatch(showErrorAlert(state.errorReason!))
          dispatch(carePlanFollowUpAdditionSlice.actions.updatedStatus(state))
        }
      } else {
        const bundle: R4.IBundle = {
          resourceType: 'Bundle',
          type: R4.BundleTypeKind._transaction,
          entry: followUp.map(
            (e) =>
              ({
                request: {
                  method: R4.Bundle_RequestMethodKind._post,
                  url: 'CommunicationRequest',
                },
                resource: getCommunicationResourceForFollowUp(
                  patient,
                  e.description ?? '',
                  encounterId
                ),
              } as R4.IBundle_Entry)
          ),
        }

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundle)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.addingInstruction = false
          state.additionSuccessful = true
          state.errorWhileAdding = false
          state.successMessage =
            'Care plan recommendations have been updated in care portal.'
          state.errorReason = undefined
          dispatch(
            carePlanFollowUpAdditionSlice.actions.updatedStatus(initialState)
          )
          dispatch(showSuccessAlert(state.successMessage!))
          /*  setTimeout(() => {
            dispatch(getInstructionsOfAppointment(appointment))
          }, 500) */
        }
      }
    } catch (error) {
      const resState: CarePlanFollowUpStatus = {
        addingInstruction: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(showErrorAlert(state.errorReason!))
      dispatch(carePlanFollowUpAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const addInstructionsApiCalls = async (
  communicationRequests: R4.ICommunicationRequest[],
  patient: R4.IPatient,

  careplanId: string,
  encounterId: string
): Promise<AxiosResponse[] | undefined> => {
  const visitContext = sessionStorage.getItem(CURRENT_VISIT_REFERENCE)
  let appointmentId
  if (visitContext) {
    if (visitContext.toLowerCase().includes('appointment')) {
      appointmentId = visitContext.split('/')[1]
    }
  }
  try {
    const body: any = {
      appointmentId,
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId,
    }
    const responses: any[] = []
    const enRolClient: EnrolCient = new EnrolCient()

    communicationRequests.forEach(async (e) => {
      body.communicationRequest = e

      const resp: AxiosResponse =
        await enRolClient.doCreateEnrolmentFlowRequest('instruction/', body)

      responses.push(resp)
    })

    return responses
  } catch (error) {
    logger.info('_______error________________')
    logger.info(error)
  }
  return undefined
}

export default carePlanFollowUpAdditionSlice.reducer
