/* eslint-disable no-await-in-loop */
/* eslint-disable no-dupe-keys */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseSlots } from 'models/dateSeparatedSlots'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import { isToday } from 'utils/dateUtil'
import { getSpecializationsString } from 'utils/fhirResourcesHelper'
import {
  getDateWiseSlots,
  isAfterNoonSlot,
  isEvening,
  isMorningSlot,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getSlotsFromSlotsRelations } from 'utils/fhirResoureHelpers/fhirSlotHelper'
import { logger } from 'utils/logger'
import { PractitionerSlotSearchStatus } from './practitionerSlotSearchStatusTypes'

const initialState: PractitionerSlotSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  noSelection: true,
}

const practitionerSlotSearchSlice = createSlice({
  name: 'practitionerSlotSearch',
  initialState,
  reducers: {
    searchingAvailableSlots(
      state,
      action: PayloadAction<PractitionerSlotSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
      state.question = undefined
      state.recordsCount = undefined
      state.pageState = undefined
      state.offset = undefined
    },
    selectDoctor(state, action: PayloadAction<PractitionerSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = true
      state.question = undefined
      state.recordsCount = undefined
      state.pageState = undefined
      state.offset = undefined
    },

    searchResults(state, action: PayloadAction<PractitionerSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.slotList = action.payload.slotList
      state.noSelection = false
      state.question = action.payload.question
      state.recordsCount = action.payload.recordsCount
      state.pageState = action.payload.pageState
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PractitionerSlotSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
      state.recordsCount = 0
      state.pageState = undefined
      state.offset = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PractitionerSlotSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
      state.question = undefined
      state.recordsCount = 0
      state.pageState = undefined
      state.offset = undefined
    },

    resetState(state, action: PayloadAction<PractitionerSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = true
      state.question = undefined
      state.recordsCount = undefined
      state.pageState = undefined
      state.offset = undefined
    },
  },
})

export const resetSlotSelectionStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PractitionerSlotSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      noSelection: false,
      errorMessage: undefined,
      slotList: undefined,
      question: undefined,
    }
    dispatch(practitionerSlotSearchSlice.actions.resetState(state))
  }

export const requestForSlots =
  (
    selectedDoctors: PractitionerWithRole[],
    selectedDate?: Date,
    selectedTimeOfDay?: string,
    selectedAppointmentType?: string,
    selectedSpecialization?: R4.ICoding[],
    pageState?: string,
    offSet?: number,
    recordsCount?: number,
    existingDateWiseData?: DateWiseSlots[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (selectedDoctors?.length === 0 && selectedSpecialization?.length === 0) {
      const state: PractitionerSlotSearchStatus = {
        error: false,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        noSelection: true,
      }
      dispatch(practitionerSlotSearchSlice.actions.selectDoctor(state))
    } else {
      const state: PractitionerSlotSearchStatus = {
        error: false,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: true,
        noSelection: false,
      }
      dispatch(
        practitionerSlotSearchSlice.actions.searchingAvailableSlots(state)
      )
      try {
        let dateData = selectedDate

        if (selectedDate) {
          if (
            moment().format('YYYY-MM-DD') ===
            moment(selectedDate).format('YYYY-MM-DD')
          ) {
            if (selectedTimeOfDay && selectedTimeOfDay === 'any') {
              dateData = moment().toDate()
            }
            const time = moment().format('HH:mm:ss')

            if (selectedTimeOfDay && selectedTimeOfDay === 'morning') {
              const currentHour = moment(selectedDate).format(
                'HH'
              ) as unknown as number

              if (currentHour >= 0 && currentHour < 12) {
                selectedDate?.setTime(new Date().getTime())
                dateData = selectedDate
              } else {
                const dateString = moment(selectedDate).format('YYYY-MM-DD')
                const finalDateString = `${dateString} 00:00:00`
                dateData = moment(finalDateString).toDate()
              }
            } else if (selectedTimeOfDay && selectedTimeOfDay === 'afternoon') {
              const dateString = moment(selectedDate).format('YYYY-MM-DD')
              const finalDateString = `${dateString} ${time}`
              dateData = moment(finalDateString).toDate()
            } else if (selectedTimeOfDay && selectedTimeOfDay === 'evening') {
              const currentHour = moment(selectedDate).format(
                'HH'
              ) as unknown as number
              if (currentHour >= 18 && currentHour < 24) {
                selectedDate?.setTime(new Date().getTime())
                dateData = selectedDate
              } else {
                const dateString = moment(selectedDate).format('YYYY-MM-DD')
                const finalDateString = `${dateString} ${time}`
                dateData = moment(finalDateString).toDate()
              }
            }
          } else if (selectedTimeOfDay && selectedTimeOfDay === 'morning') {
            const dateString = moment(selectedDate).format('YYYY-MM-DD')
            const finalDateString = `${dateString} 00:00:00`
            dateData = moment(finalDateString).toDate()
          } else if (selectedTimeOfDay && selectedTimeOfDay === 'afternoon') {
            const dateString = moment(selectedDate).format('YYYY-MM-DD')
            const finalDateString = `${dateString} 12:00:00`
            dateData = moment(finalDateString).toDate()
          } else if (selectedTimeOfDay && selectedTimeOfDay === 'evening') {
            const dateString = moment(selectedDate).format('YYYY-MM-DD')
            const finalDateString = `${dateString} 18:00:00`
            dateData = moment(finalDateString).toDate()
          }
        }
        const perPageCount: number = 20
        const fhirClient: FHIRApiClient = new FHIRApiClient()
        let searchParameters: any = {}
        if (dateData === undefined) {
          searchParameters = {
            '_include:iterate': 'Schedule:actor',
            _include: 'Slot:schedule',

            status: 'free',
            _sort: 'start',
            _count: perPageCount,
            'service-type': `${selectedAppointmentType}`,
          }
        } else {
          searchParameters = {
            '_include:iterate': 'Schedule:actor',

            _include: 'Slot:schedule',
            start: `ge${moment.utc(dateData).local().format()}`,

            status: 'free',
            _sort: 'start',
            _count: 20,
            'service-type': `${selectedAppointmentType}`,
          }
        }

        if (selectedDoctors && selectedDoctors.length > 0) {
          let doctorsList: string = ''
          selectedDoctors.forEach((item) => {
            doctorsList = `${
              doctorsList + item.roleObject.practitioner?.reference
            },`
            if (item.roleObject.healthcareService)
              doctorsList = `${
                doctorsList + item.roleObject.healthcareService[0].reference
              },`
          })
          searchParameters['schedule:Schedule.active'] = true
          searchParameters['schedule:Schedule.actor'] = doctorsList
        }
        if (selectedSpecialization && selectedSpecialization.length > 0) {
          const specializationString: string = getSpecializationsString(
            selectedSpecialization
          )
          searchParameters._filter = `specialty in ${specializationString}`
        }
        let response: any

        if (pageState === undefined) {
          response = await fhirClient.doGetResource(
            '/Slot?_include:iterate=HealthcareService:healthcare-service-billing',
            searchParameters
          )
        } else if (pageState.length > 0) {
          searchParameters = {
            '_include:iterate': 'Schedule:actor',

            _include: 'Slot:schedule',
            start: `ge${moment.utc(dateData).local().format()}`,

            status: 'free',
            _sort: 'start',
            _total: 'accurate',
            'service-type': `${selectedAppointmentType}`,
          }
          response = await fhirClient.doGetResource(
            `?${pageState}`,
            searchParameters
          )
        }

        if (response !== undefined) {
          logger.info('---SLot Response---------')
          logger.info(response)
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            const slotsResponse: R4.IBundle = relatedFhirDecodeRes.right
            const link = slotsResponse.link ?? []
            let linkFinal: string = ''
            if (link.length > 0) {
              for (let i = 0; i < link.length; i++) {
                if (link[i].relation === 'next') {
                  const urlData = link[i].url
                  if (urlData) {
                    linkFinal = urlData.split('?')[1]
                  }
                }
              }
            }
            const categorizedSlotsWithChargeItem: FhirSlotDetail[] = []
            const categorizedSlots: FhirSlotDetail[] =
              getSlotsFromSlotsRelations(
                slotsResponse,
                selectedAppointmentType ?? ''
              )

            const question = await getQuestion(selectedDoctors[0])
            if (categorizedSlots.length > 0) {
              let filteredResp = categorizedSlots

              if (selectedTimeOfDay) {
                if (selectedTimeOfDay === 'morning') {
                  filteredResp = categorizedSlots.filter((item, index, arr) =>
                    isMorningSlot(item.slot.start ?? '')
                  )
                }
                if (selectedTimeOfDay === 'afternoon') {
                  filteredResp = categorizedSlots.filter((item, index, arr) =>
                    isAfterNoonSlot(item.slot.start ?? '')
                  )
                }
                if (selectedTimeOfDay === 'evening') {
                  filteredResp = categorizedSlots.filter((item, index, arr) =>
                    isEvening(item.slot.start ?? '')
                  )
                }
              }

              const dateWiseSlots: DateWiseSlots[] =
                getDateWiseSlots(filteredResp)

              const data = [...(existingDateWiseData ?? []), ...dateWiseSlots]

              state.resultsAvailable = true
              if (data.length > 0 || dateWiseSlots.length > 0) {
                state.selectedDate = selectedDate
                state.selectedTimeOfTheDay = selectedTimeOfDay
                state.slotList = data
                state.question = question
                state.pageState = linkFinal
                state.recordsCount = slotsResponse.total
                dispatch(
                  practitionerSlotSearchSlice.actions.searchResults(state)
                )
              } else {
                const errorSearchDoctor: PractitionerSlotSearchStatus = {
                  error: false,
                  noResultsAvailable: true,
                  resultsAvailable: false,
                  searching: false,
                }
                dispatch(
                  practitionerSlotSearchSlice.actions.noDataFoundForSearch(
                    errorSearchDoctor
                  )
                )
              }
            } else {
              const errorSearchDoctor: PractitionerSlotSearchStatus = {
                error: false,
                noResultsAvailable: true,
                resultsAvailable: false,
                searching: false,
              }
              dispatch(
                practitionerSlotSearchSlice.actions.noDataFoundForSearch(
                  errorSearchDoctor
                )
              )
            }
          } else {
            state.error = true
            state.searching = false
            state.resultsAvailable = false

            dispatch(
              practitionerSlotSearchSlice.actions.errorWhileSearching(state)
            )
          }
        } else {
          const stateData: PractitionerSlotSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: false,
            searching: false,
            slotList: existingDateWiseData ?? [],
          }
          dispatch(practitionerSlotSearchSlice.actions.searchResults(stateData))
        }
      } catch (error) {
        logger.error(error)
        const errorSearchDoctor: PractitionerSlotSearchStatus = {
          error: true,
          noResultsAvailable: false,
          resultsAvailable: false,
          searching: false,
          errorMessage: 'Error',
        }
        dispatch(
          practitionerSlotSearchSlice.actions.errorWhileSearching(
            errorSearchDoctor
          )
        )
      }
    }
  }

async function getQuestion(
  selectedDoctors: PractitionerWithRole
): Promise<R4.IQuestionnaire | undefined> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `Questionnaire?questionnaire-practitioner=${selectedDoctors.roleObject.id!}`
  )
  logger.info('ChargeItemDefinition Fetching')
  logger.info(response)
  const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
  if (response) {
    if (response.entry && response.entry.length > 0) {
      return response.entry[0].resource as R4.IQuestionnaire
    }
  }
  return undefined
}

export default practitionerSlotSearchSlice.reducer
