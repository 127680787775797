import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
  PatientGenderKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import extension from 'material-ui/svg-icons/action/extension'
import { PatientProfile } from 'models/patientProfile'
import moment from 'moment'
import {
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getIdentifierValueBySystemType,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemTypeDAata,
  getIdentifierValueBySystemTypeDAataVal,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionData,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { PatientDataGrid } from 'wello-web-components'
import { EditPatientStatus } from './editPatientStatus'

const initialState: EditPatientStatus = {
  updating: false,
  updatedSuccessfully: false,
  error: false,
  errorMessage: '',
  patient: undefined,
}

const editPatientSlice = createSlice({
  name: 'editPatientSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<EditPatientStatus>) {
      state.updating = action.payload.updating
      state.updatedSuccessfully = action.payload.updatedSuccessfully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const updatePatientData =
  (
    oldPatient: R4.IPatient,
    newPatientDetails: PatientProfile,
    oldFatherData?: R4.IRelatedPerson,
    oldHusbandData?: R4.IRelatedPerson
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: EditPatientStatus = {
      updating: true,
      updatedSuccessfully: false,
      error: false,
    }
    dispatch(editPatientSlice.actions.updateStatus(addingCreatePersonState))

    try {
      const transObj: R4.IBundle = getUpdatedBundleObjet(
        oldPatient,
        newPatientDetails,
        oldFatherData,
        oldHusbandData
      )

      logger.info('patientDetails')

      logger.info(oldPatient)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '',
        transObj
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Patient un decoded', respDecoded.right)

        try {
          if (
            newPatientDetails.bothFile &&
            newPatientDetails.frontFile === undefined &&
            newPatientDetails.backFile === undefined
          ) {
            let frontFileAttachment: R4.IAttachment | undefined = {}
            frontFileAttachment = {
              data: newPatientDetails.bothFile.path as string,
              contentType: newPatientDetails.bothFile.files
                ? newPatientDetails.bothFile.files.type
                : newPatientDetails.bothFile.type ?? '',
              title: newPatientDetails.bothFile.name,
            }
            if (frontFileAttachment) {
              await uploadFileDetails(
                frontFileAttachment,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)",
                'Patient',
                oldPatient.id ?? ''
              )
            }
          }

          if (newPatientDetails.frontFile && newPatientDetails.backFile) {
            let frontFileAttachment: R4.IAttachment | undefined = {}
            frontFileAttachment = {
              data: newPatientDetails.frontFile.path as string,
              contentType: newPatientDetails.frontFile.files
                ? newPatientDetails.frontFile.files.type
                : newPatientDetails.frontFile.type ?? '',
              title: newPatientDetails.frontFile.name,
            }
            if (frontFileAttachment) {
              await uploadFileDetails(
                frontFileAttachment,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)",
                'Patient',
                oldPatient.id ?? ''
              )
            }
          }

          if (
            newPatientDetails.backFile &&
            newPatientDetails.frontFile === undefined &&
            newPatientDetails.bothFile === undefined
          ) {
            let backFileAttachment: R4.IAttachment | undefined = {}
            backFileAttachment = {
              data: newPatientDetails.backFile.path as string,
              contentType: newPatientDetails.backFile.files
                ? newPatientDetails.backFile.files.type
                : newPatientDetails.backFile.type ?? '',
              title: newPatientDetails.backFile.name,
            }
            if (backFileAttachment) {
              await uploadFileDetails(
                backFileAttachment,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back').value.as(Attachment)",
                'Patient',
                oldPatient.id ?? ''
              )
            }
          }

          if (
            newPatientDetails.frontFile &&
            newPatientDetails.backFile === undefined &&
            newPatientDetails.bothFile === undefined
          ) {
            let backFileAttachmentNew: R4.IAttachment | undefined = {}
            backFileAttachmentNew = {
              data: newPatientDetails.frontFile.path as string,
              contentType: newPatientDetails.frontFile.files
                ? newPatientDetails.frontFile.files.type
                : newPatientDetails.frontFile.type ?? '',
              title: newPatientDetails.frontFile.name,
            }
            if (backFileAttachmentNew) {
              await uploadFileDetails(
                backFileAttachmentNew,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)",
                'Patient',
                oldPatient.id ?? ''
              )
            }
          }

          //   if (backFile && userDetails) {
          //     let backFileAttachment: R4.IAttachment | undefined = {}
          //     backFileAttachment = {
          //       data: backFile.path as string,
          //       contentType: backFile.files
          //         ? backFile.files.type
          //         : backFile.type ?? '',
          //       title: backFile.name,
          //     }
          //     if (backFileAttachment) {
          //       await uploadFileDetails(
          //         backFileAttachment,
          //         "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)",
          //         'Practitioner',
          //         userDetails.id ?? ''
          //       )
          //     }
          //   }
        } catch (error) {
          logger.info(error)
          //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
        }

        dispatch(showSuccessAlert('Patient details updated successfully'))
        const successCreatePersonState: EditPatientStatus = {
          updating: false,
          updatedSuccessfully: true,
          error: false,
          errorMessage: '',
        }
        dispatch(
          editPatientSlice.actions.updateStatus(successCreatePersonState)
        )
        return
      }
      logger.info('Error while updating patient')

      logger.info(respDecoded.left)

      const errorCreatePersonState: EditPatientStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while updating patient',
      }
      dispatch(editPatientSlice.actions.updateStatus(errorCreatePersonState))

      dispatch(
        showWarningAlert('Error while adding new address. Please try later')
      )
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: EditPatientStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(editPatientSlice.actions.updateStatus(errorCreatePersonState))
    }
  }

function getUpdatedBundleObjet(
  patientDetails: R4.IPatient,
  patientProfile: PatientProfile,
  oldFatherData?: R4.IRelatedPerson,
  oldHusbandData?: R4.IRelatedPerson
): R4.IBundle {
  const oldPatient: R4.IPatient = {
    ...patientDetails,
  }
  const pat: R4.IPatient = {
    resourceType: 'Patient',
  }

  const profileTag: R4.ICoding = {
    system:
      'http://wellopathy.com/fhir/india/core/vs/profileCompletionIndicators',
    code: 'profile_details_completed',
  }
  const meta: R4.IMeta = {
    tag: [profileTag],
  }
  pat.meta = meta

  pat.extension = []
  if (patientProfile.nationality.length >= 0) {
    const nationalityExt: R4.IExtension = {}
    nationalityExt.url =
      'http://hl7.org/fhir/StructureDefinition/patient-nationality'
    nationalityExt.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/CodeSystem/country',
        valueCodeableConcept: {
          text:
            patientProfile.nationality === 'india' ||
            (patientProfile.nationalityCoding.code &&
              patientProfile.nationalityCoding.code === 'IN')
              ? 'india'
              : patientProfile.nationalityCoding.display ?? '',
          coding: [patientProfile.nationalityCoding],
        },
      },
    ]
    pat.extension.push(nationalityExt)

    if (patientProfile.nationalityCoding.code !== 'IN') {
      if (patientProfile.passportStartDate && patientProfile.passportEndDate) {
        const passportExtStart: R4.IExtension = {}

        passportExtStart.url =
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
        passportExtStart.valuePeriod = {
          start: moment(patientProfile.passportStartDate).toISOString(),
          end: moment(patientProfile.passportEndDate).toISOString(),
        }

        pat.extension.push(passportExtStart)
      }
    }
    if (
      patientProfile.nationalityCoding.code === 'IN' &&
      patientProfile.addressProofTypeCoding.code === 'PPN'
    ) {
      if (patientProfile.passportStartDate && patientProfile.passportEndDate) {
        const passportExtStart: R4.IExtension = {}

        passportExtStart.url =
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
        passportExtStart.valuePeriod = {
          start: moment(patientProfile.passportStartDate).toISOString(),
          end: moment(patientProfile.passportEndDate).toISOString(),
        }

        pat.extension.push(passportExtStart)
      }
    }
  }

  if (
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding.code &&
    patientProfile.nationalityCoding.code !== 'IN'
  ) {
    if (patientProfile.visaNo.length > 0) {
      const visaNumberExtension: R4.IExtension = {}
      pat.extension =
        removeExtensionFromExtensions(
          pat.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
        ) ?? []

      visaNumberExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
      visaNumberExtension.valueString = `${patientProfile.visaNo}`
      pat.extension.push(visaNumberExtension)
    }

    if (patientProfile.visaTypeCoding) {
      const visaTypeExtension: R4.IExtension = {}
      pat.extension =
        removeExtensionFromExtensions(
          pat.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
        ) ?? []

      visaTypeExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
      visaTypeExtension.valueCodeableConcept = {
        coding: [patientProfile.visaTypeCoding],
      }
      pat.extension.push(visaTypeExtension)
    }

    const visaCountryExtension: R4.IExtension = {}
    pat.extension =
      removeExtensionFromExtensions(
        pat.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
      ) ?? []

    visaCountryExtension.url =
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
    visaCountryExtension.valueCodeableConcept = {
      coding: [patientProfile.nationalityCoding],
      text: patientProfile.visaIssueCity,
    }
    pat.extension.push(visaCountryExtension)

    if (patientProfile.visaExpiryDate && patientProfile.visaIssueDate) {
      const visaExpiryExtension: R4.IExtension = {}
      pat.extension =
        removeExtensionFromExtensions(
          pat.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
        ) ?? []

      visaExpiryExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
      visaExpiryExtension.valuePeriod = {
        start: moment(patientProfile.visaIssueDate).toISOString(),
        end: moment(patientProfile.visaExpiryDate).toISOString(),
      }

      pat.extension.push(visaExpiryExtension)
    }
  }

  if (
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding.code &&
    patientProfile.nationalityCoding.code === 'IN'
  ) {
    if (patientProfile.visaNo.length > 0) {
      pat.extension =
        removeExtensionFromExtensions(
          pat.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
        ) ?? []
    }

    if (patientProfile.visaTypeCoding) {
      pat.extension =
        removeExtensionFromExtensions(
          pat.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
        ) ?? []
    }

    pat.extension =
      removeExtensionFromExtensions(
        pat.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
      ) ?? []

    if (patientProfile.visaExpiryDate && patientProfile.visaIssueDate) {
      pat.extension =
        removeExtensionFromExtensions(
          pat.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
        ) ?? []
    }
  }
  if (patientProfile.occupation) {
    const occupationExt: R4.IExtension = {}
    occupationExt.url =
      'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
    occupationExt.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/CodeSystem/occupation',
        valueCodeableConcept: {
          text:
            patientProfile.occupation === 'other'
              ? patientProfile.occuptionOtherData
              : patientProfile.occupation,
        },
      },
    ]
    pat.extension.push(occupationExt)
  }

  const frontExt: R4.IExtension | undefined = {}

  if (
    patientProfile.bothFile &&
    patientProfile.backFile === undefined &&
    patientProfile.frontFile === undefined
  ) {
    pat.extension =
      removeExtensionFromExtensions(
        pat.extension ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
      ) ?? []
    frontExt.url =
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
    frontExt.valueAttachment = {
      contentType: patientProfile.bothFile.files
        ? patientProfile.bothFile.files.type
        : patientProfile.bothFile.type ?? '',
      title: patientProfile.bothFile.name ?? '',
    }
    pat.extension.push(frontExt)
  }

  //   if (patientProfile.frontFile && patientProfile.backFile) {
  //     pat.extension =
  //       removeExtensionFromExtensions(
  //         pat.extension ?? [],
  //         'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
  //       ) ?? []
  //     frontExt.url =
  //       'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
  //     frontExt.valueAttachment = {
  //       contentType: patientProfile.frontFile.files
  //         ? patientProfile.frontFile.files.type
  //         : patientProfile.frontFile.type ?? '',
  //       title: patientProfile.frontFile.name ?? '',
  //     }
  //     pat.extension.push(frontExt)
  //   }

  const unitOrg = getCurrentUserUnitDetails()
  const unitExtension: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit',
    valueReference: {
      id: unitOrg.id,
      reference: `Organization/${unitOrg.id}`,
      display: unitOrg.name,
    },
  }

  pat.extension.push(unitExtension)
  const backExt: R4.IExtension | undefined = {}

  if (patientProfile.backFile) {
    pat.extension =
      removeExtensionFromExtensions(
        pat.extension ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
      ) ?? []
    backExt.url =
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
    backExt.valueAttachment = {
      contentType: patientProfile.backFile.files
        ? patientProfile.backFile.files.type
        : patientProfile.backFile.type ?? '',
      title: patientProfile.backFile.name ?? '',
    }
    pat.extension.push(backExt)
  }

  if (patientProfile.frontFile) {
    pat.extension =
      removeExtensionFromExtensions(
        pat.extension ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
      ) ?? []
    backExt.url =
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
    backExt.valueAttachment = {
      contentType: patientProfile.frontFile.files
        ? patientProfile.frontFile.files.type
        : patientProfile.frontFile.type ?? '',
      title: patientProfile.frontFile.name ?? '',
    }
    pat.extension.push(backExt)
  }

  pat.id = oldPatient.id

  let vData: string = ''

  const versionData = sessionStorage.getItem('version')

  if (versionData !== null) vData = JSON.stringify(versionData)

  const patMatchString: string =
    versionData === null
      ? `W/${JSON.stringify(oldPatient?.meta?.versionId ?? ' ')}`
      : `W/${vData}`

  if (
    getIdentifierValueBySystemType(
      oldPatient.identifier ?? [],
      'address-proof'
    )!.length > 0 &&
    getValueAttachmentFromExtension(
      oldPatient.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
    ) === '0'
  ) {
    const addressProofFront: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        oldPatient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
      )

    const addressProofBack: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        oldPatient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
      )

    const addressProofFrontExt: R4.IExtension = {}
    if (
      addressProofFront !== undefined &&
      patientProfile.frontFile === undefined
    ) {
      addressProofFrontExt.url =
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
      addressProofFrontExt.valueAttachment = {
        contentType: addressProofFront.contentType,
        title: addressProofFront.title,
        _data: {
          extension: [
            {
              url: 'http://hapifhir.io/fhir/StructureDefinition/externalized-binary-id',
              valueString: addressProofFront._data
                ? addressProofFront._data.extension
                  ? addressProofFront._data.extension[0].valueString ?? ''
                  : ''
                : '',
            },
          ],
        },
        size: addressProofFront.size,
      }
      pat.extension.push(addressProofFrontExt)
    }

    if (
      addressProofBack !== undefined &&
      patientProfile.backFile === undefined
    ) {
      const addressProofBackExt: R4.IExtension = {}
      addressProofBackExt.url =
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
      addressProofBackExt.valueAttachment = {
        contentType: addressProofBack.contentType,
        title: addressProofBack.title,
        _data: {
          extension: [
            {
              url: 'http://hapifhir.io/fhir/StructureDefinition/externalized-binary-id',
              valueString: addressProofBack._data
                ? addressProofBack._data.extension
                  ? addressProofBack._data.extension[0].valueString ?? ''
                  : ''
                : '',
            },
          ],
        },
        size: addressProofBack.size,
      }
      pat.extension.push(addressProofBackExt)
    }
  }

  if (
    getIdentifierValueBySystemType(
      oldPatient.identifier ?? [],
      'address-proof'
    )!.length > 0 &&
    getValueAttachmentFromExtension(
      oldPatient.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
    ) === '1'
  ) {
    const addressProofBoth: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        oldPatient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
      )

    if (addressProofBoth && patientProfile.bothFile === undefined) {
      const addressProofBothExt: R4.IExtension = {}

      addressProofBothExt.url =
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
      addressProofBothExt.valueAttachment = {
        contentType: addressProofBoth.contentType,
        title: addressProofBoth.title,
        _data: {
          extension: [
            {
              url: 'http://hapifhir.io/fhir/StructureDefinition/externalized-binary-id',
              valueString: addressProofBoth._data
                ? addressProofBoth._data.extension
                  ? addressProofBoth._data.extension[0].valueString ?? ''
                  : ''
                : '',
            },
          ],
        },
        size: addressProofBoth.size,
      }
      pat.extension.push(addressProofBothExt)
    }
  }

  const identifier: R4.IIdentifier = {}
  identifier.use = IdentifierUseKind._usual
  identifier.system = 'mrn-tenant1'
  identifier.value = oldPatient.identifier ? oldPatient.identifier[0].value : ''
  if (pat.identifier == null) {
    pat.identifier = [identifier]
  } else {
    pat.identifier.push(identifier)
  }

  const latestIdentifier: string | undefined = getIdentifierValueBySystem(
    oldPatient.identifier ?? [],
    'http://wellopathy.com/fhir/india/core/id/offset-phone'
  )
  if (latestIdentifier !== undefined) {
    if (latestIdentifier.length > 0) {
      const finalIdentifier: R4.IIdentifier = {}

      finalIdentifier.value = latestIdentifier
      finalIdentifier.system =
        'http://wellopathy.com/fhir/india/core/id/offset-phone'
      pat.identifier.push(finalIdentifier)
    }
  }

  if (latestIdentifier === undefined || latestIdentifier.length === 0) {
    const phoneIdentifier: R4.IIdentifier = {}

    phoneIdentifier.value = `${patientProfile.phone}`
    phoneIdentifier.system = 'phone'
    pat.identifier.push(phoneIdentifier)
    if (patientProfile.email.length > 0) {
      const emailIdentifier: R4.IIdentifier = {}
      emailIdentifier.value = `${patientProfile.email}`
      emailIdentifier.system = 'email'
      pat.identifier.push(emailIdentifier)
    }
  }

  if (patientProfile.labReference.length > 0) {
    const labRefIdentifier: R4.IIdentifier = {}
    labRefIdentifier.value = `${patientProfile.labReference}`
    labRefIdentifier.system = 'labReference'
    pat.identifier.push(labRefIdentifier)
  }

  const addProofIdentifir: R4.ICodeableConcept | undefined =
    getIdentifierValueBySystemTypeDAata(
      oldPatient.identifier ?? [],
      'address-proof'
    )
  if (addProofIdentifir) {
    const addressIdentifier: R4.IIdentifier = {}
    addressIdentifier.type = {
      coding: [patientProfile.addressProofTypeCoding],
    }
    addressIdentifier.use = IdentifierUseKind._usual

    addressIdentifier.system = 'address-proof'
    addressIdentifier.value = patientProfile.addressProofNumber
    if (pat.identifier == null) {
      pat.identifier = [addressIdentifier]
    } else {
      pat.identifier.push(addressIdentifier)
    }
  } else {
    const addressIdentifier: R4.IIdentifier = {}
    addressIdentifier.type = {
      coding: [patientProfile.addressProofTypeCoding],
    }
    addressIdentifier.use = IdentifierUseKind._usual

    addressIdentifier.system = 'address-proof'
    addressIdentifier.value = patientProfile.addressProofNumber
    if (pat.identifier == null) {
      pat.identifier = [addressIdentifier]
    } else {
      pat.identifier.push(addressIdentifier)
    }
  }

  const humanName: R4.IHumanName = {}
  humanName.given = [patientProfile.name.trim()]
  if (patientProfile.middleName) {
    humanName.given.push(patientProfile.middleName.trim())
  }

  humanName.family = patientProfile.lastName.trim()
  humanName.use = HumanNameUseKind._official
  if (pat.name == null) {
    pat.name = [humanName]
  } else {
    pat.name.push(humanName)
  }

  const phoneContact: R4.IContactPoint = {}
  phoneContact.use = ContactPointUseKind._mobile
  phoneContact.system = R4.ContactPointSystemKind._phone
  phoneContact.rank = 1
  phoneContact.value = patientProfile.phone

  if (pat.telecom == null) {
    pat.telecom = [phoneContact]
  } else {
    pat.telecom.push(phoneContact)
  }

  const emailContact: R4.IContactPoint = {}
  emailContact.use = ContactPointUseKind._home
  emailContact.system = R4.ContactPointSystemKind._email
  emailContact.rank = 1
  emailContact.value = patientProfile.email

  if (pat.telecom == null) {
    pat.telecom = [emailContact]
  } else {
    pat.telecom.push(emailContact)
  }
  const dateRes: E.Either<Errors, string> = R4.RTTI_date.decode(
    patientProfile.dob
  )
  if (dateRes._tag === 'Right') {
    pat.birthDate = dateRes.right
  } else {
    throw Error('Invalid date format')
  }
  const photo: R4.IAttachment = {}
  if (patientProfile.imgName.length > 0) {
    photo.data = patientProfile.imgName
    photo.contentType = patientProfile.type
  } else {
    photo.data = oldPatient.photo ? oldPatient.photo[0].data : ''
    photo.contentType = oldPatient.photo ? oldPatient.photo[0].contentType : ''
  }

  if (pat.photo == null) {
    pat.photo = [photo]
  } else {
    pat.photo.push(photo)
  }

  if (
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding.code === 'IN'
  ) {
    if (patientProfile.address.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []
      const patAddress: R4.IAddress[] = []
      add.push(patientProfile.address)
      address.line = add
      if (patientProfile.city.length > 0) address.city = patientProfile.city
      if (patientProfile.state.length > 0) address.state = patientProfile.state
      address.country = 'India'

      if (patientProfile.addressType === 'home')
        address.use = oldPatient.address ? R4.AddressUseKind._home : undefined
      else if (patientProfile.addressType === 'work')
        address.use = oldPatient.address ? R4.AddressUseKind._work : undefined
      else if (patientProfile.addressType === 'temp')
        address.use = oldPatient.address ? R4.AddressUseKind._temp : undefined
      else if (patientProfile.addressType === 'billing')
        address.use = oldPatient.address
          ? R4.AddressUseKind._billing
          : undefined
      else if (patientProfile.addressType === 'oth')
        address.use = oldPatient.address ? R4.AddressUseKind._temp : undefined
      else if (patientProfile.addressType === 'old')
        address.use = oldPatient.address ? R4.AddressUseKind._old : undefined
      else
        address.use = oldPatient.address ? R4.AddressUseKind._temp : undefined
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      pat.address = patAddress
    } else if (patientProfile.city.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []
      const patAddress: R4.IAddress[] = []
      address.line = add
      if (patientProfile.city.length > 0) address.city = patientProfile.city
      if (patientProfile.state.length > 0) address.state = patientProfile.state
      address.country = 'India'
      if (patientProfile.addressType === 'home')
        address.use = oldPatient.address ? R4.AddressUseKind._home : undefined
      else
        address.use = oldPatient.address ? R4.AddressUseKind._work : undefined
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      pat.address = patAddress
    }
  }
  const patAddress: R4.IAddress[] = []
  if (
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding.code !== 'IN'
  ) {
    if (patientProfile.address.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []
      add.push(patientProfile.address)
      address.line = add
      if (patientProfile.city.length > 0) address.city = patientProfile.city
      if (patientProfile.country.length > 0)
        address.country = patientProfile.country
      if (patientProfile.addressType === 'home')
        address.use = oldPatient.address ? R4.AddressUseKind._home : undefined
      else if (patientProfile.addressType === 'work')
        address.use = oldPatient.address ? R4.AddressUseKind._work : undefined
      else if (patientProfile.addressType === 'temp')
        address.use = oldPatient.address ? R4.AddressUseKind._temp : undefined
      else if (patientProfile.addressType === 'billing')
        address.use = oldPatient.address
          ? R4.AddressUseKind._billing
          : undefined
      else if (patientProfile.addressType === 'oth')
        address.use = oldPatient.address ? R4.AddressUseKind._temp : undefined
      else if (patientProfile.addressType === 'old')
        address.use = oldPatient.address ? R4.AddressUseKind._old : undefined
      else
        address.use = oldPatient.address ? R4.AddressUseKind._temp : undefined
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      pat.address = patAddress
    }
    // else if (patientProfile.city.length > 0) {
    //   const address: R4.IAddress = {}
    //   const add: string[] = []
    //   const patAddress: R4.IAddress[] = []
    //   address.line = add
    //   if (patientProfile.city.length > 0) address.city = patientProfile.city
    //   if (patientProfile.country.length > 0)
    //     address.country = patientProfile.country
    //   if (patientProfile.addressType === 'home')
    //     address.use = oldPatient.address ? R4.AddressUseKind._home : undefined
    //   else
    //     address.use = oldPatient.address ? R4.AddressUseKind._work : undefined
    //   address.type = R4.AddressTypeKind._both
    //   patAddress.push(address)
    //   pat.address = patAddress
    // }
  }

  if (
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding &&
    patientProfile.nationalityCoding.code !== 'IN'
  ) {
    if (patientProfile.tempAddress.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []

      add.push(patientProfile.tempAddress)
      address.line = add
      address.use = R4.AddressUseKind._temp
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      if (pat.address) {
        pat.address = patAddress
      }
    }
  }

  pat.gender =
    patientProfile.gender === PatientGenderKind._male
      ? PatientGenderKind._male
      : patientProfile.gender === PatientGenderKind._female
      ? PatientGenderKind._female
      : patientProfile.gender === PatientGenderKind._other
      ? PatientGenderKind._other
      : PatientGenderKind._unknown
  pat.deceasedBoolean = false
  pat.active = true

  const maritalCodeableList: React.SetStateAction<R4.ICodeableConcept[]> = []
  const divorceConcept: R4.ICodeableConcept = {}
  const marriedConcept: R4.ICodeableConcept = {}
  const unMarriedConcept: R4.ICodeableConcept = {}
  const widowedConcept: R4.ICodeableConcept = {}
  const maritalStatus: R4.ICodeableConcept = {}

  const maritalCodingList: React.SetStateAction<R4.ICoding[]> = []
  const divorceCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'D',
    display: 'Divorced',
  }
  const marriedCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'M',
    display: 'Married',
  }
  const unMarriedCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'U',
    display: 'unmarried',
  }
  const widowCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'W',
    display: 'Widowed',
  }
  maritalCodingList.push(divorceCoding)
  divorceConcept.coding = maritalCodingList

  maritalCodingList.push(marriedCoding)
  marriedConcept.coding = maritalCodingList

  maritalCodingList.push(unMarriedCoding)
  unMarriedConcept.coding = maritalCodingList

  maritalCodingList.push(widowCoding)
  widowedConcept.coding = maritalCodingList

  maritalCodeableList.push(divorceConcept)
  maritalCodeableList.push(marriedConcept)
  maritalCodeableList.push(unMarriedConcept)
  maritalCodeableList.push(widowedConcept)

  for (let i = 0; i < maritalCodeableList.length; i++) {
    const codableList = maritalCodeableList[i].coding ?? []
    for (let j = 0; j < codableList.length; j++) {
      if (codableList[j].code === patientProfile.maritalStaTus) {
        maritalStatus.coding = [codableList[j]]
        pat.maritalStatus = maritalStatus
      }
    }
  }

  pat.managingOrganization = oldPatient.managingOrganization

  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Patient/${oldPatient.id}`,
        request: {
          ifMatch: patMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Patient/${oldPatient.id}`,
        },
        resource: pat,
      },
    ],
  }

  if (oldFatherData) {
    const fathersData: R4.IRelatedPerson = { ...oldFatherData }
    const fatherMatchString: string = `W/${JSON.stringify(
      oldFatherData?.meta?.versionId ?? ' '
    )}`
    const relatedPat: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: fathersData.patient.reference,
      },
    }
    relatedPat.identifier = fathersData.identifier

    const humanNameFather: R4.IHumanName = {}
    const nameData: string[] = []
    nameData.push(patientProfile.fathersName)
    humanNameFather.given = [patientProfile.fathersName]
    humanNameFather.use = HumanNameUseKind._official
    if (relatedPat.name == null) {
      relatedPat.name = [humanNameFather]
    } else {
      relatedPat.name.push(humanNameFather)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'FTH',
      display: 'father',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPat.relationship = relationShipCodeableList

    bundleObject.entry?.push({
      fullUrl: `RelatedPerson/${oldFatherData.id}`,
      request: {
        ifMatch: fatherMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `RelatedPerson/${oldFatherData.id}`,
      },
      resource: relatedPat,
    })
  }

  if (oldHusbandData) {
    const husbandData: R4.IRelatedPerson = { ...oldHusbandData }

    const husbandMatchString: string = `W/${JSON.stringify(
      oldHusbandData?.meta?.versionId ?? ' '
    )}`

    const relatedPatHus: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: oldHusbandData.patient.reference,
      },
    }
    relatedPatHus.identifier = oldHusbandData.identifier

    const humanNameHusband: R4.IHumanName = {}
    const nameData: string[] = []
    nameData.push(patientProfile.husbandName)
    humanNameHusband.given = [patientProfile.husbandName]
    humanNameHusband.use = HumanNameUseKind._official
    if (relatedPatHus.name == null) {
      relatedPatHus.name = [humanNameHusband]
    } else {
      relatedPatHus.name.push(humanNameHusband)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'HUSB',
      display: 'husband',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPatHus.relationship = relationShipCodeableList
    bundleObject.entry?.push({
      fullUrl: `RelatedPerson/${oldHusbandData.id}`,
      request: {
        ifMatch: husbandMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `RelatedPerson/${oldHusbandData.id}`,
      },
      resource: relatedPatHus,
    })
  }
  return bundleObject
}

export const resetUpdatePatientState = () => (dispatch: AppDispatch) => {
  dispatch(editPatientSlice.actions.updateStatus(initialState))
}

export default editPatientSlice.reducer
