import {
  //   unstable_createMuiStrictModeTheme as createTheme,
  ThemeOptions,
} from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { hover } from '@testing-library/user-event/dist/hover'

export const kPrimaryMain: string = '#4543d9'
export const textMain: string = '#007AFF'
export const doctorMainBackColor: string = '#DAD9FF'
export const kPrimaryLight: string = '#4643D93A'
export const kPrimaryDark: string = '#4543d9'
export const kContrastText: string = '#ffffff'
export const kBackgroundPaper: string = '#ffffff'
export const kBackgroundDefault: string = '#dcdcdc'
export const kDialogueBackground: string = '#ededed'
export const kSelectedTabBackground: string = '#3c3c3c'
export const kBackGround: string = '#ff3b30'
export const kPrimaryErrorMain: string = '#f44336'
export const settingsBackgroundColor: string = '#e7e7e7'

const kErrorMain: string = '#f44336'
const kErrorLight: string = '#e57373'
const kErrorDark: string = '#d32f2f'
const kErrorText: string = '#fff'

const kSecondaryMain: string = '#f50057'
const kSecondaryLight: string = '#ff4081'
const kSecondaryDark: string = '#c51162'
const kSecondaryText: string = '#fff'

export const kStatusMiddle: string = '#ffcc00'
export const kStatusAccepted: string = '#ff3b30'
export const kStatusRejected: string = '#4cd964'

const options = (dark: boolean): ThemeOptions => ({
  palette: {
    action: {
      selected: '#B2B2D1',
      hover: '#B3BCE7',
    },

    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: kBackgroundPaper,
      default: kBackgroundDefault,
    },
    primary: {
      light: kPrimaryLight,
      main: kPrimaryMain,
      dark: kPrimaryDark,
      contrastText: kContrastText,
    },
    secondary: {
      light: kSecondaryLight,
      main: kSecondaryMain,
      dark: kSecondaryDark,
      contrastText: kSecondaryText,
    },
    error: {
      light: kErrorLight,
      main: kErrorMain,
      dark: kErrorDark,
      contrastText: kErrorText,
    },
    text: {
      primary: '#4d4d4d',
      secondary: 'rgba(155, 155, 155, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },

  typography: {
    fontFamily: ['DM Sans'].join(','),
    allVariants: {
      fontFamily: ['DM Sans'].join(','),
    },
    h1: {
      fontFamily: 'DM Sans',
      fontWeight: 'lighter',
      fontSize: 96,
    },

    h2: {
      fontFamily: 'DM Sans',
      fontSize: 60,

      lineHeight: 'normal',
      fontWeight: 'bold',
    },

    h3: {
      fontFamily: 'DM Sans',
      fontSize: 48,

      fontWeight: 'bold',
    },

    h4: {
      fontFamily: 'DM Sans',
      fontSize: 34,

      lineHeight: 'normal',
      fontWeight: 'bold',
    },

    h5: {
      fontFamily: 'DM Sans',
      fontSize: 24,
      fontWeight: 'bold',

      lineHeight: 'normal',
    },
    h6: {
      fontFamily: 'DM Sans',
      fontSize: 16,
      fontWeight: 600,

      lineHeight: 'normal',
      color: '#333333',
    },
    subtitle1: {
      fontFamily: 'DM Sans',
      fontSize: 14,

      fontWeight: 600,
      lineHeight: 'normal',
    },
    subtitle2: {
      fontFamily: 'DM Sans',
      fontSize: 12,

      fontWeight: 600,
      lineHeight: 'normal',
    },
    body1: {
      fontFamily: 'DM Sans',
      fontSize: 14,

      color: '#4d4d4d',
      lineHeight: 'normal',
    },
    body2: {
      fontFamily: 'DM Sans',
      fontSize: 12,

      lineHeight: 'normal',
    },
    button: {
      fontFamily: 'DM Sans',
      fontSize: 14,
      lineHeight: 'normal',
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
    },
    caption: {
      fontFamily: 'DM Sans',
      fontSize: 12,

      lineHeight: 'normal',
    },
    overline: {
      fontFamily: 'DM Sans',
      fontSize: 10,
      fontWeight: 600,
      lineHeight: 'normal',
    },
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
      style: {
        backgroundColor: kPrimaryMain,
        color: 'white',
        alignContent: 'center',
        textAlign: 'center',
        height: 48,
        fontFamily: 'DM Sans',
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    MuiTab: {
      style: { minWidth: 0 },
    },
    MuiListItemIcon: {
      style: {
        minWidth: 0,
      },
    },

    MuiSelect: {
      MenuProps: {
        getContentAnchorEl: null,
        style: {
          maxHeight: '400px',
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    },
  },
  overrides: {
    MuiIcon: {},

    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          height: 15,
          width: 15,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: kPrimaryMain,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        borderRadius: 100,
        borderColor: '#F2F2F2',
        '&:hover': {
          borderColor: kPrimaryMain,
        },
      },
      input: {
        backgroundColor: 'white',
        borderColor: 'red',
        paddingTop: 8,
        paddingBottom: 8,
      },
      inputMarginDense: {
        paddingTop: 8,
        paddingBottom: 8,
      },

      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        padding: 0,
        paddingLeft: 0,
        paddingRight: 10,
      },
      notchedOutline: {
        borderColor: '#F2F2F2',
        borderRadius: 100,
      },

      focused: {
        borderColor: kPrimaryMain,
        borderRadius: 100,
      },
    },

    MuiButton: {
      root: {
        margin: '2px',
      },
      outlined: {
        color: kPrimaryMain,
        backgroundColor: 'white',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: kPrimaryMain,
        color: 'white',
        alignContent: 'center',
        textAlign: 'center',
        height: 48,
        fontFamily: 'DM Sans',
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: kDialogueBackground,
        padding: 0,
        overflow: 'hidden',
        margin: 0,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: kDialogueBackground,
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#ededed',
      },
    },

    MuiCssBaseline: {
      '@global': {
        root: {
          '.MuiAutocomplete-option': {
            '&$selected': {
              backgroundColor: kPrimaryMain,
              color: 'white',
              '&:hover': {
                backgroundColor: kPrimaryMain,
                color: 'white',
              },
            },
          },
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white',
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'yellow',
          },
        },

        /* ".MuiAutocomplete-option": {
              "&$selected": {
                backgroundColor: kPrimaryMain,
                color: "white",
                "&:hover": {
                  backgroundColor: kPrimaryMain,
                  color: "white",
                },
              },
            }, */
        '.MuiAlert-outlinedInfo': {
          backgroundColor: 'white',
        },
      },
    },
    MuiButtonGroup: {},
    MuiSelect: {
      root: {
        '&$selected': {
          backgroundColor: kPrimaryMain,
          color: 'white',
          '&:hover': {
            backgroundColor: kPrimaryMain,
            color: 'white',
          },
        },

        '&$paper': {
          top: 200,
        },
      },
    },

    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: kPrimaryMain,
          color: 'white',
          '&:hover': {
            backgroundColor: kPrimaryMain,
            color: 'white',
          },
        },
      },
    },
  },
})

export const darkTheme = createTheme(options(true))
export const lightTheme = createTheme(options(false))

export default darkTheme
