import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  List,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import { FhirClinicTherapiesDetails } from 'models/fhirClinicTherapiesDetails'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { requestIPDAppCountForToday } from 'redux/clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import { requestForDateWiseDietLists } from 'redux/clinic/ipdmanagement/nutritionOrderList/nutritionOrderListSlice'
import { requestOPDAppCountForTodayConsult } from 'redux/clinic/opdManagement/opdConsultManagement/opdConsultCount/opdConsultCountSlice'
import { requestOPDAppCountForToday } from 'redux/clinic/opdManagement/opdCountSlice'
import { RootState } from 'redux/rootReducer'
import { useWindowSize } from 'rooks'
import {
  getCurrentUserUnitDetails,
  isTherapist,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { DietListOfTheDate } from 'views/components/ipd/ipdAppointmentList/dietListOfTheDate'
import '../../../App.css'
import CustomizedDividers, {
  ToggleOption,
} from '../../components/toggle_selector'

export interface PageProps {
  type: 'opd' | 'ipd' | 'opd-daycare' | 'appointment'
}
const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const ClinicalDieticianHomePage: React.FC<PageProps> = ({
  type,
}: PageProps) => {
  const selectedDateQ = useSelector(
    (state: RootState) => state.nutritionOrderListSlice.selectedDate
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    selectedDateQ ?? new Date()
  )

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.nutritionOrderListSlice
  )

  const nutritionCatalogueValueSetSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )

  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    appointmentSearchSlice.selectedStatuses
  )

  const [selectedIpd, setSelectedIPd] = useState<FhirClinicTherapiesDetails>()

  const [viewType, setViewType] = useState<string | undefined>('list')

  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const handleViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | undefined
  ) => {
    setViewType(newView)
  }

  const menu: ToggleOption[] = [-1, 0, 1].map((e) => ({
    value: moment().add(e, 'days').startOf('day').format(),
    displayText: moment().add(e, 'days').calendar().split(' at')[0] ?? '',
  }))

  const navigateToPath = (path: string) => {
    // history.replace(path)
    window.location.href = path
  }

  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()

  function openAppointment(appointment: FhirClinicTherapiesDetails) {
    navigateToPath(
      `/${getVendorPartId()}/viewIpdAppointment?id=${
        appointment.mainServiceRequest.id
      }`
    )
  }

  useEffect(() => {
    dispatch(
      requestForDateWiseDietLists(
        type,
        selectedDate,
        selectedIpdStatuses,
        undefined,
        undefined,
        undefined
      )
    )
  }, [dispatch, selectedDate, selectedIpdStatuses])

  return (
    <div
      id={type}
      key={type}
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          {/*  <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    // onMonthChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          borderBottom: `1px solid ${kPrimaryDark}`,
                          bottom: '20%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>
                <IPDStatusSelection
                  onSelectionChanged={(changedTypes) => {
                    setSelectedIPDStatuses(changedTypes)
                  }}
                />
              </Box>
            </SimpleBar>
          </Box> */}

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            px={1}
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              flexDirection='row'
              width='100%'
              display='flex'
              py={2}
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Typography variant='h6' color='initial'>
                Diet Chart
              </Typography>
              <Box
                display='flex'
                flexDirection='row'
                alignContent='center'
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{
                    paddingRight: '8px',
                  }}
                >
                  Filter by:
                </Typography>
                <CustomizedDividers
                  onSelectionChanged={(value) => {
                    if (value !== null) {
                      dispatch(
                        requestOPDAppCountForToday(moment(value).toDate())
                      )
                      dispatch(
                        requestIPDAppCountForToday(moment(value).toDate())
                      )
                      dispatch(
                        requestOPDAppCountForTodayConsult(
                          moment(value).toDate()
                        )
                      )
                      setSelectedDate(moment(value).toDate())
                    }
                  }}
                  options={menu}
                  preSelectedValue={moment(selectedDate)
                    .startOf('day')
                    .format()}
                  id='diet'
                />
              </Box>
            </Box>
            <Box
              flexDirection='row'
              width='100%'
              display='flex'
              py={1}
              justifyContent='space-between'
            >
              <Typography variant='subtitle1' color='initial'>
                {moment(selectedDate).format('Do MMM yyyy')}
              </Typography>
            </Box>
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              {(appointmentSearchSlice.searchingAppointments ||
                nutritionCatalogueValueSetSlice.searchingTreatment) && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No Diets are available
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while fetching Diet list
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.dateWiseAppointments && (
                <Box
                  display='flex'
                  flexDirection='Column'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height='100%'
                    overflow='auto'
                  >
                    <Box
                      display='flex'
                      overflow='auto'
                      height='100%'
                      flexGrow={2}
                    >
                      <List
                        id='scrollableDiv'
                        subheader={<li style={{ padding: 0, width: '100%' }} />}
                        style={{
                          padding: 0,
                          width: '100%',
                          overflow: 'auto',
                          height: '100%',
                        }}
                      >
                        {/* {(appointmentSearchSlice.recordsCount === undefined ||
                        appointmentSearchSlice.recordsCount === 0) &&
                        !appointmentSearchSlice.searchingAppointments && (
                          <Typography variant='body1' color='initial'>
                            No IPD's available .
                          </Typography>
                        )} */}
                        <InfiniteScroll
                          dataLength={appointmentSearchSlice.recordsCount ?? 0}
                          scrollThreshold='300px'
                          next={() => {
                            dispatch(
                              requestForDateWiseDietLists(
                                type,
                                selectedDate,
                                selectedIpdStatuses,
                                appointmentSearchSlice.pageState,
                                appointmentSearchSlice.availableAppointments
                                  ?.length,
                                appointmentSearchSlice.dateWiseAppointments,
                                appointmentSearchSlice.availableAppointments
                              )
                            )
                          }}
                          hasMore={
                            (appointmentSearchSlice.availableAppointments
                              ?.length ?? 0) <
                            (appointmentSearchSlice.recordsCount ?? -1)
                          }
                          loader=''
                          endMessage={
                            appointmentSearchSlice.searchingAppointments ? (
                              <p />
                            ) : (
                              <p style={{ textAlign: 'center' }} />
                            )
                          }
                          scrollableTarget='scrollableDiv'
                        >
                          {appointmentSearchSlice.dateWiseAppointments!.map(
                            (e, index: number) => (
                              <DietListOfTheDate
                                key={e.date}
                                dietPlansWithDate={e}
                                id={`${index}`}
                              />
                            )
                          )}
                        </InfiniteScroll>
                      </List>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
