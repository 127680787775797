import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import AodIcon from '@mui/icons-material/Aod'

import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestConditionHistoryOfPatient } from 'redux/patientMedicalHistory/medicalConditionHistory/medicalHistoryConditionsSlice'
import { RootState } from 'redux/rootReducer'

import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'

import { PhysicalExaForDisplayTileReferral } from 'views/components/referralHistory.tsx/assessmentHistory/referralTileSign'
import { getPhysicalExams } from 'utils/fhirResoureHelpers/observationHelpers'
import { GroupedOccupation } from 'models/groupedOccupations'

interface MedicalConditionsProps {
  conditionsData: GroupedOccupation[]
}

export const ReferralSignOnSymptom: React.FC<MedicalConditionsProps> = ({
  conditionsData,
}: MedicalConditionsProps) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const medicalHistorySlice = useSelector(
    (state: RootState) => state.medicalAssessmentConditionSlice
  )

  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)

  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [groupVitals, setGroupVitals] = useState<GroupedConditionData[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  //   useEffect(() => {
  //     if (conditionsData.length > 0) {
  //       updateConditions(conditionsData)
  //     }
  //   }, [])

  function updateConditions(conditionList: GroupedConditionData[]) {
    const results: GroupedConditionData[] = []
    for (let i = 0; i < conditionList.length; i++) {
      results.push({
        date: conditionList[i].date,
        condition: conditionList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedConditionData[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <>
      <Box
        flexGrow
        width='100%'
        flexDirection='column'
        display='flex'
        style={{ padding: 0, margin: 0 }}
      >
        <Box
          flexGrow
          width='100%'
          display='flex'
          flexDirection='row'
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box py={1}>
              <Typography variant='h6' style={{ fontWeight: 'bolder' }}>
                {' '}
                Sign on Examination
              </Typography>
            </Box>
          </Box>
        </Box>

        {conditionsData.length === 0 && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              No data available
            </Typography>
          </Box>
        )}

        {conditionsData.length > 0 && (
          <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
            <Box flexGrow width='100%' display='flex' flexDirection='column'>
              {conditionsData.map((val) => (
                <Box py={1} key={val.date ?? ''}>
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(val.date).format('Do MMM YYYY hh:mm A')}
                      </Typography>
                    </Box>
                  </Box>
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    style={{
                      backgroundColor: kDialogueBackground,
                    }}
                  >
                    <Grid item xs={12}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='80%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Sign
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <PhysicalExaForDisplayTileReferral
                    obsData={getPhysicalExams(val.occupation)}
                    split={true}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        )}
      </Box>

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Condition'
      />

      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
    </>
  )
}
