import { Box } from '@material-ui/core'
import _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getMedicationsOfIPD } from 'redux/clinic/ipdmanagement/medicationListIPD/medicationsListSliceIPD'
import { resetAddDietPlanDetails } from 'redux/consultation/dietPlan/addDietPlan/addDietPlanSlice'
import {
  requestDietPlanDetails,
  resetDietSearchState,
} from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetails,
  isAyurvedaDoctor,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import { AddMedicationsHandler } from 'views/components/consultation/assessment/addMedication'
import { AddKriyasForEncounters } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'
import { KriyasListForEncounterIPD } from 'views/components/consultation/plan/ayurveda/kriyasForEncounterIPD'
import { DietPlanList } from 'views/components/consultation/plan/dietPlanList'
import { DietPlanManagementOfEncounter } from 'views/components/dietplan/addDietPlan'

import { AddAyurvedaMedicationsHandlerIPD } from './AddAyurvedaMedicationIPD'

import { MedicationsListIPD } from './medicationsListIPD'
import { NextStepPlan } from './nextStepPlan'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  split: boolean
}

export const FullWidthNextStepsOfConsultationIPD: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])

    const dietTimeSearchSlice = useSelector(
      (state: RootState) => state.dietTImeSearchSlice
    )
    const [ayurvedaMedicationState, setAyurvedaMedicationState] =
      useState<string>(_.random(0, 10000).toString())

    const existingWeeklyDietPlanSearchSlice = useSelector(
      (state: RootState) => state.existingWeeklyDietPlanSearchSlice
    )

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)

    const [openDietPlan, setOpenDietPlan] = useState<boolean>(false)
    const [kriyaListState, setKriyaListState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [openKriyas, setOpenKriyas] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(resetDietSearchState())
    }, [dispatch])

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            padding={0.5}
            flexGrow
            width='100%'
          >
            <NextStepPlan
              planName={
                isAyurvedaDoctor()
                  ? `Oushadhi (${t('labelMedication:resourceName')})`
                  : t('labelMedication:resourceName')
              }
              subString=''
              onClicked={() => {
                setOpenAddMedicationDialogue(true)
              }}
              bottomWidget={
                <MedicationsListIPD
                  fhirAppointmentDetails={fhirAppointmentDetails}
                />
              }
              /* count={2} */
            />
          </Box>

          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            {isAyurvedaDoctor() && (
              <Box
                display='flex'
                flexDirection='row'
                padding={0.5}
                width='100%'
              >
                <NextStepPlan
                  planName='Kriyas (Procedure)'
                  subString=''
                  bottomWidget={
                    <KriyasListForEncounterIPD
                      fhirAppointmentDetails={fhirAppointmentDetails}
                      updateList={kriyaListState}
                    />
                  }
                  onClicked={() => {
                    if (
                      fhirAppointmentDetails.mainEncounter !== undefined &&
                      fhirAppointmentDetails.mainEncounter.id !== undefined
                    ) {
                      setOpenKriyas(true)
                    } else {
                      dispatch(
                        showErrorAlert(
                          'No details have been added as part of Appointment. Please add few details about patient visit'
                        )
                      )
                    }
                  }}
                />
              </Box>
            )}
          </Box>

          {fhirAppointmentDetails.mainEncounter && (
            <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepPlan
                planName='Diet Plan'
                subString=''
                bottomWidget={
                  <Box display='flex' flexDirection='column' width='100%'>
                    <DietPlanList
                      encounter={fhirAppointmentDetails.mainEncounter}
                      patient={fhirAppointmentDetails.patient}
                      practitionerRole={
                        fhirAppointmentDetails.practitionerDetail
                          .practitionerRole
                      }
                      onClick={() => {
                        //   setOpenDietPlan(true)
                      }}
                    />
                  </Box>
                }
                onClicked={() => {
                  if (
                    fhirAppointmentDetails.mainEncounter !== undefined &&
                    fhirAppointmentDetails.mainEncounter.id !== undefined
                  ) {
                    if (
                      existingWeeklyDietPlanSearchSlice.dietList ===
                        undefined ||
                      existingWeeklyDietPlanSearchSlice.dietList!.length === 0
                    ) {
                      setOpenDietPlan(true)
                    } else {
                      dispatch(
                        showErrorAlert('Weekly Diet plan already added.')
                      )
                    }
                  } else {
                    dispatch(
                      showErrorAlert(
                        'No details have been added as part of Appointment. Please add few details about patient visit'
                      )
                    )
                  }
                }}
              />
            </Box>
          )}

          {openDietPlan && (
            <DietPlanManagementOfEncounter
              practitionerRoleId={getCurrentUserPractitionerRoleDetails().id!}
              open={openDietPlan}
              patient={fhirAppointmentDetails.patient}
              appointmentStartDate={moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')}
              encounterID={
                fhirAppointmentDetails.mainEncounter === undefined
                  ? ''
                  : fhirAppointmentDetails.mainEncounter!.id ?? ''
              }
              patientID={fhirAppointmentDetails.patient.id!}
              onClose={() => {
                dispatch(resetAddDietPlanDetails())
                if (
                  fhirAppointmentDetails.mainEncounter &&
                  fhirAppointmentDetails.mainEncounter.id
                )
                  dispatch(
                    requestDietPlanDetails({
                      dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
                      patientId: fhirAppointmentDetails.patient.id!,
                      encounterId: fhirAppointmentDetails.mainEncounter.id,
                      practitionerRoleId:
                        fhirAppointmentDetails.practitionerDetail
                          .practitionerRole.id,
                      showPlanSuggestedBySameDoctor: true,
                    })
                  )
                setOpenDietPlan(false)
              }}
              onReferralsAdded={() => {
                dispatch(resetAddDietPlanDetails())
                if (
                  fhirAppointmentDetails.mainEncounter &&
                  fhirAppointmentDetails.mainEncounter.id
                )
                  dispatch(
                    requestDietPlanDetails({
                      dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
                      patientId: fhirAppointmentDetails.patient.id!,
                      encounterId: fhirAppointmentDetails.mainEncounter.id,
                      practitionerRoleId:
                        fhirAppointmentDetails.practitionerDetail
                          .practitionerRole.id,
                      showPlanSuggestedBySameDoctor: true,
                    })
                  )
                setOpenDietPlan(false)
              }}
            />
          )}
          {/* {!isAyurvedaDoctor() && (
            <AddMedicationsHandler
              open={openAddMedicationDialogue}
              encounter={fhirAppointmentDetails.mainEncounter!}
              patient={fhirAppointmentDetails.patient}
              practitionerDetail={fhirAppointmentDetails.practitionerDetail}
              onClose={() => {
                dispatch(
                  getMedicationsOfAppointment(
                    fhirAppointmentDetails.patient,
                    fhirAppointmentDetails.practitionerDetail,
                    fhirAppointmentDetails.mainEncounter,
                    undefined,
                    []
                  )
                )
                setOpenAddMedicationDialogue(false)
              }}
              onMedicalConditionAdded={() => {
                dispatch(
                  getMedicationsOfAppointment(
                    fhirAppointmentDetails.patient,
                    fhirAppointmentDetails.practitionerDetail,
                    fhirAppointmentDetails.mainEncounter,
                    undefined,
                    []
                  )
                )
                setOpenAddMedicationDialogue(false)
              }}
            />
          )} */}
          {openKriyas && (
            <AddKriyasForEncounters
              open={openKriyas}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
              }}
              onClose={() => {
                setKriyaListState(_.random(0, 1000).toString())
                setOpenKriyas(false)
              }}
              onLabDiagnosticsAdded={() => {
                setKriyaListState(_.random(0, 1000).toString())
                setOpenKriyas(false)
              }}
            />
          )}
          {isCurrentUnitIsWellonessCenter() && (
            <AddAyurvedaMedicationsHandlerIPD
              open={openAddMedicationDialogue}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(
                  getMedicationsOfAppointment(
                    fhirAppointmentDetails.patient,

                    fhirAppointmentDetails.mainEncounter!,
                    false,
                    fhirAppointmentDetails.mainServiceRequest.supportingInfo
                  )
                )

                setOpenAddMedicationDialogue(false)
              }}
              onMedicalConditionAdded={() => {
                dispatch(
                  getMedicationsOfAppointment(
                    fhirAppointmentDetails.patient,

                    fhirAppointmentDetails.mainEncounter!,
                    false,
                    fhirAppointmentDetails.mainServiceRequest.supportingInfo
                  )
                )
                setOpenAddMedicationDialogue(false)
                setAyurvedaMedicationState(_.random(0, 1000).toString())
              }}
            />
          )}
        </Box>
      </ReactVisibilitySensor>
    )
  }
