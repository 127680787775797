import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getMedicationsOfIPD } from 'redux/clinic/ipdmanagement/medicationListIPD/medicationsListSliceIPD'
import { resetAddWellnessReferralAdded } from 'redux/consultation/addWellnessReferralIPD/addWellnessReferralsSliceIPD'
import { getImagingRequestsOfIPD } from 'redux/consultation/ImagingReferrals/imagingDiagnosticsListSliceIPD'
import { getImagingRequestsOfAppointment } from 'redux/consultation/imaging_diagnostics_requests_list/imagingDiagnosticsListSlice'
import { getLabRequestsOfAppointmentForIPD } from 'redux/consultation/labReferralListIPD/labDiagnosticsListSliceIPD'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getWellnessServiceRequestsForIPD } from 'redux/consultation/wellnessReferralIPD/wellnessReferralsListSliceIPD'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { isTermsAndConditionAccepted } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { AddWellnessReferralsIPD } from 'views/components/consultation/assessment/addWellnessReferralIPD'
// import { MedicationsList } from 'views/components/consultation/assessment/medicationsList'
import { AddCustomInstructions } from 'views/components/consultation/plan/addCustomInstruction'
import { AddImagingDiagnosticsTests } from 'views/components/consultation/plan/addImagingDiagnosticOrders'
import { AddKriyasForEncounters } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'
import { AddAyurvedaMedicationsHandler } from 'views/components/consultation/plan/ayurveda/addMedicationForAyurveda'
import {
  AddPathyaApathya,
  AddPathyaApathyaProps,
} from 'views/components/consultation/plan/ayurveda/addPathyaAndApathya'
import { KriyasListForEncounterIPD } from 'views/components/consultation/plan/ayurveda/kriyasForEncounterIPD'
import { KriyasListForEncounter } from 'views/components/consultation/plan/ayurveda/kriyasListForEncounter'
import { PathyaApathyaListForEncounter } from 'views/components/consultation/plan/ayurveda/pathyaApathyaList'
import { PathyaApathyaListForEncounterForIPD } from 'views/components/consultation/plan/ayurveda/pathyaApathyaListIPD'
import { CarePlanInstructionList } from 'views/components/consultation/plan/instructionsList'
import { LabDiagnosticRequestsList } from 'views/components/consultation/plan/labDiagnosticList'
import { NextStepMultipleSubPlan } from 'views/components/consultation/plan/nextStepPlanMultipleSubPlan'
import { WellnessReferralsListIPD } from 'views/components/consultation/plan/wellnessReferralListIPD'
import { AyurVedaMedicationsList } from '../assessment/ayurvedaMediactionLIst'
import { AyurvedaTreatmentCard } from '../treatment/ayurvedaTreatment/ayurvedaTreatmentCard'
import { AddAyurvedaMedicationsHandlerIPD } from './AddAyurvedaMedicationIPD'
import { AddLabReferralLabTests } from './addLabTestReferral'
import { AyurvedaDietPlan } from './AyuerVedaDietPlan'
import { RecommendationWrapperIPD } from './cdsRecomendations/cds_reccomendation_wrapperIPD'
import { ImagingDiagnosticsListIPD } from './ImagingReferralList'
import { CarePlanInstructionListIPD } from './instructionsListIPD'
import { LabReferralList } from './labRefeeralList'
import { NextStepPlan } from './nextStepPlan'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  split?: boolean
}

export const NextStepsOfConsultationIPDAyurVeda: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [showCohorts, setShowCohorts] = useState<boolean>(false)

    const [openAddWellReferrals, setOpenAddWellReferrals] =
      useState<boolean>(false)

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)
    const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
      useState<boolean>(false)
    const dispatch = useDispatch()
    const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
      useState(false)
    const [openAddInstruction, setAddInstruction] = useState<boolean>(false)
    const [ayurvedaMedicationState, setAyurvedaMedicationState] =
      useState<string>(_.random(0, 10000).toString())
    const [
      openAddImagingDiagnosticDetails,
      setOpenAddImagingDiagnosticDetails,
    ] = useState<boolean>(false)
    const [openKriyas, setOpenKriyas] = useState<boolean>(false)
    const [kriyaListState, setKriyaListState] = useState<string>(
      _.random(0, 10000).toString()
    )

    const patientSubscriptionForUnitSlice = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )
    const [addPathyaApathyProps, setAddPathyaApathyProps] =
      useState<AddPathyaApathyaProps>({
        encounterReference: {
          reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
        },
        patientReference: {
          reference: `Patient/${fhirAppointmentDetails.patient.id}`,
        },
        onClose: () => {},
        onLabDiagnosticsAdded: () => {},
        open: false,
      })

    const [aharaUpdateState, setAharaUpdateState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [viharaUpdateState, setViharaUpdateState] = useState<string>(
      _.random(0, 10000).toString()
    )

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' flexDirection='column' width='100%'>
            {/* <Box
              display='flex'
              flexDirection='row'
              padding={0.5}
              flexGrow
              width='100%'
            >
              <NextStepPlan
                planName={t('labelMedication:resourceName')}
                subString=''
                onClicked={() => {
                  setOpenAddMedicationDialogue(true)
                }}
                bottomWidget={
                  <MedicationsList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName={t('labelCommon:lab_test')}
                subString=''
                bottomWidget={
                  <LabDiagnosticRequestsList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenAddDiagnosticDetails(true)
                }}
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName={t('labelCommon:followup_appointment')}
                subString=''
                bottomWidget={
                  <PatientFolloWUpAppointments
                    fhirPatientDetail={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenCreateAppointmentPopup(true)
                }}
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Referral Appointment'
                subString=''
                bottomWidget={
                  <ReferralRequestList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}

            <Box
              display='flex'
              flexDirection='row'
              padding={0.5}
              flexGrow
              width='100%'
            >
              <NextStepPlan
                planName={`Oushadhi (${t('labelMedication:resourceName')})`}
                subString=''
                onClicked={() => {
                  if (split === undefined) {
                    setOpenAddMedicationDialogue(true)
                  }
                }}
                bottomWidget={
                  <AyurVedaMedicationsList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                /* count={2} */
              />
            </Box>

            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Instructions'
                subString=''
                bottomWidget={
                  <CarePlanInstructionListIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  if (
                    fhirAppointmentDetails.mainEncounter !== undefined &&
                    fhirAppointmentDetails.mainEncounter.id !== undefined
                  ) {
                    setAddInstruction(true)
                  } else {
                    dispatch(
                      showErrorAlert(
                        'No details have been added as part of Appointment. Please add few details about patient visit'
                      )
                    )
                  }
                }}
              />
            </Box> */}
            {split === undefined && (
              <Box display='flex' flexDirection='row' padding={0.5}>
                <AyurvedaDietPlan
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  status=''
                />
              </Box>
            )}

            {split === undefined && (
              <Box
                display='flex'
                flexDirection='row'
                padding={0.5}
                width='100%'
              >
                <AyurvedaTreatmentCard
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  status=''
                />
              </Box>
            )}

            <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepPlan
                planName={t('labelCommon:lab_test')}
                subString=''
                bottomWidget={
                  <LabReferralList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenAddDiagnosticDetails(true)
                }}
              />
            </Box>

            <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepPlan
                planName='Imaging Studies'
                subString=''
                bottomWidget={
                  <ImagingDiagnosticsListIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenAddImagingDiagnosticDetails(true)
                }}
              />
            </Box>
            <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Wellness Referrals'
                subString=''
                onClicked={() => {
                  if (
                    patientSubscriptionForUnitSlice.subscriptionStatus ===
                    'not-offered'
                  ) {
                    setOpenAddWellReferrals(true)
                  } else if (
                    patientSubscriptionForUnitSlice.subscriptionStatus !==
                    'active'
                  ) {
                    const { task } = patientSubscriptionForUnitSlice.tasks![0]
                    if (isTermsAndConditionAccepted(task) === true) {
                      dispatch(
                        showErrorAlert(
                          'Patient subscription is not active. Please activate the subscription to refer wellness services.'
                        )
                      )
                    } else {
                      dispatch(
                        showErrorAlert(
                          'Patient subscription is not active. Please ask patient to  accept the terms and conditions in App, to refer wellness services.',
                          undefined,
                          'Subscription not active',
                          true
                        )
                      )
                    }
                  } else {
                    setOpenAddWellReferrals(true)
                  }

                  setOpenAddWellReferrals(true)
                }}
                bottomWidget={
                  <WellnessReferralsListIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box>
            <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepPlan
                planName='Instructions'
                subString=''
                bottomWidget={
                  <CarePlanInstructionListIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  if (
                    fhirAppointmentDetails.mainEncounter !== undefined &&
                    fhirAppointmentDetails.mainEncounter.id !== undefined
                  ) {
                    setAddInstruction(true)
                  } else {
                    dispatch(
                      showErrorAlert(
                        'No details have been added as part of Appointment. Please add few details about patient visit'
                      )
                    )
                  }
                }}
              />
            </Box>

            {/* <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepPlan
                planName='Kriyas (Procedure)'
                subString=''
                bottomWidget={
                  <KriyasListForEncounterIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    updateList={kriyaListState}
                    isReadonly={false}
                  />
                }
                onClicked={() => {
                  if (
                    fhirAppointmentDetails.mainEncounter !== undefined &&
                    fhirAppointmentDetails.mainEncounter.id !== undefined
                  ) {
                    setOpenKriyas(true)
                  } else {
                    dispatch(
                      showErrorAlert(
                        'No details have been added as part of Appointment. Please add few details about patient visit'
                      )
                    )
                  }
                }}
              />
            </Box> */}

            <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepMultipleSubPlan
                planName='Pathya Apathya'
                subPlans={[
                  {
                    subPlanName: 'Ahara',
                    subString: '',
                    isAddDisplay: !split,
                    isEditAllowed: !split,
                    bottomWidget: (
                      <PathyaApathyaListForEncounterForIPD
                        fhirAppointmentDetails={fhirAppointmentDetails}
                        pathyaApathyaTypeCode={{
                          system: 'http://www.who.int/ita',
                          code: 'ITA-8.1.2',
                          display: 'Ahara',
                        }}
                        updateList={aharaUpdateState}
                      />
                    ),
                    onClicked: () => {
                      setAddPathyaApathyProps({
                        ...addPathyaApathyProps,
                        open: true,
                        pathyaApathyaTypeCode: {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-8.1.2',
                          display: 'Ahara',
                        },
                        onLabDiagnosticsAdded: () => {
                          setAharaUpdateState(_.random(0, 1000).toString())
                        },
                        onClose: () => {
                          setAddPathyaApathyProps({
                            ...addPathyaApathyProps,
                            open: false,
                          })
                        },
                      })
                    },
                  },
                  {
                    subPlanName: 'Vihara',
                    isAddDisplay: true,
                    isEditAllowed: true,
                    bottomWidget: (
                      <PathyaApathyaListForEncounterForIPD
                        fhirAppointmentDetails={fhirAppointmentDetails}
                        pathyaApathyaTypeCode={{
                          system: 'http://www.who.int/ita',
                          code: 'ITA-10.2.14',
                          display: 'Vihara',
                        }}
                        updateList={viharaUpdateState}
                      />
                    ),
                    subString: '',
                    onClicked: () => {
                      setAddPathyaApathyProps({
                        ...addPathyaApathyProps,
                        open: true,
                        pathyaApathyaTypeCode: {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-10.2.14',
                          display: 'Vihara',
                        },
                        onLabDiagnosticsAdded: () => {
                          setViharaUpdateState(_.random(0, 1000).toString())
                        },
                        onClose: () => {
                          setAddPathyaApathyProps({
                            ...addPathyaApathyProps,
                            open: false,
                          })
                        },
                      })
                    },
                  },
                ]}
              />
            </Box>
            {openAddMedicationDialogue && (
              <AddAyurvedaMedicationsHandlerIPD
                open={openAddMedicationDialogue}
                fhirAppointmentDetails={fhirAppointmentDetails}
                onClose={() => {
                  dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
                  setOpenAddMedicationDialogue(false)
                }}
                onMedicalConditionAdded={() => {
                  dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
                  setOpenAddMedicationDialogue(false)
                  setAyurvedaMedicationState(_.random(0, 1000).toString())
                }}
              />
            )}

            {/* <CreateAppointmentHandler
              allowWalkIn={false}
              open={openCreateAppointmentPopup}
              preSelectedDoctor={{
                id:
                  fhirAppointmentDetails.practitionerDetail.practitioner.id ??
                  '',
                roleObject:
                  fhirAppointmentDetails.practitionerDetail.practitionerRole,
                practRoleId:
                  fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
                practitionerObject:
                  fhirAppointmentDetails.practitionerDetail.practitioner,
                name: getNameFromHumanName(
                  fhirAppointmentDetails.practitionerDetail.practitioner.name ??
                    []
                ),
                phone: '',
                fullName: '',
                color: '',
                primaryContact: '',
                status: '',
                enabled: fhirAppointmentDetails.practitionerDetail.practitioner
                  .active
                  ? fhirAppointmentDetails.practitionerDetail.practitioner
                      .active
                  : false,
              }}
              fhirAppointmentDetail={fhirAppointmentDetails}
              onClose={() => {
                setOpenCreateAppointmentPopup(false)
                dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
              }}
              onAppointmentCreated={(createdAppointment) => {
                setOpenCreateAppointmentPopup(false)
                dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
              }}
            /> */}
          </Box>
          {/* <Box display='flex' flexDirection='column' width='50%' p={2}>
            <RecommendationWrapperIPD
              fhirAppointmentDetails={fhirAppointmentDetails}
            />
          </Box> */}

          {openKriyas && (
            <AddKriyasForEncounters
              open={openKriyas}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${
                  fhirAppointmentDetails.mainEncounter!.id
                }`,
              }}
              onClose={() => {
                setKriyaListState(_.random(0, 1000).toString())
                setOpenKriyas(false)
              }}
              onLabDiagnosticsAdded={() => {
                setKriyaListState(_.random(0, 1000).toString())
                setOpenKriyas(false)
              }}
            />
          )}
          {openAddInstruction && (
            <AddCustomInstructions
              open={openAddInstruction}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${
                  fhirAppointmentDetails.mainEncounter!.id
                }`,
              }}
              onClose={() => {
                dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
              onInstructionAdded={() => {
                dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
            />
          )}

          {openAddImagingDiagnosticDetails && (
            <AddImagingDiagnosticsTests
              open={openAddImagingDiagnosticDetails}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${
                  fhirAppointmentDetails.mainEncounter!.id
                }`,
              }}
              onClose={() => {
                dispatch(getImagingRequestsOfIPD(fhirAppointmentDetails, false))
                setOpenAddImagingDiagnosticDetails(false)
              }}
              onLabDiagnosticsAdded={() => {
                dispatch(getImagingRequestsOfIPD(fhirAppointmentDetails, false))
                setOpenAddImagingDiagnosticDetails(false)
              }}
            />
          )}

          {openAddWellReferrals && (
            <AddWellnessReferralsIPD
              open={openAddWellReferrals}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(resetAddWellnessReferralAdded())
                dispatch(
                  getWellnessServiceRequestsForIPD(fhirAppointmentDetails)
                )

                setOpenAddWellReferrals(false)
              }}
              onReferralsAdded={() => {
                dispatch(
                  getWellnessServiceRequestsForIPD(fhirAppointmentDetails)
                )
                setOpenAddWellReferrals(false)
              }}
            />
          )}

          <AddLabReferralLabTests
            open={openAddDiagnosticDetails}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getLabRequestsOfAppointmentForIPD(fhirAppointmentDetails)
              )
              setOpenAddDiagnosticDetails(false)
            }}
            onLabDiagnosticsAdded={() => {
              dispatch(
                getLabRequestsOfAppointmentForIPD(fhirAppointmentDetails)
              )
              setOpenAddDiagnosticDetails(false)
            }}
          />
          {addPathyaApathyProps.open && (
            <AddPathyaApathya {...addPathyaApathyProps} />
          )}
        </Box>
      </ReactVisibilitySensor>
    )
  }
