import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { getObservationDefinitionsFromServer } from 'utils/valusetsHelpers'
import match from 'autosuggest-highlight/match'
import { Box, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  titleCase,
} from 'utils/fhirResourcesHelper'
import axios, { CancelTokenSource } from 'axios'
import { cancelTokenStore } from 'services/fhirApiServices'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export interface ObservationDefinitionSearchProps {
  id: string
  url: string
  title: string
  preSelectedOptions?: R4.ICodeableConcept[]
  preEnteredText?: string
  onOptionSelected: (selectedOption?: R4.IObservationDefinition) => void
  helperText?: string
  disabled: boolean
  fuzzySearch?: boolean
  alwaysShowAllOptions?: boolean
  isAyurveda?: boolean
  placeHolder?: string
  profile?: boolean
  isImaging?: boolean
}

export const ObservationDefinitionSelectSingle: React.FC<ObservationDefinitionSearchProps> =
  ({
    id,
    url,
    title,
    preSelectedOptions,
    preEnteredText,
    onOptionSelected,
    helperText,
    disabled,
    fuzzySearch = false,
    alwaysShowAllOptions = false,
    isAyurveda,
    placeHolder,
    profile,
    isImaging,
  }) => {
    const classes = useStyles()
    const [value, setValue] =
      React.useState<R4.IObservationDefinition | null>(null)
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<R4.IObservationDefinition[]>(
      []
    )
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(!_.isEmpty(preEnteredText))

    const preselectedCodes = preSelectedOptions?.map((option) =>
      getDefaultCodeOfSystemFromCodableConcept(option)
    )

    function getCancellToken() {
      axios.CancelToken.source()
      if (cancelTokenStore.has(id)) {
        const controlTokenForSearch: CancelTokenSource =
          cancelTokenStore.get(id)
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete(id)
      }
      cancelTokenStore.set(id, axios.CancelToken.source())
    }

    const fetch = React.useMemo(
      () =>
        throttle(
          (
            request: { input: string },
            callback: (results?: R4.IObservationDefinition[]) => void
          ) => {
            setLoading(true)

            getObservationDefinitionsFromServer({
              searchString: request.input,
              cancelToken: getCancellToken(),
            }).then((response) => {
              const results = response.filter(
                (item) =>
                  !preselectedCodes?.includes(
                    getDefaultCodeOfSystemFromCodableConcept(item.code)
                  )
              )
              callback(results)
            })
          },
          200
        ),
      []
    )

    React.useEffect(() => {
      let active = true
      setLoading(true)
      if (inputValue === '') {
        setOptions(value ? [value] : [])
        setLoading(false)
        return undefined
      }

      fetch({ input: inputValue }, (results?: R4.IObservationDefinition[]) => {
        if (active) {
          let newOptions = [] as R4.IObservationDefinition[]

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
        setLoading(false)
      })

      return () => {
        active = false
        setLoading(false)
      }
    }, [value, inputValue, fetch])

    React.useEffect(() => {
      if (!_.isEmpty(preEnteredText)) {
        setInputValue(preEnteredText ?? ' ')
        setLoading(true)
      }
    }, [])

    return (
      <Box display='flex' flexDirection='column' flexGrow={isAyurveda ? 0 : 1}>
        {title.length > 0 && <WelloFormItemLabel title={title} />}

        <Autocomplete
          id={id}
          getOptionLabel={(option) =>
            typeof option === 'string'
              ? option
              : profile === undefined
              ? isImaging
                ? titleCase(option.preferredReportName ?? '')
                : _.capitalize(option.preferredReportName) ?? ''
              : option.preferredReportName ?? ''
          }
          open={open}
          onOpen={() => {
            setInputValue(inputValue.length === 0 ? ' ' : inputValue)
            setOpen(true)
          }}
          autoFocus={!_.isEmpty(preEnteredText)}
          onClose={() => {
            setOpen(false)
          }}
          filterOptions={(x) => x}
          options={options}
          disabled={disabled}
          autoComplete
          loading={loading}
          getOptionSelected={(option, val) => option.code === val.code}
          disableClearable={true}
          inputValue={inputValue}
          includeInputInList={false}
          value={value ?? undefined}
          onChange={(
            event: any,
            newValue: R4.IObservationDefinition | null
          ) => {
            setValue(newValue)
            onOptionSelected(newValue === null ? undefined : newValue)
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={`${id}_ edit`}
              variant='outlined'
              placeholder={placeHolder ?? ''}
              fullWidth
              InputProps={{
                ...params.InputProps,
                inputProps: { ...params.inputProps, maxLength: '256' },
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              // inputProps={{ ...params.inputProps, maxLength: '256' }}
              size='small'
              helperText={helperText}
            />
          )}
          renderOption={(option) => {
            const matches =
              profile === undefined
                ? isImaging
                  ? match(
                      titleCase(option.preferredReportName ?? ''),
                      inputValue
                    )
                  : match(
                      _.capitalize(option.preferredReportName ?? ''),
                      inputValue
                    )
                : match(option.preferredReportName ?? '', inputValue)
            const parts =
              profile === undefined
                ? isImaging
                  ? parse(titleCase(option.preferredReportName ?? ''), matches)
                  : parse(
                      _.capitalize(option.preferredReportName ?? ''),
                      matches
                    )
                : parse(option.preferredReportName ?? '', matches)

            return (
              <Grid container alignItems='center'>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={part.text}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Grid>
              </Grid>
            )
          }}
        />
      </Box>
    )
  }
