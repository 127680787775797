import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { requestCareTeamLabOrder } from 'redux/consultation/recommendation_additions/lab_tests_addition/labTestsAdditionSlice'
import {
  requestLabReferral,
  resetLabReferralCPGState,
} from 'redux/consultation/recommendation_additions/lab_tests_referral_addition/labTestsReferralAdditionSlice'
import { RootState } from 'redux/rootReducer'
import { handleAddLabTestCarePlanActivities } from 'utils/careplan_utils/labTestsOfCarePlan'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import CPGSelectionContext from 'views/contexts/cpgContextSelection'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddLabTestFromCPGHandler } from './add_lab_referral_popup'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface PlanDefForPopUp {
  open: boolean
  tests?: R4.IPlanDefinition[]
}

interface AddLabTestsButtonProps {
  tests: R4.IPlanDefinition[]
  carePlanIdentifier: string
  onRequestFinished?: () => void
  onAddClicked?: () => void
}

export const AddLabTestsButton: React.FC<AddLabTestsButtonProps> = ({
  tests,
  carePlanIdentifier,
  onRequestFinished,
  onAddClicked,
}: AddLabTestsButtonProps) => {
  const instructionAdditionSlice = useSelector(
    (state: RootState) => state.labTestReferralAddition
  )

  const visitContext = useContext(VisitAppointmentContext)

  const classes = useStyles()
  const dispatch = useDispatch()

  const subscriptionStatus = useSelector(
    (state: RootState) => state.patientSubscriptionForUnitSlice
  )

  const cdsRecommendationAddition = useContext(CPGSelectionContext)

  const [openAddLabTestsDialogue, setOpenAddLabTestsDialogue] =
    useState<PlanDefForPopUp>({
      open: false,
    })
  const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  const [data, setData] = useState<boolean>(false)

  function handleUpdateButton() {
    if (
      visitContext !== null &&
      visitContext.patient !== null &&
      visitContext.encounter !== undefined
    ) {
      setUpdatingStatus({
        requesting: true,
      })

      handleAddLabTestCarePlanActivities(
        visitContext.patient.id!,
        tests,
        visitContext.encounter.id!,
        undefined,
        carePlanIdentifier
      ).then((e) => {
        const res: string[] = []

        if (e.response === 'valid') {
          dispatch(showSuccessAlert('Lab tests added to plan'))
          if (visitContext.onRecommendationAdditionUpdate) {
            visitContext.onRecommendationAdditionUpdate('lab')
          }

          if (onRequestFinished) {
            onRequestFinished()
          }
          setUpdatingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setUpdatingStatus({
            requesting: false,
            requestSuccess: false,
            requestError: true,
            errorDescription: e.errorReason ?? e.responseData.toString(),
          })
          if (onRequestFinished) {
            onRequestFinished()
          }
          dispatch(
            showErrorAlert(
              e.errorReason ?? e.responseData.toString(),
              '',
              'Error while adding lab orders'
            )
          )
        }
      })
    }
  }

  useEffect(() => {
    if (instructionAdditionSlice.additionSuccessful) {
      console.log(
        '---------------careplan-lab---------------- addition successfull-------------------'
      )
      dispatch(resetLabReferralCPGState())
      if (data === true) {
        setData(false)
      } else {
        setData(true)
      }
      if (visitContext.onRecommendationAdditionUpdate) {
        console.log(
          '---------------careplan-lab---------------- addition successfull-------------------'
        )
        visitContext.onRecommendationAdditionUpdate?.('lab')
      }
    }
  }, [instructionAdditionSlice])

  return (
    <Box>
      <Box flexDirection='row'>
        {instructionAdditionSlice.addingLabTests && (
          <Box height={25} width={25}>
            <CircularProgress size={20} />
          </Box>
        )}

        {instructionAdditionSlice.errorWhileAdding && (
          <Box flexDirection='row'>
            <Button
              variant='text'
              color='primary'
              className={classes.buttonText}
              onClick={(e) => {
                handleUpdateButton()

                e.stopPropagation()
              }}
              id='retry_lab_test'
            >
              Retry
            </Button>

            {/*  <Button
              variant='text'
              color='secondary'
              className={classes.rejectButtonText}
            >
              Reject
            </Button> */}
          </Box>
        )}
        {instructionAdditionSlice.additionSuccessful && (
          <Box flexDirection='row' px={2}>
            <Typography variant='subtitle1' color='initial'>
              Added
            </Typography>
          </Box>
        )}
        {!instructionAdditionSlice.additionSuccessful &&
          !instructionAdditionSlice.errorWhileAdding &&
          !instructionAdditionSlice.addingLabTests && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='finalize_lab_test'
                className={classes.buttonText}
                disabled={tests === undefined || tests.length === 0}
                onClick={(e) => {
                  if (
                    subscriptionStatus.subscriptionStatus === 'interested' ||
                    subscriptionStatus.subscriptionStatus === 'active'
                  ) {
                    setOpenAddLabTestsDialogue({
                      open: true,
                      tests,
                    })
                    // if (onAddClicked) onAddClicked()
                  } else {
                    dispatch(
                      showErrorAlert(
                        'Please subscribe to the plan to add lab tests'
                      )
                    )
                  }
                  e.stopPropagation()
                }}
              >
                Finalize & Add
              </Button>

              {/*  <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
              >
                Reject
              </Button> */}
            </Box>
          )}
      </Box>
      {openAddLabTestsDialogue.open && (
        <AddLabTestFromCPGHandler
          open={openAddLabTestsDialogue.open}
          preAddedLabTest={openAddLabTestsDialogue.tests ?? []}
          onClose={() => {
            setOpenAddLabTestsDialogue({
              open: false,
            })
          }}
          onLabTestAdded={(e, preAddedLabTest: R4.IPlanDefinition[]) => {
            setOpenAddLabTestsDialogue({
              open: false,
            })
            cdsRecommendationAddition.addSelection(
              carePlanIdentifier,
              'labTests',
              ''
            )
            // SetMedStored(e)
            if (subscriptionStatus.tasks) {
              const carePlanId = getCarePlanIdFromTaskOutput(
                subscriptionStatus.tasks[0].task
              )

              if (carePlanId)
                dispatch(
                  requestLabReferral(
                    visitContext!.patient!,
                    e,
                    preAddedLabTest,
                    visitContext!.encounter!.id,
                    carePlanIdentifier,
                    carePlanId
                  )
                )

              if (visitContext.onRecommendationAdditionUpdate) {
                visitContext.onRecommendationAdditionUpdate('lab')
              }
              if (onRequestFinished) {
                onRequestFinished()
              }
            }
          }}
        />
      )}
    </Box>
  )
}
