import { Button, Typography, Box } from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { WelloLoadingIndicator } from 'wello-web-components'
import { searchPractitioners } from '../../../redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { DoctorSelectWithSearch } from './DoctorSearch'
import { WelloSelectPractitioner } from './WelloSelectPractitioner'

interface Props {
  onPractitionerSelectionChanged?: (selectedValues: any) => void
  onProviderSelectionChanged?: (selectedValues: any) => void
  onSelectionChanged?: (selectedValues: PractitionerWithRole) => void
  doctorListId?: any
  preSelectedPractitionerRoles?: PractitionerWithRole[]
  preSelectAll?: boolean
  selectedDoctor?: PractitionerWithRole
  isWalkIn?: boolean
}
export const PractitionerSelector: React.FC<Props> = ({
  onPractitionerSelectionChanged,
  onProviderSelectionChanged,
  onSelectionChanged,
  doctorListId,
  preSelectedPractitionerRoles,
  preSelectAll = false,
  selectedDoctor,
  isWalkIn,
}: Props) => {
  const practitionerSearchState = useSelector(
    (state: RootState) => state.practitionerSearchSlice
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(searchPractitioners(undefined, isWalkIn))
  }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchPractitioners())
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <div>
        {practitionerSearchState.searching && (
          <WelloLoadingIndicator message='Searching doctors' />
        )}
        {practitionerSearchState.error && (
          <Typography>
            {' '}
            {practitionerSearchState.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {practitionerSearchState.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
        {practitionerSearchState.resultsAvailable &&
          practitionerSearchState.doctorRoleList && (
            <Box width='100%' display='flex' flexDirection='column'>
              {isWalkIn && (
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  px={1}
                  py={1}
                >
                  <WelloSelectPractitioner
                    availableCodings={practitionerSearchState.doctorRoleList}
                    onChanged={(e: PractitionerWithRole) => {
                      if (onSelectionChanged) {
                        console.log(e)
                        onSelectionChanged(e)
                      }
                    }}
                    title='Select Doctor'
                  />
                </Box>
              )}
              {isWalkIn === undefined && (
                <DoctorSelectWithSearch
                  onValuesChanged={(doctor) => {
                    if (onPractitionerSelectionChanged) {
                      onPractitionerSelectionChanged(doctor)
                    }
                  }}
                  displayField='name'
                  valueField='id'
                  availableOptions={practitionerSearchState.doctorRoleList}
                  id='search'
                  selectedDoctor={selectedDoctor}
                />
              )}
            </Box>
          )}
      </div>
    </ErrorBoundary>
  )
}
