/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import {
  AcceptForSubscriptionPopUp,
  AcceptForSubscriptionProps,
} from 'views/pages/carePlanSusbcriptions/acceptUserForSusbcriptionPopUp'

import {
  getSubscriptionId,
  getSubscriptionStatusDisplay,
} from 'utils/admisnistration/cpg/susbcriptionsHelper'
import {
  getColorOfSubscriptionStatus,
  getTaskDetails,
  isTermsAndConditionAccepted,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { TaskStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Refresh } from '@material-ui/icons'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { useDispatch } from 'react-redux'
import { requestForSubscriptionStatusOfPatient } from 'redux/subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'

interface Props {
  interestTask: R4.ITask
  patientName: string
  subscriptionName: string
  patient: R4.IPatient
}

export const PaymentStatusChecker: React.FC<Props> = ({
  interestTask,
  patientName,
  subscriptionName,
  patient,
}) => {
  const [currentTask, setCurrentTask] = useState(interestTask)

  const dispatch = useDispatch()

  const [acceptSubscription, setAcceptSubscription] =
    useState<AcceptForSubscriptionProps>({
      open: false,
    })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function fetchCurrentTaskDetails() {
    if (currentTask) {
      setUpdateStatus({ requesting: true })
      getTaskDetails(currentTask).then((e) => {
        if (e) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setCurrentTask(e)
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: false,
            requestError: true,
            errorDescription: 'Error while getting current details',
          })
        }
      })
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      overflow='auto'
      width='100%'
      borderTop={1}
      borderColor='divider'
      alignContent='center'
      alignItems='center'
      p={1}
    >
      <Box display='flex' flexGrow={1}>
        <Typography variant='subtitle1' color='initial'>
          Subscription status
        </Typography>
      </Box>
      <Box display='flex' flexGrow={2}>
        <Typography
          variant='body1'
          color='initial'
          style={{
            color: getColorOfSubscriptionStatus(interestTask.status ?? ''),
            fontWeight: 'bold',
          }}
        >
          {getSubscriptionStatusDisplay(currentTask)}
        </Typography>
      </Box>
      {currentTask &&
        currentTask.status &&
        currentTask.status === 'requested' &&
        isTermsAndConditionAccepted(currentTask) === true && (
          <Box display='flex' flexGrow={1}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={() => {
                setAcceptSubscription({
                  open: true,
                  interest: {
                    task: currentTask,
                    status: currentTask.status!,
                    id: currentTask.id!,
                    patientName,
                    subscriptionName,
                    subscriptionId: getSubscriptionId(currentTask) ?? '',
                  },
                  onCancelActionClicked: () => {
                    fetchCurrentTaskDetails()
                    setAcceptSubscription({
                      open: false,
                    })
                  },
                  onSuccessfulAction: () => {
                    fetchCurrentTaskDetails()
                    dispatch(requestForSubscriptionStatusOfPatient(patient.id!))
                    setAcceptSubscription({
                      open: false,
                    })
                  },
                })
              }}
            >
              Confirm Trial
            </Button>
          </Box>
        )}

      {currentTask &&
        currentTask.status &&
        currentTask.status !== TaskStatusKind._accepted && (
          <Box display='flex' flexGrow={1}>
            <Button
              variant='text'
              color='primary'
              size='small'
              disabled={updateStatus.requesting}
              onClick={() => {
                fetchCurrentTaskDetails()
              }}
            >
              {updateStatus.requesting && (
                <CircularProgress size={20} style={{ color: 'primary' }} />
              )}
              {!updateStatus.requesting && (
                <Refresh color='primary' fontSize='small' />
              )}
            </Button>
          </Box>
        )}

      <AcceptForSubscriptionPopUp {...acceptSubscription} />
    </Box>
  )
}
