/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined, Edit } from '@material-ui/icons'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { sendPatientInvite } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import { requestForLocation } from 'redux/clinic/ipdmanagement/bedSearch/roomBedSearchSlice'
import { requestRescheduleOfIPD } from 'redux/clinic/ipdmanagement/editIPD/manageIPDSlice'
import { getTaskStatus } from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import {
  isDoctor,
  isGre,
  isOrgAdmin,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import {
  getExtensionValueOfUrlPatient,
  getGenderOfPatient,
  getInnerExtension,
  getValueCoDeFromValueCodeable,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import {
  isDischarged,
  isDischargeInitiated,
} from 'utils/fhirResoureHelpers/invoice_helper'
import { isGenderFemale } from 'utils/fhirResoureHelpers/ipdHelper'
import { isIPDDischargeEditable } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { PatientNameInIPDWidget } from '../clinic_home_page/patientNameForIpd'
import { ProfilePicWithAgeAndGender } from '../clinic_home_page/profilePicWithAgeAndGender'
import { DischargeDiagnosisOfPT } from '../consultation/assessment/dischargeDiagnosisOfPT'
import { SystemDiagnosisManagement } from '../consultation/assessment/gastroIntestinalSystemDiagnosisNew'
import { Allergies } from '../consultation/history/allergies'
import { MedicalConditions } from '../consultation/history/medical_conditions'
import { Medication } from '../consultation/history/medication'
import { Procedures } from '../consultation/history/procedures'
import { PlanSplitAyurveda } from '../consultation/plan/nextStepsAyurvedaSplit'
import { PatientEvidenceResult } from '../consultation/plan/patientEvidenceResult'
import { PlanPartOfConsultationIPDAyurveda } from '../ipdconusltation/assessment/planDetailsForAyurvedaIPD'
import { Addictions } from '../ipdconusltation/history/additctions'
import { Appetite } from '../ipdconusltation/history/Appetite/appetite'
import { Bowels } from '../ipdconusltation/history/Bowels/bowels'
import { Diet } from '../ipdconusltation/history/Diet'
import { FamilyMedicalConditions } from '../ipdconusltation/history/familyMedicalConditions'
import { Habits } from '../ipdconusltation/history/habits'
import { MensurationDetails } from '../ipdconusltation/history/mensuration/mensurations'
import { Micturition } from '../ipdconusltation/history/Micturition/micturition'
import { Obstrics } from '../ipdconusltation/history/obstrics/obstricsList'
import { IPDChiefComplaint } from '../ipdconusltation/subjective/IPDchiefComplaintsDetails'
import { AssessmentTile } from '../ipdviewData/assessmentTile'
import { DietTile } from '../ipdviewData/dietTile'
import { Feedback } from '../ipdviewData/feedback'
import { LabResultsTile } from '../ipdviewData/labResultsTile'
import { PatientDetailsTile } from '../ipdviewData/patientDetailsTile'
import { PTtreatmentTile } from '../ipdviewData/ptTreatmentTIle'
import { SelfDeclaration } from '../ipdviewData/selfDeclarationTIle'
import { TravelHistory } from '../ipdviewData/travelHistory'
import { TreatmentTile } from '../ipdviewData/treatmentTile'
import { VisaDetailsTile } from '../ipdviewData/visaDetilasTile'
import { VitalsTile } from '../ipdviewData/vitalsTile'
import { CaseStudyPrintButton } from './caseStudyButton'
import { IPDDietReportButton } from './dietReportOfIPDButton'
import { DischargeReportButton } from './dischargeReportButton'
import { IPDReScheduleHandler } from './ipdReschuleHandler'
import { TreatmentReportOfIPDButton } from './treatmentReportOfIPDButton'
import { ViewBillingOfIPD } from './view_billing'

//   import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'
//   import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
//   import { PaymentTile } from '../orderCreationManagement/paymentTile'
//   import { RefundTile } from '../orderCreationManagement/refundTile'
//   import { OrderAppointmentDetails } from './orderAppointmentDetails'
//   import { OrderCancellationHandler } from './orderCancellationHandler'
//   import { OrderReScheduleHandler } from './orderRescheduleHandler'
//   import { OrderTimeLine } from './orderTimeLine'

interface Props {
  ipdDetailsDataFinal: FhirClinicIpdDetails
  isOdd?: boolean
  onCloseClicked: () => void
  dense?: boolean
  date?: Date
}

export const IPDDetails: React.FC<Props> = ({
  ipdDetailsDataFinal,
  isOdd = false,
  onCloseClicked,
  dense = false,
  date,
}: Props) => {
  const ipdActiveAppointmentSlice = useSelector(
    (state: RootState) => state.ipdActiveAppointmentSlice
  )

  const roomBedSearchSlice = useSelector(
    (state: RootState) => state.roomBedSearchSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<any>()
  const [reason, setReason] = useState<string>()
  const [columns, setColumns] = useState<string[]>([])
  const [showBilling, setBillingHandler] = useState<boolean>(false)
  const [showRescheduleHandler, setShowRescheduleHandler] =
    useState<boolean>(false)
  const [labOrderDetail, setLabOrderDetail] =
    useState<FhirClinicIpdDetails>(ipdDetailsDataFinal)

  const [ipdDetailsData, setIpdDetailsData] =
    useState<FhirActiveIPDDetailsForMedicalRole>()

  const locId = getInnerExtension(
    ipdDetailsDataFinal.serviceRequest.extension
      ? ipdDetailsDataFinal.serviceRequest.extension
      : [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant',
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant-bed'
  )

  const [resend, setResend] = useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [statusLoading, setStatusLoading] = useState<boolean>(false)

  function requestPollingForResendOption() {
    if (ipdActiveAppointmentSlice.activeIPDActionDetails)
      getPatientTaskStatus(
        ipdActiveAppointmentSlice.activeIPDActionDetails.patient
      )
  }

  function getPatientTaskStatus(patientDetails: R4.IPatient) {
    setPaymentStatus({ requesting: true })

    setStatusLoading(true)

    getTaskStatus(patientDetails)
      .then((e: boolean) => {
        setStatusLoading(false)

        if (e === true) {
          setResend(true)
        }
        setPaymentStatus({
          requesting: false,
          requestSuccess: true,
        })

        // dispatch(showSuccessAlert('Medication deleted successfully'))
        // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      })
      .catch((e) => {
        dispatch(showErrorAlert('Error while fetching invitation status'))
        console.log(e)
        setStatusLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function sendInvite(patient: R4.IPatient) {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    sendPatientInvite(patient.id!)
      .then((e: boolean) => {
        setLoading(false)
        if (e) {
          dispatch(showSuccessAlert('Invitation link sent successfully'))
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setResend(true)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          // dispatch(showErrorAlert('Error while sending invitation'))
        }
      })
      .catch((e) => {
        setResend(true)
        dispatch(showErrorAlert('Error while sending invitation'))
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    if (
      ipdActiveAppointmentSlice.activeIPDActionDetails &&
      ipdActiveAppointmentSlice.showResend === true
    ) {
      setResend(true)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchIPDAppointmentDetails(labOrderDetail.serviceRequest.id!))
    sessionStorage.setItem(
      CURRENT_VISIT_REFERENCE,
      `${labOrderDetail.serviceRequest.resourceType}/${labOrderDetail.serviceRequest.id}`
    )

    return () => {
      sessionStorage.removeItem(CURRENT_VISIT_REFERENCE)
    }
  }, [])

  useEffect(() => {
    if (ipdActiveAppointmentSlice.appointmentFetched) {
      if (ipdActiveAppointmentSlice.activeIPDActionDetails) {
        if (locId) {
          dispatch(requestForLocation(locId.split('/')[1]))
        }
        // setLabOrderDetail(ipdActiveAppointmentSlice.activeIPDActionDetails)
        setIpdDetailsData(ipdActiveAppointmentSlice.activeIPDActionDetails)
      }
    }
    return () => {}
  }, [ipdActiveAppointmentSlice.appointmentFetched])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
            // height: '100%',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            // style={{ position: 'absolute' }}
          >
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
              //   style={{ position: 'absolute' }}
            >
              <Box flexGrow={1}>
                <Box display='flex' alignItems='center' alignContent='center'>
                  <Box px={1}>
                    <ProfilePicWithAgeAndGender
                      patient={ipdDetailsDataFinal.patient}
                    />
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <PatientNameInIPDWidget
                      appointmentDetails={ipdDetailsDataFinal.patient}
                      serviceRequest={labOrderDetail.serviceRequest}
                      location={labOrderDetail.location}
                    />
                    {ipdDetailsData && ipdDetailsData.attendant && (
                      <Typography variant='subtitle2' color='initial'>
                        No Of Attendants : 1
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box display='flex' flexDirection='row'>
                {/*  {isIPDDischargeEditable(labOrderDetail) &&
                  !isPhysiotherapist() && (
                    <Tooltip title='Change Discharge Date'>
                      <Button
                        variant='text'
                        color='default'
                        onClick={() => {
                          setBillingHandler(true)
                        }}
                      >
                        Billing
                      </Button>e
                    </Tooltip>
                  )} */}

                {isDischarged(labOrderDetail.task!) &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails && (
                    <Box px={1.7} py={0.2}>
                      <PatientEvidenceResult
                        patientId={
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .patient.id ?? ''
                        }
                      />
                    </Box>
                  )}
                {!isOrgAdmin() &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails && (
                    <Box>
                      {(isDischarged(
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainTask!
                      ) ||
                        isDischargeInitiated(
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .mainTask!
                        )) && (
                        <DischargeReportButton
                          serviceReqId={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest.id ?? ''
                          }
                        />
                      )}
                    </Box>
                  )}

                {!isOrgAdmin() &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails && (
                    <IPDDietReportButton
                      serviceReqId={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainServiceRequest.id ?? ''
                      }
                    />
                  )}
                {!isOrgAdmin() &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails && (
                    <TreatmentReportOfIPDButton
                      serviceReqId={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainServiceRequest.id ?? ''
                      }
                    />
                  )}

                {/* {!isOrgAdmin() &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails && (
                    <CaseStudyPrintButton
                      ipdDetails={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainServiceRequest
                      }
                      serviceReqId={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainServiceRequest.id ?? ''
                      }
                      encounterId={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainEncounter
                          ? ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainEncounter.id ?? ''
                          : ''
                      }
                      patient={
                        ipdActiveAppointmentSlice.activeIPDActionDetails.patient
                      }
                      location={labOrderDetail.location}
                    />
                  )} */}
                {/* <PoliceVerificationIPDButton
                  serviceReqId={labOrderDetail.serviceRequest.id ?? ''}
                /> */}

                {isGre() &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails
                    ?.mainServiceRequest?.status !==
                    R4.AppointmentStatusKind._fulfilled && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      //   paddingTop={1}
                    >
                      {ipdActiveAppointmentSlice.activeIPDActionDetails &&
                        resend === false && (
                          <Tooltip
                            title={'Send Invite' ?? 'Send Invite'}
                            id='edit_1'
                          >
                            <IconButton
                              aria-label='btn_ord_reschedule'
                              color='primary'
                              id='edit_2'
                              disabled={
                                ipdActiveAppointmentSlice.activeIPDActionDetails ===
                                  undefined ||
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient === undefined ||
                                loading
                              }
                              onClick={() => {
                                sendInvite(
                                  ipdActiveAppointmentSlice.activeIPDActionDetails!
                                    .patient
                                )
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/Send_Invite.ico`}
                                alt='delete'
                                id='edit_3'
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                  )}
                {!isOrgAdmin() &&
                  ipdActiveAppointmentSlice.appointmentFetched &&
                  ipdActiveAppointmentSlice.activeIPDActionDetails && (
                    <Box py={1}>
                      {isIPDDischargeEditable(labOrderDetail) &&
                        !isPhysiotherapist() &&
                        !isDischarged(labOrderDetail.task!) &&
                        !isDischargeInitiated(labOrderDetail.task!) &&
                        //   isIPDDDateChangable(labOrderDetail) &&
                        labOrderDetail.appType.length === 0 && (
                          <Tooltip title='Change Discharge Date'>
                            <IconButton
                              aria-label='btn_ord_reschedule'
                              color='primary'
                              onClick={() => {
                                setShowRescheduleHandler(true)
                              }}
                            >
                              <Edit color='primary' />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                  )}

                <Tooltip title='Close'>
                  <IconButton
                    aria-label='btn_ord_detail_menu'
                    onClick={onCloseClicked}
                  >
                    <CloseOutlined color='primary' />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              style={{ width: '100%', height: '2px' }}
              color='primary'
            />
            {ipdActiveAppointmentSlice.fetchingAppointmentDetails && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {ipdActiveAppointmentSlice.appointmentFetched &&
              ipdActiveAppointmentSlice.activeIPDActionDetails && (
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    height='100%'
                    overflow='auto'
                  >
                    <SimpleBar
                      style={{
                        height: '100%',
                        width: '100%',
                        overflowX: 'hidden',
                        padding: '8px',
                      }}
                    >
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <PatientDetailsTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={ipdDetailsData}
                          roomData={roomBedSearchSlice.roomData}
                          location={labOrderDetail.location}
                        />
                        {getExtensionValueOfUrlPatient(
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .patient.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                        ) !== 'india' && (
                          <Box paddingTop={0.5}>
                            <Divider
                              orientation='horizontal'
                              variant='fullWidth'
                              flexItem
                              style={{ width: '100%', height: '2px' }}
                              color='primary'
                            />
                          </Box>
                        )}
                        {getExtensionValueOfUrlPatient(
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .patient.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                        ) !== 'india' && (
                          <Box>
                            <VisaDetailsTile
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              serviceId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id!
                              }
                            />
                          </Box>
                        )}
                      </Box>
                      {/* {labOrderDetail.appType.length === 0 && ( */}

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <SelfDeclaration
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                          }
                        />
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <TravelHistory
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                          }
                        />
                      </Box>

                      {(isDoctor() || isOrgAdmin() || isUnitAdmin()) && (
                        <Box>
                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <IPDChiefComplaint
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <MedicalConditions
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>
                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Procedures
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Allergies
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Medication
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          {isGenderFemale(
                            getGenderOfPatient(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient
                            )
                          ) && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <MensurationDetails
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}
                          {isGenderFemale(
                            getGenderOfPatient(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient
                            )
                          ) && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Obstrics
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Habits
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Addictions
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <FamilyMedicalConditions
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>
                          {(getValueCoDeFromValueCodeable(
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ).length === 0 ||
                            getValueCoDeFromValueCodeable(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ) === '439809005') && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Diet
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitview={true}
                              />
                            </Box>
                          )}
                          {(getValueCoDeFromValueCodeable(
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ).length === 0 ||
                            getValueCoDeFromValueCodeable(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ) === '439809005') && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Appetite
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}

                          {(getValueCoDeFromValueCodeable(
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ).length === 0 ||
                            getValueCoDeFromValueCodeable(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ) === '439809005') && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Micturition
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}
                          {(getValueCoDeFromValueCodeable(
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ).length === 0 ||
                            getValueCoDeFromValueCodeable(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .mainServiceRequest.extension ?? [],
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                            ) === '439809005') && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Bowels
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}
                        </Box>
                      )}

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <VitalsTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdDetails={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                        />
                      </Box>
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <LabResultsTile
                          fhirAppointmentDetails={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                          }
                        />
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <TreatmentTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdDetails={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                          isPT={false}
                        />
                      </Box>

                      {(isPhysiotherapist() ||
                        isOrgAdmin() ||
                        isUnitAdmin()) && (
                        <Box
                          borderColor='lightGrey'
                          width='100%'
                          display='flex'
                          flexDirection='column'
                        >
                          <PTtreatmentTile
                            patient={
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient
                            }
                            ipdDetails={
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .mainServiceRequest
                            }
                            isPT={true}
                          />
                        </Box>
                      )}

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <DietTile
                          serviceRequest={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                        />
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <SystemDiagnosisManagement
                          valueSetCatCode='conditionMaster'
                          encounterId={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainEncounter?.id!
                          }
                          patientId={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient.id ?? ''
                          }
                          categoryCoding={{
                            code: '148006',
                            display: 'Preliminary diagnosis',
                            system: 'http://snomed.info/sct',
                          }}
                          title='Provisional Diagnosis'
                          otherCoding={{
                            code: 'J95-J99',
                            display: 'Other',
                          }}
                          splitView={true}
                        />
                      </Box>
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {getValueCoDeFromValueCodeable(
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .mainServiceRequest.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                        ).length > 0 &&
                          getValueCoDeFromValueCodeable(
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                          ) === '1259939000' && (
                            <Box width='100%'>
                              <PlanSplitAyurveda
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                split={true}
                              />
                            </Box>
                          )}
                      </Box>
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AssessmentTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          serviceReq={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                        />

                        {ipdDetailsData &&
                          ipdDetailsData.mainEncounter &&
                          ipdDetailsData.mainEncounter.id && (
                            <DischargeDiagnosisOfPT
                              encounterId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainEncounter?.id!
                              }
                            />
                          )}
                        {/* <Box paddingTop={0.5}>
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            style={{ width: '100%', height: '1px' }}
                            color='primary'
                          />
                        </Box> */}
                      </Box>
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        paddingLeft={0.5}
                      >
                        <Feedback
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={ipdDetailsData}
                        />
                      </Box>
                    </SimpleBar>
                  </Box>
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>
      {ipdActiveAppointmentSlice.activeIPDActionDetails && (
        <IPDReScheduleHandler
          open={showRescheduleHandler}
          onClose={() => {
            setShowRescheduleHandler(false)
          }}
          orderDetail={
            ipdActiveAppointmentSlice.activeIPDActionDetails.mainServiceRequest
          }
          patientData={ipdActiveAppointmentSlice.activeIPDActionDetails.patient}
          onReScheduleConfirmed={(newSlot) => {
            dispatch(
              requestRescheduleOfIPD(labOrderDetail, newSlot, newSlot, date)
            )
            setShowRescheduleHandler(false)
            onCloseClicked()
          }}
        />
      )}

      {showBilling && (
        <ViewBillingOfIPD
          ipdDetails={labOrderDetail}
          onClose={() => {
            setBillingHandler(false)
          }}
          open={showBilling}
        />
      )}
    </Box>
  )
}
