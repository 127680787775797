import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  CancellationModel,
  ConvenienceFee,
  Settlement,
} from 'models/convinienceFeeDetails'
import { LabReferralDetails } from 'models/labReferralDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { paymentTypes } from 'utils/constants'
import { UnitTypesForSetting } from 'utils/constants/unit_types'

import {
  getAmountForConvenienceFee,
  getAmountForReferralFee,
  getAmountForReferralFeeInPercent,
  getPercentageGreaterThan,
  getPercentageLessThan,
  getServiceTypeData,
  getSettlement,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { logger } from 'utils/logger'
import { CancellationSearchStatus } from './cancellationSearchStatus'

const initialState: CancellationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const cancellationSearchSlice = createSlice({
  name: 'cancellationSearchSlice',
  initialState,
  reducers: {
    searchingCFee(state, action: PayloadAction<CancellationSearchStatus>) {},

    searchResults(state, action: PayloadAction<CancellationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<CancellationSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<CancellationSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },
    resetState(state, action: PayloadAction<CancellationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false

      state.referralList = undefined
    },
  },
})

export const resetCancellationSearch =
  (): AppThunk => async (dispatch: AppDispatch) => {
    // const state: SettlementSearchStatus = {
    //   errorReason: undefined,
    //   searchingAppointments: false,
    //   noResultsAvailable: false,
    //   question: undefined,
    //   prSelectedCoding: undefined,
    //   resultsAvailable: false,
    //   errorWhileSearchingAppointments: false,
    // }
    dispatch(cancellationSearchSlice.actions.resetState(initialState))
  }

export const searchingCancellationCharge =
  (): AppThunk => async (dispatch: any) => {
    const errorFetchingUnits: CancellationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      cancellationSearchSlice.actions.errorWhileSearching(errorFetchingUnits)
    )
    try {
      let searchParameter = {}
      searchParameter = {
        _count: 500,
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doGetResource(
        `/ChargeItemDefinition?charge-defn-code=cancellation-fee&status=active`,
        searchParameter
      )

      const orgResponse: R4.IBundle = response
      if (orgResponse.total) {
        if (orgResponse.total > 0) {
          if (orgResponse.entry) {
            const rFeeArray: R4.IChargeItemDefinition[] = orgResponse.entry.map(
              (item) => item.resource as R4.IChargeItemDefinition
            )

            if (rFeeArray.length > 0) {
              const data: CancellationModel = {
                id: rFeeArray[0].id,
                lessThan:
                  getPercentageLessThan(rFeeArray[0].useContext ?? []) * 100,
                greaterThan:
                  getPercentageGreaterThan(rFeeArray[0].useContext ?? []) * 100,
                chargeItem: rFeeArray[0],
              }
              const fetchUnitListResult: CancellationSearchStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,

                referralList: data,
              }
              dispatch(
                cancellationSearchSlice.actions.searchResults(
                  fetchUnitListResult
                )
              )
              return
            }
            const noSearchResults: CancellationSearchStatus = {
              error: false,
              noResultsAvailable: true,
              resultsAvailable: false,
              searching: false,
            }
            dispatch(
              cancellationSearchSlice.actions.noDataFoundForSearch(
                noSearchResults
              )
            )
          }
        }
      }
      const noSearchResults: CancellationSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        cancellationSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: CancellationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        cancellationSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export default cancellationSearchSlice.reducer
