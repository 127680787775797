import { Grid, Typography, Box } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getValueCoDeFromValueCodeable } from 'utils/fhirResourcesHelper'
import { LabAndRadiologyResults } from '../consultation/assessment/labReportsAndDiagnosits'
import { AyurvedaObjectiveIPD } from './objective/AyurvedaObjectiveIPD'
import { GeneralExaminationIPD } from './objective/generalExamination'
import { LabReportsIPD } from './objective/labReportsIPD'
import { LabResults } from './objective/labResults'
import { Vitals } from './objective/vitals'

interface ObjectivePartOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const ObjectivePartOfConsultation: React.FC<ObjectivePartOfConsultationProps> =
  ({ fhirAppointmentDetails }: ObjectivePartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Grid
        container
        spacing={1}
        direction='column'
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item>
          <Typography
            variant='h5'
            color='primary'
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {' '}
            {t('labelCommon:Objective')}
          </Typography>
        </Grid>
        <Grid item>
          {' '}
          <GeneralExaminationIPD
            fhirAppointmentDetails={fhirAppointmentDetails}
            status={
              fhirAppointmentDetails.mainTask.businessStatus!.coding
                ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'initiate-discharge' ||
                  fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'discharged' ||
                  fhirAppointmentDetails.mainServiceRequest.status! ===
                    'completed'
                  ? 'completed'
                  : ''
                : ''
            }
          />
        </Grid>
        <Grid item>
          {' '}
          <Vitals
            fhirAppointmentDetails={fhirAppointmentDetails}
            status={
              fhirAppointmentDetails.mainTask.businessStatus!.coding
                ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'initiate-discharge' ||
                  fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'discharged' ||
                  fhirAppointmentDetails.mainServiceRequest.status! ===
                    'completed'
                  ? 'completed'
                  : ''
                : ''
            }
          />
        </Grid>
        <Grid item>
          <LabResults
            fhirAppointmentDetails={fhirAppointmentDetails}
            status={
              fhirAppointmentDetails.mainTask.businessStatus!.coding
                ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'initiate-discharge' ||
                  fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'discharged' ||
                  fhirAppointmentDetails.mainServiceRequest.status! ===
                    'completed'
                  ? 'completed'
                  : ''
                : ''
            }
          />
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='100%'>
            <LabReportsIPD
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={true}
            />
          </Box>
        </Grid>

        {getValueCoDeFromValueCodeable(
          fhirAppointmentDetails.mainServiceRequest.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
        ).length > 0 &&
          getValueCoDeFromValueCodeable(
            fhirAppointmentDetails.mainServiceRequest.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
          ) === '1259939000' && (
            <Grid>
              <Box>
                <LabAndRadiologyResults
                  encounterId={fhirAppointmentDetails.mainEncounter?.id!}
                  patientId={fhirAppointmentDetails.patient?.id!}
                  allowAddition={true}
                  isAyurveda={true}
                />
              </Box>
            </Grid>
          )}

        {getValueCoDeFromValueCodeable(
          fhirAppointmentDetails.mainServiceRequest.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
        ).length > 0 &&
          getValueCoDeFromValueCodeable(
            fhirAppointmentDetails.mainServiceRequest.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
          ) === '1259939000' && (
            <Grid item xs={12}>
              {' '}
              <Box display='flex' width='100%'>
                <AyurvedaObjectiveIPD
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  isReadOnly={
                    fhirAppointmentDetails.mainTask.businessStatus!.coding
                      ? !!(
                          fhirAppointmentDetails.mainTask.businessStatus!
                            .coding[0].code === 'initiate-discharge' ||
                          fhirAppointmentDetails.mainTask.businessStatus!
                            .coding[0].code === 'discharged' ||
                          fhirAppointmentDetails.mainServiceRequest.status! ===
                            'completed'
                        )
                      : false
                  }
                />
              </Box>
            </Grid>
          )}
      </Grid>
    )
  }
