/* eslint-disable no-empty */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore } from 'services/fhirApiServices'
import {
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import {
  getDateWiseIPD,
  getExpandedServiceRequestFromBundleForIpd,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { IpdAppointmentSearchStatus } from './ipdAppointmentSearchStatus'

const initialState: IpdAppointmentSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  selectedStatuses: ['active'],
  selectedDate: isTherapist() ? new Date() : undefined,
}

let currentSelectedDate: Date | undefined

const ipdAppointmentSearchSlice = createSlice({
  name: 'ipdAppointmentSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IpdAppointmentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.selectedStatuses = action.payload.selectedStatuses
      state.selectedDate = action.payload.selectedDate
      state.dateWiseAppointments = action.payload.dateWiseAppointments
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
    },
    resetState(state, action: PayloadAction<IpdAppointmentSearchStatus>) {
      state.errorReason = undefined
      state.noResultsAvailable = undefined
      state.searchingAppointments = false
      state.resultsAvailable = false
      state.availableAppointments = []
      state.errorWhileSearchingOrders = false
      state.selectedStatuses = ['active']
      state.selectedDate = undefined
      state.dateWiseAppointments = undefined
      state.pageState = undefined
      state.recordsCount = undefined
    },
  },
})

export const requestForIpdAppointmentLists =
  (
    selectedDate?: Date,
    selectedStatus?: string[],
    pageState?: string,
    offSet?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate

    const state: IpdAppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],
      selectedDate,
    }
    dispatch(ipdAppointmentSearchSlice.actions.updatedStatus(state))
    try {
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const enRolClient: EnrolCient = new EnrolCient()
      const searchParams: any = {
        perPageCount: 30,
      }
      if (selectedDate) {
        const date = `${moment
          .utc(
            selectedDate || moment.utc(new Date()).subtract(30, 'days').toDate()
          )
          .local()
          .format('YYYY-MM-DD')}`
        searchParams.startDate = date
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }

      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await enRolClient.doGetResource(`ipd`, searchParams)

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(ipdAppointmentSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirClinicIpdDetails[] =
            getExpandedServiceRequestFromBundleForIpd(appointmentResponse)

          fhirAppointments.sort((a, b) => moment(b.start).diff(a.start))

          state.pageState = appointmentResponse.id
          state.recordsCount = appointmentResponse.total
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableAppointments = fhirAppointments
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(ipdAppointmentSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IpdAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],
          }
          dispatch(
            ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IpdAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
      }
      dispatch(
        ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const requestForDateWiseIpdAppointmentLists =
  (
    name: string,
    selectedDate?: Date,
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: DateWiseIPDAppointments[],
    existingDataRawData?: FhirClinicIpdDetails[],
    recordsCount?: number,
    scroll?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    // const draftData: string[] = ['draft']

    const state: IpdAppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],
      selectedDate,
      availableAppointments: existingDataRawData,
      dateWiseAppointments: existingDateWiseData,
      pageState,
      recordsCount,
    }
    dispatch(ipdAppointmentSearchSlice.actions.updatedStatus(state))
    try {
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const enRolClient: EnrolCient = new EnrolCient()
      const searchParams: any = {
        perPageCount: 10,
        unitId,
        type: 'ipd',
      }
      if (name.length > 0) {
        searchParams.patientName = name
      }
      if (selectedDate) {
        const date = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()}`
        searchParams.startDate = date
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }

      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      //   if (selectedStatus && selectedStatus.length > 0) {
      //     if (selectedStatus.includes('active') === true) {
      //       searchParams.status = [...selectedStatus, 'draft'].join(',')
      //     } else {
      //       searchParams.status = selectedStatus.join(',')
      //     }
      //   }

      //   if (selectedStatus && selectedStatus.length > 0) {
      //     if (selectedStatus.includes('active') === true) {
      //       searchParams.status = [...selectedStatus, 'draft'].join(',')
      //     } else {
      //       searchParams.status = selectedStatus.join(',')
      //     }
      //     // if (
      //     //   selectedStatus.includes('draft') === false &&
      //     //   selectedStatus.includes('completed') === false
      //     // ) {
      //     //   selectedStatus = selectedStatus.concat(draftData)
      //     // }
      //   }
      axios.CancelToken.source()
      if (cancelTokenStore.has('ipdMainSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'ipdMainSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('ipdMainSearchControlToken')
      }
      cancelTokenStore.set(
        'ipdMainSearchControlToken',
        axios.CancelToken.source()
      )

      const response: any = await enRolClient.doGetResource(
        `ipd`,
        searchParams,
        (cancelTokenStore.get('ipdMainSearchControlToken') as CancelTokenSource)
          .token
      )

      if (response !== undefined) {
        const appointmentResponse: R4.IBundle = response as R4.IBundle
        if (appointmentResponse.entry && appointmentResponse.entry.length > 0) {
          const fhirAppointments: FhirClinicIpdDetails[] =
            getExpandedServiceRequestFromBundleForIpd(appointmentResponse)
          fhirAppointments.sort((a, b) => moment(b.start).diff(a.start))
          fhirAppointments.sort((n1, n2) => n2.ipdNumber - n1.ipdNumber)
          console.log(fhirAppointments)

          state.pageState = appointmentResponse.id
          state.recordsCount = appointmentResponse.entry.length
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableAppointments = [
            ...(existingDataRawData ?? []),
            ...fhirAppointments,
          ]
          state.dateWiseAppointments = getDateWiseIPD(
            fhirAppointments,
            existingDateWiseData ?? []
          )

          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(ipdAppointmentSearchSlice.actions.updatedStatus(state))
        } else if (scroll) {
          const dataSearchDoctor: IpdAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: true,
            noResultsAvailable: false,
            selectedStatuses: selectedStatus ?? [],
            selectedDate,
            availableAppointments: existingDataRawData,
            dateWiseAppointments: existingDateWiseData,
            pageState,
            recordsCount,
          }
          dispatch(
            ipdAppointmentSearchSlice.actions.updatedStatus(dataSearchDoctor)
          )
        } else if (
          appointmentResponse.entry &&
          appointmentResponse.entry.length === 0
        ) {
          const errorSearchDoctor: IpdAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],
            selectedDate,
            availableAppointments: existingDataRawData,
            dateWiseAppointments: existingDateWiseData,
            pageState,
            recordsCount,
          }
          dispatch(
            ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        } else if (appointmentResponse.total === 0) {
          const errorSearchDoctor: IpdAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],
            selectedDate,
            availableAppointments: existingDataRawData,
            dateWiseAppointments: existingDateWiseData,
            pageState,
            recordsCount,
          }
          dispatch(
            ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } else {
        const errorSearchDoctor: IpdAppointmentSearchStatus = {
          searchingAppointments: false,
          errorWhileSearchingOrders: false,
          resultsAvailable: false,
          noResultsAvailable: true,
          selectedStatuses: selectedStatus ?? [],
          selectedDate,
          availableAppointments: existingDataRawData,
          dateWiseAppointments: existingDateWiseData,
          pageState,
          recordsCount,
        }
        dispatch(
          ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      /*  const errorSearchDoctor: IpdAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
        selectedDate,
        availableAppointments: existingDataRawData,
        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(
        ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      ) */
    }
  }

export const resetSearchForIPD =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: IpdAppointmentSearchStatus = {
      errorReason: undefined,
      noResultsAvailable: false,
      searchingAppointments: false,
      resultsAvailable: false,
      availableAppointments: [],
      errorWhileSearchingOrders: false,
      selectedStatuses: ['active'],
      selectedDate: undefined,
      dateWiseAppointments: undefined,
      pageState: undefined,
      recordsCount: undefined,
    }
    dispatch(ipdAppointmentSearchSlice.actions.resetState(state))
  }

export default ipdAppointmentSearchSlice.reducer
