import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { WelloAllergy } from 'models/WelloAllergy'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import { AllergyAddStatus } from './addAllergyStatusTypes'

const initialState: AllergyAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addAllergySlice = createSlice({
  name: 'addMedicationsSlice',
  initialState,
  reducers: {
    updateAddConditionStatus(state, action: PayloadAction<AllergyAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetAllergiesDetails(state, action: PayloadAction<AllergyAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.allergy = initialState.allergy
    },
  },
})

export const addAllergiesDetails =
  (
    patientDetails: R4.IPatient,
    addedAllergiesArray: WelloAllergy[],
    appointmentId?: string,
    encounterId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AllergyAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(addAllergySlice.actions.updateAddConditionStatus(addingState))
    const practitionerDetail = getCurrentUserPractitionerDetails()
    const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
    try {
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [],
      }
      for (let i = 0; i < addedAllergiesArray.length; i++) {
        if (addedAllergiesArray[i].otherData) {
          const allergyCode = {
            code: 'C2590123',
            display: addedAllergiesArray[i].otherData!,
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-allergy-cs',
          }
        }

        const allergyDetails: R4.IAllergyIntolerance = {
          resourceType: 'AllergyIntolerance',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndAllergyIntoleranceBase',
            ],
          },
          patient: {
            display: getNameOfPatient(patientDetails),
            id: patientDetails.id,
            reference: `${patientDetails.resourceType}/${patientDetails.id}`,
            type: patientDetails.resourceType,
          },
          type: R4.AllergyIntoleranceTypeKind._allergy,
          code: {
            coding: addedAllergiesArray[i].otherData
              ? [
                  {
                    code: 'C2590123',
                    display: addedAllergiesArray[i].otherData!,
                    system:
                      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-allergy-cs',
                  },
                ]
              : [addedAllergiesArray[i].condition!],
            text: addedAllergiesArray[i].otherData
              ? addedAllergiesArray[i].otherData
              : addedAllergiesArray[i].condition!.display ?? '',
          },
          clinicalStatus: {
            coding: [
              {
                code: addedAllergiesArray[i].isActive ? 'active' : 'inactive',
                system:
                  'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
                display: addedAllergiesArray[i].isActive
                  ? 'Active'
                  : 'Inactive',
              },
            ],
          },
          // onsetDateTime: onSetDate.toISOString(),
          recordedDate: new Date().toISOString(),
          recorder: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },

          category: [
            addedAllergiesArray[i].selectedSeverity ??
              R4.AllergyIntoleranceCategoryKind._food,
          ],
          verificationStatus: {
            text: 'Unconfirmed',
            coding: [
              {
                code: 'unconfirmed',
                display: 'Unconfirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
              },
            ],
          },
        }
        if (appointmentId) {
          allergyDetails.extension = [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
              valueReference: {
                reference: `Appointment/${appointmentId}`,
              },
            },
          ]
        }

        if (
          addedAllergiesArray[i].selectedType &&
          addedAllergiesArray[i].selectedType!.length > 0
        ) {
          if (addedAllergiesArray[i].selectedType === 'days') {
            if (
              addedAllergiesArray[i].days &&
              addedAllergiesArray[i].days! > 0
            ) {
              allergyDetails.onsetAge = {
                system: 'http://unitsofmeasure.org',
                unit: 'd',
                code: 'd',
                value: addedAllergiesArray[i].days!,
              }
            }
          } else if (addedAllergiesArray[i].selectedType === 'months') {
            allergyDetails.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'mo',
              code: 'mo',
              value: addedAllergiesArray[i].month!,
            }
          } else if (addedAllergiesArray[i].selectedType === 'weeks') {
            allergyDetails.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'wk',
              code: 'wk',
              value: addedAllergiesArray[i].week!,
            }
          } else if (addedAllergiesArray[i].selectedType === 'years') {
            allergyDetails.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'a',
              code: 'a',
              value: addedAllergiesArray[i].year!,
            }
          } else if (addedAllergiesArray[i].selectedType === 'custom') {
            if (addedAllergiesArray[i].selectedDate)
              allergyDetails.onsetDateTime =
                addedAllergiesArray[i].selectedDate!.toISOString()
          }
        }
        if (
          addedAllergiesArray[i].notes &&
          addedAllergiesArray[i].notes!.length > 0
        ) {
          allergyDetails.note = [
            {
              authorReference: {
                reference: `${practitionerDetail.resourceType}/${practitionerDetail.id}`,
              },
              text: addedAllergiesArray[i].notes!,
              time: new Date().toISOString(),
            },
          ]
        }
        if (encounterId && encounterId.length > 0) {
          allergyDetails.encounter = {
            reference: `Encounter/${encounterId}`,
            type: 'Encounter',
          }
        }
        const entry: R4.IBundle_Entry = {
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: allergyDetails.resourceType,
          },
          resource: allergyDetails,
        }
        requestBundle.entry?.push(entry)
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        requestBundle
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Allergy added successfully'))
        dispatch(addAllergySlice.actions.updateAddConditionStatus(addingState))
      } else {
        const errorCreatePersonState: AllergyAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while allergy details',
        }
        dispatch(
          addAllergySlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AllergyAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addAllergySlice.actions.updateAddConditionStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddAllergiesState = () => (dispatch: AppDispatch) => {
  dispatch(addAllergySlice.actions.resetAllergiesDetails(initialState))
}

export default addAllergySlice.reducer
