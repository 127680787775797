import excel from 'xlsx'
import { useEffect, useState } from 'react'
import { Test } from 'models/Test'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { PartnerLabTest } from 'models/partnerLabTestPrice'
import { Package } from 'models/package'
import {
  addExtensionToExtensionsOfUrl,
  getCodeOfSystemFromCodableConcept,
  getCodiong,
  getExtensionValueOfUrl,
  getValueCodableFromExtension,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import { randomInt } from 'fp-ts/lib/Random'
import { FhirAddTherapyListWithChargeDef } from 'models/fhirAddTherapyListWithChargeDef'
import { FhirTherapyListWithChargeDef } from 'models/fhirTherapyListWithChargeDef'
import { SubModel } from 'models/subModel'
import { SubstanceDataUpdate } from 'models/substanceDataUpdate'
import { DietDataModel } from 'models/dietData'
import { TherapyDataUpdate } from 'models/therapyDataUpdate'
import { PhysiotherapyDataUpdate } from 'models/tables/physiotherapyDataUpdate'
import { FhirPhysiotherapyListWithChargeDef } from 'models/tables/fhirPhysiotherapyListWithChargeDef'
import {
  ICodeableConcept,
  IObservationDefinition_QualifiedInterval,
  ObservationDefinitionPermittedDataTypeKind,
  ObservationDefinition_QualifiedIntervalCategoryKind,
  ObservationDefinition_QualifiedIntervalGenderKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { EditCatalogueDetailsModel } from 'models/editCatalogueDataModel'
import { LabObsData, ObsDataTableResource } from 'models/labObsData'
import { getDietType } from 'utils/fhirResoureHelpers/catalogHelper'

const chargeRef: R4.IReference = {}
const groupRef: R4.IReference = {}

const uuID: string[] = []

export function validateExcel(file: any): string[] {
  const errors: string[] = []
  let jsonObj: any
  errors.push('1')
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString

  reader.onload = (e) => {
    const bstr = (e.target as FileReader).result

    const wb = excel.read(bstr, {
      type: rABS ? 'binary' : 'array',
      bookVBA: true,
    })
    /* Get first worksheet */
    const wsName = wb.SheetNames
    for (let i = 0; i < wsName.length; i++) {
      const ws = wb.Sheets[wsName[i]]
      const data = excel.utils.sheet_to_json(ws)
      if (wsName[i] === 'Actual_Lab_Data') {
        jsonObj = eval(JSON.stringify(data))
        let k = 2
        for (let j = 0; j < jsonObj.length; j++) {
          if (jsonObj[j].Lab_Test_Name === undefined) {
            errors.push(`Test Name is required at column ${j} row ${k}`)
            // setErrorCode(errors)
          }
          k += 1
        }
      }
    }
    return errors
  }
  if (rABS) {
    reader.readAsBinaryString(file)
  } else {
    reader.readAsArrayBuffer(file)
  }

  return errors
}

export function displayData(valueSet: any): string {
  const value: string[] = []

  for (let i = 0; i < valueSet.length; i++) {
    value.push(valueSet[i].display)
  }

  return value.join(', ')
}

export function getUUID(valueSet: ObsDataTableResource[]) {
  for (let i = 0; i < valueSet.length; i++) {
    const randomNum: number = getRandomInt(999999)
    const conditionFullUrl = `urn:uuid:ObservationDefinition${randomNum.toString()}`
    uuID.push(conditionFullUrl)
  }
}

const getObservationObject = (valueSet: ObsDataTableResource, i: number) => {
  const iRequest: R4.IBundle_Request = {}

  const extArray: R4.IExtension[] = []

  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = `ObservationDefinition`

  const iObservationObj: R4.IObservationDefinition = {
    resourceType: 'ObservationDefinition',
    category: [
      {
        coding: [
          {
            code: 'laboratory',
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
          },
        ],
      },
    ],
    qualifiedInterval: [],
    permittedDataType: [getDataType(valueSet.permittedDataType)],
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: valueSet.obsCode,
          display: valueSet.obsName,
        },
      ],
    },
    preferredReportName: valueSet.obsName,
  }

  if (valueSet.interpretation.length > 0) {
    const interpretationExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext',
      valueString: valueSet.interpretation,
    }
    extArray.push(interpretationExtension)
  }
  if (valueSet.comment.length > 0) {
    const valueAnnotationData: R4.IAnnotation = {
      text: valueSet.comment,
    }
    const commentExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext',
      valueAnnotation: valueAnnotationData,
    }
    extArray.push(commentExtension)
  }
  if (valueSet.notes.length > 0) {
    const valueAnnotationDataForNotes: R4.IAnnotation = {
      text: valueSet.notes,
    }
    const notesExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext',
      valueAnnotation: valueAnnotationDataForNotes,
    }
    extArray.push(notesExtension)
  }
  if (extArray.length > 0) iObservationObj.extension = extArray

  const intervalData: IObservationDefinition_QualifiedInterval[] = []

  if (valueSet.highCode1 || valueSet.lowCode1) {
    const highData: IObservationDefinition_QualifiedInterval = {
      category: ObservationDefinition_QualifiedIntervalCategoryKind._reference,
      context: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
            code: 'normal',
            display: 'Normal Range',
          },
        ],
      },
    }
    if (
      valueSet.highCode1 &&
      valueSet.highCode1.length > 0 &&
      valueSet.lowCode1 &&
      valueSet.lowCode1.length > 0
    ) {
      const highRange = {
        low: {
          value: valueSet.low1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode1,
        },
        high: {
          value: valueSet.high1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode1,
        },
      }
      highData.range = highRange
    }

    if (
      valueSet.highCode1 &&
      valueSet.highCode1.length > 0 &&
      (valueSet.lowCode1 === undefined || valueSet.lowCode1!.length === 0)
    ) {
      const highRange1 = {
        high: {
          value: valueSet.high1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode1,
        },
      }
      highData.range = highRange1
    }

    if (
      valueSet.lowCode1 &&
      valueSet.lowCode1.length > 0 &&
      (valueSet.highCode1 === undefined || valueSet.highCode1!.length === 0)
    ) {
      const lowRange1 = {
        low: {
          value: valueSet.low1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode1,
        },
      }
      highData.range = lowRange1
    }

    if (
      valueSet.gender1 &&
      (valueSet.gender1 === 'male' || valueSet.gender1 === 'female')
    ) {
      highData.gender = getGenderData(valueSet.gender1)
    }

    if (
      valueSet.ageLow1 &&
      valueSet.ageLow1 >= 0 &&
      valueSet.ageHigh1 &&
      valueSet.ageHigh1
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
        high: {
          value: valueSet.ageHigh1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (
      valueSet.ageLow1 &&
      valueSet.ageLow1 >= 0 &&
      valueSet.ageHigh1 === undefined
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (
      valueSet.ageHigh1 &&
      valueSet.ageHigh1 >= 0 &&
      valueSet.ageLow1 === undefined
    ) {
      const highAgeRange = {
        high: {
          value: valueSet.ageHigh1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    intervalData.push(highData)
  }

  if (valueSet.highCode2 || valueSet.lowCode2) {
    const highData: IObservationDefinition_QualifiedInterval = {
      category: ObservationDefinition_QualifiedIntervalCategoryKind._reference,
      context: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
            code: 'normal',
            display: 'Normal Range',
          },
        ],
      },
    }
    if (
      valueSet.highCode2 &&
      valueSet.highCode2.length > 0 &&
      valueSet.lowCode2 &&
      valueSet.lowCode2.length > 0
    ) {
      const highRange = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode2,
        },
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode2,
        },
      }
      highData.range = highRange
    }

    if (
      valueSet.highCode2 &&
      (valueSet.lowCode2 === undefined || valueSet.lowCode2?.length === 0)
    ) {
      const highRange1 = {
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode2,
        },
      }
      highData.range = highRange1
    }

    if (
      valueSet.lowCode2 &&
      (valueSet.highCode2 === undefined || valueSet.highCode2?.length === 0)
    ) {
      const lowRange2 = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode2,
        },
      }
      highData.range = lowRange2
    }
    // if (valueSet.highCode2) {
    //   const highRange1 = {
    //     high: {
    //       value: valueSet.high2,
    //       system: 'http://unitsofmeasure.org',
    //       code: valueSet.highCode2,
    //     },
    //   }
    //   highData.range = highRange1
    // }
    if (
      valueSet.gender2 &&
      (valueSet.gender2 === 'male' || valueSet.gender2 === 'female')
    ) {
      highData.gender = getGenderData(valueSet.gender2)
    }

    if (
      valueSet.ageLow2 &&
      valueSet.ageLow2 >= 0 &&
      valueSet.ageHigh2 &&
      valueSet.ageHigh2
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (
      valueSet.ageLow2 &&
      valueSet.ageLow2 >= 0 &&
      valueSet.ageHigh2 === undefined
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (
      valueSet.ageHigh2 &&
      valueSet.ageHigh2 >= 0 &&
      valueSet.ageLow2 === undefined
    ) {
      const highAgeRange = {
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    intervalData.push(highData)
  } else if (
    (valueSet.ageLow2 && valueSet.ageLow2 >= 0) ||
    (valueSet.ageHigh2 && valueSet.ageHigh2 >= 0) ||
    (valueSet.low2 && valueSet.low2 >= 0) ||
    (valueSet.high2 && valueSet.high2 >= 0)
  ) {
    const highData: IObservationDefinition_QualifiedInterval = {
      category: ObservationDefinition_QualifiedIntervalCategoryKind._reference,
      context: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
            code: 'normal',
            display: 'Normal Range',
          },
        ],
      },
    }

    if (
      valueSet.low2 &&
      valueSet.low2 >= 0 &&
      valueSet.high2 &&
      valueSet.high2 >= 0
    ) {
      const highRange = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.unitCode,
        },
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.unitCode,
        },
      }
      highData.range = highRange
    }

    if (valueSet.high2 && valueSet.high2 >= 0 && valueSet.low2 === undefined) {
      const highRange1 = {
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.unitCode,
        },
      }
      highData.range = highRange1
    }

    if (valueSet.low2 && valueSet.low2 >= 0 && valueSet.high2 === undefined) {
      const lowRange2 = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode2,
        },
      }
      highData.range = lowRange2
    }

    if (
      valueSet.gender2 &&
      (valueSet.gender2 === 'male' || valueSet.gender2 === 'female')
    ) {
      highData.gender = getGenderData(valueSet.gender2)
    }

    if (
      valueSet.ageLow2 &&
      valueSet.ageLow2 >= 0 &&
      valueSet.ageHigh2 &&
      valueSet.ageHigh2 >= 0
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    if (valueSet.ageLow2 && valueSet.ageLow2 >= 0 && valueSet.ageHigh2 === 0) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (valueSet.ageHigh2 && valueSet.ageHigh2 >= 0 && valueSet.ageLow2 === 0) {
      const highAgeRange = {
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    intervalData.push(highData)
  }

  if (valueSet.unitCode) {
    iObservationObj.quantitativeDetails = {
      unit: {
        coding: [
          {
            system: 'http://unitsofmeasure.org',
            code: valueSet.unitCode,
          },
        ],
      },
      decimalPrecision: valueSet.decimalPrecision,
    }
  }
  iObservationObj.qualifiedInterval = intervalData

  //   const refObj: R4.IReference = {
  //     reference: `ObservationDefinition/${conditionFullUrl}`,

  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: uuID[i],
    request: iRequest,
    resource: iObservationObj,
  }

  return bundleEntry
}

export function getDataType(
  systemUrl: string
): ObservationDefinitionPermittedDataTypeKind {
  switch (systemUrl) {
    case 'Quantity':
      return ObservationDefinitionPermittedDataTypeKind._quantity
    case 'String':
      return ObservationDefinitionPermittedDataTypeKind._string

    default:
      return ObservationDefinitionPermittedDataTypeKind._string
  }
}

export function getGenderData(
  systemUrl: string
): ObservationDefinition_QualifiedIntervalGenderKind {
  switch (systemUrl) {
    case 'male':
      return ObservationDefinition_QualifiedIntervalGenderKind._male
    case 'female':
      return ObservationDefinition_QualifiedIntervalGenderKind._female

    default:
      return ObservationDefinition_QualifiedIntervalGenderKind._male
  }
}

const getActivityObj = (
  activityDef: R4.IActivityDefinition,
  finalDataSet: ObsDataTableResource[],
  test: Test
) => {
  const oldActivityDefVersion: string = `W/${JSON.stringify(
    activityDef.meta?.versionId ?? ' '
  )}`
  const iRequest: R4.IBundle_Request = {}
  iRequest.ifMatch = oldActivityDefVersion
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'ActivityDefinition'
  const randomNum: number = getRandomInt(999999)
  const obsRef: R4.IReference[] = []
  for (let i = 0; i < finalDataSet.length; i++) {
    const conditionFullUrl = `urn:uuid:${randomNum.toString()}${i}`
    const refObj: R4.IReference = {
      reference: `ObservationDefinition/${uuID[i]}`,
      type: 'ObservationDefinition',
    }
    obsRef.push(refObj)
  }

  const iActivityObj: R4.IActivityDefinition = {
    resourceType: 'ActivityDefinition',
    observationResultRequirement: obsRef,
    priority: activityDef.priority,
    publisher: activityDef.publisher,
    purpose: activityDef.purpose,
    status: activityDef.status,
    code: activityDef.code,
    kind: activityDef.kind,
    title: test.reportDisplayName,
    name: test.reportDisplayName,
  }
  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:ActivityDefinition`,
    request: iRequest,
    resource: iActivityObj,
  }
  return bundleEntry
}

const getChargeObj = (tests: Test) => {
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'ChargeItemDefinition'
  const urlName = tests.testName.replaceAll(' ', '')
  const iMoney: R4.IMoney = {}
  iMoney.value = tests.testPrice
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    description: tests.description,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:ChargeItemDefinition`,
    request: iRequest,
    resource: iChargeItemObj,
  }
  chargeRef.reference = `urn:uuid:ChargeItemDefinition`
  return bundleEntry
}

const getPlanDefObject = (
  tests: Test,
  locationId: string,
  planDefname?: string
) => {
  const nameData = planDefname ?? tests.testName

  const type =
    tests.planDef && tests.planDef.type && tests.planDef.type!.coding![0].code
  const unitDetails = getCurrentUserUnitDetails()
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  const urlName = tests.reportDisplayName.replaceAll(' ', '')
  iRequest.url = `PlanDefinition`
  const iPlanDef: R4.IPlanDefinition = {
    resourceType: 'PlanDefinition',
    description: tests.description,
    publisher: 'Wellopathy Inc.',
    language: 'en-US',
    identifier: [
      {
        system: 'http://loinc.org',
        value: `${tests.code[0]}`,
      },
    ],
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PlanDefinitionManagingOrganization',
        valueReference: {
          id: unitDetails.id,
          reference: `Organization/${unitDetails.id}`,
          type: 'Organization',
          display: `${unitDetails.name}`,
        },
      },
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: chargeRef.reference,
        },
      },
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary',
        valueString: tests.presetInstructions,
      },
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ScheduleSummary',
        valueString: tests.scheduleSummary,
      },
    ],
    // action: [
    //   {
    //     action: [
    //       {
    //         definitionCanonical: `http://wellopathy.com/ActivityDefinition/${urlName}`,
    //       },
    //     ],
    //   },
    // ],
    jurisdiction: [
      {
        coding: [
          {
            code: 'IN',
            display: 'INDIA',
            system: 'urn:iso:std:iso:3166',
          },
        ],
      },
    ],
    name: tests.reportDisplayName,
    status: R4.PlanDefinitionStatusKind._active,
    title: nameData,
    type: {
      coding: [
        {
          code: type ?? '',
          display:
            'unitary\u00a0measurement\u00a0performed\u00a0on\u00a0an\u00a0in\u00a0vitro\u00a0biologic\u00a0specimen',
          system:
            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
        },
      ],
    },
  }

  if (iPlanDef.extension) {
    if (locationId.length > 0) {
      iPlanDef.extension.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PartnerLabRef',
        valueReference: {
          reference: `Location/${locationId}`,
        },
      })
    }
  }

  const codeData: string[] = tests.code

  const planAction: R4.IPlanDefinition_Action[] = []
  for (let i = 0; i < codeData.length; i++) {
    const actionData: R4.IPlanDefinition_Action = {
      definitionCanonical: `http://wellopathy.com/ActivityDefinition/${codeData[i]}`,
    }
    planAction.push(actionData)
  }
  iPlanDef.action = [
    {
      action: planAction,
    },
  ]

  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:PlanDefinition`,
    request: iRequest,
    resource: iPlanDef,
  }
  return bundleEntry
}

export function getCatalogBundle(
  tests: Test,
  valueSet: any,
  locationObj: string,
  finalDataSet: ObsDataTableResource[],
  planDefname?: string,
  obsFinalData?: LabObsData
): R4.IBundle {
  const bundleArray: any = []
  getUUID(finalDataSet)
  if (obsFinalData) {
    for (let i = 0; i < finalDataSet.length; i++) {
      const observationDefinition = getObservationObject(finalDataSet[i], i)
      bundleArray.push(observationDefinition)
    }

    const activityDefinition = getActivityObj(
      obsFinalData.activityDef,
      finalDataSet,
      tests
    )
    bundleArray.push(activityDefinition)
  }

  const chargeDef = getChargeObj(tests)
  bundleArray.push(chargeDef)
  const planDef = getPlanDefObject(tests, locationObj, planDefname)
  bundleArray.push(planDef)
  const catalog: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return catalog
}

export function getCatalogBundleUpdate(
  tests: Test,
  locationId: string,
  partnerLabTest?: PartnerLabTest
): R4.IBundle | undefined {
  const bundleArray: any = []
  if (partnerLabTest) {
    const oldUpdatedPlanDef: R4.IPlanDefinition = {
      ...partnerLabTest.planDefinition,
    }
    const oldUpdatedPlanDefMatchString: string = `W/${JSON.stringify(
      oldUpdatedPlanDef.meta?.versionId ?? ' '
    )}`
    oldUpdatedPlanDef.description = tests.description

    const oldChargeDef: R4.IChargeItemDefinition = {
      ...partnerLabTest.chargeItemDefinition,
    }
    const unitDetails = getCurrentUserUnitReference()!
    oldUpdatedPlanDef.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PlanDefinitionManagingOrganization',
        valueReference: {
          id: unitDetails.id,
          reference: `Organization/${unitDetails.id}`,
          type: 'Organization',
          display: `${unitDetails.display}`,
        },
      },
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: `ChargeItemDefinition/${oldChargeDef.id}`,
        },
      },

      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary',
        valueString: tests.presetInstructions,
      },
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ScheduleSummary',
        valueString: tests.scheduleSummary,
      },
    ]
    if (locationId.length > 0) {
      oldUpdatedPlanDef.extension.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PartnerLabRef',
        valueReference: {
          reference: `Location/${locationId}`,
        },
      })
    }
    const oldChargeDefMatchString: string = `W/${JSON.stringify(
      oldChargeDef.meta?.versionId ?? ' '
    )}`
    oldChargeDef.propertyGroup = [
      {
        priceComponent: [
          {
            type: 'base',
            amount: {
              value: tests.testPrice,
              currency: 'INR',
            },
          },
          {
            type: 'deduction',
            factor: 0.2,
          },
        ],
      },
    ]
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          request: {
            ifMatch: oldUpdatedPlanDefMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          },
          resource: oldUpdatedPlanDef,
        },

        {
          fullUrl: `${oldChargeDef.resourceType}/${oldChargeDef.id}`,
          request: {
            ifMatch: oldChargeDefMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldChargeDef.resourceType}/${oldChargeDef.id}`,
          },
          resource: oldChargeDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getPackageBundleUpdate(
  packageData: Package
): R4.IBundle | undefined {
  if (packageData.package) {
    const oldUpdatedPlanDef: R4.IPlanDefinition = {
      ...packageData.package,
    }
    const oldUpdatedPlanDefMatchString: string = `W/${JSON.stringify(
      oldUpdatedPlanDef.meta?.versionId ?? ' '
    )}`

    const planDefAction: R4.IPlanDefinition_Action[] = []
    for (let i = 0; i < packageData.listOfTest.length; i++) {
      const action = packageData.listOfTest[i].planDefinition.action ?? []
      const coding = packageData.listOfTest[i].planDefinition.type
      let type = ''
      if (coding) {
        const codeData = getCodiong(coding)
        if (codeData) type = codeData.code ?? ''
      }
      if (type === 'panel') {
        for (let m = 0; m < action.length; m++) {
          const actionData: R4.IPlanDefinition_Action = {
            definitionCanonical: packageData.listOfTest[i].planDefinition.title,
            subjectReference: {
              reference: `PlanDefinition/${packageData.listOfTest[i].planDefinition.id}`,
            },
          }
          planDefAction.push(actionData)
        }
      }
      if (type === 'test') {
        for (let j = 0; j < action.length; j++) {
          const anotherAction = action[j].action ?? []
          for (let k = 0; k < anotherAction.length; k++) {
            const url = anotherAction[k].definitionCanonical ?? ''

            if (url.length > 0) {
              const actionData: R4.IPlanDefinition_Action = {
                definitionCanonical: url,
                subjectReference: {
                  reference: `PlanDefinition/${packageData.listOfTest[i].planDefinition.id}`,
                },
              }
              planDefAction.push(actionData)
            }
          }
        }
      }
    }

    oldUpdatedPlanDef.action = [
      {
        action: planDefAction,
      },
    ]
    oldUpdatedPlanDef.title = packageData.packageName

    const chargeDef = getChargeObjForPackageUpdate(packageData)
    const groupObj = getUpdatedGroupForPackage(packageData)

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          request: {
            ifNoneExist: oldUpdatedPlanDefMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          },
          resource: oldUpdatedPlanDef,
        },

        {
          fullUrl: `${groupObj.resourceType}/${groupObj.id}`,
          request: {
            ifMatch: packageData.groupVersion,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${groupObj.resourceType}/${groupObj.id}`,
          },
          resource: groupObj,
        },

        {
          fullUrl: `${chargeDef.resourceType}/${chargeDef.id}`,
          request: {
            ifMatch: packageData.chargeItemVersion,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeDef.resourceType}/${chargeDef.id}`,
          },
          resource: chargeDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getTherapyBundleUpdate(
  therapyData: TherapyDataUpdate
): R4.IBundle | undefined {
  if (therapyData.therapy) {
    const oldUpdatedHealthcareSer: R4.IHealthcareService = {
      ...therapyData.therapy,
    }
    const oldUpdatedHealthcareSerMatchString: string = `W/${JSON.stringify(
      oldUpdatedHealthcareSer.meta?.versionId ?? ' '
    )}`

    oldUpdatedHealthcareSer.name = therapyData.name

    const selfExt: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration',
      valueDuration: {
        value: therapyData.duration,
        unit: 'minutes',
        system: 'http://unitsofmeasure.org',
        code: 'min',
      },
    }

    const removeDurationExtension = removeExtensionFromExtensions(
      oldUpdatedHealthcareSer.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
    )

    oldUpdatedHealthcareSer.extension = addExtensionToExtensionsOfUrl(
      removeDurationExtension,
      selfExt
    )

    // const codeBilling: R4.ICoding[]|undefined = therapyData.billableCode

    const key = [
      {
        coding: [
          {
            system: 'http://hl7.org/fhir/chargeitem-status',
            code: therapyData.billableCode?.code,
            display: therapyData.billableCode?.display,
          },
        ],
        text: therapyData.billableCode?.display,
      },
    ]

    const billCharacteristic: ICodeableConcept = {}
    billCharacteristic.coding = [therapyData.billableCode ?? {}]
    billCharacteristic.text = therapyData.billableCode?.display

    oldUpdatedHealthcareSer.characteristic = [billCharacteristic]

    //  const removedTherapyCategoryCode = removeCategoryCode(oldUpdatedHealthcareSer.category, )

    //  const oldUpdatedHealthcareSer.category = addCategoryCode(removedTherapyCategoryCode, therapyData.category )

    const chargeDef = getChargeObjForTherapyUpdate(therapyData)

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedHealthcareSer.resourceType}/${oldUpdatedHealthcareSer.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareSerMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedHealthcareSer.resourceType}/${oldUpdatedHealthcareSer.id}`,
          },
          resource: oldUpdatedHealthcareSer,
        },
        {
          fullUrl: `${chargeDef.resourceType}/${chargeDef.id}`,
          request: {
            //   ifMatch: therapyData.chargeItemVersion,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeDef.resourceType}/${chargeDef.id}`,
          },
          resource: chargeDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getPhysiotherapyBundleUpdate(
  physiotherapyData: PhysiotherapyDataUpdate
): R4.IBundle | undefined {
  if (physiotherapyData.therapy) {
    const oldUpdatedHealthcareSer: R4.IHealthcareService = {
      ...physiotherapyData.therapy,
    }
    const oldUpdatedHealthcareSerMatchString: string = `W/${JSON.stringify(
      oldUpdatedHealthcareSer.meta?.versionId ?? ' '
    )}`

    oldUpdatedHealthcareSer.name = physiotherapyData.name

    //  const removedTherapyCategoryCode = removeCategoryCode(oldUpdatedHealthcareSer.category, )

    //  const oldUpdatedHealthcareSer.category = addCategoryCode(removedTherapyCategoryCode, therapyData.category )

    const chargeDef = getChargeObjForPhysiotherapyUpdate(physiotherapyData)

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedHealthcareSer.resourceType}/${oldUpdatedHealthcareSer.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareSerMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedHealthcareSer.resourceType}/${oldUpdatedHealthcareSer.id}`,
          },
          resource: oldUpdatedHealthcareSer,
        },
        {
          fullUrl: `${chargeDef.resourceType}/${chargeDef.id}`,
          request: {
            //   ifMatch: therapyData.chargeItemVersion,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeDef.resourceType}/${chargeDef.id}`,
          },
          resource: chargeDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getSubstanceBundleUpdate(
  substanceData: SubstanceDataUpdate
): R4.IBundle | undefined {
  if (substanceData.substance) {
    const oldUpdatedSubst: R4.ISubstance = {
      ...substanceData.substance,
    }
    const oldUpdatedSubstMatchString: string = `W/${JSON.stringify(
      oldUpdatedSubst.meta?.versionId ?? ' '
    )}`

    oldUpdatedSubst.description = substanceData.name

    const unitCodingArray: R4.ICoding[] = []
    unitCodingArray.push(substanceData?.unitCoding ?? {})

    const selfExt: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-billing-unit-ex',
      valueCodeableConcept: {
        coding: unitCodingArray,
        text: substanceData.unitCoding?.display,
      },
    }

    const unitRemoved = removeExtensionFromExtensions(
      oldUpdatedSubst.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-billing-unit-ex'
    )

    oldUpdatedSubst.extension = addExtensionToExtensionsOfUrl(
      unitRemoved,
      selfExt
    )

    const chargeDef = getChargeObjForSubstanceUpdate(substanceData)

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedSubst.resourceType}/${oldUpdatedSubst.id}`,
          request: {
            ifNoneExist: oldUpdatedSubstMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedSubst.resourceType}/${oldUpdatedSubst.id}`,
          },
          resource: oldUpdatedSubst,
        },
        {
          fullUrl: `${chargeDef.resourceType}/${chargeDef.id}`,
          request: {
            //   ifMatch: therapyData.chargeItemVersion,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeDef.resourceType}/${chargeDef.id}`,
          },
          resource: chargeDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getDietBundleUpdate(
  dietData: DietDataModel
): R4.IBundle | undefined {
  if (dietData.catalogue) {
    const oldUpdatedCatalogEntr: R4.ICatalogEntry = {
      ...dietData.catalogue,
    }
    const oldUpdatedCatalogEntrMatchString: string = `W/${JSON.stringify(
      oldUpdatedCatalogEntr.meta?.versionId ?? ' '
    )}`

    const dietCoding = getValueCodableFromExtension(
      dietData.catalogue.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
    )
    const dietCode = getCodeOfSystemFromCodableConcept(
      dietCoding ?? {},
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-type-cs'
    )

    const selfExt: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext',
      valueCodeableConcept: {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-type-cs',
            code: dietCode?.code,
            display: dietData.name,
          },
        ],
        text: dietData.name,
      },
    }

    // const newdata: R4.Timing_RepeatWhenKind[] =  [R4.Timing_RepeatWhenKind._mornEarly]
    const dietTimings: R4.Timing_RepeatWhenKind[] = []

    dietData.allDuration?.map((e) => {
      if (e === 'MORN.early')
        dietTimings.push(R4.Timing_RepeatWhenKind._mornEarly)
      if (e === 'MORN') dietTimings.push(R4.Timing_RepeatWhenKind._morn)
      if (e === 'MORN.late')
        dietTimings.push(R4.Timing_RepeatWhenKind._mornLate)
      if (e === 'NOON') dietTimings.push(R4.Timing_RepeatWhenKind._noon)
      if (e === 'EVE') dietTimings.push(R4.Timing_RepeatWhenKind._eve)
      if (e === 'NIGHT') dietTimings.push(R4.Timing_RepeatWhenKind._night)
      if (e === 'PCV') dietTimings.push(R4.Timing_RepeatWhenKind._pcv)
    })

    const selfExtDuration: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-intake-time-ext',
      valueTiming: {
        repeat: {
          when: dietTimings,
        },
      },
    }

    const removeDurationExtension = removeExtensionFromExtensions(
      oldUpdatedCatalogEntr.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
    )

    const nameUpdatedExtension = addExtensionToExtensionsOfUrl(
      removeDurationExtension,
      selfExt
    )

    oldUpdatedCatalogEntr.extension = addExtensionToExtensionsOfUrl(
      nameUpdatedExtension,
      selfExtDuration
    )
    if (
      isCurrentUnitIsWellonessCenter() &&
      sessionStorage.getItem('app') === null
    ) {
      if (getDietType(oldUpdatedCatalogEntr)) {
        const typeExtension: R4.IExtension = {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext',
          valueCodeableConcept: {
            coding: [dietData.type!],
          },
        }

        const removeDurationExtensionType = removeExtensionFromExtensions(
          oldUpdatedCatalogEntr.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext'
        )

        const nameUpdatedExtensionType = addExtensionToExtensionsOfUrl(
          removeDurationExtensionType,
          selfExt
        )

        oldUpdatedCatalogEntr.extension = addExtensionToExtensionsOfUrl(
          nameUpdatedExtensionType,
          typeExtension
        )
      } else if (dietData.type) {
        oldUpdatedCatalogEntr.extension.push({
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext',
          valueCodeableConcept: {
            coding: [dietData.type!],
          },
        })
      }
    }

    // oldUpdatedCatalogEntr.extension = addExtensionToExtensionsOfUrl(
    //   removeDurationExtension,
    //   selfExt
    // )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedCatalogEntr.resourceType}/${oldUpdatedCatalogEntr.id}`,
          request: {
            ifNoneExist: oldUpdatedCatalogEntrMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedCatalogEntr.resourceType}/${oldUpdatedCatalogEntr.id}`,
          },
          // @ts-ignore
          resource: oldUpdatedCatalogEntr,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getCatalogueBundleUpdate(
  catalogueRowData: EditCatalogueDetailsModel,
  dataSet: ObsDataTableResource[],
  obsFinalData?: LabObsData
): R4.IBundle | undefined {
  const bundleArray: any = []
  if (catalogueRowData.planDefinitionResource) {
    if (dataSet.length > 0 && obsFinalData) {
      for (let i = 0; i < dataSet.length; i++) {
        const observationDefinition = getObservationObjectForUpdate(
          dataSet[i],
          i
        )
        bundleArray.push(observationDefinition)
      }
      const activityDefinition = getActivityObjForUpdate(
        obsFinalData.activityDef,
        dataSet
      )
      bundleArray.push(activityDefinition)
    }

    const newUpdatedPlanDef: R4.IPlanDefinition = {
      ...catalogueRowData.planDefinitionResource,
    }
    const newUpdatedPlanDefMatchString: string = `W/${JSON.stringify(
      newUpdatedPlanDef.meta?.versionId ?? ' '
    )}`

    newUpdatedPlanDef.description = catalogueRowData.description
    newUpdatedPlanDef.name = catalogueRowData.reportName

    const updatedScheduleSummaryExtension: R4.IExtension = {
      url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ScheduleSummary',
      valueString: catalogueRowData.scheduleSummary,
    }

    const removeScheduleSummaryExtension = removeExtensionFromExtensions(
      newUpdatedPlanDef.extension,
      'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ScheduleSummary'
    )

    newUpdatedPlanDef.extension = addExtensionToExtensionsOfUrl(
      removeScheduleSummaryExtension,
      updatedScheduleSummaryExtension
    )

    const updatedSpecialInstructionsExtension: R4.IExtension = {
      url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary',
      valueString: catalogueRowData.specialInstructions,
    }

    const removeSpecialInstructionsExtension = removeExtensionFromExtensions(
      newUpdatedPlanDef.extension,
      'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary'
    )

    newUpdatedPlanDef.extension = addExtensionToExtensionsOfUrl(
      removeSpecialInstructionsExtension,
      updatedSpecialInstructionsExtension
    )

    const chargeDef = getChargeObjForCatalogueUpdate(catalogueRowData)

    bundleArray.push({
      fullUrl: `${newUpdatedPlanDef.resourceType}/${newUpdatedPlanDef.id}`,
      request: {
        ifNoneExist: newUpdatedPlanDefMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `${newUpdatedPlanDef.resourceType}/${newUpdatedPlanDef.id}`,
      },
      resource: newUpdatedPlanDef,
    })
    bundleArray.push({
      fullUrl: `${chargeDef.resourceType}/${chargeDef.id}`,
      request: {
        //   ifMatch: therapyData.chargeItemVersion,
        method: R4.Bundle_RequestMethodKind._put,
        url: `${chargeDef.resourceType}/${chargeDef.id}`,
      },
      resource: chargeDef,
    })

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: bundleArray,
    }

    return requestBundle
  }

  return undefined
}

export function getPackageBundleDelete(
  packageData: Package
): R4.IBundle | undefined {
  if (packageData.package) {
    const oldUpdatedPlanDef: R4.IPlanDefinition = {
      ...packageData.package,
    }
    oldUpdatedPlanDef.status = R4.PlanDefinitionStatusKind._retired
    const oldUpdatedPlanDefMatchString: string = `W/${JSON.stringify(
      oldUpdatedPlanDef.meta?.versionId ?? ' '
    )}`

    const planDefAction: R4.IPlanDefinition_Action[] = []
    for (let i = 0; i < packageData.listOfTest.length; i++) {
      const action = packageData.listOfTest[i].planDefinition.action ?? []

      for (let j = 0; j < action.length; j++) {
        const anotherAction = action[j].action ?? []
        for (let k = 0; k < anotherAction.length; k++) {
          const url = anotherAction[k].definitionCanonical ?? ''
          if (url.length > 0) {
            const actionData: R4.IPlanDefinition_Action = {
              definitionCanonical: url,
              subjectReference: {
                reference: `PlanDefinition/${packageData.listOfTest[i].planDefinition.id}`,
              },
            }
            planDefAction.push(actionData)
          }
        }
      }
    }
    oldUpdatedPlanDef.action = [
      {
        action: planDefAction,
      },
    ]
    oldUpdatedPlanDef.title = packageData.packageName

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          request: {
            ifNoneExist: oldUpdatedPlanDefMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          },
          resource: oldUpdatedPlanDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getTherapyBundleDelete(
  therapyData: TherapyDataUpdate
): R4.IBundle | undefined {
  if (therapyData.therapy) {
    const oldUpdatedHealthcareServ: R4.IHealthcareService = {
      ...therapyData.therapy,
    }
    oldUpdatedHealthcareServ.active = false
    const oldUpdatedHealthcareServMatchString: string = `W/${JSON.stringify(
      oldUpdatedHealthcareServ.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareServMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          },
          resource: oldUpdatedHealthcareServ,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getPhysiotherapyBundleDelete(
  physiotherapyData: PhysiotherapyDataUpdate
): R4.IBundle | undefined {
  if (physiotherapyData.therapy) {
    const oldUpdatedHealthcareServ: R4.IHealthcareService = {
      ...physiotherapyData.therapy,
    }
    oldUpdatedHealthcareServ.active = false
    const oldUpdatedHealthcareServMatchString: string = `W/${JSON.stringify(
      oldUpdatedHealthcareServ.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareServMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          },
          resource: oldUpdatedHealthcareServ,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getSubstanceBundleDelete(
  substanceData: SubstanceDataUpdate
): R4.IBundle | undefined {
  if (substanceData.substance) {
    const oldUpdatedSubstance: R4.ISubstance = {
      ...substanceData.substance,
    }
    oldUpdatedSubstance.status = R4.SubstanceStatusKind._inactive

    const oldUpdatedHealthcareServMatchString: string = `W/${JSON.stringify(
      oldUpdatedSubstance.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedSubstance.resourceType}/${oldUpdatedSubstance.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareServMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedSubstance.resourceType}/${oldUpdatedSubstance.id}`,
          },
          resource: oldUpdatedSubstance,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getTherapyBundleActivate(
  therapyData: TherapyDataUpdate
): R4.IBundle | undefined {
  if (therapyData.therapy) {
    const oldUpdatedHealthcareServ: R4.IHealthcareService = {
      ...therapyData.therapy,
    }
    oldUpdatedHealthcareServ.active = true
    const oldUpdatedHealthcareServMatchString: string = `W/${JSON.stringify(
      oldUpdatedHealthcareServ.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareServMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          },
          resource: oldUpdatedHealthcareServ,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getSubstanceBundleActivate(
  substanceData: SubstanceDataUpdate
): R4.IBundle | undefined {
  if (substanceData.substance) {
    const oldUpdatedSubstance: R4.ISubstance = {
      ...substanceData.substance,
    }
    oldUpdatedSubstance.status = R4.SubstanceStatusKind._active

    const oldUpdatedHealthcareServMatchString: string = `W/${JSON.stringify(
      oldUpdatedSubstance.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedSubstance.resourceType}/${oldUpdatedSubstance.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareServMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedSubstance.resourceType}/${oldUpdatedSubstance.id}`,
          },
          resource: oldUpdatedSubstance,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getDietBundleActivate(
  dietData: DietDataModel
): R4.IBundle | undefined {
  if (dietData) {
    const oldUpdatedDiet: R4.ICatalogEntry = {
      ...dietData.catalogue!,
    }

    oldUpdatedDiet.orderable = true

    const oldUpdatedDietMatchString: string = `W/${JSON.stringify(
      oldUpdatedDiet.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedDiet.resourceType}/${oldUpdatedDiet.id}`,
          request: {
            ifNoneExist: oldUpdatedDietMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedDiet.resourceType}/${oldUpdatedDiet.id}`,
          },
          // @ts-ignore
          resource: oldUpdatedDiet,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getPhysiotherapyBundleActivate(
  physiotherapyData: PhysiotherapyDataUpdate
): R4.IBundle | undefined {
  if (physiotherapyData.therapy) {
    const oldUpdatedHealthcareServ: R4.IHealthcareService = {
      ...physiotherapyData.therapy,
    }
    oldUpdatedHealthcareServ.active = true
    const oldUpdatedHealthcareServMatchString: string = `W/${JSON.stringify(
      oldUpdatedHealthcareServ.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          request: {
            ifNoneExist: oldUpdatedHealthcareServMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedHealthcareServ.resourceType}/${oldUpdatedHealthcareServ.id}`,
          },
          resource: oldUpdatedHealthcareServ,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getDietBundleDelete(
  dietData: DietDataModel
): R4.IBundle | undefined {
  if (dietData) {
    const oldUpdatedDiet: R4.ICatalogEntry = {
      ...dietData.catalogue!,
    }

    oldUpdatedDiet.orderable = false

    const oldUpdatedDietMatchString: string = `W/${JSON.stringify(
      oldUpdatedDiet.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedDiet.resourceType}/${oldUpdatedDiet.id}`,
          request: {
            ifNoneExist: oldUpdatedDietMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedDiet.resourceType}/${oldUpdatedDiet.id}`,
          },
          // @ts-ignore
          resource: oldUpdatedDiet,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export function getPackageBundleTest(
  partnerLabTestData: PartnerLabTest
): R4.IBundle | undefined {
  if (partnerLabTestData.planDefinition) {
    const oldUpdatedPlanDef: R4.IPlanDefinition = {
      ...partnerLabTestData.planDefinition,
    }
    oldUpdatedPlanDef.status = R4.PlanDefinitionStatusKind._retired
    const oldUpdatedPlanDefMatchString: string = `W/${JSON.stringify(
      oldUpdatedPlanDef.meta?.versionId ?? ' '
    )}`
    oldUpdatedPlanDef.title = partnerLabTestData.testName
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          request: {
            ifNoneExist: oldUpdatedPlanDefMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedPlanDef.resourceType}/${oldUpdatedPlanDef.id}`,
          },
          resource: oldUpdatedPlanDef,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

const getUpdatedGroupForPackage = (packageData: Package) => {
  const characteristicAray: R4.IGroup_Characteristic[] = []
  const identifiers: R4.IIdentifier[] = []
  let genderData: string = ''
  if (packageData.gender.Male) genderData += 'Male'
  if (packageData.gender.Female) genderData += 'Female'
  if (packageData.gender.Others) genderData += 'Others'

  const groupIdentifhir: R4.IIdentifier = {}
  groupIdentifhir.value = `${packageData.maxAge}${packageData.minAge}${genderData}`
  groupIdentifhir.system = 'groupCombination'
  identifiers.push(groupIdentifhir)

  if (packageData.gender.Male) {
    const maleCodebleSet = {
      code: { text: 'Gender' },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://hl7.org/fhir/administrative-gender',
            code: 'male',
          },
        ],
        text: 'Male',
      },
    }
    characteristicAray.push(maleCodebleSet)
  }

  if (packageData.gender.Female) {
    const femaleCodebleSet = {
      code: { text: 'Gender' },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://hl7.org/fhir/administrative-gender',
            code: 'female',
          },
        ],
        text: 'Female',
      },
    }
    characteristicAray.push(femaleCodebleSet)
  }

  if (packageData.gender.Others) {
    const otherCodebleSet = {
      code: { text: 'Gender' },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://hl7.org/fhir/administrative-gender',
            code: 'unknown',
          },
        ],
        text: 'Others',
      },
    }
    characteristicAray.push(otherCodebleSet)
  }

  const ageCodeConcept = {
    code: { text: 'Age' },
    valueRange: {
      low: {
        value: packageData.minAge,
        system: 'http://unitsofmeasure.org',
        code: 'a',
      },
      high: {
        value: packageData.maxAge,
        system: 'http://unitsofmeasure.org',
        code: 'a',
      },
    },
  }
  characteristicAray.push(ageCodeConcept)
  const iGroupItemRef: R4.IGroup = {
    resourceType: 'Group',
    active: true,
    id: packageData.package?.subjectReference?.reference?.split('/')[1],
    identifier: identifiers,
    characteristic: characteristicAray,
  }
  return iGroupItemRef
}

const getChargeObjForPackageUpdate = (packageData: Package) => {
  const chargeDefExt = getExtensionValueOfUrl(
    packageData.package?.extension,
    'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
  )

  const extVal = chargeDefExt?.valueReference ?? ''
  const data = JSON.stringify(extVal)
  const refId = data.split('/')[1].replace('"}', '')
  const urlName = packageData.packageName.replaceAll(' ', '')
  const iMoney: R4.IMoney = {}
  iMoney.value = packageData.packagePrice
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    id: refId,
    description: packageData.packageName,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  return iChargeItemObj
}

const getChargeObjForTherapyUpdate = (
  therapyData: FhirTherapyListWithChargeDef
) => {
  const chargeDefExt = getExtensionValueOfUrl(
    therapyData.therapy?.extension,
    'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
  )

  const extVal = chargeDefExt?.valueReference ?? ''
  const data = JSON.stringify(extVal)
  const refId = data.split('/')[1].replace('"}', '')
  const urlName = therapyData.name
  const iMoney: R4.IMoney = {}
  iMoney.value = therapyData.price
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    id: refId,
    description: therapyData.name,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  return iChargeItemObj
}

const getChargeObjForPhysiotherapyUpdate = (
  therapyData: FhirPhysiotherapyListWithChargeDef
) => {
  const chargeDefExt = getExtensionValueOfUrl(
    therapyData.therapy?.extension,
    'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
  )

  const extVal = chargeDefExt?.valueReference ?? ''
  const data = JSON.stringify(extVal)
  const refId = data.split('/')[1].replace('"}', '')
  const urlName = therapyData.name
  const iMoney: R4.IMoney = {}
  iMoney.value = therapyData.price
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    id: refId,
    description: therapyData.name,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  return iChargeItemObj
}

const getChargeObjForSubstanceUpdate = (substanceData: SubstanceDataUpdate) => {
  const chargeDefExt = getExtensionValueOfUrl(
    substanceData.substance?.extension,
    'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
  )

  const extVal = chargeDefExt?.valueReference ?? ''
  const data = JSON.stringify(extVal)
  const refId = data.split('/')[1].replace('"}', '')
  const urlName = substanceData.name
  const iMoney: R4.IMoney = {}
  iMoney.value = substanceData.price
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    id: refId,
    description: substanceData.name,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  return iChargeItemObj
}

const getChargeObjForCatalogueUpdate = (
  catalogueRowData: EditCatalogueDetailsModel
) => {
  const chargeDefExt = getExtensionValueOfUrl(
    catalogueRowData.planDefinitionResource?.extension,
    'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
  )

  const extVal = chargeDefExt?.valueReference ?? ''
  const data = JSON.stringify(extVal)
  const refId = data.split('/')[1].replace('"}', '')
  const urlName = catalogueRowData.testName.replaceAll(' ', '')
  const iMoney: R4.IMoney = {}
  iMoney.value = catalogueRowData.price
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    id: refId,
    description: catalogueRowData.description,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  return iChargeItemObj
}

const getChargeObjForPackage = (packageData: Package) => {
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'ChargeItemDefinition'
  const urlName = packageData.packageName.replaceAll(' ', '')
  const iMoney: R4.IMoney = {}
  iMoney.value = packageData.packagePrice
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    description: packageData.packageName,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:ChargeItemDefinition`,
    request: iRequest,
    resource: iChargeItemObj,
  }
  chargeRef.reference = `urn:uuid:ChargeItemDefinition`
  return bundleEntry
}

const getGroupObject = (packageData: Package) => {
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'Group'
  const characteristicAray: R4.IGroup_Characteristic[] = []
  const identifiers: R4.IIdentifier[] = []
  let genderData: string = ''
  if (packageData.gender.Male) genderData += 'Male'
  if (packageData.gender.Female) genderData += 'Female'
  if (packageData.gender.Others) genderData += 'Others'

  const groupIdentifhir: R4.IIdentifier = {}
  groupIdentifhir.value = `${packageData.maxAge}${packageData.minAge}${genderData}`
  groupIdentifhir.system = 'groupCombination'
  identifiers.push(groupIdentifhir)
  iRequest.ifNoneExist = `identifier=groupCombination|${packageData.maxAge}${packageData.minAge}${genderData}`

  if (packageData.gender.Male) {
    const maleCodebleSet = {
      code: { text: 'Gender' },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://hl7.org/fhir/administrative-gender',
            code: 'male',
          },
        ],
        text: 'Male',
      },
    }
    characteristicAray.push(maleCodebleSet)
  }

  if (packageData.gender.Female) {
    const femaleCodebleSet = {
      code: { text: 'Gender' },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://hl7.org/fhir/administrative-gender',
            code: 'female',
          },
        ],
        text: 'Female',
      },
    }
    characteristicAray.push(femaleCodebleSet)
  }

  if (packageData.gender.Others) {
    const otherCodebleSet = {
      code: { text: 'Gender' },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://hl7.org/fhir/administrative-gender',
            code: 'unknown',
          },
        ],
        text: 'Others',
      },
    }
    characteristicAray.push(otherCodebleSet)
  }

  const ageCodeConcept = {
    code: { text: 'Age' },
    valueRange: {
      low: {
        value: packageData.minAge,
        system: 'http://unitsofmeasure.org',
        code: 'a',
      },
      high: {
        value: packageData.maxAge,
        system: 'http://unitsofmeasure.org',
        code: 'a',
      },
    },
  }
  characteristicAray.push(ageCodeConcept)

  const iGroupItemRef: R4.IGroup = {
    resourceType: 'Group',
    active: true,
    identifier: identifiers,
    characteristic: characteristicAray,
  }
  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:Group`,
    request: iRequest,
    resource: iGroupItemRef,
  }
  groupRef.reference = `urn:uuid:Group`
  return bundleEntry
}

const getPlanDefObjectForPackage = (packageData: Package) => {
  const unitDetails = getCurrentUserUnitReference()!
  const identifier = Math.random().toString(36).substring(2, 7)
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = `PlanDefinition`

  const planDefAction: R4.IPlanDefinition_Action[] = []
  for (let i = 0; i < packageData.listOfTest.length; i++) {
    const action = packageData.listOfTest[i].planDefinition.action ?? []

    for (let j = 0; j < action.length; j++) {
      const anotherAction = action[j].action ?? []
      for (let k = 0; k < anotherAction.length; k++) {
        const url = anotherAction[k].definitionCanonical ?? ''
        if (url.length > 0) {
          const actionData: R4.IPlanDefinition_Action = {
            definitionCanonical: url,
            subjectReference: {
              reference: `PlanDefinition/${packageData.listOfTest[i].planDefinition.id}`,
            },
          }
          planDefAction.push(actionData)
        }
      }
    }
  }
  const usageData: string[] = []
  for (let i = 0; i < packageData.tags.length; i++) {
    usageData.push(packageData.tags[i].name)
  }
  const iPlanDef: R4.IPlanDefinition = {
    resourceType: 'PlanDefinition',
    description: packageData.packageName,
    publisher: 'Wellopathy Inc.',
    language: 'en-US',
    subjectReference: {
      reference: groupRef.reference,
    },
    identifier: [
      {
        system: 'lab-package',
        value: identifier,
      },
    ],
    usage: usageData.toString(),
    extension: [
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: chargeRef.reference,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PlanDefinitionManagingOrganization',
        valueReference: {
          id: unitDetails.id,
          reference: `Organization/${unitDetails.id}`,
          type: 'Organization',
          display: `${unitDetails.display}`,
        },
      },
    ],
    action: [
      {
        action: planDefAction,
      },
    ],
    jurisdiction: [
      {
        coding: [
          {
            code: 'IN',
            display: 'INDIA',
            system: 'urn:iso:std:iso:3166',
          },
        ],
      },
    ],
    name: packageData.packageName,
    status: R4.PlanDefinitionStatusKind._active,
    title: packageData.packageName,
    type: {
      coding: [
        {
          code: 'panel',
          display:
            'unitary\u00a0measurement\u00a0performed\u00a0on\u00a0an\u00a0in\u00a0vitro\u00a0biologic\u00a0specimen',
          system:
            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
        },
      ],
    },
  }

  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:PlanDefinition`,
    request: iRequest,
    resource: iPlanDef,
  }
  return bundleEntry
}

export function getPacakageBundle(packageData: Package): R4.IBundle {
  const bundleArray: any = []
  const groubObject = getGroupObject(packageData)
  bundleArray.push(groubObject)
  const chargeDef = getChargeObjForPackage(packageData)
  bundleArray.push(chargeDef)
  const planDef = getPlanDefObjectForPackage(packageData)
  bundleArray.push(planDef)
  const catalog: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return catalog
}
function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}

const getObservationObjectForUpdate = (
  valueSet: ObsDataTableResource,
  i: number
) => {
  const iRequest: R4.IBundle_Request = {}

  const newObsDef: R4.IObservationDefinition = {
    ...valueSet.obsInitial,
  }
  const newUpdatedPlanDefMatchString: string = `W/${JSON.stringify(
    newObsDef.meta?.versionId ?? ' '
  )}`

  newObsDef.permittedDataType = [getDataType(valueSet.permittedDataType)]
  newObsDef.preferredReportName = valueSet.obsName

  const updatedInterpretationExtension: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext',
    valueString: valueSet.interpretation,
  }

  const removeInterpretationExtension = removeExtensionFromExtensions(
    newObsDef.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-exst'
  )

  newObsDef.extension = addExtensionToExtensionsOfUrl(
    removeInterpretationExtension,
    updatedInterpretationExtension
  )

  const updatedNotes: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext',
    valueAnnotation: {
      text: valueSet.notes,
    },
  }

  const removeNotesExtension = removeExtensionFromExtensions(
    newObsDef.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext'
  )

  newObsDef.extension = addExtensionToExtensionsOfUrl(
    removeNotesExtension,
    updatedNotes
  )
  const updatedCommentsExtension: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext',
    valueAnnotation: {
      text: valueSet.comment,
    },
  }

  const removeCommentsExtension = removeExtensionFromExtensions(
    newObsDef.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext'
  )

  newObsDef.extension = addExtensionToExtensionsOfUrl(
    removeCommentsExtension,
    updatedCommentsExtension
  )

  const intervalData: IObservationDefinition_QualifiedInterval[] = []

  if (valueSet.highCode1 || valueSet.lowCode1) {
    const highData: IObservationDefinition_QualifiedInterval = {
      category: ObservationDefinition_QualifiedIntervalCategoryKind._reference,
      context: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
            code: 'normal',
            display: 'Normal Range',
          },
        ],
      },
    }
    if (
      valueSet.highCode1 &&
      valueSet.highCode1.length > 0 &&
      valueSet.lowCode1 &&
      valueSet.lowCode1.length > 0
    ) {
      const highRange = {
        low: {
          value: valueSet.low1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode1,
        },
        high: {
          value: valueSet.high1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode1,
        },
      }
      highData.range = highRange
    }

    if (
      valueSet.highCode1 &&
      valueSet.highCode1.length > 0 &&
      (valueSet.lowCode1 === undefined || valueSet.lowCode1!.length === 0)
    ) {
      const highRange1 = {
        high: {
          value: valueSet.high1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode1,
        },
      }
      highData.range = highRange1
    }

    if (
      valueSet.lowCode1 &&
      valueSet.lowCode1.length > 0 &&
      (valueSet.highCode1 === undefined || valueSet.highCode1!.length === 0)
    ) {
      const lowRange1 = {
        low: {
          value: valueSet.low1,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode1,
        },
      }
      highData.range = lowRange1
    }
    // if (valueSet.highCode1) {
    //   const highRange1 = {
    //     high: {
    //       value: valueSet.high1,
    //       system: 'http://unitsofmeasure.org',
    //       code: valueSet.highCode1,
    //     },
    //   }
    //   highData.range = highRange1
    // }
    if (
      valueSet.gender1 &&
      (valueSet.gender1 === 'male' || valueSet.gender1 === 'female')
    ) {
      highData.gender = getGenderData(valueSet.gender1)
    }

    if (
      valueSet.ageLow1 &&
      valueSet.ageLow1 >= 0 &&
      valueSet.ageHigh1 &&
      valueSet.ageHigh1
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
        high: {
          value: valueSet.ageHigh1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (
      valueSet.ageLow1 &&
      valueSet.ageLow1 >= 0 &&
      valueSet.ageHigh1 === undefined
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    if (
      valueSet.ageHigh1 &&
      valueSet.ageHigh1 >= 0 &&
      valueSet.ageLow1 === undefined
    ) {
      const highAgeRange = {
        high: {
          value: valueSet.ageHigh1,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    intervalData.push(highData)
  }

  if (valueSet.highCode2 || valueSet.lowCode2) {
    const highData: IObservationDefinition_QualifiedInterval = {
      category: ObservationDefinition_QualifiedIntervalCategoryKind._reference,
      context: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
            code: 'normal',
            display: 'Normal Range',
          },
        ],
      },
    }
    if (
      valueSet.highCode2 &&
      valueSet.highCode2.length > 0 &&
      valueSet.lowCode2 &&
      valueSet.lowCode2.length > 0
    ) {
      const highRange = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode2,
        },
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode2,
        },
      }
      highData.range = highRange
    }

    if (
      valueSet.highCode2 &&
      valueSet.highCode2.length > 0 &&
      (valueSet.lowCode2 === undefined || valueSet.lowCode2!.length === 0)
    ) {
      const highRange1 = {
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.highCode2,
        },
      }
      highData.range = highRange1
    }

    if (
      valueSet.lowCode2 &&
      valueSet.lowCode2.length > 0 &&
      (valueSet.highCode2 === undefined || valueSet.highCode2!.length === 0)
    ) {
      const lowRange2 = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode2,
        },
      }
      highData.range = lowRange2
    }
    // if (valueSet.highCode2) {
    //   const highRange1 = {
    //     high: {
    //       value: valueSet.high2,
    //       system: 'http://unitsofmeasure.org',
    //       code: valueSet.highCode2,
    //     },
    //   }
    //   highData.range = highRange1
    // }
    if (
      valueSet.gender2 &&
      (valueSet.gender2 === 'male' || valueSet.gender2 === 'female')
    ) {
      highData.gender = getGenderData(valueSet.gender2)
    }
    if (
      valueSet.ageLow2 &&
      valueSet.ageLow2 >= 0 &&
      valueSet.ageHigh2 &&
      valueSet.ageHigh2
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    if (
      valueSet.ageLow2 &&
      valueSet.ageLow2 >= 0 &&
      valueSet.ageHigh2 === undefined
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (
      valueSet.ageHigh2 &&
      valueSet.ageHigh2 >= 0 &&
      valueSet.ageLow2 === undefined
    ) {
      const highAgeRange = {
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    intervalData.push(highData)
  } else if (
    (valueSet.ageLow2 && valueSet.ageLow2 >= 0) ||
    (valueSet.ageHigh2 && valueSet.ageHigh2 >= 0) ||
    (valueSet.low2 && valueSet.low2 >= 0) ||
    (valueSet.high2 && valueSet.high2 >= 0)
  ) {
    const highData: IObservationDefinition_QualifiedInterval = {
      category: ObservationDefinition_QualifiedIntervalCategoryKind._reference,
      context: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
            code: 'normal',
            display: 'Normal Range',
          },
        ],
      },
    }

    if (
      valueSet.low2 &&
      valueSet.low2 >= 0 &&
      valueSet.high2 &&
      valueSet.high2 >= 0
    ) {
      const highRange = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.unitCode,
        },
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.unitCode,
        },
      }
      highData.range = highRange
    }

    if (valueSet.high2 && valueSet.high2 >= 0 && valueSet.low2 === undefined) {
      const highRange1 = {
        high: {
          value: valueSet.high2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.unitCode,
        },
      }
      highData.range = highRange1
    }

    if (valueSet.low2 && valueSet.low2 >= 0 && valueSet.high2 === undefined) {
      const lowRange2 = {
        low: {
          value: valueSet.low2,
          system: 'http://unitsofmeasure.org',
          code: valueSet.lowCode2,
        },
      }
      highData.range = lowRange2
    }

    if (
      valueSet.gender2 &&
      (valueSet.gender2 === 'male' || valueSet.gender2 === 'female')
    ) {
      highData.gender = getGenderData(valueSet.gender2)
    }

    if (
      valueSet.ageLow2 &&
      valueSet.ageLow2 >= 0 &&
      valueSet.ageHigh2 &&
      valueSet.ageHigh2 >= 0
    ) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    if (valueSet.ageLow2 && valueSet.ageLow2 >= 0 && valueSet.ageHigh2 === 0) {
      const highAgeRange = {
        low: {
          value: valueSet.ageLow2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }

    if (valueSet.ageHigh2 && valueSet.ageHigh2 >= 0 && valueSet.ageLow2 === 0) {
      const highAgeRange = {
        high: {
          value: valueSet.ageHigh2,
          system: 'http://unitsofmeasure.org',
          code: 'a',
        },
      }
      highData.age = highAgeRange
    }
    intervalData.push(highData)
  }
  if (valueSet.unitCode) {
    newObsDef.quantitativeDetails = {
      unit: {
        coding: [
          {
            system: 'http://unitsofmeasure.org',
            code: valueSet.unitCode,
          },
        ],
      },
      decimalPrecision: valueSet.decimalPrecision,
    }
  }

  newObsDef.qualifiedInterval = intervalData

  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `${newObsDef.resourceType}/${newObsDef.id}`,
    request: {
      ifNoneExist: newUpdatedPlanDefMatchString,
      method: R4.Bundle_RequestMethodKind._put,
      url: `${newObsDef.resourceType}/${newObsDef.id}`,
    },
    resource: newObsDef,
  }

  return bundleEntry
}

const getActivityObjForUpdate = (
  activityDef: R4.IActivityDefinition,
  finalDataSet: ObsDataTableResource[]
) => {
  const newActivity: R4.IActivityDefinition = {
    ...activityDef,
  }
  const oldActivityDefVersion: string = `W/${JSON.stringify(
    activityDef.meta?.versionId ?? ' '
  )}`

  const obsRef: R4.IReference[] = []
  for (let i = 0; i < finalDataSet.length; i++) {
    const refObj: R4.IReference = {
      reference: `ObservationDefinition/${finalDataSet[i].obsInitial.id}`,
      type: 'ObservationDefinition',
    }
    obsRef.push(refObj)
  }
  newActivity.observationResultRequirement = obsRef

  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `${newActivity.resourceType}/${newActivity.id}`,
    request: {
      ifNoneExist: oldActivityDefVersion,
      method: R4.Bundle_RequestMethodKind._put,
      url: `${newActivity.resourceType}/${newActivity.id}`,
    },
    resource: newActivity,
  }
  return bundleEntry
}
