import Paper from '@material-ui/core/Paper'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Box,
  Typography,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kPrimaryMain, kSelectedTabBackground } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { WelloFormItemLabel } from './LeftMenu/WelloFormItemLabel'

export interface ToggleOption {
  value: string
  displayText: string
}

export interface ToggleOptionProps {
  options: ToggleOption[]
  preSelectedValue?: string
  onSelectionChanged: (option: string) => void
  menuLabel?: string
  size?: 'small' | 'medium' | 'large'
  disablePreSelect?: boolean
  id?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export default function NewToggleSelectors(props: ToggleOptionProps) {
  const [value, setValue] = React.useState(
    props.disablePreSelect
      ? props.options[0].value
      : props.preSelectedValue ?? props.options[0].value
  )

  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')

  const handleAlignment = (event: any, newAlignment: string) => {
    if (newAlignment !== null) {
      props.onSelectionChanged(newAlignment)
      setValue(newAlignment)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
  }, [])
  const classes = useStyles()

  const defaultBackgroundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  return (
    <StyledToggleButtonGroup
      size={props.size ?? 'small'}
      value={value}
      exclusive
      onChange={handleAlignment}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          handleAlignment(e, value)
        }
      }}
      aria-label='text alignment'
      style={{
        backgroundColor: '#DBDBF1',
        margin: props.size === 'small' ? '0px' : undefined,
        paddingTop: '8px',
        width: '100%',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: 100,
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        zIndex: 0,
      }}
      id={props.id ? `${props.id}` : 'buttonGrp'}
    >
      {props.options.map((option, index: number) => (
        <ToggleButton
          // id={`${index}_${option.value}`}

          id={props.id ? `${props.id}${index}` : `buttonGrp${index}`}
          key={`tg_button_key${option.value}`}
          value={option.value}
          size='small'
          style={{
            background: option.value === value ? kPrimaryMain : '#F0F0FA',
            zIndex: 0,
            display: 'flex',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 4,
            paddingBottom: 4,
            margin: 0,

            flexGrow: 1,
          }}
        >
          <Typography
            variant='subtitle2'
            id={props.id ? `${props.id}${index}Text` : `buttonText{index}`}
            style={{
              zIndex: 0,
              color:
                option.value === value ? '#FFFFFF' : kSelectedTabBackground,
              letterSpacing: '1.0',
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            {t('labelCommon:' + `${option.displayText}`)}
            {/* {option.displayText} */}
          </Typography>
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
