import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLabRequestsOfAppointmentForIPD } from 'redux/consultation/labReferralListIPD/labDiagnosticsListSliceIPD'
import { RootState } from 'redux/rootReducer'
import { LabDiagnosticTabular } from 'views/components/consultation/assessment/labTestTabularTile'

interface Props {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  followUp?: boolean
  split?: boolean
}

export const LabReferralList: React.FC<Props> = ({
  fhirAppointmentDetails,
  followUp,
  split,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const labDiagnosticsListSlice = useSelector(
    (state: RootState) => state.labDiagnosticsListSliceIPD
  )

  useEffect(() => {
    dispatch(getLabRequestsOfAppointmentForIPD(fhirAppointmentDetails))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(labDiagnosticsListSlice.updatingImpressions ||
        labDiagnosticsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {split &&
        (labDiagnosticsListSlice.noResultsAvailable ||
          (labDiagnosticsListSlice.serviceRequest &&
            labDiagnosticsListSlice.serviceRequest.length === 0)) && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              No data available
            </Typography>
          </Box>
        )}
      {labDiagnosticsListSlice.serviceRequest &&
        labDiagnosticsListSlice.serviceRequest.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%'>
            <LabDiagnosticTabular
              referrals={labDiagnosticsListSlice.serviceRequest}
              type='referrals'
            />

            {/* {labDiagnosticsListSlice.serviceRequest.map(
              (e: R4.IServiceRequest) => (
                <Box key={e.id ?? ''}>
                  <LabDiagnosticRequestTile
                    referrals={e}
                    key={`${e.id ?? 't'}_${e.id ?? 's'}`}
                  />
                </Box>
              )
            )} */}
          </Box>
        )}
      {/* {labDiagnosticsListSlice.tasks &&
        labDiagnosticsListSlice.tasks.length > 0 && (
          <Box marginX={2} display='flex' flexDirection='column'>
            {labDiagnosticsListSlice.tasks.map((e: R4.ITask) => (
              <Box key={e.id ?? ''}>
                {e.contained &&
                  e.contained.length > 0 &&
                  e.contained.map((test) => (
                    <LabDiagnosticRequestTile
                      medication={test as R4.IPlanDefinition}
                      key={`${(test as R4.IPlanDefinition).id ?? 't'}_${'s'}`}
                    />
                  ))}
              </Box>
            ))}
          </Box>
        )} */}
    </Box>
  )
}
