import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core'
import { kBackgroundPaper, kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getKriyaListForTheEncounter } from 'utils/sopaNotes/plan/kriyaHelpers'
import { RootState } from 'redux/rootReducer'
import { resetKriyaAdditionState } from 'redux/consultation/recommendation_additions/kriyas_addition_slice/kriyasAdditionSlice'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { KriyaDetail } from './addKriyasForEncounter'
import { KriyaDetailTile } from './kriyaDetailTile'

interface Props {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole

  updateList: string
  onClick?: () => void
  isReadonly?: boolean
  split?: boolean
}

export const KriyasListForEncounterIPD: React.FC<Props> = ({
  fhirAppointmentDetails,
  updateList,
  onClick,
  isReadonly,
  split,
}: Props) => {
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const theme = useTheme()
  const [collapsed, setCollapsed] = useState(true)
  const dispatch = useDispatch()

  const kriyaSliceAdditionStatus = useSelector(
    (state: RootState) => state.kriyasAdditionSlice.additionSuccessful
  )

  const [pathyaApathyaList, setPathyaApathyaList] = useState<KriyaDetail[]>()

  function fetchPathyaApathyaDetails() {
    setUpdateStatus({ requesting: true })
    getKriyaListForTheEncounter({
      appointmentId: '',
      encounterId: fhirAppointmentDetails.mainEncounter?.id!,
    }).then((e) => {
      if (e !== false) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setPathyaApathyaList(e as KriyaDetail[])
      } else {
        setUpdateStatus({
          requesting: false,
          requestError: false,
        })
      }
    })
  }

  useEffect(() => {
    fetchPathyaApathyaDetails()
  }, [updateList])

  useEffect(() => {
    if (kriyaSliceAdditionStatus) {
      dispatch(resetKriyaAdditionState())
      fetchPathyaApathyaDetails()
    }
  }, [kriyaSliceAdditionStatus])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {updateStatus.requesting && <CircularProgress size={15} />}
      {updateStatus.requestError && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2' color='error'>
            {' '}
            Error while fetching details
          </Typography>
        </Box>
      )}
      {updateStatus.requestSuccess &&
        pathyaApathyaList &&
        pathyaApathyaList.length === 0 &&
        split && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              No data available
            </Typography>
          </Box>
        )}
      {updateStatus.requestSuccess && pathyaApathyaList && (
        <Box width='100%' display='flex' flexDirection='column'>
          {pathyaApathyaList.map((item, index) => (
            <Box
              width='100%'
              key={item.recordedDate + (item.kriya?.code ?? '') ?? ''}
              bgcolor={index % 2 === 0 ? kDialogueBackground : kBackgroundPaper}
              border={1}
              borderColor='white'
            >
              <KriyaDetailTile kriyaDetail={item} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
