import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import {
  FhirPrescriptionDetails,
  FhirReferralInsightDetails,
} from 'models/fhirPrescriptionDetails'
import { GroupedOccupation } from 'models/groupedOccupations'
import { Symptoms } from 'models/symptoms'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { WPAdminAPIClient } from 'services/wpApiService'
import { getWelloMedicationsFromBundleResponses } from 'utils/appointment_handle/medications_util'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getNotesString } from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getAAharaList,
  getAllergiesDataForReferral,
  getArithmeticMeasurements,
  getAssessmentCondition,
  getChiefComplaintData,
  getConditionDataForReferral,
  getDexaForReferral,
  getDietChart,
  getDoctorDetails,
  getExpandedAppointmentFromBundle,
  getFamilyConditionForReferral,
  getGeneralExaminationForReferral,
  getHistoryAddictionsForReferral,
  getHistoryDataForReferral,
  getHistoryHabitForReferral,
  getHomaForReferral,
  getInstructionList,
  getKriyaDetails,
  getLabResultsForReferral,
  getLftForReferral,
  getMedicationData,
  getPanel1ForReferral,
  getPanel2ForReferral,
  getPanel3ForReferral,
  getPatientSummary,
  getPlanPageMedication,
  getProcedureDataForReferral,
  getReferralDetails,
  getReferralImaging,
  getReferralLabTests,
  getRftForReferral,
  getRootCauseObs,
  getSerumForReferral,
  getSkinCauseObs,
  getSymptomsProfile,
  getTftPanelForReferral,
  getUnitDetails,
  getUrinePanelForReferral,
  getViharaList,
  getVitalsForReferral,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getCommunicationRequestFromBundle,
  getSymptomsAlongWithAssociated,
  getSymptomsData,
} from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { getLatestObservationsPhysical } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { responseFilter } from 'utils/response'
import { getVendorPartId } from 'utils/routes_helper'
import { getWelloMedicationFromMedicationRequest } from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import {
  getPathyaApathyForTypeAndEncounter,
  PathyaApathyaDetails,
} from 'utils/sopaNotes/plan/pathyaApathyaHelpers'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'
import { ReferralSearchStatus } from './referralSearchStatus'

const initialState: ReferralSearchStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingImpressions: false,
}

const referralHistorySlice = createSlice({
  name: 'referralHistorySlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ReferralSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.referralDetails = action.payload.referralDetails
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getReferralInsights =
  (fhirAppointmentDetails: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ReferralSearchStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(referralHistorySlice.actions.updatedStatus(state))
    try {
      const fhirClient: WPAdminAPIClient = new WPAdminAPIClient()
      const vendorId: string | null = getVendorPartId()
      const response: any = await fhirClient.doGetResource(
        `summary/xorg_referral_soap?patient_id=${fhirAppointmentDetails.patient
          .id!}&appointment_id=${fhirAppointmentDetails.appointment
          .id!}&tenant_id=${vendorId}&max_staleness=12`
      )
      logger.info(
        '-----------------------------MeREQ----------------------------------------------',
        response
      )

      if (response === undefined) {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(referralHistorySlice.actions.updatedStatus(state))
      } else {
        let encounterData: string = ''
        if (response.dubug) {
          if (response.dubug.src_sr_details) {
            if (response.dubug.src_sr_details.encounter_id) {
              encounterData = response.dubug.src_sr_details.encounter_id
            }
            if (response.dubug.src_tenant_id) {
              localStorage.setItem('vendorId', response.dubug.src_tenant_id)
            }
          }
        }
        const referralData: FhirReferralInsightDetails = {
          chiefComplaint: getChiefComplaintData(response),
          historyConditionData: getConditionDataForReferral(response),
          historyProcedureData: getProcedureDataForReferral(response),
          historyAllergies: getAllergiesDataForReferral(response),
          historyMedication: getMedicationData(response),
          historyMensuration: [],
          historyObstetrics: [],
          historyOccupation: getHistoryDataForReferral(response),
          historyHabits: getHistoryHabitForReferral(response),
          historyAddictions: getHistoryAddictionsForReferral(response),
          historyFamilyHistory: getFamilyConditionForReferral(response),
          generalExamData: getGeneralExaminationForReferral(response),
          vitals: getVitalsForReferral(response),
          labResults: {
            labTest: getLabResultsForReferral(response),
          },
          anthropometricMeasurements: getArithmeticMeasurements(response),
          rootCause: getRootCauseObs(response),
          skinIssues: getSkinCauseObs(response),
          assessmentCondition: getAssessmentCondition(response),
          symptomProfile: getSymptomsProfile(response),
          patientSummary: getPatientSummary(response),
          referralDetails: getReferralDetails(response),
          practDetails: getDoctorDetails(response),
          unitDetails: getUnitDetails(response),
          referredLabTest: getReferralLabTests(response),
          imagingStudies: getReferralImaging(response),
          kriya: getKriyaDetails(response),
          instructions: getInstructionList(response),
          ahara: getAAharaList(response),
          vihara: getViharaList(response),
          dietChart: getDietChart(response),
          medication: getPlanPageMedication(response),
          signExam: await getLabTest(fhirAppointmentDetails, encounterData),
        }

        state.resultsAvailable = true
        state.fetchingImpressions = false
        state.referralDetails = referralData
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingImpressions = false
        //   state.raw = conditionResponse.entry
        //     .filter((e) => e.resource?.resourceType === 'MedicationRequest')
        //     .map((e) => e.resource as R4.IMedicationRequest)
        dispatch(referralHistorySlice.actions.updatedStatus(state))
      } /* */
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: ReferralSearchStatus = { ...initialState }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(referralHistorySlice.actions.updatedStatus(errorSearchDoctor))
    } finally {
      console.error('ere')
    }
  }

async function getLabTest(
  fhirAppointmentDetails: FhirAppointmentDetail,
  encounterId: string
): Promise<GroupedOccupation[]> {
  const obsList: R4.IObservation[] = []
  let groupedOccupationData: GroupedOccupation[] = []
  const finalGroupedOccupationData: GroupedOccupation[] = []

  const fhirClient: FHIRDefaultApiClient = new FHIRDefaultApiClient()

  const searchParameters: any = {
    category: 'exam',
    _count: 300,
    status: 'final',
  }

  if (
    getPatientIdentifiersForSearch(fhirAppointmentDetails.patient).length > 0
  ) {
    searchParameters['patient:Patient.identifier'] =
      getPatientIdentifiersForSearch(fhirAppointmentDetails.patient)
  }

  const response: any =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
      '/Observation?_sort=-_lastUpdated',
      fhirAppointmentDetails.appointment.id!,
      searchParameters
    )

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      obsList.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations: R4.IObservation[] = []
  for (let i = 0; i < obsList.length; i++) {
    if (obsList[i].encounter) {
      const ref: R4.IReference | undefined = obsList[i].encounter
      if (ref !== undefined) {
        const data = ref.reference ? ref.reference.split('/')[1] : ''
        if (data.length > 0) {
          if (data === encounterId) {
            finalObservations.push(obsList[i])
          }
        }
      }
    }
  }

  const latestObs: R4.IObservation[] =
    getLatestObservationsPhysical(finalObservations)

  for (let i = 0; i < latestObs.length; i++) {
    if (latestObs[i].issued) {
      if (groupedOccupationData.length > 0) {
        for (let j = 0; j < groupedOccupationData.length; j++) {
          if (
            moment(latestObs[i].issued).format('YYYY-MM-DD') ===
            moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
          ) {
            groupedOccupationData[j].occupation.push(latestObs[i])
          } else {
            groupedOccupationData.push({
              date: latestObs[i].issued ?? '',
              occupation: [latestObs[i]],
            })
          }
        }
      } else {
        groupedOccupationData.push({
          date: latestObs[i].issued ?? '',
          occupation: [latestObs[i]],
        })
      }
    }
  }
  groupedOccupationData = groupedOccupationData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          moment(t.date).format('YYYY-MM-DD') ===
          moment(value.date).format('YYYY-MM-DD')
      )
  )
  for (let i = 0; i < groupedOccupationData.length; i++) {
    finalGroupedOccupationData.push({
      date: groupedOccupationData[i].date,
      occupation: groupedOccupationData[i].occupation,
    })
  }
  localStorage.removeItem('vendorId')

  return finalGroupedOccupationData
}

async function getImaging(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  const serviceRequestData: R4.IServiceRequest[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    labTest: prescription.labTest,
    followUp: [],
    instructions: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    category: '363679005',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/ServiceRequest',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IOrganization> =
    R4.RTTI_Organization.decode(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IServiceRequest)
    }
  }

  prescriptionData.imaging = serviceRequestData
  return prescriptionData
}

async function getInstructionsOfAppointment(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let insList: R4.ICommunicationRequest[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: [],
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: [],
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    '_include:iterate': 'ServiceRequest:instantiates-canonical',
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/CommunicationRequest',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    insList = getCommunicationRequestFromBundle(response)
  }
  prescriptionData.instructions = insList
  return prescriptionData
}

async function getFollowUpAppointment(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let followUp: FhirAppointmentDetail[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: [],
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    pathyaApathya: [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    '_include:iterate': 'Appointment:actor',
    _include: 'Appointment:slot',
    'supporting-info':
      `Appointment/${fhirAppointmentDetails.appointment.id}` ?? '',
    'status:': 'booked',
  }

  const response: any = await fhirClient.doGetResource(
    '/Appointment',
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    followUp = getExpandedAppointmentFromBundle(response)
  }
  prescriptionData.followUp = followUp
  return prescriptionData
}

async function getSymptoms(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let symptoms: Symptoms[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: [],
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    category: 'chief-complaint',
    _sort: '_lastUpdated',
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    symptoms = getSymptomsAlongWithAssociated(response)
  }
  prescriptionData.chiefComplaintsData = symptoms
  return prescriptionData
}

async function getVitalsData(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let weightData: number = 0
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    patient: `Patient/${fhirAppointmentDetails.patient.id}`,
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    category: 'vital-signs',
    code: '29463-7',
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    const observationData: R4.IObservation[] =
      response.entry?.map((item: any) => item.resource as R4.IObservation) ?? []

    observationData.sort((a, b) =>
      (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
        ? -1
        : (a.issued ?? '') < (b.issued ?? '')
        ? 1
        : 0
    )
    weightData = observationData[0].valueQuantity
      ? observationData[0].valueQuantity.value ?? 0
      : 0
  }
  if (weightData > 0) prescriptionData.weight = weightData
  return prescriptionData
}

async function getPathyaApathyForTypeAAhara(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let aahara: PathyaApathyaDetails[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    weight: prescription.weight,
    kriyaDetails: [],
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${
      fhirAppointmentDetails.encounter
        ? fhirAppointmentDetails.encounter.id ?? ''
        : ''
    }`,
    code: 'ITA-8.1.2',
    _count: 20,
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Task',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    const taskObject: R4.ITask[] =
      response.entry?.map((item: any) => item.resource as R4.ITask) ?? []

    if (taskObject && taskObject.length > 0) {
      aahara = taskObject.map((task) => {
        const res: PathyaApathyaDetails = {
          recordedDate: '',
        }
        task.input?.forEach((input) => {
          if (input.type?.coding && input.type?.coding.length > 0) {
            if (input.type?.coding[0].code === 'ITA-8.1.3') {
              res.pathyaText = input.valueString ?? ''
            } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
              res.apathyaText = input.valueString ?? ''
            }
          }
        })
        return res
      })
    }
  }
  if (aahara.length > 0) prescriptionData.pathyaApathya = aahara
  return prescriptionData
}

async function getPathyaApathyForTypevihara(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let vihara: PathyaApathyaDetails[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: prescription.pathyaApathya,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    weight: prescription.weight,
    kriyaDetails: [],
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${
      fhirAppointmentDetails.encounter
        ? fhirAppointmentDetails.encounter.id ?? ''
        : ''
    }`,
    code: 'ITA-10.2.14',
    _count: 20,
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Task',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    const taskObject: R4.ITask[] =
      response.entry?.map((item: any) => item.resource as R4.ITask) ?? []

    if (taskObject && taskObject.length > 0) {
      vihara = taskObject.map((task) => {
        const res: PathyaApathyaDetails = {
          recordedDate: '',
        }
        task.input?.forEach((input) => {
          if (input.type?.coding && input.type?.coding.length > 0) {
            if (input.type?.coding[0].code === 'ITA-8.1.3') {
              res.pathyaText = input.valueString ?? ''
            } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
              res.apathyaText = input.valueString ?? ''
            }
          }
        })
        return res
      })
    }
  }
  if (vihara.length > 0) prescriptionData.prescriptionVihara = vihara
  return prescriptionData
}

export async function getKriyaListForTheEncounter(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let returnResult: KriyaDetail[] = []

  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: prescription.pathyaApathya,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    weight: prescription.weight,
    kriyaDetails: [],
  }

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceForAppointment(
      `/ServiceRequest`,
      fhirAppointmentDetails.appointment.id!,
      {
        encounter: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
        category: 'C0025122',
        intent: 'proposal,directive,order',

        _count: 500,
      }
    )

  if (response.type === 'FHIRErrorResponses') {
    prescriptionData.kriyaDetails = returnResult
    return prescriptionData
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    prescriptionData.kriyaDetails = returnResult
    return prescriptionData
  }
  const taskObject = resp.entry?.map(
    (entry) => entry.resource as R4.IServiceRequest
  )

  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: KriyaDetail = {
        recordedDate: task.authoredOn ?? '',
        kriya: task.code?.coding?.[0]!,
        medications: task.orderDetail?.map((e) => e.coding?.[0]!) ?? [],
        remarks: getNotesString(task.note),
        rawKriyaDetail: task,
        priority: task.priority,
      }

      return res
    })
  }
  prescriptionData.kriyaDetails = returnResult
  console.log('--------------------returnResult----------------', returnResult)
  return prescriptionData
}

export default referralHistorySlice.reducer
