/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'

import {
  Avatar,
  Box,
  Button,
  Divider,
  Card,
  Grid,
  Typography,
  CircularProgress,
  CardContent,
  InputAdornment,
  TextField,
  useTheme,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import { getAllNutritionCatalogValueSets } from 'redux/valueSet/nutrition_catalogue/dietTableSearchSlice'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutritionCatalogueSearchSlice'
import { isCurrentUnitIsClinic } from 'services/userDetailsService'
import { DietSearch } from './dietSearch'
import { AddDiet } from './addDiet'
import { DietTimeSettings } from './dietTImeSettings'

export interface UnitDetailProps {
  unitOrgDetail?: R4.IOrganization

  onUpdated?: () => void
}

export const DietSettings: React.FC<UnitDetailProps> = ({
  unitOrgDetail,
  onUpdated,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const settingsOption = sessionStorage.getItem('app')

  const menu =
    isCurrentUnitIsClinic() ||
    (settingsOption !== null && settingsOption === 'OPD')
      ? ['diet schedule', 'Diet Items']
      : ['diet schedule', 'IPD diet Item', 'Careplan diet Item']
  const [selectedTab, setSelectedTab] = React.useState(menu[0])

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  useEffect(() => {
    dispatch(getAllNutritionValueSets())
  }, [dispatch])

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      bgcolor={kDialogueBackground}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='flex-end'
        width='100%'
        px={1}
        paddingBottom={0.5}
      >
        <Button
          variant='contained'
          color='primary'
          onClickCapture={(e) => {
            setShowPopup(true)
          }}
        >
          Add New Diet Item
        </Button>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        height='5%'
        bgcolor={useTheme().palette.background.default}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box py={1} px={0.8}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Box display='flex' paddingX={2} paddingY={1}>
                      <Avatar alt={props.unitOrgDetail.name} src='test.jpg' />
                    </Box> */}

                    <Grid item xs>
                      <Box display='flex' flexDirection='Column' height='50%'>
                        <Box paddingBottom={1}>
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            style={{
                              textTransform: 'uppercase',
                              fontFamily: 'Open Sans',

                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Diet Settings
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        height='100%'
        width='100%'
        style={{
          backgroundColor: 'white',
        }}
      >
        <Box width='100%'>
          <Card
            elevation={0}
            style={{
              padding: 0,
              margin: 0,
              borderRadius: 8,
              //   backgroundColor: '#FFFFFFAF',
            }}
          >
            <CardContent
              style={{
                padding: 0,
                border: 1,
              }}
            >
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  bgcolor={kDialogueBackground}
                >
                  <Box paddingTop={1}>
                    <WelloTabs
                      preSelectedTab={t('labelCommon:' + `${selectedTab}`)}
                      onTabChange={(e) => handleChangeTab(e)}
                      menu={menu}
                    />
                  </Box>
                </Box>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%', height: '2px' }}
                  color='primary'
                />
                {/* <Box display='flex' flexDirection='column' width='100%'>
                  <Box>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        <Box paddingLeft={1.5} py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                            }}
                          >
                            Cohort
                          </Typography>
                        </Box>
                      </FormLabel>
                      <Box paddingLeft={2.1} py={1}>
                        <RadioGroup
                          id='add_unit_address_choice_rad_group'
                          row
                          aria-label='graphType'
                          value={cohortFilterData[0]}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {}}
                        >
                          <FormControlLabel
                            value='IPD'
                            control={<Radio color='primary' />}
                            label='IPD patients with elevated BP at visit start'
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                  </Box>
                </Box> */}
                {selectedTab === menu[0] && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <DietTimeSettings />
                  </Box>
                )}

                {selectedTab === menu[1] && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <DietSearch dietCode='ipd-diet' />
                  </Box>
                )}

                {selectedTab === menu[2] && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <DietSearch dietCode='careplan-diet' />
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {showPopup && (
        <AddDiet
          open={showPopup}
          onCancelClick={() => {
            setShowPopup(false)
          }}
          onAdd={(dietData?: R4.ICatalogEntry) => {
            dispatch(getAllNutritionCatalogValueSets())
            setShowPopup(false)
          }}
        />
      )}
    </Box>
  )
}
