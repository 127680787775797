import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerRoleDetails,
  isOrgAdmin,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'

import {
  getTherapiesPlanFromBundle,
  getTreatmentPlanFromBundle,
  getTreatmentPlanFromBundleForPT,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import { ViewPhysioTherapiesOfEncounterType } from './viewPhysioTherapiesOfEncounterStatusType'

const initialState: ViewPhysioTherapiesOfEncounterType = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const viewPhysioTherapiesOfEncounterSlice = createSlice({
  name: 'viewPhysioTherapiesOfEncounterSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<ViewPhysioTherapiesOfEncounterType>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.treatmentList = action.payload.treatmentList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const requestForTherapiesForIpd =
  (appointmentId: string, splitView?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ViewPhysioTherapiesOfEncounterType = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(viewPhysioTherapiesOfEncounterSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      //   const searchParameters: any = {
      //     'based-on': appointmentId,
      //     category: '304,305',
      //     '_revInclude:iterate': 'Procedure:based-on',
      //     '_include:iterate': 'ServiceRequest:encounter',
      //   }

      //   const newLocal = `ServiceRequest?_revInclude:iterate=Encounter:based-on`
      //   const response: any = await fhirClient.doGetResourceForAppointmentIPD(
      //     newLocal,
      //     searchParameters
      //   )
      const searchParameters: any = {
        'based-on': appointmentId,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `Encounter?_r[…]ude=Encounter:part-of&_revinclude=ServiceRequest:encounter&_revinclude=Procedure:encounter&_count=1000`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingTreatment = true
        state.searchingTreatment = false

        dispatch(
          viewPhysioTherapiesOfEncounterSlice.actions.updatedStatus(state)
        )
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          let vitalLisData: TreatmentPlanData[] = []
          if (isPhysiotherapist() || isOrgAdmin() || isUnitAdmin())
            vitalLisData = getTreatmentPlanFromBundleForPT(
              proceduresResponse,
              splitView ?? ''
            )
          else
            vitalLisData = getTreatmentPlanFromBundle(
              proceduresResponse,
              'details'
            )
          vitalLisData.sort((a, b) =>
            a.status.toLowerCase().localeCompare(b.status.toLowerCase())
          )
          state.resultsAvailable = true
          state.searchingTreatment = false
          state.treatmentList = vitalLisData.sort((a, b) =>
            a.status.toLowerCase().localeCompare(b.status.toLowerCase())
          )
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingTreatment = false
          dispatch(
            viewPhysioTherapiesOfEncounterSlice.actions.updatedStatus(state)
          )
        } else {
          const errorSearchDoctor: ViewPhysioTherapiesOfEncounterType = {
            searchingTreatment: false,
            errorWhileSearchingTreatment: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            viewPhysioTherapiesOfEncounterSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      }
    } catch (error) {
      console.error(error)
      const errorSearchDoctor: ViewPhysioTherapiesOfEncounterType = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        viewPhysioTherapiesOfEncounterSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default viewPhysioTherapiesOfEncounterSlice.reducer
