import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import React from 'react'
import { SubscriptionOfferingLineWiseDetail } from 'views/components/consultation/plan/subscriptions/planDetails'

export interface SubscriptionOfferingsDetailsPopUp {
  open: boolean
  offer?: CPGForTable
  onClose?: () => void
}
export const SubscriptionOfferingsDetails: React.FC<SubscriptionOfferingsDetailsPopUp> =
  ({ offer, onClose, open }) => {
    const theme = useTheme()

    if (offer) {
      return (
        <Dialog
          open={open}
          onClose={() => {}}
          aria-labelledby='responsive-dialog-title'
          maxWidth='md'
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle id='scroll-dialog-title'>
            <Box display='flex' flexDirection='row'>
              <Box flexGrow={4}>{offer.cpgName}</Box>
              <Box id='box_cancel'>
                <CloseOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    if (onClose) onClose()
                  }}
                  id='cancel_ico'
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{
              overflow: 'auto',
              padding: 0,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <SubscriptionOfferingLineWiseDetail
              key={offer.cpgId}
              offer={{
                cpgId: offer.cpgId,
                rawCpg: offer.rawCpg,
                planName: offer.cpgName,
                subscriptionPlans: offer.offeringPlans,
              }}
            />
          </DialogContent>
        </Dialog>
      )
    }

    return <></>
  }
