import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  useTheme,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { kBackgroundPaper, kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'

import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  getStartTime,
  getEndTime,
} from 'utils/appointment_handle/medications_util'
import { getTomorrowDate, getAddedDate } from 'utils/dateUtil'
import { isAfter } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  addNewKriyaDetails,
  editKriyaDetails,
} from 'utils/sopaNotes/plan/kriyaHelpers'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import CustomizedDividersForKriya from 'views/components/LeftMenu/toggleButtonSelector'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTextField } from 'wello-web-components'
import { KriyaDetailTile } from '../plan/ayurveda/kriyaDetailTile'

export interface KriyaDetail {
  kriya?: R4.ICoding
  medications?: R4.ICoding[]
  remarks?: string
  recordedDate?: string
  dosageQuantity?: number
  dosageQuantityType?: string
  startFrom?: Date
  till?: Date
  rawKriyaDetail?: R4.IServiceRequest
  time?: string
  timeData?: string
  dosageQuantityTypeCoding?: R4.ICoding
  timeDisplay?: string
}
interface Props {
  open: boolean
  patientReference: R4.IReference
  encounterReference: R4.IReference
  onClose: () => void
  onLabDiagnosticsAdded: () => void
  existingKriyaDetails: KriyaDetail
}
export const ExistingKriyaDetails: React.FC<Props> = ({
  open,
  onClose,
  onLabDiagnosticsAdded,
  patientReference,
  encounterReference,
  existingKriyaDetails,
}) => {
  const { t } = useTranslation(['common'])
  const theme = useTheme()

  const [selectedDatePart, setSelectedDatePart] = useState<string>(
    getStartTime(
      existingKriyaDetails.startFrom
        ? moment(existingKriyaDetails.startFrom).toDate()
        : new Date()
    )
  )
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>(
    getEndTime(
      existingKriyaDetails.startFrom
        ? moment(existingKriyaDetails.startFrom).toDate()
        : new Date(),
      existingKriyaDetails.till
        ? moment(existingKriyaDetails.till).toDate()
        : new Date()
    )
  )

  const [start, setStart] = React.useState<Date>(
    new Date(`2020-01-01 ${existingKriyaDetails.timeDisplay!.split(' ')[0]}`)
  )

  const [currentKriya, setCurrentKriya] =
    useState<KriyaDetail>(existingKriyaDetails)

  const [addAnother, setAddAnother] = React.useState<boolean>(true)

  const [kriyas, setKriyas] = useState<KriyaDetail[]>([])

  function resetForm() {
    setCurrentKriya({
      kriya: undefined,
      medications: undefined,
      remarks: undefined,
      recordedDate: undefined,
    })
    setformState(_.random(0, 1000).toString())
  }

  const [formState, setformState] = useState(_.random(0, 1000).toString())

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const dispatch = useDispatch()

  function addKriyaToKriyasList(pushToSave?: boolean) {
    if (currentKriya.kriya && currentKriya.kriya.code) {
      const tempKriyas = [...kriyas]

      const kriya: KriyaDetail = {
        ...currentKriya,
      }
      tempKriyas.push(kriya)
      resetForm()
      setKriyas(tempKriyas)

      if (pushToSave) {
        addCurrentDetails(tempKriyas)
      }
    }
  }

  function addCurrentDetails(kriyasToUpload: KriyaDetail[]) {
    if (kriyasToUpload && kriyasToUpload.length > 0) {
      setUpdateStatus({ requesting: true })
      editKriyaDetails(
        patientReference,
        encounterReference,
        kriyasToUpload
      ).then((e) => {
        if (e) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Kriya updated successfully'))
          setKriyas([])
          resetForm()
          onLabDiagnosticsAdded()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showErrorAlert('Error while updating Kriya. Please try again later')
          )
        }
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Edit Kriya (Procedure)</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          px={12}
          display='flex'
          flexDirection='column'
          marginTop={1}
          marginBottom={2}
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box display='flex' flexDirection='row' width='42.5%'>
              <Box width='100%' display='flex' flexDirection='column'>
                <Box width='100%' key={`${formState}kriya_search`}>
                  <ValueSetSelectSingle
                    title='Select Kriya (Procedure)'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-treatments-vs'
                    id='kriyas'
                    fuzzySearch={true}
                    preSelectedOptions={currentKriya.kriya}
                    disabled={false}
                    onOptionSelected={(selectedKriya) => {
                      setCurrentKriya({
                        ...currentKriya,
                        kriya: selectedKriya,
                      })
                    }}
                    helperText={undefined}
                  />
                </Box>

                <Box width='100%' key={`${formState}medicine_search`}>
                  <ValueSetSelectMultiple
                    title='Select Medicines'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-med-vs'
                    id='medicines'
                    disabled={false}
                    fuzzySearch={true}
                    pluralWord='Medicines'
                    singularWorld='Medicine'
                    onOptionSelected={(selectedMedicines) => {
                      console.log(
                        '------------selectedMedicines---------------',
                        selectedMedicines
                      )
                      setCurrentKriya({
                        ...currentKriya,
                        medications: selectedMedicines,
                      })
                    }}
                    preSelectedOptions={currentKriya.medications}
                    helperText={undefined}
                  />
                </Box>
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                  key={`${formState}dosage_unit`}
                >
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box width='30%' paddingRight={1}>
                      <WelloTextField
                        title={t('labelDosage:resourceName')}
                        textProps={{
                          id: `med_dosage`,
                          placeholder: 'Dosage',
                          error:
                            currentKriya.dosageQuantity! <= 0 ||
                            currentKriya.dosageQuantity! >= 10000 ||
                            Number.isNaN(currentKriya.dosageQuantity!),
                          type: 'number',
                          value: currentKriya.dosageQuantity,
                          disabled: currentKriya.dosageQuantityType
                            ? currentKriya.dosageQuantityType === '422135004'
                            : false,

                          // value: tests.scheduleSummary,
                          helperText:
                            currentKriya.dosageQuantity! <= 0 ||
                            Number.isNaN(currentKriya.dosageQuantity!)
                              ? `${t('labelCommon:invlaid_dosages')}`
                              : '',
                          inputProps: {
                            min: 0,
                            max: 10000,
                            step: 0.1,
                          },

                          onChange: (changeData) => {
                            if (changeData) {
                              setCurrentKriya({
                                ...currentKriya,
                                dosageQuantity: parseFloat(
                                  parseFloat(changeData.target.value).toFixed(1)
                                ),
                              })
                            }
                          },
                        }}
                      />
                    </Box>
                    <Box width='70%' paddingLeft={1}>
                      <ValueSetSelectSingleDisplaySinge
                        key='wellopathy-relationship-vs_medicines'
                        title='Unit'
                        url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                        id='med_units'
                        fuzzySearch={true}
                        disabled={false}
                        alwaysShowAllOptions={true}
                        preSelectedOptions={
                          currentKriya.dosageQuantityTypeCoding
                        }
                        onOptionSelected={(
                          conditionsData: R4.ICoding | undefined
                        ) => {
                          if (conditionsData) {
                            if (conditionsData.code === '422135004') {
                              setCurrentKriya({
                                ...currentKriya,
                                dosageQuantityType: conditionsData.code,
                                dosageQuantity: undefined,
                              })
                            } else {
                              setCurrentKriya({
                                ...currentKriya,
                                dosageQuantityType: conditionsData.code,
                              })
                            }
                          }
                        }}
                        helperText={undefined}
                        placeHolder='Unit'
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width='15%' />
            <Box display='flex' flexDirection='row' width='42.5%'>
              <Box width='100%' display='flex' flexDirection='column'>
                <Box width='100%' key={`${formState}start_from`}>
                  {selectedDatePart !== 'custom' && (
                    <CustomizedDividersForKriya
                      key={`${formState}start_from`}
                      size='small'
                      menuLabel={t('labelCommon:start From')}
                      options={[
                        {
                          displayText: `${t('labelCommon:today')}`,
                          value: 'today',
                        },
                        {
                          displayText: `${t('labelCommon:tomorrow')}`,
                          value: 'tomo',
                        },
                        {
                          displayText: `${t('labelCommon:custom')}`,
                          value: 'custom',
                        },
                      ]}
                      onSelectionChanged={(selectedValue) => {
                        setSelectedDatePart(selectedValue)

                        if (selectedValue === 'today') {
                          setStart(
                            new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                          )
                          setCurrentKriya({
                            ...currentKriya,
                            startFrom: new Date(),
                            time: moment().format('HH:mm'),
                            timeData: moment().format('HH:mm A'),
                          })
                        }
                        if (selectedValue === 'tomo') {
                          setStart(new Date(`2020-01-01 00:00`))
                          setCurrentKriya({
                            ...currentKriya,
                            startFrom: getTomorrowDate(),
                            time: '00:00',
                            timeData: '00:00 AM',
                          })
                        }
                        if (selectedValue === 'custom') {
                          setStart(new Date(`2020-01-01 00:00`))
                          setCurrentKriya({
                            ...currentKriya,
                            time: '00:00',
                            timeData: '00:00 AM',
                          })
                        }
                      }}
                      preSelectedValue={selectedDatePart}
                    />
                  )}
                  {selectedDatePart === 'custom' && (
                    <Box width='100%' display='flex' flexDirection='row'>
                      <CustomizedDividersForKriya
                        key={`${formState}start_from`}
                        size='small'
                        menuLabel={t('labelCommon:start From')}
                        options={[
                          {
                            displayText: `${t('labelCommon:today')}`,
                            value: 'today',
                          },
                          {
                            displayText: `${t('labelCommon:tomorrow')}`,
                            value: 'tomo',
                          },
                        ]}
                        onSelectionChanged={(selectedValue) => {
                          setSelectedDatePart(selectedValue)
                          if (selectedValue === 'today') {
                            setStart(
                              new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                            )
                            setCurrentKriya({
                              ...currentKriya,
                              startFrom: new Date(),
                              time: moment().format('HH:mm'),
                              timeData: moment().format('hh:mm A'),
                            })
                          }
                          if (selectedValue === 'tomo') {
                            setStart(new Date(`2020-01-01 00:00`))
                            setCurrentKriya({
                              ...currentKriya,
                              startFrom: getTomorrowDate(),
                              time: '00:00',
                              timeData: '00:00 AM',
                            })
                          }
                        }}
                        preSelectedValue={selectedDatePart}
                        width='150'
                      />
                      <Box width='53%' paddingTop={1.7} paddingLeft={1.5}>
                        <TextField
                          key='custom'
                          variant='outlined'
                          style={{
                            marginTop: '16px',
                          }}
                          size='small'
                          value={
                            currentKriya.startFrom
                              ? moment(currentKriya.startFrom).format(
                                  'YYYY-MM-DD'
                                )
                              : moment().format('YYYY-MM-DD')
                          }
                          onChange={(val) => {
                            let tillDateLocal = currentKriya.till

                            // setStartDate(new Date(val.target.value))

                            if (selectedWeekPart === '3d') {
                              if (
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                ) === moment().format('YYYY-MM-DD')
                              ) {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(2, 'days')
                                  .toDate()
                              } else {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(2, 'days')
                                  .toDate()
                              }
                            }

                            if (selectedWeekPart === '3d') {
                              if (
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                ) === moment().format('YYYY-MM-DD')
                              ) {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(2, 'days')
                                  .toDate()
                              } else {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(2, 'days')
                                  .toDate()
                              }
                            }

                            if (selectedWeekPart === '1W') {
                              if (
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                ) === moment().format('YYYY-MM-DD')
                              ) {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(6, 'days')
                                  .toDate()
                              } else {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(6, 'days')
                                  .toDate()
                              }
                            }
                            if (selectedWeekPart === '2W') {
                              if (
                                moment(new Date(val.target.value)).format(
                                  'YYYY-MM-DD'
                                ) === moment().format('YYYY-MM-DD')
                              ) {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(13, 'days')
                                  .toDate()
                              } else {
                                tillDateLocal = moment(
                                  moment(new Date(val.target.value)).format(
                                    'YYYY-MM-DD'
                                  )
                                )
                                  .add(13, 'days')
                                  .toDate()
                              }
                            }
                            setCurrentKriya({
                              ...currentKriya,
                              startFrom: new Date(val.target.value),
                              till: tillDateLocal,
                            })
                          }}
                          type='date'
                          inputProps={{
                            min: moment
                              .utc(new Date())
                              .local()
                              .format('YYYY-MM-DD'),
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  width='100%'
                  key={`${formState}time_day`}
                  display='flex'
                  flexDirection='column'
                >
                  <WelloFormItemLabel title='Time of the Day' case='yes' />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {addAnother && (
                      <TimePicker
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            //   disabled={!isAfterSame('09:00', ',')}
                            id='obj_12'
                            //   InputProps={{
                            //     style: {
                            //       paddingTop: 0,
                            //       paddingBottom: 0,
                            //     },
                            //   }}
                          />
                        )}
                        value={start}
                        onChange={(newValue) => {
                          if (newValue) {
                            setStart(newValue)
                            setCurrentKriya({
                              ...currentKriya,
                              time: moment(newValue).format('HH:mm'),
                            })
                          }
                        }}
                        minTime={
                          moment().format('YYYY-MM-DD') ===
                          moment(currentKriya.startFrom).format('YYYY-MM-DD')
                            ? new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                            : new Date(0, 0, 0, 0)
                        }
                      />
                    )}
                    {addAnother === false && (
                      <TimePicker
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            //   disabled={!isAfterSame('09:00', ',')}
                            id='obj_12'
                            //   InputProps={{
                            //     style: {
                            //       paddingTop: 0,
                            //       paddingBottom: 0,
                            //     },
                            //   }}
                          />
                        )}
                        value={start}
                        onChange={(newValue) => {
                          if (newValue) {
                            setStart(newValue)
                            setCurrentKriya({
                              ...currentKriya,
                              time: moment().format('HH:mm'),
                              timeData: moment().format('hh:mm A'),
                            })
                          }
                        }}
                        minTime={
                          moment().format('YYYY-MM-DD') ===
                          moment(currentKriya.startFrom).format('YYYY-MM-DD')
                            ? new Date(`2020-01-01 ${moment().format('HH:mm')}`)
                            : new Date(0, 0, 0, 0)
                        }
                      />
                    )}
                  </LocalizationProvider>
                </Box>

                <Box
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  paddingTop={1}
                  key={`${formState}duration_data`}
                >
                  <CustomizedDividersForKriya
                    key={`${formState}duration_data`}
                    size='small'
                    menuLabel='Duration'
                    options={[
                      {
                        displayText: `${t('labelCommon:3_Days')}`,
                        value: '3d',
                      },
                      {
                        displayText: `${t('labelCommon:1_Week')}`,
                        value: '1W',
                      },
                      {
                        displayText: `2 Weeks`,
                        value: '2W',
                      },
                      {
                        displayText: `${t('labelCommon:custom')}`,
                        value: 'custom',
                      },
                    ]}
                    onSelectionChanged={(selectedValue) => {
                      let tillDateLocal = currentKriya.till
                      setSelectedWeekPart(selectedValue)
                      if (selectedValue === '3d') {
                        if (
                          moment(currentKriya.startFrom).format(
                            'YYYY-MM-DD'
                          ) === moment().format('YYYY-MM-DD')
                        ) {
                          if (selectedDatePart !== 'custom') {
                            tillDateLocal = getAddedDate(2)
                          }
                        } else if (selectedDatePart !== 'custom') {
                          tillDateLocal = getAddedDate(3)
                        }
                      }
                      if (selectedValue === '1W') {
                        if (
                          moment(currentKriya.startFrom).format(
                            'YYYY-MM-DD'
                          ) === moment().format('YYYY-MM-DD')
                        ) {
                          if (selectedDatePart === 'custom') {
                            tillDateLocal = moment(
                              moment(currentKriya.startFrom).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(6, 'days')
                              .toDate()
                          } else {
                            tillDateLocal = getAddedDate(6)
                          }
                        } else if (selectedDatePart === 'custom') {
                          tillDateLocal = moment(
                            moment(currentKriya.startFrom).format('YYYY-MM-DD')
                          )
                            .add(6, 'days')
                            .toDate()
                        } else {
                          tillDateLocal = getAddedDate(7)
                        }
                      }
                      if (selectedValue === '2W') {
                        if (
                          moment(currentKriya.startFrom).format(
                            'YYYY-MM-DD'
                          ) === moment().format('YYYY-MM-DD')
                        ) {
                          if (selectedDatePart === 'custom') {
                            tillDateLocal = moment(
                              moment(currentKriya.startFrom).format(
                                'YYYY-MM-DD'
                              )
                            )
                              .add(13, 'days')
                              .toDate()
                          } else {
                            tillDateLocal = getAddedDate(13)
                          }
                        } else if (selectedDatePart === 'custom') {
                          tillDateLocal = moment(
                            moment(currentKriya.startFrom).format('YYYY-MM-DD')
                          )
                            .add(13, 'days')
                            .toDate()
                        } else {
                          tillDateLocal = getAddedDate(14)
                        }
                      }
                      setCurrentKriya({
                        ...currentKriya,
                        till: tillDateLocal,
                      })
                    }}
                    preSelectedValue={selectedWeekPart}
                  />
                  {selectedWeekPart === 'custom' && (
                    <TextField
                      key='week_custom'
                      variant='outlined'
                      style={{
                        marginTop: '16px',
                      }}
                      id='custom_week'
                      size='small'
                      onChange={(val) => {
                        setCurrentKriya({
                          ...currentKriya,
                          till: new Date(val.target.value),
                        })

                        // setTillDate(new Date(val.target.value))
                      }}
                      type='date'
                      value={
                        currentKriya.startFrom
                          ? moment(currentKriya.till).format('YYYY-MM-DD')
                          : moment().format('YYYY-MM-DD')
                      }
                      inputProps={{
                        min: moment
                          .utc(currentKriya.startFrom)
                          .local()
                          .format('YYYY-MM-DD'),
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%'>
              <WelloFormItemLabel title='Additional Notes' />
              <TextField
                id='txt_kriya_notes'
                variant='outlined'
                size='small'
                fullWidth
                multiline
                value={currentKriya.remarks ?? ''}
                onChange={(e) => {
                  setCurrentKriya({
                    ...currentKriya,
                    remarks: e.target.value,
                  })
                }}
              />
            </Box>
          </Box>
          {kriyas.length > 0 && (
            <Box display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title='Selected Kriyas' />
                {kriyas.map((kriya, index) => (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    style={{
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                    bgcolor={
                      index % 2 === 1 ? kDialogueBackground : kBackgroundPaper
                    }
                    key={_.snakeCase(
                      kriya!.kriya!.code ?? kriya!.kriya!.display ?? ''
                    )}
                  >
                    <KriyaDetailTile kriyaDetail={kriya} isReadonly={true} />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          px={10.5}
          alignItems='center'
          width='100%'
          justifyContent='flex-start'
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            width='50%'
          >
            <Box>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                }}
                variant='outlined'
                id='cancel'
                disableElevation
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-end'
            width='50%'
          >
            <Box>
              <Button
                onClick={() => {
                  if (currentKriya.kriya === undefined) {
                    addCurrentDetails(kriyas)
                  } else {
                    addKriyaToKriyasList(true)
                  }
                }}
                variant='contained'
                color='primary'
                id='save'
                disableElevation
                disabled={
                  (currentKriya.kriya === undefined && kriyas.length === 0) ||
                  updateStatus.requesting
                }
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
