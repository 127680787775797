import { Avatar, Box, Chip } from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getProfilePicDoctor } from 'utils/fhirResourcesHelper'

interface Props {
  onPractitionerSelected?: (selectedPractioner: PractitionerWithRole[]) => void
  practitionerList: PractitionerWithRole[]
  id?: string
  valueField?: string
  disableSelection?: boolean
}

export const TherapistListViewMobile: React.FC<Props> = ({
  onPractitionerSelected,
  practitionerList,
  id,
  valueField,
  disableSelection,
}: Props) => {
  const loggendInUser = getCurrentUserPractitionerDetails()?.id
  function getPreSelectedPractitioners(): string[] {
    console.log('loggendInUser', loggendInUser)
    const index = practitionerList.findIndex(
      (item) => item.id === loggendInUser
    )
    console.log('index', index)
    if (loggendInUser !== undefined && index > -1) {
      console.log('index', [loggendInUser])
      return [loggendInUser]
    }
    return []
  }
  const dispatch = useDispatch()
  const [selectedIds, setSelectedIds] = useState<string[]>(
    getPreSelectedPractitioners()
  )
  const { t } = useTranslation()

  function handleChecked(idCheck: string) {
    if (selectedIds.includes(idCheck)) {
      const tempIds: string[] = selectedIds.filter((item) => item !== id)
      updatePractitioners(tempIds)
    } else {
      const tempIds: string[] = [...selectedIds]
      tempIds.push(idCheck)
      updatePractitioners(tempIds)
    }
  }
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

  function updatePractitioners(filteredIds: string[]) {
    if (onPractitionerSelected) {
      const selectedDoctors: PractitionerWithRole[] = [
        ...(practitionerList ?? []),
      ].filter((val) => filteredIds.includes(val.id ?? ''))
      onPractitionerSelected(selectedDoctors)
    }
    setSelectedIds(filteredIds)
  }

  const handleChange = (event: any) => {
    if (
      selectedIds.length > 0 &&
      selectedIds.length === practitionerList.length
    ) {
      const tempIds: string[] = []
      updatePractitioners(tempIds)
      setIsAllSelected(false)
    } else {
      const tempIds: string[] = []
      if (practitionerList) {
        for (let i = 0; i < practitionerList.length; i++) {
          tempIds.push(practitionerList[i].id ?? '')
        }
      }
      updatePractitioners(tempIds)
      setIsAllSelected(true)
    }
  }

  useEffect(() => {
    if (selectedIds.length > 0) {
      updatePractitioners(selectedIds)
    }
  }, [])

  return (
    <Box width='100%' paddingTop={1} display='flex' flexDirection='column'>
      <Box>
        {/* <Chip
          title='All'
          style={{
            fontSize: '18px',
            fontWeight: '500',
            padding: '2px',
            margin: '4px',
            height: '35px',
          }}
          id='all'
          label='All'
          variant={isAllSelected ? 'default' : 'outlined'}
          key='key_all'
          size='medium'
          clickable
          color='primary'
          onClick={(e) => {
            handleChange(e)
          }}
        /> */}
        {/* <FormControlLabel
          control={
            <Checkbox
              size='small'
              onChange={handleChange}
              name='checkedB'
              color='primary'
            />
          }
          label={t('labelCommon:select_all')}
        /> */}
      </Box>

      <Box
        display='flex'
        flexWrap='wrap'
        width='75%'
        justifyContent='center'
        flexDirection='column'
      >
        {practitionerList &&
          practitionerList.map((item: PractitionerWithRole, index: any) => (
            <Box display='flex' flexDirection='row' key={item.name} width='75%'>
              <Chip
                title={item.fullName}
                style={{
                  fontSize: '12px',
                  fontWeight:
                    selectedIds.indexOf(item.id ?? '') !== -1 ? 500 : 400,
                  padding: '2px',
                  margin: '4px',

                  backgroundColor: '#DBDBF3',

                  color:
                    selectedIds.indexOf(item.id ?? '') !== -1
                      ? '#4542D7'
                      : '#19274F',
                  lineHeight: '24px',
                }}
                label={item.fullName}
                variant={
                  selectedIds.indexOf(item.id ?? '') !== -1
                    ? 'outlined'
                    : 'default'
                }
                key={`key_${item.id}`}
                id={`doc${index}`}
                size='medium'
                clickable={!disableSelection}
                color='primary'
                onClick={(e) => {
                  console.log('item.id', item.id)
                  let tempIds: string[] = []
                  if (selectedIds.includes(item.id ?? '')) {
                    tempIds = selectedIds.filter(
                      (newItem) => newItem !== item.id ?? ''
                    )
                  } else {
                    tempIds = [...selectedIds]
                    tempIds.push(item.id ?? '')
                  }
                  if (tempIds.length === practitionerList.length) {
                    setIsAllSelected(true)
                  } else {
                    setIsAllSelected(false)
                  }
                  updatePractitioners(tempIds)
                }}
              />
            </Box>
          ))}
      </Box>
    </Box>
  )
}
