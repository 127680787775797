import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { createContext } from 'react'

export interface VisitAppointmentContextData {
  patient: R4.IPatient | null
  encounter?: R4.IEncounter
  onRecommendationAdditionUpdate?: (
    category:
      | 'condition'
      | 'goal'
      | 'lab'
      | 'medication'
      | 'specialistReferral'
      | 'instruction'
      | 'careplan-activities'
      | 'careplan-follow-up'
      | 'kriyas'
  ) => void
  // visitReference?: FhirAppointmentDetail | FhirActiveIPDDetailsForMedicalRole
}

const VisitAppointmentContext = createContext<VisitAppointmentContextData>({
  patient: null,
})

export default VisitAppointmentContext
