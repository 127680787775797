import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  LinearProgress,
  List,
  ListSubheader,
  Paper,
  Typography,
  withStyles,
  TextField,
  IconButton,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { ToggleButtonGroup } from '@material-ui/lab'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { requestAppointmentsCountForToday } from 'redux/appointments/appointmentCount/appointmentCountSlice'
import { requestIPDAppCountForToday } from 'redux/clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import { requestForDateWiseIpdAppointmentLists } from 'redux/clinic/ipdmanagement/therapiesList/therapiesListSlice'
import { searchMedicalProviders } from 'redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'
import { RootState } from 'redux/rootReducer'
import { useWindowSize } from 'rooks'
import {
  getCurrentUserUnitDetails,
  isTherapist,
} from 'services/userDetailsService'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { desktopScreenWidth } from 'utils/screen_utils'
import { TherapyDisplayTile } from 'views/components/ipd/ipdAppointmentList/therapyDisplayTile'
import { TherapyListHeader } from 'views/components/ipd/ipdAppointmentList/TherapyListHeader'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import '../../../App.css'
import CustomizedDividers, {
  ToggleOption,
} from '../../components/toggle_selector'

const therapyOptions = [
  {
    id: 'active',
    display: 'Scheduled',
  },
  {
    id: 'completed',
    display: 'Completed',
  },
  {
    id: 'revoked',
    display: 'Cancelled',
  },
]
export interface PageProps {
  type: 'opd' | 'ipd' | 'opd-daycare' | 'appointment'
}

export const ClinicalTherapistHomePageLarge: React.FC<PageProps> = ({
  type,
}: PageProps) => {
  const selectedDateQ = useSelector(
    (state: RootState) => state.therapiesListSlice.selectedDate
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] =
    useState<Date | undefined>(selectedDateQ)

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.therapiesListSlice
  )

  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    appointmentSearchSlice.selectedStatuses
  )

  const [selectedIpd, setSelectedIPd] = useState<FhirClinicTherapyBasic>()

  const [viewType, setViewType] = useState<string | undefined>('list')

  const [name, setName] = useState<string>('')

  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())

  const navigateToPath = (path: string) => {
    // history.replace(path)
    window.location.href = path
  }

  const { innerWidth } = useWindowSize()

  function openAppointment(appointment: FhirClinicTherapyBasic) {
    navigateToPath(
      `/${getVendorPartId()}/viewIpdAppointment?id=${
        appointment.mainServiceRequestId
      }`
    )
  }

  const menu: ToggleOption[] = [-4, -3, -2, -1, 0, 1].map((e) => ({
    value: moment().add(e, 'days').startOf('day').format(),
    displayText:
      moment()
        .add(e, 'days')
        .calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'DD MMM',
          lastDay: '[Yesterday]',
          lastWeek: 'DD MMM',
          sameElse: 'DD MMM',
        })
        .split(' at')[0] ?? '',
  }))

  const statusMenu: ToggleOption[] = therapyOptions.map((e) => ({
    value: e.id,
    displayText: e.display,
  }))

  useEffect(() => {
    dispatch(
      requestForDateWiseIpdAppointmentLists(
        type,
        name,
        selectedDate,
        selectedIpdStatuses,
        undefined,
        undefined,
        undefined
      )
    )
  }, [dispatch, selectedDate, selectedIpdStatuses])

  useEffect(() => {
    dispatch(searchMedicalProviders('', '', '106296000'))
  }, [])

  return (
    <Paper
      elevation={0}
      id={type}
      key={type}
      style={{
        backgroundColor: 'transparent',
        overflow: 'auto',
        height: '100vh',
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        overflow='auto'
        style={{ overflow: 'auto', height: '100%' }}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          px={1}
          style={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <Box
            flexDirection='row'
            width='100%'
            display='flex'
            py={1}
            justifyContent='center'
            alignContent='center'
            alignItems='center'
          >
            <Typography variant='h6' color='initial'>
              Therapies
            </Typography>
          </Box>
          <Box
            flexDirection={innerWidth! < 800 ? 'column' : 'row'}
            width='100%'
            display='flex'
            py={0.5}
            justifyContent={innerWidth! < 800 ? 'flex-start' : 'space-between'}
          >
            <Box display='flex' flexDirection='row'>
              <TextField
                variant='outlined'
                onChange={(event) => {
                  dispatch(
                    requestForDateWiseIpdAppointmentLists(
                      type,
                      event.target.value,
                      selectedDate,
                      selectedIpdStatuses,
                      undefined,
                      undefined,
                      undefined
                    )
                  )
                  setName(event.target.value)
                }}
                size='small'
                autoFocus={true}
                placeholder='Search By Patient Name'
                value={name}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <img
                        src={`${process.env.PUBLIC_URL}/search.png`}
                        alt='s'
                        style={{ paddingLeft: name ? 1 : 0 }}
                      />
                    </IconButton>
                  ),
                  endAdornment: name && (
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setName('')
                        dispatch(
                          requestForDateWiseIpdAppointmentLists(
                            type,
                            name,
                            selectedDate,
                            selectedIpdStatuses,
                            undefined,
                            undefined,
                            undefined
                          )
                        )
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              alignItems='center'
              py={innerWidth! < 800 ? 0.5 : 0}
            >
              <Box width='60px'>
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{
                    paddingRight: '8px',
                  }}
                >
                  Day :
                </Typography>
              </Box>
              <CustomizedDividers
                onSelectionChanged={(value) => {
                  if (value !== null) {
                    if (type === 'appointment') {
                      dispatch(
                        requestAppointmentsCountForToday(
                          moment(value).startOf('day').toDate()
                        )
                      )
                    } else {
                      dispatch(
                        requestIPDAppCountForToday(
                          moment(value).startOf('day').toDate()
                        )
                      )
                    }

                    setSelectedDate(moment(value).startOf('day').toDate())
                  }
                }}
                options={menu}
                preSelectedValue={moment(selectedDate).startOf('day').format()}
                id='therapy'
              />
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              alignItems='center'
              py={innerWidth! < 800 ? 0.5 : 0}
            >
              <Box width='60px'>
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{
                    paddingRight: '8px',
                  }}
                >
                  Status :
                </Typography>
              </Box>
              <TabbedMultiSelector
                onSelectionChanged={(value) => {
                  if (value !== null) {
                    setSelectedIPDStatuses(value)
                  }
                }}
                options={statusMenu}
                preSelectedValues={[therapyOptions[0].id]}
              />
            </Box>
          </Box>
          <Box flexDirection='row' width='100%' display='flex' height='8px'>
            {appointmentSearchSlice.searchingAppointments && (
              <LinearProgress
                style={{
                  height: '4px',
                  width: '100%',
                }}
              />
            )}
          </Box>
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            justifyContent='Start'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#00000005',
            }}
          >
            {appointmentSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  {isTherapist()
                    ? 'No Therapies to be performed'
                    : 'Add IPD Appointments'}
                </Typography>
              </Box>
            )}

            {appointmentSearchSlice.errorWhileSearchingOrders && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='error' align='center'>
                  Error while searching Therapies
                </Typography>
              </Box>
            )}

            {appointmentSearchSlice.dateWiseAppointments && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
              >
                <Box display='flex' overflow='auto' height='100%' flexGrow={2}>
                  <List
                    id='scrollableDiv'
                    subheader={<li style={{ padding: 0, width: '100%' }} />}
                    style={{
                      padding: 0,
                      width: '100%',
                      overflow: 'auto',
                      height: '100%',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={appointmentSearchSlice.recordsCount ?? 0}
                      scrollThreshold='300px'
                      next={() => {
                        dispatch(
                          requestForDateWiseIpdAppointmentLists(
                            type,
                            name,
                            selectedDate,
                            selectedIpdStatuses,
                            appointmentSearchSlice.pageState,
                            appointmentSearchSlice.availableAppointments
                              ?.length,
                            appointmentSearchSlice.dateWiseAppointments,
                            appointmentSearchSlice.availableAppointments
                          )
                        )
                      }}
                      hasMore={
                        (appointmentSearchSlice.availableAppointments?.length ??
                          0) < (appointmentSearchSlice.recordsCount ?? -1)
                      }
                      loader=''
                      endMessage={
                        appointmentSearchSlice.searchingAppointments ? (
                          <p />
                        ) : (
                          <p style={{ textAlign: 'center' }} />
                        )
                      }
                      scrollableTarget='scrollableDiv'
                    >
                      {appointmentSearchSlice.dateWiseAppointments!.map((e) => (
                        <li
                          key={`section-${e.date ?? ''}`}
                          style={{ padding: 0, width: '100%' }}
                        >
                          <ul style={{ padding: 0 }}>
                            <ListSubheader
                              style={{
                                color: '#333333',
                                fontWeight: 600,
                                position: 'sticky',
                                width: '100%',
                                paddingBottom: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                backgroundColor: '#ececec',
                              }}
                            >
                              <Box paddingLeft={2.2}>
                                {getCompleteDateStringWithOutDay(e.date ?? '')}
                              </Box>

                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                                paddingBottom={0.5}
                              >
                                <TherapyListHeader type={type} />
                              </Box>
                            </ListSubheader>
                            {e.orders?.map((item, index) => (
                              <TherapyDisplayTile
                                ipdAppointmentDetails={item}
                                isOdd={index % 2 === 0}
                                dense={selectedIpd !== undefined}
                                isSelected={
                                  selectedIpd !== undefined &&
                                  item.therapy.id === selectedIpd?.therapy.id &&
                                  innerWidth! > desktopScreenWidth
                                }
                                onViewClicked={() => {
                                  setSelectedIPd(item)
                                }}
                                onManageClicked={() => {
                                  openAppointment(item)
                                }}
                                key={`lab_item_key${item.therapy.id}` ?? ''}
                                id={`${index}`}
                                type={type}
                              />
                            ))}
                          </ul>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </List>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}
