import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  addCarePlanFollowups,
  resetAddCareTeamFollowUpCPGState,
} from 'redux/consultation/recommendation_additions/care_plan_followup_addition/carePlanAdditionFollowUpSlice'
import { RootState } from 'redux/rootReducer'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getCarePlans } from 'utils/patientHelper/cds_helper'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface AddCareTeamFollowUpsButtonProps {
  carePanBundle: R4.IBundle
  carePlanIdentifier?: string
}

export const AddCareTeamFollowUpsButton: React.FC<AddCareTeamFollowUpsButtonProps> =
  ({ carePanBundle, carePlanIdentifier }: AddCareTeamFollowUpsButtonProps) => {
    const instructionAdditionSlice = useSelector(
      (state: RootState) => state.carePlanFollowUpAdditionSlice
    )
    const subscriptionStatus = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )
    const classes = useStyles()
    const dispatch = useDispatch()
    const visitContext = useContext(VisitAppointmentContext)

    useEffect(() => {
      if (instructionAdditionSlice.additionSuccessful) {
        dispatch(resetAddCareTeamFollowUpCPGState())
        dispatch(showSuccessAlert('Care plan follow ups added successfully'))
        if (visitContext.onRecommendationAdditionUpdate) {
          console.log(
            '---------------careplan-follow-up---------------- addition successfull-------------------'
          )
          visitContext.onRecommendationAdditionUpdate?.('careplan-follow-up')
        }
      }
    }, [instructionAdditionSlice])

    return (
      <Box>
        <Box flexDirection='row'>
          {instructionAdditionSlice.addingInstruction && (
            <Box height={25} width={25}>
              <CircularProgress size={20} />
            </Box>
          )}

          {instructionAdditionSlice.errorWhileAdding && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                className={classes.buttonText}
                id='ins_retry'
                onClick={(e) => {
                  if (subscriptionStatus.tasks) {
                    const carePlanId = getCarePlanIdFromTaskOutput(
                      subscriptionStatus.tasks[0].task
                    )

                    if (carePlanId)
                      dispatch(
                        addCarePlanFollowups(
                          visitContext.patient!,
                          getCarePlans(carePanBundle.entry ?? []),
                          visitContext.encounter?.id!,
                          carePlanIdentifier,
                          carePlanId
                        )
                      )
                  }
                  e.stopPropagation()
                }}
              >
                Retry
              </Button>

              {/*  <Button
                                variant='text'
                                color='secondary'
                                className={classes.rejectButtonText}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                Reject
                            </Button> */}
            </Box>
          )}
          {instructionAdditionSlice.additionSuccessful && (
            <Box flexDirection='row'>
              <Typography variant='subtitle1' color='initial'>
                Added
              </Typography>
            </Box>
          )}
          {!instructionAdditionSlice.additionSuccessful &&
            !instructionAdditionSlice.errorWhileAdding &&
            !instructionAdditionSlice.addingInstruction && (
              <Box flexDirection='row'>
                <Button
                  variant='text'
                  color='primary'
                  id='ins_finalize'
                  className={classes.buttonText}
                  onClick={(e) => {
                    if (
                      subscriptionStatus.subscriptionStatus === 'interested' ||
                      subscriptionStatus.subscriptionStatus === 'active'
                    ) {
                      if (subscriptionStatus.tasks) {
                        const carePlanId = getCarePlanIdFromTaskOutput(
                          subscriptionStatus.tasks[0].task
                        )

                        if (carePlanId)
                          dispatch(
                            addCarePlanFollowups(
                              visitContext.patient!,
                              getCarePlans(carePanBundle.entry ?? []),
                              visitContext.encounter?.id!,
                              carePlanIdentifier,
                              carePlanId
                            )
                          )
                      }
                    } else {
                      dispatch(
                        showErrorAlert(
                          'Please subscribe to the plan to add care team follow ups',
                          undefined,
                          undefined,
                          false
                        )
                      )
                    }
                    e.stopPropagation()
                  }}
                >
                  Add
                </Button>

                {/*  <Button
                                    variant='text'
                                    color='secondary'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    className={classes.rejectButtonText}
                                >
                                    Reject
                                </Button> */}
              </Box>
            )}
        </Box>
      </Box>
    )
  }
