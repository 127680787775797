import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'

import '../../../App.css'

import { ClinicalTherapistHomePageLarge } from './clinicalTherapistHomePageLarge'
import { ClinicalTherapistHomePageMobile } from './clinicalTherapistHomePageMobile'

export interface PageProps {
  type: 'opd' | 'ipd' | 'opd-daycare' | 'appointment'
}

export const ClinicalTherapistHomePage: React.FC<PageProps> = ({
  type,
}: PageProps) => (
  <div>
    <MediaQuery maxWidth={1224}>
      <ClinicalTherapistHomePageMobile type={type} key={`mobile_${type}`} />
    </MediaQuery>
    <MediaQuery minWidth={1224}>
      <ClinicalTherapistHomePageLarge type={type} key={`large_${type}`} />
    </MediaQuery>
  </div>
)
