import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
  Info,
} from '@material-ui/icons'

import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import {
  getDefaultCodingOfSystemFromCodableConceptList,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getIdOfCardEntry,
  getAppointments,
  hasError,
  getTestsToBePerformed,
  hasLabTestsOfType,
  getLabTestsOfType,
  getLoincCodeFromPlanDef,
  isEssential,
  getJustificationOfPlanDef,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { AddReferralsButton } from 'views/components/consultation/plan/cdsRecommendations/add_referrals_button'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import CPGSelectionContext, {
  CPGSelectionContextData,
} from 'views/contexts/cpgContextSelection'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import { RootState } from 'redux/rootReducer'
import { AddLabTestsButton } from '../add_lab_orders_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  encounterId: string
  carePlanIdentifier: string
  cdsCard: CardsEntity
  onRequestFinished?: () => void
}

export const LabTestsOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  patient,
  encounterId,
  carePlanIdentifier,
  cdsCard: card,
  onRequestFinished,
}: CdsCardDetailsProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const labDiagnosticTestCodes = useSelector(
    (state: RootState) =>
      state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
  )

  const subscriptionStatus = useSelector(
    (state: RootState) =>
      state.patientSubscriptionForUnitSlice.subscriptionStatus
  )
  const cdsRecommendationAddition = useContext(CPGSelectionContext)

  const selectedTestsToOrder = getSelectionContextTypeSections({
    cpgIdentifier: carePlanIdentifier,
    selectionCategoryType: 'labTests',
    selectionData: cdsRecommendationAddition.selectionData ?? [],
  })

  function onSelectedTestsChanged(changedTest: string) {
    cdsRecommendationAddition.addSelection(
      carePlanIdentifier,
      'labTests',
      changedTest
    )
  }

  function isTestAlreadyAdded(testCode: string): boolean {
    return (labDiagnosticTestCodes ?? []).includes(testCode)
  }

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('labTests') && (
          <Box
            display='flex'
            flexDirection='column'
            key='lab_tests_cpg_outer_label'
            py={0.25}
            overflow='auto'
          >
            <Box
              display='flex'
              flexDirection='column'
              key='lab_tests_cpg_inner_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                width='100%'
                flex='row'
                id='referrals_list'
                display='flex'
                overflow='auto'
              >
                {(card.interceptedSuggestions.get('labTests') as any[]).length >
                  0 && (
                  <Box display='flex' width='100%'>
                    {(card.interceptedSuggestions.get('labTests') as any[])
                      .length > 0 && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        {hasLabTestsOfType(
                          card.interceptedSuggestions.get('labTests') as any[],
                          'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                          'test'
                        ) && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            flexGrow='1'
                            minWidth='50%'
                          >
                            <Typography variant='h6' color='initial'>
                              Tests
                            </Typography>
                            {getLabTestsOfType(
                              card.interceptedSuggestions.get(
                                'labTests'
                              ) as any[],
                              'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                              'test'
                            )!.map((eachTest: R4.IPlanDefinition, index) => (
                              <ListItem
                                key={`${
                                  eachTest.name ?? `${index}test`
                                }list_item`}
                                disabled={isTestAlreadyAdded(
                                  getLoincCodeFromPlanDef(eachTest) ?? ''
                                )}
                                onChange={(e) => {
                                  onSelectedTestsChanged(
                                    getLoincCodeFromPlanDef(eachTest) ?? ''
                                  )
                                }}
                                dense
                                style={{
                                  padding: '0px',
                                  paddingLeft: '2px',
                                  margin: '0px',
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    size='small'
                                    id={`checkCp${index}`}
                                    checked={
                                      getSelectionContextTypeSections({
                                        cpgIdentifier: carePlanIdentifier,
                                        selectionCategoryType: 'labTests',
                                        selectionData:
                                          cdsRecommendationAddition.selectionData ??
                                          [],
                                      }).findIndex(
                                        (e) =>
                                          e ===
                                          getLoincCodeFromPlanDef(eachTest)
                                      ) !== -1
                                    }
                                    disabled={isTestAlreadyAdded(
                                      getLoincCodeFromPlanDef(eachTest) ?? ''
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      'aria-labelledby': `${eachTest.name}list_item_id`,
                                      id: `${eachTest.name}list_item_id`,
                                    }}
                                    color='primary'
                                    //   style={{
                                    //     color: kPrimaryMain,
                                    //   }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={`${eachTest.name}list_item_id`}
                                  primary={
                                    <Box
                                      width='100%'
                                      display='flex'
                                      flexDirection='row'
                                    >
                                      {' '}
                                      {eachTest.title ?? ''}
                                      {isEssential(eachTest) && (
                                        <Box paddingLeft={1} width='5%'>
                                          <Tooltip
                                            title='Essential'
                                            placement='right-end'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='manage_ipd'
                                              style={{ padding: 0 }}
                                            >
                                              <Info />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )}
                                    </Box>
                                  }
                                  primaryTypographyProps={{
                                    variant: 'subtitle1',
                                    style: {
                                      color: '#4d4d4d',
                                    },
                                  }}
                                  secondary={
                                    <Typography
                                      className={classes.subTitleText}
                                      display='inline'
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          getJustificationOfPlanDef(eachTest) ??
                                          '',
                                      }}
                                    />
                                  }
                                />
                              </ListItem>
                            ))}
                          </Box>
                        )}

                        {hasLabTestsOfType(
                          card.interceptedSuggestions.get('labTests') as any[],
                          'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                          'panel'
                        ) && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            flexGrow='1'
                            minWidth='50%'
                          >
                            <Typography variant='h6' color='initial'>
                              Panels
                            </Typography>
                            {getLabTestsOfType(
                              card.interceptedSuggestions.get(
                                'labTests'
                              ) as any[],
                              'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                              'panel'
                            )!.map((eachTest: R4.IPlanDefinition, index) => (
                              <ListItem
                                key={`${
                                  eachTest.name ?? `${index}test`
                                }list_item`}
                                onChange={(e) => {
                                  onSelectedTestsChanged(
                                    getLoincCodeFromPlanDef(eachTest) ?? ''
                                  )
                                }}
                                dense
                                disabled={isTestAlreadyAdded(
                                  getLoincCodeFromPlanDef(eachTest) ?? ''
                                )}
                                style={{
                                  padding: '0px',
                                  paddingLeft: '2px',
                                  margin: '0px',
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    size='small'
                                    id={`checkCpPanel${index}`}
                                    disabled={isTestAlreadyAdded(
                                      getLoincCodeFromPlanDef(eachTest) ?? ''
                                    )}
                                    checked={
                                      getSelectionContextTypeSections({
                                        cpgIdentifier: carePlanIdentifier,
                                        selectionCategoryType: 'labTests',
                                        selectionData:
                                          cdsRecommendationAddition.selectionData ??
                                          [],
                                      }).findIndex(
                                        (e) =>
                                          e ===
                                          getLoincCodeFromPlanDef(eachTest)
                                      ) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      'aria-labelledby': `${eachTest.name}list_item_id`,
                                      id: `${eachTest.name}list_item_id_panel`,
                                    }}
                                    color='primary'
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={`${eachTest.name}list_item_id`}
                                  primary={
                                    <Box
                                      width='100%'
                                      display='flex'
                                      flexDirection='row'
                                    >
                                      {' '}
                                      <Box paddingTop={0.1}>
                                        {eachTest.title ?? ''}
                                      </Box>
                                      {isEssential(eachTest) && (
                                        <Box paddingLeft={1} width='5%'>
                                          <Tooltip
                                            title='Essential'
                                            placement='right-end'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='manage_ipd'
                                              style={{ padding: 0 }}
                                            >
                                              <Info />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )}
                                    </Box>
                                  }
                                  primaryTypographyProps={{
                                    variant: 'subtitle1',
                                    style: {
                                      color: '#4d4d4d',
                                    },
                                  }}
                                  secondary={
                                    <Typography
                                      className={classes.subTitleText}
                                      display='inline'
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          getJustificationOfPlanDef(eachTest) ??
                                          '',
                                      }}
                                    />
                                  }
                                />
                              </ListItem>
                            ))}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                width='100%'
                flexDirection='row-reverse'
                id='referrals_list'
                display='flex'
              >
                <AddLabTestsButton
                  carePlanIdentifier={carePlanIdentifier}
                  onRequestFinished={() => {
                    cdsRecommendationAddition.removeSelection(
                      carePlanIdentifier,
                      'labTests',
                      ''
                    )
                    if (onRequestFinished) {
                      onRequestFinished()
                    }
                  }}
                  tests={(
                    card.interceptedSuggestions.get('labTests') as any[]
                  ).filter((e) =>
                    selectedTestsToOrder.includes(
                      getLoincCodeFromPlanDef(e) ?? ''
                    )
                  )}
                />
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  )
}
