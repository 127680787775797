import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  resetAdminGraphSearch,
  searchGraphData,
} from 'redux/welloAdmin/adminGraph/graphSearchSlice'
import { adminGraphOptions } from 'utils/constants'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import CustomizedDividers from 'views/components/toggle_selector'

interface Props {
  id: string
  org: R4.IOrganization
}
export const WpDashBoard: React.FC<Props> = ({ id, org }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const graphSearchSlice = useSelector(
    (state: RootState) => state.graphSearchSlice
  )

  const { height, width } = useWindowDimensions()
  const [optionId, setOptionId] = useState<string>(adminGraphOptions[0].value)

  useEffect(() => {
    dispatch(searchGraphData(optionId))
  }, [])

  return (
    <div
      style={{
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            width='100%'
            display='flex'
            flexDirection='row'
            justifyContent='center'
            py={1}
          >
            <CustomizedDividers
              onSelectionChanged={(idData) => {
                dispatch(resetAdminGraphSearch())
                dispatch(searchGraphData(idData))
                setOptionId(idData)
              }}
              options={adminGraphOptions}
            />
          </Box>
          <Box flexDirection='row' width='100%' display='flex' p={1}>
            {graphSearchSlice.searchingAppointments && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}
          </Box>

          {graphSearchSlice.resultsAvailable &&
            graphSearchSlice.url &&
            graphSearchSlice.url.length > 0 &&
            optionId === 'web' && (
              <iframe
                src={graphSearchSlice.url ?? ''}
                title='dashboard'
                height={height - 125}
              />
            )}
          {graphSearchSlice.resultsAvailable &&
            graphSearchSlice.url &&
            graphSearchSlice.url.length > 0 &&
            optionId === 'app' && (
              <iframe
                src={graphSearchSlice.url ?? ''}
                title='dashboard'
                height={height - 125}
              />
            )}
          {/* {graphSearchSlice.resultsAvailable &&
            graphSearchSlice.appUrl &&
            graphSearchSlice.appUrl.length > 0 &&
            optionId === 'app' && (
              <iframe
                src={graphSearchSlice.appUrl ?? ''}
                title='dashboard'
                height={height - 50}
              />
            )} */}
        </Box>
      </Paper>
    </div>
  )
}
