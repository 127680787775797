import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseDietPlans } from 'models/dateWiseDietPlanData'
import { DietOfVisit } from 'models/diet'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { NutritionOrderListStatus } from './nutritionOrderListStatus'

const initialState: NutritionOrderListStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  selectedStatuses: ['active'],
  selectedDate: new Date(),
}

let currentSelectedDate: Date | undefined

const nutritionOrderListSlice = createSlice({
  name: 'nutritionOrderListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<NutritionOrderListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.selectedStatuses = action.payload.selectedStatuses
      state.selectedDate = action.payload.selectedDate
      state.dateWiseAppointments = action.payload.dateWiseAppointments
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
    },
  },
})

export const requestForDateWiseDietLists =
  (
    typeOfAppointment: 'opd' | 'ipd' | 'opd-daycare' | 'appointment',
    selectedDate?: Date,
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: DateWiseDietPlans[],
    existingDataRawData?: R4.INutritionOrder[],
    recordsCount?: number,
    gender?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    if (selectedDate === undefined) {
      selectedDate = new Date()
    }
    const state: NutritionOrderListStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],
      selectedDate,
      availableAppointments: existingDataRawData,
      dateWiseAppointments: existingDateWiseData,
      pageState,
      recordsCount,
    }
    dispatch(nutritionOrderListSlice.actions.updatedStatus(state))
    try {
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const enRolClient: EnrolCient = new EnrolCient()
      const searchParams: any = {
        'encounter:Encounter.based-on:ServiceRequest.status':
          'active,completed',
        _count: 5000,
      }

      if (typeOfAppointment === 'ipd') {
        searchParams['encounter:Encounter.based-on:ServiceRequest.code'] =
          '281685003'
      } else {
        searchParams['encounter:Encounter.based-on:ServiceRequest.code'] =
          '304903009'
      }

      const date = `${moment(
        moment(moment(selectedDate).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      ).toISOString()}`

      const endDate = `${moment(
        moment(moment(selectedDate).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .add(1, 'days')
        .toISOString()}`

      axios.CancelToken.source()
      if (cancelTokenStore.has('dietSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'dietSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('dietSearchControlToken')
      }
      cancelTokenStore.set('dietSearchControlToken', axios.CancelToken.source())
      const fhirClient: MasterFhirClient = new MasterFhirClient()

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `/NutritionOrder?_include:iterate=NutritionOrder:patient&_include:iterate=NutritionOrder:encounter&_include:iterate=Encounter:based-on&nutrition-schedule=ge${date}&nutrition-schedule=lt${endDate}&nutrition-unit=${
          getCurrentUserUnitDetails().id
        }`,
        searchParams
      )
      if (response !== undefined) {
        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        const proceduresResponse: R4.IBundle = response as R4.IBundle
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const dietDataList: R4.INutritionOrder[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.INutritionOrder
            ) ?? []

          const dietplansData: DateWiseDietPlans[] =
            getDateWiseDietPlansFromBundle(proceduresResponse)

          state.pageState = proceduresResponse.id
          state.recordsCount = proceduresResponse.total
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableAppointments = [
            ...(existingDataRawData ?? []),
            ...dietDataList,
          ]
          state.dateWiseAppointments = dietplansData
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(nutritionOrderListSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: NutritionOrderListStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],
            selectedDate,
            availableAppointments: existingDataRawData,
            dateWiseAppointments: existingDateWiseData,
            pageState,
            recordsCount,
          }
          dispatch(
            nutritionOrderListSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      /*  const errorSearchDoctor: IpdAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
        selectedDate,
        availableAppointments: existingDataRawData,
        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(
        ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      ) */
    }
  }

export default nutritionOrderListSlice.reducer
