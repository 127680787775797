import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { AppDispatch, AppThunk } from 'redux/store'
import moment from 'moment'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import {
  getTreatmentPlanFromBundle,
  getTreatmentPlanFromBundleForAyurveda,
  inBetWeenTime,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import {
  getDateWiseTherapy,
  getDateWiseTherapyAfterNoon,
  getDateWiseTherapyEvening,
  getDateWiseTherapyMidMorning,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { TreatmentAssemble } from 'models/treatmentAssemble'
import { TreatmentSearchStatus } from './ayurvedaTreatmentSearchStatus'

const initialState: TreatmentSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const treatmentSearchSliceForAyurvedaIPD = createSlice({
  name: 'treatmentSearchSliceForAyurvedaIPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<TreatmentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.treatmentList = action.payload.treatmentList
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const requestForTreatmentForAyurveda =
  (appointmentId: string, details?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: TreatmentSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(treatmentSearchSliceForAyurvedaIPD.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const searchParameters: any = {
        'based-on': appointmentId,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `Encounter?_r[…]ude=Encounter:part-of&_revinclude=ServiceRequest:encounter&_revinclude=Procedure:encounter&_count=1000`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const proceduresResponse: R4.IBundle = response as R4.IBundle
      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const vitalLisData = getTreatmentPlanFromBundleForAyurveda(
          proceduresResponse,
          details ?? ''
        )

        vitalLisData.sort((a, b) =>
          a.status.toLowerCase().localeCompare(b.status.toLowerCase())
        )

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.treatmentList = vitalLisData.sort((a, b) =>
          a.status.toLowerCase().localeCompare(b.status.toLowerCase())
        )
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false

        dispatch(
          treatmentSearchSliceForAyurvedaIPD.actions.updatedStatus(state)
        )
      } else {
        const errorSearchDoctor: TreatmentSearchStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          treatmentSearchSliceForAyurvedaIPD.actions.updatedStatus(
            errorSearchDoctor
          )
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: TreatmentSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        treatmentSearchSliceForAyurvedaIPD.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default treatmentSearchSliceForAyurvedaIPD.reducer
