import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  treatmentPlanData: TreatmentPlanData
}

export const TherapyDataDisplay: React.FC<Props> = ({
  treatmentPlanData,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Box display='flex' flexDirection='row' width='100%'>
      <Card
        elevation={0}
        style={{
          background: ' #FDFFABCC',
          width: '100%',
        }}
      >
        <CardContent>
          <Box display='flex' flexDirection='column' width='100%' height='80px'>
            <Box display='flex' flexDirection='row' width='100%'>
              <Typography
                variant='h6'
                color='initial'
                style={{
                  textTransform: 'uppercase',
                  fontSize: 12,
                }}
              >
                Selected Therapy
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              paddingTop={0.5}
            >
              <Typography variant='h6' color='initial'>
                {treatmentPlanData.therapy}{' '}
                {treatmentPlanData.treatmentType
                  ? `- ${treatmentPlanData.treatmentType}`
                  : ''}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Typography variant='body1' color='initial'>
                {moment(treatmentPlanData.date).format('DD-MM-YYYY')}
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' width='100%'>
              <Typography variant='body1' color='initial'>
                {treatmentPlanData.startTime} - {treatmentPlanData.endTime}
              </Typography>
            </Box>
            {/* {treatmentPlanData.treatmentPlan &&
                treatmentPlanData.treatmentPlan.status === 'revoked' && (
                  <Box display='flex' flexDirection='row' width='100%' py={1}>
                    <Typography variant='subtitle1' color='error'>
                      Cancelled
                    </Typography>
                  </Box>
                )} */}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
