import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import Skeleton from '@yisheng90/react-loading'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {
  FhirActiveIPDDetailsForMedicalRole,
  FhirAppointmentWithPatient,
  FhirIPDwithPatientDetails,
} from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getAgeOfPatientData,
  getDisplayOfSystemFromCodableConcept,
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMedicationForTherapy,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import {
  getStatusOfTherapy,
  isAfterTherapyFinal,
  isPastDateForTherapy,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  cancelTherapy,
  cancelTherapyForTherapist,
  cancelTherapyForTherapistOPD,
  getTherapiesDetails,
  getVisitDetailsForTherapist,
  getVisitDetailsForTherapistFHIR,
  getVisitDetailsForTherapistFHIROPD,
  updateTherapyPerformDetails,
  updateTherapyPerformDetailsForTherapies,
  updateTherapyPerformDetailsForTherapiesForAppointment,
} from 'utils/fhirResoureHelpers/therapiesHelper'
import { visaFormForPatientDateValidation } from 'utils/formValidators'
import { TherapyDetails } from 'views/components/ipdconusltation/treatment/therapyDetailsInfo'
import { TherapyDetailsForTherapist } from 'views/components/ipdconusltation/treatment/therapyDetailsInfoTherapist'
import { TherapyDetailsOPD } from 'views/components/ipdconusltation/treatment/TherapyDetailsOPD'
import { CancelTherapyNew } from 'views/components/ipdconusltation/treatment/therapyPerformCancel'
import { CancelTherapyOPD } from 'views/components/ipdconusltation/treatment/therapyPerformCancelOPD'
import { TherapyPerformCapture } from 'views/components/ipdconusltation/treatment/therapyPerformCapture'
import { TherapyPerformCaptureOPD } from 'views/components/ipdconusltation/treatment/therapyPerformCaptureOPD'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

interface Props {
  ipdAppointmentDetails: FhirClinicTherapyBasic
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
  onManageClicked: () => void
  onDataUpdated?: () => void
  id: string
  type: string
}

export const TherapyDisplayTile: React.FC<Props> = ({
  ipdAppointmentDetails,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
  onManageClicked,
  onDataUpdated,
  id,
  type,
}: Props) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [visitDetails, setVisitDetails] = useState<FhirIPDwithPatientDetails>()
  const [visitDetailsOPD, setVisitDetailsOPD] =
    useState<FhirAppointmentWithPatient>()
  const [showTherapyPerformDialog, setShowTherapyPerformDialog] =
    useState<boolean>(false)
  const [showTherapyDetails, setShowTherapyDetails] = useState<boolean>(false)
  const [showCancelTherapyDetails, setShowCancelTherapyDetails] =
    useState<boolean>(false)
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })
  const [fetchingTherapyDetails, setFetchingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })
  const [updatingTherapyDetails, setUpdatingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [plan, setPlan] = useState<TreatmentPlanData>()

  function fetchAppointmentDetails() {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setFetchingStatus({
        requesting: true,
      })

    getVisitDetailsForTherapistFHIR(ipdAppointmentDetails.mainServiceRequestId)
      .then((e) => {
        if (e) {
          if ('mainServiceRequest' in e) {
            setVisitDetails(e as FhirIPDwithPatientDetails)

            setFetchingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  function fetchTherapyDetails() {
    if (ipdAppointmentDetails.therapy.id)
      setFetchingTherapyDetails({
        requesting: true,
      })

    getTherapiesDetails(ipdAppointmentDetails.therapy.id ?? '')
      .then((e) => {
        if (e) {
          if ('date' in e) {
            setPlan(e)

            setFetchingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function updateTherapyDetails(
    appointment: FhirIPDwithPatientDetails,
    treatMent: TreatmentPlanData,
    selectedPractWithRole: PractitionerWithRole[]
  ) {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setUpdatingTherapyDetails({
        requesting: true,
      })

    updateTherapyPerformDetailsForTherapies(
      appointment,
      treatMent,
      selectedPractWithRole
    )
      .then((e) => {
        if (e !== undefined) {
          if (e) {
            setUpdatingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
          fetchTherapyDetails()
        } else {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setUpdatingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function updateTherapyDetailsOPD(
    appointment: FhirAppointmentWithPatient,
    treatMent: TreatmentPlanData,
    selectedPractWithRole: PractitionerWithRole[]
  ) {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setUpdatingTherapyDetails({
        requesting: true,
      })

    updateTherapyPerformDetailsForTherapiesForAppointment(
      appointment,
      treatMent,
      selectedPractWithRole
    )
      .then((e) => {
        if (e === true) {
          if (e) {
            setUpdatingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
          fetchTherapyDetails()
        } else {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setUpdatingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function cancelTherapyDetails(
    appointment: FhirIPDwithPatientDetails,
    treatMent: TreatmentPlanData,
    cancellationReason: R4.ICoding
  ) {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setUpdatingTherapyDetails({
        requesting: true,
      })

    cancelTherapyForTherapist(appointment, treatMent, cancellationReason)
      .then((e) => {
        if (e !== undefined) {
          if (e) {
            setUpdatingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
          fetchTherapyDetails()
        } else {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setUpdatingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function cancelTherapyDetailsOPD(
    appointment: FhirAppointmentWithPatient,
    treatMent: TreatmentPlanData,
    cancellationReason: R4.ICoding
  ) {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setUpdatingTherapyDetails({
        requesting: true,
      })

    cancelTherapyForTherapistOPD(appointment, treatMent, cancellationReason)
      .then((e) => {
        if (e === true) {
          if (e) {
            setUpdatingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setUpdatingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
          fetchTherapyDetails()
        } else {
          setUpdatingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setUpdatingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function fetchOPDAppointmentDetails() {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setFetchingStatus({
        requesting: true,
      })

    getVisitDetailsForTherapistFHIROPD(
      ipdAppointmentDetails.mainServiceRequestId
    )
      .then((e) => {
        if (e) {
          if ('appointment' in e) {
            setVisitDetailsOPD(e as FhirAppointmentWithPatient)

            setFetchingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    if (type !== 'appointment') {
      fetchAppointmentDetails()
    } else {
      fetchOPDAppointmentDetails()
    }
  }, [])

  useEffect(() => {
    fetchTherapyDetails()
  }, [])

  return (
    <Box width='100%' id={`therapistOrder${id}`}>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
        id={`card${id}`}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
            paddingTop: 0,
          }}
          id={`cardContent${id}`}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignContent='center'
            p={0.5}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='15%'
              id={`therapy_time_details-${id}`}
            >
              <Typography variant='body2' color='initial'>
                {moment(ipdAppointmentDetails.start).format('hh:mm A')} -{' '}
                {moment(ipdAppointmentDetails.end).format('hh:mm A')}
              </Typography>
            </Box>
            {(visitDetails || visitDetailsOPD) &&
              fetchingStatus.requestSuccess && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='flex-start'
                  width='15%'
                  px={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      {visitDetails && (
                        <Avatar
                          alt={getNameOfPatient(visitDetails.patient) ?? ''}
                          src={getProfilePicPatient(visitDetails.patient) ?? ''}
                        />
                      )}
                      {visitDetailsOPD && (
                        <Avatar
                          alt={getNameOfPatient(visitDetailsOPD.patient) ?? ''}
                          src={
                            getProfilePicPatient(visitDetailsOPD.patient) ?? ''
                          }
                        />
                      )}
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='start'
                      px={0.5}
                      id={`patient_details_id-${id}`}
                    >
                      {visitDetails && (
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          style={{ textTransform: 'capitalize' }}
                        >
                          {getNameOfPatient(visitDetails.patient)}{' '}
                          {getMiddleNameOfPatient(visitDetails.patient)}{' '}
                          {getLastNameOfPatient(visitDetails.patient)}
                        </Typography>
                      )}
                      {visitDetailsOPD && (
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          style={{ textTransform: 'capitalize' }}
                        >
                          {getNameOfPatient(visitDetailsOPD.patient)}{' '}
                          {getMiddleNameOfPatient(visitDetailsOPD.patient)}{' '}
                          {getLastNameOfPatient(visitDetailsOPD.patient)}
                        </Typography>
                      )}
                      {visitDetails && (
                        <Box py={0.5}>
                          {visitDetails.patient?.gender ===
                          R4.PatientGenderKind._male ? (
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='primary'
                            >
                              {' '}
                              M /
                            </Typography>
                          ) : (
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='primary'
                            >
                              {' '}
                              F /
                            </Typography>
                          )}
                          {visitDetails.patient && (
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='primary'
                            >
                              {` ${getAgeOfPatientData(visitDetails.patient)}`}
                            </Typography>
                          )}
                        </Box>
                      )}

                      {visitDetailsOPD && (
                        <Box py={0.5}>
                          {visitDetailsOPD.patient?.gender ===
                          R4.PatientGenderKind._male ? (
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='primary'
                            >
                              {' '}
                              M /
                            </Typography>
                          ) : (
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='primary'
                            >
                              {' '}
                              F /
                            </Typography>
                          )}
                          {visitDetailsOPD.patient && (
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='primary'
                            >
                              {` ${getAgeOfPatientData(
                                visitDetailsOPD.patient
                              )}`}
                            </Typography>
                          )}
                        </Box>
                      )}
                      {visitDetails && (
                        <Typography variant='subtitle2' color='initial'>
                          {getIdentifierValueBySystem(
                            visitDetails.mainServiceRequest.identifier ?? [],
                            'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                          ) ??
                            getIdentifierValueBySystem(
                              visitDetails.mainServiceRequest.identifier ?? [],
                              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                            )}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

            {fetchingStatus.requesting && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='30%'
                px={1}
              >
                {/* <Skeleton height='3rem' /> */}
              </Box>
            )}

            {fetchingTherapyDetails.requestSuccess && (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignContent='center'
                alignItems='center'
                width='50%'
                paddingLeft={5}
                paddingRight={5}
              >
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box
                    width='51%'
                    px={0.5}
                    flexDirection='row'
                    justifyContent='flex-start'
                  >
                    {plan && plan?.treatmentPlan && (
                      <Typography variant='subtitle2' color='initial'>
                        {getDisplayOfSystemFromCodableConcept(
                          plan?.treatmentPlan.code
                        ) ?? ''}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    width='34%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    px={2}
                  >
                    {plan && plan?.treatmentPlan && (
                      <Typography variant='subtitle2' color='initial'>
                        {getMedicationForTherapy(
                          plan?.treatmentPlan.supportingInfo
                        ) ?? ''}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    width='15%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    paddingLeft={4.5}
                  >
                    {plan && plan?.treatmentPlan && (
                      <Typography
                        variant='body1'
                        color={
                          plan?.treatmentPlan.status === 'revoked'
                            ? 'error'
                            : plan?.treatmentPlan.status === 'completed'
                            ? 'primary'
                            : 'initial'
                        }
                      >
                        {getStatusOfTherapy(plan?.treatmentPlan)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {fetchingTherapyDetails.requesting && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
                px={1}
              >
                <Skeleton height='3rem' />
              </Box>
            )}

            {fetchingStatus.requesting && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
                px={1}
              >
                <Skeleton height='3rem' />
              </Box>
            )}

            {updatingTherapyDetails.requesting && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
                width='20%'
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {visitDetails &&
              fetchingTherapyDetails.requestSuccess &&
              updatingTherapyDetails.requesting === false &&
              plan && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='20%'
                >
                  {plan.procedure === undefined &&
                    plan.treatmentPlan &&
                    plan.treatmentPlan.status === 'active' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        {isPastDateForTherapy(
                          moment(plan.date).format('YYYY-MM-DD')
                        ) &&
                          isAfterTherapyFinal(
                            moment(plan.endTime, ['hh:mm A']).format('HH:mm'),
                            moment(plan.date).format('YYYY-MM-DD')
                          ) && (
                            <Button
                              color='primary'
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                setShowTherapyPerformDialog(true)
                              }}
                              id={`complete_therapy-${id}`}
                            >
                              Complete
                            </Button>
                          )}
                        <Button
                          color='secondary'
                          variant='outlined'
                          size='small'
                          onClick={() => {
                            setShowCancelTherapyDetails(true)
                          }}
                          id={`cancel_therapy-${id}`}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}

                  {plan.procedure !== undefined &&
                    plan.treatmentPlan &&
                    plan.treatmentPlan.status === 'completed' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='column'
                          justifyContent='center'
                          alignContent='end'
                          alignItems='end'
                        >
                          <Typography variant='body2' color='initial'>
                            Actual Performed Time
                          </Typography>
                          <Typography variant='body1' color='primary'>
                            {plan.actualStart} - {plan.actualEnd}
                          </Typography>
                        </Box>

                        <IconButton
                          id='performed_time'
                          aria-label='info'
                          onClick={() => {
                            setShowTherapyDetails(true)
                          }}
                        >
                          <Info />
                        </IconButton>
                      </Box>
                    )}

                  {plan.treatmentPlan &&
                    plan.treatmentPlan.status === 'revoked' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        <IconButton
                          id='revoked'
                          aria-label='info'
                          onClick={() => {
                            setShowTherapyDetails(true)
                          }}
                        >
                          <Info />
                        </IconButton>
                      </Box>
                    )}
                </Box>
              )}

            {visitDetailsOPD &&
              fetchingTherapyDetails.requestSuccess &&
              updatingTherapyDetails.requesting === false &&
              plan && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='20%'
                >
                  {plan.procedure === undefined &&
                    plan.treatmentPlan &&
                    plan.treatmentPlan.status === 'active' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        {isPastDateForTherapy(
                          moment(plan.date).format('YYYY-MM-DD')
                        ) &&
                          isAfterTherapyFinal(
                            moment(plan.endTime, ['hh:mm A']).format('HH:mm'),
                            moment(plan.date).format('YYYY-MM-DD')
                          ) && (
                            <Button
                              color='primary'
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                setShowTherapyPerformDialog(true)
                              }}
                              id={`complete_therapy-${id}`}
                            >
                              Complete
                            </Button>
                          )}
                        <Button
                          color='secondary'
                          variant='outlined'
                          size='small'
                          onClick={() => {
                            setShowCancelTherapyDetails(true)
                          }}
                          id={`cancel_therapy-${id}`}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}

                  {plan.procedure !== undefined &&
                    plan.treatmentPlan &&
                    plan.treatmentPlan.status === 'completed' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='column'
                          justifyContent='center'
                          alignContent='end'
                          alignItems='end'
                        >
                          <Typography variant='body2' color='initial'>
                            Actual Performed Time
                          </Typography>
                          <Typography variant='body1' color='primary'>
                            {plan.actualStart} - {plan.actualEnd}
                          </Typography>
                        </Box>

                        <IconButton
                          id='performed_time'
                          aria-label='info'
                          onClick={() => {
                            setShowTherapyDetails(true)
                          }}
                        >
                          <Info />
                        </IconButton>
                      </Box>
                    )}

                  {plan.treatmentPlan &&
                    plan.treatmentPlan.status === 'revoked' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        <IconButton
                          id='revoked'
                          aria-label='info'
                          onClick={() => {
                            setShowTherapyDetails(true)
                          }}
                        >
                          <Info />
                        </IconButton>
                      </Box>
                    )}
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>

      {plan && visitDetails && showTherapyPerformDialog && (
        <TherapyPerformCapture
          open={showTherapyPerformDialog}
          onSubmitClicked={(
            startTime?: string,
            endTime?: string,
            selectedPractWithRole?: PractitionerWithRole[]
          ) => {
            updateTherapyDetails(
              visitDetails,
              plan,
              selectedPractWithRole ?? []
            )
            setShowTherapyPerformDialog(false)
          }}
          onClose={() => {
            setShowTherapyPerformDialog(false)
          }}
          fhirAppointmentDetails={visitDetails}
          treatmentPlanData={plan!}
        />
      )}

      {plan && visitDetailsOPD && showTherapyPerformDialog && (
        <TherapyPerformCaptureOPD
          open={showTherapyPerformDialog}
          onSubmitClicked={(
            startTime?: string,
            endTime?: string,
            selectedPractWithRole?: PractitionerWithRole[]
          ) => {
            updateTherapyDetailsOPD(
              visitDetailsOPD,
              plan,
              selectedPractWithRole ?? []
            )
            setShowTherapyPerformDialog(false)
          }}
          onClose={() => {
            setShowTherapyPerformDialog(false)
          }}
          fhirAppointmentDetails={visitDetailsOPD}
          treatmentPlanData={plan!}
        />
      )}

      {plan && visitDetails && showTherapyDetails && (
        <TherapyDetailsForTherapist
          open={showTherapyDetails}
          onLabOrderCreated={() => {
            setShowTherapyDetails(false)
          }}
          onClose={() => {
            setShowTherapyDetails(false)
          }}
          fhirAppointmentDetails={visitDetails}
          treatmentPlanData={plan!}
        />
      )}

      {plan && visitDetailsOPD && showTherapyDetails && (
        <TherapyDetailsOPD
          open={showTherapyDetails}
          onLabOrderCreated={() => {
            setShowTherapyDetails(false)
          }}
          onClose={() => {
            setShowTherapyDetails(false)
          }}
          fhirAppointmentDetails={visitDetailsOPD}
          treatmentPlanData={plan!}
        />
      )}

      <NoteDialog
        id='treatment_notes_therapist'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />

      {showCancelTherapyDetails && visitDetails && plan && (
        <CancelTherapyNew
          open={showCancelTherapyDetails}
          onLabOrderCreated={(cancelReason: R4.ICoding) => {
            cancelTherapyDetails(visitDetails, plan, cancelReason)

            setShowCancelTherapyDetails(false)
          }}
          onClose={() => {
            setShowCancelTherapyDetails(false)
          }}
          fhirAppointmentDetails={visitDetails}
          treatmentPlanData={plan}
        />
      )}

      {showCancelTherapyDetails && visitDetailsOPD && plan && (
        <CancelTherapyOPD
          open={showCancelTherapyDetails}
          onLabOrderCreated={(cancelReason: R4.ICoding) => {
            cancelTherapyDetailsOPD(visitDetailsOPD, plan, cancelReason)

            setShowCancelTherapyDetails(false)
          }}
          onClose={() => {
            setShowCancelTherapyDetails(false)
          }}
          treatmentPlanData={plan}
          fhirAppointmentDetails={visitDetailsOPD}
        />
      )}
    </Box>
  )
}
