/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { getAppointmentId } from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getEncounterFromBundle } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getLabDiagnosticRequestFromBundle,
  getTestsFromDiagnostics,
  getTestsFromTasks,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { FollowUpReportsSearchStatus } from './followupReportsSearchStatus'

const initialState: FollowUpReportsSearchStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const reportsSearchSliceForFollowUp = createSlice({
  name: 'reportsSearchSliceForFollowUp',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<FollowUpReportsSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.reports = action.payload.reports
      state.diagnosticReport = action.payload.diagnosticReport
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions

      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions

      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getLabReportsForTheAppointmentForFollowup =
  (
    fhirAppointmentDetails: FhirAppointmentDetail,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowUpReportsSearchStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(reportsSearchSliceForFollowUp.actions.updatedStatus(state))
    try {
      const docRefFiles: R4.IDocumentReference[] = []
      let referredEncounter: R4.IEncounter[] = []

      const reportsFiles: R4.IDiagnosticReport[] = []
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      if (followUp) {
        const serviceRequestData = getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        )
        const appointmentArray = await getAppointmentId(
          fhirAppointmentDetails.appointment.id ?? ''
        )

        for (let j = 0; j < appointmentArray.length; j++) {
          if (appointmentArray[j] !== fhirAppointmentDetails.appointment.id) {
            const newResponse =
              await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
                `/Appointment?_id=${appointmentArray[j]}&_revinclude=Encounter:appointment`,
                ''
              )
            if (
              newResponse &&
              newResponse.entry &&
              newResponse.entry.length > 0
            ) {
              referredEncounter = getEncounterFromBundle(
                newResponse as R4.IBundle
              )

              if (referredEncounter.length > 0) {
                const searchParametersOld: any = {
                  encounter: referredEncounter[0].id ?? '',
                  _sort: '-_lastUpdated',
                }

                const reportResponse =
                  await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
                    '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
                    referredEncounter[0].appointment
                      ? referredEncounter[0].appointment[0].id ?? ''
                      : '',
                    searchParametersOld
                  )

                logger.info('Org  Response')
                logger.info(reportResponse)
                if (
                  reportResponse &&
                  reportResponse.entry &&
                  reportResponse.entry.length > 0
                ) {
                  for (let i = 0; i < reportResponse.entry.length; i++) {
                    reportsFiles.push(
                      reportResponse.entry[i].resource as R4.IDiagnosticReport
                    )
                  }
                } else {
                  const docResponse =
                    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
                      '/DocumentReference?type=11502-2&status=current',
                      referredEncounter[0].appointment
                        ? referredEncounter[0].appointment[0].id ?? ''
                        : '',
                      searchParametersOld
                    )

                  if (
                    docResponse !== undefined &&
                    docResponse.entry &&
                    docResponse.entry.length > 0
                  ) {
                    for (let i = 0; i < docResponse.entry.length; i++) {
                      docRefFiles.push(
                        docResponse.entry[i].resource as R4.IDocumentReference
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (reportsFiles.length === 0) {
        state.reports = docRefFiles
      } else {
        state.diagnosticReport = reportsFiles.sort((a, b) =>
          a.code &&
          a.code.coding &&
          a.code.coding[0]!.display! > b.code &&
          b.code.coding &&
          b.code.coding[0]!.display!
            ? 1
            : b.code &&
              b.code.coding &&
              b.code.coding[0]!.display! > a.code &&
              a.code.coding &&
              a.code.coding[0]!.display!
            ? -1
            : 0
        )
      }

      state.resultsAvailable = true
      state.fetchingImpressions = false

      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingImpressions = false

      dispatch(reportsSearchSliceForFollowUp.actions.updatedStatus(state))
    } catch (error) {
      const errorSearchDoctor: FollowUpReportsSearchStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        reportsSearchSliceForFollowUp.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function getReports(
  appointment: FhirAppointmentDetail
): Promise<FhirAppointmentDetail> {
  const appointmentData: FhirAppointmentDetail = appointment
  const reportsFiles: R4.IDocumentReference[] = []
  const diagnosticReport: R4.IDiagnosticReport[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: appointment.encounter ? appointment.encounter.id ?? '' : '',
    _sort: '-_lastUpdated',
  }
  let response: any

  response =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
      '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
      appointment.appointment.id!,
      searchParameters
    )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      diagnosticReport.push(response.entry[i].resource as R4.IDiagnosticReport)
    }
  } else {
    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
        '/DocumentReference?type=11502-2&status=current',
        appointment.appointment.id!,
        searchParameters
      )

    if (response && response.entry && response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        reportsFiles.push(response.entry[i].resource as R4.IDocumentReference)
      }
    }
  }

  if (reportsFiles.length > 0) {
    appointmentData.reports = reportsFiles
  }

  if (diagnosticReport.length > 0) {
    appointmentData.diagnosticReport = diagnosticReport.sort((a, b) =>
      a.code &&
      a.code.coding &&
      a.code.coding[0]!.display! > b.code &&
      b.code.coding &&
      b.code.coding[0]!.display!
        ? 1
        : b.code &&
          b.code.coding &&
          b.code.coding[0]!.display! > a.code &&
          a.code.coding &&
          a.code.coding[0]!.display!
        ? -1
        : 0
    )
  }
  logger.info('Org  Response')
  logger.info(response)

  return appointmentData
}
export default reportsSearchSliceForFollowUp.reducer
