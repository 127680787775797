import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { PractitionerWithRole } from 'models/practitionerWithRole'

import React, { useEffect, useState } from 'react'
import { ReportTileForAppointment } from 'views/components/consultation/history/OPD/reportTileForAppointment'
import { PractitionerSelector } from './PractitionerSelector'

interface IProps {
  showPreview: boolean
  onCloseClicked: () => void
  onSelectionChanged: (doctor: PractitionerWithRole) => void
}

export const ProviderSelectorForWalkIn: React.FC<IProps> = ({
  showPreview,
  onCloseClicked,
  onSelectionChanged,
}: IProps) => {
  console.log('1')
  return (
    <Dialog
      maxWidth='sm'
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Provider List</Box>
          <Box>
            <Tooltip title='Close'>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onCloseClicked()
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box width='100%' paddingY={1}>
          <PractitionerSelector
            doctorListId='settingPageDoc'
            isWalkIn={true}
            onSelectionChanged={(doctor: PractitionerWithRole) => {
              onSelectionChanged(doctor)
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
