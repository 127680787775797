/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchInvitations } from 'redux/administration/userSetup/userOnBoardInvitations/userOnboardInvitationSlice'
import { RootState } from 'redux/rootReducer'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  isAgent,
  isCurrentUnitIsClinic,
  isCurrentUnitIsLab,
  isCurrentUnitIsWellonessCenter,
  isFrontDesk,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { GetDefaultBusinessTimingsFromLocation } from 'utils/fhirResoureHelpers/calender_helper'
import { UserTileWithRole } from 'views/pages/general/userTile'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { UserTitleWithoutDetail } from 'views/pages/general/userTileWithoutDetail'
import { LabProfileDetailsData } from 'views/pages/general/labProfileDetails'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'

export interface UnitDetailProps {
  unitOrgDetail: R4.IOrganization
  unitLocationDetail: R4.ILocation
  onUpdated?: () => void
}

export const LabProfileDetails: React.FC<UnitDetailProps> = (
  props: UnitDetailProps
) => {
  const practitionerSearchSlice = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const practRoleData = getCurrentUserPractitionerRoleDetails()
  const organization: R4.IOrganization = getCurrentUserUnitDetails()
  const [userDetails, setUserDetails] = useState<PractitionerWithRole>()

  const dispatch = useDispatch()

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice
  )

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
    dispatch(fetchUser(practRoleData.id ?? ''))
    if (isUnitAdmin() || isFrontDesk()) {
      dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
    }
    return () => {}
  }, [])
  console.log('userDetails', props.unitOrgDetail)
  useEffect(() => {
    if (practitionerSearchSlice.resultsAvailable) {
      if (practitionerSearchSlice.userDetails)
        setUserDetails(practitionerSearchSlice.userDetails)
    }
    return () => {}
  }, [practitionerSearchSlice.resultsAvailable])

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      width='100%'
      height='100%'
      bgcolor={kDialogueBackground}
    >
      <Box display='flex' flexDirection='row' width='100%' height='7.5%' />
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        height='5%'
        bgcolor={useTheme().palette.background.default}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box px={3} py={1}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Box display='flex' paddingX={2} paddingY={1}>
                      <Avatar alt={props.unitOrgDetail.name} src='test.jpg' />
                    </Box> */}

                    <Grid item xs>
                      <Box display='flex' flexDirection='Column' height='30%'>
                        <Box paddingX={0.5}>
                          <Typography
                            variant='subtitle1'
                            style={{
                              textTransform: 'uppercase',
                              fontFamily: 'Open Sans',

                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            {t('labelCommon:contact_information')}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        height='100%'
        width='100%'
        p={1}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              {practitionerSearchSlice.searching && (
                <CircularProgress size={25} />
              )}
              {practitionerSearchSlice.resultsAvailable && (
                <Box width='100%' display='flex' flexDirection='column'>
                  {(isUnitAdmin() || isFrontDesk()) &&
                    unitsOfOrganizationSlice.searching && (
                      <Box width='100%' justifyContent='center'>
                        <CircularProgress size='small' />
                      </Box>
                    )}
                  {(isUnitAdmin() || isFrontDesk()) &&
                    unitsOfOrganizationSlice.resultsAvailable &&
                    (isCurrentUnitIsClinic() ||
                      isCurrentUnitIsWellonessCenter()) && (
                      <LabProfileDetailsData
                        unitOrgDetail={organization}
                        userDetails={practitionerSearchSlice.userDetails}
                        unitLocationDetail={props.unitLocationDetail}
                        unitServiceData={
                          unitsOfOrganizationSlice.unitServices ?? []
                        }
                        onDone={() => {
                          if (isUnitAdmin() || isFrontDesk()) {
                            dispatch(
                              searchUnits(getCurrentUserUnitDetails().id ?? '')
                            )
                          }
                          if (props.onUpdated) {
                            props.onUpdated()
                          }
                        }}
                      />
                    )}

                  {isCurrentUnitIsLab() && (
                    <LabProfileDetailsData
                      unitOrgDetail={organization}
                      userDetails={practitionerSearchSlice.userDetails}
                      unitLocationDetail={props.unitLocationDetail}
                      unitServiceData={[]}
                      onDone={() => {
                        if (isUnitAdmin()) {
                          dispatch(
                            searchUnits(getCurrentUserUnitDetails().id ?? '')
                          )
                        }
                        if (props.onUpdated) {
                          props.onUpdated()
                        }
                      }}
                    />
                  )}

                  {!isUnitAdmin() && !isAgent() && !isFrontDesk() && (
                    <LabProfileDetailsData
                      unitOrgDetail={organization}
                      userDetails={practitionerSearchSlice.userDetails}
                      unitLocationDetail={props.unitLocationDetail}
                      unitServiceData={[]}
                      onDone={() => {
                        if (isUnitAdmin()) {
                          dispatch(
                            searchUnits(getCurrentUserUnitDetails().id ?? '')
                          )
                        }
                        if (props.onUpdated) {
                          props.onUpdated()
                        }
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
