/* eslint-disable no-lone-blocks */
/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { after } from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { Treatment } from 'models/treatMent'
import {
  AyurvedaTreatmentPlanData,
  TreatmentPlanData,
} from 'models/treatmentPlanData'
import moment from 'moment'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { WelloClinicAPIClient } from 'services/welloclinicAPIService'
import { getCarePlanOfPatientWithCurrentPractitioner } from 'utils/careplan_utils/careplan_utils'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { addKriyaDetails } from 'utils/sopaNotes/plan/kriyaHelpers'
import { getNameOfPatient } from 'wello-web-components'
import { getExpandedIPDAppointmentFromBundle } from './ipdAppointmentHelper'

export function getTreatmentPlanFromBundle(
  responseBundle: R4.IBundle,
  details: string
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}
  const morning: string[] = []
  const midMorning: string[] = []
  const afterNoonTherapy: string[] = []
  const eveningTherapy: string[] = []

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (categoryCode === '225365006') {
                      console.log(currentObServation)
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)

                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)
                      if (currentObServation.status !== 'unknown')
                        if (
                          inBetWeenTime(
                            currentObServation.occurrencePeriod
                              ? currentObServation.occurrencePeriod.end
                                ? moment(
                                    currentObServation.occurrencePeriod.end,
                                    ['hh:mm A']
                                  ).format('HH:mm')
                                : '07:30'
                              : '07:30',
                            '07:59',
                            '08:00'
                          )
                        ) {
                          if (currentObServation.occurrencePeriod)
                            morning.push(
                              currentObServation.occurrencePeriod.end ?? ''
                            )
                        }

                      if (
                        inBetWeenTime(
                          currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end
                              ? moment(
                                  currentObServation.occurrencePeriod.end,
                                  ['hh:mm A']
                                ).format('HH:mm')
                              : '09:00'
                            : '09:000',
                          '09:00',
                          '12:30'
                        )
                      ) {
                        if (currentObServation.occurrencePeriod)
                          midMorning.push(
                            currentObServation.occurrencePeriod.end ?? ''
                          )
                      }
                      if (
                        inBetWeenTime(
                          currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end
                              ? moment(
                                  currentObServation.occurrencePeriod.end,
                                  ['hh:mm A']
                                ).format('HH:mm')
                              : '15:00'
                            : '15:000',
                          '15:00',
                          '17:30'
                        )
                      ) {
                        if (currentObServation.occurrencePeriod)
                          eveningTherapy.push(
                            currentObServation.occurrencePeriod.end ?? ''
                          )
                      }

                      afterNoonTherapy.sort()

                      if (
                        inBetWeenTime(
                          currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end
                              ? moment(
                                  currentObServation.occurrencePeriod.end,
                                  ['hh:mm A']
                                ).format('HH:mm')
                              : '19:00'
                            : '19:000',
                          '19:00',
                          '19:30'
                        )
                      ) {
                        if (currentObServation.occurrencePeriod)
                          eveningTherapy.push(
                            currentObServation.occurrencePeriod.end ?? ''
                          )
                      }
                      console.log(currentObServation.occurrencePeriod)
                      planData.push({
                        date: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.start ?? ''
                          : '',
                        endData: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.end ?? ''
                          : '',
                        startTime: moment(date1).format('hh:mm A'),
                        endTime: moment(end).format('hh:mm A'),
                        actualStart: moment(date3).isValid()
                          ? moment(date3).format('hh:mm A')
                          : '',
                        actualEnd: moment(end2).isValid()
                          ? moment(end2).format('hh:mm A')
                          : '',
                        therapy: currentObServation.code
                          ? currentObServation.code.coding
                            ? currentObServation.code.coding[0].display ?? ''
                            : ''
                          : '',
                        treatmentPlan: currentObServation,
                        treatmentType: currentObServation.code
                          ? currentObServation.code.text ?? ''
                          : '',
                        procedure: currentProcedure,
                        status: currentProcedure
                          ? 'Completed'
                          : currentObServation.status === 'revoked' ||
                            currentObServation.status === 'unknown'
                          ? 'Finished'
                          : 'Active',
                        morningTherapy: morning.sort(),
                        midMorningTherapy: midMorning.sort(),
                        afterNoonTherapy,
                        eveingTherapy: eveningTherapy.sort(),
                      })
                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  if (details.length > 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }

  console.log(planData)
  return planData
}

export function getTreatmentPlanFromBundleForAyurveda(
  responseBundle: R4.IBundle,
  details: string
): AyurvedaTreatmentPlanData[] {
  const planData: AyurvedaTreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}
  const morning: string[] = []
  const midMorning: string[] = []
  const afterNoonTherapy: string[] = []
  const eveningTherapy: string[] = []

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (categoryCode === '225365006') {
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)
                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)

                      const startTime = moment(date1, 'DD-MM-YYYY hh:mm:ss')
                      const endTime = moment(end, 'DD-MM-YYYY hh:mm:ss')
                      const minutesDiff = endTime.diff(startTime, 'minutes')

                      if (currentObServation.status !== 'unknown')
                        //   if (currentObServation.status !== 'unknown') {
                        planData.push({
                          date: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.start ?? ''
                            : '',
                          endData: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end ?? ''
                            : '',
                          startTime: moment(date1).format('hh:mm A'),
                          endTime: moment(end).format('hh:mm A'),
                          actualStart: moment(date3).isValid()
                            ? moment(date3).format('hh:mm A')
                            : '',
                          actualEnd: moment(end2).isValid()
                            ? moment(end2).format('hh:mm A')
                            : '',
                          therapy: currentObServation.code
                            ? currentObServation.code.coding
                              ? currentObServation.code.coding[0].display ?? ''
                              : ''
                            : '',
                          treatmentPlan: currentObServation,
                          treatmentType: currentObServation.code
                            ? currentObServation.code.text ?? ''
                            : '',
                          procedure: currentProcedure,
                          status: currentProcedure
                            ? 'Completed'
                            : currentObServation.status === 'revoked' ||
                              currentObServation.status === 'unknown'
                            ? 'Finished'
                            : 'Active',
                          duration: `${minutesDiff} Mins`,
                          medication: currentObServation.supportingInfo
                            ? currentObServation.supportingInfo[0].display ?? ''
                            : '',
                        })
                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  if (details.length > 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }

  return planData
}

export function getTreatmentPlanFromBundleForPT(
  responseBundle: R4.IBundle,
  splitView: string
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (
                      //   categoryCode === '225365006' ||
                      categoryCode === '304' ||
                      categoryCode === '305'
                    ) {
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)
                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)
                      if (currentObServation.status !== 'unknown') {
                        planData.push({
                          date: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.start ?? ''
                            : '',
                          endData: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end ?? ''
                            : '',
                          startTime: moment(date1).format('hh:mm A'),
                          endTime: moment(end).format('hh:mm A'),
                          actualStart: moment(date3).isValid()
                            ? moment(date3).format('hh:mm A')
                            : '',
                          actualEnd: moment(end2).isValid()
                            ? moment(end2).format('hh:mm A')
                            : '',
                          therapy: currentObServation.code
                            ? currentObServation.code.coding
                              ? currentObServation.code.coding[0].display ?? ''
                              : ''
                            : '',
                          treatmentType: currentObServation.code
                            ? currentObServation.code.text ?? ''
                            : '',
                          treatmentPlan: currentObServation,
                          procedure: currentProcedure,
                          status: currentProcedure
                            ? 'Completed'
                            : currentObServation.status === 'revoked'
                            ? 'Finished'
                            : 'Active',
                          morningTherapy: [],
                          midMorningTherapy: [],
                          afterNoonTherapy: [],
                          eveingTherapy: [],
                        })
                      }

                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  if (splitView.length === 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }

  return planData
}

export function getTherapiesPlanFromBundle(
  responseBundle: R4.IBundle
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (categoryCode === '304' || categoryCode === '305') {
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)
                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)
                      //   if (!isTherapist()) {
                      planData.push({
                        date: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.start ?? ''
                          : '',
                        endData: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.end ?? ''
                          : '',
                        startTime: moment(date1).format('hh:mm A'),
                        endTime: moment(end).format('hh:mm A'),
                        actualStart: moment(date3).isValid()
                          ? moment(date3).format('hh:mm A')
                          : '',
                        actualEnd: moment(end2).isValid()
                          ? moment(end2).format('hh:mm A')
                          : '',
                        therapy: currentObServation.code
                          ? currentObServation.code.coding
                            ? currentObServation.code.coding[0].display ?? ''
                            : ''
                          : '',
                        treatmentPlan: currentObServation,
                        procedure: currentProcedure,
                        status: currentProcedure
                          ? 'Completed'
                          : currentObServation.status === 'revoked'
                          ? 'Finished'
                          : 'Active',
                        morningTherapy: [],
                        midMorningTherapy: [],
                        afterNoonTherapy: [],
                        eveingTherapy: [],
                      })
                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return planData
}

export function getProcedureData(
  procedures: any,
  id: string
): R4.IProcedure | undefined {
  for (const key3 in procedures) {
    const currentProcedure: R4.IProcedure = procedures[key3] as R4.IProcedure

    const basedOnData = currentProcedure.basedOn ?? []
    if (basedOnData.length > 0) {
      for (let i = 0; i < basedOnData.length; i++) {
        const ref = basedOnData[i].reference ?? ''
        if (ref.length > 0) {
          const servReq = ref.split('/')[0]
          const idData = ref.split('/')[1]
          if (servReq === 'ServiceRequest') {
            if (idData === id) {
              return currentProcedure
            }
          }
        }
      }
    }
  }
  return undefined
}

export function isPastDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).add(2, 'days').format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date, 'DD-MM-YYYY')
  const diff = todayDate.diff(pastDate, 'days')
  if (
    diff === 0 ||
    diff === 1 ||
    diff === 2 ||
    diff === 3 ||
    diff === 4 ||
    diff === 5 ||
    diff === 6 ||
    diff === 7
  ) {
    return false
  }

  return true
}

export function isPastDateForTherapy(date: string): boolean {
  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(date)))
  const days = duration.asDays()
  const todayDate = moment(
    moment(new Date()).add(2, 'days').format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )

  const pastDate = moment(date, 'DD-MM-YYYY')
  const diff = todayDate.diff(pastDate, 'days')
  if (
    days === 0 ||
    days === 1 ||
    days === 2 ||
    days === 3 ||
    days === 4 ||
    days === 5
  ) {
    return true
  }

  return false
}

export function isCurrentAnd2mrwDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date, 'DD-MM-YYYY')
  const diff = todayDate.diff(pastDate, 'days')

  if (diff === 0 || diff === -1) {
    return false
  }

  return true
}

export function dateCompare(date: string, compareDate: string): boolean {
  const oneDate = moment(moment(compareDate), 'DD-MM-YYYY')
  const dateOne = moment([
    moment(date).format('YYYY'),
    moment(date).format('MM'),
    moment(date).format('DD'),
  ])

  const dateTwo = moment([
    moment(oneDate).format('YYYY'),
    moment(oneDate).format('MM'),
    moment(oneDate).format('DD'),
  ])
  const diff = dateOne.diff(dateTwo, 'days')
  if (diff === 0) {
    return true
  }

  return false
}

export function isCurrentDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date).toISOString()

  return todayDate.isSame(pastDate)
}

export function isTimeAfter(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date).toISOString()

  return todayDate.isSame(pastDate)
}

export function isFutureDateTime(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date, 'DD-MM-YYYY')

  if (todayDate.isBefore(pastDate)) {
    return true
  }

  return false
}

export function getNotes(procedure: R4.IProcedure): string {
  const notes: string = procedure.note ? procedure.note[0].text ?? '' : ''
  return notes
}

export function getNotesFromTherapy(procedure: R4.IServiceRequest): string {
  const notes: string = procedure.note ? procedure.note[0].text ?? '' : ''
  return notes
}

export function getNotesData(notesData: string): string {
  const notes: string = notesData
  return notes
}

export function getOrderStatusText(treatmentPlan: R4.IServiceRequest): string {
  const statusName: string = ''
  switch (treatmentPlan.status) {
    case 'active':
      return 'Scheduled'
    case 'revoked':
      return 'Cancelled'
    case 'unknown':
      return 'Deleted'

    default:
      break
  }
  return statusName
}
export function isFutureDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const future = moment(date, 'DD-MM-YYYY')

  const dDiff = todayDate.diff(future, 'days')

  if (dDiff > 0) {
    return true
  }
  return false
}

export function isCurrentDateForTherapy(date: string): number {
  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(date)))
  const days = duration.asDays()
  return days
}

export function isFutureDateForComplete(date: string): boolean {
  const todayDate = moment().format('YYYY-MM-DD')

  if (date <= todayDate) {
    return true
  }
  return false
}

export function isFutureDateForCancel(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const dateData = moment(date).format('DD-MM-YYYY')
  const future = moment(date, 'DD-MM-YYYY')
  const dDiff = future.diff(todayDate, 'days')
  if (dDiff >= 0) {
    return true
  }
  return false
}

// export function isSameDateTIme(date: string): boolean {
//   const todayDate = moment(
//     moment(new Date()).format('DD-MM-YYYY'),
//     "'DD-MM-YYYY'"
//   )
//   const dateData = moment(date).format('DD-MM-YYYY')
//   const future = moment(dateData, 'DD-MM-YYYY')

//   const dDiff = future.diff(todayDate, 'days'

//   return false
// }

export function isBefore(startTime: string, endTime: string): boolean {
  const todayDaysTime = moment(moment().format('HH:mm:ss'), 'HH:mm:ss')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const date = moment(endTime, 'h:mm:ss A').format('HH:mm:ss')
  const endData = moment(date, 'HH:mm:ss')
  return endData.isAfter(timeAndDate)
}

export function isBeforeTime(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())

  const laterMomentTime = moment('08:00', 'HH:mm')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)

  return currentTIme.isBefore(timeAndDate)
}

export function isAfterSame(startTime: string, endTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const laterMomentTime = moment('08:00', 'HH:mm')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)

  return currentTIme.isSameOrAfter(timeAndDate)
}

export function isAfter(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  return currentTIme.isSameOrAfter(timeAndDate)
}

export function isAfterTherapyFinal(startTime: string, date: string): boolean {
  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(date)))
  const days = duration.asDays()
  if (days === 0) {
    const currentTIme = moment(moment(new Date()).toISOString())
    const currentDate = moment(new Date()).format('YYYY-MM-DD')
    const timeAndDate = moment(`${currentDate} ${startTime}`)
    return currentTIme.isSameOrAfter(timeAndDate)
  }
  return true
}

export function isAfterVital(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  return currentTIme.isSameOrBefore(timeAndDate)
}

export function isBeforeFinal(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  return currentTIme.isSameOrBefore(timeAndDate)
}

export function isSameOrBefore(startTime: string, endTime: string): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentTIme = moment(`${currentDate} ${endTime}`)

  return currentTIme.isSameOrBefore(timeAndDate)
}

export function isBeforeTherapy(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)
  return timeAndDate.isBetween(currentStartTime, currentEndTime)
}

export function isTherapybetweenForMorning(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isTherapybetweenForMidMoring(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)
  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isTherapybetweenForAfterNoon(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isTherapybetweenForEvening(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isSameTherapy(
  startTime: string,
  starttimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  return timeAndDate.isSame(currentStartTime)
}

export function isSameTherapyEnd(startTime: string, endMain: string): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${endMain}`)

  return timeAndDate.isSame(currentStartTime)
}

export function inBetWeenTime(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (timeAndDate.isBetween(currentStartTime, currentEndTime)) return true
  return false
}

export function isSameOrBeforeAdd(
  startTime: string,
  endTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const timeAndDateEnd = moment(`${currentDate} ${endTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isBefore(currentStartTime) &&
    timeAndDateEnd.isAfter(currentEndTime)
  )
    return true
  return false
}

export function isSameOrBeforeAddEveing(
  startTime: string,
  endTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const timeAndDateEnd = moment(`${currentDate} ${endTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isBefore(currentStartTime) &&
    timeAndDateEnd.isAfter(currentEndTime)
  )
    return true
  return false
}

export function isSameOrBeforeBetweenAdd(
  startTime: string,
  endTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const timeAndDateEnd = moment(`${currentDate} ${endTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isBefore(currentStartTime) &&
    timeAndDateEnd.isBetween(currentStartTime, currentEndTime)
  )
    return true
  return false
}

export function getStatusOfTherapy(sr: R4.IServiceRequest) {
  switch (sr.status) {
    case 'revoked':
      return 'Cancelled'
    case 'completed':
      return 'Completed'

    case 'active':
      return 'Scheduled'

    default:
      return ''
  }
}

export function getStatusOfTherapyByCode(code: string) {
  switch (code) {
    case 'revoked':
      return 'Cancelled'
    case 'completed':
      return 'Completed'

    case 'active':
      return 'Scheduled'

    default:
      return ''
  }
}

export async function getAppointmentDetails(
  serviceRequest: R4.IServiceRequest
): Promise<FhirActiveIPDDetailsForMedicalRole | FHIRErrorResponses> {
  const fhirClient: WelloClinicAPIClient = new WelloClinicAPIClient()

  const response: any = await fhirClient.doGetRequest(
    `/ipd/${serviceRequest.id}`,
    {}
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }
  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    const fhirAppointments: FhirActiveIPDDetailsForMedicalRole[] =
      getExpandedIPDAppointmentFromBundle(response)
    if (fhirAppointments && fhirAppointments.length > 0) {
      return fhirAppointments[0]
    }
  }

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export async function addTreatmentDetailsForAppointment(
  fhirAppointmentDetails: FhirAppointmentDetail,
  encounterId: R4.IReference,
  treatment: Treatment[]
) {
  const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
    fhirAppointmentDetails.patient.id!
  )

  let result = false
  try {
    const treatMentBundle = createBundleForTherapy(
      treatment,
      fhirAppointmentDetails,
      encounterId,
      carePlanOfPatient
    )
    result = await addKriyaDetails(treatMentBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function deleteTherapyInAppointment(
  treatment: R4.IServiceRequest
) {
  let result = false
  try {
    const Practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
    const PractitionerRole: R4.IPractitionerRole =
      getCurrentUserPractitionerRoleDetails()

    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...treatment,
    }
    const oldAppointmentMatchString: string = `W/${JSON.stringify(
      oldUpdatedServiceRequest.meta?.versionId ?? ' '
    )}`
    oldUpdatedServiceRequest.status = 'unknown'
    oldUpdatedServiceRequest.doNotPerform = true
    //   oldUpdatedServiceRequest.reasonCode = {[ coding: notes,]}
    oldUpdatedServiceRequest.performer = [
      {
        display: getNameFromHumanName(Practitioner.name ?? []),
        id: PractitionerRole.id,
        reference: `${PractitionerRole.resourceType}/${PractitionerRole.id}`,
        type: PractitionerRole.resourceType,
      },
    ]

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          request: {
            ifMatch: oldAppointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          },
          resource: oldUpdatedServiceRequest,
        },
      ],
    }
    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function createBundleForTherapy(
  treatment: Treatment[],
  fhirAppointmentDetails: FhirAppointmentDetail,
  encounterReference: R4.IReference,
  plan?: R4.ICarePlan
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  for (let i = 0; i < treatment.length; i++) {
    const therapyCoding: R4.ICoding[] = treatment[i].morning.therapy ?? []
    const idData: string[] = treatment[i].morning.threapyId ?? []

    if (therapyCoding.length > 0) {
      {
        for (let k = 0; k < therapyCoding.length; k++) {
          const displayData: string = therapyCoding[k].display ?? ''
          if (displayData) {
            const newDate = new Date(
              `${treatment[i].date}T${treatment[i].morning.startTime}`
            )
            const newDateEnd = new Date(
              `${treatment[i].date}T${treatment[i].morning.endTime}`
            )
            const isoStartDateTIme = newDate.toISOString()
            const isoEndDateTIme = newDateEnd.toISOString()
            const momentObj = moment(
              treatment[i].date + treatment[i].morning.startTime,
              'YYYY-MM-DDLT'
            )
            const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
            const enmomentObj = moment(
              treatment[i].date + treatment[i].morning.endTime,
              'YYYY-MM-DDLT'
            )
            const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')

            const currentServiceRequest1: R4.IServiceRequest = {
              resourceType: 'ServiceRequest',
              meta: {
                profile: [
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
                ],
              },
              encounter: encounterReference,
              subject: {
                id: fhirAppointmentDetails.patient.id,
                reference: `${fhirAppointmentDetails.patient.resourceType}/${fhirAppointmentDetails.patient.id}`,
                display: getNameOfPatient(fhirAppointmentDetails.patient),
              },

              requester: getCurrentUserPractitionerRoleRef(),
              status: 'active',
              intent: 'order',
              category: [
                {
                  coding: [
                    {
                      system: 'http://snomed.info/sct',
                      code: '225365006',
                      display: 'Regime care therapy',
                    },
                  ],
                },
              ],
              performer: [getCurrentUserUnitReference()!],
              priority: 'urgent',

              occurrencePeriod: {
                start: isoStartDateTIme,
                end: isoEndDateTIme,
              },
              code: {
                text:
                  treatment[i].morning.treatmentType === 'None'
                    ? ''
                    : treatment[i].morning.treatmentType,
                coding: [therapyCoding[k]],
              },
              authoredOn: new Date().toISOString(),
            }

            if (plan) {
              currentServiceRequest1.basedOn = [
                {
                  reference: `CarePlan/${plan.id!}`,
                },
              ]
            }

            const performerData: R4.IReference = {
              id: `${idData[k]}`,
              reference: `HealthcareService/${idData[k]}`,
            }

            currentServiceRequest1.supportingInfo = [
              {
                id: `${idData[k]}`,
                reference: `HealthcareService/${idData[k]}`,
              },
            ]

            if (currentServiceRequest1.performer)
              currentServiceRequest1.performer.push(performerData)
            const morningExtension: R4.IExtension = {}
            morningExtension.url =
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
            morningExtension.valueTiming = {
              code: {
                coding: [
                  {
                    system: 'http://hl7.org/fhir/event-timing',
                    code: 'MORN.early',
                    display: 'Early Morning',
                  },
                ],
              },
            }
            currentServiceRequest1.extension = []
            currentServiceRequest1.extension.push(morningExtension)
            currentServiceRequest1.extension.push({
              url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
              valueReference: {
                reference: `Appointment/${fhirAppointmentDetails.appointment
                  .id!}`,
              },
            })

            requestBundle.entry?.push({
              fullUrl: `${currentServiceRequest1.resourceType}/`,
              request: {
                method: R4.Bundle_RequestMethodKind._post,
                url: currentServiceRequest1.resourceType,
              },
              resource: currentServiceRequest1,
            })
          }
        }
      }
    }

    if (treatment[i].midAfterNoon.therapy) {
      const midAfterNoonThreapy: R4.ICoding[] =
        treatment[i].midAfterNoon.therapy ?? []
      const midAfterNoonId: string[] = treatment[i].midAfterNoon.threapyId ?? []
      if (midAfterNoonThreapy && midAfterNoonThreapy.length > 0)
        for (let k = 0; k < midAfterNoonThreapy.length; k++) {
          const momentObj = moment(
            treatment[i].date + treatment[i].midAfterNoon.startTime,
            'YYYY-MM-DDLT'
          )
          const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const enmomentObj = moment(
            treatment[i].date + treatment[i].midAfterNoon.endTime,
            'YYYY-MM-DDLT'
          )
          const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')

          const currentServiceRequest2: R4.IServiceRequest = {
            resourceType: 'ServiceRequest',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
              ],
            },
            subject: {
              id: fhirAppointmentDetails.patient.id,
              reference: `${fhirAppointmentDetails.patient.resourceType}/${fhirAppointmentDetails.patient.id}`,
              display: getNameOfPatient(fhirAppointmentDetails.patient),
            },

            requester: getCurrentUserPractitionerRoleRef(),
            encounter: encounterReference,
            status: 'active',
            intent: 'order',
            category: [
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '225365006',
                    display: 'Regime care therapy',
                  },
                ],
              },
            ],
            performer: [getCurrentUserUnitReference()!],
            priority: 'urgent',
            occurrencePeriod: {
              start: dateTime,
              end: endateTime,
            },

            authoredOn: new Date().toISOString(),
            code: {
              text:
                treatment[i].midAfterNoon.treatmentType === 'None'
                  ? ''
                  : treatment[i].midAfterNoon.treatmentType,
              coding: [midAfterNoonThreapy[k]],
            },
          }

          if (plan) {
            currentServiceRequest2.basedOn = [
              {
                reference: `CarePlan/${plan.id!}`,
              },
            ]
          }

          const performerData: R4.IReference = {
            id: `${midAfterNoonId[k]}`,
            reference: `HealthcareService/${midAfterNoonId[k]}`,
          }

          currentServiceRequest2.supportingInfo = [
            {
              id: `${midAfterNoonId[k]}`,
              reference: `HealthcareService/${midAfterNoonId[k]}`,
            },
          ]
          if (currentServiceRequest2.performer)
            currentServiceRequest2.performer.push(performerData)

          const midMorningExtension: R4.IExtension = {}
          midMorningExtension.url =
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
          midMorningExtension.valueTiming = {
            code: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/event-timing',
                  code: 'MORN',
                  display: 'Morning',
                },
              ],
            },
          }
          currentServiceRequest2.extension = []
          currentServiceRequest2.extension.push(midMorningExtension)
          currentServiceRequest2.extension.push({
            url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
            valueReference: {
              reference: `Appointment/${fhirAppointmentDetails.appointment
                .id!}`,
            },
          })
          requestBundle.entry?.push({
            fullUrl: `${currentServiceRequest2.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: currentServiceRequest2.resourceType,
            },
            resource: currentServiceRequest2,
          })
        }
    }

    if (treatment[i].afterNoon.therapy) {
      const afternoonTherapy: R4.ICoding[] =
        treatment[i].afterNoon.therapy ?? []
      const afternoonId: string[] = treatment[i].afterNoon.threapyId ?? []
      if (afternoonTherapy && afternoonTherapy.length > 0) {
        for (let k = 0; k < afternoonTherapy.length; k++) {
          const momentObj = moment(
            treatment[i].date + treatment[i].afterNoon.startTime,
            'YYYY-MM-DDLT'
          )
          const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const enmomentObj = moment(
            treatment[i].date + treatment[i].afterNoon.endTime,
            'YYYY-MM-DDLT'
          )
          const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const currentServiceRequest3: R4.IServiceRequest = {
            resourceType: 'ServiceRequest',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
              ],
            },
            subject: {
              id: fhirAppointmentDetails.patient.id,
              reference: `${fhirAppointmentDetails.patient.resourceType}/${fhirAppointmentDetails.patient.id}`,
              display: getNameOfPatient(fhirAppointmentDetails.patient),
            },

            requester: getCurrentUserPractitionerRoleRef(),
            status: 'active',
            intent: 'order',
            category: [
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '225365006',
                    display: 'Regime care therapy',
                  },
                ],
              },
            ],
            performer: [getCurrentUserUnitReference()!],
            priority: 'urgent',

            occurrencePeriod: {
              start: dateTime,
              end: endateTime,
            },
            encounter: encounterReference,
            authoredOn: new Date().toISOString(),
            code: {
              text:
                treatment[i].afterNoon.treatmentType === 'None'
                  ? ''
                  : treatment[i].afterNoon.treatmentType,
              coding: [afternoonTherapy[k]],
            },
          }

          if (plan) {
            currentServiceRequest3.basedOn = [
              {
                reference: `CarePlan/${plan.id!}`,
              },
            ]
          }

          const performerData: R4.IReference = {
            id: `${afternoonId[k]}`,
            reference: `HealthcareService/${afternoonId[k]}`,
          }

          currentServiceRequest3.supportingInfo = [
            {
              id: `${afternoonId[k]}`,
              reference: `HealthcareService/${afternoonId[k]}`,
            },
          ]
          if (currentServiceRequest3.performer)
            currentServiceRequest3.performer.push(performerData)

          const afterNoonExtension: R4.IExtension = {}
          afterNoonExtension.url =
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
          afterNoonExtension.valueTiming = {
            code: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/event-timing',
                  code: 'AFT',
                  display: 'Afternoon',
                },
              ],
            },
          }
          currentServiceRequest3.extension = []
          currentServiceRequest3.extension.push(afterNoonExtension)
          currentServiceRequest3.extension.push({
            url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
            valueReference: {
              reference: `Appointment/${fhirAppointmentDetails.appointment
                .id!}`,
            },
          })
          requestBundle.entry?.push({
            fullUrl: `${currentServiceRequest3.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: currentServiceRequest3.resourceType,
            },
            resource: currentServiceRequest3,
          })
        }
      }
    }

    if (treatment[i].evening.therapy) {
      const eveningTherapy: R4.ICoding[] = treatment[i].evening.therapy ?? []
      const eveningId: string[] = treatment[i].evening.threapyId ?? []
      if (eveningTherapy && eveningTherapy.length > 0) {
        for (let k = 0; k < eveningTherapy.length; k++) {
          const momentObj = moment(
            treatment[i].date + treatment[i].evening.startTime,
            'YYYY-MM-DDLT'
          )
          const dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const enmomentObj = moment(
            treatment[i].date + treatment[i].evening.endTime,
            'YYYY-MM-DDLT'
          )
          const endateTime = enmomentObj.format('YYYY-MM-DDTHH:mm:ssZ')
          const currentServiceRequest4: R4.IServiceRequest = {
            resourceType: 'ServiceRequest',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
              ],
            },
            subject: {
              id: fhirAppointmentDetails.patient.id,
              reference: `${fhirAppointmentDetails.patient.resourceType}/${fhirAppointmentDetails.patient.id}`,
              display: getNameOfPatient(fhirAppointmentDetails.patient),
            },

            requester: getCurrentUserPractitionerRoleRef(),
            status: 'active',
            intent: 'order',
            category: [
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '225365006',
                    display: 'Regime care therapy',
                  },
                ],
              },
            ],
            performer: [getCurrentUserUnitReference()!],
            priority: 'urgent',

            occurrencePeriod: {
              start: dateTime,
              end: endateTime,
            },
            encounter: encounterReference,
            code: {
              text:
                treatment[i].evening.treatmentType === 'None'
                  ? ''
                  : treatment[i].evening.treatmentType,
              coding: [eveningTherapy[k]],
            },
          }

          if (plan) {
            currentServiceRequest4.basedOn = [
              {
                reference: `CarePlan/${plan.id!}`,
              },
            ]
          }

          const performerData: R4.IReference = {
            id: `HealthcareService/${eveningId[k]}`,
            reference: `HealthcareService/${eveningId[k]}`,
          }
          if (currentServiceRequest4.performer)
            currentServiceRequest4.performer.push(performerData)

          const eveningExtension: R4.IExtension = {}
          eveningExtension.url =
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-time-ext'
          eveningExtension.valueTiming = {
            code: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/event-timing',
                  code: 'EVE',
                  display: 'Evening',
                },
              ],
            },
          }

          currentServiceRequest4.supportingInfo = [
            {
              id: `HealthcareService/${eveningId[k]}`,
              reference: `HealthcareService/${eveningId[k]}`,
            },
          ]
          currentServiceRequest4.extension = []
          currentServiceRequest4.extension.push(eveningExtension)
          currentServiceRequest4.extension.push({
            url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
            valueReference: {
              reference: `Appointment/${fhirAppointmentDetails.appointment
                .id!}`,
            },
          })
          requestBundle.entry?.push({
            fullUrl: `${currentServiceRequest4.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: currentServiceRequest4.resourceType,
            },
            resource: currentServiceRequest4,
          })
        }
      }
    }
  }

  return requestBundle
}

export function getTreatmentListForAppointment(
  responseBundle: R4.IBundle,
  details: string
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}
  const morning: string[] = []
  const midMorning: string[] = []
  const afterNoonTherapy: string[] = []
  const eveningTherapy: string[] = []

  if (responseBundle.entry) {
    const entries: R4.IBundle_Entry[] = responseBundle.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.id) {
          switch (value.resource.resourceType) {
            case 'Procedure':
              procedures[value.resource.id] = value.resource as R4.IProcedure
              break
            case 'ServiceRequest':
              serviceRequest[value.resource.id] =
                value.resource as R4.IServiceRequest
              break
            default:
              break
          }
        }
      }
    })

    // eslint-disable-next-line guard-for-in
    for (const key1 in serviceRequest) {
      const currentObServation: R4.IServiceRequest = serviceRequest[
        key1
      ] as R4.IServiceRequest

      const dateTime = currentObServation.occurrencePeriod
        ? currentObServation.occurrencePeriod.start ?? ''
        : ''

      const endDateTime = currentObServation.occurrencePeriod
        ? currentObServation.occurrencePeriod.end ?? ''
        : ''

      const date1 = new Date(dateTime)

      const end = new Date(endDateTime)
      const date2 = date1.toLocaleDateString().replace(/\//g, '-')

      const currentProcedure: R4.IProcedure | undefined = getProcedureData(
        procedures,
        currentObServation.id!
      )

      const dateTime1 = currentProcedure?.performedPeriod
        ? currentProcedure.performedPeriod.start ?? ''
        : ''

      const endDateTime1 = currentProcedure?.performedPeriod
        ? currentProcedure.performedPeriod.end ?? ''
        : ''

      const date3 = new Date(dateTime1)
      const end2 = new Date(endDateTime1)
      if (currentObServation.status !== 'unknown')
        if (
          inBetWeenTime(
            currentObServation.occurrencePeriod
              ? currentObServation.occurrencePeriod.end
                ? moment(currentObServation.occurrencePeriod.end, [
                    'hh:mm A',
                  ]).format('HH:mm')
                : '07:30'
              : '07:30',
            '07:59',
            '08:00'
          )
        ) {
          if (currentObServation.occurrencePeriod)
            morning.push(currentObServation.occurrencePeriod.end ?? '')
        }

      if (
        inBetWeenTime(
          currentObServation.occurrencePeriod
            ? currentObServation.occurrencePeriod.end
              ? moment(currentObServation.occurrencePeriod.end, [
                  'hh:mm A',
                ]).format('HH:mm')
              : '09:00'
            : '09:000',
          '09:00',
          '12:30'
        )
      ) {
        if (currentObServation.occurrencePeriod)
          midMorning.push(currentObServation.occurrencePeriod.end ?? '')
      }
      if (
        inBetWeenTime(
          currentObServation.occurrencePeriod
            ? currentObServation.occurrencePeriod.end
              ? moment(currentObServation.occurrencePeriod.end, [
                  'hh:mm A',
                ]).format('HH:mm')
              : '15:00'
            : '15:000',
          '15:00',
          '17:30'
        )
      ) {
        if (currentObServation.occurrencePeriod)
          eveningTherapy.push(currentObServation.occurrencePeriod.end ?? '')
      }

      afterNoonTherapy.sort()

      if (
        inBetWeenTime(
          currentObServation.occurrencePeriod
            ? currentObServation.occurrencePeriod.end
              ? moment(currentObServation.occurrencePeriod.end, [
                  'hh:mm A',
                ]).format('HH:mm')
              : '19:00'
            : '19:000',
          '19:00',
          '19:30'
        )
      ) {
        if (currentObServation.occurrencePeriod)
          eveningTherapy.push(currentObServation.occurrencePeriod.end ?? '')
      }
      console.log(currentObServation.occurrencePeriod)
      planData.push({
        date: currentObServation.occurrencePeriod
          ? currentObServation.occurrencePeriod.start ?? ''
          : '',
        endData: currentObServation.occurrencePeriod
          ? currentObServation.occurrencePeriod.end ?? ''
          : '',
        startTime: moment(date1).format('hh:mm A'),
        endTime: moment(end).format('hh:mm A'),
        actualStart: moment(date3).isValid()
          ? moment(date3).format('hh:mm A')
          : '',
        actualEnd: moment(end2).isValid() ? moment(end2).format('hh:mm A') : '',
        therapy: currentObServation.code
          ? currentObServation.code.coding
            ? currentObServation.code.coding[0].display ?? ''
            : ''
          : '',
        treatmentPlan: currentObServation,
        treatmentType: currentObServation.code
          ? currentObServation.code.text ?? ''
          : '',
        procedure: currentProcedure,
        status: currentProcedure
          ? 'Completed'
          : currentObServation.status === 'revoked' ||
            currentObServation.status === 'unknown'
          ? 'Finished'
          : 'Active',
        morningTherapy: morning.sort(),
        midMorningTherapy: midMorning.sort(),
        afterNoonTherapy,
        eveingTherapy: eveningTherapy.sort(),
      })
      //   }
    }
  }

  if (details.length > 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }
  return planData
}
