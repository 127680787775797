import { createContext } from 'react'

export interface CPGSelectionsOfType {
  type: string
  selectedItemsIdentifiers: string[]
}

export interface CPGSelections {
  cpgIdentifier: string
  selectedData: CPGSelectionsOfType[]
}

export interface CPGSelectionContextData {
  selectionData: CPGSelections[]
  addSelection: (
    cpgIdentifier: string,
    selectedItemType: string,
    newSelectedItemIdentifier: string
  ) => void
  removeSelection: (
    cpgIdentifier: string,
    selectedItemType: string,
    newSelectedItemIdentifier: string
  ) => void
}

const CPGSelectionContext = createContext<CPGSelectionContextData>({
  selectionData: [],
  addSelection: () => {},
  removeSelection: () => {},
})

export default CPGSelectionContext
