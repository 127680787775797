import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  requestCDSSpecialistAppointment,
  resetAddReferralCPGUpdate,
} from 'redux/consultation/recommendation_additions/referral_addition/referralAdditionSlice'
import { getSpecialistReferralsOfAppointment } from 'redux/consultation/speacialists_referrals_list_slice/specialistReferralsListSlice'
import { RootState } from 'redux/rootReducer'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddReferralPopUp, AddReferralPopUpProps } from './addReferralNotePopUp'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface AddReferralsButtonProps {
  patient: R4.IPatient
  encounterID: string
  appointments: R4.ICoding[]
  carePlanIdentifier?: string
}

export const AddReferralsButton: React.FC<AddReferralsButtonProps> = ({
  patient,
  encounterID,
  appointments,
  carePlanIdentifier,
}: AddReferralsButtonProps) => {
  const instructionAdditionSlice = useSelector(
    (state: RootState) => state.referralAdditionSlice
  )
  const subscriptionStatus = useSelector(
    (state: RootState) => state.patientSubscriptionForUnitSlice
  )

  const visitContext = useContext(VisitAppointmentContext)

  useEffect(() => {
    if (instructionAdditionSlice.additionSuccessful) {
      dispatch(resetAddReferralCPGUpdate())
      if (visitContext.onRecommendationAdditionUpdate) {
        visitContext.onRecommendationAdditionUpdate('specialistReferral')
      }
    }
  }, [instructionAdditionSlice])

  const [referralNotePopUp, setreferralNotePopUp] =
    useState<AddReferralPopUpProps>({
      open: false,
    })
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <Box>
      <Box flexDirection='row'>
        {instructionAdditionSlice.addingLabTests && (
          <Box height={25} width={25}>
            <CircularProgress size={20} />
          </Box>
        )}

        {instructionAdditionSlice.errorWhileAdding && (
          <Box flexDirection='row'>
            <Button
              variant='text'
              color='primary'
              id='retry'
              className={classes.buttonText}
              onClick={(e) => {
                if (subscriptionStatus.tasks) {
                  const carePlanId = getCarePlanIdFromTaskOutput(
                    subscriptionStatus.tasks[0].task
                  )

                  if (carePlanId) {
                    setreferralNotePopUp({
                      open: true,
                      onCancelActionClicked: () => {
                        setreferralNotePopUp({ open: false })
                      },
                      onSuccessfulAction: (
                        referralNote: string,
                        practRole: R4.IPractitionerRole,
                        practitioner: R4.IPractitioner
                      ) => {
                        const note = referralNote
                        console.log('note', note)
                        setreferralNotePopUp({ open: false })
                        dispatch(
                          requestCDSSpecialistAppointment(
                            patient,
                            appointments,
                            encounterID,
                            carePlanIdentifier,
                            carePlanId,
                            note,
                            practRole,
                            practitioner
                          )
                        )
                      },
                    })
                  }
                }

                e.stopPropagation()
              }}
            >
              Retry
            </Button>
          </Box>
        )}
        {instructionAdditionSlice.additionSuccessful && (
          <Box flexDirection='row' px={2}>
            <Typography variant='subtitle1' color='initial'>
              Added
            </Typography>
          </Box>
        )}
        {!instructionAdditionSlice.additionSuccessful &&
          !instructionAdditionSlice.errorWhileAdding &&
          !instructionAdditionSlice.addingLabTests && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='book_appointment'
                className={classes.buttonText}
                onClick={(e) => {
                  if (
                    subscriptionStatus.subscriptionStatus === 'interested' ||
                    subscriptionStatus.subscriptionStatus === 'active'
                  ) {
                    if (subscriptionStatus.tasks) {
                      const carePlanId = getCarePlanIdFromTaskOutput(
                        subscriptionStatus.tasks[0].task
                      )

                      if (carePlanId) {
                        setreferralNotePopUp({
                          open: true,
                          onCancelActionClicked: () => {
                            setreferralNotePopUp({ open: false })
                          },
                          onSuccessfulAction: (
                            referralNote: string,
                            practRole: R4.IPractitionerRole,
                            practitioner: R4.IPractitioner
                          ) => {
                            const note = referralNote
                            console.log('note', note)
                            setreferralNotePopUp({ open: false })
                            dispatch(
                              requestCDSSpecialistAppointment(
                                patient,
                                appointments,
                                encounterID,
                                carePlanIdentifier,
                                carePlanId,
                                note,
                                practRole,
                                practitioner
                              )
                            )
                          },
                          carePlanId,
                        })
                      }
                    }
                  } else {
                    dispatch(
                      showErrorAlert(
                        'Please subscribe to the plan to book appointment',
                        undefined,
                        undefined,
                        false
                      )
                    )
                  }
                  e.stopPropagation()
                }}
              >
                Book Appointment
              </Button>
            </Box>
          )}
      </Box>
      <AddReferralPopUp {...referralNotePopUp} />
    </Box>
  )
}
