import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Theme,
  makeStyles,
} from '@material-ui/core'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import { ClearOutlined } from '@material-ui/icons'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addHistoryProcedureDetails,
  resetAddConditionState,
} from 'redux/fhirMedicalResources/addProcedure/addProcedureSlice'
import { RootState } from 'redux/rootReducer'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
  examMethods,
} from 'utils/constants'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
} from 'utils/fhirResourcesHelper'
import { Autocomplete } from '@material-ui/lab'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import { WelloLoadingPage, WelloTextField } from 'wello-web-components'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSignExam } from 'models/WelloConditions'
import _ from 'lodash'
import { PurposeOfUse } from 'models/purposeOfUse'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { signs } from 'utils/signs'
import { LocationMasterSelector } from 'views/components/consultation/subjective/locationMasterSelector'
import { LocationData } from 'models/location'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {
  addPhysicalExam,
  createBundleObjectForObservationsPhysicalExam,
  resetPhysicalExamState,
} from 'redux/fhirMedicalResources/addPhysicalExam/addPhysicalExamSlice'
import { SiteSelector } from 'views/components/referralHistory.tsx/assessmentHistory/siteSelctor'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { updateAddictions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { ChiefComplaintTerminology } from 'models/chiefComplaintData'
import { ChiefComplaintsSelector } from '../subjective/chiefComplaintsMasterSelector'

// import { ProcedueTileForDisplay } from './procedureTile'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onMedicalProcedureAdded: (
    createdCondition?: R4.IObservation | undefined
  ) => void
  existingExam: WelloSignExam
}
export const EditExamHandler: React.FC<Props> = ({
  open,
  onClose,
  fhirAppointmentDetails,
  onMedicalProcedureAdded,
  existingExam,
}) => {
  const { t } = useTranslation(['common', 'procedure'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))
  const classes = useStyles()
  const [currentCondition, setCurrentCondition] =
    useState<WelloSignExam>(existingExam)

  const [addedConditions, setAddedConditions] = useState<WelloSignExam[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()

  const addVitalSlice = useSelector(
    (state: RootState) => state.addPhysicalExaminationSlice
  )

  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false
    if (currentCondition.singExam === undefined) {
      result = true
    }

    return result
  }

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })
    const oldObsList: R4.IObservation[] = []

    const oldProcedure: R4.IObservation = {
      ...currentCondition.obsData!,
    }
    oldProcedure.status = ObservationStatusKind._enteredInError
    oldObsList.push(oldProcedure)
    const bundleObject: R4.IBundle =
      createBundleObjectForObservationsPhysicalExam(fhirAppointmentDetails, [
        currentCondition,
      ])

    for (let i = 0; i < oldObsList.length; i++) {
      bundleObject.entry?.push({
        resource: oldObsList[i],
        fullUrl: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        },
      })
    }

    setLoading(true)

    updateAddictions(bundleObject, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showSuccessAlert('Signs on Exam details updated successfully')
          )
          onMedicalProcedureAdded(undefined)
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Signs on Exam details. Please try again later'
            )
          )
        }
      }
    )
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloSignExam[] = [...addedConditions]
    const newMedication: WelloSignExam = {
      ...currentCondition,
    }

    tempMedication.push(newMedication)

    Promise.resolve().then(() => {
      setAddedConditions(tempMedication)
      resetDetails()
    })

    if (pushToSave) {
      dispatch(addPhysicalExam(fhirAppointmentDetails, tempMedication))
    }
  }

  function resetDetails() {
    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
    })
  }

  useEffect(() => {
    if (addVitalSlice.additionSuccessful) {
      setAddedConditions([])
      onMedicalProcedureAdded()
      dispatch(resetPhysicalExamState())
      resetDetails()
    }
  }, [dispatch, addVitalSlice, onMedicalProcedureAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAddedConditions([])
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        Edit Sign on Examination
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#FFFFFF93',
          padding: 0,

          margin: 0,
        }}
      >
        {/* {loading && <WelloLoadingPage title='Updating details' />} */}

        {!loading && (
          <Box
            px={2}
            py={2}
            key={currentCondition.id}
            display='flex'
            flexDirection='column'
            width='100%'
          >
            <Box display='flex' width='100%' flexDirection='column' px={1}>
              <Box display='flex' flexDirection='row'>
                <WelloFormItemLabel title='Select Sign' />{' '}
              </Box>
              <Box width='100%'>
                <ChiefComplaintsSelector
                  onSelectionChanges={(e: ChiefComplaintTerminology) => {
                    setCurrentCondition({
                      ...currentCondition,
                      singExam: e as R4.ICodeableConcept,
                    })
                  }}
                  disabled={false}
                  selectOther={false}
                  id='chief_id_1'
                  preSelectedComplaints={
                    currentCondition.singExam as ChiefComplaintTerminology
                  }
                  type='finding'
                />
              </Box>
            </Box>
            <Box display='flex' width='100%' flexDirection='row' px={1}>
              <Box width='50%' paddingRight={0.5}>
                <WelloSelectFHIRCoding
                  title='Examination Method'
                  onChanged={(selectedKriya) => {
                    setCurrentCondition({
                      ...currentCondition,
                      type: selectedKriya,
                    })
                  }}
                  availableCodings={examMethods}
                  id='med_methods'
                  textFieldProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  preSelectedCoding={existingExam.type}
                />
              </Box>
              <Box width='50%' display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='row'>
                  <WelloFormItemLabel title='Site' />{' '}
                </Box>
                <SiteSelector
                  onSelectionChanges={(e: LocationData) => {
                    const conditonVal: R4.ICoding = {
                      code: e.cui,
                      display: e.display
                        .replace('SNOMED', '')
                        .replace(/[0-9]/g, ''),
                    }

                    setCurrentCondition({
                      ...currentCondition,
                      site: conditonVal,
                    })
                  }}
                  disabled={false}
                  id='location'
                  multiple={false}
                  preSelectedComplaints={
                    existingExam.site
                      ? {
                          cui: existingExam.site.code ?? '',
                          display: existingExam.site.display ?? '',
                          snomed_ct: existingExam.site.system ?? '',
                        }
                      : undefined
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='flex-end'
          paddingRight={1.5}
        >
          <Button
            onClick={() => {
              onClose()
              resetDetails()
              setAddedConditions([])
            }}
            variant='outlined'
            id='proc_16'
            disableElevation
            disabled={loading}
          >
            {t('labelCommon:cancel')}
          </Button>

          <Button
            onClick={() => {
              updateObsFinal()
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='proc_17'
            disabled={loading || isEnableCreateButton()}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
