import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core'

import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getIdOfCardEntry,
  getAppointments,
  hasError,
  getTestsToBePerformed,
  hasLabTestsOfType,
  getLabTestsOfType,
  getLoincCodeFromPlanDef,
  isEssential,
  getJustificationOfPlanDef,
  getKriyas,
  getCategorizedKriyas,
  CategorizedKriyas,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { AddReferralsButton } from 'views/components/consultation/plan/cdsRecommendations/add_referrals_button'
import {
  kBackgroundDefault,
  kPrimaryMain,
  textMain,
} from 'configs/styles/muiThemes'
import CPGSelectionContext, {
  CPGSelectionContextData,
} from 'views/contexts/cpgContextSelection'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import { RootState } from 'redux/rootReducer'
import { getKriyasOrderDetailsFromEntries } from 'utils/patientHelper/cds_helper'
import { AddLabTestsButton } from '../add_lab_orders_button'
import { AddKriyaRecommendationButton } from '../add_kriya_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  encounterId: string
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const KriyasOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  patient,
  encounterId,
  carePlanIdentifier,
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const labDiagnosticTestCodes = useSelector(
    (state: RootState) =>
      state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
  )
  const cdsRecommendationAddition = useContext(CPGSelectionContext)

  const selectedKriyas = getSelectionContextTypeSections({
    cpgIdentifier: carePlanIdentifier,
    selectionCategoryType: 'kriyas',
    selectionData: cdsRecommendationAddition.selectionData ?? [],
  })

  function onSelectedKriyasChanged(changedTest: string) {
    cdsRecommendationAddition.addSelection(
      carePlanIdentifier,
      'kriyas',
      changedTest
    )
  }

  function isTestAlreadyAdded(testCode: string): boolean {
    return (labDiagnosticTestCodes ?? []).includes(testCode)
  }

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('kriyas') && (
          <Box
            display='flex'
            flexDirection='column'
            key='kriyas_cpg_outer_label'
            py={0.25}
            overflow='auto'
          >
            <Box
              display='flex'
              flexDirection={
                (card.interceptedSuggestions.get('kriyas') as any[]).length > 1
                  ? 'column'
                  : 'row'
              }
              key='kriyas_cpg_inner_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                width='100%'
                flex='row'
                id='referrals_list'
                display='flex'
                overflow='auto'
              >
                {(card.interceptedSuggestions.get('kriyas') as any[]).length >
                  0 && (
                  <Box display='flex' width='100%' flexDirection='column'>
                    {/* <Box
                        display='row'
                        width='100%'
                        flexDirection='column'
                        py={2}
                        px={1}
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          color: 'black',

                          backgroundColor: kBackgroundDefault,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            color: 'black',
                          }}
                        >
                          Kriya
                        </Typography>
                      </Box> */}
                    {(card.interceptedSuggestions.get('kriyas') as any[])
                      .length > 0 && (
                      <List
                        style={{
                          columnCount:
                            (card.interceptedSuggestions.get('kriyas') as any[])
                              .length > 10
                              ? 2
                              : 1,
                          width: '100%',
                          paddingBottom: 0,
                        }}
                      >
                        {getCategorizedKriyas(
                          card.interceptedSuggestions.get('kriyas') as any[]
                        )
                          .sort((a, b) =>
                            (getDisplayOfSystemFromCodableConcept(a.category) ??
                              '') >
                            (getDisplayOfSystemFromCodableConcept(b.category) ??
                              '')
                              ? 1
                              : -1
                          )
                          .map((eachCat: CategorizedKriyas) => (
                            <li
                              key={`section-${getDisplayOfSystemFromCodableConcept(
                                eachCat.category
                              )}`}
                            >
                              <ul>
                                {eachCat.kriyas
                                  .sort((a, b) =>
                                    (getDisplayOfSystemFromCodableConcept(
                                      a.code
                                    ) ?? '') >
                                    (getDisplayOfSystemFromCodableConcept(
                                      b.code
                                    ) ?? '')
                                      ? 1
                                      : -1
                                  )
                                  .map(
                                    (
                                      eachTest: R4.IServiceRequest,
                                      index: number
                                    ) => (
                                      <ListItem
                                        key={`${getDisplayOfSystemFromCodableConcept(
                                          eachTest.code
                                        )}list_item`}
                                        onChange={(e) => {
                                          onSelectedKriyasChanged(
                                            getDisplayOfSystemFromCodableConcept(
                                              eachTest.code
                                            ) ?? ''
                                          )
                                        }}
                                        dense
                                        style={{
                                          padding: '0px',
                                          paddingLeft: '2px',
                                          margin: '0px',
                                        }}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            edge='start'
                                            size='small'
                                            id={`kriyacp${index}`}
                                            checked={
                                              selectedKriyas.findIndex(
                                                (e) =>
                                                  e ===
                                                  getDisplayOfSystemFromCodableConcept(
                                                    eachTest.code
                                                  )
                                              ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              'aria-labelledby': `${getDisplayOfSystemFromCodableConcept(
                                                eachTest.code
                                              )}list_item_id`,
                                            }}
                                            color='primary'
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={`${getDefaultCodeOfSystemFromCodableConcept(
                                            eachTest.code
                                          )}list_item_id`}
                                          primary={
                                            getDisplayOfSystemFromCodableConcept(
                                              eachTest.code
                                            ) ??
                                            getDefaultCodeOfSystemFromCodableConcept(
                                              eachTest.code
                                            ) ??
                                            ''
                                          }
                                          secondary={getKriyasOrderDetailsFromEntries(
                                            eachTest
                                          )}
                                          secondaryTypographyProps={{
                                            id: `${getDefaultCodeOfSystemFromCodableConcept(
                                              eachTest.code
                                            )}list_item_secondary_id`,
                                          }}
                                          primaryTypographyProps={{
                                            variant: 'subtitle1',
                                            id: `${getDefaultCodeOfSystemFromCodableConcept(
                                              eachTest.code
                                            )}list_item_primary_id`,
                                            style: {
                                              color: isEssential(eachTest)
                                                ? textMain
                                                : '#4d4d4d',
                                            },
                                          }}
                                        />
                                      </ListItem>
                                    )
                                  )}
                              </ul>
                            </li>
                          ))}
                      </List>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                width='100%'
                flexDirection='row-reverse'
                id='referrals_list'
                display='flex'
                paddingTop={
                  (card.interceptedSuggestions.get('kriyas') as any[]).length >
                  1
                    ? 0
                    : 2.5
                }
              >
                <AddKriyaRecommendationButton
                  encounterId={encounterId}
                  patient={patient}
                  onRequestFinished={() => {
                    cdsRecommendationAddition.removeSelection(
                      carePlanIdentifier,
                      'kriyas',
                      ''
                    )
                  }}
                  carePlanIdentifier={getIdOfCardEntry(card)}
                  kriyas={(
                    card.interceptedSuggestions.get('kriyas') as any[]
                  ).filter((e) =>
                    selectedKriyas.includes(
                      getDisplayOfSystemFromCodableConcept(e.code) ?? 'na'
                    )
                  )}
                />
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  )
}
