import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  DoctorBase,
  DoctorProfileService,
  ErrorResponse,
  SearchDocResponse,
} from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getRoleCount,
  getRoleCountForDisabled,
  getRoleCountForEnabled,
  getRoleCountForUnitAdmin,
  getRoleCountForUnitAdminDisabled,
  getRoleCountForUnitAdminEnabled,
  getUserCurrentRole,
  isMedicalServiceProvider,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getOIDCUserObject } from 'utils/authHelpers'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { PractitionerSearchStatus } from './practioinerSearchCountStatus'

const initialState: PractitionerSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  userDetailsUpdating: false,
  userDetailsUpdated: false,
}

const practitionerSearchForWelloAdminSlice = createSlice({
  name: 'practitionerSearchForWelloAdminSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorCount = undefined
      state.enabledDocCount = undefined
      state.disabledDocCount = undefined
      state.consultingDoctorCount = undefined
      state.EnabledConsultingDoctorCount = undefined
      state.disabledConsultingDoctor = undefined
      state.ayurvedaDoctorCount = undefined
      state.enabledAyurvedaDocCount = undefined
      state.disabledAyurvedaDocCount = undefined
      state.unitAdminCount = undefined
      state.enabledUnitAdminCount = undefined
      state.disabledUnitAdmin = undefined
      state.grmCount = undefined
      state.enabledGrmCount = undefined
      state.disabledGrmCount = undefined
      state.therapistCount = undefined
      state.enabledTherapistCount = undefined
      state.disabledTherapistCount = undefined
      state.radiologistCount = undefined
      state.enabledRadioLogistCount = undefined
      state.disabledRadiologistCount = undefined
      state.agentCount = undefined
      state.enabledAgentCount = undefined
      state.disabledAgentCount = undefined
      state.ptDocCount = undefined
      state.dietician = undefined
      state.enabledPTdoctorCount = undefined
      state.disabledPTDocCount = undefined
      state.enabledDieticianDoctorCount = undefined
      state.disabledDieticianDocCount = undefined
    },

    searchResults(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.doctorCount = action.payload.doctorCount
      state.enabledDocCount = action.payload.enabledDocCount
      state.disabledDocCount = action.payload.disabledDocCount
      state.consultingDoctorCount = action.payload.consultingDoctorCount
      state.EnabledConsultingDoctorCount =
        action.payload.EnabledConsultingDoctorCount
      state.disabledConsultingDoctor = action.payload.disabledConsultingDoctor
      state.ayurvedaDoctorCount = action.payload.ayurvedaDoctorCount
      state.enabledAyurvedaDocCount = action.payload.enabledAyurvedaDocCount
      state.disabledAyurvedaDocCount = action.payload.disabledAyurvedaDocCount
      state.unitAdminCount = action.payload.unitAdminCount
      state.enabledUnitAdminCount = action.payload.enabledUnitAdminCount
      state.disabledUnitAdmin = action.payload.disabledUnitAdmin
      state.grmCount = action.payload.grmCount
      state.enabledGrmCount = action.payload.enabledGrmCount
      state.disabledGrmCount = action.payload.disabledGrmCount
      state.therapistCount = action.payload.therapistCount
      state.enabledTherapistCount = action.payload.enabledTherapistCount
      state.disabledTherapistCount = action.payload.disabledTherapistCount
      state.radiologistCount = action.payload.radiologistCount
      state.enabledRadioLogistCount = action.payload.enabledRadioLogistCount
      state.disabledRadiologistCount = action.payload.disabledRadiologistCount
      state.agentCount = action.payload.agentCount
      state.enabledAgentCount = action.payload.enabledAgentCount
      state.disabledAgentCount = action.payload.disabledAgentCount
      state.dietician = action.payload.dietician
      state.enabledPTdoctorCount = action.payload.enabledPTdoctorCount
      state.disabledPTDocCount = action.payload.disabledPTDocCount
      state.enabledDieticianDoctorCount =
        action.payload.enabledDieticianDoctorCount
      state.disabledDieticianDocCount = action.payload.disabledDieticianDocCount
      state.ptDocCount = action.payload.ptDocCount
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorCount = undefined
      state.enabledDocCount = undefined
      state.disabledDocCount = undefined
      state.consultingDoctorCount = undefined
      state.EnabledConsultingDoctorCount = undefined
      state.disabledConsultingDoctor = undefined
      state.ayurvedaDoctorCount = undefined
      state.enabledAyurvedaDocCount = undefined
      state.disabledAyurvedaDocCount = undefined
      state.unitAdminCount = undefined
      state.enabledUnitAdminCount = undefined
      state.disabledUnitAdmin = undefined
      state.grmCount = undefined
      state.enabledGrmCount = undefined
      state.disabledGrmCount = undefined
      state.therapistCount = undefined
      state.enabledTherapistCount = undefined
      state.disabledTherapistCount = undefined
      state.radiologistCount = undefined
      state.enabledRadioLogistCount = undefined
      state.disabledRadiologistCount = undefined
      state.agentCount = undefined
      state.enabledAgentCount = undefined
      state.disabledAgentCount = undefined
      state.dietician = undefined
      state.enabledPTdoctorCount = undefined
      state.disabledPTDocCount = undefined
      state.enabledDieticianDoctorCount = undefined
      state.disabledDieticianDocCount = undefined
      state.ptDocCount = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorCount = undefined
      state.enabledDocCount = undefined
      state.disabledDocCount = undefined
      state.consultingDoctorCount = undefined
      state.EnabledConsultingDoctorCount = undefined
      state.disabledConsultingDoctor = undefined
      state.ayurvedaDoctorCount = undefined
      state.enabledAyurvedaDocCount = undefined
      state.disabledAyurvedaDocCount = undefined
      state.unitAdminCount = undefined
      state.enabledUnitAdminCount = undefined
      state.disabledUnitAdmin = undefined
      state.grmCount = undefined
      state.enabledGrmCount = undefined
      state.disabledGrmCount = undefined
      state.therapistCount = undefined
      state.enabledTherapistCount = undefined
      state.disabledTherapistCount = undefined
      state.radiologistCount = undefined
      state.enabledRadioLogistCount = undefined
      state.disabledRadiologistCount = undefined
      state.agentCount = undefined
      state.enabledAgentCount = undefined
      state.disabledAgentCount = undefined
      state.dietician = undefined
      state.enabledPTdoctorCount = undefined
      state.disabledPTDocCount = undefined
      state.enabledDieticianDoctorCount = undefined
      state.disabledDieticianDocCount = undefined
      state.ptDocCount = undefined
    },

    resetPractDetails(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorCount = undefined
      state.enabledDocCount = undefined
      state.disabledDocCount = undefined
      state.consultingDoctorCount = undefined
      state.EnabledConsultingDoctorCount = undefined
      state.disabledConsultingDoctor = undefined
      state.ayurvedaDoctorCount = undefined
      state.enabledAyurvedaDocCount = undefined
      state.disabledAyurvedaDocCount = undefined
      state.unitAdminCount = undefined
      state.enabledUnitAdminCount = undefined
      state.disabledUnitAdmin = undefined
      state.grmCount = undefined
      state.enabledGrmCount = undefined
      state.disabledGrmCount = undefined
      state.therapistCount = undefined
      state.enabledTherapistCount = undefined
      state.disabledTherapistCount = undefined
      state.radiologistCount = undefined
      state.enabledRadioLogistCount = undefined
      state.disabledRadiologistCount = undefined
      state.agentCount = undefined
      state.enabledAgentCount = undefined
      state.disabledAgentCount = undefined
      state.dietician = undefined
      state.enabledPTdoctorCount = undefined
      state.disabledPTDocCount = undefined
      state.enabledDieticianDoctorCount = undefined
      state.disabledDieticianDocCount = undefined
      state.ptDocCount = undefined
    },
  },
})

export const searchPractitionersForUnit =
  (unitId: string, referralArray?: string[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      userDetailsUpdated: false,
      userDetailsUpdating: false,
    }
    dispatch(
      practitionerSearchForWelloAdminSlice.actions.searchingDoctorDetails(state)
    )
    try {
      const searchParameters: any = {
        _count: 50,
      }

      const fhirClient: MasterFhirClient = new MasterFhirClient()
      let response: any

      if (referralArray === undefined) {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `PractitionerRole?organization=${unitId}&_count=500`,
            searchParameters
          )
      } else if (isOrgAdmin()) {
        response = await fhirClient.doGetResource(
          `PractitionerRole?organization=${referralArray.join(',')}&_count=500`
        )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `PractitionerRole?organization=${referralArray.join(
              ','
            )}&_count=500`,
            searchParameters
          )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practRoleArray: R4.IPractitionerRole[] =
                practitionerRolesResponses.entry.map(
                  (item) => item.resource as R4.IPractitionerRole
                )

              state.doctorCount = getRoleCount(practRoleArray, 'doctor')
              state.enabledDocCount = getRoleCountForEnabled(
                practRoleArray,
                'doctor'
              )
              state.disabledDocCount = getRoleCountForDisabled(
                practRoleArray,
                'doctor'
              )
              state.consultingDoctorCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'doctor-partner'
              ).length
              state.EnabledConsultingDoctorCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'doctor-partner' &&
                  pract.active &&
                  pract.active === true
              ).length
              state.disabledConsultingDoctor = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'doctor-partner' &&
                  pract.active! === false
              ).length
              state.ayurvedaDoctorCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'ayurveda'
              ).length
              state.enabledAyurvedaDocCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'ayurveda' &&
                  pract.active &&
                  pract.active === true
              ).length
              state.disabledAyurvedaDocCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'ayurveda' &&
                  pract.active! === false
              ).length

              state.unitAdminCount = getRoleCountForUnitAdmin(practRoleArray)
              state.enabledUnitAdminCount =
                getRoleCountForUnitAdminEnabled(practRoleArray)
              state.disabledUnitAdmin =
                getRoleCountForUnitAdminDisabled(practRoleArray)
              state.grmCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'receptionist'
              ).length
              state.enabledGrmCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'receptionist' &&
                  pract.active &&
                  pract.active === true
              ).length
              state.disabledGrmCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'receptionist' &&
                  pract.active! === false
              ).length
              state.therapistCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === '106296000'
              ).length
              state.enabledTherapistCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === '106296000' &&
                  pract.active &&
                  pract.active === true
              ).length
              state.disabledTherapistCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === '106296000' &&
                  pract.active! === false
              ).length
              state.radiologistCount = getRoleCount(
                practRoleArray,
                '1255370008'
              )
              state.enabledRadioLogistCount = getRoleCountForEnabled(
                practRoleArray,
                '1255370008'
              )
              state.disabledRadiologistCount = getRoleCountForDisabled(
                practRoleArray,
                '1255370008'
              )

              state.dietician = getRoleCount(practRoleArray, 'dietician')
              state.enabledDieticianDoctorCount = getRoleCountForEnabled(
                practRoleArray,
                'dietician'
              )
              state.disabledDieticianDocCount = getRoleCountForDisabled(
                practRoleArray,
                'dietician'
              )
              state.ptDocCount = getRoleCount(practRoleArray, '36682004')
              state.enabledPTdoctorCount = getRoleCountForEnabled(
                practRoleArray,
                '36682004'
              )
              state.disabledPTDocCount = getRoleCountForDisabled(
                practRoleArray,
                '36682004'
              )
              state.agentCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'lab-agent'
              ).length
              state.enabledAgentCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'lab-agent' &&
                  pract.active &&
                  pract.active === true
              ).length
              state.disabledAgentCount = practRoleArray.filter(
                (pract: R4.IPractitionerRole) =>
                  pract.code &&
                  pract.code[0].coding &&
                  pract.code[0].coding[0].code === 'lab-agent' &&
                  pract.active! === false
              ).length
              state.searching = false
              state.error = false
              dispatch(
                practitionerSearchForWelloAdminSlice.actions.searchResults(
                  state
                )
              )
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              practitionerSearchForWelloAdminSlice.actions.noDataFoundForSearch(
                state
              )
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          practitionerSearchForWelloAdminSlice.actions.noDataFoundForSearch(
            state
          )
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        practitionerSearchForWelloAdminSlice.actions.errorWhileSearching(state)
      )
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        practitionerSearchForWelloAdminSlice.actions.errorWhileSearching(state)
      )
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export const resetStateForWelloAdminPract =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      doctorCount: undefined,
      consultingDoctorCount: undefined,
      ayurvedaDoctorCount: undefined,
      unitAdminCount: undefined,
      grmCount: undefined,
      therapistCount: undefined,
      radiologistCount: undefined,
      agentCount: undefined,
      userDetailsUpdating: false,
      userDetailsUpdated: false,
    }
    dispatch(
      practitionerSearchForWelloAdminSlice.actions.resetPractDetails(state)
    )
  }

export default practitionerSearchForWelloAdminSlice.reducer
