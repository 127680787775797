import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { SubscriptionOfferingsDetails } from './susbcriptionOfferingsDetails'

export interface SubscriptionOfferingsPopUpProps {
  open: boolean
  onClose?: () => void
  onSelectedPlanChanged?: (plan: CarePlanSubscriptionPlan) => void
  onPatientShowedInterest?: (
    plan: CarePlanSubscriptionPlan,
    duration: R4.IChargeItemDefinition
  ) => void
  onDismissed?: () => void
  offersList?: CarePlanSubscriptionPlan[]
  displaySubscribeButton?: boolean
  preSelectedPlan?: CarePlanSubscriptionPlan
  onRequestFinished?: () => void
}
export const SubscriptionOfferingsPopUp: React.FC<SubscriptionOfferingsPopUpProps> =
  ({
    open,
    onClose,
    onPatientShowedInterest,
    onSelectedPlanChanged,
    onDismissed,
    offersList,
    displaySubscribeButton = true,
    preSelectedPlan,
    onRequestFinished,
  }) => {
    const { t } = useTranslation(['common'])
    const disapatch = useDispatch()
    const [selectedSection, setselectedSection] =
      useState<string>('subscriptionPlan')

    const [selectedPlan, setselectedPlan] = useState(
      offersList !== undefined
        ? preSelectedPlan !== undefined
          ? preSelectedPlan.cpgId
          : offersList[0].cpgId
        : undefined
    )

    const [selectedDuration, setSelectedDuration] = useState(
      preSelectedPlan !== undefined
        ? preSelectedPlan.subscriptionPlans !== undefined
          ? preSelectedPlan.subscriptionPlans[0]
          : offersList !== undefined
          ? offersList[0].subscriptionPlans !== undefined
            ? offersList[0].subscriptionPlans[0]
            : undefined
          : undefined
        : offersList !== undefined
        ? offersList[0].subscriptionPlans !== undefined
          ? offersList[0].subscriptionPlans[0]
          : undefined
        : undefined
    )

    const patientSubscriptionForUnitSlice = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )

    function onCloseClicked() {
      if (onClose) {
        onClose()
      }
    }

    return (
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby='responsive-dialog-title'
        maxWidth='xl'
        fullWidth={true}
        disableBackdropClick={true}
      >
        <DialogTitle id='scroll-dialog-title'>
          <Box display='flex' flexDirection='row'>
            <Box flexGrow={4}>Care Plan Recommendations</Box>
            <Box id='box_cancel'>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onCloseClicked()
                }}
                id='cancel_ico'
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{
            overflow: 'auto',
            padding: 0,
          }}
        >
          {offersList && (
            <Box display='flex' flexDirection='column' width='100%'>
              <SubscriptionOfferingsDetails
                offersList={offersList}
                onSelectedPlanChanged={(plan) => {
                  setselectedPlan(plan.cpgId)
                  if (onSelectedPlanChanged) {
                    onSelectedPlanChanged(plan)
                    setselectedSection('subscriptionPlan')
                    setSelectedDuration(
                      plan.subscriptionPlans !== undefined
                        ? plan.subscriptionPlans[0]
                        : undefined
                    )
                  }
                }}
                preSelectedPlan={preSelectedPlan}
                onSelectedSectionChanged={(section) => {
                  setselectedSection(section)
                }}
                onSelectedDurationChanged={(duration) => {
                  console.log('---------changed duration------------', duration)
                  setSelectedDuration(duration)
                }}
                onRequestFinished={() => {
                  if (onRequestFinished) {
                    onRequestFinished()
                  }
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {patientSubscriptionForUnitSlice.requesting && (
            <Box flexDirection='row' padding={0.5}>
              <CircularProgress size={25} />
            </Box>
          )}
          {/* <Typography variant='body1' color='initial'>
            {selectedPlan !== undefined ? selectedPlan : 'Non'}
          </Typography>
          <Typography variant='body1' color='initial'>
            {selectedDuration
              ? getDurationOfChargeItemDef(selectedDuration)
              : 'No duration'}
          </Typography> */}
          <Button
            onClick={() => {
              onCloseClicked()
            }}
            id='cancel'
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          {selectedSection === 'subscriptionPlan' &&
            patientSubscriptionForUnitSlice.subscriptionStatus !==
              'interested' &&
            patientSubscriptionForUnitSlice.subscriptionStatus !== 'active' && (
              <Button
                onClick={() => {
                  if (
                    onPatientShowedInterest &&
                    offersList &&
                    offersList.length > 0 &&
                    selectedPlan &&
                    selectedDuration
                  ) {
                    console.log(
                      '----- offersList -----',
                      offersList.map((o) => ({
                        cpgId: o.cpgId,
                        subscriptionPlans: o.subscriptionPlans?.map((sp) => ({
                          id: sp.id,
                          duration: sp.description,
                          price: sp.propertyGroup,
                        })),
                      }))
                    )

                    console.log(
                      '--------------selectedPlan-----------------------',
                      selectedPlan
                    )
                    console.log(
                      '--------------selectedDuration-------------------',
                      selectedDuration.id
                    )
                    onPatientShowedInterest(
                      offersList?.find((plan) => plan.cpgId === selectedPlan)!,
                      selectedDuration
                    )
                  }
                }}
                variant='contained'
                color='primary'
                disableElevation
                id='btn_send_invitation'
                disabled={
                  patientSubscriptionForUnitSlice.requesting ||
                  selectedPlan === undefined ||
                  selectedDuration === undefined
                }
              >
                Send invitation for Subscription
              </Button>
            )}
        </DialogActions>
      </Dialog>
    )
  }
