import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { IpdCountStatus } from './ipdCountWpStatus'

const initialState: IpdCountStatus = {
  searchingDetails: false,
  detailsAvailable: false,
  error: false,
  noResultsAvailable: false,

  count: 0,
  discharged: 0,
  cancelled: 0,
}

const ipdCountWpSlice = createSlice({
  name: 'ipdCountWpSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IpdCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingDetails = action.payload.searchingDetails
      state.detailsAvailable = action.payload.detailsAvailable
      state.count = action.payload.count
      state.discharged = action.payload.discharged
      state.cancelled = action.payload.cancelled
      state.errorReason = action.payload.errorReason

      state.error = action.payload.error
    },
  },
})

export const resetIPDCountWp =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: IpdCountStatus = {
      searchingDetails: false,
      error: false,
      detailsAvailable: false,
      noResultsAvailable: false,
      count: 0,
      discharged: 0,
      cancelled: 0,
    }
    dispatch(ipdCountWpSlice.actions.updatedStatus(state))
  }
export const requestForIPDCountWp =
  (unitId: string, unitType: string, code: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IpdCountStatus = {
      searchingDetails: true,
      error: false,
      detailsAvailable: false,
      noResultsAvailable: false,
      count: 0,
      discharged: 0,
      cancelled: 0,
    }
    dispatch(ipdCountWpSlice.actions.updatedStatus(state))

    try {
      const searchParameters: any = {
        _count: 0,
        _total: 'accurate',
      }
      let response: any
      const fhirClient: MasterFhirClient = new MasterFhirClient()

      if (unitType === 'clinic') {
        if (code.length === 0) {
          response =
            await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
              `ServiceRequest?code=281685003,33022008,304903009&performer=Organization/${unitId}&status=active`,
              '',
              searchParameters
            )
        } else {
          response =
            await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
              `ServiceRequest?code=${code}&performer=Organization/${unitId}&status=active`,
              '',
              searchParameters
            )
        }
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `ServiceRequest?code=home-sample-collection,onsite-sample-collection&performer=Organization/${unitId}&status=active`,
            '',
            searchParameters
          )
      }

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.error = true
        state.searchingDetails = false
        dispatch(ipdCountWpSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.detailsAvailable = true
          state.searchingDetails = false
          state.count = appointmentResponse.total
          state.discharged = await getCountDischarged(unitId, unitType, code)
          state.cancelled =
            unitType === 'lab' ? await getCountCancelled(unitId, unitType) : 0
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.error = false
          dispatch(ipdCountWpSlice.actions.updatedStatus(state))
        } else if (
          appointmentResponse?.total &&
          appointmentResponse?.total === 0
        ) {
          state.detailsAvailable = true
          state.searchingDetails = false
          state.count = 0
          state.discharged = await getCountDischarged(unitId, unitType, code)
          state.cancelled =
            unitType === 'lab' ? await getCountCancelled(unitId, unitType) : 0
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.error = false
          dispatch(ipdCountWpSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IpdCountStatus = {
            searchingDetails: false,
            error: false,
            detailsAvailable: false,
            noResultsAvailable: true,
            count: 0,
            discharged: await getCountDischarged(unitId, unitType, code),
            cancelled: 0,
          }
          dispatch(ipdCountWpSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IpdCountStatus = {
        searchingDetails: false,
        error: true,
        detailsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
        discharged: 0,
        cancelled: 0,
      }
      dispatch(ipdCountWpSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getCountDischarged(
  id: string,
  type: string,
  code: string
): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  let response: any
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  if (type === 'clinic') {
    if (code.length === 0) {
      response =
        await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
          `ServiceRequest?code=281685003,33022008,304903009&performer=Organization/${id}&status=completed`,
          '',
          searchParameters
        )
    } else {
      response =
        await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
          `ServiceRequest?code=${code}&performer=Organization/${id}&status=completed`,
          '',
          searchParameters
        )
    }
  } else {
    response =
      await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
        `ServiceRequest?code=home-sample-collection,onsite-sample-collection&performer=Organization/${id}&status=completed`,
        '',
        searchParameters
      )
  }

  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getCountCancelled(id: string, type: string): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  let response: any
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  if (type === 'clinic') {
    response =
      await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
        `ServiceRequest?code=281685003,33022008,304903009&performer=Organization/${id}&status=revoked`,
        '',
        searchParameters
      )
  } else {
    response =
      await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
        `ServiceRequest?code=home-sample-collection,onsite-sample-collection&performer=Organization/${id}&status=revoked&intent=order`,
        '',
        searchParameters
      )
  }

  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

export default ipdCountWpSlice.reducer
