import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { requestCPGRecommendationForSubscribedPatientData } from 'redux/subscription/cpgRecommendations/cpgRecommendationsSlice'
import { requestForSubscriptionInterestCapture } from 'redux/subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'
import {
  getOfferingSubscriptionsOfCurrentUnit,
  getOffersHavingRecommendations,
  getPlanIdFromTasks,
  getSelectedCPSDetails,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import VisitAppointmentContextProvider from 'views/providers/visitContextProvider'

import { CdsRecommendationsForAppointmentForSub } from '../cds_recommendations_list_for_sub'
import {
  SubscriptionOfferingsPopUp,
  SubscriptionOfferingsPopUpProps,
} from '../subscriptions/subscriptionOfferingPopUp'
import { PaymentStatusChecker } from './payment_status_helper_button'

interface RecommendationWrapperProps {
  patient: R4.IPatient
  encounter: R4.IEncounter
  onRecommendationAdditionUpdate?: (
    category:
      | 'condition'
      | 'goal'
      | 'lab'
      | 'medication'
      | 'specialistReferral'
      | 'instruction'
      | 'careplan-activities'
      | 'careplan-follow-up'
      | 'kriyas'
  ) => void
  onRequestFinished?: () => void
}

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: `#333333`,
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '25px',

    textAlign: 'left',
    textTtransform: 'capitalize',
  },
  wrapper: {
    background: '#FDFFCD',
    border: '0.5px solid #A7B50F',
    borderRadius: '4px',
  },
  textSubtitle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '13.62px',
  },
}))

export const RecommendationWrapperForSub: React.FC<RecommendationWrapperProps> =
  ({
    patient,
    encounter,
    onRecommendationAdditionUpdate,
    onRequestFinished,
  }: RecommendationWrapperProps) => {
    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })
    const [selectedPlan, setSelectedPlan] =
      useState<CarePlanSubscriptionPlan | undefined>()

    const patientSubscriptionForUnitSlice = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )

    const status = useSelector(
      (state: RootState) =>
        state.patientSubscriptionForUnitSlice.subscriptionStatus
    )

    const cpgRecommendationsState = useSelector(
      (state: RootState) => state.cpgRecommendationsSlice
    )

    const [offeringSubscriptionsList, setOfferingSubscriptionsList] =
      useState<CarePlanSubscriptionPlan[]>()

    const [offeringPopUp, setOfferingPopUp] =
      useState<SubscriptionOfferingsPopUpProps>({
        open: false,
      })

    function fetchCPGLists() {
      setUpdateStatus({ requesting: true })
      getOfferingSubscriptionsOfCurrentUnit().then((e) => {
        if (e !== false) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setOfferingSubscriptionsList(e as CarePlanSubscriptionPlan[])
          /// requestCpgRecommendations(e.map((plan) => plan.rawCpg))
        } else {
          setUpdateStatus({
            requesting: false,
            requestError: false,
          })
        }
      })
    }

    useEffect(() => {
      console.log(
        'patientSubscriptionForUnitSlice',
        patientSubscriptionForUnitSlice
      )
      if (
        (patientSubscriptionForUnitSlice.subscriptionStatus === 'interested' ||
          patientSubscriptionForUnitSlice.subscriptionStatus === 'active') &&
        patient &&
        patientSubscriptionForUnitSlice.tasks
      ) {
        console.log(
          '-------------patientSubscriptionForUnitSlice--------------'
        )
        if (
          offeringSubscriptionsList &&
          offeringSubscriptionsList?.length > 0
        ) {
          const newOfferList = [...(offeringSubscriptionsList ?? [])]
          const updatedOffer = newOfferList.filter((e) =>
            e.rawCpg.library?.includes(
              patientSubscriptionForUnitSlice.tasks![0].planDefinition!
                .library![0]
            )
          )

          console.log('-------------newOfferList--------------', updatedOffer)
          requestCpgRecommendations(updatedOffer.map((plan) => plan.rawCpg))
        }
      } else if (
        ['offer-expired', 'not-interested', 'not-offered'].includes(
          patientSubscriptionForUnitSlice.subscriptionStatus
        ) &&
        offeringSubscriptionsList &&
        offeringSubscriptionsList?.length > 0
      ) {
        requestCpgRecommendations(
          offeringSubscriptionsList.map((plan) => plan.rawCpg)
        )
      }
    }, [patientSubscriptionForUnitSlice, offeringSubscriptionsList])

    function requestCpgRecommendations(plans: R4.IPlanDefinition[]) {
      dispatch(
        requestCPGRecommendationForSubscribedPatientData({
          selectedPatient: patient,
          planDefinition: plans,
        })
      )
    }

    const dispatch = useDispatch()

    const classes = useStyles()

    useEffect(() => {
      fetchCPGLists()
    }, [])

    return (
      <VisitAppointmentContextProvider
        patient={patient}
        encounter={encounter}
        onRecommendationAdditionUpdate={(category) => {
          console.log('-------------- update category---------------', category)
          if (onRecommendationAdditionUpdate) {
            onRecommendationAdditionUpdate(category)
          }
        }}
      >
        {offeringSubscriptionsList && offeringSubscriptionsList?.length > 0 && (
          <Box
            display='flex'
            flexDirection='column'
            className={classes.wrapper}
            key={encounter.id}
          >
            {updateStatus.requesting && (
              <Box
                flexDirection='row'
                padding={0.5}
                flexGrow
                width='100%'
                justifyContent='center'
                alignItems='center'
                alignContent='center'
              >
                <Typography className={classes.titleText}>
                  Fetching available plans
                </Typography>
              </Box>
            )}

            {offeringSubscriptionsList && (
              <Box
                display='flex'
                flexDirection='column'
                paddingY={1}
                paddingX={2}
                width='100%'
              >
                <Box display='flex' flexDirection='row'>
                  {patientSubscriptionForUnitSlice.subscriptionStatus !==
                    'active' && (
                    <Typography className={classes.titleText}>
                      Care Plan Recommendations
                    </Typography>
                  )}
                  {patientSubscriptionForUnitSlice.subscriptionStatus ===
                    'active' && (
                    <Typography className={classes.titleText}>
                      Care Plan Subscription
                    </Typography>
                  )}
                </Box>
                <CdsRecommendationsForAppointmentForSub
                  encounter={encounter}
                  patient={patient}
                  onViewDetailsClicked={(card) => {
                    const planForCPG = getSelectedCPSDetails(
                      offeringSubscriptionsList,
                      card
                    )
                    setSelectedPlan(planForCPG)

                    setOfferingPopUp({
                      open: true,
                      onClose: () => {
                        setOfferingPopUp({ open: false })
                      },
                      onSelectedPlanChanged: (plan) => {
                        setSelectedPlan(plan)
                      },
                      preSelectedPlan: planForCPG,

                      onPatientShowedInterest: (plan, duration) => {
                        dispatch(
                          requestForSubscriptionInterestCapture(
                            patient.id!,
                            encounter!.id!,
                            `${plan.rawCpg.resourceType}/${plan.rawCpg.id}`,
                            `${duration.resourceType}/${duration.id}`,
                            patientSubscriptionForUnitSlice
                          )
                        )
                      },
                      onDismissed: () => {},
                      offersList:
                        patientSubscriptionForUnitSlice.tasks !== undefined
                          ? offeringSubscriptionsList.filter((e) =>
                              getPlanIdFromTasks(
                                patientSubscriptionForUnitSlice.tasks ?? []
                              ).includes(e.rawCpg.library![0])
                            )
                          : getOffersHavingRecommendations(
                              offeringSubscriptionsList,
                              cpgRecommendationsState.recommendationsList ?? []
                            ),
                      displaySubscribeButton:
                        patientSubscriptionForUnitSlice.subscriptionStatus !==
                        'interested',
                    })
                  }}
                />
                {patientSubscriptionForUnitSlice.tasks &&
                  patientSubscriptionForUnitSlice.tasks.length > 0 && (
                    <PaymentStatusChecker
                      interestTask={
                        patientSubscriptionForUnitSlice.tasks[0].task
                      }
                      patient={patient}
                      subscriptionName={
                        patientSubscriptionForUnitSlice.tasks[0]
                          .subscriptionName ?? ''
                      }
                      patientName={getNameFromHumanName(patient.name ?? [])}
                    />
                  )}
              </Box>
            )}

            {offeringPopUp.open && (
              <SubscriptionOfferingsPopUp
                {...offeringPopUp}
                onRequestFinished={() => {
                  if (onRequestFinished) {
                    onRequestFinished()
                  }
                }}
              />
            )}
          </Box>
        )}
      </VisitAppointmentContextProvider>
    )
  }
