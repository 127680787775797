import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { AppDispatch, AppThunk } from 'redux/store'
import moment from 'moment'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import {
  getTreatmentListForAppointment,
  getTreatmentPlanFromBundle,
  inBetWeenTime,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import {
  getDateWiseTherapy,
  getDateWiseTherapyAfterNoon,
  getDateWiseTherapyEvening,
  getDateWiseTherapyMidMorning,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { TreatmentAssemble } from 'models/treatmentAssemble'
import { TreatmentSearchStatus } from './treatmentSearchStatus'

const initialState: TreatmentSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const treatmentSearchSliceForAppointment = createSlice({
  name: 'treatmentSearchSliceForAppointment',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<TreatmentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.treatmentList = action.payload.treatmentList
      state.errorReason = action.payload.errorReason
      state.mornigTherapy = action.payload.mornigTherapy
      state.midMorningTherapy = action.payload.midMorningTherapy
      state.afternoonTherapy = action.payload.afternoonTherapy
      state.eveningTherapy = action.payload.eveningTherapy
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const requestForTreatmentAppointment =
  (encounterId: string, appointmentId: string, details?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: TreatmentSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(treatmentSearchSliceForAppointment.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const searchParameters: any = {
        encounter: `Encounter/${encounterId}`,
        category: '225365006',
        intent: 'order',
        status: 'active,completed,cancelled',
        _count: 100,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `ServiceRequest?_revinclude=Procedure:based-on`,
        searchParameters
      )

      const proceduresResponse: R4.IBundle = response as R4.IBundle
      if (proceduresResponse.entry && proceduresResponse.entry.length > 0) {
        const vitalLisData = getTreatmentListForAppointment(
          proceduresResponse,
          details ?? ''
        )

        vitalLisData.sort((a, b) =>
          a.status.toLowerCase().localeCompare(b.status.toLowerCase())
        )

        const assembleMorning: TreatmentAssemble[] =
          getDateWiseTherapy(vitalLisData)

        const assembleMidMorning: TreatmentAssemble[] =
          getDateWiseTherapyMidMorning(vitalLisData)

        const assembleAfterNoon: TreatmentAssemble[] =
          getDateWiseTherapyAfterNoon(vitalLisData)

        const assembleEveing: TreatmentAssemble[] =
          getDateWiseTherapyEvening(vitalLisData)

        const morning: string[] = []
        const midMorning: string[] = []
        const afterNoon: string[] = []
        const evening: string[] = []
        for (let i = 0; i < vitalLisData.length; i++) {
          if (
            inBetWeenTime(
              moment(vitalLisData[i].endData).format('HH:mm'),
              '07:29',
              '08:01'
            )
          ) {
            morning.push(vitalLisData[i].endData)
          }

          if (
            inBetWeenTime(
              moment(vitalLisData[i].endData).format('HH:mm'),
              '8:59',
              '12:31'
            )
          ) {
            midMorning.push(vitalLisData[i].endData)
          }
          if (
            inBetWeenTime(
              moment(vitalLisData[i].endData).format('HH:mm'),
              '14:59',
              '17:31'
            )
          ) {
            afterNoon.push(vitalLisData[i].endData)
          }

          if (
            inBetWeenTime(
              moment(vitalLisData[i].endData).format('HH:mm'),
              '18:59',
              '19:31'
            )
          ) {
            evening.push(vitalLisData[i].endData)
          }
        }

        morning.sort()
        afterNoon.sort()
        midMorning.sort()
        evening.sort()

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.treatmentList = vitalLisData
          .sort((a, b) =>
            a.status.toLowerCase().localeCompare(b.status.toLowerCase())
          )
          .sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        state.mornigTherapy = assembleMorning
        state.midMorningTherapy = assembleMidMorning
        state.afternoonTherapy = assembleAfterNoon
        state.eveningTherapy = assembleEveing
        dispatch(
          treatmentSearchSliceForAppointment.actions.updatedStatus(state)
        )
      } else {
        const noResultsAvailable: TreatmentSearchStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          treatmentSearchSliceForAppointment.actions.updatedStatus(
            noResultsAvailable
          )
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: TreatmentSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        treatmentSearchSliceForAppointment.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

export default treatmentSearchSliceForAppointment.reducer
