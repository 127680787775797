import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { WellnessAppointmentCountStatus } from './WellnessAppointmentCountStatusPerUnit'

const initialState: WellnessAppointmentCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
  ipdCount: 0,
  dayCareCount: 0,
  consultationCount: 0,
}

const appointmentWellnessCountPerUnitSlice = createSlice({
  name: 'appointmentWellnessCountPerUnitSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<WellnessAppointmentCountStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.ipdCount = action.payload.ipdCount
      state.dayCareCount = action.payload.dayCareCount
      state.consultationCount = action.payload.consultationCount
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetWellnessAppointmentForUnit =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: WellnessAppointmentCountStatus = {
      searchingAppointments: false,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      ipdCount: 0,
      count: 0,
      dayCareCount: 0,
      consultationCount: 0,
    }
    dispatch(appointmentWellnessCountPerUnitSlice.actions.updatedStatus(state))
  }
export const requestForWellnessAppointmentCount =
  (unitId: string, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: WellnessAppointmentCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
      ipdCount: 0,
      dayCareCount: 0,
      consultationCount: 0,
    }
    dispatch(appointmentWellnessCountPerUnitSlice.actions.updatedStatus(state))

    try {
      const searchParameters: any = {}
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const response: any =
        await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
          `PractitionerRole?organization=${unitId}&role=doctor,ayurveda&active=true`,
          '',
          searchParameters
        )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false
        dispatch(
          appointmentWellnessCountPerUnitSlice.actions.updatedStatus(state)
        )
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          if (appointmentResponse.entry) {
            const practRoleArray: R4.IPractitionerRole[] =
              appointmentResponse.entry.map(
                (item) => item.resource as R4.IPractitionerRole
              )
            const roleIds: string[] = []
            practRoleArray.forEach((role) => {
              if (role.practitioner)
                roleIds.push(role.practitioner.reference ?? '')
            })
            const data = await getAppointmentCount(roleIds)
            let ipdCountdata: number = 0
            let ipdDayCount: number = 0
            let IpdConsultCount: number = 0
            if (type && type.length > 0) {
              ipdCountdata = await getIpdAppointmentCount(unitId)

              ipdDayCount = await getIpdDayCareCount(unitId)
              IpdConsultCount = await getIpdConsultCount(unitId)
            }

            state.resultsAvailable = true
            state.searchingAppointments = false
            state.count = data
            state.ipdCount = ipdCountdata
            state.dayCareCount = ipdDayCount
            state.consultationCount = IpdConsultCount
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingAppointments = false
            dispatch(
              appointmentWellnessCountPerUnitSlice.actions.updatedStatus(state)
            )
          }
        } else if (type) {
          const ipdDayCount = await getIpdDayCareCount(unitId)
          const ipdConsultCount = await getIpdConsultCount(unitId)
          const ipdCountdata = await getIpdAppointmentCount(unitId)
          state.dayCareCount = ipdDayCount
          state.consultationCount = ipdConsultCount
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = 0
          state.ipdCount = ipdCountdata
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(
            appointmentWellnessCountPerUnitSlice.actions.updatedStatus(state)
          )
        } else {
          const errorSearchDoctor: WellnessAppointmentCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
            ipdCount: 0,
            dayCareCount: 0,
            consultationCount: 0,
          }
          dispatch(
            appointmentWellnessCountPerUnitSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: WellnessAppointmentCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
        ipdCount: 0,
        dayCareCount: 0,
        consultationCount: 0,
      }
      dispatch(
        appointmentWellnessCountPerUnitSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

async function getAppointmentCount(rolesArray: string[]): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `Appointment?practitioner=${rolesArray.join(',')}`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getIpdAppointmentCount(units: string): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `ServiceRequest?code=281685003&performer=${units}&status=active,completed`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getIpdDayCareCount(units: string): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `ServiceRequest?code=304903009&performer=${units}&status=active,completed`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getIpdConsultCount(units: string): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `ServiceRequest?code=33022008&performer=${units}&status=active,completed`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

export default appointmentWellnessCountPerUnitSlice.reducer
