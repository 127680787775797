import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { ConvenienceFee, Settlement } from 'models/convinienceFeeDetails'
import { LabReferralDetails } from 'models/labReferralDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { paymentTypes } from 'utils/constants'
import { UnitTypesForSetting } from 'utils/constants/unit_types'

import {
  getAmountForConvenienceFee,
  getAmountForReferralFee,
  getAmountForReferralFeeInPercent,
  getServiceTypeData,
  getSettlement,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { logger } from 'utils/logger'
import { SettlementSearchStatus } from './settlementSearchStatus'

const initialState: SettlementSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const settlementSearchSlice = createSlice({
  name: 'settlementSearchSlice',
  initialState,
  reducers: {
    searchingCFee(state, action: PayloadAction<SettlementSearchStatus>) {},

    searchResults(state, action: PayloadAction<SettlementSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    noDataFoundForSearch(state, action: PayloadAction<SettlementSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    errorWhileSearching(state, action: PayloadAction<SettlementSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },
    resetState(state, action: PayloadAction<SettlementSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false

      state.referralList = undefined
    },
  },
})

export const resetSettlementSearch =
  (): AppThunk => async (dispatch: AppDispatch) => {
    // const state: SettlementSearchStatus = {
    //   errorReason: undefined,
    //   searchingAppointments: false,
    //   noResultsAvailable: false,
    //   question: undefined,
    //   prSelectedCoding: undefined,
    //   resultsAvailable: false,
    //   errorWhileSearchingAppointments: false,
    // }
    dispatch(settlementSearchSlice.actions.resetState(initialState))
  }

export const SearchingSettlement = (): AppThunk => async (dispatch: any) => {
  const errorFetchingUnits: SettlementSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(
    settlementSearchSlice.actions.errorWhileSearching(errorFetchingUnits)
  )
  try {
    let searchParameter = {}
    searchParameter = {
      _count: 500,
    }
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response = await fhirClient.doGetResource(
      `/Basic?code=adjustment`,
      searchParameter
    )

    const orgResponse: R4.IBundle = response
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const rFeeArray: R4.IBasic[] = orgResponse.entry.map(
            (item) => item.resource as R4.IBasic
          )

          if (rFeeArray.length > 0) {
            const data = {
              id: rFeeArray[0].id,
              settleMentType: getSettlement(rFeeArray[0]),
              settlementRes: rFeeArray[0],
            }
            const fetchUnitListResult: SettlementSearchStatus = {
              error: false,
              noResultsAvailable: false,
              resultsAvailable: true,
              searching: false,

              referralList: data,
            }
            dispatch(
              settlementSearchSlice.actions.searchResults(fetchUnitListResult)
            )
            return
          }
          const noSearchResults: SettlementSearchStatus = {
            error: false,
            noResultsAvailable: true,
            resultsAvailable: false,
            searching: false,
          }
          dispatch(
            settlementSearchSlice.actions.noDataFoundForSearch(noSearchResults)
          )
        }
      }
    }
    const noSearchResults: SettlementSearchStatus = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(
      settlementSearchSlice.actions.noDataFoundForSearch(noSearchResults)
    )
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: SettlementSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      settlementSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
  }
}

export default settlementSearchSlice.reducer
