import { R4 } from '@ahryman40k/ts-fhir-types'
import { Conditions } from 'models/conditions'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { EvidenceBaseData } from 'models/evidenceBaseData'
import { OutComes } from 'models/evidenceGraph/interventions'
import { LabTestReport, Panel1 } from 'models/labTestReport'
import { MeasureLabel } from 'models/measureOptionsLabel'
import moment from 'moment'
import { getSymbolForCurrency } from './fhirResoureHelpers/currency_helper'

export const MeasureMent: MeasureLabel[] = [
  {
    id: 'Measurement Value Pre-index Monthly - Systolic BP',
    title: 'Measurement Value Pre-index Monthly - Systolic BP',
  },
  {
    id: 'Measurement Value Post-index Monthly - Systolic BP',
    title: 'Measurement Value Post-index Monthly - Systolic BP',
  },
  {
    id: 'Measurement Value Pre-index Monthly - Diastolic BP',
    title: 'Measurement Value Pre-index Monthly - Diastolic BP',
  },
  {
    id: 'Measurement Value Post-index Monthly - Diastolic BP',
    title: 'Measurement Value Post-index Monthly - Diastolic BP',
  },
]

export const timeList = [
  { id: 'all', title: 'All' },
  { id: 'morning', title: 'Morning' },
  { id: 'afternoon', title: 'Afternoon' },
  {
    id: 'evening',
    title: 'Evening',
  },
]

export const majorConditions = [
  {
    code: 'No_Major_Condition',
    display: 'No Major Condition',
  },
  {
    code: 'Pneumonia',
    display: 'Pneumonia',
  },
  {
    code: 'Typhoid',
    display: 'Typhoid',
  },
  {
    code: 'Dengue',
    display: 'Dengue',
  },
  {
    code: 'Lower_Respiratory_Infections',
    display: 'Lower Respiratory Infections',
  },
  {
    code: 'Acute_Glomerulonephritis',
    display: 'Acute Glomerulonephritis',
  },
  {
    code: 'Acute_Hepatitis_A',
    display: 'Acute Hepatitis A',
  },
  {
    code: 'Acute_Hepatitis_E',
    display: 'Acute Hepatitis E',
  },
  {
    code: 'Acute_Otitis_Media',
    display: 'Acute Otitis Media',
  },
  {
    code: 'Appendicitis',
    display: 'Appendicitis',
  },
  {
    code: 'Diphtheria',
    display: 'Diphtheria',
  },
  {
    code: 'Echinococcosis',
    display: 'Echinococcosis',
  },
  {
    code: 'Encephalitis',
    display: 'Encephalitis',
  },
  {
    code: 'Gallbladder_&_Biliary Diseases',
    display: 'Gallbladder & Biliary Diseases',
  },
  {
    code: 'Gastritis_&_Duodenitis',
    display: 'Gastritis & Duodenitis',
  },
  {
    code: 'Inflammatory_Bowel_Disease',
    display: 'Inflammatory Bowel Disease',
  },
  {
    code: 'Lip_&_Oral Cavity',
    display: 'Lip & Oral Cavity',
  },
  {
    code: 'Measles',
    display: 'Measles',
  },
  {
    code: 'Meningitis',
    display: 'Meningitis',
  },
  {
    code: 'Oral_Conditions',
    display: 'Oral Conditions',
  },
  {
    code: 'Peptic_Ulcer_Disease',
    display: 'Peptic Ulcer Disease',
  },
  {
    code: 'Rabies',
    display: 'Rabies',
  },
  {
    code: '76902006',
    system: 'http://snomed.info/sct',
    display: 'Tetanus',
  },
  {
    code: 'Upper_Respiratory_Infections',
    display: 'Upper Respiratory Infections',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const bodyLocations = [
  { code: '7832008�', display: 'Abdominal aorta' },
  { code: '4158003�', display: 'Abdominal aortic plexus structure' },
  { code: '8568009�', display: 'Abdominal lymph nodes' },
  {
    code: '7986004�',
    display: 'Abdominopelvic portion of sympathetic nervous system',
  },
  { code: '8225009�', display: 'Accessory atrioventricular bundle' },
  { code: '9181003�', display: 'Accessory carpal bone' },
  { code: '6268000�', display: 'Accessory phrenic nerves' },
  { code: '2095001�', display: 'Accessory sinus' },
  { code: '8598002�', display: 'Acoustic nerve' },
  { code: '8603005�', display: 'Adductor hallucis muscle' },
  { code: '9481009�', display: 'Afferent glomerular arteriole' },
  {
    code: '8839002�',
    display: 'Agranular endoplasmic reticulum, connection with other organelle',
  },
  { code: '2592007�', display: 'All extremities' },
  { code: '3222005�', display: 'All large arteries' },
  { code: '8671006�', display: 'All toes' },
  { code: '5560003�', display: 'Alveolar ridge mucous membrane' },
  { code: '9475001�', display: 'Amacrine cells of retina' },
  { code: '4843009�', display: 'Ampulla of semicircular duct' },
  { code: '4958002�', display: 'Amygdaloid structure' },
  { code: '420002�', display: 'Anastomosis, heterocladic' },
  { code: '2969000�', display: 'Anatomical space structure' },
  { code: '5814007�', display: 'Angle of seventh rib' },
  { code: '6550004�', display: 'Angular incisure of stomach' },
  { code: '344001�', display: 'Ankle' },
  { code: '3488009�', display: 'Annulate lamella, cisternal lumen' },
  { code: '1502004�', display: 'Anterior cecal artery' },
  { code: '5727003�', display: 'Anterior cervical lymph node' },
  { code: '6541001�', display: 'Anterior commissure of mitral valve' },
  { code: '8012006�', display: 'Anterior communicating artery' },
  { code: '895007�', display: 'Anterior cruciate ligament of knee joint' },
  { code: '202009�', display: 'Anterior division of renal artery' },
  { code: '6646002�', display: 'Anterior fossa of cranial cavity' },
  { code: '6944002�', display: 'Anterior labial veins' },
  { code: '7507003�', display: 'Anterior ligament of head of fibula' },
  { code: '4312008�', display: 'Anterior midline of abdomen' },
  { code: '6975006�', display: 'Anterior myocardium' },
  { code: '7404008�', display: 'Anterior semicircular duct' },
  { code: '7311008�', display: 'Anterior surface of arm' },
  { code: '7829005�', display: 'Anterior surface of manubrium' },
  { code: '4812002�', display: 'Anterior tibial compartment structure' },
  {
    code: '5611001�',
    display:
      'Anulus fibrosus of intervertebral disc of fifth cervical vertebra',
  },
  {
    code: '7148007�',
    display: 'Anulus fibrosus of intervertebral disc of thoracic vertebra',
  },
  { code: '1136004�', display: 'Aortopulmonary septum' },
  { code: '8242003�', display: 'Apical branch of right pulmonary artery' },
  { code: '2789006�', display: 'Apocrine gland structure' },
  { code: '5427004�', display: 'Apocrine intraepidermal duct' },
  { code: '7242000�', display: 'Appendiceal muscularis propria' },
  { code: '9878006�', display: 'Appendicular vein' },
  { code: '9089003�', display: 'Arch of foot' },
  { code: '6711001�', display: 'Arch of second lumbar vertebra' },
  { code: '1840006�', display: 'Arrector pili muscle' },
  { code: '6684008�', display: 'Articular facet of head of fibula' },
  { code: '5696005�', display: 'Articular part of tubercle of ninth rib' },
  { code: '5403001�', display: 'Articular process of third lumbar vertebra' },
  {
    code: '5923009�',
    display: 'Articular process of twelfth thoracic vertebra',
  },
  {
    code: '8265008�',
    display: 'Articular surface, metacarpal, of phalanx of hand',
  },
  {
    code: '221001�',
    display: 'Articular surface, phalanges, of fourth metacarpal bone',
  },
  {
    code: '5889006�',
    display: 'Articular surface, third metacarpal, of fourth metacarpal bone',
  },
  { code: '7999001�', display: 'Articulations of auditory ossicles' },
  { code: '6325003�', display: 'Aryepiglottic muscle' },
  { code: '9040008�', display: 'Ascending colon' },
  { code: '465006�', display: 'Ascending frontal gyrus' },
  {
    code: '7099003�',
    display: 'Attachment plaque of desmosome or hemidesmosome',
  },
  { code: '6834003�', display: 'Axial skeleton' },
  {
    code: '4677002�',
    display: 'Basal lamina, inclusion in subepithelial location',
  },
  { code: '10024003�', display: 'Base of lung' },
  { code: '7160008�', display: 'Base of metacarpal bone' },
  { code: '10025002�', display: 'Base of phalanx of index finger' },
  { code: '6572002�', display: 'Base of phalanx of middle finger' },
  { code: '464005�', display: 'Basophilic normoblast' },
  { code: '8289001�', display: 'Below knee region' },
  { code: '7035006�', display: 'Bile duct mucous membrane' },
  { code: '9840009�', display: 'Biparietal diameter of head' },
  { code: '8389004�', display: 'Birth canal' },
  { code: '2682005�', display: 'Bladder mucosa' },
  { code: '6105006�', display: 'Body of fifth thoracic vertebra' },
  { code: '692007�', display: 'Body of ischium' },
  { code: '8677005�', display: 'Body of right atrium' },
  { code: '5329002�', display: 'Bone structure of C4' },
  { code: '4247003�', display: 'Bone structure of first metacarpal' },
  { code: '2979003�', display: 'Bone structure of medial cuneiform' },
  { code: '2550002�', display: 'Bone structure of phalanx of thumb' },
  { code: '3138006�', display: 'Bone tissue' },
  { code: '8580001�', display: 'Both feet' },
  { code: '9055004�', display: 'Both forearms' },
  { code: '4180000�', display: 'Both lower extremities' },
  { code: '8887007�', display: 'Brachiocephalic vein' },
  { code: '5926001�', display: 'Bronchial lumen' },
  { code: '955009�', display: 'Bronchus' },
  { code: '9512009�', display: 'Calcaneocuboidal ligament' },
  {
    code: '6742006�',
    display: 'Callosomarginal branch of anterior cerebral artery',
  },
  { code: '227002�', display: 'Canal of Hering' },
  { code: '5643001�', display: 'Canal of stomach' },
  {
    code: '5948007�',
    display: 'Capsule of distal interphalangeal joint of third toe',
  },
  {
    code: '9185007�',
    display: 'Capsule of metatarsophalangeal joint of fifth toe',
  },
  {
    code: '587004�',
    display: 'Capsule of proximal interphalangeal joint of third toe',
  },
  { code: '6074008�', display: 'Capsule of temporomandibular joint' },
  { code: '318001�', display: 'Cardiac impression of liver' },
  { code: '5459006�', display: 'Cardiac incisure of stomach' },
  { code: '6530003�', display: 'Cardiac valve leaflet' },
  { code: '4703008�', display: 'Cardinal vein structure' },
  { code: '480000�', display: 'Cardiopulmonary circulatory system' },
  { code: '4303006�', display: 'Cartilage canal' },
  { code: '9870004�', display: 'Carunculae hymenales' },
  { code: '7173007�', display: 'Cauda equina' },
  { code: '8752000�', display: 'Cavernous portion of internal carotid artery' },
  { code: '6375001�', display: 'Cavernous portion of urethra' },
  { code: '4150005�', display: 'Celiac nervous plexus structure' },
  { code: '4897009�', display: 'Cell membrane, prokaryotic' },
  { code: '3400000�', display: 'Cell of bone' },
  { code: '4421005�', display: 'Cellular structures' },
  { code: '283001�', display: 'Central axillary lymph node' },
  { code: '6651008�', display: 'Central lobule of cerebellum' },
  { code: '1231004�', display: 'Central nervous system meninges' },
  {
    code: '149003�',
    display: 'Central pair of microtubules, cilium or flagellum, not bacterial',
  },
  { code: '1729007�', display: 'Central tegmental tract' },
  { code: '9208002�', display: 'Centriole' },
  { code: '4924005�', display: 'Cerebellar gracile lobule' },
  { code: '6566002�', display: 'Cerebellar hemisphere' },
  { code: '7494000�', display: 'Cerebellar lenticular nucleus' },
  { code: '9000002�', display: 'Cerebellar peduncle' },
  { code: '2209009�', display: 'Cerebral basal surface' },
  { code: '8935007�', display: 'Cerebral meninges' },
  { code: '6739000�', display: 'Chorda tympani' },
  { code: '2049008�', display: 'Chorionic villi' },
  {
    code: '8640002�',
    display: 'Choroidal branches of posterior spinal artery',
  },
  { code: '4015004�', display: 'Chromosomes, group A' },
  { code: '5996007�', display: 'Chromosomes, group D' },
  { code: '1174007�', display: 'Chromosomes, group E' },
  { code: '9668006�', display: 'Ciliary muscle' },
  { code: '7345001�', display: 'Ciliary processes' },
  { code: '1997009�', display: 'Cingulum dentis' },
  {
    code: '6006009�',
    display: 'Circular layer of duodenal muscularis propria',
  },
  { code: '2746007�', display: 'Circular layer of gastric muscularis' },
  { code: '2010005�', display: 'Clavicular facet of scapula' },
  { code: '2192001�', display: 'Clavicular part of pectoralis major muscle' },
  { code: '3100007�', display: 'Clinical crown of tooth' },
  { code: '6392005�', display: 'Coccygeal nerve' },
  { code: '1541002�', display: 'Coccygeal plexus' },
  { code: '8356004�', display: 'Colic lymph node' },
  { code: '457008�', display: 'Collateral ligament' },
  { code: '6987004�', display: 'Collateral sulcus' },
  { code: '6533001�', display: 'Colonic haustra' },
  { code: '6032003�', display: 'Columnar epithelial cell' },
  { code: '108003�', display: 'Condylar emissary vein' },
  { code: '7605000�', display: 'Connexon' },
  { code: '4866005�', display: 'Constrictor muscle of pharynx structure' },
  { code: '8931003�', display: 'Coracoid process of scapula' },
  { code: '7884002�', display: 'Corneal corpuscle' },
  { code: '9502002�', display: 'Coronal depression of tooth' },
  { code: '124002�', display: 'Coronoid process of mandible' },
  { code: '9284003�', display: 'Corpus cavernosum of penis' },
  { code: '8810002�', display: 'Corticomedullary junction of adrenal gland' },
  { code: '6841009�', display: 'Corticomedullary junction of kidney' },
  { code: '528006�', display: 'Costal surface of lung' },
  { code: '1101003�', display: 'Cranial cavity' },
  { code: '8600008�', display: 'Cricoid cartilage' },
  { code: '8854008�', display: 'Crista galli' },
  { code: '5542003�', display: 'Crista marginalis of tooth' },
  { code: '947002�', display: 'Crus of diaphragm' },
  { code: '1716007�', display: 'Cupula ampullaris' },
  { code: '6620001�', display: 'Cuticle of nail' },
  { code: '6450001�', display: 'Cytotrophoblast' },
  { code: '6062009�', display: 'Deciduous mandibular right canine tooth' },
  { code: '5458003�', display: 'Deep artery of clitoris' },
  { code: '9596006�', display: 'Deep branch of ulnar nerve' },
  { code: '155008�', display: 'Deep circumflex artery of ilium' },
  { code: '10124000�', display: 'Deep dorsal sacrococcygeal ligament' },
  { code: '1350001�', display: 'Deep flexor tendon of index finger' },
  { code: '7554004�', display: 'Deep head of flexor pollicis brevis muscle' },
  { code: '7296001�', display: 'Deep inguinal ring' },
  { code: '3438001�', display: 'Deep lymphatic of upper extremity' },
  { code: '798000�', display: 'Deep temporal veins' },
  { code: '3984002�', display: 'Deep vein of clitoris' },
  { code: '9791004�', display: 'Deep venous system of lower extremity' },
  { code: '10119003�', display: 'Deep volar arch of radial artery' },
  { code: '4799000�', display: 'Deiter cell' },
  { code: '8119004�', display: 'Delphian lymph node' },
  { code: '723004�', display: 'Dense intermediate filament bundles' },
  { code: '4442007�', display: 'Dental arch structure' },
  { code: '402006�', display: 'Dental branches of inferior alveolar artery' },
  { code: '4573009�', display: 'Desmosome' },
  { code: '5798000�', display: 'Diaphragm' },
  { code: '8892009�', display: 'Diaphragmatic surface of lung' },
  {
    code: '6511003�',
    display: 'Distal portion of circumflex branch of left coronary artery',
  },
  { code: '1263005�', display: 'Distinctive arrangement of microtubules' },
  { code: '9779008�', display: 'Distinctive shape of mitochondrial cristae' },
  { code: '1246006�', display: 'Dorsal digital nerves of radial nerve' },
  { code: '9999005�', display: 'Duodenal ampulla' },
  { code: '5816009�', display: 'Duodenal fold' },
  { code: '9566001�', display: 'Duodenal lumen' },
  { code: '1236009�', display: 'Duodenal serosa' },
  { code: '1910005�', display: 'Ear' },
  { code: '2759004�', display: 'Eccrine gland structure' },
  { code: '5953002�', display: 'Eighth rib' },
  { code: '1511004�', display: 'Ejaculatory duct' },
  { code: '9156001�', display: 'Embryo stage 1' },
  { code: '667009�', display: 'Embryological structure' },
  { code: '3194006�', display: 'Endocardium of right atrium' },
  { code: '8911002�', display: 'Endocardium of right ventricle' },
  { code: '5769004�', display: 'Endocervical epithelium' },
  { code: '2739003�', display: 'Endometrial structure' },
  { code: '2182009�', display: 'Endometrial zona basalis' },
  { code: '5882002�', display: 'Endothelial sieve plate' },
  { code: '3789000�', display: 'Enteroendocrine cell' },
  { code: '5122003�', display: 'Entire angle of first rib' },
  { code: '2703009�', display: 'Entire calcarine artery' },
  { code: '2499000�', display: 'Entire cisterna pontis' },
  { code: '1005009�', display: 'Entire diaphragmatic lymph node' },
  { code: '4081003�', display: 'Entire dorsal lingual vein' },
  { code: '3785006�', display: 'Entire dorsal metacarpal ligament' },
  {
    code: '4193005�',
    display: 'Entire extensor tendon and tendon sheath of fifth toe',
  },
  { code: '2861001�', display: 'Entire gland (organ)' },
  { code: '4888005�', display: 'Entire interlobular vein of kidney' },
  {
    code: '3966004�',
    display: 'Entire parietal branch of anterior cerebral artery',
  },
  { code: '3350008�', display: 'Entire right commissure of pulmonic valve' },
  { code: '2998001�', display: 'Entire right margin of uterus' },
  { code: '4881004�', display: 'Entire sublingual vein' },
  { code: '3670005�', display: 'Entire subpyloric lymph node' },
  {
    code: '4549001�',
    display:
      'Entire vagus nerve parasympathetic fibers to liver, gallbladder, bile ducts and pancreas',
  },
  {
    code: '4718009�',
    display: 'Entire venous plexus of the foramen ovale basis cranii',
  },
  { code: '2730004�', display: 'Entire vitelline vein of placenta' },
  { code: '4029003�', display: 'Eosinophilic progranulocyte' },
  { code: '5979006�', display: 'Episcleral artery' },
  { code: '4212006�', display: 'Epithelial cells' },
  { code: '9231002�', display: 'External pudendal veins' },
  { code: '9558005�', display: 'Facet for fifth costal cartilage of sternum' },
  { code: '6110005�', display: 'Facial nerve parasympathetic fibers' },
  { code: '7149004�', display: 'False rib' },
  { code: '9262003�', display: 'Fascia of pelvis' },
  { code: '9813009�', display: 'Fascia of upper extremity' },
  { code: '1086007�', display: 'Female' },
  { code: '7657000�', display: 'Femoral artery' },
  { code: '4828007�', display: 'Femoral canal structure' },
  { code: '6720005�', display: 'Femoral nerve lateral muscular branches' },
  { code: '2510000�', display: 'Femoral triangle structure' },
  { code: '6326002�', display: 'Fetal atloid articulation' },
  { code: '809008�', display: 'Fetal chondrocranium' },
  { code: '688000�', display: 'Fetal hyaloid artery' },
  { code: '7117004�', display: 'Fetal implantation site' },
  { code: '107008�', display: 'Fetal part of placenta' },
  { code: '301000�', display: 'Fifth metatarsal bone' },
  { code: '9186008�', display: 'Filaments of contractile apparatus' },
  { code: '7569003�', display: 'Finger' },
  { code: '7967007�', display: 'First left aortic arch' },
  { code: '471000�', display: 'Flexor hallucis longus in leg' },
  { code: '284007�', display: 'Flexor tendon and tendon sheath of fourth toe' },
  { code: '1631005�', display: 'Foregut' },
  { code: '9849005�', display: 'Fornix of lacrimal sac' },
  { code: '5644007�', display: 'Fractured membrane P face' },
  { code: '7652006�', display: 'Frenulum labii' },
  { code: '1159005�', display: 'Frenulum linguae' },
  { code: '5213007�', display: 'Frontal vein' },
  { code: '1516009�', display: 'Frontomental diameter of head' },
  { code: '1063000�', display: 'Frontozygomatic suture of skull' },
  { code: '8894005�', display: 'Gastric cardiac gland' },
  { code: '414003�', display: 'Gastric fundus' },
  { code: '9654004�', display: 'Gastric mucous gland' },
  { code: '1353004�', display: 'Gastric serosa' },
  { code: '6544009�', display: 'Gastrohepatic ligament' },
  { code: '6076005�', display: 'Gastrointestinal subserosa' },
  { code: '9770007�', display: 'Genital tubercle' },
  { code: '6504002�', display: 'Glans clitoridis' },
  { code: '2156000�', display: 'Glial cell' },
  { code: '1307006�', display: 'Glottis' },
  { code: '206007�', display: 'Gluteus maximus muscle' },
  { code: '8668003�', display: 'Glycogen vacuole' },
  { code: '9258009�', display: 'Gravid uterus' },
  { code: '5928000�', display: 'Great cardiac vein' },
  { code: '3711007�', display: 'Great vessel' },
  { code: '1818002�', display: 'Greater circulus arteriosus of iris' },
  { code: '7188002�', display: 'Gustatory pore' },
  { code: '6866008�', display: 'Habenular commissure' },
  { code: '6359004�', display: 'Hair medulla' },
  { code: '3336003�', display: 'Haversian canal' },
  { code: '774007�', display: 'Head and neck' },
  { code: '9498009�', display: 'Head of metacarpal bone' },
  { code: '582005�', display: 'Head of phalanx of great toe' },
  { code: '2076008�', display: 'Head of phalanx of hand' },
  { code: '9677004�', display: 'Head of second metatarsal bone' },
  { code: '4215008�', display: 'Head of second rib structure' },
  { code: '3935005�', display: 'Head of tenth rib structure' },
  { code: '7944005�', display: 'Helper cell' },
  { code: '8993003�', display: 'Hepatic vein' },
  { code: '233006�', display: 'Hepatocolic ligament' },
  { code: '6023000�', display: 'Heterolysosome' },
  { code: '9847007�', display: 'Hilum of adrenal gland' },
  { code: '1650005�', display: 'Hilum of left lung' },
  { code: '8112008�', display: 'Hindgut' },
  { code: '5366008�', display: 'Hippocampal structure' },
  { code: '1605008�', display: 'Histioblast' },
  { code: '5501001�', display: 'Hyaloid artery' },
  { code: '4159006�', display: 'Hyparterial bronchus' },
  {
    code: '7227003�',
    display: 'Hypoglossal nerve intrinsic tongue muscle branch',
  },
  { code: '1581009�', display: 'Ileal artery' },
  { code: '9970000�', display: 'Ileocecal ostium' },
  { code: '9708001�', display: 'Iliac fascia' },
  { code: '8412003�', display: 'Iliac fossa' },
  { code: '8814006�', display: 'Iliac tuberosity' },
  { code: '7480001�', display: 'Iliotibial tract' },
  {
    code: '1243003�',
    display: 'Inferior articular process of sixth cervical vertebra',
  },
  {
    code: '8523001�',
    display: 'Inferior articular process of twelfth thoracic vertebra',
  },
  { code: '7234001�', display: 'Inferior choroid vein' },
  { code: '1122009�', display: 'Inferior longitudinal muscle of tongue' },
  { code: '6553002�', display: 'Inferior nasal turbinate' },
  { code: '422005�', display: 'Inferior surface of tongue' },
  { code: '8910001�', display: 'Inferior transverse scapular ligament' },
  { code: '8017000�', display: 'Inflow tract of right ventricle' },
  { code: '9659009�', display: 'Infraclavicular lymph nodes' },
  { code: '8161005�', display: 'Infraorbital nerve' },
  { code: '7347009�', display: 'Infratendinous olecranon bursa' },
  { code: '8100009�', display: 'Infundibulum of Fallopian tube' },
  { code: '8928004�', display: 'Inguinal lymph node' },
  { code: '5653000�', display: 'Inner surface of seventh rib' },
  { code: '8560002�', display: 'Interclavicular ligament' },
  { code: '9325002�', display: 'Interdental papilla of gingiva' },
  { code: '8158009�', display: 'Interlobar duct of pancreas' },
  { code: '8292002�', display: 'Interlobular arteries of liver' },
  { code: '3003007�', display: 'Internal capsule anterior limb structure' },
  { code: '9841008�', display: 'Interspinalis thoracis muscles' },
  { code: '7076002�', display: 'Interstitial tissue of myocardium' },
  { code: '6805009�', display: 'Interstitial tissue of spleen' },
  { code: '3362007�', display: 'Intertragal incisure structure' },
  { code: '7651004�', display: 'Intervenous tubercle of right atrium' },
  { code: '589001�', display: 'Interventricular septum' },
  {
    code: '9188009�',
    display: 'Intervertebral disc of eighth thoracic vertebra',
  },
  {
    code: '1537001�',
    display: 'Intervertebral disc of eleventh thoracic vertebra',
  },
  {
    code: '6004007�',
    display: 'Intervertebral disc of second thoracic vertebra',
  },
  {
    code: '7591005�',
    display: 'Intervertebral disc space of eleventh thoracic vertebra',
  },
  {
    code: '565008�',
    display: 'Intervertebral disc space of fifth lumbar vertebra',
  },
  {
    code: '8091003�',
    display: 'Intervertebral disc space of twelfth thoracic vertebra',
  },
  {
    code: '2620004�',
    display: 'Intervertebral disc structure of third thoracic vertebra',
  },
  {
    code: '10042008�',
    display: 'Intervertebral foramen of fifth thoracic vertebra',
  },
  {
    code: '8695008�',
    display: 'Intervertebral foramen of second lumbar vertebra',
  },
  { code: '7851002�', display: 'Intracranial subdural space' },
  {
    code: '8784007�',
    display: 'Intranuclear body, granular with filamentous capsule',
  },
  { code: '8111001�', display: 'Intranuclear crystal' },
  { code: '1617005�', display: 'Intrinsic larynx' },
  { code: '3608004�', display: 'Ischiococcygeus muscle' },
  { code: '7192009�', display: 'Isthmus tympani posticus' },
  { code: '661005�', display: 'Jaw region' },
  { code: '8454000�', display: 'Joint of lumbar vertebra' },
  { code: '1893007�', display: 'Joint of upper extremity' },
  { code: '4527007�', display: 'Joint structure of lower extremity' },
  { code: '8983005�', display: 'Joint structure of vertebral column' },
  { code: '6930008�', display: 'Jugular arch' },
  { code: '8373002�', display: 'Jugum of sphenoid bone' },
  { code: '8559007�', display: 'Juxtaintestinal lymph node' },
  { code: '256002�', display: 'Kupffer cell' },
  { code: '9305001�', display: 'Labial veins' },
  { code: '5491007�', display: 'Lacrimal part of orbicularis oculi muscle' },
  { code: '3954005�', display: 'Lacrimal sac structure' },
  { code: '6050005�', display: 'Lacrimal vein' },
  {
    code: '4548009�',
    display: 'Lamellated granule, as in surfactant-secreting cell',
  },
  { code: '3646006�', display: 'Lamellipodium' },
  { code: '885000�', display: 'Lamina muscularis of colonic mucous membrane' },
  { code: '8387002�', display: 'Lamina of eighth thoracic vertebra' },
  { code: '2205003�', display: 'Lamina of fifth thoracic vertebra' },
  { code: '1527006�', display: 'Lamina of fourth thoracic vertebra' },
  { code: '4871003�', display: 'Lamina of modiolus of cochlea' },
  { code: '7756004�', display: 'Lamina of third thoracic vertebra' },
  { code: '7954009�', display: 'Lamina propria of ethmoid sinus' },
  { code: '2771005�', display: 'Lamina propria of ureter' },
  { code: '9625005�', display: 'Lanugo hair' },
  { code: '1441004�', display: 'Laryngeal saccule' },
  { code: '4596009�', display: 'Larynx structure' },
  { code: '461002�', display: 'Lateral corticospinal tract' },
  { code: '8897003�', display: 'Lateral glossoepiglottic fold' },
  { code: '9332006�', display: 'Lateral ligament of temporomandibular joint' },
  { code: '10013000�', display: 'Lateral meniscus of knee joint' },
  { code: '273000�', display: 'Lateral myocardium' },
  { code: '8057002�', display: 'Lateral nuclei of globus pallidus' },
  { code: '8688004�', display: 'Lateral olfactory gyrus' },
  { code: '4061004�', display: 'Lateral orbital wall' },
  { code: '7820009�', display: 'Lateral posterior nucleus of thalamus' },
  { code: '4268002�', display: 'Lateral spinorubral tract' },
  { code: '8160006�', display: 'Lateral striate arteries' },
  { code: '7471001�', display: 'Lateral surface of sublingual gland' },
  { code: '246001�', display: 'Lateral vestibular nucleus' },
  { code: '6864006�', display: 'Leaf of epiglottis' },
  { code: '9775002�', display: 'Left carotid sinus' },
  { code: '205006�', display: 'Left commissure of aortic valve' },
  { code: '3227004�', display: 'Left coronary artery main stem' },
  { code: '1927002�', display: 'Left elbow' },
  { code: '8966001�', display: 'Left eye' },
  { code: '7376007�', display: 'Left margin of uterus' },
  { code: '2695000�', display: 'Left maxillary sinus structure' },
  { code: '9003000�', display: 'Left parietal lobe' },
  { code: '9642004�', display: 'Left superior vena cava' },
  { code: '8907008�', display: 'Left ulnar artery' },
  { code: '5951000�', display: 'Left wrist' },
  { code: '5128004�', display: 'Lens zonules' },
  { code: '5677006�', display: 'Lenticular fasciculus' },
  { code: '2236006�', display: 'Lesser osseous pelvis' },
  { code: '10062000�', display: 'Levator labii superioris muscle' },
  { code: '7936005�', display: 'Ligament of diaphragm' },
  { code: '6073002�', display: 'Ligament of left superior vena cava' },
  { code: '6828005�', display: 'Ligament of lumbosacral joint' },
  {
    code: '8464009�',
    display: 'Ligament of sacroiliac joint and pubic symphysis',
  },
  { code: '2160002�', display: 'Ligamentum arteriosum' },
  { code: '6404004�', display: 'Ligamentum nuchae' },
  { code: '1684009�', display: 'Ligamentum teres of liver' },
  { code: '8024004�', display: 'Limitans nucleus' },
  { code: '7344002�', display: 'Lingual gyrus' },
  { code: '6598008�', display: 'Lingual nerve' },
  { code: '2048000�', display: 'Lingual tonsil' },
  { code: '8556000�', display: 'Lipid droplet' },
  { code: '4647008�', display: 'Lipid droplet, homogeneous' },
  { code: '7477002�', display: 'Lipid, crystalline' },
  {
    code: '5544002�',
    display: 'Longitudinal layer of duodenal muscularis propria',
  },
  { code: '6912001�', display: 'Lower alveolar ridge mucosa' },
  { code: '5668004�', display: 'Lower digestive tract' },
  { code: '4942000�', display: 'Lower limb lymph node structure' },
  { code: '8334002�', display: 'Lumbar lymph node' },
  { code: '9384000�', display: 'Lumbar vein' },
  {
    code: '4117005�',
    display: 'Lumbosacral spinal cord central canal structure',
  },
  { code: '7362006�', display: 'Lymphatic of head' },
  { code: '7275008�', display: 'Lymphatics of appendix and large intestine' },
  { code: '9390001�', display: 'Lymphatics of stomach' },
  { code: '6371005�', display: 'Lymphatics of thyroid gland' },
  { code: '6969002�', display: 'Lymphocytic tissue' },
  { code: '6335009�', display: 'Lymphoid follicle of stomach' },
  { code: '7841003�', display: 'Macrocytic normochromic erythrocyte' },
  { code: '4111006�', display: 'Macula of the saccule' },
  { code: '6799003�', display: 'Macula of utricle' },
  { code: '1106008�', display: 'Major calyx' },
  { code: '10052007�', display: 'Male' },
  { code: '2123001�', display: 'Mammilloinfundibular nucleus' },
  { code: '7854005�', display: 'Mandibular canal' },
  { code: '1173001�', display: 'Mandibular nerve' },
  { code: '8873007�', display: 'Mandibular right second premolar tooth' },
  { code: '8988001�', display: 'Marginal part of orbicularis oris muscle' },
  { code: '6445007�', display: 'Mast cell' },
  { code: '4906008�', display: 'Mastoid antrum structure' },
  { code: '8314003�', display: 'Mastoid fontanel of skull' },
  { code: '7121006�', display: 'Maxillary right second molar tooth' },
  { code: '6472004�', display: 'Medial aspect of ovary' },
  { code: '6564004�', display: 'Medial border of sole' },
  {
    code: '9455005�',
    display: 'Medial cartilaginous lamina of Eustachian tube',
  },
  { code: '1627004�', display: 'Medial collateral ligament of knee joint' },
  { code: '6417001�', display: 'Medial malleolus' },
  { code: '9732008�', display: 'Medial supraclavicular nerves' },
  { code: '6894000�', display: 'Medial surface of arm' },
  { code: '5802004�', display: 'Medium sized neuron' },
  { code: '8604004�', display: 'Medulla oblongata fasciculus cuneatus' },
  { code: '8595004�', display: "Meissner's plexus" },
  { code: '9683001�', display: 'Melanocyte' },
  { code: '2502001�', display: 'Membrane of lysosome' },
  { code: '8045006�', display: 'Membrane-coating granule, amorphous' },
  { code: '996007�', display: 'Meningeal branch of occipital artery' },
  { code: '9317004�', display: "Merkel's tactile disc" },
  { code: '6009002�', display: 'Mesentery of ascending colon' },
  { code: '8483002�', display: 'Mesial surface of tooth' },
  { code: '247005�', display: 'Mesotympanum' },
  { code: '7755000�', display: 'Mesovarian margin of ovary' },
  { code: '289002�', display: 'Metacarpophalangeal joint of index finger' },
  { code: '5493005�', display: 'Metacarpophalangeal joint of little finger' },
  { code: '6059006�', display: 'Metacarpophalangeal joint of middle finger' },
  { code: '1620002�', display: 'Metaphase nucleus' },
  { code: '2332005�', display: 'Metencephalon of foetus' },
  { code: '9018004�', display: 'Middle colic vein' },
  { code: '7083009�', display: 'Middle phalanx of index finger' },
  { code: '8710005�', display: 'Minor sublingual ducts' },
  { code: '6750002�', display: 'Mitochondrial inclusion' },
  { code: '3081007�', display: 'Mitochondrion in division' },
  { code: '7566005�', display: 'Mitotic cell in anaphase' },
  { code: '3198009�', display: 'Monostomatic sublingual gland' },
  { code: '8001006�', display: 'Mucous membrane of tongue' },
  { code: '5709001�', display: 'Multifidus muscles' },
  { code: '621009�', display: 'Multivesicular body, internal vesicles' },
  { code: '7295002�', display: 'Muscle of perineum' },
  { code: '5633004�', display: 'Muscular portion of interventricular septum' },
  { code: '3563003�', display: 'Muscularis of ureter' },
  { code: '1895000�', display: 'Musculophrenic vein' },
  { code: '7872004�', display: 'Myocardium of ventricle' },
  { code: '5898009�', display: 'Myotome' },
  { code: '6194002�', display: 'Nail of fourth toe' },
  { code: '8770002�', display: 'Nail of second toe' },
  { code: '1569005�', display: 'Nail of third toe' },
  { code: '1797002�', display: 'Naris' },
  {
    code: '5625000�',
    display: 'Navicular facet of intermediate cuneiform bone',
  },
  { code: '3057000�', display: 'Nerve structure' },
  { code: '2639009�', display: 'Neuroendocrine tissue' },
  { code: '4717004�', display: 'Neutrophilic myelocyte' },
  { code: '9240003�', display: 'Niemann-Pick cell' },
  { code: '3383001�', display: 'Nonsegmented basophil' },
  { code: '5398002�', display: 'Normal fat pad' },
  { code: '2083001�', display: 'Nucleus ambiguus' },
  { code: '1659006�', display: 'Nucleus medialis dorsalis' },
  {
    code: '10036009�',
    display:
      'Nucleus pulposus of intervertebral disc of eighth thoracic vertebra',
  },
  {
    code: '1562001�',
    display: 'Nucleus pulposus of intervertebral disc of third lumbar vertebra',
  },
  { code: '1580005�', display: 'Nucleus ventralis lateralis' },
  { code: '8496001�', display: 'Obliquus capitis muscle' },
  { code: '6820003�', display: 'Obturator nerve anterior branch' },
  { code: '7896009�', display: 'Occipital angle of parietal bone' },
  { code: '3332001�', display: 'Occipitofrontal diameter of head' },
  { code: '1172006�', display: 'Odontoid process of axis' },
  { code: '3960005�', display: 'Olfactory tract structure' },
  { code: '7911004�', display: 'Olivocerebellar fibers' },
  { code: '10056005�', display: 'Ophthalmic nerve' },
  { code: '836005�', display: 'Oral region of face' },
  { code: '5854009�', display: 'Orbital plate of ethmoid bone' },
  { code: '6229007�', display: 'Os lacrimale' },
  { code: '8251006�', display: 'Osseous portion of Eustachian tube' },
  { code: '1610007�', display: 'Otoconia' },
  { code: '6046003�', display: 'Outer surface of third rib' },
  { code: '7435002�', display: 'Ovarian ligament' },
  { code: '976004�', display: 'Ovarian vein' },
  { code: '3931001�', display: 'OW - Oval window' },
  { code: '7742001�', display: 'Palatine duct' },
  { code: '8862000�', display: 'Palmar surface of middle finger' },
  { code: '595000�', display: 'Palpebral fissure' },
  { code: '8059004�', display: 'Pancreatic veins' },
  { code: '3255000�', display: 'Papillary area' },
  { code: '9898001�', display: 'Paracentral lobule' },
  { code: '5780004�', display: 'Paradidymis' },
  { code: '7167006�', display: 'Paraduodenal recess' },
  { code: '1611006�', display: 'Paramammary lymph node' },
  { code: '111002�', display: 'Parathyroid gland' },
  { code: '7378008�', display: 'Paraventricular nucleus of thalamus' },
  { code: '1439000�', display: 'Paravesicular lymph node' },
  {
    code: '1467009�',
    display: 'Parietal branch of superficial temporal artery',
  },
  { code: '6829002�', display: 'Pars ciliaris of retina' },
  { code: '9380009�', display: 'Pecten ani' },
  { code: '251007�', display: 'Pectoral region' },
  { code: '8820007�', display: 'Pedicle of eleventh thoracic vertebra' },
  { code: '8279000�', display: 'Pelvic viscus' },
  { code: '3665003�', display: 'Pelvic wall structure' },
  { code: '3447009�', display: 'Penetrated oocyte' },
  { code: '345000�', display: 'Penetrating atrioventricular bundle' },
  { code: '6014003�', display: 'Penicilliary arteries' },
  {
    code: '9837009�',
    display: 'Perforating branches of internal thoracic artery',
  },
  { code: '8711009�', display: 'Periodontal tissues' },
  { code: '1711002�', display: 'Periorbital region' },
  { code: '9881001�', display: 'Peripheral nerve myelinated nerve fiber' },
  { code: '6914000�', display: 'Perivascular space' },
  { code: '9379006�', display: 'Permanent teeth' },
  { code: '5140004�', display: 'Permanent upper right 6 tooth' },
  { code: '8821006�', display: 'Peroneal artery' },
  { code: '2175005�', display: 'Pharyngeal cavity' },
  { code: '1849007�', display: 'Pharyngeal recess' },
  { code: '6217003�', display: 'Piriform recess' },
  { code: '7384006�', display: 'Plantar calcaneocuboidal ligament' },
  { code: '9432007�', display: 'Plantar surface of fourth toe' },
  { code: '311007�', display: 'Plantar surface of great toe' },
  { code: '7498002�', display: 'Plantar tarsal ligaments' },
  { code: '2579009�', display: 'Plasmablast' },
  { code: '3120008�', display: 'Pleural membrane structure' },
  { code: '6731002�', display: 'Pleural recess' },
  { code: '3058005�', display: 'PNS - Peripheral nervous system' },
  { code: '6551000�', display: 'Pollicis artery' },
  {
    code: '7697002�',
    display: 'Pontine portion of medial longitudinal fasciculus',
  },
  { code: '6902008�', display: 'Popliteal region' },
  { code: '1992003�', display: 'Porus acusticus internus' },
  { code: '6216007�', display: 'Postcapillary venule' },
  {
    code: '6317000�',
    display: 'Posterior basal branch of left pulmonary artery',
  },
  { code: '106004�', display: 'Posterior carpal region' },
  { code: '5890002�', display: 'Posterior cells of ethmoid sinus' },
  { code: '823005�', display: 'Posterior cervical spinal cord nerve root' },
  { code: '7736000�', display: 'Posterior choroidal artery' },
  { code: '4019005�', display: 'Posterior commissure of labium majorum' },
  { code: '5828001�', display: 'Posterior cord of brachial plexus' },
  { code: '9951005�', display: 'Posterior ethmoidal nerve' },
  { code: '6981003�', display: 'Posterior hypothalamic nucleus' },
  { code: '8546004�', display: 'Posterior intercavernous sinus' },
  { code: '808000�', display: 'Posterior intercostal artery' },
  { code: '9290004�', display: 'Posterior intraoccipital synchondrosis' },
  { code: '1425000�', display: 'Posterior limb of stapes' },
  { code: '7835005�', display: 'Posterior margin of nasal septum' },
  { code: '3471002�', display: 'Posterior midline of trunk' },
  { code: '5944009�', display: 'Posterior palatine arch' },
  { code: '9609000�', display: 'Posterior process of nasal septal cartilage' },
  {
    code: '9684007�',
    display: 'Posterior scrotal branches of internal pudendal artery',
  },
  { code: '9242006�', display: 'Posterior segment of right lobe of liver' },
  { code: '405008�', display: 'Posterior temporal diploic vein' },
  { code: '4258007�', display: 'Posterior tibial vein' },
  { code: '6448009�', display: 'Posterior vagal trunk' },
  { code: '1780008�', display: 'Premelanosome' },
  { code: '6413002�', display: 'Presymphysial lymph node' },
  { code: '9968009�', display: 'Primary foot process' },
  { code: '1075005�', display: 'Promonocyte' },
  { code: '1874005�', display: 'Promontory lymph node' },
  { code: '8850004�', display: 'Proper fasciculus' },
  { code: '4328003�', display: 'Protoplasmic astrocyte' },
  { code: '9825007�', display: 'Proximal phalanx of little toe' },
  { code: '7925003�', display: 'Proximal phalanx of third toe' },
  { code: '9490002�', display: 'Pulmonary ligament' },
  { code: '9535007�', display: 'Pyramid of medulla oblongata' },
  { code: '6001004�', display: 'Quadratus lumborum muscle' },
  { code: '8079007�', display: 'Radial nerve' },
  { code: '2841007�', display: 'Renal artery structure' },
  { code: '2334006�', display: 'Renal calyx' },
  { code: '8415001�', display: 'Renal surface of adrenal gland' },
  { code: '356000�', display: 'Reticular corium' },
  { code: '2071003�', display: 'Reticular formation of spinal cord' },
  { code: '6013009�', display: 'Reticuloendothelial system' },
  { code: '5665001�', display: 'Retina' },
  { code: '5597008�', display: 'Retina of right eye' },
  { code: '9976006�', display: 'Rhomboideus cervicis muscle' },
  { code: '7880006�', display: 'Rhopheocytotic vesicle' },
  { code: '6685009�', display: 'Right ankle' },
  { code: '6643005�', display: 'Right anterior cerebral artery' },
  { code: '5228007�', display: 'Right cerebral hemisphere structure' },
  { code: '7067009�', display: 'Right colic artery' },
  { code: '2033006�', display: 'Right fallopian tube' },
  { code: '7769000�', display: 'Right foot' },
  { code: '9846003�', display: 'Right kidney' },
  { code: '6757004�', display: 'Right knee' },
  { code: '266005�', display: 'Right lower lobe of lung' },
  { code: '3341006�', display: 'Right lung structure' },
  { code: '8608001�', display: 'Right margin of heart' },
  { code: '6633007�', display: 'Right posterior cerebral artery' },
  { code: '1721005�', display: 'Right tonsil' },
  { code: '6921000�', display: 'Right upper extremity' },
  { code: '5627008�', display: 'Right visceral pleura' },
  { code: '9736006�', display: 'Right wrist' },
  { code: '1825009�', display: 'Root of nose' },
  { code: '7885001�', display: 'Rotator cuff including muscles and tendons' },
  { code: '9229006�', display: 'Rotatores lumborum muscles' },
  { code: '1781007�', display: 'Sacroiliac region' },
  { code: '2327009�', display: 'Salivary seromucous gland' },
  { code: '4394008�', display: 'Scala vestibuli structure' },
  { code: '7874003�', display: 'Scapular region of back' },
  { code: '1832000�', display: 'Scleral conjunctiva' },
  { code: '1028005�', display: 'Sebaceous gland structure' },
  { code: '6850006�', display: 'Secondary foot process' },
  { code: '3374006�', display: 'Secondary spermatocyte' },
  { code: '3810000�', display: 'Septal cartilage structure' },
  { code: '5868002�', display: 'Serosa of urinary bladder' },
  { code: '5574005�', display: 'Seventh costal cartilage' },
  { code: '5421003�', display: 'Sex chromosome Y' },
  { code: '9212008�', display: 'Shaft of fifth metatarsal bone' },
  { code: '8827005�', display: 'Shaft of phalanx of middle finger' },
  { code: '5564007�', display: 'Singlet' },
  {
    code: '8482007�',
    display: 'Sinoatrial node branch of right coronary artery',
  },
  { code: '9796009�', display: 'Skeletal muscle fiber, type IIb' },
  { code: '7748002�', display: 'Skin appendage' },
  { code: '6279005�', display: 'Skin of dorsal surface of finger' },
  { code: '2059009�', display: 'Skin of ear lobule' },
  { code: '1902009�', display: 'Skin of external ear' },
  { code: '5742000�', display: 'Skin of forearm' },
  { code: '7991003�', display: 'Skin of glans penis' },
  { code: '5697001�', display: 'Skin of lateral surface of finger' },
  { code: '7726008�', display: 'Skin of medial surface of fifth toe' },
  { code: '9348007�', display: 'Skin of medial surface of middle finger' },
  { code: '315003�', display: 'Skin of umbilicus' },
  { code: '2484000�', display: 'Skin structure of frenulum of clitoris' },
  { code: '5272005�', display: 'Skin structure of lateral portion of neck' },
  { code: '4658004�', display: 'Skin structure of nuchal region' },
  { code: '4578000�', display: 'Skin structure of posterior surface of thigh' },
  { code: '4369007�', display: 'Small intestine mucous membrane structure' },
  { code: '5379004�', display: 'Small intestine muscularis propria' },
  { code: '8266009�', display: 'Small intestine serosa' },
  { code: '691000�', display: 'Small intestine submucosa' },
  { code: '1490004�', display: 'Soft tissues of trunk' },
  { code: '6789008�', display: 'Spermatozoa' },
  { code: '6608009�', display: 'Sphenoparietal sinus' },
  { code: '8369000�', display: 'Sphincter pupillae muscle' },
  { code: '3062004�', display: 'Spinal arachnoid' },
  { code: '2748008�', display: 'Spinal cord structure' },
  { code: '3169005�', display: 'Spinal nerve structure' },
  { code: '830004�', display: 'Spinous process of fifth thoracic vertebra' },
  { code: '3302009�', display: 'Splenic branch of splenic artery' },
  { code: '9081000�', display: 'Splenic sinusoids' },
  { code: '1600003�', display: 'Splenius capitis muscle' },
  { code: '6844001�', display: 'Spore crystal' },
  { code: '6623004�', display: 'Sternal muscle' },
  { code: '7844006�', display: 'Sternoclavicular joint' },
  { code: '1087003�', display: 'Sternothyroid muscle' },
  { code: '8068002�', display: 'Stratum lemnisci of corpora quadrigemina' },
  { code: '4360006�', display: 'Stratum spinosum structure' },
  { code: '8965002�', display: 'Stratum zonale of corpora quadrigemina' },
  { code: '7764005�', display: 'Striate artery' },
  { code: '2855004�', display: 'Structure of accessory cephalic vein' },
  { code: '4486002�', display: 'Structure of accessory sinus gland' },
  { code: '3377004�', display: 'Structure of agger nasi' },
  {
    code: '4402002�',
    display: 'Structure of anterior articular surface for talus',
  },
  {
    code: '2672009�',
    display: 'Structure of anterior cutaneous branch of lumbosacral plexus',
  },
  { code: '2675006�', display: 'Structure of anterior ethmoidal artery' },
  { code: '2905008�', display: 'Structure of anterior ligament of uterus' },
  {
    code: '2031008�',
    display: 'Structure of anterior median fissure of spinal cord',
  },
  { code: '5296000�', display: 'Structure of anterior mediastinal lymph node' },
  {
    code: '3366005�',
    display: 'Structure of anterior papillary muscle of left ventricle',
  },
  {
    code: '3460003�',
    display: 'Structure of anterodorsal nucleus of thalamus',
  },
  {
    code: '3056009�',
    display: 'Structure of anteroventral nucleus of thalamus',
  },
  { code: '3838008�', display: 'Structure of apex of coccyx' },
  { code: '2718005�', display: 'Structure of apical foramen of tooth' },
  {
    code: '3788008�',
    display: 'Structure of apical segment of right lower lobe of lung',
  },
  { code: '4146003�', display: 'Structure of artery of extremity' },
  {
    code: '3054007�',
    display: 'Structure of articular surface, metacarpal, of phalanx of thumb',
  },
  {
    code: '2454008�',
    display:
      'Structure of articular surface, third metacarpal, of second metacarpal bone',
  },
  { code: '4603002�', display: 'Structure of base of phalanx of foot' },
  { code: '2306004�', display: 'Structure of base of third metacarpal bone' },
  { code: '3578005�', display: 'Structure of body of gallbladder' },
  { code: '3055008�', display: 'Structure of bone marrow of vertebral body' },
  { code: '2687004�', display: 'Structure of Bowman space' },
  { code: '2712006�', display: 'Structure of capsule of ankle joint' },
  {
    code: '4066009�',
    display:
      'Structure of capsule of proximal interphalangeal joint of index finger',
  },
  {
    code: '3538003�',
    display: 'Structure of capsule of proximal tibiofibular joint',
  },
  { code: '4430002�', display: 'Structure of clivus ossis sphenoidalis' },
  { code: '3462006�', display: 'Structure of commissure of tricuspid valve' },
  { code: '3877006�', display: 'Structure of common carotid plexus' },
  { code: '3159004�', display: 'Structure of costocervical trunk' },
  { code: '2810006�', display: 'Structure of cremasteric fascia' },
  {
    code: '2543001�',
    display: 'Structure of cuboid articular facet of fourth metatarsal bone',
  },
  { code: '9438006�', display: 'Structure of deep cervical lymphatic vessel' },
  {
    code: '2433001�',
    display: 'Structure of digital tendon and tendon sheath of foot',
  },
  {
    code: '2830005�',
    display:
      'Structure of dorsal digital nerves of lateral hallux and medial second toe',
  },
  { code: '6606008�', display: 'Structure of dorsal intercuneiform ligaments' },
  { code: '3541007�', display: 'Structure of dorsal metatarsal artery' },
  { code: '2681003�', display: 'Structure of dorsal nerve of penis' },
  {
    code: '3556003�',
    display: 'Structure of dorsal nucleus of trapezoid body',
  },
  { code: '3867009�', display: 'Structure of dorsal surface of great toe' },
  { code: '3117000�', display: 'Structure of dorsal surface of index finger' },
  {
    code: '4870002�',
    display: 'Structure of dorsal tegmental nuclei of midbrain',
  },
  { code: '2402003�', display: 'Structure of dorsum of foot' },
  { code: '4432005�', display: 'Structure of ductus arteriosus' },
  { code: '3481003�', display: 'Structure of embryonic testis' },
  { code: '3937002�', display: 'Structure of entorhinal cortex' },
  { code: '5194009�', display: 'Structure of epithelium of lens' },
  { code: '2920002�', display: 'Structure of facial nerve motor branch' },
  { code: '1012000�', display: 'Structure of fibrous portion of pericardium' },
  {
    code: '3956007�',
    display:
      'Structure of fifth metatarsal articular facet of fourth metatarsal bone',
  },
  {
    code: '2397002�',
    display: 'Structure of first metatarsal facet of medial cuneiform bone',
  },
  {
    code: '2600000�',
    display: 'Structure of flexor pollicis longus muscle tendon',
  },
  { code: '2726002�', display: 'Structure of fold for stapes' },
  { code: '5279001�', display: 'Structure of foramen singulare' },
  { code: '4072009�', display: 'Structure of fourth coccygeal vertebra' },
  {
    code: '4371007�',
    display: 'Structure of fourth metatarsal facet of lateral cuneiform bone',
  },
  { code: '3582007�', display: 'Structure of gastrophrenic ligament' },
  { code: '4759003�', display: 'Structure of gemellus muscle' },
  { code: '3042007�', display: 'Structure of glomerular urinary pole' },
  { code: '2577006�', display: 'Structure of gracilis muscle' },
  { code: '3964001�', display: 'Structure of gyrus of brain' },
  { code: '2812003�', display: 'Structure of head of femur' },
  { code: '3134008�', display: 'Structure of head of fourth metatarsal bone' },
  {
    code: '4375003�',
    display: 'Structure of incisure of cartilaginous portion of auditory canal',
  },
  {
    code: '4276000�',
    display:
      'Structure of inferior articular process of seventh cervical vertebra',
  },
  { code: '5336001�', display: 'Structure of inferior frontal gyrus' },
  { code: '5076001�', display: 'Structure of infrasternal angle' },
  {
    code: '4537002�',
    display: 'Structure of internal medullary lamina of thalamus',
  },
  { code: '3865001�', display: 'Structure of interscapular region of back' },
  {
    code: '5192008�',
    display: 'Structure of intervertebral foramen of twelfth thoracic vertebra',
  },
  {
    code: '2826007�',
    display: 'Structure of lamina of fourth lumbar vertebra',
  },
  { code: '3409004�', display: 'Structure of lateral margin of forearm' },
  {
    code: '4358009�',
    display: 'Structure of lateral surface of little finger',
  },
  { code: '3743007�', display: 'Structure of lateral thoracic artery' },
  { code: '3524005�', display: 'Structure of lateral wall of mastoid antrum' },
  { code: '4621004�', display: 'Structure of lesser tuberosity of humerus' },
  { code: '4624007�', display: 'Structure of lymphatic cord' },
  { code: '1765002�', display: 'Structure of lymphatic vessel of thorax' },
  { code: '2489005�', display: 'Structure of medial check ligament of eye' },
  { code: '3989007�', display: 'Structure of medial globus pallidus' },
  { code: '2741002�', display: 'Structure of medial occipitotemporal gyrus' },
  { code: '2686008�', display: 'Structure of medial olfactory gyrus' },
  { code: '4755009�', display: 'Structure of medial surface of great toe' },
  { code: '2372001�', display: 'Structure of medial surface of toe' },
  {
    code: '5362005�',
    display: 'Structure of median arcuate ligament of diaphragm',
  },
  { code: '3396000�', display: 'Structure of mesogastrium' },
  { code: '1484003�', display: 'Structure of metatarsal region of foot' },
  { code: '4281009�', display: 'Structure of middle portion of ileum' },
  { code: '4588004�', display: 'Structure of middle trunk of brachial plexus' },
  { code: '5229004�', display: 'Structure of mucosa of gallbladder' },
  { code: '2349003�', display: 'Structure of nasal suture of skull' },
  { code: '2292006�', display: 'Structure of nasopharyngeal gland' },
  {
    code: '3761003�',
    display:
      'Structure of nucleus pulposus of intervertebral disc of first thoracic vertebra',
  },
  { code: '3156006�', display: 'Structure of obturator artery' },
  {
    code: '5068003�',
    display: 'Structure of obturator internus muscle ischial bursa',
  },
  {
    code: '3609007�',
    display: 'Structure of occipital branch of posterior auricular artery',
  },
  { code: '3916005�', display: 'Structure of occipital lymph node' },
  { code: '4148002�', display: 'Structure of palmar surface of little finger' },
  { code: '2504000�', display: 'Structure of pancreatic plexus' },
  {
    code: '2383005�',
    display: 'Structure of papillary muscles of right ventricle',
  },
  { code: '4295007�', display: 'Structure of paracortical area of lymph node' },
  {
    code: '4377006�',
    display: 'Structure of parafascicular nucleus of thalamus',
  },
  { code: '3243006�', display: 'Structure of parametrial lymph node' },
  { code: '2792005�', display: 'Structure of pars tensa of tympanic membrane' },
  {
    code: '3279003�',
    display: 'Structure of pedicle of third cervical vertebra',
  },
  { code: '3924000�', display: 'Structure of pericardiophrenic artery' },
  { code: '4768001�', display: 'Structure of perineal nerve' },
  { code: '1015003�', display: 'Structure of peritonsillar tissue' },
  {
    code: '2839006�',
    display: 'Structure of perivesicular tissues of seminal vesicles',
  },
  {
    code: '4774001�',
    display: 'Structure of phrenic nerve pericardial branch',
  },
  { code: '5115006�', display: 'Structure of posterior auricular vein' },
  { code: '2894003�', display: 'Structure of posterior epiglottis' },
  {
    code: '2922005�',
    display: 'Structure of posterior papillary muscle of left ventricle',
  },
  {
    code: '2909002�',
    display: 'Structure of posterior portion of diaphragmatic aspect of liver',
  },
  {
    code: '3236000�',
    display: 'Structure of posterior segment of right upper lobe of lung',
  },
  { code: '5026003�', display: 'Structure of posterior surface of prostate' },
  {
    code: '2653009�',
    display: 'Structure of posterior thalamic radiation of internal capsule',
  },
  { code: '4954000�', display: 'Structure of radial notch of ulna' },
  {
    code: '6424000�',
    display: 'Structure of radiating portion of cortical lobule of kidney',
  },
  { code: '3178004�', display: 'Structure of raphe of soft palate' },
  { code: '3959000�', display: 'Structure of rectus capitis muscle' },
  { code: '2845003�', display: 'Structure of respiratory epithelium' },
  { code: '5195005�', display: 'Structure of right external carotid artery' },
  { code: '3382006�', display: 'Structure of rima oris' },
  { code: '3262009�', display: 'Structure of root canal of tooth' },
  { code: '3417007�', display: 'Structure of rotator muscle' },
  { code: '5069006�', display: 'Structure of rugal column' },
  { code: '2330002�', display: 'Structure of segmental bronchial branches' },
  { code: '3068000�', display: 'Structure of seminal vesicle lumen' },
  { code: '2666009�', display: 'Structure of semispinalis capitis muscle' },
  { code: '4317002�', display: 'Structure of spinalis muscle' },
  {
    code: '2305000�',
    display: 'Structure of spinous process of cervical vertebra',
  },
  {
    code: '2824005�',
    display: 'Structure of spinous process of fourth thoracic vertebra',
  },
  { code: '4583008�', display: 'Structure of splenius muscle of trunk' },
  {
    code: '3594003�',
    display: 'Structure of straight part of longus colli muscle',
  },
  { code: '4352005�', display: 'Structure of subchorionic space' },
  { code: '4524000�', display: 'Structure of subclavian plexus' },
  { code: '2421006�', display: 'Structure of submaxillary ganglion' },
  {
    code: '5046008�',
    display: 'Structure of superficial dorsal veins of clitoris',
  },
  { code: '2848001�', display: 'Structure of superficial epigastric artery' },
  { code: '4121003�', display: 'Structure of superior frontal sulcus' },
  {
    code: '2389009�',
    display: 'Structure of superior margin of adrenal gland',
  },
  { code: '3395001�', display: 'Structure of superior palpebral arch' },
  { code: '5324007�', display: 'Structure of superior pole of kidney' },
  { code: '2539000�', display: 'Structure of superior rectal artery' },
  { code: '5001007�', display: 'Structure of superior temporal sulcus' },
  { code: '3370002�', display: 'Structure of supporting tissue of rectum' },
  { code: '4766002�', display: 'Structure of supracardinal vein' },
  { code: '3113001�', display: 'Structure of suprachoroidal space' },
  { code: '1853009�', display: 'Structure of suprahyoid muscle' },
  {
    code: '2986006�',
    display: 'Structure of talar facet of navicular bone of foot',
  },
  { code: '3093003�', display: 'Structure of tendinous arch of pelvic fascia' },
  {
    code: '2803000�',
    display: 'Structure of tendon sheath of popliteus muscle',
  },
  { code: '4566004�', display: 'Structure of tentorium cerebelli' },
  { code: '5261000�', display: 'Structure of thoracic intervertebral disc' },
  { code: '3553006�', display: 'Structure of thyroid capsule' },
  { code: '2285001�', display: 'Structure of torcular Herophili' },
  {
    code: '3118005�',
    display: 'Structure of trabecula carnea of left ventricle',
  },
  { code: '3153003�', display: 'Structure of tractus olivocochlearis' },
  { code: '3860006�', display: 'Structure of transplanted liver' },
  { code: '2395005�', display: 'Structure of transverse facial artery' },
  { code: '4861000�', display: 'Structure of tuberculum impar' },
  {
    code: '4651005�',
    display: 'Structure of tunica albuginea of corpus spongiosum',
  },
  {
    code: '3663005�',
    display: 'Structure of tympanic ostium of Eustachian tube',
  },
  {
    code: '5225005�',
    display: 'Structure of uterine ostium of fallopian tube',
  },
  { code: '4810005�', display: 'Structure of uterine venous plexus' },
  { code: '4905007�', display: 'Structure of uterovaginal plexus' },
  { code: '4093007�', display: 'Structure of vagus nerve bronchial branch' },
  { code: '3478008�', display: 'Structure of vastus medialis muscle' },
  {
    code: '3295003�',
    display: 'Structure of ventral anterior nucleus of thalamus',
  },
  {
    code: '4775000�',
    display: 'Structure of ventral posterior inferior nucleus',
  },
  { code: '4743003�', display: 'Structure of ventral sacrococcygeal ligament' },
  {
    code: '1030007�',
    display: 'Structure of vesicular bursa of sternohyoid muscle',
  },
  { code: '3315000�', display: 'Structure of vestibulospinal tract' },
  { code: '5023006�', display: 'Structure of yellow bone marrow' },
  { code: '6104005�', display: 'Subclavian nerve' },
  { code: '9454009�', display: 'Subclavian vein' },
  { code: '8039003�', display: 'Subcutaneous acromial bursa' },
  { code: '1076006�', display: 'Subcutaneous prepatellar bursa' },
  {
    code: '5751008�',
    display: 'Subcutaneous tissue of anterior portion of neck',
  },
  { code: '9568000�', display: 'Subcutaneous tissue of areola' },
  { code: '5520004�', display: 'Subcutaneous tissue of chin' },
  { code: '7050002�', display: 'Subcutaneous tissue of external genitalia' },
  { code: '5976004�', display: 'Subcutaneous tissue of eyelid' },
  {
    code: '8714001�',
    display: 'Subcutaneous tissue of interdigital space of hand',
  },
  { code: '6991009�', display: 'Subcutaneous tissue of jaw' },
  {
    code: '5872003�',
    display: 'Subcutaneous tissue of lateral border of sole of foot',
  },
  {
    code: '9662007�',
    display: 'Subcutaneous tissue of lower margin of nasal septum',
  },
  {
    code: '116007�',
    display: 'Subcutaneous tissue of medial surface of index finger',
  },
  {
    code: '6905005�',
    display: 'Subcutaneous tissue of medial surface of third toe',
  },
  { code: '608002�', display: 'Subcutaneous tissue of philtrum' },
  { code: '6269008�', display: 'Subcutaneous tissue of scalp' },
  { code: '7840002�', display: 'Subcutaneous tissue of submental area' },
  { code: '5682004�', display: 'Subcutaneous tissue of upper extremity' },
  { code: '7597009�', display: 'Subcutaneous tissue of vertex' },
  { code: '4956003�', display: 'Subcutaneous tissue structure of back' },
  { code: '3977005�', display: 'Subcutaneous tissue structure of concha' },
  {
    code: '3876002�',
    display: 'Subcutaneous tissue structure of femoral region',
  },
  {
    code: '3910004�',
    display: 'Subcutaneous tissue structure of lateral surface of fourth toe',
  },
  {
    code: '3766008�',
    display: 'Subcutaneous tissue structure of lower extremity',
  },
  {
    code: '3215002�',
    display: 'Subcutaneous tissue structure of nuchal region',
  },
  {
    code: '2453002�',
    display: 'Subcutaneous tissue structure of posterior surface of forearm',
  },
  { code: '4840007�', display: 'Subcutaneous tissue structure of ring finger' },
  {
    code: '3490005�',
    display: 'Subcutaneous tissue structure of suboccipital region',
  },
  {
    code: '2923000�',
    display: 'Subcutaneous tissue structure of supraorbital area',
  },
  { code: '3039001�', display: 'Subcutaneous tissue structure of thigh' },
  { code: '7712004�', display: 'Subdural space of spinal region' },
  { code: '5713008�', display: 'Submandibular triangle' },
  { code: '7892006�', display: 'Submucosa of anal canal' },
  { code: '9758008�', display: 'Submucosa of tonsil' },
  { code: '3394002�', display: 'Suboccipitobregmatic diameter of head' },
  { code: '2255006�', display: 'Subserosa' },
  { code: '5825003�', display: 'Substantia propria of sclera' },
  { code: '7658005�', display: 'Subtendinous bursa of triceps brachii muscle' },
  { code: '9320007�', display: 'Subtendinous iliac bursa' },
  { code: '8845005�', display: 'Subtendinous prepatellar bursa' },
  { code: '6253001�', display: 'Sulcus terminalis cordis' },
  { code: '8067007�', display: 'Superficial circumflex iliac vein' },
  { code: '8040001�', display: 'Superficial flexor tendon of little finger' },
  { code: '9783008�', display: 'Superficial lymphatics of thorax' },
  { code: '5498001�', display: 'Superior aberrant ductule of epididymis' },
  { code: '8133004�', display: 'Superior anastomotic vein' },
  {
    code: '8165001�',
    display: 'Superior articular process of fifth thoracic vertebra',
  },
  { code: '272005�', display: 'Superior articular process of lumbar vertebra' },
  {
    code: '5847003�',
    display: 'Superior articular process of seventh cervical vertebra',
  },
  {
    code: '650002�',
    display: 'Superior articular process of seventh thoracic vertebra',
  },
  {
    code: '9994000�',
    display: 'Superior articular process of sixth thoracic vertebra',
  },
  { code: '8159001�', display: 'Superior colliculus of corpora quadrigemina' },
  { code: '5382009�', display: 'Superior fascia of perineum' },
  { code: '5204005�', display: 'Superior ileocecal recess' },
  { code: '235004�', display: 'Superior labial artery' },
  { code: '917004�', display: 'Superior laryngeal aperture' },
  { code: '9086005�', display: 'Superior laryngeal vein' },
  { code: '1092001�', display: 'Superior occipital gyrus' },
  { code: '5893000�', display: 'Superior recess of tympanic membrane' },
  { code: '5815008�', display: 'Superior rectus muscle' },
  { code: '8629005�', display: 'Superior right pulmonary vein' },
  { code: '2020000�', display: 'Superior thoracic artery' },
  { code: '9646001�', display: 'Superior transverse scapular ligament' },
  { code: '2954001�', display: 'Supernumerary deciduous tooth' },
  { code: '7090004�', display: 'Supraaortic branches' },
  { code: '8128003�', display: 'Supraaortic valve area' },
  { code: '167005�', display: 'Supraclavicular part of brachial plexus' },
  { code: '1630006�', display: 'Supraorbital vein' },
  { code: '1918003�', display: 'Suprarenal aorta' },
  { code: '6423006�', display: 'Supraspinatus muscle' },
  { code: '7783003�', display: 'Sympathetic trunk spinal nerve branch' },
  { code: '1584001�', display: 'Symphysis' },
  { code: '5347008�', display: 'Synaptic specialization, cytoplasmic' },
  { code: '1216008�', display: 'Synostosis' },
  { code: '3584008�', display: 'T10 dorsal arch' },
  { code: '9321006�', display: 'Tail of epididymis' },
  { code: '1110006�', display: 'Tarsal gland' },
  { code: '485005�', display: 'TC - Transverse colon' },
  { code: '1732005�', display: 'TD - Thoracic duct' },
  { code: '3301002�', display: 'Tectopontine fibers' },
  { code: '5538001�', display: 'Tegmental portion of pons' },
  { code: '1311000�', display: 'Telogen hair' },
  { code: '5717009�', display: 'Temporal fossa' },
  { code: '8775007�', display: 'Tendinous arch' },
  { code: '6787005�', display: 'Tendon and tendon sheath of hand' },
  { code: '5718004�', display: 'Tendon and tendon sheath of leg and ankle' },
  { code: '9261005�', display: 'Tendon and tendon sheath of second toe' },
  { code: '9743000�', display: 'Tendon of index finger' },
  { code: '5580002�', display: 'Tendon of supraspinatus muscle' },
  { code: '5942008�', display: 'Tensor tympani muscle' },
  { code: '7610001�', display: 'Tenth thoracic vertebra' },
  { code: '1193009�', display: 'Teres major muscle' },
  { code: '7629007�', display: 'Thalamoolivary tract' },
  { code: '3444002�', display: 'Thalamostriate vein' },
  { code: '8815007�', display: 'Thenar and hypothenar spaces' },
  { code: '1626008�', display: 'Third thoracic vertebra' },
  { code: '263002�', display: 'Thoracic nerve' },
  { code: '6989001�', display: 'Thoracolumbar region of back' },
  { code: '1099005�', display: 'Thymic cortex' },
  { code: '1706004�', display: 'Thymic lobule' },
  { code: '9875009�', display: 'Thymus' },
  { code: '6538005�', display: 'Thyrocervical trunk' },
  { code: '921006�', display: 'Thyrohyoid branch of ansa cervicalis' },
  { code: '9880000�', display: 'Thyroid tubercle' },
  { code: '660006�', display: 'Tracheal mucous membrane' },
  { code: '4419000�', display: 'Tracheal submucosa' },
  { code: '3028004�', display: 'Transitional epithelial cell' },
  { code: '10047002�', display: 'Transplanted lung' },
  { code: '8623006�', display: 'Transplanted ureter' },
  { code: '9891007�', display: 'Transverse arytenoid muscle' },
  { code: '9127001�', display: 'Transverse folds of palate' },
  { code: '1655000�', display: 'Transverse peduncular tract nucleus' },
  { code: '8942007�', display: 'Trapezoid ligament' },
  { code: '7154008�', display: 'Trigeminal ganglion sensory root' },
  { code: '446003�', display: 'Trochanteric bursa' },
  { code: '4606005�', display: 'Tubercle of eighth rib structure' },
  { code: '5881009�', display: 'Tuberosity of distal phalanx of hand' },
  { code: '635006�', display: 'Tuberosity of distal phalanx of little toe' },
  { code: '8264007�', display: 'Tunica interna of eyeball' },
  { code: '8361002�', display: 'Tunica intima' },
  { code: '2436009�', display: 'Tunica intima of vein' },
  { code: '4205002�', display: 'T�rk cell' },
  { code: '2246008�', display: 'Type I hair cell' },
  { code: '2400006�', display: 'Universal designation 21' },
  { code: '4335006�', display: 'Upper jaw region' },
  { code: '2150006�', display: 'Urinary tract transitional epithelial cell' },
  { code: '5394000�', display: 'Uterine paracervical lymph node' },
  { code: '6649009�', display: 'Uterine subserosa' },
  { code: '2044003�', display: 'Vaginal nerves' },
  { code: '7532001�', display: 'Vagus nerve parasympathetic fibers' },
  {
    code: '552004�',
    display: 'Vagus nerve parasympathetic fibers to cardiac plexus',
  },
  { code: '7524009�', display: 'Vasa vasorum' },
  { code: '1403006�', display: 'Vastus lateralis muscle' },
  { code: '8157004�', display: 'Vein of head' },
  { code: '2302002�', display: 'Vein of the knee' },
  { code: '9108007�', display: 'Vein of the scala tympani' },
  { code: '1707008�', display: 'Ventral nuclear group of thalamus' },
  { code: '7091000�', display: 'Ventral posterolateral nucleus of thalamus' },
  { code: '10026001�', display: 'Ventral spinocerebellar tract of pons' },
  { code: '3572006�', display: 'Vertebral body' },
  { code: '1277008�', display: 'Vertebral nerve' },
  { code: '5943003�', display: 'Vestibular vein' },
  { code: '110001�', display: "Visceral layer of Bowman's capsule" },
  { code: '6871001�', display: 'Visceral pericardium' },
  { code: '790007�', display: 'Visceral surface of liver' },
  { code: '393006�', display: 'Wall of urinary bladder' },
  { code: '3008003�', display: 'White fibrocartilage' },
  { code: '9073001�', display: 'White matter of insula' },
  { code: '8205005�', display: 'Wrist' },
  { code: '1456008�', display: 'Yellow fibrocartilage' },
  { code: '8617001�', display: 'Zygomatic region of face' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const chiefComplaintsCode = [
  { code: '109006�', display: 'Anxiety disorder of childhood OR adolescence' },
  { code: '4135001�', display: '11p partial monosomy syndrome' },
  { code: '4325000�', display: '11q partial monosomy syndrome' },
  { code: '548004�', display: '13p partial trisomy syndrome' },
  { code: '4199009�', display: '18p partial trisomy syndrome' },
  { code: '4242009�', display: '18q partial monosomy syndrome' },
  { code: '1479009�', display: '20q partial trisomy syndrome' },
  { code: '554003�', display: '2p partial trisomy syndrome' },
  { code: '150003�', display: 'Abnormal bladder continence' },
  { code: '437009�', display: 'Abnormal composition of urine' },
  {
    code: '655007�',
    display: 'Abnormal fetal heart beat noted before labor in liveborn infant',
  },
  { code: '4481007�', display: 'Abnormal gastric secretion regulation' },
  { code: '1284000�', display: 'Abnormal jaw closure' },
  { code: '3720003�', display: 'Abnormal presence of hemoglobin' },
  { code: '1939005�', display: 'Abnormal vascular flow' },
  {
    code: '1639007�',
    display:
      'Abnormality of organs AND/OR soft tissues of pelvis affecting pregnancy',
  },
  { code: '341009�', display: 'ABO incompatibility reaction' },
  { code: '3885002�', display: 'ABO isoimmunization in pregnancy' },
  {
    code: '2825006�',
    display: 'Abrasion and/or friction burn of gum without infection',
  },
  {
    code: '714002�',
    display: 'Abrasion AND/OR friction burn of toe with infection',
  },
  { code: '3633001�', display: 'Abscess of hand' },
  { code: '736004�', display: 'Abscess of hip' },
  { code: '3507004�', display: 'Abscess of thigh' },
  { code: '349006�', display: 'Absent tendon reflex' },
  { code: '3677008�', display: 'Academic problem' },
  { code: '2496007�', display: 'Acalculia' },
  { code: '4313003�', display: 'Acardiacus anceps' },
  { code: '1694004�', display: 'Accessory lobe of lung' },
  { code: '1856001�', display: 'Accessory nose' },
  { code: '165002�', display: 'Accident-prone' },
  { code: '4063001�', display: 'Achillodynia' },
  { code: '2391001�', display: 'Achondrogenesis' },
  {
    code: '257006�',
    display: 'Acne rosacea, erythematous telangiectatic type',
  },
  { code: '2204004�', display: 'Acquired deformity of pinna' },
  { code: '2036003�', display: 'Acquired factor VII deficiency disease' },
  { code: '2463005�', display: 'Acquired heterochromia of iris' },
  { code: '4088009�', display: 'Acquired hydrocephalus' },
  { code: '4152002�', display: 'Acquired hypoprothrombinemia' },
  { code: '3327002�', display: 'Acquired jerk nystagmus' },
  {
    code: '685002�',
    display: 'Acquired telangiectasia of small AND/OR large intestines',
  },
  { code: '4340003�', display: 'Acrodermatitis chronica atrophicans' },
  { code: '3163006�', display: 'Acute adenoviral follicular conjunctivitis' },
  { code: '718004�', display: 'Acute bronchiolitis with obstruction' },
  { code: '2589008�', display: 'Acute dacryoadenitis' },
  { code: '2704003�', display: 'Acute disease' },
  { code: '1493002�', display: 'Acute endophthalmitis' },
  { code: '222008�', display: 'Acute epiglottitis with obstruction' },
  { code: '2390000�', display: 'Acute gonococcal vulvovaginitis' },
  { code: '2367005�', display: 'Acute hemorrhagic gastritis' },
  { code: '4399003�', display: 'Acute hemorrhagic pancreatitis' },
  { code: '364006�', display: 'Acute left-sided heart failure' },
  { code: '3059002�', display: 'Acute lymphangitis of thigh' },
  { code: '3038009�', display: 'Acute lymphangitis of umbilicus' },
  {
    code: '282006�',
    display: 'Acute myocardial infarction of basal-lateral wall',
  },
  { code: '297009�', display: 'Acute myringitis' },
  { code: '3533007�', display: 'Acute palmoplantar pustular psoriasis' },
  {
    code: '3023008�',
    display:
      'Acute peptic ulcer without hemorrhage, without perforation AND without obstruction',
  },
  { code: '2554006�', display: 'Acute purulent pericarditis' },
  {
    code: '3999002�',
    display: 'Acute pyelitis without renal medullary necrosis',
  },
  { code: '360002�', display: 'Acute radiation disease' },
  { code: '486006�', display: 'Acute vascular insufficiency' },
  { code: '701003�', display: 'Adult osteochondritis of spine' },
  {
    code: '1380006�',
    display:
      'Agoraphobia without history of panic disorder with limited symptom attacks',
  },
  { code: '3978000�', display: 'AIHA - Warm autoimmune hemolytic anemia' },
  { code: '219006�', display: 'Alcohol user' },
  { code: '281004�', display: 'Alcoholic dementia' },
  { code: '2043009�', display: 'Alcoholic gastritis' },
  { code: '1824008�', display: 'Allergic gastritis' },
  { code: '4448006�', display: 'Allergic headache' },
  { code: '1168007�', display: 'Allotype' },
  { code: '4003003�', display: 'Alphavirus disease' },
  { code: '2901004�', display: 'Altered blood passed per rectum' },
  { code: '3006004�', display: 'Altered consciousness' },
  { code: '3298001�', display: 'Amnestic syndrome' },
  { code: '1897008�', display: 'Amsinckia species poisoning' },
  { code: '2145000�', display: 'Anal intercourse' },
  { code: '504009�', display: 'Androgen-dependent hirsutism' },
  { code: '862001�', display: 'Anemia due to chlorate' },
  { code: '3827008�', display: 'Aneurysm of artery of neck' },
  { code: '2978006�', display: 'Aneurysm of conjunctiva' },
  { code: '2107001�', display: 'Anisomelia' },
  { code: '3289004�', display: 'Anisometropia' },
  { code: '813001�', display: 'Ankle instability' },
  { code: '2893009�', display: 'Anomaly of chromosome pair 10' },
  { code: '975000�', display: 'Anorectal agenesis' },
  {
    code: '4069002�',
    display: 'Anoxic brain damage during AND/OR resulting from a procedure',
  },
  { code: '1412008�', display: 'Anterior subcapsular polar cataract' },
  { code: '2935001�', display: 'Antiasthmatic poisoning' },
  { code: '3355003�', display: 'Anti-common cold drug poisoning' },
  { code: '3110003�', display: 'AOM - Acute otitis media' },
  { code: '1023001�', display: 'Apneic' },
  { code: '303002�', display: 'Apoplectic pancreatitis' },
  { code: '179005�', display: 'Apraxia of dressing' },
  { code: '3282008�', display: 'Arc eye' },
  { code: '4129001�', display: 'Argyll-Robertson pupil' },
  { code: '4338008�', display: 'Arnold nerve reflex cough syndrome' },
  { code: '4113009�', display: 'Arrested hydrocephalus' },
  { code: '2929001�', display: 'Arterial occlusion' },
  { code: '3750006�', display: 'Arteriospasm' },
  { code: '1679003�', display: 'Arthritis associated with another disorder' },
  { code: '2435008�', display: 'Ascaridiasis' },
  { code: '2562003�', display: 'Athanasia trifurcata poisoning' },
  { code: '2951009�', display: 'Atopic cataract' },
  { code: '3308008�', display: 'Atrophic-hyperplastic gastritis' },
  { code: '3387000�', display: 'Auditory discrimination aphasia' },
  { code: '2724004�', display: 'Auditory recruitment' },
  { code: '3014005�', display: 'Autoeczematization' },
  { code: '183005�', display: 'Autoimmune pancytopenia' },
  { code: '4301008�', display: 'Autoimmune state' },
  { code: '2065009�', display: 'Autosomal dominant optic atrophy' },
  { code: '1899006�', display: 'Autosomal hereditary disorder' },
  { code: '4439001�', display: 'Axenfeld-Schurenberg syndrome' },
  { code: '899001�', display: 'Axis I diagnosis' },
  { code: '3939004�', display: 'Bacterial colony density, transparent' },
  { code: '3275009�', display: 'Behcet syndrome, vascular type' },
  { code: '1201005�', display: 'Benign essential hypertension' },
  { code: '193003�', display: 'Benign hypertensive renal disease' },
  { code: '2058001�', display: 'Bilateral loss of labyrinthine reactivity' },
  {
    code: '3762005�',
    display: 'Bilateral recurrent femoral hernia with gangrene',
  },
  { code: '3560000�', display: 'Bilateral recurrent inguinal hernia' },
  { code: '1761006�', display: 'Biliary cirrhosis' },
  { code: '1027000�', display: 'Biliary esophagitis' },
  {
    code: '3530005�',
    display: 'Bipolar 1 disorder, single manic episode, full remission',
  },
  {
    code: '1499003�',
    display: 'Bipolar I disorder, single manic episode with postpartum onset',
  },
  { code: '3950001�', display: 'Birth' },
  { code: '4470009�', display: 'Blanching of skin' },
  { code: '264008�', display: 'Blind hypertensive eye' },
  { code: '3585009�', display: 'Blinking' },
  { code: '1521007�', display: 'Blister of buttock without infection' },
  { code: '3841004�', display: 'Blister of cheek with infection' },
  { code: '1525003�', display: 'Blister of foot without infection' },
  { code: '452002�', display: 'Blister of groin without infection' },
  { code: '2606006�', display: 'Boil of perineum' },
  {
    code: '3033000�',
    display:
      'Bone AND/OR joint disorder of pelvis in mother complicating pregnancy, childbirth AND/OR puerperium',
  },
  { code: '2521009�', display: 'Bone conduction better than air' },
  { code: '3548001�', display: 'Brachial plexus disorder' },
  { code: '2470005�', display: 'Brain damage' },
  { code: '2994004�', display: 'Brain fag' },
  {
    code: '3119002�',
    display:
      'Brain stem laceration with open intracranial wound AND loss of consciousness',
  },
  { code: '3304005�', display: 'Bronchial compression' },
  { code: '4120002�', display: 'Bronchiolitis' },
  { code: '4386001�', display: 'Bronchospasm' },
  { code: '4434006�', display: 'BS - Bloom syndrome' },
  { code: '1989002�', display: 'Burn of vagina AND/OR uterus' },
  { code: '3480002�', display: 'Burn of wrist' },
  { code: '2070002�', display: 'Burning sensation in eye' },
  { code: '2836004�', display: 'Butane causing toxic effect' },
  { code: '3398004�', display: 'Cadmium poisoning' },
  { code: '1356007�', display: 'Calculus of common duct with obstruction' },
  { code: '2094002�', display: 'Carbon disulfide causing toxic effect' },
  { code: '1197005�', display: 'Carbuncle of heel' },
  { code: '1156003�', display: 'Cavitary prostatitis' },
  { code: '2385003�', display: 'Cellulitis of pectoral region' },
  { code: '987007�', display: 'Cellulitis of temple region' },
  {
    code: '1538006�',
    display:
      'Central nervous system malformation in foetus affecting obstetrical care',
  },
  { code: '3401001�', display: 'Cercopithecus herpesvirus 1 disease' },
  { code: '2584003�', display: 'Cerebral degeneration in childhood' },
  { code: '2032001�', display: 'Cerebral edema' },
  { code: '1670004�', display: 'Cerebral hemiparesis' },
  { code: '2432006�', display: 'Cerebrospinal fluid circulation disorder' },
  { code: '320003�', display: 'Cervical dilatation, 1cm' },
  { code: '3502005�', display: 'Cervical lymphadenitis' },
  { code: '781000�', display: 'Cestrum diurnum poisoning' },
  { code: '998008�', display: "Chagas' disease with heart involvement" },
  { code: '834008�', display: 'Chair-seated facing coital position' },
  { code: '1482004�', display: 'Chalazion' },
  { code: '875001�', display: 'Chalcosis of eye' },
  { code: '4183003�', display: 'Charcot-Marie-Tooth disease, type IC' },
  { code: '1821000�', display: 'Chemoreceptor apnea' },
  { code: '2229005�', display: 'Chimera' },
  { code: '4287008�', display: 'Chordee of penis' },
  { code: '903008�', display: 'Chorioretinal infarction' },
  { code: '122003�', display: 'Choroidal hemorrhage' },
  { code: '2532009�', display: 'Choroidal rupture' },
  { code: '1116000�', display: 'Chronic aggressive type B viral hepatitis' },
  {
    code: '1196001�',
    display:
      'Chronic bipolar II disorder, most recent episode major depressive',
  },
  {
    code: '1567007�',
    display:
      'Chronic gastric ulcer without hemorrhage, without perforation AND without obstruction',
  },
  { code: '2807004�', display: 'Chronic gastrojejunal ulcer with perforation' },
  {
    code: '4269005�',
    display:
      'Chronic gastrojejunal ulcer without hemorrhage AND without perforation',
  },
  { code: '978003�', display: 'Chronic infantile eczema' },
  { code: '4390004�', display: 'Chronic lithium nephrotoxicity' },
  { code: '3483000�', display: 'Chronic peptic ulcer with perforation' },
  { code: '4264000�', display: 'Chronic pericoronitis' },
  { code: '140004�', display: 'Chronic pharyngitis' },
  {
    code: '1961000�',
    display: 'Chronic polyarticular juvenile rheumatoid arthritis',
  },
  { code: '3456001�', display: 'Chronic progressive non-hereditary chorea' },
  { code: '2618002�', display: 'Chronic recurrent major depressive disorder' },
  { code: '756003�', display: 'Chronic rheumatic myopericarditis' },
  { code: '2955000�', display: 'Chronic ulcerative pulpitis' },
  { code: '4232007�', display: 'Chronic vulvitis' },
  { code: '1141007�', display: 'Circumoral paresthesia' },
  { code: '3140001�', display: 'Citrullinemia, subacute type' },
  { code: '792004�', display: 'CJD - Creutzfeldt-Jakob disease' },
  { code: '1556006�', display: 'Clark melanoma level 4' },
  { code: '2581006�', display: 'Clasp knife rigidity' },
  { code: '2640006�', display: 'Clinical stage 4' },
  { code: '786005�', display: 'Clinical stage I B' },
  { code: '1523005�', display: 'Clinical stage IV B' },
  { code: '3019000�', display: 'Closed anterior dislocation of elbow' },
  { code: '1658003�', display: 'Closed fracture clavicle, lateral end' },
  { code: '1705000�', display: 'Closed fracture of base of neck of femur' },
  { code: '3228009�', display: 'Closed fracture of the radial shaft' },
  { code: '3291007�', display: 'Closed fracture of two ribs' },
  { code: '2295008�', display: 'Closed fracture of upper end of forearm' },
  { code: '357009�', display: 'Closed fracture trapezoid' },
  {
    code: '4208000�',
    display: 'Closed multiple fractures of both lower limbs',
  },
  { code: '2713001�', display: 'Closed pneumothorax' },
  { code: '4273008�', display: 'Closed posterior dislocation of elbow' },
  { code: '2651006�', display: 'Closed traumatic dislocation of elbow joint' },
  {
    code: '496002�',
    display: 'Closed traumatic dislocation of third cervical vertebra',
  },
  { code: '3903005�', display: 'Closed traumatic pneumothorax' },
  { code: '4373005�', display: 'Clubbing of nail' },
  { code: '4306003�', display: 'Cluster B personality disorder' },
  { code: '447007�', display: 'Coach in sports activity accident' },
  { code: '3909009�', display: 'Coeur en sabot' },
  { code: '1045000�', display: 'Colonospasm' },
  {
    code: '4409006�',
    display:
      'Combined methylmalonic acidemia and homocystinuria due to defects in adenosylcobalamin and methylcobalamin synthesis',
  },
  { code: '2186007�', display: 'Compensated metabolic alkalosis' },
  { code: '4103001�', display: 'Complex partial seizure' },
  { code: '2560006�', display: 'Complex syndactyly of fingers' },
  {
    code: '2989004�',
    display: 'Complication following molar AND/OR ectopic pregnancy',
  },
  { code: '4461006�', display: 'Complication of administrative procedure' },
  { code: '4224004�', display: 'Complication of infusion' },
  { code: '1308001�', display: 'Complication of reimplant' },
  { code: '1395008�', display: 'Complication of ultrasound therapy' },
  { code: '2541004�', display: 'Compulsive buying' },
  { code: '2061000�', display: 'Conductive hearing loss of combined sites' },
  { code: '1139006�', display: 'Confrontation (visual) test' },
  { code: '1287007�', display: 'Congenital absence of bile duct' },
  { code: '1967001�', display: 'Congenital absence of forearm only' },
  { code: '3650004�', display: 'Congenital absence of liver,total' },
  { code: '841002�', display: 'Congenital absence of skull bone' },
  { code: '3987009�', display: 'Congenital absence of trachea' },
  { code: '1922008�', display: 'Congenital absence of urethra' },
  { code: '2534005�', display: 'Congenital absence of vena cava' },
  { code: '703000�', display: 'Congenital adhesion of tongue' },
  {
    code: '590005�',
    display: 'Congenital aneurysm of anterior communicating artery',
  },
  { code: '1492007�', display: 'Congenital anomaly of large intestine' },
  { code: '4041005�', display: 'Congenital anomaly of macula' },
  { code: '4406004�', display: 'Congenital anomaly of male genital system' },
  {
    code: '4160001�',
    display: 'Congenital anomaly of upper respiratory system',
  },
  { code: '2213002�', display: 'Congenital anomaly of vena cava' },
  { code: '1239002�', display: 'Congenital anteversion of femoral neck' },
  { code: '1232006�', display: 'Congenital articular rigidity with myopathy' },
  { code: '520004�', display: 'Congenital bent nose' },
  { code: '2495006�', display: 'Congenital cerebral arteriovenous aneurysm' },
  { code: '1953005�', display: 'Congenital deficiency of pigment of skin' },
  { code: '2749000�', display: 'Congenital deformity of hip' },
  { code: '3004001�', display: 'Congenital dilatation of esophagus' },
  {
    code: '3733009�',
    display: 'Congenital eventration of right crus of diaphragm',
  },
  { code: '3886001�', display: 'Congenital fecaliths' },
  { code: '407000�', display: 'Congenital hepatomegaly' },
  {
    code: '3705009�',
    display: 'Congenital malformation of anterior chamber of eye',
  },
  {
    code: '4184009�',
    display: 'Congenital malformation of the endocrine glands',
  },
  { code: '1150009�', display: 'Congenital microcheilia' },
  { code: '1519002�', display: 'Congenital phlebectasia' },
  { code: '943003�', display: 'Congenital retinal aneurysm' },
  { code: '1857005�', display: 'Congenital rubella syndrome' },
  { code: '1089000�', display: 'Congenital sepsis' },
  { code: '2828008�', display: 'Congenital stenosis of nares' },
  { code: '1512006�', display: 'Congenital stricture of bile duct' },
  { code: '192008�', display: 'Congenital syphilitic hepatomegaly' },
  { code: '1131009�', display: 'Congenital valvular insufficiency' },
  { code: '2818004�', display: 'Congenital vascular anomaly of eye' },
  { code: '4275001�', display: 'Conjugate gaze spasm' },
  { code: '1332008�', display: 'Conjugated visual deviation' },
  { code: '539009�', display: 'Conjunctival argyrosis' },
  { code: '1744008�', display: 'Connation of teeth' },
  { code: '842009�', display: 'Consanguinity' },
  { code: '1151008�', display: 'Constricted visual field' },
  { code: '586008�', display: 'Contact dermatitis due to primrose' },
  { code: '871005�', display: 'Contracted pelvis' },
  { code: '3751005�', display: 'Contusion of labium' },
  { code: '3649004�', display: 'Contusion, multiple sites of trunk' },
  { code: '2665008�', display: 'Coordinate convulsion' },
  { code: '3815005�', display: 'Crohn disease of rectum' },
  { code: '2591000�', display: 'Crush injury of shoulder region' },
  { code: '2518007�', display: 'Cryptogenic sexual precocity' },
  { code: '3415004�', display: 'Cyanosis' },
  { code: '758002�', display: 'Cyst of uterus' },
  { code: '2912004�', display: 'Cystic-bullous disease of the lung' },
  { code: '443006�', display: 'Cystocele affecting pregnancy' },
  { code: '2634004�', display: 'Decreased blood erythrocyte volume' },
  { code: '134006�', display: 'Decreased hair growth' },
  { code: '2149006�', display: 'Decreased hormone production' },
  { code: '199004�', display: 'Decreased lactation' },
  { code: '2902006�', display: 'Decreased lymphocyte life span' },
  { code: '2761008�', display: 'Decreased stool caliber' },
  { code: '607007�', display: 'Decreased vital capacity' },
  { code: '2102007�', display: 'Deep corneal vascularization' },
  { code: '890002�', display: 'Deep third degree burn of elbow' },
  { code: '4300009�', display: 'Deep third degree burn of forearm' },
  {
    code: '1203008�',
    display:
      'Deep third degree burn of forehead AND/OR cheek with loss of body part',
  },
  { code: '3975002�', display: 'Deep third degree burn of lower limb' },
  {
    code: '2582004�',
    display: 'Deep third degree burn of multiple sites of lower limb',
  },
  { code: '3461004�', display: 'Deep third degree burn of thumb' },
  { code: '3712000�', display: 'Degenerated eye' },
  { code: '1112003�', display: 'Degenerative disorder of eyelid' },
  { code: '4046000�', display: 'Degenerative spondylolisthesis' },
  { code: '313005�', display: 'D�j� vu' },
  { code: '664002�', display: 'Delayed ovulation' },
  { code: '216004�', display: 'Delusion of persecution' },
  { code: '2073000�', display: 'Delusions' },
  { code: '3611003�', display: 'Demeton poisoning' },
  { code: '2403008�', display: 'Dependence syndrome' },
  { code: '1855002�', display: 'Developmental academic disorder' },
  { code: '3545003�', display: 'Diastolic dysfunction' },
  { code: '555002�', display: 'Dicentra species poisoning' },
  { code: '1384002�', display: 'Diffuse cholesteatosis of middle ear' },
  {
    code: '3704008�',
    display: 'Diffuse endocapillary proliferative glomerulonephritis',
  },
  { code: '2134003�', display: 'Diffuse pain' },
  { code: '4412009�', display: 'Digital nerve injury' },
  {
    code: '2024009�',
    display: 'Dilated cardiomyopathy secondary to metazoal myocarditis',
  },
  {
    code: '2816000�',
    display: 'Dilated cardiomyopathy secondary to myotonic dystrophy',
  },
  {
    code: '1954004�',
    display: 'Dilated cardiomyopathy secondary to toxic reaction',
  },
  { code: '2219003�', display: 'Disability evaluation, disability 100%' },
  { code: '962000�', display: 'Disability evaluation, disability 6%' },
  { code: '4197006�', display: 'Disability evaluation, impairment, class 5' },
  { code: '3089009�', display: 'Disability evaluation, impairment, class 7' },
  { code: '1038000�', display: 'Disacchariduria' },
  { code: '2910007�', display: 'Discharge from penis' },
  { code: '3303004�', display: 'Disease caused by Arenavirus' },
  {
    code: '1194003�',
    display: 'Disease condition determination, well controlled',
  },
  { code: '198007�', display: 'Disease due to Filoviridae' },
  { code: '1020003�', display: 'Disease due to Nairovirus' },
  { code: '2556008�', display: 'Disease of supporting structures of teeth' },
  { code: '3219008�', display: 'Disease type AND/OR category unknown' },
  { code: '788006�', display: 'Disease-related diet' },
  { code: '2128005�', display: 'Disorder of adenoid' },
  { code: '3305006�', display: 'Disorder of lymphatic vessel' },
  { code: '2492009�', display: 'Disorder of nutrition' },
  { code: '3855007�', display: 'Disorder of pancreas' },
  { code: '3441005�', display: 'Disorder of sebaceous gland' },
  { code: '3519007�', display: 'Disorder of synovium' },
  {
    code: '2689001�',
    display: 'Dominant dystrophic epidermolysis bullosa with absence of skin',
  },
  { code: '1145003�', display: 'DSD - Developmental speech disorder' },
  { code: '1343000�', display: 'DTA - Deep transverse arrest' },
  { code: '2593002�', display: 'Dubowitz syndrome' },
  { code: '3368006�', display: 'Dull chest pain' },
  { code: '4195003�', display: 'Duplication of anus' },
  { code: '3845008�', display: 'Duplication of intestine' },
  { code: '3253007�', display: 'Dyschromia' },
  { code: '3754002�', display: 'Dysplasia of vagina' },
  { code: '4359001�', display: 'Early congenital syphilis' },
  { code: '1667003�', display: 'Early fontanel closure' },
  {
    code: '1107004�',
    display:
      'Early latent syphilis, positive serology, negative cerebrospinal fluid, with relapse after treatment',
  },
  { code: '2506003�', display: 'Early onset dysthymia' },
  { code: '3747008�', display: 'EC - Ejection click' },
  { code: '1896004�', display: 'Ectopic breast tissue' },
  { code: '2129002�', display: 'Edema of pharynx' },
  { code: '292003�', display: 'EEG finding' },
  {
    code: '4170004�',
    display: 'Ehlers-Danlos syndrome, procollagen proteinase resistant',
  },
  {
    code: '1938002�',
    display:
      'Emotional AND/OR mental disease in mother complicating pregnancy, childbirth AND/OR puerperium',
  },
  { code: '4016003�', display: 'Empyema of mastoid' },
  { code: '3783004�', display: 'Enamel pearls' },
  { code: '1959009�', display: 'Encephalartos species poisoning' },
  {
    code: '172001�',
    display: 'Endometritis following molar AND/OR ectopic pregnancy',
  },
  { code: '1847009�', display: 'Endophthalmitis' },
  { code: '3590007�', display: 'Enteroenteric fistula' },
  { code: '3539006�', display: 'Enteromenia' },
  { code: '2772003�', display: 'Epidermolysis bullosa acquisita' },
  { code: '815008�', display: 'Episcleritis' },
  {
    code: '2786004�',
    display:
      'Epithelial ovarian tumor, International Federation of Gynecology and Obstetrics stage III',
  },
  { code: '2028007�', display: 'Erythema induratum' },
  { code: '961007�', display: 'Erythema nodosum, acute form' },
  { code: '857002�', display: 'Erythema simplex' },
  { code: '2121004�', display: 'Ethopropazine poisoning' },
  { code: '2041006�', display: 'Eunuchoid gigantism' },
  { code: '4403007�', display: 'Exclamation point hair' },
  { code: '3763000�', display: 'Expected bereavement due to life event' },
  { code: '1489008�', display: 'External hordeolum' },
  { code: '1070000�', display: 'Facial myokymia' },
  { code: '4320005�', display: 'Factor V deficiency' },
  {
    code: '3873005�',
    display:
      'Failed attempted termination of pregnancy with acute necrosis of liver',
  },
  {
    code: '1592005�',
    display: 'Failed attempted termination of pregnancy with uremia',
  },
  { code: '1192004�', display: 'Familial amyloid neuropathy, Finnish type' },
  {
    code: '3760002�',
    display: 'Familial multiple factor deficiency syndrome, type V',
  },
  { code: '3419005�', display: 'Faucial diphtheria' },
  { code: '3283003�', display: 'Feeling of sand or foreign body in eye' },
  { code: '1742007�', display: 'Female hypererotism' },
  { code: '1108009�', display: 'Female pattern alopecia' },
  {
    code: '2770006�',
    display:
      'Fetal or neonatal effect of antibiotic transmitted via placenta and/or breast milk',
  },
  {
    code: '1363007�',
    display: 'Fetal or neonatal effect of chronic maternal respiratory disease',
  },
  { code: '2452007�', display: 'Fetal rotation' },
  { code: '2751001�', display: 'Fibrocalculous pancreatic diabetes' },
  { code: '488007�', display: 'Fibroid myocarditis' },
  { code: '2900003�', display: 'Fibromuscular dysplasia of renal artery' },
  { code: '2583009�', display: 'Filigreed network of venous valves' },
  { code: '383004�', display: 'Finding of passive range of hip extension' },
  { code: '844005�', display: 'Finding relating to behavior' },
  { code: '3449007�', display: 'Finger agnosia' },
  { code: '1767005�', display: 'Fisher syndrome' },
  { code: '811004�', display: 'Flail motion' },
  { code: '932006�', display: 'Flat affect' },
  { code: '3274008�', display: 'Flat chest' },
  { code: '1860003�', display: 'Fluid volume disorder' },
  { code: '1979001�', display: 'Focal choroiditis' },
  { code: '2298005�', display: 'Focal facial dermal dysplasia' },
  {
    code: '888003�',
    display: 'Foetal or neonatal effect of maternal blood loss',
  },
  {
    code: '4006006�',
    display: 'Foetal tachycardia affecting management of mother',
  },
  { code: '409002�', display: 'Food allergy diet' },
  { code: '3310005�', display: 'Foreign body granuloma of skin' },
  { code: '517007�', display: 'Foreign body in hypopharynx' },
  { code: '2245007�', display: 'Foreign body in nasopharynx' },
  { code: '2012002�', display: 'Fracture of lunate' },
  {
    code: '1734006�',
    display: 'Fracture of vertebral column with spinal cord injury',
  },
  { code: '613003�', display: 'FRAXA - Fragile X syndrome' },
  { code: '1402001�', display: 'Frightened' },
  { code: '3696007�', display: 'Functional dyspepsia' },
  { code: '2170000�', display: 'Gallop rhythm' },
  { code: '4418008�', display: 'Gangrenous ergotism' },
  { code: '652005�', display: 'Gangrenous tonsillitis' },
  {
    code: '2066005�',
    display:
      'Gastric ulcer with hemorrhage AND perforation but without obstruction',
  },
  {
    code: '2783007�',
    display: 'Gastrojejunal ulcer without hemorrhage AND without perforation',
  },
  { code: '1208004�', display: 'Gastroptosis' },
  {
    code: '3503000�',
    display: 'Gender identity disorder of adolescence, previously asexual',
  },
  { code: '816009�', display: 'Genetic recombination' },
  { code: '2351004�', display: 'Genetic transduction' },
  { code: '931004�', display: 'Gestation period, 9 weeks' },
  { code: '4356008�', display: 'Gingival soft tissue recession' },
  { code: '1184008�', display: 'Glasgow coma scale, 10' },
  { code: '536002�', display: 'Glissonian cirrhosis' },
  { code: '3639002�', display: 'Glossoptosis' },
  {
    code: '1074009�',
    display: 'Glucocorticoid-responsive primary hyperaldosteronism',
  },
  { code: '3474005�', display: 'Glycine max poisoning' },
  { code: '1515008�', display: 'Gorham disease' },
  { code: '2740001�', display: 'Gouty proteinuria' },
  { code: '2615004�', display: 'Graafian follicle cyst' },
  { code: '1826005�', display: 'Granuloma of lip' },
  {
    code: '479003�',
    display:
      "Graves' disease with pretibial myxedema AND with thyrotoxic crisis",
  },
  { code: '1717003�', display: 'Guttate hypomelanosis' },
  { code: '568005�', display: 'Habit disorder' },
  { code: '3544004�', display: 'Hair-splitting' },
  { code: '1383008�', display: 'Hallucinogen induced mood disorder' },
  { code: '2176006�', display: 'Halogen acne' },
  { code: '1345007�', display: 'Hang nail' },
  { code: '4040006�', display: 'Hassall-Henle bodies' },
  { code: '1755008�', display: 'Healed coronary' },
  { code: '3424008�', display: 'Heart rate fast' },
  { code: '368009�', display: 'Heart valve disorder' },
  { code: '2733002�', display: 'Heel pain' },
  { code: '4416007�', display: 'Heerfordt syndrome' },
  { code: '2602008�', display: 'Hemarthrosis of shoulder' },
  { code: '2835000�', display: 'Hemolytic anemia due to cardiac trauma' },
  { code: '181007�', display: 'Hemorrhagic bronchopneumonia' },
  { code: '981008�', display: 'Hemorrhagic proctitis' },
  { code: '355001�', display: 'Hemorrhagic shock' },
  { code: '1475003�', display: 'Herpes labialis' },
  { code: '1588003�', display: 'Heterosexual precocious puberty' },
  { code: '366008�', display: 'Hidromeiosis' },
  { code: '3947004�', display: 'High oxygen affinity hemoglobin polycythemia' },
  { code: '3259006�', display: 'Homeria species poisoning' },
  { code: '129007�', display: 'Homoiothermia' },
  { code: '2736005�', display: 'Honeycomb atrophy of face' },
  { code: '4367009�', display: 'Hoover sign' },
  { code: '3072001�', display: 'Hormone-induced hypopituitarism' },
  { code: '2443003�', display: 'Hydrogen sulfide poisoning' },
  { code: '865004�', display: 'Hyperalimentation formula for ileus' },
  { code: '2359002�', display: 'Hyper-beta-alaninemia' },
  { code: '2638001�', display: 'Hypercalcemia caused by a drug' },
  { code: '2243000�', display: 'Hypercalcemia due to hyperthyroidism' },
  { code: '626004�', display: 'Hypercortisolism due to nonpituitary tumor' },
  { code: '3744001�', display: 'Hyperlipoproteinemia' },
  {
    code: '1051005�',
    display: 'Hyperplasia of islet alpha cells with gastrin excess',
  },
  { code: '547009�', display: 'Hypersecretion of calcitonin' },
  {
    code: '1474004�',
    display:
      'Hypertensive heart AND renal disease complicating AND/OR reason for care during childbirth',
  },
  { code: '643001�', display: 'Hypertrophy of lip' },
  { code: '431005�', display: 'Hypertrophy of scrotum' },
  { code: '1418007�', display: 'Hypoplastic chondrodystrophy' },
  { code: '1447000�', display: 'Icthyoparasitism' },
  { code: '1794009�', display: 'Idiopathic corneal edema' },
  { code: '646009�', display: 'Idiopathic cyst of anterior chamber' },
  { code: '3972004�', display: 'Idiopathic insomnia' },
  { code: '3345002�', display: 'Idiopathic osteoporosis' },
  { code: '1351002�', display: 'Iliac artery injury' },
  { code: '195005�', display: 'Illegal abortion with endometritis' },
  {
    code: '3230006�',
    display: 'Illegal termination of pregnancy with afibrinogenemia',
  },
  {
    code: '4451004�',
    display: 'Illegal termination of pregnancy with renal tubular necrosis',
  },
  { code: '2897005�', display: 'Immune thrombocytopenia' },
  {
    code: '1310004�',
    display: 'Impaired glucose tolerance associated with genetic syndrome',
  },
  {
    code: '1822007�',
    display: 'Impaired glucose tolerance associated with pancreatic disease',
  },
  { code: '1865008�', display: 'Impaired intestinal protein absorption' },
  { code: '2806008�', display: 'Impaired psychomotor development' },
  {
    code: '1909000�',
    display:
      'Impairment level: better eye: severe impairment: lesser eye: near-total impairment',
  },
  {
    code: '2790002�',
    display:
      'Impairment level: one eye: total impairment: other eye: not specified',
  },
  { code: '3570003�', display: 'Increased blood erythrocyte volume' },
  { code: '1608005�', display: 'Increased capillary permeability' },
  { code: '1703007�', display: 'Increased leg circumference' },
  { code: '1777007�', display: 'Increased molecular dissociation' },
  { code: '1881003�', display: 'Increased nutritional requirement' },
  { code: '4017007�', display: 'Increased stratum corneum adhesiveness' },
  { code: '4463009�', display: 'Indiana-Maryland type amyloid polyneuropathy' },
  { code: '3095005�', display: 'Induced malaria' },
  { code: '1593000�', display: 'Infantile hemiplegia' },
  { code: '3129009�', display: 'Infarction of ovary' },
  { code: '2622007�', display: 'Infected ulcer of skin' },
  { code: '1297003�', display: 'Infection by Cladosporium carrionii' },
  { code: '1214006�', display: 'Infection by Strongyloides' },
  { code: '3752003�', display: 'Infection by Trichuris' },
  { code: '2918000�', display: 'Infection caused by Bacteroides' },
  { code: '3641001�', display: 'Infection caused by Coenurosis serialis' },
  { code: '2158004�', display: 'Infection caused by Contracaecum' },
  { code: '1724002�', display: 'Infection caused by Crenosoma' },
  { code: '2114004�', display: 'Infection caused by Cysticercus pisiformis' },
  {
    code: '1606009�',
    display: 'Infection caused by Macracanthorhynchus hirudinaceus',
  },
  { code: '2341000�', display: 'Infection caused by Moniliformis' },
  { code: '3993001�', display: 'Infection caused by Muellerius' },
  { code: '3464007�', display: 'Infection caused by Oesophagostomum dentatum' },
  { code: '2808009�', display: 'Infection caused by Prosthenorchis elegans' },
  { code: '3529000�', display: 'Infection caused by Sanguinicola' },
  { code: '2850009�', display: 'Infection caused by Schistosoma incognitum' },
  { code: '4414005�', display: 'Infection caused by Setaria' },
  { code: '1858000�', display: 'Infection caused by Stilesia globipunctata' },
  { code: '3254001�', display: 'Infection caused by Strongyloides westeri' },
  { code: '3511005�', display: 'Infectious thyroiditis' },
  { code: '845006�', display: 'Inferior mesenteric artery injury' },
  { code: '4107000�', display: 'Infertile male syndrome' },
  { code: '3160009�', display: 'Infertility of cervical origin' },
  { code: '4175009�', display: 'Infestation by Estrus' },
  {
    code: '1828006�',
    display: 'Infestation caused by Gasterophilus hemorrhoidalis',
  },
  { code: '3908001�', display: 'Infestation caused by Haematopinus' },
  { code: '1903004�', display: 'Infestation caused by Psorergates ovis' },
  { code: '1415005�', display: 'Inflammation of lymphatics' },
  { code: '2303007�', display: 'Inguinal hernia with gangrene' },
  { code: '2438005�', display: 'Iniencephaly' },
  {
    code: '171008�',
    display:
      'Injury of ascending right colon without open wound into abdominal cavity',
  },
  {
    code: '658009�',
    display: 'Injury of colon without open wound into abdominal cavity',
  },
  {
    code: '1264004�',
    display:
      'Injury of descending left colon without open wound into abdominal cavity',
  },
  { code: '2999009�', display: 'Injury of ear region' },
  {
    code: '3913002�',
    display:
      'Injury of gastrointestinal tract with open wound into abdominal cavity',
  },
  { code: '3759007�', display: 'Injury of heart with open wound into thorax' },
  {
    code: '1317001�',
    display: 'Injury of ovary without open wound into abdominal cavity',
  },
  { code: '640003�', display: 'Injury of pneumogastric nerve' },
  {
    code: '1419004�',
    display: 'Injury of prostate without open wound into abdominal cavity',
  },
  { code: '1367008�', display: 'Injury of superior mesenteric artery' },
  { code: '3820005�', display: 'Inner ear conductive hearing loss' },
  { code: '801006�', display: 'Insect bite, nonvenomous, of foot, infected' },
  {
    code: '4124006�',
    display: 'Insect bite, nonvenomous, of vagina, infected',
  },
  { code: '1771008�', display: 'Insulin biosynthesis defect' },
  { code: '188001�', display: 'Intercostal artery injury' },
  { code: '2473007�', display: 'Intermittent vertical squint' },
  { code: '4251001�', display: 'Internal eye sign' },
  {
    code: '4026005�',
    display: 'Interstitial mastitis associated with childbirth',
  },
  { code: '2304001�', display: 'Intervertebral discitis' },
  { code: '2471009�', display: 'Intra-abdominal abscess postprocedure' },
  { code: '2775001�', display: 'Intra-articular loose body' },
  { code: '2940009�', display: 'Intrabasal vesicular dermatitis' },
  { code: '1386000�', display: 'Intracranial hemorrhage' },
  {
    code: '1430001�',
    display:
      'Intracranial hemorrhage following injury without open intracranial wound AND with prolonged loss of consciousness (more than 24 hours) without return to pre-existing level',
  },
  { code: '1102005�', display: 'Intraerythrocytic parasitosis by Nuttallia' },
  { code: '2116002�', display: 'Intramembranous bone formation' },
  { code: '3531009�', display: 'Intrapsychic conflict' },
  { code: '1427008�', display: 'Intraspinal abscess' },
  { code: '3105002�', display: 'Intron' },
  { code: '2655002�', display: 'Invalidism' },
  { code: '3214003�', display: 'Invasive pulmonary aspergillosis' },
  { code: '2296009�', display: 'Iron dextran toxicity' },
  { code: '3703002�', display: 'Ischiatic hernia with gangrene' },
  { code: '2109003�', display: 'Isolated somatotropin deficiency' },
  { code: '1280009�', display: 'Isologous chimera' },
  { code: '4294006�', display: 'Isosexual precocious puberty' },
  { code: '2764000�', display: 'Joint crackle' },
  { code: '3723001�', display: 'Joint inflammation' },
  { code: '1212005�', display: 'Juvenile dermatomyositis' },
  { code: '4477007�', display: 'Juvenile myopathy AND lactate acidosis' },
  { code: '1323006�', display: 'Kanamycin poisoning' },
  { code: '359007�', display: 'Kernicterus due to isoimmunization' },
  { code: '2538008�', display: 'Ketosis' },
  { code: '1126007�', display: 'Knee locking' },
  { code: '1271009�', display: 'Knuckle pads, leuconychia and deafness' },
  {
    code: '3542000�',
    display:
      'Laceration extending into parenchyma of spleen with open wound into abdominal cavity',
  },
  { code: '1282001�', display: 'Laryngeal perichondritis' },
  {
    code: '827006�',
    display:
      'Late congenital syphilis, latent (+ sero., - C.S.F., 2 years OR more)',
  },
  { code: '2366001�', display: 'Late effect of dislocation' },
  {
    code: '2053005�',
    display:
      'Late effect of injury to blood vessels of thorax, abdomen AND/OR pelvis',
  },
  {
    code: '1988005�',
    display:
      'Late effect of injury to nerve roots, spinal plexus AND/OR other nerves of trunk',
  },
  {
    code: '3859001�',
    display: 'Late effect of open wound of extremities without tendon injury',
  },
  { code: '3640000�', display: 'Late effect of traumatic amputation' },
  { code: '3577000�', display: 'Lattice retinal degeneration' },
  { code: '4030008�', display: 'Le Dantec virus disease' },
  {
    code: '398002�',
    display: 'Left axis deviation greater than -90 degrees by EKG',
  },
  { code: '1943009�', display: 'Left-right confusion' },
  { code: '382009�', display: 'Legal history finding relating to child' },
  {
    code: '3634007�',
    display: 'Legal termination of pregnancy complicated by metabolic disorder',
  },
  { code: '1393001�', display: 'Lenz-Majewski dysplasia' },
  { code: '3660008�', display: 'Lethal glossopharyngeal defect' },
  { code: '1361009�', display: 'Leucocoria' },
  { code: '3323003�', display: 'Leukoplakia of penis' },
  { code: '3358001�', display: 'Lichen ruber moniliformis' },
  { code: '4241002�', display: 'Listeria infection' },
  { code: '4009004�', display: 'Lower urinary tract infection' },
  { code: '2138000�', display: 'LSP - Left sacroposterior position' },
  { code: '228007�', display: 'Lucio phenomenon' },
  { code: '4062006�', display: 'Lumbosacral plexus lesion' },
  { code: '2415007�', display: 'Lumbosacral root lesion' },
  { code: '1240000�', display: 'Lying prone' },
  { code: '3239007�', display: 'Lymphocyte disorder' },
  { code: '2990008�', display: 'Lymphocytic leukemoid reaction' },
  { code: '1648002�', display: 'Lymphocytic pseudotumor of lung' },
  { code: '3644009�', display: 'Macerated skin' },
  { code: '4136000�', display: 'Macrodactylia of toes' },
  { code: '2853006�', display: 'Macular keratitis' },
  { code: '1079004�', display: 'Macular retinal cyst' },
  { code: '2819007�', display: 'Magnesium sulfate poisoning' },
  { code: '2904007�', display: 'Male infertility' },
  { code: '2396006�', display: 'Malignant pyoderma' },
  { code: '1778002�', display: 'Malocclusion due to abnormal swallowing' },
  { code: '2326000�', display: 'Marriage annulment' },
  {
    code: '2132004�',
    display: 'Meconium in amniotic fluid noted before labor in liveborn infant',
  },
  { code: '1674008�', display: "Meesman's epithelial corneal dystrophy" },
  {
    code: '3571004�',
    display: 'Megaloblastic anemia due to pancreatic insufficiency',
  },
  { code: '387003�', display: 'Melanuria' },
  { code: '290006�', display: 'Melnick-Fraser syndrome' },
  { code: '151004�', display: 'Meningitis due to gonococcus' },
  { code: '4089001�', display: 'Meningococcemia' },
  { code: '3914008�', display: 'Mental disorder in childhood' },
  { code: '3558002�', display: 'Mesenteric infarction' },
  { code: '1034003�', display: 'Mesenteric-portal fistula' },
  {
    code: '3591006�',
    display: 'Metabolic acidosis, normal anion gap, bicarbonate losses',
  },
  { code: '1388004�', display: 'Metabolic alkalosis' },
  { code: '1829003�', display: 'Microcephalus' },
  { code: '4473006�', display: 'Migraine with aura' },
  { code: '3261002�', display: 'Migratory osteolysis' },
  { code: '4283007�', display: 'Mirizzi syndrome' },
  {
    code: '2781009�',
    display: 'Miscarriage complicated by delayed and/or excessive hemorrhage',
  },
  { code: '1469007�', display: 'Miscarriage with urinary tract infection' },
  { code: '3900008�', display: 'Mixed sensory-motor polyneuropathy' },
  { code: '832007�', display: 'Moderate major depression' },
  { code: '3680009�', display: 'Monocephalus tripus dibrachius' },
  { code: '2374000�', display: 'Monofascicular block' },
  { code: '2256007�', display: 'Monovular twins' },
  { code: '4004009�', display: 'Monster with cranial anomalies' },
  { code: '3185000�', display: 'Mood-congruent delusion' },
  { code: '378007�', display: 'Morquio syndrome' },
  { code: '241006�', display: 'Motor simple partial status' },
  { code: '1134001�', display: 'Muehrcke lines' },
  { code: '518002�', display: 'Multiple aggregation' },
  { code: '1261007�', display: 'Multiple fractures of ribs' },
  { code: '4478002�', display: 'Multiple fractures of upper AND lower limbs' },
  { code: '4039009�', display: 'Multiple vitamin deficiency disease' },
  { code: '4142001�', display: 'Muscular asthenopia' },
  { code: '928000�', display: 'Musculoskeletal disorder' },
  { code: '3218000�', display: 'Mycotic disease' },
  { code: '2694001�', display: 'Myelophthisic anemia' },
  {
    code: '1155004�',
    display: 'Myocardial hypertrophy, determined by electrocardiogram',
  },
  { code: '4316006�', display: 'Myometritis' },
  {
    code: '3458000�',
    display: 'Myositis ossificans associated with dermato / polymyositis',
  },
  { code: '3434004�', display: 'Myotonia' },
  { code: '4038001�', display: 'Myrotheciotoxicosis' },
  { code: '4225003�', display: 'Nasal tuberculosis' },
  { code: '2919008�', display: 'Nausea, vomiting and diarrhea' },
  { code: '1835003�', display: 'Necrosis of pancreas' },
  { code: '2707005�', display: 'Necrotizing enterocolitis' },
  { code: '1426004�', display: 'Necrotizing glomerulonephritis' },
  { code: '3391005�', display: 'Negative for tumor cells' },
  { code: '3468005�', display: 'Neonatal infective mastitis' },
  { code: '2231001�', display: 'Nerve plexus disorder' },
  { code: '208008�', display: 'Neurocutaneous melanosis sequence' },
  { code: '2040007�', display: 'Neurogenic thoracic outlet syndrome' },
  { code: '3899003�', display: 'Neutropenic typhlitis' },
  { code: '2965006�', display: 'Nevoid congenital alopecia' },
  { code: '1531000�', display: 'Nitrofuran derivative poisoning' },
  { code: '1230003�', display: 'No diagnosis on Axis 1' },
  { code: '2216005�', display: 'Nocturnal emission' },
  { code: '1539003�', display: 'Nodular tendinous disease of finger' },
  { code: '3902000�', display: 'Non dose-related drug-induced neutropenia' },
  {
    code: '4092002�',
    display: 'Nonallopathic lesion of costovertebral region',
  },
  { code: '3505007�', display: 'Nonallopathic lesion of the arm' },
  { code: '2967003�', display: 'Non-comitant strabismus' },
  { code: '3427001�', display: 'Nonglucosuric melituria' },
  { code: '242004�', display: 'Noninfectious jejunitis' },
  {
    code: '2526004�',
    display: 'Noninflammatory disorder of the female genital organs',
  },
  { code: '715001�', display: 'Nontraumatic rupture of urethra' },
  {
    code: '2683000�',
    display: 'Nonvenomous insect bite of axilla without infection',
  },
  {
    code: '3032005�',
    display: 'Nonvenomous insect bite of cheek without infection',
  },
  {
    code: '3084004�',
    display: 'Nonvenomous insect bite of gum without infection',
  },
  { code: '3021005�', display: 'Normal gastric acidity' },
  { code: '369001�', display: 'Normal jugular venous pressure' },
  { code: '4181001�', display: 'Normal peak expiratory flow rate' },
  { code: '144008�', display: 'Normal peripheral vision' },
  { code: '1111005�', display: 'Normal sebaceous gland activity' },
  { code: '1965009�', display: 'Normal skin pH' },
  { code: '2388001�', display: 'Normal variation in translucency' },
  { code: '2004005�', display: 'Normotensive' },
  { code: '563001�', display: 'Nystagmus' },
  { code: '1478001�', display: 'Obliteration of lymphatic vessel' },
  { code: '1376001�', display: 'Obsessive compulsive personality disorder' },
  { code: '2261009�', display: 'Obstetrical pulmonary fat embolism' },
  {
    code: '615005�',
    display:
      'Obstruction due to foreign body accidentally left in operative wound AND/OR body cavity during a procedure',
  },
  { code: '1739001�', display: 'Occipital fracture' },
  { code: '330007�', display: 'Occipital headache' },
  { code: '2972007�', display: 'Occlusion of anterior spinal artery' },
  { code: '3135009�', display: 'OE - Otitis externa' },
  { code: '1779005�', display: 'OFD II - Orofacial-digital syndrome II' },
  { code: '4210003�', display: 'OH - Ocular hypertension' },
  { code: '467003�', display: 'Old laceration of pelvic floor muscle' },
  { code: '1542009�', display: 'Omphalocele with obstruction' },
  { code: '2469009�', display: 'Onychomalacia' },
  { code: '3421000�', display: 'Open blow-out fracture orbit' },
  { code: '1708003�', display: 'Open dislocation of clavicle' },
  { code: '1544005�', display: 'Open dislocation of knee' },
  { code: '4354006�', display: 'Open dislocation of scapula' },
  { code: '3217005�', display: 'Open dislocation of sixth cervical vertebra' },
  { code: '3381004�', display: 'Open fracture of astragalus' },
  { code: '649002�', display: 'Open fracture of distal end of ulna' },
  { code: '1370007�', display: 'Open fracture of metacarpal bone(s)' },
  {
    code: '4349002�',
    display: 'Open fracture of multiple sites of metacarpus',
  },
  { code: '3331008�', display: 'Open fracture of neck of metacarpal bone' },
  {
    code: '3446000�',
    display: 'Open fracture of T7-T12 level with spinal cord injury',
  },
  {
    code: '2840008�',
    display:
      'Open fracture of vault of skull with cerebral laceration AND/OR contusion',
  },
  {
    code: '2202000�',
    display: 'Open posterior dislocation of distal end of femur',
  },
  { code: '3013004�', display: 'Open wound of ear drum without complication' },
  { code: '2630008�', display: 'Open wound of finger without complication' },
  { code: '2419001�', display: 'Open wound of forehead with complication' },
  { code: '775008�', display: 'Open wound of head with complication' },
  { code: '2136001�', display: 'Open wound of jaw with complication' },
  { code: '3500002�', display: 'Open wound of ossicles with complication' },
  { code: '3208005�', display: 'Open wound of ossicles without complication' },
  { code: '964004�', display: 'Open wound of pharynx without complication' },
  {
    code: '3736001�',
    display: 'Open wound of thumbnail with tendon involvement',
  },
  { code: '1031006�', display: 'Open wound of trachea without complication' },
  { code: '1059007�', display: 'Opisthorchiasis' },
  { code: '734001�', display: 'Opocephalus' },
  { code: '4468000�', display: 'Oppenheim gait' },
  { code: '1207009�', display: 'Optic disc glaucomatous atrophy' },
  { code: '1486001�', display: 'Orbital congestion' },
  { code: '1837006�', display: 'Orciprenaline poisoning' },
  { code: '2776000�', display: 'Organic brain syndrome' },
  { code: '2946003�', display: 'Osmotic diarrhea' },
  { code: '1551001�', display: 'Osteomyelitis of femur' },
  { code: '1926006�', display: 'Osteopetrosis' },
  { code: '3657001�', display: 'Osteospermum species poisoning' },
  { code: '2657005�', display: 'Overflow proteinuria' },
  { code: '3652007�', display: 'Overproduction of growth hormone' },
  { code: '1977004�', display: 'Oxymetholone poisoning' },
  { code: '276008�', display: 'Oxytocin poisoning' },
  { code: '2089002�', display: 'Pagets disease of bone' },
  { code: '2237002�', display: 'Painful breathing -pleurodynia' },
  { code: '4070001�', display: 'Palinphrasia' },
  { code: '2663001�', display: 'Palpatory proteinuria' },
  { code: '1534008�', display: 'Palsy of conjugate gaze' },
  { code: '3549009�', display: 'Pancreatic acinar atrophy' },
  {
    code: '3158007�',
    display:
      'Panic disorder with agoraphobia, agoraphobic avoidance in partial remission AND panic attacks in partial remission',
  },
  {
    code: '1816003�',
    display:
      'Panic disorder with agoraphobia, severe agoraphobic avoidance AND mild panic attacks',
  },
  { code: '299007�', display: 'Paraffinoma of skin' },
  { code: '1845001�', display: 'Paraparesis' },
  { code: '4223005�', display: 'Parkinsonism caused by drug' },
  { code: '776009�', display: 'Partial arterial retinal occlusion' },
  { code: '4397001�', display: 'Partial congenital duodenal obstruction' },
  {
    code: '4178006�',
    display: 'Partial recent retinal detachment with multiple defects',
  },
  {
    code: '3598000�',
    display: 'Partial recent retinal detachment with single defect',
  },
  { code: '3469002�', display: 'Partial thickness burn of thumb' },
  { code: '2472002�', display: 'Passes no urine' },
  {
    code: '3286006�',
    display: 'Patient status determination, greatly improved',
  },
  { code: '308006�', display: 'Pearly penile papules' },
  { code: '4075006�', display: 'Peganum harmala poisoning' },
  {
    code: '2973002�',
    display: 'Pelvic organ injury without open wound into abdominal cavity',
  },
  {
    code: '3018008�',
    display: 'Penetration of eyeball with magnetic foreign body',
  },
  { code: '310008�', display: 'Penile boil' },
  { code: '3514002�', display: 'Peribronchial fibrosis of lung' },
  { code: '3238004�', display: 'Pericarditis' },
  { code: '3797007�', display: 'Periodontal cyst' },
  { code: '2307008�', display: 'Peripancreatic fat necrosis' },
  { code: '984000�', display: 'Perirectal cellulitis' },
  { code: '440009�', display: 'Persistent hyperphenylalaninemia' },
  { code: '599006�', display: 'Persistent pneumothorax' },
  { code: '1335005�', display: 'Peyronies disease' },
  { code: '1833005�', display: 'Phacoanaphylactic endophthalmitis' },
  { code: '2091005�', display: 'Pharyngeal diverticulitis' },
  {
    code: '3393008�',
    display: 'Phlebitis following infusion, perfusion AND/OR transfusion',
  },
  { code: '4262001�', display: 'Phlebitis of superior sagittal sinus' },
  { code: '2177002�', display: 'PHN - Post-herpetic neuralgia' },
  { code: '2805007�', display: 'Phosmet poisoning' },
  { code: '1714005�', display: 'Photokeratitis' },
  { code: '4229009�', display: 'Phthisical eye' },
  { code: '2838003�', display: 'Piblokto' },
  { code: '2992000�', display: 'Pigmentary pallidal degeneration' },
  { code: '904002�', display: "Pinard's sign" },
  { code: '2437000�', display: 'Placenta circumvallata' },
  { code: '324007�', display: 'Plaster ulcer' },
  { code: '2585002�', display: 'Pneumococcal pleurisy' },
  { code: '805002�', display: 'Pneumoconiosis due to silica' },
  { code: '1963002�', display: 'PNH - Paroxysmal nocturnal hemoglobinuria' },
  {
    code: '666000�',
    display: 'Poisoning by antivaricose drug AND/OR sclerosing agent',
  },
  { code: '177007�', display: 'Poisoning by sawfly larvae' },
  { code: '1848004�', display: 'Poisoning caused by gaseous anesthetic' },
  { code: '3053001�', display: 'Poisoning caused by nitroglycerin' },
  { code: '843004�', display: 'Poliomyelomalacia' },
  { code: '2418009�', display: 'Polyester fume causing toxic effect' },
  { code: '1488000�', display: 'PONV - Postoperative nausea and vomiting' },
  { code: '4249000�', display: 'Poor peripheral circulation' },
  { code: '755004�', display: 'Postgastrectomy phytobezoar' },
  { code: '3482005�', display: 'Postoperative esophagitis' },
  { code: '1124005�', display: 'Postpartum period, 6 days' },
  { code: '2858002�', display: 'Postpartum sepsis' },
  {
    code: '1318006�',
    display: 'Post-translational genetic protein processing',
  },
  { code: '1955003�', display: 'Preauricular pit' },
  {
    code: '4307007�',
    display:
      'Pregestational diabetes mellitus AND/OR impaired glucose tolerance, modified White class F',
  },
  {
    code: '3798002�',
    display: 'Premature birth of identical twins, both stillborn',
  },
  { code: '596004�', display: 'Premenstrual dysphoric disorder' },
  { code: '2624008�', display: 'Prepubertal periodontitis' },
  { code: '2725003�', display: 'Previous abnormality of glucose tolerance' },
  { code: '2239004�', display: 'Previous pregnancies 6' },
  { code: '1647007�', display: 'Primaquine poisoning' },
  { code: '2251002�', display: 'Primary hypotony of eye' },
  { code: '3951002�', display: 'Proctitis' },
  { code: '4127004�', display: 'Prostatic obstruction' },
  { code: '1563006�', display: 'Protein S deficiency' },
  { code: '3755001�', display: 'PRP - Pityriasis rubra pilaris' },
  { code: '919001�', display: 'Pseudohomosexual state' },
  { code: '258001�', display: 'Pseudoknuckle pad' },
  { code: '3376008�', display: 'Pseudoptyalism' },
  { code: '3586005�', display: 'Psychogenic fugue' },
  {
    code: '2301009�',
    display:
      'Psychosomatic factor in physical condition, psychological component of unknown degree',
  },
  { code: '3487004�', display: 'Pulmonary candidiasis' },
  {
    code: '663008�',
    display: 'Pulmonary embolism following molar AND/OR ectopic pregnancy',
  },
  { code: '2087000�', display: 'Pulmonary nocardiosis' },
  { code: '2981001�', display: 'Pulsatile mass of abdomen' },
  { code: '2284002�', display: 'Pulsating exophthalmos' },
  { code: '2439002�', display: 'Purulent endocarditis' },
  { code: '2831009�', display: 'Pyloric antral vascular ectasia' },
  { code: '335002�', display: 'Pylorospasm' },
  { code: '600009�', display: 'Pyromania' },
  { code: '2398007�', display: 'Quinidine toxicity by electrocardiogram' },
  { code: '1685005�', display: 'Rat-bite fever' },
  {
    code: '2312009�',
    display:
      'Reactive attachment disorder of infancy OR early childhood, inhibited type',
  },
  { code: '317006�', display: 'Reactive hypoglycemia' },
  { code: '2055003�', display: 'Recurrent erosion syndrome' },
  {
    code: '2103002�',
    display: 'Reflex sympathetic dystrophy of upper extremity',
  },
  { code: '1003002�', display: 'Religious discrimination' },
  { code: '3321001�', display: 'Renal abscess' },
  { code: '954008�', display: 'Renon-Delille syndrome' },
  { code: '3528008�', display: 'Restricted carbohydrate fat controlled diet' },
  { code: '3426005�', display: 'Retained magnetic intraocular foreign body' },
  { code: '2167004�', display: 'Retinal hemangioblastomatosis' },
  { code: '4410001�', display: 'Retroperitoneal hernia with obstruction' },
  { code: '787001�', display: 'Rheumatic mitral stenosis with regurgitation' },
  { code: '4464003�', display: 'Rocio virus disease' },
  { code: '3662000�', display: 'Rolling hiatus hernia' },
  { code: '651003�', display: 'Root work' },
  { code: '602001�', display: 'Ross river fever' },
  { code: '4106009�', display: 'Rotator cuff rupture' },
  { code: '4308002�', display: 'RSIS - Repetitive strain injury syndrome' },
  { code: '1776003�', display: 'RTA - Renal tubular acidosis' },
  { code: '2355008�', display: 'Rud syndrome' },
  { code: '4240001�', display: 'Rupture of aorta' },
  { code: '1869002�', display: 'Rupture of iris sphincter' },
  { code: '3073006�', display: 'Ruvalcaba syndrome' },
  {
    code: '4260009�',
    display: 'Sacral spinal cord injury without bone injury',
  },
  { code: '3200003�', display: 'Sacrocoxalgia' },
  { code: '634005�', display: 'Saddle boil' },
  { code: '1671000�', display: 'Sago spleen' },
  { code: '2523007�', display: 'Salmonella pneumonia' },
  { code: '4191007�', display: 'Scaphoid head' },
  { code: '1259003�', display: 'Schistosis' },
  { code: '750009�', display: 'Schistosoma mansoni infection' },
  { code: '2228002�', display: 'Scintillating scotoma' },
  { code: '3109008�', display: 'Secondary dysthymia early onset' },
  { code: '442001�', display: 'Secondary hypopituitarism' },
  { code: '385006�', display: 'Secondary peripheral neuropathy' },
  {
    code: '1686006�',
    display: 'Sedative, hypnotic AND/OR anxiolytic-induced anxiety disorder',
  },
  {
    code: '1973000�',
    display:
      'Sedative, hypnotic AND/OR anxiolytic-induced psychotic disorder with delusions',
  },
  { code: '2224000�', display: 'Selenium poisoning' },
  { code: '279001�', display: 'Senile myocarditis' },
  { code: '616006�', display: 'Sensorimotor disorder of eyelid' },
  { code: '2625009�', display: 'Senter syndrome' },
  { code: '1077002�', display: 'Septal infarction by EKG' },
  { code: '1125006�', display: 'Septicemia during labor' },
  { code: '1980003�', display: 'Seromucinous otitis media' },
  {
    code: '4441000�',
    display: 'Severe bipolar disorder with psychotic features',
  },
  {
    code: '3439009�',
    display:
      'Severe combined immunodeficiency (SCID) due to absent peripheral T cell maturation',
  },
  {
    code: '162004�',
    display: 'Severe manic bipolar I disorder without psychotic features',
  },
  { code: '2339001�', display: 'Sexual overexposure' },
  { code: '2815001�', display: 'Sexual pyromania' },
  { code: '799008�', display: 'Sigmoid colon ulcer' },
  { code: '2308003�', display: 'Silent alleles' },
  { code: '2365002�', display: 'Simple chronic pharyngitis' },
  { code: '1152001�', display: 'Skin reaction negative' },
  { code: '3745000�', display: 'Sleep rhythm problem' },
  { code: '358004�', display: 'Smallpox vaccine poisoning' },
  { code: '3009006�', display: 'Solanum malacoxylon poisoning' },
  { code: '1387009�', display: 'Solanum nigrum poisoning' },
  { code: '1135000�', display: 'Solar retinitis' },
  { code: '610000�', display: 'Spastic aphonia' },
  { code: '4465002�', display: 'Spherophakia' },
  { code: '2884008�', display: 'Spherophakia-brachymorphia syndrome' },
  { code: '1518005�', display: 'Splenitis' },
  { code: '3472009�', display: 'Spondylolisthesis, grade 4' },
  {
    code: '127009�',
    display: 'Spontaneous abortion with laceration of cervix',
  },
  {
    code: '527001�',
    display: "Spontaneous fetal evolution, Roederer's method",
  },
  { code: '3199001�', display: 'Sprain of shoulder joint' },
  { code: '460001�', display: 'Squamous metaplasia of prostate gland' },
  { code: '3144005�', display: 'Staphylococcal pleurisy' },
  { code: '1892002�', display: 'Star figure at the macula' },
  { code: '3756000�', display: 'Static ataxia' },
  { code: '1055001�', display: 'Stenosis of precerebral artery' },
  { code: '3506008�', display: 'Stenosis of retinal artery' },
  { code: '1654001�', display: 'Steroid-induced glaucoma' },
  { code: '3642008�', display: 'Steryl-sulfate sulfohydrolase deficiency' },
  { code: '3272007�', display: 'Stomatocytosis' },
  { code: '1763009�', display: 'Stromal keratitis' },
  { code: '4364002�', display: 'Structure of associations' },
  { code: '4324001�', display: 'Subacute cystitis' },
  {
    code: '4332009�',
    display:
      'Subarachnoid hemorrhage following injury without open intracranial wound AND with concussion',
  },
  { code: '3830001�', display: 'Subcutaneous emphysema' },
  {
    code: '4278004�',
    display:
      'Superficial foreign body of axilla without major open wound but with infection',
  },
  {
    code: '733007�',
    display:
      'Superficial foreign body of groin without major open wound but with infection',
  },
  {
    code: '147001�',
    display:
      'Superficial foreign body of scrotum without major open wound but with infection',
  },
  { code: '426008�', display: 'Superficial injury of ankle without infection' },
  { code: '825003�', display: 'Superficial injury of axilla with infection' },
  {
    code: '908004�',
    display: 'Superficial injury of interscapular region without infection',
  },
  { code: '3097002�', display: 'Superficial injury of lip with infection' },
  {
    code: '2217001�',
    display: 'Superficial injury of perineum without infection',
  },
  { code: '2477008�', display: 'Superficial phlebitis' },
  { code: '1378000�', display: 'Supination-eversion injury of ankle' },
  { code: '4237001�', display: 'Suppurative pulpitis' },
  { code: '175004�', display: 'Supraorbital neuralgia' },
  { code: '253005�', display: 'Sycosis' },
  {
    code: '4248008�',
    display: 'Synovitis AND/OR tenosynovitis associated with another disease',
  },
  { code: '4082005�', display: 'Syphilitic myocarditis' },
  { code: '3589003�', display: 'Syphilitic pericarditis' },
  { code: '4483005�', display: 'Syphilitic punched out ulcer' },
  { code: '4445009�', display: 'TB - Urogenital tuberculosis' },
  { code: '2782002�', display: 'Temporomandibular dysplasia' },
  {
    code: '4426000�',
    display: 'Ten previous induced terminations of pregnancy',
  },
  { code: '3277001�', display: 'Terminal mood insomnia' },
  { code: '934007�', display: 'Thalassemia intermedia' },
  { code: '874002�', display: 'Therapeutic diuresis' },
  { code: '1140008�', display: 'Thermal hypesthesia' },
  {
    code: '818005�',
    display: 'Third degree burn of multiple sites of lower limb',
  },
  {
    code: '2420007�',
    display: 'Third degree burn of multiple sites of upper limb',
  },
  { code: '4310000�', display: 'Third degree burn of wrist AND/OR hand' },
  { code: '1033009�', display: 'Thoracic arthritis' },
  { code: '2169001�', display: 'Thoracic radiculitis' },
  { code: '1769008�', display: 'Thoracodidymus' },
  { code: '2899008�', display: 'Thought blocking' },
  { code: '1735007�', display: 'Thrill' },
  { code: '1748006�', display: 'Thrombophlebitis of deep femoral vein' },
  {
    code: '3168002�',
    display: 'Thrombophlebitis of intracranial venous sinus',
  },
  { code: '3681008�', display: 'Thrombophlebitis of torcular Herophili' },
  {
    code: '682004�',
    display: 'Thrombosis complicating pregnancy AND/OR puerperium',
  },
  { code: '3716002�', display: 'Thyroid goiter' },
  { code: '3002002�', display: 'Thyroid hemorrhage' },
  { code: '4168008�', display: 'Tibial plateau chondromalacia' },
  { code: '2513003�', display: 'Tinea capitis caused by Trichophyton' },
  { code: '3276005�', display: 'Toad poisoning' },
  { code: '408005�', display: 'Tooth chattering' },
  { code: '675003�', display: 'Torsion of intestine' },
  { code: '849000�', display: 'Total cataract' },
  { code: '965003�', display: 'Toxic amblyopia' },
  { code: '1657008�', display: 'Toxic effect of phosdrin' },
  { code: '3344003�', display: 'Toxic labyrinthitis' },
  { code: '3229001�', display: 'Tracheal ulcer' },
  { code: '2576002�', display: 'Trachoma' },
  {
    code: '631002�',
    display: 'Transfusion reaction due to minor incompatibility',
  },
  { code: '2917005�', display: 'Transient hypothyroidism' },
  { code: '1682008�', display: 'Transitory amino acid metabolic disorder' },
  { code: '3699000�', display: 'Transverse deficiency of arm' },
  { code: '1852004�', display: 'Traumatic injury of sixth cranial nerve' },
  { code: '4174008�', display: 'Tripartite placenta' },
  { code: '1663004�', display: 'Tumor grade G2' },
  { code: '223003�', display: 'Tumor of body of uterus affecting pregnancy' },
  { code: '4374004�', display: 'TV - Congenital tricuspid valve abnormality' },
  { code: '168000�', display: 'Typhlolithiasis' },
  { code: '2829000�', display: 'Uhl disease' },
  { code: '1698001�', display: 'Ulcer of bile duct' },
  { code: '450005�', display: 'Ulcerative stomatitis' },
  { code: '1372004�', display: 'Unicornate uterus' },
  { code: '2314005�', display: 'Unprotected intercourse' },
  {
    code: '490008�',
    display: 'Upper respiratory tract hypersensitivity reaction',
  },
  { code: '1046004�', display: 'Ureteritis glandularis' },
  { code: '1723008�', display: 'Urethral stricture due to schistosomiasis' },
  { code: '1981004�', display: 'Urhidrosis' },
  { code: '4244005�', display: 'Urticaria neonatorum' },
  { code: '3098007�', display: 'Ventricular septal rupture' },
  { code: '4381006�', display: 'Verbal paraphasia' },
  { code: '3263004�', display: 'Verumontanitis' },
  { code: '3738000�', display: 'VH - Viral hepatitis' },
  { code: '2268003�', display: 'Victim of homosexual aggression' },
  { code: '1532007�', display: 'Viral pharyngitis' },
  { code: '2528003�', display: 'Viremia' },
  { code: '2198002�', display: 'Visceral epilepsy' },
  {
    code: '1283006�',
    display:
      'Visual acuity less than .02 (1/60, count fingers 1 meter) or visual field less than 5 degrees, but better than 5.',
  },
  { code: '2856003�', display: 'Vitamin A-responsive dermatosis' },
  { code: '1286003�', display: 'Vitamin K deficiency coagulation disorder' },
  { code: '3044008�', display: 'Vitreous prolapse' },
  { code: '470004�', display: 'Vitreous touch syndrome' },
  { code: '3094009�', display: 'Vomiting in infants AND/OR children' },
  { code: '1908008�', display: 'von Willebrand disease, type IIC' },
  { code: '1085006�', display: 'Vulval candidiasis' },
  { code: '4022007�', display: 'Vulvitis circumscripta plasmacellularis' },
  { code: '3067005�', display: 'Weak C phenotype' },
  { code: '2120003�', display: 'Weak cry' },
  { code: '991002�', display: 'Wide QRS complex' },
  { code: '3071008�', display: 'Widow' },
  { code: '184004�', display: 'Withdrawal arrhythmia' },
  { code: '1073003�', display: 'Xeroderma pigmentosum group B' },
  {
    code: '2241003�',
    display: 'X-linked absence of thyroxine-binding globulin',
  },
  {
    code: '3944006�',
    display: 'X-linked placental steryl-sulfatase deficiency',
  },
  { code: '3928002�', display: 'Zika virus disease' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const familyHistoryConditionCodes = [
  {
    code: 'No Family History',
    display: 'No Family History',
  },
  {
    code: 'High BP',
    display: 'High BP',
  },
  {
    code: 'Type 2 Diabetes',
    display: 'Type 2 Diabetes',
  },
  {
    code: 'Ischaemic Heart Disease',
    display: 'Ischaemic Heart Disease',
  },
  {
    code: 'Ischaemic Stroke',
    display: 'Ischaemic Stroke',
  },
  {
    code: 'Amphetamine Use Disorders',
    display: 'Amphetamine Use Disorders',
  },
  {
    code: 'Breast Cancer',
    display: 'Breast Cancer',
  },
  {
    code: 'Chronic Ascariasis',
    display: 'Chronic Ascariasis',
  },
  {
    code: 'Chronic Kidney Disease Due to Diabetes',
    display: 'Chronic Kidney Disease Due to Diabetes',
  },
  {
    code: 'Chronic Meningitis',
    display: 'Chronic Meningitis',
  },
  {
    code: 'Chronic Multiple Myeloma',
    display: 'Chronic Multiple Myeloma',
  },
  {
    code: 'Chronic Obstructive Pulmonary Disease (COPD)',
    display: 'Chronic Obstructive Pulmonary Disease (COPD)',
  },
  {
    code: 'Chronic Pancreatitis',
    display: 'Chronic Pancreatitis',
  },
  {
    code: 'Chronic Skin Diseases',
    display: 'Chronic Skin Diseases',
  },
  {
    code: 'Colon & Rectum Cancers',
    display: 'Colon & Rectum Cancers',
  },
  {
    code: 'Congenital Heart Anomalies',
    display: 'Congenital Heart Anomalies',
  },
  {
    code: "Crohn's Disease",
    display: "Crohn's Disease",
  },
  {
    code: 'Down Syndrome',
    display: 'Down Syndrome',
  },
  {
    code: 'Epilepsy',
    display: 'Epilepsy',
  },
  {
    code: 'Haemorrhagic Stroke',
    display: 'Haemorrhagic Stroke',
  },
  {
    code: 'Hodgkin Lymphoma',
    display: 'Hodgkin Lymphoma',
  },
  {
    code: 'Hypertensive Heart Disease',
    display: 'Hypertensive Heart Disease',
  },
  {
    code: 'Iron-Deficiency Anaemia',
    display: 'Iron-Deficiency Anaemia',
  },
  {
    code: 'Kidney Cancer',
    display: 'Kidney Cancer',
  },
  {
    code: 'Leishmaniasis',
    display: 'Leishmaniasis',
  },
  {
    code: 'Leukaemia',
    display: 'Leukaemia',
  },
  {
    code: 'Liver Cancer',
    display: 'Liver Cancer',
  },
  {
    code: 'Major Depressive Disorder',
    display: 'Major Depressive Disorder',
  },
  {
    code: 'Malignant Skin Melanoma',
    display: 'Malignant Skin Melanoma',
  },
  {
    code: 'Mesothelioma',
    display: 'Mesothelioma',
  },
  {
    code: 'Multiple Sclerosis',
    display: 'Multiple Sclerosis',
  },
  {
    code: 'Nasopharynx',
    display: 'Nasopharynx',
  },
  {
    code: 'Non-Melanoma Skin Cancer',
    display: 'Non-Melanoma Skin Cancer',
  },
  {
    code: 'Pancreas Cancer',
    display: 'Pancreas Cancer',
  },
  {
    code: 'Parkinson Disease',
    display: 'Parkinson Disease',
  },
  {
    code: 'Prostate Cancer',
    display: 'Prostate Cancer',
  },
  {
    code: 'Rheumatic Heart Disease',
    display: 'Rheumatic Heart Disease',
  },
  {
    code: 'Rheumatoid Arthritis',
    display: 'Rheumatoid Arthritis',
  },
  {
    code: 'Sickle Cell Disorders & Trait',
    display: 'Sickle Cell Disorders & Trait',
  },
  {
    code: 'Stomach Cancer',
    display: 'Stomach Cancer',
  },
  {
    code: 'Syphilis',
    display: 'Syphilis',
  },
  {
    code: 'Testicular Cancer',
    display: 'Testicular Cancer',
  },
  {
    code: 'Thalassaemias',
    display: 'Thalassaemias',
  },
  {
    code: 'Thyroid Cancer',
    display: 'Thyroid Cancer',
  },
  {
    code: 'Tuberculosis',
    display: 'Tuberculosis',
  },
  {
    code: 'Urolithiasis',
    display: 'Urolithiasis',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const allergyOptions = [
  { code: '409137002�', display: 'No known history of drug allergy' },
  { code: '91929009�', display: 'Anti-infectives allergy' },
  { code: '294483001�', display: '4-quinolones allergy' },
  {
    code: '293676007�',
    display: '5-Hydroxytryptamine-3-receptor antagonist allergy',
  },
  { code: '294250006�', display: 'A series prostaglandin allergy' },
  { code: '293964005�', display: 'Acebutolol allergy' },
  { code: '293611008�', display: 'Acemetacin allergy' },
  { code: '293584003�', display: 'Acetaminophen allergy' },
  { code: '294404005�', display: 'Acetic acid allergy' },
  { code: '294008004�', display: 'Acetylcholine chloride allergy' },
  { code: '294097003�', display: 'Acetylcysteine allergy' },
  { code: '294384008�', display: 'Aciclovir allergy' },
  { code: '294209003�', display: 'Acitretin allergy' },
  { code: '293764000�', display: 'Aclarubicin allergy' },
  { code: '60414003�', display: 'Acquired lactase deficiency' },
  { code: '52070001�', display: 'Acquired monosaccharide malabsorption' },
  { code: '294445002�', display: 'Acridine azo disinfectant dye allergy' },
  { code: '294113002�', display: 'Acrivastine allergy' },
  { code: '294484007�', display: 'Acrosoxacin allergy' },
  { code: '292545003�', display: 'Adverse reaction to oxitropium' },
  { code: '294459009�', display: 'Albendazole allergy' },
  { code: '293959006�', display: 'Alcohol metabolism modifier allergy' },
  { code: '294420000�', display: 'Alcohol products allergy' },
  { code: '294419006�', display: 'Alcoholic disinfectant allergy' },
  { code: '294227000�', display: 'Alcuronium allergy' },
  { code: '294422008�', display: 'Aldehyde disinfectant allergy' },
  { code: '293733002�', display: 'Aldesleukin allergy' },
  { code: '293604009�', display: 'Alfentanil allergy' },
  { code: '293986006�', display: 'Alfuzosin allergy' },
  { code: '294140002�', display: 'Alimemazine allergy' },
  { code: '294196009�', display: 'Alkali metal soap allergy' },
  { code: '293740001�', display: 'Alkylating drug allergy' },
  { code: '293640006�', display: 'Allergen extract vaccine allergy' },
  { code: '232347008�', display: 'Allergy to animal dander' },
  { code: '232346004�', display: 'Allergy to cat dander' },
  { code: '91930004�', display: 'Allergy to eggs' },
  { code: '91932007�', display: 'Allergy to fruit' },
  { code: '294162002�', display: 'Allergy to inhaled corticosteroids' },
  { code: '293580007�', display: 'Allergy to over-the-counter drug' },
  { code: '91935009�', display: 'Allergy to peanuts' },
  { code: '91938006�', display: 'Allergy to strawberries' },
  { code: '91940001�', display: 'Allergy to walnut' },
  { code: '294220003�', display: 'Allopurinol allergy' },
  { code: '294024003�', display: 'Alpha-adrenoceptor agonist allergy' },
  { code: '293985005�', display: 'Alpha-adrenoceptor blocking drug allergy' },
  { code: '293892004�', display: 'Alprazolam allergy' },
  { code: '294255001�', display: 'Alprostadil allergy' },
  { code: '294198005�', display: 'Aluminum astringent allergy' },
  { code: '293666009�', display: 'Aluminum hydroxide allergy' },
  { code: '293691009�', display: 'Alverine allergy' },
  { code: '293811005�', display: 'Amantadine allergy' },
  { code: '293957008�', display: 'Amfetamine group allergy' },
  { code: '294427002�', display: 'Amidine disinfectant allergy' },
  { code: '293732007�', display: 'Amifostine allergy' },
  { code: '294463002�', display: 'Amikacin allergy' },
  { code: '293787008�', display: 'Aminoglutethimide allergy' },
  { code: '294462007�', display: 'Aminoglycosides allergy' },
  { code: '294158008�', display: 'Aminophylline allergy' },
  { code: '294389003�', display: 'Aminoquinoline antimalarial allergy' },
  { code: '293829000�', display: 'Amitriptyline allergy' },
  { code: '294002003�', display: 'Amlodipine allergy' },
  { code: '293880004�', display: 'Amobarbital allergy' },
  { code: '293883002�', display: 'Amobarbital sodium allergy' },
  { code: '294390007�', display: 'Amodiaquine allergy' },
  { code: '294346002�', display: 'Amorolfine allergy' },
  { code: '293828008�', display: 'Amoxapine allergy' },
  {
    code: '294523000�',
    display: 'Amoxicillin + clavulanate potassium allergy',
  },
  { code: '294505008�', display: 'Amoxicillin allergy' },
  { code: '294349009�', display: 'Amphotericin allergy' },
  { code: '294524006�', display: 'Ampicillin + floxacillin allergy' },
  { code: '294506009�', display: 'Ampicillin allergy' },
  { code: '294522005�', display: 'Ampicillin and cloxacillin allergy' },
  { code: '293778004�', display: 'Amsacrine allergy' },
  { code: '293582004�', display: 'Analgesic allergy' },
  { code: '293703009�', display: 'Anesthetics and medical gases allergy' },
  { code: '294195008�', display: 'Anionic surfactant allergy' },
  { code: '293664007�', display: 'Antacid allergy' },
  { code: '294125004�', display: 'Antazoline allergy' },
  { code: '294450008�', display: 'Anthelmintics allergy' },
  { code: '293684006�', display: 'Anthraquinone laxative allergy' },
  { code: '294107006�', display: 'Antiallergenic drug allergy' },
  { code: '294461000�', display: 'Antibacterial drug allergy' },
  { code: '294065003�', display: 'Anticholinergic allergy' },
  {
    code: '294010002�',
    display: 'Anticholinesterase parasympathomimetic allergy',
  },
  { code: '293816000�', display: 'Antidepressant drug allergy' },
  { code: '293667000�', display: 'Antidiarrheal drug allergy' },
  { code: '294263000�', display: 'Antidote allergy' },
  { code: '294279004�', display: 'Antidotes for pesticides allergy' },
  { code: '293672009�', display: 'Antiemetic allergy' },
  { code: '293857007�', display: 'Antiepileptic allergy' },
  { code: '294340008�', display: 'Antifungal drug allergy' },
  { code: '294402009�', display: 'Anti-infective nasal preparations allergy' },
  { code: '294387001�', display: 'Antimalarial drug allergy' },
  { code: '293769005�', display: 'Antimetabolite allergy' },
  { code: '294066002�', display: 'Antimuscarinic allergy' },
  { code: '293739003�', display: 'Antineoplastic allergy' },
  { code: '294513009�', display: 'Antipseudomonal penicillins allergy' },
  { code: '293688009�', display: 'Antispasmodic allergy' },
  { code: '294367002�', display: 'Antiviral drug allergy' },
  { code: '293812003�', display: 'Apomorphine allergy' },
  { code: '294057001�', display: 'Apraclonidine allergy' },
  { code: '294317009�', display: 'Arachis oil allergy' },
  { code: '294313008�', display: 'Artificial saliva allergy' },
  { code: '294188004�', display: 'Aserbine allergy' },
  { code: '293586001�', display: 'Aspirin allergy' },
  { code: '294111000�', display: 'Astemizole allergy' },
  { code: '294197000�', display: 'Astringent allergy' },
  { code: '293965006�', display: 'Atenolol allergy' },
  { code: '294228005�', display: 'Atracurium allergy' },
  { code: '294076004�', display: 'Atropine allergy' },
  { code: '294240005�', display: 'Auranofin allergy' },
  { code: '293612001�', display: 'Azapropazone allergy' },
  { code: '294127007�', display: 'Azatadine allergy' },
  { code: '293799001�', display: 'Azathioprine allergy' },
  { code: '294180006�', display: 'Azelaic acid allergy' },
  { code: '294115009�', display: 'Azelastine allergy' },
  { code: '294472005�', display: 'Azithromycin allergy' },
  { code: '294516001�', display: 'Azlocillin allergy' },
  { code: '294352001�', display: 'Azole antifungal allergy' },
  { code: '294565006�', display: 'Aztreonam allergy' },
  { code: '294511006�', display: 'Bacampicillin allergy' },
  { code: '294234003�', display: 'Baclofen allergy' },
  { code: '294038005�', display: 'Bambuterol allergy' },
  { code: '293863003�', display: 'Barbiturate antiepileptic allergy' },
  { code: '293879002�', display: 'Barbiturate sedative allergy' },
  { code: '294322009�', display: 'Base allergy' },
  { code: '293858002�', display: 'Beclamide allergy' },
  { code: '294067006�', display: 'Belladonna alkaloids allergy' },
  { code: '294494002�', display: 'Benethamine penicillin allergy' },
  { code: '293920002�', display: 'Benperidol allergy' },
  { code: '293809001�', display: 'Benserazide + levodopa allergy' },
  { code: '294281002�', display: 'Bentonite powder allergy' },
  { code: '294438002�', display: 'Benzalkonium allergy' },
  { code: '294495001�', display: 'Benzathine penicillin allergy' },
  { code: '294078003�', display: 'Benzatropine allergy' },
  { code: '294457006�', display: 'Benzimidazole anthelmintic allergy' },
  { code: '293724004�', display: 'Benzocaine allergy' },
  { code: '294270000�', display: 'Benzodiazepine antagonist allergy' },
  { code: '293885009�', display: 'Benzodiazepine sedative allergy' },
  { code: '294203002�', display: 'Benzoyl peroxide allergy' },
  { code: '294499007�', display: 'Benzylpenicillin allergy' },
  { code: '294455003�', display: 'Bephenium allergy' },
  { code: '294103005�', display: 'Beractant allergy' },
  { code: '294032006�', display: 'Beta-adrenoceptor agonist allergy' },
  { code: '293962009�', display: 'Beta-adrenoceptor blocking drug allergy' },
  { code: '293966007�', display: 'Betaxolol allergy' },
  { code: '294018009�', display: 'Bethanechol allergy' },
  { code: '294395002�', display: 'Biguanide antimalarial allergy' },
  { code: '294430009�', display: 'Biguanide disinfectant allergy' },
  { code: '293698003�', display: 'Bile agent allergy' },
  { code: '294069009�', display: 'Biperiden allergy' },
  { code: '293678008�', display: 'Bisacodyl allergy' },
  { code: '293645001�', display: 'Bismuth chelate allergy' },
  {
    code: '294411009�',
    display: 'Bismuth subnitrate and iodoform paste impregnated gauze allergy',
  },
  { code: '293967003�', display: 'Bisoprolol allergy' },
  { code: '293761008�', display: 'Bleomycin allergy' },
  { code: '294433006�', display: 'Borate allergy' },
  { code: '294434000�', display: 'Boric acid allergy' },
  { code: '294448000�', display: 'Brilliant green allergy' },
  { code: '294504007�', display: 'Broad spectrum penicillins allergy' },
  { code: '293893009�', display: 'Bromazepam allergy' },
  { code: '294094005�', display: 'Bromhexine hydrochloride allergy' },
  { code: '294181005�', display: 'Bromine complexes allergy' },
  { code: '293815001�', display: 'Bromocriptine allergy' },
  { code: '294128002�', display: 'Brompheniramine allergy' },
  { code: '294161009�', display: 'Bronchodilator preparations allergy' },
  { code: '294172004�', display: 'Bufexamac allergy' },
  { code: '293683000�', display: 'Bulk-forming laxative allergy' },
  { code: '293719002�', display: 'Bupivacaine allergy' },
  { code: '293596005�', display: 'Buprenorphine allergy' },
  { code: '293911006�', display: 'Buspirone allergy' },
  { code: '293742009�', display: 'Busulfan allergy' },
  { code: '293881000�', display: 'Butobarbital allergy' },
  { code: '293822009�', display: 'Butriptyline allergy' },
  { code: '293919008�', display: 'Butyrophenone allergy' },
  { code: '294168003�', display: 'Calamine allergy' },
  { code: '294178000�', display: 'Calcipotriol allergy' },
  { code: '294570004�', display: 'Calcium sulfaloxate allergy' },
  { code: '293994004�', display: 'Calcium-channel blocker allergy' },
  { code: '294185001�', display: 'Camouflaging preparations allergy' },
  { code: '294019001�', display: 'Carbachol allergy' },
  { code: '293905008�', display: 'Carbamate sedative allergy' },
  { code: '293867002�', display: 'Carbamazepine allergy' },
  { code: '294531005�', display: 'Carbapenem allergy' },
  { code: '294510007�', display: 'Carbenicillin allergy' },
  { code: '293657005�', display: 'Carbenoxolone allergy' },
  { code: '293810006�', display: 'Carbidopa + levodopa allergy' },
  { code: '294095006�', display: 'Carbocisteine allergy' },
  { code: '294333007�', display: 'Carbomer-940 allergy' },
  { code: '293779007�', display: 'Carboplatin allergy' },
  { code: '294258004�', display: 'Carboprost allergy' },
  { code: '294304006�', display: 'Cardioplegia solution allergy' },
  { code: '293963004�', display: 'Cardioselective beta-blocker allergy' },
  { code: '294518000�', display: 'Carfecillin allergy' },
  { code: '294235002�', display: 'Carisoprodol allergy' },
  { code: '294339006�', display: 'Carmellose allergy' },
  { code: '293755005�', display: 'Carmustine allergy' },
  { code: '293976005�', display: 'Carteolol allergy' },
  { code: '293974008�', display: 'Carvedilol allergy' },
  { code: '293685007�', display: 'Cascara allergy' },
  { code: '294318004�', display: 'Castor oil allergy' },
  { code: '294435004�', display: 'Cationic surfactant allergy' },
  { code: '294541008�', display: 'Cefaclor allergy' },
  { code: '294534002�', display: 'Cefadroxil allergy' },
  { code: '294535001�', display: 'Cefalexin allergy' },
  { code: '294537009�', display: 'Cefazolin allergy' },
  { code: '294548002�', display: 'Cefixime allergy' },
  { code: '294549005�', display: 'Cefodizime allergy' },
  { code: '294545004�', display: 'Cefotaxime allergy' },
  { code: '294558003�', display: 'Cefoxitin allergy' },
  { code: '294556004�', display: 'Cefpirome allergy' },
  { code: '294550005�', display: 'Cefpodoxime allergy' },
  { code: '294538004�', display: 'Cefradine allergy' },
  { code: '294554001�', display: 'Cefsulodin allergy' },
  { code: '294546003�', display: 'Ceftazidime allergy' },
  { code: '294552002�', display: 'Ceftibuten allergy' },
  { code: '294547007�', display: 'Ceftizoxime allergy' },
  { code: '294551009�', display: 'Ceftriaxone allergy' },
  { code: '294542001�', display: 'Cefuroxime allergy' },
  { code: '293968008�', display: 'Celiprolol allergy' },
  {
    code: '294334001�',
    display: 'Cellulose-derived viscosity modifier allergy',
  },
  { code: '294054008�', display: 'Central alpha-adrenoceptor agonist allergy' },
  { code: '293953007�', display: 'Central stimulant allergy' },
  {
    code: '293801003�',
    display: 'Centrally acting appetite suppressant allergy',
  },
  { code: '294532003�', display: 'Cephalosporin allergy' },
  { code: '294536000�', display: 'Cephalothin allergy' },
  { code: '294543006�', display: 'Cephamandole allergy' },
  { code: '294557008�', display: 'Cephamycin allergy' },
  { code: '294116005�', display: 'Cetirizine allergy' },
  { code: '294437007�', display: 'Cetrimide allergy' },
  { code: '294441006�', display: 'Cetylpyridinium allergy' },
  { code: '294265007�', display: 'Charcoal-activated allergy' },
  { code: '294282009�', display: 'Chelating agent allergy' },
  { code: '294262005�', display: 'Chelating agents and antidotes allergy' },
  { code: '293699006�', display: 'Chenodeoxycholic acid allergy' },
  {
    code: '293702004�',
    display: 'Chenodeoxycholic and ursodeoxycholic acid allergy',
  },
  { code: '293908005�', display: 'Chloral hydrate allergy' },
  { code: '293907000�', display: 'Chloral sedative allergy' },
  { code: '293747003�', display: 'Chlorambucil allergy' },
  { code: '293894003�', display: 'Chlordiazepoxide allergy' },
  { code: '294431008�', display: 'Chlorhexidine allergy' },
  {
    code: '294432001�',
    display: 'Chlorhexidine hydrochloride and neomycin sulfate allergy',
  },
  { code: '294414001�', display: 'Chlorinated phenol disinfectant allergy' },
  { code: '294409000�', display: 'Chlorinated solutions allergy' },
  { code: '293753003�', display: 'Chlormethine allergy' },
  { code: '293876009�', display: 'Chlormezanone allergy' },
  { code: '294394003�', display: 'Chloroquine allergy' },
  { code: '294415000�', display: 'Chloroxylenol allergy' },
  { code: '294129005�', display: 'Chlorphenamine allergy' },
  { code: '293935001�', display: 'Chlorpromazine allergy' },
  { code: '293943006�', display: 'Chlorprothixene allergy' },
  { code: '294159000�', display: 'Choline theophyllinate allergy' },
  { code: '294272008�', display: 'Cholinesterase reactivator allergy' },
  { code: '294507000�', display: 'Ciclacillin allergy' },
  { code: '293798009�', display: 'Ciclosporin allergy' },
  { code: '293650007�', display: 'Cimetidine allergy' },
  { code: '293720008�', display: 'Cinchocaine allergy' },
  { code: '294397005�', display: 'Cinchona antimalarial allergy' },
  { code: '294130000�', display: 'Cinnarizine allergy' },
  { code: '294485008�', display: 'Cinoxacin allergy' },
  { code: '294487000�', display: 'Ciprofloxacin allergy' },
  { code: '293671002�', display: 'Cisapride allergy' },
  { code: '293780005�', display: 'Cisplatin allergy' },
  { code: '293849009�', display: 'Citalopram allergy' },
  { code: '294307004�', display: 'Citrate allergy' },
  { code: '294471003�', display: 'Clarithromycin allergy' },
  { code: '294118006�', display: 'Clemastine allergy' },
  { code: '294561002�', display: 'Clindamycin allergy' },
  { code: '293895002�', display: 'Clobazam allergy' },
  { code: '293912004�', display: 'Clomethiazole allergy' },
  { code: '293830005�', display: 'Clomipramine allergy' },
  { code: '293871004�', display: 'Clonazepam allergy' },
  { code: '294058006�', display: 'Clonidine allergy' },
  { code: '294356003�', display: 'Clotrimazole allergy' },
  { code: '294501004�', display: 'Cloxacillin allergy' },
  { code: '293916001�', display: 'Clozapine allergy' },
  { code: '294169006�', display: 'Coal tar allergy' },
  { code: '294171006�', display: 'Coal tar bath preparations allergy' },
  { code: '294170007�', display: 'Coal tar scalp preparations allergy' },
  { code: '293723005�', display: 'Cocaine allergy' },
  { code: '293597001�', display: 'Codeine allergy' },
  { code: '294215003�', display: 'Colchicine allergy' },
  { code: '294214004�', display: 'Colchicum alkaloid allergy' },
  { code: '294106002�', display: 'Colfosceril allergy' },
  { code: '294529001�', display: 'Colistin allergy' },
  { code: '294191004�', display: 'Collodion allergy' },
  { code: '293734008�', display: 'Colony stimulating factors allergy' },
  { code: '294521003�', display: 'Combined penicillin preparation allergy' },
  { code: '294581001�', display: 'Combined sulfonamides allergy' },
  { code: '293841007�', display: 'Compound antidepressants allergy' },
  { code: '294154005�', display: 'Compound cough suppressant allergy' },
  { code: '5388008�', display: 'Congenital lactase deficiency' },
  { code: '293637006�', display: 'Contrast media allergy' },
  { code: '294147004�', display: 'Cough suppressant allergy' },
  { code: '294146008�', display: 'Cough/decongestant preparation allergy' },
  { code: '294192006�', display: 'Counter irritants allergy' },
  { code: '293784001�', display: 'Crisantaspase allergy' },
  {
    code: '294141003�',
    display: 'Cromoglycate and related antiallergenic allergy',
  },
  { code: '294200004�', display: 'Crotamiton allergy' },
  { code: '294447005�', display: 'Crystal violet allergy' },
  { code: '294122001�', display: 'Cyclizine allergy' },
  { code: '293882007�', display: 'Cyclobarbitone allergy' },
  { code: '294079006�', display: 'Cyclopentolate allergy' },
  { code: '293748008�', display: 'Cyclophosphamide allergy' },
  { code: '294131001�', display: 'Cyproheptadine allergy' },
  { code: '293775001�', display: 'Cytarabine allergy' },
  { code: '293759004�', display: 'Cytotoxic antibiotic allergy' },
  { code: '293758007�', display: 'Dacarbazine allergy' },
  { code: '293760009�', display: 'Dactinomycin allergy' },
  { code: '294237005�', display: 'Dantrolene allergy' },
  { code: '293679000�', display: 'Dantron allergy' },
  { code: '293951009�', display: 'Decarboxylase inhibitor allergy' },
  { code: '293700007�', display: 'Dehydrocholic acid allergy' },
  { code: '294012005�', display: 'Demecarium allergy' },
  { code: '294223001�', display: 'Depolarizing muscle relaxant allergy' },
  { code: '294443009�', display: 'Dequalinium allergy' },
  { code: '294284005�', display: 'Desferrioxamine allergy' },
  { code: '293717000�', display: 'Desflurane allergy' },
  { code: '293831009�', display: 'Desipramine allergy' },
  { code: '294187009�', display: 'Desloughing agents allergy' },
  { code: '293958003�', display: 'Dexamfetamine allergy' },
  { code: '293804006�', display: 'Dexfenfluramine allergy' },
  { code: '294151002�', display: 'Dextromethorphan hydrobromide allergy' },
  { code: '293591000�', display: 'Dextromoramide allergy' },
  { code: '293592007�', display: 'Dextropropoxyphene allergy' },
  { code: '293634004�', display: 'Diagnostic agent allergy' },
  { code: '293641005�', display: 'Diagnostic dye allergy' },
  { code: '294299005�', display: 'Dialysis fluid allergy' },
  { code: '293598006�', display: 'Diamorphine allergy' },
  { code: '293902006�', display: 'Diazepam allergy' },
  { code: '294429004�', display: 'Dibrompropamidine isethionate allergy' },
  { code: '293909002�', display: 'Dichloralphenazone allergy' },
  { code: '293613006�', display: 'Diclofenac allergy' },
  { code: '294286007�', display: 'Dicobalt edetate allergy' },
  { code: '293693007�', display: 'Dicycloverine allergy' },
  { code: '294372006�', display: 'Didanosine allergy' },
  { code: '294456002�', display: 'Diethylcarbamazine allergy' },
  { code: '293805007�', display: 'Diethylpropion allergy' },
  { code: '294268009�', display: 'Digoxin-specific-antibody allergy' },
  { code: '293599003�', display: 'Dihydrocodeine allergy' },
  { code: '294003008�', display: 'Diltiazem allergy' },
  { code: '294123006�', display: 'Dimenhydrinate allergy' },
  { code: '294283004�', display: 'Dimercaprol allergy' },
  { code: '294329000�', display: 'Dimethicone allergy' },
  { code: '293796008�', display: 'Dimethyl sulfoxide allergy' },
  { code: '294306008�', display: 'Dimethyl-ether propane allergy' },
  { code: '294132008�', display: 'Dimetindene allergy' },
  { code: '294257009�', display: 'Dinoprost allergy' },
  { code: '294253008�', display: 'Dinoprostone allergy' },
  { code: '294133003�', display: 'Diphenhydramine allergy' },
  { code: '293925007�', display: 'Diphenylbutylpiperidine allergy' },
  { code: '294134009�', display: 'Diphenylpyraline allergy' },
  { code: '293593002�', display: 'Dipipanone allergy' },
  { code: '294060008�', display: 'Dipivefrine allergy' },
  { code: '293896001�', display: 'Dipotassium clorazepate allergy' },
  { code: '294444003�', display: 'Disinfectant dye allergy' },
  { code: '294403004�', display: 'Disinfectants and cleansers allergy' },
  { code: '294289000�', display: 'Disodium edetate allergy' },
  { code: '294013000�', display: 'Distigmine allergy' },
  { code: '293960001�', display: 'Disulfiram allergy' },
  { code: '294173009�', display: 'Dithranol allergy' },
  { code: '294175002�', display: 'Dithranol cream allergy' },
  { code: '294174003�', display: 'Dithranol ointment allergy' },
  { code: '294176001�', display: 'Dithranol paste allergy' },
  { code: '294047002�', display: 'Dobutamine allergy' },
  { code: '293687004�', display: 'Docusate allergy' },
  { code: '294439005�', display: 'Domiphen allergy' },
  { code: '293674005�', display: 'Domperidone allergy' },
  { code: '294061007�', display: 'Dopamine allergy' },
  {
    code: '293807004�',
    display: 'Dopaminergic drug used in parkinsonism allergy',
  },
  { code: '294048007�', display: 'Dopexamine allergy' },
  { code: '294091002�', display: 'Dornase alfa allergy' },
  { code: '293832002�', display: 'Dosulepin allergy' },
  { code: '294101007�', display: 'Doxapram allergy' },
  { code: '293987002�', display: 'Doxazosin allergy' },
  { code: '293823004�', display: 'Doxepin allergy' },
  { code: '293766003�', display: 'Doxorubicin allergy' },
  { code: '293923000�', display: 'Droperidol allergy' },
  { code: '294213005�', display: 'Drug for the treatment of gout allergy' },
  {
    code: '294163007�',
    display: 'Drug groups and agents primarily acting on the skin allergy',
  },
  {
    code: '293961002�',
    display:
      'Drug groups primarily affecting the autonomic nervous system allergy',
  },
  {
    code: '293800002�',
    display:
      'Drug groups primarily affecting the central nervous system allergy',
  },
  {
    code: '293643008�',
    display:
      'Drug groups primarily affecting the gastrointestinal system allergy',
  },
  {
    code: '294212000�',
    display:
      'Drug groups primarily affecting the musculoskeletal system allergy',
  },
  {
    code: '294090001�',
    display: 'Drug groups primarily affecting the respiratory system allergy',
  },
  {
    code: '294244001�',
    display:
      'Drug groups primarily used in obstetrics, gynecological and urinary tract disorders allergy',
  },
  { code: '294167008�', display: 'Dusting powders allergy' },
  { code: '294252003�', display: 'E series prostaglandin allergy' },
  { code: '294309001�', display: 'Ear wax removal preparation allergy' },
  { code: '294359005�', display: 'Econazole allergy' },
  { code: '294014006�', display: 'Ecothiopate allergy' },
  { code: '294285006�', display: 'Edetate allergy' },
  { code: '294015007�', display: 'Edrophonium allergy' },
  { code: '213020009�', display: 'Egg protein allergy' },
  { code: '294070005�', display: 'Emepronium bromide allergy' },
  { code: '294193001�', display: 'Emollient allergy' },
  { code: '294166004�', display: 'Emollient bath additives allergy' },
  { code: '294190003�', display: 'Enbucrilate allergy' },
  { code: '293712006�', display: 'Enflurane allergy' },
  { code: '294488005�', display: 'Enoxacin allergy' },
  { code: '294062000�', display: 'Ephedrine allergy' },
  { code: '293767007�', display: 'Epirubicin allergy' },
  { code: '294260002�', display: 'Epoprostenol allergy' },
  { code: '91931000�', display: 'Erythromycin allergy' },
  { code: '293969000�', display: 'Esmolol allergy' },
  { code: '293752008�', display: 'Estramustine allergy' },
  { code: '293788003�', display: 'Estrogen antagonist allergy' },
  { code: '294100008�', display: 'Ethamivan allergy' },
  { code: '293713001�', display: 'Ether, anesthetic allergy' },
  { code: '293749000�', display: 'Ethoglucid allergy' },
  { code: '293870003�', display: 'Ethosuximide allergy' },
  { code: '293614000�', display: 'Etodolac allergy' },
  { code: '293706001�', display: 'Etomidate allergy' },
  { code: '293777009�', display: 'Etoposide allergy' },
  { code: '294208006�', display: 'Etretinate allergy' },
  { code: '294156007�', display: 'Expectorant allergy' },
  { code: '294256000�', display: 'F series prostaglandin allergy' },
  { code: '294371004�', display: 'Famciclovir allergy' },
  { code: '293651006�', display: 'Famotidine allergy' },
  { code: '232348003�', display: 'Feather allergy' },
  { code: '293615004�', display: 'Felbinac allergy' },
  { code: '293999009�', display: 'Felodipine allergy' },
  { code: '293616003�', display: 'Fenbufen allergy' },
  { code: '293806008�', display: 'Fenfluramine allergy' },
  { code: '293617007�', display: 'Fenoprofen allergy' },
  { code: '294039002�', display: 'Fenoterol allergy' },
  { code: '293605005�', display: 'Fentanyl allergy' },
  { code: '294357007�', display: 'Fenticonazole allergy' },
  { code: '293737001�', display: 'Filgrastim allergy' },
  { code: '294533008�', display: 'First generation cephalosporin allergy' },
  { code: '294315001�', display: 'Fixed oil allergy' },
  { code: '294243007�', display: 'Flavoxate allergy' },
  { code: '294502006�', display: 'Flucloxacillin allergy' },
  { code: '294365005�', display: 'Fluconazole allergy' },
  { code: '294341007�', display: 'Flucytosine allergy' },
  { code: '293786004�', display: 'Fludarabine allergy' },
  { code: '294271001�', display: 'Flumazenil allergy' },
  { code: '293886005�', display: 'Flunitrazepam allergy' },
  { code: '293776000�', display: 'Fluorouracil allergy' },
  { code: '293850009�', display: 'Fluoxetine allergy' },
  { code: '293948002�', display: 'Flupentixol allergy' },
  { code: '293944000�', display: 'Flupentixol decanoate allergy' },
  { code: '293934002�', display: 'Fluphenazine allergy' },
  { code: '293931005�', display: 'Fluphenazine decanoate allergy' },
  { code: '293932003�', display: 'Fluphenazine enanthate allergy' },
  { code: '293887001�', display: 'Flurazepam allergy' },
  { code: '293618002�', display: 'Flurbiprofen allergy' },
  { code: '293927004�', display: 'Fluspirilene allergy' },
  { code: '293851008�', display: 'Fluvoxamine allergy' },
  { code: '235719002�', display: 'Food intolerance' },
  { code: '294426006�', display: 'Formaldehyde allergy' },
  { code: '293791003�', display: 'Formestane allergy' },
  { code: '294382007�', display: 'Foscarnet allergy' },
  { code: '294559006�', display: 'Fosfomycin allergy' },
  { code: '294555000�', display: 'Fourth generation cephalosporin allergy' },
  { code: '294467001�', display: 'Framycetin allergy' },
  { code: '294280001�', display: 'Fullers earth powder allergy' },
  { code: '294482006�', display: 'Fusidic acid allergy' },
  { code: '293861001�', display: 'Gabapentin allergy' },
  { code: '294229002�', display: 'Gallamine allergy' },
  { code: '294206005�', display: 'Gamolenic acid allergy' },
  { code: '294370003�', display: 'Ganciclovir allergy' },
  { code: '294254002�', display: 'Gemeprost allergy' },
  { code: '293704003�', display: 'General anesthetic drug allergy' },
  { code: '294469003�', display: 'Gentamicin allergy' },
  { code: '294423003�', display: 'Glutaraldehyde allergy' },
  { code: '294320001�', display: 'Glycerol allergy' },
  { code: '237978005�', display: 'Glycerol intolerance' },
  { code: '294319007�', display: 'Glycerol/glycol/macrogol allergy' },
  { code: '294298002�', display: 'Glycine allergy' },
  { code: '294080009�', display: 'Glycopyrronium allergy' },
  { code: '294238000�', display: 'Gold allergy' },
  { code: '294348001�', display: 'Griseofulvin allergy' },
  { code: '294109009�', display: 'H1 antihistamine allergy' },
  { code: '293649007�', display: 'H2 receptor antagonist allergy' },
  { code: '294399008�', display: 'Halofantrine allergy' },
  { code: '293924006�', display: 'Haloperidol allergy' },
  { code: '293922005�', display: 'Haloperidol decanoate allergy' },
  { code: '293714007�', display: 'Halothane allergy' },
  { code: '294301003�', display: 'Hemodialysis fluid allergy' },
  { code: '294302005�', display: 'Hemofiltration solution allergy' },
  { code: '294308009�', display: 'Hemorrhoid preparation allergy' },
  { code: '294295004�', display: 'Herbal medicine allergy' },
  { code: '25744000�', display: 'Hereditary gastrogenic lactose intolerance' },
  { code: '294416004�', display: 'Hexachlorophene allergy' },
  { code: '294407003�', display: 'Hexetidine allergy' },
  { code: '294081008�', display: 'Homatropine allergy' },
  { code: '294293006�', display: 'Homeopathic medicine allergy' },
  { code: '232349006�', display: 'House dust allergy' },
  { code: '232350006�', display: 'House dust mite allergy' },
  { code: '294376009�', display: 'Human interferon gamma-1b allergy' },
  { code: '294405006�', display: 'Hydrargaphen allergy' },
  { code: '294274009�', display: 'Hydrofluoric acid burn antidote allergy' },
  { code: '294449008�', display: 'Hydrogen peroxide allergy' },
  { code: '293781009�', display: 'Hydroxycarbamide allergy' },
  { code: '294393009�', display: 'Hydroxychloroquine allergy' },
  { code: '294337008�', display: 'Hydroxyethylcellulose allergy' },
  { code: '294135005�', display: 'Hydroxyzine allergy' },
  { code: '294074001�', display: 'Hyoscine allergy' },
  { code: '293689001�', display: 'Hyoscine butylbromide allergy' },
  { code: '294075000�', display: 'Hyoscine hydrobromide allergy' },
  { code: '294335000�', display: 'Hypromellose allergy' },
  { code: '294336004�', display: 'Hypromellose eye drops allergy' },
  { code: '294259007�', display: 'I series prostaglandin allergy' },
  { code: '293619005�', display: 'Ibuprofen allergy' },
  { code: '294177005�', display: 'Ichthammol allergy' },
  { code: '293768002�', display: 'Idarubicin allergy' },
  { code: '294385009�', display: 'Idoxuridine allergy' },
  { code: '294386005�', display: 'Idoxuridine in dimethylsulfoxide allergy' },
  { code: '293750000�', display: 'Ifosfamide allergy' },
  { code: '294355004�', display: 'Imidazole antifungal allergy' },
  { code: '293833007�', display: 'Imipramine allergy' },
  { code: '293730004�', display: 'Immunostimulant allergy' },
  { code: '293797004�', display: 'Immunosuppressant allergy' },
  { code: '293620004�', display: 'Indometacin allergy' },
  { code: '293988007�', display: 'Indoramin allergy' },
  { code: '294421001�', display: 'Industrial methylated spirit allergy' },
  { code: '293711004�', display: 'Inhalational anesthetics allergy' },
  { code: '294052007�', display: 'Inhaled isoprenaline allergy' },
  { code: '294368007�', display: 'Inosine pranobex allergy' },
  { code: '294377000�', display: 'Interferon-A-2a allergy' },
  { code: '294378005�', display: 'Interferon-A-2b allergy' },
  { code: '294379002�', display: 'Interferon-A-N1 allergy' },
  { code: '294375008�', display: 'Interferons allergy' },
  { code: '293705002�', display: 'Intravenous anesthetics allergy' },
  { code: '294264006�', display: 'Ipecacuanha allergy' },
  { code: '294082001�', display: 'Ipratropium allergy' },
  { code: '293824005�', display: 'Iprindole allergy' },
  { code: '293837008�', display: 'Iproniazid allergy' },
  { code: '294148009�', display: 'Isoaminile allergy' },
  { code: '293838003�', display: 'Isocarboxazid allergy' },
  { code: '294360000�', display: 'Isoconazole allergy' },
  { code: '294021006�', display: 'Isoetharine hydrochloride allergy' },
  { code: '293715008�', display: 'Isoflurane allergy' },
  { code: '294050004�', display: 'Isoprenaline allergy' },
  { code: '294211007�', display: 'Isotretinoin allergy' },
  { code: '293998001�', display: 'Isradipine allergy' },
  { code: '294366006�', display: 'Itraconazole allergy' },
  { code: '294464008�', display: 'Kanamycin allergy' },
  { code: '293669002�', display: 'Kaolin allergy' },
  { code: '293707005�', display: 'Ketamine allergy' },
  { code: '293897005�', display: 'Ketazolam allergy' },
  { code: '294362008�', display: 'Ketoconazole allergy' },
  { code: '293621000�', display: 'Ketoprofen allergy' },
  { code: '293622007�', display: 'Ketorolac allergy' },
  { code: '294144006�', display: 'Ketotifen allergy' },
  { code: '293977001�', display: 'Labetalol allergy' },
  { code: '188336009�', display: 'Laboratory animal dander allergy' },
  { code: '294072002�', display: 'Lachesine chloride allergy' },
  { code: '294000006�', display: 'Lacidipine allergy' },
  {
    code: '29512005�',
    display: 'Lactase deficiency in diseases other than of the small intestine',
  },
  { code: '294401002�', display: 'Lactobacill acidophil vaccine allergy' },
  {
    code: '54250004�',
    display: 'Lactose intolerance in children without lactase deficiency',
  },
  { code: '293681003�', display: 'Lactulose allergy' },
  { code: '293859005�', display: 'Lamotrigine allergy' },
  { code: '293656001�', display: 'Lansoprazole allergy' },
  { code: '294539007�', display: 'Latamoxef allergy' },
  { code: '293677003�', display: 'Laxative allergy' },
  { code: '293736005�', display: 'Lenograstim allergy' },
  { code: '293738006�', display: 'Levamisole allergy' },
  { code: '293978006�', display: 'Levobunolol allergy' },
  { code: '293808009�', display: 'Levodopa allergy' },
  { code: '293929001�', display: 'Levomepromazine allergy' },
  { code: '293609004�', display: 'Levorphanol allergy' },
  { code: '293722000�', display: 'Lidocaine allergy' },
  { code: '293995003�', display: 'Lidoflazine allergy' },
  { code: '294562009�', display: 'Lincomycin allergy' },
  { code: '294560001�', display: 'Lincomycin and derivatives allergy' },
  { code: '294327003�', display: 'Liquid paraffin allergy' },
  { code: '293647009�', display: 'Liquorice allergy' },
  { code: '293813008�', display: 'Lisuride allergy' },
  { code: '293817009�', display: 'Lithium allergy' },
  { code: '293818004�', display: 'Lithium carbonate allergy' },
  { code: '293819007�', display: 'Lithium citrate allergy' },
  { code: '293718005�', display: 'Local anesthetic drug allergy' },
  { code: '294145007�', display: 'Lodoxamide allergy' },
  { code: '293825006�', display: 'Lofepramine allergy' },
  { code: '294059003�', display: 'Lofexidine allergy' },
  { code: '293756006�', display: 'Lomustine allergy' },
  { code: '293668005�', display: 'Loperamide allergy' },
  { code: '293888006�', display: 'Loprazolam allergy' },
  { code: '294114008�', display: 'Loratadine allergy' },
  { code: '293903001�', display: 'Lorazepam allergy' },
  { code: '293889003�', display: 'Lormetazepam allergy' },
  { code: '293915002�', display: 'Loxapine allergy' },
  { code: '294321002�', display: 'Lubricant allergy' },
  { code: '91933002�', display: 'Macrolide allergy' },
  { code: '294579003�', display: 'Mafenide allergy' },
  { code: '293682005�', display: 'Magnesium sulfate allergy' },
  { code: '293665008�', display: 'Magnesium trisilicate allergy' },
  {
    code: '293639009�',
    display: 'Magnetic resonance imaging contrast media allergy',
  },
  {
    code: '235720008�',
    display: 'Malabsorption caused by intolerance to carbohydrate',
  },
  { code: '294563004�', display: 'Mandelic acid allergy' },
  { code: '293853006�', display: 'Maprotiline allergy' },
  { code: '293802005�', display: 'Mazindol allergy' },
  { code: '294458001�', display: 'Mebendazole allergy' },
  { code: '293692002�', display: 'Mebeverine allergy' },
  { code: '294119003�', display: 'Mebhydrolin allergy' },
  { code: '294519008�', display: 'Mecillinam allergy' },
  { code: '294124000�', display: 'Meclozine hydrochloride allergy' },
  { code: '293898000�', display: 'Medazepam allergy' },
  { code: '293623002�', display: 'Mefenamic acid allergy' },
  { code: '294392004�', display: 'Mefloquine allergy' },
  { code: '293751001�', display: 'Melphalan allergy' },
  { code: '294400001�', display: 'Mepacrine allergy' },
  { code: '293694001�', display: 'Mepenzolate allergy' },
  { code: '293906009�', display: 'Meprobamate allergy' },
  { code: '293608007�', display: 'Meptazinol allergy' },
  { code: '294136006�', display: 'Mepyramine allergy' },
  { code: '294120009�', display: 'Mequitazine allergy' },
  { code: '293772003�', display: 'Mercaptopurine allergy' },
  { code: '293770006�', display: 'Mercuric oxide allergy' },
  { code: '293660003�', display: 'Mesalazine allergy' },
  { code: '294269001�', display: 'Mesna allergy' },
  { code: '294026001�', display: 'Metaraminol allergy' },
  { code: '294009007�', display: 'Methacholine allergy' },
  { code: '293594008�', display: 'Methadone allergy' },
  { code: '293590004�', display: 'Methadone analog allergy' },
  { code: '294479001�', display: 'Methenamine hippurate allergy' },
  { code: '294503001�', display: 'Methicillin allergy' },
  { code: '294083006�', display: 'Methixene allergy' },
  { code: '294236001�', display: 'Methocarbamol allergy' },
  { code: '293710003�', display: 'Methohexitone allergy' },
  { code: '293771005�', display: 'Methotrexate allergy' },
  { code: '294027005�', display: 'Methoxamine allergy' },
  { code: '294096007�', display: 'Methylcysteine allergy' },
  { code: '294055009�', display: 'Methyldopa allergy' },
  { code: '294056005�', display: 'Methyldopa and diuretic allergy' },
  { code: '293955000�', display: 'Methylphenidate allergy' },
  { code: '293864009�', display: 'Methylphenobarbital allergy' },
  { code: '293877000�', display: 'Methyprylone allergy' },
  { code: '293975009�', display: 'Metipranolol allergy' },
  { code: '293675006�', display: 'Metoclopramide allergy' },
  { code: '293970004�', display: 'Metoprolol allergy' },
  { code: '294567003�', display: 'Metronidazole allergy' },
  { code: '294508005�', display: 'Mezlocillin allergy' },
  { code: '293854000�', display: 'Mianserin allergy' },
  { code: '294363003�', display: 'Miconazole allergy' },
  { code: '293901004�', display: 'Midazolam allergy' },
  { code: '294245000�', display: 'Mifepristone allergy' },
  { code: '700094005�', display: 'Milk intolerance' },
  { code: '293648004�', display: 'Misoprostol allergy' },
  { code: '293741002�', display: 'Mitobronitol allergy' },
  { code: '293762001�', display: 'Mitomycin allergy' },
  { code: '293765004�', display: 'Mitoxantrone allergy' },
  { code: '294226009�', display: 'Mivacurium allergy' },
  { code: '293840008�', display: 'Moclobemide allergy' },
  { code: '293735009�', display: 'Molgramostim allergy' },
  { code: '293835000�', display: 'Monoamine oxidase inhibitor allergy' },
  { code: '294564005�', display: 'Monobactam allergy' },
  { code: '294149001�', display: 'Morphinan cough suppressant allergy' },
  { code: '293595009�', display: 'Morphinan opioid allergy' },
  { code: '293601001�', display: 'Morphine allergy' },
  { code: '293670001�', display: 'Motility stimulant allergy' },
  { code: '294092009�', display: 'Mucolytics allergy' },
  { code: '294480003�', display: 'Mupirocin allergy' },
  { code: '293673004�', display: 'Nabilone allergy' },
  { code: '293624008�', display: 'Nabumetone allergy' },
  { code: '293972007�', display: 'Nadolol allergy' },
  { code: '293600000�', display: 'Nalbuphine allergy' },
  { code: '294486009�', display: 'Nalidixic acid allergy' },
  { code: '294277002�', display: 'Naloxone allergy' },
  { code: '294276006�', display: 'Naltrexone allergy' },
  { code: '294028000�', display: 'Naphazoline allergy' },
  { code: '293625009�', display: 'Naproxen allergy' },
  { code: '294350009�', display: 'Natamycin allergy' },
  { code: '294142005�', display: 'Nedocromil allergy' },
  { code: '293848001�', display: 'Nefazodone allergy' },
  { code: '293626005�', display: 'Nefopam allergy' },
  { code: '294468006�', display: 'Neomycin allergy' },
  { code: '294017004�', display: 'Neostigmine allergy' },
  { code: '294465009�', display: 'Netilmicin allergy' },
  { code: '293913009�', display: 'Neuroleptic allergy' },
  { code: '294222006�', display: 'Neuromuscular transmission drug allergy' },
  { code: '294004002�', display: 'Nicardipine allergy' },
  { code: '294453005�', display: 'Niclosamide allergy' },
  { code: '293993005�', display: 'Nicotine allergy' },
  { code: '293996002�', display: 'Nifedipine allergy' },
  { code: '294099000�', display: 'Nikethamide allergy' },
  { code: '294001005�', display: 'Nimodipine allergy' },
  { code: '294569000�', display: 'Nimorazole allergy' },
  { code: '293890007�', display: 'Nitrazepam allergy' },
  { code: '294478009�', display: 'Nitrofurantoin allergy' },
  { code: '294481004�', display: 'Nitrofurazone allergy' },
  { code: '293746007�', display: 'Nitrogen mustard derivative allergy' },
  { code: '294566007�', display: 'Nitroimidazole allergy' },
  { code: '294343005�', display: 'Nitrophenol allergy' },
  { code: '293754009�', display: 'Nitrosurea allergy' },
  { code: '293652004�', display: 'Nizatidine allergy' },
  { code: '293971000�', display: 'Non-cardioselective beta-blocker allergy' },
  { code: '294225008�', display: 'Non-depolarizing muscle relaxant allergy' },
  { code: '294246004�', display: 'Non-ionic surfactant allergy' },
  { code: '293583009�', display: 'Non-opioid analgesic allergy' },
  { code: '294247008�', display: 'Nonoxinol allergy' },
  { code: '294110004�', display: 'Non-sedating antihistamine allergy' },
  {
    code: '293610009�',
    display: 'Non-steroidal anti-inflammatory drug allergy',
  },
  { code: '294029008�', display: 'Norepinephrine allergy' },
  { code: '294490006�', display: 'Norfloxacin allergy' },
  { code: '293826007�', display: 'Nortriptyline allergy' },
  { code: '294152009�', display: 'Noscapine allergy' },
  { code: '294425005�', display: 'Noxythiolin allergy' },
  { code: '91934008�', display: 'Nut allergy' },
  { code: '294351008�', display: 'Nystatin allergy' },
  { code: '294248003�', display: 'Octoxinol allergy' },
  { code: '294489002�', display: 'Ofloxacin allergy' },
  { code: '294316000�', display: 'Olive oil allergy' },
  { code: '293662006�', display: 'Olsalazine allergy' },
  { code: '293655002�', display: 'Omeprazole allergy' },
  { code: '38032004�', display: 'Ontogenic late onset lactase deficiency' },
  { code: '293587005�', display: 'Opioid analgesic allergy' },
  { code: '294275005�', display: 'Opioid antagonist allergy' },
  { code: '293602008�', display: 'Opium alkaloid allergy' },
  { code: '294051000�', display: 'Oral isoprenaline allergy' },
  { code: '294040000�', display: 'Orciprenaline allergy' },
  { code: '294312003�', display: 'Oropharyngeal preparations allergy' },
  { code: '294084000�', display: 'Orphenadrine allergy' },
  { code: '294086003�', display: 'Orphenadrine citrate allergy' },
  { code: '294085004�', display: 'Orphenadrine hydrochloride allergy' },
  { code: '294121008�', display: 'Oxatomide allergy' },
  { code: '293899008�', display: 'Oxazepam allergy' },
  { code: '294025002�', display: 'Oxedrine tartrate allergy' },
  { code: '294087007�', display: 'Oxitropium allergy' },
  { code: '293979003�', display: 'Oxprenolol allergy' },
  { code: '293726002�', display: 'Oxybuprocaine allergy' },
  { code: '294088002�', display: 'Oxybutynin allergy' },
  { code: '294063005�', display: 'Oxymetazoline allergy' },
  { code: '293949005�', display: 'Oxypertine allergy' },
  { code: '293627001�', display: 'Oxyphenbutazone allergy' },
  { code: '293785000�', display: 'Paclitaxel allergy' },
  { code: '293659008�', display: 'Pancreatin allergy' },
  { code: '294230007�', display: 'Pancuronium allergy' },
  { code: '294242002�', display: 'Papaverine allergy' },
  { code: '294324005�', display: 'Paraffin allergy' },
  { code: '294326007�', display: 'Paraffin-white soft allergy' },
  { code: '294325006�', display: 'Paraffin-yellow soft allergy' },
  { code: '293878005�', display: 'Paraldehyde allergy' },
  { code: '294006000�', display: 'Parasympathomimetic allergy' },
  { code: '294053002�', display: 'Parenteral isoprenaline allergy' },
  { code: '293847006�', display: 'Paroxetine allergy' },
  { code: '293954001�', display: 'Pemoline allergy' },
  { code: '293980000�', display: 'Penbutolol allergy' },
  { code: '294291008�', display: 'Penicillamine allergy' },
  { code: '91936005�', display: 'Penicillin allergy' },
  {
    code: '294500003�',
    display: 'Penicillinase-resistant penicillins allergy',
  },
  {
    code: '294492003�',
    display: 'Penicillinase-sensitive penicillins allergy',
  },
  { code: '293588000�', display: 'Pentazocine allergy' },
  { code: '293774002�', display: 'Pentostatin allergy' },
  { code: '293690005�', display: 'Peppermint oil allergy' },
  { code: '293814002�', display: 'Pergolide allergy' },
  { code: '293930006�', display: 'Pericyazine allergy' },
  { code: '294300002�', display: 'Peritoneal dialysis solution allergy' },
  { code: '293939007�', display: 'Perphenazine allergy' },
  { code: '293606006�', display: 'Pethidine allergy' },
  { code: '293603003�', display: 'Pethidine analog allergy' },
  { code: '293589008�', display: 'Phenazocine allergy' },
  { code: '293836004�', display: 'Phenelzine allergy' },
  { code: '294496000�', display: 'Phenethicillin allergy' },
  { code: '294137002�', display: 'Phenindamine allergy' },
  { code: '294138007�', display: 'Pheniramine allergy' },
  { code: '293865005�', display: 'Phenobarbital allergy' },
  { code: '294418003�', display: 'Phenol allergy' },
  { code: '294412002�', display: 'Phenolics allergy' },
  { code: '293607002�', display: 'Phenoperidine allergy' },
  { code: '293928009�', display: 'Phenothiazine allergy' },
  { code: '293989004�', display: 'Phenoxybenzamine allergy' },
  { code: '294497009�', display: 'Phenoxymethylpenicillin allergy' },
  { code: '293803000�', display: 'Phentermine allergy' },
  { code: '293990008�', display: 'Phentolamine allergy' },
  { code: '293628006�', display: 'Phenylbutazone allergy' },
  { code: '294030003�', display: 'Phenylephrine allergy' },
  { code: '294022004�', display: 'Phenylpropanolamine hydrochloride allergy' },
  { code: '293869004�', display: 'Phenytoin allergy' },
  { code: '294153004�', display: 'Pholcodine allergy' },
  { code: '294104004�', display: 'Phospholipid fraction allergy' },
  { code: '294571000�', display: 'Phthalylsulfathiazole allergy' },
  { code: '294011003�', display: 'Physostigmine allergy' },
  { code: '294007009�', display: 'Pilocarpine allergy' },
  { code: '293926008�', display: 'Pimozide allergy' },
  { code: '293973002�', display: 'Pindolol allergy' },
  { code: '293695000�', display: 'Pipenzolate allergy' },
  { code: '294515002�', display: 'Piperacillin allergy' },
  { code: '294525007�', display: 'Piperacillin and tazobactam allergy' },
  { code: '294451007�', display: 'Piperazine allergy' },
  { code: '294068001�', display: 'Piperidolate hydrochloride allergy' },
  { code: '293936000�', display: 'Pipotiazine allergy' },
  { code: '293860000�', display: 'Piracetam allergy' },
  { code: '294035008�', display: 'Pirbuterol allergy' },
  { code: '293658000�', display: 'Pirenzepine allergy' },
  { code: '293629003�', display: 'Piroxicam allergy' },
  { code: '294509002�', display: 'Pivampicillin allergy' },
  { code: '294526008�', display: 'Pivampicillin and pivmecillinam allergy' },
  { code: '294520002�', display: 'Pivmecillinam allergy' },
  { code: '293763006�', display: 'Plicamycin allergy' },
  { code: '294183008�', display: 'Podophyllotoxin allergy' },
  { code: '294182003�', display: 'Podophyllum resin allergy' },
  { code: '293696004�', display: 'Poldine allergy' },
  { code: '294530006�', display: 'Polymyxin B allergy' },
  { code: '294528009�', display: 'Polymyxins allergy' },
  { code: '294406007�', display: 'Polynoxylin allergy' },
  { code: '294332002�', display: 'Polyvinyl alcohol allergy' },
  { code: '294410005�', display: 'Potassium permanganate allergy' },
  { code: '294194007�', display: 'Poultices allergy' },
  { code: '293981001�', display: 'Practolol allergy' },
  { code: '294273003�', display: 'Pralidoxime allergy' },
  { code: '293900003�', display: 'Prazepam allergy' },
  { code: '293991007�', display: 'Prazosin allergy' },
  { code: '293997006�', display: 'Prenylamine allergy' },
  { code: '293721007�', display: 'Prilocaine allergy' },
  { code: '294391006�', display: 'Primaquine allergy' },
  { code: '190751001�', display: 'Primary lactose intolerance' },
  { code: '293866006�', display: 'Primidone allergy' },
  { code: '294217006�', display: 'Probenecid allergy' },
  { code: '293727006�', display: 'Procaine allergy' },
  { code: '294498004�', display: 'Procaine benzylpenicillin allergy' },
  { code: '293782002�', display: 'Procarbazine allergy' },
  { code: '293940009�', display: 'Prochlorperazine allergy' },
  { code: '294089005�', display: 'Procyclidine allergy' },
  { code: '294396001�', display: 'Proguanil allergy' },
  { code: '293956004�', display: 'Prolintane allergy' },
  { code: '293937009�', display: 'Promazine allergy' },
  { code: '294126003�', display: 'Promethazine allergy' },
  { code: '294428007�', display: 'Propamidine isethionate allergy' },
  { code: '293697008�', display: 'Propantheline allergy' },
  { code: '293708000�', display: 'Propofol allergy' },
  { code: '293982008�', display: 'Propranolol allergy' },
  { code: '294155006�', display: 'Proprietary decongestant allergy' },
  { code: '294249006�', display: 'Prostaglandin allergy' },
  { code: '294278007�', display: 'Protamine allergy' },
  { code: '293654003�', display: 'Proton pump inhibitor allergy' },
  { code: '293834001�', display: 'Protriptyline allergy' },
  { code: '293728001�', display: 'Proxymetacaine allergy' },
  { code: '294023009�', display: 'Pseudoephedrine allergy' },
  { code: '294105003�', display: 'Pumactant allergy' },
  { code: '294452000�', display: 'Pyrantel allergy' },
  { code: '294016008�', display: 'Pyridostigmine allergy' },
  { code: '294388006�', display: 'Pyrimethamine allergy' },
  { code: '294436003�', display: 'Quaternary ammonium surfactant allergy' },
  { code: '294440007�', display: 'Quaternary pyridinium surfactant allergy' },
  { code: '294442004�', display: 'Quaternary quinolinium surfactant allergy' },
  { code: '294398000�', display: 'Quinine allergy' },
  { code: '293636002�', display: 'Radiopharmaceutical allergy' },
  { code: '293653009�', display: 'Ranitidine allergy' },
  { code: '293783007�', display: 'Razoxane allergy' },
  { code: '293950005�', display: 'Remoxipride allergy' },
  { code: '294041001�', display: 'Reproterol allergy' },
  { code: '294098008�', display: 'Respiratory stimulant allergy' },
  { code: '294102000�', display: 'Respiratory surfactant allergy' },
  { code: '294207001�', display: 'Retinoid allergy' },
  { code: '294380004�', display: 'Ribavirin allergy' },
  { code: '294042008�', display: 'Rimiterol allergy' },
  { code: '293917005�', display: 'Risperidone allergy' },
  { code: '294043003�', display: 'Ritodrine allergy' },
  { code: '294233009�', display: 'Rocuronium allergy' },
  { code: '294037000�', display: 'Salbutamol allergy' },
  { code: '293585002�', display: 'Salicylate allergy' },
  { code: '294036009�', display: 'Salmeterol allergy' },
  { code: '91937001�', display: 'Seafood allergy' },
  { code: '293884008�', display: 'Secobarbital allergy' },
  { code: '294540009�', display: 'Second generation cephalosporin allergy' },
  { code: '294117001�', display: 'Sedating antihistamine allergy' },
  { code: '293873001�', display: 'Sedative allergy' },
  {
    code: '294046006�',
    display: 'Selective beta-1 adrenoceptor stimulants allergy',
  },
  {
    code: '294033001�',
    display: 'Selective beta-2 adrenoceptor stimulants allergy',
  },
  {
    code: '293844004�',
    display: 'Selective serotonin re-uptake inhibitor allergy',
  },
  { code: '293952002�', display: 'Selegiline allergy' },
  { code: '293686008�', display: 'Senna allergy' },
  { code: '293845003�', display: 'Sertraline allergy' },
  { code: '294328008�', display: 'Silicone allergy' },
  { code: '294204008�', display: 'Silver nitrate allergy' },
  { code: '294580000�', display: 'Silver sulfadiazine allergy' },
  { code: '294221004�', display: 'Skeletal muscle relaxant allergy' },
  { code: '294241009�', display: 'Smooth muscle relaxant allergy' },
  { code: '294239008�', display: 'Sodium aurothiomalate allergy' },
  { code: '294287003�', display: 'Sodium calcium edetate allergy' },
  { code: '294143000�', display: 'Sodium cromoglicate allergy' },
  { code: '294473000�', display: 'Sodium fusidate allergy' },
  { code: '294297007�', display: 'Sodium hyaluronate allergy' },
  { code: '294266008�', display: 'Sodium nitrite allergy' },
  { code: '294408008�', display: 'Sodium perborate allergy' },
  { code: '293680002�', display: 'Sodium picosulfate allergy' },
  { code: '293862008�', display: 'Sodium valproate allergy' },
  { code: '293983003�', display: 'Sotalol allergy' },
  { code: '294474006�', display: 'Spectinomycin allergy' },
  { code: '294466005�', display: 'Streptomycin allergy' },
  { code: '293646000�', display: 'Sucralfate allergy' },
  { code: '294361001�', display: 'Sulconazole allergy' },
  { code: '294573002�', display: 'Sulfadiazine allergy' },
  { code: '294574008�', display: 'Sulfadimethoxine allergy' },
  { code: '294575009�', display: 'Sulfadimidine allergy' },
  { code: '294576005�', display: 'Sulfafurazole allergy' },
  { code: '294577001�', display: 'Sulfaguanidine allergy' },
  { code: '294572007�', display: 'Sulfametopyrazine allergy' },
  { code: '293663001�', display: 'Sulfasalazine allergy' },
  { code: '294578006�', display: 'Sulfaurea allergy' },
  { code: '294218001�', display: 'Sulfinpyrazone allergy' },
  { code: '91939003�', display: 'Sulfonamide allergy' },
  { code: '294179008�', display: 'Sulfur allergy' },
  { code: '293630008�', display: 'Sulindac allergy' },
  { code: '293914003�', display: 'Sulpiride allergy' },
  { code: '294184002�', display: 'Sunscreening preparations allergy' },
  { code: '294189007�', display: 'Surgical tissue adhesive allergy' },
  { code: '294224007�', display: 'Suxamethonium allergy' },
  { code: '294020007�', display: 'Sympathomimetic allergy' },
  { code: '294512004�', display: 'Talampicillin allergy' },
  { code: '293790002�', display: 'Tamoxifen allergy' },
  { code: '294476008�', display: 'Teicoplanin allergy' },
  { code: '294491005�', display: 'Temafloxacin allergy' },
  { code: '293904007�', display: 'Temazepam allergy' },
  { code: '294514003�', display: 'Temocillin allergy' },
  { code: '293631007�', display: 'Tenoxicam allergy' },
  { code: '293992000�', display: 'Terazosin allergy' },
  { code: '294342000�', display: 'Terbinafine allergy' },
  { code: '294044009�', display: 'Terbutaline allergy' },
  { code: '294112007�', display: 'Terfenadine allergy' },
  { code: '294071009�', display: 'Terodiline hydrochloride allergy' },
  { code: '294261003�', display: 'Terpenes allergy' },
  { code: '293918000�', display: 'Tetrabenazine allergy' },
  { code: '293725003�', display: 'Tetracaine allergy' },
  { code: '293852001�', display: 'Tetracyclic antidepressant drug allergy' },
  { code: '294160005�', display: 'Theophylline allergy' },
  { code: '293933008�', display: 'Thiethylperazine allergy' },
  { code: '293709008�', display: 'Thiopental allergy' },
  { code: '293938004�', display: 'Thioridazine allergy' },
  { code: '293745006�', display: 'Thiotepa allergy' },
  { code: '293942001�', display: 'Thioxanthene allergy' },
  { code: '294544000�', display: 'Third generation cephalosporin allergy' },
  { code: '294413007�', display: 'Thymol allergy' },
  { code: '294460004�', display: 'Tiabendazole allergy' },
  { code: '293632000�', display: 'Tiaprofenic acid allergy' },
  { code: '294517005�', display: 'Ticarcillin allergy' },
  { code: '294527004�', display: 'Ticarcillin and clavulanic acid allergy' },
  { code: '293984009�', display: 'Timolol allergy' },
  { code: '294568008�', display: 'Tinidazole allergy' },
  { code: '294358002�', display: 'Tioconazole allergy' },
  { code: '293773008�', display: 'Tioguanine allergy' },
  { code: '294470002�', display: 'Tobramycin allergy' },
  { code: '293633005�', display: 'Tolmetin allergy' },
  { code: '294344004�', display: 'Tolnaftate allergy' },
  { code: '294202007�', display: 'Topical abrasive agent allergy' },
  { code: '294108001�', display: 'Topical antihistamine allergy' },
  { code: '294164001�', display: 'Topical dermatological preparation allergy' },
  { code: '294310006�', display: 'Topical potassium allergy' },
  { code: '294201000�', display: 'Topical salicylic acid allergy' },
  { code: '294199002�', display: 'Topical selenium allergy' },
  { code: '294311005�', display: 'Topical zinc allergy' },
  { code: '293839006�', display: 'Tranylcypromine allergy' },
  { code: '293855004�', display: 'Trazodone allergy' },
  { code: '293743004�', display: 'Treosulfan allergy' },
  { code: '294210008�', display: 'Tretinoin allergy' },
  { code: '293757002�', display: 'Triazene antineoplastic allergy' },
  { code: '293891006�', display: 'Triazolam allergy' },
  { code: '294364009�', display: 'Triazole antifungals allergy' },
  { code: '293716009�', display: 'Trichloroethylene allergy' },
  { code: '293910007�', display: 'Triclofos sodium allergy' },
  { code: '294417008�', display: 'Triclosan allergy' },
  { code: '293821002�', display: 'Tricyclic antidepressant drug allergy' },
  { code: '294290009�', display: 'Trientine allergy' },
  { code: '293941008�', display: 'Trifluoperazine allergy' },
  { code: '294381000�', display: 'Trifluorothymidine allergy' },
  { code: '293921003�', display: 'Trifluperidol allergy' },
  { code: '294077008�', display: 'Trihexyphenidyl allergy' },
  { code: '293789006�', display: 'Trilostane allergy' },
  { code: '294477004�', display: 'Trimethoprim allergy' },
  { code: '293827003�', display: 'Trimipramine allergy' },
  {
    code: '294446001�',
    display: 'Triphenylmethane azo disinfectant dye allergy',
  },
  { code: '294139004�', display: 'Triprolidine allergy' },
  { code: '294288008�', display: 'Trisodium edetate allergy' },
  { code: '294073007�', display: 'Tropicamide allergy' },
  { code: '293842000�', display: 'Tryptophan allergy' },
  { code: '293635003�', display: 'Tuberculin allergy' },
  { code: '294231006�', display: 'Tubocurarine allergy' },
  { code: '294045005�', display: 'Tulobuterol allergy' },
  { code: '294093004�', display: 'Tyloxapol allergy' },
  { code: '293644002�', display: 'Ulcer healing drug allergy' },
  { code: '294354000�', display: 'Undecenoates allergy' },
  { code: '294165000�', display: 'Urea creams allergy' },
  { code: '294216002�', display: 'Uricosuric agent allergy' },
  { code: '293701006�', display: 'Ursodeoxycholic acid allergy' },
  { code: '294374007�', display: 'Valaciclovir allergy' },
  { code: '294475007�', display: 'Vancomycin allergy' },
  { code: '294232004�', display: 'Vecuronium allergy' },
  { code: '293843005�', display: 'Venlafaxine allergy' },
  { code: '294005001�', display: 'Verapamil allergy' },
  { code: '294383002�', display: 'Vidarabine allergy' },
  { code: '293868007�', display: 'Vigabatrin allergy' },
  { code: '293856003�', display: 'Viloxazine allergy' },
  { code: '293793000�', display: 'Vinblastine allergy' },
  { code: '293792005�', display: 'Vinca alkaloid allergy' },
  { code: '293794006�', display: 'Vincristine allergy' },
  { code: '293795007�', display: 'Vindesine allergy' },
  { code: '294331009�', display: 'Viscosity modifier allergy' },
  { code: '294330005�', display: 'Wool alcohol allergy' },
  { code: '294064004�', display: 'Xamoterol allergy' },
  { code: '294157003�', display: 'Xanthine allergy' },
  { code: '294219009�', display: 'Xanthine oxidase inhibitor allergy' },
  { code: '293638001�', display: 'X-ray contrast media allergy' },
  { code: '294031004�', display: 'Xylometazoline allergy' },
  { code: '294373001�', display: 'Zalcitabine allergy' },
  { code: '294369004�', display: 'Zidovudine allergy' },
  { code: '293875008�', display: 'Zolpidem allergy' },
  { code: '293874007�', display: 'Zopiclone allergy' },
  { code: '293946003�', display: 'Zuclopenthixol allergy' },
  { code: '293945004�', display: 'Zuclopenthixol decanoate allergy' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const procedureCodes = [
  { code: '3320000�', display: 'Abt - autologous blood transfusion' },
  { code: '6622009�', display: 'Add clasp to existing partial denture' },
  { code: '2337004�', display: 'Adductor tenotomy' },
  { code: '5326009�', display: 'Administration of dermatologic formulation' },
  { code: '6466004�', display: 'Administration of Rh immune globulin' },
  { code: '5267001�', display: 'Adrenal artery ligation' },
  {
    code: '5021008�',
    display: 'Aldosterone measurement, standing, normal salt diet',
  },
  {
    code: '4699005�',
    display: 'Alpha naphthyl butyrate stain method, blood or bone marrow',
  },
  {
    code: '1501006�',
    display: 'Altemeier operation, perineal rectal pull-through',
  },
  { code: '342002�', display: 'Amobarbital interview' },
  { code: '5422005�', display: 'Amylase measurement, peritoneal fluid' },
  { code: '267001�', display: 'Anal fistulectomy, multiple' },
  {
    code: '4520009�',
    display:
      'Anastomosis of esophagus, antesternal or antethoracic, with insertion of prosthesis',
  },
  { code: '176003�', display: 'Anastomosis of rectum' },
  {
    code: '3625002�',
    display: 'Anesthesia for brachial arteriograms, retrograde',
  },
  {
    code: '2318008�',
    display: 'Anesthesia for cast procedure on forearm, wrist or hand',
  },
  { code: '4847005�', display: 'Anesthesia for cesarean section' },
  {
    code: '1861004�',
    display: 'Anesthesia for closed procedure on humerus and elbow',
  },
  {
    code: '4976009�',
    display: 'Anesthesia for endoscopic procedure on lower extremity',
  },
  {
    code: '3088001�',
    display: 'Anesthesia for endoscopic procedure on upper extremity',
  },
  {
    code: '4331002�',
    display: 'Anesthesia for hernia repair in lower abdomen',
  },
  { code: '3688002�', display: 'Anesthesia for lens surgery' },
  {
    code: '6361008�',
    display: 'Anesthesia for popliteal thromboendarterectomy',
  },
  {
    code: '3985001�',
    display:
      'Anesthesia for procedure on arteries of lower leg with bypass graft',
  },
  { code: '1186005�', display: 'Anesthesia for procedure on bony pelvis' },
  {
    code: '5123008�',
    display: 'Anesthesia for procedure on pericardium with pump oxygenator',
  },
  {
    code: '956005�',
    display: 'Anesthesia for procedure on thoracic esophagus',
  },
  { code: '1596008�', display: 'Anesthesia for total elbow replacement' },
  { code: '1410000�', display: 'Anesthesia for tympanotomy' },
  {
    code: '3861005�',
    display: 'Aneurysmectomy with anastomosis of lower limb artery',
  },
  {
    code: '4252008�',
    display: 'Aneurysmectomy with graft replacement by interposition',
  },
  {
    code: '3090000�',
    display: 'Aneurysmectomy with graft replacement of lower limb artery',
  },
  {
    code: '2080003�',
    display: 'Angiectomy with anastomosis of lower limb artery',
  },
  { code: '3512003�', display: 'Angiography of arteries of extremity' },
  { code: '1414009�', display: 'Angiography of arteriovenous shunt' },
  { code: '5728008�', display: 'Anoscopy for removal of foreign body' },
  { code: '4558008�', display: 'Anterior resection of rectum' },
  { code: '2914003�', display: 'Anterior sclerotomy' },
  { code: '1352009�', display: 'Anterior spinal rhizotomy' },
  {
    code: '6225001�',
    display:
      'Antibody detection, RBC, enzyme, 2 stage technique, including anti-human globulin',
  },
  {
    code: '2279008�',
    display:
      'Antibody detection, red blood cell, enzyme, 1 stage technique, including anti-human globulin',
  },
  { code: '3955006�', display: 'Antibody elution from red blood cells' },
  {
    code: '4815000�',
    display:
      'Antibody identification, red blood cell antibody panel, enzyme, 2 stage technique including anti-human globulin',
  },
  { code: '3527003�', display: 'Antibody measurement' },
  { code: '6589005�', display: 'Antibody titration, high protein' },
  {
    code: '540006�',
    display: 'Antibody to single and double stranded DNA measurement',
  },
  { code: '6614002�', display: 'Antibody to single stranded DNA measurement' },
  {
    code: '1358008�',
    display: 'Anti-human globulin test, enzyme technique, titer',
  },
  {
    code: '4007002�',
    display: 'Anti-human globulin test, indirect, titer, non-gamma',
  },
  { code: '4625008�', display: 'Apical pulse taking' },
  { code: '2382000�', display: 'Application of breast pump' },
  { code: '1237000�', display: 'Application of cast, sugar tong' },
  { code: '4450003�', display: 'Application of Kirschner wire' },
  { code: '1407007�', display: 'Application of long leg cast, brace type' },
  {
    code: '3333006�',
    display: 'Application of short arm splint, forearm to hand, static',
  },
  { code: '3957003�', display: 'Arteriectomy of thoracoabdominal aorta' },
  {
    code: '5536002�',
    display: 'Arthrodesis of carpometacarpal joint of digits, other than thumb',
  },
  {
    code: '764009�',
    display: 'Arthrodesis of interphalangeal joint of great toe',
  },
  { code: '1209007�', display: 'Arthroscopic repair lateral meniscus' },
  {
    code: '4036002�',
    display: 'Arthroscopy of elbow with extensive debridement',
  },
  {
    code: '2891006�',
    display: 'Arthroscopy of elbow with partial synovectomy',
  },
  {
    code: '2244006�',
    display: 'Arthroscopy of wrist with internal fixation for instability',
  },
  {
    code: '1757000�',
    display: 'Arthroscopy of wrist with partial synovectomy',
  },
  {
    code: '5570001�',
    display:
      'Arthrotomy for infection with exploration and drainage of carpometacarpal joint',
  },
  {
    code: '5186002�',
    display: 'Arthrotomy for synovectomy of glenohumeral joint',
  },
  {
    code: '2851008�',
    display: 'Arthrotomy for synovectomy of sternoclavicular joint',
  },
  {
    code: '6465000�',
    display: 'Arthrotomy of glenohumeral joint for infection with drainage',
  },
  { code: '6188005�', display: 'Arthrotomy of knee' },
  {
    code: '135007�',
    display: 'Arthrotomy of wrist joint with exploration and biopsy',
  },
  { code: '5391008�', display: 'Artificial voice rehabilitation' },
  { code: '5651003�', display: 'Arylsulfatase A measurement' },
  { code: '1811008�', display: 'Aspiration of bursa of hand' },
  { code: '6092000�', display: 'Aspiration of nasal sinus by puncture' },
  { code: '1529009�', display: 'Aspiration of soft tissue' },
  { code: '3499006�', display: 'Aspiration of vitreous with replacement' },
  { code: '1759002�', display: 'Assessment of nutritional status' },
  {
    code: '6029001�',
    display: 'Augmentation of outflow tract of pulmonary valve',
  },
  {
    code: '4447001�',
    display:
      'Autopsy, gross and microscopic examination, stillborn or newborn without central nervous system',
  },
  { code: '3518004�', display: 'Aversive psychotherapy' },
  {
    code: '2054004�',
    display: 'Barbiturates measurement, quantitative and qualitative',
  },
  { code: '166001�', display: 'Behavioral therapy' },
  { code: '574005�', display: 'Benzodiazepine measurement' },
  {
    code: '6319002�',
    display: 'Beta lactamase, chromogenic cephalosporin susceptibility test',
  },
  { code: '2166008�', display: 'Bicuspidization of aortic valve' },
  { code: '1907003�', display: 'Bifurcation of bone' },
  { code: '5270002�', display: 'Bilateral destruction of fallopian tubes' },
  { code: '1500007�', display: 'Bilateral epididymovasostomy' },
  { code: '4321009�', display: 'Bilateral leg arteriogram' },
  { code: '1614003�', display: 'Bilateral repair of inguinal hernia, direct' },
  { code: '1866009�', display: 'Bilateral total nephrectomy' },
  { code: '3360004�', display: 'Biliary anastomosis' },
  { code: '1453000�', display: 'Biofeedback, galvanic skin response' },
  { code: '5738003�', display: 'Biopsy of cul-de-sac' },
  { code: '3748003�', display: 'Biopsy of perirenal tissue' },
  {
    code: '4263006�',
    display: 'Biopsy of soft tissue of elbow area, superficial',
  },
  { code: '388008�', display: 'Blepharorrhaphy' },
  { code: '5771004�', display: 'Blepharotomy' },
  {
    code: '3826004�',
    display: 'Blepharotomy with drainage of abscess of eyelid',
  },
  { code: '2457001�', display: 'Blind rehabilitation' },
  { code: '3413006�', display: 'Blood cell morphology' },
  {
    code: '1788001�',
    display: 'Blood unit collection for directed donation, donor',
  },
  { code: '617002�', display: 'Bone graft of mandible' },
  { code: '4149005�', display: 'Bone histomorphometry, aluminum stain' },
  { code: '3998005�', display: 'Bone imaging of limited area' },
  { code: '2315006�', display: 'Brain meninges operation' },
  { code: '5246001�', display: 'Breakpoint cluster region analysis' },
  {
    code: '1709006�',
    display:
      'Breast reconstruction, bilateral, with bilateral pedicle transverse rectus abdominis myocutaneous flaps',
  },
  { code: '1366004�', display: 'Breathing treatment' },
  { code: '1555005�', display: 'Brief group psychotherapy' },
  { code: '1746005�', display: 'Buckling of sclera using implant' },
  { code: '2373006�', display: 'Buffy coat smear evaluation' },
  { code: '2854000�', display: 'Bursectomy of hand' },
  { code: '3025001�', display: 'Calcitonin measurement' },
  { code: '1048003�', display: 'Capillary blood sampling' },
  { code: '6358007�', display: 'Capsulorrhaphy of joint' },
  { code: '870006�', display: 'Carbamazepine measurement' },
  { code: '5638008�', display: 'Carbohydrate measurement' },
  { code: '2153008�', display: 'Cardiac catheterization education' },
  {
    code: '4992004�',
    display: 'Cardiac catheterization, left heart, retrograde, percutaneous',
  },
  { code: '5930003�', display: 'Cardiac monitor removal' },
  { code: '4589007�', display: 'Care relating to reproduction and pregnancy' },
  { code: '3981005�', display: 'Carrier detection, molecular genetics' },
  { code: '4670000�', display: 'Catheterization of bronchus' },
  { code: '1001000�', display: "Cauterization of Bartholin's gland" },
  { code: '2644002�', display: 'Cauterization of liver' },
  { code: '2794006�', display: 'Cauterization of navel' },
  { code: '4593001�', display: 'Cauterization of sclera with iridectomy' },
  {
    code: '2448007�',
    display: 'Cell count of synovial fluid with differential count',
  },
  { code: '5190000�', display: 'Cell fusion' },
  { code: '1966005�', display: 'Central block anesthesia' },
  { code: '1455007�', display: 'Cerclage' },
  { code: '3938007�', display: 'Cerclage for retinal reattachment' },
  { code: '3258003�', display: 'Cerebral arteriography' },
  { code: '642006�', display: 'Cerebral thermography' },
  {
    code: '1324000�',
    display:
      'Cerebrospinal fluid immunoglobulin G ratio and immunoglobulin G index',
  },
  { code: '3256004�', display: 'Cervical lymphangiogram' },
  { code: '2847006�', display: 'Cervical myelography' },
  { code: '4957007�', display: 'Cervical spinal fusion for pseudoarthrosis' },
  { code: '6125005�', display: 'Change of gastrostomy tube' },
  { code: '6035001�', display: 'Chart abstracting' },
  { code: '2406000�', display: 'Chart periodontal pocket' },
  { code: '633004�', display: 'Chart review by physician' },
  { code: '4570007�', display: 'Chemopallidectomy' },
  { code: '6370006�', display: 'Chemosurgery of stomach lesion' },
  { code: '746002�', display: 'Chiropractic adjustment of coccyx subluxation' },
  { code: '3831002�', display: 'Chiropractic application of ice' },
  { code: '913000�', display: 'Chiropractic patient education' },
  { code: '2530001�', display: 'Chiropractic visit' },
  { code: '3287002�', display: 'Cholecystogastrostomy' },
  {
    code: '543008�',
    display: 'Choledochostomy with transduodenal sphincteroplasty',
  },
  { code: '6148000�', display: 'Chondrectomy of semilunar cartilage of knee' },
  { code: '3418002�', display: 'Chondrectomy of spine' },
  {
    code: '1638004�',
    display: 'Cineplasty with cineplastic prosthesis of extremity',
  },
  { code: '2811005�', display: 'Cineradiography of pharynx' },
  { code: '5880005�', display: 'Clinical examination' },
  { code: '712003�', display: 'Closed chest suction' },
  { code: '459006�', display: 'Closed condylotomy of mandible' },
  { code: '6433003�', display: 'Closed heart valvotomy of mitral valve' },
  { code: '5316002�', display: 'Closed osteotomy of mandibular ramus' },
  {
    code: '463004�',
    display: 'Closed reduction of coxofemoral joint dislocation with splint',
  },
  {
    code: '1119007�',
    display: 'Closed reduction of dislocation of foot and toe',
  },
  { code: '2386002�', display: 'Closed reduction of dislocation of patella' },
  {
    code: '4034004�',
    display: 'Closed reduction of facial fracture, except mandible',
  },
  { code: '4542005�', display: 'Closed reduction of fracture of foot' },
  {
    code: '6585004�',
    display: 'Closed reduction of fracture of tarsal or metatarsal',
  },
  { code: '2971000�', display: 'Closure of acquired urethrovaginal fistula' },
  { code: '3183007�', display: 'Closure of colostomy' },
  { code: '6670003�', display: 'Closure of external fistula of trachea' },
  { code: '3166003�', display: 'Closure of fistula of salivary gland' },
  { code: '2960001�', display: 'Closure of fistula of uterine cervix' },
  { code: '3664004�', display: 'Closure of scleral fistula' },
  { code: '1266002�', display: 'Closure of tympanic membrane perforation' },
  { code: '535003�', display: 'Closure of ureterovesicovaginal fistula' },
  { code: '3116009�', display: 'Clotting screening' },
  {
    code: '4701005�',
    display:
      'Colony forming unit-granulocyte-monocyte-erythroid-megakaryocyte assay',
  },
  { code: '6190006�', display: 'Colopexy' },
  { code: '1337002�', display: 'Colosigmoidostomy' },
  { code: '4770005�', display: 'Colporrhaphy for repair of urethrocele' },
  { code: '5154007�', display: 'Communication enhancement: speech deficit' },
  { code: '2220009�', display: 'Complement component assay' },
  { code: '5857002�', display: 'Complement mediated cytotoxicity assay' },
  { code: '3061006�', display: 'Complete excision of nail AND nail matrix' },
  { code: '6502003�', display: 'Complete lower denture' },
  { code: '4341004�', display: 'Complete submucous resection of turbinate' },
  { code: '6543003�', display: 'Complicated catheterization of bladder' },
  { code: '2344008�', display: 'Complicated cystorrhaphy' },
  {
    code: '3891000�',
    display:
      'Comprehensive orthodontic treatment, permanent dentition, for class I malocclusion',
  },
  {
    code: '762008�',
    display: 'Computerized axial tomography of cervical spine with contrast',
  },
  { code: '6339003�', display: 'Conditioning play audiometry' },
  { code: '4044002�', display: 'Construction of permanent colostomy' },
  { code: '4895001�', display: 'Construction of sigmoid bladder' },
  {
    code: '2002009�',
    display:
      'Construction of subcutaneous tunnel without esophageal anastomosis',
  },
  { code: '4116001�', display: 'Construction of window' },
  {
    code: '5947002�',
    display: 'Consultation for hearing and/or speech problem',
  },
  { code: '3735002�', display: 'Coproporphyrin III measurement' },
  {
    code: '4594007�',
    display: 'Coproporphyrin isomers, series I & III, urine',
  },
  { code: '5452002�', display: 'Core needle biopsy of thymus' },
  { code: '4581005�', display: 'Coreoplasty' },
  { code: '503003�', display: 'Corneal gluing' },
  {
    code: '3241008�',
    display: 'Correction of chordee with mobilization of urethra',
  },
  { code: '2270007�', display: 'Correction of cleft hand' },
  { code: '1704001�', display: 'Correction of tibial pseudoarthrosis' },
  { code: '374009�', display: 'Costosternoplasty for pectus excavatum repair' },
  {
    code: '789003�',
    display: 'Cranial decompression, subtemporal, supratentorial',
  },
  {
    code: '2968008�',
    display: 'Craniectomy with treatment of penetrating wound of brain',
  },
  {
    code: '3407002�',
    display: 'Creation of lesion of spinal cord by percutaneous method',
  },
  {
    code: '2564002�',
    display: 'Creation of lumbar shunt including laminectomy',
  },
  { code: '4579008�', display: 'Creation of ventriculoatrial shunt' },
  { code: '5694008�', display: 'Crisis intervention with follow-up' },
  { code: '4344007�', display: 'Cryopexy' },
  { code: '1813006�', display: 'Cryotherapy of genital warts' },
  { code: '4756005�', display: 'Cryotherapy of subcutaneous tissue' },
  { code: '2908005�', display: 'Cryotherapy to hemorrhoid' },
  { code: '6486000�', display: 'Curettage of sclera' },
  { code: '2214008�', display: 'Curette test of skin' },
  { code: '3546002�', display: 'CVG - Coronary vein graft' },
  { code: '4475004�', display: 'Cyanide level' },
  { code: '3942005�', display: 'Cystopexy' },
  {
    code: '153001�',
    display: 'Cystourethroscopy with resection of ureterocele',
  },
  {
    code: '1041009�',
    display: 'Debridement of open fracture of phalanges of foot',
  },
  {
    code: '2480009�',
    display: 'Debridement of skin, subcutaneous tissue, muscle and bone',
  },
  {
    code: '4507006�',
    display:
      'Decompression fasciotomy of wrist, flexor and extensor compartment',
  },
  { code: '4585001�', display: 'Decompression of tendon of hand' },
  { code: '1115001�', display: 'Decortication' },
  { code: '4443002�', display: 'Decortication of ovary' },
  {
    code: '4165006�',
    display: 'Delayed hypersensitivity skin test for staphage lysate',
  },
  {
    code: '3784005�',
    display:
      'Delayed hypersensitivity skin test for streptokinase-streptodornase',
  },
  { code: '5486006�', display: 'Delayed suture of tendon of hand' },
  { code: '2321005�', display: 'Delivery by Ritgen maneuver' },
  {
    code: '4636001�',
    display: 'Denker operation for radical maxillary antrotomy',
  },
  { code: '2115003�', display: 'Dental prophylaxis, children' },
  { code: '2069003�', display: 'Dental subperiosteal implant' },
  {
    code: '2898000�',
    display: 'Deoxyribonucleic acid analysis, antenatal, blood',
  },
  { code: '2716009�', display: 'Dermal-fat-fascia graft' },
  { code: '2742009�', display: "Destruction of Bartholin's gland" },
  { code: '6634001�', display: 'Destruction of hemorrhoids, internal' },
  { code: '5415002�', display: 'Destruction of lesion of liver' },
  { code: '6439004�', display: 'Destruction of lesion of peripheral nerve' },
  { code: '4323007�', display: 'Destruction of lesion of sclera' },
  { code: '6562000�', display: 'Destruction of lesion of tongue' },
  {
    code: '2459003�',
    display: 'Destructive procedure of artery of upper extremity',
  },
  { code: '2486003�', display: 'Destructive procedure of breast' },
  {
    code: '695009�',
    display: 'Destructive procedure of lesion on skin of trunk',
  },
  { code: '3204007�', display: 'Destructive procedure of nerve' },
  {
    code: '6286002�',
    display: 'Destructive procedure on ovaries and fallopian tubes',
  },
  { code: '6143009�', display: 'Diabetic education' },
  { code: '2347001�', display: 'Diagnostic model construction' },
  {
    code: '2903001�',
    display: 'Diagnostic procedure on anterior chamber of eye',
  },
  { code: '2670001�', display: 'Diagnostic procedure on cornea' },
  { code: '6568001�', display: 'Diagnostic procedure on eyelid' },
  { code: '5429001�', display: 'Diagnostic procedure on nipple' },
  { code: '4660002�', display: 'Diagnostic procedure on phalanges of foot' },
  { code: '5489004�', display: 'Diagnostic procedure on radius' },
  { code: '5135007�', display: 'Diagnostic procedure on scapula' },
  { code: '645008�', display: 'Diagnostic procedure on vitreous' },
  {
    code: '6127002�',
    display: 'Diagnostic radiography of abdomen, oblique standard',
  },
  { code: '6238009�', display: 'Diagnostic radiography of sacroiliac joints' },
  { code: '5846007�', display: 'Diagnostic radiography of toes' },
  { code: '5823005�', display: 'Diagnostic radiography, left' },
  {
    code: '1044001�',
    display: 'Diagnostic ultrasound of abdomen and retroperitoneum',
  },
  {
    code: '4904006�',
    display: 'Dilation of anal sphincter under nonlocal anesthesia',
  },
  { code: '6363006�', display: 'Dilation of lacrimal punctum with irrigation' },
  { code: '3717006�', display: 'Dilute Russell viper venom time' },
  { code: '1683003�', display: 'Direct closure of laceration of conjunctiva' },
  {
    code: '5551006�',
    display:
      'Direct laryngoscopy with arytenoidectomy with operating microscope',
  },
  { code: '5019003�', display: 'Direct laryngoscopy with biopsy' },
  {
    code: '1398005�',
    display: 'Direct thrombectomy of iliac vein by leg incision',
  },
  { code: '401004�', display: 'Distal subtotal pancreatectomy' },
  {
    code: '6399001�',
    display: 'Division of arteriovenous fistula with ligation',
  },
  { code: '5961007�', display: 'Division of blood vessels of cornea' },
  { code: '1329005�', display: 'Division of congenital web of larynx' },
  { code: '245002�', display: 'Division of nerve ganglion' },
  { code: '5165002�', display: 'Division of thoracic artery' },
  { code: '1043007�', display: 'Doppler color flow velocity mapping' },
  { code: '1691007�', display: 'Drainage of abscess of tonsil' },
  {
    code: '5997003�',
    display: 'Drainage of cerebral subarachnoid space by aspiration',
  },
  { code: '4045001�', display: 'Drainage of cerebral ventricle by incision' },
  { code: '1950008�', display: 'Drainage of nasal septal abscess' },
  { code: '831000�', display: 'Drawer test' },
  { code: '3895009�', display: 'Dressing' },
  { code: '791006�', display: 'Dressing and fixation procedure' },
  { code: '967006�', display: 'Drug treatment education' },
  {
    code: '3971006�',
    display: 'Duchenne muscular dystrophy carrier detection',
  },
  { code: '5304008�', display: 'DXT - Radiotherapy' },
  { code: '3858009�', display: 'Echography of thyroid, A-mode' },
  { code: '6271008�', display: 'Echography, immersion B-scan' },
  {
    code: '1385001�',
    display: 'Echography, scan B-mode for foetal age determination',
  },
  { code: '2458006�', display: 'Educational therapy' },
  { code: '1267006�', display: 'Electrocoagulation of lesion of vagina' },
  { code: '814007�', display: 'Electrophoresis measurement' },
  { code: '6615001�', display: 'Electroretinography with medical evaluation' },
  { code: '670008�', display: 'Electrosurgical epilation of eyebrow' },
  {
    code: '5787001�',
    display: 'Elevation of bone fragments of orbit of skull with debridement',
  },
  {
    code: '926001�',
    display: 'Embolectomy with catheter of radial artery by arm incision',
  },
  {
    code: '3078002�',
    display: 'Embolectomy with catheter of renal artery by abdominal incision',
  },
  { code: '3819004�', display: 'Embolization of thoracic artery' },
  { code: '3257008�', display: 'Empty and measure peritoneal dialysis fluid' },
  { code: '5845006�', display: 'Emulsification procedure' },
  { code: '1801001�', display: 'Endoscopic biopsy of duodenum' },
  { code: '4363008�', display: 'Endoscopic brush biopsy of trachea' },
  { code: '1678006�', display: 'Endoscopic procedure of nerve' },
  {
    code: '6157006�',
    display: 'Endoscopic retrograde cholangiopancreatography with biopsy',
  },
  { code: '4713000�', display: 'Endoscopy and photography' },
  { code: '6019008�', display: 'Endoscopy of large intestine' },
  { code: '941001�', display: 'Endoscopy of pituitary gland' },
  { code: '5105000�', display: 'Endoscopy of renal pelvis' },
  { code: '6444006�', display: 'Enlargement of eye socket' },
  { code: '1032004�', display: 'Enucleation of parotid gland cyst' },
  { code: '5119000�', display: 'Epiglottidectomy' },
  { code: '3190002�', display: 'Epilation by forceps' },
  {
    code: '1238005�',
    display: 'Epiphyseal arrest by stapling of distal radius',
  },
  { code: '4587009�', display: 'Epiphysiodesis of distal radius' },
  { code: '2745006�', display: 'Epiplopexy' },
  { code: '2425002�', display: 'Epstein-Barr virus serologic test' },
  { code: '3700004�', display: 'Erysophake extraction of lens' },
  { code: '474008�', display: 'Esophagoenteric anastomosis, intrathoracic' },
  { code: '1820004�', display: 'Ethanol measurement, breath' },
  { code: '4902005�', display: 'Ethchlorvynol measurement' },
  {
    code: '5745003�',
    display: 'Excision ampulla of Vater with reimplantation of common duct',
  },
  { code: '1093006�', display: 'Excision of abdominal varicose vein' },
  { code: '6189002�', display: 'Excision of aberrant tissue of breast' },
  { code: '1299000�', display: 'Excision of appendiceal stump' },
  { code: '3915009�', display: 'Excision of artery of thorax and abdomen' },
  {
    code: '6274000�',
    display: 'Excision of aural glomus tumor, extended, extratemporal',
  },
  { code: '807005�', display: 'Excision of brain' },
  { code: '935008�', display: 'Excision of bulbourethral gland' },
  {
    code: '5446003�',
    display:
      'Excision of cervical rib for outlet compression syndrome with sympathectomy',
  },
  { code: '647000�', display: 'Excision of cervix by electroconization' },
  { code: '6402000�', display: 'Excision of common bile duct' },
  { code: '4304000�', display: 'Excision of cusp of tricuspid valve' },
  { code: '4539004�', display: 'Excision of cyst of bronchus' },
  { code: '6535008�', display: 'Excision of cyst of hand' },
  { code: '817000�', display: 'Excision of cyst of spleen' },
  {
    code: '1836002�',
    display: 'Excision of diverticulum of ventricle of heart',
  },
  {
    code: '3326006�',
    display: 'Excision of exostosis of head of fifth metatarsal',
  },
  { code: '5796001�', display: 'Excision of external thrombotic hemorrhoid' },
  { code: '2547000�', display: 'Excision of keloid' },
  { code: '4671001�', display: 'Excision of lesion from sphenoid sinus' },
  { code: '550007�', display: 'Excision of lesion of adenoids' },
  { code: '5147001�', display: 'Excision of lesion of ankle joint' },
  {
    code: '5571002�',
    display: 'Excision of lesion of aorta with end-to-end anastomosis',
  },
  { code: '189009�', display: 'Excision of lesion of artery' },
  { code: '6161000�', display: 'Excision of lesion of capsule of toes' },
  { code: '2199005�', display: 'Excision of lesion of cul-de-sac' },
  { code: '6200005�', display: 'Excision of lesion of female perineum' },
  { code: '3880007�', display: 'Excision of lesion of fibula' },
  {
    code: '5212002�',
    display: 'Excision of lesion of lacrimal gland by frontal approach',
  },
  { code: '104001�', display: 'Excision of lesion of patella' },
  { code: '3786007�', display: 'Excision of lesion of pharynx' },
  { code: '4226002�', display: 'Excision of lesion of thoracic vein' },
  { code: '494004�', display: 'Excision of lesion of tonsil' },
  { code: '3654008�', display: 'Excision of lingula' },
  {
    code: '5621009�',
    display: 'Excision of malignant lesion of skin of extremities',
  },
  { code: '2407009�', display: 'Excision of mediastinal tumor' },
  { code: '1258006�', display: 'Excision of meniscus of wrist' },
  { code: '5384005�', display: 'Excision of part of frontal cortex' },
  { code: '2137005�', display: 'Excision of pericardial tumor' },
  { code: '1084005�', display: 'Excision of perinephric cyst' },
  { code: '5162004�', display: 'Excision of pressure ulcer' },
  {
    code: '3796003�',
    display: 'Excision of redundant mucosa from jejunostomy',
  },
  { code: '3186004�', display: 'Excision of Skene gland' },
  {
    code: '4829004�',
    display: 'Excision of small intestine for interposition',
  },
  { code: '3968003�', display: 'Excision of spinal facet joint' },
  {
    code: '4544006�',
    display: 'Excision of subcutaneous tumor of extremities',
  },
  { code: '1181000�', display: 'Excision of tendon for graft' },
  { code: '1651009�', display: 'Excision of tendon sheath' },
  { code: '945005�', display: 'Excision of tibia and fibula for graft' },
  { code: '2171001�', display: 'Excision of tonsil tags' },
  {
    code: '6419003�',
    display: 'Excision of tumor from elbow area, deep, subfascial',
  },
  {
    code: '142007�',
    display: 'Excision of tumor from shoulder area, deep, intramuscular',
  },
  {
    code: '4467005�',
    display: 'Excision of tumor of ankle area, deep, intramuscular',
  },
  { code: '559008�', display: 'Excision of varicose vein' },
  { code: '1198000�', display: 'Excisional biopsy of bone of scapula' },
  { code: '294002�', display: 'Excisional biopsy of joint structure of spine' },
  { code: '3917001�', display: 'Excisional biopsy of phalanges of foot' },
  { code: '3864002�', display: 'Excisional biopsy of scrotum' },
  { code: '5608002�', display: 'Excretion measurement' },
  { code: '6438007�', display: 'Exploration of ciliary body' },
  { code: '6385000�', display: 'Exploration of disc space' },
  {
    code: '5393006�',
    display:
      'Exploration of parathyroid with mediastinal exploration by sternal split approach',
  },
  { code: '2276001�', display: 'Exploration of popliteal artery' },
  { code: '4466001�', display: 'Exploration of upper limb artery' },
  { code: '2737001�', display: 'Exploratory craniotomy, infratentorial' },
  { code: '5048009�', display: 'External cephalic version with tocolysis' },
  {
    code: '4877004�',
    display: 'Extracapsular extraction of lens with iridectomy',
  },
  { code: '4966006�', display: 'Extracorporeal perfusion' },
  { code: '1868005�', display: 'FB - Removal of foreign body from brain' },
  { code: '6309008�', display: 'Fecal fat differential, quantitative' },
  { code: '853003�', display: 'Fecal fat measurement, 72-hour collection' },
  { code: '1784004�', display: 'Fecal stercobilin, qualitative' },
  { code: '5807005�', display: 'Fenestration of inner ear, initial' },
  {
    code: '6100001�',
    display: 'Fenestration of stapes footplate with vein graft',
  },
  { code: '489004�', display: 'Ferritin level' },
  { code: '1029002�', display: 'Fetoscopy' },
  { code: '6668007�', display: 'Fibrinogen assay, quantitative' },
  { code: '722009�', display: 'Fine needle biopsy of thymus' },
  { code: '5636007�', display: 'Fistulectomy of bone' },
  { code: '115006�', display: 'Fit removable orthodontic appliance' },
  {
    code: '4083000�',
    display: 'Fitting of prosthesis or prosthetic device of lower arm',
  },
  {
    code: '3669009�',
    display: 'Fitting of prosthesis or prosthetic device of upper arm',
  },
  { code: '6126006�', display: 'Fitzgerald factor assay' },
  { code: '3448004�', display: 'Fixation of contralateral testis' },
  { code: '5777000�', display: 'Flexorplasty of elbow' },
  { code: '5818005�', display: 'Flocculation test' },
  { code: '3967008�', display: 'Fluorescence polarization immunoassay' },
  { code: '1879000�', display: 'Fluorescent antigen measurement' },
  { code: '6205000�', display: 'Fluorescent antigen, titer' },
  {
    code: '5733007�',
    display: 'Fluorescent identification of anti-nuclear antibody',
  },
  { code: '5785009�', display: 'Forensic autopsy' },
  { code: '6343004�', display: 'Forensic bite mark comparison technique' },
  { code: '3060007�', display: 'Forequarter amputation, right' },
  { code: '4176005�', display: 'Fothergill repair' },
  { code: '618007�', display: 'Frontal sinusectomy' },
  { code: '5113004�', display: 'FT4 - Free thyroxine level' },
  { code: '406009�', display: 'Fulguration of stomach lesion' },
  { code: '3162001�', display: 'Gadolinium measurement' },
  { code: '6396008�', display: 'Galactokinase measurement' },
  { code: '6159009�', display: 'Galactose measurement' },
  {
    code: '1616001�',
    display: 'Galactosylceramide beta-galactosidase measurement, leukocytes',
  },
  { code: '2842000�', display: 'Gas chromatography measurement' },
  {
    code: '2196003�',
    display: 'Gas liquid chromatography, electron capture type',
  },
  {
    code: '5995006�',
    display: 'Gas liquid chromatography, flame photometric type',
  },
  { code: '3063009�', display: 'Gastroscopy through artificial stoma' },
  { code: '468008�', display: 'Glutathione measurement' },
  { code: '5456004�', display: 'Graft of lymphatic structure' },
  { code: '6231003�', display: 'Graft of palate' },
  { code: '5034009�', display: 'Graft to hair-bearing skin' },
  {
    code: '1958001�',
    display: 'Grafting of bone of thumb with transfer of skin flap',
  },
  { code: '1677001�', display: 'Haagensen test' },
  { code: '128004�', display: 'Hand microscope examination of skin' },
  { code: '1859008�', display: 'Hand tendon foreign body removed' },
  {
    code: '6487009�',
    display: 'Hand tendon pulley reconstruction with tendon prosthesis',
  },
  { code: '174000�', display: 'Harrison-Richardson operation on vagina' },
  { code: '5731009�', display: 'Hemosiderin, quantitative measurement' },
  {
    code: '2696004�',
    display: 'Hepatic venography with hemodynamic evaluation',
  },
  { code: '1924009�', display: 'Hepaticotomy with drainage' },
  { code: '697001�', display: 'Hepatitis A virus antibody measurement' },
  { code: '6429005�', display: 'Heteroautogenous transplantation' },
  {
    code: '2408004�',
    display: 'Hexosaminidase A and total hexosaminidase measurement, serum',
  },
  { code: '5902003�', display: 'History and physical examination, complete' },
  { code: '1640009�', display: 'History and physical examination, insurance' },
  { code: '4337003�', display: 'Homogentisic acid measurement' },
  { code: '1505002�', display: 'Hospital admission for isolation' },
  {
    code: '4563007�',
    display:
      'Hospital admission, transfer from other hospital or health care facility',
  },
  {
    code: '2876009�',
    display: 'Hospital admission, type unclassified, explain by report',
  },
  { code: '2252009�', display: 'Hospital admission, urgent, 48 hours' },
  { code: '417005�', display: 'Hospital re-admission' },
  { code: '867007�', display: 'Hypoglossofacial anastomosis' },
  {
    code: '1713004�',
    display: 'Hypothermia, total body, induction and maintenance',
  },
  { code: '4691008�', display: 'Identification of rotavirus antigen in feces' },
  { code: '1328002�', display: 'Ilioiliac shunt' },
  { code: '1712009�', display: 'Immunoglobulin typing, immunoglobulin G' },
  { code: '4131005�', display: 'Implantation into pelvic region' },
  {
    code: '1577009�',
    display:
      'Implantation of cardiac single-chamber device replacement, rate-responsive',
  },
  { code: '2067001�', display: 'Implantation of electromagnetic hearing aid' },
  {
    code: '4027001�',
    display: 'Implantation of electronic stimulator into phrenic nerve',
  },
  { code: '2875008�', display: 'Implantation of joint prosthesis of elbow' },
  { code: '2613006�', display: 'Implantation of joint prosthesis of hand' },
  { code: '3270004�', display: 'Implantation of neurostimulator in spine' },
  { code: '4213001�', display: 'Implantation of Ommaya reservoir' },
  {
    code: '6536009�',
    display: 'Implantation of tricuspid valve with tissue graft',
  },
  { code: '5971009�', display: 'Incision and drainage of axilla' },
  {
    code: '5298004�',
    display: 'Incision and drainage of deep hematoma of thigh region',
  },
  { code: '5925002�', display: 'Incision and drainage of hematoma of wrist' },
  {
    code: '2632000�',
    display: 'Incision and drainage of infected bursa of upper arm',
  },
  { code: '5290006�', display: 'Incision and drainage of Ludwig angina' },
  {
    code: '2673004�',
    display: 'Incision and drainage of masticator space by extraoral approach',
  },
  {
    code: '4420006�',
    display:
      'Incision and drainage of parapharyngeal abscess by external approach',
  },
  { code: '4154001�', display: 'Incision and drainage of penis' },
  { code: '4333004�', display: 'Incision and drainage of perisplenic space' },
  {
    code: '3907006�',
    display: 'Incision and drainage of retroperitoneal abscess',
  },
  {
    code: '3881006�',
    display: 'Incision and drainage of submental space by extraoral approach',
  },
  { code: '285008�', display: 'Incision and drainage of vulva' },
  { code: '5506006�', display: 'Incision and exploration of abdominal wall' },
  { code: '969009�', display: 'Incision and exploration of larynx' },
  { code: '1399002�', display: 'Incision and exploration of ureter' },
  { code: '2646000�', display: 'Incision and exploration of vas deferens' },
  { code: '2552005�', display: 'Incision of cerebral subarachnoid space' },
  { code: '1176009�', display: 'Incision of inguinal region' },
  { code: '3659003�', display: 'Incision of inner ear' },
  { code: '549007�', display: 'Incision of intracranial vein' },
  { code: '5572009�', display: 'Incision of kidney pelvis' },
  { code: '4820000�', display: 'Incision of labial frenum' },
  { code: '2442008�', display: 'Incision of lacrimal canaliculus' },
  { code: '1854003�', display: 'Incision of nose' },
  { code: '4535005�', display: 'Incision of pelvirectal tissue' },
  { code: '5690004�', display: 'Incision of seminal vesicle' },
  { code: '730005�', display: 'Incision of subcutaneous tissue' },
  {
    code: '1227005�',
    display:
      'Incision of subvalvular tissue for discrete subvalvular aortic stenosis',
  },
  { code: '3328007�', display: 'Incision of vein of head and neck' },
  { code: '1251000�', display: 'Incisional biopsy of testis' },
  { code: '2752008�', display: 'Incudopexy' },
  { code: '4758006�', display: 'Incudostapediopexy' },
  { code: '673005�', display: 'Indirect examination of larynx' },
  { code: '4143006�', display: 'Injection into anterior chamber of eye' },
  { code: '2164006�', display: 'Injection of aorta' },
  { code: '5407000�', display: 'Injection of fallopian tube' },
  {
    code: '2536007�',
    display: 'Injection of medication in anterior chamber of eye',
  },
  {
    code: '1636000�',
    display: 'Injection of sclerosing agent in varicose vein',
  },
  {
    code: '4285000�',
    display: 'Insertion of bone growth stimulator into femur',
  },
  {
    code: '1870001�',
    display:
      'Insertion of halo device of skull with synchronous skeletal traction',
  },
  { code: '6555009�', display: 'Insertion of infusion pump beneath skin' },
  {
    code: '1036001�',
    display: 'Insertion of intravascular device in common iliac vein, complete',
  },
  { code: '4457000�', display: 'Insertion of mold into vagina' },
  {
    code: '4765003�',
    display:
      'Insertion of ocular implant following or secondary to enucleation',
  },
  {
    code: '1906007�',
    display:
      'Insertion of prosthesis or prosthetic device of arm, bioelectric or cineplastic',
  },
  { code: '3559005�', display: 'Insertion of ureteral stent with ureterotomy' },
  { code: '2645001�', display: 'Intelligence test/WB1' },
  { code: '1559004�', display: 'Interleukin (IL)-2 assay' },
  { code: '2722000�', display: 'Interleukin-3 assay' },
  {
    code: '1653007�',
    display: 'Internal fixation of bone without fracture reduction',
  },
  {
    code: '2021001�',
    display: 'Internal fixation of radius and ulna without fracture reduction',
  },
  { code: '3177009�', display: 'Internal obstetrical version' },
  { code: '1127003�', display: 'Interstitial radium application' },
  {
    code: '4214007�',
    display: 'Intracardiac injection for cardiac resuscitation',
  },
  { code: '759005�', display: 'Intracerebral electroencephalogram' },
  { code: '4119008�', display: 'Intracranial phlebectomy with anastomosis' },
  {
    code: '2629003�',
    display: 'Intracranial/cerebral perfusion pressure monitoring',
  },
  { code: '2885009�', display: 'Intradermal allergen test' },
  { code: '4516005�', display: 'Iridencleisis and iridotasis' },
  { code: '3357006�', display: 'Iron kinetics' },
  { code: '3165004�', display: 'Irrigation of muscle of hand' },
  { code: '4734007�', display: 'Irrigation of wound catheter of integument' },
  {
    code: '4993009�',
    display:
      'Ischemic limb exercise with electromyography and lactic acid determination',
  },
  { code: '353008�', display: 'IV/irrigation monitoring' },
  { code: '4764004�', display: 'Jet ventilation procedure' },
  { code: '6063004�', display: 'Kanamycin measurement' },
  { code: '6307005�', display: 'Keratophakia' },
  { code: '1121002�', display: 'Kinetic activities for range of motion' },
  { code: '6661001�', display: 'King-Steelquist hindquarter operation' },
  { code: '3047001�', display: 'Kowa fundus photography' },
  {
    code: '5032008�',
    display: 'Labial veneer, porcelain laminate, laboratory',
  },
  { code: '1702002�', display: 'Labial veneer, resin laminate, laboratory' },
  { code: '5812006�', display: 'Laboratory reporting, fax' },
  { code: '3926003�', display: 'Lactate measurement' },
  { code: '6470007�', display: 'Laparoamnioscopy' },
  { code: '6025007�', display: 'Laparoscopic appendectomy' },
  { code: '1390003�', display: 'Laparoscopic sigmoid colectomy' },
  { code: '3324009�', display: 'Laser beam photocoagulation' },
  { code: '5233006�', display: 'Lateral fasciotomy' },
  { code: '3673007�', display: 'Leadbetter urethral reconstruction' },
  { code: '1585000�', display: 'Lengthening of gastrocnemius muscle' },
  { code: '6403005�', display: 'Lengthening of muscle of hand' },
  { code: '5357009�', display: 'Leukocyte poor blood preparation' },
  { code: '6337001�', display: 'Ligation of aortic arch' },
  { code: '4533003�', display: 'Ligation of artery of lower limb' },
  {
    code: '4640005�',
    display: 'Ligation of fallopian tubes by abdominal approach',
  },
  { code: '2643008�', display: 'Ligation of varicose vein of head and neck' },
  { code: '2393003�', display: 'Ligation of vein of lower limb' },
  {
    code: '5821007�',
    display:
      'Ligation, division and complete stripping of long and short saphenous veins',
  },
  { code: '4727005�', display: 'Lipoprotein electrophoresis' },
  { code: '4974007�', display: 'Liver operation' },
  {
    code: '4336007�',
    display: 'Lloyd-Davies operation, abdominoperineal resection',
  },
  { code: '6119006�', display: 'Local destruction of lesion of bony palate' },
  { code: '1689004�', display: 'Local excision of ovary' },
  { code: '4929000�', display: 'Local perfusion of kidney' },
  { code: '3450007�', display: 'Lymphocytes, T & B cell evaluation' },
  { code: '6133006�', display: 'Lymphokine assay' },
  { code: '5022001�', display: 'Lysergic acid diethylamide measurement' },
  { code: '3278006�', display: 'Lysis of adhesions of bursa of hand' },
  { code: '5789003�', display: 'Lysis of adhesions of intestines' },
  { code: '637003�', display: 'Lysis of adhesions of nose' },
  { code: '5419008�', display: 'Lysis of adhesions of tendon of hand' },
  { code: '629006�', display: 'Lysis of adhesions of urethra' },
  {
    code: '5671007�',
    display: 'Magnet extraction of foreign body from ciliary body',
  },
  { code: '6007000�', display: 'Magnetic resonance imaging of chest' },
  { code: '6657007�', display: 'Maintenance drug therapy for mental disorder' },
  { code: '3936006�', display: 'Making Foster bed' },
  { code: '1669000�', display: 'Making occupied bed' },
  { code: '4424002�', display: 'Making orthopedic bed' },
  { code: '753006�', display: 'Manipulation of ankle AND foot' },
  { code: '4449003�', display: 'Manipulation of spinal meninges' },
  { code: '5402006�', display: 'Manipulation of thoracic artery' },
  { code: '2802005�', display: 'Manual dilation and stretching' },
  { code: '1339004�', display: 'Manual evacuation of feces' },
  {
    code: '5273000�',
    display: 'Manual reduction of closed fracture of proximal end of ulna',
  },
  {
    code: '2514009�',
    display:
      'Manual reduction of closed supracondylar fracture of humerus with traction',
  },
  { code: '5151004�', display: 'Manual reduction of rectal hemorrhoids' },
  { code: '5556001�', display: 'Manually assisted spontaneous delivery' },
  { code: '5892005�', display: 'Mastoid antrotomy' },
  { code: '3251009�', display: 'Mayo operation, herniorrhaphy' },
  { code: '1347004�', display: 'Medical procedure on palate' },
  { code: '721002�', display: 'Medical procedure on periurethral tissue' },
  { code: '4672008�', display: 'Medical procedure on the nervous system' },
  {
    code: '2970004�',
    display: 'Metacarpal lengthening and transfer of local flap',
  },
  { code: '5894006�', display: 'Methyl red test' },
  {
    code: '4436008�',
    display: 'Methylatable chemotaxis protein (MCP) receptor measurement',
  },
  { code: '910002�', display: 'MHPG measurement, urine' },
  {
    code: '2290003�',
    display: 'Microbial culture, anaerobic, initial isolation',
  },
  { code: '4804005�', display: 'Microbial identification test' },
  { code: '5992009�', display: 'Midtarsal arthrodesis, multiple' },
  { code: '230009�', display: 'Miller operation, urethrovesical suspension' },
  {
    code: '1035002�',
    display: 'Minimum bactericidal concentration test, microdilution method',
  },
  { code: '1770009�', display: 'Mitral valvotomy' },
  { code: '6353003�', display: 'Mitsuda reaction to lepromin' },
  { code: '197002�', display: 'Mold to yeast conversion test' },
  {
    code: '3130004�',
    display: 'Monitoring of cardiac output by electrocardiogram',
  },
  { code: '2531002�', display: 'Mononuclear cell function assay' },
  { code: '3651000�', display: 'Morphometric analysis, nerve' },
  { code: '4496006�', display: 'Mouthcare procedure' },
  { code: '1292009�', display: 'MRI of bladder' },
  { code: '2690005�', display: 'MRI of pelvis' },
  { code: '3991004�', display: 'MRI of pelvis, prostate and bladder' },
  { code: '1235008�', display: 'Muscle transfer' },
  { code: '6397004�', display: 'Muscular strength development exercise' },
  { code: '4348005�', display: 'Musculoplasty of hand' },
  { code: '4737000�', display: 'Mycobacteria culture' },
  { code: '1774000�', display: 'Nasopharyngeal rehabilitation' },
  { code: '5130002�', display: 'Needling of lens for cataract' },
  { code: '6166005�', display: 'Nephropyeloureterostomy' },
  { code: '2813008�', display: 'Nephroureterocystectomy' },
  { code: '4489009�', display: 'Neurolysis of trigeminal nerve' },
  { code: '5338000�', display: 'Nicotine measurement' },
  {
    code: '295001�',
    display: 'Nonexcisional destruction of cyst of ciliary body',
  },
  {
    code: '3075004�',
    display: 'Nonoperative removal of prosthesis of bile duct',
  },
  {
    code: '5986003�',
    display: 'Non-sensitized spontaneous sheep erythrocyte binding, E-rosette',
  },
  { code: '4487006�', display: 'Norepinephrine measurement, supine' },
  { code: '2494005�', display: 'Nurse to nurse communication' },
  { code: '2866006�', display: 'Obliteration of lymphatic structure' },
  { code: '4010009�', display: 'Oophorectomy of remaining ovary with tube' },
  { code: '5632009�', display: 'Open biopsy of bronchus' },
  {
    code: '3828003�',
    display: 'Open biopsy of vertebral body of thoracic region',
  },
  { code: '2598006�', display: 'Open heart surgery' },
  {
    code: '531007�',
    display: 'Open pulmonary valve commissurotomy with inflow occlusion',
  },
  {
    code: '3164000�',
    display:
      'Open reduction of closed mandibular fracture with interdental fixation',
  },
  {
    code: '1278003�',
    display:
      'Open reduction of closed shoulder dislocation with fracture of greater tuberosity',
  },
  { code: '5865004�', display: 'Open reduction of dislocation of toe' },
  {
    code: '5521000�',
    display: 'Open reduction of fracture of phalanges of foot',
  },
  { code: '4455008�', display: 'Open reduction of open elbow dislocation' },
  {
    code: '2098004�',
    display:
      'Open reduction of open mandibular fracture with external fixation',
  },
  { code: '3338002�', display: 'Open reduction of open radial shaft fracture' },
  { code: '1830008�', display: 'Open reduction of open sacral fracture' },
  {
    code: '2181002�',
    display: 'Operation for bone injury of tarsals and metatarsals',
  },
  { code: '2607002�', display: 'Operation of supporting structures of uterus' },
  { code: '657004�', display: 'Operation on bursa' },
  { code: '1417002�', display: 'Operation on face' },
  {
    code: '6519001�',
    display:
      'Operation on multiple extraocular muscles with temporary detachment from globe',
  },
  { code: '5781000�', display: 'Operation on nasal septum' },
  { code: '1008006�', display: 'Operation on nerve ganglion' },
  { code: '5282006�', display: 'Operation on oropharynx' },
  { code: '1411001�', display: 'Operation on papillary muscle of heart' },
  { code: '741007�', display: 'Operation on prostate' },
  { code: '4784000�', display: 'Operation on sublingual gland' },
  { code: '3963007�', display: 'Operation on submaxillary gland' },
  { code: '2161003�', display: 'Operation on vulva' },
  { code: '4134002�', display: 'Operative block anesthesia' },
  { code: '2743004�', display: 'Operative endoscopy of ileum' },
  { code: '6240004�', display: 'Operative procedure on knee' },
  { code: '545001�', display: 'Operative procedure on lower leg' },
  { code: '1494008�', display: 'Osmolarity measurement' },
  { code: '6164008�', display: 'Osteoclasis of clavicle' },
  { code: '4987001�', display: 'Osteoplasty of cranium with flap of bone' },
  { code: '2780005�', display: 'Osteoplasty of facial bones' },
  { code: '2566000�', display: 'Osteoplasty of radius' },
  { code: '5760000�', display: 'Osteoplasty of radius and ulna, shortening' },
  { code: '5616006�', display: 'Osteoplasty of tibia' },
  { code: '5184004�', display: 'Osteotomy of shaft of femur with fixation' },
  { code: '4849008�', display: 'Ovarian biopsy' },
  { code: '6069000�', display: 'Panniculotomy' },
  { code: '334003�', display: 'Panorex examination of mandible' },
  {
    code: '4114003�',
    display: 'Parenteral chemotherapy for malignant neoplasm',
  },
  { code: '316002�', display: 'Partial dacryocystectomy' },
  { code: '4707009�', display: 'Partial excision of calcaneus' },
  { code: '3980006�', display: 'Partial excision of esophagus' },
  {
    code: '665001�',
    display: 'Partial meniscectomy of temporomandibular joint',
  },
  { code: '5832007�', display: 'Partial ostectomy of thorax, ribs or sternum' },
  { code: '1042002�', display: 'Paternity testing' },
  {
    code: '726007�',
    display:
      'Pathology consultation, comprehensive, records and specimen with report',
  },
  { code: '3133002�', display: 'Patient discharge, deceased, autopsy' },
  {
    code: '1917008�',
    display: 'Patient discharge, deceased, medicolegal case',
  },
  {
    code: '4266003�',
    display: 'Patient referral for drug addiction rehabilitation',
  },
  { code: '3929005�', display: 'Patient transfer, in-hospital, bed-to-bed' },
  { code: '1889001�', display: 'Patient transfer, in-hospital, unit-to-unit' },
  { code: '1413003�', display: 'Penetrating keratoplasty with homograft' },
  { code: '262007�', display: 'Percutaneous aspiration of renal pelvis' },
  { code: '4052004�', display: 'Percutaneous aspiration of spinal cord cyst' },
  { code: '4694000�', display: 'Percutaneous biopsy of muscle' },
  {
    code: '133000�',
    display:
      'Percutaneous implantation of neurostimulator electrodes into neuromuscular component',
  },
  { code: '5431005�', display: 'Percutaneous transluminal angioplasty' },
  { code: '6082008�', display: 'Perforation of footplate' },
  {
    code: '346004�',
    display: 'Periodontal scaling and root planing, per quadrant',
  },
  {
    code: '3029007�',
    display: 'Peripheral nervous system disease rehabilitation',
  },
  { code: '5091004�', display: 'Peripheral vascular disease study' },
  { code: '676002�', display: 'Peritoneal dialysis including cannulation' },
  { code: '1431002�', display: 'pexy' },
  { code: '948007�', display: 'Phlebectomy of intracranial varicose vein' },
  {
    code: '5663008�',
    display: 'Phlebectomy of varicose vein of head and neck',
  },
  { code: '4008007�', display: 'Phlebography of neck' },
  { code: '2857007�', display: 'Pinealectomy' },
  { code: '6506000�', display: 'Placing a patient on a bedpan' },
  { code: '3918006�', display: 'Plastic repair with lengthening' },
  { code: '1844002�', display: 'Plication of ligament' },
  { code: '2933008�', display: 'Pneumogynecography' },
  { code: '6521006�', display: 'Polytomography' },
  {
    code: '6443000�',
    display: 'Pontic, porcelain fused to predominantly base metal',
  },
  { code: '5016005�', display: 'Pontic, resin with high noble metal' },
  {
    code: '5669007�',
    display: 'Portable electroencephalogram awake and asleep with stimulation',
  },
  { code: '4139007�', display: 'Posterior spinal cordotomy' },
  { code: '4504004�', display: 'Potter obstetrical version with extraction' },
  {
    code: '2642003�',
    display: 'Prefabricated post and core in addition to crown',
  },
  { code: '3794000�', display: 'Pregnanetriol measurement' },
  { code: '6213004�', display: 'Prescribing corneoscleral contact lens' },
  { code: '3432000�', display: 'Preventive dental service' },
  { code: '1327007�', display: 'Procedure on Meckel diverticulum' },
  { code: '2234009�', display: 'Proctosigmoidopexy' },
  { code: '4891005�', display: 'Proctostomy' },
  {
    code: '5342002�',
    display: 'Prophylactic treatment of tibia with methyl methacrylate',
  },
  { code: '971009�', display: 'Prosthetic construction and fitting' },
  { code: '6491004�', display: 'Protein S, free assay' },
  { code: '2488002�', display: 'Provision of contact lens' },
  { code: '1071001�', display: 'Proximal splenorenal anastomosis' },
  {
    code: '2619005�',
    display: 'Psychiatric interpretation to family or parents of patient',
  },
  { code: '4719001�', display: 'Psychologic cognitive testing and assessment' },
  { code: '1999007�', display: 'Psychologic test' },
  { code: '3352000�', display: 'PTH - Parathyroid hormone level' },
  { code: '435001�', display: 'Pulmonary inhalation study' },
  {
    code: '6567006�',
    display: 'Pulmonary valve commissurotomy by transvenous balloon method',
  },
  { code: '3443008�', display: 'Pulp capping' },
  { code: '2078009�', display: 'Puncture of bursa of hand' },
  { code: '680007�', display: 'Radiation physics consultation' },
  {
    code: '5317006�',
    display:
      'Radical amputation of penis with bilateral pelvic lymphadenectomy',
  },
  { code: '5998008�', display: 'Radical dissection of groin' },
  { code: '897004�', display: 'Radical maxillary antrotomy' },
  { code: '5057003�', display: 'Radical orbitomaxillectomy' },
  {
    code: '2364003�',
    display: 'Radical resection of tumor of soft tissue of wrist area',
  },
  { code: '3799005�', display: 'Radiography of adenoids' },
  { code: '5131003�', display: 'Radiography of chest wall' },
  { code: '4613005�', display: 'Radioimmunoassay' },
  { code: '348003�', display: 'Radionuclide dynamic function study' },
  { code: '6650009�', display: 'Radionuclide lacrimal flow study' },
  {
    code: '352003�',
    display: 'Reagent RBC, preparation antibody sensitized pool',
  },
  { code: '4319004�', display: 'Rebase of complete lower denture' },
  { code: '2498008�', display: 'Rebase of upper partial denture' },
  { code: '2668005�', display: 'Recementation of space maintainer' },
  { code: '1876007�', display: 'Recession of prognathic jaw' },
  { code: '2732007�', display: 'Recession of tendon of hand' },
  { code: '4811009�', display: 'Reconstruction of diaphragm' },
  { code: '1315009�', display: 'Reconstruction of eyebrow' },
  { code: '2242005�', display: 'Reconstruction of eyelid' },
  { code: '1578004�', display: 'Reconstruction of ossicles with stapedectomy' },
  { code: '2051007�', display: 'Red cell iron utilization study' },
  {
    code: '3620007�',
    display: 'Red cell survival study with hepatic sequestration',
  },
  { code: '3605001�', display: 'Reduction of ciliary body' },
  { code: '3749006�', display: 'Reduction of closed ischial fracture' },
  { code: '2127000�', display: 'Reduction of closed sacral fracture' },
  {
    code: '5065000�',
    display: 'Reduction of closed traumatic hip dislocation with anesthesia',
  },
  { code: '4293000�', display: 'Reduction of intussusception by laparotomy' },
  {
    code: '4094001�',
    display: 'Reduction of retroversion of uterus by suppository',
  },
  { code: '3399007�', display: 'Reduction of torsion of omentum' },
  { code: '4772002�', display: 'Reduction of torsion of spermatic cord' },
  { code: '3457005�', display: 'Referral procedure' },
  { code: '1253002�', display: 'Refusion of spine' },
  { code: '2079001�', display: 'Reimplantation of anomalous pulmonary artery' },
  { code: '6227009�', display: 'Relationship psychotherapy' },
  {
    code: '3713005�',
    display: 'Release for de Quervain tenosynovitis of hand',
  },
  { code: '1615002�', display: 'Reline upper partial denture, chairside' },
  { code: '2601001�', display: 'Removal of bone flap of skull' },
  { code: '3580004�', display: 'Removal of calculus of pharynx' },
  { code: '1019009�', display: 'Removal of corneal epithelium' },
  {
    code: '6026008�',
    display:
      'Removal of coronary artery obstruction by percutaneous transluminal balloon with thrombolytic agent',
  },
  { code: '3016007�', display: 'Removal of Crutchfield tongs from skull' },
  { code: '6384001�', display: 'Removal of device from digestive system' },
  { code: '4411002�', display: 'Removal of device from female genital tract' },
  { code: '3083005�', display: 'Removal of device from thorax' },
  { code: '5726007�', display: 'Removal of epicardial electrodes' },
  { code: '6658002�', display: 'Removal of foreign body from alveolus' },
  {
    code: '5966002�',
    display: 'Removal of foreign body from elbow area, deep',
  },
  { code: '3564009�', display: 'Removal of foreign body from fallopian tube' },
  { code: '3843001�', display: 'Removal of foreign body from fascia' },
  { code: '5687005�', display: 'Removal of foreign body from ovary' },
  { code: '6601003�', display: 'Removal of foreign body from skin of axilla' },
  {
    code: '3740005�',
    display: 'Removal of foreign body of canthus by incision',
  },
  {
    code: '3701000�',
    display: 'Removal of foreign body of hip from subcutaneous tissue',
  },
  {
    code: '3517009�',
    display: 'Removal of foreign body of pelvis from subcutaneous tissue',
  },
  {
    code: '4503005�',
    display: 'Removal of foreign body of sclera without use of magnet',
  },
  {
    code: '160007�',
    display: 'Removal of foreign body of tendon and/or tendon sheath',
  },
  { code: '4712005�', display: 'Removal of Gardner Wells tongs from skull' },
  { code: '1449002�', display: 'Removal of hair' },
  {
    code: '3479000�',
    display: 'Removal of heart assist system with replacement',
  },
  { code: '4350002�', display: 'Removal of implant of cornea' },
  {
    code: '4642002�',
    display:
      'Removal of inflatable penile prosthesis, with pump, reservoir and cylinders',
  },
  { code: '1133007�', display: 'Removal of intact mammary implant, bilateral' },
  {
    code: '6355005�',
    display: 'Removal of internal fixation device of radius',
  },
  { code: '2614000�', display: 'Removal of ligature from fallopian tube' },
  {
    code: '3969006�',
    display: 'Removal of osteocartilagenous loose body from joint structures',
  },
  { code: '5460001�', display: 'Removal of prosthesis from fallopian tube' },
  { code: '2535006�', display: 'Removal of pulp - complete' },
  { code: '5897004�', display: 'Removal of Scribner shunt' },
  { code: '3010001�', display: 'Removal of silastic tubes from ear' },
  { code: '911003�', display: 'Removal of subarachnoid-ureteral shunt' },
  { code: '625000�', display: 'Removal of supernumerary digit' },
  { code: '4037006�', display: 'Removal of vascular graft or prosthesis' },
  {
    code: '4068005�',
    display: 'Removal of ventricular reservoir with synchronous replacement',
  },
  { code: '1871002�', display: 'Repair of aneurysm of coronary artery' },
  {
    code: '6187000�',
    display: 'Repair of aneurysm with graft of common femoral artery',
  },
  { code: '2616003�', display: 'Repair of bifid digit of hand' },
  { code: '2119009�', display: 'Repair of blood vessel' },
  {
    code: '1279006�',
    display:
      'Repair of cardiac pacemaker pocket in skin AND/OR subcutaneous tissue',
  },
  { code: '5545001�', display: 'Repair of carotid body' },
  { code: '5348003�', display: 'Repair of endocardial cushion defect' },
  { code: '5721002�', display: 'Repair of eyebrow' },
  { code: '3575008�', display: 'Repair of fascia with graft of fascia' },
  {
    code: '6198004�',
    display: 'Repair of fracture with Sofield type procedure',
  },
  { code: '6146001�', display: 'Repair of heart septum with prosthesis' },
  { code: '1576000�', display: 'Repair of intestinouterine fistula' },
  { code: '2474001�', display: 'Repair of malunion of metatarsal bones' },
  { code: '445004�', display: 'Repair of malunion of tibia' },
  { code: '4339000�', display: 'Repair of nasolabial fistula' },
  {
    code: '146005�',
    display: 'Repair of nonunion of metatarsal with bone graft',
  },
  { code: '3666002�', display: 'Repair of peripheral nerve by suturing' },
  {
    code: '2193006�',
    display: 'Repair of ruptured aneurysm with graft of celiac artery',
  },
  { code: '1021004�', display: 'Repair of scrotum' },
  { code: '5983006�', display: 'Repair of spermatic cord' },
  { code: '4388000�', display: 'Repair of splenocolic fistula' },
  { code: '3041000�', display: 'Repair of stomach' },
  {
    code: '4084006�',
    display: 'Repair of tendon of hand by graft or implant of muscle',
  },
  { code: '4930005�', display: 'Repair of thoracogastric fistula' },
  { code: '533005�', display: 'Repair of vesicocolic fistula' },
  { code: '6547002�', display: 'Repair with closure of non-surgical wound' },
  { code: '1440003�', display: 'Repair with resection-recession' },
  { code: '3137001�', display: 'Replacement' },
  { code: '243009�', display: 'Replacement of cerebral ventricular tube' },
  {
    code: '497006�',
    display: 'Replacement of cochlear prosthesis, multiple channels',
  },
  {
    code: '3515001�',
    display: 'Replacement of electronic heart device, pulse generator',
  },
  {
    code: '6639006�',
    display: 'Replacement of obstructed valve in shunt system',
  },
  { code: '1747001�', display: 'Replacement of skeletal muscle stimulator' },
  { code: '2267008�', display: 'Replacement of tracheostomy tube' },
  {
    code: '4090005�',
    display:
      'Replacement of transvenous atrial and ventricular pacemaker electrode leads',
  },
  { code: '2409007�', display: 'Replantation of toe' },
  {
    code: '6255008�',
    display: 'Resection of abdominal artery with replacement',
  },
  {
    code: '2250001�',
    display: 'Resection of ascending aorta with anastomosis',
  },
  { code: '2567009�', display: 'Resection of rib by transaxillary approach' },
  { code: '1753001�', display: 'Resection of uveal tissue' },
  {
    code: '6665005�',
    display: 'Restoration, crown, porcelain fused to noble metal',
  },
  {
    code: '4511000�',
    display:
      'Restoration, inlay, composite/resin, one surface, laboratory processed',
  },
  {
    code: '5517007�',
    display:
      'Restoration, inlay, porcelain/ceramic, per tooth, in addition to inlay',
  },
  {
    code: '4035003�',
    display: 'Restoration, resin, two surfaces, posterior, permanent',
  },
  { code: '3112006�', display: 'Restraint removal' },
  { code: '6556005�', display: 'Reticulin antibody measurement' },
  { code: '121005�', display: 'Retrobulbar injection of therapeutic agent' },
  {
    code: '2508002�',
    display: 'Retrograde urography with kidney-ureter-bladder',
  },
  { code: '4862007�', display: 'Revision of anastomosis of large intestine' },
  { code: '2455009�', display: 'Revision of lumbosubarachnoid shunt' },
  { code: '4101004�', display: 'Revision of spinal pleurothecal shunt' },
  { code: '5806001�', display: 'Revision of tracheostomy scar' },
  { code: '2843005�', display: 'Revision of urinary conduit' },
  {
    code: '3562008�',
    display: 'Rodney Smith operation, radical subtotal pancreatectomy',
  },
  {
    code: '4102006�',
    display: 'Root canal therapy, anterior, excluding final restoration',
  },
  {
    code: '851001�',
    display: 'Root canal therapy, molar, excluding final restoration',
  },
  {
    code: '3780001�',
    display: 'Routine patient disposition, no follow-up planned',
  },
  { code: '4934001�', display: 'Salpingography' },
  { code: '3778007�', display: 'Scrotum manipulation' },
  { code: '1680000�', display: 'Secondary chemoprophylaxis' },
  { code: '6354009�', display: 'Sedimentation rate, Westergren' },
  { code: '4525004�', display: 'Seen by casualty - service' },
  { code: '5479003�', display: 'Select picture audiometry' },
  {
    code: '5809008�',
    display: 'Selective vagotomy with pyloroplasty and gastrostomy',
  },
  { code: '3683006�', display: 'Selenium measurement, urine' },
  {
    code: '5025004�',
    display: 'Semen analysis, presence and motility of sperm',
  },
  { code: '6434009�', display: 'Seminal fluid detection' },
  { code: '2225004�', display: 'Sensititer system test' },
  { code: '881009�', display: 'Separation of ciliary body' },
  { code: '6295005�', display: 'Sequestrectomy of pelvic bone' },
  { code: '5482008�', display: 'Serologic test for Blastomyces' },
  { code: '2731000�', display: 'Serologic test for influenza virus A' },
  { code: '5457008�', display: 'Serologic test for Rickettsia conorii' },
  { code: '4903000�', display: 'Serum protein electrophoresis' },
  { code: '5328005�', display: 'Shortening of Achilles tendon' },
  { code: '4827002�', display: 'Shower hydrotherapy' },
  {
    code: '3690001�',
    display:
      'Shunt of left subclavian to descending aorta by Blalock-Park operation',
  },
  { code: '1597004�', display: 'Skeletal X-ray of ankle and foot' },
  { code: '5433008�', display: 'Skeletal X-ray of lower limb' },
  {
    code: '4407008�',
    display: 'Slitting of lacrimal canaliculus for passage of tube',
  },
  { code: '2658000�', display: 'Social service interview of patient' },
  { code: '1602006�', display: 'Social service interview with planning' },
  { code: '6177004�', display: 'Southern blot assay' },
  {
    code: '879007�',
    display: 'Special blood coagulation test, explain by report',
  },
  { code: '1699009�', display: 'Special dosimetry' },
  {
    code: '170009�',
    display: 'Special potency disk identification, vancomycin test',
  },
  { code: '5161006�', display: 'Specialty clinic admission' },
  { code: '4064007�', display: 'Specimen aliquoting' },
  { code: '1054002�', display: 'Sphincterotomy of papilla of Vater' },
  { code: '3734003�', display: 'SSG - Split skin graft' },
  { code: '628003�', display: 'Steinman test' },
  { code: '2238007�', display: 'Stone operation, anoplasty' },
  { code: '2693007�', display: 'Stool fat, quantitative measurement' },
  { code: '3509001�', display: 'Streptococcus vaccination' },
  { code: '5373003�', display: 'Stress breaker' },
  { code: '2677003�', display: 'Stripping' },
  {
    code: '2697008�',
    display: 'Stripping and ligation of great saphenous vein',
  },
  { code: '1995001�', display: 'Stripping of cerebral meninges' },
  { code: '2517002�', display: 'Stroke rehabilitation' },
  {
    code: '6108008�',
    display: 'Subdural tap through fontanel, infant, initial',
  },
  { code: '1775004�', display: 'Submaxillary incision with drainage' },
  {
    code: '2945004�',
    display: 'Suprapubic diverticulectomy of urinary bladder',
  },
  { code: '3249005�', display: 'Surgical construction of filtration bleb' },
  {
    code: '6130009�',
    display: 'Surgical exposure of impacted or unerupted tooth to aid eruption',
  },
  { code: '5722009�', display: 'Surgical reanastomosis of colon' },
  { code: '4365001�', display: 'Surgical repair' },
  { code: '5648005�', display: 'Surgical repair and revision of shunt' },
  {
    code: '5191001�',
    display: 'Surgical treatment of missed miscarriage of second trimester',
  },
  { code: '5243009�', display: 'Suture of adenoid fossa' },
  { code: '2915002�', display: 'Suture of capsule of ankle' },
  { code: '6221005�', display: 'Suture of colon' },
  { code: '2659008�', display: 'Suture of ligament of lower extremity' },
  { code: '3889008�', display: 'Suture of lip' },
  { code: '1872009�', display: 'Suture of male perineum' },
  {
    code: '6473009�',
    display: 'Suture of old obstetrical laceration of uterus',
  },
  {
    code: '2322003�',
    display: 'Suture of recent wound of eyelid, direct closure, full-thickness',
  },
  { code: '1730002�', display: 'Suture of skin wound of hindfoot' },
  { code: '2188008�', display: 'Suture of tendon to skeletal attachment' },
  { code: '603006�', display: 'Synchondrotomy' },
  { code: '4626009�', display: 'Take-down of arterial anastomosis' },
  { code: '1805005�', display: 'Take-down of stoma' },
  { code: '6388003�', display: 'TdT stain' },
  { code: '2461007�', display: 'Tennis elbow test' },
  { code: '4505003�', display: 'Tenolysis of flexor tendon of forearm' },
  { code: '5870006�', display: 'Tertiary closure of abdominal wall' },
  { code: '2947007�', display: 'Therapeutic compound measurement' },
  { code: '1862006�', display: 'Thoracic phlebectomy' },
  { code: '119000�', display: 'Thoracoscopic partial lobectomy of lung' },
  {
    code: '5216004�',
    display: 'Three dimensional ultrasound imaging of heart',
  },
  { code: '2977001�', display: 'Thrombectomy of lower limb vein' },
  {
    code: '3758004�',
    display: 'Thrombectomy with catheter of subclavian artery by neck incision',
  },
  {
    code: '710006�',
    display: 'Thromboendarterectomy with graft of mesenteric artery',
  },
  {
    code: '5176003�',
    display: 'Thromboendarterectomy with graft of renal artery',
  },
  { code: '3026000�', display: 'Tibiotalar arthrodesis' },
  {
    code: '3697003�',
    display:
      'Tissue processing technique, routine, embed, cut and stain, per autopsy',
  },
  { code: '389000�', display: 'Tobramycin level' },
  {
    code: '3802001�',
    display: 'Topical application of tooth medicament - desensitizing agent',
  },
  { code: '456004�', display: 'Total abdominal colectomy with ileostomy' },
  { code: '5264008�', display: 'Total bile acids measurement' },
  { code: '5182000�', display: 'Total body perfusion' },
  {
    code: '3498003�',
    display: 'Total excision of pituitary gland by transsphenoidal approach',
  },
  { code: '3001009�', display: 'Total lobectomy with bronchoplasty' },
  { code: '754000�', display: 'Total urethrectomy' },
  {
    code: '1983001�',
    display: 'Total urethrectomy including cystostomy in female',
  },
  { code: '3862003�', display: 'Total vital capacity measurement' },
  {
    code: '4192000�',
    display: 'Toxicology testing for organophosphate insecticide',
  },
  { code: '1583007�', display: 'Tractotomy of mesencephalon' },
  { code: '1103000�', display: 'Transcrural mobilization of stapes' },
  { code: '1645004�', display: 'Transduodenal sphincterotomy' },
  { code: '5447007�', display: 'Transfusion' },
  { code: '2580007�', display: 'Transplant of hair follicles to scalp' },
  { code: '4692001�', display: 'Transplantation of artery of upper extremity' },
  { code: '3607009�', display: 'Transplantation of mesenteric tissue' },
  { code: '3911000�', display: 'Transplantation of muscle' },
  { code: '5245002�', display: 'Transplantation of peripheral vein' },
  { code: '671007�', display: 'Transplantation of testis' },
  { code: '3268008�', display: 'Transplantation of tissue of pelvic region' },
  {
    code: '6005008�',
    display: 'Transplantation of vitreous by anterior approach',
  },
  { code: '6563005�', display: 'Transposition of muscle of hand' },
  { code: '2837008�', display: 'Transposition of ulnar nerve at elbow' },
  { code: '4380007�', display: 'Transposition of vulvar tissue' },
  { code: '6195001�', display: 'Transurethral drainage of prostatic abscess' },
  { code: '4914002�', display: 'Treatment planning for teletherapy' },
  { code: '1104006�', display: 'Triad knee repair' },
  { code: '5337005�', display: 'Trocar biopsy' },
  {
    code: '1457004�',
    display: 'Truncal vagotomy with pyloroplasty and gastrostomy',
  },
  {
    code: '6499002�',
    display: 'Tsuge operation on finger for macrodactyly repair',
  },
  { code: '893000�', display: 'Tumor antigen measurement' },
  { code: '2475000�', display: 'Twenty-four hour collection of urine' },
  {
    code: '2371008�',
    display: 'Tympanoplasty type II with graft against incus or malleus',
  },
  {
    code: '6527005�',
    display: 'Uchida fimbriectomy with tubal ligation by endoscopy',
  },
  { code: '951000�', display: 'Ultrasonic guidance for endomyocardial biopsy' },
  { code: '3787003�', display: 'Ultrasonic guidance for needle biopsy' },
  { code: '1787006�', display: 'Ultrasonic guidance for pericardiocentesis' },
  {
    code: '5110001�',
    display: 'Ultrasound peripheral imaging, real time scan',
  },
  { code: '5055006�', display: 'Uniscept system test' },
  { code: '1225002�', display: 'Upper arm X-ray' },
  {
    code: '1316005�',
    display:
      'Upper partial denture, cast metal base without resin saddles, including any conventional clasps, rests and teeth',
  },
  { code: '2178007�', display: 'Ureterocentesis' },
  { code: '1163003�', display: 'Ureteroenterostomy' },
  { code: '1533002�', display: 'Ureteroplication' },
  { code: '2278000�', display: 'Urinalysis, automated' },
  { code: '6480006�', display: 'Urinary bladder residual urine study' },
  { code: '351005�', display: 'Urinary undiversion of ureteral anastomosis' },
  { code: '493005�', display: 'Urobilinogen measurement, 48-hour, feces' },
  { code: '306005�', display: 'US kidneys' },
  {
    code: '570001�',
    display: 'Vaccination for arthropod-borne viral encephalitis',
  },
  {
    code: '4387005�',
    display:
      'Valvuloplasty of pulmonary valve in total repair of tetralogy of Fallot',
  },
  { code: '4970003�', display: 'Venography' },
  { code: '2266004�', display: 'Venography of adrenal, bilateral' },
  { code: '4438009�', display: 'Venography of vena cava' },
  { code: '3390006�', display: 'Verification procedure' },
  {
    code: '3143004�',
    display: 'Visual field examination and evaluation, intermediate',
  },
  { code: '6226000�', display: 'Visual rehabilitation, eye motion defect' },
  { code: '3770000�', display: 'Ward urine dip stick testing' },
  { code: '3887005�', display: 'Wart ligation' },
  { code: '5121005�', display: 'Wedge osteotomy of pelvic bone' },
  { code: '3691002�', display: 'Wedge osteotomy of tarsals and metatarsals' },
  { code: '767002�', display: 'White blood cell count - observation' },
  { code: '6289009�', display: 'White blood cell histogram evaluation' },
  {
    code: '3686003�',
    display: 'Zancolli operation for tendon transfer of biceps',
  },
]
export const availableAppointmentTypes = [
  {
    code: '556',
    display: 'Walk-in Centre /Non-Emergency',
    definition: 'In-Person',
  },
  //   {
  //     code: '538',
  //     display: 'Telephone Counselling',
  //     definition: 'Phone',
  //   },
  {
    system: 'http://wellopathy.com/service-type-codes',
    code: 'video-counselling',
    display: 'Video Counselling',
    definition: 'Video',
  },
]

export const availablePaymentTypes = [
  { id: 'cash', title: 'Cash' },
  { id: 'online', title: 'Online' },
]

export const availablePaymentTypesRemovedOnline = [
  { id: 'cash', title: 'Cash' },
]

export const availablePaymentTypesHin = [
  { id: 'cash', title: 'नकद' },
  { id: 'online', title: 'ऑनलाइन' },
]

export const availablePaymentTypesHinNotOnline = [{ id: 'cash', title: 'नकद' }]

export const onlineAvailablePaymentTypes = [{ id: 'online', title: 'Online' }]
export const onlineAvailablePaymentTypesHin = [
  { id: 'online', title: 'ऑनलाइन' },
]

export const availablePaymentOptions: R4.ICoding[] = [
  { code: 'cash', display: 'Cash' },
  { code: 'online', display: 'Online' },
]

export const roomTypes: R4.ICoding[] = [
  { code: 'sharing', display: 'Sharing' },
  { code: 'premium', display: 'Premium' },
  { code: 'suite', display: 'Suite' },
  { code: 'non-ac', display: 'Non AC' },
  { code: 'eco-premium', display: 'Eco Premium' },
]

export const paymentTypes: R4.ICoding[] = [
  { code: 'amount', display: `Amt (${getSymbolForCurrency('')})` },
  { code: 'percent', display: '%           ' },
]

export const answerOptions: R4.ICoding[] = [
  { code: 'no', display: 'No' },
  { code: 'yes', display: 'Yes' },
]

export const dietFeedbackAns: R4.ICoding[] = [
  { code: 'VeryGood', display: 'Very Good' },
  { code: 'good', display: 'Good' },
  { code: 'Ok', display: 'Ok' },
  { code: 'bad', display: 'Bad' },
  { code: 'VeryBad', display: 'Very Bad' },
]

export const subs: R4.ISubstance[] = [
  {
    resourceType: 'Substance',
    id: '2599359',
    meta: {
      versionId: '1',
      lastUpdated: '2022-07-06T10:39:16.394+00:00',
      source: '#73968e13-bacc-9d',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndSubstanceMaterial',
      ],
    },
    extension: [
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: 'ChargeItemDefinition/2599358',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-billing-unit-ex',
        valueCodeableConcept: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-unit-uom-cs',
              code: 'PCS',
              display: 'PCS',
            },
          ],
          text: 'PCS',
        },
      },
    ],
    status: R4.SubstanceStatusKind._active,
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/substance-category',
            code: 'material',
            display: 'Material',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-material-cs',
          code: '415',
          display: 'COLON KIT',
        },
      ],
      text: 'COLON KIT',
    },
    description: 'COLON KIT',
  },
  {
    resourceType: 'Substance',
    id: '2599379',
    meta: {
      versionId: '1',
      lastUpdated: '2022-07-06T10:39:16.394+00:00',
      source: '#73968e13-bacc-9d',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndSubstanceMaterial',
      ],
    },
    extension: [
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: 'ChargeItemDefinition/2599378',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-billing-unit-ex',
        valueCodeableConcept: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-unit-uom-cs',
              code: 'PKT',
              display: 'PKT',
            },
          ],
          text: 'PKT',
        },
      },
    ],
    status: R4.SubstanceStatusKind._active,
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/substance-category',
            code: 'material',
            display: 'Material',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-material-cs',
          code: '492',
          display: 'TRIPHALA POWDER',
        },
      ],
      text: 'TRIPHALA POWDER',
    },
    description: 'TRIPHALA POWDER',
    instance: [
      {
        quantity: {
          value: 100,
          unit: 'GM',
          system: 'http://unitsofmeasure.org',
          code: 'GM',
        },
      },
    ],
  },
]

export const feedbackAns: R4.ICoding[] = [
  { code: 'Very Satisfied', display: 'Very Satisfied' },
  { code: 'Satisfied', display: 'Satisfied' },
  { code: 'Ok', display: 'Ok' },
  { code: 'Dissatisfied', display: 'Dissatisfied' },
  { code: 'Very Dissatisfied', display: 'Very Dissatisfied' },
]

export const purposeOfVisit: R4.ICoding[] = [
  { code: 'treatment', display: 'Treatment' },
  { code: 'wellness', display: 'Wellness' },
]

export const dietTypeForDietItem: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
    code: 'ipd-diet',
    display: 'IPD Diet',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
    code: 'careplan-diet',
    display: 'CarePlan Diet',
  },
]

export const dayCareData: R4.ICoding[] = [
  { code: 'dayCare', display: 'Day Care' },
  { code: 'consultation', display: 'Consultation' },
]

export const dateOptionsList = [
  { id: `${moment()}`, title: 'ASAP' },
  { id: `${moment(moment(), 'DD-MM-YYYY').add(1, 'days')}`, title: 'Tomorrow' },
  {
    id: 'other',
    title: 'Other',
  },
]

export const displayoptionList = [
  { id: `${moment().format('YYYY-MM-DD')}`, title: 'This Week' },
  {
    id: `${moment(moment(), 'DD-MM-YYYY').add(7, 'days').format('YYYY-MM-DD')}`,
    title: 'Next Week',
  },
  {
    id: 'Custom',
    title: 'Custom',
  },
]

export const repeatRadioList = [
  { id: 'Never', title: 'Never' },
  { id: 'On', title: 'On' },
  { id: 'After', title: 'After' },
]

export const chartData = {
  patientId: '4567',
  plans: [
    {
      name: 'Wellopathy Platinum Subscription(Allopathy) ',
      startDate: '2020-01-01',
      endDate: '2022-01-01',
    },
  ],
  measurements: [
    {
      seriesName: 'Body mass index (BMI) [Ratio]',
      xDate: '2019-08-25',
      yValue: 27.5,
      unit: 'kg/m2',
    },
    {
      seriesName: 'Diastolic blood pressure',
      xDate: '2019-08-25',
      yValue: 76,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Systolic blood pressure',
      xDate: '2019-08-25',
      yValue: 120,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Systolic blood pressure',
      xDate: '2020-03-06',
      yValue: 125,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Diastolic blood pressure',
      xDate: '2020-03-06',
      yValue: 75,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Hemoglobin A1c/Hemoglobin.total in Blood',
      xDate: '2019-08-25',
      yValue: 6.1,
      unit: '%',
    },
    {
      seriesName: 'Hemoglobin A1c/Hemoglobin.total in Blood',
      xDate: '2021-09-05',
      yValue: 6.2,
      unit: '%',
    },
    {
      seriesName: 'Body mass index (BMI) [Ratio]',
      xDate: '2021-09-05',
      yValue: 27.5,
      unit: 'kg/m2',
    },
    {
      seriesName: 'Diastolic blood pressure',
      xDate: '2021-09-05',
      yValue: 75,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Systolic blood pressure',
      xDate: '2021-09-05',
      yValue: 129,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Hemoglobin A1c/Hemoglobin.total in Blood',
      xDate: '2020-08-30',
      yValue: 5.9,
      unit: '%',
    },
    {
      seriesName: 'Systolic blood pressure',
      xDate: '2020-08-30',
      yValue: 106,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Diastolic blood pressure',
      xDate: '2020-08-30',
      yValue: 80,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Body mass index (BMI) [Ratio]',
      xDate: '2020-08-30',
      yValue: 27.5,
      unit: 'kg/m2',
    },
    {
      seriesName: 'Hemoglobin A1c/Hemoglobin.total in Blood',
      xDate: '2018-08-19',
      yValue: 6.3,
      unit: '%',
    },
    {
      seriesName: 'Systolic blood pressure',
      xDate: '2018-08-19',
      yValue: 123,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Diastolic blood pressure',
      xDate: '2018-08-19',
      yValue: 88,
      unit: 'mm[Hg]',
    },
    {
      seriesName: 'Body mass index (BMI) [Ratio]',
      xDate: '2018-08-19',
      yValue: 27.5,
      unit: 'kg/m2',
    },
  ],
  drugExp: [
    {
      drugExposureStartDate: '2019-07-14',
      drugExposureEndDate: '2019-12-08',
      drugName: 'lisinopril 10 MG Oral Tablet',
      drugCode: '314076',
      drugVocabulary: 'RxNorm',
      ancestorName: 'ACE INHIBITORS, PLAIN',
      ancestorCode: 'C09A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2019-07-14',
      drugExposureEndDate: '2019-12-08',
      drugName: 'Dummy Ayush concept',
      drugCode: 'E.5',
      drugVocabulary: 'AYUSH',
      drugGroup: 'AYUSH drugs',
    },
    {
      drugExposureStartDate: '2021-12-19',
      drugExposureEndDate: '2021-12-19',
      drugName: 'Dummy Ayush concept',
      drugCode: 'E.5',
      drugVocabulary: 'AYUSH',
      drugGroup: 'AYUSH drugs',
    },
    {
      drugExposureStartDate: '2020-12-13',
      drugExposureEndDate: '2021-12-19',
      drugName: 'Dummy Ayush concept',
      drugCode: 'E.5',
      drugVocabulary: 'AYUSH',
      drugGroup: 'AYUSH drugs',
    },
    {
      drugExposureStartDate: '2019-07-14',
      drugExposureEndDate: '2019-12-08',
      drugName: 'hydrochlorothiazide 25 MG Oral Tablet',
      drugCode: '310798',
      drugVocabulary: 'RxNorm',
      ancestorName: 'LOW-CEILING DIURETICS, THIAZIDES',
      ancestorCode: 'C03A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2021-12-19',
      drugExposureEndDate: '2021-12-19',
      drugName: 'lisinopril 10 MG Oral Tablet',
      drugCode: '314076',
      drugVocabulary: 'RxNorm',
      ancestorName: 'ACE INHIBITORS, PLAIN',
      ancestorCode: 'C09A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2020-12-13',
      drugExposureEndDate: '2021-12-19',
      drugName: 'lisinopril 10 MG Oral Tablet',
      drugCode: '314076',
      drugVocabulary: 'RxNorm',
      ancestorName: 'ACE INHIBITORS, PLAIN',
      ancestorCode: 'C09A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2018-12-02',
      drugExposureEndDate: '2019-07-14',
      drugName: 'hydrochlorothiazide 25 MG Oral Tablet',
      drugCode: '310798',
      drugVocabulary: 'RxNorm',
      ancestorName: 'LOW-CEILING DIURETICS, THIAZIDES',
      ancestorCode: 'C03A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2019-12-08',
      drugExposureEndDate: '2020-12-13',
      drugName: 'Dummy Ayush concept',
      drugCode: 'E.5',
      drugVocabulary: 'AYUSH',
      drugGroup: 'AYUSH drugs',
    },
    {
      drugExposureStartDate: '2018-12-02',
      drugExposureEndDate: '2019-07-14',
      drugName: 'Dummy Ayush concept',
      drugCode: 'E.5',
      drugVocabulary: 'AYUSH',
      drugGroup: 'AYUSH drugs',
    },
    {
      drugExposureStartDate: '2018-12-02',
      drugExposureEndDate: '2019-07-14',
      drugName: 'lisinopril 10 MG Oral Tablet',
      drugCode: '314076',
      drugVocabulary: 'RxNorm',
      ancestorName: 'ACE INHIBITORS, PLAIN',
      ancestorCode: 'C09A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2019-12-08',
      drugExposureEndDate: '2020-12-13',
      drugName: 'lisinopril 10 MG Oral Tablet',
      drugCode: '314076',
      drugVocabulary: 'RxNorm',
      ancestorName: 'ACE INHIBITORS, PLAIN',
      ancestorCode: 'C09A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2019-12-08',
      drugExposureEndDate: '2020-12-13',
      drugName: 'hydrochlorothiazide 25 MG Oral Tablet',
      drugCode: '310798',
      drugVocabulary: 'RxNorm',
      ancestorName: 'LOW-CEILING DIURETICS, THIAZIDES',
      ancestorCode: 'C03A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2021-12-19',
      drugExposureEndDate: '2021-12-19',
      drugName: 'hydrochlorothiazide 25 MG Oral Tablet',
      drugCode: '310798',
      drugVocabulary: 'RxNorm',
      ancestorName: 'LOW-CEILING DIURETICS, THIAZIDES',
      ancestorCode: 'C03A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
    {
      drugExposureStartDate: '2020-12-13',
      drugExposureEndDate: '2021-12-19',
      drugName: 'hydrochlorothiazide 25 MG Oral Tablet',
      drugCode: '310798',
      drugVocabulary: 'RxNorm',
      ancestorName: 'LOW-CEILING DIURETICS, THIAZIDES',
      ancestorCode: 'C03A',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
  ],
  drugEra: [
    {
      personId: 13,
      drugEraStartDate: '2004-05-30',
      drugEraEndDate: '2021-09-05',
      gapDays: 18875,
      drugExposureCount: 21,
      drugName: 'amlodipine',
      drugCode: '17767',
      drugVocabulary: 'RxNorm',
      ancestorName: 'CALCIUM CHANNEL BLOCKERS',
      ancestorCode: 'C08',
      ancestorVocabulary: 'ATC',
      drugGroup: 'Allopathy Drugs',
    },
  ],
}

export const cohortsData = {
  reports: [
    {
      analysisName: 'Measurement Value Pre-index Monthly - BMI',
      analysisId: 114,
      resultType: 'DISTRIBUTION',
      domainIds: ['MEASUREMENT'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 114,
          analysisName: 'Measurement Value Pre-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 15,
          covariateName: '-1',
          covariateShortName: '-1',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 28,
          pct: 2391.4285714285716,
          avg: 23.914285714285715,
          stdDev: 4.922263427395698,
          min: 17.7,
          p10: 19.8,
          p25: 20.6,
          median: 21.8,
          p75: 25,
          p90: 31.1,
          max: 37,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 114,
          analysisName: 'Measurement Value Pre-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 17,
          covariateName: '-3',
          covariateShortName: '-3',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 3770.0000000000005,
          avg: 37.7,
          stdDev: 0,
          min: 37.7,
          p10: 37.7,
          p25: 37.7,
          median: 37.7,
          p75: 37.7,
          p90: 37.7,
          max: 37.7,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 114,
          analysisName: 'Measurement Value Pre-index Monthly - BMI',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 15,
          covariateName: '-1',
          covariateShortName: '-1',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 26,
          pct: 2408.846153846154,
          avg: 24.088461538461537,
          stdDev: 3.830229958953057,
          min: 18.6,
          p10: 19.7,
          p25: 21.5,
          median: 22.9,
          p75: 26.3,
          p90: 29.9,
          max: 33.7,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 114,
          analysisName: 'Measurement Value Pre-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 18,
          covariateName: '-4',
          covariateShortName: '-4',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 1950,
          avg: 19.5,
          stdDev: 0,
          min: 19.5,
          p10: 19.5,
          p25: 19.5,
          median: 19.5,
          p75: 19.5,
          p90: 19.5,
          max: 19.5,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CRITERIA_SET',
      domainId: 'MEASUREMENT',
    },
    {
      analysisName: 'Measurement Value Post-index Monthly - BMI',
      analysisId: 115,
      resultType: 'DISTRIBUTION',
      domainIds: ['MEASUREMENT'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 115,
          analysisName: 'Measurement Value Post-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 24,
          covariateName: '4',
          covariateShortName: '4',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 2870,
          avg: 28.7,
          stdDev: 0,
          min: 28.7,
          p10: 28.7,
          p25: 28.7,
          median: 28.7,
          p75: 28.7,
          p90: 28.7,
          max: 28.7,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 115,
          analysisName: 'Measurement Value Post-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 23,
          covariateName: '3',
          covariateShortName: '3',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 3240,
          avg: 32.4,
          stdDev: 9.8726896031426,
          min: 21,
          p10: 21,
          p25: 21,
          median: 38.1,
          p75: 38.1,
          p90: 38.1,
          max: 38.1,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 115,
          analysisName: 'Measurement Value Post-index Monthly - BMI',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 22,
          covariateName: '2',
          covariateShortName: '2',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 2790,
          avg: 27.9,
          stdDev: 0,
          min: 27.9,
          p10: 27.9,
          p25: 27.9,
          median: 27.9,
          p75: 27.9,
          p90: 27.9,
          max: 27.9,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 115,
          analysisName: 'Measurement Value Post-index Monthly - BMI',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 21,
          covariateName: '1',
          covariateShortName: '1',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 26,
          pct: 2408.846153846154,
          avg: 24.088461538461537,
          stdDev: 3.830229958953057,
          min: 18.6,
          p10: 19.7,
          p25: 21.5,
          median: 22.9,
          p75: 26.3,
          p90: 29.9,
          max: 33.7,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 115,
          analysisName: 'Measurement Value Post-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 21,
          covariateName: '1',
          covariateShortName: '1',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 28,
          pct: 2391.4285714285716,
          avg: 23.914285714285715,
          stdDev: 4.922263427395698,
          min: 17.7,
          p10: 19.8,
          p25: 20.6,
          median: 21.8,
          p75: 25,
          p90: 31.1,
          max: 37,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
        {
          analysisId: 115,
          analysisName: 'Measurement Value Post-index Monthly - BMI',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 26,
          covariateName: '6',
          covariateShortName: '6',
          faType: 'CRITERIA_SET',
          domainId: 'MEASUREMENT',
          conceptId: 0,
          conceptName: 'No matching concept',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 2480,
          avg: 24.8,
          stdDev: 0,
          min: 24.8,
          p10: 24.8,
          p25: 24.8,
          median: 24.8,
          p75: 24.8,
          p90: 24.8,
          max: 24.8,
          aggregateId: 7,
          aggregateName: 'Value as number',
          missingMeansZero: false,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CRITERIA_SET',
      domainId: 'MEASUREMENT',
    },
    {
      analysisName: 'Drug Exposure Post-index 1 Month',
      analysisId: 118,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094118,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 77,
          pct: 51.33333333333333,
          avg: 0.5133333333333333,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924118,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324118,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216118,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765118,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 920300118,
          covariateName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          covariateShortName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 920300,
          conceptName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128118,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 100,
          pct: 66.66666666666666,
          avg: 0.6666666666666666,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248118,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106118,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 78,
          pct: 53.79310344827586,
          avg: 0.5379310344827586,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281118,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918118,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19009384118,
          covariateName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          covariateShortName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19009384,
          conceptName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924118,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094118,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 72,
          pct: 49.6551724137931,
          avg: 0.496551724137931,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062118,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216118,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606118,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40236824118,
          covariateName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          covariateShortName:
            'phenazopyridine hydrochloride 100 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40236824,
          conceptName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893118,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047118,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128118,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 103,
          pct: 71.03448275862068,
          avg: 0.7103448275862069,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261118,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248118,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106118,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 80,
          pct: 53.333333333333336,
          avg: 0.5333333333333333,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344118,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539463118,
          covariateName: 'simvastatin 10 MG Oral Tablet',
          covariateShortName: 'simvastatin 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539463,
          conceptName: 'simvastatin 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281118,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805118,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CUSTOM_FE',
      domainId: 'DRUG',
    },
    {
      analysisName: 'Drug Exposure Post-index 3 Months',
      analysisId: 119,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 920300119,
          covariateName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          covariateShortName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 920300,
          conceptName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128119,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 106,
          pct: 73.10344827586206,
          avg: 0.7310344827586207,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324119,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248119,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937119,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352119,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261119,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918119,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094119,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 82,
          pct: 54.666666666666664,
          avg: 0.5466666666666666,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281119,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106119,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 88,
          pct: 60.689655172413794,
          avg: 0.6068965517241379,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19008572119,
          covariateName: 'hydrocortisone 10 MG/ML Topical Cream',
          covariateShortName: 'hydrocortisone 10 MG/ML Topical Cream',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19008572,
          conceptName: 'hydrocortisone 10 MG/ML Topical Cream',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344119,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805119,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40229134119,
          covariateName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          covariateShortName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40229134,
          conceptName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765119,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606119,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19009384119,
          covariateName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          covariateShortName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19009384,
          conceptName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461119,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411119,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40236824119,
          covariateName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          covariateShortName:
            'phenazopyridine hydrochloride 100 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40236824,
          conceptName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924119,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1107882119,
          covariateName: 'loratadine 10 MG Oral Tablet',
          covariateShortName: 'loratadine 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1107882,
          conceptName: 'loratadine 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216119,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075001119,
          covariateName: 'cefuroxime 250 MG Oral Tablet',
          covariateShortName: 'cefuroxime 250 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075001,
          conceptName: 'cefuroxime 250 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128119,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 103,
          pct: 68.66666666666667,
          avg: 0.6866666666666666,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047119,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248119,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539463119,
          covariateName: 'simvastatin 10 MG Oral Tablet',
          covariateShortName: 'simvastatin 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539463,
          conceptName: 'simvastatin 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601119,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094119,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 75,
          pct: 51.724137931034484,
          avg: 0.5172413793103449,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281119,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133905119,
          covariateName: 'penicillin V potassium 500 MG Oral Tablet',
          covariateShortName: 'penicillin V potassium 500 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133905,
          conceptName: 'penicillin V potassium 500 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419119,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106119,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078461119,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924119,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216119,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062119,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1594382119,
          covariateName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          covariateShortName:
            'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1594382,
          conceptName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893119,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CUSTOM_FE',
      domainId: 'DRUG',
    },
    {
      analysisName: 'Drug Exposure Post-index 6 Months',
      analysisId: 120,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19008572120,
          covariateName: 'hydrocortisone 10 MG/ML Topical Cream',
          covariateShortName: 'hydrocortisone 10 MG/ML Topical Cream',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19008572,
          conceptName: 'hydrocortisone 10 MG/ML Topical Cream',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3793103448275863,
          avg: 0.013793103448275862,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40236824120,
          covariateName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          covariateShortName:
            'phenazopyridine hydrochloride 100 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40236824,
          conceptName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128120,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 106,
          pct: 73.10344827586206,
          avg: 0.7310344827586207,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924120,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261120,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40229134120,
          covariateName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          covariateShortName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40229134,
          conceptName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344120,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 920300120,
          covariateName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          covariateShortName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 920300,
          conceptName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1107882120,
          covariateName: 'loratadine 10 MG Oral Tablet',
          covariateShortName: 'loratadine 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1107882,
          conceptName: 'loratadine 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324120,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281120,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062120,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461120,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248120,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216120,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606120,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893120,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539463120,
          covariateName: 'simvastatin 10 MG Oral Tablet',
          covariateShortName: 'simvastatin 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539463,
          conceptName: 'simvastatin 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075001120,
          covariateName: 'cefuroxime 250 MG Oral Tablet',
          covariateShortName: 'cefuroxime 250 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075001,
          conceptName: 'cefuroxime 250 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094120,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 75,
          pct: 51.724137931034484,
          avg: 0.5172413793103449,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419120,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133905120,
          covariateName: 'penicillin V potassium 500 MG Oral Tablet',
          covariateShortName: 'penicillin V potassium 500 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133905,
          conceptName: 'penicillin V potassium 500 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047120,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40232448120,
          covariateName: 'diphenhydramine hydrochloride 25 MG Oral Tablet',
          covariateShortName: 'diphenhydramine hydrochloride 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40232448,
          conceptName: 'diphenhydramine hydrochloride 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765120,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128120,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 103,
          pct: 68.66666666666667,
          avg: 0.6866666666666666,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1713671120,
          covariateName: 'amoxicillin 250 MG / clavulanate 125 MG Oral Tablet',
          covariateShortName:
            'amoxicillin 250 MG / clavulanate 125 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1713671,
          conceptName: 'amoxicillin 250 MG / clavulanate 125 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601120,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128009120,
          covariateName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          covariateShortName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128009,
          conceptName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924120,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106120,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078461120,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19041324120,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281120,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805120,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918120,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1594382120,
          covariateName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          covariateShortName:
            'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1594382,
          conceptName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19009384120,
          covariateName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          covariateShortName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19009384,
          conceptName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216120,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248120,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411120,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937120,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128263120,
          covariateName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          covariateShortName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128263,
          conceptName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106120,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 88,
          pct: 60.689655172413794,
          avg: 0.6068965517241379,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1115171120,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094120,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 82,
          pct: 54.666666666666664,
          avg: 0.5466666666666666,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352120,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CUSTOM_FE',
      domainId: 'DRUG',
    },
    {
      analysisName: 'Drug Exposure Pre-index 1 Months',
      analysisId: 121,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281121,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216121,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216121,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606121,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106121,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 52,
          pct: 35.86206896551724,
          avg: 0.3586206896551724,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248121,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893121,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248121,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106121,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 58,
          pct: 38.666666666666664,
          avg: 0.38666666666666666,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094121,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 39,
          pct: 26.89655172413793,
          avg: 0.2689655172413793,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094121,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 42,
          pct: 28.000000000000004,
          avg: 0.28,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1115171121,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924121,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918121,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261121,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924121,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128121,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128121,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 57.931034482758626,
          avg: 0.5793103448275863,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344121,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324121,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765121,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047121,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805121,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062121,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CUSTOM_FE',
      domainId: 'DRUG',
    },
    {
      analysisName: 'Drug Exposure Pre-index 3 Months',
      analysisId: 122,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924122,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805122,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248122,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047122,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40221415122,
          covariateName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          covariateShortName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40221415,
          conceptName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765122,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281122,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133679122,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133679,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062122,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918122,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106122,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 52,
          pct: 35.86206896551724,
          avg: 0.3586206896551724,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128009122,
          covariateName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          covariateShortName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128009,
          conceptName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19129144122,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19129144,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128263122,
          covariateName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          covariateShortName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128263,
          conceptName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411122,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937122,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128122,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1115171122,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094122,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 42,
          pct: 28.000000000000004,
          avg: 0.28,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216122,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352122,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344122,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324122,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924122,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261122,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248122,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461122,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281122,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601122,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893122,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 43012433122,
          covariateName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          covariateShortName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 43012433,
          conceptName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19127904122,
          covariateName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          covariateShortName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19127904,
          conceptName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106122,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 58,
          pct: 38.666666666666664,
          avg: 0.38666666666666666,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419122,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1115171122,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216122,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606122,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128122,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 57.931034482758626,
          avg: 0.5793103448275863,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094122,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 39,
          pct: 26.89655172413793,
          avg: 0.2689655172413793,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CUSTOM_FE',
      domainId: 'DRUG',
    },
    {
      analysisName: 'Drug Exposure Pre-index 6 Months',
      analysisId: 123,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601123,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133679123,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133679,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128009123,
          covariateName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          covariateShortName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128009,
          conceptName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765123,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 43012433123,
          covariateName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          covariateShortName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 43012433,
          conceptName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19129144123,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19129144,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106123,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 58,
          pct: 38.666666666666664,
          avg: 0.38666666666666666,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411123,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924123,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606123,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216123,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128123,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419123,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1115171123,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094123,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 39,
          pct: 26.89655172413793,
          avg: 0.2689655172413793,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344123,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19041324123,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248123,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937123,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918123,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281123,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40221415123,
          covariateName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          covariateShortName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40221415,
          conceptName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047123,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805123,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1127433123,
          covariateName: 'acetaminophen 325 MG Oral Tablet',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1127433,
          conceptName: 'acetaminophen 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3793103448275863,
          avg: 0.013793103448275862,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1115171123,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461123,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106123,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 52,
          pct: 35.86206896551724,
          avg: 0.3586206896551724,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19127904123,
          covariateName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          covariateShortName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19127904,
          conceptName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128263123,
          covariateName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          covariateShortName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128263,
          conceptName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261123,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924123,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216123,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128123,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 57.931034482758626,
          avg: 0.5793103448275863,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352123,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094123,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 42,
          pct: 28.000000000000004,
          avg: 0.28,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40229134123,
          covariateName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          covariateShortName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40229134,
          conceptName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324123,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893123,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248123,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281123,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40224805123,
          covariateName: '1 ML medroxyprogesterone acetate 150 MG/ML Injection',
          covariateShortName:
            '1 ML medroxyprogesterone acetate 150 MG/ML Injection',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40224805,
          conceptName: '1 ML medroxyprogesterone acetate 150 MG/ML Injection',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062123,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
      ],
      isComparative: false,
      isSummary: false,
      faType: 'CUSTOM_FE',
      domainId: 'DRUG',
    },
    {
      analysisName: 'All prevalence covariates',
      analysisId: null,
      resultType: 'PREVALENCE',
      domainIds: ['DRUG'],
      cohorts: [
        {
          cohortId: 2,
          cohortName: 'Hypertension patients',
        },
      ],
      items: [
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133679123,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133679,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128119,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 106,
          pct: 73.10344827586206,
          avg: 0.7310344827586207,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261120,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40229134120,
          covariateName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          covariateShortName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40229134,
          conceptName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924123,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918119,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281122,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419123,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1115171123,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062120,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 920300118,
          covariateName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          covariateShortName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 920300,
          conceptName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937123,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128122,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047119,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128009120,
          covariateName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          covariateShortName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128009,
          conceptName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924120,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106120,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128263123,
          covariateName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          covariateShortName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128263,
          conceptName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601119,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461122,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805120,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248120,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893122,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248123,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106120,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 88,
          pct: 60.689655172413794,
          avg: 0.6068965517241379,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216122,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606122,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094122,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 39,
          pct: 26.89655172413793,
          avg: 0.2689655172413793,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1594382119,
          covariateName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          covariateShortName:
            'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1594382,
          conceptName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40236824120,
          covariateName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          covariateShortName:
            'phenazopyridine hydrochloride 100 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40236824,
          conceptName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094118,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 77,
          pct: 51.33333333333333,
          avg: 0.5133333333333333,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324119,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248121,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924122,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1107882120,
          covariateName: 'loratadine 10 MG Oral Tablet',
          covariateShortName: 'loratadine 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1107882,
          conceptName: 'loratadine 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344119,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106122,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 52,
          pct: 35.86206896551724,
          avg: 0.3586206896551724,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606120,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893120,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281123,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324121,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094120,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 75,
          pct: 51.724137931034484,
          avg: 0.5172413793103449,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216122,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918118,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352122,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1115171123,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765120,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924118,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062118,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261123,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924123,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216123,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281120,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19009384120,
          covariateName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          covariateShortName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19009384,
          conceptName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078461119,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937120,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 43012433122,
          covariateName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          covariateShortName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 43012433,
          conceptName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248118,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106118,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 80,
          pct: 53.333333333333336,
          avg: 0.5333333333333333,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128122,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 57.931034482758626,
          avg: 0.5793103448275863,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1115171120,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805121,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19008572120,
          covariateName: 'hydrocortisone 10 MG/ML Topical Cream',
          covariateShortName: 'hydrocortisone 10 MG/ML Topical Cream',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19008572,
          conceptName: 'hydrocortisone 10 MG/ML Topical Cream',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3793103448275863,
          avg: 0.013793103448275862,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765123,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924118,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805122,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216118,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 920300120,
          covariateName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          covariateShortName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 920300,
          conceptName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765118,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281120,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924119,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281118,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094122,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 42,
          pct: 28.000000000000004,
          avg: 0.28,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1127433123,
          covariateName: 'acetaminophen 325 MG Oral Tablet',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1127433,
          conceptName: 'acetaminophen 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3793103448275863,
          avg: 0.013793103448275862,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419120,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40232448120,
          covariateName: 'diphenhydramine hydrochloride 25 MG Oral Tablet',
          covariateShortName: 'diphenhydramine hydrochloride 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40232448,
          conceptName: 'diphenhydramine hydrochloride 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128120,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 103,
          pct: 68.66666666666667,
          avg: 0.6866666666666666,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924122,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261122,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248119,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094121,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 42,
          pct: 28.000000000000004,
          avg: 0.28,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40236824118,
          covariateName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          covariateShortName:
            'phenazopyridine hydrochloride 100 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40236824,
          conceptName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133905119,
          covariateName: 'penicillin V potassium 500 MG Oral Tablet',
          covariateShortName: 'penicillin V potassium 500 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133905,
          conceptName: 'penicillin V potassium 500 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1115171121,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601122,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106122,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 58,
          pct: 38.666666666666664,
          avg: 0.38666666666666666,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128263120,
          covariateName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          covariateShortName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128263,
          conceptName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539463118,
          covariateName: 'simvastatin 10 MG Oral Tablet',
          covariateShortName: 'simvastatin 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539463,
          conceptName: 'simvastatin 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40224805123,
          covariateName: '1 ML medroxyprogesterone acetate 150 MG/ML Injection',
          covariateShortName:
            '1 ML medroxyprogesterone acetate 150 MG/ML Injection',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40224805,
          conceptName: '1 ML medroxyprogesterone acetate 150 MG/ML Injection',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893119,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128009123,
          covariateName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          covariateShortName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128009,
          conceptName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128120,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 106,
          pct: 73.10344827586206,
          avg: 0.7310344827586207,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281121,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19129144123,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19129144,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047122,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606123,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128123,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19009384119,
          covariateName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          covariateShortName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19009384,
          conceptName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248118,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461119,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106118,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 78,
          pct: 53.79310344827586,
          avg: 0.5379310344827586,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216119,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805123,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094118,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 72,
          pct: 49.6551724137931,
          avg: 0.496551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248121,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216118,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352123,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1594382120,
          covariateName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          covariateShortName:
            'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1594382,
          conceptName: 'NDA020800 0.3 ML epinephrine 1 MG/ML Auto-Injector',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40229134123,
          covariateName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          covariateShortName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40229134,
          conceptName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324123,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19127904122,
          covariateName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          covariateShortName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19127904,
          conceptName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924119,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344118,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344121,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062123,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352120,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106121,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 52,
          pct: 35.86206896551724,
          avg: 0.3586206896551724,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248119,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893121,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411123,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248122,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094119,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 82,
          pct: 54.666666666666664,
          avg: 0.5466666666666666,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344120,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094121,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 39,
          pct: 26.89655172413793,
          avg: 0.2689655172413793,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324120,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918122,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19041324123,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606119,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128263122,
          covariateName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          covariateShortName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128263,
          conceptName:
            '{7 (ethinyl estradiol 0.035 MG / norgestimate 0.18 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.215 MG Oral Tablet) / 7 (ethinyl estradiol 0.035 MG / norgestimate 0.25 MG Oral Tablet) / 7 (inert ingredients 1 MG Oral Tablet) } Pack...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40236824119,
          covariateName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          covariateShortName:
            'phenazopyridine hydrochloride 100 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40236824,
          conceptName: 'phenazopyridine hydrochloride 100 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1107882119,
          covariateName: 'loratadine 10 MG Oral Tablet',
          covariateShortName: 'loratadine 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1107882,
          conceptName: 'loratadine 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075001120,
          covariateName: 'cefuroxime 250 MG Oral Tablet',
          covariateShortName: 'cefuroxime 250 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075001,
          conceptName: 'cefuroxime 250 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047123,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19009384118,
          covariateName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          covariateShortName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19009384,
          conceptName:
            'insulin isophane, human 70 UNT/ML / insulin, regular, human 30 UNT/ML Injectable Suspension [Humulin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461123,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601120,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19127904123,
          covariateName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          covariateShortName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19127904,
          conceptName:
            '{24 (drospirenone 3 MG / ethinyl estradiol 0.02 MG Oral Tablet) / 4 (inert ingredients 1 MG Oral Tablet) } Pack [Yaz 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078461120,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281119,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128123,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 57.931034482758626,
          avg: 0.5793103448275863,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419119,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106119,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128118,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 103,
          pct: 71.03448275862068,
          avg: 0.7103448275862069,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1332419122,
          covariateName: 'amlodipine 5 MG Oral Tablet',
          covariateShortName: 'amlodipine 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1332419,
          conceptName: 'amlodipine 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216119,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765121,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062119,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 920300119,
          covariateName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          covariateShortName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 920300,
          conceptName: 'nitrofurantoin 5 MG/ML Oral Suspension',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261119,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281119,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133679122,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133679,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Errin 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128118,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 100,
          pct: 66.66666666666666,
          avg: 0.6666666666666666,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128121,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 56.00000000000001,
          avg: 0.56,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918123,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062121,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216121,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606121,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106121,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 58,
          pct: 38.666666666666664,
          avg: 0.38666666666666666,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1511248122,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539463119,
          covariateName: 'simvastatin 10 MG Oral Tablet',
          covariateShortName: 'simvastatin 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539463,
          conceptName: 'simvastatin 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19041324120,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047118,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411120,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893123,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1115171122,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169281118,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047121,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216121,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 43012433123,
          covariateName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          covariateShortName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 43012433,
          conceptName:
            '21 DAY ethinyl estradiol 0.000625 MG/HR / etonogestrel 0.005 MG/HR Vaginal System [NuvaRing]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19126352119,
          covariateName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          covariateShortName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19126352,
          conceptName: 'nitroglycerin 0.4 MG/ACTUAT Mucosal Spray',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324118,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40221415122,
          covariateName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          covariateShortName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40221415,
          conceptName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216123,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106119,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 88,
          pct: 60.689655172413794,
          avg: 0.6068965517241379,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078461120,
          covariateName: 'ibuprofen 200 MG Oral Tablet',
          covariateShortName: 'ibuprofen 200 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078461,
          conceptName: 'ibuprofen 200 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1154062122,
          covariateName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          covariateShortName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1154062,
          conceptName: '72 HR fentanyl 0.025 MG/HR Transdermal System',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248120,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40163924121,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094123,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 39,
          pct: 26.89655172413793,
          avg: 0.2689655172413793,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 40169216120,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 8,
          pct: 5.517241379310345,
          avg: 0.05517241379310345,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344123,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19128009122,
          covariateName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          covariateShortName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19128009,
          conceptName:
            '{7 (ethinyl estradiol 0.01 MG Oral Tablet) / 84 (ethinyl estradiol 0.03 MG / levonorgestrel 0.15 MG Oral Tablet) } Pack [Seasonique]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19129144122,
          covariateName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          covariateShortName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19129144,
          conceptName:
            '{28 (norethindrone 0.35 MG Oral Tablet) } Pack [Jolivette 28 Day]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 1115171122,
          covariateName: 'naproxen sodium 220 MG Oral Tablet',
          covariateShortName: 'naproxen sodium 220 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1115171,
          conceptName: 'naproxen sodium 220 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19133905120,
          covariateName: 'penicillin V potassium 500 MG Oral Tablet',
          covariateShortName: 'penicillin V potassium 500 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19133905,
          conceptName: 'penicillin V potassium 500 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19134047120,
          covariateName: 'tramadol hydrochloride 50 MG Oral Tablet',
          covariateShortName: 'tramadol hydrochloride 50 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19134047,
          conceptName: 'tramadol hydrochloride 50 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19077344122,
          covariateName: 'ferrous sulfate 325 MG Oral Tablet',
          covariateShortName: 'ferrous sulfate 325 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19077344,
          conceptName: 'ferrous sulfate 325 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19041324122,
          covariateName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          covariateShortName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19041324,
          conceptName: 'acetaminophen 325 MG Oral Tablet [Tylenol]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1713671120,
          covariateName: 'amoxicillin 250 MG / clavulanate 125 MG Oral Tablet',
          covariateShortName:
            'amoxicillin 250 MG / clavulanate 125 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1713671,
          conceptName: 'amoxicillin 250 MG / clavulanate 125 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19078106123,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 52,
          pct: 35.86206896551724,
          avg: 0.3586206896551724,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19073094119,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 75,
          pct: 51.724137931034484,
          avg: 0.5172413793103449,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281122,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918120,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19080128121,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 84,
          pct: 57.931034482758626,
          avg: 0.5793103448275863,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075601123,
          covariateName: 'clopidogrel 75 MG Oral Tablet',
          covariateShortName: 'clopidogrel 75 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075601,
          conceptName: 'clopidogrel 75 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924120,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19078106123,
          covariateName: 'hydrochlorothiazide 25 MG Oral Tablet',
          covariateShortName: 'hydrochlorothiazide 25 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19078106,
          conceptName: 'hydrochlorothiazide 25 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 58,
          pct: 38.666666666666664,
          avg: 0.38666666666666666,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937119,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765122,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 19008572119,
          covariateName: 'hydrocortisone 10 MG/ML Topical Cream',
          covariateShortName: 'hydrocortisone 10 MG/ML Topical Cream',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19008572,
          conceptName: 'hydrocortisone 10 MG/ML Topical Cream',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805119,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40229134119,
          covariateName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          covariateShortName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40229134,
          conceptName:
            'acetaminophen 21.7 MG/ML / dextromethorphan hydrobromide 1 MG/ML / doxylamine succinate 0.417 MG/ML Oral Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1718765119,
          covariateName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          covariateShortName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1718765,
          conceptName:
            '12 HR hydrocodone bitartrate 10 MG Extended Release Oral Capsule',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1511248123,
          covariateName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1511248,
          conceptName:
            'NDA020503 200 ACTUAT albuterol 0.09 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411122,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 122,
          analysisName: 'Drug Exposure Pre-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1519937122,
          covariateName: 'etonogestrel 68 MG Drug Implant',
          covariateShortName: 'etonogestrel 68 MG Drug Implant',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1519937,
          conceptName: 'etonogestrel 68 MG Drug Implant',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539411119,
          covariateName: 'simvastatin 20 MG Oral Tablet',
          covariateShortName: 'simvastatin 20 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539411,
          conceptName: 'simvastatin 20 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40221415123,
          covariateName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          covariateShortName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40221415,
          conceptName:
            '{5 (dienogest 2 MG / estradiol valerate 2 MG Oral Tablet) / 17 (dienogest 3 MG / estradiol valerate 2 MG Oral Tablet) / 2 (estradiol valerate 1 MG Oral Tablet) / 2 (estradiol valerate 3 MG Oral Tablet) / 2 (inert ingredients 1 MG Oral Tablet) } Pack [N...',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 1539463120,
          covariateName: 'simvastatin 10 MG Oral Tablet',
          covariateShortName: 'simvastatin 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 1539463,
          conceptName: 'simvastatin 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19075001119,
          covariateName: 'cefuroxime 250 MG Oral Tablet',
          covariateShortName: 'cefuroxime 250 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19075001,
          conceptName: 'cefuroxime 250 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 119,
          analysisName: 'Drug Exposure Post-index 3 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19080128119,
          covariateName: 'lisinopril 10 MG Oral Tablet',
          covariateShortName: 'lisinopril 10 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19080128,
          conceptName: 'lisinopril 10 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 103,
          pct: 68.66666666666667,
          avg: 0.6866666666666666,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40162606118,
          covariateName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          covariateShortName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40162606,
          conceptName:
            'acetaminophen 300 MG / hydrocodone bitartrate 5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19033893118,
          covariateName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          covariateShortName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19033893,
          conceptName: 'ibuprofen 400 MG Oral Tablet [Ibu]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231918121,
          covariateName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          covariateShortName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231918,
          conceptName:
            'acetaminophen 325 MG / oxycodone hydrochloride 10 MG Oral Tablet [Percocet]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 2,
          pct: 1.3333333333333335,
          avg: 0.013333333333333334,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261121,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094123,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 42,
          pct: 28.000000000000004,
          avg: 0.28,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169216120,
          covariateName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          covariateShortName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169216,
          conceptName:
            '120 ACTUAT fluticasone propionate 0.044 MG/ACTUAT Metered Dose Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 9,
          pct: 6,
          avg: 0.06,
        },
        {
          analysisId: 121,
          analysisName: 'Drug Exposure Pre-index 1 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40163924121,
          covariateName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          covariateShortName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40163924,
          conceptName:
            '24 HR metformin hydrochloride 500 MG Extended Release Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 5,
          strataName: 'MALE',
          covariateId: 964261118,
          covariateName: 'vitamin B12 5 MG/ML Injectable Solution',
          covariateShortName: 'vitamin B12 5 MG/ML Injectable Solution',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 964261,
          conceptName: 'vitamin B12 5 MG/ML Injectable Solution',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6896551724137931,
          avg: 0.006896551724137931,
        },
        {
          analysisId: 123,
          analysisName: 'Drug Exposure Pre-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40169281123,
          covariateName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          covariateShortName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40169281,
          conceptName:
            '60 ACTUAT fluticasone propionate 0.25 MG/ACTUAT / salmeterol 0.05 MG/ACTUAT Dry Powder Inhaler',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
        {
          analysisId: 120,
          analysisName: 'Drug Exposure Post-index 6 Months',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 19073094120,
          covariateName: 'amlodipine 2.5 MG Oral Tablet',
          covariateShortName: 'amlodipine 2.5 MG Oral Tablet',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 19073094,
          conceptName: 'amlodipine 2.5 MG Oral Tablet',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 82,
          pct: 54.666666666666664,
          avg: 0.5466666666666666,
        },
        {
          analysisId: 118,
          analysisName: 'Drug Exposure Post-index 1 Month',
          strataId: 6,
          strataName: 'FEMALE',
          covariateId: 40231805118,
          covariateName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          covariateShortName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          faType: 'CUSTOM_FE',
          domainId: 'DRUG',
          conceptId: 40231805,
          conceptName:
            'Abuse-Deterrent 12 HR oxycodone hydrochloride 10 MG Extended Release Oral Tablet [Oxycontin]',
          cohortId: 2,
          cohortName: 'Hypertension patients',
          count: 1,
          pct: 0.6666666666666667,
          avg: 0.006666666666666667,
        },
      ],
      isComparative: false,
      isSummary: true,
      faType: null,
      domainId: null,
    },
  ],
  prevalenceThreshold: 0.01,
  showEmptyResults: null,
  count: 232,
}

export const locales = [
  {
    country: 'US',
    language: 'en',
    display: 'English',
  },
  //   {
  //     country: 'IN',
  //     language: 'hi',
  //     display: 'हिन्दी',
  //   },
]

export const questions = {
  resourceType: 'Questionnaire',
  id: '3512',
  meta: {
    versionId: '1',
    lastUpdated: '2022-09-20T19:11:04.309+00:00',
    source: '#c20ae63b-e74e-99',
  },
  identifier: [
    {
      value: 'HV_Covid_Q_100',
    },
  ],
  version: '0.10',
  name: 'Travel History',
  title: 'Travel History',
  status: 'active',
  subjectType: ['Patient'],
  date: '2022-06-07',
  publisher: 'Healthville',
  contact: [
    {
      name: 'support@thehealthville.com',
    },
  ],
  description: 'Questionnaire about Your Travel History',
  copyright: 'NA',
  approvalDate: '2022-06-07',
  lastReviewDate: '2022-06-07',
  effectivePeriod: {
    start: '2022-06-07',
    end: '2024-06-07',
  },
  item: [
    {
      linkId: '1.1',
      text: 'Do you have any of the following symptoms (please tick)',
      type: 'group',
      required: true,
      item: [
        {
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
              valueCode: 'horizontal',
            },
          ],
          linkId: '1.1.1',
          text: 'Fever (38 deg C or Higher)',
          type: 'choice',
          required: true,
          answerOption: [
            {
              valueString: 'Yes',
            },
            {
              valueString: 'No',
            },
          ],
        },
        {
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
              valueCode: 'horizontal',
            },
          ],
          linkId: '1.1.2',
          text: 'Cough',
          type: 'choice',
          required: true,
          answerOption: [
            {
              valueString: 'Yes',
            },
            {
              valueString: 'No',
            },
          ],
        },
        {
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
              valueCode: 'horizontal',
            },
          ],
          linkId: '1.1.3',
          text: 'Breathlessness',
          type: 'choice',
          required: true,
          answerOption: [
            {
              valueString: 'Yes',
            },
            {
              valueString: 'No',
            },
          ],
        },
        {
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
              valueCode: 'horizontal',
            },
          ],
          linkId: '1.1.4',
          text: 'Sore throat',
          type: 'choice',
          required: true,
          answerOption: [
            {
              valueString: 'Yes',
            },
            {
              valueString: 'No',
            },
          ],
        },
        {
          linkId: '1.1.5',
          text: 'Other (Please Specify)',
          type: 'string',
          required: false,
        },
      ],
    },
    {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
          valueInteger: 3,
        },
      ],
      linkId: '2',
      text: 'Please list the country/city you have travelled to in last 2 days, including as a transit passenger boarding connecting flights, prior to arriving',
      type: 'group',
      required: false,
      repeats: true,
      item: [
        {
          linkId: '2.2',
          text: 'Date of Arrival',
          type: 'date',
          required: false,
        },
        {
          linkId: '2.1',
          text: 'Name of Country/City',
          type: 'string',
          required: false,
        },
        {
          linkId: '2.3',
          text: 'Date of Departure',
          type: 'date',
          required: false,
        },
      ],
    },
    {
      linkId: '3',
      text: 'Have you or an immediate family member come in close contact with confirmed case of CORONA VIRUS in the last 21 days?',
      type: 'group',
      required: true,
      item: [
        {
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
              valueCode: 'horizontal',
            },
          ],
          linkId: '3.1',
          text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
          type: 'choice',
          required: true,
          answerOption: [
            {
              valueString: 'Yes',
            },
            {
              valueString: 'No',
            },
          ],
        },
      ],
    },
  ],
}

export const feedbackQuestion = {
  resourceType: 'Questionnaire',
  id: '2224495',
  meta: {
    versionId: '2',
    lastUpdated: '2022-07-04T04:37:35.183+00:00',
    source: '#763696e6-9795-95',
  },
  identifier: [
    {
      value: 'HV_Dischagre_Q_101',
    },
  ],
  version: '0.10',
  name: 'Discharge Feedback',
  title: 'Discharge Feedback',
  status: 'active',
  subjectType: ['Patient'],
  date: '2022-05-23',
  publisher: 'Healthville',
  contact: [
    {
      name: 'support@thehealthville.com',
    },
  ],
  description: 'Questionnaire about Your Travel History',
  copyright: 'NA',
  approvalDate: '2022-05-23',
  lastReviewDate: '2022-05-23',
  effectivePeriod: {
    start: '2022-05-23',
    end: '2024-05-23',
  },
  item: [
    {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
          valueCode: 'horizontal',
        },
      ],
      linkId: '1.1',
      text: 'How satisfied are you with the quality of treatment?',
      type: 'choice',
      required: true,
      answerOption: [
        {
          valueString: 'Very Satisfied',
        },
        {
          valueString: 'Satisfied',
        },
        {
          valueString: 'Ok',
        },
        {
          valueString: 'Dissatisfied',
        },
        {
          valueString: 'Very Dissatisfied',
        },
      ],
    },
    {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
          valueCode: 'horizontal',
        },
      ],
      linkId: '1.2',
      text: 'How do you rate the quality of diet?',
      type: 'choice',
      required: true,
      answerOption: [
        {
          valueString: 'Very Good',
        },
        {
          valueString: 'Good',
        },
        {
          valueString: 'Avergae',
        },
        {
          valueString: 'Bad',
        },
        {
          valueString: 'Very Bad',
        },
      ],
    },
    {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
          valueCode: 'horizontal',
        },
      ],
      linkId: '1.3',
      text: 'How satisfied are you with behavior of our staff?',
      type: 'choice',
      required: true,
      answerOption: [
        {
          valueString: 'Very Satisfied',
        },
        {
          valueString: 'Satisfied',
        },
        {
          valueString: 'Ok',
        },
        {
          valueString: 'Dissatisfied',
        },
        {
          valueString: 'Very Dissatisfied',
        },
      ],
    },
    {
      linkId: '1.4',
      text: 'Recovery/Benefits',
      type: 'string',
      required: false,
    },
    {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
          valueCode: 'horizontal',
        },
      ],
      linkId: '1.5',
      text: 'How do you rate the quality of accomodation?',
      type: 'choice',
      required: true,
      answerOption: [
        {
          valueString: 'Very Good',
        },
        {
          valueString: 'Good',
        },
        {
          valueString: 'Avergae',
        },
        {
          valueString: 'Bad',
        },
        {
          valueString: 'Very Bad',
        },
      ],
    },
    {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
          valueCode: 'horizontal',
        },
      ],
      linkId: '1.6',
      text: 'How satisfied are you with the overall ambience?',
      type: 'choice',
      required: true,
      answerOption: [
        {
          valueString: 'Very Satisfied',
        },
        {
          valueString: 'Satisfied',
        },
        {
          valueString: 'Ok',
        },
        {
          valueString: 'Dissatisfied',
        },
        {
          valueString: 'Very Dissatisfied',
        },
      ],
    },
    {
      linkId: '1.7',
      text: 'Suggestions for Improvement',
      type: 'string',
      required: false,
    },
  ],
}

export const listOfHabbits: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/observation-codes',
    code: '10000-2',
    display: 'Coffee', // 'How many cups of coffee do you drink per day'
  },
  {
    system: 'http://wellopathy.com/observation-codes',
    code: '10000-1',
    display: 'Tea', // 'How many cups of tea do you drink per day'
  },
  {
    system: 'http://loinc.org',
    code: '65515-9',
    display: 'Energy level in past 7 days',
  },

  {
    system: 'http://loinc.org',
    code: '74008-4',
    display: 'Exercise Intensity',
  },
  {
    system: 'http://loinc.org',
    code: '74009-2',
    display: 'Exercise duration/Exercise frequency',
  },
  {
    system: 'http://loinc.org',
    code: '65968-0',
    display: 'How many hours do you normally sleep during day time',
  },
  {
    system: 'http://loinc.org',
    code: '65561-3',
    display: 'How many hours do you normally sleep during night time',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-habit-cs',
    code: 'other-habit',
    display: 'Other',
  },
  {
    system: 'http://loinc.org',
    code: '95607-8',
    display: 'Sleep quality', // - 1-5 numeric rating [Score] 24 hour'
  },
]

export const excersiseCode: R4.ICoding[] = [
  {
    system: '',
    code: 'no-exercise',
    display: 'No Exercise',
  },
  {
    system: '',
    code: '1-days-a-week',
    display: '1 Day/week',
  },
  {
    system: '',
    code: '2-days-a-week',
    display: '2 Days/week',
  },
  {
    system: '',
    code: '3-days-a-week',
    display: '3 Days/week',
  },
  {
    system: '',
    code: '4-days-a-week',
    display: '4 Days/week',
  },
  {
    system: '',
    code: '5-days-a-week',
    display: '5 Days/week',
  },
  {
    system: '',
    code: '6-days-a-week',
    display: '6 Days/week',
  },
  {
    system: '',
    code: '7-days-a-week',
    display: '7 Days/week',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const intensity: R4.ICoding[] = [
  {
    system: '',
    code: 'VL',
    display: 'Very Low',
  },
  {
    system: '',
    code: 'LO',
    display: 'Low',
  },
  {
    system: '',
    code: 'Ok',
    display: 'Ok',
  },
  {
    system: '',
    code: 'MD',
    display: 'Moderate',
  },
  {
    system: '',
    code: 'HI',
    display: 'High',
  },
]

export const energyLevels: R4.ICoding[] = [
  {
    system: '',
    code: 'VL',
    display: 'Very Low',
  },
  {
    system: '',
    code: 'LO',
    display: 'Low',
  },
  {
    system: '',
    code: 'MD',
    display: 'Medium',
  },
  {
    system: '',
    code: 'EG',
    display: 'Energized',
  },
  {
    system: '',
    code: 'HE',
    display: 'Highly Energized',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const breathe: R4.ICoding[] = [
  {
    system: '',
    code: 'comfortable',
    display: 'Comfortable',
  },
  {
    system: '',
    code: 'slightly-labored',
    display: 'Slightly Labored',
  },
  {
    system: '',
    code: 'difficult',
    display: 'Difficult',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const others: R4.ICoding[] = [
  {
    system: '',
    code: 'other',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const sleepPattern: R4.ICoding[] = [
  {
    system: '',
    code: '1-hours-per-day',
    display: '1 Hour/day',
  },
  {
    system: '',
    code: '2-hours-per-day',
    display: '2 Hours/day',
  },
  {
    system: '',
    code: '3-hours-per-day',
    display: '3 Hours/day',
  },
  {
    system: '',
    code: '4-hours-per-day',
    display: '4 Hours/day',
  },
  {
    system: '',
    code: '5-hours-per-day',
    display: '5 Hours/day',
  },
  {
    system: '',
    code: '6-hours-per-day',
    display: '6 Hours/day',
  },
  {
    system: '',
    code: '7-hours-per-day',
    display: '7 Hours/day',
  },
  {
    system: '',
    code: '8-hours-per-day',
    display: '8 Hours/day',
  },
  {
    system: '',
    code: '9-hours-per-day',
    display: '9 Hours/day',
  },
  {
    system: '',
    code: '10-hours-per-day',
    display: '10 Hours/day',
  },
  {
    system: '',
    code: '11-hours-per-day',
    display: '11 Hours/day',
  },
  {
    system: '',
    code: '12-hours-per-day',
    display: '12 Hours/day',
  },
  {
    system: '',
    code: '13-hours-per-day',
    display: '13 Hours/day',
  },
  {
    system: '',
    code: '14-hours-per-day',
    display: '14 Hours/day',
  },
  {
    system: '',
    code: '15-hours-per-day',
    display: '15 Hours/day',
  },
  {
    system: '',
    code: '16-hours-per-day',
    display: '16 Hours/day',
  },
  {
    system: '',
    code: '17-hours-per-day',
    display: '17 Hours/day',
  },
  {
    system: '',
    code: '18-hours-per-day',
    display: '18 Hours/day',
  },
  {
    system: '',
    code: '19-hours-per-day',
    display: '19 Hours/day',
  },
  {
    system: '',
    code: '20-hours-per-day',
    display: '20 Hours/day',
  },
]

export const addictions: R4.ICoding[] = [
  {
    system: 'http://loinc.org',
    code: '64218-1',
    display: 'Smoke', // 'How many cigarettes do you smoke per day now'
  },
  {
    system: 'http://loinc.org',
    code: '96103-7',
    display: 'Chewing Tobacco/ Paan/ Gutkha', // 'Tobacco amount per day'
  },
  {
    system: 'http://loinc.org',
    code: '68518-0',
    display: 'Alcohol', // 'How often do you have a drink containing alcohol'
  },
  {
    system: 'http://wellopathy.com/observation-codes',
    code: '10000-1',
    display: 'Tea', // 'How many cups of tea do you drink per day'
  },
  {
    system: 'http://wellopathy.com/observation-codes',
    code: '10000-2',
    display: 'Coffee', // 'How many cups of coffee do you drink per day'
  },
  {
    system: 'http://wellopathy.com/observation-codes',
    code: '10000-3',
    display: 'Drugs', // 'How many types of drugs do you take'
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const smokeCounts: R4.ICoding[] = [
  {
    system: '',
    code: 'do-not-smoke',
    display: "Don't Smoke",
  },
  {
    system: '',
    code: '1-cigarettes-a-day',
    display: '1 Cigarette/day',
  },
  {
    system: '',
    code: '2-cigarettes-a-day',
    display: '2 Cigarettes/day',
  },
  {
    system: '',
    code: '3-cigarettes-a-day',
    display: '3 Cigarettes/day',
  },
  {
    system: '',
    code: '4-cigarettes-a-day',
    display: '4 Cigarettes/day',
  },
  {
    system: '',
    code: '5-cigarettes-a-day',
    display: '5 Cigarettes/day',
  },
  {
    system: '',
    code: '6-or-more-cigarettes-a-day',
    display: '6 or more Cigarettes/day',
  },
]

export const tobacoCount: R4.ICoding[] = [
  {
    system: '',
    code: 'do-not-chew',
    display: "Don't Chew",
  },
  {
    system: '',
    code: '1-times-a-day',
    display: '1 Time/day',
  },
  {
    system: '',
    code: '2-times-a-day',
    display: '2 Times/day',
  },
  {
    system: '',
    code: '3-times-a-day',
    display: '3 Times/day',
  },
  {
    system: '',
    code: '4-times-a-day',
    display: '4 Times/day',
  },
  {
    system: '',
    code: '5-times-a-day',
    display: '5 Times/day',
  },
  {
    system: '',
    code: '6-or-more-times-a-day',
    display: '6 or More Times/day',
  },
]

export const alcoholCount: R4.ICoding[] = [
  {
    system: '',
    code: 'do-not-drink',
    display: "Don't Drink",
  },
  {
    system: '',
    code: '1-drinks-a-month',
    display: '1 Drink/month',
  },
  {
    system: '',
    code: '2-drinks-a-month',
    display: '2 Drinks/month',
  },
  {
    system: '',
    code: '3-drinks-a-month',
    display: '3 Drinks/month',
  },
  {
    system: '',
    code: '4-drinks-a-month',
    display: '4 Drinks/month',
  },
  {
    system: '',
    code: '5-drinks-a-month',
    display: '5 Drinks/month',
  },
  {
    system: '',
    code: '6-or-more-drinks-a-month',
    display: '6 Drinks/month',
  },
]

export const teaCount: R4.ICoding[] = [
  {
    system: '',
    code: 'do-not-drink',
    display: "Don't Drink",
  },
  {
    system: '',
    code: '1-cups-a-day',
    display: '1 Cup/day',
  },
  {
    system: '',
    code: '2-cups-a-day',
    display: '2 Cups/day',
  },
  {
    system: '',
    code: '3-cups-a-day',
    display: '3 Cups/day',
  },
  {
    system: '',
    code: '4-cups-a-day',
    display: '4 Cups/day',
  },
  {
    system: '',
    code: '5-cups-a-day',
    display: '5 Cups/day',
  },
  {
    system: '',
    code: '6-or-more-cups-a-day',
    display: '6 or More Cups/day',
  },
]

export const drugCount: R4.ICoding[] = [
  {
    system: '',
    code: 'do-not-do-drugs',
    display: "Don't Do Drugs",
  },
  {
    system: '',
    code: '1-types',
    display: '1 Type',
  },
  {
    system: '',
    code: '2-types',
    display: '2 Types',
  },
  {
    system: '',
    code: '3-types',
    display: '3 Types',
  },
  {
    system: '',
    code: '4-types',
    display: '4 Types',
  },
  {
    system: '',
    code: '5-types',
    display: '5 Types',
  },
  {
    system: '',
    code: '6-or-more-types',
    display: '6 or More Types',
  },
]

export const drugOpinion: R4.ICoding[] = [
  {
    system: '',
    code: 'yes',
    display: 'Yes',
  },
  {
    system: '',
    code: 'no',
    display: 'No',
  },
]

export const occupations: R4.ICoding[] = [
  {
    display: 'Accountant and auditor',
    code: '106302007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Actor',
    code: '12919008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Advertising and promotions manager',
    code: '158842006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Advertising sales agent',
    code: '50138006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Aerospace engineer',
    code: '91091000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agricultural engineers',
    code: '16305002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Aircraft pilot and flight engineer',
    code: '106280003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Archeologist',
    code: '158929005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Architect',
    code: '308047006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Astrologer',
    code: '18309002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Athletes, coaches, umpires, and related workers',
    code: '106325008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Baker',
    code: '308241004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Barber',
    code: '12514009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bartender',
    code: '2257003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Biologist',
    code: '72327007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Broker',
    code: '158804000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Businessman',
    code: '308169002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carpenter',
    code: '24413000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chartered Accountant',
    code: '158790009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chef',
    code: '308207007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chief steward (ship)',
    code: '50798002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Clergy',
    code: '309456003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Computer Hardware Engineer',
    code: '236301003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Consultant',
    code: '768839008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cook/Chef',
    code: '265985004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dancer and choreographer',
    code: '106320003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decorator',
    code: '308348007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Designer',
    code: '106316002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Detective',
    code: '10284005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dietitian',
    code: '159033005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Doctor',
    code: '158965000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Domestic helper/maid',
    code: '265988002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Draftsman',
    code: '106271003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Driver',
    code: '236320001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Economist',
    code: '106301000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Editor',
    code: '308003000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Electrical/electronics engineer',
    code: '106263002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Electrician',
    code: '112262001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Engineer, Other',
    code: '106269003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Farmer',
    code: '106388008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Financial Analyst',
    code: '158806003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fire fighter',
    code: '106382009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fisherman and related fishing worker',
    code: '106399005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fitter/Maintener/Mechanic',
    code: '91091000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Flight attendant',
    code: '83610008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Front Desk Exectuive',
    code: '159561009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gardener',
    code: '34024003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Geologist',
    code: '159160001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Government administrator',
    code: '24794006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hairdressers, hairstylists, and cosmetologists',
    code: '265992009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Healthcare Worker',
    code: '223366009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Historian',
    code: '78826004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Horticulturist',
    code: '61030009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Housewife',
    code: '444168002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Illustrator/Draftsman',
    code: '106271003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Inspector',
    code: '158866000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Janitor and Cleaner',
    code: '159756005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Journalist',
    code: '29536009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Judge',
    code: '11205001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Laborer',
    code: '106540006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lawyer',
    code: '12877000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lecturer',
    code: '158891000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Librarian',
    code: '59142002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Machinery Operator',
    code: '159579008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Manager',
    code: '106333009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Marine Engineer',
    code: '45870003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mathematician',
    code: '159165006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mechanical Engineer',
    code: '106264008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Militaty Personnel',
    code: '302314004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mining Engineer',
    code: '106264008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Musician, Singer and Related Worker',
    code: '106319009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nurse',
    code: '106292003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Other Government executive',
    code: '16849006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Other Govt Servant',
    code: '16849006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Other Private Sector Worker',
    code: '106541005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Other Religious Worker',
    code: '64220005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Packer',
    code: '46858005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Painter',
    code: '106510009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pharmacist',
    code: '46255001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Photographer',
    code: '308017009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Plumber',
    code: '106487002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Policeman',
    code: '14406004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Politician/Legislatures',
    code: '69493002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Priest',
    code: '79918004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Printing Press Operator',
    code: '106504009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Producer/Director',
    code: '265944009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Professor',
    code: '158890004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Psychologist',
    code: '59944000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Real Estate Developer',
    code: '308365003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Researcher',
    code: '769038007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sales Executive',
    code: '307958002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Scientist',
    code: '308027003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secretary',
    code: '308204000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Security Guards',
    code: '159675005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ship Crew/Sailor',
    code: '106534004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sociologist',
    code: '824004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Software Engineer',
    code: '79859009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Space Scientist',
    code: '81779000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Statistician',
    code: '106298004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Student',
    code: '65853000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Supervisor',
    code: '236321002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Surgeon',
    code: '304292004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Surveyor',
    code: '106270002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Systems Analyst',
    code: '20222007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tailor',
    code: '106451009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Teacher',
    code: '106304008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Technical Writer',
    code: '89007006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Technician/Technical Assistant',
    code: '159324001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Therapist',
    code: '309398001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tool and die maker',
    code: '8861007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tour and travel guides',
    code: '36548004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Travel Agent',
    code: '159395009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urban and regional planners',
    code: '63502007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Veterinarian',
    code: '106290006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Volunteer',
    code: '276037005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Waiters and waitresses',
    code: '159695004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Welder',
    code: '106488007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Writers and authors',
    code: '37920003',
    system: 'http://snomed.info/sct',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const dietType = [
  {
    system: 'http://snomed.info/sct',
    code: '300928006',
    display: 'Vegetarian',
  },
  {
    system: 'http://snomed.info/sct',
    code: '226081000',
    display: 'Non-veg',
  },
  {
    system: 'http://snomed.info/sct',
    code: '267111003',
    display: 'Vegan',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const appetite = [
  {
    system: 'http://snomed.info/sct',
    code: '64379006',
    display: 'Decreased Appetite',
  },
  {
    system: 'http://snomed.info/sct',
    code: '72405004',
    display: 'Increased Appetite',
  },
  {
    system: 'http://snomed.info/sct',
    code: '161825005',
    display: 'Good Appetite',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const micturition: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '162115004',
    display: 'Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '249291007',
    display: 'Decreased frequency',
  },
  {
    system: 'http://snomed.info/sct',
    code: '162116003',
    display: 'Increased Frequency',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const bowels = [
  {
    system: 'http://loinc.org',
    code: 'LA19583-6',
    display: 'Irregular',
  },
  {
    system: 'http://loinc.org',
    code: 'LA15487-4',
    display: 'Regular',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const stressLevel = [
  {
    system: 'http://loinc.org',
    code: 'LA6568-5',
    display: 'Not at all',
  },
  {
    system: 'http://loinc.org',
    code: 'LA13863-8',
    display: 'A little bit',
  },
  {
    system: 'http://loinc.org',
    code: 'LA13909-9',
    display: 'Somewhat',
  },
  {
    system: 'http://loinc.org',
    code: 'LA13902-4',
    display: 'Quite a bit',
  },
  {
    system: 'http://loinc.org',
    code: 'LA13914-9',
    display: 'Very much',
  },
  {
    system: 'http://loinc.org',
    code: 'LA30122-8',
    display: 'I choose not to answer this question',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const reasonData: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'prov-pats',
    display: 'Patient Requested',
  },
  {
    system:
      'http://terminology.hl7.org/CodeSystem/appointment-cancellation-reason',
    code: 'pat-mv',
    display: 'Patient Discharged',
  },
  {
    system:
      'http://terminology.hl7.org/CodeSystem/appointment-cancellation-reason',
    code: 'pat-fb',
    display: 'Patient Feeling Better',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'prov-hosp',
    display: 'Therapist Unwell',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'pat-crs',
    display: 'Patient Unwell',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'pat-dec',
    display: 'Patient Deceased',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'prov-pers',
    display: 'Therapist Not Available',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'maint',
    display: 'Equipment Maintenance/Repair',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'meds-inc',
    display: 'Preparation Incomplete/Consumables Unavailable',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'oth-room',
    display: 'Room/Resource Maintenance',
  },
  {
    system: 'http://wellopathy.com/appointment-cancel-codes',
    code: 'oth-weath',
    display: 'Inclement Weather',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const discharge: R4.ICoding[] = [
  {
    system: 'http://loinc.org',
    code: '72229-8',
    display: 'Discharge summary',
  },
  {
    system: 'http://loinc.org',
    code: '81242-0',
    display: 'Enhanced discharge summary',
  },
  {
    system: 'http://loinc.org',
    code: '81221-4',
    display: 'Transfer summary',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const dietDuration: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.early',
    display: 'Early Morning',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: 'Breakfast',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: 'Mid Morning',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: 'Lunch',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: 'Evening Snack',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: 'Night',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: 'Bed Time',
  },
]

export const unitCodes: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-unit-uom-cs',
    code: 'PKT',
    display: 'PKT',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-unit-uom-cs',
    code: 'PCS',
    display: 'PCS',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'kg',
    display: 'KG',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'L',
    display: 'LTR',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'mL',
    display: 'ml',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const therapyBillingCodes: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/chargeitem-status',
    code: 'not-billable',
    display: 'Not billable',
  },
  {
    system: 'http://hl7.org/fhir/chargeitem-status',
    code: 'billable',
    display: 'Billable',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const rules = `
<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><u><span lang=EN-IN
style='font-size:14.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>Consent </span></u></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'></span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> 
I/we hereby affirm that l/we have been explained in detail about nature cure, yoga by competent authority. I voluntarily would like to undergo the nature cure, yoga, Pancha karma treatments at Healthville. Management will not be responsible for any untoward reaction during the stay and the institution will be free to transfer me to any suitable hospital in any such case with my own cost and will not be opposed by me and my family members in any circumstances. I will abide by rules and regulations of the institute and will follow the discipline of the institution, if found in violation of any rules, regulation and discipline it is understood that management will be free to take any strict action against me and l/we have to follow it. Institution, trustees, Doctors or employees will not be liable for any loss, damage and personal theft.</span></p>


</div>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><u><span lang=EN-IN
style='font-size:14.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>Rules &amp; Regulation </span></u></p>

<p class=MsoNormal align=center style='text-align:center'><b><u><span
lang=EN-IN style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>GENERAL INSTRUCTION</span></u></b></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>Guests must
carry their ID proof in original at the time of arrival </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Single therapy will be provided in a day as
per the scheduled appointment </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Check out time:- 10.00 AM </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> We have our swimming costume on a chargeable
basis, but if you use your swimming costume, please maintain the hygiene. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Use of stimulants like tea, coffee, tobacco,
alcohol, pan, zarda and food items brought from outside is strictly prohibited </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Guests are required to involve themselves in
all routine activities of the centre </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> During the stay, patients are not allowed to
go out of the campus </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Strict discipline, cleanliness and decorum
are to be maintained by the guests of the centre </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Kindly Do Not Bring valuable ornaments to
the property. The management will not be responsible in case there is any loss
of such valuables </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Mobiles &amp; Laptop use is restricted to
the accommodation rooms only. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Cooking inside room is strictly prohibited </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Plucking flowers, fruits, plants and etc is
strictly prohibited. If found fine will be charged. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> All the dues and bills are to be settled
before entry </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Laboratory reports, laundry, pick and drop
facilities and other facilities will be charged extra. </span></P>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN style='font-size:10.5pt;
line-height:107%;font-family:"Arial",sans-serif;color:#33333F'>
The package tariff is inclusive of stay, diet and medical consultation.
However, there will be a few premium therapies, which, if opted and prescribed,
will be charged extra. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Tariffs, policies, rules and regulations are
subject to change with prior notice </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Management reserves the right to take final
&amp; binding decisions in case of any disputes </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> It should be the endeavour of all to follow
the rules and regulations, maintain decorum &amp; preserve the sanctity of the
place </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Misbehaving with doctors/therapist/staff by
the guests is viewed as a serious breach of the centre disciplinary rules,
&amp; such situation will lead to an immediate discharge of the guest on the
disciplinary grounds from the centre and any charges already paid will stand
forfeited. </span></p>

<p class=MsoNormal><span lang=EN-IN style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#33333F'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><u><span
lang=EN-IN style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>FOR MEMBERSHIP </span></u></b></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Membership is valid for definite period only
&amp; cannot be amended in any situation. Non utilized days will lapse once the
membership validity is expired. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Membership can be availed for the minimum 7
days package &amp; above per person at any given point of time. Even if the
member stays for less than 7 days, 7 days from the total number of membership
days will be deducted automatically. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Membership charge will be an one time
payment in full during registration after which agreement letter will be issued
accordingly, </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Management reserves the right to change the
terms of membership from time to time without any notice. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Membership benefits will not apply during
special events, black out days and festival weeks. </span></P>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN style='font-size:10.5pt;
line-height:107%;font-family:"Arial",sans-serif;color:#33333F'>
All reservations to be communicated at least 2 weeks advance and are valid only
subject to availability after receiving the booking confirmation from the
centre, </span></p>

<p class=MsoNormal align=center style='text-align:center'><b><u><span
lang=EN-IN style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><u><span
lang=EN-IN style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>ADMISSION NON-ELIGIBILITY RULES </span></u></b></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Below 16 years and above 80 years of age are
not eligible for treatment (Therapy) </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> A person is suffering from the diseases
other than mentioned under &quot;Disease Treated.&quot; </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Pregnant women </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Persons with communicable diseases </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Persons with an unstable heart condition </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Disabled/immobile persons, without aides
Patients addicted to evils such as drugs, smoking, alcohol, zarda, pan masala
etc </span></p>

<p class=MsoNormal><span lang=EN-IN style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#33333F'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><u><span
lang=EN-IN style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'>ADMISSION NON-ELIGIBILITY RULES </span></u></b></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Centre requires three clear working days
notice from the guest in writing for the postponement or cancellation of a
confirmed booking. In such a case, only the processing charge will be deducted
Rs 1000. </span></p>

<p class=MsoNormal><span lang=EN-IN style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#33333F'> &nbsp;&nbsp;&nbsp;&nbsp; 1:Cancellation
Notice received two days prior- only 50% of the total reservation amount will
be refunded. </span></p>

<p class=MsoNormal><span lang=EN-IN style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#33333F'>&nbsp;&nbsp;&nbsp;&nbsp; 2:Cancellation
Notice received one day prior- only 25% of the total reservation amount will be
refunded. </span></p>

<p class=MsoNormal><span lang=EN-IN style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#33333F'>&nbsp;&nbsp;&nbsp;&nbsp; 3:Cancellation
Notice received less than one day (0hrs to 24hrs of arrival time)-There will be
no refund on such cancellation of the reservation </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> Frequent changes or cancellations in booking
dates are not entertained. </span></p>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"MS Gothic";color:#33333F'>✓</span><span lang=EN-IN
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#33333F'> In the event of patients' request for
premature discharge from the treatment process, the balance amount in the
package will not be refunded. </span></p>

</div>`

export const therapyType: R4.ICoding[] = [
  { code: 'hot', display: 'Hot' },
  { code: 'cold', display: 'Cold' },
  { code: 'neutral', display: 'Neutral' },
  { code: 'none', display: 'None' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const obsData: R4.ICoding[] = [
  { code: '200144004', display: 'C-Section', system: 'http://snomed.info/sct' },
  { code: '48782003', display: 'Normal', system: 'http://snomed.info/sct' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const mensType: R4.ICoding[] = [
  { code: '78456001', display: 'Regular', system: 'http://snomed.info/sct' },
  { code: '80182007', display: 'Irregular', system: 'http://snomed.info/sct' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const mensClots: R4.ICoding[] = [
  { code: '789554009', display: 'Absent', system: 'http://snomed.info/sct' },
  { code: '289588007', display: 'Present', system: 'http://snomed.info/sct' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const mensLeukorrhea: R4.ICoding[] = [
  { code: '2667000', display: 'Absent', system: 'http://snomed.info/sct' },
  { code: '52101004', display: 'Present', system: 'http://snomed.info/sct' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const systolicAndDiastolicAndPulseAverage = [
  {
    name: 'Systolic Average',
    style: '#FFDA83',
    id: 'right',
    category: 'Systolic Average',
    data: [
      { day: '23/05/2020', value: 76 },
      { day: '24/05/2020', value: 98 },
      { day: '25/05/2020', value: 63 },
      { day: '26/05/2020', value: 112 },
      { day: '27/05/2020', value: 81 },
      { day: '28/05/2020', value: 81 },
      { day: '29/05/2020', value: 72 },
      { day: '30/05/2020', value: 74 },
      { day: '31/05/2020', value: 124 },
    ],
  },
]

export const obsDataForLabTest = [
  {
    name: 'Serum Creatinine',
    type: 'Test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338957',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '2160-0',
              display: 'Serum Creatinine',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Serum Creatinine',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
          decimalPrecision: 2,
        },
      },
    ],
  },

  {
    name: 'Hemoglobin',
    type: 'test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338983',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '718-7',
              display: 'Hemoglobin',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Hemoglobin',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'g/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
    ],
  },
  {
    name: 'Post Prandial Blood Sugar',
    type: 'Test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338954',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '1521-4',
              display: 'Post Prandial Blood Sugar',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Post Prandial Blood Sugar',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
        },
      },
    ],
    qualifiedInterval: [
      {
        category: 'reference',
        range: {
          low: {
            value: 100.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 140.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },
  {
    name: 'Serum C peptide',
    type: 'Test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338958',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '1986-9',
              display: 'Serum C peptide',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Serum C peptide',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'ng/mL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
    ],
  },
  {
    name: 'Fasting Blood Sugar',
    type: 'Test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338953',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '1558-6',
              display: 'Fasting Blood Sugar',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Fasting Blood Sugar',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
        },
      },
    ],
  },
  {
    name: 'Serum Urea',
    type: 'Test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338956',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '3091-6',
              display: 'Serum Urea',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Serum Urea',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
    ],
  },
  {
    name: 'HbA1c',
    type: 'Test',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338955',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '4548-4',
              display: 'HbA1c',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'HbA1c',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
    ],
  },
].sort((a, b) => (a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0))

export const panelData = [
  {
    name: 'CBC panel - Blood',
    id: 1,
    type: 'Panel',
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338981',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '6690-2',
              display: 'WBC',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'WBC',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338982',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '789-8',
              display: 'RBC',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'RBC',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E6/uL',
              },
            ],
          },
          decimalPrecision: 2,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338983',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '718-7',
              display: 'Hemoglobin',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Hemoglobin',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'g/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338984',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '4544-3',
              display: 'Hematocrit',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Hematocrit',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338985',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '787-2',
              display: 'MCV',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'MCV',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'fL',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338986',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '785-6',
              display: 'MCH',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'MCH',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'pg',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338987',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '786-4',
              display: 'MCHC',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'MCHC',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'g/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338988',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '788-0',
              display: 'RDW',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'RDW',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338989',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '777-3',
              display: 'Platelets',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Platelets',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338990',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '770-8',
              display: 'Neutrophils',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Neutrophils',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338991',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '736-9',
              display: 'Lymphs',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Lymphs',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338992',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5905-5',
              display: 'Monocytes',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Monocytes',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338993',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '713-8',
              display: 'Eos',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Eos',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338994',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '706-2',
              display: 'Basos',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Basos',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 0,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338995',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '751-8',
              display: 'Neutrophils (Absolute)',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Neutrophils (Absolute)',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338996',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '731-0',
              display: 'Lymphs (Absolute)',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Lymphs (Absolute)',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338997',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '742-7',
              display: 'Monocytes(Absolute)',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Monocytes(Absolute)',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338998',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '711-2',
              display: 'Eos (Absolute)',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Eos (Absolute)',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338999',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '704-7',
              display: 'Baso (Absolute)',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'Baso (Absolute)',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'x10E3/uL',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4339000',
        meta: {
          versionId: '1',
          lastUpdated: '2022-11-17T09:06:08.286+00:00',
          source: '#ef5c232b-f905-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '58413-6',
              display: 'NRBC',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: true,
        preferredReportName: 'NRBC',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '%',
              },
            ],
          },
          decimalPrecision: 1,
        },
      },
    ].sort((a, b) =>
      a.preferredReportName! > b.preferredReportName!
        ? 1
        : b.preferredReportName! > a.preferredReportName!
        ? -1
        : 0
    ),
  },
  {
    name: 'Diabetic Retinopathy Screening',
    type: 'panel',
    id: 2,
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4339001',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '71491-5',
              display: 'Right Eye Diabetic Retinopathy Severity Level',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Right Eye Diabetic Retinopathy Severity Level',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4339002',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '71490-7',
              display: 'Left Eye Diabetic Retinopathy Severity Level',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Left Eye Diabetic Retinopathy Severity Level',
      },
    ].sort((a, b) =>
      a.preferredReportName! > b.preferredReportName!
        ? 1
        : b.preferredReportName! > a.preferredReportName!
        ? -1
        : 0
    ),
  },
  {
    name: 'Lipid Profile',
    type: 'Panel',
    id: 3,
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '23293',
        meta: {
          versionId: '3',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '2093-3',
              display: 'Total Cholesterol',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'Total Cholesterol',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              high: {
                value: 200.0,
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338961',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '2571-8',
              display: 'Triglycerides',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'Triglycerides',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              high: {
                value: 150.0,
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338963',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '13458-5',
              display: 'VLDL Cholesterol Calc',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'VLDL Cholesterol Calc',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              high: {
                value: 30.0,
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338964',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '13457-7',
              display: 'LDL Cholesterol Calc',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'LDL Cholesterol Calc',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            ],
          },
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              high: {
                value: 100.0,
                system: 'http://unitsofmeasure.org',
                code: 'mg/dL',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338965',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '11054-4',
              display: 'LDL Cholesterol/HDL Cholesterol Ratio',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'LDL Cholesterol/HDL Cholesterol Ratio',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '{ratio}',
              },
            ],
          },
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              low: {
                value: 0.5,
                system: 'http://unitsofmeasure.org',
                code: '{ratio}',
              },
              high: {
                value: 3.0,
                system: 'http://unitsofmeasure.org',
                code: '{ratio}',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338966',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: 'I9830-1',
              display: 'Total Cholesterol/HDL Cholesterol',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'Total Cholesterol/HDL Cholesterol',
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: 'http://unitsofmeasure.org',
                code: '{ratio}',
              },
            ],
          },
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              low: {
                value: 0.0,
                system: 'http://unitsofmeasure.org',
                code: '{ratio}',
              },
              high: {
                value: 5.0,
                system: 'http://unitsofmeasure.org',
                code: '{ratio}',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
    ].sort((a, b) =>
      a.preferredReportName! > b.preferredReportName!
        ? 1
        : b.preferredReportName! > a.preferredReportName!
        ? -1
        : 0
    ),
  },
  {
    name: 'Urinalysis complete panel',
    type: 'panel',
    id: 4,
    obs: [
      {
        resourceType: 'ObservationDefinition',
        id: '4338967',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5811-5',
              display: 'Specific Gravity',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'Specific Gravity',
        quantitativeDetails: {
          decimalPrecision: 3,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              low: {
                value: 1.005,
                system: 'http://unitsofmeasure.org',
              },
              high: {
                value: 1.03,
                system: 'http://unitsofmeasure.org',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338968',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5803-2',
              display: 'pH',
            },
          ],
        },
        permittedDataType: ['Quantity'],
        multipleResultsAllowed: false,
        preferredReportName: 'pH',
        quantitativeDetails: {
          decimalPrecision: 1,
        },
        qualifiedInterval: [
          {
            category: 'reference',
            range: {
              low: {
                value: 5.0,
                system: 'http://unitsofmeasure.org',
              },
              high: {
                value: 7.5,
                system: 'http://unitsofmeasure.org',
              },
            },
            context: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
                  code: 'normal',
                  display: 'Normal Range',
                },
              ],
            },
          },
        ],
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338969',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5778-6',
              display: 'Urine−Color',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Urine−Color',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338970',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5767-9',
              display: 'Appearance',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Appearance',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338979',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5799-2',
              display: 'Leukocyte esterase',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Leukocyte esterase',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338971',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '20454-5',
              display: 'Protein',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Protein',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338972',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '25428-4',
              display: 'Glucose',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Glucose',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338973',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '2514-8',
              display: 'Ketones',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Ketones',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338974',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5794-3',
              display: 'Hemoglobin presence',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Hemoglobin presence',
      },
      {
        resourceType: 'ObservationDefinition',
        id: '4338980',
        meta: {
          versionId: '2',
          lastUpdated: '2022-11-23T06:32:41.329+00:00',
          source: '#823257c1-c7ce-92',
        },
        category: [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '5809-9',
              display: 'Reducing substances',
            },
          ],
        },
        permittedDataType: ['string'],
        quantitativeDetails: {
          unit: {
            coding: [
              {
                system: '',
                code: '',
              },
            ],
          },
          decimalPrecision: 0,
        },
        multipleResultsAllowed: false,
        preferredReportName: 'Reducing substances',
      },
    ].sort((a, b) =>
      a.preferredReportName! > b.preferredReportName!
        ? 1
        : b.preferredReportName! > a.preferredReportName!
        ? -1
        : 0
    ),
  },
].sort((a, b) => (a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0))

export const cbcpanel: Panel1[] = [
  {
    name: 'Baso (Absolute)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '704-7',
      display: 'Baso (Absolute)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 11.7,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 15.4,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Basos',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '706-2',
      display: 'Basos',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.5,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 1.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Eos',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '713-8',
      display: 'Eos',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.0,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 0.4,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Eos (Absolute)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '711-2',
      display: 'Eos (Absolute)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.0,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 0.4,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Hematocrit',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '4544-3',
      display: 'Hematocrit',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 41.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 50.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._male,
      },
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 36.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 44.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._female,
      },
    ],
  },

  {
    name: 'Hemoglobin',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'g/dL',
    code: {
      system: 'http://loinc.org',
      code: '718-7',
      display: 'Hemoglobin',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 14.0,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
          high: {
            value: 18.0,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._male,
      },
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 12.0,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
          high: {
            value: 16.0,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._female,
      },
    ],
  },

  {
    name: 'Lymphs',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '736-9',
      display: 'Lymphs',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 20.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 40.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Lymphs (Absolute)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '731-0',
      display: 'Lymphs (Absolute)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.7,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 3.1,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'MCH',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'pg',
    code: {
      system: 'http://loinc.org',
      code: '785-6',
      display: 'MCH',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 26.6,
            system: 'http://unitsofmeasure.org',
            code: 'pg',
          },
          high: {
            value: 33.0,
            system: 'http://unitsofmeasure.org',
            code: 'pg',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'MCHC',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'g/dL',
    code: {
      system: 'http://loinc.org',
      code: '786-4',
      display: 'MCHC',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 31.5,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
          high: {
            value: 35.7,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'MCV',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'fL',
    code: {
      system: 'http://loinc.org',
      code: '787-2',
      display: 'MCV',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 80.0,
            system: 'http://unitsofmeasure.org',
            code: 'fL',
          },
          high: {
            value: 100.0,
            system: 'http://unitsofmeasure.org',
            code: 'fL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Monocytes',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '5905-5',
      display: 'Monocytes',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 2.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 8.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Monocytes(Absolute)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '742-7',
      display: 'Monocytes(Absolute)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.1,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 0.9,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'NRBC',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '58413-6',
      display: 'NRBC',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 0.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Neutrophils',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '770-8',
      display: 'Neutrophils',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 40.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 60.0,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Neutrophils (Absolute)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '751-8',
      display: 'Neutrophils (Absolute)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 2.5,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 6.0,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Platelets',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '777-3',
      display: 'Platelets',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 150.0,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 450.0,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'RBC',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '789-8',
      display: 'RBC',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 4.7,
            system: 'http://unitsofmeasure.org',
            code: 'x10E6/uL',
          },
          high: {
            value: 6.1,
            system: 'http://unitsofmeasure.org',
            code: 'x10E6/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._male,
      },
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 4.2,
            system: 'http://unitsofmeasure.org',
            code: 'x10E6/uL',
          },
          high: {
            value: 5.4,
            system: 'http://unitsofmeasure.org',
            code: 'x10E6/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._female,
      },
    ],
  },
  {
    name: 'RDW',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '788-0',
      display: 'RDW',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 11.7,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
          high: {
            value: 15.4,
            system: 'http://unitsofmeasure.org',
            code: '%',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'WBC',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'x10E3/uL',
    code: {
      system: 'http://loinc.org',
      code: '6690-2',
      display: 'WBC',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 4.5,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
          high: {
            value: 11.0,
            system: 'http://unitsofmeasure.org',
            code: 'x10E3/uL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },
]

export const lipidPanel: Panel1[] = [
  {
    name: 'HDL Cholesterol',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '2085-9',
      display: 'HDL Cholesterol',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          high: {
            value: 40,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._male,
      },
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          high: {
            value: 60,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._female,
      },
    ],
  },

  {
    name: 'LDL Chilesterol Calc',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '13457-7',
      display: 'LDL Chilesterol Calc',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          high: {
            value: 100.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'LDL Cholesterol/HDL Cholesterol Ratio',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '{ratio}',

    code: {
      system: 'http://loinc.org',
      code: '11054-4',
      display: 'LDL Cholesterol/HDL Cholesterol Ratio',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.5,
            system: 'http://unitsofmeasure.org',
            code: '{ratio}',
          },
          high: {
            value: 3.0,
            system: 'http://unitsofmeasure.org',
            code: '{ratio}',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Total Cholesterol',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',

    code: {
      system: 'http://loinc.org',
      code: '2093-3',
      display: 'Total Cholesterol',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          high: {
            value: 200.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Total Cholesterol/HDL Cholesterol',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '{ratio}',

    code: {
      system: 'http://loinc.org',
      code: 'I9830-1',
      display: 'Total Cholesterol/HDL Cholesterol',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.0,
            system: 'http://unitsofmeasure.org',
            code: '{ratio}',
          },
          high: {
            value: 5.0,
            system: 'http://unitsofmeasure.org',
            code: '{ratio}',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Triglycerides',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',

    code: {
      system: 'http://loinc.org',
      code: '2571-8',
      display: 'Triglycerides',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          high: {
            value: 150.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'VLDL Cholesterol Calc',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',

    code: {
      system: 'http://loinc.org',
      code: '13458-5',
      display: 'VLDL Cholesterol Calc',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          high: {
            value: 40.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },
]

export const urinepanel: Panel1[] = [
  {
    name: 'pH',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '',

    code: {
      system: 'http://loinc.org',
      code: '5803-2',
      display: 'pH',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 5.0,
            system: 'http://unitsofmeasure.org',
          },
          high: {
            value: 7.5,
            system: 'http://unitsofmeasure.org',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },
]

export const oralUnits: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'Number',
    display: 'Nos.',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'ml',
    display: 'ml',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'Package',
    display: 'Package',
  },

  {
    system: 'http://unitsofmeasure.org',
    code: 'tbs_us',
    display: 'Tablespoon',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'tsp_us',
    display: 'Teaspoon',
  },
]

export const timeOfDayForDiet: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'Number',
    display: 'Nos.',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'ml',
    display: 'ml',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'Package',
    display: 'Package',
  },

  {
    system: 'http://unitsofmeasure.org',
    code: 'tbs_us',
    display: 'Tablespoon',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'tsp_us',
    display: 'Teaspoon',
  },
]

export const nasalUnits: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'ml',
    display: 'ml',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const routesOfAdministration: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '26643006',
    display: 'Oral',
  },
  {
    system: 'http://snomed.info/sct',
    code: '47625008',
    display: 'Intravenous',
  },
  {
    system: 'http://snomed.info/sct',
    code: '46713006',
    display: 'Nasal',
  },

  {
    system: 'http://snomed.info/sct',
    code: '54485002',
    display: 'Ophthalmic',
  },
  {
    system: 'http://snomed.info/sct',
    code: '34206005',
    display: 'Subcutaneous',
  },
  {
    system: 'http://snomed.info/sct',
    code: '6064005',
    display: 'Topical',
  },
  {
    system: 'http://snomed.info/sct',
    code: '54471007',
    display: 'Buccal route',
  },
  {
    system: 'http://snomed.info/sct',
    code: '78421000',
    display: 'Intramuscular',
  },
  {
    system: 'http://snomed.info/sct',
    code: '10547007',
    display: 'Otic',
  },
  {
    system: 'http://snomed.info/sct',
    code: '37161004',
    display: 'Per Rectum',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const durationData: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'd',
    display: 'Days',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'wk',
    display: 'Weeks',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'mo',
    display: 'Months',
  },

  {
    system: 'http://unitsofmeasure.org',
    code: 'a',
    display: 'Years',
  },
]

export const durationDataWithOne: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'd',
    display: 'Day',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'wk',
    display: 'Week',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'mo',
    display: 'Month',
  },

  {
    system: 'http://unitsofmeasure.org',
    code: 'a',
    display: 'Year',
  },
]

export const durationDataForChiefComplaints: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'd',
    display: 'Days',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'wk',
    display: 'Weeks',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'mo',
    display: 'Months',
  },

  {
    system: 'http://unitsofmeasure.org',
    code: 'a',
    display: 'Years',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'c',
    display: 'Custom',
  },
]

export const durationDataForChiefComplaintsWithOne: R4.ICoding[] = [
  {
    system: 'http://unitsofmeasure.org',
    code: 'd',
    display: 'Day',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'wk',
    display: 'Week',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'mo',
    display: 'Month',
  },

  {
    system: 'http://unitsofmeasure.org',
    code: 'a',
    display: 'Year',
  },
  {
    system: 'http://unitsofmeasure.org',
    code: 'c',
    display: 'Custom',
  },
]

export const nationalityValueSet: R4.ICoding[] = [
  {
    code: 'AD',
    display: 'Andorra',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AE',
    display: 'United Arab Emirates',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AF',
    display: 'Afghanistan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AG',
    display: 'Antigua and Barbuda',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AI',
    display: 'Anguilla',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AL',
    display: 'Albania',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AM',
    display: 'Armenia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AO',
    display: 'Angola',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AQ',
    display: 'Antarctica',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AR',
    display: 'Argentina',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AS',
    display: 'American Samoa',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AT',
    display: 'Austria',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AU',
    display: 'Australia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AW',
    display: 'Aruba',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AX',
    display: 'Eland Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'AZ',
    display: 'Azerbaijan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BA',
    display: 'Bosnia and Herzegovina',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BB',
    display: 'Barbados',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BD',
    display: 'Bangladesh',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BE',
    display: 'Belgium',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BF',
    display: 'Burkina Faso',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BG',
    display: 'Bulgaria',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BH',
    display: 'Bahrain',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BI',
    display: 'Burundi',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BJ',
    display: 'Benin',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BL',
    display: 'Saint Barthilemy',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BM',
    display: 'Bermuda',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BN',
    display: 'Brunei Darussalam',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BO',
    display: 'Bolivia, Plurinational State of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BQ',
    display: 'Bonaire, Sint Eustatius and Saba',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BR',
    display: 'Brazil',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BS',
    display: 'Bahamas',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BT',
    display: 'Bhutan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BV',
    display: 'Bouvet Island',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BW',
    display: 'Botswana',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BY',
    display: 'Belarus',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'BZ',
    display: 'Belize',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CA',
    display: 'Canada',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CC',
    display: 'Cocos (Keeling) Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CD',
    display: 'Congo, the Democratic Republic of the',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CF',
    display: 'Central African Republic',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CG',
    display: 'Congo',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CH',
    display: 'Switzerland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CI',
    display: "Ctte d'Ivoire",
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CK',
    display: 'Cook Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CL',
    display: 'Chile',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CM',
    display: 'Cameroon',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CN',
    display: 'China',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CO',
    display: 'Colombia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CR',
    display: 'Costa Rica',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CU',
    display: 'Cuba',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CV',
    display: 'Cabo Verde',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CW',
    display: 'Curagao',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CX',
    display: 'Christmas Island',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CY',
    display: 'Cyprus',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'CZ',
    display: 'Czechia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'DE',
    display: 'Germany',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'DJ',
    display: 'Djibouti',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'DK',
    display: 'Denmark',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'DM',
    display: 'Dominica',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'DO',
    display: 'Dominican Republic',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'DZ',
    display: 'Algeria',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'EC',
    display: 'Ecuador',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'EE',
    display: 'Estonia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'EG',
    display: 'Egypt',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'EH',
    display: 'Western Sahara',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ER',
    display: 'Eritrea',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ES',
    display: 'Spain',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ET',
    display: 'Ethiopia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'FI',
    display: 'Finland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'FJ',
    display: 'Fiji',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'FK',
    display: 'Falkland Islands (Malvinas)',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'FM',
    display: 'Micronesia, Federated States of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'FO',
    display: 'Faroe Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'FR',
    display: 'France',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GA',
    display: 'Gabon',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GB',
    display: 'United Kingdom of Great Britain and Northern Ireland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GD',
    display: 'Grenada',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GE',
    display: 'Georgia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GF',
    display: 'French Guiana',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GG',
    display: 'Guernsey',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GH',
    display: 'Ghana',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GI',
    display: 'Gibraltar',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GL',
    display: 'Greenland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GM',
    display: 'Gambia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GN',
    display: 'Guinea',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GP',
    display: 'Guadeloupe',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GQ',
    display: 'Equatorial Guinea',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GR',
    display: 'Greece',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GS',
    display: 'South Georgia and the South Sandwich Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GT',
    display: 'Guatemala',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GU',
    display: 'Guam',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GW',
    display: 'Guinea-Bissau',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'GY',
    display: 'Guyana',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'HK',
    display: 'Hong Kong',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'HM',
    display: 'Heard Island and McDonald Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'HN',
    display: 'Honduras',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'HR',
    display: 'Croatia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'HT',
    display: 'Haiti',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'HU',
    display: 'Hungary',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ID',
    display: 'Indonesia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IE',
    display: 'Ireland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IL',
    display: 'Israel',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IM',
    display: 'Isle of Man',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IN',
    display: 'India',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IO',
    display: 'British Indian Ocean Territory',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IQ',
    display: 'Iraq',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IR',
    display: 'Iran, Islamic Republic of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IS',
    display: 'Iceland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'IT',
    display: 'Italy',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'JE',
    display: 'Jersey',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'JM',
    display: 'Jamaica',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'JO',
    display: 'Jordan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'JP',
    display: 'Japan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KE',
    display: 'Kenya',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KG',
    display: 'Kyrgyzstan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KH',
    display: 'Cambodia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KI',
    display: 'Kiribati',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KM',
    display: 'Comoros',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KN',
    display: 'Saint Kitts and Nevis',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KP',
    display: "Korea, Democratic People's Republic of",
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KR',
    display: 'Korea, Republic of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KW',
    display: 'Kuwait',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KY',
    display: 'Cayman Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'KZ',
    display: 'Kazakhstan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LA',
    display: "Lao People's Democratic Republic",
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LB',
    display: 'Lebanon',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LC',
    display: 'Saint Lucia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LI',
    display: 'Liechtenstein',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LK',
    display: 'Sri Lanka',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LR',
    display: 'Liberia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LS',
    display: 'Lesotho',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LT',
    display: 'Lithuania',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LU',
    display: 'Luxembourg',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LV',
    display: 'Latvia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'LY',
    display: 'Libya',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MA',
    display: 'Morocco',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MC',
    display: 'Monaco',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MD',
    display: 'Moldova, Republic of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ME',
    display: 'Montenegro',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MF',
    display: 'Saint Martin (French part)',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MG',
    display: 'Madagascar',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MH',
    display: 'Marshall Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MK',
    display: 'Macedonia, the former Yugoslav Republic of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ML',
    display: 'Mali',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MM',
    display: 'Myanmar',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MN',
    display: 'Mongolia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MO',
    display: 'Macao',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MP',
    display: 'Northern Mariana Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MQ',
    display: 'Martinique',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MR',
    display: 'Mauritania',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MS',
    display: 'Montserrat',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MT',
    display: 'Malta',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MU',
    display: 'Mauritius',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MV',
    display: 'Maldives',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MW',
    display: 'Malawi',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MX',
    display: 'Mexico',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MY',
    display: 'Malaysia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'MZ',
    display: 'Mozambique',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NA',
    display: 'Namibia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NC',
    display: 'New Caledonia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NE',
    display: 'Niger',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NF',
    display: 'Norfolk Island',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NG',
    display: 'Nigeria',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NI',
    display: 'Nicaragua',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NL',
    display: 'Netherlands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NO',
    display: 'Norway',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NP',
    display: 'Nepal',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NR',
    display: 'Nauru',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NU',
    display: 'Niue',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'NZ',
    display: 'New Zealand',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'OM',
    display: 'Oman',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PA',
    display: 'Panama',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PE',
    display: 'Peru',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PF',
    display: 'French Polynesia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PG',
    display: 'Papua New Guinea',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PH',
    display: 'Philippines',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PK',
    display: 'Pakistan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PL',
    display: 'Poland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PM',
    display: 'Saint Pierre and Miquelon',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PN',
    display: 'Pitcairn',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PR',
    display: 'Puerto Rico',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PS',
    display: 'Palestine, State of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PT',
    display: 'Portugal',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PW',
    display: 'Palau',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'PY',
    display: 'Paraguay',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'QA',
    display: 'Qatar',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'RE',
    display: 'Riunion',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'RO',
    display: 'Romania',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'RS',
    display: 'Serbia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'RU',
    display: 'Russian Federation',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'RW',
    display: 'Rwanda',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SA',
    display: 'Saudi Arabia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SB',
    display: 'Solomon Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SC',
    display: 'Seychelles',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SD',
    display: 'Sudan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SE',
    display: 'Sweden',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SG',
    display: 'Singapore',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SH',
    display: 'Saint Helena, Ascension and Tristan da Cunha',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SI',
    display: 'Slovenia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SJ',
    display: 'Svalbard and Jan Mayen',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SK',
    display: 'Slovakia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SL',
    display: 'Sierra Leone',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SM',
    display: 'San Marino',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SN',
    display: 'Senegal',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SO',
    display: 'Somalia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SR',
    display: 'Suriname',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SS',
    display: 'South Sudan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ST',
    display: 'Sao Tome and Principe',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SV',
    display: 'El Salvador',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SX',
    display: 'Sint Maarten (Dutch part)',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SY',
    display: 'Syrian Arab Republic',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'SZ',
    display: 'Swaziland',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TC',
    display: 'Turks and Caicos Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TD',
    display: 'Chad',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TF',
    display: 'French Southern Territories',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TG',
    display: 'Togo',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TH',
    display: 'Thailand',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TJ',
    display: 'Tajikistan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TK',
    display: 'Tokelau',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TL',
    display: 'Timor-Leste',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TM',
    display: 'Turkmenistan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TN',
    display: 'Tunisia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TO',
    display: 'Tonga',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TR',
    display: 'Turkey',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TT',
    display: 'Trinidad and Tobago',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TV',
    display: 'Tuvalu',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TW',
    display: 'Taiwan, Province of China',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'TZ',
    display: 'Tanzania, United Republic of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'UA',
    display: 'Ukraine',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'UG',
    display: 'Uganda',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'UM',
    display: 'United States Minor Outlying Islands',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'US',
    display: 'United States of America',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'UY',
    display: 'Uruguay',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'UZ',
    display: 'Uzbekistan',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VA',
    display: 'Holy See',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VC',
    display: 'Saint Vincent and the Grenadines',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VE',
    display: 'Venezuela, Bolivarian Republic of',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VG',
    display: 'Virgin Islands, British',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VI',
    display: 'Virgin Islands,',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VN',
    display: 'Viet Nam',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'VU',
    display: 'Vanuatu',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'WF',
    display: 'Wallis and Futuna',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'WS',
    display: 'Samoa',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'YE',
    display: 'Yemen',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'YT',
    display: 'Mayotte',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ZA',
    display: 'South Africa',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ZM',
    display: 'Zambia',
    system: 'urn:iso:std:iso:3166',
  },
  {
    code: 'ZW',
    display: 'Zimbabwe',
    system: 'urn:iso:std:iso:3166',
  },
]
export const preDefinedCountry: R4.ICoding = {
  system: 'urn:iso:std:iso:3166',
  code: 'IN',
  display: 'India',
}

export const visaTypes: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-visa-type',
    code: 'touristVisa',
    display: 'Tourist Visa',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-visa-type',
    code: 'businessVisa',
    display: 'Business Visa',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-visa-type',
    code: 'workVisa',
    display: 'Work Visa',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-visa-type',
    code: 'trasitVisa',
    display: 'Transit Visa',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-visa-type',
    code: 'studentVisa',
    display: 'Student Visa',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const purposeOfVisitForForegin: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-purpose-of-visit',
    code: 'treatment',
    display: 'Medical/Health',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-purpose-of-visit',
    code: 'official',
    display: 'Official',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-purpose-of-visit',
    code: 'education',
    display: 'Education',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-purpose-of-visit',
    code: 'others',
    display: 'Others',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const outComes: OutComes[] = [
  {
    groupId: 'weight',
    type: 'Weight',
  },
  {
    groupId: 'heart_rate',
    type: 'Pulse',
  },
  //   {
  //     groupId: 'bmi',
  //     type: 'BMI',
  //   },
  {
    groupId: 'respiratory_rate',
    type: 'Respiratory Rate',
  },

  {
    groupId: 'systolic_bp',
    type: 'Systolic Blood Pressure',
  },
  {
    groupId: 'diastolic_bp',
    type: 'Diastolic Blood Pressure',
  },
  {
    groupId: 'temperature',
    type: 'Temperature',
  },
].sort((a, b) => (a.type! > b.type! ? 1 : b.type! > a.type! ? -1 : 0))

export const checkBoxConditions: Conditions[] = [
  {
    checked: false,
    data: {
      display: 'Asthma',
      code: '195967001',
      system: 'http://snomed.info/sct',
    },
  },

  {
    checked: false,
    data: {
      display: 'Chronic heart disease',
      code: '128238001',
      system: 'http://snomed.info/sct',
    },
  },
  {
    checked: false,
    data: {
      display: 'Chronic kidney disease',
      code: '709044004',
      system: 'http://snomed.info/sct',
    },
  },
  {
    checked: false,
    data: {
      display: 'Chronic Obstructive Pulmonary Disease',
      code: '13645005',
      system: 'http://snomed.info/sct',
    },
  },
]

export const checkConditions2: Conditions[] = [
  {
    checked: false,
    data: {
      display: 'High Blood Pressure (BP)',
      code: '38341003',
      system: 'http://snomed.info/sct',
    },
  },

  {
    checked: false,
    data: {
      display: 'Tuberculosis',
      code: '56717001',
      system: 'http://snomed.info/sct',
    },
  },
  {
    checked: false,
    data: {
      display: 'Type 2 diabetes mellitus',
      code: '44054006',
      system: 'http://snomed.info/sct',
    },
  },
]

export const reminingConditons: R4.ICoding[] = [
  {
    code: '1187220002',
    display: 'Cocaine Use Disorders',
    system: 'http://snomed.info/sct',
  },
  {
    code: '80612004',
    display: 'Leishmaniasis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '109989006',
    display: 'Multiple Myeloma',
    system: 'http://snomed.info/sct',
  },
  {
    code: '235494005',
    display: 'Chronic Pancreatitis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '363349007',
    display: 'Stomach Cancer',
    system: 'http://snomed.info/sct',
  },

  {
    code: '2435008',
    display: 'Ascariasis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '781382000',
    display: 'Colon and/or Rectum Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '95454007',
    display: 'Brain stem hemorrhage',
    system: 'http://snomed.info/sct',
  },
  {
    code: '24700007',
    display: 'Multiple sclerosis ',
    system: 'http://snomed.info/sct',
  },
  {
    code: '55525008',
    display: 'Paralytic Ileus & Intestinal Obstruction',
    system: 'http://snomed.info/sct',
  },
  {
    code: '76272004',
    display: 'Syphilis',
    system: 'http://snomed.info/sct',
  },

  {
    code: '399326009',
    display: 'Bladder Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '13213009',
    display: 'Congenital Heart Disease',
    system: 'http://snomed.info/sct',
  },

  {
    code: '62479008',
    display: 'HIV/AIDS',
    system: 'http://snomed.info/sct',
  },
  {
    code: '81004002',
    display: 'Leprosy',
    system: 'http://snomed.info/sct',
  },
  {
    code: '49049000',
    display: 'Parkinson Disease',
    system: 'http://snomed.info/sct',
  },
  {
    code: '94442001',
    display: 'Brain & Nervous System Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '59051007',
    display: 'Cysticercosis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '118599009',
    display: 'Hodgkin Lymphoma',
    system: 'http://snomed.info/sct',
  },
  {
    code: '92812005',
    display: 'Chronic Leukaemia',
    system: 'http://snomed.info/sct',
  },
  {
    code: '47841006',
    display: 'Chronic nasopharyngitis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '422504002',
    display: 'Ischaemic Stroke',
    system: 'http://snomed.info/sct',
  },
  {
    code: '254837009',
    display: 'Breast Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '41040004',
    display: 'Down Syndrome',
    system: 'http://snomed.info/sct',
  },
  {
    code: '34000006',
    display: "Crohn's Disease",
    system: 'http://snomed.info/sct',
  },
  {
    code: '93870000',
    display: 'Liver Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '253098009',
    display: 'Neural tube defect',
    system: 'http://snomed.info/sct',
  },
  {
    code: '254900004',
    display: 'Prostate Cancer',
    system: 'http://snomed.info/sct',
  },

  {
    code: '72366004',
    display: 'Eating Disorder',
    system: 'http://snomed.info/sct',
  },
  {
    code: '238121000',
    display: 'Iodine Deficiency',
    system: 'http://snomed.info/sct',
  },
  {
    code: '370143000',
    display: 'Major depressive disorder ',
    system: 'http://snomed.info/sct',
  },
  {
    code: '238107002',
    display: 'Protein-Energy Malnutrition',
    system: 'http://snomed.info/sct',
  },
  {
    code: '363478007',
    display: 'Thyroid Cancer',
    system: 'http://snomed.info/sct',
  },

  {
    code: '84757009',
    display: 'Epilepsy',
    system: 'http://snomed.info/sct',
  },
  {
    code: '87522002',
    display: 'Iron deficiency anemia',
    system: 'http://snomed.info/sct',
  },
  {
    code: '61462000',
    display: 'Malaria',
    system: 'http://snomed.info/sct',
  },
  {
    code: '372130007',
    display: 'Skin Cancer',
    system: 'http://snomed.info/sct',
  },

  {
    code: '363353009',
    display: 'Gallbladder Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '363402007',
    display: 'Oesophagus Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '69896004',
    display: 'Rheumatoid arthritis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '95566004',
    display: 'Urinary Stone',
    system: 'http://snomed.info/sct',
  },
  {
    code: '363429002',
    display: 'Larynx Cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '109378008',
    display: 'Mesothelioma',
    system: 'http://snomed.info/sct',
  },
  {
    code: '363418001',
    display: 'Pancreatic cancer',
    system: 'http://snomed.info/sct',
  },
  {
    code: '128236002',
    display: 'Chronic disease of skin',
    system: 'http://snomed.info/sct',
  },
  {
    code: '4556007',
    display: 'Gastritis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '21664006',
    display: 'Chronic Meningitis',
    system: 'http://snomed.info/sct',
  },
  {
    code: '5602001',
    display: 'Opioid abuse',
    system: 'http://snomed.info/sct',
  },
  {
    code: '16402000',
    display: 'Sickle Cell Disease',
    system: 'http://snomed.info/sct',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const range = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
    label: '1',
  },
  {
    value: 20,
    label: '2',
  },
  {
    value: 30,
    label: '3',
  },
  {
    value: 40,
    label: '4',
  },
  {
    value: 50,
    label: '5',
  },
  {
    value: 60,
    label: '6',
  },
  {
    value: 70,
    label: '7',
  },
  {
    value: 80,
    label: '8',
  },
  {
    value: 90,
    label: '9',
  },
  {
    value: 100,
    label: '10',
  },
]

export const errorMsgForOPD: string = 'Patient is still in OPD'

export const rft: Panel1[] = [
  {
    name: 'Glucose',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '2345-7',
      display: 'Glucose',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 70.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 99.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'BUN',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '3094-0',
      display: 'BUN',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 6.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 20.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Creatinine',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: '%',
    code: {
      system: 'http://loinc.org',
      code: '2160-0',
      display: 'Creatinine',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.57,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 1.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'eGFR',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mL/min/{1.73_m2}',
    code: {
      system: 'http://loinc.org',
      code: '98979-8',
      display: 'eGFR',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 59.0,
            system: 'http://unitsofmeasure.org',
            code: 'mL/min/{1.73_m2}',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'BUN/Creatinine Ratio',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'fraction',
    code: {
      system: 'http://loinc.org',
      code: '3097-3',
      display: 'BUN/Creatinine Ratio',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 9.0,
            system: 'http://unitsofmeasure.org',
            code: 'fraction',
          },
          high: {
            value: 23.0,
            system: 'http://unitsofmeasure.org',
            code: 'fraction',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Sodium',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mmol/L',
    code: {
      system: 'http://loinc.org',
      code: '2951-2',
      display: 'Sodium',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 136.0,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
          high: {
            value: 145.0,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Potassium',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mmol/L',
    code: {
      system: 'http://loinc.org',
      code: '2823-3',
      display: 'Potassium',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 3.5,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
          high: {
            value: 5.1,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Chloride',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mmol/L',
    code: {
      system: 'http://loinc.org',
      code: '2075-0',
      display: 'Chloride',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 96.0,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
          high: {
            value: 106.0,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Carbon Dioxide, Total',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mmol/L',
    code: {
      system: 'http://loinc.org',
      code: '2028-9',
      display: 'Carbon Dioxide, Total',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 20.0,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
          high: {
            value: 29.0,
            system: 'http://unitsofmeasure.org',
            code: 'mmol/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Calcium',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '17861-6',
      display: 'Calcium',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 8.7,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 10.2,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Phosphorus',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '2777-1',
      display: 'Phosphorus',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 3.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 4.3,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Albumin',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'g/dL',
    code: {
      system: 'http://loinc.org',
      code: '1751-7',
      display: 'Albumin',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 3.8,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
          high: {
            value: 4.8,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },
].sort((a, b) => (a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0))

export const lft: Panel1[] = [
  {
    name: 'Protein, Total',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'g/dL',
    code: {
      system: 'http://loinc.org',
      code: '2885-2',
      display: 'Protein, Total',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 6.0,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
          high: {
            value: 8.3,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Albumin',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'g/dL',
    code: {
      system: 'http://loinc.org',
      code: '1751-7',
      display: 'Albumin',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 3.8,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
          high: {
            value: 4.8,
            system: 'http://unitsofmeasure.org',
            code: 'g/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Bilirubin, Total',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'mg/dL',
    code: {
      system: 'http://loinc.org',
      code: '1968-7',
      display: 'Bilirubin, Total',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0.0,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
          high: {
            value: 0.4,
            system: 'http://unitsofmeasure.org',
            code: 'mg/dL',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'Alkaline Phosphatase',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'IU/L',
    code: {
      system: 'http://loinc.org',
      code: '6768-6',
      display: 'Alkaline Phosphatase',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 40,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
          high: {
            value: 120,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._male,
      },
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 30,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
          high: {
            value: 100,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._female,
      },
    ],
  },

  {
    name: 'AST (SGOT)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'IU/L',
    code: {
      system: 'http://loinc.org',
      code: '1920-8',
      display: 'AST (SGOT)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 10.0,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
          high: {
            value: 40.0,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
      },
    ],
  },

  {
    name: 'ALT (SGPT)',
    quantity: 0,
    measureInString: '',
    type: 'number',
    unit: 'IU/L',
    code: {
      system: 'http://loinc.org',
      code: '1742-6',
      display: 'ALT (SGPT)',
    },
    qualifiedInterval: [
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
          high: {
            value: 40,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._male,
      },
      {
        category:
          R4.ObservationDefinition_QualifiedIntervalCategoryKind._reference,
        range: {
          low: {
            value: 0,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
          high: {
            value: 30,
            system: 'http://unitsofmeasure.org',
            code: 'IU/L',
          },
        },
        context: {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/referencerange-meaning',
              code: 'normal',
              display: 'Normal Range',
            },
          ],
        },
        gender: R4.ObservationDefinition_QualifiedIntervalGenderKind._female,
      },
    ],
  },
].sort((a, b) => (a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0))

export const wpAdminOptions = [
  { value: 'users', displayText: ' Users' },
  { value: 'patient', displayText: 'Patients' },
  { value: 'appointments', displayText: 'Appointments' },
  {
    value: 'revenue',
    displayText: 'Revenue',
  },
]

export const analyticsOptions = [
  { value: 'plan', displayText: 'Careplan Treatment-Outcome Pathways' },
  { value: 'blg', displayText: 'BP Treatment-Outcome Analysis' },
  { value: 'sc', displayText: 'BP Treatment-Outcome Pathways' },
]

export const adminGraphOptions = [
  { value: 'web', displayText: 'Business Analytics' },
  { value: 'app', displayText: 'App Analytics' },
]

export const clinicAnalyticsOptions = [
  { value: 'plan', displayText: 'Careplan Treatment-Outcome Pathways' },
]

export const wpAdminOptionsMain = [
  { value: 'units', displayText: ' Units' },
  { value: 'users', displayText: ' Users' },
  { value: 'patient', displayText: 'Patients' },
  { value: 'appointments', displayText: 'Appointments' },
  { value: 'orders', displayText: 'Lab Orders' },
  {
    value: 'revenue',
    displayText: 'Revenue',
  },
]

export const wpAdminOptionsLAb = [
  { value: 'users', displayText: ' Users' },
  { value: 'patient', displayText: 'Patients' },
  { value: 'orders', displayText: 'Lab Orders' },
  {
    value: 'revenue',
    displayText: 'Revenue',
  },
]

export const cohortsDataDisplay = [
  {
    group_idx: 0,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 0,
    strataName: '',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 105.0,
        stdDev: 21.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 120.0,
        p90: 120.0,
        max: 120.0,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 93.33333333333333,
        stdDev: 14.0,
        min: 80.0,
        p10: 80.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 120.0,
        max: 120.0,
      },
    ],
  },
  {
    group_idx: 1,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 1,
    strataName: 'Diet count = 0',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 105.0,
        stdDev: 21.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 120.0,
        p90: 120.0,
        max: 120.0,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 93.33333333333333,
        stdDev: 14.0,
        min: 80.0,
        p10: 80.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 120.0,
        max: 120.0,
      },
    ],
  },
  {
    group_idx: 2,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 2,
    strataName: 'Therapies count >= 2',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
    ],
  },
  {
    group_idx: 3,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 3,
    strataName: 'Therapies count = 0',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 3,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 105.0,
        stdDev: 21.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 120.0,
        p90: 120.0,
        max: 120.0,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        avg: 95.0,
        stdDev: 17.0,
        min: 80.0,
        p10: 80.0,
        p25: 80.0,
        median: 90.0,
        p75: 90.0,
        p90: 120.0,
        max: 120.0,
      },
    ],
  },
  {
    group_idx: 4,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 6,
    strataName: 'Therapies count >= 1',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 90.0,
        stdDev: 0.0,
        min: 90.0,
        p10: 90.0,
        p25: 90.0,
        median: 90.0,
        p75: 90.0,
        p90: 90.0,
        max: 90.0,
      },
    ],
  },
  {
    group_idx: 5,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 0,
    strataName: '',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        avg: 185.0,
        stdDev: 36.0,
        min: 125.0,
        p10: 125.0,
        p25: 180.0,
        median: 200.0,
        p75: 200.0,
        p90: 220.0,
        max: 220.0,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 195.0,
        stdDev: 7.0,
        min: 190.0,
        p10: 190.0,
        p25: 190.0,
        median: 190.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 200.0,
        stdDev: 0.0,
        min: 200.0,
        p10: 200.0,
        p25: 200.0,
        median: 200.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 165.83333333333334,
        stdDev: 43.0,
        min: 100.0,
        p10: 100.0,
        p25: 125.0,
        median: 180.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
    ],
  },
  {
    group_idx: 6,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 1,
    strataName: 'Diet count = 0',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        avg: 185.0,
        stdDev: 36.0,
        min: 125.0,
        p10: 125.0,
        p25: 180.0,
        median: 200.0,
        p75: 200.0,
        p90: 220.0,
        max: 220.0,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 195.0,
        stdDev: 7.0,
        min: 190.0,
        p10: 190.0,
        p25: 190.0,
        median: 190.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 200.0,
        stdDev: 0.0,
        min: 200.0,
        p10: 200.0,
        p25: 200.0,
        median: 200.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 165.83333333333334,
        stdDev: 43.0,
        min: 100.0,
        p10: 100.0,
        p25: 125.0,
        median: 180.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
    ],
  },
  {
    group_idx: 7,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 2,
    strataName: 'Therapies count >= 2',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 190.0,
        stdDev: 14.0,
        min: 180.0,
        p10: 180.0,
        p25: 180.0,
        median: 180.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 190.0,
        stdDev: 14.0,
        min: 180.0,
        p10: 180.0,
        p25: 180.0,
        median: 180.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
    ],
  },
  {
    group_idx: 8,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 3,
    strataName: 'Therapies count = 0',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 3,
        avg: 181.66666666666666,
        stdDev: 50.0,
        min: 125.0,
        p10: 125.0,
        p25: 125.0,
        median: 200.0,
        p75: 220.0,
        p90: 220.0,
        max: 220.0,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 195.0,
        stdDev: 7.0,
        min: 190.0,
        p10: 190.0,
        p25: 190.0,
        median: 190.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 200.0,
        stdDev: 0.0,
        min: 200.0,
        p10: 200.0,
        p25: 200.0,
        median: 200.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        avg: 153.75,
        stdDev: 49.0,
        min: 100.0,
        p10: 100.0,
        p25: 100.0,
        median: 125.0,
        p75: 190.0,
        p90: 200.0,
        max: 200.0,
      },
    ],
  },
  {
    group_idx: 9,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 6,
    strataName: 'Therapies count >= 1',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 190.0,
        stdDev: 14.0,
        min: 180.0,
        p10: 180.0,
        p25: 180.0,
        median: 180.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 190.0,
        stdDev: 14.0,
        min: 180.0,
        p10: 180.0,
        p25: 180.0,
        median: 180.0,
        p75: 200.0,
        p90: 200.0,
        max: 200.0,
      },
    ],
  },
]

export const exampleData = [
  {
    idx: 0,
    summaryData: [
      {
        analysisName: 'Diastolic BP',
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        index: 0,
        avg: 90,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        avg: 90,
        index: 1,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 2,
        avg: 90,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 3,
        avg: 90,
        index: 3,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 4,
        avg: 90,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        index: 5,
        avg: 185,
        stdDev: 36,
        min: 125,
        p10: 125,
        p25: 180,
        median: 200,
        p75: 200,
        p90: 220,
        max: 220,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 5,
        avg: 185,
        index: 7,
        stdDev: 36,
        min: 125,
        p10: 125,
        p25: 180,
        median: 200,
        p75: 200,
        p90: 220,
        max: 220,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 190,
        index: 8,
        stdDev: 14,
        min: 180,
        p10: 180,
        p25: 180,
        median: 180,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 3,
        index: 9,
        avg: 181.66666666666666,
        stdDev: 50,
        min: 125,
        p10: 125,
        p25: 125,
        median: 200,
        p75: 220,
        p90: 220,
        max: 220,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 10,
        avg: 190,
        stdDev: 14,
        min: 180,
        p10: 180,
        p25: 180,
        median: 180,
        p75: 200,
        p90: 200,
        max: 200,
      },
    ],
  },
  {
    idx: 1,
    summaryData: [
      {
        analysisName: 'Diastolic BP',
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 11,
        avg: 105,
        stdDev: 21,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 120,
        p90: 120,
        max: 120,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 105,
        index: 12,
        stdDev: 21,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 120,
        p90: 120,
        max: 120,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 13,
        avg: 105,
        stdDev: 21,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 120,
        p90: 120,
        max: 120,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 14,
        avg: 195,
        stdDev: 7,
        min: 190,
        p10: 190,
        p25: 190,
        median: 190,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 195,
        index: 15,
        stdDev: 7,
        min: 190,
        p10: 190,
        p25: 190,
        median: 190,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 195,
        index: 16,
        stdDev: 7,
        min: 190,
        p10: 190,
        p25: 190,
        median: 190,
        p75: 200,
        p90: 200,
        max: 200,
      },
    ],
  },
  {
    idx: 3,
    summaryData: [
      {
        analysisName: 'Diastolic BP',
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 90,
        index: 17,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        index: 18,
        avg: 90,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        index: 19,
        avg: 90,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 200,
        index: 20,
        stdDev: 0,
        min: 200,
        p10: 200,
        p25: 200,
        median: 200,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        index: 21,
        avg: 200,
        stdDev: 0,
        min: 200,
        p10: 200,
        p25: 200,
        median: 200,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        index: 22,
        avg: 200,
        stdDev: 0,
        min: 200,
        p10: 200,
        p25: 200,
        median: 200,
        p75: 200,
        p90: 200,
        max: 200,
      },
    ],
  },
  {
    idx: 4,
    summaryData: [
      {
        analysisName: 'Diastolic BP',
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 93.33333333333333,
        stdDev: 14,
        index: 23,
        min: 80,
        p10: 80,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 120,
        max: 120,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        index: 24,
        avg: 93.33333333333333,
        stdDev: 14,
        min: 80,
        p10: 80,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 120,
        max: 120,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 25,
        avg: 90,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        index: 26,
        avg: 95,
        stdDev: 17,
        min: 80,
        p10: 80,
        p25: 80,
        median: 90,
        p75: 90,
        p90: 120,
        max: 120,
      },
      {
        analysisName: 'Diastolic BP',
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 90,
        index: 27,
        stdDev: 0,
        min: 90,
        p10: 90,
        p25: 90,
        median: 90,
        p75: 90,
        p90: 90,
        max: 90,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        index: 28,
        count: 6,
        avg: 165.83333333333334,
        stdDev: 43,
        min: 100,
        p10: 100,
        p25: 125,
        median: 180,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        index: 29,
        avg: 165.83333333333334,
        stdDev: 43,
        min: 100,
        p10: 100,
        p25: 125,
        median: 180,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 190,
        index: 30,
        stdDev: 14,
        min: 180,
        p10: 180,
        p25: 180,
        median: 180,
        p75: 200,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        index: 31,
        avg: 153.75,
        stdDev: 49,
        min: 100,
        p10: 100,
        p25: 100,
        median: 125,
        p75: 190,
        p90: 200,
        max: 200,
      },
      {
        analysisName: 'Systolic BP',
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        index: 32,
        avg: 190,
        stdDev: 14,
        min: 180,
        p10: 180,
        p25: 180,
        median: 180,
        p75: 200,
        p90: 200,
        max: 200,
      },
    ],
  },
]

export const testData = [
  {
    name: 'uv',
    val: [
      { value: 4000, label: 'Page A' },
      { value: 3000, label: 'Page B' },
      { value: 2000, label: 'Page C' },
    ],
  },
  {
    name: 'pv',
    val: [
      { value: 2400, label: 'Page A' },
      { value: 1398, label: 'Page B' },
      { value: 9800, label: 'Page C' },
    ],
  },
]

export const result = [
  {
    group_idx: 0,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 0,
    strataName: '',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 124,
        avg: 82.73924731182798,
        stdDev: 11,
        min: 60,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 88,
        p90: 90,
        max: 170,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 160,
        avg: 81.58749999999995,
        stdDev: 8,
        min: 50,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 87,
        p90: 90,
        max: 108,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 140,
        avg: 81.3142857142857,
        stdDev: 8,
        min: 60,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 86,
        p90: 90,
        max: 110,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 165,
        avg: 79.89090909090903,
        stdDev: 8,
        min: 60,
        p10: 70,
        p25: 76,
        median: 80,
        p75: 84,
        p90: 90,
        max: 110,
      },
    ],
  },
  {
    group_idx: 1,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 28,
    strataName: 'Hydro (Therapy)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        avg: 82.5,
        stdDev: 4,
        min: 80,
        p10: 80,
        p25: 80,
        median: 80,
        p75: 82,
        p90: 88,
        max: 88,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 10,
        avg: 80.1,
        stdDev: 8,
        min: 70,
        p10: 70,
        p25: 70,
        median: 81,
        p75: 86,
        p90: 90,
        max: 90,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 9,
        avg: 80.11111111111111,
        stdDev: 6,
        min: 70,
        p10: 70,
        p25: 80,
        median: 80,
        p75: 80,
        p90: 90,
        max: 90,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 8,
        avg: 82.875,
        stdDev: 6,
        min: 76,
        p10: 76,
        p25: 80,
        median: 80,
        p75: 84,
        p90: 95,
        max: 95,
      },
    ],
  },
  {
    group_idx: 2,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 31,
    strataName: 'Massages (Therapy)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 77,
        avg: 82.43722943722943,
        stdDev: 12,
        min: 60,
        p10: 72.66666666666667,
        p25: 78,
        median: 80,
        p75: 86,
        p90: 90,
        max: 170,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 111,
        avg: 81.33633633633636,
        stdDev: 8,
        min: 50,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 88,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 87,
        avg: 80.26436781609198,
        stdDev: 7,
        min: 67,
        p10: 70,
        p25: 76,
        median: 80,
        p75: 86,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 109,
        avg: 79.26605504587154,
        stdDev: 8,
        min: 60,
        p10: 70,
        p25: 76,
        median: 80,
        p75: 82,
        p90: 90,
        max: 109,
      },
    ],
  },
  {
    group_idx: 3,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 32,
    strataName: 'Mud (Therapy)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 53,
        avg: 83.40880503144652,
        stdDev: 14,
        min: 60,
        p10: 74,
        p25: 80,
        median: 80,
        p75: 88,
        p90: 90,
        max: 170,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 86,
        avg: 81.05038759689921,
        stdDev: 8,
        min: 50,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 86,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 76,
        avg: 79.74999999999999,
        stdDev: 7,
        min: 60,
        p10: 70,
        p25: 76,
        median: 80,
        p75: 84,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 71,
        avg: 79.66197183098592,
        stdDev: 7,
        min: 60,
        p10: 70,
        p25: 76,
        median: 80,
        p75: 84,
        p90: 90,
        max: 95,
      },
    ],
  },
  {
    group_idx: 4,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 48,
    strataName: 'Diuretics equivalent (Diet)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        avg: 86.5,
        stdDev: 12,
        min: 78,
        p10: 78,
        p25: 78,
        median: 82,
        p75: 82,
        p90: 104,
        max: 104,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 7,
        avg: 78,
        stdDev: 7,
        min: 70,
        p10: 70,
        p25: 70,
        median: 80,
        p75: 80,
        p90: 90,
        max: 90,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 7,
        avg: 83.14285714285714,
        stdDev: 10,
        min: 70,
        p10: 70,
        p25: 74,
        median: 84,
        p75: 90,
        p90: 96,
        max: 96,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 8,
        avg: 78.75,
        stdDev: 8,
        min: 70,
        p10: 70,
        p25: 70,
        median: 78,
        p75: 80,
        p90: 90,
        max: 90,
      },
    ],
  },
  {
    group_idx: 5,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 50,
    strataName: 'Vasodilators equivalent (Diet)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 60,
        stdDev: 0,
        min: 60,
        p10: 60,
        p25: 60,
        median: 60,
        p75: 60,
        p90: 60,
        max: 60,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 70,
        stdDev: 0,
        min: 70,
        p10: 70,
        p25: 70,
        median: 70,
        p75: 70,
        p90: 70,
        max: 70,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 60,
        stdDev: 0,
        min: 60,
        p10: 60,
        p25: 60,
        median: 60,
        p75: 60,
        p90: 60,
        max: 60,
      },
    ],
  },
  {
    group_idx: 6,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 51,
    strataName: 'CCB equivalent (Diet)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 8,
        avg: 80.12499999999999,
        stdDev: 11,
        min: 60,
        p10: 60,
        p25: 70,
        median: 80,
        p75: 89,
        p90: 92,
        max: 92,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 84.5,
        stdDev: 6,
        min: 80,
        p10: 80,
        p25: 80,
        median: 80,
        p75: 89,
        p90: 89,
        max: 89,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 70,
        stdDev: 14,
        min: 60,
        p10: 60,
        p25: 60,
        median: 60,
        p75: 80,
        p90: 80,
        max: 80,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 79.77777777777777,
        stdDev: 7,
        min: 70,
        p10: 70,
        p25: 76.66666666666667,
        median: 78,
        p75: 82,
        p90: 92,
        max: 92,
      },
    ],
  },
  {
    group_idx: 7,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 52,
    strataName: 'Ragi and other millets (Diet)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 39,
        avg: 80.52991452991452,
        stdDev: 7,
        min: 68,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 84,
        p90: 90,
        max: 104,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 70,
        avg: 81.71428571428571,
        stdDev: 7,
        min: 68,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 88,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 64,
        avg: 81.875,
        stdDev: 7,
        min: 70,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 86,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 67,
        avg: 80.04477611940295,
        stdDev: 7,
        min: 68,
        p10: 70,
        p25: 76,
        median: 80,
        p75: 86,
        p90: 90,
        max: 92,
      },
    ],
  },
  {
    group_idx: 8,
    analysisId: 106,
    analysisName: 'Diastolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 53,
    strataName: 'Fruits (Diet)',
    data: [
      {
        covariateId: 2,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 22,
        avg: 82.30303030303031,
        stdDev: 12,
        min: 60,
        p10: 70,
        p25: 80,
        median: 82,
        p75: 88,
        p90: 90,
        max: 110,
      },
      {
        covariateId: 3,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 50,
        avg: 81.20666666666664,
        stdDev: 7,
        min: 70,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 86,
        p90: 90,
        max: 100,
      },
      {
        covariateId: 5,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 49,
        avg: 81.63265306122449,
        stdDev: 8,
        min: 60,
        p10: 70,
        p25: 78,
        median: 80,
        p75: 85,
        p90: 90,
        max: 110,
      },
      {
        covariateId: 4,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 41,
        avg: 80.11382113821138,
        stdDev: 7,
        min: 60,
        p10: 70,
        p25: 76.66666666666667,
        median: 80,
        p75: 80,
        p90: 90,
        max: 92,
      },
    ],
  },
  {
    group_idx: 9,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 0,
    strataName: '',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 124,
        avg: 132.3870967741936,
        stdDev: 14,
        min: 100,
        p10: 122,
        p25: 124,
        median: 130,
        p75: 138,
        p90: 148,
        max: 190,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 160,
        avg: 132.25625,
        stdDev: 12,
        min: 100,
        p10: 121.33333333333333,
        p25: 124,
        median: 130,
        p75: 138,
        p90: 150,
        max: 170,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 140,
        avg: 129.00714285714278,
        stdDev: 12,
        min: 100,
        p10: 118,
        p25: 122,
        median: 126,
        p75: 136,
        p90: 140,
        max: 190,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 165,
        avg: 127.34343434343434,
        stdDev: 13,
        min: 100,
        p10: 110,
        p25: 120,
        median: 126,
        p75: 132,
        p90: 142,
        max: 190,
      },
    ],
  },
  {
    group_idx: 10,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 28,
    strataName: 'Hydro (Therapy)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        avg: 138.5,
        stdDev: 13,
        min: 130,
        p10: 130,
        p25: 130,
        median: 130,
        p75: 136,
        p90: 158,
        max: 158,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 10,
        avg: 135.8,
        stdDev: 18,
        min: 118,
        p10: 118,
        p25: 122,
        median: 128,
        p75: 140,
        p90: 166,
        max: 166,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 9,
        avg: 124.66666666666667,
        stdDev: 11,
        min: 108,
        p10: 108,
        p25: 122,
        median: 124,
        p75: 132,
        p90: 138,
        max: 138,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 8,
        avg: 121.5,
        stdDev: 10,
        min: 110,
        p10: 110,
        p25: 110,
        median: 118,
        p75: 124,
        p90: 138,
        max: 138,
      },
    ],
  },
  {
    group_idx: 11,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 31,
    strataName: 'Massages (Therapy)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 77,
        avg: 131.6363636363637,
        stdDev: 13,
        min: 100,
        p10: 120,
        p25: 122,
        median: 128,
        p75: 138,
        p90: 150,
        max: 170,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 111,
        avg: 132.32132132132125,
        stdDev: 12,
        min: 100,
        p10: 122,
        p25: 124,
        median: 130,
        p75: 140,
        p90: 144,
        max: 170,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 87,
        avg: 127.14942528735632,
        stdDev: 11,
        min: 100,
        p10: 114,
        p25: 120,
        median: 126,
        p75: 134,
        p90: 140,
        max: 160,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 109,
        avg: 125.81345565749233,
        stdDev: 12,
        min: 100,
        p10: 110,
        p25: 120,
        median: 124,
        p75: 130,
        p90: 140,
        max: 166,
      },
    ],
  },
  {
    group_idx: 12,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 32,
    strataName: 'Mud (Therapy)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 53,
        avg: 130.7169811320755,
        stdDev: 11,
        min: 100,
        p10: 118,
        p25: 124,
        median: 130,
        p75: 136,
        p90: 148,
        max: 158,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 86,
        avg: 131.2403100775194,
        stdDev: 11,
        min: 100,
        p10: 120,
        p25: 124,
        median: 130,
        p75: 140,
        p90: 142,
        max: 166,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 76,
        avg: 126.63157894736841,
        stdDev: 11,
        min: 100,
        p10: 112,
        p25: 120,
        median: 126,
        p75: 134,
        p90: 140,
        max: 154,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 71,
        avg: 124.67605633802815,
        stdDev: 11,
        min: 100,
        p10: 110,
        p25: 118,
        median: 124,
        p75: 130,
        p90: 140,
        max: 150,
      },
    ],
  },
  {
    group_idx: 13,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 48,
    strataName: 'Diuretics equivalent (Diet)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 4,
        avg: 131.5,
        stdDev: 11,
        min: 122,
        p10: 122,
        p25: 122,
        median: 126,
        p75: 130,
        p90: 148,
        max: 148,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 7,
        avg: 132.14285714285714,
        stdDev: 12,
        min: 118,
        p10: 118,
        p25: 122,
        median: 135,
        p75: 140,
        p90: 150,
        max: 150,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 7,
        avg: 126.28571428571429,
        stdDev: 14,
        min: 112,
        p10: 112,
        p25: 116,
        median: 124,
        p75: 130,
        p90: 154,
        max: 154,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 8,
        avg: 129.25,
        stdDev: 15,
        min: 110,
        p10: 110,
        p25: 112,
        median: 122,
        p75: 140,
        p90: 150,
        max: 150,
      },
    ],
  },
  {
    group_idx: 14,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 50,
    strataName: 'Vasodilators equivalent (Diet)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 160,
        stdDev: 0,
        min: 160,
        p10: 160,
        p25: 160,
        median: 160,
        p75: 160,
        p90: 160,
        max: 160,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 160,
        stdDev: 0,
        min: 160,
        p10: 160,
        p25: 160,
        median: 160,
        p75: 160,
        p90: 160,
        max: 160,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 1,
        avg: 160,
        stdDev: 0,
        min: 160,
        p10: 160,
        p25: 160,
        median: 160,
        p75: 160,
        p90: 160,
        max: 160,
      },
    ],
  },
  {
    group_idx: 15,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 51,
    strataName: 'CCB equivalent (Diet)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 8,
        avg: 129.99999999999997,
        stdDev: 7,
        min: 124,
        p10: 124,
        p25: 124,
        median: 126,
        p75: 132,
        p90: 144,
        max: 144,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 139,
        stdDev: 7,
        min: 134,
        p10: 134,
        p25: 134,
        median: 134,
        p75: 144,
        p90: 144,
        max: 144,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 2,
        avg: 130,
        stdDev: 6,
        min: 126,
        p10: 126,
        p25: 126,
        median: 126,
        p75: 134,
        p90: 134,
        max: 134,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 6,
        avg: 127.33333333333334,
        stdDev: 3,
        min: 124,
        p10: 124,
        p25: 124,
        median: 126,
        p75: 130,
        p90: 132,
        max: 132,
      },
    ],
  },
  {
    group_idx: 16,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 52,
    strataName: 'Ragi and other millets (Diet)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 39,
        avg: 128.7179487179487,
        stdDev: 11,
        min: 114,
        p10: 118,
        p25: 122,
        median: 126,
        p75: 132,
        p90: 140,
        max: 168,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 70,
        avg: 133.05714285714282,
        stdDev: 11,
        min: 114,
        p10: 122,
        p25: 124,
        median: 130,
        p75: 140,
        p90: 144,
        max: 170,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 64,
        avg: 129.375,
        stdDev: 11,
        min: 110,
        p10: 118,
        p25: 122,
        median: 128,
        p75: 138,
        p90: 142,
        max: 160,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 67,
        avg: 126.98507462686568,
        stdDev: 12,
        min: 100,
        p10: 116,
        p25: 122,
        median: 124,
        p75: 132,
        p90: 146,
        max: 166,
      },
    ],
  },
  {
    group_idx: 17,
    analysisId: 107,
    analysisName: 'Systolic BP',
    cohortId: 2,
    cohortName: 'IPD patients with elevated BP at visit start',
    strataId: 53,
    strataName: 'Fruits (Diet)',
    data: [
      {
        covariateId: 8,
        covariateName: 'start',
        time_norm_idx: 0,
        time_unit: 'd',
        time_scale: 5,
        count: 22,
        avg: 137.09090909090912,
        stdDev: 19,
        min: 114,
        p10: 118,
        p25: 126,
        median: 130,
        p75: 144,
        p90: 160,
        max: 190,
      },
      {
        covariateId: 9,
        covariateName: 'start_1d',
        time_norm_idx: 1,
        time_unit: 'd',
        time_scale: 5,
        count: 50,
        avg: 134.05333333333334,
        stdDev: 12,
        min: 114,
        p10: 122,
        p25: 124,
        median: 134,
        p75: 140,
        p90: 144,
        max: 170,
      },
      {
        covariateId: 12,
        covariateName: 'end_1d',
        time_norm_idx: 3,
        time_unit: 'd',
        time_scale: 5,
        count: 49,
        avg: 130.6122448979592,
        stdDev: 14,
        min: 110,
        p10: 114,
        p25: 122,
        median: 126,
        p75: 138,
        p90: 148,
        max: 190,
      },
      {
        covariateId: 11,
        covariateName: 'end',
        time_norm_idx: 4,
        time_unit: 'd',
        time_scale: 5,
        count: 41,
        avg: 126.92682926829268,
        stdDev: 13,
        min: 100,
        p10: 110,
        p25: 118,
        median: 124,
        p75: 134,
        p90: 146,
        max: 160,
      },
    ],
  },
]

export const pilotDataResponse = {
  id: 7,
  analysis_ids: [106, 107],
  name: 'Outcome Analysis for IPD visits - v3',
  generation: {
    id: 258,
    status: 'COMPLETED',
    sourceKey: 'wellofhir_pilot1',
    startTime: '2023-06-29T12:02:19.536000',
    endTime: '2023-06-29T12:49:01.809000',
  },
  result: [
    {
      group_idx: 0,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 0,
      strataName: '',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 124,
          avg: 82.73924731182798,
          stdDev: 11,
          min: 60,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 88,
          p90: 90,
          max: 170,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 160,
          avg: 81.58749999999995,
          stdDev: 8,
          min: 50,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 87,
          p90: 90,
          max: 108,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 140,
          avg: 81.3142857142857,
          stdDev: 8,
          min: 60,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 86,
          p90: 90,
          max: 110,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 165,
          avg: 79.89090909090903,
          stdDev: 8,
          min: 60,
          p10: 70,
          p25: 76,
          median: 80,
          p75: 84,
          p90: 90,
          max: 110,
        },
      ],
    },
    {
      group_idx: 1,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 28,
      strataName: 'Hydro (Therapy)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 4,
          avg: 82.5,
          stdDev: 4,
          min: 80,
          p10: 80,
          p25: 80,
          median: 80,
          p75: 82,
          p90: 88,
          max: 88,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 10,
          avg: 80.1,
          stdDev: 8,
          min: 70,
          p10: 70,
          p25: 70,
          median: 81,
          p75: 86,
          p90: 90,
          max: 90,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 9,
          avg: 80.11111111111111,
          stdDev: 6,
          min: 70,
          p10: 70,
          p25: 80,
          median: 80,
          p75: 80,
          p90: 90,
          max: 90,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 8,
          avg: 82.875,
          stdDev: 6,
          min: 76,
          p10: 76,
          p25: 80,
          median: 80,
          p75: 84,
          p90: 95,
          max: 95,
        },
      ],
    },
    {
      group_idx: 2,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 31,
      strataName: 'Massages (Therapy)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 77,
          avg: 82.43722943722943,
          stdDev: 12,
          min: 60,
          p10: 72.66666666666667,
          p25: 78,
          median: 80,
          p75: 86,
          p90: 90,
          max: 170,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 111,
          avg: 81.33633633633636,
          stdDev: 8,
          min: 50,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 88,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 87,
          avg: 80.26436781609198,
          stdDev: 7,
          min: 67,
          p10: 70,
          p25: 76,
          median: 80,
          p75: 86,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 109,
          avg: 79.26605504587154,
          stdDev: 8,
          min: 60,
          p10: 70,
          p25: 76,
          median: 80,
          p75: 82,
          p90: 90,
          max: 109,
        },
      ],
    },
    {
      group_idx: 3,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 32,
      strataName: 'Mud (Therapy)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 53,
          avg: 83.40880503144652,
          stdDev: 14,
          min: 60,
          p10: 74,
          p25: 80,
          median: 80,
          p75: 88,
          p90: 90,
          max: 170,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 86,
          avg: 81.05038759689921,
          stdDev: 8,
          min: 50,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 86,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 76,
          avg: 79.74999999999999,
          stdDev: 7,
          min: 60,
          p10: 70,
          p25: 76,
          median: 80,
          p75: 84,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 71,
          avg: 79.66197183098592,
          stdDev: 7,
          min: 60,
          p10: 70,
          p25: 76,
          median: 80,
          p75: 84,
          p90: 90,
          max: 95,
        },
      ],
    },
    {
      group_idx: 4,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 48,
      strataName: 'Diuretics equivalent (Diet)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 4,
          avg: 86.5,
          stdDev: 12,
          min: 78,
          p10: 78,
          p25: 78,
          median: 82,
          p75: 82,
          p90: 104,
          max: 104,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 7,
          avg: 78,
          stdDev: 7,
          min: 70,
          p10: 70,
          p25: 70,
          median: 80,
          p75: 80,
          p90: 90,
          max: 90,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 7,
          avg: 83.14285714285714,
          stdDev: 10,
          min: 70,
          p10: 70,
          p25: 74,
          median: 84,
          p75: 90,
          p90: 96,
          max: 96,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 8,
          avg: 78.75,
          stdDev: 8,
          min: 70,
          p10: 70,
          p25: 70,
          median: 78,
          p75: 80,
          p90: 90,
          max: 90,
        },
      ],
    },
    {
      group_idx: 5,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 50,
      strataName: 'Vasodilators equivalent (Diet)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 1,
          avg: 60,
          stdDev: 0,
          min: 60,
          p10: 60,
          p25: 60,
          median: 60,
          p75: 60,
          p90: 60,
          max: 60,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 7,
          avg: 83.14285714285714,
          stdDev: 10,
          min: 70,
          p10: 70,
          p25: 74,
          median: 84,
          p75: 90,
          p90: 96,
          max: 96,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 1,
          avg: 70,
          stdDev: 0,
          min: 70,
          p10: 70,
          p25: 70,
          median: 70,
          p75: 70,
          p90: 70,
          max: 70,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 1,
          avg: 60,
          stdDev: 0,
          min: 60,
          p10: 60,
          p25: 60,
          median: 60,
          p75: 60,
          p90: 60,
          max: 60,
        },
      ],
    },
    {
      group_idx: 6,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 51,
      strataName: 'CCB equivalent (Diet)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 8,
          avg: 80.12499999999999,
          stdDev: 11,
          min: 60,
          p10: 60,
          p25: 70,
          median: 80,
          p75: 89,
          p90: 92,
          max: 92,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 2,
          avg: 84.5,
          stdDev: 6,
          min: 80,
          p10: 80,
          p25: 80,
          median: 80,
          p75: 89,
          p90: 89,
          max: 89,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 2,
          avg: 70,
          stdDev: 14,
          min: 60,
          p10: 60,
          p25: 60,
          median: 60,
          p75: 80,
          p90: 80,
          max: 80,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 6,
          avg: 79.77777777777777,
          stdDev: 7,
          min: 70,
          p10: 70,
          p25: 76.66666666666667,
          median: 78,
          p75: 82,
          p90: 92,
          max: 92,
        },
      ],
    },
    {
      group_idx: 7,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 52,
      strataName: 'Ragi and other millets (Diet)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 39,
          avg: 80.52991452991452,
          stdDev: 7,
          min: 68,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 84,
          p90: 90,
          max: 104,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 70,
          avg: 81.71428571428571,
          stdDev: 7,
          min: 68,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 88,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 64,
          avg: 81.875,
          stdDev: 7,
          min: 70,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 86,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 67,
          avg: 80.04477611940295,
          stdDev: 7,
          min: 68,
          p10: 70,
          p25: 76,
          median: 80,
          p75: 86,
          p90: 90,
          max: 92,
        },
      ],
    },
    {
      group_idx: 8,
      analysisId: 106,
      analysisName: 'Diastolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 53,
      strataName: 'Fruits (Diet)',
      data: [
        {
          covariateId: 2,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 22,
          avg: 82.30303030303031,
          stdDev: 12,
          min: 60,
          p10: 70,
          p25: 80,
          median: 82,
          p75: 88,
          p90: 90,
          max: 110,
        },
        {
          covariateId: 3,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 50,
          avg: 81.20666666666664,
          stdDev: 7,
          min: 70,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 86,
          p90: 90,
          max: 100,
        },
        {
          covariateId: 5,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 49,
          avg: 81.63265306122449,
          stdDev: 8,
          min: 60,
          p10: 70,
          p25: 78,
          median: 80,
          p75: 85,
          p90: 90,
          max: 110,
        },
        {
          covariateId: 4,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 41,
          avg: 80.11382113821138,
          stdDev: 7,
          min: 60,
          p10: 70,
          p25: 76.66666666666667,
          median: 80,
          p75: 80,
          p90: 90,
          max: 92,
        },
      ],
    },
    {
      group_idx: 9,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 0,
      strataName: '',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 124,
          avg: 132.3870967741936,
          stdDev: 14,
          min: 100,
          p10: 122,
          p25: 124,
          median: 130,
          p75: 138,
          p90: 148,
          max: 190,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 160,
          avg: 132.25625,
          stdDev: 12,
          min: 100,
          p10: 121.33333333333333,
          p25: 124,
          median: 130,
          p75: 138,
          p90: 150,
          max: 170,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 140,
          avg: 129.00714285714278,
          stdDev: 12,
          min: 100,
          p10: 118,
          p25: 122,
          median: 126,
          p75: 136,
          p90: 140,
          max: 190,
        },
      ],
    },
    {
      group_idx: 10,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 28,
      strataName: 'Hydro (Therapy)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 4,
          avg: 138.5,
          stdDev: 13,
          min: 130,
          p10: 130,
          p25: 130,
          median: 130,
          p75: 136,
          p90: 158,
          max: 158,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 10,
          avg: 135.8,
          stdDev: 18,
          min: 118,
          p10: 118,
          p25: 122,
          median: 128,
          p75: 140,
          p90: 166,
          max: 166,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 9,
          avg: 124.66666666666667,
          stdDev: 11,
          min: 108,
          p10: 108,
          p25: 122,
          median: 124,
          p75: 132,
          p90: 138,
          max: 138,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 8,
          avg: 121.5,
          stdDev: 10,
          min: 110,
          p10: 110,
          p25: 110,
          median: 118,
          p75: 124,
          p90: 138,
          max: 138,
        },
      ],
    },
    {
      group_idx: 11,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 31,
      strataName: 'Massages (Therapy)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 77,
          avg: 131.6363636363637,
          stdDev: 13,
          min: 100,
          p10: 120,
          p25: 122,
          median: 128,
          p75: 138,
          p90: 150,
          max: 170,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 111,
          avg: 132.32132132132125,
          stdDev: 12,
          min: 100,
          p10: 122,
          p25: 124,
          median: 130,
          p75: 140,
          p90: 144,
          max: 170,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 87,
          avg: 127.14942528735632,
          stdDev: 11,
          min: 100,
          p10: 114,
          p25: 120,
          median: 126,
          p75: 134,
          p90: 140,
          max: 160,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 109,
          avg: 125.81345565749233,
          stdDev: 12,
          min: 100,
          p10: 110,
          p25: 120,
          median: 124,
          p75: 130,
          p90: 140,
          max: 166,
        },
      ],
    },
    {
      group_idx: 12,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 32,
      strataName: 'Mud (Therapy)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 53,
          avg: 130.7169811320755,
          stdDev: 11,
          min: 100,
          p10: 118,
          p25: 124,
          median: 130,
          p75: 136,
          p90: 148,
          max: 158,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 86,
          avg: 131.2403100775194,
          stdDev: 11,
          min: 100,
          p10: 120,
          p25: 124,
          median: 130,
          p75: 140,
          p90: 142,
          max: 166,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 76,
          avg: 126.63157894736841,
          stdDev: 11,
          min: 100,
          p10: 112,
          p25: 120,
          median: 126,
          p75: 134,
          p90: 140,
          max: 154,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 71,
          avg: 124.67605633802815,
          stdDev: 11,
          min: 100,
          p10: 110,
          p25: 118,
          median: 124,
          p75: 130,
          p90: 140,
          max: 150,
        },
      ],
    },
    {
      group_idx: 13,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 48,
      strataName: 'Diuretics equivalent (Diet)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 4,
          avg: 131.5,
          stdDev: 11,
          min: 122,
          p10: 122,
          p25: 122,
          median: 126,
          p75: 130,
          p90: 148,
          max: 148,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 7,
          avg: 132.14285714285714,
          stdDev: 12,
          min: 118,
          p10: 118,
          p25: 122,
          median: 135,
          p75: 140,
          p90: 150,
          max: 150,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 7,
          avg: 126.28571428571429,
          stdDev: 14,
          min: 112,
          p10: 112,
          p25: 116,
          median: 124,
          p75: 130,
          p90: 154,
          max: 154,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 8,
          avg: 129.25,
          stdDev: 15,
          min: 110,
          p10: 110,
          p25: 112,
          median: 122,
          p75: 140,
          p90: 150,
          max: 150,
        },
      ],
    },
    {
      group_idx: 14,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 50,
      strataName: 'Vasodilators equivalent (Diet)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 1,
          avg: 160,
          stdDev: 0,
          min: 160,
          p10: 160,
          p25: 160,
          median: 160,
          p75: 160,
          p90: 160,
          max: 160,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 1,
          avg: 160,
          stdDev: 0,
          min: 160,
          p10: 160,
          p25: 160,
          median: 160,
          p75: 160,
          p90: 160,
          max: 160,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 1,
          avg: 160,
          stdDev: 0,
          min: 160,
          p10: 160,
          p25: 160,
          median: 160,
          p75: 160,
          p90: 160,
          max: 160,
        },
      ],
    },
    {
      group_idx: 15,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 51,
      strataName: 'CCB equivalent (Diet)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 8,
          avg: 129.99999999999997,
          stdDev: 7,
          min: 124,
          p10: 124,
          p25: 124,
          median: 126,
          p75: 132,
          p90: 144,
          max: 144,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 2,
          avg: 139,
          stdDev: 7,
          min: 134,
          p10: 134,
          p25: 134,
          median: 134,
          p75: 144,
          p90: 144,
          max: 144,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 2,
          avg: 130,
          stdDev: 6,
          min: 126,
          p10: 126,
          p25: 126,
          median: 126,
          p75: 134,
          p90: 134,
          max: 134,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 6,
          avg: 127.33333333333334,
          stdDev: 3,
          min: 124,
          p10: 124,
          p25: 124,
          median: 126,
          p75: 130,
          p90: 132,
          max: 132,
        },
      ],
    },
    {
      group_idx: 16,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 52,
      strataName: 'Ragi and other millets (Diet)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 39,
          avg: 128.7179487179487,
          stdDev: 11,
          min: 114,
          p10: 118,
          p25: 122,
          median: 126,
          p75: 132,
          p90: 140,
          max: 168,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 70,
          avg: 133.05714285714282,
          stdDev: 11,
          min: 114,
          p10: 122,
          p25: 124,
          median: 130,
          p75: 140,
          p90: 144,
          max: 170,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 64,
          avg: 129.375,
          stdDev: 11,
          min: 110,
          p10: 118,
          p25: 122,
          median: 128,
          p75: 138,
          p90: 142,
          max: 160,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 67,
          avg: 126.98507462686568,
          stdDev: 12,
          min: 100,
          p10: 116,
          p25: 122,
          median: 124,
          p75: 132,
          p90: 146,
          max: 166,
        },
      ],
    },
    {
      group_idx: 17,
      analysisId: 107,
      analysisName: 'Systolic BP',
      cohortId: 2,
      cohortName: 'IPD patients with elevated BP at visit start',
      strataId: 53,
      strataName: 'Fruits (Diet)',
      data: [
        {
          covariateId: 8,
          covariateName: 'start',
          time_norm_idx: 0,
          time_unit: 'd',
          time_scale: 5,
          count: 22,
          avg: 137.09090909090912,
          stdDev: 19,
          min: 114,
          p10: 118,
          p25: 126,
          median: 130,
          p75: 144,
          p90: 160,
          max: 190,
        },
        {
          covariateId: 9,
          covariateName: 'start_1d',
          time_norm_idx: 1,
          time_unit: 'd',
          time_scale: 5,
          count: 50,
          avg: 134.05333333333334,
          stdDev: 12,
          min: 114,
          p10: 122,
          p25: 124,
          median: 134,
          p75: 140,
          p90: 144,
          max: 170,
        },
        {
          covariateId: 12,
          covariateName: 'end_1d',
          time_norm_idx: 3,
          time_unit: 'd',
          time_scale: 5,
          count: 49,
          avg: 130.6122448979592,
          stdDev: 14,
          min: 110,
          p10: 114,
          p25: 122,
          median: 126,
          p75: 138,
          p90: 148,
          max: 190,
        },
        {
          covariateId: 11,
          covariateName: 'end',
          time_norm_idx: 4,
          time_unit: 'd',
          time_scale: 5,
          count: 41,
          avg: 126.92682926829268,
          stdDev: 13,
          min: 100,
          p10: 110,
          p25: 118,
          median: 124,
          p75: 134,
          p90: 146,
          max: 160,
        },
      ],
    },
  ],
}

export const datas = {
  generation: {
    id: 490,
    status: 'COMPLETED',
    sourceKey: 'wellofhir_pilot1',
    hashCode: 1744675095,
    startTime: 1690485942224,
    endTime: 1690486163493,
    exitMessage: '',
  },
  design: {
    createdDate: 1690367377353,
    modifiedDate: 1690485938398,
    tags: [],
    name: 'IPD Pathway BP x Therapies',
    targetCohorts: [
      {
        id: 6,
        name: 'IPD patients',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 4,
                    Op: 'gte',
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    eventCohorts: [
      {
        id: 33,
        name: 'Massage (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 2,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 34,
        name: 'Mud (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 3,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 3,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 35,
        name: 'Hydro (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 4,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 4,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 36,
        name: 'Elevated BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 37,
        name: 'Normal BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 38,
        name: 'Normal BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 7,
        name: 'Elevated BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 32,
        name: 'Steam (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    combinationWindow: 0,
    minCellCount: 3,
    maxDepth: 5,
    allowRepeats: false,
  },
  results: {
    start_labels: ['IPD patients'],
    pre_labels: ['Normal BP at Admission', 'Elevated BP at Admission'],
    post_labels: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
    treatment_labels: [
      'Massage (Therapy)',
      'Mud (Therapy)',
      'Hydro (Therapy)',
      'Steam (Therapy)',
    ],
    path_codes: {
      start_1: {
        cohorts: ['IPD patients'],
        index: 0,
      },
      pre_1: {
        cohorts: ['Normal BP at Admission'],
        index: 1,
      },
      pre_2: {
        cohorts: ['Elevated BP at Admission'],
        index: 2,
      },
      pre_3: {
        cohorts: ['Elevated BP at Admission', 'Normal BP at Admission'],
        index: 3,
      },
      post_1: {
        cohorts: ['Elevated BP at Discharge'],
        index: 4,
      },
      post_2: {
        cohorts: ['Normal BP at Discharge'],
        index: 5,
      },
      post_3: {
        cohorts: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
        index: 6,
      },
      treatment_1: {
        cohorts: ['Massage (Therapy)', 'Mud (Therapy)', 'Steam (Therapy)'],
        index: 7,
      },
      treatment_2: {
        cohorts: ['Massage (Therapy)', 'Steam (Therapy)'],
        index: 8,
      },
      treatment_3: {
        cohorts: ['Steam (Therapy)'],
        index: 9,
      },
      treatment_4: {
        cohorts: [
          'Hydro (Therapy)',
          'Massage (Therapy)',
          'Mud (Therapy)',
          'Steam (Therapy)',
        ],
        index: 10,
      },
      treatment_5: {
        cohorts: ['Mud (Therapy)', 'Steam (Therapy)'],
        index: 11,
      },
      treatment_6: {
        cohorts: ['Mud (Therapy)'],
        index: 12,
      },
      treatment_7: {
        cohorts: ['Massage (Therapy)'],
        index: 13,
      },
      treatment_8: {
        cohorts: ['Hydro (Therapy)'],
        index: 14,
      },
      treatment_9: {
        cohorts: ['Massage (Therapy)', 'Mud (Therapy)'],
        index: 15,
      },
      treatment_10: {
        cohorts: ['Hydro (Therapy)', 'Massage (Therapy)'],
        index: 16,
      },
      treatment_11: {
        cohorts: ['Hydro (Therapy)', 'Massage (Therapy)', 'Mud (Therapy)'],
        index: 17,
      },
    },
    pathway_groups: [
      {
        targetCohortId: 6,
        targetCohortCount: 135,
        totalPathwaysCount: 134,
        path_pairs: [
          {
            source: 'start_1',
            target: 'pre_2',
            personCount: 79,
          },
          {
            source: 'pre_2',
            target: 'treatment_9',
            personCount: 3,
          },
          {
            source: 'treatment_9',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'post_3',
            personCount: 3,
          },
          {
            source: 'treatment_9',
            target: 'post_1',
            personCount: 3,
          },
          {
            source: 'start_1',
            target: 'pre_3',
            personCount: 15,
          },
          {
            source: 'pre_3',
            target: 'treatment_2',
            personCount: 3,
          },
          {
            source: 'treatment_2',
            target: 'post_1',
            personCount: 12,
          },
          {
            source: 'start_1',
            target: 'treatment_3',
            personCount: 1,
          },
          {
            source: 'treatment_3',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'start_1',
            target: 'pre_1',
            personCount: 37,
          },
          {
            source: 'pre_1',
            target: 'treatment_1',
            personCount: 22,
          },
          {
            source: 'treatment_1',
            target: 'post_3',
            personCount: 7,
          },
          {
            source: 'start_1',
            target: 'treatment_1',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'treatment_1',
            personCount: 7,
          },
          {
            source: 'pre_1',
            target: 'treatment_4',
            personCount: 2,
          },
          {
            source: 'treatment_4',
            target: 'post_2',
            personCount: 4,
          },
          {
            source: 'pre_1',
            target: 'treatment_2',
            personCount: 6,
          },
          {
            source: 'treatment_2',
            target: 'post_2',
            personCount: 7,
          },
          {
            source: 'pre_2',
            target: 'treatment_1',
            personCount: 43,
          },
          {
            source: 'treatment_1',
            target: 'post_1',
            personCount: 35,
          },
          {
            source: 'treatment_2',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'treatment_4',
            personCount: 8,
          },
          {
            source: 'treatment_4',
            target: 'post_1',
            personCount: 5,
          },
          {
            source: 'pre_2',
            target: 'treatment_2',
            personCount: 11,
          },
          {
            source: 'treatment_1',
            target: 'post_2',
            personCount: 30,
          },
          {
            source: 'pre_2',
            target: 'treatment_11',
            personCount: 1,
          },
          {
            source: 'treatment_11',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_1',
            target: 'post_2',
            personCount: 2,
          },
          {
            source: 'pre_2',
            target: 'treatment_7',
            personCount: 1,
          },
          {
            source: 'treatment_7',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'start_1',
            target: 'treatment_7',
            personCount: 1,
          },
          {
            source: 'treatment_7',
            target: 'post_1',
            personCount: 1,
          },
          {
            source: 'pre_1',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'treatment_10',
            personCount: 1,
          },
          {
            source: 'treatment_10',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'post_1',
            personCount: 2,
          },
          {
            source: 'pre_1',
            target: 'treatment_9',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'treatment_6',
            personCount: 1,
          },
          {
            source: 'treatment_6',
            target: 'post_1',
            personCount: 1,
          },
          {
            source: 'pre_1',
            target: 'treatment_5',
            personCount: 1,
          },
          {
            source: 'treatment_5',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'treatment_9',
            personCount: 1,
          },
          {
            source: 'treatment_9',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'treatment_4',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'post_1',
            personCount: 7,
          },
          {
            source: 'pre_1',
            target: 'treatment_3',
            personCount: 1,
          },
          {
            source: 'treatment_3',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'pre_1',
            target: 'treatment_10',
            personCount: 1,
          },
          {
            source: 'treatment_10',
            target: 'post_2',
            personCount: 1,
          },
        ],
      },
    ],
  },
}

export const dietpathWays = {
  generation: {
    id: 501,
    status: 'COMPLETED',
    sourceKey: 'wellofhir_pilot1',
    hashCode: -108397387,
    startTime: 1690487319293,
    endTime: 1690487521571,
    exitMessage: '',
  },
  design: {
    createdDate: 1690447473925,
    modifiedDate: 1690485989962,
    tags: [],
    name: 'IPD Pathway - BP x Diets',
    targetCohorts: [
      {
        id: 6,
        name: 'IPD patients',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 4,
                    Op: 'gte',
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    eventCohorts: [
      {
        id: 17,
        name: 'Diuretics equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 7,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 8,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 7,
              name: 'Diuretics equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000071,
                      CONCEPT_NAME: 'Tender Coconut',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000006,
                      CONCEPT_NAME: 'Barley Water',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4037467,
                      CONCEPT_NAME: 'Plantain',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227259009',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 8,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 18,
        name: 'ACE Inhibitors equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 7,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 6,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 3,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 4,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 5,
              name: 'Diuretics equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000071,
                      CONCEPT_NAME: 'Tender Coconut',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000006,
                      CONCEPT_NAME: 'Barley Water',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4037467,
                      CONCEPT_NAME: 'Plantain',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227259009',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 6,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 7,
              name: 'ACE Inhibitors equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 1620125,
                      CONCEPT_NAME: 'Hibiscus tea',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'LP419911-5',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'LOINC Component',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 19,
        name: 'Vasodilators equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: -1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 7,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 3,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 4,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 5,
              name: 'Diuretics equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000071,
                      CONCEPT_NAME: 'Tender Coconut',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000006,
                      CONCEPT_NAME: 'Barley Water',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4037467,
                      CONCEPT_NAME: 'Plantain',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227259009',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 6,
              name: 'Vasodilators equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000062,
                      CONCEPT_NAME: 'Beetroot Juice',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '159',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000072,
                      CONCEPT_NAME: 'Carrot and Beetroot Soup',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '169',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 7,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 8,
              name: 'Vasodilators equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000062,
                      CONCEPT_NAME: 'Beetroot Juice',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '159',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000072,
                      CONCEPT_NAME: 'Carrot and Beetroot Soup',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '169',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 36,
        name: 'Elevated BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 20,
        name: 'CCB equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 9,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 8,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 8,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 9,
              name: 'CCB equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000056,
                      CONCEPT_NAME: 'Boiled Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '153',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000044,
                      CONCEPT_NAME: 'Green Leafy Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '141',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000040,
                      CONCEPT_NAME: 'Steamed Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '137',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 37,
        name: 'Normal BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 21,
        name: 'Ragi and other millets (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 8,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 9,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 8,
              name: 'Ragi and other millets (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000066,
                      CONCEPT_NAME: 'Boil Ragi',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '163',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000045,
                      CONCEPT_NAME: 'Millets Khichadi',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '142',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000038,
                      CONCEPT_NAME: 'Millets Roti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '135',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000065,
                      CONCEPT_NAME: 'Ragi Dosa',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '162',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000074,
                      CONCEPT_NAME: 'Ragi Kheer',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '171',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 9,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 38,
        name: 'Normal BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 22,
        name: 'Fruits (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 9,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 10,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 9,
              name: 'Fruits (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 4026207,
                      CONCEPT_NAME: 'Fresh fruit salad',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227426008',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4032218,
                      CONCEPT_NAME: 'Fruit juice',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '129560007',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 42536408,
                      CONCEPT_NAME: 'Apple',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '735215001',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4023865,
                      CONCEPT_NAME: 'Apple juice',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '226491003',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4217045,
                      CONCEPT_NAME: 'Fruit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '72511004',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: true,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 10,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 7,
        name: 'Elevated BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 25,
        name: 'Vegetables (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 10,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 11,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 10,
              name: 'Vegetables (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000056,
                      CONCEPT_NAME: 'Boiled Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '153',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000057,
                      CONCEPT_NAME: 'Boiled Vegetable Saute',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '154',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000044,
                      CONCEPT_NAME: 'Green Leafy Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '141',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000040,
                      CONCEPT_NAME: 'Steamed Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '137',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 11,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    combinationWindow: 0,
    minCellCount: 3,
    maxDepth: 5,
    allowRepeats: false,
  },
  results: {
    start_labels: ['IPD patients'],
    pre_labels: ['Normal BP at Admission', 'Elevated BP at Admission'],
    post_labels: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
    treatment_labels: [
      'Diuretics equivalent (Diet)',
      'ACE Inhibitors equivalent (Diet)',
      'Vasodilators equivalent (Diet)',
      'CCB equivalent (Diet)',
      'Ragi and other millets (Diet)',
      'Fruits (Diet)',
      'Vegetables (Diet)',
    ],
    path_codes: {
      start_1: {
        cohorts: ['IPD patients'],
        index: 0,
      },
      pre_1: {
        cohorts: ['Normal BP at Admission'],
        index: 1,
      },
      pre_2: {
        cohorts: ['Elevated BP at Admission'],
        index: 2,
      },
      pre_3: {
        cohorts: ['Elevated BP at Admission', 'Normal BP at Admission'],
        index: 3,
      },
      post_1: {
        cohorts: ['Elevated BP at Discharge'],
        index: 4,
      },
      post_2: {
        cohorts: ['Normal BP at Discharge'],
        index: 5,
      },
      post_3: {
        cohorts: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
        index: 6,
      },
      treatment_1: {
        cohorts: ['Fruits (Diet)'],
        index: 7,
      },
      treatment_2: {
        cohorts: [
          'CCB equivalent (Diet)',
          'Fruits (Diet)',
          'Vegetables (Diet)',
        ],
        index: 8,
      },
      treatment_3: {
        cohorts: [
          'Diuretics equivalent (Diet)',
          'Ragi and other millets (Diet)',
        ],
        index: 9,
      },
      treatment_4: {
        cohorts: ['CCB equivalent (Diet)'],
        index: 10,
      },
      treatment_5: {
        cohorts: ['Fruits (Diet)', 'Ragi and other millets (Diet)'],
        index: 11,
      },
      treatment_6: {
        cohorts: ['Ragi and other millets (Diet)'],
        index: 12,
      },
      treatment_7: {
        cohorts: ['Vegetables (Diet)'],
        index: 13,
      },
      treatment_8: {
        cohorts: ['Vasodilators equivalent (Diet)'],
        index: 14,
      },
      treatment_9: {
        cohorts: [
          'Diuretics equivalent (Diet)',
          'Fruits (Diet)',
          'Ragi and other millets (Diet)',
        ],
        index: 15,
      },
      treatment_10: {
        cohorts: ['CCB equivalent (Diet)', 'Vegetables (Diet)'],
        index: 16,
      },
      treatment_11: {
        cohorts: ['ACE Inhibitors equivalent (Diet)'],
        index: 17,
      },
      treatment_12: {
        cohorts: [
          'CCB equivalent (Diet)',
          'Ragi and other millets (Diet)',
          'Vegetables (Diet)',
        ],
        index: 18,
      },
      treatment_13: {
        cohorts: ['Diuretics equivalent (Diet)'],
        index: 19,
      },
      treatment_14: {
        cohorts: [
          'CCB equivalent (Diet)',
          'Fruits (Diet)',
          'Ragi and other millets (Diet)',
          'Vegetables (Diet)',
        ],
        index: 20,
      },
    },
    pathway_groups: [
      {
        targetCohortId: 6,
        targetCohortCount: 135,
        totalPathwaysCount: 135,
        path_pairs: [
          {
            source: 'start_1',
            target: 'pre_3',
            personCount: 15,
          },
          {
            source: 'pre_3',
            target: 'post_1',
            personCount: 6,
          },
          {
            source: 'start_1',
            target: 'pre_2',
            personCount: 79,
          },
          {
            source: 'pre_2',
            target: 'post_1',
            personCount: 47,
          },
          {
            source: 'start_1',
            target: 'post_1',
            personCount: 1,
          },
          {
            source: 'start_1',
            target: 'pre_1',
            personCount: 37,
          },
          {
            source: 'pre_1',
            target: 'post_2',
            personCount: 27,
          },
          {
            source: 'pre_2',
            target: 'treatment_6',
            personCount: 5,
          },
          {
            source: 'treatment_6',
            target: 'post_1',
            personCount: 4,
          },
          {
            source: 'pre_1',
            target: 'treatment_2',
            personCount: 1,
          },
          {
            source: 'treatment_2',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'treatment_6',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'treatment_10',
            personCount: 1,
          },
          {
            source: 'treatment_10',
            target: 'post_1',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'treatment_9',
            personCount: 1,
          },
          {
            source: 'treatment_9',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'post_3',
            personCount: 10,
          },
          {
            source: 'pre_2',
            target: 'treatment_14',
            personCount: 1,
          },
          {
            source: 'treatment_14',
            target: 'post_1',
            personCount: 1,
          },
          {
            source: 'start_1',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'post_2',
            personCount: 5,
          },
          {
            source: 'pre_2',
            target: 'post_2',
            personCount: 12,
          },
          {
            source: 'pre_2',
            target: 'treatment_3',
            personCount: 1,
          },
          {
            source: 'treatment_3',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_1',
            target: 'treatment_6',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'treatment_5',
            personCount: 1,
          },
          {
            source: 'treatment_5',
            target: 'post_2',
            personCount: 1,
          },
          {
            source: 'pre_3',
            target: 'post_3',
            personCount: 3,
          },
          {
            source: 'pre_1',
            target: 'post_1',
            personCount: 5,
          },
          {
            source: 'pre_1',
            target: 'post_3',
            personCount: 3,
          },
          {
            source: 'treatment_6',
            target: 'post_3',
            personCount: 1,
          },
          {
            source: 'pre_2',
            target: 'treatment_12',
            personCount: 1,
          },
          {
            source: 'treatment_12',
            target: 'post_1',
            personCount: 1,
          },
          {
            source: 'start_1',
            target: 'treatment_1',
            personCount: 2,
          },
        ],
      },
    ],
  },
}

export const newDataSet = {
  generation: {
    id: 692,
    status: 'COMPLETED',
    sourceKey: 'wellofhir_pilot1',
    hashCode: -1062021470,
    startTime: 1693564894732,
    endTime: 1693565208776,
    exitMessage: '',
  },
  design: {
    createdDate: 1693564860102,
    modifiedDate: 1693564888093,
    tags: [],
    name: 'IPD Pathway BP x Therapies - v3',
    targetCohorts: [
      {
        id: 6,
        name: 'IPD patients',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 4,
                    Op: 'gte',
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    eventCohorts: [
      {
        id: 33,
        name: 'Massage (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000077,
                      CONCEPT_NAME: 'Abhyanga',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '1',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000079,
                      CONCEPT_NAME: 'Body Scrub with aroma',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '3',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000082,
                      CONCEPT_NAME: 'Griva Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '6',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000085,
                      CONCEPT_NAME: 'Janu Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '9',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000103,
                      CONCEPT_NAME: 'Kansabati',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '27',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000086,
                      CONCEPT_NAME: 'Kati Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '10',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000087,
                      CONCEPT_NAME: 'Lemon & Sugar body scrub',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '11',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000088,
                      CONCEPT_NAME: 'Nasya',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '12',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000089,
                      CONCEPT_NAME: 'Navara kizhi',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '13',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000090,
                      CONCEPT_NAME: 'Netra Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '14',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000091,
                      CONCEPT_NAME: 'Oat meal body scrub',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '15',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000093,
                      CONCEPT_NAME: 'Patra Potli',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '17',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000095,
                      CONCEPT_NAME: 'Sarvangadhara (phizichil)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '19',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000096,
                      CONCEPT_NAME: 'Shiro Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '20',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000097,
                      CONCEPT_NAME: 'Shiroabhyanga',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '21',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000098,
                      CONCEPT_NAME: 'Shirodhara',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '22',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000102,
                      CONCEPT_NAME: 'Ubtan',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '26',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000104,
                      CONCEPT_NAME: 'Wheat, coffee & Sugar body scrub',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '28',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 34,
        name: 'Mud (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000162,
                      CONCEPT_NAME: 'Herbal Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '87',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000163,
                      CONCEPT_NAME: 'Herbal Mud Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '88',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 35,
        name: 'Hydro (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000129,
                      CONCEPT_NAME: 'Arm Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '54',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000130,
                      CONCEPT_NAME: 'Buttermilk Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '55',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000131,
                      CONCEPT_NAME: 'Castor oil Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '56',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000132,
                      CONCEPT_NAME: 'Circular Jet Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '57',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000134,
                      CONCEPT_NAME: 'Contrast Hip Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '59',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000135,
                      CONCEPT_NAME: 'Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '60',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000140,
                      CONCEPT_NAME: 'Foot Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '65',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000139,
                      CONCEPT_NAME: 'Foot and Arm Bath with epsom salt',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '64',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000141,
                      CONCEPT_NAME: 'Forehead Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '66',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000143,
                      CONCEPT_NAME: 'Hip Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '68',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000144,
                      CONCEPT_NAME: 'Immersion Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '69',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000146,
                      CONCEPT_NAME: 'Immersion Bath with Neem',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '71',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000145,
                      CONCEPT_NAME: 'Immersion Bath with epsom salt',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '70',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000147,
                      CONCEPT_NAME: 'Lemon Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '72',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000149,
                      CONCEPT_NAME: 'Neem Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '74',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000150,
                      CONCEPT_NAME: 'Oat meal Immersion Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '75',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000151,
                      CONCEPT_NAME: 'Plantain Leaf Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '76',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000152,
                      CONCEPT_NAME: 'Sauna Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '77',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000153,
                      CONCEPT_NAME: 'Spinal Spray',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '78',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000154,
                      CONCEPT_NAME: 'Spinal spray with epsom salt',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '79',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000156,
                      CONCEPT_NAME: 'Sun Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '81',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000158,
                      CONCEPT_NAME: 'Vaginal Douche',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '83',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 36,
        name: 'Elevated BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 37,
        name: 'Normal BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 38,
        name: 'Normal BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 7,
        name: 'Elevated BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 39,
        name: 'Yoga (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Yoga therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000176,
                      CONCEPT_NAME: 'Meditation',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '101',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000177,
                      CONCEPT_NAME: 'Shankhaprakshalana',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '102',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000178,
                      CONCEPT_NAME: 'Shatkriyas',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4046464,
                      CONCEPT_NAME: 'Participation in yoga',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '229224000',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 40,
        name: 'Machine Physiotherapy (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Machine Physiotherapy',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000182,
                      CONCEPT_NAME: 'Cervical Traction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '107',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000183,
                      CONCEPT_NAME: 'Cervical Traction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '108',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000187,
                      CONCEPT_NAME: 'Faradic Current',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '112',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000188,
                      CONCEPT_NAME: 'Galvanic Current',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '113',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000186,
                      CONCEPT_NAME: 'Infrared',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '111',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000184,
                      CONCEPT_NAME: 'Lumbo-Sacral Traction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '109',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000185,
                      CONCEPT_NAME: 'Short Wave Diathermy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '110',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000189,
                      CONCEPT_NAME: 'Tens Therapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '114',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000126,
                      CONCEPT_NAME: 'Cupping Therapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '51',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000179,
                      CONCEPT_NAME: 'Interferential Therapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '104',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000181,
                      CONCEPT_NAME: 'Laser  Therapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '106',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000180,
                      CONCEPT_NAME: 'Ultra-Sound Therapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '105',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 41,
        name: 'Manual Physiotherapy (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Manual Physiotherapy',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000198,
                      CONCEPT_NAME: 'Calf Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '123',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000190,
                      CONCEPT_NAME: 'Cervico-Thoracic',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '115',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000126,
                      CONCEPT_NAME: 'Cupping Therapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '51',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000199,
                      CONCEPT_NAME: 'Hamstring Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '124',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000194,
                      CONCEPT_NAME: 'Median Nerve Gliding',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '119',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000192,
                      CONCEPT_NAME: 'Neck Isometrics',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '117',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000193,
                      CONCEPT_NAME: 'Neck Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '118',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000201,
                      CONCEPT_NAME: 'Piriformis Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '126',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000196,
                      CONCEPT_NAME: 'Radial Nerve Gliding',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '121',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000200,
                      CONCEPT_NAME: 'Rectus Femoris Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '125',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000191,
                      CONCEPT_NAME: 'Rotation Manipulation',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '116',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000197,
                      CONCEPT_NAME: 'Sciatic Nerve Mobilisation',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '122',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000195,
                      CONCEPT_NAME: 'Ulnar Nerve Gliding',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '120',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000233,
                      CONCEPT_NAME: 'Abdominal Exercise',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '158',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000208,
                      CONCEPT_NAME:
                        'Abductor Strengthening Exercise of Lower Limb',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '133',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000209,
                      CONCEPT_NAME:
                        'Abductor Strengthening Exercise of Upper Limb',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '134',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000207,
                      CONCEPT_NAME:
                        'Adductor Strengthening Exercise of Lower Limb',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '132',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000210,
                      CONCEPT_NAME:
                        'Adductor Strengthening Exercise of Upper Limb',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '135',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000219,
                      CONCEPT_NAME: 'Ball Exercises',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '144',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000234,
                      CONCEPT_NAME: 'Breathing Exercise',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '159',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000221,
                      CONCEPT_NAME: 'Bridging',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '146',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000228,
                      CONCEPT_NAME: 'Capsular Stretching Of Knee',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '153',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000205,
                      CONCEPT_NAME: 'Capsular Stretching Of Shoulder',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '130',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000244,
                      CONCEPT_NAME: 'Cat-Camel Exercise',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '169',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000216,
                      CONCEPT_NAME: 'Core Muscle Strengthening',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '141',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000224,
                      CONCEPT_NAME: 'Cyriax Manipulation Technique',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '149',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000232,
                      CONCEPT_NAME: 'DTFM',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '157',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000215,
                      CONCEPT_NAME: 'Deltoid Strengthening',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '140',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000235,
                      CONCEPT_NAME: 'Diapraghmatic Breathing',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '160',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000240,
                      CONCEPT_NAME: 'Efflurage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '165',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000237,
                      CONCEPT_NAME: 'Ergonomics Training',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '162',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000230,
                      CONCEPT_NAME: 'Faradic Foot Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '155',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000222,
                      CONCEPT_NAME: 'Flexion Exercise',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '147',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000220,
                      CONCEPT_NAME: 'Gait Training',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '145',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000214,
                      CONCEPT_NAME: 'Hamstring Strengthening',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '139',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000229,
                      CONCEPT_NAME: 'Hand Intrinsic Muscle Activities',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '154',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000226,
                      CONCEPT_NAME: 'Kaltenborg Technique',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '151',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000217,
                      CONCEPT_NAME: 'Kneading',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '142',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000239,
                      CONCEPT_NAME: 'Lymphatic Drainage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '164',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000227,
                      CONCEPT_NAME: 'Maitland Technique',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '152',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000218,
                      CONCEPT_NAME: 'Mat Exercises',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '143',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000223,
                      CONCEPT_NAME: 'Mc Kenzie Extension',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '148',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000225,
                      CONCEPT_NAME: 'Patellar Mobilization',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '150',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000206,
                      CONCEPT_NAME: 'Pendular Exercise Of Shoulder',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '131',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000231,
                      CONCEPT_NAME: 'Posterior Capsular Stretch',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '156',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000238,
                      CONCEPT_NAME: 'Postural Correction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '163',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000211,
                      CONCEPT_NAME: 'Quadricep Exercise',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '136',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000204,
                      CONCEPT_NAME: 'Range Of Motion Exercise',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '129',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000236,
                      CONCEPT_NAME: 'Spirometry',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '161',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000202,
                      CONCEPT_NAME: 'Sternocleidomastoid Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '127',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000241,
                      CONCEPT_NAME: 'Tapping',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '166',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000203,
                      CONCEPT_NAME: 'Trapezius Stretching',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '128',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000213,
                      CONCEPT_NAME: 'Vastus Lateralis Strengthening',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '138',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000212,
                      CONCEPT_NAME: 'Vastus Medialis Strengthening',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '137',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000243,
                      CONCEPT_NAME: 'Vibration',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 42,
        name: 'Face, Body Pack (Lep) & Hair Pack (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Face, Body Pack (Lep) & Hair Pack',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000165,
                      CONCEPT_NAME: 'Coconut Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '90',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000166,
                      CONCEPT_NAME: 'Flax Seed Hair Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '91',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000167,
                      CONCEPT_NAME: 'Ganji Turmeric Aloevera Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '92',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000168,
                      CONCEPT_NAME: 'Ganji Turmeric Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '93',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000169,
                      CONCEPT_NAME: 'Herbal Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '94',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000170,
                      CONCEPT_NAME: 'Herbal Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '95',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000171,
                      CONCEPT_NAME: 'Herbal Hair Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '96',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000172,
                      CONCEPT_NAME: 'Neem Aloevera Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '97',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000173,
                      CONCEPT_NAME: 'Neem Aloevera Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '98',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000174,
                      CONCEPT_NAME: 'Onion & Castor Oil Hair Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '99',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000175,
                      CONCEPT_NAME: 'Varna Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '100',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 43,
        name: 'Acupuncture (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Acupuncture',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000128,
                      CONCEPT_NAME: 'Acupuncture with moxa',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '53',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000127,
                      CONCEPT_NAME: 'Acupuncture with stimulation',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '52',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4260518,
                      CONCEPT_NAME: 'Acupuncture',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '44868003',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 44,
        name: 'Packs or Wraps (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Packs or Wraps',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000105,
                      CONCEPT_NAME: 'Abdomen Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '29',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000106,
                      CONCEPT_NAME: 'Arm Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '30',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000107,
                      CONCEPT_NAME: 'Castor oil compress',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '31',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000108,
                      CONCEPT_NAME: 'Chest Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '32',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000109,
                      CONCEPT_NAME: 'Cold Compress',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '33',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000110,
                      CONCEPT_NAME: 'Epsom salt Body Friction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '34',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000111,
                      CONCEPT_NAME: 'Full wet Sheet Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '35',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000112,
                      CONCEPT_NAME: 'Gastrohepatic Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '36',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000113,
                      CONCEPT_NAME: 'Girdle Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '37',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000114,
                      CONCEPT_NAME: 'Head Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '38',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000115,
                      CONCEPT_NAME: 'Hot Compress or fomentation',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '39',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000186,
                      CONCEPT_NAME: 'Infrared',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '111',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000116,
                      CONCEPT_NAME: 'Kidney Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '41',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000117,
                      CONCEPT_NAME: 'Knee Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '42',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000118,
                      CONCEPT_NAME: 'Leg Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '43',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000120,
                      CONCEPT_NAME: 'Mustard Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '45',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000121,
                      CONCEPT_NAME: 'Neem Body Friction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '46',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000123,
                      CONCEPT_NAME: 'Thigh Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '48',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000124,
                      CONCEPT_NAME: 'Throat Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '49',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000125,
                      CONCEPT_NAME: 'Trunk Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '50',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 32,
        name: 'Steam (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    combinationWindow: 0,
    minCellCount: 3,
    maxDepth: 5,
    allowRepeats: false,
  },
  results: {
    stages: {
      target: [
        {
          code: 'start_1',
          cohorts: ['IPD patients'],
        },
      ],
      pre_obs: [
        {
          code: 'pre_1',
          cohorts: ['Normal BP at Admission'],
        },
        {
          code: 'pre_2',
          cohorts: ['Elevated BP at Admission'],
        },
        {
          code: 'pre_3',
          cohorts: ['Elevated BP at Admission', 'Normal BP at Admission'],
        },
      ],
      treatment: [
        {
          code: 'treatment_1',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_2',
          cohorts: ['Massage (Therapy)'],
        },
        {
          code: 'treatment_3',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_4',
          cohorts: ['Hydro (Therapy)', 'Massage (Therapy)', 'Steam (Therapy)'],
        },
        {
          code: 'treatment_5',
          cohorts: ['Hydro (Therapy)', 'Mud (Therapy)'],
        },
        {
          code: 'treatment_6',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_7',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_8',
          cohorts: ['Manual Physiotherapy (Therapy)'],
        },
        {
          code: 'treatment_9',
          cohorts: ['Hydro (Therapy)'],
        },
        {
          code: 'treatment_10',
          cohorts: ['Packs or Wraps (Therapy)'],
        },
        {
          code: 'treatment_11',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_12',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
          ],
        },
        {
          code: 'treatment_13',
          cohorts: ['Acupuncture (Therapy)'],
        },
        {
          code: 'treatment_14',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
          ],
        },
        {
          code: 'treatment_15',
          cohorts: ['Machine Physiotherapy (Therapy)'],
        },
        {
          code: 'treatment_16',
          cohorts: [
            'Acupuncture (Therapy)',
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_17',
          cohorts: ['Yoga (Therapy)'],
        },
        {
          code: 'treatment_18',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
          ],
        },
        {
          code: 'treatment_19',
          cohorts: ['Hydro (Therapy)', 'Packs or Wraps (Therapy)'],
        },
        {
          code: 'treatment_20',
          cohorts: ['Steam (Therapy)'],
        },
        {
          code: 'treatment_21',
          cohorts: ['Face, Body Pack (Lep) & Hair Pack (Therapy)'],
        },
        {
          code: 'treatment_22',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_23',
          cohorts: ['Mud (Therapy)'],
        },
      ],
      post_obs: [
        {
          code: 'post_1',
          cohorts: ['Elevated BP at Discharge'],
        },
        {
          code: 'post_2',
          cohorts: ['Normal BP at Discharge'],
        },
        {
          code: 'post_3',
          cohorts: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
        },
      ],
    },
    pathway_groups: [
      {
        targetCohortId: 6,
        targetCohortCount: 135,
        totalPathwaysCount: 134,
        targetCohortName: 'IPD patients',
        pathways: [
          {
            path: ['start_1', 'pre_2', 'treatment_19', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_9', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_7', 'post_3'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_7', 'post_1'],
            personCount: 2,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_22', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_11', 'post_1'],
            personCount: 23,
          },
          {
            path: ['start_1', 'treatment_7'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_18', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_11', 'post_1'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_7', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'treatment_10', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_1', 'post_1'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_1', 'post_2'],
            personCount: 2,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_1', 'post_1'],
            personCount: 2,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_5', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_2', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_12', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_7', 'post_2'],
            personCount: 2,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_16', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_4', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_3', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_3', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'post_1'],
            personCount: 2,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_3', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_7', 'post_2'],
            personCount: 14,
          },
          {
            path: ['start_1', 'treatment_6', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_12', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_1', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_3', 'post_1'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_7', 'post_2'],
            personCount: 11,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_11', 'post_2'],
            personCount: 2,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_14', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_12', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_11', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_7', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'post_3'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_11', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_11', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_18', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_14', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_12', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'post_1'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_1', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_1', 'post_2'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_7', 'post_1'],
            personCount: 10,
          },
        ],
      },
    ],
  },
}

export const newDiet = {
  generation: {
    id: 684,
    status: 'COMPLETED',
    sourceKey: 'wellofhir_pilot1',
    hashCode: -108397387,
    startTime: 1693506991347,
    endTime: 1693507240627,
    exitMessage: '',
  },
  design: {
    createdDate: 1690447473925,
    modifiedDate: 1690485989962,
    tags: [],
    name: 'IPD Pathway - BP x Diets',
    targetCohorts: [
      {
        id: 6,
        name: 'IPD patients',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 4,
                    Op: 'gte',
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    eventCohorts: [
      {
        id: 17,
        name: 'Diuretics equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 7,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 8,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 7,
              name: 'Diuretics equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000071,
                      CONCEPT_NAME: 'Tender Coconut',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000006,
                      CONCEPT_NAME: 'Barley Water',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4037467,
                      CONCEPT_NAME: 'Plantain',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227259009',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 8,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 18,
        name: 'ACE Inhibitors equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 7,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 6,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 3,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 4,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 5,
              name: 'Diuretics equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000071,
                      CONCEPT_NAME: 'Tender Coconut',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000006,
                      CONCEPT_NAME: 'Barley Water',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4037467,
                      CONCEPT_NAME: 'Plantain',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227259009',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 6,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 7,
              name: 'ACE Inhibitors equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 1620125,
                      CONCEPT_NAME: 'Hibiscus tea',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'LP419911-5',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'LOINC Component',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 19,
        name: 'Vasodilators equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: -1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 7,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 3,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 4,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 5,
              name: 'Diuretics equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000071,
                      CONCEPT_NAME: 'Tender Coconut',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '168',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000006,
                      CONCEPT_NAME: 'Barley Water',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '103',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4037467,
                      CONCEPT_NAME: 'Plantain',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227259009',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 6,
              name: 'Vasodilators equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000062,
                      CONCEPT_NAME: 'Beetroot Juice',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '159',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000072,
                      CONCEPT_NAME: 'Carrot and Beetroot Soup',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '169',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 7,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 8,
              name: 'Vasodilators equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000062,
                      CONCEPT_NAME: 'Beetroot Juice',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '159',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000072,
                      CONCEPT_NAME: 'Carrot and Beetroot Soup',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '169',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 36,
        name: 'Elevated BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 20,
        name: 'CCB equivalent (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 9,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 8,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 8,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 9,
              name: 'CCB equivalent (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000056,
                      CONCEPT_NAME: 'Boiled Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '153',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000044,
                      CONCEPT_NAME: 'Green Leafy Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '141',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000040,
                      CONCEPT_NAME: 'Steamed Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '137',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 37,
        name: 'Normal BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 21,
        name: 'Ragi and other millets (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 8,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 9,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 8,
              name: 'Ragi and other millets (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000066,
                      CONCEPT_NAME: 'Boil Ragi',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '163',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000045,
                      CONCEPT_NAME: 'Millets Khichadi',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '142',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000038,
                      CONCEPT_NAME: 'Millets Roti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '135',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000065,
                      CONCEPT_NAME: 'Ragi Dosa',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '162',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000074,
                      CONCEPT_NAME: 'Ragi Kheer',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '171',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 9,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 38,
        name: 'Normal BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 22,
        name: 'Fruits (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 9,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 10,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 9,
              name: 'Fruits (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 4026207,
                      CONCEPT_NAME: 'Fresh fruit salad',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '227426008',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4032218,
                      CONCEPT_NAME: 'Fruit juice',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '129560007',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 42536408,
                      CONCEPT_NAME: 'Apple',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '735215001',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4023865,
                      CONCEPT_NAME: 'Apple juice',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '226491003',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 4217045,
                      CONCEPT_NAME: 'Fruit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '72511004',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'SNOMED',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: true,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 10,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 7,
        name: 'Elevated BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'gt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 25,
        name: 'Vegetables (Diet)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Observation: {
                            ObservationTypeExclude: false,
                            ObservationSourceConcept: 10,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 11,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 10,
              name: 'Vegetables (Diet)',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000056,
                      CONCEPT_NAME: 'Boiled Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '153',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000057,
                      CONCEPT_NAME: 'Boiled Vegetable Saute',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '154',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000044,
                      CONCEPT_NAME: 'Green Leafy Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '141',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000040,
                      CONCEPT_NAME: 'Steamed Green Vegetables',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '137',
                      DOMAIN_ID: 'Observation',
                      VOCABULARY_ID: 'wpFoodType',
                      CONCEPT_CLASS_ID: 'Substance',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 11,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    combinationWindow: 0,
    minCellCount: 3,
    maxDepth: 5,
    allowRepeats: false,
  },
  results: {
    stages: {
      target: [
        {
          code: 'start_1',
          cohorts: ['IPD patients'],
        },
      ],
      pre_obs: [
        {
          code: 'pre_1',
          cohorts: ['Normal BP at Admission'],
        },
        {
          code: 'pre_2',
          cohorts: ['Elevated BP at Admission'],
        },
        {
          code: 'pre_3',
          cohorts: ['Elevated BP at Admission', 'Normal BP at Admission'],
        },
      ],
      treatment: [
        {
          code: 'treatment_1',
          cohorts: ['Fruits (Diet)'],
        },
        {
          code: 'treatment_2',
          cohorts: [
            'CCB equivalent (Diet)',
            'Fruits (Diet)',
            'Vegetables (Diet)',
          ],
        },
        {
          code: 'treatment_3',
          cohorts: [
            'Diuretics equivalent (Diet)',
            'Ragi and other millets (Diet)',
          ],
        },
        {
          code: 'treatment_4',
          cohorts: ['CCB equivalent (Diet)'],
        },
        {
          code: 'treatment_5',
          cohorts: ['Fruits (Diet)', 'Ragi and other millets (Diet)'],
        },
        {
          code: 'treatment_6',
          cohorts: ['Ragi and other millets (Diet)'],
        },
        {
          code: 'treatment_7',
          cohorts: ['Vegetables (Diet)'],
        },
        {
          code: 'treatment_8',
          cohorts: ['Vasodilators equivalent (Diet)'],
        },
        {
          code: 'treatment_9',
          cohorts: [
            'Diuretics equivalent (Diet)',
            'Fruits (Diet)',
            'Ragi and other millets (Diet)',
          ],
        },
        {
          code: 'treatment_10',
          cohorts: ['CCB equivalent (Diet)', 'Vegetables (Diet)'],
        },
        {
          code: 'treatment_11',
          cohorts: ['ACE Inhibitors equivalent (Diet)'],
        },
        {
          code: 'treatment_12',
          cohorts: [
            'CCB equivalent (Diet)',
            'Ragi and other millets (Diet)',
            'Vegetables (Diet)',
          ],
        },
        {
          code: 'treatment_13',
          cohorts: ['Diuretics equivalent (Diet)'],
        },
        {
          code: 'treatment_14',
          cohorts: [
            'CCB equivalent (Diet)',
            'Fruits (Diet)',
            'Ragi and other millets (Diet)',
            'Vegetables (Diet)',
          ],
        },
      ],
      post_obs: [
        {
          code: 'post_1',
          cohorts: ['Elevated BP at Discharge'],
        },
        {
          code: 'post_2',
          cohorts: ['Normal BP at Discharge'],
        },
        {
          code: 'post_3',
          cohorts: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
        },
      ],
    },
    pathway_groups: [
      {
        targetCohortId: 6,
        targetCohortCount: 135,
        totalPathwaysCount: 135,
        targetCohortName: 'IPD patients',
        pathways: [
          {
            path: ['start_1', 'pre_3', 'post_1'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_2', 'post_1'],
            personCount: 47,
          },
          {
            path: ['start_1', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_6', 'post_1'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_1', 'post_2'],
            personCount: 27,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_2', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_6', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_10', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_9', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'post_3'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_14', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_2', 'post_2'],
            personCount: 12,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_3', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_6', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_5', 'post_2'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_3', 'post_3'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_1', 'post_1'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_1', 'post_3'],
            personCount: 3,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_6', 'post_3'],
            personCount: 1,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_12', 'post_1'],
            personCount: 1,
          },
          {
            path: ['start_1', 'treatment_1'],
            personCount: 2,
          },
        ],
      },
    ],
  },
}

export const allowedDataType: R4.ICoding[] = [
  { code: 'Quantity', display: 'Quantity' },
  { code: 'String', display: 'String' },
]

export const genderCoding: R4.ICoding[] = [
  { code: 'All', display: 'All' },
  { code: 'male', display: 'Male' },
  { code: 'female', display: 'Female' },
]

export const multipleAllowed: R4.ICoding[] = [
  { code: 'mg/dL', display: 'mg/dL' },
  { code: 'ng/mL', display: 'ng/mL' },
  { code: '{ratio}', display: '{ratio}' },
  { code: 'g/dL', display: 'g/dL' },
  { code: 'x10E3/uL', display: 'x10E3/uL' },
  { code: 'x10E6/uL', display: 'x10E6/uL' },
  { code: '%', display: '%' },
  { code: 'Thou/ul', display: 'Thou/ul' },
]

export const unitServiceType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '394656005',
    display: 'IPD',
  },
  {
    system: 'http://snomed.info/sct',
    code: '33022008',
    display: 'OPD',
  },
  {
    system: 'http://snomed.info/sct',
    code: '304903009',
    display: 'Day Center',
  },
]

export const serviceType: R4.ICoding[] = [
  {
    system: '',
    code: 'ipd',
    display: 'IPD',
  },
  {
    system: '',
    code: 'opd',
    display: 'Appointment',
  },
]

export const accountTypeForBank: R4.ICoding[] = [
  {
    system: '',
    code: 'savings',
    display: 'Savings',
  },
  {
    system: '',
    code: 'current',
    display: 'Current',
  },
]

export const OrderTab: R4.ICoding[] = [
  {
    system: '',
    code: 'clinic',
    display: 'Order for Clinic',
  },
  {
    system: '',
    code: 'patient',
    display: 'Order for Patient',
  },
]

export const latestSankeyData = {
  generation: {
    id: 845,
    status: 'COMPLETED',
    sourceKey: 'wellofhir_pilot1',
    hashCode: 1657604064,
    startTime: 1698327217767,
    endTime: 1698327754330,
    exitMessage: '',
  },
  design: {
    createdDate: 1698327123074,
    modifiedDate: 1698327137323,
    tags: [],
    name: 'IPD Pathway BP x Therapies - v7',
    targetCohorts: [
      {
        id: 51,
        name: 'IPD patients (3 to 4 day)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 3,
                    Op: 'bt',
                    Extent: 4,
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 52,
        name: 'IPD patients (7+ day)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 7,
                    Op: 'gte',
                    Extent: 4,
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 53,
        name: 'IPD patients (5 to 6 day)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CodesetId: 0,
                  VisitTypeExclude: false,
                  VisitLength: {
                    Value: 5,
                    Op: 'bt',
                    Extent: 6,
                  },
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [
            {
              name: 'BP measurement at start and end of visit',
              expression: {
                Type: 'ALL',
                CriteriaList: [
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 0,
                        Coeff: -1,
                      },
                      End: {
                        Days: 1,
                        Coeff: 1,
                      },
                      UseIndexEnd: false,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                  {
                    Criteria: {
                      Measurement: {
                        CodesetId: 1,
                        MeasurementTypeExclude: false,
                      },
                    },
                    StartWindow: {
                      Start: {
                        Days: 1,
                        Coeff: -1,
                      },
                      End: {
                        Days: 0,
                        Coeff: 1,
                      },
                      UseIndexEnd: true,
                      UseEventEnd: false,
                    },
                    RestrictVisit: false,
                    IgnoreObservationPeriod: false,
                    Occurrence: {
                      Type: 2,
                      Count: 1,
                      IsDistinct: false,
                    },
                  },
                ],
                DemographicCriteriaList: [],
                Groups: [],
              },
            },
          ],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    eventCohorts: [
      {
        id: 33,
        name: 'Massage (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Massages',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000157,
                      CONCEPT_NAME: 'Under water massage (Jaccuzi)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '82',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000077,
                      CONCEPT_NAME: 'Abhyanga',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '1',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000081,
                      CONCEPT_NAME: 'Antioxidant Body Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '5',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000078,
                      CONCEPT_NAME: 'Aroma Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '2',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000079,
                      CONCEPT_NAME: 'Body Scrub with aroma',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '3',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000080,
                      CONCEPT_NAME: 'Deep Tissue Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '4',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000082,
                      CONCEPT_NAME: 'Griva Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '6',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000083,
                      CONCEPT_NAME: 'Herbal Body or Uptan Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '7',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000084,
                      CONCEPT_NAME: 'Hot Stone Massage with aroma oil',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000085,
                      CONCEPT_NAME: 'Janu Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '9',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000103,
                      CONCEPT_NAME: 'Kansabati',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '27',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000086,
                      CONCEPT_NAME: 'Kati Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '10',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000087,
                      CONCEPT_NAME: 'Lemon & Sugar body scrub',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '11',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000088,
                      CONCEPT_NAME: 'Nasya',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '12',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000089,
                      CONCEPT_NAME: 'Navara kizhi',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '13',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000090,
                      CONCEPT_NAME: 'Netra Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '14',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000091,
                      CONCEPT_NAME: 'Oat meal body scrub',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '15',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000092,
                      CONCEPT_NAME: 'Partial Massage or specific area massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '16',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000093,
                      CONCEPT_NAME: 'Patra Potli',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '17',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000094,
                      CONCEPT_NAME: 'Salt Glow Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '18',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000095,
                      CONCEPT_NAME: 'Sarvangadhara (phizichil)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '19',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000096,
                      CONCEPT_NAME: 'Shiro Vasti',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '20',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000097,
                      CONCEPT_NAME: 'Shiroabhyanga',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '21',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000098,
                      CONCEPT_NAME: 'Shirodhara',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '22',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000099,
                      CONCEPT_NAME: 'Swedish Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '23',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000102,
                      CONCEPT_NAME: 'Ubtan',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '26',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000100,
                      CONCEPT_NAME:
                        'Udvarthnam (medicated ayurvedic powder massage)',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '24',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000101,
                      CONCEPT_NAME: 'Vibro powder Massage',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '25',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000104,
                      CONCEPT_NAME: 'Wheat, coffee & Sugar body scrub',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '28',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 34,
        name: 'Mud (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Mud therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000159,
                      CONCEPT_NAME: 'Abdomen Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '84',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000160,
                      CONCEPT_NAME: 'Eye Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '85',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000161,
                      CONCEPT_NAME: 'Full Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '86',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000162,
                      CONCEPT_NAME: 'Herbal Mud Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '87',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000163,
                      CONCEPT_NAME: 'Herbal Mud Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '88',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000164,
                      CONCEPT_NAME: 'Partial Mud Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '89',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 35,
        name: 'Hydro (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Hydro therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000133,
                      CONCEPT_NAME: 'Colon Hydrotherapy',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '58',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000129,
                      CONCEPT_NAME: 'Arm Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '54',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000130,
                      CONCEPT_NAME: 'Buttermilk Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '55',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000131,
                      CONCEPT_NAME: 'Castor oil Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '56',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000132,
                      CONCEPT_NAME: 'Circular Jet Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '57',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000134,
                      CONCEPT_NAME: 'Contrast Hip Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '59',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000135,
                      CONCEPT_NAME: 'Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '60',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000140,
                      CONCEPT_NAME: 'Foot Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '65',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000139,
                      CONCEPT_NAME: 'Foot and Arm Bath with epsom salt',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '64',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000141,
                      CONCEPT_NAME: 'Forehead Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '66',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000143,
                      CONCEPT_NAME: 'Hip Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '68',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000144,
                      CONCEPT_NAME: 'Immersion Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '69',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000146,
                      CONCEPT_NAME: 'Immersion Bath with Neem',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '71',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000145,
                      CONCEPT_NAME: 'Immersion Bath with epsom salt',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '70',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000147,
                      CONCEPT_NAME: 'Lemon Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '72',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000149,
                      CONCEPT_NAME: 'Neem Enema',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '74',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000150,
                      CONCEPT_NAME: 'Oat meal Immersion Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '75',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000151,
                      CONCEPT_NAME: 'Plantain Leaf Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '76',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000152,
                      CONCEPT_NAME: 'Sauna Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '77',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000153,
                      CONCEPT_NAME: 'Spinal Spray',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '78',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000154,
                      CONCEPT_NAME: 'Spinal spray with epsom salt',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '79',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000156,
                      CONCEPT_NAME: 'Sun Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '81',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000158,
                      CONCEPT_NAME: 'Vaginal Douche',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '83',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 36,
        name: 'Elevated BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'bt',
                              Extent: 129,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 37,
        name: 'Normal BP at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 38,
        name: 'Normal BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lte',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 7,
        name: 'Elevated BP at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'bt',
                              Extent: 129,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'lt',
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 42,
        name: 'Face, Body Pack (Lep) & Hair Pack (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Face, Body Pack (Lep) & Hair Pack',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000165,
                      CONCEPT_NAME: 'Coconut Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '90',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000166,
                      CONCEPT_NAME: 'Flax Seed Hair Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '91',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000167,
                      CONCEPT_NAME: 'Ganji Turmeric Aloevera Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '92',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000168,
                      CONCEPT_NAME: 'Ganji Turmeric Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '93',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000169,
                      CONCEPT_NAME: 'Herbal Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '94',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000170,
                      CONCEPT_NAME: 'Herbal Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '95',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000171,
                      CONCEPT_NAME: 'Herbal Hair Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '96',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000172,
                      CONCEPT_NAME: 'Neem Aloevera Body Lep',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '97',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000173,
                      CONCEPT_NAME: 'Neem Aloevera Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '98',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000174,
                      CONCEPT_NAME: 'Onion & Castor Oil Hair Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '99',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000175,
                      CONCEPT_NAME: 'Varna Face Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '100',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 44,
        name: 'Packs or Wraps (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Packs or Wraps',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000105,
                      CONCEPT_NAME: 'Abdomen Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '29',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000106,
                      CONCEPT_NAME: 'Arm Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '30',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000107,
                      CONCEPT_NAME: 'Castor oil compress',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '31',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000108,
                      CONCEPT_NAME: 'Chest Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '32',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000109,
                      CONCEPT_NAME: 'Cold Compress',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '33',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000110,
                      CONCEPT_NAME: 'Epsom salt Body Friction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '34',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000111,
                      CONCEPT_NAME: 'Full wet Sheet Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '35',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000112,
                      CONCEPT_NAME: 'Gastrohepatic Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '36',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000113,
                      CONCEPT_NAME: 'Girdle Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '37',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000114,
                      CONCEPT_NAME: 'Head Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '38',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000115,
                      CONCEPT_NAME: 'Hot Compress or fomentation',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '39',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000186,
                      CONCEPT_NAME: 'Infrared',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '111',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000116,
                      CONCEPT_NAME: 'Kidney Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '41',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000117,
                      CONCEPT_NAME: 'Knee Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '42',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000118,
                      CONCEPT_NAME: 'Leg Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '43',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000120,
                      CONCEPT_NAME: 'Mustard Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '45',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000121,
                      CONCEPT_NAME: 'Neem Body Friction',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '46',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000123,
                      CONCEPT_NAME: 'Thigh Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '48',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000124,
                      CONCEPT_NAME: 'Throat Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '49',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000125,
                      CONCEPT_NAME: 'Trunk Pack',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '50',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 45,
        name: 'High BP(stage 1) at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 130,
                              Op: 'bt',
                              Extent: 139,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'bt',
                              Extent: 89,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 46,
        name: 'High BP(stage 2) at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 140,
                              Op: 'bt',
                              Extent: 189,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 90,
                              Op: 'bt',
                              Extent: 119,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 47,
        name: 'Very High BP(crisis) at Admission',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 190,
                              Op: 'gte',
                              Extent: 189,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gte',
                              Extent: 119,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Days: 1,
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 48,
        name: 'High BP(stage 1) at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 130,
                              Op: 'bt',
                              Extent: 139,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 80,
                              Op: 'bt',
                              Extent: 89,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 49,
        name: 'High BP(stage 2) at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 140,
                              Op: 'bt',
                              Extent: 189,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 90,
                              Op: 'bt',
                              Extent: 119,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 50,
        name: 'Very High BP(crisis) at Discharge',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ANY',
                    CriteriaList: [
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 1,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 190,
                              Op: 'gte',
                              Extent: 189,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                      {
                        Criteria: {
                          Measurement: {
                            CodesetId: 2,
                            MeasurementTypeExclude: false,
                            ValueAsNumber: {
                              Value: 120,
                              Op: 'gte',
                              Extent: 119,
                            },
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 1,
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Systolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3004249,
                      CONCEPT_NAME: 'Systolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8480-6',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 2,
              name: 'Diastolic BP',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 3012888,
                      CONCEPT_NAME: 'Diastolic blood pressure',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '8462-4',
                      DOMAIN_ID: 'Measurement',
                      VOCABULARY_ID: 'LOINC',
                      CONCEPT_CLASS_ID: 'Clinical Observation',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
      {
        id: 32,
        name: 'Steam (Therapy)',
        expression: {
          cdmVersionRange: '>=5.0.0',
          PrimaryCriteria: {
            CriteriaList: [
              {
                VisitOccurrence: {
                  CorrelatedCriteria: {
                    Type: 'ALL',
                    CriteriaList: [
                      {
                        Criteria: {
                          ProcedureOccurrence: {
                            ProcedureTypeExclude: false,
                            ProcedureSourceConcept: 1,
                          },
                        },
                        StartWindow: {
                          Start: {
                            Days: 0,
                            Coeff: -1,
                          },
                          End: {
                            Coeff: 1,
                          },
                          UseIndexEnd: false,
                          UseEventEnd: false,
                        },
                        EndWindow: {
                          Start: {
                            Coeff: -1,
                          },
                          End: {
                            Days: 0,
                            Coeff: 1,
                          },
                          UseIndexEnd: true,
                          UseEventEnd: false,
                        },
                        RestrictVisit: false,
                        IgnoreObservationPeriod: false,
                        Occurrence: {
                          Type: 2,
                          Count: 1,
                          IsDistinct: false,
                        },
                      },
                    ],
                    DemographicCriteriaList: [],
                    Groups: [],
                  },
                  CodesetId: 0,
                  VisitTypeExclude: false,
                },
              },
            ],
            ObservationWindow: {
              PriorDays: 0,
              PostDays: 0,
            },
            PrimaryCriteriaLimit: {
              Type: 'First',
            },
          },
          ConceptSets: [
            {
              id: 0,
              name: 'Inpatient visit',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 9201,
                      CONCEPT_NAME: 'Inpatient Visit',
                      STANDARD_CONCEPT: 'S',
                      STANDARD_CONCEPT_CAPTION: 'Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: 'IP',
                      DOMAIN_ID: 'Visit',
                      VOCABULARY_ID: 'Visit',
                      CONCEPT_CLASS_ID: 'Visit',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
            {
              id: 1,
              name: 'Steam therapies',
              expression: {
                items: [
                  {
                    concept: {
                      CONCEPT_ID: 2000000136,
                      CONCEPT_NAME: 'Facial Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '61',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000137,
                      CONCEPT_NAME: 'Facial steam with Ajwain',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '62',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000138,
                      CONCEPT_NAME: 'Facial steam with Eucalyptus',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '63',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000142,
                      CONCEPT_NAME: 'Herba I Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '67',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000148,
                      CONCEPT_NAME: 'Local Steam',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '73',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                  {
                    concept: {
                      CONCEPT_ID: 2000000155,
                      CONCEPT_NAME: 'Steam Bath',
                      STANDARD_CONCEPT: 'N',
                      STANDARD_CONCEPT_CAPTION: 'Non-Standard',
                      INVALID_REASON: 'V',
                      INVALID_REASON_CAPTION: 'Valid',
                      CONCEPT_CODE: '80',
                      DOMAIN_ID: 'Procedure',
                      VOCABULARY_ID: 'wpAyushTherapy',
                      CONCEPT_CLASS_ID: 'Procedure',
                    },
                    isExcluded: false,
                    includeDescendants: false,
                    includeMapped: false,
                  },
                ],
              },
            },
          ],
          QualifiedLimit: {
            Type: 'First',
          },
          ExpressionLimit: {
            Type: 'First',
          },
          InclusionRules: [],
          EndStrategy: {
            DateOffset: {
              DateField: 'EndDate',
              Offset: 0,
            },
          },
          CensoringCriteria: [],
          CollapseSettings: {
            CollapseType: 'ERA',
            EraPad: 0,
          },
          CensorWindow: {},
        },
      },
    ],
    combinationWindow: 0,
    minCellCount: 3,
    maxDepth: 5,
    allowRepeats: false,
  },
  results: {
    stages: {
      target: [
        {
          code: 'start_1',
          cohorts: ['IPD patients (3 to 4 day)'],
        },
        {
          code: 'start_2',
          cohorts: ['IPD patients (7+ day)'],
        },
        {
          code: 'start_3',
          cohorts: ['IPD patients (5 to 6 day)'],
        },
        {
          code: 'start_4',
          cohorts: ['IPD patients (3 to 4 day)', 'IPD patients (7+ day)'],
        },
        {
          code: 'start_5',
          cohorts: ['IPD patients (3 to 4 day)', 'IPD patients (5 to 6 day)'],
        },
        {
          code: 'start_6',
          cohorts: ['IPD patients (5 to 6 day)', 'IPD patients (7+ day)'],
        },
        {
          code: 'start_7',
          cohorts: [
            'IPD patients (3 to 4 day)',
            'IPD patients (5 to 6 day)',
            'IPD patients (7+ day)',
          ],
        },
      ],
      pre_obs: [
        {
          code: 'pre_1',
          cohorts: ['Normal BP at Admission'],
        },
        {
          code: 'pre_2',
          cohorts: ['Elevated BP at Admission'],
        },
        {
          code: 'pre_3',
          cohorts: ['High BP(stage 1) at Admission'],
        },
        {
          code: 'pre_4',
          cohorts: ['High BP(stage 2) at Admission'],
        },
        {
          code: 'pre_5',
          cohorts: ['Very High BP(crisis) at Admission'],
        },
        {
          code: 'pre_6',
          cohorts: ['Elevated BP at Admission', 'Normal BP at Admission'],
        },
        {
          code: 'pre_7',
          cohorts: ['High BP(stage 1) at Admission', 'Normal BP at Admission'],
        },
        {
          code: 'pre_8',
          cohorts: ['High BP(stage 2) at Admission', 'Normal BP at Admission'],
        },
        {
          code: 'pre_9',
          cohorts: [
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_10',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
          ],
        },
        {
          code: 'pre_11',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 2) at Admission',
          ],
        },
        {
          code: 'pre_12',
          cohorts: [
            'Elevated BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_13',
          cohorts: [
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
          ],
        },
        {
          code: 'pre_14',
          cohorts: [
            'High BP(stage 1) at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_15',
          cohorts: [
            'High BP(stage 2) at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_16',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'Normal BP at Admission',
          ],
        },
        {
          code: 'pre_17',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
          ],
        },
        {
          code: 'pre_18',
          cohorts: [
            'Elevated BP at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_19',
          cohorts: [
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
          ],
        },
        {
          code: 'pre_20',
          cohorts: [
            'High BP(stage 1) at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_21',
          cohorts: [
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_22',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
          ],
        },
        {
          code: 'pre_23',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_24',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 2) at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_25',
          cohorts: [
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_26',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
          ],
        },
        {
          code: 'pre_27',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_28',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_29',
          cohorts: [
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_30',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
        {
          code: 'pre_31',
          cohorts: [
            'Elevated BP at Admission',
            'High BP(stage 1) at Admission',
            'High BP(stage 2) at Admission',
            'Normal BP at Admission',
            'Very High BP(crisis) at Admission',
          ],
        },
      ],
      treatment: [
        {
          code: 'treatment_1',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_2',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_3',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
          ],
        },
        {
          code: 'treatment_4',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
          ],
        },
        {
          code: 'treatment_5',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_6',
          cohorts: [
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Packs or Wraps (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_7',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Packs or Wraps (Therapy)',
          ],
        },
        {
          code: 'treatment_8',
          cohorts: ['Steam (Therapy)'],
        },
        {
          code: 'treatment_9',
          cohorts: ['Hydro (Therapy)', 'Packs or Wraps (Therapy)'],
        },
        {
          code: 'treatment_10',
          cohorts: ['Hydro (Therapy)'],
        },
        {
          code: 'treatment_11',
          cohorts: ['Massage (Therapy)'],
        },
        {
          code: 'treatment_12',
          cohorts: ['Packs or Wraps (Therapy)'],
        },
        {
          code: 'treatment_13',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Hydro (Therapy)',
            'Massage (Therapy)',
            'Mud (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_14',
          cohorts: ['Face, Body Pack (Lep) & Hair Pack (Therapy)'],
        },
        {
          code: 'treatment_15',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Steam (Therapy)',
          ],
        },
        {
          code: 'treatment_16',
          cohorts: [
            'Face, Body Pack (Lep) & Hair Pack (Therapy)',
            'Mud (Therapy)',
          ],
        },
        {
          code: 'treatment_17',
          cohorts: ['Hydro (Therapy)', 'Massage (Therapy)', 'Steam (Therapy)'],
        },
        {
          code: 'treatment_18',
          cohorts: ['Hydro (Therapy)', 'Mud (Therapy)'],
        },
        {
          code: 'treatment_19',
          cohorts: ['Mud (Therapy)'],
        },
      ],
      post_obs: [
        {
          code: 'post_1',
          cohorts: ['Elevated BP at Discharge'],
        },
        {
          code: 'post_2',
          cohorts: ['Normal BP at Discharge'],
        },
        {
          code: 'post_3',
          cohorts: ['High BP(stage 1) at Discharge'],
        },
        {
          code: 'post_4',
          cohorts: ['High BP(stage 2) at Discharge'],
        },
        {
          code: 'post_5',
          cohorts: ['Very High BP(crisis) at Discharge'],
        },
        {
          code: 'post_6',
          cohorts: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
        },
        {
          code: 'post_7',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
          ],
        },
        {
          code: 'post_8',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 2) at Discharge',
          ],
        },
        {
          code: 'post_9',
          cohorts: [
            'Elevated BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_10',
          cohorts: ['High BP(stage 1) at Discharge', 'Normal BP at Discharge'],
        },
        {
          code: 'post_11',
          cohorts: ['High BP(stage 2) at Discharge', 'Normal BP at Discharge'],
        },
        {
          code: 'post_12',
          cohorts: [
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_13',
          cohorts: [
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
          ],
        },
        {
          code: 'post_14',
          cohorts: [
            'High BP(stage 1) at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_15',
          cohorts: [
            'High BP(stage 2) at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_16',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'Normal BP at Discharge',
          ],
        },
        {
          code: 'post_17',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
          ],
        },
        {
          code: 'post_18',
          cohorts: [
            'Elevated BP at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_19',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
          ],
        },
        {
          code: 'post_20',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_21',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 2) at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_22',
          cohorts: [
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
          ],
        },
        {
          code: 'post_23',
          cohorts: [
            'High BP(stage 1) at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_24',
          cohorts: [
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_25',
          cohorts: [
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_26',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
          ],
        },
        {
          code: 'post_27',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_28',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_29',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_30',
          cohorts: [
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
        {
          code: 'post_31',
          cohorts: [
            'Elevated BP at Discharge',
            'High BP(stage 1) at Discharge',
            'High BP(stage 2) at Discharge',
            'Normal BP at Discharge',
            'Very High BP(crisis) at Discharge',
          ],
        },
      ],
    },
    pathway_groups: [
      {
        targetCohortId: 53,
        targetCohortCount: 55,
        totalPathwaysCount: 55,
        targetCohortName: 'IPD patients (5 to 6 day)',
        pathways: [
          {
            path: ['start_3', 'pre_3', 'post_13'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_1', 'post_10'],
            personCount: 10,
          },
          {
            path: ['start_3', 'pre_6', 'treatment_5', 'post_1'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_4', 'post_10'],
            personCount: 4,
          },
          {
            path: ['start_3', 'pre_13', 'treatment_5', 'post_3'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_1', 'post_10'],
            personCount: 1,
          },
          {
            path: ['start_3', 'pre_10', 'post_10'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_5', 'post_3'],
            personCount: 8,
          },
          {
            path: ['start_3', 'pre_10', 'treatment_5', 'post_3'],
            personCount: 3,
          },
          {
            path: ['start_3', 'pre_1', 'treatment_6', 'post_2'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_7', 'treatment_6', 'post_3'],
            personCount: 10,
          },
          {
            path: ['start_3', 'pre_13', 'treatment_5', 'post_4'],
            personCount: 7,
          },
          {
            path: ['start_3', 'pre_4', 'treatment_1', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_4', 'post_4'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_7', 'treatment_1', 'post_16'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_13', 'post_3'],
            personCount: 8,
          },
          {
            path: ['start_3', 'pre_13', 'treatment_1', 'post_6'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_7', 'post_16'],
            personCount: 10,
          },
          {
            path: ['start_3', 'pre_16', 'treatment_5', 'post_10'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_13', 'treatment_10', 'post_13'],
            personCount: 4,
          },
          {
            path: ['start_3', 'pre_1', 'treatment_16'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_1', 'post_3'],
            personCount: 8,
          },
          {
            path: ['start_3', 'pre_6', 'treatment_1', 'post_3'],
            personCount: 7,
          },
          {
            path: ['start_3', 'pre_6', 'treatment_5', 'post_4'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_4', 'treatment_7', 'post_3'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_15', 'post_16'],
            personCount: 10,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_1', 'post_2'],
            personCount: 3,
          },
          {
            path: ['start_3', 'pre_6', 'post_7'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_6', 'treatment_17', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_2', 'treatment_6', 'post_8'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_1', 'post_6'],
            personCount: 7,
          },
          {
            path: ['start_3', 'pre_13', 'treatment_2', 'post_3'],
            personCount: 8,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_5', 'post_1'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_5', 'post_13'],
            personCount: 10,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_5', 'post_10'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_4', 'treatment_9', 'post_4'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_3', 'treatment_5', 'post_19'],
            personCount: 4,
          },
          {
            path: ['start_3', 'pre_2', 'post_16'],
            personCount: 8,
          },
          {
            path: ['start_3', 'pre_2', 'post_10'],
            personCount: 7,
          },
          {
            path: ['start_3', 'pre_1', 'treatment_1', 'post_2'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_13', 'treatment_1', 'post_10'],
            personCount: 8,
          },
          {
            path: ['start_3', 'pre_1', 'post_2'],
            personCount: 9,
          },
          {
            path: ['start_3', 'pre_17', 'post_2'],
            personCount: 10,
          },
          {
            path: ['start_3', 'pre_11', 'treatment_5', 'post_7'],
            personCount: 7,
          },
          {
            path: ['start_3', 'treatment_12', 'post_7'],
            personCount: 6,
          },
          {
            path: ['start_3', 'pre_4', 'treatment_1', 'post_3'],
            personCount: 5,
          },
          {
            path: ['start_3', 'pre_2', 'treatment_3', 'post_1'],
            personCount: 4,
          },
          {
            path: ['start_3', 'pre_7', 'treatment_2', 'post_16'],
            personCount: 7,
          },
          {
            path: ['start_3', 'pre_16', 'treatment_1', 'post_2'],
            personCount: 5,
          },
        ],
      },
      {
        targetCohortId: 51,
        targetCohortCount: 76,
        totalPathwaysCount: 75,
        targetCohortName: 'IPD patients (3 to 4 day)',
        pathways: [
          {
            path: ['start_1', 'pre_7', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_5', 'post_10'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_6', 'post_13'],
            personCount: 4,
          },
          {
            path: ['start_1', 'pre_22', 'treatment_5', 'post_7'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_6', 'treatment_1', 'post_10'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_5', 'post_3'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_3', 'post_2'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_3', 'post_10'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_5', 'post_3'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_6', 'treatment_2', 'post_2'],
            personCount: 15,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_6', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_2', 'post_13'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_5', 'post_3'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_2', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_1', 'post_16'],
            personCount: 11,
          },
          {
            path: ['start_1', 'pre_22', 'post_3'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_2', 'treatment_3', 'post_16'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_1', 'post_1'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_13', 'post_3'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_4', 'treatment_5', 'post_13'],
            personCount: 4,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_13', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_13', 'post_13'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_19', 'treatment_6', 'post_1'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_2', 'post_1'],
            personCount: 12,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_6', 'post_3'],
            personCount: 11,
          },
          {
            path: ['start_1', 'pre_4', 'treatment_6', 'post_7'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_19', 'treatment_13', 'post_2'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_5', 'post_2'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_3', 'post_3'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_5', 'post_3'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_3', 'post_7'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_5', 'post_2'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_3', 'post_2'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_11', 'post_16'],
            personCount: 11,
          },
          {
            path: ['start_1', 'pre_4', 'post_10'],
            personCount: 12,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_3', 'post_6'],
            personCount: 13,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_17', 'post_2'],
            personCount: 14,
          },
          {
            path: ['start_1', 'pre_7', 'post_19'],
            personCount: 9,
          },
          {
            path: ['start_1', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_2', 'post_7'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_5', 'post_1'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_13', 'treatment_5', 'post_13'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_5', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_22', 'treatment_1', 'post_16'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_4', 'post_2'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_6', 'treatment_1', 'post_2'],
            personCount: 11,
          },
          {
            path: ['start_1', 'pre_7', 'treatment_1', 'post_2'],
            personCount: 12,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_5', 'post_13'],
            personCount: 7,
          },
          {
            path: ['start_1', 'pre_16', 'treatment_5', 'post_6'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_4', 'treatment_5', 'post_3'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_16', 'treatment_5', 'post_2'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_2', 'post_10'],
            personCount: 5,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_1', 'post_2'],
            personCount: 6,
          },
          {
            path: ['start_1', 'pre_1', 'post_6'],
            personCount: 8,
          },
          {
            path: ['start_1', 'pre_15', 'treatment_5', 'post_3'],
            personCount: 9,
          },
          {
            path: ['start_1', 'pre_1', 'post_2'],
            personCount: 4,
          },
          {
            path: ['start_1', 'pre_7', 'post_3'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_13', 'post_4'],
            personCount: 11,
          },
          {
            path: ['start_1', 'pre_1', 'treatment_16', 'post_2'],
            personCount: 12,
          },
          {
            path: ['start_1', 'pre_3', 'post_6'],
            personCount: 13,
          },
          {
            path: ['start_1', 'pre_4', 'treatment_6', 'post_13'],
            personCount: 15,
          },
          {
            path: ['start_1', 'pre_3', 'treatment_6', 'post_4'],
            personCount: 10,
          },
          {
            path: ['start_1', 'pre_13', 'post_1'],
            personCount: 11,
          },
        ],
      },
      {
        targetCohortId: 52,
        targetCohortCount: 37,
        totalPathwaysCount: 37,
        targetCohortName: 'IPD patients (7+ day)',
        pathways: [
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_4'],
            personCount: 9,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_16'],
            personCount: 8,
          },
          {
            path: ['start_2', 'pre_3', 'post_3'],
            personCount: 7,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_10'],
            personCount: 6,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_6', 'post_13'],
            personCount: 5,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_2'],
            personCount: 4,
          },
          {
            path: ['start_2', 'pre_1', 'treatment_5', 'post_2'],
            personCount: 10,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_5', 'post_3'],
            personCount: 15,
          },
          {
            path: ['start_2', 'pre_7', 'treatment_7', 'post_1'],
            personCount: 14,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_5', 'post_3'],
            personCount: 13,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_1', 'post_13'],
            personCount: 12,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_22'],
            personCount: 11,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_1', 'post_3'],
            personCount: 9,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_6'],
            personCount: 8,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_6', 'post_4'],
            personCount: 7,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_5', 'post_1'],
            personCount: 6,
          },
          {
            path: ['start_2', 'pre_7', 'treatment_5', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_2', 'pre_1', 'treatment_1', 'post_6'],
            personCount: 4,
          },
          {
            path: ['start_2', 'pre_7', 'treatment_1', 'post_10'],
            personCount: 4,
          },
          {
            path: ['start_2', 'pre_6', 'treatment_1', 'post_2'],
            personCount: 8,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_1', 'post_4'],
            personCount: 7,
          },
          {
            path: ['start_2', 'pre_7', 'treatment_1', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_2', 'pre_4', 'treatment_5', 'post_13'],
            personCount: 10,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_1', 'post_1'],
            personCount: 8,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_1', 'post_10'],
            personCount: 9,
          },
          {
            path: ['start_2', 'pre_1', 'treatment_1', 'post_2'],
            personCount: 5,
          },
          {
            path: ['start_2', 'pre_4', 'treatment_6', 'post_4'],
            personCount: 7,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_18', 'post_4'],
            personCount: 5,
          },
          {
            path: ['start_2', 'pre_16', 'treatment_6', 'post_2'],
            personCount: 7,
          },
          {
            path: ['start_2', 'pre_16', 'treatment_1', 'post_1'],
            personCount: 8,
          },
          {
            path: ['start_2', 'pre_13', 'treatment_6', 'post_6'],
            personCount: 10,
          },
          {
            path: ['start_2', 'pre_3', 'treatment_1', 'post_3'],
            personCount: 11,
          },
          {
            path: ['start_2', 'pre_2', 'treatment_1', 'post_6'],
            personCount: 12,
          },
        ],
      },
    ],
  },
}
export const dietTimeData: DietTiming[] = [
  {
    dietTime: 'Early Morning',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
  {
    dietTime: 'Breakfast',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
  {
    dietTime: 'Mid Morning',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
  {
    dietTime: 'Lunch',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
  {
    dietTime: 'Evening Snack',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
  {
    dietTime: 'Dinner',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
  {
    dietTime: 'Bed Time',
    time: undefined,
    active: false,
    displayLabel: '',
    timeOfTheDay: '',
    isEdit: true,
  },
]

export const earlyMorning: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.early',
    display: '05:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.early',
    display: '06:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.early',
    display: '06:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.early',
    display: '06:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.early',
    display: '06:45',
  },
]

export const breakFast: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '07:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '07:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '07:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '07:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '08:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '08:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '08:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '08:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN',
    display: '09:00',
  },
]

export const midMorning: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '09:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '09:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '09:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '10:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '10:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '10:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '10:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '11:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '11:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '11:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'MORN.late',
    display: '11:45',
  },
]

export const lunch: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '12:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '12:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '12:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '12:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '01:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '01:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '01:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '01:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '02:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '02:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '02:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '02:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NOON',
    display: '03:00',
  },
]

export const snack: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '03:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '03:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '03:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '04:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '04:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '04:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '04:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '05:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '05:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '05:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'EVE',
    display: '05:45',
  },
]

export const dinner: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '06:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '06:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '06:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '06:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '07:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '07:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '07:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '07:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'NIGHT',
    display: '08:00',
  },
]

export const bedTime: R4.ICoding[] = [
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '08:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '08:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '08:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '09:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '09:15',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '09:30',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '09:45',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '10:00',
  },
  {
    system: 'http://hl7.org/fhir/event-timing',
    code: 'PCV',
    display: '10:15',
  },
]

export const GenderList: R4.ICoding[] = [
  {
    id: 'female',
    display: 'Female',
    system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
  },
  {
    id: 'male',
    display: 'Male',
    system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
  },

  {
    id: 'other',
    display: 'Other',
    system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
  },
  {
    id: 'unknown',
    display: 'Unknown',
    system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
  },
]

export const latestSankeyDataForETL = {
  generation: {
    id: 773,
    status: 'COMPLETED',
    sourceKey: 'dev_xorg',
    hashCode: -705679486,
    startTime: 1711556318345,
    endTime: 1711556666850,
    exitMessage: '',
  },
  results: {
    stages: {
      target: [
        {
          code: 'tgt_15',
          cohorts: ['Diabetic'],
        },
        {
          code: 'tgt_14',
          cohorts: ['Prediabetic'],
        },
      ],
      intervention: [
        {
          code: 'int_12',
          cohorts: ['Kriyas'],
        },
        {
          code: 'int_11',
          cohorts: ['Avshada'],
        },
        {
          code: 'int_11_13',
          cohorts: ['Avshada', 'Dietery regime'],
        },
        {
          code: 'int_11_12_13',
          cohorts: ['Avshada', 'Kriyas', 'Dietery regime'],
        },
        {
          code: 'int_13',
          cohorts: ['Dietery regime'],
        },
      ],
      outcome: [
        {
          code: 'out_16',
          cohorts: ['HbA1c above normal - 90day outcome'],
        },
        {
          code: 'out_17',
          cohorts: ['HbA1c normal - 90day outcome'],
        },
        {
          code: 'out_18',
          cohorts: ['HbA1c very high - 90day outcome'],
        },
      ],
    },
    pathway_groups: [
      {
        targetCohortId: 15,
        targetCohortCount: 29,
        totalPathwaysCount: 28,
        targetCohortName: 'Diabetic',
        pathways: [
          {
            path: ['tgt_15', 'int_11', 'out_18'],
            personCount: 7,
          },
          {
            path: ['tgt_15', 'int_13'],
            personCount: 1,
          },
          {
            path: ['tgt_15', 'int_12'],
            personCount: 2,
          },
          {
            path: ['tgt_15', 'int_11', 'out_17'],
            personCount: 5,
          },
          {
            path: ['tgt_15', 'int_13', 'out_17'],
            personCount: 4,
          },
          {
            path: ['tgt_15', 'int_11', 'out_16'],
            personCount: 4,
          },
          {
            path: ['tgt_15', 'int_13', 'out_16'],
            personCount: 5,
          },
        ],
      },
      {
        targetCohortId: 14,
        targetCohortCount: 69,
        totalPathwaysCount: 67,
        targetCohortName: 'Prediabetic',
        pathways: [
          {
            path: ['tgt_14', 'int_11', 'out_18'],
            personCount: 4,
          },
          {
            path: ['tgt_14', 'int_11_13', 'out_16'],
            personCount: 7,
          },
          {
            path: ['tgt_14', 'int_11_12_13', 'out_17'],
            personCount: 1,
          },
          {
            path: ['tgt_14', 'int_13', 'out_18'],
            personCount: 2,
          },
          {
            path: ['tgt_14', 'int_11_13', 'out_18'],
            personCount: 6,
          },
          {
            path: ['tgt_14', 'int_11_12_13', 'out_16'],
            personCount: 3,
          },
          {
            path: ['tgt_14', 'int_11_12_13', 'out_18'],
            personCount: 4,
          },
          {
            path: ['tgt_14', 'int_11', 'out_17'],
            personCount: 11,
          },
          {
            path: ['tgt_14', 'int_13', 'out_17'],
            personCount: 5,
          },
          {
            path: ['tgt_14', 'int_11_13', 'out_17'],
            personCount: 5,
          },
          {
            path: ['tgt_14', 'int_13'],
            personCount: 1,
          },
          {
            path: ['tgt_14', 'int_12'],
            personCount: 3,
          },
          {
            path: ['tgt_14', 'int_11', 'out_16'],
            personCount: 7,
          },
          {
            path: ['tgt_14', 'out_18'],
            personCount: 1,
          },
          {
            path: ['tgt_14', 'int_13', 'out_16'],
            personCount: 7,
          },
        ],
      },
    ],
  },
}

export const examMethods: R4.ICoding[] = [
  {
    code: '129433002',
    system: 'http://snomed.info/sct',
    display: 'Inspection',
  },
  {
    code: '113011001',
    system: 'http://snomed.info/sct',
    display: 'Palpation',
  },
  {
    code: '37931006',
    system: 'http://snomed.info/sct',
    display: 'Auscultation',
  },
  {
    code: '75180006',
    system: 'http://snomed.info/sct',
    display: 'Percussion',
  },
]

export const pre = {
  generation: {
    id: 924,
    status: 'COMPLETED',
    sourceKey: 'dev_vendortest100',
    hashCode: -1991567969,
    startTime: 1712233240147,
    endTime: 1712233640321,
    exitMessage: '',
  },
  results: {
    stages: {
      target: [
        {
          code: 'tgt_14',
          cohorts: ['Prediabetic'],
        },
      ],
      intervention: [
        {
          code: 'int_34_36',
          cohorts: ['Avshada', 'Dietery regime'],
        },
        {
          code: 'int_34',
          cohorts: ['Avshada'],
        },
        {
          code: 'int_35',
          cohorts: ['Kriyas'],
        },
        {
          code: 'int_34_35_36',
          cohorts: ['Avshada', 'Kriyas', 'Dietery regime'],
        },
        {
          code: 'int_36',
          cohorts: ['Dietery regime'],
        },
      ],
      outcome: [
        {
          code: 'out_41',
          cohorts: ['HbA1c normal - 14day outcome'],
        },
        {
          code: 'out_40',
          cohorts: ['HbA1c very high - 14day outcome'],
        },
        {
          code: 'out_42',
          cohorts: ['HbA1c above normal - 14day outcome'],
        },
      ],
    },
    pathway_groups: [
      {
        targetCohortId: 14,
        targetCohortCount: 146,
        totalPathwaysCount: 144,
        targetCohortName: 'Prediabetic',
        pathways: [
          {
            path: ['tgt_14', 'int_34'],
            personCount: 28,
          },
          {
            path: ['tgt_14', 'int_36'],
            personCount: 32,
          },
          {
            path: ['tgt_14', 'int_34_36'],
            personCount: 63,
          },
          {
            path: ['tgt_14', 'int_35'],
            personCount: 1,
          },
          {
            path: ['tgt_14', 'int_34_35_36'],
            personCount: 20,
          },
        ],
      },
    ],
  },
}

export const carePlanCodings: R4.ICoding[] = [
  {
    code: 'T2DM-AP',
    display: 'Diabetes Care Plan - Allopathy',
  },
  {
    code: 'T2DM-AV',
    display: 'Diabetes Care Plan - Integrative Ayurveda',
  },
  {
    code: 'HTN-AP',
    display: 'Hypertension Care Plan - Allopathy',
  },
  {
    code: 'HTN-AV',
    display: 'Hypertension Care Plan - Integrative Ayurveda',
  },
  {
    code: 'OBS-AV',
    display: 'Obesity Care Plan - Integrative Ayurveda',
  },
]
export const statusCodings: R4.ICoding[] = [
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0184511',
    display: 'Improved',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0442739',
    display: 'Unchanged',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C3714811',
    display: 'Resolved',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0034897',
    display: 'Recurred',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0231241',
    display: 'Fluctuating',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0205360',
    display: 'Stable',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C1444749',
    display: 'Aggravated',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0392756',
    display: 'Reduced',
  },
]

export const medicationCancellationReason: R4.ICoding[] = [
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0877248',
    display: 'Adverse Event',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0420215',
    display: 'Side Effects',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0686905',
    display: 'Medicine Unavailable',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0420217',
    display: 'Patient Unwilling to Continue',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0444794',
    display: 'Prescribing Physician Recommended to Stop',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C2936279',
    display: 'Prescribing Physician Substituted the Medicine',
  },
  //   {
  //     system: 'http://terminology.hl7.org/CodeSystem/umls',
  //     code: 'C2936279',
  //     display: 'Paused by Prescribing Physician Until Further Notice',
  //   },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0544452',
    display: 'Condition in Remission',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0420219',
    display: 'Medical Aim Achieved',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/umls',
    code: 'C0420214',
    display: 'Treatment Ineffective',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
