import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { DietDataModel } from 'models/dietData'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
// import { updateCatalog } from 'redux/valueSet/nutrition_catalogue/addDietCatalogueSlice';
// import { updateCatalog } from 'redux/valueSet/therapyList/addTherapyCatalougeSlice';
import { updateCatalog } from 'redux/valueSet/nutrition_catalogue/addDietCatalogueSlice'
import { isCurrentUnitIsWellonessCenter } from 'services/userDetailsService'
import { dietDuration, dietTypeForDietItem } from 'utils/constants'
import { getDietTimeList } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getDietBundleUpdate } from 'utils/labHelpers/uploadHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { WelloTextField } from 'wello-web-components'

export interface DeleteDietProps {
  open: boolean
  onCancelClick: () => void
}

export interface EditDietProps {
  open: boolean
  onCancelClick: () => void
  selectedValue: DietDataModel
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export function EditDiet(props: EditDietProps) {
  const { open, selectedValue, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const dispatch = useDispatch()

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )
  console.log(selectedValue.allDuration)

  const [dietData, setDietData] = React.useState<DietDataModel>({
    name: selectedValue.name,
    duration: selectedValue.duration,
    allDuration: selectedValue.allDuration,
    catalogue: selectedValue.catalogue,
    timeData: '',
    durationCodes: selectedValue.durationCodes,
    type: selectedValue.type,
  })

  function handleNameChange(nameDiet: string) {
    setDietData({ ...dietData, name: nameDiet })
  }

  function handleTimeChange(timeDiet: R4.ICoding[]) {
    const allTimings: Array<string> = []
    const allCoding: R4.ICoding[] = []

    const finalTime: string[] = []
    timeDiet.map((time) => {
      allTimings.push(time.code ?? '')
    })
    console.log(allTimings)
    console.log(dietData.allDuration)
    console.log(dietData.durationCodes)
    if (
      !allTimings.includes('Any') &&
      dietData.allDuration &&
      dietData.allDuration.includes('Any')
    ) {
      if (
        allTimings.length ===
        getDietTimeList(dietTimeSearchSlice.prSelectedCoding ?? []).length - 1
      ) {
        getDietTimeList(dietTimeSearchSlice.prSelectedCoding ?? []).map(
          (time) => {
            if (time.code !== 'Any') {
              finalTime.push(time.code ?? '')
              allCoding.push(time)
            }
          }
        )

        setDietData({
          ...dietData,
          allDuration: [],
          durationCodes: [],
        })
      }
    } else if (
      allTimings.includes('Any') &&
      dietData.allDuration &&
      !dietData.allDuration.includes('Any')
    ) {
      getDietTimeList(dietTimeSearchSlice.prSelectedCoding ?? []).map(
        (time) => {
          finalTime.push(time.code ?? '')
          allCoding.push(time)
        }
      )
      allTimings.map((e) => {})
      setDietData({
        ...dietData,
        allDuration: finalTime,
        durationCodes: allCoding,
      })
    } else if (
      dietData.allDuration &&
      dietData.allDuration.includes('Any') &&
      !allTimings.includes('Any')
    ) {
      setDietData({
        ...dietData,
        allDuration: [],
        durationCodes: [],
      })
    } else {
      const timeData: string[] = []
      const finalCoding: R4.ICoding[] = []
      setDietData({
        ...dietData,
        allDuration: allTimings.filter((item) => item !== 'Any'),
        durationCodes: timeDiet.filter(
          (item) => item.code && item.code !== 'Any'
        ),
      })
    }
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (
      dietData.name?.length === 0 ||
      !dietData.name?.trim() ||
      dietData.name?.length > 100
    ) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }
    if (dietData.allDuration === undefined) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }

    if (!hasErrorsInData) {
      const bundleData = getDietBundleUpdate(dietData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Diet'))
      }
    }
  }

  function validateDietEdit(): number {
    const errorLog: string[] = []
    if (dietData.name === undefined) {
      errorLog.push('1')
    }
    if (dietData.name && dietData.name.length > 100) {
      errorLog.push('1')
    }
    if (dietData.allDuration === undefined) {
      errorLog.push('1')
    }
    if (dietData.allDuration && dietData.allDuration.length === 0) {
      errorLog.push('1')
    }
    if (
      isCurrentUnitIsWellonessCenter() &&
      sessionStorage.getItem('app') === null
    ) {
      if (dietData.type === undefined) {
        errorLog.push('1')
      }
    }

    return errorLog.length
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Update Diet')}
      </DialogTitle>
      <DialogContent>
        <Box marginX={2} marginY={1}>
          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <WelloTextField
              title='Diet Item Name'
              textProps={{
                defaultValue: selectedValue?.name,
                type: 'text',
                placeholder: 'Diet',
                inputProps: {
                  maxLength: 110,
                },
                error:
                  dietData.name?.length === 0 ||
                  !dietData.name?.trim() ||
                  dietData.name?.length > 100,
                helperText:
                  dietData.name?.length === 0 ||
                  !dietData.name?.trim() ||
                  dietData.name?.length > 100
                    ? 'Diet Name is required and characters between 1 and 100'
                    : '',
                onChange: (changeName) => {
                  handleNameChange(changeName.target.value)
                },
              }}
            />
          </Box>
          {isCurrentUnitIsWellonessCenter() &&
            sessionStorage.getItem('app') === null && (
              <Box display='flex' flexDirection='column' width='100%'>
                {dietData.type && (
                  <WelloSelectFHIRCodingWithAutocomplete
                    title='Diet Type'
                    id='diet_type'
                    availableCodings={dietTypeForDietItem}
                    onChanged={(type) => {
                      setDietData({
                        ...dietData,
                        type,
                      })
                      //   setDietType(type)
                    }}
                    preSelectedCoding={dietData.type}
                  />
                )}
                {dietData.type === undefined && (
                  <WelloSelectFHIRCodingWithAutocomplete
                    title='Diet Type'
                    id='diet_type'
                    availableCodings={dietTypeForDietItem}
                    onChanged={(type) => {
                      setDietData({
                        ...dietData,
                        type,
                      })
                    }}
                    preSelectedCoding={dietData.type}
                  />
                )}
              </Box>
            )}

          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <Box display='flex' flexDirection='row'>
              <Box py={1} px={0.25} display='flex' flexDirection='row'>
                <Typography variant='subtitle2'>Time of Day</Typography>
              </Box>
            </Box>
            {getDietTimeList(dietTimeSearchSlice.prSelectedCoding ?? [])
              .length === 0 && (
              <Box width='100%'>
                <Typography variant='subtitle2' color='primary'>
                  Please set the diet timing
                </Typography>
              </Box>
            )}
            <Box width='100%'>
              {getDietTimeList(dietTimeSearchSlice.prSelectedCoding ?? [])
                .length > 0 && (
                <Autocomplete
                  id='diet-duration'
                  multiple={true}
                  onOpen={() => {}}
                  onClose={() => {
                    //   dispatch(resetState())
                  }}
                  style={{
                    borderRadius: '4px',
                  }}
                  disableClearable
                  fullWidth
                  size='small'
                  getOptionSelected={(option, value) =>
                    option.display === value.display
                  }
                  disabled={false}
                  value={dietData.durationCodes}
                  getOptionLabel={(option) => option.display || ''}
                  options={getDietTimeList(
                    dietTimeSearchSlice.prSelectedCoding ?? []
                  )}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  onChange={(e, changedValue, reason) => {
                    handleTimeChange(changedValue)
                  }}
                  ChipProps={{
                    deleteIcon: (
                      <ClearOutlined
                        style={{
                          height: '15px',
                          color: 'white',
                        }}
                      />
                    ),
                    style: {
                      backgroundColor: kPrimaryLight,
                      borderRadius: '4px',
                    },
                  }}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.display ?? '', inputValue)
                    const parts = parse(option.display ?? '', matches)
                    return (
                      <ListItem id='diet-id'>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={part.text}
                              style={{
                                backgroundColor: part.highlight
                                  ? kPrimaryLight
                                  : undefined,
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </ListItem>
                    )
                  }}
                  filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Time of day'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                      //   InputProps={{
                      //   ...params.InputProps,
                      //     style: { height: 36}
                      //   }}
                      //   style={{ height: 10 }}
                      variant='outlined'
                    />
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            disabled={validateDietEdit() > 0}
            onClick={() => {
              handleSubmit()
            }}
            id='Diet Update'
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export function DeleteDiet(props: DeleteDietProps) {
  const { open, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Delete Diet')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <Typography>Are you sure you want to delete the Diet?</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button variant='contained' disableElevation>
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
