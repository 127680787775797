import { IReference } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Icon } from '@iconify/react'
import { Box, Typography } from '@material-ui/core'
import Skeleton from '@yisheng90/react-loading'
import {
  FhirAppointmentWithPatient,
  FhirIPDwithPatientDetails,
} from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getTherapiesDetails,
  getVisitDetailsForTherapistFHIR,
  getVisitDetailsForTherapistFHIROPD,
} from 'utils/fhirResoureHelpers/therapiesHelper'
import { CancelledTherapyDetailsSheet } from 'views/components/ipdconusltation/treatment/cancelledTherapyDetailsSheet'
import { CompletedTherapyDetailsSheet } from 'views/components/ipdconusltation/treatment/completedTherapyDetailsSheet'
import { ScheduledTherapyDetailsSheet } from 'views/components/ipdconusltation/treatment/scheduledTherapyDetailsSheet'
import { TherapyCancelCaptureMobile } from 'views/components/ipdconusltation/treatment/therapyCancelCaptureMobile'
import { TherapyPerformCaptureMobile } from 'views/components/ipdconusltation/treatment/therapyPerformCaptureMobile'

interface Props {
  ipdAppointmentDetails: FhirClinicTherapyBasic
  id: string
  type: string
}

export const TherapyDisplayTileForMobile: React.FC<Props> = ({
  ipdAppointmentDetails,

  id,
  type,
}: Props) => {
  const [visitDetails, setVisitDetails] = useState<FhirIPDwithPatientDetails>()
  const [visitDetailsOPD, setVisitDetailsOPD] =
    useState<FhirAppointmentWithPatient>()
  const [showTherapyPerformDialog, setShowTherapyPerformDialog] =
    useState<boolean>(false)

  const [showTherapyDetailsSheet, setShowTherapyDetailsSheet] =
    useState<boolean>(false)
  const [showCompletedTherapyDetails, setShowCompletedTherapyDetails] =
    useState<boolean>(false)
  const [showCancelledTherapyDetails, setShowCancelledTherapyDetails] =
    useState<boolean>(false)
  const [showCancelTherapyDetails, setShowCancelTherapyDetails] =
    useState<boolean>(false)
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })
  const [fetchingTherapyDetails, setFetchingTherapyDetails] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })

  const [plan, setPlan] = useState<TreatmentPlanData>()

  function fetchAppointmentDetails() {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setFetchingStatus({
        requesting: true,
      })

    getVisitDetailsForTherapistFHIR(ipdAppointmentDetails.mainServiceRequestId)
      .then((e) => {
        if (e) {
          if ('mainServiceRequest' in e) {
            setVisitDetails(e as FhirIPDwithPatientDetails)

            setFetchingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  function fetchTherapyDetails() {
    if (ipdAppointmentDetails.therapy.id)
      setFetchingTherapyDetails({
        requesting: true,
      })

    getTherapiesDetails(ipdAppointmentDetails.therapy.id ?? '')
      .then((e) => {
        if (e) {
          if ('date' in e) {
            setPlan(e)

            setFetchingTherapyDetails({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingTherapyDetails({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingTherapyDetails({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingTherapyDetails({
          requesting: false,
          requestError: true,
        })
      })
  }

  function fetchOPDAppointmentDetails() {
    if (ipdAppointmentDetails.mainServiceRequestId)
      setFetchingStatus({
        requesting: true,
      })

    getVisitDetailsForTherapistFHIROPD(
      ipdAppointmentDetails.mainServiceRequestId
    )
      .then((e) => {
        if (e) {
          if ('appointment' in e) {
            setVisitDetailsOPD(e as FhirAppointmentWithPatient)

            setFetchingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          }
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    if (type !== 'appointment') {
      fetchAppointmentDetails()
    } else {
      fetchOPDAppointmentDetails()
    }
  }, [])

  useEffect(() => {
    fetchTherapyDetails()
  }, [])

  if (
    fetchingTherapyDetails.requesting === true ||
    fetchingStatus.requesting === true
  ) {
    return (
      <Box width='100%' id={`therapistOrder${id}`}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignContent='flex-start'
          width='100%'
          px={1}
        >
          <Skeleton height='3rem' />
        </Box>
      </Box>
    )
  }

  if ((visitDetails || visitDetailsOPD) && fetchingStatus.requestSuccess) {
    const patient =
      visitDetails !== undefined
        ? visitDetails.patient
        : visitDetailsOPD!.patient

    const visitReferance: IReference =
      visitDetails !== undefined
        ? {
            reference: `${visitDetails.mainServiceRequest.resourceType}/${visitDetails.mainServiceRequest.id}`,
          }
        : {
            reference: `${visitDetailsOPD!.appointment.resourceType}/${
              visitDetailsOPD!.appointment.id
            }`,
          }
    return (
      <Box
        id={`therapistOrder${id}`}
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        alignContent='center'
        style={{
          border: '1px solid #E6E6E6',
          borderRadius: 14,
          padding: '8px 16px',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          width='85%'
          id={`therapy_time_details-${id}`}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            alignContent='left'
          >
            <Typography
              variant='subtitle1'
              style={{
                textTransform: 'capitalize',
                alignSelf: 'stretch',
                fontSize: '16px',
                fontWeight: 400,
                fontFeatureSettings: `'dlig' on`,
                fontVariantNumeric: 'lining-nums proportional-nums',
                color: '#19274F',
                lineHeight: '24px',
              }}
            >
              {getNameOfPatient(patient)} {getMiddleNameOfPatient(patient)}{' '}
              {getLastNameOfPatient(patient)}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            alignContent='left'
          >
            <Typography
              variant='body2'
              style={{
                textTransform: 'capitalize',
                alignSelf: 'stretch',
                fontSize: '14px',
                fontWeight: 400,
                fontFeatureSettings: `'dlig' on`,
                fontVariantNumeric: 'lining-nums proportional-nums',
                color: '#8C9097',
                lineHeight: 'normal',
              }}
            >
              {`${moment(ipdAppointmentDetails.start).format('hh:mm A')} - 
              ${moment(ipdAppointmentDetails.end).format('hh:mm A')}     
              `}
            </Typography>
            <Box width='8px' />
            <Typography
              variant='body2'
              style={{
                textTransform: 'capitalize',
                alignSelf: 'stretch',
                fontSize: '14px',
                fontWeight: 400,
                fontFeatureSettings: `'dlig' on`,
                fontVariantNumeric: 'lining-nums proportional-nums',
                color: '#8C9097',
                lineHeight: 'normal',
              }}
            >
              {`  
              ${
                visitDetails !== undefined
                  ? getIdentifierValueBySystem(
                      visitDetails.mainServiceRequest.identifier ?? [],
                      'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                    ) ??
                    getIdentifierValueBySystem(
                      visitDetails.mainServiceRequest.identifier ?? [],
                      'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                    )
                  : ''
              }`}
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignContent='center'
          alignItems='center'
          justifyContent='end'
          width='15%'
        >
          {plan &&
            plan?.treatmentPlan &&
            plan.treatmentPlan.status === 'active' && (
              <Icon
                icon='charm:menu-meatball'
                style={{
                  color: '#1C274C',
                  fontSize: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowTherapyDetailsSheet(true)
                }}
                id={`complete_therapy-${id}`}
              />
            )}

          {plan &&
            plan?.treatmentPlan &&
            plan.treatmentPlan.status === 'completed' && (
              <Icon
                icon='uiw:information'
                style={{
                  color: '#1C274C',
                  fontSize: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowCompletedTherapyDetails(true)
                }}
                id={`complete_therapy-${id}`}
              />
            )}
          {plan &&
            plan?.treatmentPlan &&
            plan.treatmentPlan.status === 'revoked' && (
              <Icon
                icon='uiw:information'
                style={{
                  color: '#C00010',
                  fontSize: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowCancelledTherapyDetails(true)
                }}
                id={`complete_therapy-${id}`}
              />
            )}
        </Box>
        {plan && showTherapyDetailsSheet && (
          <ScheduledTherapyDetailsSheet
            open={showTherapyDetailsSheet}
            therapyBasicDetails={ipdAppointmentDetails}
            onCompleteClicked={() => {
              setShowTherapyDetailsSheet(false)
              setShowTherapyPerformDialog(true)
            }}
            onCancelTherapyClicked={() => {
              setShowTherapyDetailsSheet(false)
              setShowCancelTherapyDetails(true)
            }}
            onClose={() => {
              setShowTherapyDetailsSheet(false)
            }}
            patient={patient}
            treatmentPlanData={plan!}
            appointmentRefId={`  
              ${
                visitDetails !== undefined
                  ? getIdentifierValueBySystem(
                      visitDetails.mainServiceRequest.identifier ?? [],
                      'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                    ) ??
                    getIdentifierValueBySystem(
                      visitDetails.mainServiceRequest.identifier ?? [],
                      'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                    )
                  : ''
              }`}
          />
        )}

        {plan && showTherapyPerformDialog && (
          <TherapyPerformCaptureMobile
            open={showTherapyPerformDialog}
            onSuccess={() => {
              setShowTherapyPerformDialog(false)
              fetchTherapyDetails()
            }}
            onClose={() => {
              setShowTherapyPerformDialog(false)
            }}
            patient={patient}
            visitReferance={visitReferance}
            treatmentPlanData={plan!}
          />
        )}

        {showCancelTherapyDetails && plan && (
          <TherapyCancelCaptureMobile
            open={showCancelTherapyDetails}
            onClose={() => {
              setShowCancelTherapyDetails(false)
            }}
            onSuccess={() => {
              setShowCancelTherapyDetails(false)
              fetchTherapyDetails()
            }}
            treatmentPlanData={plan}
          />
        )}
        {plan && showCancelledTherapyDetails && (
          <CancelledTherapyDetailsSheet
            open={showCancelledTherapyDetails}
            therapyBasicDetails={ipdAppointmentDetails}
            onClose={() => {
              setShowCancelledTherapyDetails(false)
            }}
            patient={patient}
            appointmentRefId={`  
                ${
                  visitDetails !== undefined
                    ? getIdentifierValueBySystem(
                        visitDetails.mainServiceRequest.identifier ?? [],
                        'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                      ) ??
                      getIdentifierValueBySystem(
                        visitDetails.mainServiceRequest.identifier ?? [],
                        'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                      )
                    : ''
                }`}
            treatmentPlanData={plan!}
          />
        )}

        {plan && showCompletedTherapyDetails && (
          <CompletedTherapyDetailsSheet
            open={showCompletedTherapyDetails}
            therapyBasicDetails={ipdAppointmentDetails}
            onClose={() => {
              setShowCompletedTherapyDetails(false)
            }}
            patient={patient}
            treatmentPlanData={plan!}
            appointmentRefId={`  
                ${
                  visitDetails !== undefined
                    ? getIdentifierValueBySystem(
                        visitDetails.mainServiceRequest.identifier ?? [],
                        'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                      ) ??
                      getIdentifierValueBySystem(
                        visitDetails.mainServiceRequest.identifier ?? [],
                        'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                      )
                    : ''
                }`}
          />
        )}
      </Box>
    )
  }

  return <>All data not there</>
}
