import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import AodIcon from '@mui/icons-material/Aod'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getObservationContent } from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getBottom4HabitList,
  getTop4HabitListForTile,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { hasAddedObsByPatient } from 'utils/fhirResoureHelpers/allergyHelpers'

interface MedicationTileProps {
  topObs: R4.IObservation[]
  id: string
}

export const AddictionTile: React.FC<MedicationTileProps> = ({
  topObs,
  id,
}: MedicationTileProps) => {
  const firstColumn: R4.IObservation[] = topObs.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '68518-0'
      )
  )

  const secColumn: R4.IObservation[] = topObs.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '96103-7'
      )
  )
  const thirdColumn: R4.IObservation[] = topObs.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '10000-3'
      )
  )

  const fourthColumn: R4.IObservation[] = topObs.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '64218-1'
      )
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
      id={`${id}Column`}
    >
      <Grid container direction='column' spacing={1} id={`${id}ColumnGrid`}>
        <Grid
          container
          direction='row'
          id='vitals'
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <Grid item xs={3} id={`data${id}`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Alcohol
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`datas1${id}`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Tobacco/Paan/Gutkha Per Day
                  </Typography>
                </Box>

                {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Drugs
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Smoking
                  </Typography>
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',
  
                      width: '2px',
                    }}
                  />
                </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}

        <Grid
          container
          direction='row'
          id='vitals'
          style={{
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {firstColumn.length > 0 && (
                    <Box display='flex' flexDirection='row' width='100%'>
                      {hasAddedObsByPatient(firstColumn[0]) && (
                        <Box paddingRight={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                            >
                              <AodIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box paddingTop={0.5}>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                          id='add_1'
                        >
                          {getObservationContent(firstColumn[0])}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.1}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {secColumn.length > 0 && (
                    <Box display='flex' flexDirection='row' width='100%'>
                      {hasAddedObsByPatient(secColumn[0]) && (
                        <Box paddingRight={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                            >
                              <AodIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box paddingTop={0.5}>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                          id='add_2'
                        >
                          {getObservationContent(secColumn[0])}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
              </Box>
              <Box display='flex' width='2%' paddingTop={0.1}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {thirdColumn.length > 0 && (
                    <Box display='flex' flexDirection='row' width='100%'>
                      {hasAddedObsByPatient(thirdColumn[0]) && (
                        <Box paddingRight={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                            >
                              <AodIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box paddingTop={0.5}>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                          id='add_4'
                        >
                          {getObservationContent(thirdColumn[0])}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.1}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {fourthColumn.length > 0 && (
                    <Box display='flex' flexDirection='row' width='100%'>
                      {hasAddedObsByPatient(fourthColumn[0]) && (
                        <Box paddingRight={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                            >
                              <AodIcon color='primary' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box paddingTop={0.5}>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                          id='add_5'
                        >
                          {getObservationContent(fourthColumn[0])}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
