import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import moment from 'moment'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestForClinicalImpressions } from 'redux/fhirMedicalResources/clinicalImpression/clinicalImpressionSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { requestForSubscriptionStatusOfPatient } from 'redux/subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { addAndGetEncounterDetailsOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import {
  getExpandedAppointmentFromBundle,
  getExpandedAppointmentFullDetailFromBundle,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'

import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'
import { AppointmentHandlerStatus } from './appointmentDetailsSearchStatus'

const initialState: AppointmentHandlerStatus = {
  fetchingAppointmentDetails: false,
  enableEndAppointmentButton: false,
  errorWhileUpdatingAppointment: false,
  appointmentFetched: false,
  appointmentDetailsUpdated: false,
  errorWhileFetchingAppointment: false,
  updatingAppointment: false,
  enableStartVideoButton: false,
  endingAppointment: false,
  endedAppointment: false,
}

const appointmentDetailsSlice = createSlice({
  name: 'appointmentDetailsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AppointmentHandlerStatus>) {
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingAppointment =
        action.payload.errorWhileUpdatingAppointment
      state.fetchingAppointmentDetails =
        action.payload.fetchingAppointmentDetails
      state.appointmentDetails = action.payload.appointmentDetails
      state.appointmentFetched = action.payload.appointmentFetched
      state.enableEndAppointmentButton =
        action.payload.enableEndAppointmentButton
      state.appointmentId = action.payload.appointmentId
      state.endedAppointment = action.payload.endedAppointment
      state.endingAppointment = action.payload.endingAppointment
    },
    updatedDetails(state, action: PayloadAction<FhirAppointmentDetail>) {
      state.appointmentDetails = action.payload
    },

    resetState(state, action: PayloadAction<AppointmentHandlerStatus>) {
      state.errorReason = undefined
      state.errorWhileUpdatingAppointment = false
      state.fetchingAppointmentDetails = false
      state.appointmentDetails = undefined
      state.appointmentFetched = false
      state.enableEndAppointmentButton = false
      state.appointmentId = undefined
      state.endedAppointment = false
      state.endingAppointment = false
    },
  },
})

export const resetAppointmentHandlerForSplit =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(appointmentDetailsSlice.actions.resetState(initialState))
  }

export const fetchAppointmentDetailsForSplit =
  (appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentHandlerStatus = {
      fetchingAppointmentDetails: true,
      errorWhileUpdatingAppointment: false,
      appointmentFetched: false,
      appointmentDetailsUpdated: false,
      errorWhileFetchingAppointment: false,
      updatingAppointment: false,
      enableEndAppointmentButton: false,
      endingAppointment: false,
      endedAppointment: false,
      enableStartVideoButton: false,
    }
    dispatch(appointmentDetailsSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _id: appointmentId,
        _revinclude: 'Encounter:appointment',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Appointment',
        appointmentId,
        searchParameters
      )

      if (response?.total && response?.total > 0) {
        const fhirAppointments: FhirAppointmentFullDetail[] =
          getExpandedAppointmentFullDetailFromBundle(response)
        const appointmentDetail: FhirAppointmentFullDetail = fhirAppointments[0]

        if (
          appointmentDetail.encounter === undefined ||
          appointmentDetail.encounter?.id === undefined ||
          appointmentDetail.encounter?.id?.length === 0
        ) {
          const encounter: R4.IEncounter | undefined =
            await addAndGetEncounterDetailsOfAppointment(appointmentDetail)

          appointmentDetail.encounter = encounter
        }

        //   const appointmentType =getAppointmentDisplayCode(fhirAppointments[1])

        state.appointmentFetched = true
        state.fetchingAppointmentDetails = false
        state.enableEndAppointmentButton = appointmentDetail.encounter
          ? fhirAppointments[0].encounter?.status !==
            R4.EncounterStatusKind._finished
          : false
        state.enableStartVideoButton = true
        state.appointmentDetails = appointmentDetail

        dispatch(appointmentDetailsSlice.actions.updatedStatus(state))
      } else {
        state.errorWhileFetchingAppointment = true
        state.fetchingAppointmentDetails = false
        state.errorReason = 'Appointment not available'
        dispatch(appointmentDetailsSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
      state.errorWhileFetchingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details`
      dispatch(appointmentDetailsSlice.actions.updatedStatus(state))
    }
  }

export default appointmentDetailsSlice.reducer
